import * as API from './OPX.API';
import { OpmLogicalThing } from '../models/LogicalPart/OpmLogicalThing';
import { OpmVisualThing } from '../models/VisualPart/OpmVisualThing';
import { OpmLogicalState } from '../models/LogicalPart/OpmLogicalState';
import { OpmRelation } from '../models/LogicalPart/OpmRelation';
import { OpmLink } from '../models/VisualPart/OpmLink';
import { util } from 'jointjs';
import uuid = util.uuid;
import { OpmModel } from '../models/OpmModel';
import * as Log from './OPX.LogFile';
import { EntityType, RelationType } from '../models/model/entities.enum';
import { logicalFactoryInsertCurrentOPD } from '../models/logical.factory';


//---------------------------------- Things Instances Section --------------------------------------------------------
export function handleThingInstances(ImportedOpmModel: OpmModel, InZoomedTree, UnfoldedTree,
  MainEntities, UnfoldMainEntities, ChildrenContainer, Instances, VThing, id,
  log, LogicalThing?: OpmLogicalThing<OpmVisualThing>, ThingData?: any) {
  let opd_Name;
  if (Instances) {
    for (let i = 0; i < Instances.length; i++) {
      opd_Name = Instances[i].$.name;

      for (let tree of InZoomedTree) {
        let Layout = tree.getLayout(opd_Name, VThing, id);
        if (Layout) {
          opd_Name = API.handleOPDName(opd_Name);
          if (opd_Name) {
            const params1 = {
              id: uuid(),
              xPos: parseInt(Layout.data.x),
              yPos: parseInt(Layout.data.y),
              width: parseInt(Layout.data.width),
              height: parseInt(Layout.data.height),
            };
            ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(opd_Name);
            if (LogicalThing && ImportedOpmModel.currentOpd) {
              if (VThing === 'VisualObject') {
                let visualObject = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, LogicalThing);
                LogicalThing.add(visualObject);
                API.HandleMainEntitiesInzoom(Layout, visualObject, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)
              }
              else {
                let visualProcess = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, LogicalThing);
                LogicalThing.add(visualProcess);
                API.HandleMainEntitiesInzoom(Layout, visualProcess, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)

              }
            }
            else if (ThingData && ImportedOpmModel.currentOpd) {
              let CheckThingExist = ImportedOpmModel.getLogicalElementByVisualId(ThingData.id);
              const params2 = {
                text: ThingData.text,
                affiliation: ThingData.affiliation,
                essence: ThingData.essence,
                id: ThingData.id,
                xPos: parseInt(Layout.data.x),
                yPos: parseInt(Layout.data.y),
                width: parseInt(Layout.data.width),
                height: parseInt(Layout.data.height),
              };

              if (VThing === 'VisualObject') {
                if (CheckThingExist) {
                  let visualObject = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, CheckThingExist);
                  CheckThingExist.add(visualObject);
                  API.HandleMainEntitiesInzoom(Layout, visualObject, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)

                }
                else {
                  let LogicalObject = logicalFactoryInsertCurrentOPD(EntityType.Object, ImportedOpmModel, params2);
                  let visualObject = ImportedOpmModel.getVisualElementById(ThingData.id);
                  API.HandleMainEntitiesInzoom(Layout, visualObject, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)
                }

              }
              else {
                if (CheckThingExist) {
                  let visualProcess = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, CheckThingExist);
                  CheckThingExist.add(visualProcess);
                  API.HandleMainEntitiesInzoom(Layout, visualProcess, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)

                } else {
                  let LogicalProcess = logicalFactoryInsertCurrentOPD(EntityType.Process, ImportedOpmModel, params2);
                  let visualProcess = ImportedOpmModel.getVisualElementById(ThingData.id);
                  API.HandleMainEntitiesInzoom(Layout, visualProcess, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer)

                }

              }

            }
          }
        }

      }

      for (let tree of UnfoldedTree) {
        let Layout = tree.getLayout(opd_Name, VThing, id);
        if (Layout) {
          opd_Name = API.handleOPDName(opd_Name);
          const params1 = {
            id: uuid(),
            xPos: parseInt(Layout.data.x),
            yPos: parseInt(Layout.data.y),
            width: parseInt(Layout.data.width),
            height: parseInt(Layout.data.height),
          };
          if (opd_Name) {
            ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(opd_Name);
            if (LogicalThing && ImportedOpmModel.currentOpd) {
              if (VThing === 'VisualObject') {
                let visualObject = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, LogicalThing);
                LogicalThing.add(visualObject);
                API.HandleMainEntitiesUnfold(Layout, visualObject, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);

              }
              else {
                let visualProcess = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, LogicalThing);
                LogicalThing.add(visualProcess);
                API.HandleMainEntitiesUnfold(Layout, visualProcess, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);

              }

            }
            else if (ThingData && ImportedOpmModel.currentOpd) {
              let CheckThingExist = ImportedOpmModel.getLogicalElementByVisualId(ThingData.id);
              const params2 = {
                text: ThingData.text,
                affiliation: ThingData.affiliation,
                essence: ThingData.essence,
                id: ThingData.id,
                xPos: parseInt(Layout.data.x),
                yPos: parseInt(Layout.data.y),
                width: parseInt(Layout.data.width),
                height: parseInt(Layout.data.height),
              };
              if (VThing === 'VisualObject') {
                if (CheckThingExist) {
                  let visualObject = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, CheckThingExist);
                  CheckThingExist.add(visualObject);
                  API.HandleMainEntitiesUnfold(Layout, visualObject, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);

                } else {
                  let LogicalObject = logicalFactoryInsertCurrentOPD(EntityType.Object, ImportedOpmModel, params2);
                  let visualObject = ImportedOpmModel.getVisualElementById(ThingData.id);
                  API.HandleMainEntitiesUnfold(Layout, visualObject, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);


                }
              }

              else {
                if (CheckThingExist) {
                  let visualProcess = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, CheckThingExist);
                  CheckThingExist.add(visualProcess);
                  API.HandleMainEntitiesUnfold(Layout, visualProcess, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);
                } else {
                  let LogicalProcess = logicalFactoryInsertCurrentOPD(EntityType.Process, ImportedOpmModel, params2);
                  let visualProcess = ImportedOpmModel.getVisualElementById(ThingData.id);
                  API.HandleMainEntitiesUnfold(Layout, visualProcess, ImportedOpmModel.currentOpd.id, UnfoldMainEntities);

                }

              }
            }
          }
        }

      }
    }
  }
  else {
    Log.EditLogFile(log, 'Info: ' + VThing + ' ' + id, 'Check .opx version file , Thing Instances Issue', false);
  }

}

//------------------------------------------------------Link Instances Section ------------------------------------------

/**
 *
 * @param LogiclLink
 * @param SourceID
 * @param TargetID
 * @param id
 * @param OPD
 * @returns {boolean}
 * @constructor
 * Handle Structural Links duplicates in OPX File
 */
function CheckStructuralLinkExist(LogiclLink, SourceID, TargetID, id, OPD) {
  for (let visualInOpd of OPD.visualElements) {
    for (let visualInLogical of LogiclLink.visualElements) {
      if (visualInOpd.id === visualInLogical.id) {
        if (visualInOpd.sourceVisualElement.id === SourceID &&
          visualInOpd.targetVisualElements[0].id === TargetID) {
          return true;
        }
      }
    }
  }
  return false
}


function OPXStructuralParamsFull(ImportedOpmModel, LinkData, Layout, id) {

  const params = {
    id: uuid(),
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.dataSource.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.dataTarget.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    linkConnectionType: LinkData.linkConnectionType,
    linkType: LinkData.linkType,
    symbolPos: API.checkTrianglePosition(API.VisualFundamentalRelationSection(Layout.dataVisual, id))
  };
  return params;
}
function OPXStructuralParamsPartial(ImportedOpmModel, Layout, id) {
  const params = {
    id: uuid(),
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.dataSource.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.dataTarget.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    symbolPos: API.checkTrianglePosition(API.VisualFundamentalRelationSection(Layout.dataVisual, id))
  };
  return params;

}
function OPXTaggedParamsFull(ImportedOpmModel, LinkData, Layout, id) {
  const params = {
    id: LinkData.id,
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.dataSource.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.dataTarget.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    linkConnectionType: LinkData.linkConnectionType,
    linkType: LinkData.linkType,
    BreakPoints: API.VisualGeneralRelationSection(Layout.dataVisual, id),
    tag: LinkData.forwardTag,
    backwardTag: LinkData.backwardTag,
  };
  return params;

}
function OPXTaggedParamsPartial(ImportedOpmModel, Layout, id) {
  const params = {
    id: uuid(),
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.dataSource.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.dataTarget.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    BreakPoints: API.VisualGeneralRelationSection(Layout.dataVisual, id)
  };

  return params;
}

function OPXProceduralParamsPartial(ImportedOpmModel, Layout, id) {
  const params = {
    id: uuid(),
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.data.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.data.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    BreakPoints: API.VisualLinkSection(Layout.dataVisual, id),
  };

  return params;
}
function OPXProceduralParamsFull(ImportedOpmModel, LinkData, Layout, id) {
  const params = {
    id: LinkData.id,
    sourceElementId: API.MatchLinksSourceOrTarget(Layout.data.sourceId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    targetElementId: API.MatchLinksSourceOrTarget(Layout.data.destinationId, ImportedOpmModel.currentOpd, ImportedOpmModel),
    linkConnectionType: LinkData.linkConnectionType,
    linkType: LinkData.linkType,
    /* sourceConnectedLinks: LinkData.sourceConnectedLinks,
     targetConnectedLinks: LinkData.targetConnectedLinks,
     targetLogicalConnection: LinkData.targetLogicalConnection,
     sourceLogicalConnection: LinkData.sourceLogicalConnection,*/
    condition: LinkData.condition,
    event: LinkData.event,
    BreakPoints: API.VisualLinkSection(Layout.dataVisual, id),
  };

  return params;
}

function LinkInstanceTree(ImportedOpmModel, Tree, IsFundamental, opd_Name, id, opmRelation, LinkData, log) {
  for (let tree of Tree) {
    if (IsFundamental) {
      let Layout = tree.getRelationLayout(opd_Name, id);
      if (Layout) {
        opd_Name = API.handleOPDName(opd_Name);
        if (Layout.type === 'Fundamental') {
          if (opd_Name && Layout.dataTarget.visible === 'true') {
            ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(opd_Name);
            const params1 = OPXStructuralParamsPartial(ImportedOpmModel, Layout, id);
            if (ImportedOpmModel.currentOpd && opmRelation) {
              if (params1.sourceElementId && params1.targetElementId) {
                let visualLink = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, opmRelation);
                opmRelation.add(visualLink);
              }
              else {
                Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params1.sourceElementId + ' target: ' + params1.targetElementId, 'Check .opx version file , Link source / target issue', false);
              }
            }
            else if (ImportedOpmModel.currentOpd && LinkData) {
              let CheckLinkExist = ImportedOpmModel.getLogicalElementByVisualId(LinkData.id);
              const params2 = OPXStructuralParamsFull(ImportedOpmModel, LinkData, Layout, id);
              if (CheckLinkExist) {
                if (!CheckStructuralLinkExist(CheckLinkExist, params1.sourceElementId, params1.targetElementId, LinkData.id, ImportedOpmModel.currentOpd)) {
                  if (params2.sourceElementId && params2.targetElementId) {
                    let logicalLink = logicalFactoryInsertCurrentOPD(RelationType.Fundamental, ImportedOpmModel, params2);
                  }
                  else {
                    Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
                  }
                }
              }
              else {
                if (params2.sourceElementId && params2.targetElementId) {
                  let logicalLink = logicalFactoryInsertCurrentOPD(RelationType.Fundamental, ImportedOpmModel, params2);
                }
                else {
                  Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
                }
              }
            }
          }

        }
        if (Layout.type === 'General') {
          if (opd_Name) {
            ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(opd_Name);
            const params1 = OPXTaggedParamsPartial(ImportedOpmModel, Layout, id);
            if (ImportedOpmModel.currentOpd && opmRelation) {
              if (params1.sourceElementId && params1.targetElementId) {
                let visualLink = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, opmRelation);
                opmRelation.add(visualLink);
              }
              else {
                Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params1.sourceElementId + ' target: ' + params1.targetElementId, 'Check .opx version file , Link source / target issue', false);
              }
            }
            else if (ImportedOpmModel.currentOpd && LinkData) {
              let CheckLinkExist = ImportedOpmModel.getLogicalElementByVisualId(LinkData.id);
              const params2 = OPXTaggedParamsFull(ImportedOpmModel, LinkData, Layout, id);
              if (CheckLinkExist) {
                if (!CheckStructuralLinkExist(CheckLinkExist, params1.sourceElementId, params1.targetElementId, LinkData.id, ImportedOpmModel.currentOpd)) {
                  if (params2.sourceElementId && params2.targetElementId) {
                    let logicalLink = logicalFactoryInsertCurrentOPD(RelationType.Tagged, ImportedOpmModel, params2);
                  }
                  else {
                    Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
                  }
                }
              }
              else {
                if (params2.sourceElementId && params2.targetElementId) {
                  
                  let logicalLink = logicalFactoryInsertCurrentOPD(RelationType.Tagged, ImportedOpmModel, params2);
                }
                else {
                  Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
                }
              }
            }
          }
        }
      }

    }
    else {
      let Layout = tree.getLinkLayout(opd_Name, id);
      if (Layout) {
        opd_Name = API.handleOPDName(opd_Name);
        if (opd_Name) {
          ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(opd_Name);
          const params1 = OPXProceduralParamsPartial(ImportedOpmModel, Layout, id);
          if (ImportedOpmModel.currentOpd && opmRelation) {
            if (params1.sourceElementId && params1.targetElementId) {
              let visualLink = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, opmRelation);
              opmRelation.add(visualLink);
            }
            else {
              Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params1.sourceElementId + ' target: ' + params1.targetElementId, 'Check .opx version file , Link source / target issue', false);
            }
          }
          else if (ImportedOpmModel.currentOpd && LinkData) {
            let CheckLinkExist = ImportedOpmModel.getLogicalElementByVisualId(LinkData.id);
            const params2 = OPXProceduralParamsFull(ImportedOpmModel, LinkData, Layout, id);
            if (CheckLinkExist) {
              if (params2.sourceElementId && params2.targetElementId) {
                let visualLink = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, CheckLinkExist);
                CheckLinkExist.add(visualLink);
              }
              else {
                Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
              }
            } else {
              if (params2.sourceElementId && params2.targetElementId) {
                let logicalLink = <any>logicalFactoryInsertCurrentOPD(RelationType.Procedural, ImportedOpmModel, params2);
                if (logicalLink.targetLogicalConnection) {
                  logicalLink.visualElements[0]['targetLogicalConnection'] = logicalLink.targetLogicalConnection;
                }
                if (logicalLink.sourceLogicalConnection) {
                  logicalLink.visualElements[0]['sourceLogicalConnection'] = logicalLink.sourceLogicalConnection;
                }
              }
              else {
                Log.EditLogFile(log, 'Info: Link ID: ' + id + ' source: ' + params2.sourceElementId + ' target: ' + params2.targetElementId, 'Check .opx version file , Link source / target issue', false);
              }
            }
          }
        }
      }
    }
  }
}


export function handleLinkInstances(ImportedOpmModel: OpmModel, InZoomedTree, UnfoldedTree
  , Instances, id, IsFundamental, log,
  opmRelation?: OpmRelation<OpmLink>, LinkData?: any) {
  let opd_Name;
  if (Instances) {
    for (let i = 0; i < Instances.length; i++) {
      opd_Name = Instances[i].$.name;
      //For Inzoomed Tree
      LinkInstanceTree(ImportedOpmModel, InZoomedTree, IsFundamental, opd_Name, id, opmRelation, LinkData, log)
      // For Unfolded Tree
      LinkInstanceTree(ImportedOpmModel, UnfoldedTree, IsFundamental, opd_Name, id, opmRelation, LinkData, log)
    }
  }
  else {
    Log.EditLogFile(log, 'Info: Link ID:' + id, 'Check .opx version file , Link Instances Issue', false);
  }

}




//---------------------------------------------------State Instances Section ------------------------------------------------
export function handleStateInstances(ImportedOpmModel: OpmModel, InZoomedTree, UnfoldedTree, MainEntities, ChildrenContainer,
  Instances, VThing, Objid, stateID, log,
  logicalState?: OpmLogicalState, StateData?: any) {
  let OPDName;
  if (Instances) {
    for (let inst in Instances) {
      OPDName = Instances[inst].$.name;
      for (let tree of InZoomedTree) {
        let Layout = tree.getLayout(OPDName, VThing, Objid);
        if (Layout) {
          OPDName = API.handleOPDName(OPDName);
          for (let state in Layout.data) {
            if (Layout.data[state].InstanceAttr[0].$.entityId === stateID) {
              let visual = Layout.data[state].ConnectionEdgeAttr[0].$;
              const params1 = {
                id: uuid(),
                xPos: parseInt(visual.x),
                yPos: parseInt(visual.y),
                width: parseInt(visual.width),
                height: parseInt(visual.height),
              };
              if (Layout.data[state].$.visible === 'true' && OPDName) {
                ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(OPDName);
                if (logicalState && ImportedOpmModel.currentOpd) {
                  let visualState = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, logicalState);
                  logicalState.add(visualState);
                  API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                  API.HandleMainEntitiesInzoom(Layout, visualState, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer);
                }
                else if (StateData && ImportedOpmModel.currentOpd) {
                  let checkLogicalExist = ImportedOpmModel.getLogicalElementByVisualId(StateData.id);
                  const params2 = {
                    id: StateData.id,
                    text: StateData.text,
                    stateType: StateData.stateType,
                    xPos: parseInt(visual.x),
                    yPos: parseInt(visual.y),
                    width: parseInt(visual.width),
                    height: parseInt(visual.height),
                  };
                  if (checkLogicalExist) {
                    let visualState = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, checkLogicalExist);
                    checkLogicalExist.add(visualState);
                    API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                    API.HandleMainEntitiesInzoom(Layout, visualState, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer);
                  }
                  else {
                    let LogicalState = logicalFactoryInsertCurrentOPD(EntityType.State, ImportedOpmModel, params2);
                    let visualState = ImportedOpmModel.getVisualElementById(StateData.id);
                    API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                    API.HandleMainEntitiesInzoom(Layout, visualState, ImportedOpmModel.currentOpd.id, MainEntities, ChildrenContainer);
                  }

                }
              }
            }
          }
        }
      }

      for (let tree of UnfoldedTree) {
        let Layout = tree.getLayout(OPDName, VThing, Objid);

        if (Layout) {
          OPDName = API.handleOPDName(OPDName);
          for (let state in Layout.data) {
            if (Layout.data[state].InstanceAttr[0].$.entityId === stateID) {
              let visual = Layout.data[state].ConnectionEdgeAttr[0].$;
              const params1 = {
                id: uuid(),
                xPos: parseInt(visual.x),
                yPos: parseInt(visual.y),
                width: parseInt(visual.width),
                height: parseInt(visual.height),
              };
              if (Layout.data[state].$.visible === 'true' && OPDName) {
                ImportedOpmModel.currentOpd = ImportedOpmModel.getOpdByName(OPDName);
                if (logicalState && ImportedOpmModel.currentOpd) {
                  let visualState = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, logicalState);
                  logicalState.add(visualState);
                  API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                }
                else if (StateData && ImportedOpmModel.currentOpd) {
                  let checkLogicalExist = ImportedOpmModel.getLogicalElementByVisualId(StateData.id);
                  const params2 = {
                    id: StateData.id,
                    text: StateData.text,
                    stateType: StateData.stateType,
                    xPos: parseInt(visual.x),
                    yPos: parseInt(visual.y),
                    width: parseInt(visual.width),
                    height: parseInt(visual.height),
                  };
                  if (checkLogicalExist) {
                    let visualState = ImportedOpmModel.createNewVisualElementInsertToCurrentOpd(params1, checkLogicalExist);
                    checkLogicalExist.add(visualState);
                    API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                  }
                  else {
                    let LogicalState = logicalFactoryInsertCurrentOPD(EntityType.State, ImportedOpmModel, params2)
                    let visualState = ImportedOpmModel.getVisualElementById(StateData.id);
                    API.MatchFatherElements(Objid, visualState, ImportedOpmModel.currentOpd, ImportedOpmModel);
                  }

                }
              }
            }
          }
        }
      }

    }
  }
  else {
    Log.EditLogFile(log, 'Info: State ID:' + stateID + ' Object ID: ' + Objid, 'Check .opx version file , State Instances Issue', false);
  }

}
