import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';

@Component ({
  selector: 'opcloud-new-model-dialog',
  templateUrl: 'new-model-dialog.html',
  styleUrls: ['new-model-dialog.css']
})
export class NewModelComponent implements OnInit {


  constructor(
    @Optional() public dialogRef: MatDialogRef<NewModelComponent>) {
  }

  ngOnInit() {
  }
}
