import { oplFunctions} from '../../opl-generation/opl-functions';

export function createDialog(options, link ) {

  const dialogComponentRef = {
    type: 'choose-link',
    instance: {
      newLink: link,
      linkSource: link.getSourceElement(),
      linkTarget: link.getTargetElement(),
      opmLinks: oplFunctions.generateLinksWithOpl(link),
      Structural_Links: [],
      Agent_Links: [],
      Agent_Negation_Links: [],
      Instrument_Links: [],
      Instrument_Negation_Links: [],
      Effect_links: [],
      Effect_Negation_links: [],
      Consumption_links: [],
      Consumption_Negation_links: [],
      Result_Link: [],
      Invocation_links: [],
      Exception_links: [],
      Relation_Links: [],
      In_out_Link_Pair: [],
      replaceTriangleLink: link.replaceTriangleLink,
    },
    chosen: false
  };


  for (const link of dialogComponentRef.instance.opmLinks) {
    // in_out_link
    // if (link.name === 'In/out_linkPair') {
    //   dialogComponentRef.instance.In_out_Link_Pair.push(link);
    // }
    // Relation Links
    if (link.name === 'Unidirectional_Tagged_Link'
      || link.name === 'Bidirectional_Tagged_Link')
    {
      dialogComponentRef.instance.Relation_Links.push(link); }
    // Structural Links
    else if (link.name === 'Aggregation-Participation'
      || link.name === 'Generalization-Specialization'
      || link.name === 'Exhibition-Characterization'
      || link.name === 'Classification-Instantiation') {
      dialogComponentRef.instance.Structural_Links.push(link);
    } else if (link.name === 'Agent' || link.name === 'Agent_Event' || link.name === 'Agent_Condition') {
      // Agent Links
      dialogComponentRef.instance.Agent_Links.push(link);
    } else if (link.name === 'Instrument' || link.name === 'Instrument_Condition' || link.name === 'Instrument_Event') {
      // Instrument links
      dialogComponentRef.instance.Instrument_Links.push(link);
    } else if ( link.name === 'Effect'|| link.name === 'Effect_Condition' || link.name === 'Effect_Event') {
      // Effect links
      dialogComponentRef.instance.Effect_links.push(link);
      // dialogComponentRef.instance.Effect_links.reverse();
    } else if (link.name === 'Consumption' || link.name === 'Consumption_Condition' || link.name === 'Consumption_Event') {
      // Consumption links
      dialogComponentRef.instance.Consumption_links.push(link);
    } else if (link.name === 'Result') {
      // Result
      dialogComponentRef.instance.Result_Link.push(link);
    } else if (link.name === 'Invocation') {
      // Invocation
      dialogComponentRef.instance.Invocation_links.push(link);
    } else if (link.name === 'Overtime_exception'
      || link.name === 'Undertime_exception'
      || link.name === 'OvertimeUndertime-exception') {
      // Exception Links
      dialogComponentRef.instance.Exception_links.push(link);
    } else if (link.name === 'In-out_Link_Pair' || link.name === 'Split_output' || link.name === 'Split_input'
      || link.name === 'In-out_Link_Pair_Condition' || link.name === 'In-out_Link_Pair_Event') {
      dialogComponentRef.instance.In_out_Link_Pair.push(link);
      // console.log(dialogComponentRef.instance)
      } else if (link.name === 'Agent_Negation' || link.name === 'Agent_Condition_Negation') {
      // Agent Negation Links
      dialogComponentRef.instance.Agent_Negation_Links.push(link);
    } else if (link.name === 'Instrument_Negation' || link.name === 'Instrument_Condition_Negation') {
      // Instrument Negation Links
      dialogComponentRef.instance.Instrument_Negation_Links.push(link);
    } else if (link.name === 'Consumption_Negation' || link.name === 'Consumption_Condition_Negation') {
      // Consumption Negation Links
      dialogComponentRef.instance.Consumption_Negation_links.push(link);
    } else if (link.name === 'Effect_Negation' || link.name === 'Effect_Condition_Negation') {
      // Effect Negation Links
      dialogComponentRef.instance.Effect_Negation_links.push(link);
    }
  }
  options.dialog$.next(dialogComponentRef);
  return dialogComponentRef;
}

