import { OpmOpd } from '../../models/OpmOpd';
import { OpmVisualThing } from '../../models/VisualPart/OpmVisualThing';
import { OpmVisualEntity } from '../../models/VisualPart/OpmVisualEntity';
import { OpmLink } from '../../models/VisualPart/OpmLink';
import { code, linkType } from '../../models/ConfigurationOptions';
import { OpmVisualObject } from '../../models/VisualPart/OpmVisualObject';
import {getAllParents, hasWhiteSpaces, ObjectItem, textToName} from './computationalPartUtils';
import { OpmModel } from '../../models/OpmModel';
import { OpmVisualElement } from '../../models/VisualPart/OpmVisualElement';
import { link } from 'fs';
import {OPCloudUtils} from "../rappidEnviromentFunctionality/shared";

/**
 * A class that finds all of the objects connected (by aggregation/consists of links) to a given computational Opm process,
 * (connected to an object that is instrumentally linked to the process) and sets the computational objects
 * values in valuesArray.
 * nodesArray - an array of all of the objects as described above
 * OpmVisualProcess - the computational process
 * opmModel - the current opm model
 * callerCode - an enum representing the type of function caller: ros/mqtt/external/user defined
 * */
export class PathsFromObjectsToProcessCalculator {
  nodesArray = [];
  OpmVisualProcess;
  opmModel: OpmModel;
  opd: OpmOpd;
  callerCode: code;
  globalRunTimeEnvironment;

  constructor(opd: OpmOpd, OpmVisualProcess, opmModel: OpmModel, callerCode: code, globalRunTimeEnvironment?) {
    this.opmModel = opmModel;
    this.opd = opd;
    this.OpmVisualProcess = OpmVisualProcess;
    this.callerCode = callerCode;
    this.globalRunTimeEnvironment = globalRunTimeEnvironment;
    this.getRelatedValues(OpmVisualProcess.id, opd);
  }

  isNotInstanced(id) {
    const sc = this.opmModel.getCurrentConfiguration();
    if (sc[id] && sc[id].value === 0)
      return true;
    return false;
  }

  filterUnInstancedThings(all_paths) {
    if (!this.opmModel.getCurrentConfiguration())
      return;
    const keys = Array.from(all_paths.keys());
    for (let i = keys.length - 1; i >= 0 ; i--) {
      const key = keys[i];
      const availablePathsToTarget = all_paths.get(key);
      for (let j = availablePathsToTarget.length - 1; j >= 0 ; j--) {
        const pathArr = availablePathsToTarget[j];
        if (pathArr.find(item => this.isNotInstanced(item.elementId))) {
          availablePathsToTarget.splice(j, 1);
          continue;
        }
      }
    }
    for (let i = keys.length - 1; i >= 0 ; i--) {
      const key = keys[i];
      if (all_paths.get(key).length === 0)
        all_paths.delete(key);
    }
  }

  /**
   * returns all paths- a map between element id to all paths from this element to OpmVisualProcess on the given opd
   * (and sub opds of this opd- unfolded/inzoomed)
   * a path is a list of objects connected by aggregation/consists of links (but the element before the computational
   * process that the link from it to the process is instrumentally)
   * */
  public calculate(opd: OpmOpd, valuesArray: ObjectItem[]) {
    this.setValues(this.nodesArray, valuesArray, this.OpmVisualProcess);
    let all_paths = new Map<string, any>();
    const parents = getAllParents(this.OpmVisualProcess);
    this.nodesArray.forEach(elm => {
      if (elm instanceof OpmVisualObject || OPCloudUtils.isInstanceOfVisualState(elm)) {
        if (!all_paths.get(elm.logicalElement.lid))
          all_paths.set(elm.logicalElement.lid, []);
        const correctOpd = this.OpmVisualProcess.logicalElement.opmModel.getOpdByThingId(elm.id);
        if (correctOpd)
          this.getPaths(this.OpmVisualProcess, elm, all_paths, correctOpd);
        for (const p of parents)
          this.getPaths(p, elm, all_paths, correctOpd);
      }
    });
    this.filterUnInstancedThings(all_paths);
    return all_paths;
  }
  /**
   *  updates nodesArray to include all of the objects relevant to the visual element (aggregation/consists of links)
   *  with thingID id
   **/
  private getRelatedValues(thingID: string, opd: OpmOpd) {
    // const thingLinks = opd.visualElements.filter((elm) =>
    //   elm instanceof OpmLink &&
    //   (elm.targetVisualElements[0].targetVisualElement.id === thingID ||
    //     (((elm.type === linkType.Aggregation || elm.type === linkType.Exhibition)
    //       && elm.sourceVisualElement.id === thingID))) // instrument/ consists of/ exhibition link
    // );
    const visProcess = this.opmModel.getVisualElementById(thingID) as OpmVisualThing;
   const parentsIds = getAllParents(visProcess).map(l => l.id);
    const thingLinks = opd.visualElements.filter((elm) =>
      elm instanceof OpmLink &&
      ((elm.target.id === thingID || parentsIds.includes(elm.target.id)) &&
        (elm.type === linkType.Instrument || elm.type === linkType.Consumption || elm.type === linkType.Effect))
    );
    let visitedLinks = [];
    const that = this;
    thingLinks.forEach(link => {
      if (link instanceof OpmLink) {
        visitedLinks.push(link.id);
        let sourceId = link.sourceVisualElement.id;
        if (OPCloudUtils.isInstanceOfVisualState(link.sourceVisualElement))
          sourceId = (<any>link.sourceVisualElement).fatherObject.id;
        that.getRelatedNodesAux(sourceId, opd, visitedLinks, [], []);
        const model = link.logicalElement.opmModel;
        const visualSourceFromOtherOpds = model.getLogicalElementByVisualId(sourceId)?.visualElements || [];
        for (const visFromOtherOpd of visualSourceFromOtherOpds) {
          const relatedOpd = model.getOpdByThingId(visFromOtherOpd.id);
          if (relatedOpd)
            that.getRelatedNodesAux(visFromOtherOpd.id, relatedOpd, visitedLinks, [], []);
        }
      }
    });
  }
  /**
   * a recursive function updates nodesArray to include all of the objects relevant to the visual element with thingID id
   **/
  private getRelatedNodesAux(thingID: string, opd: OpmOpd, visitedLinks: string[],
    visitedInzoom: string[], visitedUnfold: string[]) {
    const is_user_defined_or_external_function = this.callerCode === code.External || this.callerCode === code.UserDefined
      || this.callerCode === code.Python || this.callerCode === code.GenAI;
    const thingIdVisualElement = this.opmModel.getVisualElementById(thingID);
    // if (thingIdVisualElement instanceof OpmVisualEntity && thingIdVisualElement.isComputational() &&
    //   thingIdVisualElement.getChildren().length === 1 && thingIdVisualElement.getChildren()[0].logicalElement.text) {
    //   this.nodesArray.push(thingIdVisualElement);
    //   this.nodesArray.sort((n1, n2) => n1.getPosition().x - n2.getPosition().x);
    // }
    const that = this;
    let thingLinks = (<any>thingIdVisualElement).getAllLinks().outGoing.filter(elm => that.isARelevantOutGoingLinkToComputation(elm, thingID));

    if ((thingIdVisualElement instanceof OpmVisualEntity && thingIdVisualElement.isComputational() && thingIdVisualElement.getChildren().length === 1 && thingIdVisualElement.getChildren()[0].logicalElement.text)
      || (this.isInstrumentallyLinked(thingIdVisualElement) && is_user_defined_or_external_function)) {
      if (!(this.nodesArray.filter(elm => elm.logicalElement.lid === thingIdVisualElement.logicalElement.lid).length > 0)) {
        this.nodesArray.push(thingIdVisualElement);
      }
    }
    if (thingIdVisualElement instanceof OpmVisualObject && !thingIdVisualElement.isComputational() && thingIdVisualElement.states?.length > 0 && !this.nodesArray.includes(thingIdVisualElement)) {
      this.nodesArray.push(thingIdVisualElement);
    }
    if (thingIdVisualElement instanceof OpmVisualThing) {
      const thingIdVisualElementUnfold = thingIdVisualElement.getRefineeUnfold(); // this element is unfolded
      if (thingIdVisualElementUnfold && !visitedUnfold.includes(thingIdVisualElementUnfold.id)) {
        const thingIdVisualUnfoldedId = thingIdVisualElementUnfold.id;
        const thingIdUnfoldedOpd = this.opmModel.getOpdByThingId(thingIdVisualUnfoldedId); // find the opd included
        visitedUnfold.push(thingIdVisualElementUnfold.id);
        this.getRelatedNodesAux(thingIdVisualUnfoldedId, thingIdUnfoldedOpd, visitedLinks, visitedInzoom, visitedUnfold);
      }
      const thingIdVisualElementInzoom = thingIdVisualElement.getRefineeInzoom();  // this element is inzoomed
      if (thingIdVisualElementInzoom && !visitedInzoom.includes(thingIdVisualElementInzoom.id)) {
        const thingIdVisualInzoomId = thingIdVisualElementInzoom.id;
        const thingIdInzoomOpd = this.opmModel.getOpdByThingId(thingIdVisualInzoomId); // find the opd included
        visitedInzoom.push(thingIdVisualInzoomId);
        this.getRelatedNodesAux(thingIdVisualInzoomId, thingIdInzoomOpd, visitedLinks, visitedInzoom, visitedUnfold);
      }
    }
    const links_of_stereotypes: OpmLink[] = [];
    const possible_stereotype = (thingIdVisualElement && thingIdVisualElement.logicalElement &&
      (<any>thingIdVisualElement.logicalElement).getStereotype) ? (<any>thingIdVisualElement).logicalElement.getStereotype() : undefined;
    if (possible_stereotype) { // adding the logical elements linked to the stereotype (according to it's definition)
      const stereotypes_links = (thingIdVisualElement as OpmVisualThing).getAllLinks();
      stereotypes_links.outGoing.filter(link => that.elmIsLinkAndLinkTypeIsRelevant(link) && !thingLinks.includes(link) && !links_of_stereotypes.includes(link))
        .forEach(link => {
          const itsOpd = that.opmModel.getOpdByThingId(link.id);
          if (itsOpd && !itsOpd.isHidden)
            links_of_stereotypes.push(link);
        });
    }
    links_of_stereotypes.forEach(link => {
      const new_opd = that.opmModel.getOpdByElement(link.source);
      // then we should check the target and not the source (because we came from the source)
      if (link instanceof OpmLink && !visitedLinks.includes(link.id)) {
        visitedLinks.push(link.id);
        this.getRelatedNodesAux(link.targetVisualElements[0].targetVisualElement.id, new_opd, visitedLinks, visitedInzoom, visitedUnfold);
      }
    });
    if (thingLinks.length > 0) { // there are more links to traverse
      thingLinks.forEach(link => {
        if (link instanceof OpmLink && !visitedLinks.includes(link.id)) {
          if (link.type === linkType.Aggregation || link.type === linkType.Exhibition || link.type === linkType.Instantiation) { // if its an aggregation link (consists of),
            // then we should check the target and not the source (because we came from the source)
            visitedLinks.push(link.id);
            const correctOpd = this.opmModel.getOpdByThingId(link.id);
            this.getRelatedNodesAux(link.targetVisualElements[0].targetVisualElement.id, correctOpd, visitedLinks,
              visitedInzoom, visitedUnfold);
          } else {
            visitedLinks.push(link.id);
            const correctOpd = this.opmModel.getOpdByThingId(link.id);
            this.getRelatedNodesAux(link.sourceVisualElement.id, correctOpd, visitedLinks, visitedInzoom, visitedUnfold);
          }
        }
      });
    }
    // traverse children of inzoom objects
    const thingChildren = opd.visualElements.filter((elm) => //
      elm instanceof OpmVisualThing && thingIdVisualElement instanceof OpmVisualThing && thingIdVisualElement.children.includes(elm));
    thingChildren.forEach(child => {
      if (!visitedInzoom.includes(child.id)) {
        visitedInzoom.push(child.id);
        this.getRelatedNodesAux(child.id, opd, visitedLinks, visitedInzoom, visitedUnfold);
      }
    });
  }
  /**
   * a function that for each object in nodesArray updates it's value in valuesArray
   **/
  private setValues(nodesArray: any[], valuesArray: ObjectItem[], OpmVisualProcess) {
    const is_user_defined_or_external_function = this.callerCode === code.UserDefined || this.callerCode === code.External;
    const that = this;
    for (let i = 0; i < nodesArray.length; i++) {
      const elementId = nodesArray[i].logicalElement.lid;
      const sourceElementValue = nodesArray[i].logicalElement.value;
      const name = nodesArray[i].logicalElement.getStereotype && nodesArray[i].logicalElement.getStereotype() ?
        textToName(nodesArray[i].logicalElement.getDisplayText().trim().substring(nodesArray[i].logicalElement.text.lastIndexOf('>') + 1))
        : textToName(nodesArray[i].logicalElement.getDisplayText().trim());
      const alias = nodesArray[i].logicalElement.alias;
      const sourceElementUnit = nodesArray[i].logicalElement.units;
      if (sourceElementValue !== undefined && sourceElementValue !== null && sourceElementValue) {
        // if the current node is computational, we want to keep its value
        if (sourceElementValue && (sourceElementValue !== 'None')) {
          valuesArray.push({ sourceElementValue, sourceElementUnit, name, alias, elementId });
        }
        if (OpmVisualProcess.logicalElement.isTimeDuration()) {
          valuesArray.push({ name });
        }
      } // if the element is instrumentally linked to the process, and has no value, the value should be the object name, for extrnal/user defined functions
      if (sourceElementValue === undefined || sourceElementValue === null || sourceElementValue === 'None') {
        if (that.isInstrumentallyLinked(nodesArray[i]) && is_user_defined_or_external_function) {
          // if alias or name can be used as a variable (not considering if there is already a variable with this name yet)
          if ((name && !hasWhiteSpaces(name)) || (alias && !hasWhiteSpaces(alias))) {
            const val = this.globalRunTimeEnvironment?.objects.get(nodesArray[i].logicalElement.text)?.state || name;
            valuesArray.push({ sourceElementValue: val, sourceElementUnit, name, alias, elementId });
          }
        }
      }
    }
  }
  /**
   * updates all paths map - a map between objectTarget id to all paths from objectTarget to processSource on the given opd
   * (and sub opds of this opd- unfolded/inzoomed)
   * a path is a list of objects connected by aggregation/consists of links (but the element before the computational
   * process that the link from it to the process is instrumentally)
   **/
  private getPaths(processSource, objectTarget, all_paths: Map<string, ObjectItem[][]>, opd: OpmOpd) {
    const that = this;
    // const thingLinks = opd.visualElements.filter((elm) =>
    //   elm instanceof OpmLink &&
    //   (elm.targetVisualElements[0].targetVisualElement.id === processSource.id && (elm.type === linkType.Instrument || elm.type === linkType.Consumption || elm.type === linkType.Effect))
    // );
    const thingLinks = processSource.getAllLinks().inGoing;
    thingLinks.forEach(link => { // for each link, find out to who it is connected by instrument or consist of links
      if (link instanceof OpmLink) {
        const correctOpd = this.opmModel.getOpdByThingId(link.id);
        that.getAllPaths(link.source, objectTarget, [], [], all_paths, correctOpd, false, false);
      }
    });
  }
  /**
   * a recursive function that updates all_paths to include a map between objectTarget id to all paths from objectTarget
   * to source on the given opd (and sub opds of this opd- unfolded/inzoomed)
   * the function idea is to be very similar to dfs tree traversal
   **/
  private getAllPaths(sourceInitial, objectTarget, visited: boolean[], path: any[], all_paths: Map<string, ObjectItem[][]>,
    opd: OpmOpd, firstUnFold: boolean, firstInzoom: boolean) {
    let source = sourceInitial;
    visited[source.id] = true;
    visited[source.logicalElement.lid] = true;
    const possible_stereotype = (source && source.logicalElement && source.logicalElement.getStereotype) ? source.logicalElement.getStereotype() : undefined;
    if (!firstUnFold && !firstInzoom) { // not to put the inzoomed/unfolded element twice
      if (possible_stereotype === undefined || possible_stereotype === null) {
        let src = source.logicalElement;
        if (OPCloudUtils.isInstanceOfLogicalState(src)) {
          src = src.parent;
          source = source.fatherObject;
          visited[source.id] = true;
        }
        path.push({ elementId: src.lid, alias: src.alias, name: textToName(src.text) });
      } else { // stereotyped object name should be without <<,>>
        path.push({
          elementId: source.logicalElement.lid, alias: source.logicalElement.alias,
          name: textToName(source.logicalElement.text.substring(source.logicalElement.text.lastIndexOf('>') + 1))
        });
      }
    }
    if (source.id === objectTarget.id || source?.fatherObject?.id === objectTarget.id) {
      let new_path = [];
      path.forEach(elm => new_path.push(elm));
      if (all_paths.has(objectTarget.logicalElement.lid) && !this.pathAlreadyExists(all_paths.get(objectTarget.logicalElement.lid), new_path)) { // for multiple paths
        (all_paths.get(objectTarget.logicalElement.lid)).push(new_path);
      } else {
        if (!all_paths.has(objectTarget.logicalElement.lid)) {
          all_paths.set(objectTarget.logicalElement.lid, [new_path]);
        }
      }
    } else {
      const that = this;
      const thingLinks = source.getAllLinks().outGoing.filter((elm) =>
        elm instanceof OpmLink && visited[elm.target.logicalElement.lid] !== true && that.isARelevantOutGoingLinkToComputation(elm, source.id));
      const links_of_stereotypes: OpmLink[] = [];
      if (possible_stereotype) { // adding the logical elements linked to the stereotype (according to it's definition)
        const stereotypes_links = (source as OpmVisualThing).getAllLinks();
        stereotypes_links.outGoing.filter(link =>
          that.elmIsLinkAndLinkTypeIsRelevant(link) && !thingLinks.includes(link) &&
          !links_of_stereotypes.includes(link)).forEach(link => links_of_stereotypes.push(link));
      }
      thingLinks.forEach(link => {
        if (link instanceof OpmLink && visited[link.target.logicalElement.lid] !== true) {
          if (that.isARelevantOutGoingLinkToComputation(link, source.id)
            && visited[link.targetVisualElements[0].targetVisualElement.id] !== true) { // if its an aggregation link (consists of),
            // then we should check the target and not the source (because we came from the source)
            this.getAllPaths(link.targetVisualElements[0].targetVisualElement, objectTarget, visited, path, all_paths, opd, false, false);
          } else if (visited[link.sourceVisualElement.id] !== true) {
            this.getAllPaths(link.sourceVisualElement, objectTarget, visited, path, all_paths, opd, false, false);
          }
        }
      });
      links_of_stereotypes.forEach(link => {
        const new_opd = that.opmModel.getOpdByElement(link.source);
        if (visited[link.targetVisualElements[0].targetVisualElement.id] !== true) { // if its an aggregation link (consists of),
          // then we should check the target and not the source (because we came from the source)
          this.getAllPaths(link.targetVisualElements[0].targetVisualElement, objectTarget, visited, path, all_paths, new_opd, false, false);
        } else {
          this.getAllPaths(link.sourceVisualElement, objectTarget, visited, path, all_paths, new_opd, false, false);
        }
      });
      if (source instanceof OpmVisualThing) {
        const thingIdVisualElementUnfold = source.getRefineeUnfold();
        if (thingIdVisualElementUnfold && visited[thingIdVisualElementUnfold.id] !== true) { // this element is unfolded
          const thingIdVisualUnfoldedId = thingIdVisualElementUnfold.id;
          const thingIdUnfoldedOpd = this.opmModel.getOpdByThingId(thingIdVisualUnfoldedId); // find the opd included
          this.getAllPaths(thingIdVisualElementUnfold, objectTarget, visited, path, all_paths, thingIdUnfoldedOpd, true, false);
        }
        const thingIdVisualElementInzoom = source.getRefineeInzoom();
        if (thingIdVisualElementInzoom && visited[thingIdVisualElementInzoom.id] !== true) { // this element is inzoomed
          const thingIdVisualInzoomId = thingIdVisualElementInzoom.id;
          const thingIdInzoomOpd = this.opmModel.getOpdByThingId(thingIdVisualInzoomId); // find the opd included
          this.getAllPaths(thingIdVisualElementInzoom, objectTarget, visited, path, all_paths, thingIdInzoomOpd, false, true);
        }
      }
      // traverse children of inzoom objects
      const thingChildren = opd.visualElements.filter((elm) => //
        elm instanceof OpmVisualThing && source instanceof OpmVisualThing && source.children.includes(elm));
      thingChildren.forEach(child => {
        if (visited[child.id] !== true) {
          this.getAllPaths(child, objectTarget, visited, path, all_paths, opd, false, false);
        }
      });
    }
    if (path.length > 0 && (!firstUnFold && !firstInzoom)) {
      path.pop();
    }
    visited[source.id] = false;
  }

  /**
   * receives a visual element and returns true if the element is instrumentally linked to the computational process, false otherwise
   * */
  private isInstrumentallyLinked(thingIdVisualElement: OpmVisualElement) {
    const visProcess = this.OpmVisualProcess
    const moreRelevantTargetsIds = getAllParents(visProcess).map(pr => pr.id);
    return this.opd.visualElements.filter((elm) =>
      elm instanceof OpmLink &&
      (     elm.source && thingIdVisualElement &&
            (elm.source.logicalElement.lid === thingIdVisualElement.logicalElement.lid || elm.source.fatherObject?.logicalElement?.lid === thingIdVisualElement.logicalElement.lid)
            && ((elm.target.id === this.OpmVisualProcess.id) || (moreRelevantTargetsIds.includes(elm.target.id))) &&
            elm.type === linkType.Instrument)
      ).length > 0;
  }

  /* receives a map entrance and a new path, a checks if the new_path exists in the map entrance*/
  private pathAlreadyExists(mapEntrance: any, new_path: ObjectItem[]) {
    for (let index = 0; index < mapEntrance.length; index++) {
      const path = mapEntrance[index];
      if (this.comparePaths(path, new_path)) {
        return true;
      }
    }
    return false;
  }
  /*compares two paths, returns true if it is the same, false otherwise*/
  private comparePaths(path: ObjectItem[], new_path: ObjectItem[]) {
    if (path.length !== new_path.length) {
      return false;
    }
    for (let i = 0; i < path.length; i++) {
      if (path[i].elementId !== new_path[i].elementId) {
        return false;
      }
    }
    return true;
  }
  /* receives an opm visual element and return true if the elm is an OpmLink of the right type and its source id is sourceId*/
  private isARelevantOutGoingLinkToComputation(elm: OpmVisualElement, sourceId: string) {
    if (elm instanceof OpmLink) {
      const sourceLid = this.opmModel.getVisualElementById(sourceId)?.logicalElement?.lid;
      return !!sourceLid && (this.elmIsLinkAndLinkTypeIsRelevant(elm) && (elm.source.logicalElement.lid === sourceLid || elm.source?.fatherObject?.logicalElement.lid === sourceLid));
    } else {
      return false;
    }
  }
  /*returns true if the element is a link and its type is aggregation, exhibition or instantiation*/
  private elmIsLinkAndLinkTypeIsRelevant(elm: OpmVisualElement) {
    return (elm instanceof OpmLink && (elm.type === linkType.Aggregation || elm.type === linkType.Exhibition
      || elm.type === linkType.Instantiation));
  }
}
