import { OpmLink } from './OpmLink';
import { OpmVisualElement } from './OpmVisualElement';
import {linkType} from '../ConfigurationOptions';
import {OpmVisualEntity} from './OpmVisualEntity';
import {OpmVisualProcess} from './OpmVisualProcess';
import {OpmVisualState} from './OpmVisualState';

export class OpmProceduralLink extends OpmLink {

  private partner: OpmProceduralLink;

  path: string;
  Probability: number;
  rate: number;
  rateUnits: string;
  sourceMultiplicity: number;
  targetMultiplicity: number;
  timeMin: number;
  timeMax: number;
  timeMinVal: string;
  timeMaxVal: string;
  center: { x: number, y: number };
  selfInvocationPeakPoint: { x: number, y: number };
  previousLinksData: [ {sourceID: string, targetID: string, BreakPoints: Array<any>, params: any} ];

  constructor(params, logicalElement) {
    super(params, logicalElement);
  }

  updateParams(params) {
    super.updateParams(params);
    this.path = params.path;
    this.Probability = params.Probability;
    this.rate = params.rate;
    this.rateUnits = params.rateUnits;
    this.sourceMultiplicity = params.sourceMultiplicity;
    this.targetMultiplicity = params.targetMultiplicity;
    this.timeMin = params.timeMin;
    this.timeMax = params.timeMax;
    this.timeMinVal = params.timeMinVal;
    this.timeMaxVal = params.timeMaxVal;
    this.center = params.center;
    this.selfInvocationPeakPoint = params.selfInvocationPeakPoint;
  }

  setParams(params) {
    super.setParams(params);
    this.path = params.path;
    this.Probability = params.Probability;
    this.rate = params.rate;
    this.rateUnits = params.rateUnits;
    this.sourceMultiplicity = params.sourceMultiplicity;
    this.targetMultiplicity = params.targetMultiplicity;
    this.timeMin = params.timeMin;
    this.timeMax = params.timeMax;
    this.timeMinVal = params.timeMinVal;
    this.timeMaxVal = params.timeMaxVal;
    this.center = params.center;
    this.selfInvocationPeakPoint = params.selfInvocationPeakPoint;
  }

  getParams() {
    const params = {
      path: this.path,
      Probability: this.Probability,
      rate: this.rate,
      rateUnits: this.rateUnits,
      sourceMultiplicity: this.sourceMultiplicity,
      targetMultiplicity: this.targetMultiplicity,
      timeMin: this.timeMin,
      timeMax: this.timeMax,
      timeMinVal: this.timeMinVal,
      timeMaxVal: this.timeMaxVal,
      center: this.center,
      selfInvocationPeakPoint: this.selfInvocationPeakPoint
    };
    return { ...super.getLinkParams(), ...params };
  }

  getParamsFromJsonElement(jsonElement) {
    const params = {
      path: jsonElement.path,
      Probability: jsonElement.Probability,
      rate: jsonElement.rate,
      rateUnits: jsonElement.rateUnits,
      sourceMultiplicity: jsonElement.sourceMultiplicity,
      targetMultiplicity: jsonElement.targetMultiplicity,
      timeMin: jsonElement.timeMin,
      timeMax: jsonElement.timeMax,
      timeMinVal: jsonElement.timeMinVal,
      timeMaxVal: jsonElement.timeMaxVal,
      center: jsonElement.center,
      selfInvocationPeakPoin: jsonElement.selfInvocationPeakPoin,
    };
    return { ...super.getLinkParamsFromJsonElement(jsonElement), ...params };
  }

  isStructuralLink() {
    return false;
  }

  isProceduralLink() {
    return true;
  }


  public getPartner() {
    return this.partner;
  }

  clone() {
    const newLink = new OpmProceduralLink(this.getParams(), this.logicalElement);
    newLink.setNewUUID();
    return newLink;
  }

  public setAsPartner(link: OpmProceduralLink) {
    this.partner = link;
    link.partner = this;
  }

  public setLabels(labels): ReadonlyArray<OpmProceduralLink> {
    const links = this.logicalElement.visualElements as Array<OpmProceduralLink>;

    const ret = (new Array<OpmProceduralLink>()).concat(links);

    for (const link of links) {
      link.path = labels.Path;
      link.Probability = labels.Probability;
      link.targetMultiplicity = labels.targetMultiplicity;
      link.sourceMultiplicity = labels.sourceMultiplicity;
      link.rate = labels.rate;
      link.rateUnits = labels.rateUnits;
    }

    return ret;
  }

  public remove(): ReadonlyArray<OpmVisualElement> {
    const ret = ([]).concat(super.remove());
    const model = this.logicalElement.opmModel;

    const visSource = this.sourceVisualElement as OpmVisualEntity;
    const visTarget = this.targetVisualElements[0].targetVisualElement as OpmVisualEntity;
    const visualProcess = visSource instanceof OpmVisualProcess ? visSource : visTarget;
    let visualObject = visSource instanceof OpmVisualProcess ? visTarget : visSource;
    if (visualObject instanceof OpmVisualState)
      visualObject = visualObject.fatherObject;

    const isHiddingLinks = model.links.isHavingHiddenInOuts(visualProcess, visualObject).isHaving;

    if (this.type === linkType.Effect && isHiddingLinks) {
        model.links.switchEffectToInOuts(this);
    } else if (this.type === linkType.Result || this.type === linkType.Consumption) {
      const links = [...visualProcess.getLinksWith(visualObject).inGoing, ...visualProcess.getLinksWith(visualObject).outGoing];
      const effect = links.find( l => l.type === linkType.Effect && l.visible === false);
      const isHavingVisibleInOuts = model.links.isHavingVisibleInOuts(visualProcess, visualObject).isHaving;
      if (effect && !isHavingVisibleInOuts)
        effect.remove();
    }
    if (this.partner) {
      this.partner.partner = undefined;
      ret.push(...this.partner.remove());
    }

    return ret;
  }

}
