import { Injectable } from '@angular/core';

import { defaultSettings, edxDefaultSettings } from './opl-database';

export abstract class OplConfig {

  abstract user();
  abstract organization();

}

@Injectable()
export class OplEdxConfig implements OplConfig {

  user() {
    return edxDefaultSettings.user;
  }

  organization() {
    return edxDefaultSettings.organization;
  }

}

@Injectable()
export class OplDefaultConfig implements OplConfig {

  user() { // At first the user settings should be undefined,  <==Yang: Why??
    // than it updated from the settings of the user that are saved in the database,
    // if there are still undefined variables it should be taken from the org settings.
    let res = {};
    for (let key of Object.keys(defaultSettings.user)){
      res[key] = undefined;
    }
    return res;
  }

  organization() {
    return defaultSettings.organization;
  }

}


