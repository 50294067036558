import { NgModule } from '@angular/core';
import { MenuComponent } from './header/menu/menu.component';
import { CommonModule } from '@angular/common';
import { SharedModule } from '../shared/shared.module';
import { RappidToolbarComponent } from '../../rappid-components/rappid-toolbar/rappid-toolbar.component';
import { UserStatusComponent } from './header/user-status/user-status.component';
import { CollaborationMenuComponent } from '../../opd-hierarchy/coll-menu.component';
import { CollaborationDialogComponent } from '../../dialogs/collaboration-dialog/collaboration-dialog.component';
import { OpcModule } from '../opc.module';
import { OplConfig, OplDefaultConfig } from '../../opl-generation/opl.config';
import {MatLegacyProgressSpinnerModule as MatProgressSpinnerModule} from "@angular/material/legacy-progress-spinner";

@NgModule({
    imports: [
        SharedModule,
        CommonModule,
        OpcModule,
        MatProgressSpinnerModule,
    ],
    providers: [
        { provide: OplConfig, useClass: OplDefaultConfig },
    ],
    declarations: [
        CollaborationMenuComponent,
        MenuComponent,
        UserStatusComponent,
        RappidToolbarComponent,
        CollaborationDialogComponent,
    ],
    exports: [
        CollaborationMenuComponent,
        MenuComponent,
        UserStatusComponent,
        RappidToolbarComponent,
    ]
})
export class OpcLayoutModule { }
