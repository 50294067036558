import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../rappid-components/services/user.service';
import { validationAlert } from '../../../..//configuration/rappidEnviromentFunctionality/shared';
import { OrganizationService } from "../../../../rappid-components/services/organization.service";
import { Observable } from 'rxjs';
import { GroupsService } from '../../../../rappid-components/services/groups.service';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { AddUserComponent } from '../../../../dialogs/add-users-excel/add-users.component';

@Component({
  selector: 'new-user',
  templateUrl: 'new-user.component.html',
  styleUrls: ['./new-user.component.css']
})
export class SettingsNewUser implements OnInit {

  private defaultData = new Date();
  public isActive: boolean = true;
  public menuOpen: boolean = false;
  user = {
    name: '',
    email: '',
    password: '',
    repeatPassword: '',
    organization: '',
    exp_date: this.defaultData.getTime() + 5184000000,
    isPermanent: false,
    forceToChangeInitialPassword: true,
    viewer: false,
    executionUser: false,
    dsmUser: false,
    insightsUser: false,
    genAIUser: false,
  };
  orgSettings;
  authError: string = null;
  organizations$;

  constructor(
    private userService: UserService,
    private orgService: OrganizationService,
    private groupService: GroupsService,
    private _dialog: MatDialog) {
  }

  ngOnInit() {
    const that = this;
    this.userService.user$.take(1).subscribe(data => {
      const user = data.userData;
      if (user.SysAdmin) {
        this.organizations$ = this.orgService.getOrganizations();
      } else {
        this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
      }
      this.user.organization = this.userService.userOrg;
      that.orgService.getOrganization(this.user.organization).then(res => {
        that.orgSettings = res;
        that.user.dsmUser = res.defaultUserOptions.dsmUser;
        that.user.insightsUser = res.defaultUserOptions.insightsUser;
        that.user.viewer = res.defaultUserOptions.viewer;
        that.user.executionUser = res.defaultUserOptions.executionUser;
        that.user.genAIUser = res.defaultUserOptions.genAIUser || false;
      });
    });
  }

  compareOrgs(o1: any, o2: any): boolean {
    return o1 === o2;
  }

  addUser() {
    if (!this.validateInput()) { //needs to add Org validation
      return;
    }

    this.userService.registerUser(this.user)
      .then((res) => {
        if (res.success) {
          validationAlert('New user created', null, 'Success');
          this.user = {
            name: '',
            email: '',
            password: '',
            repeatPassword: '',
            organization: this.userService.userOrg,
            exp_date: this.defaultData.getTime() + 5184000000,
            isPermanent: false,
            forceToChangeInitialPassword: true,
            dsmUser: this.orgSettings.defaultUserOptions.dsmUser,
            insightsUser: this.orgSettings.defaultUserOptions.insightsUser,
            viewer: this.orgSettings.defaultUserOptions.viewer,
            executionUser: this.orgSettings.defaultUserOptions.executionUser,
            genAIUser: this.orgSettings.defaultUserOptions.genAIUser,
          };
          return;
        }

        this.authError = res.message;
      })
      .catch(err => {
        this.authError = err.message;
      });
  }
  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.isActive = true;
      return;
    } else if (!this.menuOpen) {
      this.menuOpen = true;
      this.isActive = false;
    }
  }

  addingExcelFile() {
    this.toggleMenu();
    this._dialog.open(AddUserComponent, {
      width: '600'
    });
  }

  validateInput(): boolean {
    this.authError = null;

    //Verify mandatory fields
    let mandatoryFields = ["Name", "Email", "password", "Organization"];
    for (let field of mandatoryFields) {
      if (this.user[field] === "") {
        const msg = 'The field ' + field + ' is empty';
        validationAlert(msg, 3500, 'Error');
        this.authError = "'" + field + "'" + " field can't be empty";
        return false;
      }
    }
    //Password Check
    if (!this.isPasswordMatch()) {
      const msg = ' The passwords do not match!'
      validationAlert(msg, 3500, 'Error');
      this.authError = "Password and Repeat-Password doesn't Match";
      return false;
    }
    return true;
  }

  isPasswordMatch(): boolean {
    return (this.user.password === this.user.repeatPassword);
  }


}
