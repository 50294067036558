import { HttpClient } from '@angular/common/http';

class ObjectToSend {
  what?;
  topic?;
  message?;
  data_type?;
  ROSScript?;
}

export interface HttpCommunicatorI {
  get(url: string): Promise<any>;
}

export interface WebSocketCommunicatorI {
  isActive: boolean;
  send(toSend: ObjectToSend): void;
  get(): Promise<any>;
}

export class WebSocketCommunicator implements WebSocketCommunicatorI {

  constructor(private readonly ws: WebSocket, public readonly isActive: boolean) {
  }

  async get() {
    const that = this;
    return new Promise(function (resolve, reject) {
      that.ws.onmessage = function (event) {
        resolve(event.data);
      };
      that.ws.onerror = function (err) {
        console.error("MQTT socket connection error : ", err);
        reject(err);
      };
    });
  }

  send(objToSend) {
    this.ws.send(JSON.stringify(objToSend));
  }

}

export class HttpCommunicator implements HttpCommunicatorI {

  constructor(private readonly http: HttpClient) {
  }

  async get(url: string) {
    return this.http.get(url).toPromise();
  }

}
