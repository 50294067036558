//Authentication with Microsoft user credentials

import { AuthenticationDriver } from "../rappid-components/services/authentication/authentication.driver";
import { MsalService } from '@azure/msal-angular';


import { Injectable } from "@angular/core";
import { ReplaySubject, Observable } from "rxjs";

//****SSO LOGIN HANDLING****//
@Injectable()
export class MsalAuthDriver implements AuthenticationDriver {

  private readonly token_ = new ReplaySubject<string>(1);
  private readonly token$ = this.token_.asObservable();

  public token(): Observable<string> {
    return this.token$;
  }

  public readonly availableProviders = []; // environment.firebaseAuthProviders;

  constructor(private service: MsalService) {
    this.service.handleRedirectObservable().subscribe(); // Subscribing to handleRedirectObservable before any other functions both initialize the application and ensures redirects are handled
  }

  //this is not being used in SSO authentication
  //since Msal package is handling the credentials hendling process.
  public async signInWithEmailAndPassword(email, password): Promise<any> {
    return false;
  }

  //uses the stored access token to have a quick login
  public autoSignIn(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const token = localStorage.getItem('msal-token');
      if (token) {
        this.token_.next(token);
        resolve(true);
        return;
      }

      resolve(false);
    });
  }

  public signOut(): Promise<boolean> {
    return this.service.logout().toPromise().then(() => { localStorage.removeItem('msal-token'); return true; });
  }

  public sendPasswordResetEmail(user_email: string): Promise<boolean> {
    return Promise.reject();
  }

  //signin with Microsoft login popup
  public signInWithPopup(provider) {
    return new Promise((resolve, reject) => {
      this.service.loginPopup()
        .subscribe((value) => {
          // this.token_.next(value.accessToken);
          localStorage.setItem('msal-token', value.accessToken); //stores the access token
          resolve(value);
        });
    });
    }

  public getToken(): Promise<string> {
    return this.token_.toPromise();
  }

  signInWithCustomToken(token: any) {
  }

  public refreshExpiredToken() {
  }

  signInWith2FactorAuthentication(error: any, recaptchaVerifier: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  add2FactorAuthenticationForUser(recaptchaVerifier: any, phoneNumber: string): Promise<any> {
    throw new Error('Method not implemented.');
  }

  finishAdd2FactorAuthForUser(verificationCode: string, verificationId: any): Promise<{success: boolean, message?: string}> {
    throw new Error('Method not implemented.');
  }

  removeSecondAuthFactorForUser(): Promise<void> {
    throw new Error('Method not implemented.');
  }
}
