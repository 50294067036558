// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divs {
  position: relative;
  width: 453px;
  left: 50px;
  top: 50px;
}

table{
  width: 453px;
  border: 1px solid rgba(73, 114, 132, 0.77);
  line-height: 30px;
  border-radius: 6px;
}

th{
//border-bottom: 1px solid rgba(73, 114, 132, 0.77);
  text-align: left;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 16px;

  color: #3B3B3B;
}

th:nth-child(1){
//border-right: 2px solid rgba(73, 114, 132, 0.77);
//line-height: 32px;
//border-top: none;
//border-left: none;

}
td:nth-child(1){
//border-right: 2px solid rgba(73, 114, 132, 0.77);
//line-height: 100%;
}


tr:nth-child(even) {
  background: #ffffff;
}
tr:nth-child(odd) {
  background: rgba(228, 232, 236, 0.43);
}


::ng-deep .mat-form-field-underline {
  display: none;
}

.header {
  color: #1A3763;
  text-align: center;
}

#whole {
  display: grid;
  justify-content: center;
  width: 100%;
  padding-bottom: 40px;
}

.Btn {
  color: #1A3763;
  opacity: 60%;
  font-weight: 500;
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 4px;
  height: 36px;
  margin-left: 133px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/Settings/model-analysis/model-analysis.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;EACE,YAAY;EACZ,0CAA0C;EAC1C,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;AACA,mDAAmD;EACjD,gBAAgB;EAChB,iDAAiD;EACjD,kBAAkB;EAClB,mBAAmB;EACnB,mBAAmB;EACnB,eAAe;;EAEf,cAAc;AAChB;;AAEA;AACA,kDAAkD;AAClD,mBAAmB;AACnB,kBAAkB;AAClB,mBAAmB;;AAEnB;AACA;AACA,kDAAkD;AAClD,mBAAmB;AACnB;;;AAGA;EACE,mBAAmB;AACrB;AACA;EACE,qCAAqC;AACvC;;;AAGA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;EACZ,kBAAkB;AACpB","sourcesContent":[".divs {\n  position: relative;\n  width: 453px;\n  left: 50px;\n  top: 50px;\n}\n\ntable{\n  width: 453px;\n  border: 1px solid rgba(73, 114, 132, 0.77);\n  line-height: 30px;\n  border-radius: 6px;\n}\n\nth{\n//border-bottom: 1px solid rgba(73, 114, 132, 0.77);\n  text-align: left;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  line-height: normal;\n  font-size: 16px;\n\n  color: #3B3B3B;\n}\n\nth:nth-child(1){\n//border-right: 2px solid rgba(73, 114, 132, 0.77);\n//line-height: 32px;\n//border-top: none;\n//border-left: none;\n\n}\ntd:nth-child(1){\n//border-right: 2px solid rgba(73, 114, 132, 0.77);\n//line-height: 100%;\n}\n\n\ntr:nth-child(even) {\n  background: #ffffff;\n}\ntr:nth-child(odd) {\n  background: rgba(228, 232, 236, 0.43);\n}\n\n\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n\n.header {\n  color: #1A3763;\n  text-align: center;\n}\n\n#whole {\n  display: grid;\n  justify-content: center;\n  width: 100%;\n  padding-bottom: 40px;\n}\n\n.Btn {\n  color: #1A3763;\n  opacity: 60%;\n  font-weight: 500;\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 4px;\n  height: 36px;\n  margin-left: 133px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
