// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
  font-family: Roboto, Arial, Helvetica, sans-serif;
  text-align: center;
  align-items: center;
  font-size: medium;
  color: #1A3763;
}

ul {
  list-style-type: none;
  float: left;
  padding: 5px;
}

#load_file_button {
  margin-top: 5px;
  background-color: #1A3763;
}

#file_import_label {
  margin-left: 10px;
}

#import {
  right: 15px;
}

.import_file_details {
  width: 420px;
  color: #1A3763;
  font-weight: bold;
}

div {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  border: 2px solid #FFFFFF;
  width: 100%;
  text-align: left;
  border-collapse: collapse;
  color: #1A3763;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}

.button {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: normal;
  line-height: normal;
  font-size: 14px;
  color: #1A3763;
  margin: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/FileUploader/FileUploader.scss"],"names":[],"mappings":"AACA;EACE,iDAAA;EACA,kBAAA;EACA,mBAAA;EACA,iBAAA;EACA,cAAA;AAAF;;AAGA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;AAAF;;AAGA;EACE,eAAA;EACA,yBAAA;AAAF;;AAGA;EACE,iBAAA;AAAF;;AAIA;EACE,WAAA;AADF;;AAIA;EACE,YAAA;EACA,cAAA;EACA,iBAAA;AADF;;AAGA;EACE,iDAAA;EACA,yBAAA;EACA,WAAA;EACA,gBAAA;EACA,yBAAA;EACA,cAAA;AAAF;;AAGA;EACE,aAAA;AAAF;;AAGA;EAEE,iDAAA;EACA,kBAAA;EACA,mBAAA;EACA,mBAAA;EACA,eAAA;EACA,cAAA;EACA,YAAA;AADF","sourcesContent":["\np{\n  font-family: Roboto, Arial, Helvetica, sans-serif;\n  text-align: center;\n  align-items: center;\n  font-size: medium;\n  color: #1A3763;\n}\n\nul{\n  list-style-type: none ;\n  float: left;\n  padding: 5px;\n\n}\n#load_file_button {\n  margin-top: 5px;\n  background-color: #1A3763;\n}\n\n#file_import_label {\n  margin-left: 10px;\n\n}\n\n#import {\n  right: 15px;\n}\n\n.import_file_details {\n  width: 420px;\n  color: #1A3763;\n  font-weight: bold;\n}\ndiv {\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  border: 2px solid #FFFFFF;\n  width: 100%;\n  text-align: left;\n  border-collapse: collapse;\n  color: #1A3763;\n}\n\n::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {\n  display: none;\n}\n\n.button /*general button class*/\n{\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: normal;\n  line-height: normal;\n  font-size: 14px;\n  color: #1A3763;\n  margin: 15px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
