import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserService } from '../../rappid-components/services/user.service';
import { DisplayStereotype, StereotypeType, StereotypeStorageMode } from './StereotypesRelatedInterface';
import { OpmLogicalThing } from '../../models/LogicalPart/OpmLogicalThing';
import { OpmVisualThing } from '../../models/VisualPart/OpmVisualThing';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';
import { StorageService } from '../../rappid-components/services/storage.service';
import { ContextService } from '../../modules/app/context.service';

@Component({
  selector: 'stereotypes-dialog',
  templateUrl: './stereotypes-dialog.component.html',
  styleUrls: ['./stereotypes-dialog.component.css']
})
export class StereotypesDialogComponent implements OnInit {

  public readonly Mode = StereotypeStorageMode;
  public readonly StereotypeType = StereotypeType;

  public readonly mode: StereotypeStorageMode = StereotypeStorageMode.SAVE;

  stereotypesToShow: Array<DisplayStereotype>;
  allStereotypes: Array<DisplayStereotype>;

  selected: DisplayStereotype;
  name: string = '';
  archiveMode = 'false';
  searchInput: string = '';

  spinnerFlag = true;
  logicalElements: Array<OpmLogicalThing<OpmVisualThing>>;
  public shouldShowAreYouSureDiv = false;
  private favorites = [];

  constructor(
    @Optional() public dialogRef: MatDialogRef<StereotypesDialogComponent>,
    private storage: StorageService,
    private context: ContextService,
    private userService: UserService,
    private initRappid: InitRappidService,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.mode = data.mode;
  }

  ngOnInit() {
    this.spinnerFlag = true;
    const this_ = this;
    this.storage.getAllStereotypes()
      .then(allStereotypes => this.stereotypesToShow = this.allStereotypes = allStereotypes)
      .then(() => this.storage.getFavoriteStereotypes())
      .then(favorites => favorites.forEach(f => {
        if (this_.allStereotypes.find(s => s.id === f))
          this.favorites.push(this.allStereotypes.find(s => s.id == f))
        }
      ))
      .then(() => {
        this.spinnerFlag = false;
        this.selectMainThingIfAlreadySet();
      });

    this.logicalElements = this.initRappid.getOpmModel().logicalElements.filter(
      log => (log.constructor.name.includes('Object') || log.constructor.name.includes('Process')) && !(<any>log).isComputational() &&
        !(<any>log).getBelongsToStereotyped() && !this.initRappid.getOpmModel().getStereotypeByLogicalElement(<any>log)).sort((a, b) => {
          return (<any>a).getBareName() > (<any>b).getBareName() ? 1 : -1;
        }) as Array<OpmLogicalThing<OpmVisualThing>>;
    $('.wrapDialog')[0].onclick = ($event) => {
      if ($event.target === $('.wrapDialog')[0])
        this.selected = undefined;
    };
  }

  selectMainThingIfAlreadySet() {
    const mainThing = this.initRappid.opmModel.logicalElements.find(log => (<any>log).isMainThing) as any;
    if (mainThing) {
      const idx = this.logicalElements.indexOf(mainThing);
      if (idx !== -1 && $('#mainThingSelect')[0]) {
        (<any>$('#mainThingSelect')[0]).selectedIndex = idx;
        return mainThing.getBareName();
      }
      return null;
    }
    return null;
  }

  private isValidateStereotypeName(): boolean {
    const name = this.name;
    if (name === '')
      return false;
    return RegExp('^[a-zA-Z0-9_() ]+$').test(name);
  }

  clearSelected() {
    this.selected = undefined;
  }

  search() {
    this.stereotypesToShow = this.allStereotypes.filter(s => s.name.includes(this.searchInput));
    this.clearSelected();
  }

  select(stereotype: DisplayStereotype) {
    this.selected = stereotype;
    this.name = stereotype.name;
  }

  prepareString(name) {
    return (name.length > 9) ? (name.slice(0, 9)) + '...' : (name);
  }

  doubleClickAction() {
    if (this.isAllowedToSet())
      return this.setAction();
    else if (this.isAllowedToLoad())
      return this.loadAction();
  }

  getSaveText(): string {
    return 'Save As Organization Stereotype';
  }

  shouldDisplaySave(): boolean {
    return (this.mode === StereotypeStorageMode.SAVE && (this.userService.isOrgAdmin() || this.userService.isSysAdmin()));
  }

  isAllowedToSave(): boolean {
    return (this.isValidateStereotypeName());
  }

  isFavorited(item: DisplayStereotype) {
    return this.favorites.includes(item);
  }

  saveAction() {
    if ((<any>$('#mainThingSelect')[0]).selectedIndex === -1) {
      (<any>$('#requiredField')[0]).style.color = 'red';
      return;
    }
    if (!this.isValidStereotype())
      return;
    const type = StereotypeType.Organization;
    const existing = this.allStereotypes.find(s => s.name == this.name && s.type == type);
    (<any>this.logicalElements[(<any>$('#mainThingSelect')[0]).selectedIndex]).isMainThing = true; // TODO: Make part of the model
    this.context.saveStereotype(existing ? existing : { name: this.name, type: type, id: undefined, description: '' }, !!existing)
      .then(res => {
        this.initRappid.modelService.setName(this.name);
        validationAlert(`Successfully saved stereotype [${this.name}].`, 2500, 'Success');
        this.dialogRef.close();
      }).catch(res => {
        validationAlert(`Failed to save stereotype [${this.name}].`, 2500, 'error');
      });
  }

  getSaveAsGenericText(): string {
    return 'Save As Global Stereotype';
  }

  shouldDisplayGenericSave() {
    return (this.mode === StereotypeStorageMode.SAVE && this.userService.isSysAdmin());
  }

  isAllowedToSaveAsGeneric() {
    return (this.isValidateStereotypeName());
  }

  saveAsGenericAction() {
    if ((<any>$('#mainThingSelect')[0]).selectedIndex === -1) {
      (<any>$('#requiredField')[0]).style.color = 'red';
      return;
    }
    if (!this.isValidStereotype())
      return;
    const type = StereotypeType.System;
    (<any>this.logicalElements[(<any>$('#mainThingSelect')[0]).selectedIndex]).isMainThing = true; // TODO: Make part of the model
    const existing = this.allStereotypes.find(s => s.name == this.name && s.type == type);
    this.context.saveStereotype(existing ? existing : { name: this.name, type: type, id: undefined, description: '' }, !!existing)
      .then(res => {
        this.initRappid.modelService.setName(this.name);
        this.dialogRef.close();
        validationAlert(`Successfully saved stereotype [${this.name}].`, 2500, 'Success');
      }).catch(err => validationAlert(`Failed to save stereotype [${this.name}].`, 2500, 'Error'));
  }

  geLoadText(): string {
    return 'Load Stereotype';
  }

  isAllowedToLoad() {
    return (this.mode === StereotypeStorageMode.LOAD);
  }

  loadAction() {
    this.dialogRef.close();
    this.context.loadStereotype(this.selected)
      .then(() => validationAlert(`Successfully loaded stereotype [${this.selected.name}].`, 2500, 'Success'))
      .catch(err => validationAlert(`Failed to load stereotype [${this.selected.name}].`, 2500, 'Error'));;
  }

  geSetText(): string {
    return 'Set Stereotype';
  }

  isAllowedToSet() {
    return (this.mode === StereotypeStorageMode.SET);
  }

  isValidStereotype(): boolean {
    if (this.initRappid.getOpmModel().opds.filter(o => !o.isHidden).length > 1) {
      validationAlert('Currently, OPCloud supports only stereotypes with one level diagram.', 5000, 'Error');
      return false;
    }
    return true;
  }

  setAction() {
    this.spinnerFlag = true;
    this.storage.setStereotype(this.selected).then(stereotype => {
      this.dialogRef.close(stereotype);
    });
  }

  getModelTooltip(model: DisplayStereotype): string {
    return 'Stereotype: ' + model.name + '\nDescription: ' + model.description;
  }

  isAllowedToDelete(): boolean {
    return ((this.mode === this.Mode.SAVE || this.mode === this.Mode.LOAD) && this.userService.user.userData.SysAdmin || (this.userService.user.userData.OrgAdmin && this.selected && this.selected.type === StereotypeType.Organization));
  }

  shouldDisplayDelete(): boolean {
    return (this.mode === this.Mode.SAVE || this.mode === this.Mode.LOAD);
  }

  deleteAction() {
    // TODO: check again if allowed to delete this stereotype
    this.showAreYouSureToDeleteDiv();
  }

  showAreYouSureToDeleteDiv() {
    this.shouldShowAreYouSureDiv = true;
  }

  verifiedDelete() {
    this.storage.deleteStereotype(this.selected.id).
      then(() => {
        this.allStereotypes = this.allStereotypes.filter(s => s.id != this.selected.id);
        this.shouldShowAreYouSureDiv = false;
        this.name = '';
        this.search();
      });
  }

  addToFavorites(stereotype: DisplayStereotype, $event) {
    this.storage.setFavoriteStereotype(stereotype.id)
      .then(() => {
        if (!this.favorites.includes(stereotype))
          this.favorites.push(stereotype);
        this.clearSelected();
      });

    $event.stopPropagation();
  }

  removeFromFavorites(stereotype: DisplayStereotype, $event) {
    this.storage.unsetFavoriteStereotype(stereotype.id)
      .then(() => {
        if (this.favorites.includes(stereotype))
          this.favorites.splice(this.favorites.indexOf(stereotype), 1);
        this.clearSelected();
      });

    $event.stopPropagation();
  }
}
