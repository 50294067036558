import { Component, OnInit, AfterViewInit, ViewContainerRef, ViewChild, Input } from '@angular/core';

@Component({
  selector: 'opcloud-rappid-paper',
  template: `
    <div style="position: relative; touch-action: none;" class="paper-container" id="paper-container" #paperContainer></div>
  `,
  styleUrls: ['./rappid-paper.component.scss']
})
export class RappidPaperComponent implements AfterViewInit{
  @Input() paper;
  @ViewChild('paperContainer', { read: ViewContainerRef, static: false }) paperContainer;
  @Input() paperScroller;

  constructor() {
  }

  ngAfterViewInit() {
    // this.initPaperScroller();
    this.paperContainer.element.nativeElement.appendChild(this.paperScroller.el);
  }



  initPaperScroller() {
    /*this.paperScroller = new joint.ui.PaperScroller({
      paper: this.paper,
      autoResizePaper: true,
      padding: 50
    });*/
  }

}
