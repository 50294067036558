import { OpmEntity } from './OpmEntity';
import { Affiliation, Essence, linkType, valueType } from '../ConfigurationOptions';
import {
  _,
  getInitRappidShared,
  highlighSD,
  initRappidShared,
  joint, OPCloudUtils,
  paddingObject,
  validationAlert
} from '../../configuration/rappidEnviromentFunctionality/shared';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { OpmVisualObject } from '../VisualPart/OpmVisualObject';
import { OpmVisualThing } from '../VisualPart/OpmVisualThing';
import { oplDefaultSettings } from '../../opl-generation/opl-database';
import { Arc } from './Links/OrXorArcs';
import { OpmLogicalThing } from '../LogicalPart/OpmLogicalThing';
import { EntityType } from '../model/entities.enum';
import {OpmModel} from "../OpmModel";
import {BackgroundImageState} from "../VisualPart/backgroundImageEnum";
import {BringConnectedTypes} from "../Actions/BringConnectedOptionsInterface";

export class OpmThing extends OpmEntity {

  dashArrayValues = ['0', '10,5'];
  dashArrayKey = 'stroke-dasharray';
  essenceValues = [{ dx: this.getAxisEssence(), dy: this.getAxisEssence() * 2 }, { dx: 0, dy: 0 }];
  argsAtt = '/filter/args';
  public isActive = true;
  public menuOpen = false;

  constructor() {
    super();
    this.set(this.thingAttributes());
    this.attr({ text: { 'font-weight': 600 } });
    this.attr({ value: { 'value': 'None', 'valueType': valueType.None, 'units': '' } });
    this.setEssence(oplDefaultSettings.essence);
  }

  numberThing(val?) {
    const counter = (val) ? val : this.getCounter()
    let thisText = this.attributes.attrs.text.textWrap.text;
    thisText = this.getNumberedText(thisText, counter);
    this.attr({ text: { textWrap: { text: thisText } } });
  }


  thingShape() {
    return {
      filter: { name: 'dropShadow', args: { ...this.essenceValues[oplDefaultSettings.essence], ...{ blur: 0, color: 'grey' } } },
      [this.dashArrayKey]: this.dashArrayValues[oplDefaultSettings.affiliation],
      width: 10,
      height: 10,
    };
  }

  thingAttributes() {
    return {
      minSize: { width: 135, height: 60 },
      statesWidthPadding: 0,
      statesHeightPadding: 0,
      //essense: 1,
    };
  }

  CanBeComputational() {
    return (<OpmVisualThing>this.getVisual()).CanBeComputational();
  }

  getThingParams() {
    const essence = this.attr(this.getShape() + this.argsAtt);
    const params = {
      essence:
        (essence.dx === this.essenceValues[1].dx && essence.dy === this.essenceValues[1].dy) ? Essence.Informatical : Essence.Physical,
      affiliation: (this.getShapeAttr()[this.dashArrayKey] === this.dashArrayValues[0]) ? Affiliation.Systemic : Affiliation.Environmental,
    };
    return { ...super.getEntityParams(), ...params };
  }


  getAffiliation() {
    return (this.getShapeAttr()[this.dashArrayKey] === this.dashArrayValues[0]) ? Affiliation.Systemic : Affiliation.Environmental;
  }

  getAxisEssence(): number {
    return 3;
  }

  updateThingFromOpmModel(visualElement) {
    const essenceArgs = { ...this.essenceValues[visualElement.logicalElement.essence], ...{ blur: 0, color: 'grey' } };
    // const essenceArgs = visualElement.logicalElement.essence ? { dx: 0, dy: 0, blur: 0, color: 'grey' } : {
    //   dx: 3,
    //   dy: 5,
    //   blur: 0,
    //   color: 'grey'
    // };
    //  const affiliation = visualElement.logicalElement.affiliation ? '10,5' : '0';
    return {
      filter: { name: 'dropShadow', args: essenceArgs },
      // 'stroke-dasharray': affiliation,
      [this.dashArrayKey]: this.dashArrayValues[visualElement.logicalElement.affiliation],
    };
  }

  // Function gets cell and update the default configuration in the all fields that embeded cells arrangement used.
  arrangeEmbededParams(refX, refY, alignX, alignY, arrangeState, stateWidthPadding, statesHeightPadding) {
    this.attr({ text: { 'ref-x': refX } });
    this.attr({ text: { 'ref-y': refY } });
    this.attr({ text: { 'x-alignment': alignX } });
    this.attr({ text: { 'y-alignment': alignY } });
    this.attr({ 'statesArrange': arrangeState });
    this.set('statesWidthPadding', stateWidthPadding);
    this.set('statesHeightPadding', statesHeightPadding);

  }
  updateSizePositionToFitEmbeded(includeSemiFolded = false, calledByChild = false) {
    const leftSideX = this.getBBox().origin().x;
    const topSideY = this.getBBox().origin().y;
    const rightSideX = this.getBBox().corner().x;
    const bottomSideY = this.getBBox().corner().y;
    const newDimensions = this.getNewDimensions(leftSideX, topSideY, rightSideX, bottomSideY, includeSemiFolded);
    if (!calledByChild)
      this.keepChildrenPositionByPosition(getInitRappidShared());
    // a block of code that shouldn't be followed by any event
    this.graph.startBatch('ignoreEvents');
    if (calledByChild) {
      this.set({ position: { x: newDimensions.leftSideX, y: newDimensions.topSideY }});
      this.set({
        size: { width: newDimensions.rightSideX - newDimensions.leftSideX, height: newDimensions.bottomSideY - newDimensions.topSideY }
      });
    }
    if (this.get('duplicationMark')) {
      this.redrawDuplicationMark(getInitRappidShared());
    }
    this.graph.stopBatch('ignoreEvents');
  }

  // function called from changeSize event and checks only case of changing
  // size from right or bottom sides. when changing size from left or top
  // the position is change as well and therefore these cases will be handled in
  // changePosition event
  updateSizeToFitEmbeded() {
    const rightSideX = this.getBBox().corner().x;
    const bottomSideY = this.getBBox().corner().y;
    const leftSideX = this.getBBox().origin().x;
    const topSideY = this.getBBox().origin().y;
    let restoreSize = false;
    let restorePosition = false;
    _.each(this.getEmbeddedCells(), function (child) {
      const childBbox = child.getBBox();
      // Updating the new size of the thing to have margins
      // of at least paddingObject so that the embedded entity will not touch the thing
      if (childBbox.corner().x > rightSideX - paddingObject + 1) {
        restoreSize = true;
      }
      if (childBbox.corner().y > bottomSideY - paddingObject + 1) {
        restoreSize = true;
      }
      if (childBbox.origin().x < leftSideX + paddingObject - 1) {
        restorePosition = true;
      }
      if (childBbox.corner().y < topSideY + paddingObject - 1) {
        restorePosition = true;
      }

    });
    this.graph.startBatch('ignoreEvents');
    if (restoreSize) {
      this.set({ size: this.previousAttributes().size });
    }
    if (restoreSize || !restorePosition) this.graph.stopBatch('changeSize');
    this.graph.stopBatch('ignoreEvents');
  }
  // Function updatePositionToFitEmbeded Update the position and size
  // of the object so that no embedded cell will not exceed the father border
  // from left and top with padding of 10p.
  updatePositionToFitEmbeded() {
    let leftSideX = this.getBBox().origin().x;
    let topSideY = this.getBBox().origin().y;
    let restorePosition = false;
    _.each(this.getEmbeddedCells(), function (child) {
      const childBbox = child.getBBox();
      if (childBbox.x < (leftSideX + paddingObject - 1)) {
        restorePosition = true;
      }
      if (childBbox.y < (topSideY + paddingObject - 1)) {
        restorePosition = true;
      }
    });
    if (restorePosition) {
      this.graph.startBatch('ignoreEvents');
      const position = this.previousAttributes().position;
      this.set({
        position: position,
      });
      this.graph.stopBatch('ignoreEvents');
    }
  }
  addHandle(options, greyEntity) {
    super.addHandle(options, greyEntity);
  }

  pointerDownHandle(cellView, options) {
    super.pointerDownHandle(cellView, options);
    // Gal : If the thing has embedded cells in it with arcs we need to make them transparent when the thing moves
    const embedded = this.getEmbeddedCells()
    for (let i = 0; i < embedded.length; i++) {
      Arc.makeThingArcsTransparent(embedded[i], options, true);
      if (embedded[i].removeDuplicationMark)
        embedded[i].removeDuplicationMark();
    }
    const duplicationMarkCell = options.graph.getCell(this.id);
    if (duplicationMarkCell)
      duplicationMarkCell.removeDuplicationMark();
  }
  pointerUpHandle(cellView, options) {
    super.pointerUpHandle(cellView, options);

    const elementsUnder = options.graph.findModelsUnderElement(this, {searchBy: 'center'}).filter(dr => OPCloudUtils.isInstanceOfDrawnThing(dr)).map(dr => dr.getVisual());
    if (this.lastPosition && !this.getParent() && elementsUnder.find(vis => vis.getRefineeInzoom()?.id === vis.id)) {
      this.set('position', this.lastPosition);
      const elementsUnderPrevPos = options.graph.findModelsUnderElement(this, {searchBy: 'center'})
        .filter(dr => OPCloudUtils.isInstanceOfDrawnThing(dr)).map(dr => dr.getVisual());
      const intersectThingCell = options.graph.getCell(elementsUnderPrevPos.find(vis => vis.getRefineeInzoom()?.id === vis.id));
      if (intersectThingCell) {
        this.set('position', {x: this.lastPosition.x, y: intersectThingCell.get('position').y - 100});
      }
      validationAlert('An external thing should not overlap an in-zoomed thing. To insert a thing into an' +
        ' in-zoomed thing (to contain it), drag the thing from the Draggable OPM Things module.', 5000, 'warning');
    }

    // Gal : If the thing has embedded cells with arcs we need to redraw them in the new position
    const embedded = this.getEmbeddedCells();
    for (let i = 0; i < embedded.length; i++) {
      Arc.redrawAllArcs(embedded[i], options, true);
      if (embedded[i].attributes.type !== "opm.State")
        this.addDuplicationMarkToThisElement(embedded[i], options);
    }
    this.addDuplicationMarkToThisElement(this, options);
  }
  addDuplicationMarkToThisElement(thing, options) {
    // const myOPD = options.opmModel.getOpdByThingId(thing.id);
    const myOPD = options.opmModel.currentOpd;
    let duplicates, dupls;
    const dpls = new Array();
    try {
      duplicates = <OpmVisualObject>options.opmModel.currentOpd.visualElements.find(v => v.id === thing.id);
      dupls = duplicates.logicalElement.visualElements.filter(v => options.opmModel.getOpdByThingId(v.id) === myOPD && (<OpmVisualThing>v).isFoldedUnderThing().isFolded === false);
      dupls.forEach((value) => {
        if (!dpls.some(x => (x.id === value.id))) {
          dpls.push(value);
        }
      });
    } catch (e) { return; }
    if (dpls.length > 1) {
      const duplicationMarkCell = options.graph.getCell(thing.id);
      if (duplicationMarkCell)
        duplicationMarkCell.removeDuplicationMark();
      const visualElement = options.opmModel.getVisualElementById(thing.id);
      if (visualElement)
        this.addDuplicationMark(options, visualElement);
    }
  }
  drawDuplicationMarkToAllDuplicatesInSameOPD(rappid: InitRappidService) {
    const this_ = this;
    // const myOPD = rappid.opmModel.getOpdByThingId(this.id);
    const myOPD = rappid.opmModel.currentOpd;
    const duplicates = <OpmVisualObject>rappid.opmModel.currentOpd.visualElements.find(v => v.id === this_.id);
    if (!duplicates) return;
    const dupls = duplicates.logicalElement.visualElements
      .filter(v => rappid.opmModel.getOpdByThingId(v.id) === myOPD && (<OpmVisualThing>v).isFoldedUnderThing().isFolded === false);
    // array without the same things twice
    const dpls = new Array();
    dupls.forEach((value) => {
      if (!dpls.some(x => (x.id === value.id))) {
        dpls.push(value);
      }
    });
    if (dpls.length > 1) {
      for (let i = 0; i < dpls.length; i++) {
        const currentElement = dpls[i];
        this.addDuplicationMarkToThisElement(currentElement, rappid);
      }
    }
  }
  addDuplicationMark(init, duplications, direction = null) { }
  removeDuplicationMarkWhenNoDuplicats(rappid) {
    const all = <any>rappid.opmModel.currentOpd.visualElements;
    const myOpd = rappid.opmModel.currentOpd;
    for (let i = 0; i < all.length; i++) {
      if (!all[i].id) continue;
      const cellView = rappid.graph.getCell(all[i].id);
      if (!cellView) continue;
      const duplicationMark = cellView.get('duplicationMark');
      if (!duplicationMark) continue;
      const dupls = all[i].logicalElement.visualElements
        .filter(v => rappid.opmModel.getOpdByThingId(v.id) === myOpd);
      const dpls = new Array();
      dupls.forEach((value) => {
        if (!dpls.some(x => (x.id === value.id))) {
          dpls.push(value);
        }
      });
      if (dpls.length <= 1) {
        cellView.removeDuplicationMark();
      }
    }
  }
  updateDuplicationMarkBorderColor(borderColor) {
    const duplicationMark = this.get('duplicationMark');
    if (duplicationMark)
      duplicationMark.attr('stroke', borderColor);
  }
  updateDuplicationMarkFillColor(fillColor) {
    const duplicationMark = this.get('duplicationMark');
    if (duplicationMark)
      duplicationMark.attr('fill', fillColor);
  }

  toggleEssence(thing: OpmVisualThing) {
    const model = thing.logicalElement.opmModel;
    const newEssence = this.getEssence() === Essence.Physical ? Essence.Informatical : Essence.Physical;
    const isLeagal = (<any>this.getVisual()).isLegalEssence(newEssence);
    if (isLeagal.isLegal && newEssence === Essence.Informatical) {
      const links = thing.getAllLinks().outGoing.filter(l => l.type === linkType.Aggregation);
      if (links.find(lnk => [EntityType.Process, EntityType.Object].includes(lnk.target.type) && (<any>lnk.target).getEssence() === Essence.Physical)) {
        let txt = 'An informatical thing cannot consist of physical things.<br>';
        txt += 'Please change ' + this.attributes.attrs.text.textWrap.text + ' physical parts to informatical at: ';
        const aggLinks = links.filter(lnk => [EntityType.Process, EntityType.Object].includes(lnk.target.type) && (<any>lnk.target).getEssence() === Essence.Physical);
        for (const lk of aggLinks)
          txt += '<br>' + (<any>lk.target).logicalElement.getBareName() + ' at ' + model.getOpdByThingId(lk.target.id).getNumberedName();
        validationAlert(txt, null, 'Error');
        return;
      }
    }
    const ret = thing.toggleEssence();
    if (ret.changed) {
      this.updateSiblingsEssenceAndAffiliation(thing);
    } else {
      if (ret.reason) {
        validationAlert(ret.reason, 3500, 'Error');
        return;
      }
      if ((<any>thing.logicalElement).getBelongsToStereotyped() && !thing.isComputational())
        validationAlert('A thing which originally came from a stereotype as non-computational cannot be changed to computational.', null, 'Error');
      else if (ret.value === Essence.Informatical) {
        const text = (<any>this.getVisual().logicalElement).getBareName() + " can't be physical.";
        validationAlert(text, null, 'Error');
      }
      else if (ret.value === Essence.Physical) {
        const text = (<any>this.getVisual().logicalElement).getBareName() + " can't be informatical.";
        validationAlert(text, null, 'Error');
      }
    }
  }

  private updateSiblingsEssenceAndAffiliation(visual: OpmVisualThing): void {
    for (const v of visual.logicalElement.opmModel.currentOpd.visualElements) {
      if (v.logicalElement === visual.logicalElement) {
        const cell = this.graph.getCell(v.id);
        cell.setEssence(visual.getEssence());
        cell.setAffiliation(visual.getAffiliation());
      }
    }
  }

  setEssence(essence: Essence) { // for internal use only! - So why not set private?
    this.attr(this.getShape() + this.argsAtt, this.essenceValues[essence]);
  }

  toggleAffiliation(thing: OpmVisualThing) {
    const ret = thing.toggleAffiliation();
    if (ret.changed) {
      this.updateSiblings(thing, getInitRappidShared());
    } else {
      if (ret.reason) {
        validationAlert(ret.reason, 3500, 'Error');
        return;
      }
      if (ret.value === Affiliation.Environmental) {
        const text = this.attributes.attrs.text.textWrap.text + " can't be systemic.";
        validationAlert(text, null, 'Error');
      }
      if (ret.value === Affiliation.Systemic) {
        const text = this.attributes.attrs.text.textWrap.text + " can't be environmental.";
        validationAlert(text, null, 'Error');
      }
    }
  }

  setAffiliation(affiliation: Affiliation) {
    this.attr(this.getShape(), { [this.dashArrayKey]: this.dashArrayValues[affiliation] });
  }

  haloConfiguration(halo, options) {
    super.haloConfiguration(halo, options);
    this.initializeHaloForComplexityManagement(halo, options);
  }

  display(msg: string): void {

  }

  getCameraSvgTooltip(): string {
    const visual = this.getVisual() as OpmVisualThing;
    if (visual.semiFolded.length)
      return 'The image is unabled in semi-fold view';

    if (visual.getRefineeInzoom() === visual)
      return 'The image view is unabled in inzoomed thing';

    return 'Toggle Image/Text, Right Click to Edit';
  }

  getCameraSvgIcon(xPos, yPos, shouldShowBackground: boolean) {
    if (shouldShowBackground)
      return `<svg class="cameraSign" x=${xPos} y=${yPos} width="18" height="14" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M21.5 3.5H28C29.1046 3.5 30 4.39543 30 5.5V19.5C30 21.1569 28.6569 22.5 27 22.5H3C1.34315 22.5 0 21.1569 0 19.5V6.5C0 4.84315 1.34315 3.5 3 3.5H8V2C8 0.895431 8.89543 0 10 0H19.5C20.6046 0 21.5 0.895431 21.5 2V3.5Z" fill="#5A6F8F"/>
          <circle cx="15" cy="13" r="7" fill="#F8F8F8"/>
          <circle cx="15" cy="13" r="4" fill="#5A6F8F"/>
          <path d="M22 7V5H25H28V7H22Z" fill="#F8F8F8"/>
          <title>${this.getCameraSvgTooltip()}</title>
      </svg>`;
    return `<svg class="cameraSign" x=${xPos} y=${yPos} width="18" height="14" viewBox="0 0 30 23" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path opacity="0.5" d="M21.5 3.5H28C29.1046 3.5 30 4.39543 30 5.5V19.5C30 21.1569 28.6569 22.5 27 22.5H3C1.34315 22.5 0 21.1569 0 19.5V6.5C0 4.84315 1.34315 3.5 3 3.5H8V2C8 0.895431 8.89543 0 10 0H19.5C20.6046 0 21.5 0.895431 21.5 2V3.5Z" fill="#5A6F8F"/>
        <path opacity="0.5" d="M22 7V5H25H28V7H22Z" fill="#F8F8F8"/>
        <circle opacity="0.5" cx="15" cy="13" r="7" fill="#F8F8F8"/>
        <circle opacity="0.5" cx="15" cy="13" r="4" fill="#5A6F8F"/>
        <title>${this.getCameraSvgTooltip()}</title>
      </svg>`;
  }

  setBackgroundImage(url: string) {
    const visual = this.getVisual() as OpmVisualThing;
    const logical = this.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    visual.showBackgroundImage = BackgroundImageState.TEXTANDIMAGE;
    logical.setBackgroundImage(url);
    this.attr('image/xlinkHref', url);
    this.updateURLArray();
  }

  toggleBackgroundPhoto() {
    const visual = this.getVisual() as OpmVisualThing;
    if (visual.showBackgroundImage === undefined)
      visual.showBackgroundImage = BackgroundImageState.TEXTONLY;

    if (visual.showBackgroundImage === BackgroundImageState.IMAGEONLY) {
      visual.showBackgroundImage = BackgroundImageState.TEXTANDIMAGE;
    } else if (visual.showBackgroundImage === BackgroundImageState.TEXTANDIMAGE) {
      visual.showBackgroundImage = BackgroundImageState.TEXTANDIMAGEFULL;
      validationAlert('Pay attention, you can control the text location using the styling section on the left side of the toolbar.', 5000);
    } else if (visual.showBackgroundImage === BackgroundImageState.TEXTANDIMAGEFULL) {
      visual.showBackgroundImage = BackgroundImageState.TEXTONLY;
    } else if (visual.showBackgroundImage === BackgroundImageState.TEXTONLY) {
      visual.showBackgroundImage = BackgroundImageState.IMAGEONLY;
    }

    this.updateURLArray();
  }

  hasBackgroundImage(): boolean {
    const logical = this.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    return logical.getBackgroundImageUrl()?.length > 0 && logical.getBackgroundImageUrl() !== 'assets/SVG/redx.png';
  }

  shouldShowBackgroundImage(): boolean {
    const visual = this.getVisual() as OpmVisualThing;
    if (!visual || visual.getRefineeInzoom() === visual || visual.semiFolded.length)
      return false;
    return [BackgroundImageState.IMAGEONLY, BackgroundImageState.TEXTANDIMAGE, BackgroundImageState.TEXTANDIMAGEFULL].includes(visual.showBackgroundImage);
  }

  updateParentSizeAfterChildAddedRequirement(init: InitRappidService) {
    if (this.getParent()) {
      init.opmModel.setShouldLogForUndoRedo(false, 'updateSizeBecauseOfRequirement');
      this.getParent().updateSizePositionToFitEmbeded(true, true);
      init.opmModel.setShouldLogForUndoRedo(true, 'updateSizeBecauseOfRequirement');
    }
  }


  addRequirement(init: InitRappidService) {
    if (this.getVisual().logicalElement.visualElements.find(v => v.protectedFromBeingChangedBySubModel)) {
      validationAlert('Cannot add requirements to a thing the is shared with a sub model.', 5000, 'Error');
      return;
    }
    init.opmModel.addRequirement(this.getVisual() as OpmVisualThing);
    const cellsIds = init.graph.getCells().map(c => c.id);
    init.graphService.renderGraph(init.opmModel.currentOpd);
    this.updateParentSizeAfterChildAddedRequirement(init);
    this.beautifyOpdAfterNewRequirementAdded(init, cellsIds);
  }

  beautifyOpdAfterNewRequirementAdded(init: InitRappidService, cellsIds: Array<string>) {
    const newCells = init.graph.getCells().filter(c => !cellsIds.includes(c.id)).filter(cell => OPCloudUtils.isInstanceOfDrawnThing(cell));
    if (newCells.length === 0) {
      return;
    }
    const cellsInNewArea = init.paper.findViewsInArea(init.graph.getCellsBBox(newCells).inflate(10,10)).map(v => v.model).filter(cell => OPCloudUtils.isInstanceOfDrawnThing(cell));
    init.opmModel.setShouldLogForUndoRedo(false, 'beautifyOpdAfterNewRequirementAdded');
    init.getGraphService().beautifyThings(cellsInNewArea, newCells.map(cell => cell.id));
    for (const cl of newCells.filter(c => OPCloudUtils.isInstanceOfDrawnObject(c))) {
      cl.shiftEmbeddedToEdge(init);
      // cl.getVisual().setParams(cl.getParams());
    }
    init.opmModel.setShouldLogForUndoRedo(true, 'beautifyOpdAfterNewRequirementAdded');
  }

  toggleAttributesSet(init: InitRappidService, shouldHideSetObject = false) {
    init.opmModel.toggleAttributesSet(this.getVisual() as OpmVisualThing, shouldHideSetObject);
    init.graphService.renderGraph(init.opmModel.currentOpd);
    this.updateParentSizeAfterChildAddedRequirement(init);
  }

  hideSingleRequirement(init: InitRappidService) {
    init.opmModel.hideSingleRequirement(this.getVisual() as OpmVisualThing);
    this.remove();
  }

  doubleClickHandle(cellView, evt, initRappid) {
    const logical = this.getVisual()?.logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.isSatisfiedRequirementSetObject() || logical.isSatisfiedRequirementObject())
        return;
    super.doubleClickHandle(cellView, evt, initRappid);
  }

  convertBringConnectedSettings(init): Array<BringConnectedTypes> {
    const ret = [];
    const settings = init.currentBringConnectedSettings || init.oplService.settings.bringConnectedSettings;
    if (settings.proceduralEnablers)
      ret.push(BringConnectedTypes.proceduralEnablers);
    if (settings.proceduralTransformers)
      ret.push(BringConnectedTypes.proceduralTransformers);
    if (settings.fundamentals)
      ret.push(BringConnectedTypes.fundamental);
    if (settings.tagged)
      ret.push(BringConnectedTypes.tagged);
    return ret;
  }

  bringAction(init: InitRappidService) {
    const model = init.getOpmModel();
    if (this.getVisual() !== init.opmModel.currentOpd.visualElements.filter(v => v.logicalElement.lid === this.getVisual().logicalElement.lid)[0]) {
      validationAlert('Cannot bring connected things to a duplicated entity.', 5000, 'Error');
      return;
    }
    model.logForUndo('bring connected things');
    const visuals = [...model.currentOpd.visualElements];
    model.bring(model.getVisualElementById(this.id) as OpmVisualThing, this.convertBringConnectedSettings(init), this.getDefaultStyleParams(init));
    init.getGraphService().renderGraph(init.opmModel.currentOpd, init);
    for (const cell of init.graph.getCells()) {
      if (cell instanceof OpmThing && cell.getVisual() && !visuals.includes(cell.getVisual()))
        cell.shiftEmbeddedToEdge(init);
    }
    const newRenderedCell = init.graph.getCell(this.id);
    if (newRenderedCell) {
      newRenderedCell.setFundamentalLinksAnchors();
    }
    for (const link of init.graph.getCells().filter(c => c.constructor.name.includes('Unidirectional'))) {
      if (link.isForkedLink()) {
        const vertices = link.vertices()?.length ? link.vertices() : [{
          x: (link.getSourcePoint().x + link.getTargetPoint().x) / 2,
          y: (link.getSourcePoint().y + link.getTargetPoint().y) / 2
        }];
        link.forkedLinkHandle(link, vertices);
      }
    }
    validationAlert('All known elements were connected', 5000, 'Success');
  }

  setFundamentalLinksAnchors() {
    this.graph.getConnectedLinks(this, { outbound: true })
      .filter(l => OPCloudUtils.isInstanceOfDrawnTriangle(l.getTargetElement()))
      .forEach(link => {
        link.onChangedSource(link, {});
        link.getTargetElement().getBottomLinks().forEach(bLink => bLink.onChangedTarget(bLink, {}));
      });
    this.graph.getConnectedLinks(this, { inbound: true })
      .filter(l => OPCloudUtils.isInstanceOfDrawnTriangle(l.getSourceElement()))
      .forEach(link => {
        link.onChangedTarget(link, {});
        const upperLink = link.getMainUpperLink();
        upperLink.onChangedSource(upperLink, {})
      });
  }

  showRefineable(returnObject, init: InitRappidService) {
    if (!returnObject)
      return;
    if (returnObject.isNewlyCreated) {
      init.getTreeView().createNewNode(returnObject.opd.id, returnObject.opd.parendId, undefined, true);
    } else {
      init.opdHierarchyRef.previousOpdId = init.opmModel.currentOpd.id;
    }
    init.getGraphService().renderGraph(returnObject.opd, init);
    init.opmModel.currentOpd = returnObject.opd;
    highlighSD(returnObject.opd.id, init);
  }

  getDefaultStyleParams(init: InitRappidService) {
    return {
      process: {
        fill: init.oplService.settings.style?.process?.fill_color || init.oplService.getProcessStyleDefaultSettings().fill_color,
        border_color: init.oplService.settings.style?.process?.border_color || init.oplService.getProcessStyleDefaultSettings().border_color,
        font: init.oplService.settings.style?.process?.font || init.oplService.getProcessStyleDefaultSettings().font,
        font_size: init.oplService.settings.style?.process?.font_size || init.oplService.getProcessStyleDefaultSettings().font_size,
        text_color: init.oplService.settings.style?.process?.text_color || init.oplService.getProcessStyleDefaultSettings().text_color,
      },
      object: {
        fill: init.oplService.settings.style?.object?.fill_color || init.oplService.getObjectStyleDefaultSettings().fill_color,
        border_color: init.oplService.settings.style?.object?.border_color || init.oplService.getObjectStyleDefaultSettings().border_color,
        font: init.oplService.settings.style?.object?.font || init.oplService.getObjectStyleDefaultSettings().font,
        font_size: init.oplService.settings.style?.object?.font_size || init.oplService.getObjectStyleDefaultSettings().font_size,
        text_color: init.oplService.settings.style?.object?.text_color || init.oplService.getObjectStyleDefaultSettings().text_color,
      }
    }
  }

  inzoomAction(init: InitRappidService, clean = false) {
    const model = init.getOpmModel();
    const currentOpdId = model.currentOpd.id;
    const visual = model.getVisualElementById(this.id) as OpmVisualThing;
    const ret = model.tryToInzoom(visual, this.getDefaultStyleParams(init), clean);
    if (ret.success === false) {
      validationAlert(ret.message, null, 'Error');
      return;
    }
    init.opdHierarchyRef.previousOpdId = currentOpdId;
    this.showRefineable(ret, init);
    if (ret.isNewlyCreated === true) {
      init.graph.getCells().filter(cell => cell instanceof OpmThing && !cell.getVisual().isInzoomed() && !cell.getParentCell())
        .forEach(cl => {
          cl.set('size', cl.get('minSize'));
          cl.updateSizePositionToFitEmbeded();
        });
      const newRenderedCell = init.graph.getCell(this.id);
      if (newRenderedCell) {
        newRenderedCell.setFundamentalLinksAnchors();
      }
    }
    init.criticalChanges_.next();
  }

  inzoomInDiagramAction(init) {
    const model = init.getOpmModel() as OpmModel;
    model.logForUndo('Inzoom In Diagram');
    model.setShouldLogForUndoRedo(false, 'InzoomInDiagram');
    init.graph.startBatch('ignoreChange');
    const visual = this.getVisual() as OpmVisualThing;
    const ret = model.tryToInzoomInDiagram(visual);
    if (ret.success === false) {
      model.setShouldLogForUndoRedo(true, 'InzoomInDiagram');
      if (init.graph.hasActiveBatch('ignoreEvents'))
        init.graph.stopBatch('ignoreEvents');
      return validationAlert(ret.message, null, 'Error');
    }
    init.getGraphService().renderGraph(ret.opd, init);
    const updatedCell = init.graph.getCell(this.id);
    if (updatedCell && updatedCell.getParent())
      updatedCell.getParent().updateSizePositionToFitEmbeded();
    model.setShouldLogForUndoRedo(true, 'InzoomInDiagram');
    if (init.graph.hasActiveBatch('ignoreEvents'))
      init.graph.stopBatch('ignoreEvents');
    init.criticalChanges_.next();
  }

  unfoldAction(init: InitRappidService, clean = false) {
    const visual = init.getOpmModel().getVisualElementById(this.id) as OpmVisualThing;
    const currentOpdId = init.getOpmModel().currentOpd.id;
    const ret = init.getOpmModel().canBeUnfold(visual, this.getDefaultStyleParams(init), clean);
    if (ret.success === false) {
      validationAlert(ret.message, null, 'Error');
      return;
    }
    init.opdHierarchyRef.previousOpdId = currentOpdId;
    this.showRefineable(ret, init);
    if (ret.isNewlyCreated === true) {
      init.graph.getCells().filter(cell => cell instanceof OpmThing).forEach(cl => {
        // cl.set('size', cl.get('minSize'));
        cl.updateSizePositionToFitEmbeded();
        cl.shiftEmbeddedToEdge(init);
      });
      const refinee = visual.getRefineeUnfold();
      const refineeCell = init.graph.getCell(refinee?.id);
      if (refineeCell) {
        refineeCell.setFundamentalLinksAnchors();
      }
      init.graph.getCell(ret.opd.getUnfoldedThing()?.id)?.autosize(init);
    }
    init.criticalChanges_.next();
  }

  unfold(init, thingID, unfoldingOptions) {
    if (!this.checkIfUnfold(init)) return init.opmModel.unfold(thingID, unfoldingOptions);
  }

  checkIfUnfold(init: InitRappidService): boolean {
    return init.getOpmModel().isUnfolded(init);
  }

  removeHandle(options) {
    super.removeHandle(options);
    this.removeDuplicationMark();
    this.removeDuplicationMarkWhenNoDuplicats(options);
  }

  keepChildrenPositionBySize(initRappid) {
    const thisPos = this.get('position');
    const thisSize = this.get('size');
    const oldSize = this.previousAttributes().size;
    const bbox = this.getBBox();
    const shape = OPCloudUtils.isInstanceOfDrawnProcess(this) ? joint.g.ellipse.fromRect(bbox) : joint.g.Rect(bbox.x, bbox.y, bbox.width, bbox.height);
    let embds = this.getEmbeddedCells().filter(cld => !cld.constructor.name.includes('Semi'));
    for (const child of embds) {
      const childBbox = child.getBBox();
      if (!shape.containsPoint(childBbox.topRight()) || !shape.containsPoint(childBbox.bottomLeft()) ||
        !shape.containsPoint(childBbox.origin()) || !shape.containsPoint(childBbox.corner())) {
        if (thisSize.width < oldSize.width || thisSize.height < oldSize.height) {
          this.graph.startBatch('ignoreEvents');
          this.set('size', oldSize);
          this.graph.stopBatch('ignoreEvents');
          return;
        }
      }
    }
    for (const cell of this.getEmbeddedCells({deep: true}).filter(c => OPCloudUtils.isInstanceOfDrawnThing(c))) {
      const cellPos = cell.get('position');
      const cellSize = cell.get('size');
      const relativeX = (cellPos.x - thisPos.x + cellSize.width/2 ) / oldSize.width;
      const relativeY = (cellPos.y - thisPos.y + cellSize.height/2 ) / oldSize.height;
      const newXPos = relativeX * thisSize.width + thisPos.x - cellSize.width / 2;
      const newYPos = relativeY * thisSize.height + thisPos.y - cellSize.height / 2;
      this.graph.startBatch('ignoreEvents');
      cell.set('position', { x: newXPos, y: newYPos });
      if (OPCloudUtils.isInstanceOfDrawnThing(cell))
        cell.shiftEmbeddedToEdge(initRappid);
      this.graph.stopBatch('ignoreEvents');
    }
  }

  keepChildrenPositionByPosition(initRappid) {
    const thisPos = this.get('position');
    const thisSize = this.get('size');
    const oldPos = this.previousAttributes().position;
    for (const cell of this.getEmbeddedCells({deep: true}).filter(c => OPCloudUtils.isInstanceOfDrawnThing(c))) {
      const cellPos = cell.get('position');
      const cellSize = cell.get('size');
      const relativeX = (cellPos.x - oldPos.x + cellSize.width/2 ) / thisSize.width;
      const relativeY = (cellPos.y - oldPos.y + cellSize.height/2 ) / thisSize.height;
      const newXPos = relativeX * thisSize.width + thisPos.x - cellSize.width / 2;
      const newYPos = relativeY * thisSize.height + thisPos.y - cellSize.height / 2;
      this.graph.startBatch('ignoreEvents');
      cell.set('position', { x: newXPos, y: newYPos });
      if (OPCloudUtils.isInstanceOfDrawnThing(cell))
        cell.shiftEmbeddedToEdge(initRappid);
      this.graph.stopBatch('ignoreEvents');
    }
  }

  changeSizeHandle(initRappid, direction = null) {
    super.changeSizeHandle(initRappid, direction);
    this.keepChildrenPositionBySize(initRappid);

    const embedded = this.getEmbeddedCells();
    for (let i = 0; i < embedded.length; i++) {
      Arc.redrawAllArcs(embedded[i], initRappid, true);
    }
    if (this.get('duplicationMark')) {
      this.redrawDuplicationMark(initRappid);
    }
    if (this.getParent() && this.getParent().get('duplicationMark')) {
      this.getParent().redrawDuplicationMark(initRappid);
    }
    embedded.filter(sm => sm.constructor.name.includes('Semi')).forEach(semi => semi.updateSize(initRappid));
  }

  initializeHaloForComplexityManagement(halo, init) {
    const this_ = this;
    halo.on(`action:inzoom:pointerdown`, function () {
      this_.inzoomAction(init);
    });
    halo.on('action:ShowInZoom:pointerdown', function () {
      this_.inzoomAction(init);
    });
    halo.on('action:unfold:pointerdown', function () {
      this_.unfoldAction(init);
    });
    halo.on('action:ShowUnfold:pointerdown', function () {
      this_.unfoldAction(init);
    });
    halo.on('action:addConnected:pointerdown', function () {
      this_.bringAction(init);
    });
    halo.on('action:computation:pointerdown', function () {
      this_.graph.startBatch('computationAdd');
      this_.computation(init.paper.findViewByModel(this_).el, init);
      this_.graph.stopBatch('computationAdd');
      this.remove();
    });
    halo.on('action:timeDurationFunction:pointerdown', function () {
      this_.openTimeDuration(init.paper.findViewByModel(this_).el, (<any>init.opmModel.getVisualElementById(this_.id)).logicalElement.getDurationManager(), { digits: init.oplService.settings.timeDurationUnitsDigits });
      this.remove();
    });
  }

  public removeComputational(init: InitRappidService) {
  }

  public updateComputational(init: InitRappidService) {
  }

  openTextEditor(cellView, initRappid: InitRappidService, onFinish: () => void = () => { }) {
    if ((<any>this.getVisual().logicalElement).getBelongsToStereotyped())
      return;
    super.openTextEditor(cellView, initRappid, onFinish);
  }

  createContentForUnfoldingOptions(icon, desc) {
    return ('<img width="25" height="20" src="assets/icons/OPM_Links/' + icon + '" ' + '></img><span> ' + desc + '</span><br>');
  }

  // Generic function for ContextToolbar creation
  createContextToolbar(actions, contents, target) {
    const tools = [];
    actions.forEach((action, index) => {
      tools.push({ action: action, content: contents[index] });
    });
    return new joint.ui.ContextToolbar({ theme: 'modern', tools: tools, target: target, autoClose: true, padding: 30 });
  }

  createContextToolbarForComplexityOpts(halo, options = null) {
    const isInZoomed = options.opmModel.isInzoomed(this.id) ? 'Show In-Zoomed' : '<button><svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">\n' +
      '<path d="M20 0C31.0457 0 40 8.9543 40 20C40 31.0457 31.0457 40 20 40C8.9543 40 0 31.0457 0 20C0 8.9543 8.9543 0 20 0Z" fill="#1A3763" fill-opacity="0.8"/>\n' +
      '<path fill-rule="evenodd" clip-rule="evenodd" d="M32 20C32 25.2448 26.9277 30 20 30C13.0723 30 8 25.2448 8 20C8 14.7552 13.0723 10 20 10C26.9277 10 32 14.7552 32 20ZM34 20C34 26.6274 27.7319 32 20 32C12.2681 32 6 26.6274 6 20C6 13.3726 12.2681 8 20 8C27.7319 8 34 13.3726 34 20ZM17 12.9V15.2999C17 15.7418 17.4478 16.1 18 16.1H22C22.5522 16.1 23 15.7418 23 15.2999V12.9C23 12.4581 22.5522 12.1 22 12.1H18C17.4478 12.1 17 12.4581 17 12.9ZM18 18C17.4478 18 17 18.3582 17 18.7999V21.2C17 21.6417 17.4478 22 18 22H22C22.5522 22 23 21.6417 23 21.2V18.7999C23 18.3582 22.5522 18 22 18H18ZM17 27.2V24.7999C17 24.3582 17.4478 24 18 24H22C22.5522 24 23 24.3582 23 24.7999V27.2C23 27.6417 22.5522 28 22 28H18C17.4478 28 17 27.6417 17 27.2Z" fill="white"/>\n' +
      '</svg>\n</button>';
    const isUnfolded = options.opmModel.isUnfolded(this.id) ? 'Show Unfolded' : 'Unfold';
    console.log(isUnfolded);
    return this.createContextToolbar(['In-Zoom', 'Unfold'], [isInZoomed, isUnfolded], halo.el);
  }

  getConfigurationTools(initRappid) {
    const thingToolsArray = [{ action: 'bring', content: 'Add Connected Things' }, { action: 'value', content: 'value' },
    { action: 'Affiliation', content: '<img src=' + this.getImageAffiliation() + ' width="85" height="25">' },
    { action: 'essence', content: '<img src=' + this.getImageEssnce() + ' width="85" height="25">' }];
    if ((initRappid.opmModel.getVisualElementById(this.id).refineeInzooming !== undefined) || (initRappid.opmModel.getVisualElementById(this.id).refineable !== undefined)) {
      thingToolsArray.splice(1, 1);
    }
    return super.getConfigurationTools(initRappid).concat(thingToolsArray);
  }

  configurationContextToolbarEvents(target, contextToolbar, initRappid) {
    super.configurationContextToolbarEvents(target, contextToolbar, initRappid);
    const thisThing = this;
    contextToolbar.on('action:value', function () {
      this.remove();
      thisThing.computation(target, initRappid);
    });
    contextToolbar.on('action:essence', function () {
      const visualThing = <OpmVisualThing>initRappid.opmModel.getVisualElementById(thisThing.get('id'));
      thisThing.toggleEssence(visualThing);
      this.remove();
    });
    contextToolbar.on('action:Affiliation', function () {
      const visualThing = <OpmVisualThing>initRappid.opmModel.getVisualElementById(thisThing.get('id'));
      thisThing.toggleAffiliation(visualThing);
      thisThing.toggleAffiliation(visualThing);
      this.remove();
    });
    contextToolbar.on('action:bring', function () {
      thisThing.bringAction(initRappid);
      this.remove();
    });
  }



  // createContextToolbarForUnfolding(halo, options, ctxThis) {
  //   const thisProcess = this;
  //   const cellModel = halo.options.cellView.model;
  //   let thingID = halo.options.cellView.model.id;
  //   if (options.opmModel.isUnfolded(thingID)) {
  //     ctxThis.remove();
  //     let unfoldedID = options.opmModel.getRefineeUnfoldingID(thingID);
  //     options.graphService.renderGraph(options.opmModel.getOpdByThingId(unfoldedID), options);
  //     options.opmModel.setCurrentOpd(thingID);
  //     return;
  //   }
  //   // thisProcess.startProcessUnfolding(options, null);
  //   ctxThis.remove();
  //   if (1 == 1) return;
  //   const popup = new joint.ui.Popup({
  //     events: {
  //       'click .btn-unfold': function () {
  //         popup.remove();
  //         const unfoldingOptions = {
  //           'Aggregation-Participation': this.$('.btn-c1')[0].checked,
  //           'Exhibition-Characterization-Attributes': this.$('.btn-c2')[0].checked,
  //           'Exhibition-Characterization-Operations': this.$('.btn-c3')[0].checked,
  //           'Generalization-Specialization': this.$('.btn-c4')[0].checked,
  //           'Classification-Instantiation': this.$('.btn-c5')[0].checked
  //         };
  //         thisProcess.startProcessUnfolding(options, unfoldingOptions);
  //       },
  //     },
  //     content: [
  //       '<div>',
  //       '<input type="checkbox" name="structural"  class="btn-c1">' + this.createContentForUnfoldingOptions('StructuralAgg.png', 'Parts') + '<br>',
  //       '<input type="checkbox" name="structural"  class="btn-c2">' + this.createContentForUnfoldingOptions('StructuralExhibit.png', 'Attributes') + '<br>',
  //       '<input type="checkbox" name="structural"  class="btn-c3">' + this.createContentForUnfoldingOptions('StructuralExhibit.png', 'Operations') + '<br>',
  //       '<input type="checkbox" name="structural"  class="btn-c4">' + this.createContentForUnfoldingOptions('StructuralGeneral.png', 'Specializations') + '<br>',
  //       '<input type="checkbox" name="structural"  class="btn-c5">' + this.createContentForUnfoldingOptions('StructuralSpecify.png', 'Instances') + '<br>',
  //       '<center><button class="btn-unfold" style="text-align:center">Unfold</button></center>',
  //       '</div>'
  //     ].join(''),
  //     target: halo.el,
  //     padding: 30,
  //   });
  //   ctxThis.remove();
  //   popup.render();
  // }

  startProcessUnfolding(options, unfoldingOptions) {
    let thisProcess = this;
    let opd = thisProcess.unfold(options, thisProcess.id, unfoldingOptions); // new Ahmad
    options.treeViewService.createNewNode(opd.id, opd.parendId);
    options.graphService.renderGraph(opd, options);
    options.treeViewService.treeView.treeModel.getNodeById(opd.id).toggleActivated();
    options.treeViewService.treeView.treeModel.getNodeById(opd.id).parent.expand();
  }

  toggleFirstLetter(word) {
    return word.charAt(0).toUpperCase() + word.substring(1); // capitalizing the first letter of the word
  }

  toggleLetter(letter) {
    // if(letter === letter.toUpperCase()){
    return letter.toUpperCase();
    // }
    // return letter.toLowerCase();

  }

  getExhibitors() {
    const links = this.graph.getConnectedLinks(this, { inbound: true });
    const exibitors = [];
    for (const link of links) {
      if (link.attributes.name === 'Exhibition-Characterization') {
        exibitors.push(link.getSource());
      }
    }
    return exibitors;
  }

  getInZoomedThings() {
    let NUM_OF_PIXELS = 5;
    const processList = [];
    const objectList = [];
    const objectdict = {};
    const processdict = {};
    try {
      const cells = this.getEmbeddedCells();
      for (const cell of cells) {
        let inside = false;
        if (cell.attributes.type === 'opm.Object') {
          for (const loc of Object.keys(objectdict)) {
            if (Math.abs(cell.get('position').y - Number(loc)) < NUM_OF_PIXELS) {
              objectdict[loc].push(cell);
              inside = true;
              break;
            }
          }
          if (!inside) {
            objectdict[cell.get('position').y] = [cell];
          }

          /*const l = objectList.length;
          if (l===0){
            objectList.push([cell]);
          }else if (Math.abs(objectList[l-1][0].get('position').y-cell.get('position').y)<NUM_OF_PIXELS){
            objectList[l-1].push(cell);
          }else{
            objectList.push([cell]);
          }*/
        } else if (cell.attributes.type === 'opm.Process') {
          /*const l = processList.length;
          if (l===0){
            processList.push([cell]);
          }else if (Math.abs(processList[l-1][0].get('position').y - cell.get('position').y)<NUM_OF_PIXELS){
            processList[l-1].push(cell);
          }else{
            processList.push([cell]);
          }*/
          for (const loc of Object.keys(processdict)) {
            if (Math.abs(cell.get('position').y - Number(loc)) < NUM_OF_PIXELS) {
              processdict[loc].push(cell);
              inside = true;
              break;
            }
          }
          if (!inside) {
            processdict[cell.get('position').y] = [cell];
          }


        }
      }
    } catch (e) {}
    for (const loc of Object.keys(objectdict).map(k => Number(k)).sort((a,b) => a > b ? 1 : -1)) {
      objectList.push(objectdict[loc]);
    }
    for (const loc of Object.keys(processdict).map(k => Number(k)).sort((a,b) => a > b ? 1 : -1)) {
      processList.push(processdict[loc]);
    }
    return [processList, objectList];
  }

  getUnfoldedThings() {
    const aggregation = { objectsAndStates: [], processes: [] };
    const exhibition = { objectsAndStates: [], processes: [] };
    const instantiation = { objectsAndStates: [], processes: [] };
    const generalization = { objectsAndStates: [], processes: [] };
    const visual = this.getVisual() as OpmVisualThing;
    const links = visual.getLinks().outGoing;
    for (const link of links) {
      let targetType;
      if (OPCloudUtils.isInstanceOfVisualObject(link.target)) {
        targetType = 'objectsAndStates';
      } else if (OPCloudUtils.isInstanceOfVisualState(link.target)) {
        targetType = 'objectsAndStates';
      } else {
        targetType = 'processes';
      }
      if (link.type === linkType.Aggregation) {
        aggregation[targetType].push(link.target);
      } else if (link.type === linkType.Exhibition) {
        exhibition[targetType].push(link.target);
      } else if (link.type === linkType.Generalization) {
        generalization[targetType].push(link.target);
      } else if (link.type === linkType.Instantiation) {
        instantiation[targetType].push(link.target);
      }
    }
    const sortFunc = (a,b) => a.xPos - b.xPos;
    aggregation.objectsAndStates.sort(sortFunc);
    exhibition.objectsAndStates.sort(sortFunc);
    instantiation.objectsAndStates.sort(sortFunc);
    generalization.objectsAndStates.sort(sortFunc);
    aggregation.processes.sort(sortFunc);
    exhibition.processes.sort(sortFunc);
    instantiation.processes.sort(sortFunc);
    generalization.processes.sort(sortFunc);

    return { aggregation, exhibition, instantiation, generalization }
  }

  changeAttributesHandle(init) {
    super.changeAttributesHandle(init);
    /*
    const currentOpd = init.getOpmModel().currentOpd;
    const visualArr = init.getOpmModel().getLogicalElementByVisualId(this.id).visualElements.filter(visualsOnCurrentOpd => init.getOpmModel().getOpdByThingId(visualsOnCurrentOpd.id) === currentOpd);
    for (const visualInstance of visualArr) {
      if (this.graph.getCell(visualInstance.id).getEssence() !== this.getEssence()) {
        this.graph.getCell(visualInstance.id).setEssence(this.getEssence());
      }
      if (this.graph.getCell(visualInstance.id).getAffiliation() !== this.getAffiliation()) {
        this.graph.getCell(visualInstance.id).setAffiliation(this.getAffiliation());
      }
    }
    */
  }

  getIconsForHalo() {
    return (this.isComputational()) ?
      Object.assign(super.getIconsForHalo(), { addConnected: 'assets/SVG/addConnected.svg' }, { computation: 'assets/SVG/computation.svg' },
        { deleteFunction: 'assets/SVG/deleteFunction.svg' }, { simulation: 'assets/SVG/sim.svg' }, {updateComputationalProcess: 'assets/SVG/updateComputationalProcess.svg'}) :
      Object.assign(super.getIconsForHalo(), { inzoom: 'assets/SVG/inzoom.svg' }, { ShowInZoom: 'assets/SVG/inzoom.svg' },
        { unfold: 'assets/SVG/unfold.svg' }, { ShowUnfold: 'assets/SVG/unfold.svg' }, { addConnected: 'assets/SVG/addConnected.svg' },
        { computation: 'assets/SVG/computation.svg' });
  }

  public updateView(visual: OpmVisualThing) {
    super.updateView(visual);
    this.setAffiliation(visual.getAffiliation());
    this.setEssence(visual.getEssence());
    this.updateStrokeWidth(visual);
    this.attr('text/ref-y', visual.refY);
  }

  private updateStrokeWidth(visual: OpmVisualThing) {
    let width = 2;
    if (visual.getRefineeInzoom() || visual.getRefineeUnfold() || (<any>visual.logicalElement).getStereotype())
      width = 4;
    this.setStrokeWidth(width);
  }

  private setStrokeWidth(width: number) {
    this.attr(this.getShape(), { 'stroke-width': width });
  }

  isComputational() {
    return this.isComputational();
  }

  addSemifolded() {
    const init = getInitRappidShared();
    init.getOpmModel().logForUndo('Semi-folding');
    init.getOpmModel().setShouldLogForUndoRedo(false, 'OpmThing-addSemifolded');
    const ret = init.getOpmModel().foldInAllFundamentalRelations(this.getVisual());
    if (ret.success)
      init.getGraphService().viewSemiFoldedUpdate(ret);
    this.updateSizePositionToFitEmbeded(true);
    this.set('size', { width: (<any>this.getVisual()).calculateMinWidth(), height: (<any>this.getVisual()).calculateMinHeight() });
    this.shiftEmbeddedToEdge(initRappidShared);
    init.getOpmModel().setShouldLogForUndoRedo(true, 'OpmThing-addSemifolded');
  }

}
