
export enum OntologyEnforcementLevel {
  NONE = 1,
  SUGGEST,
  FORCE
}

export interface OntologyItem {
  phrases: Array<string>;
  synonyms: Array<string>;
}
