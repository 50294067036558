import {OpmModel} from "./OpmModel";
import {OpmOpd} from "./OpmOpd";
import {OpmVisualEntity} from "./VisualPart/OpmVisualEntity";
import {OpmLink} from "./VisualPart/OpmLink";
import { util } from 'jointjs';
import uuid = util.uuid;
import {ContextService} from "../modules/app/context.service";
import {OpmLogicalEntity} from "./LogicalPart/OpmLogicalEntity";
import {OPCloudUtils} from "../configuration/rappidEnviromentFunctionality/shared";
import {ModelContext} from "../modules/app/context";
import {OpmVisualObject} from "./VisualPart/OpmVisualObject";
import {OpmLogicalThing} from "./LogicalPart/OpmLogicalThing";
import {OpmVisualThing} from "./VisualPart/OpmVisualThing";
import {SatisfiedRequirementSetModule} from "./hiddenAttributes/satisfied-requirement-set";
import {OpmLogicalObject} from "./LogicalPart/OpmLogicalObject";
import {OpmLogicalProcess} from "./LogicalPart/OpmLogicalProcess";

export class SubModelCreator {

  constructor(private model: OpmModel, private readonly contextService: ContextService = undefined) {
  }

  public async createFirstOpdFromSharedLogicalsParams(subModel: OpmModel, logicalsParams, title: { nameForOpd: string , nameForModel: string }): Promise<string> {
    const sharedOpd = this.createSharedOpd(title.nameForOpd);
    const ret = this.createSharedVisuals(logicalsParams);
    const newLogicalParams = ret.json;
    const newVisualsIds = ret.newVisualsId;
    this.createLogicalElementsFromLogicalsParams(subModel, newLogicalParams, newVisualsIds);
    subModel.fatherModelName = (<ModelContext>this.contextService.getCurrentContext()).properties.name;
    const fatherModelId = (<ModelContext>this.contextService.getCurrentContext()).properties.id;
    const newModelId = await this.contextService.saveSubModel(subModel.toJson(), title.nameForModel, fatherModelId);
    sharedOpd.sharedOpdWithSubModelId = newModelId;
    // now import the model with the shared import subModel mechanism (shared for creation and import).
    this.importSubModel(subModel, sharedOpd, newModelId);
    return newModelId;
  }

  public lazyLoadSubModel(subModelId: string, subModel: OpmModel, sharedOpd: OpmOpd, subModelEditDate?: string) {
    sharedOpd.sharedOpdWithSubModelId = subModelId;
    sharedOpd.subModelEditDate = subModelEditDate;
    this.importSubModel(subModel, sharedOpd, subModelId);
  }

  public importSubModel(subModel: OpmModel, sharedOpd: OpmOpd, subModelId: string) {
    const sharedLogicals = [];
    const notSharedLogicals = [];
    for (let i = subModel.opds.length - 1; i >=0 ; i--) {
      if (subModel.opds[i].requirementViewOf) {
        subModel.opds[i].visualElements.forEach(v => v.remove()); // removing sub model's requirements views opds visuals.
      }
    }
    for (const logical of subModel.logicalElements) {
      if (this.model.getLogicalElementByLid(logical.lid)) {
        sharedLogicals.push(logical);
        if (OPCloudUtils.isInstanceOfLogicalEntity(logical)) {
          (<OpmLogicalEntity<OpmVisualEntity>>this.model.getLogicalElementByLid(logical.lid)).text = (<OpmLogicalEntity<OpmVisualEntity>>logical).getBareName();
        }
        if (OPCloudUtils.isInstanceOfLogicalObject(logical)) {
          (<OpmLogicalObject>this.model.getLogicalElementByLid(logical.lid)).value = (<OpmLogicalObject>logical).value;
          (<OpmLogicalObject>this.model.getLogicalElementByLid(logical.lid)).alias = (<OpmLogicalObject>logical).alias;
        } else if (OPCloudUtils.isInstanceOfLogicalProcess(logical)) {
          (<OpmLogicalProcess>this.model.getLogicalElementByLid(logical.lid)).code = (<OpmLogicalProcess>logical).code;
          (<OpmLogicalProcess>this.model.getLogicalElementByLid(logical.lid)).insertedFunction = (<OpmLogicalProcess>logical).insertedFunction;
        }
        continue;
      }
      logical.opmModel = this.model;
      notSharedLogicals.push(logical);
      logical.visualElements.forEach(v => {
        v.belongsToSubModel = subModelId;
      }); // marking all the sub model brought visuals
    }
    this.model.logicalElements.push(...notSharedLogicals);
    for (const logical of sharedLogicals) {
      const logicalInThisModel = this.model.getLogicalElementByLid(logical.lid);
      for (const visual of logical.visualElements) {
        if (!logicalInThisModel.visualElements.find(vis => vis.id === visual.id)) {
          logicalInThisModel.visualElements.push(visual); // moving the visuals of the shared logicals of the sub model to the father model.
          visual.belongsToSubModel = subModelId; // marking all the sub model brought visuals
          visual.logicalElement = logicalInThisModel;
        }
      }
      if (OPCloudUtils.isInstanceOfLogicalThing(logical) && (<OpmLogicalThing<OpmVisualThing>>logical).getAllRequirements().length) {
        (<OpmLogicalThing<OpmVisualThing>>logicalInThisModel).hiddenAttributesModule.satisfiedRequirementSetModule =
          new SatisfiedRequirementSetModule(logical.hiddenAttributesModule.satisfiedRequirementSetModule.toJson(), this.model);
      }
    }
    const sharedOpdId = sharedOpd.id;
    const sharedOpdInSubModel = subModel.opds.find(o => o.id === 'SD');
    sharedOpd.visualElements = sharedOpdInSubModel.visualElements.map(v => this.model.getVisualElementById(v.id)); // CRITICAL! - puts the correct visuals in the father model opd's visual elements array.
    for (const opd of subModel.opds) {
      if (opd.requirementViewOf) {
        continue;
      }
      if (opd.parendId === 'SD') {
        opd.parendId = sharedOpdId;
      }
      if (opd.id !== 'SD') {
        this.model.opds.push(opd);
        opd.belongsToSubModel = subModelId;
      }
    }
    sharedOpd.children = sharedOpdInSubModel.children.map(c => c).filter(c => c.id !== sharedOpd.id); // copy array for safety.
    sharedOpd.sharedOpdWithSubModelId = subModelId; // when clicking on this opd on the tree the sub model will be loaded based on this property.
    for (const stereotype of subModel.stereotypes.getStereoTypes()) {
      stereotype.belongsToSubModel = subModelId;
      this.model.stereotypes.addStereotype(stereotype);
    }
  }

  private createSharedOpd(title: string): OpmOpd {
    const sharedOpd = new OpmOpd(title);
    sharedOpd.setAsViewOpd();
    sharedOpd.parendId = this.model.currentOpd.id;
    this.model.currentOpd.children.push(sharedOpd);
    this.model.opds.push(sharedOpd);
    return sharedOpd;
  }

  private createSharedVisuals(logicalsParams): { json, newVisualsId: Array<string> } {
    // creates the new visuals that will be on the shared opd (by copying the existing and changing their ids).
    const oldVisualsIds = logicalsParams.map(l => l.visualElementsParams[0].id);
    let jsonAsString = JSON.stringify(logicalsParams);
    const newVisualsId = [];
    for (const id of oldVisualsIds) {
      const newId = uuid();
      newVisualsId.push(newId);
      while (jsonAsString.includes(id)) {
        jsonAsString = jsonAsString.replace(id, newId)
      }
    }
    return { json: JSON.parse(jsonAsString), newVisualsId };
  }

  private createLogicalElementsFromLogicalsParams(subModel: OpmModel, newLogicalParams: any, newVisualsIds: Array<string>) {
    const tempModel = new OpmModel();
    (<any>tempModel.opds[0]).visualElements = newVisualsIds;
    const jsonFormatModel = {
      name: 'subModel',
      description: '',
      archiveMode: { archiveMode: false, date: '', user: ''},
      permissions: null,
      currentOpd: tempModel.opds[0],
      logicalElements: newLogicalParams,
      opds: tempModel.opds,
      stereotypes: [],
      autoOpdTreeSort: null,
      importedTemplates: null,
      relatedRelations: null
    }
    subModel.fromJson(jsonFormatModel);
    const fatherModelId = (<ModelContext>this.contextService.getCurrentContext()).properties.id;
    for (const logical of subModel.logicalElements) {
        (<OpmLogicalEntity<OpmVisualEntity>>logical).belongsToFatherModelId = fatherModelId;
        for (const vis of logical.visualElements) {
          vis.belongsToFatherModelId = fatherModelId;
        }
    }
  }

  getElementsParamsToCopyToSubModel(opd: OpmOpd, visuals: Array<OpmVisualEntity>) {
    const entities = [];
    const links = [];
    for (const visual of opd.visualElements) {
      if (visual.isLink()) {
        const link = visual as OpmLink;
        if (visuals.includes(link.source) && visuals.includes(link.target)) {
          const params = link.logicalElement.getParams();
          // leaving only one visual for the new sub model (visual id should be changed).
          params.visualElementsParams = params.visualElementsParams.filter(v => v.id === link.id);
          links.push(params);
        }
      }
    }
    for (const visual of visuals) {
      if (!visual.isLink()) {
        const params = visual.logicalElement.getParams();
        // leaving only one visual for the new sub model (visual id should be changed).
        params.visualElementsParams = params.visualElementsParams.filter(v => v.id === visual.id);
        entities.push(params);
        if (OPCloudUtils.isInstanceOfVisualObject(visual)) {
          const states = (<OpmVisualObject>visual).states || [];
          for (const state of states) {
            if (!visuals.includes(state)) {
              const logStateParams = state.logicalElement.getParams();
              logStateParams.visualElementsParams = logStateParams.visualElementsParams.filter(s => s.id === state.id);
              entities.push(logStateParams);
            }
          }
        }
      }
    }
    return [...entities, ...links];
  }


  createSubModel(visuals: Array<OpmVisualEntity>, title: { nameForOpd: string , nameForModel: string }): Promise<string> {
    const logicalsParams = this.getElementsParamsToCopyToSubModel(this.model.currentOpd, visuals);
    const subModel = new OpmModel(); // or a loaded sub model OpmModel.
    return this.createFirstOpdFromSharedLogicalsParams(subModel, logicalsParams, title);
  }

  public switchStereotypesIds(rawSubModel) {
    const fatherModelStereotypes = this.model.stereotypes.getStereoTypes().map(st => {
      return { id: st.id, date: st.lastEditDate };
    });
    const toSwitch = [];
    for (const str of (rawSubModel.stereotypes || [])) {
      if (fatherModelStereotypes.find(item => str.id === item.id && str.lastEditDate !== item.date)) {
        toSwitch.push({oldId: str.id, newId: uuid()});
      }
    }
    if (toSwitch.length === 0)
      return rawSubModel;
    let jsonAsString = JSON.stringify(rawSubModel);
    for (const item of toSwitch) {
      while (jsonAsString.includes(item.oldId)) {
        jsonAsString = jsonAsString.replace(item.oldId, item.newId)
      }
    }
    return JSON.parse(jsonAsString);
  }

  protectElementsFromBeingRefined(newModelId: string, logicals: Array<OpmLogicalEntity<OpmVisualEntity>>) {
    for (const logical of logicals) {
      logical.protectedFromBeingRefinedBySubModel = newModelId;
    }
  }

  protectElementsFromBeingChanged(newModelId: string, visuals: Array<OpmVisualEntity>) {
    for (const vis of visuals) {
      vis.protectedFromBeingChangedBySubModel = newModelId;
      const links = vis.getLinks();
      for (const inLink of links.inGoing) {
        if ((<OpmLogicalEntity<OpmVisualEntity>>inLink.source.logicalElement).protectedFromBeingRefinedBySubModel) {
          inLink.protectedFromBeingChangedBySubModel = newModelId;
        }
      }
      for (const outLink of links.outGoing) {
        if ((<OpmLogicalEntity<OpmVisualEntity>>outLink.target.logicalElement).protectedFromBeingRefinedBySubModel) {
          outLink.protectedFromBeingChangedBySubModel = newModelId;
        }
      }
    }
  }
}
