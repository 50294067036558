import { Component, Inject, Optional } from "@angular/core";
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GraphService } from '../../rappid-components/services/graph.service';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { OplService } from '../../opl-generation/opl.service';
import { GroupsService } from '../../rappid-components/services/groups.service';
import { linkType } from '../../models/ConfigurationOptions';
import { ContextService } from '../../modules/app/context.service';
import { StorageService } from '../../rappid-components/services/storage.service';
import {OpmOpd} from '../../models/OpmOpd';
import * as FileSaver from 'file-saver';
import {OPCloudUtils, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {SelectOpdsTreeDialog} from '../select-opds-tree-dialog/select-opds-tree-dialog';

/*
* This Component enables to export the entire model as an HTML file.
* inputs: open OPM model in the web
* output: single HTML file
*/
@Component({
  selector: 'opcloud-export-model-as-html-dialog',
  templateUrl: 'export-model-as-html.html',
  styleUrls: ['export-model-as-html.css']
})

export class ExportModelAsHtmlComponent {
  modelName; // Used for showing to the user the default model name when choosing a name for the HTML file.
  spinnerFlag = false; // flag to show a spinner while downloading
  showTooltips = false;
  opdsOrder;
  numberedOPL;
  originalOpd;
  includeEntitiesDescription: boolean;
  includeProcessComputational: boolean;
  resolutionOPDs;
  resolutionCheck: boolean;
  confidentialWatermark: boolean;
  includeRequirementViews: boolean;
  includeDictionary: boolean;
  private selectedOpds: Array<OpmOpd>;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any, // to get the model name (if saved)
    @Optional() public dialogRef: MatDialogRef<ExportModelAsHtmlComponent>,
    private graphService: GraphService,
    private initRappidService: InitRappidService,
    private contextService: ContextService,
    private storageService: StorageService,
    private oplService: OplService, // Do not delete, it is used in the component! "this" is passed as a parameter to some functions
    private groupsService: GroupsService // Do not delete, it is used in the component!
  ) {
    if (data.modelName) { // get the model name to set as a default value to the HTML file name
      this.modelName = data.modelName;
    } else { // if the model is not saved, and thus we have no model name, set 'Unsaved Model' as the default file name
      this.modelName = 'Unsaved Model';
    }
    this.originalOpd = this.initRappidService.opmModel.currentOpd;// save current open OPD to render after the process has finished
  }

  async openOpdsSelectionDialog(includeUnloadedSubModels) {
    if (includeUnloadedSubModels) {
      if (this.initRappidService.opmModel.opds.some(opd => opd.sharedOpdWithSubModelId && opd.visualElements.length === 0)) {
        validationAlert('Loading all sub models');
        await OPCloudUtils.waitXms(100);
      }
      await this.initRappidService.opdHierarchyRef.loadAllSubModels();
    }
    this.initRappidService.dialogService.openDialog(SelectOpdsTreeDialog, 700, 900, { allowMultipleDialogs: true, title: 'Select OPDs to Export:', mode: 'export' })
      .afterClosed().toPromise().then(res => {
      this.selectedOpds = res;
    });
  }

  public async saveHtmlMain(
    fileName: string,
    includeURL: boolean,
    includeTooltips: boolean,
    numberedOPL: boolean,
    includeDescription: boolean,
    includeComputational: boolean,
    resolutionCheck: boolean,
    resolutionOPDs: number,
    confidentialWatermark: boolean,
    includeElementsDictionary: boolean,
    includeRequirementViews: boolean,
    includeUnloadedSubModels: boolean
  ) {
    if ($('.mat-dialog-container')[0])
      $('.mat-dialog-container')[0].style.overflow = 'hidden';
    if (includeUnloadedSubModels) {
      await this.initRappidService.opdHierarchyRef.loadAllSubModels();
    }
    this.initRappidService.currentlyExportingPdf = true; // Starting export, using PDF hook
    const title = fileName;
    this.includeRequirementViews = includeRequirementViews;
    this.includeEntitiesDescription = includeDescription;
    this.includeProcessComputational = includeComputational;
    // Set OPD order and collect selected OPDs
    this.setOpdsOrder();
    this.spinnerFlag = true; // from here on the spinner will be shown until the downloading ends.
    if ($('.mat-dialog-container').length > 0) {
      $('.mat-dialog-container')[0].style.background = 'transparent';
      $('.mat-dialog-container')[0].style.boxShadow = 'none';
    }
    this.dialogRef.disableClose = true;
    const selectedOpds = this.opdsOrder;

    // Initialize model metadata
    let creationDate = "Unknown";
    let userName = "Unknown";
    let description = "No description available";
    const model = this.initRappidService.opmModel;

    // Check for model metadata and fill in the fields if available
    if (this.graphService.modelObject.modelData) {
      const modelData = this.graphService.modelObject.modelData;
      if (modelData?.editBy && modelData.editBy['date'] && modelData.editBy['date'] !== '') {
        creationDate = typeof modelData.editBy['date'] === 'number'
          ? formatDate(new Date(modelData.editBy['date']))
          : modelData.editBy['date'];
      } else {
        const today = new Date();
        creationDate = formatDate(today);
      }

      const permissions = await this.storageService.getPermissions(this.graphService.modelObject.id) || {};
      userName = this.groupsService.getUserById(permissions.ownerID)?.Name || '';

      // If username is empty or in Hebrew, use email instead
      if (permissions.ownerID && (userName.search(/[\u0590-\u05FF]/) >= 0 || userName === '')) {
        userName = this.groupsService.getUserById(permissions.ownerID)?.Email || '';
      }

      description = modelData.description || description;
    }

    // Helper function to format dates and times with seconds according to the user's locale
    function formatDate(date: Date): string {
      // Use Intl.DateTimeFormat with the user's locale and include seconds
      return new Intl.DateTimeFormat(navigator.language, {
        year: 'numeric',
        month: '2-digit',
        day: '2-digit',
        hour: '2-digit',
        minute: '2-digit',
        second: '2-digit'
      }).format(date);
    }

    // Begin HTML content
    let htmlContent = `
<!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>${title}</title>
    <style>
        body { font-family: Arial, sans-serif; display: flex; }
        .sidebar { width: 20%; height: 100vh; padding: 10px; position: fixed; overflow-y: auto; border-right: 1px solid #ddd; }
        .content { width: 80%; margin-left: 22%; padding: 10px; }
        .section { margin: 20px 0; }
        .section h2 { color: #4CAF50; }
        ul { list-style-type: none; padding: 0; }
        .image { text-align: center; margin: 20px 0; }
        img { max-width: 100%; height: auto; }
        a { color: #0066cc; text-decoration: none; }
        a:hover { text-decoration: underline; }
        .indented { margin-left: 20px; }
        ${confidentialWatermark ? 'body::after { content: "Confidential"; position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); font-size: 5em; color: rgba(200, 200, 200, 0.3); }' : ''}
    </style>
</head>
<body>
    <div class="sidebar" id="sidebar">
        <h2>Navigation</h2>
        <ul>
            <li><a href="#overview">Overview</a></li>
            <li><a href="#opd-tree">OPD Tree</a></li>
            <li><a href="#diagrams-opl">Diagrams & OPL</a></li>`;

    // Add OPD links dynamically with hierarchy
    htmlContent += `<ul>`;
    this.opdsOrder.forEach(opd => {
      const opdName = this.getFormattedOpdName(opd, this);
      htmlContent += `<li><a href="#${opdName.replace(/\s+/g, '_')}">${opdName}</a></li>`;
    });
    htmlContent += `</ul>`;

    // Conditionally add the Elements Dictionary link at the end if selected
    if (includeElementsDictionary) {
      htmlContent += `<li><a href="#elements-dictionary">Elements Dictionary</a></li>`;
    }

    htmlContent += `</ul></div><div class="content" id="content">`;

    // Overview Section
    htmlContent += `<div id="overview"><h1>${title}</h1>`;
    htmlContent += `<p>Last Edited: ${creationDate}</p>`;
    htmlContent += `<p>Created by: ${userName}</p>`;
    htmlContent += `<p>Model Description: ${description}</p>`;
    if (includeURL) {
      const urldata = this.contextService.makeUrl();
      let url = 'URL not defined for unsaved model';
      if (urldata.allowed)
        url = urldata.url.split('|||')[0];
      htmlContent += `<p>Model URL: <a href="${url}">${title}</a></p>`;
    }
    htmlContent += `</div>`;


    htmlContent += `<div class="section" id="opd-tree"><h2>OPD Tree</h2><ul>`;
    this.opdsOrder.forEach(opd => {
      const opdName = this.getFormattedOpdName(opd, this);
      const numberedName = opd.getNumberedName();
      // Set hierarchy level: `SD` is top level, `SD1`, `SD2`, etc., are at the next level,
      // and deeper levels like `SD1.1`, `SD1.1.1` follow accordingly.
      let hierarchyLevel;
      if (numberedName === 'SD') {
        hierarchyLevel = 1; // SD is the top level
      } else if (/^SD\d+$/.test(numberedName)) {
        hierarchyLevel = 2; // SD1, SD2, etc., are one level below SD
      } else {
        hierarchyLevel = numberedName.split('.').length + 1; // further levels increase accordingly
      }
      htmlContent += `<li class="indented" style="margin-left: ${20 * (hierarchyLevel - 1)}px;">${opdName}</li>`;
    });
    htmlContent += `</ul></div>`;

    // Diagrams & OPL Section
    htmlContent += `<div class="section" id="diagrams-opl"><h2>Diagrams & OPL</h2>`;
    const diagrams = await this.captureSelectedOPDsAsImages(selectedOpds, resolutionCheck, resolutionOPDs, includeTooltips, includeUnloadedSubModels, includeComputational, numberedOPL);
    for (const diagram of diagrams) {
      htmlContent += this.insertImageAndNameHtml(diagram.imageData, diagram.name, diagram.oplHtml);
      htmlContent += `<p><a href="#overview">Back to Top</a></p>`;
    }
    htmlContent += `</div>`;

    // Elements Dictionary Section - if applicable
    if (includeElementsDictionary) {
      htmlContent += this.insertElementsDictionary();
    }

    // Close content and body
    htmlContent += `
    </div>
    <script>
        let sidebarZoom = 1;
        let contentZoom = 1;
        const zoomFactor = 1.05; // Zoom factor for smoother transitions
        const minZoom = 0.5;    // Minimum zoom level
        const maxZoom = 2;      // Maximum zoom level

        function applyZoom(element, zoomLevel) {
            element.style.transform = \`scale(\${zoomLevel})\`;
            element.style.transformOrigin = "top left";
        }

        function resetZoom() {
            sidebarZoom = 1;
            contentZoom = 1;
            applyZoom(document.getElementById('sidebar'), sidebarZoom);
            applyZoom(document.getElementById('content'), contentZoom);
        }

        document.getElementById('sidebar').addEventListener('wheel', (event) => {
            if (event.ctrlKey) {
                event.preventDefault();
                if (event.deltaY < 0) {
                    sidebarZoom = Math.min(maxZoom, sidebarZoom * zoomFactor); // Zoom in
                } else {
                    sidebarZoom = Math.max(minZoom, sidebarZoom / zoomFactor); // Zoom out
                }
                applyZoom(event.currentTarget, sidebarZoom);
            }
        });

        document.getElementById('content').addEventListener('wheel', (event) => {
            if (event.ctrlKey) {
                event.preventDefault();
                if (event.deltaY < 0) {
                    contentZoom = Math.min(maxZoom, contentZoom * zoomFactor); // Zoom in
                } else {
                    contentZoom = Math.max(minZoom, contentZoom / zoomFactor); // Zoom out
                }
                applyZoom(event.currentTarget, contentZoom);
            }
        });

        // Reset Button with Material Design Style
        document.body.insertAdjacentHTML('beforeend', \`
            <button id="resetZoom"
                    style="position: fixed; bottom: 10px; right: 10px; padding: 8px 16px; font-size: 14px; background-color: #3f51b5; color: #fff; border: none; border-radius: 4px; box-shadow: 0px 2px 4px rgba(0,0,0,0.2); cursor: pointer;">
                Reset Zoom
            </button>\`);

        document.getElementById('resetZoom').addEventListener('click', resetZoom);

        // Add hover effect for button
        document.getElementById('resetZoom').addEventListener('mouseenter', () => {
            document.getElementById('resetZoom').style.backgroundColor = '#303f9f';
        });
        document.getElementById('resetZoom').addEventListener('mouseleave', () => {
            document.getElementById('resetZoom').style.backgroundColor = '#3f51b5';
        });
    </script>
</body></html>`;



    // Save HTML file
    const blob = new Blob([htmlContent], { type: 'text/html' });
    FileSaver.saveAs(blob, `${fileName.replace(/\s+/g, '_')}.html`);
    this.initRappidService.currentlyExportingPdf = false; // Ending export, using PDF hook
    this.graphService.renderGraph(this.originalOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
    this.dialogRef.close();
  }

  private setOpdsOrder() {
    this.opdsOrder = [];
    let defaultSelection = false;
    if (!this.selectedOpds) {
      this.selectedOpds = this.initRappidService.opmModel.opds;
      defaultSelection = true;
    }
    const regularOpds = this.selectedOpds.filter(o => !o.requirementViewOf && !o.stereotypeOpd).sort((a,b) => {
      return a.getNumberedName() > b.getNumberedName() ? 1 : -1;
    })
    const requirementViews = this.includeRequirementViews ? this.selectedOpds.filter(o => o.requirementViewOf) : [];
    let stereotypesOpds;
    if (defaultSelection) {
      stereotypesOpds = this.initRappidService.opmModel.stereotypes.getStereoTypes().map(str => str.opd);
    } else {
      stereotypesOpds = this.selectedOpds.filter(opd => opd.stereotypeOpd);
    }
    this.opdsOrder = [...regularOpds, ...requirementViews, ...stereotypesOpds].filter(opd => !opd.isHidden);
  }

  private getFormattedOpdName(opd: any, self: this): string {
    let opdName = opd.getName ? opd.getName() : opd.name;

    if (opd.isStereotypeOpd && opd.isStereotypeOpd()) {
      const stereotype = self.initRappidService.opmModel.stereotypes.getStereoTypes().find(s => s.opd === opd);
      opdName = 'Stereotype: ' + (stereotype ? (stereotype.getName ? stereotype.getName() : stereotype["name"]) : 'Unnamed');
    } else if (opd.requirementViewOf) {
      opdName = opd.name;
    } else {
      const sdNumber = opd.getNumberedName ? opd.getNumberedName() : 'SD';
      if (sdNumber !== 'SD') {
        opdName = `${sdNumber}: ${opdName}`;
      }
    }

    return opdName;
  }

  private async captureSelectedOPDsAsImages(
    selectedOpds: any[],
    resolutionCheck: boolean,
    resolutionOPDs: number,
    includeTooltips: boolean,
    includeUnloadedSubModels: boolean,
    includeComputational: boolean,
    numberedOPL: boolean
  ): Promise<any[]> {
    const diagrams = [];

    if (includeUnloadedSubModels) {
      await this.initRappidService.opdHierarchyRef.loadAllSubModels();
    }
    let treeLevel = 0; // this variable is used later to count the levels(opds) of the module tree
    for (const opd of selectedOpds) {
      const precents = String(Math.floor((treeLevel / selectedOpds.length) * 100)) + '%';
      if ($('#spinnerValue').length > 0) {
        $('#spinnerValue')[0].textContent = precents;
      }
      treeLevel += 1; // go to the next "level" in the opm-model-tree
      this.graphService.renderGraph(opd, this.initRappidService);

      if (includeTooltips) {
        for (const proc of this.graphService.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnProcess(c))) {
          proc.showDummyTooltip();
        }
      }

      const imageData = await this.captureImageData(opd, resolutionCheck ? resolutionOPDs : '1');
      const oplHtml = await this.insertOpls(opd, includeComputational, numberedOPL);
      diagrams.push({
        name: this.getFormattedOpdName(opd, this),
        imageData: imageData,
        oplHtml: oplHtml
      });
      $('.dummyTooltip').remove(); // remove the computational tooltip is present
    }

    return diagrams;
  }

  private async captureImageData(opd: any, resolution: string | number): Promise<string> {
    return new Promise((resolve, reject) => {
      const paper = this.initRappidService.paper;
      paper.toJPEG((imageData) => resolve(imageData), {
        padding: 40,
        useComputedStyles: false,
        size: `${resolution}x`,
        quality: 1.0
      });
    });
  }

  private async insertOpls(opd: any, includeComputational: boolean, numberedOPL: boolean): Promise<string> {
    this.graphService.renderGraph(opd, this.initRappidService);
    const opls = this.initRappidService.oplService.generateOpl();

    let oplHtml = "<ul>";
    for (let i = 0; i < opls.length; i++) {
      let oplSentence = opls[i].opl;
      if (!includeComputational && opls[i].isComputational) {
        continue;
      }
      if (numberedOPL) {
        oplSentence = `${i + 1}. ${oplSentence}`;
      }
      oplHtml += `<li>${oplSentence}</li>`;
    }
    oplHtml += "</ul>";

    return oplHtml;
  }

  private insertElementsDictionary(): string {
    const logicArray = this.initRappidService.opmModel.logicalElements;
    const objectsArray = [];
    const processesArray = [];
    const proceduralMap = new Map();
    const fundamentalMap = new Map();
    const taggedMap = new Map();

    for (let entry of logicArray) {
      if (entry.name === 'OpmLogicalObject') {
        objectsArray.push(this.getThingData(entry, 'Object'));
      } else if (entry.name === 'OpmLogicalProcess') {
        processesArray.push(this.getThingData(entry, 'Process'));
      } else if (entry.name === 'OpmProceduralRelation') {
        this.getRelationData(entry, proceduralMap);
      } else if (entry.name === 'OpmFundamentalRelation') {
        this.getRelationData(entry, fundamentalMap);
      } else if (entry.name === 'OpmTaggedRelation') {
        this.getRelationData(entry, taggedMap);
      }
    }

    let dictionaryHtml = `<div class="section" id="elements-dictionary"><h2>Elements Dictionary</h2>`;

    // Objects Section
    dictionaryHtml += `<h3>Objects:</h3>`;
    objectsArray.forEach(object => {
      dictionaryHtml += `<div class="indented">${object}</div><br>`;
    });

    // Processes Section
    dictionaryHtml += `<h3>Processes:</h3>`;
    processesArray.forEach(process => {
      dictionaryHtml += `<div class="indented">${process}</div><br>`;
    });

    // Relations Sections
    let showExample = true; // Flag to show title only for the first non-empty map
    if (proceduralMap.size > 0) {
      dictionaryHtml += this.generateRelationSection('Procedural Relations', proceduralMap, showExample);
      showExample = false; // Set flag to false after the first non-empty call
    }
    if (fundamentalMap.size > 0) {
      dictionaryHtml += this.generateRelationSection('Fundamental Relations', fundamentalMap, showExample);
      showExample = false; // Set flag to false in case this is the first non-empty map
    }
    if (taggedMap.size > 0) {
      dictionaryHtml += this.generateRelationSection('Tagged Relations', taggedMap, showExample);
    }

    dictionaryHtml += `</div>`;
    return dictionaryHtml;
  }

  private getThingData(entry: any, thingType: string): string {
    let thingHtml = `<strong>${thingType} Name:</strong> <span style="color: ${thingType === 'Object' ? '#00B050' : '#0070C0'};">${entry.text.replace('\n', ' ')}</span>`;

    const imageUrl = entry.getBackgroundImageUrl && entry.getBackgroundImageUrl() !== '' && entry.getBackgroundImageUrl() !== 'assets/SVG/redx.png' ? entry.getBackgroundImageUrl() : null;
    if (imageUrl) {
      thingHtml += `<br><strong>Image URL:</strong><div class="indented">${imageUrl}</div>`;
    }
    let skipBR = false; // Skip the <br> as it is not first
    if (entry.URLarray?.length > 0 && entry.URLarray[0].url !== 'http://') {
      thingHtml += `${imageUrl ? '' : '<br>'}<strong>Hyperlinks URLs: </strong><div class="indented">${entry.URLarray.map(item => item.url).join('<br>')}</div>`;
      skipBR = true;
    }
    if (this.includeProcessComputational && thingType === 'Process' && entry.insertedFunction !== 'None'
      && entry.insertedFunction.functionInput) { // Currently supports only user defined,AI and python functions
      let func = entry.insertedFunction.functionInput.toString();
      if (entry.insertedFunction.functionInput.code) { // If its Python or AI code
        func = entry.insertedFunction.functionInput.code.toString();
      }
      thingHtml += `${imageUrl || skipBR ? '' : '<br>'}<strong>Process Computational Function: </strong><div class="indented">${func}</div>`;
      skipBR = true;
    }
    if (this.includeEntitiesDescription && entry.description?.trim() !== '') {
      thingHtml += `${imageUrl || skipBR ? '' : '<br>'}<strong>Description: </strong><div class="indented">${entry.description}</div>`;
      skipBR = true;
    }

    const opds = entry.visualElements.map((visual: any) => {
      const opd = this.initRappidService.opmModel.getOpdByThingId(visual.id);
      return opd ? opd.getName() : null;
    }).filter((opdName: any) => opdName !== null);
    thingHtml += `${imageUrl || skipBR ? '' : '<br>'}<strong>${thingType} OPDs:</strong><div class="indented">${opds.length > 0 ? opds.join('<br>') : 'N/A'}</div>`;

    if (entry.states && entry.states.length > 0) {
      const states = entry.states.map((state: any) => {
        let stateText = state._text;
        if (state.URLarray?.length > 0 && state.URLarray[0].url !== 'http://') {
          stateText += ` (URLs: ${state.URLarray.map((item: any) => item.url).join(', ')})`;
        }
        if (this.includeEntitiesDescription && state.description?.trim()) {
          stateText += ` (Description: ${state.description})`;
        }
        return stateText;
      });
      thingHtml += `<strong>${thingType} States:</strong><div class="indented" style="color: #808000;">${states.join('<br>')}</div>`;
    }

    return thingHtml;
  }

  private generateRelationSection(title: string, relationMap: Map<string, any[]>, addHeader: boolean = false): string {
    let relationHtml = `<h3>${title}:</h3><div class="indented">`;

    if (addHeader) {
      relationHtml += `<p><strong>Source Name → Target(s) Name</strong></p>`;
    }

    relationMap.forEach((links, linkName) => {
      relationHtml += `<strong>${linkName}:</strong><ul>`;
      links.forEach(link => {
        relationHtml += `<li>${link[0]} → ${link[1].join(', ')}</li>`;
      });
      relationHtml += `</ul>`;
    });
    relationHtml += `</div>`;
    return relationHtml;
  }

  private getRelationData(entry: any, relationMap: Map<string, any[]>): void {
    const linkArray = [];
    linkArray.push(entry.sourceLogicalElement.text.replace('\n', ' '));

    const targetArray = [];
    for (let target of entry.targetLogicalElements) {
      if (target) {
        targetArray.push(target.text.replace('\n', ' '));
      }
    }
    linkArray.push(targetArray);

    const linkName = linkType[entry.linkType];
    if (!relationMap.has(linkName)) {
      relationMap.set(linkName, []);
    }
    relationMap.get(linkName).push(linkArray);
  }

  private insertImageAndNameHtml(imageData: string, opdName: string, oplHtml: string): string {
    const imgTag = `<img src="${imageData}" alt="${opdName} Diagram">`;
    return `
        <div class="section" id="${opdName.replace(/\s+/g, '_')}">
            <h3>${opdName}</h3>
            <div class="image">${imgTag}</div>
            <h4>OPL</h4>${oplHtml}
        </div>`;
  }

}
