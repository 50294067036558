import {Essence, linkType} from '../../models/ConfigurationOptions';
import {OpmVisualThing} from '../../models/VisualPart/OpmVisualThing';

const Name_To_Enum = {
  'Classification-Instantiation':linkType.Instantiation,
  'Exhibition-Characterization':linkType.Exhibition,
  'Generalization-Specialization':linkType.Generalization,
  'Aggregation-Participation':linkType.Aggregation
};
const defaultObjToReturn = {
  isValidLink: true,
  errorMessage: undefined,
  targetEssence: undefined
};
const defaultErrorMessage = "This link is not permitted.";

export const LinkConstraints = {
  isValidLink(link, linkName,initRappid){
    let source = this.getElementFromLink(link,initRappid,'source');
    let target = this.getElementFromLink(link,initRappid,'target');
    const visualElementTarget = <OpmVisualThing>(initRappid.opmModel.getVisualElementById(target.attributes.id));
    if(!source || !target){
      return defaultObjToReturn;
    }
    if(!((["OpmObject","OpmProcess"].indexOf(source.constructor.name)>-1)&&(["OpmObject","OpmProcess"].indexOf(target.constructor.name)>-1))) {
      return defaultObjToReturn;
    }
    const targetType = target.constructor.name.slice(3,).toLowerCase();
    let current_essence = this.isHaveTobeEssenceThing(target.id,targetType,initRappid);
    let newlink_essence = this.isHaveTobeEssenceLink(link,linkName,initRappid);
    const finalDecision = this.finalDecision(current_essence, newlink_essence);
    let error = this.generateErrorMessage(target);
    const finalTargetEssence = this.getFinalTargetEssence(linkName,source,target,finalDecision,newlink_essence,current_essence);
    let ans = {
      isValidLink: finalDecision,
      errorMessage: error,
      targetEssence: finalTargetEssence
    };
    if (ans.isValidLink) {
      if (ans.targetEssence === Essence.Informatical) {
        // this.changeEssenceToInformatical(initRappid, target.id, targetType, target.getEssence());
        target.toggleEssence(visualElementTarget);
        if(target.getEssence() !== Essence.Informatical) {
          ans.isValidLink = false;
          ans.errorMessage = defaultErrorMessage;
        }
      } else {
        if (ans.targetEssence === Essence.Physical) {
          // this.changeEssenceToPhysical(initRappid, target.id, targetType, target.getEssence());
          target.toggleEssence(visualElementTarget);
          if (target.getEssence() !== Essence.Physical) {
            ans.isValidLink = false;
            ans.errorMessage = defaultErrorMessage;
          }
        }
      }
    }

    return ans;
  },

  isHaveTobeEssence(links, targetType){
    let essence = new Set();
    for (let link of links.in) {
      let link_e = this.linkTargetEssence(link.linkType, link.source.type, link.source.essence, targetType);
      if (link_e !== undefined) {
        essence.add(link_e);
      }
    }
    return essence;
  },

  isHaveTobeEssenceLink(link,link_type,initRappid){
    const source = this.getElementFromLink(link,initRappid,'source');
    const target = this.getElementFromLink(link,initRappid,'target');
    const targetType = target.attributes.type.slice(4,).toLowerCase();
    const sourceType = source.attributes.type.slice(4,).toLowerCase();
    let this_link_attrs = {
      "in": [{
        "linkType": Name_To_Enum[link_type],
        "source": {
          "type": sourceType,
          "essence": source.getEssence()
        }
      }]
    };
    let essence = this.isHaveTobeEssence(this_link_attrs,targetType);
    essence = Array.from(essence.values());
    return essence;
  },

  isHaveTobeEssenceThing(targetElement_id,targetType,initRappid){
    let connected_links = this.getThingAllStructuralLinks(initRappid,targetElement_id);
    let essence = this.isHaveTobeEssence(connected_links, targetType);
    let logicalCell = initRappid.opmModel.getLogicalElementByVisualId(targetElement_id);
    if((targetType==='object' && logicalCell._value !== "None")||(targetType === 'process' && logicalCell.insertedFunction!=="None")){
      essence.add(Essence.Informatical);
    }
    essence = Array.from(essence.values());
    return essence;
  },

  linkTargetEssence(LinkType, sourceType, sourceEssence, targetType){
    if (sourceType === 'process' && targetType === 'process') {
      if (LinkType === linkType.Instantiation) {
        return sourceEssence;
      }
    }
    if (sourceType === 'object' && targetType === 'object') {
      if (LinkType === linkType.Exhibition) {
        return Essence.Informatical;
      }
      if (LinkType === linkType.Aggregation) {
        if (sourceEssence === Essence.Informatical) {
          return Essence.Informatical;
        }
      }
      if (LinkType === linkType.Generalization) {
        if (sourceEssence === Essence.Informatical) {
          return Essence.Informatical;
        }
      }
      if (LinkType === linkType.Instantiation) {
        return sourceEssence;
      }
    }
    if (sourceType === 'process' && targetType === 'object') {
      if (LinkType === linkType.Exhibition) {
        if (sourceEssence === Essence.Informatical) {
          return Essence.Informatical;
        }
      }
    }
  },

  finalDecision(curr_e, new_e){
    if (new_e.length > 1) { // TODO: handle this case.
      return true;
    }
    if (curr_e.length > 1) { // TODO: handle this case, it is currently false because of link dragging.
      return false;
    }
    if (new_e.length === 0 || curr_e.length === 0) {
      return true;
    }
    new_e = new_e[0];
    curr_e = curr_e[0];
    return new_e === curr_e;
  },

  getTargetDefaultEssence(link_type,source,target){
    const source_type = source.constructor.name;
    const target_type = target.constructor.name;
    if ((target_type === "OpmProcess")&&(source_type === "OpmProcess")){
      if(['Exhibition-Characterization','Aggregation-Participation','Generalization-Specialization','Classification-Instantiation'].indexOf(link_type)>-1){
        return source.getEssence();
      }
    }
    if ((target_type === "OpmObject")&&(source_type === "OpmObject")){
      if(['Aggregation-Participation','Generalization-Specialization','Classification-Instantiation'].indexOf(link_type)>-1){
        return source.getEssence();
      }
      if(link_type === 'Exhibition-Characterization'){
        return Essence.Informatical;
      }
    }
    if((source_type === "OpmProcess")&&(target_type === "OpmObject")){
      if(link_type === 'Exhibition-Characterization'){
        return Essence.Informatical;
      }
    }
    if((source_type === "OpmObject")&&(target_type === "OpmProcess")){
      if(link_type === 'Exhibition-Characterization'){
        return source.getEssence();
      }
    }
    return undefined;
  },

  getFinalTargetEssence(link_type,source,target,finalDecision,newlink_essence,current_essence){
    if(!finalDecision){
      return undefined;
    }
    let essences = new Set(newlink_essence.concat(current_essence));
    if(essences.size>1){
      return undefined;
    }
    if(essences.size === 1){
      return essences.values().next().value;
    }
    return this.getTargetDefaultEssence(link_type,source,target);

  },

  getConnectedStructuralLinks(initRappid, opd, thing_id, links_in, links_out) {
    let structuralLinks=opd.getThingStructuralLinks(thing_id);
    for(let i = 0; i < structuralLinks.length; i++ ){
      if(structuralLinks[i].targetVisualElements[0].targetVisualElement.id === thing_id){
        const source = initRappid.graph.getCell(structuralLinks[i].id).sourceElement;
        let link_attrs={
          "linkType":structuralLinks[i].logicalElement.linkType,
          "source":{
            "type" : structuralLinks[i].sourceVisualElement.constructor.name.slice(9, ).toLowerCase(),
            "essence" : source.get('type').includes('State') ? source.getParentCell().getEssence() : source.getEssence(),
          }
        };
        links_in.push(link_attrs);
      }
      if(structuralLinks[i].sourceVisualElement.id === thing_id){
        const target = initRappid.graph.getCell(structuralLinks[i].id).targetElement;
        const source = initRappid.graph.getCell(structuralLinks[i].id).sourceElement;
        let link_attrs={
          "linkType":structuralLinks[i].logicalElement.linkType,
          "target":{
            "type":structuralLinks[i].targetVisualElements[0].targetVisualElement.constructor.name.slice(9, ).toLowerCase(),
            "essence": target.getEssence(),
            "id": structuralLinks[i].targetVisualElements[0].targetVisualElement.id
          },
          "source":{
            "type":structuralLinks[i].sourceVisualElement.constructor.name.slice(9, ).toLowerCase(),
            "essence": source.getEssence()
          }
        };
        links_out.push(link_attrs);
      }
      //console.log(structuralLinks[i]);
      /*if(structuralLinks[i].sourceVisualElement.id === thing.id){
        links_out.push(structuralLinks[i].logicalElement.linkType)
      }*/
    }
  },

  // getConnectedStructuralLinks(opd, thing_id, links_in, links_out) {
  //   let structuralLinks=opd.getThingStructuralLinks(thing_id);
  //   for(let i = 0; i < structuralLinks.length; i++ ){
  //     if(structuralLinks[i].targetVisualElements[0].targetVisualElement.id === thing_id){
  //       let link_attrs={
  //         "linkType":structuralLinks[i].logicalElement.linkType,
  //         "source":{
  //           "type":structuralLinks[i].sourceVisualElement.constructor.name.slice(9, ).toLowerCase(),
  //           "essence":structuralLinks[i].sourceVisualElement.logicalElement.essence
  //         }
  //       };
  //       links_in.push(link_attrs);
  //     }
  //     if(structuralLinks[i].sourceVisualElement.id === thing_id){
  //       let link_attrs={
  //         "linkType":structuralLinks[i].logicalElement.linkType,
  //         "target":{
  //           "type":structuralLinks[i].targetVisualElements[0].targetVisualElement.constructor.name.slice(9, ).toLowerCase(),
  //           "essence":structuralLinks[i].targetVisualElements[0].targetVisualElement.logicalElement.essence,
  //           "id": structuralLinks[i].targetVisualElements[0].targetVisualElement.id
  //         },
  //         "source":{
  //           "type":structuralLinks[i].sourceVisualElement.constructor.name.slice(9, ).toLowerCase(),
  //           "essence":structuralLinks[i].sourceVisualElement.logicalElement.essence
  //         }
  //       };
  //       links_out.push(link_attrs);
  //     }
  //     //console.log(structuralLinks[i]);
  //     /*if(structuralLinks[i].sourceVisualElement.id === thing.id){
  //       links_out.push(structuralLinks[i].logicalElement.linkType)
  //     }*/
  //   }
  // },

  getThingAllStructuralLinks(initRappid,targetElement_id){
    let links_in =[];
    let links_out = [];
    this.getConnectedStructuralLinks(initRappid,initRappid.opmModel.getOpdByThingId(targetElement_id),targetElement_id,links_in,links_out);
    if(initRappid.opmModel.getVisualElementById(targetElement_id).refineable){
      let refinee = initRappid.opmModel.getVisualElementById(targetElement_id).refineable;
      this.getConnectedStructuralLinks(initRappid,initRappid.opmModel.getOpdByThingId(refinee.id),refinee.id,links_in,links_out);
    }
    if(initRappid.opmModel.getVisualElementById(targetElement_id).refineeInzooming){
      let refinee=initRappid.opmModel.getVisualElementById(targetElement_id).refineeInzooming;
      this.getConnectedStructuralLinks(initRappid,initRappid.opmModel.getOpdByThingId(refinee.id),refinee.id,links_in,links_out);
    }
    if(initRappid.opmModel.getVisualElementById(targetElement_id).refineeUnfolding){
      let refinee=initRappid.opmModel.getVisualElementById(targetElement_id).refineeUnfolding;
      this.getConnectedStructuralLinks(initRappid,initRappid.opmModel.getOpdByThingId(refinee.id),refinee.id,links_in,links_out);
    }
    return {"in":links_in,"out":links_out};
  },

  generateErrorMessage(target){
    let target_type = target.constructor.name;
    let name = target.attributes.attrs.text.textWrap.text;
    if(target_type==="OpmObject"){
      return "An instance of an object must have the same essence as its class."
    }
    if(target_type==="OpmProcess"){
      return "An instance of a process must have the same essence as its class.";
    }
    return defaultObjToReturn.errorMessage;
  },

  // setEssence(element,initRappid){
  //   const curr_essence = element.getEssence();
  //   const type =  element.constructor.name.slice(3,).toLowerCase();
  //   let ans = defaultObjToReturn;
  //   if(curr_essence === Essence.Physical){
  //     this.changeEssenceToInformatical(initRappid,element.id,type,curr_essence);
  //   }
  //   if(curr_essence === Essence.Informatical){
  //     ans = this.changeEssenceToPhysical(initRappid,element.id,type,curr_essence);
  //   }
  //   return ans;
  // },
  // changeEssenceToPhysical(initRappid,id,type,curr_essence){
  //   if(curr_essence === Essence.Physical){
  //     return;
  //   }
  //   let essences = this.isHaveTobeEssenceThing(id,type,initRappid);
  //   if(essences.indexOf(Essence.Informatical)>-1){
  //     return {errorMessage:"Error"};
  //   }
  //   let all_instances = new Set();
  //   all_instances.add(id);
  //   this.getAllInstances(initRappid,all_instances,all_instances);
  //   let thisProcess = this;
  //   let changed = new Set();
  //   all_instances.forEach(function (id) {
  //     if(thisProcess.updateEssenceByID(id,initRappid,Essence.Physical)){
  //       changed.add(id);
  //     }
  //   });
  //   if(!this.checkEssenceForGroup(all_instances,initRappid,Essence.Informatical)){
  //     changed.forEach(function (id) {
  //       thisProcess.updateEssenceByID(id,initRappid,Essence.Informatical);
  //     });
  //   }
  //
  //
  // },
  getAllInstances(initRappid,all_instances,sons){
    if(sons.size === 0){
      return;
    }
    let thisProcess = this;
    let new_sons = new Set();
    sons.forEach(function (id) {
      all_instances.add(id);
      let links = thisProcess.getThingAllStructuralLinks(initRappid,id);
      links.out.forEach(function (link) {
        if(all_instances.has(link.target.id) || sons.has(link.target.id)){
          return;
        }
        if(link.linkType === linkType.Instantiation){
          new_sons.add(link.target.id);
        }
      });
    });
    return this.getAllInstances(initRappid,all_instances,new_sons);
  },
  // changeEssenceToInformatical(initRappid,id,type,curr_essence){
  //   if(curr_essence === Essence.Informatical){
  //     return;
  //   }
  //   let essences = this.isHaveTobeEssenceThing(id,type,initRappid);
  //   if(essences.indexOf(Essence.Physical)>-1){
  //     return {errorMessage:"Error"};
  //   }
  //   let info_things = new Set();
  //   info_things.add(id);
  //   this.getAllinfoThings(initRappid,info_things,info_things);
  //   let thisProcess = this;
  //   let changed = new Set();
  //   info_things.forEach(function (id) {
  //     if(thisProcess.updateEssenceByID(id,initRappid,Essence.Informatical)){
  //       changed.add(id);
  //     }
  //   });
  //   if(!this.checkEssenceForGroup(info_things,initRappid,Essence.Physical)){
  //     changed.forEach(function (id) {
  //       thisProcess.updateEssenceByID(id,initRappid,Essence.Physical);
  //     });
  //   }
  //
  // },
  getAllinfoThings(initRappid,all_info,sons){
    if(sons.size === 0){
      return;
    }
    let thisProcess = this;
    let new_sons = new Set();
    sons.forEach(function (id) {
      all_info.add(id);
      let links = thisProcess.getThingAllStructuralLinks(initRappid,id);
      links.out.forEach(function (link) {
        if(all_info.has(link.target.id) || sons.has(link.target.id)){
          return;
        }
        if(link.target.type === 'object' && link.source.type === 'object'){
          if([linkType.Instantiation,linkType.Aggregation,linkType.Generalization].indexOf(link.linkType)>-1){
            new_sons.add(link.target.id);
          }
        }
        if(link.target.type === 'process' && link.source.type === 'process'){
          if(link.linkType === linkType.Instantiation){
            new_sons.add(link.target.id);
          }
        }
        if(link.target.type === 'object' && link.source.type === 'process'){
          if(link.linkType === linkType.Exhibition){
            new_sons.add(link.target.id);
          }
        }
      });
    });
    return this.getAllinfoThings(initRappid,all_info,new_sons);
  },

  // updateEssenceByID(id,initRappid,essence){
  //   let logicalCell = initRappid.opmModel.getLogicalElementByVisualId(id);
  //   if(logicalCell.essence === essence){
  //     return false;
  //   }
  //   let cell = initRappid.graph.getCell(id);
  //   if(cell !==undefined){
  //     cell.updateEssence(essence);
  //     return true;
  //   }
  //   logicalCell.essence = essence;
  //   return true;
  //
  // },
  // checkEssenceForGroup(id_set,initRappid,oppositeChangedEssence){
  //   let thisProcess = this;
  //   let ans = true;
  //   id_set.forEach(function (id) {
  //     let type = initRappid.opmModel.getVisualElementById(id).constructor.name.slice(9,).toLowerCase();
  //     let essences = thisProcess.isHaveTobeEssenceThing(id,type,initRappid);
  //     if(essences.indexOf(oppositeChangedEssence)>-1){
  //       ans = false;
  //     }
  //   });
  //   return ans;
  // },
  CanBeComputational(id, initRappid, thing) {
    const visualCell = initRappid.opmModel.getVisualElementById(id);
    let cellType = visualCell.constructor.name.slice(9,).toLowerCase();
    let essences = this.isHaveTobeEssenceThing(id,cellType,initRappid);
    if(essences.length === 1 && essences[0] === Essence.Physical){
      return false;
    }
    // this.changeEssenceToInformatical(initRappid,id,cellType,visualCell.logicalElement.essence);
    // thing.toggleEssence(visualCell);
    //
    // if(thing.getEssence() === Essence.Informatical) {
    //   return true;
    // }
    // return false;
    if (!thing.CanBeComputational()) 
      thing.toggleEssence(visualCell);
    return thing.CanBeComputational();
  },

  getElementFromLink(link, initRappid, type) {
    try{
      type = type.toLowerCase();
      if(type === 'source'){
        let source = link.getSourceElement();
        if(!source){
          return null;
        }
        if(source.constructor.name === "TriangleClass"){
          return initRappid.graph.getCell(link.id).sourceElement;
        }
        return source;
      }
      if(type === 'target'){
        let target = link.getTargetElement();
        if(!target){
          return null;
        }
        if(target.constructor.name === "TriangleClass"){
          return initRappid.graph.getCell(link.id).targetElement;
        }
        return target;
      }
    }
    catch(e){
      console.log(e);
      return null;
    }
  }
};

export const legalConnections = {
  'OO' : [linkType.Bidirectional, linkType.Unidirectional, linkType.Instantiation, linkType.Generalization, linkType.Aggregation, linkType.Exhibition],
  'PP' : [linkType.Bidirectional, linkType.Unidirectional, linkType.Instantiation, linkType.Generalization, linkType.Aggregation, linkType.Exhibition, linkType.Invocation, linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.UndertimeException],
  'PO' : [linkType.Exhibition, linkType.Result, linkType.Effect],
  'OP' : [linkType.Exhibition, linkType.Agent, linkType.Instrument, linkType.Consumption, linkType.Effect],
  'SP' : [linkType.Exhibition, linkType.Agent, linkType.Instrument, linkType.Consumption, linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.UndertimeException],
  'PS' : [linkType.Result],
  'SO' : [linkType.Aggregation, linkType.Exhibition, linkType.Unidirectional, linkType.Bidirectional],
  'OS' : [linkType.Aggregation, linkType.Exhibition, linkType.Unidirectional, linkType.Bidirectional],
  'SS' : [linkType.Unidirectional, linkType.Bidirectional]
};

// from strongest to weakest
export const linksStrength = {
  'fundamental' : [linkType.Aggregation, linkType.Generalization, linkType.Exhibition, linkType.Instantiation],
  'procedural' : [linkType.Effect, linkType.Consumption, linkType.Result, linkType.Instrument, linkType.Agent],
  'tagged' : [linkType.Bidirectional, linkType.Unidirectional],
};

