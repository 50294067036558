import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
import {OntologyEnforcementLevel} from "../../modules/Settings/OrgOntology/ontologyInterfaces";

@Component ({
  selector: 'ontology-suggestion-dialog',
  templateUrl: 'ontology-suggestion-dialog.html',
  styleUrls: ['ontology-suggestion-dialog.css']
})
export class OntologySuggestionDialog implements OnInit {

  constructor(@Optional() public dialogRef: MatDialogRef<OntologySuggestionDialog>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.dialogRef.disableClose = this.shouldEnforce();
  }

  shouldEnforce(): boolean {
    return this.data.enforcementLevel === OntologyEnforcementLevel.FORCE;
  }

  ngOnInit() {
  }

  replaceWithSuggestion(option: string) {
    this.dialogRef.close(option);
  }
}
