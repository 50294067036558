import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {StorageService} from "../../rappid-components/services/storage.service";
import {PopupMessageData} from "../create-popup-message/popup.message.interface";
import {DatabaseService} from "../../database/database.service";
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'opcloud-popup-message-content',
  templateUrl: 'popup-message-content.component.html',
  styleUrls: ['./popup-message-content.component.css']
})
export class PopupMessageContentComponent implements OnInit {

  private type: 'org' | 'system';
  private message: PopupMessageData;
  private remindMeLater: boolean;
  private confirmation: boolean;
  private mode: 'preview' | 'showToUser';
  private showConfirmationRequiredWarning = false;

  constructor(private database: DatabaseService, @Inject(MAT_DIALOG_DATA) private data: any,
              @Optional() public dialogRef: MatDialogRef<PopupMessageContentComponent>, private _sanitizer: DomSanitizer) {
    this.type = data.type;
    this.message = data.message;
    this.remindMeLater = true;
    this.confirmation = false;
    this.mode = data.mode || 'showToUser';
    this.sanitizeHtml();
  }

  ngOnInit() {
    if (this.mode !== 'preview')
      this.markMessageAsSeen()

    if (!this.message.hasToConfirm) {
      return this.dialogRef.disableClose = false;
    }
  }

  sanitizeHtml() {
    this.message.content = this._sanitizer.bypassSecurityTrustHtml(this.message.content as string);
  }

  markMessageAsSeen() {
    if (this.mode !== 'preview')
      this.database.driver.markPopupMessageAsSeen(this.type, this.message.id);
  }

  markMessageAsConfirmed() {
    if (this.mode !== 'preview') {
      if (!this.confirmation && this.message.hasToConfirm) {
        this.showConfirmationRequiredWarning = true;
        return;
      }
      return this.database.driver.markMessageAsConfirmed(this.type, this.message.id, this.remindMeLater).then(() => this.dialogRef.close());
    } else {
      this.dialogRef.close();
    }
  }

  signOut() {
    this.dialogRef.close('signout');
  }

  getAcceptButtonText(): string {
    if (!this.message.hasToConfirm)
      return 'Accept';
    return 'Ok';
  }

}

