import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ModelService } from '../modules/app/model.service';
import { OplService } from '../opl-generation/opl.service';
import { User } from '../rappid-components/services/authentication/authentication.service';
import { InitRappidService } from '../rappid-components/services/init-rappid.service';
import { OrganizationService } from '../rappid-components/services/organization.service';
import { UserService } from '../rappid-components/services/user.service';
import { ErrorLog } from './error-log.interface';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandlerService {

  sendErrors = false;
  user$: Observable<User>;
  orgS: any;
  private last5Errors: Array<string>;

  constructor(private orgService: OrganizationService, private userService: UserService, public modelService: ModelService, private readonly init: InitRappidService, private oplService: OplService) {
    this.user$ = this.userService.user$;
    this.orgS = this.userService.userOrg;
    this.last5Errors = [];
   }

  private organizationWantsLogs() {
    return this.oplService.orgOplSettings.logCollectingEnabled;
  }

  private organizationLogsMandatory() {
    return this.oplService.orgOplSettings.ignoreUserLogSharingPermission;
  }

  private userSendsLogs() {
    console.log(`userSendsLogs: ${this.userService.user.userData.opl.logSharingPermission}`)
    return this.userService.user.userData.opl.logSharingPermission;
  }

  private shouldSendLog() {
    const shouldSendLog = this.organizationWantsLogs() && (this.organizationLogsMandatory() || this.userSendsLogs());
    // console.log(`should send log: ${shouldSendLog}`);
    const dev = window.origin !== 'http://localhost:4200';
    return shouldSendLog && dev;
  }

  private getLastSteps() {
    const undoStack = this.init.getOpmModel().undoRedo.getUndoStack()
    const last5 = undoStack.slice(Math.max(undoStack.length-5, 0)).map(op => op ? op.reason : '');
    return JSON.stringify(last5);
  }

  public logError(error:string) {
    console.error(error);

    if (this.shouldIgnore(error))
      return;

    let jsonModel;
    if (this.init.getOpmModel().undoRedo.getLastUndoOpertaion()?.json) {
      jsonModel = JSON.stringify(this.init.getOpmModel().undoRedo.getLastUndoOpertaion().json)
    } else {
        try {
          jsonModel = JSON.stringify(this.init.getOpmModel().toJson());
        } catch(err) {
          jsonModel = '';
        }
    }

    const selectedElementName = this.init.selectedElement?.attributes?.attrs?.text?.textWrap?.text;
    if (selectedElementName)
      error += '\n selected element: ' + selectedElementName;

    const currentOpdName = this.init.opmModel.currentOpd?.getName();
    if (currentOpdName)
      error += '\n Current OPD: ' + currentOpdName;

    let lastOperations = [...(this.init.opmModel.lastOperations || [])];
    // last 50 operations.
    if (lastOperations.length > 50)
      lastOperations = lastOperations.slice(lastOperations.length - 50)

    if (lastOperations.length > 0)
      error += '\n\n' + lastOperations.filter(str => typeof str === 'string').join(' -> ');

    const logInfo:ErrorLog = {
      userId: this.userService?.user?.uid,
      userName: this.userService?.user?.userData?.Name,
      userOrganization: this.userService?.user?.userData?.organization,
      modelName: this.modelService?.displayName,
      modelId: this.modelService?.modelObject?.id,
      modelPath: this.modelService?.modelObject?.path,
      errorMessage: error,
      steps: this.getLastSteps(),
      timestamp: new Date().toLocaleString(),
      json: jsonModel,
    }

    this.addLastErrorToLast5(error);

    if (this.shouldSendLog())
      this.orgService.addLog(logInfo)

  }

  private shouldIgnore(error: string) {
    if (this.last5Errors.find(err => err.includes(error)))
      return true;
    for (const err of this.getErrorsToIgnore())
      if (error.includes(err))
        return true;
    return false;
  }

  getErrorsToIgnore() {
    return [
      '404',
      'Cannot POST',
      'Failed to load resource',
      'status of 500',
      'ERR_CONNECTION_REFUSED',
      'Http failure response',
      'user_cancelled',
      'BrowserAuthError',
      'interaction_in_progress',
      'There is no interval scheduled',
      'ExpressionChangedAfterItHasBeenCheckedError',
      'auth/multi-factor-auth-required',
      'auth/wrong-password',
      'auth/network-request-failed'
    ];
  }

  private addLastErrorToLast5(error: string) {
    this.last5Errors.push(error);
    if (this.last5Errors.length > 5)
      this.last5Errors = this.last5Errors.slice(1);
  }
}
