import { OpmVisualElement } from './OpmVisualElement';
import { OpmOpd } from '../OpmOpd';
import { OpmRelation } from "../LogicalPart/OpmRelation";
import { linkType } from '../ConfigurationOptions';
import { OpmVisualEntity } from './OpmVisualEntity';
import {fundamental} from '../consistency/links.set';

export class OpmLink extends OpmVisualElement {
  sourceVisualElement: OpmVisualElement;
  sourceVisualElementPort;
  sourceConnectedLinks: Array<OpmLink>;

  public get source(): OpmVisualEntity {
    return this.sourceVisualElement as OpmVisualEntity;
  }

  public get target(): OpmVisualEntity {
    return this.targetVisualElements[0].targetVisualElement as OpmVisualEntity;
  }

  targetVisualElements: Array<TargetElementData>;

  targetVisualElementPort;
  targetConnectedLinks: Array<OpmLink>;
  BreakPoints: Array<any>;
  tag: string;
  labels;
  visible: boolean;
  showRequirementsLabel: boolean;

  constructor(params, logicalElement) {
    super(params, logicalElement);
    this.sourceVisualElementPort = params ? params.sourceVisualElementPort : null;
    this.sourceConnectedLinks = params ? params.sourceConnectedLinks : new Array<OpmLink>();
    this.targetVisualElementPort = params ? params.targetVisualElementPort : null;
    this.targetConnectedLinks = params ? params.targetConnectedLinks : new Array<OpmLink>();
    this.showRequirementsLabel = params?.hasOwnProperty('showRequirementsLabel') ? params.showRequirementsLabel : true;
  }

  get type(): Readonly<linkType> {
    return (<OpmRelation<OpmLink>>this.logicalElement).linkType;
  }

  isLink() {
    return true;
  }

  isStructuralLink() {
    return false;
  }

  isProceduralLink() {
    return false;
  }

  isFundamentalLink() {
    return false;
  }

  updateParams(params) {
    super.updateParams(params);
    this.targetVisualElements = new Array<TargetElementData>();
    const sourceVisualElement = this.logicalElement.opmModel.getVisualElementById(params.sourceElementId);
    this.sourceVisualElement = sourceVisualElement ? sourceVisualElement : params.sourceElementId;
    this.sourceVisualElementPort = params.sourceVisualElementPort;
    let targetVisualElement = this.logicalElement.opmModel.getVisualElementById(params.targetElementId);
    targetVisualElement = targetVisualElement ? targetVisualElement : params.targetElementId;
    if (params.targetVisualElements && params.targetVisualElements[0]?.vertices)
      this.BreakPoints = params.targetVisualElements[0].vertices;
    if (params.vertices)
      this.BreakPoints = params.vertices;
    this.targetVisualElements.push(new TargetElementData(targetVisualElement, this.BreakPoints));
    this.targetVisualElementPort = params.targetVisualElementPort;
    this.tag = params.tag;
    this.labels = params.labels?.filter(label => !!label);
    this.visible = params.visible;
    this.showRequirementsLabel = params.showRequirementsLabel;
  }

  setParams(params) {
    super.setParams(params);
    this.targetVisualElements[0].vertices = params.vertices;
    this.BreakPoints = params.vertices;
    this.tag = params.tag;
    this.labels = params.labels;
    this.visible = params.visible;
    this.showRequirementsLabel = params.hasOwnProperty('showRequirementsLabel') ? params.showRequirementsLabel : true;
  }

  getLinkParams() {
    const targetVisualElements = new Array<TargetElementData>();
    for (let i = 0; i < this.targetVisualElements.length; i++) {
      if (this.targetVisualElements[i].targetVisualElement) {
        const targetElementData = new TargetElementData(this.targetVisualElements[i].targetVisualElement.id, this.targetVisualElements[i].vertices);
        targetVisualElements.push(targetElementData);
      }
    }
    const params = {
      sourceVisualElement: this.sourceVisualElement ? this.sourceVisualElement.id : null,
      sourceVisualElementPort: this.sourceVisualElementPort,
      sourceConnectedLinks: this.logicalElement.opmModel.arrayFromElementsToIds(this.sourceConnectedLinks),
      targetVisualElements: targetVisualElements,
      targetVisualElementPort: this.targetVisualElementPort,
      targetConnectedLinks: this.logicalElement.opmModel.arrayFromElementsToIds(this.targetConnectedLinks),
      tag: this.tag,
      labels: this.labels,
      strokeWidth: this.strokeWidth,
      strokeColor: this.strokeColor,
      visible: this.visible,
      showRequirementsLabel: this.showRequirementsLabel,
    };
    return { ...super.getElementParams(), ...params };
  }
  getLinkParamsFromJsonElement(jsonElement) {
    const params = {
      sourceElementId: jsonElement.sourceVisualElement,
      sourceVisualElementPort: jsonElement.sourceVisualElementPort,
      sourceConnectedLinks: jsonElement.sourceConnectedLinks,
      targetElementId: (jsonElement.targetVisualElements) ? jsonElement.targetVisualElements[0].targetVisualElement : null,
      targetVisualElementPort: jsonElement.targetVisualElementPort,
      targetConnectedLinks: jsonElement.targetConnectedLinks,
      vertices: (jsonElement.targetVisualElements) ? jsonElement.targetVisualElements[0].vertices : null,
      tag: jsonElement.tag,
      labels: jsonElement.labels,
      showRequirementsLabel: jsonElement.showRequirementsLabel,
      strokeWidth: jsonElement.strokeWidth,
      strokeColor: jsonElement.strokeColor
    };
    return { ...super.getElementParamsFromJsonElement(jsonElement), ...params };
  }
  // in case instead of a reference to an object there is a string (representing object's id),
  // replace the id with the reference to object
  updateSourceAndTargetFromJson() {
    if (typeof this.sourceVisualElement === 'string') {
      this.sourceVisualElement = this.logicalElement.opmModel.getVisualElementById(this.sourceVisualElement);
    }
    for (let i = 0; i < this.targetVisualElements.length; i++) {
      if (typeof this.targetVisualElements[i].targetVisualElement === 'string') {
        this.targetVisualElements[i].targetVisualElement = this.logicalElement.opmModel.getVisualElementById(this.targetVisualElements[i].targetVisualElement);
      }
    }
  }

  /*updateSourceAndTargetConnectedLinksFromJson() {
    if (this.sourceConnectedLinks) {
      const linksArray = this.logicalElement.opmModel.arrayFromIdsToElements(this.sourceConnectedLinks);
      this.sourceConnectedLinks = <OpmLink[]>linksArray;
    }
    if (this.targetConnectedLinks) {
      const linksArray = this.logicalElement.opmModel.arrayFromIdsToElements(this.targetConnectedLinks);
      this.targetConnectedLinks = <OpmLink[]>linksArray;
    }
  }*/

  // Clone current visual and add to (current) opd.
  // Can be changed in the future, without the dependency in OpmOpd, if we will render the graph differently.
  // Attaches both ends.
  copyToOpd(opd: OpmOpd, sourceCopy: OpmVisualElement, targetCopy: OpmVisualElement): OpmLink {
    const copy = this.clone();
    opd.visualElements.push(copy);
    copy.sourceVisualElement = sourceCopy;
    copy.targetVisualElements[0].targetVisualElement = targetCopy;
    return copy;
  }

  public canBeRemoved(): boolean {
    if (this.belongsToFatherModelId) {
      return false;
    }
    if ((<any>this.source.logicalElement).isWaitingProcess || (<any>this.target.logicalElement).isWaitingProcess)
      return false;
    return true;
  }

  public remove(): ReadonlyArray<OpmVisualElement> {
    let target = this.target;
    const type = this.type;
    const model = this.logicalElement.opmModel;
    const opd = model.getOpdByThingId(this.id);
    const ret = super.remove();
    if (this.logicalElement.visualElements.length === 0) {
      this.logicalElement.opmModel.removeLogicalElement(this.logicalElement);
      if (target.constructor.name.includes('State'))
        target = target.fatherObject;
      // if fundamental link is removed in opd B and in another opd the relation is folded => remove it.
      if (fundamental.contains(type) && (target.constructor.name.includes('Object') || target.constructor.name.includes('Process')) && target.logicalElement.visualElements.find
        (vis => model.getOpdByThingId(vis.id) !== opd && (<any>vis).isFoldedUnderThing().isFolded === true && (<any>vis).isFoldedUnderThing().triangleType === this.type)) {
        const semifolded = target.logicalElement.visualElements.find(vis => model.getOpdByThingId(vis.id) !== opd && (<any>vis).isFoldedUnderThing().isFolded === true &&
          (<any>vis).isFoldedUnderThing().triangleType === this.type);
        const father = (<any>semifolded).fatherObject;
        father.removeThingFromSemiFoldedArray(semifolded);
        semifolded.remove();
        father.arrangeInnerSemiFoldedThings();
      }
    }
    return ret;
  }

  public setReferencesFromJson(json: any, map): void {
    this.sourceVisualElement = map.get(json.sourceVisualElement);
    this.targetVisualElements[0].targetVisualElement = map.get(json.targetVisualElements[0].targetVisualElement);
  }

  public setReferencesOnCreate(): void {
    (<OpmRelation<OpmLink>>this.logicalElement).sourceLogicalElement = this.sourceVisualElement.logicalElement;
    (<OpmRelation<OpmLink>>this.logicalElement).targetLogicalElements = [this.targetVisualElements[0].targetVisualElement.logicalElement];
  }

  public getHaloCommands() {
    return [];
  }

  public getToolbarCommands() {
    return [];
  }

  removeRequirementsLabel() {
    this.labels = this.labels || [];
    for (let i = this.labels.length - 1 ; i >= 0 ; i--) {
      if (this.labels[i].attrs.label.text.startsWith('Satisfied: ')) {
        this.labels.splice(i, 1);
        return;
      }
    }
  }
}
/*
 TargetElementData contains the target element and an array of vertices on the connection
 that gets to it. In case of fundamental link it will be all the vertices from the
 triangle to the target, in any other case it will be the vertices from the source to the
 target.
 */
export class TargetElementData {
  targetVisualElement: OpmVisualElement;
  vertices: Array<[number, number]>;
  constructor(targetVisualElement, vertices) {
    this.targetVisualElement = targetVisualElement;
    this.vertices = vertices;
  }
}
