// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-tab-body-wrapper {
  height: 100% !important;
}

:host ::ng-deep .mat-tab-body.mat-tab-body-active {
  height: 100% !important;
}


::ng-deep.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),
::ng-deep.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {
  background-color: white;
}

::ng-deep .mat-ink-bar {
  background-color: #1a3763 !important;
}

p {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/images-pool-container/images-pool-container.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;;AAGA;;EAEE,uBAAuB;AACzB;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":[":host ::ng-deep .mat-tab-body-wrapper {\n  height: 100% !important;\n}\n\n:host ::ng-deep .mat-tab-body.mat-tab-body-active {\n  height: 100% !important;\n}\n\n\n::ng-deep.mat-tab-label.mat-tab-label-active:not(.mat-tab-disabled),\n::ng-deep.mat-tab-label.mat-tab-label-active.cdk-keyboard-focused:not(.mat-tab-disabled) {\n  background-color: white;\n}\n\n::ng-deep .mat-ink-bar {\n  background-color: #1a3763 !important;\n}\n\np {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
