import {Component, DoCheck, ElementRef, ViewChild} from '@angular/core';
import {
  IActionMapping, ITreeOptions,
  KEYS,
  TREE_ACTIONS,
  TreeComponent,
} from '@ali-hm/angular-tree-component';
import { GraphService } from '../rappid-components/services/graph.service';
import { TreeViewService } from '../rappid-components/services/tree-view.service';
import { Node } from '../models/node.model';
import { Subscription } from 'rxjs/Subscription';
import { DomSanitizer } from "@angular/platform-browser";
import {
  textWithoutSpaces,
  validationAlert,
  highlighSD,
  styleOpdTreeRightClickMenu, OPCloudUtils, setShowMapButton
} from '../configuration/rappidEnviromentFunctionality/shared';
import { MatLegacyDialog as MatDialog } from "@angular/material/legacy-dialog";
import { NoteService } from '../models/notes/note.service';
import { MainComponent } from "../modules/layout/main/main.component";
import { OpmModel } from '../models/OpmModel';
import { ModelService } from '../modules/app/model.service';
import { MatTreeNestedDataSource } from '@angular/material/tree';
import { BehaviorSubject } from 'rxjs';
import { NestedTreeControl } from '@angular/cdk/tree';
import { GraphDBService } from '../rappid-components/services/GraphDB/graphDB.service';
import { OplService } from '../opl-generation/opl.service';
import { InitRappidService } from '../rappid-components/services/init-rappid.service';
import {CreateViewDialog} from "../dialogs/create-view-dialog/create-view-dialog";
import {AbstractVersionService} from "../rappid-components/services/init/abstract.init.service";
import {TabsManager} from "../modules/app/tabsService";
import {ConfirmDialogDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog";
import {SubModelNameComponent} from "../dialogs/submodel-name-dialog/submodel-name-dialog";
import {SelectOpdsTreeDialog} from "../dialogs/select-opds-tree-dialog/select-opds-tree-dialog";
import {
  DisconnectSubModelTreeAction, OpenSubModelInNewTabTreeAction,
  RemoveOpdTreeAction,
  RenameOpdTreeAction, RenameSubModelTreeAction,
  ToggleOPDsNamesTreeAction,
  UpdateRequirementViewTreeAction
} from "./opdsTreeActions";

const joint = require('rappid');

@Component({
  selector: 'opcloud-opd-hierarchy',
  templateUrl: './opd-hierarchy.component.html',
  styleUrls: ['./opd-hierarchy.component.scss']
})
export class OPDHierarchyComponent {

  subscription: Subscription;
  // subscription2: Subscription;
  nodes: Node[] = [];
  toggleButtonActive: boolean = true;
  nestedDataSource: MatTreeNestedDataSource<Node>;
  dataChange: BehaviorSubject<Node[]> = new BehaviorSubject<Node[]>([]);
  nestedTreeControl;
  _shouldShowLoadAllSubModels: boolean;

  @ViewChild('tree', { static: false }) treeView: TreeComponent;
  @ViewChild('opdsNavigation') navigationBar: ElementRef;

  private graph;
  showApi = false;
  // showOPMResLabel = false;
  sdTreeOpen = true;
  defaultModelName: any;
  currentUser: any;
  opmModel: OpmModel;
  readOnlyString = '';
  user: any;
  public previousOpdId: string;

  constructor(
    private readonly oplService: OplService,
    private graphService: GraphService,
    public _treeViewService: TreeViewService,
    private sanitizer: DomSanitizer,
    public modelService: ModelService,
    private readonly service: AbstractVersionService,
    private _dialog: MatDialog,
    private noteService: NoteService,
    private main: MainComponent,
    private graphDB: GraphDBService) {
    this.graph = graphService.getGraph();
    this.subscription = new Subscription();
    // this.defaultModelName = ({ text: 'Temp Name' });
    this.nestedDataSource = new MatTreeNestedDataSource();
    this.nestedTreeControl = new NestedTreeControl<Node>(this._getChildren);
    this.dataChange.subscribe(data => this.nestedDataSource.data = data);
    this._treeViewService.initRappid.opdHierarchyRef = this;
    const that = this;
    this._treeViewService.initRappid.modelService.modelChange$.subscribe(change => {
      that._shouldShowLoadAllSubModels = this.hasSubModels();
    });
  }

  private map: Map<String, { depth: string }> = new Map<String, { depth: string }>();

  public getNodeTitle(node) {
    if (node.id === this.modelService.model.getOPMQueryID())
      return 'OPM Insights Result';
    if (node.id === 'SystemMap')
      return 'System Map';
    if (node.id === 'Stereotypes')
      return 'Stereotypes';
    if (node.id === 'Requirements')
      return node.data.name;
    if (node.parent && (node.parent.id === 'Stereotypes' || node.parent.id === 'Requirements'))
      return node.data.name;
    const opd = this.map.get(node.id);
    if (!opd)
      return 'Bug';
    return 'SD' + opd.depth + (this.oplService.settings.SDNames && opd.depth.length > 0 ? ': ' + this.modelService.model.getOpdName(node.id) : '');
  }

  private update(nodes: Array<Node>) {
    const head = nodes[0];
    const map_npde = this.map.get(head.id);
    // if (!map_npde)
    this.map.set(head.id, { depth: '' });

    for (let i = 0; i < head.children.length; i++)
      this.zoom(head.children[i], '', (i + 1).toString());
  }

  private zoom(node: Node, depth: string, index: string) {
    depth = (depth == '' ? '' : depth + '.') + index;

    const map_npde = this.map.get(node.id);
    // if (!map_npde)
    this.map.set(node.id, { depth });

    for (let i = 0; i < node.children.length; i++)
      this.zoom(node.children[i], depth, (i + 1).toString());
  }

  ngAfterViewInit() {
    this.subscription = this._treeViewService.getNodes().subscribe(nodes => {
      this.nodes = nodes;
      this.treeView.treeModel.update();
      this.update(nodes);
    });
    this._treeViewService.treeView = this.treeView;
    if (this._treeViewService) {
      setTimeout(() => {
        if (this._treeViewService.treeView.treeModel.nodes.length != 0) {
          try {
            this._treeViewService.treeView.treeModel.getNodeById(this._treeViewService.treeView.treeModel.nodes[0].id).toggleActivated();
          } catch (e) { }
        }
      }, 700);
    }
  }

  private get mapping(): IActionMapping {
    const component = this;
    return {
      mouse: {
        contextMenu: (tree, node, $event) => {
          $event.preventDefault();
          const target = $event.target;
          const sdnames_content = !(component.oplService.settings.SDNames) ? 'Show Names' : 'Hide Names';
          const opd = this.modelService.model.getOpd(node.data.id);
          if (!opd || opd.isStereotypeOpd())
            return;
          let tools = [{ action: 'remove', content: 'Remove', attrs: { 'left': '80px' } },
            { action: 'expandAll', content: 'Expand All' },
            { action: 'collapseAll', content: 'Collapse All' },
            { action: 'ShowHideNames', content: sdnames_content },
            ];
          if (this._treeViewService.initRappid.opmModel.opds.filter(opd => !opd.isHidden).length >= 20)
            tools.splice(1,1);
          if (opd.isViewOpd && !(opd.sharedOpdWithSubModelId || opd.belongsToSubModel))
            tools.push({ action: 'rename', content: 'Rename' });
          if (opd.requirementViewOf) {
            tools = [
              {action: 'remove', content: 'Remove', attrs: {'left': '80px'}},
              {action: 'updateRequirementView', content: 'Update'}
            ];
          }
          if (opd.sharedOpdWithSubModelId || opd.belongsToSubModel) {
            const idx = tools.findIndex(t => t.action === 'remove');
            if (idx >= 0) {
              tools.splice(idx, 1);
            }
            if (opd.sharedOpdWithSubModelId) {
              tools = [{ action: 'renameSubModelView', content: 'Rename'}, ...tools];
              tools.push({ action: 'openSubModelInNewTab', content: 'Open Sub Model In New Tab' });
              if (!opd.belongsToSubModel) {
                tools.push({ action: 'disconnectSubModel', content: 'Disconnect Sub Model' });
              }
            }
          }
          const contextService = (<any>this.service).model?.context;
          let ctx = new joint.ui.ContextToolbar({
            theme: 'modern',
            tools: tools,
            target: target, autoClose: true, padding: 10
          });
          const that = this;

          ctx.on('action:openSubModelInNewTab', function() {
            (new OpenSubModelInNewTabTreeAction(that._treeViewService.initRappid, contextService, opd)).act();
            this.remove();
          });

          ctx.on('action:renameSubModelView', function () {
            this.remove();
            (new RenameSubModelTreeAction(that._treeViewService.initRappid, opd)).act();
          });

          ctx.on('action:disconnectSubModel', async function() {
            this.remove();
            (new DisconnectSubModelTreeAction(that._treeViewService.initRappid, contextService, opd)).act();
          });

          ctx.on('action:ShowHideNames', function () {
            (new ToggleOPDsNamesTreeAction(that._treeViewService.initRappid)).act();
            this.remove();
          });

          ctx.on('action:rename', function () {
            const init = that._treeViewService.initRappid;
            (new RenameOpdTreeAction(init, opd)).act();
            this.remove();
          });

          ctx.on('action:updateRequirementView', async function () {
            (new UpdateRequirementViewTreeAction(that._treeViewService.initRappid, opd)).act();
            this.remove();
          });

          ctx.on('action:remove', function () {
            (new RemoveOpdTreeAction(that._treeViewService.initRappid, node, tree)).act();
            this.remove();
          });

          ctx.on('action:expandAll', function () {
            node.expandAll();
            this.remove();
          });

          ctx.on('action:collapseAll', function () {
            node.collapseAll();
            this.remove();
          });

          ctx.render();
          ctx.el.style.left = "20px";
          styleOpdTreeRightClickMenu();
        },
        dblClick: (tree, node, $event) => {
          if (node.hasChildren) TREE_ACTIONS.TOGGLE_EXPANDED(tree, node, $event);
        },
        click: (tree, node, $event) => {
          $event.shiftKey
            ? TREE_ACTIONS.TOGGLE_ACTIVE_MULTI(tree, node, $event)
            : TREE_ACTIONS.TOGGLE_ACTIVE(tree, node, $event)
        },
      },
      keys: {
        [KEYS.ENTER]: (tree, node, $event) => { }
      }
    };
  }

  public readonly customTemplateStringOptions: ITreeOptions = {
    // displayField: 'subTitle',
    isExpandedField: 'expanded',
    idField: 'id',
    actionMapping: this.mapping,
    nodeHeight: 1,
    useVirtualScroll: true,
    levelPadding: 10,
    allowDrop: (element, { parent, index }) => {
      if (!element)
        return false;
      return true;
    },
    allowDrag:(element) => {
      if (!element)
        return false;
      return true;
    },
  };

  onEvent(event) {
  }

  // in case of a large model the tree causes the system to be slow. so if there are more than 20 opds - only the expanded node will be expanded and the others will be collapsed.
  toggleExpanded(event) {
    if (this._treeViewService.initRappid.opmModel.opds.filter(opd => !opd.isHidden).length < 20)
      return;
    if (this._treeViewService.initRappid.currentlyExportingPdf)
      return;
    if (typeof event.node.data.id === 'number')
      return;
    if (event.node.isExpanded) {
      const parents = [event.node];
      let currentParent = event.node.parent;
      while (currentParent) {
        parents.push(currentParent);
        currentParent = currentParent.parent;
      }
      const ids = parents.map(p => p.data.id);
      for (const opd of this._treeViewService.initRappid.opmModel.opds) {
        const node = this.treeView.treeModel.getNodeById(opd.id);
        if (node && node.isExpanded && !ids.includes(node.id) && node.id !== this._treeViewService.initRappid.opmModel.currentOpd.id) {
          node.collapse();
        }
      }
    }
    const currentOpdNode = this.treeView.treeModel.getNodeById(this._treeViewService.initRappid.opmModel.currentOpd.id);
    if (!currentOpdNode.isExpanded)
      currentOpdNode.expand();
  }

  changeGraphModel($event, node) {
    if (this._treeViewService.initRappid.isDSMClusteredView.value === true && (this._treeViewService.initRappid.isDSMClusteredView.type === 'flattening'))
      return;
    const thisOPD = this;
    if (node.id === 'SystemMap') {
      return;
    }
    setShowMapButton(false);
    if (this._treeViewService.initRappid.opmModel.currentOpd.id !== node.id)
      this.previousOpdId = this._treeViewService.initRappid.opmModel.currentOpd.id;
    if (node.id !== this.modelService.model.getOPMQueryID()) {
      this._treeViewService.initRappid.isRendering = true;
      const that = this;
      setTimeout(async () => {
        this.cleanUpOPMQueryOPD();
        // this.updateReadOnly();
        const opd = that._treeViewService.initRappid.opmModel.getOpd(node.id);
        if (opd && this.shouldLoadSubModel(node)) {
          await that.lazyLoadSubModel(node);
        }
        this.graphService.changeGraphModel(node.data.id, this._treeViewService, node.data.type)
        that._treeViewService.initRappid.isRendering = false;
        this.toggleExpanded({ node: node });
        highlighSD(node.data.id, this._treeViewService.initRappid, $event?.target);
        // node.toggleActivated();
        // node.parent.expand();
        //   thisOPD._treeViewService.initRappid.opmModel.currentOpd = thisOPD._treeViewService.initRappid.opmModel.opds[0];
        //   for (let i = 0; i < thisOPD._treeViewService.initRappid.opmModel.opds.length; i++) {
        //     if (thisOPD._treeViewService.initRappid.opmModel.opds[i].id === node.data.id) {
        //       thisOPD._treeViewService.initRappid.opmModel.currentOpd = thisOPD._treeViewService.initRappid.opmModel.opds[i];
        //     }
        //   }
      }, 500);

    }
    return this;
  }

  cleanUpOPMQueryOPD() {
    const queryNode = this.treeView.treeModel.getNodeById(this.modelService.model.getOPMQueryID())
    if (queryNode) {
      this.modelService.model.removeOPMQueryOPD();
      this.graphDB.ResultOPLs = '';
      // queryNode.treeModel.getActiveNode();
      this._treeViewService.removeNode(queryNode.data.id);
      this._treeViewService.treeView.treeModel.update();
    }
  }

  getNodeSubTitle(node) {
    //console.log(node.data.graph.getCell(node.id));
    return this.modelService.model.getOpdName(node.id);
  }

  activateSubSub(tree) {
    // tree.treeModel.getNodeBy((node) => node.data.name === 'subsub')
    tree.treeModel.getNodeById(1001)
      .setActiveAndVisible();
  }

  getColorByType(node) {
    if (node.data.type === 'in-zoom') {
      return '2px solid #0000FF';
    }
    if (node.data.type === 'unfold')
      return '2px solid #0096FF';
    return '1px solid #000000';
  }

  removeNode(tree) {
    if (tree.treeModel.getActiveNode().isLeaf) {
      let parentNode = tree.treeModel.getActiveNode().parent.data.id;
      this._treeViewService.removeNode(tree.treeModel.getActiveNode().data.id);
      tree.treeModel.update();
      this.graphService.renderGraph(this._treeViewService.initRappid.opmModel.getOpd(parentNode), this._treeViewService.initRappid);
    }
  }

  toggleNotes() {
    this.toggleButtonActive = !this.toggleButtonActive;
    if (this.toggleButtonActive)
      this.noteService.showNotes(this.graph);
    else
      this.noteService.hideNotes(this.graph);
  }

  getDescription(node) {
    if (node.path.length == 1)
      return '';
    if (this.oplService.settings.SDNames)
      return ": " + this.getNodeSubTitle(node);
    return '';
  }

  toggaleNavbar() {
    this.main.toggleSidenav();
  }

  private _getChildren = (node: Node) => { return node.children };

  public displayName(): string {
    return (<any>this.service)?.model?.context?.getDisplayName() || this.modelService.displayName;
  }

  onMoveNode($event, effectedO?, realParentN?, realParentID?, prevParentO?) {
    const init = this._treeViewService.initRappid;
    if ($event.node.id === 'Stereotypes' || $event.node.id === 'Requirements' || $event.node.sharedOpdWithSubModelId || $event.node.belongsToSubModel)
      return this._treeViewService.init(this._treeViewService.initRappid.getOpmModel());

    const effectedOpd = effectedO || init.opmModel.getOpd($event.node.id);
    const realParent = realParentN || this.treeView.treeModel.getNodeById(effectedOpd.id).parent;
    const realParentId = realParentID || ((typeof realParent === 'string') ? realParent : realParent.id);
    const prevParent = prevParentO || init.opmModel.getOpd(effectedOpd.parendId);
    // illegal movements
    if (effectedOpd.parendId === 'Stereotypes' || realParent.data.name === 'Stereotypes' || (realParent.data.parent && realParent.data.parent.name === 'Stereotypes')) {
      this._treeViewService.init(this._treeViewService.initRappid.getOpmModel());
      return;
    }
    if (effectedOpd.parendId === 'Requirements' || realParent.data.name === 'Requirements' || (realParent.data.parent && realParent.data.parent.name === 'Requirements')) {
      this._treeViewService.init(this._treeViewService.initRappid.getOpmModel());
      return;
    }
    if (prevParent.sharedOpdWithSubModelId || prevParent.belongsToSubModel || realParent.data.sharedOpdWithSubModelId || realParent.data.belongsToSubModel) {
      this._treeViewService.init(this._treeViewService.initRappid.getOpmModel());
      return;
    }
    init.getOpmModel().logForUndo('Opds tree order change: ' + effectedOpd.getName());
    init.getOpmModel().opdDragMoveUpdate(prevParent, effectedOpd, realParentId);
    this.update(this.nodes);
    this.sortOpdsToFixNewOrderLoading(init);
  }

  // if we don't sort the opds - when loading it will try to put an opd under uncreated yed opd.
  // thus we need to sort the opds after changing the tree
  sortOpdsToFixNewOrderLoading(init: InitRappidService) {
    const that = this;
    init.opmModel.opds = init.opmModel.opds.sort(function (a, b) {
      const nodeA = that.treeView.treeModel.getNodeById(a.id);
      const nodeB = that.treeView.treeModel.getNodeById(b.id);
      if (!nodeA || !nodeB)
        return 1;
      const depthA = init.treeViewService.getNodeDepth(nodeA);
      const depthB = init.treeViewService.getNodeDepth(nodeB);
      if (depthA == depthB) {
        return nodeA.index > nodeB.index ? 1 : -1;
      }
      else return depthA > depthB ? 1 : -1;
    });
  }

  showGIF($event, handlerGif = '') {
    return OPCloudUtils.showGIF($event, handlerGif);
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }

  dragEnter(event, node) {
    if (event.dataTransfer.items[0]?.type === 'allowedtodrop') {
      node.collapseAll();
      node.expand();
    }
  }

  dragStart($event: DragEvent) {
    $event.dataTransfer.setData('allowedtodrop', 'true');
  }

  backOpd() {
    if (this.previousOpdId) {
      const node = this.treeView.treeModel.getNodeById(this.previousOpdId);
      if (node)
        this.changeGraphModel(undefined, node);
    }
  }

  moveUp() {
    const node = this.treeView.treeModel.getNodeById(this._treeViewService.initRappid.opmModel.currentOpd.id);
    if (node && node.parent && typeof node.parent.id !== 'number')
      this.changeGraphModel(undefined, node.parent);
  }

  moveInside() {
    const node = this.treeView.treeModel.getNodeById(this._treeViewService.initRappid.opmModel.currentOpd.id);
    if (node && node.getFirstChild())
      this.changeGraphModel(undefined, node.getFirstChild());
  }

  moveNext() {
    const node = this.treeView.treeModel.getNodeById(this._treeViewService.initRappid.opmModel.currentOpd.id);
    if (node && node.parent) {
      const idxInParent = node.getIndexInParent();
      const next = node.parent.visibleChildren[idxInParent + 1];
      if (next)
        this.changeGraphModel(undefined, next);
    }
  }

  movePrevious() {
    const node = this.treeView.treeModel.getNodeById(this._treeViewService.initRappid.opmModel.currentOpd.id);
    if (node && node.parent) {
      const idxInParent = node.getIndexInParent();
      const prev = node.parent.visibleChildren[idxInParent - 1];
      if (prev)
        this.changeGraphModel(undefined, prev);
    }
  }

  mouseEnterNavigationBar() {
    this.navigationBar.nativeElement.style.display = 'inline-flex';
    $('#showHideNavigationBarIcon')[0].style.display = 'none';
  }

  mouseLeaveNavigationBar() {
    this.navigationBar.nativeElement.style.display = 'none';
    $('#showHideNavigationBarIcon')[0].style.display = 'block';
  }

  async toggleNode(node) {
    if (node.isExpanded) {
      node.collapse();
      this._treeViewService.treeView.treeModel.setExpandedNode(node, false);
    } else {
      if (this.shouldLoadSubModel(node)) {
        await this.lazyLoadSubModel(node);
      }
      node.expand();
    }
  }

  shouldLoadSubModel(node): boolean {
    return !this.nodeHasChildren(node) && this.nodeSharedWithSubModel(node) &&
      this._treeViewService.initRappid.getOpmModel().getOpd(node.id)?.visualElements.length === 0;
  }

  shouldShowRedResync(node): boolean {
    return this.nodeSharedWithSubModel(node) && !node.data.subModelAlreadyLoaded;
  }

  shouldShowGreenSynced(node): boolean {
    if (!(this.nodeSharedWithSubModel(node) && node.data.subModelAlreadyLoaded))
      return false;

    const subModelsEditDates = node.data.initRappid.service?.model?.context?.context?.subModelsEditDates || {};
    if (subModelsEditDates[node.data.sharedOpdWithSubModelId] === undefined)
      return true;

    if (!node.data.subModelEditDate)
      return node.data.subModelEditDate = subModelsEditDates[node.data.sharedOpdWithSubModelId].toString();

    return subModelsEditDates[node.data.sharedOpdWithSubModelId].toString() === node.data.subModelEditDate;
  }

  shouldShowYellowSynced(node): boolean {
    if (!(this.nodeSharedWithSubModel(node) && node.data.subModelAlreadyLoaded))
      return false;

    const subModelsEditDates = node.data.initRappid.service?.model?.context?.context?.subModelsEditDates || {};
    if (subModelsEditDates[node.data.sharedOpdWithSubModelId] === undefined)
      return false;

    if (!node.data.subModelEditDate)
      return false;

    return subModelsEditDates[node.data.sharedOpdWithSubModelId].toString() !== node.data.subModelEditDate;
  }

  nodeHasChildren(node): boolean {
    return node.hasChildren;
  }

  nodeSharedWithSubModel(node): boolean {
    return node.data?.sharedOpdWithSubModelId;
  }

  async lazyLoadSubModel(node, loadsubsub = false) {
    this._treeViewService.initRappid.isLoadingModel = true;
    const contextService = (<any>this.service).model.context;
    const sharedOpd = this._treeViewService.initRappid.opmModel.getOpd(node.id);
    const rawSubModel = await contextService.loadSubModelFromDB(node.data.sharedOpdWithSubModelId).catch(err => null);
    if (rawSubModel === null) {
      validationAlert('Cannot load sub model.');
      this._treeViewService.initRappid.isLoadingModel = false;
      return Promise.resolve();
    } else if (!rawSubModel.fatherModelName && !rawSubModel.logicalElements.some(l => l.belongsToFatherModelId)) {
      validationAlert('This model is no longer a sub model for this model. Disconnect this model by using the right click on the node in the hierarchy tree or restore a version of the sub model and override the main version of the  sub model.', 10000, 'Error');
      this._treeViewService.initRappid.isLoadingModel = false;
      return Promise.resolve();
    }
    this.modelService.lazyLoadSubModel(this._treeViewService.initRappid.opmModel, sharedOpd, node.data.sharedOpdWithSubModelId, rawSubModel);
    this._treeViewService.init(this._treeViewService.initRappid.opmModel);
    this._treeViewService.treeView.treeModel.getNodeById(node.id)?.expand();
    this._treeViewService.initRappid.isLoadingModel = false;
    for (const opd of rawSubModel.opds) {
      if (opd.sharedOpdWithSubModelId && loadsubsub) {
        await this.lazyLoadSubModel({id: opd.id, data: { sharedOpdWithSubModelId: opd.sharedOpdWithSubModelId}}, loadsubsub)
      }
    }
    node.data.subModelAlreadyLoaded = true;
    this._treeViewService.initRappid.criticalChanges_.next(true);
    this._treeViewService.initRappid.getGraphService().renderGraph(this._treeViewService.initRappid.opmModel.currentOpd, this._treeViewService.initRappid);
    return Promise.resolve();
  }

  getOpacity(node) {
    if (this.nodeSharedWithSubModel(node) || node.data.belongsToSubModel)
      return { opacity: 0.6 };
    return { opacity: 1 };
  }

  async loadAllSubModels() {
    const sharedOpds = this._treeViewService.initRappid.opmModel.opds.filter(opd => opd.sharedOpdWithSubModelId);
    const nodes = sharedOpds.map(opd => this._treeViewService.treeView.treeModel.getNodeById(opd.id)).filter(n => !!n && this.shouldLoadSubModel(n));
    return Promise.all(nodes.map(n => this.lazyLoadSubModel(n, true)));
  }

  hasSubModels(): boolean {
    return this._treeViewService.initRappid.opmModel.opds.some(opd => opd.sharedOpdWithSubModelId);
  }

  getSubModelSyncTooltip(node) {
    if (this.shouldShowRedResync(node)) {
      return 'Sub-model is not loaded';
    } else if (this.shouldShowYellowSynced(node)) {
      return 'Sub-model is loaded but not synced with the latest version';
    }
    return 'Sub-model is loaded and synced with the latest version';
  }

  openTreeManagementDialog() {
    this._treeViewService.initRappid.dialogService.openDialog(SelectOpdsTreeDialog, 700, 1180, {
      title: 'OPDs Tree Management', mode: 'tree-management' });
  }
}
