import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {CreatePopupMessagesComponent} from "../../../dialogs/create-popup-message/create-popup-message.component";
import {ActivatedRoute} from "@angular/router";
import {ConfirmDialogDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog";
import {StorageService} from "../../../rappid-components/services/storage.service";
import {OPCloudUtils, validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {
  DisplayPopupMessageItem,
  PopupMessageData,
  PopupMessageItem
} from "../../../dialogs/create-popup-message/popup.message.interface";
import {PopupMessageContentComponent} from "../../../dialogs/popup-message-content/popup-message-content.component";
import {SafeHtml} from "@angular/platform-browser";
import {PopupViewsOrConfirmsComponent} from "../../../dialogs/popup-views-or-confirms/popup-views-or-confirms.component";

@Component({
  selector: 'popup-messages',
  templateUrl: './popup-messages.component.html',
  styleUrls: ['./popup-messages.component.css']
})
export class PopupMessagesComponent implements OnInit {

  private messages: Array<PopupMessageItem>;
  private messagesToDisplay: Array<DisplayPopupMessageItem>;
  private type: 'org' | 'system';
  private searchString: string;
  private timezoneOffset = new Date().getTimezoneOffset() * 60000;
  private sortColumn: 'views' | 'author' | 'subject' | 'confirms' | 'publish_date' | 'exp_date';
  private sortDirection: 'up' | 'down';

  constructor(private init: InitRappidService, private route: ActivatedRoute, private storage: StorageService) {
    this.messages = [];
    this.searchString = '';
    this.sortColumn = 'publish_date';
    this.sortDirection = 'down';
  }

  async ngOnInit() {
    this.type = this.route.snapshot.data.type;
    this.refreshMessages();
  }

  refreshMessages() {
    this.storage.database.driver.getAllPopupMessages(this.type).then(messages => {
      this.messages = messages;
      this.formatMessages();
      this.messagesToDisplay = this.messagesToDisplay.filter(msg => msg.subject.includes(this.searchString));
      this.sortMessages();
    });
  }

  columnClick(colName: 'views' | 'author' | 'subject' | 'confirms' | 'publish_date' | 'exp_date') {
    this.toggleSortDirection();
    this.sortColumn = colName;
    this.sortMessages();
  }

  toggleSortDirection() {
    this.sortDirection = this.sortDirection === 'up' ? 'down' : 'up';
  }

  sortMessages() {
    this.messagesToDisplay = this.messagesToDisplay.sort((a, b) => {
      const sign = this.sortDirection === 'up' ? -1 : 1;
      if (this.sortColumn === 'views') {
        if (a.views === b.views) return 0;
        return a.views > b.views ? sign * 1 : sign * -1;
      } else if (this.sortColumn === 'confirms') {
        if (a.confirms === b.confirms) return 0;
        return a.confirms > b.confirms ? sign * 1 : sign * -1;
      } else if (this.sortColumn === 'author') {
        if (a.author === b.author) return 0;
        return a.author > b.author ? sign * 1 : sign * -1;
      } else if (this.sortColumn === 'subject') {
        return a.subject > b.subject ? sign * 1 : sign * -1;
      } else if (this.sortColumn === 'publish_date') {
        return a.publish_date > b.publish_date ? sign * 1 : sign * -1;
      } else if (this.sortColumn === 'exp_date') {
        return a.exp_date_as_number > b.exp_date_as_number ? sign * 1 : sign * -1;
      }
    });
  }

  formatMessages() {
    this.messagesToDisplay = this.messages.map(msg => {
      return {
        id: msg.id,
        views: msg.views,
        confirms: msg.confirms,
        author: msg.author,
        exp_date: new Date(msg.exp_date + this.timezoneOffset).toLocaleString(),
        exp_date_as_number: msg.exp_date,
        publish_date: new Date(msg.publish_date).toLocaleString(),
        subject: msg.subject,
        content: msg.content,
        messageAppearance: msg.messageAppearance,
        hasToConfirm: msg.hasToConfirm,
        showActions: false
      }
    });
  }

  onInputChange($event) {
    this.searchString = $event.target.value.trim();
    this.formatMessages();
    this.messagesToDisplay = this.messagesToDisplay.filter(msg => msg.subject.includes(this.searchString));
  }

  createNewMessage() {
    this.init.dialogService.openDialog(CreatePopupMessagesComponent, 835, 800, { mode: 'create', type: this.type })
      .afterClosed().toPromise().then(() => OPCloudUtils.waitXms(500)).then(() => this.refreshMessages());
  }

  viewMessage(msg: DisplayPopupMessageItem) {
    this.init.dialogService.openDialog(CreatePopupMessagesComponent, 835, 800, { mode: 'view', message: msg, type: this.type });
  }

  previewMessage(msg: DisplayPopupMessageItem) {
    const message: PopupMessageData = {
      id: msg.id,
      subject: msg.subject,
      hasToConfirm: msg.hasToConfirm,
      content: msg.content,
      messageAppearance: msg.messageAppearance
    };
    this.init.dialogService.openDialog(PopupMessageContentComponent, null, 700, { mode: 'preview', message, type: this.type });
  }

  updateMessage(msg: DisplayPopupMessageItem) {
    this.init.dialogService.openDialog(CreatePopupMessagesComponent, 835, 800, { mode: 'update', message: msg, type: this.type })
    .afterClosed().toPromise().then(() => OPCloudUtils.waitXms(500)).then(() => this.refreshMessages());
  }

  duplicateMessage(msg: DisplayPopupMessageItem) {
    this.init.dialogService.openDialog(CreatePopupMessagesComponent, 835, 800, { mode: 'create', message:  msg, type: this.type })
      .afterClosed().toPromise().then(() => OPCloudUtils.waitXms(500)).then(() => this.refreshMessages());
  }

  messageMouseEnter(msg: DisplayPopupMessageItem) {
    msg.showActions = true;
  }

  messageMouseLeave(msg: DisplayPopupMessageItem) {
    msg.showActions = false;
  }

  async deleteMessage(msg: DisplayPopupMessageItem) {
    const canClose = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 180, 350, {
      message: `Pay attention, "${msg.subject}" will be removed permanently.`,
      okName: 'Delete', okColor: '#ff0000', centerText: true, closeName: 'Cancel'
    }).afterClosed().toPromise();
    if (!canClose)
      return;
    this.storage.database.driver.removePopupMessage(this.type, msg.id).then(() => {
      validationAlert('The message was removed successfully.');
      this.messages = this.messages.filter(m => m.id !== msg.id);
      this.messagesToDisplay = this.messagesToDisplay.filter(m => m.id !== msg.id);
    }).catch(err => {
      validationAlert('There was an error while trying to remove this message. Please try again.', 3500, 'error');
    });
  }

  getArrowSign(colName: string) {
    if (colName === this.sortColumn) {
      return this.sortDirection === 'up' ? '↑ ' : '↓ ';
    }
    return '';
  }

  getSizeOfDict(dict) {
    return Object.keys(dict || {}).length;
  }

  openViewsOrConfirmsDataDialog(msg: DisplayPopupMessageItem, type: 'confirms' | 'views') {
    this.init.dialogService.openDialog(PopupViewsOrConfirmsComponent, null, 500, { type, message: msg, mode: this.type })
  }

}


