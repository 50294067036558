import { Component, OnInit } from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'opcloud-model-analysis',
  templateUrl: 'model-analysis.component.html',
  styleUrls: ['./model-analysis.component.css']
})
export class ModelAnalysisComponent implements OnInit {
  private content: Array<string>;

  constructor(private initRappidService: InitRappidService) {
    this.content = initRappidService.opmModel.modelMetaData.getString().split('\n');
  }

  ngOnInit() {
  }

  /**
   * The main method called from the HTML to export the model metrics data as a CSV file.
   */
  exportModelMetricsCSVFile() {
    const fileName = 'ModelMetrics_' + this.initRappidService.opmModel.name;
    const metricsArray = ['Metric\'s Name,', 'Value\n'];
    for (const metric of this.content) {
      metricsArray.push(metric.split(':')[0] += ',');
      metricsArray.push(metric.split(':')[1] += '\n');
    }
    const exportValuesFile = new Blob(metricsArray, { type: 'text/csv' });
    FileSaver.saveAs(exportValuesFile, fileName + '.csv'); // Save the exported file.
  }

}
