import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_RADIO_DEFAULT_OPTIONS as MAT_RADIO_DEFAULT_OPTIONS } from '@angular/material/legacy-radio';
import { OrganizationService } from '../../rappid-components/services/organization.service';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogDialogComponent } from '../confirm-dialog/confirm-dialog';

@Component({
  selector: 'remove-user',
  templateUrl: './remove-user.component.html',
  styleUrls: ['./remove-user.component.css'],
  providers: [{
    provide: MAT_RADIO_DEFAULT_OPTIONS,
    useValue: { color: 'primary' },
  }]
})
export class RemoveUserComponent implements OnInit {

  pendding: boolean = true;

  rest_of_organization_users: Array<any>;
  user_id: string;
  name: string;

  can_be_auto_transfered: boolean = undefined;

  error: string = '';

  transfer_to: string;

  organization_users: Array<any>;

  action: 'remove' | 'transfer' = 'remove';

  constructor(@Optional() public dialogRef: MatDialogRef<ConfirmDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) private data, private readonly organizationService: OrganizationService) {
  }

  ngOnInit() {
    this.name = this.data.user.user.Name;
    this.user_id = this.data.user.user.uid;

    this.organizationService.deleteUserCanBeAutoTrasfered(this.user_id).then(res => {
      if (res == false) {
        this.organizationService.getOrganizationUsers(this.data.user.user.organization)
          .then(users => {
            this.organization_users = users;
            this.pendding = false;
            this.can_be_auto_transfered = false;
          });
      } else {
        this.pendding = false;
        this.can_be_auto_transfered = true;
      }

    });
  }

  deleteUser() {
    let promise;
    if (this.can_be_auto_transfered) {
      promise = this.organizationService.deleteUser(this.user_id, { action: 'auto' });
    } else {
      if (this.action == 'remove') {
        promise = this.organizationService.deleteUser(this.user_id, { action: 'remove' });
      } else if (this.action == 'transfer') {
        if (this.transfer_to && this.transfer_to != this.user_id) {
          promise = this.organizationService.deleteUser(this.user_id, { action: 'transfer', transfer_to: this.transfer_to });
        } else {
          this.error = 'Please select a user';
          return;
        }
      }
    }

    this.pendding = true;
    promise.then(() => this.dialogRef.close({ removed: true }));
  }

  doNotDeleteUser() {
    this.dialogRef.close({ removed: false });
  }

}
