import {getInitRappidShared, initRappidShared, joint} from '../../configuration/rappidEnviromentFunctionality/shared';
import {Affiliation, Essence, linkConnectionType, linkType} from '../ConfigurationOptions';
import {OpmVisualThing} from '../VisualPart/OpmVisualThing';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {OpmLogicalThing} from '../LogicalPart/OpmLogicalThing';
import {OpmVisualEntity} from '../VisualPart/OpmVisualEntity';

export class OpmSemifoldedFundamental extends joint.shapes.standard.EmbeddedImage {

  private triangleType;

  constructor(fundamentalType: linkType, entityType: string) {
    super();
    this.triangleType = fundamentalType;
    let entType;
    if (entityType.includes('Object'))
      entType = 'opm.Object';
    else if (entityType.includes('Process'))
      entType = 'opm.Process';
    else if (entityType.includes('State'))
      entType = 'opm.State';
    this.set('type', entType);
    this.set(this.semiFoldedAttributes());
    this.semiFoldedAttrs();
    this.removeIrrelevantAttrs();

    if (fundamentalType === linkType.Aggregation)
      this.attr('image/xlinkHref', 'assets/SVG/links/structural/aggregation.svg');
    else if (fundamentalType === linkType.Instantiation)
      this.attr('image/xlinkHref', 'assets/SVG/links/structural/classification.svg');
    else if (fundamentalType === linkType.Generalization)
      this.attr('image/xlinkHref', 'assets/SVG/links/structural/generalization.svg');
    else if (fundamentalType === linkType.Exhibition)
      this.attr('image/xlinkHref', 'assets/SVG/links/structural/exhibition.svg');

    // this.set('ports', this.getPortGroups());
    // this.addPort({ group: 'left', id: 1 });
    // this.addPort({ group: 'right', id: 2 });

    const leftPort = {
      id: '1',
      group: 'left',
      args: {},
      attrs: {magnet: true},
      markup: '<g><rect width="5" height="5" x="28" y="19" strokegit ="red" fill="transparent" pointer-events="none"/></g>'
    };
    const rightPort = {
      id: '2',
      group: 'right',
      args: {},
      attrs: {magnet: true},
      markup: '<g><rect width="5" height="5" y="19" x="0" strokegit ="red" fill="transparent" pointer-events="none"/></g>'
      // markup: '<g transform="translate(150,0)"><rect width="5" height="5" y="21" x="0" strokegit ="red" fill="red"/></g>'
    };

    this.addPort(leftPort);
    this.addPort(rightPort);

  }

  static bestSemiFoldedPort(semiFoldedContainer: OpmVisualThing, target: OpmVisualEntity) {
    if (!semiFoldedContainer || !target)
      return 1;
    const sourceMidPoint = semiFoldedContainer.xPos + semiFoldedContainer.width / 2;
    if (target.xPos < sourceMidPoint)
      return 1;
    return 2;
  }

  hasStates() {
    return false;
  }

  getStatesOnly() {
    return [];
  }

  semiFoldedAttributes() {
    return {
      'size': {width: 150, height: 40},
    };
  }

  updateText(newText: string) {
    this.attr('label/text', newText);
    this.attr('text/textWrap/text', newText);
  }

  semiFoldedAttrs() {
    this.attr('label/text', '');
    this.attr('body/refWidth', '100%');
    this.attr('body/refHeight', '100%');
    this.attr('body/height', '40px');
    this.attr('image/x', '10px');
    this.attr('image/y', '-5px');
    this.attr('image/width', '50px');
    this.attr('image/height', '50px');
    this.attr('label/refX', '30px');
    this.attr('root/magnet', false);
    this.attr('image/magnet', true);
    this.attr('label/fontWeight', 600);
    this.attr('label/fontSize', 12);
    this.attr('label/refY', 15);
    this.attr('body/fill', 'rgba(0, 0, 0, 0)');
    this.attr('value/value', 'None');
  }

  updateParamsFromOpmModel(visual: OpmVisualThing) {
    const parent = getInitRappidShared().graph.getCell(visual.fatherObject.id);
    this.set('position', {x: parent.get('position').x + visual.xPos, y: parent.get('position').y + visual.yPos});
  }

  removeIrrelevantAttrs() {
    // this.removeAttr('body/refHeight');
    // this.removeAttr('body/refWidth');
    this.removeAttr('body/stroke');
    this.removeAttr('image/refHeight');
    this.removeAttr('image/refWidth');
  }

  getVisual(): OpmVisualThing {
    return getInitRappidShared().getOpmModel().getVisualElementById(this.id);
  }

  getParams() {
    // return undefined;
    return {
      xPos: this.getParentCell() ? this.get('position').x - this.getParentCell().get('position').x : undefined,
      yPos:  this.getParentCell() ? this.get('position').y - this.getParentCell().get('position').y : undefined,
      width: this.get('size').width,
      height: this.get('size').height,
      // textFontWeight: this.attr('text/font-weight'),
      // textFontSize: this.attr('text/font-size'),
      // textFontFamily: this.attr('text/font-family'),
      // textColor: this.attr('text/fill'),
      // refX: this.attr('text/ref-x'),
      // refY: this.attr('text/ref-y'),
      // xAlign: this.attr('text/x-alignment'),
      // yAlign: this.attr('text/y-alignment'),
      // textWidth: this.attr('text/textWrap/width'),
      // textHeight: this.attr('text/textWrap/height'),
      // text: this.attr('text/textWrap/text'),
      // fill: this.getShapeAttr().fill,
      // strokeColor: this.getShapeAttr().stroke,
      // strokeWidth: this.attr('rect/stroke-width') || this.attr('ellipse/stroke-width'),
      id: this.get('id'),
      fatherObjectId: this.get('parent'),
      // essence: Essence.Physical,
      // affiliation: (this.getShapeAttr()[this.dashArrayKey] === this.dashArrayValues[0]) ? Affiliation.Systemic : Affiliation.Environmental,
      // statesArrangement: this.attr('statesArrange'),
      // valueType: this.attr('value/valueType'),
      // value: this.attr('value/value'),
      // units: this.attr('value/units'),
      // digitalTwin: this.digitalTwin,
      // predigitalTwin: this.predigitalTwin,
      // originalObj: this.originalObj,
      // preoriginalObj: this.preoriginalObj,
      // digitalTwinConnected: this.digitalTwinConnected,
      ////////////////////
      // unique attrs  //
      //////////////////
      bodyFill: this.attr('body/fill'),
      bodyHeight: this.attr('body/height'),
      bodyWidth: this.attr('body/height'),
      imgaeX: this.attr('image/x'),
      imgaeY: this.attr('image/y'),
      imageXlinkRef: this.attr('image/xlinkHref'),
      labelFill: this.attr('label/fill'),
      labelFontSize: this.attr('label/fontSize'),
      labelFontWeight: this.attr('label/fontWeight'),
      labelRefX: this.attr('label/refX'),
      labelRefX2: this.attr('label/refX2'),
      labelRefY: this.attr('label/refY'),
      labelText: this.attr('label/text'),
    };
  }

  getPortAttr(x, y, width, height) {
    return {
      rect: {
        stroke: 'red',
        fill: 'green',
        width: width,
        height: height,
        x: x,
        y: y,
        magnet: 'true'
      }
    };
  }

  createPortGroup(name, args, x, y, width, height) {
    return {
      position: { name: name, args: {        x: 10,
          y: 10,
          angle: 30,
          dx: 1,
          dy: 1} },
      attrs: this.getPortAttr(x, y, width, height),
      markup: '<g><rect/></g>'
    };
  }

  getPortGroups() {
    return {
      'left': this.createPortGroup('1', null, 0, 0, 5, 5),
      'right': this.createPortGroup('2', null, 0, 0, 5, 5)
    };
  }

  updateView(vis) {
  }

  setPorts(side: string): number {
    return 1;
  }

  getEssence() {
    return (<OpmLogicalThing<OpmVisualThing>>this.getVisual().logicalElement).essence;
  }

  getAffiliation() {
    return (<OpmLogicalThing<OpmVisualThing>>this.getVisual().logicalElement).affiliation;
  }

  isComputational() {
    return (<OpmLogicalThing<OpmVisualThing>>this.getVisual()?.logicalElement)?.isComputational();
  }

  takeThingOut() {
    const init = getInitRappidShared();
    const model = init.getOpmModel();
    model.logForUndo('Fold out relation');
    model.setShouldLogForUndoRedo(false, 'OpmSemifoldedFundamental-takeThingOut');
    const drawnParent = this.getParentCell();
    const ret = model.foldOutFundamentalRelation(this.getVisual());
    init.graph.getCell(ret.thing.id).updateView(ret.thing);
    ret.createdEntities.forEach(ent => init.getGraphService().updateEntity(ent));
    for (const semf of ret.thing.semiFolded) {
      const cell = this.graph.getCell(semf.id);
      cell.set('position', {x: semf.xPos + ret.thing.xPos, y: semf.yPos + ret.thing.yPos});
    }
    for (const removed of ret.removed)
      if (init.graph.getCell(removed.id))
        init.graph.getCell(removed.id).remove();
    ret.createdEntities.forEach(ent => {
      const entCell = init.graph.getCell(ent.id);
      if (entCell && (entCell.constructor.name.includes('Object') || entCell.constructor.name.includes('Process')))
        entCell.shiftEmbeddedToEdge(init);
    });
    init.getGraphService().updateLinksView(ret.createdLinks);
    // init.graph.resetCells(init.graph.getCells());
    for (const sm of init.graph.getCells().filter(cl => cl.constructor.name.includes('OpmSemifoldedFund')))
      if (init)
        sm.addHandle(init);
    model.setShouldLogForUndoRedo(true, 'OpmSemifoldedFundamental-takeThingOut');
  }

  hasURLs() {
    return false;
  }

  getParent() {
    return this.getParentCell();
  }

  doubleClickHandle(cellView, initRappid) {
    this.foldOut();
  }

  foldOut() {
    if (initRappidShared.opmModel.currentOpd.stereotypeOpd)
      return;
    const parent = this.getParentCell();
    this.takeThingOut();
    if (parent.shiftEmbeddedToEdge)
      parent.shiftEmbeddedToEdge(initRappidShared);
    parent.updateSizePositionToFitEmbeded(true);
  }

  fixRightPortPosition(init: InitRappidService) {
    if (!init.paper)
      return;
    const el = init.paper.findViewByModel(this)?.el;
    if (!el) return;
    const textbbox = el.children[2]?.getBBox();
    if (textbbox) {
      const xDist = 50 + textbbox.width;
      const rightPortMarkup = `<g><rect width="5" height="5" y="19" x="0" strokegit ="red" fill="transparent" pointer-events="none" transform="translate(${xDist},0)"/></g>`;
      this.portProp('2', 'markup', rightPortMarkup);
    }
  }

  removeDuplicationMark() {}
  pointerUpHandle(cellView, options) {
    options.setSelectedElementToNull();
  }
  changeAttributesHandle(options) {
    this.fixRightPortPosition(options);
  }
  changeSizeHandle(initRappid) { }
  changePositionHandle(initRappid) { }
  removeHandle(options) { }
  addHandle(options) {
    this.fixRightPortPosition(options);
    this.updateSize(options);
    if (options.paper && this.findView(options.paper))
      this.findView(options.paper).setInteractivity({elementMove: false});
  }

  updateSize(options) {
    const el = options.paper.findViewByModel(this).el;
    const width = el.getBBox().width;
    if (width && !isNaN(width)) {
      this.set('size', {width: width, height: this.get('size').height });
      if (this.getVisual())
        this.getVisual().width = width;
    }
  }
  pointerDownHandle(cellView, options, event) {
    options.setSelectedElementToNull();
  }

}

