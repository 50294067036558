import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmEntity } from "../../DrawnPart/OpmEntity";
import { OpmVisualEntity } from "../../VisualPart/OpmVisualEntity";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class StylingCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly entity: OpmEntity,
        private readonly visual: OpmVisualEntity) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'styling',
            displayTitle: 'Style Element',
            svg: 'styleElement',
            action: new StyleAction(this.init, this.entity, this.visual)
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'styling',
            displayTitle: 'Style Element',
            svg: 'styleElement',
            action: new StyleAction(this.init, this.entity, this.visual),
            gif: '',
        }
    }

}

class StyleAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly entity: OpmEntity,
        private readonly visual: OpmVisualEntity) {
    }

    act() {
        this.init.elementToolbarReference.openStylingDiv()
    }

}
