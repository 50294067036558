import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { MethodologicalChecker, MethodologicalCheckingStatus} from "./methodological-checker";
import {IngProcessesNamesChecker} from "./checkers/ing-checker";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {ObjectNameAsSingularChecker} from "./checkers/object-name-as-singular-checker";
import {PartUnfoldContentChecker} from "./checkers/part-unfold-content-checker";
import {InzoomedContentChecker} from "./checkers/inzoomed-content-checker";
import {TransformingProcessChecker} from "./checkers/transforming-process-checker";
import {SearchItemsDialogComponent} from "../search-items-dialog/search-items-dialog.component";

@Component({
  selector: 'methodological-checking-dialog',
  templateUrl: 'methodological-checking-dialog.html',
  styleUrls: ['methodological-checking-dialog.css']
})
export class MethodologicalCheckingDialog implements OnInit {

  private readonly checkers: Array<MethodologicalChecker>;

  constructor(private init: InitRappidService, private dialogRef: MatDialogRef<MethodologicalCheckingDialog>, @Inject(MAT_DIALOG_DATA) private data) {
    this.checkers = [
      new IngProcessesNamesChecker(this.init.opmModel),
      new ObjectNameAsSingularChecker(this.init.opmModel),
      new PartUnfoldContentChecker(this.init.opmModel),
      new InzoomedContentChecker(this.init.opmModel),
      new TransformingProcessChecker(this.init.opmModel)
    ];
  }

  ngOnInit() {
    this.checkAll();
  }

  checkAll() {
    for (const item of this.checkers) {
      item.check();
    }
  }

  getCheckerDetails(event, item: MethodologicalChecker) {
    event.stopPropagation();
    const data = {
      allEntities: item.getInvalidThings(),
      allowMultipleDialogs: true,
      title: 'Invalid Things',
      subtitle: item.getDescriptionTooltip()
    };
    const that = this;
    this.init.dialogService.openDialog(SearchItemsDialogComponent, 580, 600, data).afterClosed().toPromise()
      .then(val => {
      if (val === 'goToOPD') {
        that.dialogRef.close();
      }
    });
  }

  onStartDrag(event) {
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e) };
    window.onmouseup = function (e) { that.endDrag(e) };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
  }

  moveDrag(event) {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    const rect = $('mat-dialog-container')[0].parentElement.getClientRects()[0];
    const moveBtnRects = $('.move-button')[0].getClientRects()[0];
    const dx = moveBtnRects.left - rect.left + moveBtnRects.width/2;
    const dy = moveBtnRects.top - rect.top + moveBtnRects.height/2;
    this.dialogRef.updatePosition({ left: (event.clientX - dx) + 'px',
    top: (event.clientY - dy + scrollTop) + 'px'});
  }

}



