// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#selectDiv {
  width: 150px;
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 4px;
  padding: 3px;
}

select {
  display: block;
  width: 150px;
  background-image: url('select_arrow.png');
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow: hidden;
  color: #1A3763;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: normal;
  Opacity: 70%;
}

#orgSelection {
  width: 100%;
  display: inline-flex;
  margin-bottom: 10px;
  align-items: center;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/popup-views-or-confirms/popup-views-or-confirms.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,yCAAA;EACA,kBAAA;EACA,YAAA;AACF;;AAEA;EACE,cAAA;EACA,YAAA;EACA,yCAAA;EACA,4BAAA;EACA,iCAAA;EACA,YAAA;EACA,wBAAA;EACA,qBAAA;EACA,gBAAA;EACA,cAAA;EACA,iDAAA;EACA,mBAAA;EACA,YAAA;AACF;;AAEA;EACE,WAAA;EACA,oBAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;AACF","sourcesContent":["#selectDiv {\n  width: 150px;\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 4px;\n  padding: 3px;\n}\n\nselect {\n  display: block;\n  width: 150px;\n  background-image: url('../../../assets/icons/select_arrow.png');\n  background-repeat: no-repeat;\n  background-position: right center;\n  border: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  overflow: hidden;\n  color: #1A3763;\n  font-family: Roboto, Arial, Helvetica, sans-serif;\n  font-weight: normal;\n  Opacity: 70%;\n}\n\n#orgSelection {\n  width: 100%;\n  display: inline-flex;\n  margin-bottom: 10px;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
