import {Component, ElementRef, OnInit, ViewChild} from "@angular/core";
import {OrganizationService} from "../../../rappid-components/services/organization.service";
import {validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OplService} from "../../../opl-generation/opl.service";
import {OntologyItem} from "./ontologyInterfaces";

interface DisplayOntologyItem {
  value: {
    phrases: string,
    synonyms: string,
  },
  isEditable: boolean
}

@Component({
  selector: 'Organization-Ontology',
  templateUrl: './Organization-Ontology.component.html',
  styleUrls: ['./Organization-Ontology.component.css']
})
export class OrganizationOntologyComponent implements OnInit {

  private data: Array<OntologyItem>;
  private fullDisplayData: Array<DisplayOntologyItem>;
  private filterText: string;
  private dataToDisplay: Array<DisplayOntologyItem>;

  constructor(private orgService: OrganizationService, private oplService: OplService) {
    this.filterText = '';
  }

  ngOnInit() {
    this.data = this.oplService.orgOplSettings.ontology;
    this.fullDisplayData = this.convertDataToDisplayData(this.data);
    if (this.fullDisplayData.length === 0)
      this.addNewEntry();
    this.resetFilterText();
  }

  resetFilterText() {
    this.filterText = '';
    this.dataToDisplay = this.fullDisplayData;
  }

  convertDataToDisplayData(data: Array<OntologyItem>): Array<DisplayOntologyItem> {
    return data.map(item => {
      const value = {
        phrases: item.phrases.join('; '),
        synonyms: item.synonyms.join('; '),
      };
      return {
        value: value,
        isEditable: false
      }
    });
  }

  convertDisplayDataToData(displayData: Array<DisplayOntologyItem>): { success: boolean, errors?: Array<string>, ontology?: Array<OntologyItem>} {
    const ret = displayData.map(item => {
      return {
        phrases: item.value.phrases.split(';').map(ph => ph.trim()),
        synonyms:item.value.synonyms.split(';').map(sy => sy.trim())
      }
    });
    const errors = [];

    if (ret.find(item => item.phrases.includes('') || item.synonyms.includes('')))
      errors.push('Empty phrases are not allowed.');
    else if (ret.find(item => item.phrases.find(ph => ph.length < 2) || item.synonyms.find(sy => sy.length < 2)))
      errors.push('Phrase or a synonym must contain at least 2 characters.');

    if (errors.length > 0)
      return { success: false, errors: errors};

    return { success: true, ontology: ret};
  }

  addNewEntry() {
    this.fullDisplayData.push({ value: { phrases: '', synonyms: '' }, isEditable: true });
    this.resetFilterText();
  }

  save() {
    const ret = this.convertDisplayDataToData(this.fullDisplayData);
    if (ret.success) {
      this.oplService.orgOplSettings.ontology = ret.ontology;
      this.orgService.updateOrganizationOntology(ret.ontology).then(() => validationAlert('Saved Successfully'))
        .catch(err => validationAlert('An Error has occured.', 5000, 'Error'));
    } else {
      validationAlert('Cannot update the ontology.\n' + ret.errors.join('\n'), 8000, 'Error');
    }
  }

  removeItem(item: DisplayOntologyItem) {
    this.fullDisplayData.splice(this.fullDisplayData.indexOf(item), 1);
  }

  makeItemEditable(item: DisplayOntologyItem) {
    item.isEditable = true;
  }

  filterTextClick() {
    this.filterText = this.filterText.trim();
    this.dataToDisplay = this.fullDisplayData.filter(
      item => item.value.phrases.includes(this.filterText) || item.value.synonyms.includes(this.filterText));
  }
}

