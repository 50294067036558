export class ModelTitleValidator {

    public validateTitle(title: string): boolean {
        // Does match windows & firebase restriction.
        if (title)
            return RegExp(/^(?=[\S])[^\\\/:*?"<>|.$[\]#]+$/).test(title);
        return false;
    }

    public static create() {
        return new ModelTitleValidator();
    }

}
