import {ValueAttributeRange, ValueAttributeType} from "./attribute-range";

export class CharRange implements ValueAttributeRange {

    constructor() {
    }

    public setPattern(value: string): { wasSet: boolean } {
        return { wasSet: true };
    }

    public getDefault(): string {
        return 'a';
    }

    public getPattern(): string {
        return 'CHAR';
    }

    public validate(value: string): boolean {
        if (!value)
            return false;
        return value.length == 1;
    }

    public getType(): ValueAttributeType {
        return ValueAttributeType.CHAR;
    }

    public isSubRange(newRange): boolean {
        return true;
    }
}
