import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementAction, ElementCommand, ElementHaloHandle, ElementToolbarHandle } from "./command";

export class InzoomCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    createHaloHandle(): ElementHaloHandle {
      const inInzoomedOPD = this.isInzoomedInCurrentOPD();
      const displayTitle = this.visual.getRefineeInzoom() ? inInzoomedOPD ? 'This is the In-Zoomed view' : 'Show In-Zoomed View' : 'In-Zoom';
      return {
        flag: true,
        name: 'inzoom',
        displayTitle: displayTitle,
        svg: 'inzoom',
        action: new InzoomAction(this.init, this.thing),
        gif: 'assets/gifs/thing_inzooming.gif',
      };
    }

    createToolbarHandle(): ElementToolbarHandle {
      const inInzoomedOPD = this.isInzoomedInCurrentOPD();
      const displayTitle = this.visual.getRefineeInzoom() ? inInzoomedOPD ? 'This is the In-Zoomed view' : 'Show In-Zoomed View' : 'In-Zoom';
      return {
        name: 'inzoom',
        displayTitle: displayTitle,
        svg: 'inzoom',
        action: new InzoomAction(this.init, this.thing),
        gif: 'assets/gifs/thing_inzooming.gif',
      };
    }

    private isInzoomedInCurrentOPD() {
    let inInzoomed = false;
    const InzoomOPD = this.init.opmModel.getOpdByThingId(this.visual.getRefineeInzoom()?.id);
    if (InzoomOPD === this.init.opmModel.currentOpd) {
      inInzoomed = true;
    }
    return inInzoomed;
  }
}

class InzoomAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing) {
    }

    act() {
      const cell = this.init.graph.getCell(this.thing.id);
      if (cell && cell.inzoomAction)
        cell.inzoomAction(this.init);
    }

}
