import { TextualModule } from "./components/interfaces";

export interface LogicalTextModule {
    getDisplayText(): string;
    getName(): string;
    getNameModule(): NameModule;
    updateFromInput(text: string): void;
    addTextualModules(...modules: Array<TextualModule>): void;
}

export interface NameModule {
    shouldAutoFormat(val: boolean): void;
    isAutoFormat(): boolean;
    formatText(val: string): string;
    setText(val: string): void;
    getText(): string;
}

export class BasicNameModule implements NameModule {

    private name: string = '';

    private autoFormatting: boolean = true;

    constructor(private readonly formatter: (text: string) => string) {
    }

    shouldAutoFormat(val: boolean): void {
        this.autoFormatting = val;
    }

    isAutoFormat(): boolean {
        return this.autoFormatting;
    }

    formatText(val: string): string {
        return this.formatter(val);
    }

    setText(val: string): void {
    // TODO: Add some validation function
        val = val.trim();
        if (val.length == 0)
            return;

        if (this.autoFormatting) {
            this.name = this.formatter(val);
            return;
        }
        this.name = val;
    }

    getText(): string {
        return this.name;
    }

}

export class BasicLogicalTextModule implements LogicalTextModule {

    private readonly modules: Array<TextualModule> = new Array();

    constructor(private readonly name: NameModule) {
    }

    public getDisplayText(): string {
      const ret = [];
      const modules = this.getActiveModules();
      modules.filter(m => m.getPriority() < 0).forEach(mdl =>  ret.push(mdl.getText()));
      ret.push(this.name.getText());
      modules.filter(m => m.getPriority() > 0).forEach(mdl =>  ret.push(mdl.getText()));
      return ret.join(' ').trim();
    }

    public getName(): string {
        return this.name.getText();
    }

    // Set name only from input. Other modules are set directly currently.
    public updateFromInput(text: string) {
        this.updateNameFromInput(text);
    }

    public addTextualModules(...modules: Array<TextualModule>) {
        this.modules.push(...modules);
    }

    private updateNameFromInput(text: string) {
        if (!text)
            return;
        let name = this.removeSpecialChars(text);
        const addOn = this.getAddOnText();
        if (addOn.length > 0) {
            const addOnIndex = name.indexOf(addOn);
            if (addOnIndex >= 0)
                name = text.substr(0, addOnIndex - 1).trim();
        }
        this.name.setText(name);
    }

    private getAddOnText(): string {
        return this.modules.filter(m => m.isTextActive()).map(m => m.getText()).join(' ').trim();
    }

    private getActiveModules(): Array<TextualModule> {
      let ret = this.modules.filter(m => m.isTextActive());
      ret = ret.sort( function (a, b) {
        return a.getPriority() > b.getPriority() ? 1 : -1;
      });
      return ret;
    }

    private removeSpecialChars(text: string) {
        return text.split(String.fromCharCode(160)).join(String.fromCharCode(32)).split(String.fromCharCode(10)).join(String.fromCharCode(32));
    }

    public getNameModule(): NameModule {
        return this.name;
    }

}
