import {Component} from '@angular/core';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OpmLogicalEntity} from "../../models/LogicalPart/OpmLogicalEntity";
import {linkType} from "../../models/ConfigurationOptions";
import 'lodash.multipermutations';
import 'lodash.product';
import {isNumber} from "../../configuration/rappidEnviromentFunctionality/shared";
import {createDocumentProperties, insertText, newPage} from "../savePdf-dialog/savePdf";
export const _ = require('lodash');
import jspdf from 'jspdf';
import {DomSanitizer} from "@angular/platform-browser";

@Component({
  selector: 'multi-instances-dialog',
  templateUrl: './multi-instances-dialog.html',
  styleUrls: ['./multi-instances-dialog.css']
})
export class multiInstancesDialog {

  list;
  _dialog: MatDialog;
  configurations;
  filteredConfigurations;
  sliceStart = 0;
  spinnerFlag = false;
  showZeros = false;
  private downloadJsonHref;
  totalModelCombinations = 0;

  constructor(private init: InitRappidService, public dialogRef: MatDialogRef<multiInstancesDialog>, private sanitizer: DomSanitizer) {
    this.list = [];
    this.filteredConfigurations = [];
    this.createList();
    if (!this.init.generatedSelectedConfigurations)
      this.generate();
    else {
      this.filteredConfigurations = this.init.generatedSelectedConfigurations;
      this.totalModelCombinations = this.init.previousTotalConfigurations;
    }
  }

  createList() {
    const logicalEntities = this.init.opmModel.logicalElements.filter(elm => elm instanceof OpmLogicalEntity);
    for (let log of logicalEntities) {
      const instances = (<OpmLogicalEntity<any>>log).getLinks().outGoing.filter(link => link.linkType === linkType.Instantiation).map(lnk =>
      lnk.targetLogicalElements[0]);
      if (instances.length === 0)
        continue;
      const multiAgg = (<OpmLogicalEntity<any>>log).getLinks().inGoing.find(l => l.linkType === linkType.Aggregation &&
        l.visualElements.find(vis => vis.targetMultiplicity));
      const multiplicity = multiAgg ? multiAgg.visualElements.find(vis => vis.targetMultiplicity).targetMultiplicity : 1;
      const selectedValues = {};
      const constrains = [];
      for (const inst of instances) {
        selectedValues[inst.lid] = 0;
        constrains.push('<=')
      }

      this.list.push({ entity: log, instances: instances, multiplicity: Number(multiplicity), selectedValues: selectedValues, visible: false, constrains: constrains});
    }
  }

  getCombinations(n,k) {
    const up = k + n - 1;
    return Math.round(this.factorial(up)/(this.factorial(k) * this.factorial(n - 1)));
  }

  factorial(num) {
    let rval = 1;
    for (let i = 2; i <= num; i++)
      rval = rval * i;
    return rval;
  }

  getTotalModelCombinations() {
    let ret = 1;
    for (const item of this.list)
      ret = ret * this.getCombinations(item.instances.length, item.multiplicity);
    return Math.round(ret);
  }

  onInputChange($event, inst, item) {
    let sum = 0;
    let val = Number($event.target.value);
    if (isNumber(val))
      item.selectedValues[inst.lid] = val;
    for (const inp in item.selectedValues) {
      if (isNumber(Number(item.selectedValues[inp])))
        sum = sum + Number(item.selectedValues[inp]);
    }
    if (sum > item.multiplicity)
      val = Number($event.target.value - 1)
    item.selectedValues[inst.lid] = val;
    $event.target.value = val;
  }

  itemToggle($event, item: any) {
    if ($event.target.className !== 'inputNumber' && $event.target.className !== 'constrainsSelect')
      item.visible = !item.visible;
  }

  close() {
    // this.generate();
    this.dialogRef.close();
    // this.importFiltersListFromJson(this.exportFiltersListToJson())
    // console.log('hi');
  }

  isValidSum (arr, sum) {
    let s = 0;
    for (const n of arr)
    s += n;
    if (s === sum)
      return true;
    return false;
  }

  async generate() {
    this.sliceStart = 0;
    this.spinnerFlag = true;
    await new Promise<any>((resolve, reject) => {
      setTimeout(() => { resolve({}) }, 50);
    });
    const dict = {};
    // { entity: log, instances: instances, multiplicity: Number(multiplicity), selectedValues: selectedValues, visible: false}
    for (const item of this.list) {
      const range = _.range(0, item.multiplicity + 1, 1);
      const multicombinations = _.multipermutations(range, item.instances.length).filter(m => this.isValidSum(m, item.multiplicity)).map(t => {
        const ret = {};
        for (let i = 0 ; i < item.instances.length ; i++) {
          const alias = this.init.opmModel.getLogicalElementByLid(item.instances[i].lid)?.alias;
          ret[item.instances[i].lid] = { value: t[i], constrain: item.constrains[i], selectedValue: item.selectedValues[item.instances[i].lid], alias: alias };
        }
        return ret;
      });
      dict[item.entity.lid] = multicombinations;
    }
    this.generateSelectedConfigurations(dict);
    this.spinnerFlag = false;
    return dict;
  }

  generateSelectedConfigurations(dict) {
    let i = 0;
    this.configurations = _.product(...Object.values(dict)).map(j => {
      i++;
      return Object.assign({refId: i}, ...j);
    });
    this.filterConfigurationsByLogicalPhysibility();
    this.totalModelCombinations = this.filteredConfigurations.length;
    this.updateNumbering();
    this.filterConfigurationsByConstrains();
    this.init.generatedSelectedConfigurations = this.filteredConfigurations;
    this.init.previousTotalConfigurations = this.totalModelCombinations;
  }

  filterConfigurationsByConstrains() {
    this.filteredConfigurations = this.filteredConfigurations.filter(conf => {
      for (const key of this.getConfigurationKeys(conf)) {
        if (conf[key].constrain === '<=') {
          if (conf[key].value < conf[key].selectedValue)
            return false;
        } else {
          if (conf[key].value !== conf[key].selectedValue)
            return false;
        }
      }
      return true;
    });
  }

  filterConfigurationsByLogicalPhysibility() {
    this.filteredConfigurations = this.configurations.filter(conf => {
      for (const key of this.getConfigurationKeys(conf)) {
        const logical = this.init.opmModel.getLogicalElementByLid(key);
        const links = (<any>logical).getLinks();
        const sources = links.inGoing.map(l => l.sourceLogicalElement);
        const targets = [];
        for (const outl of links.outGoing)
          targets.push(...outl.targetLogicalElements);
        for (const src of sources)
          if (conf[key].value !== 0 && conf[src.lid] && conf[src.lid].value === 0)
            return false;
        for (const trgt of targets)
          if (conf[key].value !== 0 && conf[trgt.lid] && conf[trgt.lid].value === 0)
            return false;
      }
      return true;
    });
  }

  updateNumbering() {
    for (let i = 0; i < this.filteredConfigurations.length; i++)
      this.filteredConfigurations[i].refId = i + 1;
  }

  getNameByLid(lid) {
    return (<any>this.init.opmModel.getLogicalElementByLid(lid)).getBareName();
  }

  getConfigurationKeys(conf) {
    return Object.keys(conf).filter(k => k !== 'refId');
  }

  getSubArray() {
    return this.filteredConfigurations.slice(this.sliceStart * 500, this.sliceStart * 500 + 500);
  }

  increaseSliceStart() {
    if (this.sliceStart * 500 + 501 < this.filteredConfigurations.length)
      this.sliceStart++;
  }

  decreaseSliceStart() {
    if (this.sliceStart !== 0)
      this.sliceStart--;
  }

  changeConstrains($event: Event, item: any, idx: number) {
    item.constrains[idx] = (<any>$event.target).value;
  }

  toggleShowZeros($event) {
    this.showZeros = $event.target.checked;
  }

  getHowMuchLeft(item) {
    let sum = 0;
    for (const key of Object.keys(item.selectedValues))
      sum += Number(item.selectedValues[key]);

    return item.multiplicity - sum;
  }

  setCurrentConfiguration(conf) {
    this.init.opmModel.setCurrentConfiguration(conf);
    this.init.graphService.renderGraph(this.init.opmModel.currentOpd);
    this.dialogRef.close();
  }

  exportFiltersListToJson() {
    const ret = [];
    for (const item of this.list) {
      const temp = {};
      temp['constrains'] = item.constrains;
      temp['entity'] = item.entity.lid;
      temp['instances'] = item.instances.map(i => i.lid);
      temp['multiplicity'] = item.multiplicity;
      temp['selectedValues'] = item.selectedValues;
      temp['visible'] = item.visible;
      ret.push(temp);
    }
    const theJSON = JSON.stringify(ret);
    const element = document.createElement('a');
    element.setAttribute('href', "data:text/json;charset=UTF-8," + encodeURIComponent(theJSON));
    const modelName = this.init.modelService.modelObject && this.init.modelService.modelObject.name ? this.init.modelService.modelObject.name : 'unsavedModel'
    element.setAttribute('download', modelName + "-selected-instances-filters.json");
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  }

  importFiltersListFromJson($event) {
    const file = (<any>event.target).files[0];
    if (!file)
      return;
    const reader = new FileReader();
    reader.onload = (e) => {
      const text = reader.result.toString().trim();
      const json = JSON.parse(text);
      for (const item of json) {
        const exist = this.list.find(itm => itm.entity.lid === item.entity);
        if (!exist)
          continue;
        for (const instId of item.instances) {
          const idx = item.instances.indexOf(instId);
          const inner = exist.instances.find(log => log.lid === instId);
          if (inner) {
            const filterIdx = exist.instances.indexOf(inner);
            exist.constrains[filterIdx] = item.constrains[idx];
            exist.selectedValues[instId] = item.selectedValues[instId];
          }
        }
      }
    }
    reader.readAsText(file);
  }

  exportSelectedToPdf() {
    const pdf = new jspdf({ orientation: 'p', unit: 'pt', format: 'a4', putOnlyUsedFonts: true });
    const pdfProps = createDocumentProperties(pdf, 40, 40);
    const modelName = this.init.opmModel.name ? this.init.opmModel.name : 'Unsaved Model';
    pdf.setTextColor('#1A3763');
    insertText('Model Selected Instances: ' + modelName, pdf, pdfProps, false, 'center', 'bold', 18);
    insertText('\n', pdf, pdfProps, false, 'center', 'bold', 8);
    pdf.setTextColor('#000');
    const innerText = $('#decided')[0].innerText.split('\n');
    for (const line of innerText)
      insertText(line, pdf, pdfProps, true, 'left', 'normal', 11);
    pdf.save(modelName + '-selected-instances.pdf');
  }

  inputFileClick() {
    document.getElementById('selectedFile').click();
  }
}
