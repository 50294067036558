import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
import {OplService} from '../../../opl-generation/opl.service';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {UserService} from '../../../rappid-components/services/user.service';
import {validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {
  ModelAnalysisToolsComponent
} from '../../../modules/Settings/model-analysis-tools/model-analysis-tools.component';
import {ServerFlatteningService} from '../../../rappid-components/services/server-flattening.service';
import {ExportOPLAPIService} from "../../../modules/app/export-opl.service";

@Component ({
  selector: 'generative-ai-impact-analysis-dialog',
  templateUrl: 'generative-ai-impact-analysis.html',
  styleUrls: ['generative-ai-impact-analysis.css']
})
export class GenerativeAIImpactAnalysisDialogComponent implements OnInit {

  loggedUser: any;
  response: string;
  tooltip: string;
  waiting: boolean;

  constructor(
    public dialogRef: MatDialogRef<GenerativeAIImpactAnalysisDialogComponent>,
    private exportOPLService: ExportOPLAPIService,
    private userService: UserService,
    private initRappidService: InitRappidService,
    private serverFlatteningService: ServerFlatteningService) {
  }

  ngOnInit() {
    this.waiting = false;
    this.loggedUser = this.userService.user?.userData;
    this.response = 'Generating an Impact Analysis of the model by Gemini AI';
    this.tooltip = 'Note: This action may take time and incur computational costs. ' +
      'Ensure your model is finalized before proceeding, as adjustments cannot be made once analysis begins.';
  }

  async genAIImpactAnalysis() {
    this.waiting = true; // starting the box spinner
    await this.initRappidService.opdHierarchyRef.loadAllSubModels();
    const triplets =  await (new ModelAnalysisToolsComponent(this.initRappidService, this.serverFlatteningService)).createTripletsArray(true);
    const params = [this.initRappidService.opmModel.name, false, null, false];
    const modelOpl: string = await this.exportOPLService.exportOPL(params);
    const AIresponse = await this.userService.getGenAIImpactAnalysis(this.loggedUser.uid, modelOpl, triplets);
    this.response = AIresponse;
    this.waiting = false; // ending the box spinner
  }

  copyGenAIText() {
    navigator.clipboard.writeText(this.response).then().catch(e => console.error(e));
    validationAlert('Generative AI text copied to clipboard', 2000, 'warning');
  }

}
