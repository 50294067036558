import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';

@Component ({
  selector: 'opcloud-confirm-dialog',
  templateUrl: 'confirm-dialog.html',
  styleUrls: ['confirm-dialog.css']
})
export class ConfirmDialogDialogComponent implements OnInit {


  constructor(
    @Optional() public dialogRef: MatDialogRef<ConfirmDialogDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (!this.data.closeName) { this.data.closeName = 'CLOSE'; }
    if (!this.data.okName) { this.data.okName = 'OK'; }
    if (this.data.closeFlag === true) {
      const closeButt = document.getElementById('closeButton');
      closeButt.style.display = 'none';
    }
  }


}
