// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.divs {
  position: relative;
  width: 650px;
  left: 50px;
  top: 50px;
}

p{
  text-align: center;
}

.sk-cube-grid .sk-cube {
  background-color: rgba(26, 55, 99, 0.6);
}

.GenAIText {
  min-width: 500px;
  min-height: 250px;
  color: #1a3661;
  overflow:auto;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 14px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/opl-generative-ai-dialog/opl-generative-ai.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,aAAa;EACb,iDAAiD;EACjD,eAAe;AACjB","sourcesContent":[".divs {\n  position: relative;\n  width: 650px;\n  left: 50px;\n  top: 50px;\n}\n\np{\n  text-align: center;\n}\n\n.sk-cube-grid .sk-cube {\n  background-color: rgba(26, 55, 99, 0.6);\n}\n\n.GenAIText {\n  min-width: 500px;\n  min-height: 250px;\n  color: #1a3661;\n  overflow:auto;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 14px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
