import {OpmTaggedLink} from './OpmTaggedLink';
import {linkType} from '../../ConfigurationOptions';
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {popupGenerator, stylePopup, validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';

export class BiDirectionalTaggedLink extends OpmTaggedLink {
  backwardTag: string;
  constructor(sourceElement, targetElement ,id?:string) {
    super(sourceElement, targetElement ,id);
   // this.attr({'.marker-source' : {fill: 'none', d: 'M20,33 L0,23 L20,23'}});
   // this.attr({'.marker-target' : {fill: 'none', d: 'M20,33 L0,23 L20,23'}});


    this.attr('line/sourceMarker', {
      type: 'polyline', // SVG polyline
      fill: 'none',
      stroke: '#586D8C',
      'strokeWidth': 2,
      points:'0.5,0 20,10'
    });

    this.attr('line/targetMarker', {
      type: 'polyline', // SVG polyline
      fill: 'none',
      stroke: '#586D8C',
      'strokeWidth': 2,
      points:'0.5,0 20,10'
    });

    this.attributes.name = 'Bidirectional_Tagged_Link';

  }
  getParams() {
    const params = {
      sourceMultiplicity: this.get('sourceMultiplicity'),
      targetMultiplicity: this.get('targetMultiplicity'),
      tag: this.get('tag'),
      backwardTag: this.get('backwardTag'),
      linkType: linkType.Bidirectional
    };
    return {...super.getTaggedLinkParams(), ...params};
  }

  popupContentDbClick() {
    const sourceMultiplicity = (this.attributes.sourceMultiplicity) ? this.attributes.sourceMultiplicity : '';
    const targetMultiplicity = (this.attributes.targetMultiplicity) ? this.attributes.targetMultiplicity : '';
    const tag = (this.attributes.tag) ? this.attributes.tag : '';
    const backwardTag = (this.attributes.backwardTag) ? this.attributes.backwardTag : '';
    return super.popupContentDbClick().concat(['<div style="height: 16px"> <div class="textAndInput">Backward Tag: <input size="2" class="btag PopupInput" value="' + backwardTag.trim() + '">' +
      '</div><span data-title="' + this.getBackwardtagPopupTooltipText() + '"><img class="questionMarkForInfo"src="assets/SVG/questionmark.svg"></span></div><br>']);
  }

  updateBackwardTagLabel(oldVal: string, newVal: string, link: OpmTaggedLink, init: InitRappidService) {
    const visuals = this.getVisual().logicalElement.visualElements;
    let newLabel;
    if (newVal)
      newLabel = link.setLabelsOLinks(newVal, 0.2, null, 10);
    link.set('backwardTag', newVal);
    for (const vis of visuals) {
      (<any>vis).backwardTag = newVal;
      if (!(<any>vis).labels && newLabel)
        (<any>vis).labels = [newLabel];
      else if ((<any>vis).labels && newVal) {
        const old = (<any>vis).labels.find(lb => lb.attrs.label.text === oldVal);
        if (old)
          (<any>vis).labels.splice((<any>vis).labels.indexOf(old), 1);
        if (newLabel)
          (<any>vis).labels.push(newLabel);
      }
    }
  }

  popupEventsDbClick(element, init?: InitRappidService) {
    const this_ = this;
    return {
      'click .urlSvg': function() {
        const dataToRemember = this_.getPopupDataToRemember(this);
        this_.openLinkURLEditing(init).afterClosed().toPromise().then(res => {
          this_.rightClickHandlePopoup(init.paper.findViewByModel(element).el, init);
          this_.restorePopupData(dataToRemember);
        });
        this.remove();
      },
      'click .btnUpdate': function() {
        init.getOpmModel().logForUndo('link labels update');
        if (element.labels().length > 0)
          element.deleteLabel();
        const targtLabel = (/\S/.test(this.$('.trgt').val())) ? this.$('.trgt').val().toLowerCase().trim() : undefined;
        this_.updateTargetMultiplicity(element.get('targetMultiplicity'), targtLabel, element, init);
        const sourceLabel = (/\S/.test(this.$('.srce').val())) ? this.$('.srce').val().toLowerCase().trim() : undefined;
        this_.updateSourceMultiplicity(element.get('sourceMultiplicity'), sourceLabel, element, init);
        const tagLabel = (/\S/.test(this.$('.tag').val())) ? this.$('.tag').val().trim() : undefined;
        const backTagLabel = (/\S/.test(this.$('.btag').val())) ? this.$('.btag').val().trim() : undefined;
        // element.setLabelsOLinks(tagLabel, 0.7, null);
        // element.setLabelsOLinks(backTagLabel, 0.3, null, -30);
        element.set('sourceMultiplicity', this.$('.srce').val()?.trim());
        element.getVisual().sourceMultiplicity = this.$('.srce').val()?.trim();
        element.set('targetMultiplicity', this.$('.trgt').val()?.trim());
        element.getVisual().targetMultiplicity = this.$('.trgt').val()?.trim();
        this_.updateTagLabel(element.get('tag'), tagLabel, element, init);
        this_.updateBackwardTagLabel(element.get('backwardTag'), backTagLabel, element, init);
        this_.fixArrowDirection()
        element.set('tag', tagLabel);
        element.set('backwardTag', backTagLabel);
        this_.updateRequirementsLabel(element.get('requirements'), this.$('.req').val().trim(), this.$('.showReq')[0].checked, element, init);
        element.set('requirements', this.$('.req').val().trim());
        element.set('showRequirementsLabel', this.$('.showReq')[0].checked);
        this_.addDblClickListenerForLabels();
        this.remove(); },
      /**
       * group 04:
       * By clicking on the 'Copy Style' button, we keep the style of the source link in the 'linkCopiedStyleParams' dictionary.
       */
      'click .btnStyleCopy': function () {
        this.remove();
        init.linkCopiedStyleParams = {};
        init.linkCopiedStyleParams['strokeWidth'] = this_.attr('line/strokeWidth');
        init.linkCopiedStyleParams['strokeColor'] = this_.attr('line/stroke');
      },
      'click .btnStyle': function () {
        this.remove();
        const stylePopupContent = ['Link color: <input type="color" class="linkColor PopupColorInput" value=' + this_.attr('link/fill') + '><br>',
          'Link width: <input type="width" style="width:35px;padding-top: 5px" class="linkwidth PopupInput" value=' + this_.attr('line/strokeWidth') + '><br>',
          '<button style="padding-top: 5px;margin-left: 6px" class="btnUpdateStyle Popup">Update Style</button>'];
        const stylePopupEvents = {
          'click .btnUpdateStyle': function () {
            if ( this.$('.linkwidth').val() < '1' || this.$('.linkwidth').val() > '6') {
              const errorMessage = 'Maximum width is 6';
              validationAlert(errorMessage, 5000, 'Error');
              return;
            }
            init.getOpmModel().logForUndo('link style change');
            this_.attr({line: {'stroke': this.$('.linkColor').val()}});
            this_.attr({line: {'strokeWidth': this.$('.linkwidth').val()}});

            this.remove();
          }
        };
        const el = init.paper.findViewByModel(this_).el;
        popupGenerator(el, stylePopupContent, stylePopupEvents).render();
        stylePopup();
        (<HTMLInputElement>$('.linkColor')[0]).value =  this_.attr('line/stroke');
      }
    };
  }

  getBackwardtagPopupTooltipText(): string {
    return 'A bidirectional tagged structural link is a combination of two tagged structural links in opposite\n' +
      'directions. The bidirectional default tag is the phrase “related”';
  }

  fixArrowDirection() {
    if (this.getTargetPoint().x - this.getSourcePoint().x > 0) {
      this.attr('line/sourceMarker', {
        type: 'polyline', // SVG polyline
        fill: 'none',
        stroke: '#586D8C',
        'strokeWidth': 2,
        points:'0.5,0 20,10'
      });
      this.attr('line/targetMarker', {
        type: 'polyline', // SVG polyline
        fill: 'none',
        stroke: '#586D8C',
        'strokeWidth': 2,
        points:'0.5,0 20,10'
      });
    } else {
      this.attr('line/sourceMarker', {
        type: 'polyline', // SVG polyline
        fill: 'none',
        stroke: '#586D8C',
        'strokeWidth': 2,
        points:'0.5,0 20,-10'
      });
      this.attr('line/targetMarker', {
        type: 'polyline', // SVG polyline
        fill: 'none',
        stroke: '#586D8C',
        'strokeWidth': 2,
        points:'0.5,0 20,-10'
      });
    }

  }

}
