import { validationAlert } from '../rappidEnviromentFunctionality/shared';
import { OpmModel } from '../../models/OpmModel';
import { OpmOpd } from '../../models/OpmOpd';
import { handleExeExceptions, hasWhiteSpaces, ObjectItem, elmIsSubObject } from './computationalPartUtils';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { VariablesCalculator } from './variables-calculator';
import { WebSocketCommunicatorI } from './communication-object';
import { code } from '../../models/ConfigurationOptions';

/**
 * A class that handles external functions.
 * opmModel - the current opm model
 * initRappid - init rappid service
 * */
export class RosFunctionExecutor {
  constructor(private readonly opmModel: OpmModel, private readonly rosWS: WebSocketCommunicatorI) {
  }

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], rosFunction) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.ROS);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    const variables_array = this.calc_variables_array(all_paths, valuesArray);
    return this.runROSFunction(variables_array, rosFunction, variables_array);
  }

  private async runROSFunction(valuesArray, ROSFunction, variables_array: ObjectItem[]) {
    const that = this;
    let x;
    const message = this.calcAndValidateMessage(valuesArray, ROSFunction, variables_array);
    if (that.rosWS.isActive) {
      if (ROSFunction.ROStopicwhat == "publish") {
        that.rosWS.send({
          what: ROSFunction.ROStopicwhat,
          // topic: ROSFunction.ROStopic,
          // data_type: ROSFunction.ROStopic_type,
          topic: that.replace_topic_or_data_type(true, ROSFunction.ROStopic, variables_array),
          data_type: that.replace_topic_or_data_type(false, ROSFunction.ROStopic_type, variables_array),
          message: message
        });
        x = "published";
      } else if (ROSFunction.ROStopicwhat == "subscribe") {
        that.rosWS.send({
          what: ROSFunction.ROStopicwhat,
          // topic: ROSFunction.ROStopic,
          // data_type: ROSFunction.ROStopic_type,
          topic: that.replace_topic_or_data_type(true, ROSFunction.ROStopic, variables_array),
          data_type: that.replace_topic_or_data_type(false, ROSFunction.ROStopic_type, variables_array),
        });
        x = await that.rosWS.get();
      } else if (ROSFunction.ROStopicwhat == "service") {
        that.rosWS.send({
          what: ROSFunction.ROStopicwhat,
          // topic: ROSFunction.ROStopic,
          // data_type: ROSFunction.ROStopic_type,
          topic: that.replace_topic_or_data_type(true, ROSFunction.ROStopic, variables_array),
          data_type: that.replace_topic_or_data_type(false, ROSFunction.ROStopic_type, variables_array),
          message: message
        });
        x = "service";
      } else if (ROSFunction.ROStopicwhat == "script") {
        that.rosWS.send({
          what: ROSFunction.ROStopicwhat,
          ROSScript: ROSFunction.ROS_Script
        });
        //x = "script";
        x = await that.rosWS.get();
      }
    } else {
      x = '';
      validationAlert('No ROS connection established!', 2500, undefined, true);
    }
    return x;
  }

  /**
   * returns the message that should be sent (undefined if there is no need to send a message)
   */
  calcAndValidateMessage(valuesArray: any, ROSFunction: any, variables_array: ObjectItem[]) {
    if (ROSFunction.ROStopicwhat === 'publish' || ROSFunction.ROStopicwhat === 'service'){
      // const variables_array_concated_ids_as_set = new Set(variables_array.map(elm => elm.concatedId));
      // const amount_of_instrumented_links_computational_object = new Set(variables_array.filter(elm => elm.concatedId.split('_').length > 2)).size;
      const variables_array_as_ids = variables_array.map(elm => elm.concatedId);
      const amount_of_different_computational_object = new Set((variables_array.filter(elm => !elmIsSubObject(elm.concatedId,variables_array_as_ids))).map(elm => elm.concatedId)).size; // the set is needed to prevent an object with alias and name to appear twice
      const rosMessage = ROSFunction.ROSMessage;
      const rosMessageInVariableArray = variables_array.filter(value => value.name === rosMessage || value.alias === rosMessage)[0];
      const rosMessageIsEmpty = !rosMessage || (rosMessage === '');
      const rosMessageVariable = ( rosMessageIsEmpty || hasWhiteSpaces(rosMessage)) ? undefined : rosMessageInVariableArray;
      let returnedRosMessage;
      if (ROSFunction.ROStopic_type === 'std_msgs/Empty'){
        return '';
      }
      if (!rosMessageIsEmpty && hasWhiteSpaces(rosMessage) && rosMessageInVariableArray === undefined ){
        handleExeExceptions('The inserted message is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
      }
      if (rosMessageVariable === undefined){
        // if (variables_array_concated_ids_as_set.size > 1) { // the same object whether if they will have different identifiers will have the same concatedID
        if(!rosMessage) {
          if (amount_of_different_computational_object > 1) { // the same object whether if they will have different identifiers will have the same concatedID
            handleExeExceptions('A ROS computational process can have only one connected computational object.');
          } else {
            if (variables_array.length === 0) {
              handleExeExceptions('A ROS computational process must have one connected computational object (unless its defined to be empty).');
            }
          }
        }else{
          if (amount_of_different_computational_object > 0) {
            validationAlert('Using the internal process defined ROS message; Disregarding the connected computational objects', 4500, undefined, true);
          }
          returnedRosMessage = rosMessage;
        }
      }
      // there should be a valid message or one computational object (or its subs)
      return  returnedRosMessage ? returnedRosMessage : rosMessageVariable ? rosMessageVariable.sourceElementValue : valuesArray[0].sourceElementValue;
      //old return, disregarding the ros message in the process
      //rosMessageVariable ? rosMessageVariable.sourceElementValue : valuesArray[0].sourceElementValue;
    } else { // the other topic what are subscribe/script and they doesnot require a message
      return undefined;
    }
  }
  /**
   * A function that calculates variables_array, which is an ObjectItem array that includes for each object in it the
   * the object name,alias and value.
   **/
  private calc_variables_array(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[]) {
    let all_variables_str = [];
    let variables_array = [];
    let alias = [];
    new VariablesCalculator(all_paths, valuesArray).calc_variables_str(all_variables_str, code.ROS,
      [], alias, variables_array);
    return variables_array;
  }

  /**
   * replace the topic/data with it's value (if a variable with this alias/name exists)
   * is_topic - a boolean to indicate if the string we want to replace represents a topic or data_type.
   * topic_or_data_type - the string we are checking if it is a variable that should be replaced by its value
   * variables_array - includes the variables and their values
   **/
  private replace_topic_or_data_type(is_topic: boolean, topic_or_data_type: string, variables_array: ObjectItem[]) {
    const to_check = (is_topic && (topic_or_data_type.length > 1)) ? topic_or_data_type.substring(1) : topic_or_data_type;
    const variable = hasWhiteSpaces(to_check) ? undefined : variables_array.filter(value => value.name === to_check || value.alias === to_check)[0]; // do not replace variables without legal identifiers
    if (variable !== undefined) {
      return is_topic ? '/' + variable.sourceElementValue : '' + variable.sourceElementValue;
    } else {
      return topic_or_data_type;
    }
  }
}
