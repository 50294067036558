import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { ElementCommand, CommandsDecider } from "./command";
import { OpmState } from "../../DrawnPart/OpmState";
import { OpmVisualState } from "../../VisualPart/OpmVisualState";
import { RemoveCommand } from "./remove";
import { StylingCommand } from "./style";
import { SetStateTimeDurationCommand } from "./set-state-time-duration";
import { SuppressStateCommand } from "./suppress-state";
import { ToggleTextAutoFormatCommand } from "./toggle-text-formation";

export class StateCommandsDecider extends CommandsDecider {

    private drawn: OpmState;
    private init: InitRappidService;
    private visual: OpmVisualState;

    set(init: InitRappidService, drawn: OpmState, visual: OpmVisualState): CommandsDecider {
        this.init = init;
        this.drawn = drawn;
        this.visual = visual;
        return this;
    }

    private remove(): ElementCommand {
        return new RemoveCommand(this.init);
    }

    private style(): ElementCommand {
        return new StylingCommand(this.init, this.drawn, this.visual);
    }

    private setTimeDuration(): ElementCommand {
        return new SetStateTimeDurationCommand(this.init, this.drawn, this.visual);
    }

    private suppress(): ElementCommand {
        return new SuppressStateCommand(this.init, this.drawn, this.visual);
    }

    private toggleTextAutoFormat(): ElementCommand {
        return new ToggleTextAutoFormatCommand(this.init, this.drawn, this.visual);
    }

    getHaloCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();
        if (this.visual.isValueTyped())
            return commands;
        if (this.visual.isFatherComputational() == false)
            commands.push(this.remove(), this.suppress());
        commands.push(this.style(), this.setTimeDuration());
        return commands;
    }

    getToolabarCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();

      if (this.visual.logicalElement.opmModel.currentOpd.requirementViewOf)
        return commands;

        if (this.visual.isFatherComputational() == false && this.visual.isValueTyped() == false)
            commands.push(this.remove(), this.setTimeDuration());
        if (this.visual.canModifyText())
            commands.push(this.toggleTextAutoFormat());
        if (this.visual.isFatherComputational() === false && !this.visual.isValueTyped())
          commands.push(this.remove(), this.suppress());
        return commands;
    }

}
