import {OpmProceduralLink} from './OpmProceduralLink';
import {linkType} from "../../ConfigurationOptions";

export class OvertimeExceptionLink extends OpmProceduralLink {
  constructor(sourceElement, targetElement, condition, event ,id?: string) {
    super(sourceElement, targetElement, condition, event ,id);
   // this.attr({'.marker-source' : {d: ''}});
    //this.attr({'.marker-target' : {d: 'M30,46 L46,26 M26,36 L50,36'}});

    this.attr('line/targetMarker', {
      type: 'polyline', // SVG polyline
      fill: 'none',
      stroke: '#586D8C',
      'strokeWidth': 2,
      points:'4,10 13,-10 '
    });
    this.attributes.name = 'Overtime_exception';
  }
  getParams() {
    const params = { linkType: linkType.OvertimeException };
    return {...super.getProceduralLinkParams(), ...params};
  }
  getSourceMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances of initiating processes that didn\'t complete successfully to invoke the target process, if greater than 1';
  }
  getTargetMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances of destination processes to be invoked, if greater than 1';
  }
}
