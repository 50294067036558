// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#footer {
  text-align: center;
  margin-top: 30px;
}

#editor {
  width: calc(100% - 100px);
  height: 375px;
  margin-top: 7px;
}

#header {
  text-align: center;
  color: #1a3763;
}

#subject {
  border: 1px solid lightgrey;
  height: 30px;
  width: 400px;
  border-radius: 10px;
  padding-left: 10px;
  margin-top: 15px;
}

#adminConfirmDiv {
  margin-top: 20px;
  height: 19px;
}

::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {
  border-radius: 10px 10px 0 0 !important;
}

::ng-deep .mat-focused .mat-form-field-label {
  color: #1a3661 !important;
}

::ng-deep.mat-form-field-underline {
  background-color: #1a3661 !important;
}

::ng-deep.mat-form-field-ripple {
  background-color: #1a3661 !important;
}

.error {
  color: red;
  font-size: small;
}

.field {
  background: #f6f6f6;
  height: 55px;
  padding: 5px;
  margin-top: 18px;
  margin-right: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/create-popup-message/create-popup-message.component.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;EACzB,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,2BAA2B;EAC3B,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,kBAAkB;EAClB,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,UAAU;EACV,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;EACnB,YAAY;EACZ,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;AACnB","sourcesContent":["\n#footer {\n  text-align: center;\n  margin-top: 30px;\n}\n\n#editor {\n  width: calc(100% - 100px);\n  height: 375px;\n  margin-top: 7px;\n}\n\n#header {\n  text-align: center;\n  color: #1a3763;\n}\n\n#subject {\n  border: 1px solid lightgrey;\n  height: 30px;\n  width: 400px;\n  border-radius: 10px;\n  padding-left: 10px;\n  margin-top: 15px;\n}\n\n#adminConfirmDiv {\n  margin-top: 20px;\n  height: 19px;\n}\n\n::ng-deep .mat-form-field-appearance-fill .mat-form-field-flex {\n  border-radius: 10px 10px 0 0 !important;\n}\n\n::ng-deep .mat-focused .mat-form-field-label {\n  color: #1a3661 !important;\n}\n\n::ng-deep.mat-form-field-underline {\n  background-color: #1a3661 !important;\n}\n\n::ng-deep.mat-form-field-ripple {\n  background-color: #1a3661 !important;\n}\n\n.error {\n  color: red;\n  font-size: small;\n}\n\n.field {\n  background: #f6f6f6;\n  height: 55px;\n  padding: 5px;\n  margin-top: 18px;\n  margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
