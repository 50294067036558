import {MethodologicalChecker, MethodologicalCheckingStatus} from "../methodological-checker";
import {OpmModel} from "../../../models/OpmModel";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OpmVisualThing} from "../../../models/VisualPart/OpmVisualThing";
import {OpmLogicalThing} from "../../../models/LogicalPart/OpmLogicalThing";

export class InzoomedContentChecker extends MethodologicalChecker {

  constructor(model: OpmModel) {
    super(model);
    this.title = 'In-zoomed thing content';
  }

  check(): void {
    this.invalidThings = [];
    for (const opd of this.model.opds.filter(o => !o.isHidden)) {
      for (const vis of opd.visualElements) {
        if (OPCloudUtils.isInstanceOfVisualThing(vis)) {
         const refinee = (<OpmVisualThing>vis).getRefineeInzoom() as OpmVisualThing;
         if (refinee && refinee.children.length < 2) {
           this.addToInvalidThings(refinee.logicalElement as OpmLogicalThing<OpmVisualThing>);
         }
        }
      }
    }
    this.status = this.invalidThings.length === 0 ? MethodologicalCheckingStatus.VALID : MethodologicalCheckingStatus.INVALID;
  }

  getDescriptionTooltip(): string {
    return `An in-zoom thing must contain at least two things.`;
  }
}
