import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {FileUploader} from 'ng2-file-upload';

global.Buffer = require('buffer').Buffer;
@Component({
  selector: 'opcloud-nlp-model-analysis',
  templateUrl: './nlp-model-analysis.component.html',
  styleUrls: ['./nlp-model-analysis.component.css']
})
export class NlpModelAnalysisComponent implements OnInit {

  private nlp;
  private content: Array<string>;
  private bowArr;
  private modelLogicalThingsNames;
  private opl;
  private tokenTypes = ['Currency', 'Email', 'Emoji', 'Emoticon', 'Hashtag', 'Number',
    'Ordinal', 'Punctuation', 'Quoted Phrase', 'Symbol', 'Time', 'Mention', 'URL', 'Word'];
  private uploader: FileUploader = new FileUploader({}); // Empty options to avoid having a target URL
  private file;
  private requirementsText: string;

  constructor(private initRappidService: InitRappidService) {
    const winkNLP = require( 'wink-nlp' );
      // Load "its" helper to extract item properties.
    const its = require( 'wink-nlp/src/its.js' );
      // Load "as" reducer helper to reduce a collection.
    const as = require( 'wink-nlp/src/as.js' );
    const model = require( 'wink-eng-lite-web-model' );
    this.nlp = winkNLP( model );
    // Set the needed text to analyze with NLP
    const opl = this.getModelOpl(initRappidService);
    const modelThings = this.getModelThingsNames(initRappidService);
    // NLP Code.
    const doc = this.nlp.readDoc( opl.opltext );
    const nlpModelNames = this.nlp.readDoc( modelThings );
    // Setting up the data for display
    this.content = doc.tokens().out( its.type, as.freqTable );
    // OPL bag of words
    this.bowArr = this.getSortedBow(doc, its, as);
    // Model names bag of words
    this.modelLogicalThingsNames = this.getSortedBow(nlpModelNames, its, as);
    // All OPL for display
    this.opl = opl;
  }

  ngOnInit() {
  }

  /**
   * Using the NLP parameter to extract the text content, and filter it for type 'word' only. Then,
   * creating bag of words with the #its and #as parameters, and finally sorting them in descending order.
   * @param nlpText
   * @param its
   * @param as
   * @private
   */
  private getSortedBow(nlpText, its, as) {
    let tokensArray = new Array;
    tokensArray = Object.entries(nlpText.tokens()
      .filter( ( t ) => ( t.out( its.type ) === 'word' ) ).out(its.value, as.bow));
    return tokensArray.sort(function(a, b) {
      return b[1] - a[1];
    });
  }

  /**
   * Using the #initRappidService parameter for getting the full model OPL.
   * Returning it as a text and also HTML.
   * @param initRappidService
   * @private
   */
  private getModelOpl(initRappidService: InitRappidService) {
    const currentOpd = initRappidService.opmModel.currentOpd;
    let allOpl = '';
    let innerHTML = '';
    const oplDisplay = new Array();
    // Get all existing OPDs in the graph.
    const allOpd = initRappidService.opmModel.opds.filter(o => !o.isHidden);
    for (let i = 0; i < allOpd.length; i++) {
      initRappidService.graphService.renderGraph(allOpd[i], this.initRappidService); // Goes to the next OPD in the allOpd array.
      // Get the cells that contain the OPL sentences of the current OPD.
      const cells = initRappidService.oplService.generateOpl();
      for (let j = 0; j < cells.length; j++) {
        if (cells[j].opl) { // Check if the cell contains an OPL sentence.
          // Get the OPL sentence of the cell.
          innerHTML = cells[j].opl;
          oplDisplay.push(innerHTML + '\n'); /// Insert the OPL sentence into the arrOfAllOpl array.
          allOpl += innerHTML.replace(/<[^>]*>?/gm, '') + ' ';
        }
      }
    }
    initRappidService.graphService.renderGraph(currentOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
    return {opltext: allOpl, oplForDisplay: oplDisplay};
  }

  /**
   * Retrieving all the model things names: Objects, Processes and States' values
   * @param initRappidService
   * @private
   */
  private getModelThingsNames(initRappidService: InitRappidService) {
    // Get all existing elements names in the model.
    let modelNames = '';
    initRappidService.opmModel.logicalElements.filter(
      log => log.constructor.name.includes('Object') || log.constructor.name.includes('Process') ||
        log.constructor.name.includes('State')).forEach(element => modelNames += this.getThingsName(element) + ' ');
    return modelNames;
  }

  /**
   * Helper function for #getModelThingsNames function. Checks if the thing is a state to get its value not
   * the actual state constant name.
   * @param element
   * @private
   */
  private getThingsName(element) {
    if (element.constructor.name.includes('State')) {
      if (element.getParams().text !== 'value') {
        return element.getParams().text;
      }
    } else {
      return element.getName();
    }
    return '';
  }

  /**
   * Loading the text file content and calling the #showSimilarityScore function.
   * @param event
   * @private
   */
  private loadRequirementsFile(event) {
    if (event.target.files.length === 0) {
      this.file = null;
      return;
    }
    this.file = event.target.files[0];
    const fileReader = new FileReader();
    const that = this;
    fileReader.onload = (e) => {
      typeof fileReader.result === 'string' ? that.requirementsText = fileReader.result : that.requirementsText = '';
      that.showSimilarityScore();
    };
    fileReader.readAsText(this.file);
  }

  /**
   * Showing the similarity score of the model OPL and the requirements text file bag of words.
   * This function use the NLP service function 'similarity'.
   * @private
   */
  private showSimilarityScore() {
    // Load "its" helper to extract item properties.
    const its = require( 'wink-nlp/src/its.js' );
    // Load "as" reducer helper to reduce a collection.
    const as = require( 'wink-nlp/src/as.js' );
    const similarity = require('wink-nlp/utilities/similarity.js');
    const reqTxt = this.nlp.readDoc( this.requirementsText );
    const nlpModel = this.nlp.readDoc( this.opl.opltext );
    const bowR = reqTxt.tokens().out(its.value, as.bow);
    const bowM = nlpModel.tokens().out(its.value, as.bow);
    const simValue = similarity.bow.cosine(bowR, bowM);
    document.getElementById('compareReqBowResults').innerHTML =
      '<h3 style="position: relative;color: #1A3763">Similarity Score: ' + simValue + '</h3>';
  }
}
