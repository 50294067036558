import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class SetComputationCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: true,
            name: 'set-computation',
            displayTitle: 'Computation',
            svg: 'computation',
            action: new SetComputationAction(this.init, this.thing),
            gif: 'assets/gifs/computation.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'set-computation',
            displayTitle: 'Computation',
            svg: 'computation',
            action: new SetComputationAction(this.init, this.thing),
            gif: 'assets/gifs/computation.gif',
        }
    }

}

class SetComputationAction implements ElementAction {

    constructor(private readonly init: InitRappidService,
        private readonly thing: OpmThing) {
    }

    act() {
      const cell = this.init.graph.getCell(this.thing.id);
      if (cell)
        cell.computation(cell, this.init);
    }

}
