import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {getInitRappidShared, OPCloudUtils} from '../../configuration/rappidEnviromentFunctionality/shared';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {OpmModel, RemoveType} from '../../models/OpmModel';
import {OpmLink} from '../../models/VisualPart/OpmLink';
import {OpmVisualThing} from '../../models/VisualPart/OpmVisualThing';
import {OpmDefaultLink} from '../../models/DrawnPart/Links/OpmDefaultLink';
import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";

@Component ({
  selector: 'opcloud-remove-opertaion-dialog',
  templateUrl: 'remove-operation.html',
  styleUrls: ['remove-operation.css']
})
export class RemoveOperationComponent implements OnInit {

  public initRappid: InitRappidService;

  constructor(
    @Optional() public dialogRef: MatDialogRef<RemoveOperationComponent>) {
    this.initRappid = getInitRappidShared();
  }

  ngOnInit() {
  }

  // getOccurrences() {
  //   const drawn = this.initRappid.getElementToRemove();
  //   if (!drawn)
  //     return [];
  //   const oc = this.initRappid.getOpmModel().getLogicalElementByVisualId(drawn.id).visualElements;
  //   const data = new Array();
  //   for (const vis of oc) {
  //     if (vis.id) {
  //       const opd = this.initRappid.getOpmModel().getOpdByThingId(vis.id);
  //       data.push({name: opd.getName(), visualId: vis.id});
  //     }
  //   }
  //   return data;
  // }

  shouldBeRedColored() {
    return this.numberOfOPDsAppearsAt() <= 1;
  }

  instancesInOpd(): Array<OpmVisualThing> {
    const drawn = this.initRappid.getElementToRemove();
    if (!drawn)
      return [];
    const visual = this.initRappid.opmModel.getVisualElementById(drawn.id);
    if (!visual)
      return [];
    const opd = this.initRappid.opmModel.getOpdByThingId(drawn.id);
    return <Array<OpmVisualThing>>(opd.visualElements.filter(th => th.logicalElement === visual.logicalElement));
  }

  isInzoomed(): boolean {
    const drawn = this.initRappid.getElementToRemove();
    if (!drawn)
      return true;
    const visual = this.initRappid.opmModel.getVisualElementById(drawn.id);
    if (!visual)
      return true;
    return !!((<OpmVisualThing>visual).getRefineable());
  }

  isLastOccourence() {
    const drawn = this.initRappid.getElementToRemove();
    if (!drawn)
      return true;
    const logical = this.initRappid.opmModel.getLogicalElementByVisualId(drawn.id);
    if (!logical)
      return true;
    return logical.visualElements.length === 1;
  }

  numberOfOPDsAppearsAt() {
    const drawn = this.initRappid.getElementToRemove();
    if (!drawn)
      return 1;
    const logical = this.initRappid.opmModel.getLogicalElementByVisualId(drawn.id);
    if (!logical)
      return 1;
    const visuals = logical.visualElements;
    const opds = [];
    visuals.forEach(vis => {
      const opd = this.initRappid.opmModel.getOpdByThingId(vis.id);
      if (opd && !opds.includes(opd.id)) {
        opds.push(opd.id);
      }
    });
    return opds.length;
  }

  removeLocally() {
    const drawn = this.initRappid.getElementToRemove();
    this.initRappid.onRemoveOptionChosen(drawn, RemoveType.Localy);
    this.initRappid.setElementToRemoveToNull();
    this.dialogRef.close();
  }

  removeInOPD() {
    const drawn = this.initRappid.getElementToRemove();
    this.initRappid.onRemoveOptionChosen(drawn, RemoveType.InThisOPDOnly);
    this.initRappid.setElementToRemoveToNull();
    this.dialogRef.close();
  }

  removeInModel() {
    const model: OpmModel = this.initRappid.getOpmModel();
    const drawn = this.initRappid.getElementToRemove();
    if (!drawn) {
      this.dialogRef.close();
      return;
    }
    const logical = model.getLogicalElementByVisualId(drawn.id);
    if (drawn.constructor.name.includes('Default')) {
      const triangle = drawn.getTargetElement();
      const linksToRemove = this.initRappid.getGraphService().getGraph().getConnectedLinks(triangle, {outbound: true});
      for (const lnk of linksToRemove) {
        const lgc = this.initRappid.opmModel.getLogicalElementByVisualId(lnk.id);
        const rr = model.getRelatedRelationsByLogicalLink(<any>lgc);
        if (rr) {
          for (let i = rr.length - 1 ; i >= 0 ; i--) {
            if (rr[i] && rr[i].visualElements[0])
              this.initRappid.onRemoveOptionChosen(drawn, RemoveType.AllOPDs, rr[i].visualElements[0]);
          }
        }
        this.initRappid.onRemoveOptionChosen(lnk, RemoveType.AllOPDs);
      }
    } else if (logical && model.getRelatedRelationsByLogicalLink(<any>logical)) {
      const related = model.getRelatedRelationsByLogicalLink(<any>logical);
      for (let i = related.length - 1 ; i >= 0 ; i--) {
        if (related[i] && related[i].visualElements[0])
          this.initRappid.onRemoveOptionChosen(drawn, RemoveType.AllOPDs, related[i].visualElements[0]);
      }
    } else
        this.initRappid.onRemoveOptionChosen(drawn, RemoveType.AllOPDs);
    this.initRappid.setElementToRemoveToNull();
    this.dialogRef.close();
  }

  checkIfLink() {
    const toRemove = this.initRappid.getElementToRemove();
    if (toRemove)
      return toRemove.constructor.name.includes('link') || toRemove.constructor.name.includes('Link');
    return false;
  }

  isStereotyped() {
    const toRemove = this.initRappid.getElementToRemove();
    if (toRemove) {
      if (toRemove instanceof OpmDefaultLink) {
        return false;
      }
      return !!toRemove.getVisual().logicalElement.visualElements.find(vis => {
          const visOpd = this.initRappid.getOpmModel().getOpdByThingId(vis.id);
          return visOpd?.isHidden && visOpd?.requirementsOpd !== true;
      });
    }
    return false;
  }

  isRequirementsSetObject(): boolean {
    const toRemove = this.initRappid.getElementToRemove();
    if (toRemove && OPCloudUtils.isInstanceOfLogicalThing(toRemove.getVisual()?.logicalElement)) {
      const logical = toRemove.getVisual()?.logicalElement as OpmLogicalThing<OpmVisualThing>;
      if (logical.isSatisfiedRequirementSetObject())
        return true;
     }
    return false;
  }

  isRequirementsOwnerWithStereotype(): boolean {
    const toRemove = this.initRappid.getElementToRemove();
    if (toRemove && OPCloudUtils.isInstanceOfLogicalThing(toRemove.getVisual()?.logicalElement)) {
      const logical = toRemove.getVisual()?.logicalElement as OpmLogicalThing<OpmVisualThing>;
      if (logical.getAllRequirements()?.length > 0)
        return !!logical.getStereotype();
    }
    return false;
  }
}

