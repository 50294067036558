// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
    #settingBtn {
      border: none;
      background-color: transparent;
    }
    `, "",{"version":3,"sources":["webpack://./src/app/opd-hierarchy/coll-menu.component.ts"],"names":[],"mappings":";IACI;MACE,YAAY;MACZ,6BAA6B;IAC/B","sourcesContent":["\n    #settingBtn {\n      border: none;\n      background-color: transparent;\n    }\n    "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
