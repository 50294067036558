import {ValueAttributeRange, ValueAttributeType} from "./attribute-range";

const DEFAULT_CHAR = '$';

const WILDCARD = {
    STRING: '*',
    CHAR: '%'
}

export class StringRange implements ValueAttributeRange {

    private words: Array<string> = [];
    private pattern: string;

    private default: string;

    public setPattern(pattern: string): { wasSet: boolean } {
        const words = pattern.split(',');
        const clean = [];
        for (let w of words) {
            let def = false;
            if (w.startsWith(DEFAULT_CHAR)) {
                def = true;
                w = w.substring(1);
            }

            if ((w.startsWith('"') && w.endsWith('"')) == false)
                return { wasSet: false };

            if ((w.match(/"/g) || []).length % 2 !== 0) // if there is an odd number of "
              return { wasSet: false };

            w = w.substring(1, w.length - 1);

            if (w.match(/^[A-Za-z.*%]+$/).length == 0)
                return { wasSet: false };

            if (def)
                this.default = w;

            clean.push(w);
        }

        this.words = clean;
        this.pattern = pattern;
        return { wasSet: true };
    }

    public getPattern(): string {
        return this.pattern;
    }

    public validate(value: string): boolean {
        for (const w of this.words) {
            if (validate(value, w) == true)
                return true;
        }
        return false;
    }

    public getDefault(): string {
        return this.default;
    };

    public getType(): ValueAttributeType {
        return ValueAttributeType.STRING;
    }

    public isSubRange(newRange): boolean {
      let validated = 0;
      for (const w of newRange.words) {
        if (this.words.find(word => validate(w, word)))
          validated++;
      }
      return validated === newRange.words.length;
    }

}

const validate = function (value: string, pattern: string): boolean {
    let i: number = 0;
    for (i; i < pattern.length; i++) {
        if (pattern[i] == WILDCARD.CHAR) {
            // all good
        } else if (pattern[i] == WILDCARD.STRING) {
            if (pattern === value)
              return true;
            if (pattern.substring(i + 1) == '' && value.substring(i + 1) != '')
                return true;
            for (let j = 1; j < value.length; j++) {
                if (validate(value.substring(i + j), pattern.substring(i + 1)) == true)
                    return true;
            }
            return false;
        } else if (pattern[i] == value[i]) {
            // all good
        } else {
            return false;
        }
    }
    return (i == value.length);
}
