// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#ActivateUserComponent {
  overflow: auto;
  text-align: center;
}

button{
  background: #1A3763;
  color: #fff;
}

#buttonsDivider{
  width:2px;
}

#buttonsWrapper{
  text-align: center;
}

#ActivateUserButton{
  margin-right: 5px;
}


`, "",{"version":3,"sources":["webpack://./src/app/dialogs/activate-user-dialog/activate-user-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,cAAc;EACd,kBAAkB;AACpB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;AACnB","sourcesContent":["#ActivateUserComponent {\n  overflow: auto;\n  text-align: center;\n}\n\nbutton{\n  background: #1A3763;\n  color: #fff;\n}\n\n#buttonsDivider{\n  width:2px;\n}\n\n#buttonsWrapper{\n  text-align: center;\n}\n\n#ActivateUserButton{\n  margin-right: 5px;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
