import { Injectable } from "@angular/core";
import { AbstractVersionService } from "./abstract.init.service";
import { UserService } from "../user.service";
import { ModelStorageService } from '../storage/model-storage.service';
import {validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";

@Injectable()
export class OpcVersionService extends AbstractVersionService {

    constructor(public readonly model: ModelStorageService, public readonly userService: UserService) {
        super();
    }

    save(image?: string): void {
      if (this.userService.user.userData.isViewerAccount) {
        validationAlert('This operation is not available for viewer accounts. Please contact your organization\'s admin to make changes. Thank you!', 5000, 'ERROR');
        return;
      }
      this.model.save(image);
    }

  areTemplatesSupported(): boolean {
    return true;
  }

  areSubModelsSupported(): boolean {
      return true;
  }

  isBackendSupported(): boolean {
    return true;
  }

  renameModel(id: string, name: string, sysExamples = false, globalTemplates = false): Promise<void> {
    return this.model.renameModel(id, name, sysExamples , globalTemplates);
  }

    updateDB(details: any): void {
        this.userService.updateUserOplSetting(details);
    }

}
