import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyTooltipModule as MatTooltipModule } from '@angular/material/legacy-tooltip';
import { ModelStorageService } from '../../rappid-components/services/storage/model-storage.service';
import { GraphService } from '../../rappid-components/services/graph.service';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';
import { UserService } from '../../rappid-components/services/user.service';
import { GroupsService } from '../../rappid-components/services/groups.service';
import { OrganizationService } from '../../rappid-components/services/organization.service';

@Component({
  selector: 'app-collaboration-model-dialog',
  templateUrl: './collaboration-dialog.component.html',
  styleUrls: ['./collaboration-dialog.component.css']
})
export class CollaborationDialogComponent implements OnInit {
  groups;
  users;
  groupsData;
  tokenSelected;
  modelOwner;
  currentUser;
  sysAdminFlag = false;
  orgAdminFlag = false;
  groupAdminFlag = false;
  adminsOfGroupsThisModelBelongsTo = [];
  groupsCurrentUserIsAdminWithModelOwner;
  groupMembersTokenCanBeMovedTo = []; // if the user is a group admin, to which users can the admin pass the token
  currentModel;
  userList = [];
  groupList = [];
  chosenGroup = '';
  flag = false;
  connectedUsersIds;
  showOnlyConnected = false;
  showPassPermissionMessage: boolean;

  constructor(
    private graphService: GraphService,
    private orgService: OrganizationService,
    @Optional() public dialogRef: MatDialogRef<CollaborationDialogComponent>,
    private groupService: GroupsService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.currentUser = data.currentUser;
    this.currentModel = {
      modelName: this.graphService.modelObject.name,
      path: this.graphService.modelObject.path
    };
    (Object).assign(this.userList, data.checkedUsers);
    (Object).assign(this.groupList, data.checkedGroups);
    this.connectedUsersIds = {};
    this.showPassPermissionMessage = data.showPassPermissionMessage;
  }

  connectedUsersNumber(): number {
    return Object.keys(this.connectedUsersIds).length;
  }

  async ngOnInit() {
    this.connectedUsersIds = {};
    await this.orgService.getOrganizationAnalytics(this.data.org).then(res => {
      res.connectedModelers.forEach(item => this.connectedUsersIds[item.uid] = true);
      res.connectedViewers.forEach(item => this.connectedUsersIds[item.uid] = true);
    }).catch(err => {});
    this.groupService.updateOrgUsers(this.data.org);
    this.groupService.updateOrgGroups(this.data.org);
    this.orgService.getGroups(this.data.org).then(list => {
      //this.groupService.groupList$.subscribe(list => {
      this.groups = [];
      this.groupsData = list;
      this.tokenSelected = this.data.userToken;
      this.modelOwner = this.data.modelOwner;
      if (this.data.groupChkBoxOneAble) {
        this.chosenGroup = this.data.checkedGroups[0];
      }
      this.groupsCurrentUserIsAdminWithModelOwner = this.groupsData.filter(g => Object.keys(g.Administrators || {}).includes(this.data.currentUser.uid))
        .filter(g => this.data.modelOwner in (g.Members || {}));

      const moreGroupsToAdd = [];
      const getInnerGroups = (group) => {
          const subGroupsIds = group.subGroups;
          if (subGroupsIds?.length > 0) {
            for (const subId of subGroupsIds) {
              const sub = this.groupsData.find(gr => gr.GroupID === subId);
              if (sub) {
                moreGroupsToAdd.push(sub);
                getInnerGroups(sub);
              }
            }
          }
      }

      for (const g of this.groupsCurrentUserIsAdminWithModelOwner) {
        getInnerGroups(g);
      }
      this.groupsCurrentUserIsAdminWithModelOwner.push(...moreGroupsToAdd);

      this.groupAdminFlag = this.groupsCurrentUserIsAdminWithModelOwner.length > 0;
      for (const g of this.groupsCurrentUserIsAdminWithModelOwner) {
        this.groupMembersTokenCanBeMovedTo.push(...Object.keys(g.Members || {}))
      }


      const groupsCurrentUserBelongsTo = [];
      for (const group of this.groupsData) {
        if (this.data.modelOwner in (group.Members || {}) || this.data.modelOwner in (group.Administrators || {})) {
          if (!group.Name?.endsWith('All Users'))
            groupsCurrentUserBelongsTo.push(group);
        }
      }

      for (const gr of groupsCurrentUserBelongsTo) {
        if (gr.Parent && gr.Parent !== '') {
          const parentData = this.groupsData.find(g => g.GroupID === gr.Parent);
          if (parentData && !parentData.Name?.endsWith('All Users') && !groupsCurrentUserBelongsTo.includes(parentData)) {
            groupsCurrentUserBelongsTo.push(parentData);
          }
        }
      }
      this.adminsOfGroupsThisModelBelongsTo = [];
      for (const gr of groupsCurrentUserBelongsTo) {
        this.adminsOfGroupsThisModelBelongsTo.push(...Object.keys(gr.Administrators || {}));
      }

      this.updateAdminsFlag();
      const orgGroupName = this.orgService.getOrgGroupName(this.data.org);
      this.groupsData.forEach(group => {
        if (group.Parent === '' && (!this.data.groupChkBoxOneAble || group.Name !== orgGroupName)) {
          if (group.Name === orgGroupName) this.groups.unshift(group.GroupID);
          else this.groups.push(group.GroupID);
        }
      });
      try {
        this.groupsAndUsersTree(document.querySelector('ul'), this.groups);
      } catch(err) {
        // in case the user closes the dialog before it has time to be built. prevents the red error.
      }
      if (this.orgAdminFlag || this.sysAdminFlag || (this.currentUser.uid === this.modelOwner) ||
        (this.currentUser.uid === this.data.userToken)) {
        if (this.showPassPermissionMessage) {
          validationAlert('To pass the the writing permission - double click the name of the next model editor',
            5000, 'warning');
        }
      }
      for (const i of $('#data').find('.material-icons'))
        i.style.display = 'inline';
      if ((<HTMLInputElement>document.getElementById('showOnlyConnectedCheckbox')))
        (<HTMLInputElement>document.getElementById('showOnlyConnectedCheckbox')).disabled = false;
    });
  }

  getFormatData() {
    return this.data.headLine;
  }

  updateAdminsFlag() {
    if (this.data.userChkBoxFlag && 'OrgAdmin' in this.currentUser.userData) {
      if (this.currentUser.userData.OrgAdmin) this.orgAdminFlag = true;
    }
    if (this.data.userChkBoxFlag && 'SysAdmin' in this.currentUser.userData) {
      if (this.currentUser.userData.SysAdmin) this.sysAdminFlag = true;
    }
  }

  setToken(user) {
    this.tokenSelected = user;
  }

  checkUser(user) {
    if (!this.tokenSelected) {
      return false;
    }
    if (this.tokenSelected === user) {
      return true;
    }
    return false;
  }

  toggleToList(id, list) {
    const idx = list.indexOf(id);
    if (idx > -1) {
      list.splice(idx, 1);
    } else {
      list.push(id);
    }
    // console.log('list ', list);
  }

  // recursive function creates groups, sub groups and users
  groupsAndUsersTree(parent, groups) {
    groups.forEach(group => {
      let test = document.createElement('i');
      this.styleDownArrow(test);
      test.addEventListener('click', function () { $('#' + group).click() });
      const groupMembers = this.groupService.getMembersByGroup(group);
      const groupAdmins = this.groupService.getAdminsByGroup(group);
      const groupUsersWithDuplicates = groupAdmins.concat(groupMembers);
      // Remove duplicates from the array
      let groupUsers = groupUsersWithDuplicates.filter((element, index, list) => index === list.indexOf(element)).filter(usid => this.groupService.getUserById(usid));
      if (this.showOnlyConnected) {
        groupUsers = groupUsers.filter(id => this.connectedUsersIds[id]);
      }
      groupUsers.sort((a, b) => this.groupService.getUserName(a) < this.groupService.getUserName(b) ? -1 : this.groupService.getUserName(a) > this.groupService.getUserName(b) ? 1 : 0);
      const subGroups = this.getSubGroup(group);
      const li = document.createElement('li');
      let ul;
      const groupCheckBox = this.createGroupChkBox(group);
      if (!this.data.groupChkBoxOneAble) this.checkAllCode(this, group);
      this.foldingGroupSiblings(group);
      const GroupSpanText = this.createGroupSpanText(group);
      const self = this;
      groupCheckBox.onchange = function () { self.groupChkBoxOnChange(self, group); };
      this.appandChildrenToParent(li, [groupCheckBox, GroupSpanText, test]);
      parent.appendChild(li);
      if (subGroups.length > 0) { // there are subgroups in the group
        const groupHeadline = this.createGroupHeadline();
        let test = document.createElement('i');
        this.styleDownArrow(test);
        ul = document.createElement('ul');
        this.appandChildrenToParent(li, [groupHeadline, ul]);
        this.groupsAndUsersTree(ul, subGroups);
      }
      if (groupUsers.length) { // group is not empty
        const usersHeadline = this.createUserHeadline();
        this.appandChildrenToParent(li, [usersHeadline]);
        for (let i = 0; i < groupUsers.length; i++) {
          const userSpanText = this.createUserSpanText(groupUsers[i]);
          const userCheckBox = this.createUserChkBox(groupUsers[i]);
          if (groupAdmins.includes(groupUsers[i])) {
            userSpanText.textContent = userSpanText.textContent + '_Group Admin_';
          }
          if (groupCheckBox.checked) {
            userCheckBox.checked = true;
            self.userChkBoxOnChange(self, groupUsers[i], true);
          }
          const ul = document.createElement('ul');
          this.appandChildrenToParent(li, [ul]);
          const li2 = document.createElement('li');
          this.appandChildrenToParent(ul, [li2]);
          userSpanText.ondblclick = function () { self.userOnDblClick(self, groupUsers[i]); };
          userSpanText.onclick = function () { self.userOnClick(self, groupUsers[i]); };
          userCheckBox.onchange = function () {
            const isChecked = $(this).is(':checked');
            self.userChkBoxOnChange(self, groupUsers[i], isChecked);
          };
          const key_icon = this.createKeyIcon(groupUsers[i]);
          const token_icon = this.createTokenIcon(groupUsers[i]);
          const connectedIndicator = this.createConnectedUserIndicator(groupUsers[i]);
          this.appandChildrenToParent(li2, [userCheckBox, connectedIndicator, userSpanText, key_icon, token_icon].filter(item => !!item));
          if (groupUsers[i] === this.modelOwner) {
            this.setAttributes(key_icon, 'assets/icons/key-icon.png', 'Model Owner');
          }
          if (this.checkUser(groupUsers[i])) {
            this.setAttributes(token_icon, 'assets/icons/token-icon.png', 'Token Owner');
          }
          $('span').css({ 'cursor': 'pointer' });
          $('ul').css({ 'listStyle': 'none' });
        }
      }
      // hide the tree at the start. start only with the main groups
      $('#' + group).siblings().slice(1).hide();
    });
  }

  userOnClick(self, user) { // click on user trigers this function
    if (this.orgAdminFlag || this.sysAdminFlag || (self.tokenSelected !== user && self.modelOwner !== user && self.currentUser.uid === self.modelOwner)) {
      self.toggleToList(user, self.userList);
      if (this.data.disableUnsubscribedEmails && !this.groupService.getUserById(user)?.email_subscription)
        return;
      // checked/unchecked the same user in other groups
      if (self.userList.includes(user)) {
        $(':checkbox[value*="' + user + '"]').prop('checked', true);
      } else {
        $(':checkbox[value*="' + user + '"]').prop('checked', false);
      }
    }
  }

  userOnDblClick(self, user) { // Dbl click on user triggers this function
    const newToken = user;
    const prevToken = self.tokenSelected;
    const userGroups = self.groupsData.filter( g => g.Members && g.Members[user]).map(g => g.GroupID);
    if (self.tokenSelected === '') return;
    // if current user can pass the token
    if (this.orgAdminFlag || this.sysAdminFlag || (self.currentUser.uid === self.modelOwner) || (this.groupMembersTokenCanBeMovedTo.includes(newToken)) ||
      (self.currentUser.uid === self.data.userToken && (self.userList.includes(user) || self.groupList.some(g => userGroups.includes(g))))) {
      if (prevToken !== newToken) {
        // if ((prevToken !== self.modelOwner) && (self.currentUser.uid === self.modelOwner || this.orgAdminFlag || this.sysAdminFlag)) {
          $(':checkbox[value*="' + prevToken + '"]').prop('disabled', false);
        // }
        $(':checkbox[value*="' + newToken + '"]').prop('checked', true);
        $(':checkbox[value*="' + newToken + '"]').prop('disabled', true);

        $('img[id="token' + newToken + '"]').attr('src', 'assets/icons/token-icon.png');
        $('img[id="token' + newToken + '"]').attr('title', 'Token Owner');
        $('img[id="token' + prevToken + '"]').removeAttr('src');
        $('img[id="token' + prevToken + '"]').removeAttr('title');
        validationAlert(this.groupService.getUserName(user) + ' Is now the current model editor');
        if (self.userList.includes(user) === false) {
          self.setToken(user);
          self.toggleToList(user, self.userList);
        } else {
          self.setToken(user);
        }
      }
    }
  }

  createGroupChkBox(group) { // creating group check box element
    const groupCheckBox = document.createElement('input');
    groupCheckBox.type = 'checkbox';
    groupCheckBox.id = 'checkBOX' + group;
    groupCheckBox.checked = this.groupList.includes(group);
    groupCheckBox.disabled = ((this.currentUser.uid !== this.modelOwner && this.currentUser.uid !== this.tokenSelected) && !this.orgAdminFlag && !this.sysAdminFlag && !this.groupAdminFlag);
    if (this.data.groupChkBoxOneAble && this.data.groupChkBoxFlag) groupCheckBox.disabled = false;
    $(groupCheckBox).css({ 'width': '18px', 'height': '18px', 'marginBottom': '10px' });
    if (!this.data.groupChkBoxFlag) groupCheckBox.hidden = true;
    return groupCheckBox;
  }

  createGroupSpanText(group) { // creating text span for group
    const groupSpanText = document.createElement('span');
    groupSpanText.textContent = this.groupService.getGroupNameByID(group);
    groupSpanText.id = group;
    $(groupSpanText).css({ 'marginBottom': '10px' });
    return groupSpanText;
  }

  createUserChkBox(user) { // creating checkbox for user
    const userCheckBox = document.createElement('input');
    userCheckBox.type = 'checkbox';
    userCheckBox.value = user;
    userCheckBox.checked = this.userList.includes(user) || this.modelOwner === user || this.tokenSelected === user;
    this.groupList.forEach(group => {
      const usersGroups = this.groupService.getGroupsByUserID(user);
      if (usersGroups.includes(group)) userCheckBox.checked = true;  // if user is in checked group so he is checked too
    });
    userCheckBox.disabled = (user === this.tokenSelected || user === this.modelOwner
      || (this.currentUser.uid !== this.modelOwner  && this.currentUser.uid !== this.tokenSelected && !this.orgAdminFlag && !this.sysAdminFlag));
    if (this.data.disableUnsubscribedEmails && !this.groupService.getUserById(user)?.email_subscription)
      userCheckBox.disabled = true;
    if (this.groupMembersTokenCanBeMovedTo.includes(user) || this.groupAdminFlag)
      userCheckBox.disabled = false;
    if (this.groupAdminFlag && user === this.currentUser.uid) {
      userCheckBox.disabled = true;
      userCheckBox.checked = true;
    }
    if (this.adminsOfGroupsThisModelBelongsTo.includes(user)) {
      userCheckBox.disabled = true;
      userCheckBox.title = 'An Admin of a group the model owner belongs to has always read permissions.';
    }
    $(userCheckBox).css({ 'width': '18px', 'height': '18px', 'marginBottom': '10px' });
    if (!this.data.userChkBoxFlag) userCheckBox.hidden = true;
    return userCheckBox;
  }

  createUserSpanText(user) { // creating span text for user
    const userSpanText = document.createElement('span');
    userSpanText.textContent = this.groupService.getUserName(user) + '  ' + '(' + this.groupService.getUserEmail(user) + ')';
    $(userSpanText).css({ 'marginBottom': '10px' });
    return userSpanText;
  }

  compareNames(a, b) {
    // Use toUpperCase() to ignore character casing
    const bandA = this.groupService.getUserName(a);
    const bandB = this.groupService.getUserName(b);

    let comparison = 0;
    if (bandA > bandB) {
      comparison = 1;
    } else if (bandA < bandB) {
      comparison = -1;
    }
    return comparison;
  }

  userChkBoxOnChange(self, user, isChecked) { // clicks on user's checkbox trigers this function
    self.toggleToList(user, self.userList);
    self.flag = true;
    // checked/unchecked the same user in other groups
    if (isChecked) {
      $(':checkbox[value*="' + user + '"]').prop('checked', true);
    } else {
      $(':checkbox[value*="' + user + '"]').prop('checked', false);
      const groupList = this.groupService.getGroupsByUserID(user);
      for (let i = 0; i < groupList.length; i++) {
        if (this.flag) {
          this.unchekedGroupParent(groupList[i]);
        }
      }
    }
  }

  groupChkBoxOnChange(self, group) { // clicks on group's checkbox trigers this function
    if (this.data.groupChkBoxOneAble) {
      self.groupList = [];
      $('#' + group).siblings().slice(2).slideToggle('slow'); /* making all subgroups of this group appear (or disappear)*/
      $('#' + group).siblings()[1].style.display = 'inline';
      this.unchackedAllOtherGroups(group);
      if (self.chosenGroup === group) self.chosenGroup = '';
      else self.chosenGroup = group;
    }
    self.toggleToList(group, self.groupList);

    self.flag = true;
  }

  createKeyIcon(user) { // creating key icon element
    const key_icon = document.createElement('img');
    key_icon.id = 'key' + user;
    key_icon.textContent = '';
    return key_icon;
  }

  createConnectedUserIndicator(uid) { // creating key icon element
    if (this.connectedUsersIds[uid]) {
      const ind = document.createElement('img');
      ind.src = 'assets/SVG/greenIndicator.svg';
      ind.textContent = '';
      ind.classList.add('userIndicator');
      return ind;
    }
    return null;
  }

  setAttributes(element, src, title) { // setting attributes src and title for given element
    element.title = title;
    element.src = src;
  }

  createTokenIcon(user) { // creating token icon element
    const token_icon = document.createElement('img');
    token_icon.id = 'token' + user;
    token_icon.textContent = '';
    return token_icon;
  }
  appandChildrenToParent(parent, children) { // append children to parent
    for (let i = 0; i < children.length; i++) {
      parent.appendChild(children[i]);
    }
  }

  createUserHeadline() { // create user headline element
    const usersHeadline = document.createElement('ul');
    usersHeadline.style.fontWeight = 'bold';
    usersHeadline.style.color = 'purple';
    usersHeadline.textContent = 'Users';
    return usersHeadline;
  }

  createGroupHeadline() { // create group headline element
    const groupHeadline = document.createElement('ul');
    groupHeadline.style.fontWeight = 'bold';
    groupHeadline.style.color = 'blue';
    groupHeadline.textContent = 'Groups';
    return groupHeadline;
  }

  checkAllCode(self, group) { // checking group check box makes all users and subgroups inside to be checked
    $(document).on('change', '#' + 'checkBOX' + group, function () {
      const childrenCheckBoxes = $('#' + 'checkBOX' + group).siblings().slice(1).children().children('input');

      childrenCheckBoxes.each(function () {
        if (!$(this).prop('disabled')) {
          if ($('#' + 'checkBOX' + group).prop('checked')) {
            if (!$(this).prop('checked')) {
              $(this).prop('checked', true);
              $(this).trigger('change'); // important to do this!
            }
          } else {
            if ($(this).prop('checked')) {
              $(this).prop('checked', false);
              $(this).trigger('change'); // important to do this!
            }
          }
        }
        if ($('#checkBOX' + group).is(':checked') === false && self.flag) {
          self.unchekedGroupParent(group);
        }
        $(this).trigger('change');
      });
    });
  }

  unchekedGroupParent(id) {
    let parentID = this.groupService.getGroupParent(id);
    if ($('#checkBOX' + id).is(':checked') === true) {
      this.toggleToList(id, this.groupList);
      $('#checkBOX' + id).prop('checked', false);
    }
    while (parentID !== '') {
      if ($('#checkBOX' + parentID).is(':checked') === true) {
        this.toggleToList(parentID, this.groupList);
        $('#checkBOX' + parentID).prop('checked', false);
      }
      parentID = this.groupService.getGroupParent(parentID);
    }
  }

  unchackedAllOtherGroups(groupID) {
    this.groupsData.forEach(group => {
      if (group.GroupID !== groupID) {
        $('#checkBOX' + group.GroupID).prop('checked', false);
      }
    });
  }

  foldingGroupSiblings(group) { // when click group name: show/don't show users and sub groups
    $(document).off('click', '#' + group); // prevent from double bind of 'on' event after open the dialog
    $(document).on('click', '#' + group, function () {
      $('#' + group).siblings().slice(2).slideToggle('slow');
      $('#' + group).siblings()[1].style.display = 'inline';
      if ($('#' + group).siblings()[1].innerText === 'arrow_downward') {
        $('#' + group).siblings()[1].innerText = 'arrow_upward';
      } else {
        $('#' + group).siblings()[1].innerText = 'arrow_downward';
      }
    });
  }

  getSubGroup(groupID) {
    let rightGroup;
    this.groupsData.forEach(group => {
      if (group.GroupID === groupID) rightGroup = group;
    });
    return rightGroup.subGroups;
  }

  /* receives an icon element of the groups and users tree and styles it*/
  styleDownArrow(iconElement) {
    iconElement.innerText = 'arrow_downward';
    iconElement.classList.add('material-icons');
    iconElement.style.color = 'DarkGray';
    iconElement.style.position = 'relative';
    iconElement.style.top = '5px';
  }

  toggleShowOnlyConnected() {
    (<HTMLInputElement>document.getElementById('showOnlyConnectedCheckbox')).disabled = true;
    this.showOnlyConnected = !this.showOnlyConnected;
    Array.from($('#treelist')[0].children).map(ch => ch.remove());
    this.showPassPermissionMessage = false;
    this.ngOnInit();
  }

}
