import { Injectable, Inject } from '@angular/core';
import { DatabaseDriver } from './databaseDriverInterface';

@Injectable()
export class DatabaseService {

  private readonly databaseDriver: DatabaseDriver;

  constructor(@Inject('DatabaseDriver') private database_: DatabaseDriver) {
    this.databaseDriver = database_;
  }

  get driver(): DatabaseDriver {
    return this.databaseDriver;
  }

}

