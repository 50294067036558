import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {MainComponent} from '../main/main.component';
const joint = require('rappid');

import {
  leftArrowButton,
  downArrowButton,
  minusButton,
  plusButton,
  dragHandleButton,
  rightArrowButton
} from '../../../models/ConfigurationOptions';

/*
* This is the component for the opcloud navigator:
* a small dialog/box in which the user can see all the (current open) model together, but in a smaller version.
* inputs: paper scroller of open OPM model in the web
* output: 2 navigators, one floating and the other is on the side bar. Only one will be seen.
*/

@Component({
  selector: 'opcloud-navigator-component',
  templateUrl: 'navigator.component.html',
  styleUrls: ['navigator.component.css']
})

export class NavigatorComponent implements OnInit, AfterViewInit {
  // floatingNavigatorBoxSize holds the floating box size, in order to be able to resize it.
  // this is not the navigator size itself, but the box in which it is.
  // the style in the html is attached to it and changes according to it's value. initial value is set to 250.
  floatingNavigatorBoxSize = 250;
  // draggableBox is made to keep track on the floating box and reset it's fields when needed to keep it's boundaries.
  @ViewChild(CdkDrag, {static: false}) draggableBox: CdkDrag;
  paperScroller; // of the current model. this parameter is needed each time when a navigator is created or resized.

  existingNavigator;

  constructor(private initRappid: InitRappidService,
              public main: MainComponent) {
    this.paperScroller = initRappid.paperScroller;
    this.initRappid.navigatorComponentRef = this;
  }
  /* ngOnInit creates both navigators. */
  // comment: Gal: there should be only one navigator at a time due to performance issues.
  ngOnInit() {
    // this.createNavigator('floatingNavigator'); // assuming that this id exists in navigator.component.html
    this.existingNavigator = this.createNavigator('sideBarNavigator'); // assuming that this id exists in main.component.html
  }

  switchNavigator(switchTo: string) {
    if (this.existingNavigator)
      this.existingNavigator.remove();
    const navigatorToCreate = (switchTo === 'floating') ? 'floatingNavigator' : 'sideBarNavigator';
    this.existingNavigator = this.createNavigator(navigatorToCreate);
    this.initRappid.navigator = this.existingNavigator;
  }

  /* ngAfterViewInit changes some elements after their creation
  * mainly adds svg-paths (the visual part) to the buttons on the navigators.
  * it affects both navigator.component.html and main.component.html*/
  ngAfterViewInit() {
    // set the floating navigator box to it's initial position.
    this.initializeFloatingNavigatorPosition('floatingNavigatorBox');
    $('#minusButton').html(minusButton); // adding svg to the minusButton
    $('#plusButton').html(plusButton); // adding svg to the plusButton
    $('#dragHandleButton').html(dragHandleButton); // adding svg to the dragHandleButton
    $('.leftArrowButton').html(leftArrowButton); // adding svg to all which are in leftArrowButton class
    $('.downArrowButton').html(downArrowButton); // adding svg to all which are in downArrowButton class
    $('.rightArrowButton').html(rightArrowButton); // adding svg to all which are in rightArrowButton class
  }
  /*createNavigator creates the navigator and renders it inside the html element which id is given
  * input:  @htmlElementId (in this html or in main.component.html) to which the navigator should be attached.
  *         @size of the navigator itself. initially set to 200 but can change in order to resize*/
  createNavigator(htmlElementId: string, size = 200) {
    // assuming only the id name itself is given and thus we need to add '#' in front.
    htmlElementId = '#' + htmlElementId;
    const navigator = new joint.ui.Navigator({ // built in function which creates the navigator.
      paperScroller: this.paperScroller,
      height: size,
      width: (size / 2 ) * 3,
      padding: 15,
      zoomOptions: { max: 2, min: 0.2 },
      paperOptions: {
        async: { batchSize: 1 },
        sorting: joint.dia.Paper.sorting.APPROX,
      }
    });
    navigator.$el.appendTo(htmlElementId); // append the navigator element to the given html element.
    navigator.render(); // render the navigator.
    return navigator;
  }
  /*initializeFloatingNavigatorPosition is transforming the position of the floating navigator from the top left corner,
   to the right bottom corner. The floating navigator is set initially to the top left corner because in this way
   when increasing it's size, it grows toward the bottom and the right and doesn't go out of it's borders.
   But visually it suits more to be in the bottom right and thus we need to make the transformation just after the init.
   input: @floatingNavigatorId: the floating box element id in which the navigator is.
          @boundaryClass: the html class name which serves as the boundary, and from which the box should not exit.*/
  initializeFloatingNavigatorPosition(floatingNavigatorId: string, boundaryClass: string = '.sd-content') {
    // assuming only the id name itself is given and thus we need to add '#' in front.
    floatingNavigatorId = '#' + floatingNavigatorId;
    if ($(boundaryClass).length < 0) { return; } // checking that an element of this class exists.
    const sdContentBoundaries = $(boundaryClass)[0].getBoundingClientRect(); // get the size parameters of this class.

    // get the initial position of the floating box from the left and top.
    const floatingBoxInitialLeft = parseInt($(floatingNavigatorId)[0].style.left.slice(0, -2), 10);
    const floatingBoxInitialTop = parseInt($(floatingNavigatorId)[0].style.top.slice(0, -2), 10);
    // how much need to change the initial position considering also the size of the floating box itself.
    const transformX = sdContentBoundaries.width - floatingBoxInitialLeft - this.floatingNavigatorBoxSize;
    const transformY = sdContentBoundaries.height - floatingBoxInitialTop - this.floatingNavigatorBoxSize;

    // transform the position
    $(floatingNavigatorId)[0].style.transform = 'translate3d(' + transformX.toString() + 'px, ' +   transformY.toString() + 'px, 0px)';
  }
  /*resetDraggableBoundary resets these fields (below) of the draggable box so that it will understand the size
  of it's boundary after being resized. Otherwise it it thinks the boundary is smaller than it really is and
  the floating box gets stuck in the middle of the window.*/
  resetDraggableBoundary() {
    this.draggableBox._dragRef['_previewRect'] = undefined;
    this.draggableBox._dragRef['_boundaryRect'] = undefined;
  }
  /*resizeFloatingNavigator enables to make the floating navigator bigger or smaller by clicking on the minus or plus
  * buttons.
  * input:  @floatingNavigatorId: the floating box element id in which the navigator is.
  *         @increase: true if the size should be increased and false if it should be decreased*/
  resizeFloatingNavigator(floatingNavigatorId: string, increase: boolean) {
    if (increase) {
      if (this.floatingNavigatorBoxSize >= 430) { return; } // check that the size of the box is not already too big.
      this.floatingNavigatorBoxSize += 30; // increase the size of the box.
    } else {
      if (this.floatingNavigatorBoxSize <= 130) { return; } // check that the size of the box is not already too small.
      this.floatingNavigatorBoxSize -= 30; // decrease the size of the box.
    }
    // get the element of the current floating navigator
    const floatingNavigator = document.getElementById(floatingNavigatorId).firstChild;
    if (floatingNavigator) { // check that the navigator really exists.
      document.getElementById(floatingNavigatorId).removeChild(floatingNavigator); // remove the current navigator
    }
    // create a new navigator with the updated size.
    this.createNavigator(floatingNavigatorId, this.floatingNavigatorBoxSize - 50);
    // reset the boundaries so that the floating box will stay in it it's boundaries
    // although the size has been changed.
    this.resetDraggableBoundary();
  }

}
