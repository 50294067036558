import {Component, OnInit} from '@angular/core';
import {UserService} from '../../rappid-components/services/user.service';
import {getValidationObject} from '../../opl-generation/opl.service';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ValidationSettingsComponent} from '../validation-settings/validation-settings';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {ModelService} from '../../modules/app/model.service';
@Component({
  selector: 'app-opdtree-model-settings',
  templateUrl: './opdtree-model-settings.component.html',
  styleUrls: ['./opdtree-model-settings.component.css']
})

export class OpdtreeModelSettingsComponent {
  shouldArrangeOpdTreeInModel: boolean;

  constructor(public dialogRef: MatDialogRef<OpdtreeModelSettingsComponent>, private init: InitRappidService) {
    this.shouldArrangeOpdTreeInModel = this.init.getOpmModel().autoOpdTreeSort;
  }

  cancel() {
    this.dialogRef.close();
  }

  changeValue($event) {
    if ($event.target.value === 'undefined') {
      this.shouldArrangeOpdTreeInModel = undefined;
    } else {
      this.shouldArrangeOpdTreeInModel = ($event.target.value === 'true') ? true : false;
    }
  }

  apply() {
    this.init.getOpmModel().autoOpdTreeSort = this.shouldArrangeOpdTreeInModel;
    this.dialogRef.close();
  }
}
