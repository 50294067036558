import {Component } from '@angular/core';
import {TreeViewService} from '../services/tree-view.service';
import {GraphService} from '../services/graph.service';
import {InitRappidService} from "../services/init-rappid.service";
import {OplService} from "../../opl-generation/opl.service";
import {oplDefaultSettings} from "../../opl-generation/opl-database";
import { GraphDBService } from '../services/GraphDB/graphDB.service';
import {OpmEntity} from "../../models/DrawnPart/OpmEntity";
import {
  getInitRappidShared,
  OPCloudUtils,
  popupGenerator,
  stylePopup
} from '../../configuration/rappidEnviromentFunctionality/shared';

@Component({
  selector: 'opcloud-rappid-opl',
  template: `
    <div class="opl-container" #oplContainer (mouseleave)="unHighlightAllCells($event)" (mousemove)="unHighlightAllCells($event)">
      <!--<ng-container *ngFor="let opl of highlightedOpls">
          <span *ngIf = "opl"
                [innerHTML]="opl.opl"
                (mouseover)="highlightCells(opl.cells)"
                (mouseleave)="unhighlightCells(opl.cells)"
                [ngClass] = "{'oplHighlight' : opl.highlighted === true}"
          >
          </span><br *ngIf = "opl.opl">
      </ng-container>
      <ng-container *ngFor="let opl of opls">
          <span *ngIf = "opl.opl && opl.highlighted ===false"
                [innerHTML]="opl.opl"
                (mouseover)="highlightCells(opl.cells)"
                (mouseleave)="unhighlightCells(opl.cells)"
          >
          </span><br *ngIf = "opl.opl && opl.highlighted ===false">
      </ng-container>-->
        <ng-container *ngFor="let opl of opls; let idx = index">
          <span *ngIf="this.oplService.settings.oplNumbering && (opl.showAll || opl.highlighted )">{{idx+1 +'. '}}</span>
                <span class="oplText" *ngIf = "opl.opl && (opl.showAll || opl.highlighted )"
                    [innerHTML]="opl.opl"
                    (mouseover)="highlightCells(opl.cells)"
                    (mouseleave)="unhighlightCells(opl.cells)"
                    (dblclick)="handleDblClicking($event, opl.cells)"
                    (click)="handleClicking($event)"
                    [ngClass]="{'oplHighlight': opl.highlighted}"
              >
              </span><br *ngIf = "opl.opl && (opl.showAll || opl.highlighted )">
        </ng-container>
    </div>
  `,
  styleUrls: ['./rappid-opl.component.css']
})
export class RappidOplComponent {
  //@ViewChild('oplContainer') oplContainer: ElementRef;
  hoverOnOpl = false;
  opls = [];
  //highlightColor = '#F7DC6F';
  //highlightColor = '#D1D8E1';
  highlightColor = '#E1E6EB';
  ignoreEvent = false;
  // queryResultOPL;

  prev_colors = {};
  graph;
  paper;
  shouldUpdateOpl = true;

  constructor(private treeViewService: TreeViewService, private graphService: GraphService,
              private options: InitRappidService,
              private graphDB: GraphDBService,
              private oplService: OplService) {
    this.oplService.oplSwitch.subscribe(message =>{
      if (message === 'urgent opl refresh')
        this.updateOpl();
    });
    this.options.currentOpl = this.opls;
    this.oplService.oplSwitch.next('opl widget initialized');
    this.graph = this.options.graph;
    this.paper = this.options.paper;
    const this_ = this;
    setInterval(function() {
      if (this_.shouldUpdateOpl && this_.oplService.oplOpen && !this_.options.graph.hasActiveBatch('pointerdown') && !this_.options.graph.hasActiveBatch('linkCreation') && !this_.options.graph.hasActiveBatch('arrowhead-move')) {
        this_.updateOpl();
        this_.shouldUpdateOpl = false;
      }
    }, 800);
    this.graph.on('change', (cell) => {
      this.oplService.oplSwitch.next("graph change");
      this.shouldUpdateOpl = true;
    });
    this.graph.on('add', (cell) => {
      this.oplService.oplSwitch.next("graph add");
      this.shouldUpdateOpl = true;
    });
    this.graph.on('remove', (cell) => {
      this.oplService.oplSwitch.next("graph remove");
      this.shouldUpdateOpl = true;
    });
    this.graph.on('reset', (cell)=>{
      this.oplService.oplSwitch.next("graph reset");
      this.shouldUpdateOpl = true;
    });
    this.graph.on('clear', (cell)=>{
      this.opls = [];
    });
    this.paper.on('cell:mouseover', function (cellView, evt) {
      try {
        if (oplDefaultSettings.highlightOpl && (!this_.options.selectedElement || !OPCloudUtils.isInstanceOfDrawnEntity(this_.options.selectedElement))){
          for (const opl of this.opls){
            opl.highlighted = !!opl.cells.find(c => c.id && c.id === cellView.model.id);
            if (cellView.model.constructor.name.includes('Default') || cellView.model.constructor.name.includes('Triangle'))
              opl.showAll = true;
            else opl.showAll = false;
          }
        }
      } catch (e) { }
    }, this);
    this.paper.on('cell:mouseout', function (cellView, evt) {
      try {
        if (oplDefaultSettings.highlightOpl && (!this_.options.selectedElement || !OPCloudUtils.isInstanceOfDrawnEntity(this_.options.selectedElement))){
          for (const opl of this.opls){
            opl.highlighted = false;
            opl.showAll = true;
          }
        }
      } catch (e) { }
    }, this);
    this.paper.on('blank:pointerup', () => {
      for (const opl of this.opls) {
        opl.highlighted = false;
        opl.showAll = true;
      }
    });
    this.options.selected$.subscribe((element) => {
      if (element) {
        for (const opl of this.opls) {
          opl.showAll = false;
          opl.highlighted = opl.cells.includes(element);
        }
      } else {
        for (const opl of this.opls) {
          opl.highlighted = false;
          opl.showAll = true;
        }
      }
    });
  }

  updateOpl(){
    if (this.oplService.oplOpen){
      this.oplService.queryResultLabel.next(false);
      let opls = this.oplService.generateOpl();
      let changeOpl = false;
      if (this.opls.length !== opls.length){
        changeOpl = true;
      }else{
        for (let i=0; i< opls.length; i++){
          if (!this.opls[i] || this.opls[i].opl !== opls[i].opl){
            changeOpl = true;
            break;
          }
        }
      }
      if ((this.options.opmModel.currentOpd.id === this.options.opmModel.getOPMQueryID()) && (this.graphDB.ResultOPLs)) {
          opls = this.graphDB.ResultOPLs;
          changeOpl = true;
          this.oplService.queryResultLabel.next(true);
      }
      if (changeOpl){
        this.opls = opls;
        for (const opl of this.opls){
          opl.highlighted = false;
          opl.showAll = true;
          // if (opl.opl.includes('<<') && opl.opl.includes('>>')) {
          //   const re = new RegExp('<<', 'g');
          //   const re2 = new RegExp('>>', 'g');
          //   opl.opl = opl.opl.replace(re, '<<<b>');
          //   opl.opl = opl.opl.replace(re2, '</b>>>');
          // }
        }
      }
      this.options.currentOpl = this.opls;
    }

  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(null), ms)).then(() => console.log('fired'));
}

  test() {
    this.graph.on('change', (cell) => {
      //console.log(this.graph);
    });
  }



  HoverOnCells() {
    this.paper.on('link:mouseenter', function (cellView, evt) {
    }, this);
  }




  highlightObject(cell) {
    if (cell.constructor.name.includes('Semi')) return;
    let cellView = this.paper.findViewByModel(cell);
    if (!cellView) return;
    if(!(Object.keys(this.prev_colors).indexOf(cell.id)>-1)){
        this.prev_colors[cell.id] = cellView.model.attr('rect/fill');
    }
    cellView.model.attr('rect/fill', this.highlightColor);
  }

  unhighlightObject(cell) {
    if (cell.constructor.name.includes('Semi')) return;
    let color = 'white';
    let cellView = this.paper.findViewByModel(cell);
    if (!cellView) return;
    if((Object.keys(this.prev_colors).indexOf(cell.id)>-1)){
      color = this.prev_colors[cell.id];
      delete this.prev_colors[cell.id];
    }
    const mc = this.options.opmModel.getCurrentConfiguration();
    if (mc && mc[cellView.model.getVisual().logicalElement.lid] && mc[cellView.model.getVisual().logicalElement.lid].value !== 0) {
      cellView.model.colorIfInCurrentConfiguration(this.options);
      return;
    }
    const shouldBeGreyed = cell && cell.getVisual() && cell.getVisual().logicalElement.shouldBeGreyed && this.options.shouldGreyOut;
    if (cellView.model.getVisual() && cellView.model.getVisual().fill)
      cellView.model.attr('rect/fill', shouldBeGreyed ? 'lightgrey' : cellView.model.getVisual().fill);
    else cellView.model.attr('rect/fill', color);
  }

  highlightProcess(cell) {
    let cellView = this.paper.findViewByModel(cell);
    if (!cellView) return;
    if(!(Object.keys(this.prev_colors).indexOf(cell.id)>-1)){
      this.prev_colors[cell.id] = cellView.model.attr('ellipse/fill');
    }
    cellView.model.attr('ellipse/fill', this.highlightColor);
  }

  unhighlightProcess(cell) {
    let color = 'white';
    let cellView = this.paper.findViewByModel(cell);
    if (!cellView) return;
    if((Object.keys(this.prev_colors).indexOf(cell.id)>-1)){
      color = this.prev_colors[cell.id];
      delete this.prev_colors[cell.id];
    }
    const mc = this.options.opmModel.getCurrentConfiguration();
    if (mc && mc[cellView.model.getVisual().logicalElement.lid] && mc[cellView.model.getVisual().logicalElement.lid].value !== 0) {
      cellView.model.colorIfInCurrentConfiguration(this.options);
      return;
    }
    const shouldBeGreyed = cell && cell.getVisual() && cell.getVisual().logicalElement.shouldBeGreyed && this.options.shouldGreyOut;
    if (cellView.model.getVisual() && cellView.model.getVisual().fill)
      cellView.model.attr('ellipse/fill', shouldBeGreyed ? 'lightgrey' : cellView.model.getVisual().fill);
    else cellView.model.attr('ellipse/fill', color);
  }

  highlightLink(cell) {
    let cellView = this.paper.findViewByModel(cell);
    if (cellView?.model)
      cellView.model.attr('.connection/stroke', this.highlightColor);
  }

  unhighlightLink(cell) {
    let cellView = this.paper.findViewByModel(cell);
    if (cellView && cellView.model)
      cellView.model.removeAttr('.connection/stroke');
  }

  highlightStates(cell) {
    const parent = cell.getParent();
    if (parent.getEmbeddedCells()) {
      const states = parent.getEmbeddedCells();
      for (const state of states) {
        this.highlightSingleState(state);
      }
    }
  }

  unhighlightStates(cell) {
    const parent = cell.getParent();
    if (parent.getEmbeddedCells()) {
      const states = parent.getEmbeddedCells();
      for (const state of states) {
        this.unhighlightSingleState(state);
      }
    }
  }

  highlightSingleState(state) {
    const cellView = this.paper.findViewByModel(state);
    if (!cellView) return;
    //cellView.model.attr('.inner/fill', this.highlightColor);
    //cellView.model.attr('.outer/fill', );
    if(!(Object.keys(this.prev_colors).indexOf(state.id)>-1)){
      this.prev_colors[state.id] = cellView.model.attr('rect/fill')===undefined ? 'white' : cellView.model.attr('rect/fill');
    }
    cellView.model.attr('.inner/fill', this.highlightColor);
    cellView.model.attr('.outer/fill', this.highlightColor);
  }

  unhighlightSingleState(state) {
    let color = 'white';
    const cellView = this.paper.findViewByModel(state);
    if (!cellView) return;
    //cellView.model.attr('.inner/fill', 'white');
    //cellView.model.attr('.outer/fill', 'white');
    if((Object.keys(this.prev_colors).indexOf(state.id)>-1)){
      color = this.prev_colors[state.id];
      delete this.prev_colors[state.id];
    }
    const shouldBeGreyed = state && state.getVisual() && state.getVisual().fatherObject.logicalElement.shouldBeGreyed && this.options.shouldGreyOut;
    if (cellView.model.getVisual() && cellView.model.getVisual().fill) {
      cellView.model.attr('.inner/fill', shouldBeGreyed ? 'lightgrey' : cellView.model.getVisual().fill);
      cellView.model.attr('.outer/fill', shouldBeGreyed ? 'lightgrey' : cellView.model.getVisual().fill);
    }
    else {
      cellView.model.attr('.inner/fill', shouldBeGreyed ? 'lightgrey' : color);
      cellView.model.attr('.outer/fill', shouldBeGreyed ? 'lightgrey' : color);
    }
    const validationColor = state.getVisual()?.getValidationView().color;
    if (validationColor) {
      cellView.model.attr('.inner/fill', validationColor);
      cellView.model.attr('.outer/fill', validationColor);
    }
  }

  handlePopupLocation($event) {
    // handle location of popup based on:
    // 1. top - the height of the event place:
    // default behaviour is to be opened on the bottom side of the sentence
    // and in case the height is not sufficient enough we will move it to the top of the sentence.
    // 2. left - making sure the popup is not passing the screen's width
    // will mostly be used in the side view of the OPL
    const element = $('.joint-popup')[0];
    if (element !== undefined) {  // activate only if there is a popup on the screen
      const popup = element as HTMLDivElement;
      if (window.innerHeight - $event.clientY < element.getClientRects()[0].height + 30) {
        const height = element.getClientRects()[0].height;
        const top = $event.clientY - height - 30;
        popup.style.top = top.toString() + 'px';
      }
      if (element.getClientRects()[0].left < 0) {
        popup.style.left = '0px';
      }
    }
  }

  createPopupDiv(cellList) {
    let popupContent = '<div style="padding-top: 8px">';
    for (let i = 0; i < cellList.length; i++) {
      let first = cellList[i].sourceElement.lastEnteredText;
      if (first.length > 10) {
        first = first.substring(0, 9) + '...';
      }
      let second = cellList[i].targetElement.lastEnteredText;
      if (second.length > 10) {
        second = second.substring(0, 9) + '...';
      }
      popupContent += '<button title="' + cellList[i].sourceElement.lastEnteredText + '->' + cellList[i].targetElement.lastEnteredText
        + '" class="Popup PopupOPL" style="margin: 0 5px 5px 5px;" value="' + i.toString() + '">' + first + ' -> ' + second + '</button>';
    }
    popupContent += '</div>';
    return popupContent;
  }

  handleClicking($event) {
    // single clicking on an OPL sentence
    // triggers copying to clipboard of the OPL text
    const text = $event.target.innerText;
    const el = document.createElement('textarea');
    el.value = text;
    // appending and then removing element so we can copy to clipboard
    document.body.appendChild(el); // adding as an element
    el.select();
    document.execCommand('copy'); // copied to clipboard
    document.body.removeChild(el); // removing the element
  }

  handleDblClicking($event, cells) {
    // double clicking in an OPL sentence:
    // clicking on object/process/state - triggers opening of this entity editor
    // clicking on other parts of the sentence - triggers opening of the link's editor.
    // if more than one link is in the sentence than first another popup is opened
    // allowing the user to decide which link they want to edit.

    // handle process/object/state
    if ($event.target.tagName === 'B') {
      const className = $event.target.className;
      if (className) {
        let cellId = className;
        if (className.startsWith('state')) {
          cellId = className.split(' ')[1];
        }
        // let cell = cells.find(c => c.id === cellId); // find the right cell
        let cell = this.options.graph.getCell(cellId);
        // if cell wasn't found, look for it in cells' parents.
        // this is needed when an entity has states because than the entity is not in "cells".
        if (!cell) {
          cell = cells.find(c => c.getParent()?.id === cellId);
          cell = cell?.getParent();
        }
        if (cell) { // if cell was found, open its text editor
          cell.openTextEditor($event.target, getInitRappidShared());
        }
      }
      // handle links
    } else if ($event.target.className === 'oplText ng-star-inserted') {
      const cellList = cells.filter(c => c.attributes.type === 'opm.Link');
      if (cellList.length !== 0) {
        // handle single link -> open the editor popup
        if (cellList.length === 1) {
          cellList[0].rightClickHandlePopoup($event.target, getInitRappidShared());

          // handle multiple links -> open selection popup
        } else {
          // create a new popup to decide which link to open
          const popupContent = this.createPopupDiv(cellList);

          const _this = this; // used for the inner function in stylePopupEvents
          // handle selection of the user -> open the chosen link's editor popup
          const stylePopupEvents = {
            'click .PopupOPL': function(el) {
              cellList[el.target.value].rightClickHandlePopoup($event.target, getInitRappidShared());
              _this.handlePopupLocation($event);
            }
          };
          // generate the new popup to the screen
          popupGenerator($event.target, popupContent, stylePopupEvents).render(); // create the popup
          stylePopup(false, true, false); // style it according to the system
        }
      }
    }
    // handle location of popup
    // default location is to the bottom of the sentence
    // if the popup is too high, than location will change to the top of the sentence
    this.handlePopupLocation($event);
  }


  highlightCells(cells){
    this.hoverOnOpl = true;
    if (oplDefaultSettings.highlightOpd){
      for (const cell of cells){
        this.ignoreEvent = true;
        this.highlightCell(cell);
        this.ignoreEvent = false;
      }
    }
  }
  unhighlightCells(cells){
    this.hoverOnOpl = false;
    if (oplDefaultSettings.highlightOpd){
      for (const cell of cells){
        this.ignoreEvent = true;
        this.unhighlightCell(cell);
        this.ignoreEvent = false;
      }
    }
  }

  highlightCell(cell) {
    this.options.graph.startBatch('ignoreEvents');
    this.options.graph.startBatch('ignoreChange');
    switch (cell.attributes.type) {
      case 'opm.Object':
        this.highlightObject(cell);
        break;
      case 'opm.Process':
        this.highlightProcess(cell);
        break;
      case 'opm.Link':
        this.highlightLink(cell);
        break;
      case 'opm.State':
        this.highlightSingleState(cell);
        break;
    }
    this.options.graph.stopBatch('ignoreEvents');
    this.options.graph.stopBatch('ignoreChange');
  }

  unhighlightCell(cell){
    this.options.graph.startBatch('ignoreEvents');
    this.options.graph.startBatch('ignoreChange');
    switch (cell.attributes.type) {
      case 'opm.Object':
        this.unhighlightObject(cell);
        break;
      case 'opm.Process':
        this.unhighlightProcess(cell);
        break;
      case 'opm.Link':
        this.unhighlightLink(cell);
        break;
      case 'opm.State':
        this.unhighlightSingleState(cell);
        break;
    }
    this.options.graph.stopBatch('ignoreEvents');
    this.options.graph.stopBatch('ignoreChange');
  }

  unHighlightAllCells($event: MouseEvent) {
    if ($event.type === 'mouseleave' || ($event.type === 'mousemove' && (<any>$event.target).className === 'opl-container')) {
      const cells = this.options.graph.getCells().filter(cell => cell instanceof OpmEntity)
      this.unhighlightCells(cells);
    }
  }
}


