// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  padding-top: 10px;
  text-align: center;
}

h3 {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
}

#sentence {
  line-height: 30px;
}

.bold {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: bold;
  color: #1A3763;
}

#options {
  text-align: center;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/ontologySugesstionDialog/ontology-suggestion-dialog.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,iDAAiD;EACjD,kBAAkB;EAClB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;AAClB","sourcesContent":[".buttons {\n  padding-top: 10px;\n  text-align: center;\n}\n\nh3 {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n}\n\n#sentence {\n  line-height: 30px;\n}\n\n.bold {\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: bold;\n  color: #1A3763;\n}\n\n#options {\n  text-align: center;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
