import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs/Rx';
import { UserService } from '../../../../rappid-components/services/user.service';
import { OrganizationService } from '../../../../rappid-components/services/organization.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog'; import { GroupsService } from '../../../../rappid-components/services/groups.service';
import htmlString = JQuery.htmlString;
import { CollaborationDialogComponent } from '../../../../dialogs/collaboration-dialog/collaboration-dialog.component';
import { validationAlert } from '../../../../configuration/rappidEnviromentFunctionality/shared';

@Component({
  selector: 'opcloud-send-email',
  templateUrl: 'SendEmailComponent.html',
  styleUrls: ['./send-email.component.css']
})
export class SendEmailComponent implements OnInit {
  messagePlaceHolder: string;
  orgPlaceHolder: string;
  subjectPlaceHolder: string;
  usersPlaceHolder: string;
  subject: string;
  spinnerFlag: boolean;
  selectedGroups;
  selectedUsers;
  emailsList;
  EmailsString: string;
  message: string;
  selectedOrg: '';
  currentUser;
  organizations$: Observable<Array<any>>;
  private ssoNoneActiveTime: Number = 2; // in months

  constructor(private _dialog: MatDialog, private orgService: OrganizationService, private groupService: GroupsService,
    private userService: UserService) {
  }

  ngOnInit() {
    this.spinnerFlag = false;
    this.messagePlaceHolder = 'Enter Message...';
    this.subjectPlaceHolder = 'Enter Subject...';
    this.usersPlaceHolder = 'Send To Emails...';
    // this.orgPlaceHolder = 'Organization';
    this.userService.user$.take(1).subscribe(data => {
      this.selectedOrg = data.userData.organization;
    });
    this.subject = '';
    this.message = '';
    this.selectedGroups = [];
    this.selectedUsers = [];
    this.emailsList = [];
    this.userService.user$.subscribe(user => {
      this.currentUser = user;
    });
    this.groupService.updateOrgUsers();
    this.updateOrgList();
  }
  updateOrgList() {
    if (this.currentUser && this.currentUser.userData.SysAdmin) {
      document.getElementById('allUsersButton').hidden = false;
      document.getElementById('allDeactiveUsersButton').hidden = false;
      this.organizations$ = this.orgService.getAllOrganizations();
    } else {
      if (this.currentUser) {
        this.organizations$ = Observable.of([{ name: this.currentUser.userData.organization }]);
      }
    }
  }
  getOrgRef(event: any) {
    // const btn = <HTMLInputElement>document.getElementById('orgButton');
    // btn.disabled = false;
    this.selectedOrg = event.value;
    this.groupService.updateOrgGroups(this.selectedOrg);
    this.groupService.updateOrgUsers(this.selectedOrg);
  }

  isSSOUserWasntActiveLately(user): boolean {
    let lastAuth = user.lastAuthTime;
    if (String(lastAuth).length < 13)
      lastAuth = Number(String(user.lastAuthTime) + '0'.repeat(13 - String(user.lastAuthTime).length));
    if (new Date((Number(new Date()) - Number(new Date(lastAuth)))).getMonth() >= Number(this.ssoNoneActiveTime))
      return true;
    return false;
  }

  async selectAllUsers() {
    const date = new Date();
    const currentDate = date.getTime() + 5184000000;
    this.emailsList = [];
    this.EmailsString = '';
    const organizations = await this.orgService.getAllOrgs() as Array<any>;
    organizations.forEach(org => {
      this.orgService.getOrganizationUsers(org.name).then(users => {
        if (users) {
          users.forEach(user => {
            if (user) {
              if (!user.email_subscription || !user.isActive || (user.exp_date !== '' && new Date() > new Date(Number(user.exp_date)) )) {
                return;
              }
              if (user.sso_user && this.isSSOUserWasntActiveLately(user)) {
                  return;
              }
              this.EmailsString = this.EmailsString + '<' + user.Email + '> ';
              this.emailsList.push(user.Email);
            }
          });
        }
      });
    });
  }

  async selectAllInactiveUsers() {
    const date = new Date();
    this.emailsList = [];
    this.EmailsString = '';
    const organizations = await this.orgService.getAllOrgs() as Array<any>;
    organizations.forEach(org => {
      this.orgService.getOrganizationUsers(org.name).then(users => {
        if (!users)
          return
        users.forEach(user => {
          if (!user || !user.email_subscription)
            return;
          if (!user.isActive || (user.exp_date !== '' && new Date() > new Date(Number(user.exp_date)) ) || (user.sso_user && this.isSSOUserWasntActiveLately(user))) {
            this.EmailsString = this.EmailsString + '<' + user.Email + '> ';
            this.emailsList.push(user.Email);
          }
        });
      });
  });
  }

  sendEmail() {
    if (this.message === '' || this.subject === '' || this.emailsList.length === 0) {
      validationAlert('One or more of the fields is missing..', null, 'Error');
      return;
    }
    this.spinnerFlag = true;
    let message;
    let subject;
    if (this.currentUser.userData.SysAdmin) {
      message = this.message + '\n' + '\n' + '\n' + 'Do not reply to this mail';
      subject = 'A system Message From OPCloud: ' + this.subject;
    } else if (this.currentUser.userData.OrgAdmin) {
      subject = 'Admin Message From OPCloud: ' + this.subject;
      message = this.currentUser.userData.Name + ' From organization ' + this.currentUser.userData.organization +
        ' Send to you the following  message using OPCloud: ' + '\n' + '\n' + '\n' + this.message +
        '\n' + '\n' + '\n' + 'Do not reply to this mail';
    } else subject = 'A message From OPCloud: ' + this.subject;
    this.orgService.sendMail(this.emailsList, subject, message).then(res => {
      this.spinnerFlag = false;
      this.message = '';
      this.subject = '';
      this.selectedUsers = [];
      this.selectedGroups = [];
      this.EmailsString = '';
      this.updateOrgList();

      validationAlert('The E-mail was sent Successfully!', null, 'Success');
    });
  }

  collDialogOpen() {
    const dialogRef = this._dialog.open(CollaborationDialogComponent, {
      // height: '600px',
      width: '775px',
      data: {
        userToken: '',
        headLine: 'Select Users and Groups in ' + this.selectedOrg + ' organization',
        userChkBoxFlag: true,
        groupChkBoxFlag: true,
        groupChkBoxOneAble: false,
        currentUser: this.currentUser,
        org: this.selectedOrg,
        checkedUsers: this.selectedUsers,
        checkedGroups: this.selectedGroups,
        modelOwner: '',
        disableUnsubscribedEmails: true,
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.selectedGroups = data.checkedGroupList;
        this.selectedUsers = data.checkedUserList;
        this.createUserEmailsList();
      }
    });
  }

  createUserEmailsList() {
    this.emailsList = [];
    this.EmailsString = '';
    this.selectedUsers.forEach(userID => {
      if (userID) {
        const user = this.groupService.getUserById(userID);
        if (user) {
          this.EmailsString = this.EmailsString + '<' + user.Email + '> ';
          this.emailsList.push(user.Email);
        }
      }
    });
  }

  ssoNoneActiveTimeChange($event) {
    this.ssoNoneActiveTime = Number($event.target.value);
  }
}
