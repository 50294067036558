import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmEntity } from "../../DrawnPart/OpmEntity";
import { OpmLogicalEntity } from "../../LogicalPart/OpmLogicalEntity";
import { OpmVisualEntity } from "../../VisualPart/OpmVisualEntity";
import { ElementAction, ElementCommand, ElementHaloHandle, ElementToolbarHandle } from "./command";

export class ToggleTextAutoFormatCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly entity: OpmEntity,
        private readonly visual: OpmVisualEntity) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: true,
            name: 'toggle-text-format',
            displayTitle: 'Toggle Text Format',
            svg: 'toggle-text-format',
            action: new ToggleTextAutoFormatAction(this.init, this.entity),
            gif: 'assets/gifs/auto_format.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        const status = (this.visual.logicalElement as OpmLogicalEntity<any>).getNameModule().isAutoFormat();
        const statusText = status ? 'On' : 'Off';
        return {
            name: 'toggle-text-format',
            displayTitle: `OPM Principle Autoformatting: ${statusText}`,
            svg: status ? 'text-auto-format-on' : 'text-auto-format-off',
            action: new ToggleTextAutoFormatAction(this.init, this.entity),
            gif: 'assets/gifs/auto_format.gif',
        }
    }

}

class ToggleTextAutoFormatAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly entity: OpmEntity) {
    }

    act() {
      const cell = this.init.graph.getCell(this.entity.id);
      if (cell)
        cell.toggleAutoFormat(this.init);
    }

}
