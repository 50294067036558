import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OPCloudUtils } from "../../configuration/rappidEnviromentFunctionality/shared";
import { OpmOpd } from '../../models/OpmOpd';
import { ModelService } from '../../modules/app/model.service';
import { GraphService } from '../../rappid-components/services/graph.service';
import { InitRappidService } from "../../rappid-components/services/init-rappid.service";
import { TreeViewService } from '../../rappid-components/services/tree-view.service';

@Component({
  selector: 'create-view-dialog',
  templateUrl: 'create-view-dialog.html',
  styleUrls: ['create-view-dialog.css']
})
export class CreateViewDialog implements OnInit {

  public mode: 'new' | 'rename' = 'new';

  public opdView: OpmOpd;
  public title = '';
  public error = '';

  constructor(private model: ModelService,
    private init: InitRappidService,
    private tree: TreeViewService,
    private graph: GraphService,
    private dialogRef: MatDialogRef<CreateViewDialog>,
    @Inject(MAT_DIALOG_DATA) private data) {
  }

  ngOnInit() {
    if (this.data.renameOpdId) {
      this.opdView = this.model.model.getOpd(this.data.renameOpdId);
      this.mode = 'rename';
      this.title = this.opdView.name.slice(8);
    }
  }

  createView() {
    const selected = this.init.selection.collection.models.filter(cell => OPCloudUtils.isInstanceOfDrawnThing(cell)).map(m => m.getVisual());
    const names = selected.map(vis => vis.logicalElement.getBareName()).join(', ');
    this.init.opmModel.logForUndo('Create View of ' + names);
    this.init.opmModel.setShouldLogForUndoRedo(false, 'createView');
    const ret = this.model.model.createViewOpd(selected, this.title.trim());
    if (ret.created == false) {
      this.error = ret.errors[0];
      this.init.opmModel.setShouldLogForUndoRedo(true, 'createView');
      return;
    }
    // update view
    this.tree.init(this.model.model);
    this.model.model.currentOpd = ret.viewOpd;
    this.graph.renderGraph(ret.viewOpd, this.init);
    for (const obj of this.graph.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnObject(c)))
      obj.syncStatesOrder(this.init, false);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'createView');
    this.dialogRef.close();
  }


  rename() {
    const ret = this.model.model.renameViewOpd(this.opdView, this.title.trim());
    if (ret.renamed == false) {
      this.error = ret.error;
      return;
    }

    // update view
    this.tree.init(this.model.model);

    this.dialogRef.close(this.opdView.getNumberedName() + ': ' + this.opdView.getName());
  }
}
