import { ModelObject } from './model-object.class';
import {FolderPermissions} from "../../../dialogs/folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";

export abstract class ModelStorageInterface {
  abstract get(modelName: string, path?: string): Promise<ModelObject>;
  // new policy param in save(): if 'overwrite' - it will overwrite a model forcefully,
  // 'create' - will optionally create a new model, and than 'update' it.
  // 'update' - will update content of model only if legal (e.g., applying ifMatch rules).
  // isAutosave true means policy === 'create'.
  abstract save(modelObject: ModelObject, isAutosave?: boolean): any;
  abstract getModels(path?: string): Promise<Array<string>>;
  abstract getFolders(path?: string): Promise<Array<string>>;
  abstract deleteModel(modelName, path, fileType): any;
  abstract getParentPath(parentName, currentPath): string;
  abstract getCurrentDir(path): string;
  abstract isVersion(path): boolean;
  abstract createFolder(folderName, path): Promise<boolean>;
  abstract exportModel(modelObject: ModelObject);
  abstract isInPath(currentModelPath, checkPath);
  abstract isExist(name, path, type): boolean;

}

export enum DisplayModelType {
  MAIN = 'main',
  AUTOSAVE = 'autosave',
  VERSION = 'version'
}

export enum DisplayModelPermissionType {
  READ = 'read',
  WRITE = 'write',
}

export interface DisplayModel {
  id: string,
  title: string,
  description: string,
  archiveMode: {
    archiveMode: boolean,
    date: string,
    name: string
  },
  editBy: {
    date: string,
    name: string
  },
  path: string,
  permissions: DisplayModelPermissionType,
  type: DisplayModelType,
  exampleType?: 'SYS' | 'ORG',
  templateType?: 'SYS' | 'ORG' | 'PERSONAL',
  image?: string,
  lastAutosaveData?: {
    date: string,
    name: string
  },
  fatherModelId?: string,
}

export interface VerisonsServerData {
  id: string,
  title: string,
  description: string,
  archiveMode: {
    archiveMode: boolean,
    date: string,
    name: string
  },
  editBy: string,
  versions: Array<{
    ver_index: string,
    date: string
  }>
}

export enum DisplayFolderType {
  ORDINARY = 'ordinary',
  VERSION = 'version'
}

export interface DisplayFolder {
  id: string,
  name: string,
  type: DisplayFolderType,
  permissions?:UserFolderPermissions
}

export interface UserFolderPermissions {
  read: boolean,
  write: boolean,
  owner: boolean,
}

export interface DirectoryData {
  id: string,
  title: string,
  father: string,
};
