import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../../rappid-components/services/user.service';
import { SettingsModule } from '../../settings.module'
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateUserDialogComponent } from "../../../../dialogs/update-user-dialog/update-user-dialog.component";
import { OrganizationService } from '../../../../rappid-components/services/organization.service';

@Component({
  selector: 'settings-update-org-users-details',
  templateUrl: 'update-org-users-details.html',
  styleUrls: ['update-org-users-details.scss']
})

export class SettingsUpdateOrgUserDetails implements OnInit, SettingsModule {
  users;
  curUser;

  constructor(
    private userService: UserService,
    public dialog: MatDialog,
    public orgService: OrganizationService) {
    //this.users = this.getUsers();
  }

  ngOnInit() {
    this.users.splice(0, this.users.length);
  }

  /*
  getUsers() {
    return this.userService.getUserListWithDetails();
  }

  updateuser(user) {
    this.curUser = user;
    this.userService.setUser2Update(user);
    this.openDialog();
  }*/

  openDialog() {
    const dialogRef = this.dialog.open(UpdateUserDialogComponent);
  }

  switch(user) {
    user.flag = user.flag ? false : true;
  }

  // resetPassword(user) {
  //   this.userService.resetUserPassword(user.Email);
  // }

}

