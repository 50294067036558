import { Injectable } from '@angular/core';

import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { Observable, of } from 'rxjs';

import { UserService } from '../../rappid-components/services/user.service';
import { ContextService } from '../../modules/app/context.service';
import {EmptyContext} from "../../modules/app/context";
import {ExportOPLAPIService} from "../../modules/app/export-opl.service";

@Injectable()
export class OpcAppResolver  {

    constructor(private readonly user: UserService,
        private readonly context: ContextService, private oplExporting: ExportOPLAPIService) {
    }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const id = route.params['id'];
        const opd_id = route.params['opd'];
        const name = route.queryParams['name'];
        const path = route.queryParams['path'];
        const opd = route.queryParams['opd'];
        const isOplExporting = route.queryParams['export-opl'] === 'true';

        if (id || name) {
            return this.user.user$.take(1).map(user => {
                if (name)
                    this.context.loadModelByPath(name, path, opd);
                else if (id) {
                  if (this.context.getTabs().find(t => (<any>t?.context)?.properties?.id === id))
                    return;
                  this.context.oplService.options.isLoadingModel = true;
                  this.context.loadModel(id, undefined, 'MAIN', opd_id).then(async res => {
                    // if we loaded using a link, there shouldnt be an empty model.
                    if (this.context.getTabs().length > 1 && this.context.getTabs()[0].context instanceof EmptyContext)
                      this.context.removeTab(0);
                    if (isOplExporting) {
                      this.oplExporting.exportOPL(null);
                    }
                  });
                }

            });
        }

        return of({});
    }

}
