import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OplService } from '../../opl-generation/opl.service';
import { PipeTransform, Pipe } from '@angular/core';
import {oplDefaultSettings, Languages, OplTables, DisplayOpt} from '../../opl-generation/opl-database';
import * as FileSaver from 'file-saver';
import {Essence} from '../../models/ConfigurationOptions';
import {Affiliation} from '../../models/ConfigurationOptions';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {UserService} from '../../rappid-components/services/user.service';
import {OrganizationService} from '../../rappid-components/services/organization.service';

@Component({
  selector: 'opcloud-opl-dialog',
  template: `
    <div id="mainContainer">
      <div class="container">
        <div class="h1">OPL Table</div>
        <br>
        <br>
        <div id="mainContent">

          <label>Language</label>
          <span id="arrow1">
        <svg width="14" height="9" viewBox="0 0 14 9" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M13 1L7 7L1 1" stroke="#1A3763" stroke-width="2"/>
        </svg>
      </span>
          <select [(ngModel)]="language" name="selectLan" (click)="updateTable(language)">
            <option class="optionText" *ngFor="let lan of availableLanguage" [attr.value]="lan">
              {{lan}}
            </option>
          </select>
          <br>
          <br>
          <label>Add new language</label>
          <input type="text" name="newLanguage" value="new language code" [(ngModel)] = "newLanguage" class = "addLan">
          <button (click)="addLanguage(newLanguage)">Add language</button>
          <br>
          <button id="rtnDfltBTN" (click)="ReturnToDefault()">Reset to Default</button>
          <button id="rtnDfltBTN" (click)="exportTable()">Export Table</button>
          <br>
        </div>
        <div class="tableContainer">
          <mat-dialog-content>
            <table *ngFor="let relation of oplTable | keys" >
              <thead>
              <tr><th colspan="2">{{relation.key}}</th></tr>
              </thead>
              <tbody>
              <tr *ngFor="let link of relation.value | keys">
                <td class="tdKey">{{link.key}}</td>
                <td>
                  <span (click)="edit[relation.key][link.key]=true"
                        *ngIf="!edit[relation.key][link.key]" class="tdValue">{{ link.value }} </span>
                  <input *ngIf="edit[relation.key][link.key]"
                         [value]="oplTable[relation.key][link.key]"
                         [(ngModel)]="oplTable[relation.key][link.key]">
                </td>
              </tr>
              </tbody>
            </table>
          </mat-dialog-content>
          <!--<button (click)="saveTable()">save</button>-->
          
        </div>
      </div>
    </div>
  `,
  styleUrls: ['./opl-dialog.component.css']
})


export class OplDialogComponentSysAdmin implements OnInit {

  public oplTable;
  public language;
  public availableLanguage;
  public edit = {};
  newLanguage;

  constructor(
    private oplService: OplService,private user: UserService,private orgS: OrganizationService){
    this.language = oplService.orgOplSettings.language;
    this.oplTable = oplService.orgOplSettings.oplTables[this.language];
    this.availableLanguage = oplService.getAvailableLanguages();
  }

  ngOnInit() {
    this.updateTable( this.language );
    this.initiateEdit();
  }

  updateTable(lan) {
    this.oplTable = this.oplService.getOplTable(lan);
  }
  cancelTableChange() {
    this.oplTable = OplTables[this.language];
  }
  ReturnToDefault(){
    this.oplTable = this.oplService.getOplTable(this.language);
  }
  initiateEdit() {
    for (const relation of Object.keys(this.oplTable)){
      this.edit[relation] = {};
      for (const link of Object.keys(this.oplTable[relation])){
        this.edit[relation][link] = false;
      }
    }
  }
  addLanguage(lan){
    if(lan === undefined){
      alert('Language most have name.');
      return;
    }
    if (this.availableLanguage.indexOf(lan) === -1){
      this.oplService.orgOplSettings.oplTables[lan] = this.oplTable;
      const settings = this.oplService.orgOplSettings;
      const details = {};
      Object.keys(settings).forEach(key => {
        if (settings[key] === undefined)
          return;
        if(key === 'oplTables'){
          details[key]={};
          for(let lan of Object.keys(settings[key])){
            if(Languages.indexOf(lan)>-1){
              continue;
            }
            details[key][lan] = settings[key][lan];
          }
        }

        if (key === 'essence') {
          details[key] = String(settings[key]);
          return;
        }
        details[key]=settings[key];
      });
      this.oplService.updateDefaultSettings();
      this.orgS.updateOrganization(this.user.user.userData.organization,details);
      validationAlert(`New language ${lan} is added!`);
    }else{
      validationAlert('The language already exists!');
    }
    this.availableLanguage = this.oplService.getAvailableLanguages();
    this.newLanguage = '';
  }
  exportTable(){
    const table = JSON.stringify(this.oplTable, null, ' ');
    FileSaver.saveAs(new Blob([table], {type: 'text/plain;charset=utf-8'}),
      'OPL_table_' + this.language + '.txt');
  }

}

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];
    let key;
    for ( key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}


//<input type="text" [value]="link.value" class="tdValue"
//  [(ngModel)]="link.value"
//(keyup.enter)="changeReservedPhrase(relation.key, link.key, $event)">
