import {OpmStructuralLink} from './OpmStructuralLink';
import {getInitRappidShared} from '../../configuration/rappidEnviromentFunctionality/shared';
import {OpmLink} from './OpmLink';
import {OpmVisualEntity} from './OpmVisualEntity';
import {linkType} from '../ConfigurationOptions';
import {OpmVisualThing} from './OpmVisualThing';

export class OpmFundamentalLink extends OpmStructuralLink {
  // The vertices on the connection from the entity to the triangle
  private UpperConnectionVertices: Array<{x: number, y: number}>;
  // Triangle position
  private symbolPos: [number, number];
  private upperLinkAnchorPos;
  private targetAnchorPos;

  constructor(params, logicalElement) {
    super(params, logicalElement);
  }

  updateParams(params) {
    super.updateParams(params);
    this.symbolPos = params.symbolPos;
    this.UpperConnectionVertices = params.UpperConnectionVertices;
    this.upperLinkAnchorPos = params.upperLinkAnchorPos;
    this.targetAnchorPos = params.targetAnchorPos;
  }

  setParams(params) {
    super.setParams(params);
    this.symbolPos = params.symbolPos;
    this.UpperConnectionVertices = params.UpperConnectionVertices;
    this.upperLinkAnchorPos = params.upperLinkAnchorPos;
    this.targetAnchorPos = params.targetAnchorPos;
  }

  isFundamentalLink() {
    return true;
  }

  getParams() {
    const params = {
      symbolPos: this.symbolPos,
      UpperConnectionVertices: this.UpperConnectionVertices,
      upperLinkAnchorPos: this.upperLinkAnchorPos,
      targetAnchorPos: this.targetAnchorPos
    };
    return {...super.getStructuralParams(), ...params};
  }
  getParamsFromJsonElement(jsonElement) {
    const params = {
      symbolPos: jsonElement.symbolPos,
      UpperConnectionVertices: jsonElement.UpperConnectionVertices,
      upperLinkAnchorPos: jsonElement.upperLinkAnchorPos,
      targetAnchorPos: jsonElement.targetAnchorPos
    };
    return {...super.getStructuralParamsFromJsonElement(jsonElement), ...params};
  }
  clone() {
    const newLink = new OpmFundamentalLink(this.getParams(), this.logicalElement);
    newLink.setNewUUID();
    return newLink;
  }
  getSymbolPos() {
    return this.symbolPos;
  }

  setSymbolPos(x: number, y: number) {
    this.symbolPos = [x,y];
  }

  public canBeRemoved(): boolean {
    if (this.belongsToFatherModelId) {
      return false;
    }
    if (this.type === linkType.Aggregation && this.source instanceof OpmVisualThing && this.source.getRefineeUnfold() &&
      this.source.getRefineeUnfold() === this.source && this.source.getRefineeInzoom() && this.source.getRefineeInzoom().children
        .find(child => child.logicalElement === this.target.logicalElement))
      return false;
    if (this.logicalElement.opmModel.getOpdByThingId(this.id) && this.logicalElement.opmModel.getOpdByThingId(this.id).isHidden)
      return false;
    return true;
  }

}
