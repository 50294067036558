import { Injectable } from '@angular/core';
import { OrganizationService } from './organization.service';
import { UserService } from './user.service';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { Observable } from 'rxjs';
import { DatabaseService } from '../../database/database.service';

interface UserStruct {
    key: string;
    val: object;
}

@Injectable()
export class GroupsService {
    org: '';
    private userList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    public userList$: Observable<Array<any>> = this.userList.asObservable();
    private groupList: BehaviorSubject<Array<any>> = new BehaviorSubject<Array<any>>([]);
    public groupList$: Observable<Array<any>> = this.groupList.asObservable();

    constructor(private orgService: OrganizationService,
        private userService: UserService,
        private dbService: DatabaseService) {
        this.updateOrgGroups();
        this.updateOrgUsers();
    }

    groupExists: boolean;
    groupsFolderExists: boolean;
    userExists: boolean;
    counterTracker;
    updateOrgGroups(org = this.userService.userOrg) {
      this.userService.user$.subscribe(user => {
        if (!org)
          org = user.userData.organization;
          const groupsPromise = this.orgService.getOrganizationGroups(org);
          return groupsPromise.then(groups => {
            this.groupList = new BehaviorSubject<Array<any>>([]);
            /*const groupsList = [];
            groups.forEach(group => {
              groupsList.push(group);
            });*/
            this.groupList.next(groups);
          }).catch(err => this.groupList.next([]));
        });
    }
    updateOrgUsers(org = '') {
        const userList = [];
        this.userService.user$.take(1).toPromise().then(user => {
          if (org === '')
            org = user.userData.organization;
            this.orgService.getOrganizationUsers(org).then(users => {
                this.userList = new BehaviorSubject<Array<any>>([]);
                if (!users)
                    return;
                users.forEach(user => {
                    userList.push(user);
                });
                this.userList.next(userList);
            });
        })

    }
    getMembersByGroup(groupID): Array<any> { // Kfir : Done
        const group = this.getGroupByID(groupID);

        // Very bad...
        // if (this.dbService.driver instanceof MongoDatabaseDriver)
        //     return group.Members;

        const membersList = [];
        if (group?.Members)
          for (let user in group.Members) {
              membersList.push(user);
          }
        return membersList;
    }
    getAdminsByGroup(groupID) { // Kfir : Done
        const group = this.getGroupByID(groupID);
        // Very bad...
        // if (this.dbService.driver instanceof MongoDatabaseDriver)
        //     return group.Administrators;

        const membersList = [];
        if (group?.Administrators)
          for (let user in group.Administrators) {
              membersList.push(user);
          }
        return membersList;
    }
    getAllUsersByGroup(groupID) {
        let allUsersList = this.getMembersByGroup(groupID);
        allUsersList = allUsersList.concat(this.getAdminsByGroup(groupID));
        return allUsersList.filter((element, index, list) => index === list.indexOf(element)); // Remove duplicates from the array
    }
    getGroupsByUserID(userID) { // Kfir : Done
        let groupList;
        const res = [];
        this.groupList$.subscribe(list => {
            groupList = list;
            groupList.forEach(group => {
                const members = this.getMembersByGroup(group.GroupID);
                const admins = this.getAdminsByGroup(group.GroupID);
                if (members.includes(userID) || admins.includes(userID)) {
                    res.push(group.GroupID);
                }
            });
        });
        return res;
    }

  getGroupsByUserIDNew(userID) {
    let groupList;
    const res = [];
    return this.orgService.getOrganizationGroups(this.userService.userOrg).then(list => {
      groupList = list;
      groupList.forEach(group => {
        const members = this.getMembersByGroup(group.GroupID);
        const admins = this.getAdminsByGroup(group.GroupID);
        if (members.includes(userID) || admins.includes(userID)) {
          res.push(group.GroupID);
        }
      });
      return res;
    });
  }

    getSubGroupsByGroupID(groupID) { // Kfir: Done
        const group = this.getGroupByID(groupID);
        let res;
        return typeof group !== 'undefined' ? group.subGroups : res;
    }
    getGroupParent(groupID) { // Kfir: Done
        const group = this.getGroupByID(groupID);
        let res;
        return typeof group !== 'undefined' ? group.Parent : res;
    }

    getGroupNameByID(groupID) { // Kfir: Done
        const group = this.getGroupByID(groupID);
        return group?.Name;
    }

    getGroupByID(groupID) { // Kfir: Done
        // this.updateOrgGroups();
        for (let i = 0; i < this.groupList.getValue().length; i++) {
            if (this.groupList.getValue()[i].GroupID === groupID)
                return this.groupList.value[i];
        }
        return null;
    }

    getUserById(userID) { // Kfir : Done
        for (let i = 0; i < this.userList.getValue().length; i++) {
            if (this.userList.getValue()[i].uid === userID)
                return this.userList.value[i];
        }
        return null;
    }

    getUserEmail(userID) { // Kfir : Done
        const user = this.getUserById(userID);
        return user ? user.Email : 'undefined';
    }

    getUserName(userID) { // Kfir : Done
        const user = this.getUserById(userID);
        return user ? user.Name : 'undefined';
    }

    addGroup(org, nameOfGroup, descriptionOfGroup, parent, parentName) {
        this.org = org;
        this.updateOrgGroups(org);
        return this.orgService.addGroup(org, nameOfGroup, descriptionOfGroup, parent, parentName);


    }
    /**
     * Alon: Deleting a group
     * @param org
     * @param group
     */
    deleteGroupg(org, groupKey, groupParent, groupName) {
        return this.orgService.deleteGroups(org, groupKey, groupParent, groupName);
    }
    /*returnCurCounter() {
      return this.orgService.returnCurCounter(this.org);
    }*/
    addMembers(user, groupID, org) {
        this.orgService.addMembers(user, groupID, org);
        this.updateOrgGroups(org);
        return;
    }
    addMultipleMembers(usersAraay, group, org) {
        this.orgService.addMultipleMembers(usersAraay, group, org);
        this.updateOrgGroups(org);
    }
    addUserToAdminGroup(org, groupID, user) {
        this.orgService.addUserToAdminGroup(org, groupID, user);
        this.updateOrgGroups(org);
        return;
    }
    delUserFromGroup(org, groupID, user) {
        this.orgService.delUserFromGroup(org, groupID, user);
        this.updateOrgGroups(org);
    }
    delAdminGroupUser(org, groupID, user) {
        this.orgService.delAdminGroupUser(org, groupID, user);
        this.updateOrgGroups(org);
    }
    addAdminGroup(org, nameOfGroup, descriptionOfGroup, counter) {

    }
    deleteMultipleUsers(org, group, usersArray) {
        console.log(usersArray);
    }


    setGroupName() { } // not implemented

    setGroupPermissions() { } // group of admins, if you add a user he becomes an admin.. // not implemented

    // not implemented
    setGroupPrefrances(group) { } // modelperfrances, such as... if things are informatical or physical, enviromental or systemic ...

    addSubGroup(group) { } // not implemented



}
