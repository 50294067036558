// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  background: lightskyblue;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/Settings/group-mgmt/update-user-grp/member-grp/member-grp.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B","sourcesContent":[".selected {\n  background: lightskyblue;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
