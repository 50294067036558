export enum ValueAttributeType {
    STRING,
    CHAR,
    INTEGER,
    FLOAT,
    BOOLEAN
}

export interface ValueAttributeRange {
    setPattern(value: string): { wasSet: boolean },
    validate(value: string): boolean,
    getDefault(): string,
    getPattern(): string,
    getType(): ValueAttributeType,
    isSubRange(newRange): boolean,
}
