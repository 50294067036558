import {AuthenticationDriver} from "../rappid-components/services/authentication/authentication.driver";
import {Observable, ReplaySubject} from "rxjs";
import {DatabaseService} from "./database.service";
import {Injectable} from "@angular/core";
import {environment} from "../../environments/environment";

@Injectable()
export class ServerAuthDriver implements AuthenticationDriver {


  private readonly token_ = new ReplaySubject<string>(1);
  private readonly token$ = this.token_.asObservable();

  constructor(private database: DatabaseService) {
  }

  public token(): Observable<string> {
    return this.token$;
  }


  signInWithEmailAndPassword(email: string, password: string, verificationCode?: string): Promise<boolean> {
    return this.database.driver.signInWithEmailAndPassword(email, password, verificationCode).then(data => {
      this.token_.next(data.token);
      localStorage.setItem("auth_token", data.token);
      return data;
    });
  }

  async autoSignIn(): Promise<boolean> {
    const token = localStorage.getItem("auth_token");
    if (token) {
      this.token_.next(token);
      return fetch(environment.apiTarget + '/user', {
        method: 'GET',
        headers: {
          auth: token
        },
      }).then(res => res.json());
    }
    return Promise.resolve(false);
  }

  sendPasswordResetEmail(user_email: string): Promise<boolean> {
    return Promise.resolve(false);
  }

  signInWithPopup(provider) {
  }

  signOut(): Promise<boolean> {
    localStorage.removeItem("auth_token");
    return Promise.resolve(false);
  }

  signInWithCustomToken(token: any) {
    this.token_.next(token.token);
    return Promise.resolve(token.token);
  }

  public refreshExpiredToken() {
  }

  signInWith2FactorAuthentication(error: any, recaptchaVerifier: any): Promise<any> {
    throw new Error('Method not implemented.');
  }

  add2FactorAuthenticationForUser(recaptchaVerifier: any, phoneNumber: string): Promise<any> {
    throw new Error('Method not implemented.');
  }

  finishAdd2FactorAuthForUser(verificationCode: string, verificationId: any): Promise<{success: boolean, message?: string}> {
    throw new Error('Method not implemented.');
  }

  removeSecondAuthFactorForUser(): Promise<void> {
    throw new Error('Method not implemented.');
  }

}
