import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmState } from "../../DrawnPart/OpmState";
import { OpmLogicalState } from "../../LogicalPart/OpmLogicalState";
import { OpmVisualState } from "../../VisualPart/OpmVisualState";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class SetStateTimeDurationCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly state: OpmState,
        private readonly visual: OpmVisualState) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'set-time-duration',
            displayTitle: 'Add Time Duration',
            svg: 'timeDuration',
            action: new SetStateTimeDurationAction(this.init, this.state, this.visual),
            gif: 'assets/gifs/state_set_time_duration.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'set-time-duration',
            displayTitle: 'Add Time Duration',
            svg: 'timeDuration',
            action: new SetStateTimeDurationAction(this.init, this.state, this.visual),
            gif: 'assets/gifs/state_set_time_duration.gif',
        }
    }

}

class SetStateTimeDurationAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly drawn: OpmState,
        private readonly visual: OpmVisualState) {
    }

    act() {
      const cell = this.init.graph.getCell(this.drawn.id);
      const vis = this.init.opmModel.getVisualElementById(this.drawn.id);
      if (cell && vis)
        cell.openTimeDuration(this.init.paper.findViewByModel(cell).el, (vis.logicalElement as OpmLogicalState).getDurationManager(), { digits: this.init.oplService.settings.timeDurationUnitsDigits });
    }

}
