import { NoteType } from './note-type';

interface UserDetails {
  uid: string;
  displayName: string;
}

export interface NoteDataJsonRaw {
  createdBy: string;
  createdDate: string;
  modifiedBy: string;
  modifiedDate: string;
  content: string;
  type: string;
}

export interface NoteDataJson extends NoteDataJsonRaw {
  createdByName: string;
  modifiedByName: string;
}

export class NoteData {
  get createdBy() {
    return this._createdBy;
  }

  get createdDate() {
    return this._createdDate;
  }

  get modifiedBy() {
    return this._modifiedBy;
  }

  get modifiedDate() {
    return this._modifiedDate;
  }

  get content() {
    return this._content;
  }

  get type() {
    return this._type;
  }

  constructor(
    private _createdBy: UserDetails,
    private _createdDate: Date = new Date(),
    private _modifiedBy: UserDetails = _createdBy,
    private _modifiedDate: Date = new Date(),
    private _content: string = 'write your comment here',
    private _type: NoteType = NoteType.noteTypes[0]) {
  }

  static fromJSON(json: NoteDataJson): NoteData {
    const type = NoteType.getType(json.type);
    return new NoteData(
      { uid: json.createdBy, displayName: json.createdByName },
      new Date(json.createdDate),
      { uid: json.modifiedBy, displayName: json.modifiedByName },
      new Date(json.modifiedDate),
      json.content,
      type || NoteType.noteTypes[0]
    );
  }

  toJSON(): NoteDataJson {
    return {
      createdBy: this.createdBy.uid,
      createdDate: this.createdDate.toJSON(),
      modifiedBy: this.modifiedBy.uid,
      modifiedDate: this.modifiedDate.toJSON(),
      content: this.content,
      type: this.type.value,
      createdByName: this.createdBy.displayName,
      modifiedByName: this.modifiedBy.displayName
    };
  }

  update(userDetails: UserDetails, updates: { content?: string, type?: string | NoteType }) {
    const typeUpdated = this._updateType(updates.type);
    const contentUpdated = this._updateContent(updates.content);
    if (typeUpdated || contentUpdated) {
      this._modifiedBy = userDetails;
      this._modifiedDate = new Date();
    }
  }

  private _updateType(type: string | NoteType) {
    const foundType = !isNullOrUndefined(type) && NoteType.getType(type);
    if (foundType && foundType !== this._type) {
      this._type = foundType;
      return true;
    } else {
      return false;
    }
  }

  private _updateContent(content) {
    if (!isNullOrUndefined(content) && content !== this._content) {
      this._content = content;
      return true;
    }
  }
}


function isNullOrUndefined(item) {
  return item === null || item === undefined;
}
