import {OpmDefaultLink} from './OpmDefaultLink';
import {OpmEntity} from '../OpmEntity';
import {OpmStructuralLink} from './OpmStructuralLink';
import {joint, _, getInitRappidShared, OPCloudUtils} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {dia} from "jointjs";
import {createDialog} from '../../../configuration/elementsFunctionality/linkDialog';
import {OpmLink} from "../../VisualPart/OpmLink";
import {OpmVisualEntity} from "../../VisualPart/OpmVisualEntity";
import {linkType} from '../../ConfigurationOptions';
import {OpmVisualThing} from '../../VisualPart/OpmVisualThing';
import {EntityType} from '../../model/entities.enum';
import {OpmThing} from '../OpmThing';
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {OpmVisualObject} from "../../VisualPart/OpmVisualObject";


export class OpmFundamentalLink extends OpmStructuralLink {
  sourceElement: OpmEntity;
  targetElement: OpmEntity;
  triangle: any;
  mainUpperLink: OpmDefaultLink;
  graph: dia.Graph;
  constructor(sourceElement, targetElement, graph ,id?:string) {
    super(id);
    this.sourceElement = sourceElement;
    this.targetElement = targetElement;
    // Get all outgoing links from the source element
    this.graph = graph;
    const outboundLinks = graph.getConnectedLinks(this.sourceElement, { outbound: true });
    const isPointObstacle = function(point) {
      if (sourceElement.graph?.findModelsFromPoint(point).find(c =>
        OPCloudUtils.isInstanceOfDrawnEntity(c) || c.constructor.name.includes('Triangle')))
        return true;
      return false;
    };
    const router = { name: 'manhattan', args: {
        padding: 5,
        step: 11,
        startDirections: ['bottom'],
        isPointObstacle: isPointObstacle,
      }};
    for (const pt in outboundLinks) {
      // Already exists a link with the same type
      if (outboundLinks[pt].get('OpmLinkType') === this.constructor.name) {
        this.mainUpperLink = outboundLinks[pt];
        this.triangle = outboundLinks[pt].getTargetElement();
      }
    }
    // If didn't found a matching link, need to create one and a triangle
    if (!this.triangle) {
      this.triangle = new TriangleClass();
      const newX = (this.sourceElement.getBBox().center().x + this.targetElement.getBBox().center().x) / 2;
      const newY = (this.sourceElement.getBBox().center().y + this.targetElement.getBBox().center().y) / 2;
      this.triangle.set('position', {x: newX, y: newY});
      this.triangle.set('size', {width: 30, height: 25});
      this.triangle.set('numberOfTargets', 0);
      // Define the link from the source element to the triangle
      const newLink = new OpmDefaultLink();
      newLink.set({
        source: {id: this.sourceElement.id},
        target: {id: this.triangle.id, port: 'in'},
        connector : {name: 'normal'},
        OpmLinkType: this.constructor.name
      });
      newLink.attr('line/targetMarker', {
        type: 'polyline', // SVG polyline
        fill: 'none',
        stroke: 'rgba(88,109,140,0)',
        'stroke-width': 2,
        points:'0,0 -2,0'
      });

      newLink.attr({
        '.link-tools': {display: 'none'},
        '.marker-arrowheads': {display: 'none'},
        'line': {'strokeDasharray': '0'}
      });

      const upperLinkRouter = { name: 'manhattan', args: {
          padding: 5,
          step: 11,
          endDirections: ['top'],
          isPointObstacle: isPointObstacle,
        }};
      newLink.router(upperLinkRouter);
      this.mainUpperLink = newLink;
      try { graph.addCells([this.triangle, newLink])} catch (e) { }
      if(outboundLinks.length > 0 &&  outboundLinks[0].getTargetElement() instanceof TriangleClass){
        outboundLinks[0].getTargetElement().checkFOrOverLapping(sourceElement);
    }
      newLink.set({
        source: {id: this.sourceElement.id,
          // port: sourceElement.setPorts(side)
        },
      });
    }
    // Define the connection from the triangle to the current link
      this.set({
      source: {id: this.triangle.id, port: 'out'},
      target: {id: this.targetElement.id},
      connector : {name: 'normal'}
    });

    this.router(router);

    let numberOfTargets = this.triangle.get('numberOfTargets') + 1;
    this.triangle.set('numberOfTargets', numberOfTargets);

    // this.checkForOverLappingConnectionOnPorts();
    const img = this.getTriangleSVG(false, this.attr('line/stroke'));
    this.triangle.attr({image: {'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(img)}});
  }

  getTriangleSVG(withLine = false, color =  '#586D8C'): string {
    return '';
  }

  checkForOverLappingConnectionOnPorts(){
    const source = this.graph.getCell(this.mainUpperLink.source().id);
    const target = this.graph.getCell(this.target().id);
    this.checkOnSource(source);
    this.checkOnTarget(target);
  }

  checkOnSource(source) {
    console.log(this.graph.getConnectedLinks(source, ));
  }

  checkOnTarget(target) {
    console.log(this.graph.getConnectedLinks(target, {inbound: true}));

  }
  getFundamentalLinkParams() {
    const params = {
      symbolPos: [this.triangle.get('position').x, this.triangle.get('position').y],
      UpperConnectionVertices: this.mainUpperLink.get('vertices'),
      sourceElementId: this.mainUpperLink.getSourceElement()?.get('id') || this.sourceElement?.get('id'),
      sourceVisualElementPort: this.mainUpperLink.get('source').port,
      targetVisualElementPort: this.get('target').port,
      upperLinkAnchorPos: this.mainUpperLink.prop('source/anchor'),
      targetAnchorPos: this.prop('target/anchor')
    };
    return {...super.getStructuralLinkParams(), ...params};
  }
  // handles the line adding/deleting and number updating/deleting in the triangle after removing a thing from the relation
  removeHandle(options) {
    super.removeHandle(options);
    const numberOfTargets = this.triangle.get('numberOfTargets');
    this.triangle.set('numberOfTargets', (numberOfTargets - 1));
    if (this.triangle.get('numberOfTargets') < 1) {
      this.triangle.remove();
    } else {
      // getting a remaining link that is connected to the triangle
      const remainingLink = options.graph.getConnectedLinks(this.triangle, {outbound: true})[0] as OpmFundamentalLink;
      if (remainingLink && remainingLink.mainUpperLink)
        remainingLink.mainUpperLink.deleteLabel();
      if (remainingLink && remainingLink.getVisual() && (<any>remainingLink.getVisual()).CheckAddLine().missingNumber > 0) { // check if there is need to add a line and number
        remainingLink.AddLineAndNumber();   // add line and number
      }
    }
  }
  getTriangle() {
    return this.triangle;
  }
  getTriangleChildren() {
    const links_out = this.graph.getConnectedLinks(this.triangle, {outbound: true});
    const targets = [];
    const links = [];
    // links.push(this.mainUpperLink);
    for(const link of links_out){
      if(!link.getTargetElement()||!link.getSourceElement()){
        continue;
      }
      const target = link.getTargetElement() as any;
      targets.push(link.getTargetElement());
      target.attributes.targetMultiplicity = (<any>link).attributes.targetMultiplicity;
      links.push(link);
    }
    return [targets,links];
  }
  getAllFundamentalLinks(){
    return this.graph.getConnectedLinks(this.triangle, {outbound: true});
  }
  getSource() {
    return this.getMainUpperLink().getSourceElement() || this.sourceElement;
  }
  getMainUpperLink() {
    return this.mainUpperLink;
  }

  // adding a line and a value to every fundamental link that calls this function. the line is added via calling this function from
  // the link itself
  AddLineAndNumber() {
    if (!this.getVisual()) {
      return;
    }
    const numberAsString = String((<any>this.getVisual()).CheckAddLine().missingNumber); // the number we should show
    const listMissingChildrenNames = (<any>this.getVisual()).getMissingChildrenNames().names;
    if (!this.mainUpperLink.labels().find(lb => lb.attrs.label.text === numberAsString)) {
      this.mainUpperLink.setLabelsOLinks(numberAsString, 1, 20 , 15, undefined, undefined, false);
      this.mainUpperLink.attr({
        '.': {
          'data-tooltip': listMissingChildrenNames.join('<br>'),
          'data-tooltip-position': 'top',
        }
      });
    } // showing the number via adding it as a label
    const img = this.getTriangleSVG(true, this.attr('line/stroke'));
    this.triangle.attr({image: {'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(img)}});
  }

  updateParamsFromOpmModel(link) {
    super.updateParamsFromOpmModel(link);
    if (link.upperLinkAnchorPos && this.getMainUpperLink())
      this.getMainUpperLink().prop('source/anchor', link.upperLinkAnchorPos);
    if (link.targetAnchorPos)
      this.prop('target/anchor', link.targetAnchorPos);
  }

  isFundamentalLink(): boolean {
    return true;
  }

  updateTriangle(initRappid) {
    // if must add line/number.
    if (!initRappid.exportingOpl && this.getVisual() && (<any>this.getVisual()).CheckAddLine().missingNumber > 0) {
      this.AddLineAndNumber(); // add line and number
    } else {
      const img = this.getTriangleSVG(false, this.attr('line/stroke'));
      this.triangle.attr({image: {'xlink:href': 'data:image/svg+xml;utf8,' + encodeURIComponent(img)}});
    }
  }

  addHandle(options) {
    if (options.exportingOpl) {
      return;
    }
    super.addHandle(options);
    // getting the visual link
    const visualLink = getInitRappidShared().opmModel.getVisualElementById(this.id) as OpmLink;
    this.mainUpperLink.deleteLabel(); // delete the last number label in order to add new one
    // if must add line/number.
    if (this.getVisual() && (<any>this.getVisual()).CheckAddLine().missingNumber > 0) {
      this.AddLineAndNumber(); // add line and number
    }
    if (this.includedInOrderedTypes())
      this.addLabelOrderedSubpart();
  }



  getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {
    const term = this.getMainUpperLink().getSourceElement()?.getVisual()?.isSemiFolded() && !this.getTargetElement()?.getVisual()?.isSemiFolded();
    const term2 = this.getVisual().logicalElement.visualElements.length > 1;
    const term3 = (<any>this.getVisual()).source instanceof OpmVisualThing && (<any>this.getVisual()).source.getRefineeInzoom() && (<any>this.getVisual()).source.getRefineeInzoom().children.find(
      ch => ch.logicalElement === (<any>this.getVisual()).target.logicalElement);
    if (term && (term2 || term3))
      return super.getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool,
        boundaryTool, removeButton).concat([this.semifoldButton()]);
    return super.getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton);
  }

  foldRelation() {
    const init = getInitRappidShared();
    const source = this.getMainUpperLink().getSourceElement();

    if (this.getTargetElement()?.getVisual()?.isSemiFolded())
      return;

    init.getOpmModel().logForUndo('Fold in relation');
    init.getOpmModel().setShouldLogForUndoRedo(false, 'OpmFundamentalLink-foldRelation');
    const ret = init.getOpmModel().foldInFundamentalRelation(this.getVisual());
    if (ret.success) {
      init.getGraphService().viewSemiFoldedUpdate(ret);
      (<any>source).updateSizePositionToFitEmbeded(true);
      // init.graph.resetCells(init.graph.getCells());
      const semifolded = init.graph.getCells().filter(cl => cl.constructor.name.includes('OpmSemifoldedFund'));
      if (init)
        for (const sm of semifolded)
          sm.addHandle(init);
    }
    init.getOpmModel().setShouldLogForUndoRedo(true, 'OpmFundamentalLink-foldRelation');
    // source.beautifyAfterSemiFolding();
  }

  rightClickHandlePopoup(target, init) {
    const logicalTarget = (<any>this.getVisual()).target?.logicalElement;
    if (logicalTarget?.isSatisfiedRequirementObject() || logicalTarget?.isSatisfiedRequirementSetObject())
      return;
    return super.rightClickHandlePopoup(target, init);
  }

  popupContentDbClick() {
    const this_ = this;
    const ordered = this.includedInOrderedTypes();
    const orderedHtml = ordered ? 'checked="true"' : '';
    const targetMultiplicity = (this.attributes.targetMultiplicity) ? this.attributes.targetMultiplicity : '';
    const requirements = this.attributes.requirements || '';
    const showReqLabel = this.attributes.showRequirementsLabel;
    setTimeout(() => {
      if ($('.showReq').length)
        (<any>$('.showReq')[0]).checked = showReqLabel;
    }, 500); // fix for a bug that the custom "V" causes.
    return [
    '<div style="height: 16px" ><div class="textAndInput">Target Multiplicity : <input size="2"  class="trgt PopupInput" value="' + targetMultiplicity.trim() + '"></div><span data-title="' + this.getTargetMultiplicityPopupTooltipText() + '">' +
    '<img class="questionMarkForInfo"src="assets/SVG/questionmark.svg"></span></div><br>'+
    '<div style="height: 30px; margin-bottom: 4px; width: 273px;" ><div class="textAndInput">Requirement Set:<input size="2" class="PopupInput req" value="' + requirements + '" ></div><span data-title="Displaying satisfied requirements text on the link"><input type="checkbox"" class="checkbox-round showReq"></span>' +
    '<span class="iconSpan" style="margin-left: -8px;" data-title="' + this.getRequirementsPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span><img class="urlSvg" src="assets/SVG/url.svg"></div>',
    'Ordered: <span data-title="Adding OPD label “ordered” and the OPL reserved phrase “in that sequence” to indicate the order of the linked things">' +
    '<input type="checkbox" class="ordered" style="vertical-align: middle" title="check to order the subpart top down" ' +
      orderedHtml + '></span>'];
  }

  removeLinkFromOrderedFundamental() {
    const orderedFundamentalTypes = (<any>this.sourceElement.getVisual()?.logicalElement)?.orderedFundamentalTypes || [];
    if (orderedFundamentalTypes.includes((<any>this.getVisual()).type)) {
      const pos = orderedFundamentalTypes.indexOf((<any>this.getVisual()).type);
      orderedFundamentalTypes.splice(pos, 1);
      this.mainUpperLink.deleteLabel();
    }
  }

  addLabelOrderedSubpart() {
    if (this.mainUpperLink.labels().length === 0)
      this.mainUpperLink.setLabelsOLinks('ordered', 0.5);
  }

  semifoldButton() {
    const that = this;
    return new joint.linkTools.Button({
      markup: [
        {
          tagName: 'rect',
          attributes: {
            x: '-2', y: '-2', width: '18', height: '16', stroke: 'transparent', fill: 'transparent', 'transform': 'translate(-5,0) scale(2)',
          }
        },
        {
          tagName: 'rect',
          attributes: {
            x: '0.5', y: '0.5', width: '7', height: '10', stroke: '#1A3763', fill: '#FFFFFF', 'transform': 'translate(-5,0) scale(2)',
          }
        },
        {
          tagName: 'path',
          attributes: {
            'd': 'M4 2L5.73205 5H2.26795L4 2Z',
            'fill': '#1A3763',
            'transform': 'translate(-5,0) scale(2)',
          },
        },
        {
          tagName: 'path',
          attributes: {
            'd': 'M4 6L5.73205 9H2.26795L4 6Z',
            'fill': '#1A3763',
            'transform': 'translate(-5,0) scale(2)',
          },
        },
        {
          tagName: 'path',
          attributes: {
            'd': 'M10.5001 12.5L8.99896 12.5001L10.6309 12.4999C10.8756 12.4999 11.1193 12.4699 11.3567 12.4106L11.7084 12.3227C12.5472 12.113 13.2977 11.6421 13.8513 10.9779V10.9779C14.785 9.85778 14.7103 8.21047 13.679 7.17948L13.5592 7.05972C13.2007 6.70128 12.7145 6.49992 12.2075 6.49992L11.9993 6.49992L8.99929 6.49992',
            'fill': 'transparent',
            'stroke': '#1A3763',
            'transform': 'translate(-5,0) scale(2)',
          },
        },
        {
          tagName: 'path',
          attributes: {
            'd': 'M12 4L9 6.5L12 9',
            'fill': '#1A3763',
            'transform': 'translate(-5.7,0) scale(2)',
          },
        }],
      distance: '50%',
      action: () => that.foldRelation(),
    });
  }
}


export class TriangleClass extends joint.shapes.devs.Model.extend({
  markup: '<image/>',
  defaults: _.defaultsDeep({
    type: 'opm.TriangleAgg',

    inPorts: ['in'],
    outPorts: ['out'],
    ports: {
      groups: {
        'in': {
          position: {
            name: 'top',
          },
          attrs: {
            '.port-body': {
              fill: '#586D8C',
              magnet: true,
              r: 0,
              transform: 'translate(0,2)',
            }
          },
          label: {markup: '<text class="label-text"/>'}
        },
        'out': {
          position: {
            name: 'bottom',
          },
          attrs: {
            '.port-body': {
              fill: '#586D8C',
              magnet: true,
              r: 0
            }
          },
          label: {markup: '<text class="label-text"/>'}
        }
      }
    },

    attrs: {

    //  image: { 'xlink:href': 'assets/OPM_Links/StructuralAgg.png', width: 30, height: 30},
      image: {

      }

    }
  }, joint.shapes.devs.Model.prototype.defaults)}) {
  counter:number = 10;
  doubleClickHandle(cellView, evt, initRappid) {
    this.changeRelationType(cellView, initRappid);
  }
  rightClickHandle(cellView, options) {
    this.bringMissingFundamentals(options);
  }
  getBottomLinks() {
    return this.graph.getConnectedLinks(this, {outbound: true});
  }
  pointerUpHandle(cellView, options) {
    const source = options.graph.getConnectedLinks(this, { inbound: true }).filter(l => !!l)[0].getSourceElement();
    const targets = options.graph.getConnectedLinks(this, { outbound: true }).filter(l => !!l).map(link => link.getTargetElement());
    if (!OPCloudUtils.isInstanceOfDrawnSemiFoldedFundamental(source)) {
      source?.sortStructuralLinks();
    }
    for (const target of targets) {
      if (!OPCloudUtils.isInstanceOfDrawnSemiFoldedFundamental(target)) {
        target?.sortStructuralLinks();
      }
    }
  }
  getVisual() {return null;}
  changeAttributesHandle(options) {}
  changeSizeHandle(initRappid) {}
  changePositionHandle(initRappid) {}
  removeHandle(options) {}
  addHandle(options) {
    setTimeout(() => {
      // saving the initial pos of the triangle.
      const outboundLinks = options.graph.getConnectedLinks(this, { outbound: true }).filter(l => !!l);
      for (const link of outboundLinks) {
        const params = link.getParams();
        if (link.getVisual())
          link.getVisual().setParams(params);
      }
    }, 500);
  }

  /**
   * Alon: Checks if we have overlapping triangles
   * @param {OpmEntity} source
   */
  checkFOrOverLapping(source:OpmEntity):void{
    const outboundDefaultLinks = this.graph.getConnectedLinks(source, { outbound: true }).filter((link) => link.attributes.name === "defaultLink");
    if(outboundDefaultLinks.length > 0){
      for(let i = 0; i < outboundDefaultLinks.length; i++) {
        const overlappingTrianglesArray = this.graph.findModelsUnderElement(this.graph.getCell(outboundDefaultLinks[i].get('target')));
        if(overlappingTrianglesArray.length > 0 && outboundDefaultLinks[i].get('target') != this.id){
          this.setSpaces(overlappingTrianglesArray, source)
        }
      }
    }
    // this.moveLinksPorts(outboundDefaultLinks);
  }

  /**
   * Alon: Moves any over lapping triangle by 50px to the right or to the left(left or right based on sourceX <> triangleX )
   * @param {Array<TriangleClass>} overLappaing
   * @param {OpmEntity} source
   */
  setSpaces(overLappaing:Array<TriangleClass>, source:OpmEntity):void{
    for (let triangle of overLappaing){
        triangle.set({
          position:{
            x: (source.get('position').x < this.get('position').x) ? triangle.get('position').x + 50: triangle.get('position').x - 50,
            y: triangle.get('position').y
          }
        });
    }
  }

  moveLinksPorts(links:Array<OpmFundamentalLink>):void{

    // link.set({'source': {'id':link.getSourceElement().id, 'port': 17 }});

  }

  // setDefaultLinkArray(link:OpmDefaultLink){};

  pointerDownHandle() {}

  changeRelationType(cellView, initRappid) {
    if (initRappid.getOpmModel().currentOpd.isStereotypeOpd() ||  initRappid.getOpmModel().currentOpd.requirementViewOf  || initRappid.isDSMClusteredView.value === true) {
      return;
    }
    if (initRappid.opmModel.currentOpd.visualElements.find(v => v.belongsToSubModel)) {
      return;
    }
    const link = this.graph.getConnectedLinks(cellView.model, {inbound: true});
    const outLink = this.graph.getConnectedLinks(cellView.model, {outbound: true})[0];
    if (outLink.getVisual().type === linkType.Exhibition
      && (outLink.getTargetElement().getVisual() instanceof OpmVisualObject)
      && outLink.getTargetElement().getVisual().isValueTyped()) {
      return;
    }
    initRappid.getOpmModel().logForUndo('change structural link type');
    const sourceCell = link[0]?.getSourceElement();
    if (!sourceCell || sourceCell.attributes.attrs.digitalTwinConnected)
      return;
    const selected = this.graph.getConnectedLinks(cellView.model, {outbound: true});
    if (selected.length === 0)
      return;

    const visSource = sourceCell.getVisual();
    if (visSource && (visSource.logicalElement.isSatisfiedRequirementObject() || visSource.logicalElement.isSatisfiedRequirementSetObject())) {
      return;
    }
    const newLink =  new OpmDefaultLink();
    const selectedThings = new Array();
    selected.forEach( mLink => {
      selectedThings.push(initRappid.graph.getCell(mLink.get('target')));
    });

    for (const target of selectedThings) {
        if (OPCloudUtils.isInstanceOfDrawnEntity(target)) {
          const visTarget = target.getVisual();
          if (visTarget && (visTarget.logicalElement.isSatisfiedRequirementObject() || visTarget.logicalElement.isSatisfiedRequirementSetObject())) {
            return;
          }
        }
    }
    initRappid.selection.collection.reset([]);
    initRappid.selection.collection.add(selectedThings, {silent: true});
    newLink.source({id: link[0].get('source')});
    newLink.target({id: selected[0].get('target')});
    newLink.graph = cellView.model.graph;
    newLink.selection = initRappid.selection;
    newLink.replaceTriangleLink = initRappid.getOpmModel().getVisualElementById(selected[0].id);
    createDialog(initRappid, newLink);
  }

  bringMissingFundamentals(initRappid: InitRappidService) {
    if (initRappid.getOpmModel().currentOpd.isStereotypeOpd() ||  initRappid.getOpmModel().currentOpd.requirementViewOf  || initRappid.isDSMClusteredView.value === true) {
      return;
    }
    if (initRappid.opmModel.currentOpd.visualElements.find(v => v.belongsToSubModel)) {
      return;
    }
    const visSource = this.graph.getConnectedLinks(this, {'outbound': true})[0].getVisual().source;
    if (!visSource)
      return;
    const isSemiFolded = visSource.isSemiFolded();
    const foldedNumber = isSemiFolded ? visSource.semiFolded.length : 0;
    initRappid.getOpmModel().logForUndo('Bring missing Aggregation relations');
    initRappid.getOpmModel().setShouldLogForUndoRedo(false, 'bringMissingAggregations');
    const type = this.graph.getConnectedLinks(this, {'outbound': true})[0].getVisual().type;
    const ret = (<OpmVisualEntity>visSource).bringMissingFundamentals(type);
    if (isSemiFolded && visSource.semiFolded.length < foldedNumber) {
      initRappid.getGraphService().renderGraph(initRappid.getOpmModel().currentOpd);
    } else {
      for (const newEntity of ret.entities) {
        initRappid.getGraphService().updateEntity(newEntity);
        const cell = initRappid.graph.getCell(newEntity.id);
        if (cell) {
          newEntity.setParams(cell.getParams());
          if (cell instanceof OpmThing) {
            initRappid.setSelectedElement(cell);
            cell.shiftEmbeddedToEdge(initRappid);
          }
        }
      }
      initRappid.getGraphService().updateEntity(visSource);
      initRappid.getGraphService().updateLinksView(ret.links);
    }
    initRappid.getOpmModel().setShouldLogForUndoRedo(true, 'bringMissingAggregations');
  }
}
