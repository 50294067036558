import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmObject } from "../../DrawnPart/OpmObject";
import { OpmVisualObject } from "../../VisualPart/OpmVisualObject";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class AddStateCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'add-state',
            displayTitle: 'Add States',
            svg: 'addStates',
            action: new AddStatesAction(this.init, this.object, this.visual),
            gif: 'assets/gifs/add_states.gif'
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'add-state',
            displayTitle: 'Add States',
            svg: 'addStates',
            action: new AddStatesAction(this.init, this.object, this.visual),
            gif: 'assets/gifs/add_states.gif'
        }
    }

}

class AddStatesAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    act() {
        const cell = this.init.graph.getCell(this.visual.id);
        const vis = this.init.opmModel.getVisualElementById(this.visual.id) as OpmVisualObject;
        if (cell && vis)
          cell.addStateAction(vis, this.init);
    }

}
