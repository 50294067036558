import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmState } from "../../DrawnPart/OpmState";
import { OpmVisualState } from "../../VisualPart/OpmVisualState";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class SuppressStateCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly state: OpmState,
        private readonly visual: OpmVisualState) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'suppress',
            displayTitle: 'Suppress',
            svg: 'supressHalo',
            action: new SupressStateCommandAction(this.init, this.state, this.visual),
            gif: 'assets/gifs/suppress_single_state.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'suppress',
            displayTitle: 'Suppress',
            svg: 'supressHalo',
            action: new SupressStateCommandAction(this.init, this.state, this.visual),
            gif: 'assets/gifs/suppress_single_state.gif',
        }
    }

}

class SupressStateCommandAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly drawn: OpmState,
        private readonly visual: OpmVisualState) {
    }

    act() {
      const cell = this.init.graph.getCell(this.drawn.id);
      const vis = this.init.opmModel.getVisualElementById(this.drawn.id);
      if (cell && vis)
        cell.suppressAction(vis, this.init);
    }

}
