import { OpmLogicalThing } from './OpmLogicalThing';
import { OpmVisualProcess } from '../VisualPart/OpmVisualProcess';
import * as ConfigurationOptions from '../ConfigurationOptions';
import { code } from '../ConfigurationOptions';
import { isNumber } from 'util';
import { TimeDurationModule } from './components/TimeDurationModule';
import { TextualModule } from './components/interfaces';

export class OpmLogicalProcess extends OpmLogicalThing<OpmVisualProcess> {

  private duration = new TimeDurationModule();
  private waitingProcessLid: string;
  private isWaitingProcess = false;
  private _code: ConfigurationOptions.code;
  private _insertedFunction: any;
  static logicalCounter = 1;
  private _needUserInput: boolean;
  static resetLogicalCounter() {
    OpmLogicalProcess.logicalCounter = 1;
  }

  constructor(params, model, addToCurrentOpd = true) {
    super(params, model);
    OpmLogicalProcess.logicalCounter++;
    this.textModule.addTextualModules(new ProcessComputational(this), this.duration);
    this._code = ConfigurationOptions.code.Unspecified;
  }

  createVisual(params): OpmVisualProcess {
    return new OpmVisualProcess(params, this);
  }

  get needUserInput() {
    return this._needUserInput;
  }

  set needUserInput(val: boolean) {
    this._needUserInput = val;
  }

  getTimeDurationText(): string {
    return this.duration.getText();
  }

  get code(): ConfigurationOptions.code {
    return this._code;
  }
  set code(code: ConfigurationOptions.code) {
    this._code = code;
  }
  get insertedFunction(): any {
    return this._insertedFunction;
  }
  set insertedFunction(insertedFunction: any) {
    this._insertedFunction = insertedFunction;
  }

  updateParams(params) {
    super.updateParams(params);
    // in case there is a user defined function, it will be stored in userDefinedFunction attribute.
    // in case there is a pre defined function, it will be stored in function attribute.
    // in case there is no function, function='None', userDefinedFunction undefined
    this.insertedFunction = params.insertedFunction;
    this.code = this.getCodeType(params.code);
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
    this.text = params.text;
    this.isWaitingProcess = params.isWaitingProcess || false;
    this.waitingProcessLid = params.waitingProcessLid;
    this.needUserInput = params.needUserInput
  }

  getIsWaitingProcess(): boolean {
    return this.isWaitingProcess;
  }

  setIsWaitingProcess(val: boolean) {
    this.isWaitingProcess = val;
  }

  getWaitingProcess(): string {
    return this.waitingProcessLid;
  }

  setWaitingProcess(lid: string) {
    this.waitingProcessLid = lid;
  }

  setParams(params) {
    super.setParams(params);
    if (params.needUserInput)
      this.needUserInput = params.needUserInput;
    if (params.code)
      this.code = this.getCodeType(params.code);
    if (params.insertedFunction)
      this.insertedFunction = params.insertedFunction;
    if (params.isWaitingProcess)
      this.isWaitingProcess = params.isWaitingProcess;
    if (params.waitingProcessLid)
      this.waitingProcessLid = params.waitingProcessLid;
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
  }

  getCodeType(functionType) {
    if (typeof functionType === 'number')
      return functionType;
    switch (functionType) {
      case 'None':
        return code.Unspecified;
      case 'userDefined':
        return code.UserDefined;
      case 'userPythonDefined':
        return code.Python;
      case 'external':
        return code.External;
      case 'ros':
        return code.ROS;
      case 'mqtt':
        return code.MQTT;
      case 'sql':
        return code.SQL;
      case 'userGenAIDefined':
        return code.GenAI;
      default:
        return code.PreDefined;
    }
  }

  getParams() {
    const visualElementsParams = new Array();
    for (let i = 0; i < this.visualElements.length; i++) {
      visualElementsParams.push(this.visualElements[i].getParams());
    }
    const params = {
      code: this.code,
      insertedFunction: this.insertedFunction,
      visualElementsParams: visualElementsParams,
      min: this.duration.getTimeDuration().min,
      nominal: this.duration.getTimeDuration().nominal,
      max: this.duration.getTimeDuration().max,
      units: this.duration.getTimeDuration().units,
      //timeDurationStatus:this.duration.isTimeDuration(),
      timeDurationStatus: this.duration.getTimeDuration().timeDurationStatus,
      isWaitingProcess: this.isWaitingProcess,
      waitingProcessLid: this.waitingProcessLid,
      needUserInput: this.needUserInput,
    };
    return { ...super.getThingParams(), ...params };
  }

  getParamsFromJsonElement(jsonElement) {
    const params = {
      code: (jsonElement.code === code.UserDefined) ? 'userDefined' : ((jsonElement.code === code.PreDefined) ?
        'preDefined' : ((jsonElement.code === code.Python) ? 'userPythonDefined' : ((jsonElement.code === code.GenAI) ?
          'userGenAIDefined' : ((jsonElement.code === code.External) ? 'external' : 'None')))),
      insertedFunction: jsonElement.insertedFunction,
      needUserInput: jsonElement.needUserInput
    };
    return { ...super.getThingParamsFromJsonElement(jsonElement), ...params };
  }

  get counter() {
    return OpmLogicalProcess.logicalCounter;
  }

  getDurationManager(): TimeDurationModule {
    return this.duration;
  }

  getNumberedName(): string {
    return OpmLogicalProcess.getNumberedNameByNum(this.counter);
  }

  static getNumberedNameByNum(counter): string {
    // in English there are 26 letters
    let prefix = '';
    const lastChar = (counter % 26) === 0 ? 26 : (counter % 26);
    // decide how many letters will be in the name
    let numberOfChars = 1;  // A...Z
    if (counter > 26) numberOfChars++;  // AA...ZZ
    if (counter > Math.pow(26, 2) + 26) numberOfChars++; // AAA...ZZZ
    if (numberOfChars === 1) prefix = String.fromCharCode(lastChar + 64);
    if (numberOfChars === 2) {
      prefix = String.fromCharCode(Math.ceil((counter - 26) / 26) + 64) +
        String.fromCharCode(lastChar + 64);
    }
    if (numberOfChars === 3) {
      const firstDigit = Math.ceil((counter - Math.pow(26, 2) - 26) / Math.pow(26, 2));
      const firstChar = String.fromCharCode(firstDigit + 64);
      const secondDigit = Math.ceil((counter - Math.pow(26, 2) - 26 -
        (firstDigit - 1) * Math.pow(26, 2)) / 26);
      const secondChar = String.fromCharCode(secondDigit + 64);
      prefix = firstChar + secondChar + String.fromCharCode(lastChar + 64);
    }
    return prefix + ' ' + 'Processing';
  }

  isComputational(): boolean {
    return (this.code !== code.Unspecified && this.code !== undefined);
  }

  // public setDuration(duration: { min: number, nominal: number, max: number }): boolean {
  //  return this.duration.setDuration(duration);
  //}


  // public setUnits(duration: {units: string}) {
  //  this.duration.setUnits(duration);
  // }

  public editUnits(units: string) {
    this.duration.editUnits(units);
  }

  public isTimeDuration(): boolean { // true if has timeDuration
    //return (this.timeDurationStatus !== TimeDurationType.Unspecified && this.timeDurationStatus !== undefined)
    return (this.duration.isTimeDuration());
  }

  isBasicThing(): boolean {
    return true;
  }

}

// This is not complete yet and all informatino should be taken out of the Logical class.
class ProcessComputational implements TextualModule {

  constructor(private readonly process: OpmLogicalProcess) {
  }

  getText(): string {
    return '()';
  }

  isTextActive(): boolean {
    return this.process.isComputational();
  }

  public getPriority() {
    return 2;
  }

}



