import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementAction, ElementCommand, ElementHaloHandle, ElementToolbarHandle } from "./command";

export class ToggleAffiliationCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: true,
            name: 'toggle-affiliation',
            displayTitle: 'Change Affiliation',
            svg: 'toggle-affiliation',
            action: new ToggleAffiliationAction(this.init, this.thing, this.visual),
            gif: 'assets/gifs/affiliation.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'toggle-affiliation',
            displayTitle: 'Change Affiliation',
            svg: 'toggle-affiliation',
            action: new ToggleAffiliationAction(this.init, this.thing, this.visual),
            gif: 'assets/gifs/affiliation.gif',
        }
    }

}

class ToggleAffiliationAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    act() {
      const cell = this.init.graph.getCell(this.thing.id);
      const vis = this.init.opmModel.getVisualElementById(this.thing.id);
      if (cell && vis)
        cell.toggleAffiliation(vis);
    }

}
