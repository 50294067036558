import {AfterViewInit, Component} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from '../../../../rappid-components/services/user.service';
import { Router } from '@angular/router';
import { AuthenticationService } from '../../../../rappid-components/services/authentication/authentication.service';
import {validationAlert} from "../../../../configuration/rappidEnviromentFunctionality/shared";
import {environment} from "../../../../../environments/environment";
import {DialogService} from "../../../../rappid-components/services/dialog.service";
import {EnterEmailVerificationCodeDialog} from "../../../../dialogs/enter-email-verification-code-dialog/enter-email-verification-code-dialog";
import {getAuth, RecaptchaVerifier} from "firebase/auth";
@Component({
  selector: 'opc-sign-in',
  templateUrl: 'sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements AfterViewInit {
  user = {
    Name: '',
    Email: '',
    password: '',
    repeatPassword: '',
    Organization: '',
    PhotoURL: ''
  };
  signUp = false;
  authError: string = null;
  defaultOrg: string = null;
  orgS;
  private recaptchaVerifier: RecaptchaVerifier;

  constructor(
    public dialogRef: MatDialogRef<SignInComponent>,
    private router: Router,
    private userService: UserService,
    private auth: AuthenticationService,
    private readonly dialogService: DialogService) {
  }

  signInWithGoogle() {
    this.authError = null;
  }

  ngAfterViewInit() {
    if (!environment['serverSideAuth']) {
      const auth = getAuth();
      this.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        'recaptcha-container',
        // Optional reCAPTCHA parameters.
        {
          'size': 'normal',
          'callback': function (response) {
          },
          'expired-callback': function () {
            // Response expired. Ask user to solve reCAPTCHA again.
          }
        });
    }
  }

  signInWithPassword() {
    this.authError = null;
    if ((!this.isPasswordMatch()) && (this.signUp)) {
      this.authError = "Password and Repeat-Password doesn't Match";
      return;
    }
    this.userService.signInWithEmailAndPassword(this.user, this.recaptchaVerifier).then(res => {
      this.dialogRef.close();
      this.router.navigate(['']);
    }).catch(err => {
      if (err.code === 'auth/argument-error') {
        this.authError = 'Invalid verification code.';
      } else if (err.message.includes('verification code was sent')) {
        validationAlert('A verification code was sent to your Email.');
        const dialog = this.dialogService.openDialog(EnterEmailVerificationCodeDialog, 285, 415, {
          user: this.user,
          allowMultipleDialogs: true
        });
        dialog.afterClosed().toPromise().then(success => {
          if (success) {
            this.dialogRef.close();
            this.router.navigate(['']);
          }
        })
      } else {
        this.authError = err.error?.reason || err.message;
      }
    });
  }

  forgotPassword() {
    const email = this.user.Email;
    if (email === '' || !this.validateEmail(String(email))) {
      const msg = 'The Email is not valid';
      validationAlert(msg, 3500, 'Error');
      this.authError = 'The Email is not valid';
      return;
    }
    this.userService.resetPasswordForNotLoggedUser(email)
      .then(msg => validationAlert(msg, 5000, 'Warning'))
      .catch(err => validationAlert('Failed: ' + err.error, 5000, 'Warning'));
    this.authError = null;
  }

  validateEmail(email: string) {
    const re = /^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;
    return re.test(email.toLowerCase());
  }

  isPasswordMatch(): boolean {
    return (this.user.password === this.user.repeatPassword);
  }
}
