import {OpmVisualThing} from "../../VisualPart/OpmVisualThing";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";

export abstract class ChangeAction {

  constructor(protected source: OpmVisualThing, protected target: OpmVisualThing, protected init: InitRappidService) { }

  abstract act(): Promise<ChangeActionResult>;
}


export interface ChangeActionResult {
  changed: boolean
}
