import { RelationType, EntityType } from "./model/entities.enum";
import { BasicOpmModel } from "./BasicOpmModel";
import { OpmVisualElement } from "./VisualPart/OpmVisualElement";
import { OpmLogicalElement } from "./LogicalPart/OpmLogicalElement";
import { OpmLogicalObject } from "./LogicalPart/OpmLogicalObject";
import { OpmLogicalState } from "./LogicalPart/OpmLogicalState";
import { OpmLogicalProcess } from "./LogicalPart/OpmLogicalProcess";
import { OpmProceduralRelation } from "./LogicalPart/OpmProceduralRelation";
import { OpmTaggedRelation } from "./LogicalPart/OpmTaggedRelation";
import { OpmFundamentalRelation } from "./LogicalPart/OpmFundamentalRelation";

export function logicalFactoryInsertCurrentOPD(type: RelationType | EntityType, opmModel: BasicOpmModel, params) {
  const logical = opmModel.logicalFactory(type, params);
  opmModel.currentOpd.add(logical.visualElements[0]);
  return logical;
}

export function logicalFactory(type: RelationType | EntityType, opmModel: BasicOpmModel, params) {
  let logical: OpmLogicalElement<OpmVisualElement>;
  switch (type) {
    case EntityType.Object:
      logical = new OpmLogicalObject(params, opmModel);
      break;
    case EntityType.Process:
      logical = new OpmLogicalProcess(params, opmModel);
      break;
    case EntityType.State:
      logical = new OpmLogicalState(params, opmModel);
      break;
    case RelationType.Procedural:
      logical = new OpmProceduralRelation(params, opmModel);
      break;
    case RelationType.Tagged:
      logical = new OpmTaggedRelation(params, opmModel);
      break;
    case RelationType.Fundamental:
      logical = new OpmFundamentalRelation(params, opmModel);
      break;
  }

  if (params) {
    logical.updateParams(params);
    logical.visualElements[0]?.updateParams(params);
  }

  if (!(logical.lid))
    logical.generateId();

  return logical;
}
