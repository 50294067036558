import { Component, ElementRef, Inject, Input, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';

@Component({
  selector: 'opcloud-resize-bar',
  template: `
    <div  class="resize-bar" [ngClass]="direction"  #resizeBar>
      <div class="resize-bar-control"></div>
    </div>
  `,
  styleUrls: ['./resize-bar.component.scss']
})
export class ResizeBarComponent implements OnInit {
  @Input() direction
  resize$;

  ngOnInit() {

  }

  constructor(@Inject(DOCUMENT) private document, private el: ElementRef) {
    this.setDragListener();
  }

  drag(event, element) {
    if (element.parentElement.id === 'oplFullScreen') {
      const oplFullDiv = document.getElementById('oplFullScreen');
      const height = oplFullDiv.getBoundingClientRect().bottom - event.changedTouches[0].clientY + 'px';
      oplFullDiv.style.height = height;
      const oplDiv = document.getElementById('opl-widget');
      const aiDiv = document.getElementById('opl-ai-text');
      oplDiv.style.height = height;
      aiDiv.style.height = height;
    } else if (element.parentElement.parentElement && element.parentElement.parentElement.id === 'opcloud-containerf') {
      const sideNav = document.getElementsByClassName('mat-sidenav')[0] as HTMLElement;
      sideNav.style.width = event.changedTouches[0].clientX + 'px';
    }
  }

  setDragListener() {
    const that = this;
    const nativeElement = this.el.nativeElement;
    nativeElement.addEventListener('touchmove', function (event) {
      that.drag(event, nativeElement);
    });
    const mousedown$ = Observable.fromEvent(nativeElement, 'mousedown');
    const mousemove$ = Observable.fromEvent(this.document, 'mousemove')
      .map((e: MouseEvent) => ({ y: e.movementY, x: e.movementX }))
      .filter(movement => movement.x !== 0 || movement.y !== 0);
    const mouseup$ = Observable.fromEvent(this.document, 'mouseup');

    this.resize$ = mousedown$.mergeMap((mat: MouseEvent) => {
      return mousemove$.takeUntil(mouseup$);
    });
  }

}
