import { Component, OnInit } from '@angular/core';
import { UserService } from '../../../rappid-components/services/user.service';
import {OrganizationService} from '../../../rappid-components/services/organization.service';
import {
  OplService,
  defaultObjectStyleSettings,
  defaultProcessStyleSettings,
  StyleSettings,
  defaultRosConnectionSettings,
  defaultMqttConnectionSettings,
  ConnectionSettings,
  defaultAllowUsersConnectionSettings,
  defaultTutorialMode,
  defaultMaxUsersEnabled,
  MySQLConnectionSettings,
  defaultMySQLConnectionSettings,
  GraphDBConnectionSettings,
  defaultGraphDBConnectionSettings,
  CalculationsServerConnectionSettings,
  defaultCalculationsServerSettings, defaultPythonConnectionSettings,
} from '../../../opl-generation/opl.service';
import {validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {Observable} from 'rxjs/Rx';
import {OntologyEnforcementLevel} from '../OrgOntology/ontologyInterfaces';
import {environment} from '../../../../environments/environment';
import {NewOrgUserDefaults} from './newOrgUserDefaultsInterface';
import {defaultStateStyleSettings} from '../../../models/Defaults/style';
import {MatSlideToggleChange} from '@angular/material/slide-toggle';
import {ConfirmDialogDialogComponent} from '../../../dialogs/confirm-dialog/confirm-dialog';
import {MatLegacyDialog as MatDialog} from '@angular/material/legacy-dialog';

@Component({
  selector: 'OPCloud-Organization-Settings',
  templateUrl: './OPCloud-Organization-Settings.component.html',
  styleUrls: ['./OPCloud-Organization-Settings.component.css']
})

export class OPCloudOrganizationSettingsComponent_org implements OnInit {
  settingsForOrg = true;
  organizations$: Observable<Array<any>>;
  placeholder: string;
  selectedOrg = this.user.user.userData.organization;
  public logCollectingEnabled: boolean;
  public ignoreUserLogSharingPermission: boolean;
  public checked: boolean;
  public chatEnabled: boolean;
  public font_sizes_options = [ 8, 10, 12, 14, 16, 18, 20, 22, 24, 28, 32];
  public style: {object: StyleSettings, process: StyleSettings, state: StyleSettings};
  public connection: {ros: ConnectionSettings, mqtt: ConnectionSettings, python: ConnectionSettings, mysql: MySQLConnectionSettings, graphdb: GraphDBConnectionSettings, allow_users: boolean, calculationsServer: CalculationsServerConnectionSettings};
  public archive: {days_interval: number};
  public tutorialMode = defaultTutorialMode;
  public maxUsersEnabled: boolean = defaultMaxUsersEnabled;
  public maxUsersNumber: number;
  public maxViewUsersNumber: number;
  public ontologyEnforcementLevel: OntologyEnforcementLevel;
  private OntologyTypes = OntologyEnforcementLevel;
  private auth2Factors: 'disabled' | 'mandatory' | 'optional';
  private readonly isMongo: boolean;
  private defaultUserOptions: NewOrgUserDefaults;
  apiKeyResetTooltip: string;

  constructor(private oplService: OplService, private user: UserService, private orgService: OrganizationService, private _dialog: MatDialog) {
    this.isMongo = environment.serverSideAuth; // hide some part if it is mongo
    this.defaultUserOptions = {
      executionUser: false,
      dsmUser: false,
      viewer: false,
      insightsUser: false,
      genAIUser: false,
    };
  }

  ngOnInit() {
    let user;
    if (this.user.user) {
      user = this.user.user.userData ;
    }
    if (user.SysAdmin) {
      this.organizations$ = this.orgService.getOrganizations();
      this.orgService.getOrganizations().subscribe(data => {
        if (data.length > 0) {
          this.selectOrg({value: data[0].name});
        }
      });
    } else {
      this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
    }
    this.checked = this.oplService.orgOplSettings.SDNames;
    this.logCollectingEnabled = this.oplService.orgOplSettings.logCollectingEnabled;
    this.ignoreUserLogSharingPermission = this.oplService.orgOplSettings.ignoreUserLogSharingPermission;
    this.tutorialMode = this.oplService.orgOplSettings.tutorialMode;
    this.maxUsersEnabled = !!this.oplService.orgOplSettings.maxUsersEnabled;
    this.maxUsersNumber = this.oplService.orgOplSettings.maxUsersNumber;
    this.maxViewUsersNumber = this.oplService.orgOplSettings.maxViewUsersNumber;
    this.auth2Factors = this.oplService.orgOplSettings.auth2Factors;
    this.ontologyEnforcementLevel = this.oplService.orgOplSettings.ontologyEnforcementLevel;
    this.placeholder = (user.OrgAdmin) ? user.organization : 'Organization';
    document.getElementById('selectOPDName').addEventListener('selection', this.updateOrganizationOPDnames);
    this.style = this.oplService.orgStylingSettings();
    // this.style.object = this.oplService.orgStylingSettings()[0];
    // this.style.process = this.oplService.orgStylingSettings()[1];
    this.setColorInputChangeHandlers();
    this.connection = this.oplService.orgConnectionSettings();
    this.chatEnabled = this.oplService.orgOplSettings.chatEnabled;
    this.archive = this.oplService.orgArchiveSettings();
    this.apiKeyResetTooltip = 'This will reset the Organization API Key of Gemini Generative AI';
  }
  selectOrg(event) {
    this.selectedOrg = event.value;
    const thisProcess = this;
    this.orgService.getOrgSDNames(this.selectedOrg).then(SDNamesFlag => {
      thisProcess.checked = (SDNamesFlag === undefined || SDNamesFlag === null) ? true : SDNamesFlag;
    });

    this.orgService.getOrganization(this.selectedOrg).then(res => {
      thisProcess.loadChosenOrgStyleSettings(res);
      thisProcess.loadChosenOrgConnectionSettings(res);
      thisProcess.updateLogSharingParams(res);
      thisProcess.updateTutorialModeParam(res);
      thisProcess.updateMaxUsersParams(res);
      thisProcess.updateChatEnabledParam(res);
      thisProcess.updateDefaultUserOptions(res);
      thisProcess.auth2Factors = res.auth2Factors || 'disabled';
    });
  }

  private updateChatEnabledParam(res) {
    if (!res) {
      return;
    }
    this.chatEnabled = (res['chatEnabled'] === undefined || res['chatEnabled'] === null) ? true : res['chatEnabled'];
    this.oplService.orgOplSettings.chatEnabled = this.chatEnabled;
  }

  private updateDefaultUserOptions(res) {
    if (!res || !res['defaultUserOptions']) {
      return;
    }
    this.defaultUserOptions = res['defaultUserOptions'];
  }

  private updateMaxUsersParams(res) {
    if (!res) {
      return;
    }
    const keys = Object.keys(res);

    this.maxUsersEnabled = !!res['maxUsersEnabled'];
    this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;

    if (keys.includes('maxUsersNumber')) {
      this.maxUsersNumber = res['maxUsersNumber'];
      this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    }

    if (keys.includes('maxViewUsersNumber')) {
      this.maxViewUsersNumber = res['maxViewUsersNumber'];
      this.oplService.orgOplSettings.maxViewUsersNumber = this.maxViewUsersNumber;
    }
  }

  private updateLogSharingParams(res) {
    if (!res) {
      return;
    }
    const keys = Object.keys(res);
    if (keys.includes('logCollectingEnabled')) {
      this.logCollectingEnabled = res['logCollectingEnabled'];
      this.oplService.orgOplSettings.logCollectingEnabled = this.logCollectingEnabled;
    }
    if (keys.includes('ignoreUserLogSharingPermission')) {
      this.ignoreUserLogSharingPermission = res['ignoreUserLogSharingPermission'];
      this.oplService.orgOplSettings.ignoreUserLogSharingPermission = this.ignoreUserLogSharingPermission;
    }
  }

  private updateTutorialModeParam(res) {
    if (!res) {
      return;
    }
    const keys = Object.keys(res);
    if (keys.includes('tutorialMode')) {
      this.tutorialMode = res['tutorialMode'];
      this.oplService.orgOplSettings.tutorialMode = this.tutorialMode;
    }
  }

  /**
   * receives: selected organization details
   * updates current process seen style according to the selected organization
   * */
  private loadChosenOrgStyleSettings(res: any) {
    const thisProcess = this;
    let resAtKey;
    if (res) {
      // get object style settings
      Object.keys(thisProcess.style.object).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
          resAtKey = (!res.style || !res.style.object || res.style.object[key] === undefined) ? defaultObjectStyleSettings[key] : res.style.object[key];
          thisProcess.style.object[key] = resAtKey;
      });
      // get process style settings
      Object.keys(thisProcess.style.process).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
        resAtKey = (!res.style || !res.style.process || res.style.process[key] === undefined) ? defaultProcessStyleSettings[key] : res.style.process[key];
        thisProcess.style.process[key] = resAtKey;
      });
      // get state style settings
      Object.keys(thisProcess.style.state).forEach(function (key) {
        // if the organization data is undefined, update according to default settings
        resAtKey = (!res.style || !res.style.state || res.style.state[key] === undefined) ? defaultStateStyleSettings[key] : res.style.state[key];
        thisProcess.style.state[key] = resAtKey;
      });
    }
  }
  /**
   * receives: selected organization details
   * updates current process seen connection settings according to the selected organization
   * */
  private loadChosenOrgConnectionSettings(res: any) {
    const thisProcess = this;
    if (res) {
      thisProcess.connection.allow_users = (res.connection && (res.connection.allow_users !== undefined))
        ? res.connection.allow_users : defaultAllowUsersConnectionSettings;
      const options = ['ros', 'mqtt', 'mysql', 'graphdb', 'calculationsServer'];
      thisProcess.connection.ros = this.oplService.getRosConnectionDefaultSettings();
      thisProcess.connection.mqtt = this.oplService.getMqttConnectionDefaultSettings();
      thisProcess.connection.mysql = this.oplService.getMySQLConnectionDefaultSettings();
      thisProcess.connection.graphdb = this.oplService.getGraphDBConnectionDefaultSettings();
      thisProcess.connection.calculationsServer = this.oplService.getComputingServerConnectionDefaultSettings();
      for (const opt of options) {
        Object.keys(thisProcess.connection[opt]).forEach(function (key) {
          // if the organization data is undefined, keep default settings
          thisProcess.connection[opt][key] =  (!res.connection || !res.connection[opt] || res.connection[opt][key] === undefined) ? thisProcess.connection[opt][key] : res.connection[opt][key];
        });
      }
      }
  }
  updateOrganizationOPDnames(event) {
    this.checked = event.value;
    this.orgService.SDNamesUpdateForOrg(this.selectedOrg, this.checked).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.SDNames = this.checked;
      this.oplService.updateDefaultSettings();
    }
  }
  updateLogCollectionFlag(event) {
    this.orgService.updateLogCollectionFlagForOrg(this.selectedOrg, this.logCollectingEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.logCollectingEnabled = this.logCollectingEnabled;
      this.oplService.updateDefaultSettings();
    }
  }
  updateIgnoreUserLogSharingPermissionFlag(event) {
    this.orgService.updateIgnoreUserLogSharingPermissionFlagForOrg(this.selectedOrg, this.ignoreUserLogSharingPermission).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.ignoreUserLogSharingPermission = this.ignoreUserLogSharingPermission;
      this.oplService.updateDefaultSettings();
    }
  }

  /** Enable/disable chat throughout the org according to settings
   * params: selectChatEnabled toggle event
   */
  updateOrganizationChatEnabled(event) {
    this.chatEnabled = event.value;
    this.orgService.chatEnabledUpdateForOrg(this.selectedOrg, this.chatEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization chat settings
      this.oplService.orgOplSettings.chatEnabled = this.chatEnabled;
      this.oplService.updateDefaultSettings();
    }
  }

  /** updates the organization object style settings according to the new chosen font.**/
  updateObjectFont() {
    const thisProcess = this;
    const settings = {object: {font: this.style.object.font}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.font = thisProcess.style.object.font;
      }
      validationAlert('saved', null, 'Success');
  });
  }
  /** updates the organization process style settings according to the new chosen font.**/
  updateProcessFont() {
      const thisProcess = this;
      const settings = {process: {font: this.style.process.font }};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
          thisProcess.oplService.orgOplSettings.style.process.font = thisProcess.style.process.font;
        }
        validationAlert('saved', null, 'Success');
      });
  }

  updateStateFont() {
    const thisProcess = this;
    const settings = {state: {font: this.style.state.font }};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.font = thisProcess.style.state.font;
      }
      validationAlert('saved', null, 'Success');
    });
  }

  /** updates the organization object style settings according to the new chosen font size.**/
  updateObjectFontSize() {
    const thisProcess = this;
    const settings = {object: {font_size: this.style.object.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.font_size = thisProcess.style.object.font_size;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen font size.**/
  updateProcessFontSize() {
    const thisProcess = this;
    const settings = {process: {font_size: this.style.process.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style settings
          thisProcess.oplService.orgOplSettings.style.process.font_size = this.style.process.font_size;
        }
        validationAlert('saved', null, 'Success');
      }
    );
  }

  updateStateFontSize() {
    const thisProcess = this;
    const settings = {state: {font_size: this.style.state.font_size}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style settings
          thisProcess.oplService.orgOplSettings.style.state.font_size = this.style.state.font_size;
        }
        validationAlert('saved', null, 'Success');
      }
    );
  }

  /** updates the organization object style settings according to the new chosen fill color.**/
  updateObjectFillColor(value) {
    this.style.object.fill_color = value;
    const thisProcess = this;
    const settings = { object: {fill_color: thisProcess.style.object.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.fill_color = thisProcess.style.object.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen fill color.**/
  updateProcessFillColor(value) {
    this.style.process.fill_color = value;
    const thisProcess = this;
    const settings = { process: {fill_color: thisProcess.style.process.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.fill_color = thisProcess.style.process.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateFillColor(value) {
    this.style.state.fill_color = value;
    const thisProcess = this;
    const settings = { state: {fill_color: thisProcess.style.state.fill_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.fill_color = thisProcess.style.state.fill_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization object style settings according to the new chosen text color.**/
  updateObjectTextColor(value) {
    this.style.object.text_color = value;
    const thisProcess = this;
    const settings = { object: {text_color: thisProcess.style.object.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.text_color = thisProcess.style.object.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen text color.**/
  updateProcessTextColor(value) {
    this.style.process.text_color = value;
    const thisProcess = this;
    const settings = { process: {text_color: thisProcess.style.process.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.text_color = thisProcess.style.process.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateTextColor(value) {
    this.style.state.text_color = value;
    const thisProcess = this;
    const settings = { state: {text_color: thisProcess.style.state.text_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.text_color = thisProcess.style.state.text_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization object style settings according to the new chosen border color.**/
  updateObjectBorderColor(value) {
    this.style.object.border_color = value;
    const thisProcess = this;
    const settings = { process: {border_color: thisProcess.style.object.border_color} };
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.object.border_color = thisProcess.style.object.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /** updates the organization process style settings according to the new chosen border color.**/
  updateProcessBorderColor(value) {
    this.style.process.border_color = value;
    const thisProcess = this;
    const settings = { process: {border_color: thisProcess.style.process.border_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.process.border_color = thisProcess.style.process.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  updateStateBorderColor(value) {
    this.style.state.border_color = value;
    const thisProcess = this;
    const settings = { state: {border_color: thisProcess.style.state.border_color}};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization style settings
        thisProcess.oplService.orgOplSettings.style.state.border_color = thisProcess.style.state.border_color;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /**
   * updating handlers for input with type color
   */
  private setColorInputChangeHandlers() {
    const thisProc = this;
    const object_fill_color = document.getElementById('object_fill_color_input') as HTMLInputElement;
    object_fill_color.addEventListener('change', function() {
      thisProc.updateObjectFillColor(this.value);
    });
    const process_fill_color = document.getElementById('process_fill_color_input') as HTMLInputElement;
    process_fill_color.addEventListener('change', function() {
      thisProc.updateProcessFillColor(this.value);
    });
    const state_fill_color = document.getElementById('state_fill_color_input') as HTMLInputElement;
    state_fill_color.addEventListener('change', function() {
      thisProc.updateStateFillColor(this.value);
    });
    const object_text_color = document.getElementById('object_text_color_input') as HTMLInputElement;
    object_text_color.addEventListener('change', function() {
      thisProc.updateObjectTextColor(this.value);
    });
    const process_text_color = document.getElementById('process_text_color_input') as HTMLInputElement;
    process_text_color.addEventListener('change', function() {
      thisProc.updateProcessTextColor(this.value);
    });
    const state_text_color = document.getElementById('state_text_color_input') as HTMLInputElement;
    state_text_color.addEventListener('change', function() {
      thisProc.updateStateTextColor(this.value);
    });
    const object_border_color = document.getElementById('object_border_color_input') as HTMLInputElement;
    object_border_color.addEventListener('change', function() {
      thisProc.updateObjectBorderColor(this.value);
    });
    const process_border_color = document.getElementById('process_border_color_input') as HTMLInputElement;
    process_border_color.addEventListener('change', function() {
      thisProc.updateProcessBorderColor(this.value);
    });
    const state_border_color = document.getElementById('state_border_color_input') as HTMLInputElement;
    state_border_color.addEventListener('change', function() {
      thisProc.updateStateBorderColor(this.value);
    });
  }
  ReturnToDefault() {
    const thisProcess = this;
    this.checked = true ; // TODO: should be according to current default settings.
    this.chatEnabled = true; // Chat is enabled by default
    this.style.process = defaultProcessStyleSettings;
    this.style.object = defaultObjectStyleSettings;
    this.style.state = defaultStateStyleSettings;
    this.connection.ros = defaultRosConnectionSettings;
    this.connection.mqtt = defaultMqttConnectionSettings;
    this.connection.mysql = defaultMySQLConnectionSettings;
    this.connection.python = defaultPythonConnectionSettings;
    this.connection.graphdb = defaultGraphDBConnectionSettings;
    this.connection.calculationsServer = defaultCalculationsServerSettings;
    this.orgService.updateOrganization(this.selectedOrg, { style: this.style, SDNames: this.checked, chatEnabled: this.chatEnabled, connection: this.connection }).then(_ => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {// update the current organization style and connection settings
        thisProcess.oplService.orgOplSettings.style = thisProcess.style;
        thisProcess.oplService.orgOplSettings.connection = thisProcess.connection;
      }
      validationAlert('Returned to default settings', null, 'Success');
    });
  }
  /**
   * updates the organization Computing Server URL according to the choice
   **/
  private updateComputingServerURL(event) {
    const newValue = (<any>event).target.value;
    const prev_url = this.connection.calculationsServer.computingServerURL;
    if (this.isValidURL(newValue)) {
      this.connection.calculationsServer.computingServerURL = newValue;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.calculationsServer.computingServerURL = thisProcess.connection.calculationsServer.computingServerURL;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'The enhanced calculation server URL is not valid. Please check the protocol,\n' +
        'domain name, which can include letters, digits, dots, and hyphens \n' +
        ' domain extension that must be between 2 and 6 characters\n' +
        'or optional path segments allowed characters.';
      validationAlert(message);
      (document.getElementById('server-Python-input') as HTMLInputElement).value = prev_url;
    }

  }

  /**
   * validates the legality of the url
   * */
  private isValidURL(url) {
    const regex = /^(https?:\/\/)?((([a-zA-Z\d-]+\.)*[a-zA-Z\d-]+|localhost)|((\d{1,3}\.){3}\d{1,3}))(:\d{1,5})?(\/[^\s]*)?$/;
    return regex.test(url);
  }

  /**
   * updates the organization if to use the Computing Server Target according to the choice
   **/
  private onChangeLocalServerCheckbox($event: MatSlideToggleChange) {
    const newValue = $event.checked;
    this.connection.calculationsServer.computingServerCalculations = newValue;
    const thisProcess = this;
    const settings = {connection: this.connection};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
        thisProcess.oplService.orgOplSettings.connection.calculationsServer.computingServerCalculations = thisProcess.connection.calculationsServer.computingServerCalculations;
      }
      validationAlert('saved', null, 'Success');
    });
  }
  /**
   * updates the user GraphDB API according to the choice.
   **/
  updateGraphDBAPI(event) {
    const prev_hostname = this.connection.graphdb.graphdb_api;
    const new_hostname = event.target.value;
    if (this.hostnameIsValid(new_hostname)) {
      this.connection.graphdb.graphdb_api = new_hostname;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.graphdb.graphdb_api = thisProcess.connection.graphdb.graphdb_api;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('GraphDB API should be a string!');
      (document.getElementById('graphdb-api') as HTMLInputElement).value = prev_hostname ;
    }
  }
  /**
   * updates the GraphDB username according to the choice.
   **/
  updateGraphDBUsername(event) {
    const prev_username = this.connection.graphdb.username;
    const new_username = event.target.value;
    if (this.usernameIsValid(new_username)) {
      this.connection.graphdb.username = new_username;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.graphdb.username = thisProcess.connection.graphdb.username;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Username should not be a null value ');
      (document.getElementById('graphdb-user') as HTMLInputElement).value = prev_username ;
    }
  }
  /**
   * updates the user GraphDB password according to the choice.
   **/
  updateGraphDBPassword(event) {
    const prev_password = this.connection.graphdb.password;
    const new_password = event.target.value;
    if (this.passwordIsValid(new_password)) {
      this.connection.graphdb.password = new_password;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.graphdb.password = thisProcess.connection.graphdb.password;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Password should not be a null value');
      (document.getElementById('graphdb-password') as HTMLInputElement).value = prev_password ;
    }
  }

  /**
   * updates the organization ros server according to the choice
   **/
  private updatePythonServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.python.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.python.server = new_server;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.python.server = thisProcess.connection.python.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Python-input') as HTMLInputElement).value = prev_server;
    }

  }

  /**
   * updates the organization ros port according to the choice
   **/
  private updatePythonPort(event) {
    const prev_port = this.connection.python.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.python.port = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.python.port = thisProcess.connection.python.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Python-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the user Mysql port according to the choice
   **/
  updateMySQLPort(event) {
    const prev_port = this.connection.mysql.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.mysql.port = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.port = thisProcess.connection.mysql.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-MySQL-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the user mysql hostname according to the choice.
   **/
  updateMySQLHostname(event) {
    const prev_hostname = this.connection.mysql.hostname;
    const new_hostname = event.target.value;
    if (this.hostnameIsValid(new_hostname)) {
      this.connection.mysql.hostname = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.hostname = thisProcess.connection.mysql.hostname;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Hostname should be a string!');
      (document.getElementById('hostname-MySQL-input') as HTMLInputElement).value = prev_hostname ;
    }
  }
  /**
   * updates the user mysql username according to the choice.
   **/
  updateMySQLUsername(event) {
    const prev_username = this.connection.mysql.username;
    const new_username = event.target.value;
    if (this.usernameIsValid(new_username)) {
      this.connection.mysql.username = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.username = thisProcess.connection.mysql.username;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Username should not be a null value ');
      (document.getElementById('username-MySQL-input') as HTMLInputElement).value = prev_username ;
    }
  }
  /**
   * updates the user mysql password according to the choice.
   **/
  updateMySQLPassword(event) {
    const prev_password = this.connection.mysql.password;
    const new_password = event.target.value;
    if (this.passwordIsValid(new_password)) {
      this.connection.mysql.password = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.password = thisProcess.connection.mysql.password;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Password should not be a null value');
      (document.getElementById('password-MySQL-input') as HTMLInputElement).value = prev_password ;
    }
  }
  /**
   * updates the mysql Schema according to the choice.
   **/
  updateMySQLSchema(event) {
    const prev_Schema = this.connection.mysql.schema;
    const new_Schema = event.target.value;
    if (this.hostnameIsValid(new_Schema)) {
      this.connection.mysql.schema = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.schema = thisProcess.connection.mysql.schema;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Schema should be a string!');
      (document.getElementById('schema-input') as HTMLInputElement).value = prev_Schema  ;
    }
  }
  /**
   * updates the user mysql hostname according to the choice.
   **/
  updateWSHostname(event) {
    const prev_WS_hostname = this.connection.mysql.ws_hostname;
    const new_WS_hostname = event.target.value;
    if (this.hostnameIsValid(new_WS_hostname)) {
      this.connection.mysql.ws_hostname = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.ws_hostname = thisProcess.connection.mysql.ws_hostname;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Hostname should be a string!');
      (document.getElementById('hostname-WS-input') as HTMLInputElement).value = prev_WS_hostname  ;
    }
  }
  /**
   * updates the user ros port according to the choice
   **/
  updateWSPort(event) {
    const prev_WS_port = this.connection.mysql.ws_port;
    const new_WS_port = event.target.value;
    if (this.portIsValid(new_WS_port )) {
      this.connection.mysql.ws_port = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mysql.ws_port = thisProcess.connection.mysql.ws_port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-WS-input') as HTMLInputElement).value = prev_WS_port;
    }
  }
  /**
   * validates the legality of the new_hostname: should be a string
   * */
  private hostnameIsValid(new_hostname: any) {
    return typeof new_hostname === 'string';
  }
  /**
   * validates the legality of the new_username: should not be a null value.
   * */
  private usernameIsValid(new_username: any) {
    return true;
  }
  /**
   * validates the legality of the new_username: should not be a null value.
   * */
  private passwordIsValid(new_password: any) {
    return true;
  }
  /**
   * updates the organization ros server according to the choice
   **/
  private updateRosServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.ros.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.ros.server = new_server;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.ros.server = thisProcess.connection.ros.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Ros-input') as HTMLInputElement).value = prev_server;
    }
  }

  /**
   * updates the organization ros port according to the choice
   **/
  private updateRosPort(event) {
    const prev_port = this.connection.ros.port;
    const new_port = event.target.value;
    if (this.portIsValid(new_port)) {
      this.connection.ros.port = event.target.value;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.ros.port = thisProcess.connection.ros.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Ros-input') as HTMLInputElement).value = prev_port;
    }
  }
  /**
   * updates the organization mqtt server according to the choice
   **/
  private updateMqttServer(event) {
    const new_server = event.target.value;
    const prev_server = this.connection.mqtt.server;
    const check_server_validity = this.serverIsValid(new_server);
    if (check_server_validity.isValid) {
      this.connection.mqtt.server = new_server;
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mqtt.server = thisProcess.connection.mqtt.server;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      const message = 'Server can contain only numbers, dots and english letters, \n or to be in a format of an IP address.' +
        (check_server_validity.illegal_char !== undefined ? '\n The character ' + check_server_validity.illegal_char + ' cannot be used.' : '');
      validationAlert(message);
      (document.getElementById('server-Mqtt-input') as HTMLInputElement).value = prev_server;
    }
  }
  /**
   * updates the organization mqtt port according to the choice
   **/
  private updateMqttPort(event) {
    const prev_port = this.connection.mqtt.port;
    const new_port = event.target.value;
    this.connection.mqtt.port = new_port;
    if (this.portIsValid(new_port)) {
      const thisProcess = this;
      const settings = {connection: this.connection};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) { // update the current organization connection settings
          thisProcess.oplService.orgOplSettings.connection.mqtt.port = thisProcess.connection.mqtt.port;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Port should be a number or empty');
      (document.getElementById('port-Mqtt-input') as HTMLInputElement).value = prev_port;
    }
  }

  /**
   * validates the legality of the new_port: should be a number or an empty string
   * */
  private portIsValid(new_port: any) {
    return !isNaN(new_port);
  }

  /**
   * validates the legality of the new_server: should not include certain characters
   * */
  private serverIsValid(new_server: any) {
      const legal_chars_regex = new RegExp('[^a-zA-Z0-9\.]', 'i');
      const is_legal_chars = new_server.search(legal_chars_regex);
      return {isValid: is_legal_chars === -1,
        illegal_char: (is_legal_chars === -1) ? undefined : new_server[is_legal_chars]};
  }

  /**
   * updates the organization field for enabling/disabling users to edit their own connection settings
   **/
  public updateAllowUsers(event) {
    this.connection.allow_users = !event.checked;
    const settings = {connection: this.connection};
    this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
      if (this.selectedOrg === this.user.user.userData.organization) { // update the current organization connection settings
        this.oplService.orgOplSettings.connection.allow_users = this.connection.allow_users;
      }
    });
  }
  /**
   * updates the organization field for automatic archive interval settings
   **/
  private updateArchiveInterval(event) {
    const prev_archiev_interval = this.archive.days_interval;
    const new_archiev_interval = event.target.value;
    this.archive.days_interval = new_archiev_interval;
    if (/^\d+$/.test(new_archiev_interval)) {
      const thisProcess = this;
      const settings = {archive: {days_interval: this.archive.days_interval}};
      this.orgService.updateOrganization(this.selectedOrg, settings).then(() => {
        if (thisProcess.selectedOrg === thisProcess.user.user.userData.organization) {
          thisProcess.oplService.orgOplSettings.archive.days_interval = thisProcess.archive.days_interval;
        }
        validationAlert('saved', null, 'Success');
      });
    } else {
      validationAlert('Archive interval should be a number or empty');
      (document.getElementById('archive-timeinterval-input') as HTMLInputElement).value = String(prev_archiev_interval);
    }
  }

  updateOrganizationTutorialMode($event) {
    const value = $event.value;
    this.orgService.tutorialModeUpdateForOrg(this.selectedOrg, value).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {// update the current organization style settings
      this.oplService.orgOplSettings.tutorialMode = value;
      this.oplService.updateDefaultSettings();
    }
  }

  updateMaxUsersEnabled($event) {
    console.log(this.maxUsersEnabled);
    this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;
    this.orgService.maxUsersEnabledUpdateForOrg(this.selectedOrg, this.maxUsersEnabled).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxUsersEnabled = this.maxUsersEnabled;
    }
    if (this.maxUsersEnabled && !this.maxUsersNumber) {
      this.maxUsersNumber = 10;
      this.updateMaxUsersNumber();
    }
    if (this.maxUsersEnabled && !this.maxViewUsersNumber) {
      this.maxViewUsersNumber = 1;
      this.updateMaxViewUsersNumber();
    }
  }

  updateMaxUsersNumber() {
    if (isNaN(Number(this.maxUsersNumber))) {
      return;
    }
    this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    this.orgService.maxUsersNumberUpdateForOrg(this.selectedOrg, this.maxUsersNumber).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxUsersNumber = this.maxUsersNumber;
    }
  }

  updateOrgGenAIAPIKey(apiKey) {
    if (!apiKey) {
      validationAlert('API Key can not be empty', null, 'Error');
      return;
    }
    this.orgService.updateOrgGenAIAPIKey(this.selectedOrg, apiKey).then(_ =>
      validationAlert('API Key Update for ' + this.selectedOrg + ' Finished Successfully', 4, 'Success'));
  }

  resetOrgAPIKey() {
    const confirmDialog = this._dialog.open(ConfirmDialogDialogComponent, {
      height: '210px',
      width: '400px',
      data: { message: 'Warning: This will reset the Organization API Key of Gemini Generative AI.\n\nAre you sure?', closeFlag: false },
    });
    confirmDialog.afterClosed().subscribe((data) => {
      if (data) {
        this.orgService.resetOrgGenAIAPIKey(this.selectedOrg).then(_ =>
          validationAlert('Reset of the Organization API Key of ' + this.selectedOrg + ' Finished Successfully', 4, 'Success'));
      }
    });
    return;
  }

  updateMaxViewUsersNumber() {
    if (isNaN(Number(this.maxViewUsersNumber))) {
      return;
    }
    this.oplService.orgOplSettings.maxEditUsersNumber = this.maxViewUsersNumber;
    this.orgService.maxViewUsersNumberUpdateForOrg(this.selectedOrg, this.maxViewUsersNumber).then(_ => validationAlert('Saved', null, 'Success'));
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.maxEditUsersNumber = this.maxViewUsersNumber;
    }
  }

  updateOntologyEnforcement() {
    if (this.selectedOrg === this.user.user.userData.organization)
      this.oplService.orgOplSettings.ontologyEnforcementLevel = this.ontologyEnforcementLevel;
    this.orgService.ontologyEnforcementLevelUpdateForOrg(this.selectedOrg, this.ontologyEnforcementLevel).then(_ => validationAlert('Saved', null, 'Success'));
  }

  updateOrgAuth2Factors($event) {
    if (this.selectedOrg === this.user.user.userData.organization) {
      this.oplService.orgOplSettings.auth2Factors = this.auth2Factors;
    }
    this.orgService.auth2FactorsUpdateForOrg(this.selectedOrg, this.auth2Factors).then(_ => validationAlert('Saved', null, 'Success'));
  }

  toggleDefaultUserOption(val) {
    this.defaultUserOptions[val] = !this.defaultUserOptions[val];
    this.onDefaultUserOption();
  }

  onDefaultUserOption() {
    this.orgService.updateDefaultUserOptions(this.selectedOrg, this.defaultUserOptions).then(_ => validationAlert('Saved', null, 'Success'));
  }

  onDefaultUserOptionSelection($event) {
    $event.stopPropagation();
  }
}
