import {Component, OnInit, Optional} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OpmLogicalProcess} from "../../models/LogicalPart/OpmLogicalProcess";
import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";
import {OpmLogicalObject} from "../../models/LogicalPart/OpmLogicalObject";
import {GraphService} from "../../rappid-components/services/graph.service";
import {TreeViewService} from "../../rappid-components/services/tree-view.service";
import {OpmModel} from "../../models/OpmModel";
import {OpmOpd} from "../../models/OpmOpd";
import {OpmThing} from "../../models/DrawnPart/OpmThing";
import {DialogService} from "../../rappid-components/services/dialog.service";
import {getInitRappidShared, removeDuplicationsInArray} from '../../configuration/rappidEnviromentFunctionality/shared';
import {linkType} from '../../models/ConfigurationOptions';
import {OpmLink} from '../../models/VisualPart/OpmLink';
import {OpmLogicalEntity} from '../../models/LogicalPart/OpmLogicalEntity';
import {OpmVisualEntity} from '../../models/VisualPart/OpmVisualEntity';

@Component ({
  selector: 'remove-locator-links',
  templateUrl: 'remove-locator-links.html',
  styleUrls: ['remove-locator-links.css'],
})

/**
 * The search component opens up a search box.
 * Once the users enters an input, the dialog box displaying the things and the levels they are located in.
 * The user can click on the wanted "thing" and access the opd where it is located.
 **/
export class RemoveLocatorLinks{// implements OnInit{
  searchList;
  constList;
  currentOpd;
  showTypeIndex = 0;
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject];
  searchString = '';
  searchID = '';
  opmModel: OpmModel;
  graphService: GraphService;
  opd: Array<OpmOpd>;
  listOfVisuals = new Array();

  constructor(@Optional() public dialogRef: MatDialogRef<RemoveLocatorLinks>,
              public treeViewService: TreeViewService) {
    this.opmModel = treeViewService.initRappid.opmModel;
    this.graphService = treeViewService.initRappid.graphService;
    this.opd = treeViewService.initRappid.opmModel.opds;
    this.addOpdToConstList();
    this.searchList = [...this.constList];
    if (getInitRappidShared().getElementToRemove()) {
      this.searchID = getInitRappidShared().getElementToRemove().id;
      this.createList();
      // this.search();
    }
  }

  updateSearchList() {
    this.searchList = [...this.constList];
    this.filterList();
  }

  private sortFunc(e1, e2): number {
    if (e1.name == e2.name)
      return e1.text < e2.text ? -1 : 1;
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  filterList() {
    this.searchList = this.searchList.filter(e => e.thing instanceof this.showType[this.showTypeIndex])
      .sort((e1, e2) => this.sortFunc(e1, e2));
    if (this.searchString.length > 0) {
      this.searchList = this.searchList.filter(e => e.thing.text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    }
  }

  search() {
    this.updateSearchList();
  }

  addOpdToConstList() {
    const updatedList = [];
    for (const item of this.opmModel.logicalElements) {
      if (! (item instanceof OpmLogicalThing)) continue;
      const newItem = {thing: item, opdElements: []};
     // const itemVisualElementIdsList = item.getAllVisualElementIdsByLogical();
      for (let i = 0; i < item.visualElements.length; i++) {
        const opd = this.opmModel.getOpdByThingId(item.visualElements[i].id);
        if (opd)
          newItem.opdElements.push({name: opd.getName(), id: opd.id});
      }
      updatedList.push(newItem);
    }
    this.constList = updatedList;
  }

  goToOpdById(opdID, visID) {
    const logical = getInitRappidShared().getOpmModel().getLogicalElementByVisualId(visID);
    this.graphService.changeGraphModel(opdID, this.treeViewService, '', logical);
    this.dialogRef.close();
  }

  createList() {
    const initRappid = getInitRappidShared();
    const model = initRappid.getOpmModel();
    const elementToRemove = initRappid.getElementToRemove();
    let logicals = [];

    if (elementToRemove.constructor.name.includes('Default')) {
      const triangle = elementToRemove.getTargetElement();
      const linksToRemove = initRappid.graphService.getGraph().getConnectedLinks(triangle, {outbound: true});
      for (const lnk of linksToRemove) {
        const lg = model.getLogicalElementByVisualId(lnk.id);
        logicals.push(lg);
        const rr = model.getRelatedRelationsByLogicalLink(lg);
        if (rr)
          logicals.push(...rr.filter( l => !logicals.includes(l)));
      }
    } else
        logicals.push(model.getLogicalElementByVisualId(this.searchID));

    const logicalLinkToRemove = model.getLogicalElementByVisualId(elementToRemove.id);
    if (initRappid.getOpmModel().getRelatedRelationsByLogicalLink(logicalLinkToRemove)) {
      const related = initRappid.getOpmModel().getRelatedRelationsByLogicalLink(logicalLinkToRemove);
      logicals = [...logicals, ...related];
    }
    logicals = removeDuplicationsInArray(logicals);
    for (const log of logicals) {
      log.visualElements.forEach(vis => {
        if (vis.visible === false) return;
        const opd = model.getOpdByThingId(vis.id);
        if (opd.isHidden)
          return;
        const opdSubTitle = this.treeViewService.treeView.treeModel.getNodeById(opd.id).data.subTitle;
        const isRootSD = opd.getName().endsWith('SD') ? '' : ': ' + opd.getName();
        const name = 'SD' + opdSubTitle + isRootSD;
        this.listOfVisuals.push({id: vis.id,
          opdId: opd.id,
          opdName: name,
          sourceName: (<OpmLogicalEntity<OpmVisualEntity>>(<OpmLink>vis).sourceVisualElement.logicalElement).text,
          targetName: (<OpmLogicalEntity<OpmVisualEntity>>(<OpmLink>vis).targetVisualElements[0].targetVisualElement.logicalElement).text,
          linkType: linkType[vis.logicalElement.linkType]});
      });
    }
    this.listOfVisuals.sort( (e1, e2) => {
      const node1 = initRappid.treeViewService.getNodeById(e1.opdId);
      const node2 = initRappid.treeViewService.getNodeById(e2.opdId);
      if (node1 && node2) {
        const first = initRappid.treeViewService.getNodeDepth(node1);
        const second = initRappid.treeViewService.getNodeDepth(node2);
        return first > second ? 1 : -1;
      }
      return 1;
    });
  }
  getClassNameByItemId(itemId, sourceOrTarget): string {
    const init = getInitRappidShared();
    let visual;
    const item = init.getOpmModel().getVisualElementById(itemId);
    if (!item) return 'divTableCell';
    if (sourceOrTarget === 'source')
     visual = item.sourceVisualElement;
    else if (item && item.targetVisualElements[0])
      visual = item.targetVisualElements[0].targetVisualElement;
    if (!visual)
      return 'divTableCell';
    if(visual.constructor.name.includes('State'))
      return 'divTableCell state'
    if(visual.constructor.name.includes('Object'))
      return 'divTableCell object';
    if(visual.constructor.name.includes('Process'))
      return 'divTableCell process';
    return 'divTableCell';
  }

  getToolTipMsg() {
    const that = this;
    const element = this.listOfVisuals.find( item => this.opmModel.getVisualElementById(item.id) &&
      this.opmModel.getVisualElementById(item.id).logicalElement.visualElements.find(v => that.opmModel.getOpdByThingId(v.id).isHidden));
  if (element)
      return 'The logical link belongs to a stereotype. Hence, it will be removed visually only.';
    return '';
  }
}
