import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {OPCloudUtils, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmState} from "../DrawnPart/OpmState";
import {OpmLogicalObject} from "../LogicalPart/OpmLogicalObject";
import {OpmEntityRappid} from "../DrawnPart/OpmEntityRappid";
import {OpmVisualState} from "../VisualPart/OpmVisualState";

interface DeletionResult {
  success: boolean,
  message?: string,
  relevantCell?: OpmEntityRappid
}

export class DeleteAction {

  constructor(private readonly init: InitRappidService) {
  }

  act(): void {
    const elements = this.init.selection.collection.models.filter(m => OPCloudUtils.isInstanceOfDrawnEntity(m) || OPCloudUtils.isInstanceOfDrawnNote(m));
    if (elements.length === 0) {
      return;
    }
    let ret;
    const generallyPossible = this.canOperationBeDoneGenerally();
    if (!generallyPossible.success) {
      ret = [{ success: false, message: generallyPossible.message }];
    } else if (elements.length === 1) {
      ret = [this.deleteSingleElement(elements[0])];
    } else {
      ret = this.multiDeleteElements(elements);
    }
    for (const result of ret) {
      if (result.message && (!result.relevantCell || (result.relevantCell && this.init.graph.getCell(result.relevantCell?.id)))) {
        validationAlert(result.message, 3500, 'Error'); // handles the case that a cell that can't be removed on its own is deleted by other cell deletion (so we should not alert its failure message)
      }
    }
  }

  deleteSingleElement(element: OpmEntityRappid): DeletionResult {
    const father = element.getParentCell();
    const fatherHadStates = father && OPCloudUtils.isInstanceOfDrawnObject(father) && father.getStatesOnly().length > 0;
    const ret = this.canElementBeRemoved(element);
    if (ret.success) {
      element.removeAction(element.getVisual(), this.init);
      if (fatherHadStates) {
        father.fixTextOverflow();
      }
      return { success: true };
    }
    this.resetSelected();
    return { success: true, message: ret.message, relevantCell: element };
  }

  multiDeleteElements(elements: Array<OpmEntityRappid>): Array<DeletionResult> {
    if (!this.init.oplService.settings.multiDeletion) {
      return [{ success: false, message: 'Can remove only one element at a time.\n' +
        'In order to delete multiple selected things, change the setting under the User Management.' }];
    }
    this.init.opmModel.logForUndo('Multi Deletion');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Multi Deletion');
    const sorted = elements.sort((a,b) => a.getParentCell() ? -1 : 1);
    this.init.selection.collection.reset([]);
    const results = [];
    for (let i = sorted.length - 1; i >= 0 ; i--) {
      if (!this.init.graph.getCell(sorted[i]?.id)) {
        continue;
      }
      const ret = this.canElementBeRemoved(sorted[i]);
      if (ret.success) {
        sorted[i].removeAction(sorted[i].getVisual(), this.init, true);
      } else {
        results.push({ success: false, message: ret.message, relevantCell: sorted[i] });
      }
    }
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Multi Deletion');
    this.resetSelected();
    return results;
  }

  canElementBeRemoved(element: OpmEntityRappid): DeletionResult {
    if (!this.init.graph.getCell(element)) {
      return { success: false }; // was already deleted by previous element deletion.
    }
    if (OPCloudUtils.isInstanceOfDrawnObject(element)) {
      const logical = element.getVisual().logicalElement as OpmLogicalObject;
      if (logical.isSatisfiedRequirementSetObject() || logical.isSatisfiedRequirementObject())
        return { success: false, message: 'Cannot remove requirements this way. Use the dedicated toolbar requirements actions.', relevantCell: element };
    }
    if (OPCloudUtils.isInstanceOfDrawnState(element)) {
      const logicalFather = (<OpmVisualState>element.getVisual()).fatherObject?.logicalElement as OpmLogicalObject;
      if (logicalFather.isSatisfiedRequirementSetObject() || logicalFather.isSatisfiedRequirementObject())
        return { success: false, message: 'Cannot remove requirements this way. Use the dedicated toolbar requirements actions.', relevantCell: element };
    }
    if (OPCloudUtils.isInstanceOfDrawnState(element) && (<OpmState>element).getParent().isComputational()) {
      return { success: false, message: 'Cannot remove computational state', relevantCell: element };
    }
    return { success: true };
  }

  canOperationBeDoneGenerally(): DeletionResult {
    if (this.init.Executing && !this.init.ExecutingPause) {
      return { success: false, message: 'Cannot remove while execution.'};
    } else if ($('mat-dialog-container').length) {
      return { success: false, message: 'Cannot remove while a dialog is open.'};
    } else  if (this.init.opmModel.currentOpd.isStereotypeOpd()) {
      return { success: false, message: 'Cannot remove on stereotype opd.'};
    }
    return { success: true };
  }

  resetSelected() {
    this.init.setSelectedElementToNull();
    this.init.setElementToRemoveToNull();
  }

}
