import { Pipe, PipeTransform } from '@angular/core';
import {CurrentModelContext} from "../modules/app/context";

@Pipe({
  name: 'TabTitlePipe',
  standalone: true
})
export class TabTitlePipe implements PipeTransform {

  transform(value: CurrentModelContext, ...args: unknown[]): string {
    return value.getWholeTextName();
  }

}
