import { TextualModule } from './interfaces';
import { ComputationModule } from './computation-module';
import {getInitRappidShared} from "../../../configuration/rappidEnviromentFunctionality/shared";

export class UnitsTextModule implements TextualModule {
  public units: string = undefined;
  private computation: ComputationModule;

  constructor(computation: ComputationModule) {
    this.computation = computation;
  }
  public getText(): string {
    const unitsToWrite = this.units && this.units !== 'None' ? this.units : '';
    return (this.isActive() ? '[' + unitsToWrite + ']' : '' );
  }

  public isTextActive(): boolean {
    return this.isActive();
  }

  public isActive(): boolean {
    //return (this.units !== undefined && this.units !== null);
    const unitsCondition = (this.units !== null && this.units !== undefined && this.units !== 'None' && this.units !== '');
    const userSelection = getInitRappidShared()?.oplService?.settings?.unitsOpt || 'Show only when applicable';
    const unitsSettingsBool = (userSelection === 'Show only when applicable' || userSelection === 'Always show units');
    const computational = this.computation.isActive();
    return (((userSelection === 'Always show units') || (unitsCondition && (userSelection === 'Show only when applicable')) ) && computational);
  }

  public getPriority() {
    return 2; // to appear before the alias
  }

}

