import { Component } from '@angular/core';
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {MatLegacyDialog as MatDialog} from "@angular/material/legacy-dialog";

let valuesArray = new Array()

@Component({
  selector: 'opcloud-headlessRunner-dialog',
  templateUrl: './headlessRunner-dialog.component.html',
  styleUrls: ['./headlessRunner-dialog.component.css']
})

export class HeadlessRunnerComponent {
  elementDefault;
  isStopped: boolean;

  constructor(private readonly init: InitRappidService, private dialog: MatDialog) {
    this.elementDefault = {
      fontStyle: null,
      fontColor: null,
      fill: null,
      stroke: null,
      textSize: null,
    };
  }

  get ExecuteMode() {
    return this.init.ExecuteMode;
  }

  set ExecutingPause(val) {
    this.init.ExecutingPause = val;
  }

  get ExecutingPause() {
    return this.init.ExecutingPause;
  }

  set Executing(val) {
    this.init.Executing = val;
  }

  get Executing() {
    return this.init.Executing;
  }

  ExecutePause() {
    this.init.elementToolbarReference.ExecutePause();
  }

  ExecuteStop() {
    this.isStopped = true;
    this.init.elementToolbarReference.ExecuteStop();
  }

  getSyncRunner() {
    this.init.elementToolbarReference.syncExecute(true);
  }
}
