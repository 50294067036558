import { OpmVisualEntity } from '../VisualPart/OpmVisualEntity';
import { Rule, RuleType } from './rules';
import { rules as structural } from './structural.rules';
import { rules as behavioral } from './behavioral.rules';
import { rules as consistent } from './consistional.rules';
import { EntityType } from '../model/entities.enum';
import { linkType } from '../ConfigurationOptions';

export interface ConnectionResult {
    success: boolean;
    message?: string;
    type?: RuleType;
    warnings: Array<string>;
    changeAction?;
}

// TODO: We should work with these methods
function getEntityType(logical_name: string): EntityType {
    switch (logical_name) {
        case 'OpmLogicalObject':
            return EntityType.Object;
        case 'OpmLogicalProcess':
            return EntityType.Process;
        case 'OpmLogicalState':
            return EntityType.State;
    }
}

export function getLinkType(name: string): linkType {
    if (name.includes('Agent')) {
        return linkType.Agent;
    } else if (name.includes('Instrument')) {
        return linkType.Instrument;
    } else if (name.includes('OvertimeUndertime-exception')) {
      return linkType.UndertimeOvertimeException;
    } else if (name.includes('Invocation')) {
        return linkType.Invocation;
    } else if (name.includes('Result')) {
        return linkType.Result;
    } else if (name.includes('Consumption')) {
        return linkType.Consumption;
    } else if (name.includes('Effect')) {
        return linkType.Effect;
    } else if (name.includes('Overtime')) {
        return linkType.OvertimeException;
    } else if (name.includes('Undertime')) {
        return linkType.UndertimeException;
    }  else if (name.includes('Unidirectional')) {
        return linkType.Unidirectional;
    } else if (name.includes('Bidirectional')) {
        return linkType.Bidirectional;
    } else if (name.includes('Aggregation')) {
        return linkType.Aggregation;
    } else if (name.includes('Exhibition')) {
        return linkType.Exhibition;
    } else if (name.includes('Generalization')) {
        return linkType.Generalization;
    } else if (name.includes('Instantiation')) {
        return linkType.Instantiation;
    }
}

export class Consistency {

    private readonly structural: ReadonlyArray<Rule> = structural;
    private readonly behavioral: ReadonlyArray<Rule> = behavioral;
    private readonly consistent: ReadonlyArray<Rule> = consistent;

    private getStructuralRules(source: EntityType, target: EntityType): ReadonlyArray<Rule> {
        return this.structural.filter(r => r.shouldBeApplied(source, target, undefined));
    }

    private getBehavioralRules(source: EntityType, target: EntityType, link: linkType): ReadonlyArray<Rule> {
        return this.behavioral.filter(r => r.shouldBeApplied(source, target, link));
    }

    private getConsistentRules(source: EntityType, target: EntityType, link: linkType): ReadonlyArray<Rule> {
        return this.consistent.filter(r => r.shouldBeApplied(source, target, link));
    }

    private getAllRules(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): ReadonlyArray<Rule> {
        // Fixed in new typescript
        // return (new Array<Rule>()).concat(this.getStructuralRules(source, target)).
        //     concat(this.getBehavioralRules(source, target, link)).
        //     concat(this.getConsistentRules(source, target, link));

        const sourceType = getEntityType(source.logicalElement.name);
        const targetType = getEntityType(target.logicalElement.name);

        return [...this.getStructuralRules(sourceType, targetType), ...this.getBehavioralRules(sourceType, targetType, link),
        ...this.getConsistentRules(sourceType, targetType, link)];
    }

    private check(rules: ReadonlyArray<Rule>, source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): ConnectionResult {
        const warnings = [];
        for (let i = 0; i < rules.length; i++) {
          if (rules[i].canConnect(source, target, link) === false)
            return { success: false, message: rules[i].message(), type: rules[i].type(), changeAction: rules[i].changeAction, warnings: ((<any>rules[i]).warning &&
                (<any>rules[i]).warning(source, target)) ? [(<any>rules[i]).warning(source, target)] : []};
          else if ((<any>rules[i]).warning && (<any>rules[i]).warning(source, target, link) !== undefined)
            warnings.push((<any>rules[i]).warning(source, target, link));
        }

        return { success: true, warnings };
    }

    isLegal(source: OpmVisualEntity, target: OpmVisualEntity): ConnectionResult {
        const sourceType = getEntityType(source.logicalElement.name);
        const targetType = getEntityType(target.logicalElement.name);

        const rules = this.getStructuralRules(sourceType, targetType);
        return this.check(rules, source, target, undefined);
    }

    canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): ConnectionResult {
        /*
        const relevantLinks = oplFunctions.generateLinksWithOplByElements(source, target);
        const legal = relevantLinks.filter((l) => (l.name === name)).length > 0;
        if (legal === false) {
            this.setPrevious();
            validationAlert('Not allowed according to OPM standart', 5000);
        */

        const rules = this.getAllRules(source, target, link);
        return this.check(rules, source, target, link);
    }

}
