
import { OpmVisualElement } from '../VisualPart/OpmVisualElement';
import { OpmModel } from '../OpmModel';
import { OpmOpd } from '../OpmOpd';
import { util } from 'jointjs';
import uuid = util.uuid;
import { ElementsMap } from '../components/ElementsMap';

export abstract class OpmLogicalElement<T extends OpmVisualElement> {

  static objectText = 'Object';
  static processText = 'Process';
  static stateText = 'State';
  // defining array of URL to the Element, as an attribute
  public _URLarray: Array<{ iconType: string, url: string, description: string }>;

  name = '';
  lid: string;

  visualElements: Array<T> = new Array<T>();
  opmModel: OpmModel;
  public belongsToFatherModelId: string;

  constructor(params, model) {
    this.name = this.constructor.name;
    this.opmModel = model;
    // if (params)
    // this.updateParams(params);
    if (params && params.URLarray) // if params and params._URLarray are exists
      this._URLarray = params.URLarray; // update opm model
    else {
      // alocate a new arrey
      this._URLarray = new Array<{ iconType: string, url: string, description: string }>();
      // URL array has: Type(selection- video, picture, article ..), link and description.
      this._URLarray.push({ iconType: 'picture', url: 'http://', description: ' ' });
    }
    this.createVisualOnInit(params);
  }

  abstract getParams();
  abstract createVisual(params): OpmVisualElement;
  abstract getParamsFromJsonElement(params);

  private createVisualOnInit(params): OpmVisualElement {
    const visual = this.createVisual(params);
    this.addVisual(visual);
    return visual;
  }

  public generateId() {
    this.lid = uuid();
  }

  isLink(): boolean {
    return false;
  }

  add(opmVisualElement, addToCurrentOpd = true) {
    // USED ONLY FROM OPX
    // push only if not exist
    if (this.findVisualElement(opmVisualElement.id) === opmVisualElement) {
      console.log('prevented a visual from being created more than once.');
      return;
    }
    this.visualElements.push(opmVisualElement);
    opmVisualElement.pointToFather(this);
    if (addToCurrentOpd) {
      this.opmModel.currentOpd.add(opmVisualElement);
    }
  }

  protected addVisual(visual: OpmVisualElement): void {
    if (this.findVisualElement(visual.id)) {
      // If we remove this statement, models won't be loaded correctly.
      // TODO: Remove it after refactoring model load.
      this.remove(visual.id);
    }
    // TODO: Remove any
    this.visualElements.push(<any>visual);
  }

  public removeVisual(visual: OpmVisualElement) {
    for (let i = this.visualElements.length - 1; i >= 0; i--)
      if (this.visualElements[i] === visual)
        this.visualElements.splice(i, 1);
  }

  remove(opmVisualElementId: string) {
    for (let i = this.visualElements.length - 1; i >= 0; i--) {
      if (this.visualElements[i].id === opmVisualElementId) {
        this.visualElements.splice(i, 1);
        const opd = this.opmModel.getOpdByThingId(opmVisualElementId);
        if (opd) opd.remove(opmVisualElementId);
        this.opmModel.currentOpd.remove(opmVisualElementId);
        break;
      }
    }
  }

  findVisualElement(id) {
    for (let k = 0; k < this.visualElements.length; k++)
      if (this.visualElements[k].id === id)
        return this.visualElements[k];
    return null;
  }

  updateParams(params) {
    if (params.lid)
      this.lid = params.lid;
    if (params && params._URLarray) { // if params and params._URLarray are exists
      this._URLarray = params.URLarray; // update database
    }
    if (params?.hasOwnProperty('belongsToFatherModelId')) {
      this.belongsToFatherModelId = params.belongsToFatherModelId;
    }
  }

  setParams(params) { }

  getElementParams() {
    return {
      name: this.name,
      lid: this.lid,
      URLarray: this.URLarray, // URL saving
      belongsToFatherModelId: this.belongsToFatherModelId,
    };
  }

  get URLarray() { // get URLarray function
    return this._URLarray;
  }
  set URLarray(arr) { // set URLarray function
    this._URLarray = arr;
  }

  updateSourceAndTargetFromJson() { }

  removeFromFather() { }

  get alias() {
    return '';
  }

  // needed for computational part
  getName() {
    return this.name;
  }

  isAtOPD(opd: OpmOpd) {
    const opds = [];
    for (const vis of this.visualElements) {
      opds.push(this.opmModel.getOpdByThingId(vis.id));
    }
    if (opds.indexOf(opd) === -1)
      return false;
    return true;
  }

  isBasicThing(): boolean {
    return false;
  }

  setReferencesFromJson(json, map: ElementsMap<OpmLogicalElement<any>>) {
  }

  getElementParamsFromJsonElement(jsonElement: any) {
    const params = {
      belongsToFatherModelId: jsonElement.belongsToFatherModelId
    }
    return params;
  }
}

