import { OpmLogicalObject } from '../../LogicalPart/OpmLogicalObject';
import {ValueAttributeRange, ValueAttributeType} from "./attribute-range";
import { AttributeValue } from "./attribute-value";

export class ValidationModule {

    private valueTypeElement: OpmLogicalObject;
    private attribute: AttributeValue = new AttributeValue();

    public isActive(): boolean {
        return this.attribute.isActive();
    }

    public setValueTypeElement(object: OpmLogicalObject) {
        this.valueTypeElement = object;
    }

    public getValueTypeElement(): OpmLogicalObject {
        return this.valueTypeElement;
    }

    public getType(): ValueAttributeType {
        return this.attribute.getType();
    }

    public getRange(): string {
        return this.attribute.getRange();
    }

    public getDefault(): string {
        const val = this.attribute.getDefault();
        if (val)
            return val;
        return 'value';
    }

    public getValidator() {
      return this.attribute.getValidator();
    }

    public setRange(type: ValueAttributeType, range: string, stereotypeValidator: ValueAttributeRange): { wasSet: boolean, errors?: Array<string> } {
        const wasSet = this.attribute.setRange(type, range, stereotypeValidator);
        if (wasSet == false)
            return { wasSet, errors: ['The range entered wasn\'t valid'] };
        return { wasSet };
    }

    public validateValue(value: string): boolean {
        return this.attribute.validate(value);
    }

    public removeRange() {
        this.valueTypeElement = undefined;
        this.attribute.remove();
    }

    public toJson(): object {
        if (this.isActive()) {
            return {
                valueTypeElementId: this.valueTypeElement.lid,
                attribute: {
                    range: this.attribute.getRange(),
                    type: this.attribute.getType()
                }
            }
        }
        return {};
    }

}

export function getValidationColor(logicalObject) {
  const status = logicalObject.states[0].visualElements[0].getValidationStatus();

  if (status.status === 'value-set-invalid')
    return 'ff7474';
  if (status.status === 'value-set-valid')
    return '00ff85';
  if (status.status === 'value-not-set')
    return '73c7ff';

  return 'FFFFFF';

}
