import { Injectable, Inject } from '@angular/core';
import { AuthenticationDriver } from './authentication.driver';
import { Observable, ReplaySubject } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { MsalAuthDriver } from '../../../database/msalAuth';
import {DatabaseService} from "../../../database/database.service";
import {environment} from "../../../../environments/environment";
import * as workerTimers from 'worker-timers';
import {getInitRappidShared} from "../../../configuration/rappidEnviromentFunctionality/shared";

export enum AuthActionTypes {
  login,
  logout,
  signup
}

export interface AuthActionPayload {
  provider?: string;
  user?: {
    Email: string,
    password: string,
  };
}

export interface User {
  uid: string,
  userData: any
}

const MAX_TIME_WITHOUT_USE = 2 * 1000 * 60 * 60; // 2 hours

@Injectable()
export class AuthenticationService {

  private readonly authenticated: ReplaySubject<boolean> = new ReplaySubject<any>(1);
  public readonly authenticated$: Observable<boolean> = this.authenticated.asObservable();

  private token: string;
  private current: AuthenticationDriver;
  private googleAuthInstance: gapi.auth2.GoogleAuth;
  private lastMouseMoveTime;

  constructor(private readonly cookie: CookieService, @Inject('AuthenticationDriver') private authDriver: AuthenticationDriver,
              private msal: MsalAuthDriver, private database: DatabaseService) {
  }

  listenForInactivity() {
    if (window) {
      this.lastMouseMoveTime = (new Date).getTime();
      window.addEventListener('mousemove', () => {
        this.lastMouseMoveTime = (new Date).getTime();
      })
      const interval = workerTimers.setInterval(() => {
        if (getInitRappidShared().currentlyExportingPdf || getInitRappidShared().currentlyCreatingSystemMap) {
          return;
        }
        if ((new Date).getTime() - this.lastMouseMoveTime > MAX_TIME_WITHOUT_USE) {
          this.signOut();
          workerTimers.clearInterval(interval);
        }
      }, 60000); // checking once in a minute that the max time without use has not reached. if so - logout.
    }
  }

  public signInWithEmailAndPassword(email: string, password: string, recaptchaVerifier = undefined, verificationCode = undefined): Promise<any> {
    if (environment['serverSideAuth']) {
      return this.authDriver.signInWithEmailAndPassword(email, password, verificationCode).then(res => {
        // implement the relevant code in the future when we will use this for purpose like mongo or AWS and etc...
        this.current = this.authDriver;
        return this.authenticator(res['token']).then(() => res);
      }).catch(err => {
        throw new Error(err.error);
      });
    } else {
      return this.authDriver.signInWithEmailAndPassword(email, password)
        .then(value => {
          this.current = this.authDriver;
          return value;
        }).catch(err => {
          this.current = this.authDriver;
          if (err.code === 'auth/multi-factor-auth-required') { // the user has 2 factor authentication, need to verify the second factor.
            return this.authDriver.signInWith2FactorAuthentication(err, recaptchaVerifier);
          } else if (err.code === 'auth/wrong-password') {
            throw new Error('Wrong Password.');
          } else if (err.code === 'auth/user-not-found') {
            throw new Error('User does not exist');
          }
        })
        .then(this.authenticator);
      }
  }

  clearMsalUid(uid: string) {
    return uid.split('.').join('').split('-').join('');
  }

  async initGoogleAuth(): Promise<void> {
    const pload = new Promise((resolve) => {
      gapi.load('auth2', resolve);
    });
    return pload.then(async () => {
      await gapi.auth2
        .init({ client_id: '926349859368-17n61o55faetig1ho793bi6p849p85hg' })
        .then(auth => {
          this.googleAuthInstance = auth;
        });
    });
  }

  async signInGoogle() {
    if (!this.googleAuthInstance)
      await this.initGoogleAuth();

    let data;
    let googleData;
    return this.googleAuthInstance.signIn().then(user => {
      googleData = (<gapi.auth2.GoogleUser>user).getAuthResponse();
      return this.database.driver.validateGoogleToken(googleData.id_token);
    }).then(async userData =>{
      data = userData;
      this.current = this.authDriver;
      const newToken = await this.database.driver.getTokenForSSO(googleData.id_token, 'google');
      return newToken.token;
    }).then(ret => this.finishSignInByCustomToken(ret, data));
  }

  async finishSignInByCustomToken(ret, data) {
      return this.authDriver.signInWithCustomToken(ret.token).then(this.authenticator).then(r => data);
  }

  public signInMsal() {
    let data;
    return this.msal.signInWithPopup(this.msal.availableProviders).then(async userData => {
        data = userData;
        this.current = this.authDriver;
        data.account.homeAccountId = this.clearMsalUid(data.account.homeAccountId);
        const newToken = await this.database.driver.getTokenForSSO(data.idToken, 'microsoft');
        return newToken.token;
      }).then(ret => this.finishSignInByCustomToken(ret, data));
  }

  private authenticator = async (value) => {
    const authenticated: boolean = !!value;
    this.authenticated.next(authenticated);
    const almostHour = 1000 * 60 * 55;
    if (authenticated) {
      workerTimers.setInterval(() => this.authDriver.refreshExpiredToken(), almostHour); // refresh the token every 55 minutes. (it expires after 1 hour)...
    }
    this.current.token().subscribe(token => this.updateToken(token));
    return value;
  }

  private updateToken(token: string) {
    this.token = token;
    if (window.location.host.includes('localhost')) {
      // for local oslc debugging support
      this.cookie.set('auth', token);
    } else {
      // for production oslc support
      this.cookie.set('auth', token, undefined, undefined, '.' + window.location.host);
    }
  }

  public async signInWithFirebaseToken(token) {
    return this.authDriver.signInWithCustomToken(token).then(res => this.authenticator(res)).catch(err => console.log(err));
  }

  public autoSignIn(): Promise<any> {
    // TODO: Add catch
    return Promise.all([
      this.authDriver.autoSignIn(),
    ]).then(res => {
      if (res[0])
        this.current = this.authDriver;
      return res[0];
    }).then(this.authenticator);
  }

  removeCookies() {
    if (window.location.host.includes('localhost'))
      this.cookie.delete('auth');
    else
      this.cookie.delete('auth', undefined,'.' + window.location.host);
  }

  public signOut(): Promise<boolean> {
    return Promise.all([this.authDriver.signOut()]).then(res => {
      if (res[0]) {
        this.authenticated.next(false);
      }
      this.removeCookies();
      location.reload();
      return res[0];
    });
  }

  public signOutWithMsal(): Promise<boolean> {
    return Promise.all([this.msal.signOut()]).then(res => {
      if (res[0])
        this.authenticated.next(false);
      this.removeCookies();
      return res[0];
    });
  }

  public resetPassword(user_email: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      if (environment['serverSideAuth']) {
        return this.resetPasswordForNotLoggedUser(user_email).then(_ => resolve(true));
      }
      return this.authDriver.sendPasswordResetEmail(user_email).then(_ => resolve(true));
    });
  }

  public resetPasswordForNotLoggedUser(email: string) {
    return this.database.driver.resetPasswordForNotLoggedUser(email);
  }

  public getToken(): string {
    return this.token || '';
  }

  public refreshExpiredToken() {
    // TODO: we can add here condition for maximal session time and if it reaches that time we can force the user to logout.
    this.authDriver.refreshExpiredToken();
  }

  add2FactorAuthenticationForUser(recaptchaVerifier, phoneNumber: string): Promise<any> {
    return this.authDriver.add2FactorAuthenticationForUser(recaptchaVerifier, phoneNumber);
  }

  finishAdd2FactorAuthForUser(verificationCode: string, verificationId): Promise<{success: boolean, message?: string}> {
    return this.authDriver.finishAdd2FactorAuthForUser(verificationCode, verificationId);
  }

  removeSecondAuthFactorForUser() {
    return this.authDriver.removeSecondAuthFactorForUser();
  }
}


