import { Component, OnInit, Optional, Inject } from '@angular/core';
import { UserService } from '../../rappid-components/services/user.service';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';

@Component({
  selector: 'change-password-dialog',
  templateUrl: './change-password-dialog.component.html',
  styleUrls: ['./change-password-dialog.component.css']
})
export class ChangePasswordDialogComponent {

  user = {
    password: '',
    repeatPassword: '',
  };

  error: string = '';

  constructor(public dialogRef: MatDialogRef<ChangePasswordDialogComponent>, @Inject(MAT_DIALOG_DATA) private data, private service: UserService) {
  }

  submit() {
    if (!this.isPasswordMatch()) {
      this.error = 'Password doesn\'t match';
      return;
    }
    this.service.changePassword(this.data.user.uid, this.user.password).then(
      res => { validationAlert("Successfully updated", null, 'Success'); this.dialogRef.close() },
      rej => this.error = 'this.error');
  }

  isPasswordMatch(): boolean {
    return (this.user.password === this.user.repeatPassword);
  }


}
