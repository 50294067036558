import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { MaterialModule } from './material.module';
import { AvatarComponent } from './avatar/avatar.component';
import { ResizeBarDirective } from './resize-bar/resize-bar.directive';
import { ResizeBarComponent } from './resize-bar/resize-bar.component';
import {CalanderComponent} from "./calander/calander.component";
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProgressSpinner} from "../../dialogs/Spinner/Progress_Spinner";
import {BackgroundPhotoDialogComponent} from "../../dialogs/background-photo-dialog/background-photo-dialog";
import {MatLegacyChipsModule as MatChipsModule} from "@angular/material/legacy-chips";
import {NewModelByWizardComponentComponent} from "../../dialogs/new-model-by-wizard-component/new-model-by-wizard-component.component";
import {MatLegacyProgressBarModule as MatProgressBarModule} from "@angular/material/legacy-progress-bar";
import {ConfirmDialogDialogComponent} from "../../dialogs/confirm-dialog/confirm-dialog";
import {DragDropModule} from "@angular/cdk/drag-drop";
import {SelectOpdsTreeDialog} from "../../dialogs/select-opds-tree-dialog/select-opds-tree-dialog";
import {
  MethodologicalCheckingDialog
} from "../../dialogs/methodological-checking-dialog/methodological-checking-dialog";

@NgModule({
    imports: [
        CommonModule,
        MaterialModule,
        FormsModule,
        MatChipsModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        DragDropModule
    ],
    exports: [
        MaterialModule,
        AvatarComponent,
        ResizeBarDirective,
        CalanderComponent,
        ProgressSpinner,
        MatChipsModule,
        ReactiveFormsModule,
        NewModelByWizardComponentComponent,
        DragDropModule,
        SelectOpdsTreeDialog
    ],
    providers: [
        DatePipe
    ],
    declarations: [
        AvatarComponent,
        ResizeBarDirective,
        ResizeBarComponent,
        CalanderComponent,
        ProgressSpinner,
        BackgroundPhotoDialogComponent,
        NewModelByWizardComponentComponent,
        ConfirmDialogDialogComponent,
        SelectOpdsTreeDialog,
        MethodologicalCheckingDialog
    ]
})
export class SharedModule {
}
