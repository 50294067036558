import { Injectable } from '@angular/core';
import { ModelService } from './model.service';
import {CurrentModelContext, DSMContext, EmptyContext, ModelContext, StereotypeContext} from './context';
import { StorageService } from '../../rappid-components/services/storage.service';
import { WriteModelSchema, CurrentModelPermission } from '../../rappid-components/services/storage/model-object.class';
import { DisplayStereotype, WriteStereotypeSchema } from '../../dialogs/stereotypes-dialog/StereotypesRelatedInterface';
import { ModelStorageService } from "../../rappid-components/services/storage/model-storage.service";
import {OpmModel} from "../../models/OpmModel";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {getInitRappidShared, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {NewModelByWizardParams} from "../../dialogs/new-model-by-wizard-component/newModelWizardParamsInterface";
import {OplService} from "../../opl-generation/opl.service";


export declare type Path = Array<{ id: string, title: string }>;

@Injectable()
export class ContextService {

    private context: CurrentModelContext;
    private contextTabs: Array<{context: CurrentModelContext, modelData, undo: Array<any>, redo: Array<any>}> = [];
    private isLoadingTabs: boolean;
    private userModelsAlreadyLoaded: boolean;
    public isCurrentlySavingModel: boolean;

    public getAutosaveInterval(): number {
        return this.storage.getAutosaveTime();
    }

    constructor(private readonly model: ModelService, private readonly storage: StorageService, public readonly oplService: OplService) {
      this.context = new EmptyContext();
      this.userModelsAlreadyLoaded = false;
      this.addContextForTabs(this.context, this.model.model.toJson(), [], []);
      this.storage.getUserObservable().subscribe( user => {
        if (user)
          this.restoreLastTabs();
      });
    }

    getCurrentModelId() {
      return (<any>this.context).properties.id;
    }

    getModelService() {
        return this.model;
    }

    renameModel(id: string, name: string, sysExamples = false, globalTemplates = false): Promise<void> {
      return this.storage.renameModel(id, name, sysExamples, globalTemplates).then(() => {});
    }

    isExample(): boolean {
      return this.context.isExample();
    }

    isTemplate(): boolean {
      return this.context.isTemplate();
    }

    isStereotype(): boolean {
      return this.context.isStereotype();
    }

    isModelOrgExample(): boolean {
      return this.context.isOrgExample();
    }

    isOrgTemplate(): boolean {
      return this.context.isOrgTemplate();
    }

    isGlobalTemplate(): boolean {
      return this.context.isGlobalTemplate();
    }

    isUserSysAdmin() {
      return this.storage.isSysAdmin();
    }

    isUserOrgAdmin() {
      return this.storage.isOrgAdmin();
    }

    public getPath(): Path {
        return this.context.getPath();
    }

    private replaceContext(new_context: CurrentModelContext) {
        this.context.terminate();
        this.context = new_context;
        if (new_context instanceof ModelContext) {
          new_context.startSubModelsCheck();
          if (new_context.isALlowedToSave()) {
            new_context.startInterval();
          }
        }
    }

    public getSubModelsEditDates() {
      const subModelsOpdsIds = this.model.model.opds.filter(opd => opd.sharedOpdWithSubModelId).map(opd => opd.sharedOpdWithSubModelId);
      const ret = {};
      return Promise.all(subModelsOpdsIds.map(id => this.storage.getModelLastEditDate(id).then(date => ret[id] = date).catch(err => null))).then(() => ret);
    }

    public replaceContextByTab(item) {
        this.replaceContext(item.context);
        this.oplService.options.showDraggableThings = item.modelData.logicalElements.length < 8000;
        this.model.set(item.modelData, item.context.getWholeTextName(), item.modelData.id || item.context?.properties?.id, { path: item.modelData.path, opd_id: item.modelData?.currentOpd?.id || 'SD' });
    }

    private empty(): void {
        this.context = new EmptyContext();
        this.model.setName(this.context.getWholeTextName());
    }

    public save(modelService: ModelStorageService, image?: string): void {
        if (this.isCurrentlySavingModel) {
          validationAlert('Cannot save while there is already a saving process running.', 5000, 'REDWARNING');
          return;
        }
        this.context.save(this, modelService, image);
        modelService.getOpmModel().hasUnsavedWork = false;
    }

    public updateLocalPermissionsAfterChange(data, gotToken: boolean) {
        this.context.updateLocalPermissionsAfterChange(data, gotToken);
    }

    public makeUrl(): { allowed: boolean, url?: string, opdUrl?: string } {
        const arg = this.context.makeUrl();
        if (arg.allowed)
            arg.url += '|||' + this.model.model.currentOpd.id;
        return arg;
    }

    public isLoaded(): boolean {
        return !(this.context.isEmpty());
    }

    public getCurrentOpdName() {
        return this.model.getCurrentOpdName();
    }

    public doesSupportModelPermissions(): boolean {
        return this.context.supportModelPermissions();
    }

    public getDisplayName(): string {
        return this.context.getWholeTextName();
    }

    public isReadonly(): boolean {
        return this.context.isReadonly();
    }

    public async loadModelByPath(title: string, path: string, opd?: string) {
        return this.storage.getModelByPath(title, path).then(model => {
            const c = new ModelContext({ id: model.id, name: model.name, path: [], description: model.description, archiveMode: model.archiveMode.archiveMode, permission: model.permission, isVersion: model.isVersion, isAutosave: model.isAutosave }, this);
            this.addContextForTabs(c, model,[], []);
            this.replaceContext(c);
            this.removeTab(0);
            this.model.set(model, c.getWholeTextName(), model.id, { path: model.path, opd: opd });
        });
    }

    public loadDSMFlatteningModel(alternateModel: OpmModel) {
      const dsmItem = { context: new DSMContext(alternateModel.name), modelData: alternateModel.toJson() };
      this.addContextForTabs(dsmItem.context, dsmItem.modelData, [], []);
      const currentTab = this.getCurrentTabItem();
      this.updateTabData(currentTab);
      this.replaceContext(dsmItem.context);
      this.model.set(dsmItem.modelData as any, dsmItem.context.getWholeTextName(), 'noId', {
        alternativeOpmModel: alternateModel, opd_id: alternateModel.currentOpd.id
      });
    }

    public async getTemplateForImport(model_id: string, isGlobalTemplates: boolean) {
      if (isGlobalTemplates)
        return await this.storage.getModel(model_id, 'MAIN', false, true);

      return this.storage.getModel(model_id, 'MAIN', false, false);
    }

    public async loadModel(model_id: string, path: Path, mode: 'MAIN' | 'AUTO', opd_id?: string, isSysExamples?: boolean, isOrgExamples?: boolean, isGlobalTemplates?: boolean, isOrgTemplates?: boolean): Promise<void> {
      const tryToLoad = (model, sysEx = false, globTemp = false) => {
        const c = new ModelContext({ id: model.id, name: model.name, path: path || model.dirsPath,
          description: model.description, archiveMode: model.archiveMode.archiveMode, permission: model.permission,
          isVersion: model.isVersion, isAutosave: model.isAutosave, isSysExample: sysEx, isOrgExamples: isOrgExamples || !!model.dirsPath?.find(d => d.id === 'ORGEXAMPLES'),
          isGlobalTemplate: globTemp, isOrgTemplate: isOrgTemplates || !!model.dirsPath?.find(d => d.id === 'ORGTEMPLATES' || d.id === 'PRIVATETEMPLATES')}, this);
        this.addContextForTabs(c, model,[], []);
        const currentTab = this.getCurrentTabItem();
        this.updateTabData(currentTab);
        this.replaceContext(c);
        if (model.dirsPath && !c.isExample() && !c.isTemplate())
          localStorage.setItem('dirsPath', JSON.stringify(model.dirsPath));
        this.oplService.options.showDraggableThings = model.logicalElements.length < 8000;
        this.model.set(model, c.getWholeTextName(), model.id, { path: model.path, opd_id: opd_id });
        this.closeEmptyTabs();
      };

      let regularModel = {};
      let sysExampleModel = {};
      let globalTemplateModel = {};

      if (isSysExamples)
        sysExampleModel = await this.storage.getModel(model_id, mode, true, false).catch(err => err);
      else if (isGlobalTemplates)
        globalTemplateModel = await this.storage.getModel(model_id, mode, false, true).catch(err => err);
      else
        regularModel = await this.storage.getModel(model_id, mode, false, false).catch(async err => {
          // support for model url links. by the link we can't know if it is regular or example or template. we should try them all.
          sysExampleModel = await this.storage.getModel(model_id, mode, true, false).catch(err => err);
          globalTemplateModel = await this.storage.getModel(model_id, mode, false, true).catch(err => err);
          console.clear();
          return err;
        });

      const modelsArr = [regularModel, sysExampleModel ,globalTemplateModel];
      const model = modelsArr.find(m => m['id']);
      if (model) {
        await this.oplService.areSettingsAlreadyLoaded();
        return tryToLoad(model, sysExampleModel.hasOwnProperty('id'), globalTemplateModel.hasOwnProperty('id'));
      }
      else {
        this.oplService.options.isLoadingModel = false;
        const msg = modelsArr.find(err => err['status'] === 404) ?
          'Unable to load the model. It seems you do not have permission to view it.' : 'Unable to load the model. It seems the model does not exist.';
        validationAlert(msg, 6000, 'Error');
        throw Error(modelsArr[0]['message']);
      }
      // return this.storage.getModel(model_id, mode, isSysExamples, isGlobalTemplates).then(model => tryToLoad(model, isSysExamples, isGlobalTemplates)).catch(err => {
      //   // if cant load - maybe it is a system example -> try as system example.
      //   console.clear();
      //   // TODO: check here
      //   return this.storage.getModel(model_id, mode, true).then(model => tryToLoad(model, true))
      //     .catch(err => this.storage.getModel(model_id, mode, false, true).then(model => tryToLoad(model, false, true)));
      // });
    }

    public async loadVersion(model_id: string, ver_index: string, path: Path): Promise<void> {
        return this.storage.getVersionModel(model_id, ver_index).then(model => {
            const c = new ModelContext({ id: model.id, name: model.name, path: path, description: model.description, archiveMode: model.archiveMode.archiveMode, permission: model.permission, isVersion: model.isVersion, isAutosave: model.isAutosave }, this);
            this.addContextForTabs(c, model,[], []);
            const currentTab = this.getCurrentTabItem();
            this.updateTabData(currentTab);
            this.replaceContext(c);
            this.closeEmptyTabs();
            this.model.set(model, c.getWholeTextName(), model.id, { path: model.path });
        });
    }

    public async saveModelAsSystemExample(prop: { model_id?: string, directory_id?: string, title?: string, path?: Path, description?: string, archiveMode?: boolean }, mode: 'create' | 'override' | 'autosave') {
      if (mode == 'create') {
        const model: WriteModelSchema = {
          ...this.model.model.toJson(),
          title: prop.title,
          description: prop.description,
          archiveMode: {
            archiveMode: prop.archiveMode,
            date: '',
            name: '',
          }
        };
        return this.storage.saveModelAsSystemExample(undefined, model)
          .then(model => {
          }).catch(err => console.log(err));
      }
    }

    public async saveModel(prop: { model_id?: string, directory_id?: string, title?: string, path?: Path, description?: string, image?: string, archiveMode?: boolean, sysExample?: boolean, globalTemplate?: boolean }, mode: 'create' | 'override' | 'autosave'): Promise<void> {
      const that = this;
      if (mode == 'create') {
            const model: WriteModelSchema = {
                ...this.model.model.toJson(false, true),
                title: prop.title,
                description: prop.description,
                sysExample: prop.sysExample,
                image: prop.image ? prop.image : null,
                globalTemplate: prop.globalTemplate,
                archiveMode: {
                    archiveMode: prop.archiveMode,
                    date: '',
                    name: '',
                  }
            };
            return this.storage.createModel({ at_directory: prop.directory_id }, model)
                .then(model => {
                    const orgExample = !prop.sysExample && !!prop.path?.find(d => d.id === 'ORGEXAMPLES');
                    const orgTemplate = !prop.globalTemplate && !!prop.path?.find(d => d.id === 'ORGTEMPLATES' || d.id === 'PRIVATETEMPLATES');
                    const c = new ModelContext({ id: model.created_id, name: prop.title, path: prop.path,
                      description: prop.description, archiveMode: prop.archiveMode, permission: CurrentModelPermission.WRITE,
                      isVersion: false, isAutosave: false, isGlobalTemplate: prop.globalTemplate, isOrgTemplate: orgTemplate,
                      isOrgExamples: orgExample, isSysExample: prop.sysExample}, this);
                    const currentTab = this.getCurrentTabItem();
                    this.replaceContext(c);
                    this.updateTabData(currentTab);
                    this.model.setName(prop.title);
                    this.model.setId(model.created_id);
                    this.replaceContextByTab(this.getCurrentTabItem());
                });
        }

        if (mode == 'override') {
            const model: WriteModelSchema = {
                ...this.model.model.toJson(false, true),
              archiveMode:
                {
                  archiveMode: prop.archiveMode,
                  date: '',
                  name: '',
                },
              description: prop.description || '',
              image: prop.image,
              sysExample: prop.sysExample,
              globalTemplate: prop.globalTemplate
            };
            return this.storage.overrideModel({ model_id: prop.model_id }, model)
                .then(model => {
                    const orgExample = !prop.sysExample && !!prop.path?.find(d => d.id === 'ORGEXAMPLES');
                    const orgTemplate = that.isOrgTemplate();
                    const c = new ModelContext({ id: prop.model_id, name: model.title, path: prop.path,
                      description: model.description, archiveMode: prop.archiveMode, permission: CurrentModelPermission.WRITE,
                      isGlobalTemplate: prop.globalTemplate, isOrgTemplate: orgTemplate,
                      isVersion: false, isAutosave: false, isOrgExamples: orgExample, isSysExample: prop.sysExample}, this);
                    const currentTab = this.getCurrentTabItem();
                    this.replaceContext(c);
                    this.updateTabData(currentTab);
                    this.model.setName(model.title);
                });
        }

        if (mode == 'autosave') {
            const model: WriteModelSchema = {
                ...this.model.model.toJson(false, true),
              archiveMode:
                {
                  archiveMode: prop.archiveMode,
                  date: '',
                  name: '',
                },
              sysExample: prop.sysExample,
              globalTemplate: prop.globalTemplate,
            };
            return this.storage.autosaveModel({ model_id: prop.model_id }, model);
        }

    }

  // Change the archiveMode flag of the model (model_id) to the input value archiveMode
    public async archiveModel(model_id: string, archiveMode: boolean): Promise<any> {
      return this.storage.archiveModel({ model_id: model_id , archiveMode: archiveMode});
    }

    public newModel(): void {
        const currentTab = this.getCurrentTabItem();
        this.updateTabData(currentTab);
        this.context.terminate();
        this.empty();
        this.model.newModel();
        this.addContextForTabs(this.context, this.model.model.toJson(), [], []);
        this.replaceContext(this.context);
        this.oplService.options.showDraggableThings = true;
    }

    public async loadStereotype(stereotype: DisplayStereotype): Promise<void> {
        return this.storage.getStereotype(stereotype.id)
            .then(s => {
                const c = new StereotypeContext({ id: s.id, name: s.name, description: s.description, type: s.type, permission: s.permission })
                this.addContextForTabs(c, s,[], []);
                this.replaceContext(c);
                this.model.set(s, c.getWholeTextName(), s.id);
            });
    }

    public async saveStereotype(stereotype: DisplayStereotype, existingStereotype: boolean): Promise<void> {
        const json = this.model.model.toJson(!existingStereotype);
        delete json['permissions'];
        delete json['name'];
        delete json['description'];
        const write_stereotype: WriteStereotypeSchema = { ...json, name: stereotype.name, type: stereotype.type, id: stereotype.id };
        this.storage.saveStereotype(write_stereotype).then(s => {
            const currentTab = this.getCurrentTabItem();
            this.replaceContext(new StereotypeContext({
                id: stereotype.id, name: stereotype.name,
                description: stereotype.description, type: stereotype.type,
                permission: CurrentModelPermission.WRITE
            }));
            this.updateTabData(currentTab);
        });
    }

  private addContextForTabs(c: CurrentModelContext, modelData, undo, redo) {
      if (!this.contextTabs.find(item => item.context === c)) {
        this.contextTabs.push({context: c, modelData: modelData, undo: undo, redo: redo});
        if (this.getTabs().filter(t => (<any>t?.context)?.properties?.id).length > 0)
          this.updateUserLastTabsToDB();
      }
  }

  getTabs() {
    return this.contextTabs;
  }

  getCurrentContext() {
    return this.context;
  }

  closeTab(item: { context, modelData }) {
    const currentTab = this.getTabs().find(t => t.context === this.getCurrentContext());
    const tabContext = this.getTabs().find(it => it.context === item.context);
    if (tabContext) {
      const index = this.getTabs().indexOf(tabContext);
      let nextItem;
      if (index > 0) {
        nextItem = this.getTabs()[index - 1];
      }
      if (index === 0 && this.getTabs().length > 1) {
        nextItem = this.getTabs()[1];
      }
      else if (index === 0 && this.getTabs().length === 1) {
        nextItem = { context: new EmptyContext(), modelData: new OpmModel().toJson() }
        this.addContextForTabs(nextItem.context, nextItem.modelData, [], []);
      }
      this.removeTab(index);
      if (nextItem !== currentTab)
        this.replaceContextByTab(nextItem);
      this.updateUserLastTabsToDB();
    }
  }

  removeTab(index: number) {
    this.contextTabs.splice(index, 1);
  }

  moveTab(previousIndex, currentIndex) {
    moveItemInArray(this.contextTabs, previousIndex, currentIndex);
  }

  isModelAlreadyOpenOnTab(id: string) {
    return !!this.contextTabs.find(tab => (<any>tab.context).properties?.id === id);
  }

  getCurrentTabItem() {
      return this.getTabs().find(it => it.context === this.getCurrentContext());
  }

  updateTabData(tabItem, keepSubModels = true) {
    tabItem.context = this.getCurrentContext();
    tabItem.modelData = this.getModelService().model.toJson(false, !keepSubModels);
    tabItem.undo = this.getModelService().model.getUndoStack();
    tabItem.redo = this.getModelService().model.getRedoStack();
    tabItem.lastOperations = this.getModelService().model.lastOperations;

    if (!tabItem.context.isAutoSave() && !tabItem.context.isReadonly() && tabItem.undo?.length > 3)
      tabItem.context.autoSaveManually();
  }

  closeEmptyTabs() {
    const tabs = this.getTabs();
    if (tabs.length > 1 && tabs[0].modelData.logicalElements.length === 0)
      this.closeTab(tabs[0]);
  }

  updateUserLastTabsToDB() {
    if (this.isLoadingTabs)
      return;
    const relevantTabs = this.getTabs().filter(t => !(t.context instanceof EmptyContext) && (<any>t?.context)?.properties?.id)
      .map(tab => (<any>tab?.context)?.properties?.id);
    this.storage.updateUserLastTabsToDB(relevantTabs).then(() => {});
  }

  isModelAlreadyOpen(modelId: string): boolean {
      return !!this.getTabs().find(t => t.modelData?.id === modelId);
  }

   private async restoreLastTabs() {
      if (window.location.href.includes('/load/'))
        return;
      if (this.getTabs().length === 1 && this.getTabs()[0].context instanceof EmptyContext) {
        this.storage.getUserLastTabsFromDB().then(async lastTabsIds => {
          if (this.userModelsAlreadyLoaded) {
            return;
          }
          if (lastTabsIds.length > 0 && getInitRappidShared())
            getInitRappidShared().isLoadingModel = true;
          this.userModelsAlreadyLoaded = true;
          this.isLoadingTabs = true;
          for (const modelId of lastTabsIds) {
            if (this.isModelAlreadyOpen(modelId))
              continue;
            await this.loadModel(modelId, undefined, 'MAIN');
            getInitRappidShared().isLoadingModel = true;
            getInitRappidShared()?.elementToolbarReference.setIsExample(this.isExample());
            getInitRappidShared()?.elementToolbarReference.setIsTemplate(this.isTemplate());
            getInitRappidShared()?.elementToolbarReference.setIsStereotype(this.isStereotype());
          }
          if (getInitRappidShared())
            getInitRappidShared().isLoadingModel = false;
          this.isLoadingTabs = false;
        }).catch(err => {
          this.isLoadingTabs = false;
          if (getInitRappidShared())
            getInitRappidShared().isLoadingModel = false;
        });
      }
  }

  downloadModel() {
    const that = this;
    this.storage.downloadModel(this.getCurrentModelId(), this.isExample() && !this.isModelOrgExample(), this.isTemplate() && !this.isOrgTemplate()).then(res => {
      const element = document.createElement('a');
      element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(JSON.stringify(res)));
      element.setAttribute('download', (<any>that.getCurrentContext()).properties.name + '.opcl');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
      document.body.removeChild(element);
    }).catch(err => { validationAlert('You are not allowed to download models.', 3500, 'error')});
  }

  loadSubModelFromDB(modelId: string) {
      return this.storage.getModel(modelId, 'MAIN');
  }

  loadModelFromFile(json: Object) {
    const c = new ModelContext({
      id: undefined,
      name: json['name'] || 'Unsaved Model',
      path: [],
      description: json['description'] || '',
      archiveMode: undefined,
      permission: undefined,
      isVersion: undefined,
      isAutosave: undefined
    }, this);
    this.addContextForTabs(c, json,[], []);
    this.replaceContext(c);
    this.removeTab(0);
    this.model.set(json as any, c.getWholeTextName(), undefined);
  }

  async saveSubModel(jsonSubModel, title: string, fatherModelId?: string) {
    let path = this.context.getPath();
    if (path[0].id === 'root') {
      path = path.reverse();
    }
    const dir_id = path[0].id;
    const params = { at_directory: dir_id };
    const toWrite: WriteModelSchema = {
      ...jsonSubModel,
      title: title,
      description: '',
      sysExample: false,
      image: null,
      globalTemplate: false,
      fatherModelId: fatherModelId,
      archiveMode: {
        archiveMode: false,
        date: '',
        name: '',
      }
    };
    return this.storage.createModel(params, toWrite).then(ret => ret.created_id);
  }

  createNewModelFromWizard(params: NewModelByWizardParams) {
    this.newModel();
    this.model.model.createModelFromWizardParams(params);
  }

  hasUnsavedModels(): boolean {
      return this.getTabs().some(t => t.modelData.hasUnsavedWork);
  }
}
