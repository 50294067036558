import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmProcess } from "../../DrawnPart/OpmProcess";
import { OpmLogicalProcess } from "../../LogicalPart/OpmLogicalProcess";
import { OpmVisualProcess } from "../../VisualPart/OpmVisualProcess";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class SetProcessTimeDurationCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly process: OpmProcess,
        private readonly visual: OpmVisualProcess) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'set-time-duration',
            displayTitle: 'Add Time Duration',
            svg: 'timeDuration',
            action: new SetProcessTimeDurationAction(this.init, this.process, this.visual),
            gif: 'assets/gifs/set_time_duration.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'set-time-duration',
            displayTitle: 'Add Time Duration',
            svg: 'timeDuration',
            action: new SetProcessTimeDurationAction(this.init, this.process, this.visual),
            gif: 'assets/gifs/set_time_duration.gif',
        }
    }

}

class SetProcessTimeDurationAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly drawn: OpmProcess,
        private readonly visual: OpmVisualProcess) {
    }

    act() {
      const cell = this.init.graph.getCell(this.drawn.id);
      const vis = this.init.opmModel.getVisualElementById(this.drawn.id);
      if (cell && vis)
        cell.openTimeDuration(this.init.paper.findViewByModel(cell).el, (vis.logicalElement as OpmLogicalProcess).getDurationManager(), { digits: this.init.oplService.settings.timeDurationUnitsDigits });
    }

}
