import {OpmDefaultLink} from './OpmDefaultLink';
import {
  getInitRappidShared,
  joint,
  popupGenerator,
  popupInputsEnterListener,
  stylePopup,
  validationAlert
} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {linkType} from '../../ConfigurationOptions';

export  class OpmStructuralLink extends OpmDefaultLink {
  ordered:boolean;
  constructor(id?: string) {
    super(id);
    this.attr({'line': {'strokeDasharray': '0'}});
    this.attr({'line': {'stroke': '#586D8C'}});
    this.attr({'line': {'strokWidth': 2}});
    this.attr({'sourceMarker' : {'strokeWidth': 2}});
    this.attr({'targetMarker' : {'strokeWidth': 2}});
    if (this.ordered) this.mainUpperLink.setLabelsOLinks('ordered', 0.5, null);
  }
  getStructuralLinkParams() {
    const params = {
      targetMultiplicity: this.get('targetMultiplicity'),
    };
    return {...super.getDefaultLinkParams(), ...params};
  }
  popupContentDbClick() {
  }

  updateTargetMultiplicity(oldVal: string, newVal: string, link: OpmStructuralLink, init: InitRappidService) {
    const visuals = this.getVisual().logicalElement.visualElements.filter(vis => vis !== this.getVisual());
    if (link.labels().length > 0 && link.labels().find(lb => lb.attrs.label.text === oldVal))
      link.deleteLabel();
    let newLabel;
    if (newVal)
      newLabel = link.setLabelsOLinks(newVal, 0.9, null);
    link.set('targetMultiplicity', newVal);
    for (const vis of visuals) {
      (<any>vis).targetMultiplicity = newVal;
      // (<any>vis).labels = [newLabel];
      if (!(<any>vis).labels && newLabel)
        (<any>vis).labels = [newLabel];
      else if ((<any>vis).labels) {
        const badVisLabel = (<any>vis).labels.find(lb => lb.attrs.label.text === oldVal);
        if (badVisLabel)
          (<any>vis).labels.splice((<any>vis).labels.indexOf(badVisLabel), 1);
        if (newVal)
          (<any>vis).labels.push(newLabel);
      }
    }
    this.addDblClickListenerForLabels();
  }


  addLinkToOrderedFundamental() {
    const orderedFundamentalTypes = (<any>this.sourceElement.getVisual()?.logicalElement)?.orderedFundamentalTypes || [];
    if (!orderedFundamentalTypes.includes((<any>this.getVisual()).type))
      orderedFundamentalTypes.push((<any>this.getVisual()).type);
  }

  removeLinkFromOrderedFundamental() {
    const orderedFundamentalTypes = (<any>this.sourceElement.getVisual()?.logicalElement)?.orderedFundamentalTypes || [];
    if (orderedFundamentalTypes.includes((<any>this.getVisual()).type)) {
      const pos = orderedFundamentalTypes.indexOf((<any>this.getVisual()).type);
      orderedFundamentalTypes.splice(pos, 1);
    }
  }

  popupEventsDbClick(element, init?: InitRappidService) {
    const this_ = this;
    return {
      'click .urlSvg': function() {
        const dataToRemember = this_.getPopupDataToRemember(this);
        this_.openLinkURLEditing(init).afterClosed().toPromise().then(res => {
          this_.rightClickHandlePopoup(init.paper.findViewByModel(element).el, init);
          this_.restorePopupData(dataToRemember);
        });
        this.remove();
      },
      'click .btnUpdate': function () {
        init.getOpmModel().logForUndo('link labels update');
        if (element.labels().length > 0)
          element.deleteLabel();
        const text = (/\S/.test(this.$('.trgt').val())) ? this.$('.trgt').val().toLowerCase().trim() : undefined;
        this_.updateTargetMultiplicity(element.get('targetMultiplicity'), text, element, init);
        this_.updateRequirementsLabel(element.get('requirements'), this.$('.req').val().trim(), this.$('.showReq')[0].checked, element, init);
        element.set('requirements', this.$('.req').val().trim());
        element.set('showRequirementsLabel', this.$('.showReq')[0].checked);
        if (this.$('.ordered')[0].checked) {
          (<any>this_).addLabelOrderedSubpart();
          (<any>this_).addLinkToOrderedFundamental();
        } else (<any>this_).removeLinkFromOrderedFundamental();

        this.remove();
      },
      /**
       * group 04:
       * By clicking on the 'Copy Style' button, we keep the style of the source link in the 'linkCopiedStyleParams' dictionary.
       */
      'click .btnStyleCopy': function () {
        this.remove();
        init.linkCopiedStyleParams = {};
        init.linkCopiedStyleParams['strokeWidth'] = this_.attr('line/strokeWidth');
        init.linkCopiedStyleParams['strokeColor'] = this_.attr('line/stroke');
      },
      'click .btnStyle': function () {
        this.remove();
        const stylePopupContent = ['Link color: <input type="color" class="linkColor PopupColorInput" value=' + this_.attr('link/fill') + '><br>',
          'Link width: <input type="width" style="width:35px;padding-top: 5px" class="linkwidth PopupInput" value=' + this_.attr('line/strokeWidth') + '><br>',
          '<button class="btnUpdateStyle Popup" style="margin-left: 6px;margin-top:5px">Update Style</button>'];
        const stylePopupEvents = {
          'click .btnUpdateStyle': function () {
            if ( this.$('.linkwidth').val() < '1' || this.$('.linkwidth').val() > '6') {
              const errorMessage = 'Maximum width is 6';
              validationAlert(errorMessage, 5000, 'Error');
              return;
            }
            init.getOpmModel().logForUndo('link style change');
            this_.attr({line: {'stroke': this.$('.linkColor').val()}});
            this_.attr({line: {'strokeWidth': this.$('.linkwidth').val()}});
            this_.mainUpperLink.attr({line: {'stroke': this.$('.linkColor').val()}});
            this_.mainUpperLink.attr({line: {'strokeWidth': this.$('.linkwidth').val()}});

            if (this_.isFundamentalLink())
              this_.updateTriangle(init);

            this.remove();
          },

        };
        const el = init.paper.findViewByModel(this_).el;
        popupGenerator(el, stylePopupContent, stylePopupEvents).render();
        stylePopup();
        popupInputsEnterListener();
        (<HTMLInputElement>$('.linkColor')[0]).value =  this_.attr('line/stroke');
      }
    };
  }

  pointerUpHandle(cellView, initRappid, $event) {
    super.pointerUpHandle(cellView, initRappid, $event);
    // this.resizePort();
  }

  getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {
    return (this.sourceElement && (this.sourceElement.attributes.attrs.digitalTwinConnected)) ? [verticesTool, segmentsTool, removeButton] : [verticesTool, segmentsTool, targetArrowheadTool, removeButton];
  }

  getSourceMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances that represents a binary structural relation in an OPD from the source thing';
  }
  getTargetMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances that represents a binary structural relation in an OPD of the target thing';
  }

  includedInOrderedTypes() { // return true if the link exist in orderedFundamentalTypes.
    const orderedFundamentalTypes = (<any>this.sourceElement.getVisual()?.logicalElement)?.orderedFundamentalTypes || [];
    if (orderedFundamentalTypes.includes((<any>this.getVisual())?.type)) {
      return true;
    }
    return false;
  }
}
