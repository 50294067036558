import { Component, Optional } from '@angular/core';

@Component({
  selector: 'progress-spinner',
  templateUrl: 'Progress_Spinner.html',
  styleUrls: ['Progress_Spinner.scss']
})
export class ProgressSpinner {


  constructor() { }

  Show() {

  }

  Hide() {

  }
}
