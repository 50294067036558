import { Component } from '@angular/core';
//import {MdListModule} from '@angular/material';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { SignInComponent } from './layout/header/sign-in/sign-in.component';
import {UserService} from "../rappid-components/services/user.service";
import {OPCloudUtils} from "../configuration/rappidEnviromentFunctionality/shared";
//import { MatDialog } from '@angular/material/dialog';import { MatDialog } from '@angular/material/dialog';import { SignInComponent } from './layout/header/sign-in/sign-in.component';

@Component({
  selector: 'opc-main-login',
  template: `
              <!--<mat-toolbar class="app-toolbar" color="primary"> &lt;!&ndash;duplicated&ndash;&gt;-->
                <!--<img src="assets/SVG/logo.svg" class="logo"> &lt;!&ndash;duplicated&ndash;&gt;-->
                <!--&lt;!&ndash;<opc-user-status class="user_info"></opc-user-status>&ndash;&gt;-->
              <!--</mat-toolbar>-->
              <div id="mainDIV" align="center" >
              <img class= "opmLogo" src="assets/SVG/newLogo.svg" >
                  <mat-card class="WarningCard">
                      <div class="my_text">Please Sign In</div>
                       <mat-card-content>
                          <button class="button" id="sign-in" (click)="backToMain()">
                           <div id="spin"></div><a> OPCloud Account </a></button>
                          <button class="button" id="sign-in2" (click)="signWithSSO('Microsoft')" (mouseenter)="this.showGIF($event, 'assets/gifs/microsoft.jpg')" (mouseleave)="this.mouseLeave()">
                          <div id="spin"></div><a> With Microsoft's SSO </a></button>
                          <a id="switchSSO" (click)="switchSSOAccounts()"><span>Switch SSO Account</span></a>
                         <button class="button" id="sign-in3" (click)="signWithSSO('Google')" (mouseenter)="this.showGIF($event, 'assets/gifs/google1.jpg')" (mouseleave)="this.mouseLeave()">
                           <div id="spin"></div><a> With Google's SSO </a></button>
                      </mat-card-content>
                  </mat-card>
              </div>

            `,
  styleUrls: ['./MainLogin.component.scss']
})
export class MainLoginComponent {
  private isLoading = false;
  constructor(
    private router: Router,
    private _dialog: MatDialog,
    private userService: UserService
  ) { }

  backToMain() {
    document.getElementById('mainDIV').classList.add('bluring');
    const dialogRef = this._dialog.open(SignInComponent);
    dialogRef.afterClosed().subscribe(result => {
      if (document.getElementById('mainDIV')) {
        document.getElementById('mainDIV').classList.remove('bluring');
      }
      this.isLoading = true;
    });
  }

  signWithSSO(providerName: 'Google' | 'Microsoft') {
    ((providerName === 'Microsoft') ? this.userService.signInWithMsal() : this.userService.signInWithGoogle())
      .then(() => this.router.navigate([''])).catch(err => {
        this.router.navigate(['/login']);
        if (err && typeof err === 'string')
          alert(err);
    });
  }

  switchSSOAccounts() {
    this.userService.signOutWithMsal().then(() => {}).catch((err) => {console.log(err)});
  }

  showGIF($event, handlerGif = '') {
    return OPCloudUtils.showGIF($event, handlerGif, false, true);
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }
}


