import { Component, OnInit, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { OpmOpd } from '../../models/OpmOpd';
import { InitRappidService } from "../../rappid-components/services/init-rappid.service";

interface RequirementData {
  name: string,
  opd: OpmOpd
}

@Component({
  selector: 'create-view-dialog',
  templateUrl: 'create-requirement-view-dialog.html',
  styleUrls: ['create-requirement-view-dialog.css']
})

export class CreateRequirementViewDialog implements OnInit {

  private requirements: Array<{ name: string, opd: OpmOpd }>;

  constructor(private init: InitRappidService, private dialogRef: MatDialogRef<CreateRequirementViewDialog>, @Inject(MAT_DIALOG_DATA) private data) {
    const reqNames = this.init.opmModel.getAllModelRequirementsNumbers();
    this.requirements = reqNames.map(req => {
      return { name: req, opd: this.init.opmModel.opds.find(opd => opd.requirementViewOf === req) };
    });
  }

  ngOnInit() {

  }

  onRequirementClick(req: RequirementData) {
    if (!req.opd)
      this.createRequirementViewOf(req.name);
    else {
      this.updateRequirementViewOf(req.opd);
    }
  }

  createRequirementViewOf(requirementName: string) {
    const ret = this.init.opmModel.createRequirementViewOf(requirementName);
    if (ret.success) {
      this.init.treeViewService.init(this.init.opmModel);
      this.init.getGraphService().renderGraph(ret.opd, this.init);
      this.dialogRef.close();
    }
  }

  updateRequirementViewOf(opd: OpmOpd) {
    const ret = this.init.opmModel.updateRequirementViewOf(opd);
    if (ret.updated && !ret.removed)
      this.init.getGraphService().renderGraph(opd, this.init);
    else if (ret.removed)
      this.init.treeViewService.init(this.init.opmModel);
    this.dialogRef.close();
  }
}
