import { Component, OnInit, Optional } from '@angular/core';

@Component ({
  selector: 'opcloud-clear-canvas-dialog',
  templateUrl: 'clear-canvas.html',
  styleUrls: ['clear-canvas.css']
})
export class ClearCanvasComponent {

  constructor() {
  }

}
