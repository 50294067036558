
export enum BackgroundImageState {
  TEXTONLY = 1,
  IMAGEONLY,
  TEXTANDIMAGE,
  TEXTANDIMAGEFULL
}

export enum ImagesPoolType {
  GLOBAL = 1,
  ORG,
  PERSONAL
}

export interface ImagesPoolItem {
  id: string,
  dateAdded: number,
  url: string,
  imageTags: Array<string>
}
