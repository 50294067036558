// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
  text-align: center;
  font-size: large;
  font-weight: bold;
}

ul{
  list-style-type: none ;
  float: left;
  padding: 5px;
}

span{
  text-align: left;
  font-size: medium;
  font-weight: bold;
  color: #1A3763;
}

li{
  padding-left:1em
}

.mat-button-red:hover{
  background-color: #ff443d;
  color: white;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/remove-operation-selection/remove-operation.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,sBAAsB;EACtB,WAAW;EACX,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;EACjB,iBAAiB;EACjB,cAAc;AAChB;;AAEA;EACE;AACF;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,aAAa;AACf","sourcesContent":["p{\n  text-align: center;\n  font-size: large;\n  font-weight: bold;\n}\n\nul{\n  list-style-type: none ;\n  float: left;\n  padding: 5px;\n}\n\nspan{\n  text-align: left;\n  font-size: medium;\n  font-weight: bold;\n  color: #1A3763;\n}\n\nli{\n  padding-left:1em\n}\n\n.mat-button-red:hover{\n  background-color: #ff443d;\n  color: white;\n}\n\n::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
