import {Component, Inject, OnInit, Optional} from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { TreeViewService } from '../../rappid-components/services/tree-view.service';
import {Node} from "../../models/node.model";
import {OPCloudUtils, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {ContextService} from "../../modules/app/context.service";
import {TreeParser} from "../treeParser";
import {
  DisconnectSubModelTreeAction, OpenSubModelInNewTabTreeAction,
  RemoveOpdTreeAction,
  RenameOpdTreeAction, RenameSubModelTreeAction,
  ToggleOPDsNamesTreeAction,
  UpdateRequirementViewTreeAction
} from "../../opd-hierarchy/opdsTreeActions";
import { trigger, state, style, animate, transition } from '@angular/animations';
import {moveItemInArray} from "@angular/cdk/drag-drop";

interface TreeItem {
  id: string;
  checked: boolean;
  selected?: boolean;
  title: string;
  depth: string;
  depthAsNumber: number;
  highlighted: boolean;
  node;
  isOpen: boolean;
  isVisible: boolean;
  parent: TreeItem;
}

@Component({
  selector: 'select-opds-tree-dialog',
  templateUrl: 'select-opds-tree-dialog.html',
  styleUrls: ['select-opds-tree-dialog.css'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(
          ':enter',
          [
            style({ height: 0, opacity: 0 }),
            animate('0.3s ease-out',
              style({ height: 20, opacity: 1 }))
          ]
        ),
        transition(
          ':leave',
          [
            style({ height: 20, opacity: 1 }),
            animate('0.3s ease-in',
              style({ height: 0, opacity: 0 }))
          ]
        )
      ]
    )
  ]
})

export class SelectOpdsTreeDialog implements OnInit{

  private checkedValues;
  private nodes: Array<any>;
  private arr = new Array<TreeItem>();
  private displayArr = new Array<TreeItem>();
  private searchString: string;
  private readonly mode: 'export' | 'tree-management';
  private readonly searchTitle: string;
  private readonly searchInnerText: string;
  private readonly closeButtonText: string;
  private selectedItem: TreeItem;
  private draggedItem: TreeItem;
  private dragTarget: TreeItem;
  private multiSelectedItems: Array<any>;
  private itemsMap: Map<string, TreeItem>;

  private _shouldShowRename: boolean;
  private _shouldShowRemove: boolean;
  private _shouldShowUpdateRequirementsView: boolean;
  private _shouldShowDisconnectSubModel: boolean;
  private _shouldShowRenameSubModel: boolean;
  private _shouldShowUpdateSubModel: boolean;
  private _shouldShowLoadSubModel: boolean;
  private _shouldShowCut: boolean;
  private _shouldShowPaste: boolean;
  private _shouldShowOpenSubModelInNewTab: boolean;
  private isLoading: boolean;
  private openNodes: {};
  private visibleNodes: {};
  private cutItem: Array<TreeItem>;
  private invisibleItem: TreeItem;

  constructor(@Optional() @Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<SelectOpdsTreeDialog>, public treeViewService: TreeViewService, private context: ContextService) {
    this.checkedValues = {};
    this.searchString = '';
    if (!data) {
      data = { mode: 'tree-management', title: '' };
      this.data = data;
    }
    this.mode = data.mode;
    this.searchTitle = this.mode === 'export' ? 'SDs to export' : 'Search OPD';
    this.searchInnerText = this.mode === 'export' ? 'Search by name, number or \'Checked SDs\'' : 'Search by name or number';
    this.closeButtonText = this.mode === 'export' ? 'Cancel' : 'Close';
    this.openNodes = {};
    this.visibleNodes = {};
    this.multiSelectedItems = [];
    this.itemsMap = new Map<string, TreeItem>();
    this.cutItem = [];
  }

  ngOnInit() {
    this.itemsMap.clear();
    this.nodes = this.treeViewService.nodes;
    this.arr = (new TreeParser(this.treeViewService)).parse(true);
    for (const item of this.arr) {
      if (this.openNodes.hasOwnProperty(item.id)) {
        item.isOpen = this.openNodes[item.id];
        item.isVisible = this.visibleNodes[item.id];
      }
    }
    this.displayArr = this.arr;
    this.displayArr.forEach(item => this.itemsMap.set(item.id, item));
    const storageItem = localStorage.getItem(((<any>this.context.getCurrentContext())?.properties?.id || '') + 'selectedPdfOPDs');
    if (storageItem) {
      const asJson = JSON.parse(storageItem);
      for (const item of this.arr) {
        const same = asJson.find(i => i.title === item.title && i.depth === item.depth);
        if (same)
          item.checked = same.checked;
      }
    }
    this.setLasso();
  }


  apply() {
    const ret = this.arr.filter(item => item.checked).map(item => this.treeViewService.initRappid.opmModel.getOpd(item.id));
    if (ret.length === 0)
      return validationAlert('At least 1 OPD should be selected.', 5000);
    const toSet = this.arr.map(item => {
      return {
        id: item.id,
        depth: item.depth,
        title: item.title,
        checked: item.checked
      }
    })
    localStorage.setItem(((<any>this.context.getCurrentContext()).properties?.id || '') + 'selectedPdfOPDs', JSON.stringify(toSet));
    this.dialogRef.close(ret);
  }

  cancel() {
    this.dialogRef.close(undefined);
  }

  selectSubTree(item: TreeItem) {
    if (item.title.includes('stereotype') || item.title.includes('View of Requirement')) {
      item.checked = !item.checked;
      return;
    }
    const itemsToChange = [];
    for (const other of this.arr) {
      if (other.depth.startsWith(item.depth) && !other.title.includes('stereotype') && !other.title.toLowerCase().includes('view of'))
        itemsToChange.push(other);
    }
    let valToSet;
    if (itemsToChange.find(i => !i.checked))
      valToSet = true;
    else valToSet = false;

    itemsToChange.forEach(i => i.checked = valToSet);
    this.filterResults();
  }

  highlight(item: TreeItem) {
    if (item.title.includes('stereotype') || item.title.includes('View of Requirement') || this.mode === 'tree-management')
      return;
    for (const i of this.arr)
      if (i !== item && i.depth.startsWith(item.depth))
        i.highlighted = true;
  }

  unhighlight() {
    for (const i of this.arr)
        i.highlighted = false;
  }

  getMarginLeft(item: TreeItem) {
    if (this.searchString.trim().length > 0) {
      return 'margin-left: 0px;';
    }
    if (item.title.includes('stereotype') || item.title.includes('View of Requirement'))
      return 'margin-left: 0px;';
    return 'margin-left: ' + item.depthAsNumber * 40 + 'px;';
  }

  onSearchStringChange($event) {
    this.searchString = $event.target.value;
    this.filterResults();
  }

  filterResults() {
    if (this.searchString.trim().length === 0) {
      this.displayArr = this.arr;
    } else if (this.searchString.trim().toLowerCase() === 'checked sds') {
      this.displayArr = this.arr.filter(item => item.checked);
    } else {
      this.displayArr = this.arr.filter(item => item.title.toLowerCase().includes(this.searchString.toLowerCase()));
    }
  }

  selectAll() {
    this.displayArr.forEach(item => item.checked = true);
  }

  unselectAll() {
    this.displayArr.forEach(item => item.checked = false);
  }

  onRadioChange($event, item: TreeItem) {
    if ($event.ctrlKey || $event.metaKey) {
      if (this.mode !== 'tree-management' || item.node.belongsToSubModel || item.node.sharedOpdWithSubModelId) {
        return;
      }
      if (!this.multiSelectedItems.includes(item)) {
        if (this.multiSelectedItems.length === 0 || this.multiSelectedItems[0].parent === item.parent) {
          this.multiSelectedItems.push(item);
          $('#tree').find("[opdId='" + item.id + "']")[0].classList.add('multiselected');
        }
      } else {
        const idx = this.multiSelectedItems.indexOf(item);
        if (idx >= 0) {
          this.multiSelectedItems.splice(idx, 1);
          $('#tree').find("[opdId='" + item.id + "']")[0].classList.remove('multiselected');
        }
      }
    } else {
      this.multiSelectedItems = [];
      $('.nodeName').removeClass('multiselected');
    }
    this.selectedItem = item;
    this.arr.forEach(other => other.selected = item === other);
    this.onSelection();
  }

  onSelection() {
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    this._shouldShowRename = (new RenameOpdTreeAction(init, opd)).canBePerformed();
    this._shouldShowUpdateRequirementsView = (new UpdateRequirementViewTreeAction(init, opd)).canBePerformed();
    this._shouldShowDisconnectSubModel = (new DisconnectSubModelTreeAction(init, this.context, opd)).canBePerformed();
    this._shouldShowRenameSubModel = (new RenameSubModelTreeAction(init, opd)).canBePerformed();
    this._shouldShowOpenSubModelInNewTab = (new OpenSubModelInNewTabTreeAction(init, this.context, opd)).canBePerformed();
    this._shouldShowLoadSubModel = this._shouldShowRenameSubModel && this.shouldShowRedResync(this.selectedItem.node);
    this._shouldShowRemove = !this.selectedItem.node.belongsToSubModel && !this.selectedItem.node.sharedOpdWithSubModelId
      && this.selectedItem.node.parent?.id !== 'Stereotypes';
    this._shouldShowCut = this.canBeCut(this.selectedItem);
    this._shouldShowPaste = this.cutItem.length > 0 && this.canBePasted(this.selectedItem);
  }

  getItemTooltip() {
    return this.mode === 'export' ? 'Double-click to select / unselect all children' : 'Double-click to open';
  }

  openOpd(item?: TreeItem) {
    const init = this.treeViewService.initRappid;
    const id = item?.id || this.selectedItem.id;
    init.opdHierarchyRef.changeGraphModel(null, init.treeViewService.treeView.treeModel.getNodeById(id));
    this.dialogRef.close();
  }

  renameOpd() {
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    (new RenameOpdTreeAction(init, opd)).act().then(newName => {
      this.selectedItem.title = newName;
    });
  }

  async removeOpd() {
    const init = this.treeViewService.initRappid;
    const treeModel = this.treeViewService.treeView.treeModel;
    const node = treeModel.getNodeById(this.selectedItem.id);
    const ret = await (new RemoveOpdTreeAction(init, node, treeModel)).act();
    if (ret.removed) {
      const idx = this.arr.findIndex(item => item.id === this.selectedItem.id);
      this.arr.splice(idx, 1);
      this.selectedItem = undefined;
    }
  }

  doubleClickItem(item: TreeItem) {
    return this.mode === 'export' ? this.selectSubTree(item) : this.openOpd(item);
  }

  toggleShowHideOpdsNames() {
    (new ToggleOPDsNamesTreeAction(this.treeViewService.initRappid)).act();
  }

  getShowHideButtonText() {
    return !this.treeViewService.initRappid.oplService.settings.SDNames ? 'Show Names' : 'Hide Names';
  }

  clickItem($event, item: TreeItem) {
    if (this.mode === 'tree-management') {
      this.onRadioChange($event, item);
    }
  }

  getItemTitle(item: TreeItem) {
    const state = this.treeViewService.initRappid.oplService.settings.SDNames;
    if (state) {
      return item.title
    }
    return item.title.split(':')[0];
  }

  async onDrop() {
    const init = this.treeViewService.initRappid;
    if (this.multiSelectedItems.length > 1) {
      const dragTargetId = this.dragTarget?.id;
      const invisibleId = this.invisibleItem?.id;
      init.opmModel.logForUndo('Multi tree movement');
      init.opmModel.setShouldLogForUndoRedo(false, 'Multi tree movement');
      for (const item of this.multiSelectedItems) {
        this.invisibleItem = this.itemsMap.get(invisibleId);
        this.draggedItem = this.itemsMap.get(item.id);
        this.dragTarget = this.itemsMap.get(dragTargetId);
        await this.onDropAction();
      }
      this.multiSelectedItems = [];
      $('.nodeName').removeClass('multiselected');
      init.opmModel.setShouldLogForUndoRedo(true, 'Multi tree movement');
      return;
    }
    return this.onDropAction();
  }

  async onDropAction() {
    let idxInParent;
    if (this.invisibleItem && this.invisibleItem.parent) {
      const parentOpd = this.treeViewService.initRappid.opmModel.getOpd(this.invisibleItem.parent.id);
      idxInParent = parentOpd.children.findIndex(it => it.id === this.invisibleItem.id);
      this.dragTarget = this.invisibleItem.parent;
    }
    if (!this.canBeDropped()) {
      this.draggedItem = undefined;
      this.dragTarget = undefined;
      return;
    }
    this.isLoading = true;
    await OPCloudUtils.waitXms(300);
    const init = this.treeViewService.initRappid;
    const effectedO = init.opmModel.getOpd(this.draggedItem.id);
    const realParentN = this.treeViewService.treeView.treeModel.getNodeById(this.dragTarget.id).parent;
    const realParentID = this.dragTarget.id;
    const realParentO = init.opmModel.getOpd(this.dragTarget.id);
    const prevParentO = init.opmModel.getOpd(effectedO.parendId);
    const ev = {
      node: {}
    }
    init.opdHierarchyRef.onMoveNode(ev, effectedO, realParentN, realParentID, prevParentO);
    if (effectedO.parendId === realParentID) {
      prevParentO.children = prevParentO.children.sort((a,b) => a.id === this.draggedItem.id ? -1 : 0);
    }
    if (idxInParent !== undefined) {
      moveItemInArray(realParentO.children, realParentO.children.findIndex(c => c.id === this.draggedItem.id), idxInParent + 1);
    }
    this.treeViewService.init(init.opmModel);
    if (!this.dragTarget.isOpen) {
      this.draggedItem.isVisible = false;
    }
    this.draggedItem = undefined;
    this.dragTarget = undefined;
    this.selectedItem = undefined;
    this.keepWhatNodesAreOpen();
    this.ngOnInit();
    this.isLoading = false;
  }

  dragStart($event: DragEvent, item: TreeItem) {
    this.draggedItem = item;
    (<any>$event.target).classList.add('dragged');
  }

  dragEnter($event: DragEvent, item: TreeItem) {
    this.dragTarget = item;
  }

  dragLeave($event: DragEvent) {
    this.dragTarget = undefined;
    (<any>$event.target).classList.remove('dragged');
  }

  dragover($event) {
    $event.stopPropagation();
    $event.preventDefault();
  }

  getItemStyle(item: TreeItem) {
    let style = '';
    if (item.highlighted) {
      style += 'opacity: 0.6;'
    } else if (item === this.draggedItem) {
      style += 'opacity: 0.3;'
    } else if (this.cutItem.includes(item)) {
      style += 'opacity: 0.3;'
    }
    if (this.dragTarget === item) {
      style += 'font-weight: bold;'
    }
    return style;
  }

  isDraggingActive(item: TreeItem): boolean {
    return this.mode === 'tree-management' && item.title !=='SD' && !item.node.belongsToSubModel
      && !item.title.startsWith('View of Requirement') && !item.node.sharedOpdWithSubModelId && item.node.parent.id !== 'Stereotypes';
  }

  updateRequirementsView() {
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    (new UpdateRequirementViewTreeAction(init, opd)).act().then(() => this.dialogRef.close());
  }

  disconnectSubModel() {
    const that = this;
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    (new DisconnectSubModelTreeAction(init, this.context, opd)).act().then(() => {
      that.dialogRef.close();
    });
  }

  renameSubModel() {
    const that = this;
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    (new RenameSubModelTreeAction(init, opd)).act().then(newName => {
      if (newName) {
        that.selectedItem.title = that.selectedItem.title.split(':')[0] + ': ' + newName + ' Subsystem Model View';
      }
    });
  }

  openSubModelInNewTab() {
    const init = this.treeViewService.initRappid;
    const opd = init.opmModel.getOpd(this.selectedItem.id);
    (new OpenSubModelInNewTabTreeAction(init, this.context, opd)).act().then(() => this.dialogRef.close());
  }

  nodeHasChildren(node) {
    return node.children?.length > 0;
  }

  nodeSharedWithSubModel(node) {
    return this.treeViewService.initRappid.opdHierarchyRef.nodeSharedWithSubModel(node);
  }

  arrowClick($event, item: TreeItem) {
    $event?.stopPropagation();
    $event?.preventDefault();
    if (!this.nodeHasChildren(item.node)) {
      return;
    }
    item.isOpen = !item.isOpen;
    const beginning = item.title === 'SD' ? 'SD' : item.title.split(':')[0] + '.';
    if (item.isOpen) {
      for (const other of this.arr) {
        if (other !== item && other.title.startsWith(beginning) && other.parent.isOpen && other.parent.isVisible) {
          other.isVisible = true;
        }
      }
    } else if (!item.isOpen) {
      for (const other of this.arr) {
        if (other !== item && other.title.startsWith(beginning)) {
          other.isVisible = false;
        }
      }
    }
  }

  getSubModelSyncTooltip(node) {
    return this.treeViewService.initRappid.opdHierarchyRef.getSubModelSyncTooltip({data: node});
  }

  shouldShowRedResync(node) {
    return this.mode === 'tree-management' && this.treeViewService.initRappid.opdHierarchyRef.shouldShowRedResync({data: node});
  }

  shouldShowGreenSynced(node) {
    return this.mode === 'tree-management' && this.treeViewService.initRappid.opdHierarchyRef.shouldShowGreenSynced({data: node});
  }

  shouldShowYellowSynced(node) {
    return this.mode === 'tree-management' && this.treeViewService.initRappid.opdHierarchyRef.shouldShowYellowSynced({data: node});
  }

  shouldShowItem(item: TreeItem) {
    return this.mode === 'export' || !item.parent || item.isVisible;
  }

  loadSubModel() {
    const node = this.treeViewService.treeView.treeModel.getNodeById(this.selectedItem.id);
    this.selectedItem = undefined;
    this.isLoading = true;
    this.treeViewService.initRappid.opdHierarchyRef.toggleNode(node).then(() => {
      this.keepWhatNodesAreOpen();
      this.ngOnInit();
      this.isLoading = false;
    });
  }

  keepWhatNodesAreOpen() {
    this.openNodes = {};
    this.visibleNodes = {};
    for (const item of this.arr) {
      this.openNodes[item.id] = item.isOpen;
      this.visibleNodes[item.id] = item.isVisible;
    }
  }

  cut() {
    this.cutItem = this.multiSelectedItems.length > 0 ? [...this.multiSelectedItems] : [this.selectedItem];
  }

  async paste() {
    const init = this.treeViewService.initRappid;
    const dragTargetId = this.dragTarget?.id;
    if (this.cutItem.length === 1) {
      await this.onDrop();
    } else {
      init.opmModel.logForUndo('Multi tree movement');
      init.opmModel.setShouldLogForUndoRedo(false, 'Multi tree movement');
      for (const item of this.cutItem) {
        this.dragTarget = this.itemsMap.get(dragTargetId);
        this.draggedItem = this.itemsMap.get(item.id);
        await this.onDrop();
      }
      init.opmModel.setShouldLogForUndoRedo(true, 'Multi tree movement');
    }
    this.cutItem = [];
    this.selectedItem = undefined;
  }

  canBeCut(item: TreeItem): boolean {
    if (this.multiSelectedItems.length === 0) {
      return this.isDraggingActive(item);
    }
    for (const it of this.multiSelectedItems) {
      if (!this.isDraggingActive(it)) {
        return false;
      }
    }
    return true;
  }

  canBePasted(target: TreeItem): boolean {
    this.dragTarget = target;
    this.draggedItem = this.cutItem[0];
    if (this.multiSelectedItems.length === 0) {
      return this.canBeDropped();
    }
    for (const it of this.multiSelectedItems) {
      this.draggedItem = it;
      if (!this.canBeDropped()) {
        return false;
      }
    }
    return true;
  }

  private canBeDropped(): boolean {
    if (!this.dragTarget || this.draggedItem === this.dragTarget || this.dragTarget.node.belongsToSubModel
      || this.dragTarget.node.sharedOpdWithSubModelId || this.dragTarget.title.startsWith('View of Requirement')
      || this.dragTarget.node.parent.id === 'Stereotypes') {
      return false;
    }
    const draggedT = this.draggedItem.title.split(':')[0];
    const targetT = this.dragTarget.title.split(':')[0];
    if (targetT.startsWith(draggedT)) {
      return false;
    }
    return true;
  }

  invisibleDragEnter($event: DragEvent, item) {
    if (item.id === 'SD') {
      return;
    }
    (<HTMLDivElement>$event.target).style.background = 'linear-gradient(180deg, transparent, #f4f5f9, #ffc000, #f4f5f9, transparent)';
    this.invisibleItem = item;
  }

  invisibleDragLeave($event) {
    (<HTMLDivElement>$event.target).style.background = 'transparent';
    this.invisibleItem = undefined;
  }

  setLasso() {
    if (this.mode !== 'tree-management') {
      return;
    }
    const that = this;
    const elNew = (tag, prop) => Object.assign(document.createElement(tag), prop);
    const collides = (a, b) =>
      a.x < b.x + b.width &&
      a.x + a.width > b.x &&
      a.y < b.y + b.height &&
      a.y + a.height > b.y;

    const checkElementsCollision = (x, y, width, height) => {
      that.multiSelectedItems = [];
      Array.from(document.getElementsByClassName('nodeName')).forEach(elBox => {
        const isColliding = collides({x, y, width, height}, elBox.getBoundingClientRect());
        if (isColliding) {
          const opdId = elBox.getAttribute('opdId');
          const item = that.itemsMap.get(opdId);
          const canBeDone = !item.node.belongsToSubModel && !item.node.sharedOpdWithSubModelId;
          if (item && canBeDone && that.multiSelectedItems.length === 0 || that.multiSelectedItems[0].parent === item.parent) {
            that.multiSelectedItems.push(item);
            elBox.classList.add('multiselected');
          } else {
            elBox.classList.remove('multiselected');
          }
        } else {
          elBox.classList.remove('multiselected');
        }
      });
    };

    const toolLasso = {
      onDown(event) {
        if (!event.shiftKey || that.mode !== 'tree-management') {
          return;
        }
        that.multiSelectedItems = [];
        $('.nodeName').removeClass('multiselected');
        this.startX = event.clientX;
        this.startY = event.clientY;
        this.el = elNew('div', {className: 'lasso'});

        this.onMove = this.onMove.bind(this);
        this.onUp = this.onUp.bind(this);
        addEventListener('pointermove', this.onMove);
        addEventListener('pointerup', this.onUp);

        Object.assign(this.el.style, {position: `fixed`, outline: `1px dashed black`, zIndex: `99999`, pointerEvents: `none`, userSelect: `none`});
        document.getElementById('tree').append(this.el);
      },
      onMove({clientX, clientY}) {
        this.currX = clientX;
        this.currY = clientY;
        const x = Math.min(this.startX, this.currX);
        const y = Math.min(this.startY, this.currY);
        const w = Math.abs(this.startX - this.currX);
        const h = Math.abs(this.startY - this.currY);
        Object.assign(this.el.style, { left: `${x}px`, top: `${y}px`, width: `${w}px`, height: `${h}px`});
        checkElementsCollision(x, y, w, h);
      },
      onUp() {
        removeEventListener('pointermove', this.onMove);
        removeEventListener('pointerup', this.onUp);
        this.el.remove();
      }
    };
    addEventListener('pointerdown', (evt) => toolLasso.onDown(evt));
  }

  closeAllNodes() {
    this.displayArr.forEach(item => {
      if (item.isOpen) {
        this.arrowClick(undefined, item);
      }
    });
  }
}
