import { OpmFundamentalLink } from './OpmFundamentalLink';
import {linkType, ExhibitionSVG, ExhSVGWithLine, AggregationSVG} from '../../ConfigurationOptions';
import { OpmVisualObject } from '../../VisualPart/OpmVisualObject';

export class ExhibitionLink extends OpmFundamentalLink {
  constructor(sourceElement, targetElement, graph, id?: string) {
    super(sourceElement, targetElement, graph, id);
    this.attributes.name = 'Exhibition-Characterization';
  }
  getParams() {
    const params = { linkType: linkType.Exhibition };
    return { ...super.getFundamentalLinkParams(), ...params };
  }
  clone() {
    return new ExhibitionLink(this.sourceElement, this.targetElement, this.graph);
  }

  removeHandle(init) {
    super.removeHandle(init);
    if (this.sourceElement && this.sourceElement.attributes.attrs.digitalTwinConnected &&
      <OpmVisualObject>init.getOpmModel().getVisualElementById(this.sourceElement.id)) {
      this.sourceElement.attributes.attrs.digitalTwinConnected = false;
      (<OpmVisualObject>init.getOpmModel().getVisualElementById(this.sourceElement.id)).digitalTwinConnected = false;
      (<OpmVisualObject>init.getOpmModel().getVisualElementById(this.sourceElement.id)).updateParams((<OpmVisualObject>init.getOpmModel().getVisualElementById(this.sourceElement.id)).getParams());
    }
    this.updateTarget(init);
  }

  addHandle(options) {
    super.addHandle(options);
    this.updateTarget(options);
  }

  getTriangleSVG(withLine = false, color =  '#586D8C'): string  {
    if (!withLine) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="">
              <path fill="${color}" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>
              <path fill="white"  fill-opacity="1" stroke="${color}" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/><path fill="${color}" stroke="${color}" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_7" d="M10.262040632199465,19.732118465227213 L14.94954026218473,11.528994112752926 L19.637039892170023,19.732118465227213 L10.262040632199465,19.732118465227213 z"/>
              </svg>`
    }
    return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="">
            <path fill="${color}" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/>
            <path fill="white"  fill-opacity="1" stroke="${color}" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z" style="color: rgb(0, 0, 0);" class=""/><path fill="${color}" stroke="${color}" stroke-width="2" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_7" d="M10.262040632199465,19.732118465227213 L14.94954026218473,11.528994112752926 L19.637039892170023,19.732118465227213 L10.262040632199465,19.732118465227213 z"/>
            <path fill="${color}" stroke="${color}" stroke-width="3" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,30 L28.162149898128664,30 z"/>
            </svg>`
  }

  updateTarget(options) {
    if (this.targetElement && options.getOpmModel().getVisualElementById(this.targetElement.id)) {
      for (const vis of this.targetElement.getVisual().logicalElement.visualElements) {
        const cl = options.graph.getCell(vis.id);
        if (cl)
          cl.updateView(vis);
      }
    }
  }
}
