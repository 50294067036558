const requirementsStereotype = {
  "currentOpd": {
    "id": "SD",
    "name": "SD",
    "parendId": "SD",
    "visualElements": [
      "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
      "6611c45d-5bcc-4a57-bed0-c185833e72f9",
      "8703ec6b-4c4c-4192-9740-4d4aaa59d194",
      "53b4c181-3e37-49e7-afd4-6b2803056d02",
      "091babc7-2017-4485-9e2c-f2e9d436e341",
      "69ccfd5a-ba2f-4d86-b560-aad1a7218f8f",
      "6d30be3c-fea7-4a46-b933-758a6753cc20",
      "7f15a02d-6370-42dc-a1e0-6bdef631de6e",
      "f13340c6-48ba-47d7-a66b-4d2827474d65",
      "45582763-58db-4670-8cb5-0435f4f02245",
      "f2562ccd-2498-4c46-b263-8940419d751d",
      "4ac0814c-0919-4565-b4b9-8418cd96f108",
      "1286d3ca-04c2-4089-bc3d-c6fbd605e495",
      "89807df7-1dc8-4bf2-9d54-3a4353ed0926",
      "318d4e2e-070c-44a2-b394-d57bc4778ad8",
      "d5bedc8b-45c8-4535-a3b7-3d0d5de92298",
      "41638d57-2698-4076-af3d-544b77506e8a",
      "f9031c58-1fdc-4082-bcc9-348bae7f1752",
      "b6723f4c-a276-47e6-b1ba-03c4748c9779",
      "e19ca6e7-d749-437d-9b07-966adcc317d9",
      "5bd868fd-f38c-4e11-ab88-e930d297fa2e",
      "aad11386-2b8f-4818-9cb1-5aa07a096e93",
      "7ca4f8e0-b142-4ef3-b78e-318897a4acdb",
      "6f61b95c-ee38-4a80-8405-49a4e26465f4",
      "44520822-a4cb-46a2-ac90-548e27d3f4de",
      "2ae57e21-ef88-4458-9750-2bb945c735ea",
      "fac07276-20d2-4473-9e5c-4d022a5c9a71",
      "1ad9036d-8f81-4607-850d-a7a73dd14a92",
      "d4461241-e469-44e4-8585-f049f883b5ab",
      "cd5c0651-274a-4505-8f61-47c48e085641",
      "f8dfad66-f2f0-4db0-a941-f0985aac9a9f",
      "b4886b13-459d-4b6b-b011-d9fb6c5ece03",
      "73e27e00-2b81-4f27-bceb-3ecccef61e5e",
      "97ea6f87-2ec5-4d2b-9138-4608555705cb",
      "85dcc87a-2158-4858-89ce-4bd72178cffd",
      "39292d20-b370-4cde-9d6b-f43028e18fe4",
      "5cbb8ad7-35e3-46b1-bbcf-f6e5ebf53b0e",
      "725a3ea0-5b91-4484-bf26-c97591d808be",
      "55422d78-85ac-4491-8bdb-05e0f050b8c7",
      "17d9d07a-075d-4680-b2be-89c71ba0f5a7",
      "e013f9b2-91f2-4922-80b0-e02165f76001",
      "eb0514d4-81bc-406c-a5f0-7acb287be10e",
      "a081c152-4a48-4545-95c7-92e04cd9248d",
      "70ba3bd9-4cef-4345-ac9d-bde748f255f2",
      "02e45645-61f5-40fe-b982-1fd36407f64b",
      "6222f9e5-473f-4c2c-a7ed-b1411b9972ca",
      "ee2570d8-db53-44cf-9367-5bbb502710fe",
      "cb83ac4b-5410-407e-aa47-fc7a155b914d",
      "1eda4a20-c049-40b8-a34d-b6187e1885a2",
      "903869a3-bd83-4a69-87cb-757639e5692d",
      "d67413ca-e193-41b7-907e-3034760a7358",
      "30f16fef-4ad4-48dc-a785-1d96d8f4adf9"
    ]
  },
  "logicalElements": [
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "Meta Attribute",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "6b42283a-5e91-4fe0-b6ff-2b5a3c151f7a",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Satisfaction",
      "valueType": 0,
      "visualElementsParams": [
        {
          "children": [
            "8703ec6b-4c4c-4192-9740-4d4aaa59d194",
            "53b4c181-3e37-49e7-afd4-6b2803056d02"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 78,
          "id": "6611c45d-5bcc-4a57-bed0-c185833e72f9",
          "lastStatesOrder": [
            "79f5efa8-f45a-44a3-8cdd-83f14a68e5c4",
            "4d28c02b-5290-482d-9e78-4ad4e353b20e"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 205,
          "xAlign": "middle",
          "xPos": 561,
          "yAlign": "top",
          "yPos": -594
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "Meta Attribute",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "01e16e98-ba40-45c7-82d5-e350cdab763c",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Essence",
      "valueType": 0,
      "visualElementsParams": [
        {
          "children": [
            "69ccfd5a-ba2f-4d86-b560-aad1a7218f8f",
            "6d30be3c-fea7-4a46-b933-758a6753cc20",
            "7f15a02d-6370-42dc-a1e0-6bdef631de6e"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 78,
          "id": "091babc7-2017-4485-9e2c-f2e9d436e341",
          "lastStatesOrder": [
            "0d4a52d8-2cd6-48c4-a5fe-226db0d53426",
            "da8488f3-f529-451a-98a8-90742b19f8ba",
            "2218b2b8-1844-49e6-9a3c-020a4ff7e27d"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 319,
          "xAlign": "middle",
          "xPos": 558,
          "yAlign": "top",
          "yPos": -690
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "7d3b47df-263c-4ccd-bfae-b6811993b1ff",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "5cbb8ad7-35e3-46b1-bbcf-f6e5ebf53b0e",
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetVisualElementPort": "89a6d9b0-afa7-42ad-922e-0431bc741db8",
          "targetVisualElements": [
            {
              "targetVisualElement": "6611c45d-5bcc-4a57-bed0-c185833e72f9"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "dd62b872-3f1c-4449-b6e0-d69ecdbae0eb",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "725a3ea0-5b91-4484-bf26-c97591d808be",
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetVisualElements": [
            {
              "targetVisualElement": "091babc7-2017-4485-9e2c-f2e9d436e341"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0d4a52d8-2cd6-48c4-a5fe-226db0d53426",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "operational",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "091babc7-2017-4485-9e2c-f2e9d436e341",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "69ccfd5a-ba2f-4d86-b560-aad1a7218f8f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 88,
          "xAlign": "middle",
          "xPos": 568.75,
          "yAlign": "middle",
          "yPos": -653.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "da8488f3-f529-451a-98a8-90742b19f8ba",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "non-operational",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "091babc7-2017-4485-9e2c-f2e9d436e341",
          "fill": "#FFFFFF",
          "height": 31,
          "id": "6d30be3c-fea7-4a46-b933-758a6753cc20",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 128,
          "xAlign": "middle",
          "xPos": 667.5,
          "yAlign": "middle",
          "yPos": -654.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "2218b2b8-1844-49e6-9a3c-020a4ff7e27d",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "hybrid",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "091babc7-2017-4485-9e2c-f2e9d436e341",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "7f15a02d-6370-42dc-a1e0-6bdef631de6e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 806.25,
          "yAlign": "middle",
          "yPos": -653.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "4d28c02b-5290-482d-9e78-4ad4e353b20e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "hard",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6611c45d-5bcc-4a57-bed0-c185833e72f9",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "8703ec6b-4c4c-4192-9740-4d4aaa59d194",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 677.6666666666667,
          "yAlign": "middle",
          "yPos": -557.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "79f5efa8-f45a-44a3-8cdd-83f14a68e5c4",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "soft",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6611c45d-5bcc-4a57-bed0-c185833e72f9",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "53b4c181-3e37-49e7-afd4-6b2803056d02",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 589.3333333333333,
          "yAlign": "middle",
          "yPos": -557.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": true,
      "lid": "1c8feee0-c572-428d-b3e5-377396958ae5",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Requirement",
      "units": "",
      "value": "None",
      "valueType": 0,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 60,
          "id": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "refX": 0.5,
          "refY": 0.5,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 415,
          "yAlign": "middle",
          "yPos": -808,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "a961ea77-4e55-4a17-9e1d-d30d1a318291",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Name",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "4b58dc39-bfca-4eb7-8d66-7226714acdb3"
      },
      "value": "req name",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "32bb3e7b-f990-4207-981b-84a198425b21",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "45582763-58db-4670-8cb5-0435f4f02245"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 86.93,
          "id": "f13340c6-48ba-47d7-a66b-4d2827474d65",
          "lastStatesOrder": [
            "5dbfdcab-3d72-4ae6-84c0-106e9cd7c448"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 197,
          "yAlign": "top",
          "yPos": -698
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": false,
      "isMainThing": false,
      "lid": "03ec5ba8-6c69-4942-913b-d326d06c89a6",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "ID",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "f3cd6641-fd3f-486a-b115-a1f20f43f6f8"
      },
      "value": "req id",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "7a290ac2-8d85-4e0e-a17b-871ccde5a9e8",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "4ac0814c-0919-4565-b4b9-8418cd96f108"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 70,
          "id": "f2562ccd-2498-4c46-b263-8940419d751d",
          "lastStatesOrder": [
            "500790f2-c3a2-428a-a59f-ebf5ca997618"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 197,
          "yAlign": "top",
          "yPos": -600
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "38291cc9-6318-4a7b-8226-2d5b1edfbbcd",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Description",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "15cb41fa-0fd9-42ca-9829-311a5414e6b4"
      },
      "value": "desc name",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "df81966e-597c-42f4-ae8d-0da42de496ca",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "89807df7-1dc8-4bf2-9d54-3a4353ed0926"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 89.423,
          "id": "1286d3ca-04c2-4089-bc3d-c6fbd605e495",
          "lastStatesOrder": [
            "bc93f92a-bcbd-4eee-aeb6-8527943ce16b"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 199,
          "yAlign": "top",
          "yPos": -522
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "3eb0b7a9-073b-4008-8f16-421c4fb3949f",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Pre-condition Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "0f828928-78f6-461a-bc81-34312d500571"
      },
      "value": "pre-condition desc",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "03e052f3-b61c-4e31-86f2-79b04816eb7d",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "d5bedc8b-45c8-4535-a3b7-3d0d5de92298"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 91.5,
          "id": "318d4e2e-070c-44a2-b394-d57bc4778ad8",
          "lastStatesOrder": [
            "81d51d96-4b04-42d1-8934-7c502a2ad6a0"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 205,
          "xAlign": "middle",
          "xPos": 561,
          "yAlign": "top",
          "yPos": -391
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "2fd7b053-0317-4558-ad7d-6727a0233a61",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Post-condition Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "70f3055b-9c73-4f51-9aa1-aaa22bff594e"
      },
      "value": "post-condition desc",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "af20812c-a7c7-4b70-bfab-07e4656719d6",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "f9031c58-1fdc-4082-bcc9-348bae7f1752"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 90.5,
          "id": "41638d57-2698-4076-af3d-544b77506e8a",
          "lastStatesOrder": [
            "adeb29bf-dcda-40bc-b46b-580553fc863b"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 223,
          "xAlign": "middle",
          "xPos": 561,
          "yAlign": "top",
          "yPos": -286
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "Main successes scenario ",
      "essence": 1,
      "isAutoFormat": false,
      "isMainThing": false,
      "lid": "64b0ed02-3c2a-4e75-b48e-3999a1a3c730",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "MSS",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "6d60d149-f9e9-40b4-9cf7-55c56ab3ef92"
      },
      "value": "mss",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "fd34f66f-c3d1-471a-845f-a5e25903fd3f",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "e19ca6e7-d749-437d-9b07-966adcc317d9"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 70,
          "id": "b6723f4c-a276-47e6-b1ba-03c4748c9779",
          "lastStatesOrder": [
            "0f71d5b7-be29-448a-bd9d-9edff579e649"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 202,
          "yAlign": "top",
          "yPos": -328
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "e4a38fb0-e96b-476c-9d76-6347c6005454",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Branch",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "e98d0a90-eb1d-4b92-bf53-8cb99f9fd6dd"
      },
      "value": "branch",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "4f243f1a-b30c-427d-97c9-8fecd55ea673",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "aad11386-2b8f-4818-9cb1-5aa07a096e93"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 74,
          "id": "5bd868fd-f38c-4e11-ab88-e930d297fa2e",
          "lastStatesOrder": [
            "c9ca36df-69cd-4e94-9659-84bdcee283e9"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 563,
          "yAlign": "top",
          "yPos": -181
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f11d87b3-12df-4a9e-b74f-f6b34a5a3707",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Exception",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "a0942af2-1017-4535-a16e-44c22d390cf3"
      },
      "value": "exception",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "143c74b2-9454-4767-a93a-a0139676338d",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "6f61b95c-ee38-4a80-8405-49a4e26465f4"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 85.43000000000006,
          "id": "7ca4f8e0-b142-4ef3-b78e-318897a4acdb",
          "lastStatesOrder": [
            "0095c823-cc8f-427d-856f-7ce5c6163f13"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 562,
          "yAlign": "top",
          "yPos": -90
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "f275c5ff-f23d-43ea-b7a6-b6a81afc8f15",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "55422d78-85ac-4491-8bdb-05e0f050b8c7",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "+",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.8195876288659794,
                "offset": 20
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "+",
          "targetVisualElements": [
            {
              "targetVisualElement": "f13340c6-48ba-47d7-a66b-4d2827474d65"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "3d0318dd-f658-4004-b5fd-f2e063321b2e",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "17d9d07a-075d-4680-b2be-89c71ba0f5a7",
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetVisualElements": [
            {
              "targetVisualElement": "f2562ccd-2498-4c46-b263-8940419d751d"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "b6e8a7a1-fa44-45a2-8ab5-fc4aecc936b3",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "e013f9b2-91f2-4922-80b0-e02165f76001",
          "labels": [
            {
              "attrs": {
                "label": {
                  "event": "label:pointerclick",
                  "fill": "black",
                  "fontSize": 16,
                  "text": "+",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 10
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9,
                "offset": {
                  "x": 5,
                  "y": -5
                }
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "+",
          "targetVisualElements": [
            {
              "targetVisualElement": "1286d3ca-04c2-4089-bc3d-c6fbd605e495"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "4356cef9-fc50-4fe0-beee-074d7be887a5",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "eb0514d4-81bc-406c-a5f0-7acb287be10e",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "angle": 0,
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9358709143566405,
                "offset": -25.399999999999977
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElementPort": "80403932-1eee-48d0-8200-5bf49d18d402",
          "targetVisualElements": [
            {
              "targetVisualElement": "318d4e2e-070c-44a2-b394-d57bc4778ad8"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "aa7aaeeb-32e5-4f9e-9e21-df2d95a2438f",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "a081c152-4a48-4545-95c7-92e04cd9248d",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "angle": 0,
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9508224520942851,
                "offset": -30.699999999999935
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "41638d57-2698-4076-af3d-544b77506e8a"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "3d63c365-449b-4f0c-bd54-30039bd1822b",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "70ba3bd9-4cef-4345-ac9d-bde748f255f2",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "+",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9403389830508475,
                "offset": 22.5
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "+",
          "targetVisualElements": [
            {
              "targetVisualElement": "b6723f4c-a276-47e6-b1ba-03c4748c9779"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "3fd35e56-6ff4-4a0b-b757-a34c5b554bed",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "02e45645-61f5-40fe-b982-1fd36407f64b",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9480840543881335,
                "offset": 21
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "5bd868fd-f38c-4e11-ab88-e930d297fa2e"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "198865a9-6bde-49f7-8799-4e547e08f905",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "6222f9e5-473f-4c2c-a7ed-b1411b9972ca",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9468024900962083,
                "offset": 22.5
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "7ca4f8e0-b142-4ef3-b78e-318897a4acdb"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "33c26de7-e1c0-4496-bdc1-886752e66120",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Input Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "f704f2b7-10d6-4a50-852d-699bf6a7a2f6"
      },
      "value": "input",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "026bbf74-18df-474a-a7e0-e4c8013e1e6b",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "1ad9036d-8f81-4607-850d-a7a73dd14a92"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 70,
          "id": "fac07276-20d2-4473-9e5c-4d022a5c9a71",
          "lastStatesOrder": [
            "a9ac3a92-42bc-49e9-9ecb-8349847d86c2"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 203,
          "yAlign": "top",
          "yPos": -245
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "16b5501a-61f1-4567-898d-1cd6f013efff",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Output Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "67ce598b-78ba-41ea-a6c8-8b149fc6ee2a"
      },
      "value": "output",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "17953a14-add2-4b78-a810-9557ab43f258",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "2ae57e21-ef88-4458-9750-2bb945c735ea"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 70,
          "id": "44520822-a4cb-46a2-ac90-548e27d3f4de",
          "lastStatesOrder": [
            "27f17763-d78c-4cd7-ba55-ed8e37cc64db"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 204,
          "yAlign": "top",
          "yPos": -164
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "2a989d9d-a897-438f-8936-8fe15ef32502",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "ee2570d8-db53-44cf-9367-5bbb502710fe",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9562864886393441,
                "offset": 21.430000000000064
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElementPort": "2ef10c5c-fc96-42fb-bd26-a771bc220305",
          "targetVisualElements": [
            {
              "targetVisualElement": "44520822-a4cb-46a2-ac90-548e27d3f4de"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "235ce794-233b-4cf5-aca0-92e996581e48",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "cb83ac4b-5410-407e-aa47-fc7a155b914d",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9527980280065034,
                "offset": 24.34999999999991
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElementPort": "81a888ed-c473-447e-99b4-44deb58d4a8e",
          "targetVisualElements": [
            {
              "targetVisualElement": "fac07276-20d2-4473-9e5c-4d022a5c9a71"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "5dbfdcab-3d72-4ae6-84c0-106e9cd7c448",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "req name",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f13340c6-48ba-47d7-a66b-4d2827474d65",
          "fill": "#FFFFFF",
          "height": 39.93000000000001,
          "id": "45582763-58db-4670-8cb5-0435f4f02245",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 79.86000000000001,
          "xAlign": "middle",
          "xPos": 224.56999999999994,
          "yAlign": "middle",
          "yPos": -662.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "4b58dc39-bfca-4eb7-8d66-7226714acdb3",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "a961ea77-4e55-4a17-9e1d-d30d1a318291",
      "visualElementsParams": [
        {
          "children": [
            "f417bf43-a596-4a52-a29e-096d6fa57966",
            "a89126aa-6fae-401e-8414-4ade53b0a1ce",
            "7b67e00e-12ae-4c60-9e81-578f4c2d8d0e",
            "5918e6a1-db0d-4c59-ba6e-f865ad066700",
            "a8190050-dfac-4646-a757-c890607349b7"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 70,
          "id": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "textHeight": "80%",
          "textWidth": "80%",
          "xAlign": "middle",
          "yAlign": "top"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "2c1deb4c-5ae7-4734-a282-c81f08d30327",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "f417bf43-a596-4a52-a29e-096d6fa57966",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d8802262-d0ce-48c5-90d1-a978c87173f7",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "a89126aa-6fae-401e-8414-4ade53b0a1ce",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0cec83f3-b50d-4de7-958a-d3211e221fbe",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "7b67e00e-12ae-4c60-9e81-578f4c2d8d0e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "98a39e69-5c71-44f8-b4ef-18db52439d8c",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5918e6a1-db0d-4c59-ba6e-f865ad066700",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "154983de-1216-411b-b00f-8fead8d1cd2a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13c6755d-b2a4-4e87-99df-81a1729aa243",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "a8190050-dfac-4646-a757-c890607349b7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "0b9ac93a-1dc5-41ad-a6f0-2744ca8710a3",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "e3121e19-bad8-42c5-a6fb-93a329870f72",
          "sourceVisualElement": "32bb3e7b-f990-4207-981b-84a198425b21",
          "targetVisualElements": [
            {
              "targetVisualElement": "13c6755d-b2a4-4e87-99df-81a1729aa243"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "500790f2-c3a2-428a-a59f-ebf5ca997618",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "req id",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f2562ccd-2498-4c46-b263-8940419d751d",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "4ac0814c-0919-4565-b4b9-8418cd96f108",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 234.5,
          "yAlign": "middle",
          "yPos": -571.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f3cd6641-fd3f-486a-b115-a1f20f43f6f8",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "03ec5ba8-6c69-4942-913b-d326d06c89a6",
      "visualElementsParams": [
        {
          "children": [
            "6ebacddb-383b-4a19-adaf-03496b1b691a",
            "3ace1fae-3e49-405e-b37f-700b66566bb0",
            "6cab848a-b6c9-40f4-a08b-4c08d489f1c8",
            "640a12e9-f64d-4756-bd04-235761da9d2a",
            "26aec141-7dd1-4163-ad7b-f20bc7fddf61"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f85093da-4b57-4c1e-ad34-9a43dc9b833f",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6ebacddb-383b-4a19-adaf-03496b1b691a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "829fa3be-ae48-43d5-b1b1-4e959bcd661e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "3ace1fae-3e49-405e-b37f-700b66566bb0",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "07fbd232-a737-4d4d-845a-c6fe6827bcfb",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6cab848a-b6c9-40f4-a08b-4c08d489f1c8",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f60ae75f-1e41-44b1-a41a-5079b47acc10",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "640a12e9-f64d-4756-bd04-235761da9d2a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "34bb49f9-46c7-4326-86fb-023c67decd2d",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "8b89a833-308e-43e6-b632-3da3ef26353c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "26aec141-7dd1-4163-ad7b-f20bc7fddf61",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "338a777a-d698-4d54-9bd1-a5a452b7a595",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "51e53d08-4d1f-4474-8d50-df3829a37f42",
          "sourceVisualElement": "7a290ac2-8d85-4e0e-a17b-871ccde5a9e8",
          "targetVisualElements": [
            {
              "targetVisualElement": "8b89a833-308e-43e6-b632-3da3ef26353c"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "bc93f92a-bcbd-4eee-aeb6-8527943ce16b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "desc name",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1286d3ca-04c2-4089-bc3d-c6fbd605e495",
          "fill": "#FFFFFF",
          "height": 43.92300000000001,
          "id": "89807df7-1dc8-4bf2-9d54-3a4353ed0926",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 87.84600000000002,
          "xAlign": "middle",
          "xPos": 222.577,
          "yAlign": "middle",
          "yPos": -488
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "15cb41fa-0fd9-42ca-9829-311a5414e6b4",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "38291cc9-6318-4a7b-8226-2d5b1edfbbcd",
      "visualElementsParams": [
        {
          "children": [
            "d41052df-f3ab-4a55-ac15-050ad89f7a9d",
            "611b4092-708e-44ee-a474-a3c5a20dfdc3",
            "f26ff94f-764e-4965-b7a0-4d21bc4c0419",
            "fe25e6b6-6215-4666-9e18-9a3ad3727a17",
            "dc1b17d8-2223-4875-8216-cd61c1f4b664"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "1eac5168-b1ed-4973-9574-b00d11e13dea",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d41052df-f3ab-4a55-ac15-050ad89f7a9d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "fe3a2350-6b02-490a-ae1a-fe5f88726b9a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "611b4092-708e-44ee-a474-a3c5a20dfdc3",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "8fc52ddd-6cd2-451a-98a3-56c842d1cb09",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "f26ff94f-764e-4965-b7a0-4d21bc4c0419",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "9657193c-bdd4-4cd7-9d2a-d97cbe0550aa",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "fe25e6b6-6215-4666-9e18-9a3ad3727a17",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "ad138c2f-2e6e-4a13-aea6-8bc08ad30ae9",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d1bc787e-b389-4d56-80dd-bf0192382e7a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "dc1b17d8-2223-4875-8216-cd61c1f4b664",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "19e74c0f-4681-4a7e-90fe-51d30a90c668",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "6ceceb3d-a544-464f-b23f-61c008e4f645",
          "sourceVisualElement": "df81966e-597c-42f4-ae8d-0da42de496ca",
          "targetVisualElements": [
            {
              "targetVisualElement": "d1bc787e-b389-4d56-80dd-bf0192382e7a"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "81d51d96-4b04-42d1-8934-7c502a2ad6a0",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "pre-condition desc",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "318d4e2e-070c-44a2-b394-d57bc4778ad8",
          "fill": "#FFFFFF",
          "height": 39,
          "id": "d5bedc8b-45c8-4535-a3b7-3d0d5de92298",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 155,
          "xAlign": "middle",
          "xPos": 586,
          "yAlign": "middle",
          "yPos": -350
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "0f828928-78f6-461a-bc81-34312d500571",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "3eb0b7a9-073b-4008-8f16-421c4fb3949f",
      "visualElementsParams": [
        {
          "children": [
            "b90b8332-965e-432f-95f3-91dd78b02ba1",
            "f17a0d59-cc13-4369-8bf5-dc9f48500108",
            "d79b4bef-8685-4439-b39f-d6ce8dd21f2d",
            "a0536d55-7868-4940-9479-06497368edc1",
            "091e3e6e-9508-4aab-8a09-87e03804981d"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f85d50af-65ea-4b77-aa4e-36ab9bad4aac",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b90b8332-965e-432f-95f3-91dd78b02ba1",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a48069b2-35ad-4219-adda-e0379ab808f7",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "f17a0d59-cc13-4369-8bf5-dc9f48500108",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "4bebd097-cc7f-4f7e-8f14-378a33cbf7fd",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d79b4bef-8685-4439-b39f-d6ce8dd21f2d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "6d253b8b-30a0-4304-a2cd-1c24d0cd825a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "a0536d55-7868-4940-9479-06497368edc1",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f646b53d-6940-4374-aefe-20bc2ddb376e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "091e3e6e-9508-4aab-8a09-87e03804981d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "eb18aa3b-a961-42cc-959c-705cf5f6970f",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "67d8c481-8c32-4503-ae85-9cf1e391e681",
          "sourceVisualElement": "03e052f3-b61c-4e31-86f2-79b04816eb7d",
          "targetVisualElements": [
            {
              "targetVisualElement": "af994ee5-cc0d-4210-9bfe-eefdadc24d66"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "adeb29bf-dcda-40bc-b46b-580553fc863b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "post-condition desc",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "41638d57-2698-4076-af3d-544b77506e8a",
          "fill": "#FFFFFF",
          "height": 40,
          "id": "f9031c58-1fdc-4082-bcc9-348bae7f1752",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 163,
          "xAlign": "middle",
          "xPos": 591,
          "yAlign": "middle",
          "yPos": -247
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "70f3055b-9c73-4f51-9aa1-aaa22bff594e",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "2fd7b053-0317-4558-ad7d-6727a0233a61",
      "visualElementsParams": [
        {
          "children": [
            "9137aee9-cdd4-4a7b-bd26-c584c8670a91",
            "3c68fd65-7e1a-46a5-90fe-c45fef25b7e5",
            "79d43224-09f4-43fc-8467-8967fe58798a",
            "9d3061be-ffd5-47ae-805d-c5a49dc5b10f",
            "371dbd0c-e13f-45a7-ac92-008f61012f0f"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f4202ca7-2812-4103-9240-ce22093f85ac",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9137aee9-cdd4-4a7b-bd26-c584c8670a91",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "79488025-4356-44f4-aaa1-bcb98117294b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "3c68fd65-7e1a-46a5-90fe-c45fef25b7e5",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "bea79197-688d-47c7-acb5-b7f6cea173c0",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "79d43224-09f4-43fc-8467-8967fe58798a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b3370af8-efbf-473a-9f4b-c8d5ee3cb7b3",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9d3061be-ffd5-47ae-805d-c5a49dc5b10f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "caa5aaca-44bb-4135-b37c-002d1efb13f4",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "371dbd0c-e13f-45a7-ac92-008f61012f0f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "937338d0-0308-4922-906f-8485b3aeaa86",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "0ed87c30-3cdd-40df-be9d-273830845582",
          "sourceVisualElement": "af20812c-a7c7-4b70-bfab-07e4656719d6",
          "targetVisualElements": [
            {
              "targetVisualElement": "26eca17e-a32c-486b-ba2e-94ca903fd2d5"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0f71d5b7-be29-448a-bd9d-9edff579e649",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "mss",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "b6723f4c-a276-47e6-b1ba-03c4748c9779",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e19ca6e7-d749-437d-9b07-966adcc317d9",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 239.5,
          "yAlign": "middle",
          "yPos": -299.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "c9ca36df-69cd-4e94-9659-84bdcee283e9",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "branch",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "5bd868fd-f38c-4e11-ab88-e930d297fa2e",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "aad11386-2b8f-4818-9cb1-5aa07a096e93",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 600.5,
          "yAlign": "middle",
          "yPos": -148.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0095c823-cc8f-427d-856f-7ce5c6163f13",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "exception",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "7ca4f8e0-b142-4ef3-b78e-318897a4acdb",
          "fill": "#FFFFFF",
          "height": 39.93000000000001,
          "id": "6f61b95c-ee38-4a80-8405-49a4e26465f4",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 79.86000000000001,
          "xAlign": "middle",
          "xPos": 589.5699999999999,
          "yAlign": "middle",
          "yPos": -55.99999999999994
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a9ac3a92-42bc-49e9-9ecb-8349847d86c2",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "input",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "fac07276-20d2-4473-9e5c-4d022a5c9a71",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1ad9036d-8f81-4607-850d-a7a73dd14a92",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 240.5,
          "yAlign": "middle",
          "yPos": -216.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "27f17763-d78c-4cd7-ba55-ed8e37cc64db",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "output",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "44520822-a4cb-46a2-ac90-548e27d3f4de",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "2ae57e21-ef88-4458-9750-2bb945c735ea",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 241.5,
          "yAlign": "middle",
          "yPos": -135.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "a3934539-c014-4fda-a8a0-62a0090d9cda",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Actor Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "8a9554c3-9c08-4cfc-b3ca-5a2c26c67135"
      },
      "value": "actor name",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "d6163c98-34f2-4f46-8e78-e2997b6a7ff5",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "cd5c0651-274a-4505-8f61-47c48e085641"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 85.923,
          "id": "d4461241-e469-44e4-8585-f049f883b5ab",
          "lastStatesOrder": [
            "951591e0-0a4b-416e-94d4-a451aa12cb41"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 135,
          "xAlign": "middle",
          "xPos": 200,
          "yAlign": "top",
          "yPos": -422
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "951591e0-0a4b-416e-94d4-a451aa12cb41",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "actor name",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d4461241-e469-44e4-8585-f049f883b5ab",
          "fill": "#FFFFFF",
          "height": 43.92300000000001,
          "id": "cd5c0651-274a-4505-8f61-47c48e085641",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 87.84600000000002,
          "xAlign": "middle",
          "xPos": 223.577,
          "yAlign": "middle",
          "yPos": -391.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "8a9554c3-9c08-4cfc-b3ca-5a2c26c67135",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "a3934539-c014-4fda-a8a0-62a0090d9cda",
      "visualElementsParams": [
        {
          "children": [
            "05ed72e1-af7d-49b1-8657-bed17497f24a",
            "e2bb1d1e-a789-4e1c-a7f0-135ed6708e1c",
            "e68a3612-d569-48a3-8f5a-f7a5b4edad99",
            "51e0715e-187e-4a9f-98b3-ec3240125542",
            "4151cf93-229c-4200-8967-4802565dff86"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "3fa178a0-06c5-43a1-82f8-7df892ab6c7f",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "05ed72e1-af7d-49b1-8657-bed17497f24a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "7cc24af2-2e98-4133-ad0b-da325e56617e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e2bb1d1e-a789-4e1c-a7f0-135ed6708e1c",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b3eba4d3-88f9-41ed-bb6e-b70c4380d814",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e68a3612-d569-48a3-8f5a-f7a5b4edad99",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "9fb82658-a879-4a54-aa9a-afaf96589789",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "51e0715e-187e-4a9f-98b3-ec3240125542",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "72c7b276-8335-4ee2-b62d-7365c0cb3126",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9935a578-ee31-4224-8cc3-2d551e7a43bf",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "4151cf93-229c-4200-8967-4802565dff86",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "0f1c7134-2292-434d-88ed-ab17406b66b4",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "e184958c-dffe-4b6c-881a-3baa64d24cd5",
          "sourceVisualElement": "d6163c98-34f2-4f46-8e78-e2997b6a7ff5",
          "targetVisualElements": [
            {
              "targetVisualElement": "9935a578-ee31-4224-8cc3-2d551e7a43bf"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "9d60e453-2a9d-4ac5-847f-664c84081fda",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "1eda4a20-c049-40b8-a34d-b6187e1885a2",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "+",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9109227917278571,
                "offset": 21.370000000000005
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "+",
          "targetVisualElementPort": "112cba37-51b6-4040-8c49-bb62d8709da6",
          "targetVisualElements": [
            {
              "targetVisualElement": "d4461241-e469-44e4-8585-f049f883b5ab"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "dd28ef77-fb1f-4dad-a0e2-2fc89a008e00",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Trigger Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "2d2fa4d1-53d2-4884-b790-9e0579381bd4"
      },
      "value": "trigger name",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "c7ad0e5f-9c3a-49f9-9937-6561f0d95a1f",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "b4886b13-459d-4b6b-b011-d9fb6c5ece03"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 89.577,
          "id": "f8dfad66-f2f0-4db0-a941-f0985aac9a9f",
          "lastStatesOrder": [
            "7daa6980-03d8-4d87-a495-c4d2d63d81a3"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 183,
          "xAlign": "middle",
          "xPos": 562,
          "yAlign": "top",
          "yPos": -497
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "7daa6980-03d8-4d87-a495-c4d2d63d81a3",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "trigger name",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f8dfad66-f2f0-4db0-a941-f0985aac9a9f",
          "fill": "#FFFFFF",
          "height": 33,
          "id": "b4886b13-459d-4b6b-b011-d9fb6c5ece03",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 107,
          "xAlign": "middle",
          "xPos": 600,
          "yAlign": "middle",
          "yPos": -451.923
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "2d2fa4d1-53d2-4884-b790-9e0579381bd4",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "dd28ef77-fb1f-4dad-a0e2-2fc89a008e00",
      "visualElementsParams": [
        {
          "children": [
            "c01065f5-2a49-4ce1-9378-ac28dfd7fd1c",
            "c687bf99-57cb-4b1f-978d-c6fe81dcf148",
            "5dff20e7-7e28-4a77-b896-ffe6ca2e4a54",
            "1bcdfde2-5687-439e-8fda-3ce154d8647b",
            "1771184f-a967-4a96-8054-8e406d871366"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "52edd185-3f09-48df-8d05-5111976bc897",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "c01065f5-2a49-4ce1-9378-ac28dfd7fd1c",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b57b2df7-5712-42c7-acdf-8ce3ed1cb3eb",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "c687bf99-57cb-4b1f-978d-c6fe81dcf148",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "cb5095f2-c1d1-431b-9724-42ebd133d2d0",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5dff20e7-7e28-4a77-b896-ffe6ca2e4a54",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "da4a87cd-26a9-4168-943c-649cf5b7ea97",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1bcdfde2-5687-439e-8fda-3ce154d8647b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "3df26b88-9232-4618-a52e-51496e406939",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1771184f-a967-4a96-8054-8e406d871366",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "821fc7b7-d20c-4bce-a99b-315cdf50d8d1",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "04b145c7-fc4a-4b17-91af-60da8624f8d1",
          "sourceVisualElement": "c7ad0e5f-9c3a-49f9-9937-6561f0d95a1f",
          "targetVisualElements": [
            {
              "targetVisualElement": "53a59c25-9f26-4ce6-9642-7f7ba0b2c559"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "534cde51-3115-4b68-8a17-111c524d9d56",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "903869a3-bd83-4a69-87cb-757639e5692d",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 15
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "angle": 0,
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.9124980751057079,
                "offset": -24.487639831141554
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "f8dfad66-f2f0-4db0-a941-f0985aac9a9f"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "6d60d149-f9e9-40b4-9cf7-55c56ab3ef92",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "64b0ed02-3c2a-4e75-b48e-3999a1a3c730",
      "visualElementsParams": [
        {
          "children": [
            "98dbf629-c76e-41d1-8f62-38b8d8c9cb9f",
            "8dcf8a90-8a5d-4a77-a7ab-f976f245b18d",
            "8396c1a4-e41f-4f3f-a467-c4e0b73bfd54",
            "1b914aac-1edb-48b7-97e7-c0cdc33a567f",
            "86358635-0c33-4172-8d6a-2a33c40e0cff"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "6d8d5844-7e4f-4dc7-af1a-22593b287fd6",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "98dbf629-c76e-41d1-8f62-38b8d8c9cb9f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "2e297ac3-3f46-45d3-b333-790ca2e74fd8",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "8dcf8a90-8a5d-4a77-a7ab-f976f245b18d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "1be229db-6a06-4a46-9453-782a8e219311",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "8396c1a4-e41f-4f3f-a467-c4e0b73bfd54",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "edc934c6-5d04-461e-a989-05b8eb0f4328",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1b914aac-1edb-48b7-97e7-c0cdc33a567f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a8bf08b2-70b1-4141-9308-d1e7ea4bebe3",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6d32904b-0345-40f5-85b4-239bb41860dd",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "86358635-0c33-4172-8d6a-2a33c40e0cff",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "bd5d1fcc-cb46-4ab5-9cda-321b8dff25db",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "ebef0d39-419d-4d67-bdb1-310846be1f9f",
          "sourceVisualElement": "fd34f66f-c3d1-471a-845f-a5e25903fd3f",
          "targetVisualElements": [
            {
              "targetVisualElement": "6d32904b-0345-40f5-85b4-239bb41860dd"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "e98d0a90-eb1d-4b92-bf53-8cb99f9fd6dd",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "e4a38fb0-e96b-476c-9d76-6347c6005454",
      "visualElementsParams": [
        {
          "children": [
            "5eafc1e9-b4d5-4844-8538-48ed9131d81b",
            "f6842ddd-3385-450d-bfc4-154565dee700",
            "d170ec15-477b-45fd-8052-34702b9b14c2",
            "f84f9cc6-8a2e-40f9-9992-c960415f7e78",
            "1e206e3f-86ae-4e9e-8df0-8adad0463c79"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "4f706ab0-6073-43fd-8bc3-04cf68047829",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5eafc1e9-b4d5-4844-8538-48ed9131d81b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "eddace8e-ae33-4a12-918f-5dc3d56e31f8",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "f6842ddd-3385-450d-bfc4-154565dee700",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "717cbdfd-11fb-48c8-b42e-f036d4a94e8e",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d170ec15-477b-45fd-8052-34702b9b14c2",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "92aa971b-83a1-4992-be8c-de9b344c0969",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "f84f9cc6-8a2e-40f9-9992-c960415f7e78",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "e07eca57-6cc0-4e4d-9756-49ed069fef7b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1e206e3f-86ae-4e9e-8df0-8adad0463c79",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "c2edfce1-6cc7-48de-acae-f575449d609e",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "e33125fe-c3dd-4994-8641-6f9a7d34590e",
          "sourceVisualElement": "4f243f1a-b30c-427d-97c9-8fecd55ea673",
          "targetVisualElements": [
            {
              "targetVisualElement": "e00d9c36-2f8e-4b66-97c0-90348a1e1845"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "a0942af2-1017-4535-a16e-44c22d390cf3",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "f11d87b3-12df-4a9e-b74f-f6b34a5a3707",
      "visualElementsParams": [
        {
          "children": [
            "805a6d72-58b4-4d8f-ae37-16021c74b497",
            "e94f6534-0fca-40d6-99d5-33398b31253e",
            "76bf8c67-4194-44ca-a220-a1c67fc6fb6e",
            "9e219ab3-c8e1-4114-bd65-dde3e885a3f5",
            "cb7713b6-e9c3-4668-8dfd-70d6e7a951fb"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "9ccb8501-d11c-4981-9979-0572520cb7f5",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "805a6d72-58b4-4d8f-ae37-16021c74b497",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "ba58d764-0937-49d4-9e1c-e01021c0204a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e94f6534-0fca-40d6-99d5-33398b31253e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0229f7d0-1fda-4525-8e67-1ab9c5f9eda2",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "76bf8c67-4194-44ca-a220-a1c67fc6fb6e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "6dfa4597-4e05-4b06-b984-1345ca0624cb",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9e219ab3-c8e1-4114-bd65-dde3e885a3f5",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a0226693-de05-4b5a-b7b5-ee1994ee90e2",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "f9952811-efdb-4e52-a77c-33ce04146e33",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "cb7713b6-e9c3-4668-8dfd-70d6e7a951fb",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "ab192a93-12b9-4223-986b-dc488191b385",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "d5e25434-3ef2-49b7-beb9-503477cfe1d1",
          "sourceVisualElement": "143c74b2-9454-4767-a93a-a0139676338d",
          "targetVisualElements": [
            {
              "targetVisualElement": "f9952811-efdb-4e52-a77c-33ce04146e33"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f704f2b7-10d6-4a50-852d-699bf6a7a2f6",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "33c26de7-e1c0-4496-bdc1-886752e66120",
      "visualElementsParams": [
        {
          "children": [
            "d79bab83-af43-454f-80b7-82905c1e7078",
            "be9b959e-a7ca-4a57-ae46-94daa3bf91fc",
            "2927ff4d-ca2f-4987-8773-b4fb65063ef7",
            "006a60e7-531b-4f0a-85ec-f9c129173089",
            "3618e6ed-fa4b-43c5-aa01-1135b72de808"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a8892773-989f-4567-abdc-ccae7b19eec6",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d79bab83-af43-454f-80b7-82905c1e7078",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "90d0e120-878a-451e-9e26-c8af37922547",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "be9b959e-a7ca-4a57-ae46-94daa3bf91fc",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "807a9f4c-e421-4c3f-84d8-0f1bf935de90",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "2927ff4d-ca2f-4987-8773-b4fb65063ef7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f5173b29-44e6-4a2c-850f-a2e742f8d8f8",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "006a60e7-531b-4f0a-85ec-f9c129173089",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "1028b4cf-80e7-4538-8380-8b5daf3aadeb",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "3618e6ed-fa4b-43c5-aa01-1135b72de808",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "3cfd0a5c-4b26-4df4-b12b-4b4c34b9607b",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "30ed5db5-b530-457e-a0c5-a91d5efac2f7",
          "sourceVisualElement": "026bbf74-18df-474a-a7e0-e4c8013e1e6b",
          "targetVisualElements": [
            {
              "targetVisualElement": "2ace3a07-da05-4012-9a9d-76fc7256e5fe"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "67ce598b-78ba-41ea-a6c8-8b149fc6ee2a",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "16b5501a-61f1-4567-898d-1cd6f013efff",
      "visualElementsParams": [
        {
          "children": [
            "688506a9-164a-4b79-9b88-1f90927645ba",
            "7d2ab9e9-1de3-460b-a14d-9616e53df428",
            "cb2655c1-7043-4093-bf88-6d3eb5abdf8f",
            "8672caf4-31c5-4ecd-89b2-e7f2266e554a",
            "80641cf1-f14c-4925-983b-abfb65de15ae"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "62aca179-4d36-48fc-894f-ad3dfec5c684",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "688506a9-164a-4b79-9b88-1f90927645ba",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d5a7c629-e697-458c-87d7-2bee57fcd3a5",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "7d2ab9e9-1de3-460b-a14d-9616e53df428",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "56f2bbef-22db-4677-8a97-e29e5420bc72",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "cb2655c1-7043-4093-bf88-6d3eb5abdf8f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "040481d0-6a44-439a-9b56-9155ae19655a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "8672caf4-31c5-4ecd-89b2-e7f2266e554a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "bb21a35c-9b73-4d79-b7b6-7a2743326d1e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1857b70e-02cc-4f07-a047-e101ec62f43c",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "80641cf1-f14c-4925-983b-abfb65de15ae",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "fc1505f6-eb0c-428f-9c2f-8df09f39d9e3",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "6e718310-a482-481f-ac5d-3a1ba0d2d8d6",
          "sourceVisualElement": "17953a14-add2-4b78-a810-9557ab43f258",
          "targetVisualElements": [
            {
              "targetVisualElement": "1857b70e-02cc-4f07-a047-e101ec62f43c"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f0ad2ba5-f789-4f97-b0dd-58891e1df8a1",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Message Rate Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "97df4b8f-5a6d-4792-a183-1235244abec3"
      },
      "value": "rate",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "8ccba107-c9f5-43bb-bf9a-55bc9820e354",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "97ea6f87-2ec5-4d2b-9138-4608555705cb"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 74,
          "id": "73e27e00-2b81-4f27-bceb-3ecccef61e5e",
          "lastStatesOrder": [
            "608776d9-185e-48bc-a15f-6d0c9a78234f"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 200,
          "xAlign": "middle",
          "xPos": 140,
          "yAlign": "top",
          "yPos": -84
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "608776d9-185e-48bc-a15f-6d0c9a78234f",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "rate",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "73e27e00-2b81-4f27-bceb-3ecccef61e5e",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "97ea6f87-2ec5-4d2b-9138-4608555705cb",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "xPos": 210,
          "yAlign": "middle",
          "yPos": -51.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "97df4b8f-5a6d-4792-a183-1235244abec3",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "f0ad2ba5-f789-4f97-b0dd-58891e1df8a1",
      "visualElementsParams": [
        {
          "children": [
            "b6a9230f-f85d-4fcf-8e94-41d3752c926e",
            "bda94790-88f6-4065-af10-690e2f827db7",
            "4d1f793b-74bb-4161-a327-6ba68c3d1196",
            "9cd8fcc2-41a9-4727-8edd-9413e57f4328",
            "46b92cba-2eaa-4bae-b5c5-85b8ba317c0a"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b8ab8100-02bd-4950-8f43-f796c75faf9b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b6a9230f-f85d-4fcf-8e94-41d3752c926e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "abeffac4-94ee-4eef-99c0-b3900d336640",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "bda94790-88f6-4065-af10-690e2f827db7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b68d16a9-6ad1-4ed6-a2d8-5271aec0148d",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "4d1f793b-74bb-4161-a327-6ba68c3d1196",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "900dc4d2-f5ce-4a98-aa21-e18620f9c588",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9cd8fcc2-41a9-4727-8edd-9413e57f4328",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0d99d136-60af-45fd-aa9d-bc2a55597c0c",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "46b92cba-2eaa-4bae-b5c5-85b8ba317c0a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "0ee17072-bfc0-4c38-ab0b-050a53e75389",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "8c99a025-b92e-4b9e-99a8-6c64838b9d0f",
          "sourceVisualElement": "8ccba107-c9f5-43bb-bf9a-55bc9820e354",
          "targetVisualElements": [
            {
              "targetVisualElement": "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "d43e6c0e-5ef2-4c40-b80f-b0d305bc1f7f",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Protocol Type Set",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "f9520ab3-7158-4d24-b337-7ced5128b5a2"
      },
      "value": "protocol",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "1413ddc0-a39b-47b6-8266-810163741480",
          "statesArrangement": 1,
          "children": []
        },
        {
          "children": [
            "39292d20-b370-4cde-9d6b-f43028e18fe4"
          ],
          "descriptionStatus": " ",
          "fill": "#FFFFFF",
          "foldedUnderThing": {
            "isFolded": false
          },
          "height": 78,
          "id": "85dcc87a-2158-4858-89ce-4bd72178cffd",
          "lastStatesOrder": [
            "0e85b8c5-dc1a-4b68-8e32-8c4c675e6ab4"
          ],
          "refX": 0.5,
          "refY": 0.1,
          "statesArrangement": 1,
          "strokeColor": "#70E483",
          "strokeWidth": 2,
          "textAnchor": "middle",
          "textColor": "#000002",
          "textFontFamily": "Arial",
          "textFontSize": 14,
          "textFontWeight": 600,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 195,
          "xAlign": "middle",
          "xPos": 146,
          "yAlign": "top",
          "yPos": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0e85b8c5-dc1a-4b68-8e32-8c4c675e6ab4",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "protocol",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "85dcc87a-2158-4858-89ce-4bd72178cffd",
          "fill": "#FFFFFF",
          "height": 33,
          "id": "39292d20-b370-4cde-9d6b-f43028e18fe4",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 66,
          "xAlign": "middle",
          "xPos": 210.5,
          "yAlign": "middle",
          "yPos": 34.5
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f9520ab3-7158-4d24-b337-7ced5128b5a2",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "d43e6c0e-5ef2-4c40-b80f-b0d305bc1f7f",
      "visualElementsParams": [
        {
          "children": [
            "9ee4fe70-e246-4a0a-be6c-0f8291bc1090",
            "2bbfbff9-6313-48f1-b14e-3d040d4e635b",
            "cb39e9d4-4f4a-434a-99af-867534d035e3",
            "6d4d9bc8-bc0a-4545-a529-e1357396a309",
            "b300841f-37db-43e9-9423-26ddc8c8a401"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "140d395e-e96f-4bf2-965c-afa7ac2d684e",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9ee4fe70-e246-4a0a-be6c-0f8291bc1090",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "654b8cce-3308-4e20-acdf-c93e84e672b6",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "2bbfbff9-6313-48f1-b14e-3d040d4e635b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "bb8aa164-6ef9-4051-ba2f-55d397d5b6f7",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "cb39e9d4-4f4a-434a-99af-867534d035e3",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d6c4c2a6-413d-4bb9-af5d-76b73e2d293a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6d4d9bc8-bc0a-4545-a529-e1357396a309",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "aced33e4-c2f6-41d3-a771-1eb3da67219d",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b300841f-37db-43e9-9423-26ddc8c8a401",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "3881dd25-9605-44be-b8b8-397734d66011",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "9c66106a-dbd7-43fe-b9ff-0f711b762f89",
          "sourceVisualElement": "1413ddc0-a39b-47b6-8266-810163741480",
          "targetVisualElements": [
            {
              "targetVisualElement": "1382cb0c-f3e8-4228-8e05-08c2b86b7806"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "5ca0ef92-9915-4d05-9d15-d26799283555",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "d67413ca-e193-41b7-907e-3034760a7358",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 10
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "angle": 0,
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.969991173874669,
                "offset": 15
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "73e27e00-2b81-4f27-bceb-3ecccef61e5e"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "024dd9f5-dcb8-43ea-9014-f235fe3e2f7e",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "30f16fef-4ad4-48dc-a785-1d96d8f4adf9",
          "labels": [
            {
              "attrs": {
                "label": {
                  "fill": "black",
                  "fontSize": 16,
                  "text": "?",
                  "textAnchor": "middle",
                  "textVerticalAnchor": "middle",
                  "y": 10
                }
              },
              "markup": [
                {
                  "selector": "label",
                  "tagName": "text"
                }
              ],
              "position": {
                "angle": 0,
                "args": {
                  "ensureLegibility": true,
                  "keepGradient": true
                },
                "distance": 0.975,
                "offset": 23
              }
            }
          ],
          "sourceVisualElement": "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
          "strokeColor": "#586D8C",
          "strokeWidth": "2",
          "symbolPos": [
            469,
            -708
          ],
          "targetMultiplicity": "?",
          "targetVisualElements": [
            {
              "targetVisualElement": "85dcc87a-2158-4858-89ce-4bd72178cffd"
            }
          ],
          "textColor": "black",
          "textFontFamily": "ariel",
          "textFontSize": "12",
          "textFontWeight": "normal"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "ded01e03-c16c-4da7-821d-8ba573eb81d5",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Input",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "5c8f07d0-ed7d-43bf-b7ed-6b2c35ccc69a"
      },
      "value": "test input",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "0771480d-44af-4032-9fc4-3c20d7133f27",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "54351431-3606-4fe2-a696-0a48dc306246",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Expected Output",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "7e1a3e66-d3ac-41fd-a0fc-c40e4fc9eb9e"
      },
      "value": "test expected out",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "53e37da6-651d-48ee-af5e-ddf108b5bdeb",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "ae151d97-bcf9-4413-a26e-abdc6c42061b",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Name",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "ea18778c-872c-45bf-933c-632c7ad91c68"
      },
      "value": "test name",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "60b38a99-6cb3-4e33-8752-01fd6e79e4c5",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "ea18778c-872c-45bf-933c-632c7ad91c68",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "ae151d97-bcf9-4413-a26e-abdc6c42061b",
      "visualElementsParams": [
        {
          "children": [
            "10334f5c-1eea-4aca-93e3-3e768a4b6b23",
            "2f2b59cc-5eec-42df-9115-3b01849a69a7",
            "b1852802-48f3-4e5d-8051-0a3c909dcc1a",
            "c8be8d13-fa11-47b8-b3a9-220ac8a1e309",
            "aa4db0bf-3921-4af4-a045-f828490bf31e"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a1e8246b-0501-4a71-8f97-dddbe4037d4c",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "10334f5c-1eea-4aca-93e3-3e768a4b6b23",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "db8587e8-f740-4209-b200-8063aee71b99",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "2f2b59cc-5eec-42df-9115-3b01849a69a7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "12628ea3-4038-45d1-a088-d038f143121a",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b1852802-48f3-4e5d-8051-0a3c909dcc1a",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "fa2de587-9270-43b3-b8a8-2316e3a9aba1",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "c8be8d13-fa11-47b8-b3a9-220ac8a1e309",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "99d9a95b-1947-41fc-a95b-4947a9c87e08",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cb5320fc-c96f-4ada-ba20-91425935cc67",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "aa4db0bf-3921-4af4-a045-f828490bf31e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "21378b56-7f20-4bba-8386-be3999ec331f",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "f1825c26-021e-4c42-9eff-a96d07e7768f",
          "sourceVisualElement": "60b38a99-6cb3-4e33-8752-01fd6e79e4c5",
          "targetVisualElements": [
            {
              "targetVisualElement": "cb5320fc-c96f-4ada-ba20-91425935cc67"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": false,
      "isMainThing": false,
      "lid": "67f192f5-326f-430a-a8e3-5c4b9ac42bc2",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "ID",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "00421024-735d-4fb3-82ce-94b8454f8ad0"
      },
      "value": "test id",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "d61654ff-9089-416f-ba08-7e0e05c9ca26",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "00421024-735d-4fb3-82ce-94b8454f8ad0",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "67f192f5-326f-430a-a8e3-5c4b9ac42bc2",
      "visualElementsParams": [
        {
          "children": [
            "70a7a5bd-98b1-42b4-b7c8-24a4bdd48ff7",
            "db25f11a-3c61-4714-b32b-e6accb87432f",
            "e7805dd5-ceb2-4fb8-972e-126cb9ab82be",
            "7f800cc0-4166-4ea2-88f9-3b1785ad2feb",
            "884340ee-40f9-4469-8cdb-d0cb2fa210f8"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "cf1dcf1a-f649-478f-86f2-87a19292ab1a",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "70a7a5bd-98b1-42b4-b7c8-24a4bdd48ff7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d52e81ce-1b5b-48de-99be-5d7b89ca4161",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "db25f11a-3c61-4714-b32b-e6accb87432f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f3928882-9b3f-4101-9b1c-e95dad27a288",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e7805dd5-ceb2-4fb8-972e-126cb9ab82be",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "bbe43bad-39a8-4a36-b9cd-884b32ba3f37",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "7f800cc0-4166-4ea2-88f9-3b1785ad2feb",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "54011b34-fe70-47bc-bc4a-b564deb730b9",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "884340ee-40f9-4469-8cdb-d0cb2fa210f8",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "83909aad-c870-4687-9cc3-30e1d0e8ead0",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "d4227db0-9a0f-4310-860b-a375443549fd",
          "sourceVisualElement": "d61654ff-9089-416f-ba08-7e0e05c9ca26",
          "targetVisualElements": [
            {
              "targetVisualElement": "a0c5d62a-b831-48b3-9ae0-48d9af6b604a"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "03d45356-049d-42a4-9761-5c0b155cd4c2",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Purpose",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "e1a566f3-0767-470b-bc04-4a5754852ba3"
      },
      "value": "test purpose",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "9cf4a1d1-1ba7-4d29-a100-a4c2982f3fb9",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "e1a566f3-0767-470b-bc04-4a5754852ba3",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "03d45356-049d-42a4-9761-5c0b155cd4c2",
      "visualElementsParams": [
        {
          "children": [
            "d4de79ec-7581-4ae7-ab1e-4d99b04ec35b",
            "bd66640b-2001-413b-8e20-245a244bf6ed",
            "b0897c18-c93d-445a-97e5-f1bb093786ac",
            "2ede544e-f071-452d-bb08-2318c236c07c",
            "0ca9b725-1916-4987-9011-86580a3b6a76"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d7f4f586-45d9-4676-8441-64fa91efa19b",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d4de79ec-7581-4ae7-ab1e-4d99b04ec35b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b4ea9ccc-91f6-49e4-9d18-a16198d594a0",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "bd66640b-2001-413b-8e20-245a244bf6ed",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "cfaf3d68-ecc4-4e61-90b6-be840609e820",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b0897c18-c93d-445a-97e5-f1bb093786ac",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "3cb94af2-311c-400b-b5f6-b6d120c588f3",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "2ede544e-f071-452d-bb08-2318c236c07c",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "08d742d7-336b-4cf2-9f22-5941bc8b1dde",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "0ca9b725-1916-4987-9011-86580a3b6a76",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "cbc7453d-9e2a-48f3-ae33-a59b851a5b30",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "e26388fa-4525-43d5-b7ec-070de72fda75",
          "sourceVisualElement": "9cf4a1d1-1ba7-4d29-a100-a4c2982f3fb9",
          "targetVisualElements": [
            {
              "targetVisualElement": "ba90f1c1-f363-4db0-ba35-b3f1e7241079"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "2294daa4-08c2-4ae3-97a7-6160969c818d",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Traceability",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "2a4ef9c4-ee70-466e-a73b-30c5f3a503c9"
      },
      "value": "test trace",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "5c347f3f-30b2-4f1c-b29c-0c89a19cadc6",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "2a4ef9c4-ee70-466e-a73b-30c5f3a503c9",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "2294daa4-08c2-4ae3-97a7-6160969c818d",
      "visualElementsParams": [
        {
          "children": [
            "59854c2b-9e2d-4201-8c12-10434b4b6457",
            "e018c532-826d-49c1-8f46-9096b1b52246",
            "06f79b1a-4a66-4a55-879f-57bad710cfa0",
            "cd11c840-0a1d-4a8f-aa89-319b378995df",
            "a6d31f71-81fa-4c2d-93dd-05b323962956"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "eae8fbe9-4d09-4b89-8df0-2e6abca123b9",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "59854c2b-9e2d-4201-8c12-10434b4b6457",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "77f84642-af55-48a1-aef5-7fdd72b0baef",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e018c532-826d-49c1-8f46-9096b1b52246",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "efc0a2d6-4145-4399-bbaa-fa33aebaf1a8",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "06f79b1a-4a66-4a55-879f-57bad710cfa0",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f5d11ed1-fea2-475f-8a74-d136b16cae9f",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "cd11c840-0a1d-4a8f-aa89-319b378995df",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0cb8ceb4-44fc-4503-b391-71fbe27e5ec6",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "6263a6cd-028f-48ea-b413-25d997fe763f",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "a6d31f71-81fa-4c2d-93dd-05b323962956",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "49425529-843c-409d-abe9-1a60f5424010",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "af2cb907-0037-41e0-a842-63fd27772c76",
          "sourceVisualElement": "5c347f3f-30b2-4f1c-b29c-0c89a19cadc6",
          "targetVisualElements": [
            {
              "targetVisualElement": "6263a6cd-028f-48ea-b413-25d997fe763f"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "5b1f3f3d-0e7c-4f30-93a0-5cf31db2c4c4",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Preparations",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "065f733c-6ab1-47be-a10c-e9f896277473"
      },
      "value": "test pre conditions",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "03190dc5-9965-4162-ac65-c15cf2c6362c",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "065f733c-6ab1-47be-a10c-e9f896277473",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "5b1f3f3d-0e7c-4f30-93a0-5cf31db2c4c4",
      "visualElementsParams": [
        {
          "children": [
            "5be39352-994e-46bf-b37a-b0ca17987a96",
            "861513c1-9e8a-48d7-8fc6-c81e8f1abf1b",
            "7f8ba660-44e0-4ed7-8627-5c57ed6d44ab",
            "552e82a3-38a9-4639-8c4e-e7a9fe1560b7",
            "1e9b9b8a-ff15-4fa9-92a4-c794dbf235e3"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "ab8eae74-b442-4eed-8ecd-3c59bfb7e888",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5be39352-994e-46bf-b37a-b0ca17987a96",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "09b086f6-d52e-417c-8349-721ba65e98f7",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "861513c1-9e8a-48d7-8fc6-c81e8f1abf1b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b28e6e8f-4b67-4eaf-9760-55485248237a",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "7f8ba660-44e0-4ed7-8627-5c57ed6d44ab",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "7d9b5fab-ec86-4623-8853-d976d115e8a1",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "552e82a3-38a9-4639-8c4e-e7a9fe1560b7",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0156b9e1-85f6-4d85-9178-20f4988c7d27",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1e9b9b8a-ff15-4fa9-92a4-c794dbf235e3",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "fd77a326-8a78-43df-a725-e86da451b9a9",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "699023b9-ac31-49c2-94bd-ad84f665c740",
          "sourceVisualElement": "03190dc5-9965-4162-ac65-c15cf2c6362c",
          "targetVisualElements": [
            {
              "targetVisualElement": "47accb1d-846e-49de-bec7-d3ebfbe6f79a"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "cf1bc470-8c68-4b36-bb6e-7ff730844f8f",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Assumptions & Constrains",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "3e110624-19f8-4b04-ae5f-8dc58666ec71"
      },
      "value": "test assumptions",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "4edb6fea-1d05-4227-aa9c-781c8624d3d9",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "3e110624-19f8-4b04-ae5f-8dc58666ec71",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "cf1bc470-8c68-4b36-bb6e-7ff730844f8f",
      "visualElementsParams": [
        {
          "children": [
            "b9d42ee6-e655-48b3-8dd8-c454620a10b5",
            "664402a3-ecc6-4742-a0ea-ef941b0d198f",
            "bd8a4200-d359-4aa3-ba7a-1ece1b425a84",
            "b444393c-7603-4c00-91b6-45bc09566df2",
            "36a11742-0faa-4e76-995c-2e2325b69b15"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "d47c6035-74e2-4921-85df-d163a1b44a4f",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b9d42ee6-e655-48b3-8dd8-c454620a10b5",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "98592ddf-e051-4552-a056-858f221b1fac",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "664402a3-ecc6-4742-a0ea-ef941b0d198f",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0aa6c405-2501-4fcb-8f58-53f4a56e284a",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "bd8a4200-d359-4aa3-ba7a-1ece1b425a84",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "be4f4dcd-5ee6-4642-ba40-2ad02a7449e1",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "b444393c-7603-4c00-91b6-45bc09566df2",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "49d100cd-b8c5-4042-a054-0932dd2f3cc7",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "36a11742-0faa-4e76-995c-2e2325b69b15",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "2c5a7801-bd2e-41e6-b3c0-fadc79a0f8dc",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "0ed30591-8647-4cdf-b53a-effc9d07a2ea",
          "sourceVisualElement": "4edb6fea-1d05-4227-aa9c-781c8624d3d9",
          "targetVisualElements": [
            {
              "targetVisualElement": "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "17bffa6d-709c-42ab-84d0-b6308bc83bbb",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Special Instructions",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "f4716413-35c9-4729-833e-be3c1b64d6f0"
      },
      "value": "test executable instruct",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "f931a28a-400d-44d8-8b89-c47cc3d6a3f2",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "f4716413-35c9-4729-833e-be3c1b64d6f0",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "17bffa6d-709c-42ab-84d0-b6308bc83bbb",
      "visualElementsParams": [
        {
          "children": [
            "637e72f5-98c8-497a-9527-9eb9fd2afd8d",
            "49fbbfed-0639-4c83-800a-5543e7ef5432",
            "6b8d050e-a1cc-46c1-b338-232736ae5901",
            "82180b26-62ff-46f8-91b3-44d78e09fc75",
            "6c048091-15d0-4838-80f9-08aa59077d7e"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "2da129b1-72b3-40b4-91d6-72eb2a5b9a40",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "637e72f5-98c8-497a-9527-9eb9fd2afd8d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "e3503aba-cf95-4e51-9e59-fd2ce1f1a9c9",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "49fbbfed-0639-4c83-800a-5543e7ef5432",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "a07652cb-8b1c-4437-90a1-d474f3c612c7",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6b8d050e-a1cc-46c1-b338-232736ae5901",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "b9395508-5d6a-47e6-9212-d50826cee85c",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "82180b26-62ff-46f8-91b3-44d78e09fc75",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "cfd858f9-2d32-4c32-85ea-7acc680700cb",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "13f359c8-f215-43ac-8000-dce7bc6821ac",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6c048091-15d0-4838-80f9-08aa59077d7e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "0906bc58-a1d4-474b-871d-29d82a291dfc",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "f63fb4b4-f120-4e9e-ad6a-0d0eb2665739",
          "sourceVisualElement": "f931a28a-400d-44d8-8b89-c47cc3d6a3f2",
          "targetVisualElements": [
            {
              "targetVisualElement": "13f359c8-f215-43ac-8000-dce7bc6821ac"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "affiliation": 0,
      "alias": "",
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "9e3634f0-4047-4cf8-be39-a53fa1e18958",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Action",
      "units": "",
      "validation": {
        "attribute": {
          "range": "\"*\"",
          "type": 0
        },
        "valueTypeElementId": "3acc9761-52ba-436d-9cd5-54ae256fc7e0"
      },
      "value": "test action",
      "valueType": 1,
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "f065ddc8-ef9f-4092-bce4-e1e8cfec8d25",
          "statesArrangement": 1,
          "children": []
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "3acc9761-52ba-436d-9cd5-54ae256fc7e0",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "9e3634f0-4047-4cf8-be39-a53fa1e18958",
      "visualElementsParams": [
        {
          "children": [
            "110fa59e-5e51-44a2-96f8-7d6cd63b35e5",
            "5c02214e-bada-4329-9864-a8b5035dff7d",
            "cf225963-fd17-4304-bf32-d13aebf18d8b",
            "5d931385-566e-47a9-8ac5-5af02a3a7551",
            "d149da54-c03f-4e01-b6ce-637ed607c908"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "4035d488-542b-45f1-b884-c32798c59ef6",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0825ccdb-456e-430b-9d11-440f8b7ded0c",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "4035d488-542b-45f1-b884-c32798c59ef6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "110fa59e-5e51-44a2-96f8-7d6cd63b35e5",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "679c77ba-b4a0-4e3f-acdd-46f296819655",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "4035d488-542b-45f1-b884-c32798c59ef6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5c02214e-bada-4329-9864-a8b5035dff7d",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "45e6d244-11a3-4a8e-8444-a33d501f6b41",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "4035d488-542b-45f1-b884-c32798c59ef6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "cf225963-fd17-4304-bf32-d13aebf18d8b",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "9e9042a8-cdd8-4a4f-b7cf-911da9f97a50",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "4035d488-542b-45f1-b884-c32798c59ef6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "5d931385-566e-47a9-8ac5-5af02a3a7551",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "9376b57e-98d8-4389-9074-b69305830538",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "4035d488-542b-45f1-b884-c32798c59ef6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d149da54-c03f-4e01-b6ce-637ed607c908",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "0fe874f8-91b2-4e3c-b77f-932ea8d11df9",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "bbab80ea-934d-4a8b-a41c-a4bb76df1a89",
          "sourceVisualElement": "f065ddc8-ef9f-4092-bce4-e1e8cfec8d25",
          "targetVisualElements": [
            {
              "targetVisualElement": "4035d488-542b-45f1-b884-c32798c59ef6"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "7e1a3e66-d3ac-41fd-a0fc-c40e4fc9eb9e",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "54351431-3606-4fe2-a696-0a48dc306246",
      "visualElementsParams": [
        {
          "children": [
            "e73e75fd-3dd9-4123-8123-02c2d8e9e880",
            "a23b8428-0625-4bf1-b8f2-336a73d0b2d2",
            "159d8b0a-99c9-46c2-bad3-e8b1d0645275",
            "9651e3fc-145d-461e-8b1d-42b84fc676ff",
            "354ae6ec-0048-47fe-93e1-db5291164806"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "0083efb9-1988-471a-98d7-c4dbb27237bd",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "e73e75fd-3dd9-4123-8123-02c2d8e9e880",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "e59f736e-bfa9-4a30-a0f2-e558a211f700",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "a23b8428-0625-4bf1-b8f2-336a73d0b2d2",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f1d66d34-e6a8-46cd-9f45-da9aa30d0763",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "159d8b0a-99c9-46c2-bad3-e8b1d0645275",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "f31945a4-35b1-44c3-8c85-4aac756cf970",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "9651e3fc-145d-461e-8b1d-42b84fc676ff",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "ccd2394f-f1e5-408f-81ad-acbc5849da95",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "354ae6ec-0048-47fe-93e1-db5291164806",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "cb823213-a94d-4f67-99d0-e42b851ef6d6",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "2b6f7baf-4e53-40cb-8ad4-f07db1708f6a",
          "sourceVisualElement": "53e37da6-651d-48ee-af5e-ddf108b5bdeb",
          "targetVisualElements": [
            {
              "targetVisualElement": "9e559075-4f3a-4946-8bf4-7e9b9b33cae6"
            }
          ]
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "essence": 1,
      "isAutoFormat": true,
      "isMainThing": false,
      "lid": "5c8f07d0-ed7d-43bf-b7ed-6b2c35ccc69a",
      "name": "OpmLogicalObject",
      "satisfiedRequirementsSetParams": {
        "isRequirementObject": false,
        "isRequirementSetObject": false
      },
      "shouldBeGreyed": false,
      "simulationParams": {
        "bernoulli": false,
        "binomial": false,
        "distribution": "uniform",
        "exponential": false,
        "geometric": false,
        "normal": false,
        "numerical": false,
        "poisson": false,
        "simulated": false,
        "textualArray": [
          {
            "percent": "",
            "text": ""
          }
        ],
        "uniform": true
      },
      "text": "Type",
      "valueType": 0,
      "valuedObjectForId": "ded01e03-c16c-4da7-821d-8ba573eb81d5",
      "visualElementsParams": [
        {
          "children": [
            "d7815aab-7e0d-4625-857f-adaa1a547b82",
            "1460c9c6-3b56-4f9c-a184-935e47d3dd2e",
            "68fb3b0e-9816-4755-a3ca-3315f44b2398",
            "57d0976a-2fd8-46ab-8cae-2d8c6da393ac",
            "6fb0efbf-84f4-487b-8461-8385fb869ebe"
          ],
          "descriptionStatus": " ",
          "foldedUnderThing": {
            "isFolded": false
          },
          "id": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "statesArrangement": 1
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "165de0d6-6ddb-44f3-9c69-d83692eafbed",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "int",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "d7815aab-7e0d-4625-857f-adaa1a547b82",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "cce4e9b8-2dec-4e17-861e-b4076da531e1",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "float",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "1460c9c6-3b56-4f9c-a184-935e47d3dd2e",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "5db96a9a-a8cd-4f96-bac5-c6cc38d4e5d3",
      "name": "OpmLogicalState",
      "stateType": "Current",
      "text": "string",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "68fb3b0e-9816-4755-a3ca-3315f44b2398",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "664a31fa-e870-4cd9-908c-75e33674ebb8",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "char",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "57d0976a-2fd8-46ab-8cae-2d8c6da393ac",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "description": "",
      "isAutoFormat": true,
      "lid": "67cd4ac6-a380-4f5b-8499-c8fe8edca9ec",
      "name": "OpmLogicalState",
      "stateType": "none",
      "text": "boolean",
      "units": "s",
      "visualElementsParams": [
        {
          "descriptionStatus": " ",
          "fatherObjectId": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
          "fill": "#FFFFFF",
          "height": 30,
          "id": "6fb0efbf-84f4-487b-8461-8385fb869ebe",
          "refX": 0.5,
          "refY": 0.5,
          "strokeColor": "#808000",
          "textAnchor": "middle",
          "textColor": "#000000",
          "textFontFamily": "Arial, helvetica, sans-serif",
          "textFontSize": 14,
          "textFontWeight": 300,
          "textHeight": "80%",
          "textWidth": "80%",
          "width": 60,
          "xAlign": "middle",
          "yAlign": "middle"
        }
      ]
    },
    {
      "URLarray": [
        {
          "description": " ",
          "iconType": "picture",
          "url": "http://"
        }
      ],
      "lid": "a6d77cf2-2c9c-41c5-84cc-e234ff2a0c4d",
      "linkConnectionType": 1,
      "linkType": 12,
      "name": "OpmFundamentalRelation",
      "visualElementsParams": [
        {
          "id": "6ab7fbc6-38af-43f2-ae80-407a53c14ca4",
          "sourceVisualElement": "0771480d-44af-4032-9fc4-3c20d7133f27",
          "targetVisualElements": [
            {
              "targetVisualElement": "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0"
            }
          ]
        }
      ]
    }
  ],
  "opds": [
    {
      "id": "SD",
      "isFlatteningOpd": false,
      "isHidden": false,
      "isRangesOpd": false,
      "name": "SD",
      "parendId": "SD",
      "stencil": true,
      "stereotypeOpd": false,
      "visualElements": [
        "38d2419c-df98-4a3d-9bc2-3424dafb8fb5",
        "6611c45d-5bcc-4a57-bed0-c185833e72f9",
        "8703ec6b-4c4c-4192-9740-4d4aaa59d194",
        "53b4c181-3e37-49e7-afd4-6b2803056d02",
        "091babc7-2017-4485-9e2c-f2e9d436e341",
        "69ccfd5a-ba2f-4d86-b560-aad1a7218f8f",
        "6d30be3c-fea7-4a46-b933-758a6753cc20",
        "7f15a02d-6370-42dc-a1e0-6bdef631de6e",
        "f13340c6-48ba-47d7-a66b-4d2827474d65",
        "45582763-58db-4670-8cb5-0435f4f02245",
        "f2562ccd-2498-4c46-b263-8940419d751d",
        "4ac0814c-0919-4565-b4b9-8418cd96f108",
        "1286d3ca-04c2-4089-bc3d-c6fbd605e495",
        "89807df7-1dc8-4bf2-9d54-3a4353ed0926",
        "318d4e2e-070c-44a2-b394-d57bc4778ad8",
        "d5bedc8b-45c8-4535-a3b7-3d0d5de92298",
        "41638d57-2698-4076-af3d-544b77506e8a",
        "f9031c58-1fdc-4082-bcc9-348bae7f1752",
        "b6723f4c-a276-47e6-b1ba-03c4748c9779",
        "e19ca6e7-d749-437d-9b07-966adcc317d9",
        "5bd868fd-f38c-4e11-ab88-e930d297fa2e",
        "aad11386-2b8f-4818-9cb1-5aa07a096e93",
        "7ca4f8e0-b142-4ef3-b78e-318897a4acdb",
        "6f61b95c-ee38-4a80-8405-49a4e26465f4",
        "44520822-a4cb-46a2-ac90-548e27d3f4de",
        "2ae57e21-ef88-4458-9750-2bb945c735ea",
        "fac07276-20d2-4473-9e5c-4d022a5c9a71",
        "1ad9036d-8f81-4607-850d-a7a73dd14a92",
        "d4461241-e469-44e4-8585-f049f883b5ab",
        "cd5c0651-274a-4505-8f61-47c48e085641",
        "f8dfad66-f2f0-4db0-a941-f0985aac9a9f",
        "b4886b13-459d-4b6b-b011-d9fb6c5ece03",
        "73e27e00-2b81-4f27-bceb-3ecccef61e5e",
        "97ea6f87-2ec5-4d2b-9138-4608555705cb",
        "85dcc87a-2158-4858-89ce-4bd72178cffd",
        "39292d20-b370-4cde-9d6b-f43028e18fe4",
        "5cbb8ad7-35e3-46b1-bbcf-f6e5ebf53b0e",
        "725a3ea0-5b91-4484-bf26-c97591d808be",
        "55422d78-85ac-4491-8bdb-05e0f050b8c7",
        "17d9d07a-075d-4680-b2be-89c71ba0f5a7",
        "e013f9b2-91f2-4922-80b0-e02165f76001",
        "eb0514d4-81bc-406c-a5f0-7acb287be10e",
        "a081c152-4a48-4545-95c7-92e04cd9248d",
        "70ba3bd9-4cef-4345-ac9d-bde748f255f2",
        "02e45645-61f5-40fe-b982-1fd36407f64b",
        "6222f9e5-473f-4c2c-a7ed-b1411b9972ca",
        "ee2570d8-db53-44cf-9367-5bbb502710fe",
        "cb83ac4b-5410-407e-aa47-fc7a155b914d",
        "1eda4a20-c049-40b8-a34d-b6187e1885a2",
        "903869a3-bd83-4a69-87cb-757639e5692d",
        "d67413ca-e193-41b7-907e-3034760a7358",
        "30f16fef-4ad4-48dc-a785-1d96d8f4adf9"
      ]
    },
    {
      "id": "b7d911c2-734c-4258-86c7-8abd0b5656ff",
      "isFlatteningOpd": false,
      "isHidden": true,
      "isRangesOpd": true,
      "isViewOpd": false,
      "name": "rangesOpd",
      "stencil": true,
      "stereotypeOpd": false,
      "visualElements": [
        "f417bf43-a596-4a52-a29e-096d6fa57966",
        "a89126aa-6fae-401e-8414-4ade53b0a1ce",
        "7b67e00e-12ae-4c60-9e81-578f4c2d8d0e",
        "5918e6a1-db0d-4c59-ba6e-f865ad066700",
        "a8190050-dfac-4646-a757-c890607349b7",
        "13c6755d-b2a4-4e87-99df-81a1729aa243",
        "32bb3e7b-f990-4207-981b-84a198425b21",
        "e3121e19-bad8-42c5-a6fb-93a329870f72",
        "6ebacddb-383b-4a19-adaf-03496b1b691a",
        "3ace1fae-3e49-405e-b37f-700b66566bb0",
        "6cab848a-b6c9-40f4-a08b-4c08d489f1c8",
        "640a12e9-f64d-4756-bd04-235761da9d2a",
        "26aec141-7dd1-4163-ad7b-f20bc7fddf61",
        "8b89a833-308e-43e6-b632-3da3ef26353c",
        "7a290ac2-8d85-4e0e-a17b-871ccde5a9e8",
        "51e53d08-4d1f-4474-8d50-df3829a37f42",
        "d41052df-f3ab-4a55-ac15-050ad89f7a9d",
        "611b4092-708e-44ee-a474-a3c5a20dfdc3",
        "f26ff94f-764e-4965-b7a0-4d21bc4c0419",
        "fe25e6b6-6215-4666-9e18-9a3ad3727a17",
        "dc1b17d8-2223-4875-8216-cd61c1f4b664",
        "d1bc787e-b389-4d56-80dd-bf0192382e7a",
        "df81966e-597c-42f4-ae8d-0da42de496ca",
        "6ceceb3d-a544-464f-b23f-61c008e4f645",
        "b90b8332-965e-432f-95f3-91dd78b02ba1",
        "f17a0d59-cc13-4369-8bf5-dc9f48500108",
        "d79b4bef-8685-4439-b39f-d6ce8dd21f2d",
        "a0536d55-7868-4940-9479-06497368edc1",
        "091e3e6e-9508-4aab-8a09-87e03804981d",
        "af994ee5-cc0d-4210-9bfe-eefdadc24d66",
        "03e052f3-b61c-4e31-86f2-79b04816eb7d",
        "67d8c481-8c32-4503-ae85-9cf1e391e681",
        "9137aee9-cdd4-4a7b-bd26-c584c8670a91",
        "3c68fd65-7e1a-46a5-90fe-c45fef25b7e5",
        "79d43224-09f4-43fc-8467-8967fe58798a",
        "9d3061be-ffd5-47ae-805d-c5a49dc5b10f",
        "371dbd0c-e13f-45a7-ac92-008f61012f0f",
        "26eca17e-a32c-486b-ba2e-94ca903fd2d5",
        "af20812c-a7c7-4b70-bfab-07e4656719d6",
        "0ed87c30-3cdd-40df-be9d-273830845582",
        "05ed72e1-af7d-49b1-8657-bed17497f24a",
        "e2bb1d1e-a789-4e1c-a7f0-135ed6708e1c",
        "e68a3612-d569-48a3-8f5a-f7a5b4edad99",
        "51e0715e-187e-4a9f-98b3-ec3240125542",
        "4151cf93-229c-4200-8967-4802565dff86",
        "9935a578-ee31-4224-8cc3-2d551e7a43bf",
        "d6163c98-34f2-4f46-8e78-e2997b6a7ff5",
        "e184958c-dffe-4b6c-881a-3baa64d24cd5",
        "c01065f5-2a49-4ce1-9378-ac28dfd7fd1c",
        "c687bf99-57cb-4b1f-978d-c6fe81dcf148",
        "5dff20e7-7e28-4a77-b896-ffe6ca2e4a54",
        "1bcdfde2-5687-439e-8fda-3ce154d8647b",
        "1771184f-a967-4a96-8054-8e406d871366",
        "53a59c25-9f26-4ce6-9642-7f7ba0b2c559",
        "c7ad0e5f-9c3a-49f9-9937-6561f0d95a1f",
        "04b145c7-fc4a-4b17-91af-60da8624f8d1",
        "98dbf629-c76e-41d1-8f62-38b8d8c9cb9f",
        "8dcf8a90-8a5d-4a77-a7ab-f976f245b18d",
        "8396c1a4-e41f-4f3f-a467-c4e0b73bfd54",
        "1b914aac-1edb-48b7-97e7-c0cdc33a567f",
        "86358635-0c33-4172-8d6a-2a33c40e0cff",
        "6d32904b-0345-40f5-85b4-239bb41860dd",
        "fd34f66f-c3d1-471a-845f-a5e25903fd3f",
        "ebef0d39-419d-4d67-bdb1-310846be1f9f",
        "5eafc1e9-b4d5-4844-8538-48ed9131d81b",
        "f6842ddd-3385-450d-bfc4-154565dee700",
        "d170ec15-477b-45fd-8052-34702b9b14c2",
        "f84f9cc6-8a2e-40f9-9992-c960415f7e78",
        "1e206e3f-86ae-4e9e-8df0-8adad0463c79",
        "e00d9c36-2f8e-4b66-97c0-90348a1e1845",
        "4f243f1a-b30c-427d-97c9-8fecd55ea673",
        "e33125fe-c3dd-4994-8641-6f9a7d34590e",
        "805a6d72-58b4-4d8f-ae37-16021c74b497",
        "e94f6534-0fca-40d6-99d5-33398b31253e",
        "76bf8c67-4194-44ca-a220-a1c67fc6fb6e",
        "9e219ab3-c8e1-4114-bd65-dde3e885a3f5",
        "cb7713b6-e9c3-4668-8dfd-70d6e7a951fb",
        "f9952811-efdb-4e52-a77c-33ce04146e33",
        "143c74b2-9454-4767-a93a-a0139676338d",
        "d5e25434-3ef2-49b7-beb9-503477cfe1d1",
        "d79bab83-af43-454f-80b7-82905c1e7078",
        "be9b959e-a7ca-4a57-ae46-94daa3bf91fc",
        "2927ff4d-ca2f-4987-8773-b4fb65063ef7",
        "006a60e7-531b-4f0a-85ec-f9c129173089",
        "3618e6ed-fa4b-43c5-aa01-1135b72de808",
        "2ace3a07-da05-4012-9a9d-76fc7256e5fe",
        "026bbf74-18df-474a-a7e0-e4c8013e1e6b",
        "30ed5db5-b530-457e-a0c5-a91d5efac2f7",
        "688506a9-164a-4b79-9b88-1f90927645ba",
        "7d2ab9e9-1de3-460b-a14d-9616e53df428",
        "cb2655c1-7043-4093-bf88-6d3eb5abdf8f",
        "8672caf4-31c5-4ecd-89b2-e7f2266e554a",
        "80641cf1-f14c-4925-983b-abfb65de15ae",
        "1857b70e-02cc-4f07-a047-e101ec62f43c",
        "17953a14-add2-4b78-a810-9557ab43f258",
        "6e718310-a482-481f-ac5d-3a1ba0d2d8d6",
        "b6a9230f-f85d-4fcf-8e94-41d3752c926e",
        "bda94790-88f6-4065-af10-690e2f827db7",
        "4d1f793b-74bb-4161-a327-6ba68c3d1196",
        "9cd8fcc2-41a9-4727-8edd-9413e57f4328",
        "46b92cba-2eaa-4bae-b5c5-85b8ba317c0a",
        "ae3fc80e-0a92-4533-9dc9-3aca7ce5962f",
        "8ccba107-c9f5-43bb-bf9a-55bc9820e354",
        "8c99a025-b92e-4b9e-99a8-6c64838b9d0f",
        "9ee4fe70-e246-4a0a-be6c-0f8291bc1090",
        "2bbfbff9-6313-48f1-b14e-3d040d4e635b",
        "cb39e9d4-4f4a-434a-99af-867534d035e3",
        "6d4d9bc8-bc0a-4545-a529-e1357396a309",
        "b300841f-37db-43e9-9423-26ddc8c8a401",
        "1382cb0c-f3e8-4228-8e05-08c2b86b7806",
        "1413ddc0-a39b-47b6-8266-810163741480",
        "9c66106a-dbd7-43fe-b9ff-0f711b762f89",
        "10334f5c-1eea-4aca-93e3-3e768a4b6b23",
        "2f2b59cc-5eec-42df-9115-3b01849a69a7",
        "b1852802-48f3-4e5d-8051-0a3c909dcc1a",
        "c8be8d13-fa11-47b8-b3a9-220ac8a1e309",
        "aa4db0bf-3921-4af4-a045-f828490bf31e",
        "cb5320fc-c96f-4ada-ba20-91425935cc67",
        "60b38a99-6cb3-4e33-8752-01fd6e79e4c5",
        "f1825c26-021e-4c42-9eff-a96d07e7768f",
        "70a7a5bd-98b1-42b4-b7c8-24a4bdd48ff7",
        "db25f11a-3c61-4714-b32b-e6accb87432f",
        "e7805dd5-ceb2-4fb8-972e-126cb9ab82be",
        "7f800cc0-4166-4ea2-88f9-3b1785ad2feb",
        "884340ee-40f9-4469-8cdb-d0cb2fa210f8",
        "a0c5d62a-b831-48b3-9ae0-48d9af6b604a",
        "d61654ff-9089-416f-ba08-7e0e05c9ca26",
        "d4227db0-9a0f-4310-860b-a375443549fd",
        "d4de79ec-7581-4ae7-ab1e-4d99b04ec35b",
        "bd66640b-2001-413b-8e20-245a244bf6ed",
        "b0897c18-c93d-445a-97e5-f1bb093786ac",
        "2ede544e-f071-452d-bb08-2318c236c07c",
        "0ca9b725-1916-4987-9011-86580a3b6a76",
        "ba90f1c1-f363-4db0-ba35-b3f1e7241079",
        "9cf4a1d1-1ba7-4d29-a100-a4c2982f3fb9",
        "e26388fa-4525-43d5-b7ec-070de72fda75",
        "59854c2b-9e2d-4201-8c12-10434b4b6457",
        "e018c532-826d-49c1-8f46-9096b1b52246",
        "06f79b1a-4a66-4a55-879f-57bad710cfa0",
        "cd11c840-0a1d-4a8f-aa89-319b378995df",
        "a6d31f71-81fa-4c2d-93dd-05b323962956",
        "6263a6cd-028f-48ea-b413-25d997fe763f",
        "5c347f3f-30b2-4f1c-b29c-0c89a19cadc6",
        "af2cb907-0037-41e0-a842-63fd27772c76",
        "5be39352-994e-46bf-b37a-b0ca17987a96",
        "861513c1-9e8a-48d7-8fc6-c81e8f1abf1b",
        "7f8ba660-44e0-4ed7-8627-5c57ed6d44ab",
        "552e82a3-38a9-4639-8c4e-e7a9fe1560b7",
        "1e9b9b8a-ff15-4fa9-92a4-c794dbf235e3",
        "47accb1d-846e-49de-bec7-d3ebfbe6f79a",
        "03190dc5-9965-4162-ac65-c15cf2c6362c",
        "699023b9-ac31-49c2-94bd-ad84f665c740",
        "b9d42ee6-e655-48b3-8dd8-c454620a10b5",
        "664402a3-ecc6-4742-a0ea-ef941b0d198f",
        "bd8a4200-d359-4aa3-ba7a-1ece1b425a84",
        "b444393c-7603-4c00-91b6-45bc09566df2",
        "36a11742-0faa-4e76-995c-2e2325b69b15",
        "cdfa74fe-00a1-4a55-b276-0a3ca8bbde25",
        "4edb6fea-1d05-4227-aa9c-781c8624d3d9",
        "0ed30591-8647-4cdf-b53a-effc9d07a2ea",
        "637e72f5-98c8-497a-9527-9eb9fd2afd8d",
        "49fbbfed-0639-4c83-800a-5543e7ef5432",
        "6b8d050e-a1cc-46c1-b338-232736ae5901",
        "82180b26-62ff-46f8-91b3-44d78e09fc75",
        "6c048091-15d0-4838-80f9-08aa59077d7e",
        "13f359c8-f215-43ac-8000-dce7bc6821ac",
        "f931a28a-400d-44d8-8b89-c47cc3d6a3f2",
        "f63fb4b4-f120-4e9e-ad6a-0d0eb2665739",
        "110fa59e-5e51-44a2-96f8-7d6cd63b35e5",
        "5c02214e-bada-4329-9864-a8b5035dff7d",
        "cf225963-fd17-4304-bf32-d13aebf18d8b",
        "5d931385-566e-47a9-8ac5-5af02a3a7551",
        "d149da54-c03f-4e01-b6ce-637ed607c908",
        "4035d488-542b-45f1-b884-c32798c59ef6",
        "f065ddc8-ef9f-4092-bce4-e1e8cfec8d25",
        "bbab80ea-934d-4a8b-a41c-a4bb76df1a89",
        "e73e75fd-3dd9-4123-8123-02c2d8e9e880",
        "a23b8428-0625-4bf1-b8f2-336a73d0b2d2",
        "159d8b0a-99c9-46c2-bad3-e8b1d0645275",
        "9651e3fc-145d-461e-8b1d-42b84fc676ff",
        "354ae6ec-0048-47fe-93e1-db5291164806",
        "9e559075-4f3a-4946-8bf4-7e9b9b33cae6",
        "53e37da6-651d-48ee-af5e-ddf108b5bdeb",
        "2b6f7baf-4e53-40cb-8ad4-f07db1708f6a",
        "d7815aab-7e0d-4625-857f-adaa1a547b82",
        "1460c9c6-3b56-4f9c-a184-935e47d3dd2e",
        "68fb3b0e-9816-4755-a3ca-3315f44b2398",
        "57d0976a-2fd8-46ab-8cae-2d8c6da393ac",
        "6fb0efbf-84f4-487b-8461-8385fb869ebe",
        "d885909a-c2d0-48d9-87e4-1d6f04cf7ef0",
        "0771480d-44af-4032-9fc4-3c20d7133f27",
        "6ab7fbc6-38af-43f2-ae80-407a53c14ca4"
      ]
    }
  ],
  "description": "",
  "name": "Requirement",
  "organization": "Technion",
  "type": "ORG",
  "id": "-Myv3oHJbYUXTd60dk9Z",
  "permission": "WRITE"
}


export function getRequirementStereotype() {
  return requirementsStereotype;
}
