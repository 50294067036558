import { Component, OnInit } from '@angular/core';
import {OrganizationService} from '../../../../rappid-components/services/organization.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import {validationAlert} from "../../../../configuration/rappidEnviromentFunctionality/shared";

@Component({
  selector: 'opcloud-delete-org',
  templateUrl: './delete-org.component.html',
  styleUrls: ['./delete-org.component.css']
})
export class DeleteOrgComponent implements OnInit {
  orgS;
  selectedOrg = '';

  constructor(private orgService: OrganizationService,
              public dialog: MatDialog
  ) {}

  ngOnInit() {
    this.orgS = this.orgService.getOrganizations();
  }

  getOrg(event: any) {
    this.selectedOrg = event.value;
  }

  deleteOrg() {
    if (window.confirm('Are sure you want to delete this organization: ' + this.selectedOrg +'?')){
      this.orgService.deleteOrganization(this.selectedOrg).then(() =>{
        validationAlert("Organization Deleted Successfully!",null, 'Success');
      }).catch(err => {validationAlert("ERRROR! Organization couldn't be deleted: " + err, null, 'Error');})


    }
  }
}
