import { HttpClient } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { MatLegacyPaginator as MatPaginator } from '@angular/material/legacy-paginator';
import { MatLegacyTableDataSource as MatTableDataSource } from '@angular/material/legacy-table';
import { BehaviorSubject, merge, Observable, of as observableOf, of} from 'rxjs';
import {catchError, elementAt, map, startWith, switchMap} from 'rxjs/operators';
import { ErrorLog } from '../../error-handler/error-log.interface';
import { OrganizationService } from '../../rappid-components/services/organization.service';
import { LogsDashboardDialogComponent } from '../logs-dashboard-dialog/logs-dashboard-dialog.component';

const HEADERS = {
  LABELS: ['Timestamp', 'Organization', 'Modeler', 'Model', 'Error Message', 'Steps'],
  ATTRIBUTES: ['createdAt', 'userOrganization', 'userName', 'modelName', 'errorMessage', 'steps']
}

export interface LogElement {
  timestamp: string;
  modeler_name: string;
  model_url: string;
  error_message: string;
  steps: Array<any>;
}

export interface LogPage {
  items: LogElement[],
  total_count: number
}

const ELEMENT_DATA: LogElement[] = [
  {
    timestamp: new Date().toLocaleString(),
    modeler_name: 'Eddie',
    model_url: 'localhost:4200/group15/final_model',
    error_message: 'failed to load model',
    steps: [

    ]
  },
];


const API_RESPONSE_PAGES = [
  {
    items: [
      {
        timestamp: new Date().toLocaleString(),
        modeler_name: 'Eddie1',
        model_url: 'localhost:4200/group15/final_model1',
        error_message: 'failed to load model1',
        steps: [

        ]
      }
    ],
    total_count: 3
  },
  {
    items: [
      {
        timestamp: new Date().toLocaleString(),
        modeler_name: 'Eddie2',
        model_url: 'localhost:4200/group15/final_model2',
        error_message: 'failed to load model2',
        steps: [

        ]
      }
    ],
    total_count: 3
  },
  {
    items: [
      {
        timestamp: new Date().toLocaleString(),
        modeler_name: 'Eddie3',
        model_url: 'localhost:4200/group15/final_model3',
        error_message: 'failed to load model3',
        steps: [

        ]
      }
    ],
    total_count: 3
  },
]

const ALL_USER = {
  Name: 'All Users',
  uid: ''
}

const ALL_ORGS = {
  name: 'All Organizations',
}

@Component({
  selector: 'opcloud-logs-dashboard',
  templateUrl: 'logs-dashboard.component.html',
  styleUrls: ['./logs-dashboard.component.scss']
})
export class LogsDashboardComponent implements OnInit {
  public displayedColumns: string[] = HEADERS.ATTRIBUTES
  public displayedColumnLabels: string[] = HEADERS.LABELS

  dataUpdates = new BehaviorSubject<number>(0);
  organization: any;
  organizations$: Observable<Array<any>>;
  users = [ALL_USER]
  user: any = ALL_USER;
  public data:MatTableDataSource<ErrorLog> = new MatTableDataSource([]);

  exampleDatabase: ExampleHttpDatabase | null;

  // data: GithubIssue[] = [];
  public MAX_TEXT_SIZE = 60;
  public pageSize = 50;
  public userFilterCache = "";
  public userFilter = "";
  resultsLength = 0;
  isLoadingResults = true;
  isRateLimitReached = false;
  orgs = [];

  @ViewChild(MatPaginator) paginator: MatPaginator;

  constructor(private orgService: OrganizationService, private dialog: MatDialog) {}

  ngOnInit(): void {
    const that = this;
    this.organizations$ = this.orgService.getOrganizations()
    this.organizations$.subscribe(orgs => {
      that.orgs = [ALL_ORGS].concat(orgs);
      that.organization = ALL_ORGS.name;
      // if (orgs.length > 0) {
      //   this.organization = orgs[0].name
      //   this.selectOrganization({ value: this.organization });
      // }
      this.showAllOrganizations();
    });

  }

  public showAllOrganizations() {
    this.isLoadingResults = true;
    Promise.all(this.orgs.map(o => this.orgService.getOrganizationUsers(o.name))).then(users=> {
      this.users = [ALL_USER].concat(...users);
      this.user = ALL_USER;
      this.isLoadingResults = false;
    });
  }

  public selectOrganization(org) {
    if (org.value === ALL_ORGS.name)
      return this.showAllOrganizations();
    this.orgService.getOrganizationUsers(org.value).then(users=> {
      this.users = [ALL_USER].concat(users);
      this.user = ALL_USER;
      this.refresh();
    });

  }

  public rowClicked(row) {
    const that = this;
    if (this.dialog.openDialogs.length > 0)
      return;
    let dialogRef = this.dialog.open(LogsDashboardDialogComponent, {
      data: row,
    });
    dialogRef.afterClosed().subscribe(value => {
      if (value)
        that.removeItem(value);
    });
  }

  public removeItem(item) {
    const itemInData = this.data.data.find(it => (<any>it).id === item.id) as any;
    if (itemInData) {
      const idx = this.data.data.indexOf(itemInData);
      if (idx === -1)
        return;
      this.data.data.splice(idx ,1);
      this.data.paginator = this.paginator;
      this.data._updateChangeSubscription();
      this.orgService.removeLog(itemInData.id);
    }
  }

  public refresh() {
    if (this.paginator.pageIndex == 0) {
      this.dataUpdates.next(0);
    } else {
      this.paginator.firstPage();
    }
  }

  public selectUser(user) {
    this.refresh();
  }

  public filterByUser() {
    this.userFilter = this.userFilterCache;
    this.refresh();
    // this.paginator.pageIndex = 0
  }

  displayTimestamp(timestamp:number) {
    return new Date(timestamp*1000).toLocaleString();
  }

  public trim(text: string) {
    return (text || '').length > this.MAX_TEXT_SIZE ? text.substring(0, this.MAX_TEXT_SIZE-3) + '...' : text;
  }

  ngAfterViewInit() {
    // this.exampleDatabase = new ExampleHttpDatabase(this._httpClient);

    // this.data.paginator = this.paginator;
    // this.data.paginator = this.paginator;
    // this.paginator.hidePageSize = true;
    merge(this.paginator.page, this.dataUpdates)
      .pipe(
      startWith({}),
        switchMap(() => {
          this.isLoadingResults = true;


          if (this.userFilter != "" || this.user != ALL_USER) {
            // return this.orgService.getLogs(this.pageSize, this.paginator.pageIndex * this.pageSize, this.getUserIdentifier(this.userFilter))
            return this.orgService!.getLogs(this.pageSize, this.paginator.pageIndex * this.pageSize, this.user.uid)
            // return this.exampleDatabase!.getUserLogs(this.getUserIdentifier(this.userFilter), this.paginator.pageIndex);
          } else {
            return this.orgService!.getLogs(this.pageSize, this.paginator.pageIndex * this.pageSize)
            // return this.exampleDatabase!.getAllLogs(this.paginator.pageIndex);
          }
        }),
        map(data => {
          // Flip flag to show that loading has finished.
          this.isLoadingResults = false;
          this.isRateLimitReached = false;
          this.resultsLength = data["logsCounter"];
          let logs = data["logs"];
          if (this.organization !== ALL_ORGS.name)
            logs = logs.filter(lg => lg.userOrganization === this.organization)
          logs.map(element => {
            element["createdAt"] = this.displayTimestamp(element["timestamp"]);
            return element;
          })
          return logs
        }),
        catchError(() => {
          this.isLoadingResults = false;
          // Catch if the GitHub API has reached its rate limit. Return empty data.
          this.isRateLimitReached = true;
          return observableOf([]);
        })
    ).subscribe(data => this.data.data = data);
  }
}


/** An example database that the data source uses to retrieve data for the table. */
export class ExampleHttpDatabase {
  constructor(private _httpClient: HttpClient) {}

  getAllLogs(page:number): Observable<LogPage> {
    // console.log(`getting all logs in page ${page}`)
    return of(API_RESPONSE_PAGES[page])
  }

  getUserLogs(uid:string, page:number): Observable<LogPage> {
    // console.log(`getting all user ${uid} logs in page ${page}`)
    return of(API_RESPONSE_PAGES[page])
  }

}
