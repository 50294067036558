import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, Optional} from '@angular/core';
@Component({
  selector: 'opcloud-privacy-policy',
  templateUrl: './opcloud-privacy-policy.component.html',
  styleUrls: ['./opcloud-manual.component.css']
})
export class OPCloudPrivacyPolicyComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
