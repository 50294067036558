import { OpmProcess } from "../../models/DrawnPart/OpmProcess";
import { OpmObject } from "../../models/DrawnPart/OpmObject";
import { EntityType } from "../../models/model/entities.enum";
import { OpmVisualEntity } from "../../models/VisualPart/OpmVisualEntity";
import { OpmEntity } from "../../models/DrawnPart/OpmEntity";
import { OpmState } from "../../models/DrawnPart/OpmState";

export function draw(visual: OpmVisualEntity): OpmEntity {
    const drawn = factory(visual.type);
    drawn.updateParamsFromOpmModel(visual);
    return drawn;
}

export function putToGraph(graph, visual: OpmVisualEntity) {
    const drawn = draw(visual);
    graph.addCell(drawn);
    drawn.updateView(visual);
    return drawn;
}

export function factory(type: EntityType): OpmEntity {
    switch (type) {
        case EntityType.Object:
            return new OpmObject();
        case EntityType.Process:
            return new OpmProcess();
        case EntityType.State:
            return new OpmState();
    }
}