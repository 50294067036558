import { Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';
import { OplService } from '../../opl-generation/opl.service';
import { UserService } from '../../rappid-components/services/user.service';

@Component({
  selector: 'opcloud-log-sharing-dialog',
  template: `
    <mat-card>
      <div class="hdr1">
        <h4><b><i>
          Do you allow sharing your opcloud exceptions to help improve our platform?
        </i></b></h4>
      </div>
      <div class="btns" style="text-align: center">
          <button mat-button color="primary" id = "yesBtn" (click)="updateLogSharingPermission(true)">Accept</button>
          <button mat-button color="primary" id = "noBtn" (click)="updateLogSharingPermission(false)">Decline</button>
      </div>
    </mat-card>


  `,
  styleUrls: ['./log-sharing-dialog.component.scss']
})
export class LogSharingDialogComponent implements OnInit {

  constructor(private oplService: OplService, private userService: UserService, public dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any) { }

  updateLogSharingPermission(val:boolean) {
    //console.log(`log sharing permission: ${val}`)
    const settings = { 'logSharingPermission': val };
    const message = (val) ? 'Log sharing enabled!' : 'Log sharing disabled!';
    this.oplService.updateUserSettings(settings);
    this.userService.updateUserOplSetting(settings).then(() => validationAlert(message, null, 'Success'));
    this.dialog.closeAll();
  }
  ngOnInit(): void {
  }
}
