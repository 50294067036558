import {InitRappidService} from "../rappid-components/services/init-rappid.service";
import {CreateViewDialog} from "../dialogs/create-view-dialog/create-view-dialog";
import {OpmOpd} from "../models/OpmOpd";
import {validationAlert} from "../configuration/rappidEnviromentFunctionality/shared";
import {TreeModel} from "@ali-hm/angular-tree-component";
import {ConfirmDialogDialogComponent} from "../dialogs/confirm-dialog/confirm-dialog";
import {TabsManager} from "../modules/app/tabsService";
import {ContextService} from "../modules/app/context.service";
import {SubModelNameComponent} from "../dialogs/submodel-name-dialog/submodel-name-dialog";

export interface TreeAction {
  act();
  canBePerformed(): boolean;
}


export class ToggleOPDsNamesTreeAction implements TreeAction {

  constructor(private init: InitRappidService) {
  }

  act() {
    const value = !(this.init.oplService.settings.SDNames);
    const details = { SDNames: value };
    this.init.oplService.updateUserSettings(details);
    this.init.updateDB(details);
  }

  canBePerformed(): boolean {
    return true;
  }
}


export class RenameOpdTreeAction implements TreeAction {

  constructor(private init: InitRappidService, private opd: OpmOpd) {
  }

  act(): Promise<string> {
    return this.init.dialogService.openDialog(CreateViewDialog, 230, 426, {
      renameOpdId: this.opd.id, allowMultipleDialogs: true
    }).afterClosed().toPromise();
  }

  canBePerformed(): boolean {
    return this.opd.isViewOpd && !(this.opd.sharedOpdWithSubModelId || this.opd.belongsToSubModel);
  }

}

export class RemoveOpdTreeAction implements TreeAction {

  constructor(private init: InitRappidService, private node, private tree: TreeModel) {
  }

  async act(): Promise<{ removed: boolean }> {
    if (!this.node.isLeaf || this.node.id === "SD") {
      validationAlert('You are not allowed to remove inner nodes!', null, 'Error');
      return { removed: false };
    }
    const opdName = this.init.opmModel.getOpd(this.node.id).getName();
    const canClose = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 180, 350, {
      message: `Pay attention, "${opdName}" will be removed permanently.`,
      okName: 'Delete', okColor: '#ff0000', centerText: true, closeName: 'Cancel', allowMultipleDialogs: true
    }).afterClosed().toPromise();
    if (!canClose)
      return { removed: false };
    const nodeID = this.node.data.id;
    let opdParent = this.node.data.initRappid.opmModel.getOpd(nodeID).parendId;
    if (opdParent === 'Requirements')
      opdParent = 'SD';
    this.init.setSelectedElementToNull();
    this.init.opmModel.removeOpd(nodeID);
    this.init.graphService.renderGraph(this.init.opmModel.getOpd(opdParent), this.init);
    this.init.opmModel.setCurrentOpd(opdParent);
    this.init.treeViewService.treeView.treeModel.getNodeById(opdParent).toggleActivated();
    this.init.treeViewService.removeNode(this.node.data.id);
    this.tree.update();
    this.init.treeViewService.init(this.init.opmModel);
    this.init.criticalChanges_.next();
    return { removed: true };
  }

  canBePerformed(): boolean {
    return true;
  }

}


export class UpdateRequirementViewTreeAction implements TreeAction {

  constructor(private init: InitRappidService, private opd: OpmOpd) {
  }

  async act() {
    this.init.graphService.closeSelectedTextEditorBeforeRendering(this.init);
    this.init.setElementToRemoveToNull();
    if (this.init.opmModel.hasSubModels()) {
      await this.init.opdHierarchyRef.loadAllSubModels()
    }
    const ret = this.init.opmModel.updateRequirementViewOf(this.opd);
    if (ret.removed) {
      this.init.getTreeView().init(this.init.opmModel);
      if (this.init.opmModel.currentOpd === this.opd)
        this.init.getGraphService().renderGraph(this.init.opmModel.opds[0], this.init);
      validationAlert('The requirement view opd was removed because no elements left that satisfy this requirement anymore.', 8000);
    } else {
      this.init.getGraphService().renderGraph(this.opd, this.init);
    }
  }

  canBePerformed(): boolean {
    return !!this.opd.requirementViewOf;
  }

}


export class DisconnectSubModelTreeAction {

  constructor(private init: InitRappidService, private contextService: ContextService, private opd: OpmOpd) {
  }

  async act() {
    const canClose = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 230, 380, {
      message: 'Pay attention, this action is irreversible. You will not be able to use the undo button to return to the state before this action. ' +
        'Are you sure you want to disconnect this sub model?',
      okName: 'Disconnect', okColor: '#ff0000', centerText: true, closeName: 'Cancel', allowMultipleDialogs: true
    }).afterClosed().toPromise();
    if (!canClose)
      return;
    this.init.opmModel.setShouldLogForUndoRedo(false, 'disconnectSubModel');
    const subModelId = this.opd.sharedOpdWithSubModelId;
    this.init.opmModel.disconnectSubModel(subModelId);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'disconnectSubModel');
    const tabsManager = new TabsManager(this.init, this.contextService);
    tabsManager.refreshTab();
  }

  canBePerformed(): boolean {
    return this.opd.sharedOpdWithSubModelId && !this.opd.belongsToSubModel;
  }
}


export class RenameSubModelTreeAction {

  constructor(private init: InitRappidService, private opd: OpmOpd) {
  }

  act() {
    return this.init.dialogService.openDialog(SubModelNameComponent, 240, 400, {
      mode: 'rename', name: this.opd.name, fatherModelName: this.init.modelService.modelObject.name, allowMultipleDialogs: true }
    ).afterClosed().toPromise().then(ret => {
      if (ret) {
        this.opd.name = ret.nameForOpd;
        this.init.service.renameModel(this.opd.sharedOpdWithSubModelId, ret.nameForModel);
        return ret.nameForOpd;
      }
    });
  }

  canBePerformed(): boolean {
    return !!this.opd.sharedOpdWithSubModelId;
  }
}


export class OpenSubModelInNewTabTreeAction {

  constructor(private init: InitRappidService, private contextService: ContextService, private opd: OpmOpd) {
  }

  act(): Promise<void> {
    const that = this;
    const subModelId = this.opd.sharedOpdWithSubModelId;
    if (this.contextService.isModelAlreadyOpenOnTab(subModelId)) {
      validationAlert(`The model is already open in other tab.`, 2500, 'Error');
      return Promise.resolve(undefined);
    }
    this.init.isLoadingModel = true;
    return this.contextService.loadModel(subModelId, undefined,'MAIN').then((res) => {
      that.init.isLoadingModel = false;
      that.init.elementToolbarReference.setIsExample(that.contextService.isExample());
      that.init.elementToolbarReference.setIsTemplate(that.contextService.isTemplate());
      that.init.elementToolbarReference.setIsStereotype(that.contextService.isStereotype());
      validationAlert(`Successfully loaded sub model.`, 2500, 'warning');
    }).catch(err => {
      that.init.isLoadingModel = false;
    });
  }

  canBePerformed(): boolean {
    return !!this.opd.sharedOpdWithSubModelId;
  }
}
