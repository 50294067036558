import { Injectable, Inject } from "@angular/core";
import {Observable,throwError} from "rxjs";
import { HttpInterceptor, HttpErrorResponse, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { AuthenticationService } from "../rappid-components/services/authentication/authentication.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    public readonly AUTH_HEADER = 'auth';

    constructor(private auth: AuthenticationService) {
    }

    private handleError(err: HttpErrorResponse): Observable<any> {
      if (err.status === 401 && err.error?.reason?.includes('Firebase ID token has expired')) {
        this.auth.refreshExpiredToken();
      } else if ((err.status === 401 || err.status === 403) && !err.error?.reason?.includes('subscription has expired'))
           this.auth.signOut();
      return throwError(err);
    }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({ headers: req.headers.set(this.AUTH_HEADER, this.auth.getToken()) });
        return next.handle(req).catch(err => this.handleError(err));
    }

}
