import {OpmVisualEntity} from '../VisualPart/OpmVisualEntity';
import {OpmLogicalEntity} from '../LogicalPart/OpmLogicalEntity';
import {OpmRelation} from '../LogicalPart/OpmRelation';
import {OpmLink} from '../VisualPart/OpmLink';
import {fundamental, procedural, proceduralEnablers, proceduralTransformers, structural} from './links.set';
import {OpmModel} from '../OpmModel';
import {linkType} from '../ConfigurationOptions';
import {OpmFundamentalRelation} from '../LogicalPart/OpmFundamentalRelation';
import {OpmLogicalState} from '../LogicalPart/OpmLogicalState';
import {LinkParams} from './links.model';
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalObject} from "../LogicalPart/OpmLogicalObject";
import {BringConnectedTypes} from "../Actions/BringConnectedOptionsInterface";

interface FetchResult {
  relations: Array<OpmRelation<OpmLink>>,
  entities: Array<OpmLogicalEntity<OpmVisualEntity>>
}

interface FetchResult1 {
  link: LinkParams,
  source: OpmVisualEntity
  target: OpmVisualEntity
}

export interface FetchingRule1 {
  getNeededElements(relation: OpmRelation<OpmLink>): FetchResult;
}

export interface FetchingRule {
  ruleType: Array<BringConnectedTypes>;
  getNeededElements(relation: OpmRelation<OpmLink>): FetchResult;
}

class BringProceduralEnablersRelations implements FetchingRule {

  public ruleType = [BringConnectedTypes.proceduralEnablers];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (proceduralEnablers.contains(logicalConnection.linkType)) {
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}

class BringProceduraTransformersRelations implements FetchingRule {

  public ruleType = [BringConnectedTypes.proceduralTransformers];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (proceduralTransformers.contains(logicalConnection.linkType)) {
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}


class BringUniBiDirectionalRelations implements FetchingRule {

  public ruleType = [BringConnectedTypes.tagged];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if ([linkType.Bidirectional, linkType.Unidirectional].includes(logicalConnection.linkType)) {
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}

class BringFundamentalRelations implements FetchingRule {

  public ruleType = [BringConnectedTypes.fundamental];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (fundamental.contains(logicalConnection.linkType)) {
      const logicalTarget = logicalConnection.targetLogicalElements[0];
      if (OPCloudUtils.isInstanceOfLogicalObject(logicalTarget)) {
        if ((<OpmLogicalObject>logicalTarget).isSatisfiedRequirementObject())
        return { relations, entities };
      }
      if ((<any>logicalConnection.targetLogicalElements[0]).isValueTyped && (<any>logicalConnection.targetLogicalElements[0]).isValueTyped())
        return { relations: relations, entities: entities };
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}

class BringStructuralRelationsThatHasProceduralInParallel implements FetchingRule {

  public ruleType = [BringConnectedTypes.fundamental];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (structural.contains(logicalConnection.linkType)) {
      let isExistParallel = false;
      const source = logicalConnection.sourceLogicalElement;
      const target = logicalConnection.targetLogicalElements[0];
      const model: OpmModel = logicalConnection.opmModel;
      const modelLinks = model.logicalElements.filter(link => link.constructor.name.includes('OpmProceduralRelation'));
      for (const l of modelLinks) {
        if ((l as OpmRelation<OpmLink>).sourceLogicalElement === source &&
          (l as OpmRelation<OpmLink>).targetLogicalElements[0] === target) {
          isExistParallel = true;
        }
      }
      if (isExistParallel) {
        relations.push(logicalConnection);
        entities.push(logicalConnection.sourceLogicalElement);
        entities = [...entities, ...logicalConnection.targetLogicalElements];
      }
    }
    return { relations: relations, entities: entities };
  }

}

class BringExhibitingThing implements FetchingRule {

  public ruleType = [BringConnectedTypes.fundamental];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (procedural.contains(logicalConnection.linkType) == false)
      return { relations, entities };
    const source = logicalConnection.sourceLogicalElement instanceof OpmLogicalState ?
      logicalConnection.sourceLogicalElement.parent : logicalConnection.sourceLogicalElement;
    const target = logicalConnection.targetLogicalElements[0];
    const model: OpmModel = logicalConnection.opmModel;
    const exhibitions = model.logicalElements.filter(link => link.constructor.name.includes('OpmFundamentalRelation')
      && (<OpmFundamentalRelation>link).linkType === linkType.Exhibition);
    for (const exh of exhibitions) {
      const current = exh as OpmFundamentalRelation;
      if ((<any>current.targetLogicalElements[0]).isValueTyped && (<any>current.targetLogicalElements[0]).isValueTyped())
        continue;
      if (current.sourceLogicalElement === source || current.sourceLogicalElement === target
        || current.targetLogicalElements[0] === source || current.targetLogicalElements[0] === target) {
        relations.push(exh);
        entities.push(current.sourceLogicalElement);
        entities.push(current.targetLogicalElements[0]);
      }
    }
    if (exhibitions.length) {
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}

class BringSelfInvocationAsInzoomedInvocation implements FetchingRule {

  public ruleType = [BringConnectedTypes.proceduralTransformers];

  getNeededElements(logicalConnection: OpmRelation<OpmLink>) {
    const relations = []; let entities = [];
    if (logicalConnection.linkType === linkType.Invocation && logicalConnection.sourceLogicalElement === logicalConnection.targetLogicalElements[0]) {
      relations.push(logicalConnection);
      entities.push(logicalConnection.sourceLogicalElement);
      entities = [...entities, ...logicalConnection.targetLogicalElements];
    }
    return { relations: relations, entities: entities };
  }

}

export const unfoldingRules = new Array<FetchingRule>(
  new BringProceduralEnablersRelations(),
  new BringProceduraTransformersRelations(),
  new BringStructuralRelationsThatHasProceduralInParallel(),
  new BringExhibitingThing(),
);

export const inzoomingRules = new Array<FetchingRule>(
  new BringFundamentalRelations(),
  new BringProceduralEnablersRelations(),
  new BringProceduraTransformersRelations(),
  new BringStructuralRelationsThatHasProceduralInParallel(),
  new BringExhibitingThing(),
);

export const bringConnectedRules = new Array<FetchingRule>(
  new BringFundamentalRelations(),
  new BringProceduralEnablersRelations(),
  new BringProceduraTransformersRelations(),
  new BringStructuralRelationsThatHasProceduralInParallel(),
  new BringExhibitingThing(),
  new BringUniBiDirectionalRelations(),
  new BringSelfInvocationAsInzoomedInvocation(),
);

