import { OpmVisualEntity } from "./OpmVisualEntity";
import { OpmLogicalStateEllipsis } from "../LogicalPart/OpmLogicalState";

export class OpmVisualEllipsis {

  public fatherObject;

  public id: string;
  public width: number;
  public height: number;
  public xPos: number;
  public yPos: number;

  constructor(params, public readonly logicalElement: OpmLogicalStateEllipsis) {
    if (params) {
      this.id = params.id;
      this.setParams(params);
    }
  }

  setParams(params): void {
    this.width = params.width;
    this.height = params.height;
    this.xPos = params.xPos;
    this.yPos = params.yPos;
  }

  express(checked: Array<any>) {
    return (<any>this.fatherObject).expressChecked(checked);
  }

  remove(): any {
    this.logicalElement.remove(this);
    return this;
  }

  getMissingStates() {
    const logStatesNames = this.fatherObject.logicalElement.states.map(s => s.getBareName());
    const visStatesNames = this.fatherObject.states.map(s => s.logicalElement.getBareName());
    return logStatesNames.filter(s => !visStatesNames.includes(s));
  }

  setDefaultPosition(): OpmVisualEllipsis {
    const padding = 10;
    this.xPos = this.fatherObject.xPos + this.fatherObject.width - this.width - padding;
    this.yPos = this.fatherObject.yPos + this.fatherObject.height - this.height - padding;

    const between = function(a, b, c): boolean {
      return (a <= b) && (b <= c);
    };

    const intersect = function(ellipsis: OpmVisualEllipsis, state: OpmVisualEntity): boolean {
      return ((between(state.xPos, ellipsis.xPos, state.xPos + state.width) ||
       between(state.xPos, ellipsis.xPos + ellipsis.xPos + ellipsis.width, state.xPos + state.width))
        && ((between(state.yPos, ellipsis.yPos, state.yPos + state.height) ||
        between(state.yPos, ellipsis.yPos + ellipsis.height, state.yPos + state.height))));
    };

    const intersect_cell = this.fatherObject.states.find(state => intersect(this, state));
    if (intersect_cell) {
      const padding = 10;
      this.xPos = intersect_cell.xPos + intersect_cell.width + padding;
      this.fatherObject.width += this.width + padding;
    }

    return this;
  }

  getDisplayText(): String {
    return this.logicalElement.getDisplayText();
  }

  /*check(): OpmVisualStateEllipsis {
    if (this.logical.states.length === this.states_.length) {
      if (this._ellipsis)
        this._ellipsis.remove();
      return this._ellipsis = undefined;
    }

    const ellipsis = this._ellipsis = this.logical.ellipsis.createVisual();
    ellipsis.fatherObject = this;
    ellipsis.parent = this;

    // DRAWN PART
    const padding = 10;
    this.setPos(this.fatherObject.xPos + this.fatherObject.width - this.width - padding, this.fatherObject.yPos + this.fatherObject.height - this.height - padding);

    const between = function(a, b, c): boolean {
      return (a <= b) && (b <= c);
    };

    const intersect = function(a, b): boolean {
      return ((between(a.xPos, b.xPos, a.xPos + a.width) || between(a.xPos, b.xPos + b.width, a.xPos + a.width))
       && ((between(a.yPos, b.yPos, a.yPos + a.height) || between(a.yPos, b.yPos + b.height, a.yPos + a.height))));
    };

    /*const int = this.states_.find(state => intersect(state, ellipsis));
    if (int) {
      ellipsis.xPos = int.xPos + int.width + padding;
      if (ellipsis.xPos + ellipsis.width > this.xPos + this.width) {
        this.width += ellipsis.width + padding;
      }
    }

    return this;
  }*/
}

