import {MethodologicalChecker, MethodologicalCheckingStatus} from "../methodological-checker";
import {OpmModel} from "../../../models/OpmModel";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OpmVisualProcess} from "../../../models/VisualPart/OpmVisualProcess";
import {createSet, fundamental} from "../../../models/consistency/links.set";
import {OpmLogicalProcess} from "../../../models/LogicalPart/OpmLogicalProcess";
import {linkType} from "../../../models/ConfigurationOptions";

export class TransformingProcessChecker extends MethodologicalChecker {

  private neededLinks;

  constructor(model: OpmModel) {
    super(model);
    this.title = 'Transforming process';
    this.neededLinks = createSet(linkType.Result, linkType.Consumption, linkType.Effect);
  }

  check(): void {
    this.invalidThings = [];
    for (const log of this.model.logicalElements.filter(l => OPCloudUtils.isInstanceOfLogicalProcess(l))) {
      const vis = log.visualElements[0];
      const visProcess = vis as OpmVisualProcess;
      const links = visProcess.getAllLinks();
      const father = visProcess.fatherObject || visProcess.getAllLinks().inGoing.find(l => fundamental.contains(l.type))?.source;
      const relevantIngoingLinks = links.inGoing.filter(l => this.neededLinks.contains(l.type));
      const relevantOutgoingLinks = links.outGoing.filter(l => this.neededLinks.contains(l.type));
      const fatherIngoingLinks = father?.getAllLinks().inGoing.filter(l => this.neededLinks.contains(l.type)) || [];
      const fatherOutgoingLinks = father?.getAllLinks().outGoing.filter(l => this.neededLinks.contains(l.type)) || [];
      if (!father) {
        if (relevantIngoingLinks.length === 0 && relevantOutgoingLinks.length === 0) {
          this.addToInvalidThings(visProcess.logicalElement as OpmLogicalProcess);
        }
      } else if (relevantIngoingLinks.length === 0 && relevantOutgoingLinks.length === 0 &&
          fatherIngoingLinks.length === 0 && fatherOutgoingLinks.length === 0) {
          this.addToInvalidThings(visProcess.logicalElement as OpmLogicalProcess);
        }
    }
    for (let i = this.invalidThings.length - 1 ; i >= 0 ; i--) {
      const logical = this.invalidThings[i];
      const children = logical.getChildren().filter(ch => OPCloudUtils.isInstanceOfLogicalProcess(ch));
      // if there is one valid child the father process is valid.
      if (this.invalidThings.filter(inv => children.includes(inv)).length !== children.length) {
        this.invalidThings.splice(i, 1);
      }
    }
    this.status = this.invalidThings.length === 0 ? MethodologicalCheckingStatus.VALID : MethodologicalCheckingStatus.INVALID;
  }

  getDescriptionTooltip(): string {
    return `A process must transform (create, consume or affect) at least one object.`;
  }
}
