import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../rappid-components/services/organization.service';
import { UserService } from '../../rappid-components/services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateUserDialogComponent } from '../update-user-dialog/update-user-dialog.component';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';
import { Observable } from 'rxjs';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { ActivateUserDialogComponent } from '../activate-user-dialog/activate-user-dialog.component';
import { RemoveUserComponent } from '../remove-user/remove-user.component';

@Component({
  selector: 'opcloud-find-user-admin',
  templateUrl: './find-user-admin.component.html',
  styleUrls: ['./find-user-admin.component.css']
})
export class FindUserAdminComponent implements OnInit {
  organizations$: Observable<Array<any>>;
  OrgAdmin;
  currentUser: any;
  placeholder: string;
  listView = false;
  searchText = '';
  selected = '';
  loggedUser: any;
  private allUsers: Array<any>;
  private filteredUsers: Array<any>;
  private isLoading: boolean = true;

  constructor(private orgService: OrganizationService, private userService: UserService, public dialog: MatDialog) {
  }

  ngOnInit() {
    this.loadAllOrganizationsUsers();
  }

  async loadAllOrganizationsUsers() {
    this.allUsers = [];
    this.filteredUsers = [];
    const orgs = await this.orgService.getAllOrgs() as Array<any>;
    for (const org of orgs) {
        const orgUsers = await this.orgService.getOrganizationUsers(org.name);
        this.allUsers.push(...orgUsers);
    }
    this.loggedUser = this.userService.user?.userData;
    this.isLoading = false;
  }


  openDialogUpdateUser(user) {
    this.dialog.open(UpdateUserDialogComponent, { data: { user: user, organization: user.organization } });
  }

  async openDialogDelUser(user) {
    const currProc = this;
    currProc.currentUser = user;
    let wantToDeactivate = false;
    if (currProc.currentUser.isActive || currProc.currentUser.isActive === undefined) {
      wantToDeactivate = window.confirm('Are sure you want to deactivate the user: ' + user.Email + '?');
      if (!wantToDeactivate) { return; } // the user clicked 'cancel' so nothing should happen
    }
    const action = (wantToDeactivate) ? 'Deactivated' : 'Activated';
    const errorMsg = 'ERROR! User wasn\'t ' + action + '!';
    const successMsg = 'User ' + action + ' Successfully!';
    if (!wantToDeactivate) { // if user should be activated
      if (!currProc.isUserActivatedAccordingToDate(user.exp_date)) {
        const dialogRef = currProc.dialog.open(ActivateUserDialogComponent, { data: { user: user, organization: this.selected } });
        await dialogRef.afterClosed().toPromise() // should wait until the user updated expiration date or decided to do nothing
          .then(res => {
            if (currProc.isUserActivatedAccordingToDate(currProc.currentUser.exp_date)) { // expiration date is in the future
              currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
            }
          });
      } else { // original expiration date is in the future
        currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
      }
    } else { // user should be deactivated
      currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
    }
  }

  private changeActivationStatusWrapper(successMsg: string, errorMsg: string, user, wantToDeactivate: boolean) {
    this.orgService.changeActivationStatus(user.uid, !wantToDeactivate)
      .then(res => { validationAlert(successMsg, null, 'Success'); user.isActive = !wantToDeactivate; })
      .catch(err => { validationAlert(errorMsg + err, null, 'Error'); });
  }

  resetPassword(user) {
    this.currentUser = user;
    if (this.userService.shouldChangePassword()) {
      this.dialog.open(ChangePasswordDialogComponent, { data: { user: user } });
    } else {
      this.userService.resetPassword(this.currentUser.Email).then(
        res => { validationAlert('Mail with reset details sent to ' + this.currentUser.Email, null, 'Success'); }
      ).catch(err => { validationAlert('Failed: ' + err, null, 'Error'); });
    }
  }

  sortFilteredUsersArray() {
    this.filteredUsers = this.filteredUsers.sort((a, b) => {
      if (a.organization !== b.organization)
        return a.organization > b.organization ? 1 : -1;
      return (a.Name.charAt(0).toUpperCase() < b.Name.charAt(0).toUpperCase()) ? -1 : a.Name.charAt(0).toUpperCase() > b.Name.charAt(0).toUpperCase() ? 1 : 0;
    });
  }

  isUserActivatedAccordingToDate(user_exp_date) {
    if (user_exp_date === '') { // the user has permanent access
      return true;
    }
    if (user_exp_date) {
      const currDateTime = new Date().setHours(0, 0, 0, 0); // to compare just according to day,month and year
      return (currDateTime <= user_exp_date);
    }
    return false;
  }

  updateToDefaultUrl(event, defaultPictureUrl: string) {
    event.target.src = defaultPictureUrl;
  }

  deleteUser(user: any) {
    if (user.uid === this.loggedUser['uid']) {
      validationAlert('You cannot delete yourself. ', 5000, 'Error');
    } else {
      const dialog_ref = this.dialog.open(RemoveUserComponent, {
        width: '600',
        data: { user: { user } }
      });
      dialog_ref.afterClosed().toPromise().then(res => {
        if (res.removed) {
          const idx = this.allUsers.indexOf(user);
          if (idx > 0) {
            this.allUsers.splice(idx, 1);
            this.filterUsers();
          }
        }
      });
    }
  }

  canDeleteUsers() {
    return (this.loggedUser['SysAdmin']);
  }

  textChange($event) {
    this.searchText = $event.target.value;
    this.filterUsers();
    this.sortFilteredUsersArray();
  }

  filterUsers() {
    if (this.searchText.length < 2) {
      this.filteredUsers = [];
    } else {
      this.filteredUsers = this.allUsers.filter((user) => {
        return user.Name.toLowerCase().trim().includes(this.searchText.toLowerCase().trim())
          || user.Email.toLowerCase().trim().includes(this.searchText.toLowerCase().trim());
      });
    }
  }
}
