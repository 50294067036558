import {geometry, joint, stylePopup, vectorizer} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {OpmLink} from '../../VisualPart/OpmLink';
import {InvocationLink} from './InvocationLink';
import {LinkLogicalConnection, linkType} from '../../ConfigurationOptions';

export class Arc {
  // arcType: String;
  x: number;
  y: number;
  startAngle: number;
  endAngle: number;
  port: number;
  mSide;
  arcVec;
  linksArray;
  initRappid;
  constructor(minitRappid, mlinksArray, centerX, centerY, nSide, arcStartAngle, arcEndAngle, myPort) {
    // this.arcType = myArcType;
    this.x = centerX;
    this.y = centerY;
    this.startAngle = arcStartAngle;
    this.endAngle = arcEndAngle;
    this.port = myPort;
    this.mSide = nSide;
    this.linksArray = mlinksArray;
    this.initRappid = minitRappid;
    this.arcVec = vectorizer.V('path', {
      'fill': 'transparent',
      'stroke': '#586d8c',
      'stroke-width': '2',
      'stroke-dasharray': '4 1'
    });
    let path;
    const logicalLink = this.initRappid.opmModel.getLogicalElementByVisualId(this.linksArray[0].id);
    const existingArcType = this.mSide === 'source' ? logicalLink.sourceLogicalConnection : logicalLink.targetLogicalConnection;
    // Set the multi link angle
    // Multi NOT links don't not have an angle (should be added here if necessary)
    let arc = 'NOT';
    // if === 1 its XOR else its OR
    if (existingArcType === 1) {
      path = this.describeArc(this.x, this.y, 30, this.startAngle, this.endAngle);
      arc = 'XOR';
    } else if (existingArcType === 0) { // OR
      path = this.describeArc(this.x, this.y, 30, this.startAngle, this.endAngle) + ' ' +
        this.describeArc(this.x, this.y, 35, this.startAngle, this.endAngle);
      arc = 'OR';
    }
    // updating the path - single or doubled
    this.arcVec.attr('d', path);
    // adding click listener for toggling the arc type
    this.arcVec.node.addEventListener('click', this.toggleArcType.bind(this, arc), false);
    if (this.linksArray[0] && (this.mSide === 'source' || !(this.linksArray[0].constructor.name.includes('nvocation'))))
      this.arcVec.node.addEventListener('contextmenu', this.rightClickArcPopup.bind(this), false);
    const that = this;
    let highestZ = this.getLinksArray()[0].attributes.z;
    if (highestZ) {
      for (const link of this.getLinksArray()) {
        highestZ = (link.attributes.z && highestZ < link.attributes.z) ? link.attributes.z : highestZ;
      }
      this.arcVec.node.style.zIndex = highestZ + 1;
    }
    this.arcVec.node.addEventListener('mouseenter', function () {that.arcVec.node.style.opacity = '0.7';}, false);
    this.arcVec.node.addEventListener('mouseleave', function () {that.arcVec.node.style.opacity = '1';}, false);
    if (this.mSide === 'target' && arc === 'OR') {
      const statesFromSameObject = this.linksArray.filter(l => l.getSourceElement().constructor.name.includes('State'))
        .map(l => l.getVisual().source.fatherObject);
      // if there are 2 or more states from the same object in the relation it must be XOR
      if (statesFromSameObject.length !== (new Set(statesFromSameObject)).size) {
        this.toggleArcType(undefined);
      }
    }
  }
  // Gal: redraw the arcs on the element and on the source and target elements. goToOther is for preventing infinity reqursive calls
  // and its made for making it go only one time to an element
  static redrawAllArcs(element, initRappid, goToOther) {
    const sourceLinks = initRappid.graph.getConnectedLinks(element, 'outbound');
    const targetLinks = initRappid.graph.getConnectedLinks(element, 'inbound');
    sourceLinks.forEach(function (link) {
      if (link && initRappid.opmModel.getLogicalElementByVisualId(link.get('id')) != null &&
        initRappid.opmModel.getLogicalElementByVisualId(link.get('id')).sourceLogicalConnection != null) {
        // redraw source arc
        initRappid = initRappid === null ? link.getSourceArcOnLink().initRappid : initRappid;
        link.drawArc(initRappid, 'source', element, link.get('source').port, 'outbound');
      }
      if (link && initRappid.opmModel.getLogicalElementByVisualId(link.get('id')) != null &&
        initRappid.opmModel.getLogicalElementByVisualId(link.get('id')).targetLogicalConnection != null && goToOther) {
        // redraw target arc
        initRappid = initRappid === null ? link.getTargetArcOnLink().initRappid : initRappid;
        this.redrawAllArcs(link.getTargetElement(), initRappid, false);
      }
    }.bind(this));
    targetLinks.forEach(function (link) {
      if (link && initRappid.opmModel.getLogicalElementByVisualId(link.get('id')) != null &&
        initRappid.opmModel.getLogicalElementByVisualId(link.get('id')).targetLogicalConnection != null) {
        // redraw target arc
        initRappid = initRappid === null ? link.getTargetArcOnLink().initRappid : initRappid;
        link.drawArc(initRappid, 'target', element, link.get('target').port, 'inbound');
      }
      if (link && initRappid.opmModel.getLogicalElementByVisualId(link.get('id')) != null &&
        initRappid.opmModel.getLogicalElementByVisualId(link.get('id')).sourceLogicalConnection != null && goToOther) {
        // redraw source arc
        initRappid = initRappid === null ? link.getSourceArcOnLink().initRappid : initRappid;
        this.redrawAllArcs(link.getSourceElement(), initRappid, false);
      }
    }.bind(this));
  }
  // when object is moved to a new position the old arc is still on the screen until it is removed and redrawn on the screen
  // so in this time we make it transparent
  static makeThingArcsTransparent(element, initRappid, goToOther) {
    const sourceLinks = initRappid.graph.getConnectedLinks(element, { outbound: true });
    const targetLinks = initRappid.graph.getConnectedLinks(element, { inbound: true });
    sourceLinks.forEach(function (link) {
      if (link && link.getSourceArcOnLink()) {
        link.getSourceArcOnLink().getArcVec().attr('stroke', 'transparent');
      }
      if (link && link.getTargetArcOnLink() && goToOther) {
        this.makeThingArcsTransparent(link.getTargetElement(), initRappid, false);
      }
    }.bind(this));
    targetLinks.forEach(function (link) {
      if (link && link.getTargetArcOnLink()) {
        link.getTargetArcOnLink().getArcVec().attr('stroke', 'transparent');
      }
      if (link && link.getSourceArcOnLink() && goToOther) {
        this.makeThingArcsTransparent(link.getSourceElement(), initRappid, false);
      }
    }.bind(this));
  }
  // when link is clicked we will make the arcs of it transparent until redrawing the new arc
  static makeLinksArcsTransparent(link) {
    if (link.getSourceArcOnLink()) {
      link.getSourceArcOnLink().getArcVec().attr('stroke', 'transparent');
    }
    if (link.getTargetArcOnLink()) {
      link.getTargetArcOnLink().getArcVec().attr('stroke', 'transparent');
    }
  }
  static redrawLinkArcs(link, initRappid) {
    if (link.getTargetArcOnLink()) {
      // redraw target arc
      link.drawArc(initRappid, 'target', link.getTargetElement(), link.get('target').port, 'inbound');
    }
    if (link.getSourceArcOnLink()) {
      // redraw source arc
      link.drawArc(initRappid, 'source', link.getSourceElement(), link.get('source').port, 'outbound');
    }
  }
  // changing the arc type on the logical layer of the system
  toggleArcType(arc) {
    this.initRappid.getOpmModel().logForUndo('OR/XOR logical relation type change');
    let logicalLink = this.initRappid.opmModel.getLogicalElementByVisualId(this.linksArray[0].id);
    let newArcType =  this.getArcType();
    if (arc === 'NOT') {
      newArcType =  LinkLogicalConnection.Not;
    } else {
      newArcType = (newArcType === LinkLogicalConnection.Xor) ? LinkLogicalConnection.Or : LinkLogicalConnection.Xor;
      const canChange = this.initRappid.getOpmModel().canChangeArcType(newArcType, this.getLinksArray().map(l => l.getVisual()), this.getSide());
      if (canChange === false)
        return;
    }
    for (let i = 0; i < this.linksArray.length; i++) {
      logicalLink = this.initRappid.opmModel.getLogicalElementByVisualId(this.linksArray[i].id);
      if (this.mSide === 'source') {
        logicalLink.sourceLogicalConnection = newArcType;
      } else {
        logicalLink.targetLogicalConnection = newArcType;
      }
    }
    let newPath;
    // updating the new representation  - single arc or double arc
    if (newArcType === LinkLogicalConnection.Xor) {
      newPath = this.describeArc(this.x, this.y, 30, this.startAngle, this.endAngle);
      this.arcVec.attr('d', newPath);
    } else if (newArcType === LinkLogicalConnection.Or) {
      newPath = this.describeArc(this.x, this.y, 30, this.startAngle, this.endAngle) + ' ' +
        this.describeArc(this.x, this.y, 35, this.startAngle, this.endAngle);
      this.arcVec.attr('d', newPath);
    }
    this.initRappid.oplService.oplSwitch.next('urgent opl refresh');
  }
  // comvert polar to cartesian
  polarToCartesian(centerX, centerY, radius, angleInDegrees) {
    const angle = angleInDegrees >= 360 ? angleInDegrees - 360 : angleInDegrees;
    const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
    return {
      x: centerX + (radius * Math.cos(angleInRadians)),
      y: centerY + (radius * Math.sin(angleInRadians))
    };
  }
  // Creating a svg path with x,y center point, start angle and end angle
  // Creating a svg path with x,y center point, start angle and end angle
  describeArc(x, y, radius, startAngle, endAngle) {
    let start = this.polarToCartesian(x, y, radius, endAngle);
    let end = this.polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = 0;
    if (endAngle - startAngle >= 180) {
      const temp = start;
      start = end;
      end = temp;
    }
    const d = [
      // The previous drawing way
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y
      // 'M', start.x, start.y,
      // 'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      // 'L', x, y,
      // 'L', start.x, start.y
    ].join(' ');
    return d;
  }
  remove() {
    const linksOfArc = this.linksArray;
    for (let i = 0; i < linksOfArc.length; i++) {
      if (this.mSide === 'source') linksOfArc[i].sourceArcOnLink = null;
      else linksOfArc[i].targetArcOnLink = null;
    }
    this.arcVec.remove();
  }

  plusNextPort(direction, currentPortId, cellView) {
    const currentPortData = cellView.model.getPorts().find(p => p.id === currentPortId);
    let refX = currentPortData.args.x;
    if (refX.constructor.name.includes('tring'))
      refX = Number(refX.replace('%',''))/100;
    let refY = currentPortData.args.y;
    if (refY.constructor.name.includes('tring'))
      refY = Number(refY.replace('%',''))/100;
    let shape;
    const bbox = {x: 0, y: 0, width: cellView.model.get('size').width, height: cellView.model.get('size').height};
    if (cellView.model.constructor.name.includes('Process'))
      shape = new geometry.g.ellipse.fromRect(bbox);
    else shape = new geometry.g.rect(bbox);
    let p = new geometry.g.Point(refX * bbox.width, refY * bbox.height);
    const center = {x: bbox.width / 2, y:bbox.height / 2};
    const line = new geometry.g.line(center, p);
    line.setLength(line.length() + 8000);
    const angle = direction === 'plus' ? -10 : 10;
    line.rotate(center, angle*(bbox.height/bbox.width));
    p = line.pointAt(1);
    const nextPoint = shape.intersectionWithLineFromCenterToPoint(p);
    const newPort = cellView.model.addCustomPort(nextPoint);
    return newPort;
  }

  rightClickArcPopup() {
    const this_ = this;
    const relevantLinks = this_.getLinksArray();
    const elementInRelation = relevantLinks[0].source().id === relevantLinks[1].source().id ? 'source' : 'target';
    const port = relevantLinks[0].get(elementInRelation).port;
    const elementCell = this_.initRappid.graph.getCell(relevantLinks[0].get(elementInRelation).id);
    const cellView = this_.initRappid.paper.findViewByModel(elementCell);
    const maxPort = elementCell.constructor.name.includes('State') ? 15 : 29;
    const arcsPopup = (new joint.ui.Popup({
      events: {
        'click .Popup.BtnDelete': function () {
          this_.remove();
          let element;
          this_.linksArray.forEach(link => {
            const logicalLink = this_.initRappid.opmModel.getLogicalElementByVisualId(link.id);
            if (this_.mSide === 'source' && logicalLink) {
              logicalLink.disconnectSourceLogicalConnectionAllVisuals();
              link.set('source', { id: link.getSourceElement().get('id'), port: null });
              element = link.getSourceElement();
            } else if (logicalLink) {
              logicalLink.disconnectTargetLogicalConnectionAllVisuals();
              link.set('target', { id: link.getTargetElement().get('id'), port: null });
              element = link.getTargetElement();
            }
          });
          if (element)
            Arc.redrawAllArcs(element, this_.initRappid, true);
          cellView.model.removeUnusedPorts();
          this.remove();
        },
        'click .Popup.BtnPlus': function () {
          const newPort = this_.plusNextPort('plus', port, cellView);
          for (const link of relevantLinks) {
            link.set(elementInRelation, {id: link.get(elementInRelation).id, port: newPort});
            const visual = this_.initRappid.opmModel.getVisualElementById(link.id) as OpmLink;
            if (elementInRelation === 'source')
              visual.sourceVisualElementPort = newPort;
            else visual.targetVisualElementPort = newPort;
          }
          Arc.redrawAllArcs(elementCell, this_.initRappid, true);
          if (elementInRelation === 'source')
            relevantLinks[0].getSourceArcOnLink().rightClickArcPopup();
          else relevantLinks[0].getTargetArcOnLink().rightClickArcPopup();
          cellView.model.removeUnusedPorts();
        },
        'click .Popup.BtnClose': function () {this.remove(); },
        'click .Popup.BtnMinus': function () {
          const newPort = this_.plusNextPort('minus', port, cellView);
          for (const link of relevantLinks) {
            link.set(elementInRelation, {id: link.get(elementInRelation).id, port: newPort});
            const visual = this_.initRappid.opmModel.getVisualElementById(link.id) as OpmLink;
            if (elementInRelation === 'source')
              visual.sourceVisualElementPort = newPort;
            else visual.targetVisualElementPort = newPort;
          }
          Arc.redrawAllArcs(elementCell, this_.initRappid, true);
          if (elementInRelation === 'source')
            relevantLinks[0].getSourceArcOnLink().rightClickArcPopup();
          else relevantLinks[0].getTargetArcOnLink().rightClickArcPopup();
          cellView.model.removeUnusedPorts();
        },
      },
      content: [
        '<div style="text-align: center; padding-bottom:4px; font-family: Roboto, Helvetica Neue, sans-serif;">Port Movement:&nbsp;&nbsp;' +
        '<button class="Popup BtnPlus">&nbsp;+&nbsp;</button><button class="Popup BtnMinus">&nbsp;-&nbsp;</button></div>',
        '<button class="Popup BtnDelete">Remove Relation</button>&nbsp;<button class="Popup BtnClose">Close</button>'],
      target: cellView.el
    }));
    arcsPopup.render();
    stylePopup(true);
  }
  // stylePopup() {
  //   const ppup = document.getElementsByClassName('joint-popup joint-theme-modern')[0] as HTMLDivElement;
  //   ppup.style.borderColor = '#d6d6d6';
  //   ppup.style.height = '77px';
  //   ppup.style.top = String(Number(ppup.style.top.substr(0, ppup.style.top.length - 2)) + 20) + 'px';
  //   ppup.style.background = 'rgba(251, 251, 251, 0.91)';
  //   ppup.style.boxShadow =  '0px 2px 4px rgba(198, 198, 198, 0.64)';
  //   ppup.style.color = '#1a3763';
  //   // this line hides the ugly triangle at the top of the popup
  //   $('.joint-popup').addClass('noBefore');
  // }
  getArcVec() {
    return this.arcVec;
  }
  getPort() {
    return this.port;
  }
  getSide() {
    return this.mSide;
  }
  getLinksArray() {
    return this.linksArray;
  }
  getArcType() {
    const logicalLink = this.initRappid.opmModel.getLogicalElementByVisualId(this.linksArray[0]?.id);
    if (!logicalLink)
      return 1;
    const existingArcType = this.mSide === 'source' ? logicalLink.sourceLogicalConnection : logicalLink.targetLogicalConnection;
    return existingArcType;
  }
}
