import { OpmVisualElement } from './VisualPart/OpmVisualElement';
import { OpmLink } from "./VisualPart/OpmLink";
import { OpmVisualProcess } from "./VisualPart/OpmVisualProcess";
import { OpmProceduralLink } from "./VisualPart/OpmProceduralLink";
import { OpmStructuralLink } from "./VisualPart/OpmStructuralLink";
import { util } from "jointjs";
import uuid = util.uuid;
import { OpmVisualThing } from "./VisualPart/OpmVisualThing";
import { OpmVisualEntity } from "./VisualPart/OpmVisualEntity";
import { OpmLogicalEntity } from "./LogicalPart/OpmLogicalEntity";
import { OpmLogicalProcess } from "./LogicalPart/OpmLogicalProcess";
import { OpmLogicalElement } from './LogicalPart/OpmLogicalElement';
import {OpmImage} from "./OpmImage";
import {getInitRappidShared} from '../configuration/rappidEnviromentFunctionality/shared';
import {linkType} from "./ConfigurationOptions";
import {OpmFundamentalLink} from "./VisualPart/OpmFundamentalLink";
import {OpmLogicalThing} from "./LogicalPart/OpmLogicalThing";

export class OpmOpd {
  visualElements: Array<OpmVisualElement>;
  children: Array<OpmOpd>;
  notes;
  noteLinks;
  name: string;
  id: string;
  parendId: string;
  permissions: any;
  stencil;
  shape: OpmImage = null;
  stereotypeOpd = false;
  requirementsOpd = false;
  requirementViewOf: string; // What is the requirement that is represented in this view opd.
  isHidden = false;
  isFlatteningOpd = false;
  isRangesOpd = false;
  isViewOpd = false;
  sharedOpdWithSubModelId: string;
  belongsToSubModel: string;
  subModelEditDate: string;

  constructor(name: string) {
    this.visualElements = new Array<OpmVisualElement>();
    this.children = new Array<OpmOpd>();
    this.notes = new Array();
    this.noteLinks = new Array();
    this.name = name;
    this.stencil = true;
    this.id = name === 'SD' ? 'SD' : uuid();
    if (this.id === 'SD')
      this.parendId = 'SD';
  }

  add(visualElement: OpmVisualElement) {
    // TODO: Eliminate this if
    if (!this.visualElements.includes(visualElement))
      this.visualElements.push(visualElement);
  }

  addElements(visualElements: Array<OpmVisualElement>) {
    for (let i = 0; i < visualElements.length; i++)
      this.add(visualElements[i]);
  }

  SetParent(parentId: string) {
    this.parendId = parentId;
  }

  setHidden() {
    this.isHidden = true;
  }

  setVisible() {
    this.isHidden = false;
  }

  removeByIndex(opmVisualElementIndex) {
    this.visualElements.splice(opmVisualElementIndex, 1);
  }

  public isStereotypeOpd(): boolean {
    return this.stereotypeOpd;
  }

  public isRequirementsOpd(): boolean {
    return this.requirementsOpd;
  }

  public setAsStereotypeOpd() {
    this.stereotypeOpd = true;
  }

  public setAsRequirementsOpd() {
    this.requirementsOpd = true;
  }

  remove(opmVisualElementId) {
    for (let i = this.visualElements.length - 1; i >= 0; i--) {
      if (this.visualElements[i].id === opmVisualElementId) {
        this.visualElements.splice(i, 1);
        break;
      }
    }
  }

  removeVisual(visual: OpmVisualElement) {
    for (let i = this.visualElements.length - 1; i >= 0; i--) {
      if (this.visualElements[i] === visual) {
        this.visualElements.splice(i, 1);
        break;
      }
    }
  }


  removeNote(noteId) {
    for (let i = this.notes.length - 1; i >= 0 ; i--) {
      if (this.notes[i].id === noteId) {
        this.notes.splice(i, 1);
        break;
      }
    }
  }

  // removing note link from the array
  removeNoteLink(linkId) {
    for (let i = this.noteLinks.length - 1; i >= 0 ; i--) {
      if (this.noteLinks[i].id === linkId) {
        this.noteLinks.splice(i, 1);
        break;
      }
    }
  }

  removeAll() {
    for (let i = 0; i < this.visualElements.length; i++) {
      this.remove(this.visualElements[i]);
    }
  }

  createOpdFromJson(jsonOpd, opmModel) {
    if (jsonOpd.notes) {
      for (let i = 0; i < jsonOpd.notes.length; i++) {
        this.addNote(jsonOpd.notes[i], i);
      }
    }
    if (jsonOpd.noteLinks) {
      for (let i = 0; i < jsonOpd.noteLinks.length; i++) {
        this.addNoteLink(jsonOpd.noteLinks[i]);
      }
    }
    if (jsonOpd.isHidden)
      this.isHidden = jsonOpd.isHidden;
    if (jsonOpd.isRangesOpd)
      this.isRangesOpd = jsonOpd.isRangesOpd;
    if (jsonOpd.isViewOpd)
      this.isViewOpd = jsonOpd.isViewOpd;
    if (jsonOpd.requirementsOpd)
      this.requirementsOpd = jsonOpd.requirementsOpd;
    if (jsonOpd.requirementViewOf)
      this.requirementViewOf = jsonOpd.requirementViewOf;
    if (jsonOpd.sharedOpdWithSubModelId)
      this.sharedOpdWithSubModelId = jsonOpd.sharedOpdWithSubModelId;
    if (jsonOpd.belongsToSubModel)
      this.belongsToSubModel = jsonOpd.belongsToSubModel;
    if (jsonOpd.subModelEditDate)
      this.subModelEditDate = jsonOpd.subModelEditDate;
  }

  getThingLinks(thingID) {
    const thingLinks = this.visualElements.filter((elm) =>
      elm instanceof OpmLink && elm.visible !== false &&
      (elm.sourceVisualElement.id === thingID ||
        elm.targetVisualElements[0].targetVisualElement.id === thingID)
    );
    return thingLinks;
  }

  getThingProceduralLinks(thingID) {
    const thingLinks = this.visualElements.filter((elm) =>
      elm instanceof OpmProceduralLink &&
      (elm.sourceVisualElement.id === thingID ||
        elm.targetVisualElements[0].targetVisualElement.id === thingID)
    );
    return thingLinks;
  }

  getThingStructuralLinks(thingID) {
    const thingLinks = this.visualElements.filter((elm) =>
      elm instanceof OpmStructuralLink &&
      (elm.sourceVisualElement.id === thingID ||
        elm.targetVisualElements[0].targetVisualElement.id === thingID)
    );
    return thingLinks;
  }

  addNote(note, index?) {
    // support for old notes without 2 text boxes.
    if (note.text) {
      note.title = 'Note ' + (index + 1) + ' title';
      note.content = note.text;
      delete note['text'];
      if (note.fill === '#FFFC7F')
        note.fill = '#fff7d1';
    }
    this.notes.push(note);
  }

  addNoteLink(linkParams) { // adding new note link to array
    this.noteLinks.push(linkParams);
  }

  updateNote(noteData, noteCell) {
    const noteUpdate = this.notes.filter(note => (note.id === noteCell.cid))[0];
    const attributes = {size: noteCell.get('size'), position: noteCell.get('position'), id: noteCell.cid}
    if (noteUpdate && noteCell && noteData) {
      noteUpdate.size = noteCell.get('size');
      noteUpdate.position = noteCell.get('position');
      noteUpdate._content = noteData._content;
      noteUpdate._modifiedBy = noteData.modifiedBy;
      noteUpdate._modifiedDate = noteData.modifiedDate;
      noteUpdate._type = noteData.type;
    }
  }

  findRoot() {
    let j;
    for (let k = 0; k < this.visualElements.length; k++)
      if (this.visualElements[k] instanceof OpmLink) {
        let link = <OpmLink>this.visualElements[k];
        for (j = 0; j < this.visualElements.length; j++) {
          if (this.visualElements[j] instanceof OpmLink) {
            let linkk = <OpmLink>this.visualElements[j];
            if (link.sourceVisualElement.id == linkk.targetVisualElements[0].targetVisualElement.id)
              break;
          }
        }
        if (j === this.visualElements.length)
          return link.sourceVisualElement;
      }
    for (let k = 0; k < this.visualElements.length; k++)
      if (this.visualElements[k] instanceof OpmVisualThing)
        return this.visualElements[k];
  }

  getOutboundThings(thing) {
    let outboundThings = [];
    for (let k = 0; k < this.visualElements.length; k++) {
      if (this.visualElements[k] instanceof OpmLink) {
        let link = <OpmLink>this.visualElements[k];
        if (link.sourceVisualElement.id === thing.id)
          outboundThings.push(link.targetVisualElements[0].targetVisualElement);
      }
    }
    return outboundThings;
  }

  layoutHierarchically() {
    const root = <OpmVisualThing>this.findRoot();
    root.xPos = 450;
    root.yPos = 100;
    root.width = 135;
    root.height = 60;
    let queue = [];
    const isOrdered = (vis) => vis.getRefineeInzoom() && vis.getRefineeUnfold();
    let nextLevelThings = this.getOutboundThings(root);
    nextLevelThings.forEach((child) => queue.push({thing: child, level: 1}));
    let x = 450;
    let y = 10;
    let lastThing = root;
    let idx = 0;
    while (queue.length > 0) {
      let current = queue.shift();
      let nextLevelThings = this.getOutboundThings(current["thing"]);
      if (nextLevelThings.length != 0) {
        nextLevelThings.map((child) => queue.push({thing: child, level: current["level"] + 1}));
      }
      if (lastThing["level"] !== current["level"]) {
        lastThing = current;
        if (current["level"] === 1 && isOrdered(root)) {
          x = root.xPos - 170;
          y = root.yPos + 200 + idx * 80;
          idx++;
        } else {
          x = 20;
          y = y + lastThing["thing"].height + 200;
        }
      } else {
        if (current["level"] === 1 && isOrdered(root)) {
          x = root.xPos - 170;
          y = root.yPos + 200 + idx * 80;
          idx++;
        }
        else
          x += lastThing["thing"].width + 20;
      }
      current["thing"].setPos(x, y);
    }
    if (isOrdered(root)) {
      root.getLinks().outGoing.filter(l => l.type === linkType.Aggregation).forEach(link =>
        (<OpmFundamentalLink>link).setSymbolPos(link.source.xPos + 45, link.source.yPos + 170))
    }
  }

  isEmpty() {
    return this.visualElements.length === 0;
  }

  disconnectRefineables() {
    for (let k = 0; k < this.visualElements.length; k++) {
      if ((<OpmVisualThing>(this.visualElements[k])).disconnectRefinable)
        (<OpmVisualThing>(this.visualElements[k])).disconnectRefinable();
    }
  }

  getInzoomedThing() {
    for (let k = 0; k < this.visualElements.length; k++) {
      const refineable = <OpmVisualThing>((<OpmVisualThing>this.visualElements[k]).refineable);
      if (refineable && refineable.refineeInzooming === this.visualElements[k] && refineable !== refineable.refineeInzooming) {
        return this.visualElements[k];
      }
    }
    const possible = this.visualElements.filter(v => {
      const refineable = <OpmVisualThing>((<OpmVisualThing>v).refineable);
      if (refineable && refineable.refineeInzooming === v) {
        return true;
      }
      return false;
    });
    if (possible.length === 1)
      return possible[0];
    return null;
  }

  getUnfoldedThing() {
    for (let k = 0; k < this.visualElements.length; k++) {
      const refineable = <OpmVisualThing>((<OpmVisualThing>this.visualElements[k]).refineable);
      if (refineable && refineable.refineeUnfolding === this.visualElements[k] && refineable !== refineable.refineeUnfolding) {
        return this.visualElements[k];
      }
    }
    const possible = this.visualElements.filter(v => {
      const refineable = <OpmVisualThing>((<OpmVisualThing>v).refineable);
      if (refineable && refineable.refineeUnfolding === v) {
        return true;
      }
      return false;
    });
    if (possible.length === 1)
      return possible[0];
    return null;
  }

  getDisplayFullName() {
    return this.getNumberedName() + ((this.getNumberedName() === 'SD') ? '' : ': ' + this.getName());
  }

  getName() {
    const readOnly = !!(this.sharedOpdWithSubModelId || this.belongsToSubModel);

    if (this.name === 'SD')
      return this.name;

    if (this.sharedOpdWithSubModelId) {
      return this.name + ' Subsystem Model View';
    }

    let thing = this.getInzoomedThing();
    if (thing != null)
      return (<OpmLogicalEntity<OpmVisualEntity>>thing.logicalElement).getBareName() + ' ' + 'in-zoomed' + (readOnly ? ' (read only)' : '');
    thing = this.getUnfoldedThing();

    if (thing != null) {
      let addition = '';
      const logical = thing.logicalElement as OpmLogicalThing<OpmVisualThing>;
      if (logical.isSatisfiedRequirementObject()) {
        const owner = logical.opmModel.getOwnerOfRequirementByRequirementLID(logical.lid);
        addition += ' of ' + owner.text;
      }
      return (<OpmLogicalEntity<OpmVisualEntity>>thing.logicalElement).getBareName() + addition + ' ' + 'unfolded' + (readOnly ? ' (read only)' : '');
    }

    return this.name + (readOnly ? ' (read only)' : '');
  }

  getDefaultName() {
    let firstProcessName = 'opmModelOpl';
    for (let i = 0; i < this.visualElements.length; i++) {
      if (this.visualElements[i] instanceof OpmVisualProcess) {
        firstProcessName = (<OpmLogicalProcess>this.visualElements[i].logicalElement).text;
        firstProcessName = firstProcessName + ' System';
        return firstProcessName;
      }
    }
    return firstProcessName;
  }

  beautify(root: OpmVisualThing, padding = 40): void {

    const m_const = root.height / root.width;

    const between = function (a, b, c): boolean {
      return (a <= b) && (b <= c);
    };

    const intersect = function (aX, aY, bX, bY): boolean {
      return ((between(aX[0], bX[0], aX[1]) || between(aX[0], bX[1], aX[1]))
        && ((between(aY[0], bY[0], aY[1]) || between(aY[0], bY[1], aY[1]))));
    };

    const moveChildren = function (thing: OpmVisualThing, xMove, yMove) {
      for (let i = 0; i < thing.children.length; i++) {
        thing.children[i].xPos += xMove;
        thing.children[i].yPos += yMove;
      }
    }

    let things = [];
    for (let i = 0; i < this.visualElements.length; i++)
      if ((<OpmVisualThing>this.visualElements[i]).fatherObject)
        things.push((<OpmVisualThing>this.visualElements[i]).fatherObject);
      else
        things.push(this.visualElements[i]);

    const toMove = [root];
    while (toMove[0]) {
      const current = toMove[0];
      things = things.filter(t => t !== current);
      const currentX = [current.xPos, current.xPos + current.width];
      const currentY = [current.yPos, current.yPos + current.height];
      for (let i = 0; i < things.length; i++) {
        const thisThing = things[i];
        const thisX = [thisThing.xPos, thisThing.xPos + thisThing.width];
        const thisY = [thisThing.yPos, thisThing.yPos + thisThing.height];
        if (intersect(currentX, currentY, thisX, thisY) === false)
          continue;

        /*const diff = Math.abs(currentX[1] - thisX[0]) + padding;
        thisThing.xPos += diff;*/

        // move ThisThing
        let xOff = (thisX[0] + (thisThing.width / 2)) - (root.xPos + (root.width / 2));
        if (xOff === 0)
          xOff = 1;
        const yOff = (thisY[0] + (thisThing.height / 2)) - (root.yPos + (root.height / 2));
        const m = yOff / xOff;
        let diff;
        if (Math.abs(m) < m_const) {
          diff = Math.abs(currentX[1] - thisX[0]) + padding; // Move right
          if (xOff < 0) // Move left
            diff = (-1) * (Math.abs(currentX[0] - thisX[1]) + padding);
          thisThing.xPos += diff;
          thisThing.yPos += m * diff;
          moveChildren(thisThing, diff, m * diff);
        } else {
          diff = Math.abs(currentY[1] - thisY[0]) + padding; // Move Down
          if (yOff < 0) // Move Up
            diff = (-1) * (Math.abs(currentY[0] - thisY[1]) + padding);
          thisThing.xPos += diff / m;
          thisThing.yPos += diff;
          moveChildren(thisThing, diff / m, diff);
        }

        if (toMove.findIndex(t => t === thisThing) === -1)
          toMove.push(thisThing);
      }
      toMove.shift();
    }
  }

  getVisualElementByLogical(logicalElement: OpmLogicalElement<OpmVisualElement>): OpmVisualElement {
    // TODO: needs to be changed to filter - cuz we can have few visuals with same logical at the same opd.
    return this.visualElements.find(v => v.logicalElement === logicalElement);
  }

  getOpdDepth() {
    let counter = 0;
    let currentOpd: OpmOpd = this;
    if (this.isHidden || this.isStereotypeOpd() || this.isRequirementsOpd())
      return 0;
    const model = this.visualElements[0].logicalElement.opmModel;
    while (currentOpd.getName() !== 'SD') {
      counter += 1;
      currentOpd = model.getOpd(currentOpd.parendId);
    }
    return counter;
  }

  getNumberedName() {
    const init = getInitRappidShared();
    if (init.getTreeView() && init.getTreeView().getNodeById(this.id)) {
      return init.getTreeView().getNodeById(this.id).name + init.getTreeView().getNodeById(this.id).subTitle;
    } else {
      return this.getName();
    }
  }

  setAsViewOpd() {
    this.isViewOpd = true;
  }

  hasVisual(visual: OpmVisualElement): boolean {
    return !!(this.visualElements.find(v => v == visual));
  }

}
