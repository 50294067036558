import { OpmLogicalEntity } from './OpmLogicalEntity';
import { Affiliation, Essence } from '../ConfigurationOptions';
import { OpmVisualThing } from '../VisualPart/OpmVisualThing';
import { OpmModel } from '../OpmModel';
import { OpmStereotype } from '../OpmStereotype';
import { BelongsToStereotypTextModule, StereotypeModule } from './components/StereotypeModule';
import {SimulationModule} from "./components/SimulationModule";
import {ConfigurationsTextModule} from "./components/configurationsTextModule";
import {HiddenAttributesModule} from "../hiddenAttributes/hidden-attributes-module";
import {SatisfiedRequirement, SatisfiedRequirementSetModule} from "../hiddenAttributes/satisfied-requirement-set";
import {OpmVisualObject} from "../VisualPart/OpmVisualObject";

export abstract class OpmLogicalThing<T extends OpmVisualThing> extends OpmLogicalEntity<T> {

  private _essence: Essence;
  private _affiliation: Affiliation;
  private refineable_ = false;
  private refineeInzooming_ = false;
  private refineeUnfolding_ = false;
  private backgroundImageUrl: string = '';
  private stereotype: OpmStereotype;
  private stereotypeTextModule: StereotypeModule;
  // for adding the <<>> of the stereotype
  private belongsToStereotypeTxtModule: BelongsToStereotypTextModule;
  // used for marking the element as part which has brought from a stereotype.
  private belongsToStereotyped: OpmLogicalThing<OpmVisualThing>;
  public equivalentFromStereotypeLID: string;
  shouldBeGreyed = false;
  private simulationModule: SimulationModule;
  public isMainThing = false;
  private configurationsModule: ConfigurationsTextModule;
  public readonly hiddenAttributesModule: HiddenAttributesModule;
  public textForListLogical: string;

  public getName(): string {
    return this.textModule.getName();
  }

  constructor(params, model: OpmModel) {
    super(params, model);

    if (params && params.shouldBeGreyed)
      this.shouldBeGreyed = params.shouldBeGreyed;
    this.text = this.getNumberedName();
    this.stereotypeTextModule = new StereotypeModule(this);
    this.configurationsModule = new ConfigurationsTextModule(this);
    this.belongsToStereotypeTxtModule = new BelongsToStereotypTextModule(this);
    this.textModule.addTextualModules(this.stereotypeTextModule);
    this.textModule.addTextualModules(this.belongsToStereotypeTxtModule);
    this.textModule.addTextualModules(this.configurationsModule);
    if (params && params.sterotypeId)
      this.stereotype = this.opmModel.stereotypes.getStereotypeById(params.sterotypeId);
    if (params?.equivalentFromStereotypeLID)
      this.equivalentFromStereotypeLID = params.equivalentFromStereotypeLID;
    this.simulationModule = new SimulationModule(this, params ? params.simulationParams : undefined);
    this.hiddenAttributesModule = new HiddenAttributesModule(params?.satisfiedRequirementsSetParams, this.opmModel);
    this.backgroundImageUrl = params?.backgroundImageUrl || '';
  }

  getSimulationParams() {
    return this.simulationModule.simulationParams;
  }

  isSimulated(): boolean {
    return this.simulationModule.simulationParams.simulated;
  }

  resetSimulationParams() {
    this.simulationModule.resetParams();
  }

  public getRandomValues(numberOfValues: number): Array<any> {
    return this.simulationModule.getRandomValues(numberOfValues);
  }

  setBelongsToStereotyped(element: OpmLogicalThing<OpmVisualThing>) {
    this.belongsToStereotyped = element;
  }

  getBelongsToStereotyped() {
    return this.belongsToStereotyped;
  }


  setShouldBeGreyed(value: boolean) {
    this.shouldBeGreyed = value;
  }

  get essence(): Essence {
    return this._essence;
  }
  set essence(essence: Essence) {
    this._essence = essence;
  }
  get affiliation(): Affiliation {
    return this._affiliation;
  }

  set affiliation(affiliation: Affiliation) {
    this._affiliation = affiliation;
  }

  get refineable(): boolean {
    return this.refineable_;
  }

  get refineeInzooming(): boolean {
    return this.refineeInzooming_;
  }

  get refineeUnfolding(): boolean {
    return this.refineeUnfolding_;
  }

  cancelRefineable() {
    this.refineable_ = false;
  }

  setRefineable(): void {
    this.refineable_ = !this.refineable_;
  }

  setRefineeInzooming(): void {
    this.refineeInzooming_ = !this.refineeInzooming_;
  }

  setRefineeUnfolding(): void {
    this.refineeUnfolding_ = !this.refineeUnfolding_;
  }

  setStereotype(st: OpmStereotype) {
    this.stereotype = st;
  }

  getStereotype() {
    return this.stereotype;
  }

  updateParams(params) {
    super.updateParams(params);
    this.essence = params.essence;
    this.affiliation = params.affiliation;

    if (params && params.shouldBeGreyed)
      this.shouldBeGreyed = params.shouldBeGreyed;
    if (params && params.isMainThing)
      this.isMainThing = params.isMainThing;
    if (params && params.simulationParams)
      this.simulationModule.simulationParams = params.simulationParams;
    if (params?.equivalentFromStereotypeLID)
      this.equivalentFromStereotypeLID = params.equivalentFromStereotypeLID;
    }

  setParams(params) {
    super.setParams(params);
    if (params.sterotypeId)
      this.stereotype = this.opmModel.stereotypes.getStereotypeById(params.sterotypeId);
    if (params && params.simulationParams)
      this.simulationModule.simulationParams = params.simulationParams;
    if (params?.equivalentFromStereotypeLID)
      this.equivalentFromStereotypeLID = params.equivalentFromStereotypeLID;
  }

  abstract getNumberedName(): string;

  getThingParams() {
    const sterotypeId = this.stereotype && this.stereotype.id ? this.stereotype.id : undefined;
    const params = {
      essence: this.essence,
      affiliation: this.affiliation,
      shouldBeGreyed: this.shouldBeGreyed,
      isMainThing: this.isMainThing,
      simulationParams: this.getSimulationParams(),
      equivalentFromStereotypeLID: this.equivalentFromStereotypeLID,
      satisfiedRequirementsSetParams: this.hiddenAttributesModule.satisfiedRequirementSetModule.toJson(),
      backgroundImageUrl: this.backgroundImageUrl,
    };
    if (sterotypeId)
      params['sterotypeId'] = sterotypeId;
    if (this.getBelongsToStereotyped() && this.getBelongsToStereotyped().visualElements.length > 0)
      params['belongsToStereotyped'] = this.getBelongsToStereotyped().visualElements[0].id;
    return { ...super.getEntityParams(), ...params };
  }
  getThingParamsFromJsonElement(jsonElement) {
    const params = {
      essence: (jsonElement.essence === 0) ? Essence.Physical : Essence.Informatical,
      affiliation: (jsonElement.affiliation === 0) ? Affiliation.Systemic : Affiliation.Environmental,
      shouldBeGreyed: jsonElement.shouldBeGreyed,
    };
    return { ...super.getEntityParamsFromJsonElement(jsonElement), ...params };
  }

  public getDisplayText(): string {
    return this.textModule.getDisplayText();
  }

  abstract isComputational(): boolean;

  removeVisual(visual) {
    const visAtHiddenOpd = this.visualElements.find(vis => this.opmModel.getOpdByThingId(vis.id) && this.opmModel.getOpdByThingId(vis.id).isHidden === true);
    super.removeVisual(visual);
    if (this.visualElements.length === 1 && this.getStereotype() && visAtHiddenOpd) {
      const ret = this.opmModel.removeClonedStereotypeAndItsParts(this);
      return { removed: ret.removed };
    }
  }

  resetVisualsStrokeWidth() {
    for (const vis of this.visualElements)
      vis.strokeWidth = 2;
  }

  getBackgroundImageUrl(): string {
    return this.backgroundImageUrl;
  }

  setBackgroundImage(url: string) {
    this.backgroundImageUrl = url;
  }

  public getSatisfiedRequirementSetModule(): SatisfiedRequirementSetModule {
    return this.hiddenAttributesModule.satisfiedRequirementSetModule;
  }

  public createSatisfiedRequirementSet(visual: OpmVisualThing) {
    return this.hiddenAttributesModule.createSatisfiedRequirementSet(visual);
  }

  public hasRequirements(): boolean {
    return !!this.hiddenAttributesModule.satisfiedRequirementSetModule.getRequirementsSet();
  }

  public getAllRequirements(): Array<SatisfiedRequirement> {
    return this.hiddenAttributesModule.satisfiedRequirementSetModule.getRequirementsSet()?.getAllRequirements() || [];
  }

  public removeSingleRequirement(lidToRemove: string): Array<OpmVisualThing> {
    const logicalReqObject = this.opmModel.getLogicalElementByLid(lidToRemove) as OpmLogicalThing<OpmVisualThing>;
    const firstVis = logicalReqObject?.visualElements[0] as OpmVisualObject;
    if (firstVis.getRefineeUnfold() || firstVis.getRefineeInzoom() || logicalReqObject.getBelongsToStereotyped())
      return [];
    const ret = this.hiddenAttributesModule.satisfiedRequirementSetModule.removeSingleRequirement(lidToRemove);
    if (!this.hasRequirements() && this.visualElements.find(v => this.opmModel.getOpdByThingId(v.id)?.requirementsOpd))
      this.visualElements.find(v => this.opmModel.getOpdByThingId(v.id)?.requirementsOpd).remove(); // removing the visual from the requirements hidden opd because there is no need for it anymore.
    return ret;
  }
}

