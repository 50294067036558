import { ModelSchema } from '../../rappid-components/services/storage/model-object.class';

/*interface describing a stereotype information*/
export interface Stereotype extends ModelSchema {
  id: string;
  type: StereotypeType;
}

export interface WriteStereotypeSchema {
  id?: string,
  type: StereotypeType,
  name: string,
  description: string,
  currentOpd?: any
  opds?: Array<any>,
  logicalElements?: Array<any>,
}

/*enum representing the options of a actions */
export enum StereotypeStorageMode {
  LOAD,
  SAVE,
  SET
}

/*enum representing the options of a stereotype type */
export enum StereotypeType {
  System = 'SYS',
  Organization = 'ORG'
}

export interface DisplayStereotype {
  id: string ;
  type: StereotypeType ;
  name: string ;
  description: string;
}
