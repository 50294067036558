import {
  joint,
  _,
  vectorizer,
  isNumber,
  validationAlert
} from '../../../configuration/rappidEnviromentFunctionality/shared';

import {Component, OnInit, ViewChild, ElementRef, AfterViewInit} from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import * as $ from 'jquery';
import {exportValues} from '../../../configuration/elementsFunctionality/computationalPart';
import {UserService} from '../../../rappid-components/services/user.service';
import {Router} from '@angular/router';
import {OpmVisualThing} from '../../../models/VisualPart/OpmVisualThing';
import {DsmModel} from '../../../models/DSM/dsm.model';
import {timer} from 'rxjs';
import * as FileSaver from 'file-saver';
import {OpmModel} from "../../../models/OpmModel";
import {ContextService} from "../../app/context.service";
import {ServerFlatteningService} from "../../../rappid-components/services/server-flattening.service";


@Component({
  selector: 'opcloud-dsm-analysis',
  templateUrl: '../../../../../../client/src/app/modules/Settings/dsm-analysis/dsm-analysis.component.html',
  styleUrls: ['../../../../../../client/src/app/modules/Settings/dsm-analysis/dsm-analysis.component.css']
})
export class DsmAnalysisComponent implements OnInit {
  public content;
  public clusters;
  public service;
  public entitiesOptions = ['All things', 'Processes only', 'Objects only'];
  public ChooseThingOption;
  public linksOptions = ['All links', 'Procedural links', 'Structural links'];
  public ChooseLinkOption;
  public isLoading = false;
  private dsm;
  private readonly alternateOpmModel: OpmModel;
  currentUser;

  constructor(private initRappidService: InitRappidService, private userService: UserService, private router: Router, private contextService: ContextService, private serverFlatteningService: ServerFlatteningService) {
    this.service = initRappidService;
    this.ChooseThingOption = this.entitiesOptions[0];
    this.ChooseLinkOption = this.linksOptions[0];
    this.userService.user$.subscribe(user => this.currentUser = user);
    this.alternateOpmModel = new OpmModel();
    this.alternateOpmModel.fromJson(this.initRappidService.opmModel.toJson());
    this.dsm = new DsmModel(this.alternateOpmModel);
  }

  listeners() {
    const that = this;
    const table = document.getElementById('dsmTableHTML') as HTMLTableElement;
    for ( let i = 0; i < table.rows.length ; i++) {
      for ( let j = 0; j < table.rows[i].cells.length ; j++) {
        const item = table.rows[i].cells[j];
        item.onmouseenter = () => {
          that.highlightCell(item, i, j);
        };
      }
    }
  }

  highlightCell(item, k, m) {
    const table = document.getElementById('dsmTableHTML') as HTMLTableElement;
    for ( let i = 0; i < table.rows.length ; i++) {
      for (let j = 0; j < table.rows[i].cells.length; j++) {
        const current = table.rows[i].cells[j];
        if (i === k || j === m)
          current.style.backgroundColor = 'rgba(130,173,194,0.32)';
        else current.style.backgroundColor = 'white';
      }
    }
  }


  async clickCreateDSM() {
    let serverRet;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverRet = await this.serverFlatteningService.getFlattenedModelFromServer(true);
      if (!serverRet.success) {
        validationAlert('Unable to run Flattening algorithm on the BE server. Running it locally.');
      }
    }
    this.content = this.dsm.createDsm(this.ChooseLinkOption, this.ChooseThingOption, serverRet?.serverModel);
    const that = this;
    timer (0).subscribe( val => that.listeners());
  }

  async clickCreateAgentInstrumentDSM() {
    let serverRet;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverRet = await this.serverFlatteningService.getFlattenedModelFromServer(true);
      if (!serverRet.success) {
        validationAlert('Unable to run Flattening algorithm on the BE server. Running it locally.');
      }
    }
    this.content = this.dsm.createAgentInstrumentDSM(serverRet?.serverModel);
  }

  downloadDSM(){
    const dsmForDownload = this.content;
    for (const line of dsmForDownload) {
      for (let i = 0; i < line.length; i++) {
        const temp = line[i].replace(/(\r\n|\n|\r)/gm, " ");
        line[i] = temp;
        if (i === (line.length - 1)) {
          line[i] += '\n';
        }
      }
    }
    this.exportDSMValues(dsmForDownload, 'DSM' + this.service.opmModel.name);
  }

  exportDSMValues(exportArray, fileName) {
    const exportValuesFile = new Blob(exportArray, { type: 'text/csv' });
    FileSaver.saveAs(exportValuesFile, fileName + '.csv'); // Save the exported file.
  }

  ngOnInit() {
  }

  scroll() {
    window.scrollBy(100, 0);
  }

  increaseZoom() {
    const table = document.getElementById('dsmTableHTML') as any;
    const zoomVal = Number(table.style.zoom) + 0.05;
    table.style.zoom = String(zoomVal);
  }

  decreaseZoom() {
    const table = document.getElementById('dsmTableHTML');
    const zoomVal = Number((<any>table).style.zoom) - 0.05;
    (<any>table.style).zoom = String(zoomVal);
  }
  swapElements() {
    const row1 = (<HTMLInputElement>document.getElementById('row1'));
    const row2 = (<HTMLInputElement>document.getElementById('row2'));
    this.content = this.dsm.swapRows(Number(row1.value), Number(row2.value), this.content);
  }

  async partitioning() {
    const that = this;
    this.isLoading = true;
    let serverPartitioning;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverPartitioning = await this.serverFlatteningService.getPartitionsFromServer(this.content);
    }
    setTimeout( function() {
      that.content = serverPartitioning?.partitionedDSM || that.dsm.partitioning(that.content);
      that.isLoading = false;
      timer (0).subscribe( val => that.showBandedDSM());
    }, 800 );
  }

  async clustering() {
    this.isLoading = true
    const that = this;
    let serverClustersRes;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverClustersRes  = await this.serverFlatteningService.getModelClustersFromServer(that.content);
    }
    setTimeout( function() {
      that.clusters = serverClustersRes?.clusters || that.dsm.clustering(that.content);
      that.content = that.dsm.reorganizeDsmForClusters(that.content, that.clusters);
      timer (0).subscribe( val => that.showClusteredDSM());
      that.isLoading = false;
    }, 800 );
  }

  showClusteredModel() {
    if (!this.clusters) {
      this.isLoading = false;
      return validationAlert('You need to create clusters first.', 3500, 'error');
    }
    this.isLoading = true;
    this.service.isDSMClusteredView = { value: true, type: 'clustering' };
    const that = this;
    setTimeout( function() {
      that.dsm.colorClustersRegularModel(that.content, that.clusters);
      that.alternateOpmModel.name = '[Clustered] ' + that.alternateOpmModel.name;
      that.contextService.loadDSMFlatteningModel(that.alternateOpmModel);
      that.isLoading = false;
      that.router.navigate(['']);
    }, 800 );
  }

  showClusteredDSM() {
    if (!this.clusters) {
      this.isLoading = false;
      return validationAlert('You need to create clusters first.', 3500, 'error');
    }
    const table = (<HTMLTableElement>document.getElementById('dsmTableHTML'));
    let add = 0;
    for ( let i = 0; i < this.clusters.length; i++){
      for (let k = 0; k < this.clusters[i].length; k++){
        for (let j = 0; j < this.clusters[i].length; j++){
          table.rows[1 + add + k].cells[2 + add + j].style.backgroundColor = this.dsm.getRandomColor(i, this.clusters.length);
        }
      }
      add += this.clusters[i].length;
    }
    this.isLoading = false;
  }
  colorRow(rowNumber, odd) {
    const table = (<HTMLTableElement>document.getElementById('dsmTableHTML'));
    for ( let i = 0; i < table.rows[rowNumber].cells.length; i++) {
      if (odd) {
        //table.rows[rowNumber].cells[i].style.backgroundColor = 'blue';
      }
    }
    return table.rows[rowNumber].cells[1].innerText;
  }

  colorRowRange(startRowNumber, endRowNumber, odd){
    const parts = [];
    for (let i = startRowNumber; i <= endRowNumber; i++) {
      // this.colorRow(i);
      parts.push(this.colorRow(i, odd));
    }
    return parts;
  }

  showBandedDSM() {
    const table = (<HTMLTableElement>document.getElementById('dsmTableHTML'));
    let newStartRow = 1;
    let odd = true;
    this.clusters = [];
    for (let i = 2; i < this.content.length; i++) {
      for (let j = newStartRow ; j < i; j++) {
        if (this.content[i][j + 1] !== '') {
          // if (odd) {
          //   const part = this.colorRowRange(newStartRow, i - 1, odd);
          //   this.clusters.push(part);
          //   odd = false;
          // } else {
            const part = this.colorRowRange(newStartRow, i - 1, odd);
            this.clusters.push(part);
          //  odd = true;
          //}
          newStartRow = i;
        }
        if (i === this.content.length - 1) {
          const part = this.colorRowRange(newStartRow, i , odd);
          this.clusters.push(part);
        }

      }
    }
    let add = 0;
    for ( let i = 0; i < this.clusters.length; i++){
      for (let k = 0; k < this.clusters[i].length; k++){
        for (let j = 0; j < table.rows[k].cells.length; j++) {
          if (table.rows[1 + add + k]) { // When there are odd number of instrument and result links, it trys to color non existing line. This needs to be debug further.
            table.rows[1 + add + k].cells[j].style.backgroundColor = this.dsm.getRandomColor(i, this.clusters.length);
          }
        }
      }
      add += this.clusters[i].length;
    }
  }

  async showClusteredFLatModel() {
    if (!this.clusters || this.clusters.length === 0)
      return validationAlert('You need to create clusters first.', 3500, 'error');
    this.isLoading = true;
    this.service.isDSMClusteredView = { value: true, type: 'flattening' };
    let serverRet;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverRet = await this.serverFlatteningService.getFlattenedModelFromServer(true);
      if (!serverRet.success) {
        validationAlert('Unable to run Flattening algorithm on the BE server. Running it locally.');
      }
    }
    const that = this;
    setTimeout( function() {
      const modelToShow = that.dsm.colorClustersFlatModel(that.content, that.clusters, serverRet?.serverModel);
      modelToShow.name = '[Flattened] ' + modelToShow.name;
      that.contextService.loadDSMFlatteningModel(modelToShow);
      that.isLoading = false;
      that.router.navigate(['']);
    }, 800 );

    // const clustrs = [];
    //for (let j = 0; j < this.clusters.length; j++ ) {
    //  const clust = [];
    //  for (let i = 0; i < this.clusters[j].length; i++) {
    //    const visualToBeColored = this.service.opmModel.getVisualOfOpdByName(this.service.opmModel.opds[this.service.opmModel.opds.length - 1], this.service.opmModel.getThingNameFromDsmWithRowID(this.clusters[j][i], this.content));
    //    clust.push(visualToBeColored);
    //  }
    //  clustrs.push(clust);
    //}
    //console.log(clustrs);
    //let xy = 2000;
    //for (const cl of clustrs) {
    //  for (const el of cl) {
    //   el.xPos = xy;
    //    el.yPos = xy+100;
    //  }
    //  xy = xy + 500;
    // }
    //const opd = this.service.opmModel.opds[this.service.opmModel.opds.length - 1];
    //for (const cl of clustrs) {
    //  for (const el of cl) {
    //    if (el instanceof OpmVisualThing) {
    //      opd.beautify(el);
    //    }
    //  }
    //  xy = xy + 500;
    //}
  }

  getCellTitle(value) {
    if (value !== '' && isNumber(value)) {
      let number = Number(value);
      let isConditionOrEvent = 0;
      let addition = '';
      if (number > 99) {
        isConditionOrEvent = number % 10;
        number = Math.floor(number/10);
        if (isConditionOrEvent === 1)
          addition = ' Condition';
        else if (isConditionOrEvent === 2)
          addition = ' Event';
      }
      switch(number) {
        case 11:
          return 'Consumption' + addition;
        case 12:
          return 'Result' + addition;
        case 13:
          return 'Effect' + addition;
        case 14:
          return 'Invocation' + addition;
        case 16:
          return 'Agent' + addition;
        case 17:
          return 'Instrument' + addition;
        case 21:
          return 'Aggregation' + addition;
        case 22:
          return 'Generalization' + addition;
        case 23:
          return 'Exhibition' + addition;
        case 24:
          return 'Instantiation' + addition;
        case 25:
          return 'Unidirectional' + addition;
        case 26:
          return 'Bidirectional' + addition;
        default: return '';
      }
    }
    return '';
  }

  getPanelStyle() {
    if (this.content && this.content.length > 0)
      return {'margin-top': '-36px', 'width': '1050px', 'margin-left': 'calc(50% - 548px)'};
    return {'margin-top': '-36px', 'width': '1050px', 'margin-left': 'calc(50% - 225px)'};
  }

  getMainDivStyle() {
    if (this.isLoading)
      return {'filter': 'blur(2px)'};
    return {};
  }
}
