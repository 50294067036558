import {OpmVisualThing} from "../../VisualPart/OpmVisualThing";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {ChangeAction, ChangeActionResult} from "./changeAction.interface";
import {Essence, linkConnectionType, linkType} from "../../ConfigurationOptions";
import {ConfirmDialogDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog";

export class CannotBePhysicalChangeAction extends ChangeAction {

  async act(): Promise<ChangeActionResult> {
    const check = this.target.isLegalEssence(Essence.Informatical);
    // if it is possible to change the target essence
    if (check.isLegal) {
      let message = 'A physical object cannot be part of an informatical one.\n The Object can be changed to informatical.\n\n';
      message += `<span class="redText">This will effect all ${(<any>this.target.logicalElement).getBareName()} instances at:</span><br>`;
      for (const vis of this.target.logicalElement.visualElements) {
        const opd = this.init.opmModel.getOpdByThingId(vis.id);
        if (opd && !opd.isHidden) {
          message += '<span class="redText">' + opd.getDisplayFullName() + '</span>'+ '<br>';
        }
      }
      const ret = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, null, 350, {
        title: 'ERROR!!', message: message, titleColor: '#ff0000', closeName: 'Close',
        okName: 'Change part to informatical', centerText: true, allowMultipleDialogs: true
      }).afterClosed().toPromise();
      if (ret) {
        this.target.toggleEssence();
        return Promise.resolve({ changed: true });
      }
    }
    return Promise.resolve({ changed: false });
  }
}
