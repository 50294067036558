import {Component, Inject, OnInit, Optional, QueryList, ViewChildren} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {UserDetails, UserService} from "../../../rappid-components/services/user.service";
import {PermissionsService} from "../../../rappid-components/services/permissions/permissions.service";
import {StorageService} from "../../../rappid-components/services/storage.service";
import {OrganizationService} from "../../../rappid-components/services/organization.service";
import {GroupsService} from "../../../rappid-components/services/groups.service";
import {removeDuplicationsInArray, validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";
import { trigger, style, animate, transition } from '@angular/animations';

interface UserItem {
  read: boolean,
  write: boolean,
  owner: boolean,
  userData: UserDetails
}

interface GroupData {
  key: string,
  Administrators: Map<string, string>,
  Description: string,
  GroupID: string,
  Members: Map<string, string>,
  Name: string,
  Parent: string,
  subGroups: Array<string> | '',
}

interface GroupItem {
  read: boolean,
  write: boolean,
  owner: boolean,
  isOpen: boolean,
  groupData: GroupData,
  children?: Array<GroupItem>,
  depth?: number
}

export interface FolderPermissions {
  readIds: Array<string>,
  writeIds: Array<string>,
  ownerIds: Array<string>,
  groupsReadIds: Array<string>,
  groupsWriteIds: Array<string>,
  groupsOwnersIds: Array<string>,
  isModelsReadEnabled?: boolean
}

@Component({
  selector: 'opcloud-folder-permissions-dialog',
  templateUrl: './folder-permissions-dialog.component.html',
  styleUrls: ['./folder-permissions-dialog.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [   // :enter is alias to 'void => *'
        style({ opacity:0, height: '0px' }),
        animate(200, style({opacity:1, height: '*'}))
      ]),
      transition(':leave', [   // :leave is alias to '* => void'
        style({opacity:1,  height: '*'}),
        animate(100, style({opacity:0, height: '0px'}))
      ])
    ])
  ]
})
export class FolderPermissionsDialogComponent implements OnInit {

  private users: Array<UserItem>;
  private groups: Array<GroupItem>;
  private originalGroups: Array<any>;
  private isReadOnly: boolean;
  private folderId: string;
  private isModelsReadEnabled: boolean;
  private allUsersPermission: {
    read: boolean,
    write: boolean,
    owner: boolean
  }

  constructor(private orgService: OrganizationService, private groupService: GroupsService,
              private dialogRef: MatDialogRef<FolderPermissionsDialogComponent>, private userService: UserService,
              private permissions: PermissionsService, private storage: StorageService, @Inject(MAT_DIALOG_DATA) private data: any) {
    this.users = [];
    this.groups = [];
    this.originalGroups = [];
    this.folderId = this.data.folderID;
    this.isReadOnly = true;
    this.allUsersPermission = {
      read: false,
      write: false,
      owner: false
    }
  }

  ngOnInit() {
    this.collapseUsersAndGroups();
    this.loadData();
  }

  @ViewChildren('allTheseThings') groupsLoading: QueryList<any>;

  ngAfterViewInit() {
    this.groupsLoading.changes.subscribe(t => this.collapseUsersAndGroups());
  }

  async loadData(): Promise<void> {
    const userData = () => this.userService.user.userData;
    const org = userData().organization;
    const permissions = await this.storage.getFolderPermissions(this.folderId);
    const users = await this.orgService.getOrganizationUsers(org);
    this.users = users.map(us => {
      return {
        read: permissions.readIds.includes(us.uid) || permissions.readIds.includes('all'),
        write: permissions.writeIds.includes(us.uid) || permissions.writeIds.includes('all'),
        owner: permissions.ownerIds.includes(us.uid) || permissions.ownerIds.includes('all'),
        userData: us
      }
    });
    this.sortUsers();
    const groups = await this.orgService.getGroups(org);
    this.groups = groups.map(gr => {
      return {
        read: permissions.groupsReadIds.includes(gr.key),
        write: permissions.groupsWriteIds.includes(gr.key),
        owner: permissions.groupsOwnersIds.includes(gr.key),
        isOpen: false,
        groupData: gr
      }
    })
      .filter(item => item.groupData.Name && !item.groupData.Name.includes(org + ' All Users'))
      .sort((a,b) => a.groupData.Name[0].toLowerCase() > b.groupData.Name[0].toLowerCase() ? 1 : -1);
    const userGroups = [];
    for (const gi of this.groups) {
      if (gi.groupData.key && Object.keys(gi.groupData.Members || []).includes(userData().uid))
        userGroups.push(gi.groupData.key)
    }
    this.isReadOnly = !userData().SysAdmin && !userData().OrgAdmin && !permissions.ownerIds.includes(userData().uid) && !permissions.groupsOwnersIds.find(gid => userGroups.includes(gid));
    this.isModelsReadEnabled = !!permissions.isModelsReadEnabled;
    this.beautifyGroupsData();
    this.getGroupsOrdered();
    this.allUsersPermission.read = permissions.readIds.includes('all');
    this.allUsersPermission.write = permissions.writeIds.includes('all');
    this.allUsersPermission.owner = permissions.ownerIds.includes('all');
  }

  beautifyGroupsData(): void {
    this.groups = this.groups.sort((a,b) => this.getGroupDepth(a.groupData.key) > this.getGroupDepth(b.groupData.key) ? 1 : -1);
    for (const item of this.groups) {
      item.depth = this.getGroupDepth(item.groupData.key);
      item.children = item.children || [];
      item.children.push(...this.groups.filter(itm => itm.groupData.Parent === item.groupData.key));
    }
    this.originalGroups = [...this.groups];
    for (let i = this.groups.length - 1 ; i >= 0 ; i--)
      if (this.groups[i].groupData?.Parent) {
        const parentEntry = this.groups.find(ent => ent.groupData?.key === this.groups[i].groupData?.Parent);
        if (parentEntry) {
          parentEntry.children = parentEntry.children || [];
          parentEntry.children.push(this.groups[i]);
          this.groups.splice(i, 1);
        }
      }
  }

  selectAll($event, type: string): void {
    this.allUsersPermission[type] = $event.target.checked;
    this.users.forEach(item => item[type] = $event.target.checked);
  }

  updateItem($event, item, type: string): void {
    item[type] = $event.target.checked;
    if ($event.target.checked === false)
      this.allUsersPermission[type] = false;
    else if (this.users.length === this.users.filter(user => user[type]).length) {
      this.allUsersPermission[type] = true;
    }
  }

  toggleAllUsers($event): void {
    $($event.target.parentElement).siblings('ul').slideToggle('slow');
  }

  collapseUsersAndGroups() {
    $('.collapse').slideUp(0);
  }

  sumUsersPermissions(type: string): Array<string> {
    const hasPermission = this.users.filter(userItem => userItem[type] === true);
    if (this.allUsersPermission[type])
      return ['all'];
    else
      return hasPermission.map(item => item.userData.uid).filter(u => !!u);
  }

  sumGroupsPermissions(type: string): Array<string> {
    const hasPermission = this.originalGroups.filter(groupItem => groupItem[type] === true);
    return hasPermission.map(groupItem => groupItem.groupData.GroupID).filter(u => !!u);
  }

  save(): void {
    const ret: FolderPermissions = {
      readIds: this.sumUsersPermissions('read'),
      writeIds: this.sumUsersPermissions('write'),
      ownerIds: this.sumUsersPermissions('owner'),
      groupsReadIds: this.sumGroupsPermissions('read'),
      groupsWriteIds: this.sumGroupsPermissions('write'),
      groupsOwnersIds: this.sumGroupsPermissions('owner'),
      isModelsReadEnabled: this.isModelsReadEnabled
    }
    this.storage.updateFolderPermissions(this.folderId, ret).then((res) => {
      if (res.success)
        validationAlert('Updated Successfully.')
      else
        validationAlert(res.message, 5000, 'error');
      this.dialogRef.close(ret);
    }).catch(err => {});
  }

  close(): void {
    this.dialogRef.close();
  }

  getUserItemByUserId(memberId: string): UserItem {
    return this.users.find(userItem => userItem.userData.uid === memberId);
  }

  getGroupMembers(groupItem: GroupItem) {
    const keys = Object.keys(groupItem.groupData.Members || []);
    return keys.map(key => this.getUserItemByUserId(key)).filter(u => !!u);
  }

  changeArrow($event): void {
    const arrow = $($event.target.parentElement).children('i')[0];
    if (arrow.innerText === 'arrow_downward')
      arrow.innerText = 'arrow_upward';
    else
      arrow.innerText = 'arrow_downward';
  }

  getGroupDepth(groupKey: string): number {
    let ret = 0;
    let currentItem = this.groups.find(gi => gi.groupData.key === groupKey);
    while(currentItem) {
      ret++;
      if (currentItem.groupData.Parent === '')
        break;
      currentItem = this.groups.find(gi => gi.groupData.key === currentItem.groupData.Parent);
    }
    return ret;
  }

  getGroupsOrdered() {
    const ret = [];
    for (const gi of this.groups) {
      ret.push(gi);
      for (const child of gi.children)
        this.getSubGroups(gi, ret);
    }
    return ret;
  }

  getSubGroups(groupItem, arr) {
    if (!arr.includes(groupItem))
      arr.push(groupItem);
    for (const child of groupItem.children || [])
      this.getSubGroups(child, arr);
  }

  isParentOpen(groupItem: GroupItem): boolean {
    if (!groupItem.groupData.Parent || groupItem.groupData.Parent.length === 0)
      return true;
    const parent = this.originalGroups.find(gr => gr.groupData.key === groupItem.groupData.Parent);
    return parent?.isOpen;
  }

  getSubGroupsMembers(groupItem: GroupItem, arr = []) {
    arr.push(...Object.keys(groupItem.groupData.Members || []));
    for (const child of groupItem.children)
      this.getSubGroupsMembers(child, arr);
    return arr;
  }

  getSubGroupsMembersWithData(groupItem): Array<UserItem> {
    let members = this.getSubGroupsMembers(groupItem).map(uid => this.users.find(u => u.userData.uid === uid)).filter(us => !!us);
    members = removeDuplicationsInArray(members) as Array<UserItem>;
    members = members.sort((a,b) => a.userData?.Name > b.userData.Name ? 1 : -1);
    return members;
  }

  closeGroupItem(groupItem: GroupItem): void {
    groupItem.isOpen = !groupItem.isOpen;
    if (!groupItem.isOpen) {
      const subs = [];
      this.getSubGroups(groupItem, subs);
      subs.forEach(sub => sub.isOpen = false);
    }
  }

  selectGroupAndSubgroups($event, groupItem: GroupItem, permission: string): void {
    const value = (<HTMLInputElement>$event.target).checked;
    groupItem[permission] = value;
    const subGroups = [];
    this.getSubGroups(groupItem, subGroups);
    for (const sub of subGroups)
      sub[permission] = value;
    // const members = this.getSubGroupsMembersWithData(groupItem);
    // for (const member of members)
    //   member[permission] = value;
  }

  groupNameClick($event, groupItem: GroupItem): void {
    this.closeGroupItem(groupItem);
    this.changeArrow($event);
  }

  tickUserPermission(user: UserItem): void {
    if (this.isReadOnly)
      return;
    let val;
    if (user.read && user.write && user.owner)
      val = false;
    else
      val = true;

    user.read = val;
    user.write = val;
    user.owner = val;
  }

  sortUsers() {
    this.users = this.users.sort((a,b) => a.userData?.Name > b.userData?.Name ? 1 : -1);
  }

  toggleModelsReadEnabled($event: Event) {
    this.isModelsReadEnabled = (<HTMLInputElement>$event.target).checked;
  }
}
