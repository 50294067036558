import { Component, OnInit } from '@angular/core';
import {Observable} from "rxjs";
import {OrganizationService} from "../../../../rappid-components/services/organization.service";
import {
  removeDuplicationsInArray,
  validationAlert
} from "../../../../configuration/rappidEnviromentFunctionality/shared";
import {DatabaseService} from "../../../../database/database.service";

export interface SSOSubDomain {
  orgName: string,
  subSuffix: string
}

export interface SSODomain {
  orgName: string,
  orgId: string,
  editable: boolean,
  subDomains: Array<SSOSubDomain>,
  viewerAccounts?: boolean
}

export interface SSORequest {
  orgAddress: string,
  orgId: string
}

@Component({
  selector: 'sso-settings',
  templateUrl: 'sso-settings.html',
  styleUrls: ['sso-settings.css']
})
export class SSOSettingsComponent implements OnInit {

  private data: Array<SSODomain>;
  private requests: Array<SSORequest>;
  private organizations$: Observable<Array<any>>;
  private orgs;

  constructor(private orgService: OrganizationService, private database: DatabaseService) {
    this.data = [];
    this.requests = [];
    this.orgs = [];
    this.organizations$ = this.orgService.getAllOrganizations();
  }

  getData(): Promise<any> {
    return Promise.all([this.database.driver.getOrganizationsSSOMap(), this.database.driver.getOrganizationsSSORequests()]);
  }

  updateLocalData() {
    this.getData().then(result => {
      this.data = result[0]?.data?.map(item => { return {...item, editable: false, subDomains: (item.subDomains || [])}}) || [];
      this.requests = result[1]?.data || [];
    });
  }

  ngOnInit() {
    this.updateLocalData();
    this.organizations$.subscribe(res => this.orgs = res);
  }

  removeItem(item: SSODomain) {
    this.data.splice(this.data.indexOf(item), 1);
  }

  addNewEntry() {
    this.data.push({orgName: undefined, orgId: '', editable: true, subDomains: []})
  }

  save() {
    // const usedNames = this.data.map(item => item.orgName).filter(n => !!n);
    // const beforeLength = usedNames.length;
    // const afterLength = removeDuplicationsInArray(usedNames).length;
    //
    // if (beforeLength !== afterLength)
    //   return validationAlert('An Organization can be mapped only to one id.');

    const ids = this.data.map(org => org.orgId);
    const uniqueIds = removeDuplicationsInArray(ids);
    if (ids.length !== uniqueIds.length)
      return validationAlert('An id can be mapped only to one Organization.');

    if (this.data.find(item => !item.orgName || !item.orgId || item.orgName.trim() === '' || item.orgId.trim() === '' ||
        item.subDomains.find(sub => !sub.orgName || sub.orgName.trim() === '' || !sub.subSuffix || sub.subSuffix.trim() === '')))
      return validationAlert('Empty fields are not allowed.');

    this.data.forEach(item => delete item['editable']);

    this.database.driver.updateOrganizationsSSOMap(this.data).then(res => {
      validationAlert('Updated Successfully.');
      this.updateLocalData();
    }).catch(err => validationAlert('Something, somewhere went wrong....'));

  }

  orgSelect($event, item: SSODomain) {
    item.orgName = $event.target.value;
  }

  orgIdChange($event, item: SSODomain) {
    item.orgId = String($event.target.value).trim();
  }

  getUnusedOrgs() {
    return this.orgs.filter(o => !this.data.find(org => org.orgName?.trim() === o.name.trim()));
  }

  shouldShowOptionFromDb(item) {
    return !this.getUnusedOrgs().find(o => o.name === item.orgName);
  }

  addSupport(item: SSORequest) {
    this.data.push({orgName: '', orgId: item.orgId, editable: true, subDomains: []});
  }

  makeItemEditable(item: SSODomain) {
    item.editable = true;
  }

  removeSupportRequset(item: { orgAddress: string; orgId: string }) {
    this.requests.splice(this.requests.indexOf(item), 1);
    this.database.driver.updateSSORequests(this.requests).then(res => {
      validationAlert('Removed Successfully.');
    }).catch(err => {});
  }

  subDomainOrgSelect($event, sub: SSOSubDomain) {
    sub.orgName = $event.target.value;
  }

  subSuffixChange($event, sub: SSOSubDomain) {
    sub.subSuffix = $event.target.value;
  }

  addSubDomain(item: SSODomain) {
    item.subDomains.push({ orgName: '', subSuffix: '' });
    this.makeItemEditable(item);
  }

  removeSubDomain(item: SSODomain, sub: SSOSubDomain) {
    item.subDomains.splice(item.subDomains.indexOf(sub), 1);
  }
}
