import { EntityType } from "../model/entities.enum";

export interface EntitiesSet {
    contains(type: EntityType): boolean;
}

class Set implements EntitiesSet {

    constructor(private readonly set: EntityType[]) {
    }

    contains(type: EntityType): boolean {
        return this.set.includes(type);
    }

}

export function createSet(...set: EntityType[]): EntitiesSet {
    return new Set(set);
}