import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OpmLogicalProcess } from "../../models/LogicalPart/OpmLogicalProcess";
import { OpmLogicalThing } from "../../models/LogicalPart/OpmLogicalThing";
import { OpmLogicalObject } from "../../models/LogicalPart/OpmLogicalObject";
import { GraphService } from "../../rappid-components/services/graph.service";
import { TreeViewService } from "../../rappid-components/services/tree-view.service";
import { OpmModel } from "../../models/OpmModel";
import { OpmOpd } from "../../models/OpmOpd";

@Component({
  selector: 'search-items-dialog',
  templateUrl: 'search-items-dialog.component.html',
  styleUrls: ['search-items-dialog.component.css'],
})
export class SearchItemsDialogComponent {// implements OnInit{
  searchList;
  constList;
  currentOpd;
  showTypeIndex = '0';
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject, Object];
  searchString = '';
  opmModel: OpmModel;
  graphService: GraphService;
  opd: Array<OpmOpd>;
  searchLid: string;
  allEntities;
  title: string;
  subtitle: string;
  showAlsoNotes: boolean;
  private notesList: any[];

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<SearchItemsDialogComponent>,
    public treeViewService: TreeViewService) {//, private dialogService:DialogService) {
    this.opmModel = treeViewService.initRappid.opmModel;
    this.graphService = treeViewService.initRappid.graphService;
    this.opd = treeViewService.initRappid.opmModel.opds;
    this.title =  data.title || 'Model Things Searching';
    this.subtitle = data.subtitle;
    this.showAlsoNotes = false;
    this.allEntities = data.allEntities || this.opmModel.getAllBasicThings();
    // adds the opd to the constList
    this.addOpdToConstList();
    this.searchList = [...this.constList.filter(th => !th.thing.getBelongsToStereotyped())];
    this.notesList = [];
    treeViewService.initRappid.opmModel.opds.forEach(opd => {
      for (const note of opd.notes) {
        this.notesList.push({
          thing: {
            id: note.id,
            name: note.title,
            text: note.title,
            _text: note.title,
            content: note.content,
            getBelongsToStereotyped: () => false,
          },
          opdElements: [{ id: opd.id,
            name: opd.getNumberedName() + (opd.getName() === 'SD' ? '' : (': ' +opd.getName())),
          }]
        });
      }
    });
    if (data && data.element) {
      this.searchString = data.element.text;
      this.searchLid = data.element.lid;
      this.search();
    }
  }

  // ngOnInit(){
  //   const data ={message: 'Warning: Not a valid search!', closeFlag: true}
  //   this.dialogService.openDialog(this , 500, 600, data)
  // }

  /**
   *  Tanys: Recieves a search input from the user and creates a list of relevant "things"
   * @param evt - the event of user's input
   */
  thingSearching(evt) {
    this.showTypeIndex = evt.target.value;
    if (Number(this.showTypeIndex) === 3) {
      this.showAlsoNotes = true;
    }
    this.updateSearchList();
  }

  /**
   * Tanya: Updating the search list according to the search input
   */
  updateSearchList() {
    this.searchList = [...this.constList];
    if (this.showAlsoNotes) {
      this.searchList.push(...this.notesList);
    }
    this.filterList();
  }

  /**
   *  Tanya: sorting two "things" by their names.
   */
  private sortFunc(e1, e2): number {
    if (e1.name == e2.name)
      return e1.text < e2.text ? -1 : 1;
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  /**
   * Tanya: Filtering of the list containing the "things" by user's input
   */
  filterList() {
    if (Number(this.showTypeIndex) === 3) {
      this.searchList = this.searchList.filter(e => !(e.thing instanceof OpmLogicalThing));
    }
    this.searchList = this.searchList.filter(e => (!e.thing.content && e.thing instanceof this.showType[this.showTypeIndex]) || (this.showAlsoNotes && e.thing.content && (Number(this.showTypeIndex) === 0 || Number(this.showTypeIndex) === 3)))
      .sort((e1, e2) => this.sortFunc(e1, e2));
    this.searchList = this.searchList.filter(th => !th.thing.getBelongsToStereotyped());
    if (this.searchLid)
      this.searchList = this.searchList.filter(item => item.thing.lid === this.searchLid);
    if (this.searchString.length > 0)
      this.searchList = this.searchList.filter(e => e.thing.text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1 ||
        e.thing.content?.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
  }


  search() {
    this.updateSearchList();
  }

  /**
   * Adds the OPD name and ID to the list of all the "things"
   * The name will be displayed in the table
   * The Id will be used to connect to the OPD location
   */
  addOpdToConstList() {
    const updatedList = [];
    for (const item of this.allEntities) {
      if (!(item instanceof OpmLogicalThing)) continue;
      const newItem = { thing: item, opdElements: [] };
      // const itemVisualElementIdsList = item.getAllVisualElementIdsByLogical();
      for (let i = 0; i < item.visualElements.length; i++) {
        const opd = this.opmModel.getOpdByThingId(item.visualElements[i].id);
        if (opd && opd.isHidden === false)
          newItem.opdElements.push({ name: opd.getNumberedName() + (opd.getName() === 'SD' ? '' : (': ' +opd.getName())), id: opd.id });
      }
      updatedList.push(newItem);
    }
    this.constList = updatedList;
  }

  /**
   * Tanya: Redirection of the screen to the relevant OPD
   * @param id - The OPD Id of the searched thing.
   */
  goToOpdById(element, visualIndex) {
    this.treeViewService.initRappid.opdHierarchyRef.previousOpdId = this.opmModel.currentOpd.id;
    const opd = this.opmModel.getOpd(element.opdElements[visualIndex].id);
    if (opd)
      this.graphService.renderGraph(opd, this.treeViewService.initRappid, element.thing)
    this.dialogRef.close('goToOPD');
  }

  onStartDrag(event) {
    if (!!event.touches)
      return;
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e) };
    window.onmouseup = function (e) { that.endDrag(e) };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
  }

  moveDrag(event) {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    this.dialogRef.updatePosition({ left: (event.clientX - 560) + "px", top: (event.clientY - 35 + scrollTop) + "px" })
  }

  onChangeShowNotes($event) {
    this.showAlsoNotes = $event.target.checked;
    this.updateSearchList();
  }

  exportToCSV() {
    // const modelName = this.opmModel.name ? this.opmModel.name : 'Unsaved Model';
    let csv = '';
    if (this.searchString.trim().length > 0) {
      csv += 'Search by:,' + `"${this.searchString.trim()}"` + '\n';
    }
    csv += 'Element Name,Element Type,Containing OPD\n';
    for (const element of this.searchList) {
      let y = 0;
      for (const locat of element.opdElements) {
        csv += `"${element.thing._text}",`;
        let type = 'Note';
        if (element.thing.name === 'OpmLogicalObject') {
          type = 'Object';
        } else if (element.thing.name === 'OpmLogicalProcess') {
          type = 'Process';
        }
        csv += `${type},`;
        csv += `"${element.opdElements[y].name}"` + '\n';
        y += 1;
      }
    }
    const csvData = new Blob([new Uint8Array([0xEF, 0xBB, 0xBF]), csv], { type: 'text/csv;charset=utf-8;' });
    const csvURL = URL.createObjectURL(csvData);
    const a = document.createElement('a');
    a.href = csvURL;
    const fileName = 'Search_List_' + getCurrentDateFormatted() + '.csv';
    a.setAttribute('download', fileName);
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }
}


function getCurrentDateFormatted(): string {
  const date = new Date();
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are zero-indexed
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');

  return `${year}_${month}_${day} ${hours}${minutes}${seconds}`;
}

