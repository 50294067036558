import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {CdkDrag} from '@angular/cdk/drag-drop';
import {MainComponent} from '../main/main.component';

import {
  leftArrowButton,
  downArrowButton,
  minusButton,
  plusButton,
  dragHandleButton,
  rightArrowButton
} from '../../../models/ConfigurationOptions';
import {ChatStorageService} from '../../../rappid-components/services/storage/chat/chat-storage.service';

/*
* This is the component for the opcloud chat:
* a small dialog/box in which the user can see all the (current open) model together, but in a smaller version.
* inputs: paper scroller of open OPM model in the web
* output: 2 navigators, one floating and the other is on the side bar. Only one will be seen.
*/

@Component({
  selector: 'opcloud-chat-component-float',
  templateUrl: 'chat.component.float.html',
  styleUrls: ['chat.component.float.css']
})

export class ChatComponentFloat implements OnInit, AfterViewInit {
  // floatingChatBoxSize holds the floating box size, in order to be able to resize it.
  // this is not the chat size itself, but the box in which it is.
  // the style in the html is attached to it and changes according to it's value. initial value is set to 250.
  floatingChatBoxSize = 400;
  // draggableBox is made to keep track on the floating box and reset it's fields when needed to keep it's boundaries.
  @ViewChild(CdkDrag, {static: false}) draggableBox: CdkDrag;
  paperScroller; // of the current model. this parameter is needed each time when a navigator is created or resized.
  existingChat;
  public readonly chat: ChatStorageService;
  constructor(private initRappid: InitRappidService,
              private main: MainComponent) {
    this.paperScroller = initRappid.paperScroller;
    this.initRappid.ChatComponentRef = this;
  }
/* ngOnInit creates both chats. */
  ngOnInit() {
    this.initRappid.ChatIsFloating = false;
  }
  switchChat(switchTo: string) {
    const chatToCreate = (switchTo === 'floating') ? 'floatingChat' : 'sideBarChat';
    this.initRappid.Chat = this.existingChat;
  }

  /* ngAfterViewInit changes some elements after their creation
  * mainly adds svg-paths (the visual part) to the buttons on the chats.
  * it affects both chat.component.float.html and main.component.html*/
  ngAfterViewInit() {
    // set the floating chat box to it's initial position.
    this.initializeFloatingChatPosition('floatingChatBox');
    $('#minusButton').html(minusButton); // adding svg to the minusButton
    $('#plusButton').html(plusButton); // adding svg to the plusButton
    $('#dragHandleButton').html(dragHandleButton); // adding svg to the dragHandleButton
    $('.leftArrowButton').html(leftArrowButton); // adding svg to all which are in leftArrowButton class
    $('.downArrowButton').html(downArrowButton); // adding svg to all which are in downArrowButton class
    $('.rightArrowButton').html(rightArrowButton); // adding svg to all which are in rightArrowButton class
  }
  initializeFloatingChatPosition(floatingChatId: string, boundaryClass: string = '.sd-content') {
    // assuming only the id name itself is given and thus we need to add '#' in front.
    floatingChatId = '#' + floatingChatId;
    if ($(boundaryClass).length < 0) { return; } // checking that an element of this class exists.
    const sdContentBoundaries = $(boundaryClass)[0].getBoundingClientRect(); // get the size parameters of this class.

    // get the initial position of the floating box from the left and top.
    const floatingBoxInitialLeft = parseInt($(floatingChatId)[0].style.left.slice(0, -2), 10);
    const floatingBoxInitialTop = parseInt($(floatingChatId)[0].style.top.slice(0, -2), 10);
    // how much need to change the initial position considering also the size of the floating box itself.
    const transformX = sdContentBoundaries.width - floatingBoxInitialLeft - this.floatingChatBoxSize;
    const transformY = sdContentBoundaries.height - floatingBoxInitialTop - this.floatingChatBoxSize;

    // transform the position
    $(floatingChatId)[0].style.transform = 'translate(' + transformX.toString() + 'px, ' +   transformY.toString() + 'px)';
  }
  /*resetDraggableBoundary resets these fields (below) of the draggable box so that it will understand the size
  of it's boundary after being resized. Otherwise it it thinks the boundary is smaller than it really is and
  the floating box gets stuck in the middle of the window.*/
  resetDraggableBoundary() {
    this.draggableBox._dragRef['_previewRect'] = undefined;
    this.draggableBox._dragRef['_boundaryRect'] = undefined;
  }
  /*resizeFloatingChat enables to make the floating navigator bigger or smaller by clicking on the minus or plus
  * buttons.
  * input:  @floatingChatId: the floating box element id in which the chat is.
  *         @increase: true if the size should be increased and false if it should be decreased*/
  resizeFloatingChat(floatingChatId: string, increase: boolean) {
    if (increase) {
      if (this.floatingChatBoxSize >= 700) { return; } // check that the size of the box is not already too big.
      this.floatingChatBoxSize += 30; // increase the size of the box.
    } else {
      if (this.floatingChatBoxSize <= 400) { return; } // check that the size of the box is not already too small.
      this.floatingChatBoxSize -= 30; // decrease the size of the box.
    }

    const historyDiv = $('#historyContainer')[0];
    // set height and padding according to floatingChatBoxSize
    const newHistoryHeight = this.floatingChatBoxSize - 90 - 25 + 'px';
    historyDiv.style.height = newHistoryHeight;
    historyDiv.style.paddingBottom = 0.3 + 'em';


    // get the element of the current floating chat
    const floatingChat = document.getElementById(floatingChatId);
    if (floatingChat && floatingChat.firstChild) {
      document.getElementById(floatingChatId).removeChild(floatingChat); // remove the current chat
    }
    // reset the boundaries so that the floating box will stay in it it's boundaries
    // although the size has been changed.
    this.resetDraggableBoundary();
  }

}
