import {
  Component, EventEmitter, OnInit, Optional,
} from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { GraphService } from '../../rappid-components/services/graph.service';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import * as FileSaver from 'file-saver';
import {
  getInitRappidShared,
  OPCloudUtils,
  validationAlert
} from '../../configuration/rappidEnviromentFunctionality/shared';
import { ModelService } from '../../modules/app/model.service';
import { ContextService } from '../../modules/app/context.service';
import { parseString } from 'xml2js';
import {OpmVisualThing} from '../../models/VisualPart/OpmVisualThing';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {TabsManager} from '../../modules/app/tabsService';

@Component({
  selector: 'Upload_File',
  templateUrl: 'FileUploader.html',
  styleUrls: ['FileUploader.scss']
})

export class UploadFile implements OnInit {

  file: File | null = null; // Variable to store file
  reader: FileReader = new FileReader();
  XML: XMLDocument;
  onAdd = new EventEmitter();
  importOPX = true;
  private OPX_JSON: any;
  imported = true;
  uploaded = false;
  logcolor = 'primary';
  DoneImport = false;
  log = '';
  now = new Date();

  constructor(@Optional() public dialogRef: MatDialogRef<FileUploader>,
    private graphService: GraphService,
    private contextService: ContextService,
    private modelService: ModelService,
    private initRappidService: InitRappidService) {
  }

  ngOnInit(): void {
  }

  /**
   * Loading the OPCAT file content and reading it preparing for import.
   * @param event
   * @private
   */
  protected loadOPCATFile(event) {
    if (event.target.files.length === 0) {
      return;
    }
    const file: File  = event.target.files[0];
    if (file) {
      this.file = file;
      const fileReader = new FileReader();
      const that = this;
      fileReader.readAsText(file);
      fileReader.onload = (e: any) => {
        this.XML = e.target.result;

        parseString(this.XML, function (err, result) {
          that.OPX_JSON = result;
          that.uploaded = true;
          that.imported = false;
        });
      };
    }
  }

  public async Import() {
    const promise = new Promise<any>((resolve, reject) => {
      setTimeout(() => { resolve({}); }, 3000);
    });
    promise.then(() => {
      // reset the current graph in the back
      this.contextService.newModel();
      this.contextService.closeEmptyTabs();
      this.graphService.renderGraph(this.modelService.model.getOpd('SD'), this.initRappidService);
      const ImportedModel = this.graphService.importOpxGraph(this.OPX_JSON, this.initRappidService);
      this.log = JSON.stringify(ImportedModel.Log, null, ' ');
      this.DoneImport = true;
      this.imported = true;
      if (ImportedModel.CheckLog) {
        this.logcolor = 'warn';
      }
      const init = getInitRappidShared();
      init.getOpmModel().opds.forEach(opd => {
        init.graphService.renderGraph(opd, init);
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnEntity(c)).forEach(th => {
          const vis = th.getVisual();
          th.set('position', {x: vis.xPos * 2, y: vis.yPos * 2});
          th.autosize(init);
          if (OPCloudUtils.isInstanceOfDrawnProcess(th)) {
            th.changeSizeHandle(init);
          }
        });
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnTriangle(c)).forEach(tr => {
          tr.set('position', {x: tr.get('position').x * 2, y: tr.get('position').y * 2});
        });
        init.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnObject(c)).forEach(obj => {
          obj.attr('text/ref-y', obj.hasStates() ? 0.25 : 0.5);
          obj.shiftEmbeddedToEdge(init);
        });
        for (const vis of opd.visualElements) {
          if (vis instanceof OpmVisualThing) {
            opd.beautify(vis);
          }
        }
        init.graphService.updateOPDAfterImport(opd, init);
        init.graphService.renderGraph(opd, init);
      });
      // Going back to SD opd so it will be displayed and rendering all OPDs tree
      this.graphService.renderGraph(this.modelService.model.getOpd('SD'), this.initRappidService);
      const tabsManager = new TabsManager(this.initRappidService, this.contextService);
      tabsManager.refreshTab();
    }).catch(error => validationAlert(`OPCloud uncounted an issue importing the OPCAT file:\n` + error, 5000, 'Error'));
  }

  logFile() {
    FileSaver.saveAs(new Blob([this.log], { type: 'text/plain;charset=utf-8' }),
      'OPCloud_log' + this.now + '.txt');
    this.dialogRef.close();
  }

  onButtonClick() {
    this.onAdd.emit();
  }

}
