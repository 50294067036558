import {linkType} from '../ConfigurationOptions';

export interface LinksSet {
    contains(type: linkType): boolean;
}

class Set implements LinksSet {

    constructor(public readonly set: linkType[]) {
        this.set = set;
    }

    contains(type: linkType): boolean {
        return this.set.includes(type);
    }

}

export function createSet(...set: linkType[]): LinksSet {
    return new Set(set);
}

export const all = createSet(linkType.Result, linkType.Consumption, linkType.Aggregation, linkType.Bidirectional, linkType.Unidirectional, linkType.Exhibition, linkType.Generalization, linkType.Instantiation,
    linkType.Agent, linkType.Consumption, linkType.Effect, linkType.Instrument);

export const fundamental = createSet(linkType.Aggregation, linkType.Exhibition, linkType.Generalization, linkType.Instantiation);
export const structural = createSet(linkType.Aggregation, linkType.Exhibition, linkType.Generalization, linkType.Instantiation);
export const procedural = createSet(linkType.Result, linkType.Consumption, linkType.Effect, linkType.Instrument, linkType.Agent);
export const proceduralEnablers = createSet(linkType.Instrument, linkType.Agent);
export const proceduralTransformers = createSet(linkType.Result, linkType.Consumption, linkType.Effect);
export const instruments = createSet(linkType.Instrument, linkType.Agent);
export const consumptions = createSet(linkType.Consumption);
export const results = createSet(linkType.Result);
export const invoactions = createSet(linkType.Invocation);
export const consumers = createSet(linkType.Consumption, linkType.Result);
export const tagged = createSet(linkType.Unidirectional, linkType.Bidirectional);
export const proceduralWithInvocation = createSet(linkType.Result, linkType.Consumption, linkType.Effect, linkType.Instrument, linkType.Agent, linkType.Invocation);

export const commutativeDirect = createSet(
    linkType.Agent,
    linkType.Instrument,
    linkType.Consumption,
    linkType.Result,
    linkType.Effect,
    linkType.UndertimeException,
    linkType.OvertimeException,
    linkType.UndertimeOvertimeException,
    linkType.Unidirectional,
    linkType.Bidirectional,
);
