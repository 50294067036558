// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
  text-align: left;
}

.dialogdemoBasicUsage #popupContainer {
  position: relative; }

.dialogdemoBasicUsage .footer {
  width: 100%;
  text-align: center;
  margin-left: 20px; }

.dialogdemoBasicUsage .footer, .dialogdemoBasicUsage .footer > code {
  font-size: 0.8em;
  margin-top: 50px; }

.dialogdemoBasicUsage button {
  width: 200px; }

.dialogdemoBasicUsage div#status {
  color: #c60008; }

.dialogdemoBasicUsage .dialog-demo-prerendered mat-checkbox {
  margin-bottom: 0; }


::ng-deep .mat-form-field-underline {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/UserAgreement/user-agreement.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,kBAAkB,EAAE;;AAEtB;EACE,WAAW;EACX,kBAAkB;EAClB,iBAAiB,EAAE;;AAErB;EACE,gBAAgB;EAChB,gBAAgB,EAAE;;AAEpB;EACE,YAAY,EAAE;;AAEhB;EACE,cAAc,EAAE;;AAElB;EACE,gBAAgB,EAAE;;;AAGpB;EACE,aAAa;AACf","sourcesContent":["p{\n  text-align: left;\n}\n\n.dialogdemoBasicUsage #popupContainer {\n  position: relative; }\n\n.dialogdemoBasicUsage .footer {\n  width: 100%;\n  text-align: center;\n  margin-left: 20px; }\n\n.dialogdemoBasicUsage .footer, .dialogdemoBasicUsage .footer > code {\n  font-size: 0.8em;\n  margin-top: 50px; }\n\n.dialogdemoBasicUsage button {\n  width: 200px; }\n\n.dialogdemoBasicUsage div#status {\n  color: #c60008; }\n\n.dialogdemoBasicUsage .dialog-demo-prerendered mat-checkbox {\n  margin-bottom: 0; }\n\n\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
