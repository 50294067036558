import { valueType } from "../../ConfigurationOptions";
import {ValueAttributeRange, ValueAttributeType} from '../../modules/attribute-validation/attribute-range';
import { ValidationModule } from "../../modules/attribute-validation/validation-module";

// an object indicating if the object is computational or not
export class ComputationModule {

  public valueType: valueType = valueType.None;
  public value: any = undefined;

  public validationModule: ValidationModule = new ValidationModule();

  public isActive(): boolean {
    return (<any>this.valueType !== 'None' && this.valueType !== valueType.None && this.valueType !== undefined
      && this.valueType !== null);
  }

  public remove(): void {
    this.value = undefined;
    this.valueType = valueType.None;
  }

  public setValue(value: string) {
    if (this.validationModule.validateValue(value))
      this.value = value;
  }

  public hasRange(): boolean {
    return this.validationModule.isActive();
  }

  public setRange(type: ValueAttributeType, range: string, stereotypeValidator: ValueAttributeRange): { wasSet: boolean, errors?: Array<string> } {
    const ret = this.validationModule.setRange(type, range, stereotypeValidator);
    if (ret.wasSet) {
      return { wasSet: true };
    }
    return { wasSet: false, errors: ret.errors };
  }

  public removeRange() {
    this.validationModule.removeRange();
  }

  public getRange(): string {
    return this.validationModule.getRange();
  }

}
