export interface FontData {
  name: string,
  style: string;
}

const SUPPORTED_FONTS: Array<FontData> = [
  { name: 'Arial', style: `font-family: Arial, Helvetica, sans-serif;` },
  { name: 'Bookman', style: `font-family: Bookman Old Style , Arial, Helvetica, sans-serif;` },
  { name: 'Comic Sans MS', style: `font-family: Comic Sans MS , Arial, Helvetica, sans-serif;` },
  { name: 'Cambria', style: `font-family: Cambria, Arial, Helvetica, sans-serif;` },
  { name: 'Courier', style: `font-family: Courier, Arial, Helvetica, sans-serif;` },
  { name: 'Courier New' , style: `font-family: Courier New, Arial, Helvetica, sans-serif;` },
  { name: 'Garamond', style: `font-family: Garamond, Arial, Helvetica, sans-serif;` },
  { name: 'Georgia', style: `font-family: Georgia, Arial, Helvetica, sans-serif;` },
  { name: 'Helvetica', style: `font-family: Helvetica, Arial, sans-serif;` },
  { name: 'Palatino', style: `font-family: Palatino, Arial, Helvetica, sans-serif;` },
  { name: 'sans-serif', style: `font-family: sans-serif, Arial, Helvetica, sans-serif;` },
  { name: 'serif', style: `font-family: Serif, Arial, Helvetica, sans-serif;` },
  { name: 'Times', style: `font-family: Times, Arial, Helvetica, sans-serif;` },
  { name: 'Times New Roman', style: `font-family: Times New Roman, Arial, Helvetica, sans-serif;` },
  { name: 'Trebuchet MS', style: `font-family: Trebuchet MS, Arial, Helvetica, sans-serif;` },
  { name: 'Verdana', style: `font-family: Verdana, Arial, Helvetica, sans-serif;` },
];


export function getSupportedFonts(): Array<FontData> {
  return SUPPORTED_FONTS;
}
