import { OpmStructuralRelation } from './OpmStructuralRelation';
import { OpmFundamentalLink } from '../VisualPart/OpmFundamentalLink';

export class OpmFundamentalRelation extends OpmStructuralRelation<OpmFundamentalLink> {

  constructor(params, model, addToCurrentOPD = true) {
    super(params, model);
  }

  createVisual(params): OpmFundamentalLink {
    return new OpmFundamentalLink(params, this)
  }

  getParams() {
    const visualElementsParams = new Array();
    for (let i = 0; i < this.visualElements.length; i++) {
      visualElementsParams.push(this.visualElements[i].getParams());
    }
    const params = {
      visualElementsParams: visualElementsParams
    };
    return { ...super.getStructuredParams(), ...params };
  }

  getParamsFromJsonElement(jsonElement) {
    return super.getStructuralParamsFromJsonElement(jsonElement);
  }
  public removeVisual(visual: OpmFundamentalLink) {
    for (let i = this.visualElements.length - 1; i >= 0; i--) {
      if (this.visualElements[i] === visual) {
        this.visualElements.splice(i, 1);
        this.opmModel.removeElementFromOpds(visual);
        break;
      }
    }
  }
}
