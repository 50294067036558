import {Component, OnInit} from '@angular/core';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import * as fileSaver from 'file-saver';
import {joint} from "../../configuration/rappidEnviromentFunctionality/shared";
import * as XLSX from 'xlsx';
const pf = require('pareto-frontier');

@Component({
  selector: 'multi-instances-dialog',
  templateUrl: './pareto-frontier-dialog.html',
  styleUrls: ['./pareto-frontier-dialog.css']
})
export class paretoFrontierDialog implements OnInit {
  graph;
  paper;
  chart;
  file;
  optimizationType: 'topRight';
  dataToShow;
  dataTitles;
  tooltip;
  lineType = 'bezier';

  constructor(private init: InitRappidService,) {
  }

  ngOnInit() {
    this.graph = new joint.dia.Graph;
    // this.paper = new joint.dia.Paper({el: $('#paretoPaper'), width: 500, height: 500, model: this.graph, elementView: joint.shapes.chart.PlotView});
    this.paper = new joint.dia.Paper({el: $('#paretoPaper'), width: 500, height: 500, model: this.graph, cellViewNamespace: joint.shapes});

  }
  showData() {
    this.graph.getCells().forEach(c => c.remove());
    const graph = this.dataToShow.map(it => [it[1], it[2], it[0]]);
    let opt = pf.getParetoFrontier(graph, { optimize: this.optimizationType });

    opt = opt.sort(function(a,b) {return a[0] > b[0] ? 1 : -1});
    const series = graph.map(item => {
      return { x: item[0], y: item[1] ,label: item[2]};
    });
    const optimal = opt.map(item => {
      return { x: item[0], y: item[1] ,label: item[2]};
    });
    if (optimal.length === 1)
      optimal.push(optimal[0]);
    let minX = Math.min(...series.map(item => item.x));
    let minY = Math.min(...series.map(item => item.y));
    let maxX = Math.max(...series.map(item => item.x));
    let maxY = Math.max(...series.map(item => item.y));
    const xGap = maxX - minX;
    const yGap = maxY - minY;
    minX = minX - xGap / 4;
    maxX = maxX + xGap / 4;
    minY = minY - yGap / 4;
    maxY = maxY + yGap / 4;
    this.chart = new joint.shapes.chart.Plot({
      position: { x: 35, y: 20 },
      size: { width: 450, height: 450 },
      series: [
        {
          name: 'non-optimal',
          data: series,
          hideFillBoundaries: true,
        },
        {
          name: 'optimal',
          data: optimal,
          interpolate: this.lineType,
          hideFillBoundaries: true,
        },
      ],
      axis: {
        'y-axis': {
          min: minY,
          max: maxY,
        },
        'x-axis': {
          min: minX,
          max: maxX,
        }
      },
      attrs: {
        // '.tick': {
        //   display: 'none',
        // },
        '.non-optimal path': {
          stroke: 'transparent',
        },
        '.optimal path': {
          stroke: 'rgb(0,84,255)',
          'opacity': 0.5,
          'stroke-width': 4
        },
        '.non-optimal circle': {
          fill: 'rgb(255,255,255)',
          stroke: '#5A6F8F',
          'stroke-width': 2,
          'opacity': 0.6,
          'r': 5,
        },
        '.optimal circle': {
          fill: 'white',
          'stroke': '#0b3f8d',
          'opacity': 1,
          'stroke-width': 2,
          'r': 5,
        }
      }
    });
    this.graph.addCell(this.chart);
    this.chart.findView(this.paper).setInteractivity(false);
    const chartView = this.chart.findView(this.paper);
    const that = this;
    const points = chartView.el.querySelectorAll('.point');
    for (const point of points) {
      point.addEventListener('mouseenter', function (event) {
        if (event.target.tagName === 'g' && !!event.target.getAttribute('data-serie')) {
          const target = event.target;
          const xData = Number(target.getAttribute('data-x'));
          const yData = Number(target.getAttribute('data-y'));
          const serie = target.getAttribute('data-serie');
          const point = that.chart.get('series').find(s => s.name === serie).data.find(d => d.x === xData && d.y === yData);
          const text = that.dataTitles[1] + ': ' + point.x + '<br>' + that.dataTitles[2] + ': ' + point.y + '<br> Instance Ref: #' + point.label;
          new joint.ui.Tooltip({
            target: event.target,
            rootTarget: event.target,
            direction: 'auto',
            padding: 10,
            content: text,
          });
        }
      });
    }
    this.removeVerticalLines();
  }

  incomingfile(event) {
    if (event.target.files.length === 0) {
      this.file = null;
      return;
    }
    this.file = event.target.files[0];
    let arrayBuffer;
    const fileReader = new FileReader();
    const that = this;
    fileReader.onload = (e) => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      const temp = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      const dataFromExcel = temp.map( item => {
        const arr = [];
        const keys = Object.keys(temp[0]);
        for (const key of keys)
          arr.push(item[key]);
        return arr;
      });
      that.dataToShow = dataFromExcel;
      that.dataTitles = Object.keys(temp[0]);
      that.showData();
    };
    fileReader.readAsArrayBuffer(this.file);
  }


  close() {
  }

  download() {
    this.paper.toJPEG((img) => {
      const byteCharacters = atob(img.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
      const byteNumbers = new Array(byteCharacters.length);
      for (let i = 0; i < byteCharacters.length; i++)
        byteNumbers[i] = byteCharacters.charCodeAt(i);
      const byteArray = new Uint8Array(byteNumbers);
      const blob = new Blob([byteArray], { type: 'image/jpeg' });
      fileSaver.saveAs(blob, 'data'.replace(/\./g, '_'));
    }, {
      padding: 40, size: '3x', quality: 1.0
    });
  }

  selectChange() {
    this.optimizationType = (<any>$('#selectOptimization')[0]).value;
    if (this.dataToShow)
      this.showData();
  }

  selectLineTypeChange($event: Event) {
    this.lineType = (<any>$event.target).value;
    if (this.chart) {
      this.chart.remove();
      this.showData();
    }
  }

  getAxis(ax: string, opt: string) {
    if (this.chart) {
      return this.chart.attributes['axis'][ax][opt];
    } else return 0;
  }

  changeAxis($event, ax: string, opt: string) {
    if (this.chart) {
      const temp = {...this.chart.attributes['axis']};
      temp[ax][opt] = Number($event.target.value);
      try { this.chart.set('axis', temp) } catch (e) {}
      this.chart.findView(this.paper).update();
      this.removeVerticalLines();
    }
  }

  removeVerticalLines() {
    if (this.lineType === 'bezier') {
      for (const path of $('path')) {
        if ((<any>path.parentElement.className).baseVal === 'optimal') {
          const pathLength = (<any>path).pathSegList?.numberOfItems;
          if (!pathLength) {
            continue;
          }
          for (let i = pathLength - 1 ; i >= 0 ; i--) {
            if ((<any>path).pathSegList.getItem(i).pathSegTypeAsLetter === 'V')
              (<any>path).pathSegList.removeItem(i);
          }
        }
      }
    }
  }
}
