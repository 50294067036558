export class ModelObject {

  public id: string;
  name: string;
  modelData: any;
  path: string;
  description: string;
  archiveMode: boolean;
  tokenFlag: boolean;

  constructor(name: string = '', modelData: any = null) {
    this.path = '';
    this.name = name;
    this.modelData = modelData;
    this.tokenFlag = false;
  }

  public isModelLoaded(): boolean {
    return (this.name != undefined && this.path != undefined);
  }

  saveModelParam(newName, newModel, path) {
    this.name = newName;
    this.modelData = newModel;
    this.path = path;
  }

  copyModel() {
    const copyModel = new ModelObject(this.name, this.modelData);
    copyModel.path = '';
    return copyModel;
  }
}

export interface WriteModelSchema {
  id?: string
  currentOpd?: any
  opds?: Array<any>,
  logicalElements?: Array<any>,
  title?: string,
  description?: string,
  sysExample?: boolean,
  globalTemplate?: boolean,
  image?: string,
  importedTemplates?,
  fatherModelId?: string,
  archiveMode?: {
    archiveMode: boolean,
    date: string,
    name: string
  }
}

export enum CurrentModelPermission {
  WRITE = 'WRITE',
  READ = 'READ'
}

export interface ReceivedModelFromServer {
  data: any,
  meta: {
    id: string,
    title: string,
    description: string,
    archiveMode: {
      archiveMode: boolean,
      date: string,
      name: string
    },
    dirsPath: Array<any>,
    editBy: {
      date: string,
      name: string
    },
    archive: boolean,
    permissions: 'write' | 'read',
    type: 'main' | 'auto'
  }
}

export interface ModelSchema {
  id: string,
  currentOpd?: any
  opds?: Array<any>,
  logicalElements?: Array<any>,
  stereotypes?: Array<any>,
  name: string,
  path: string,
  description: string,
  importedTemplates?,
  archiveMode: {
    archiveMode: boolean,
    date: string,
    name: string
  },
  permissions?: ModelPermissions,
  editBy?: {
    date: string,
    name: string
  },
  isAutosave: boolean,
  isVersion: boolean,
  permission: CurrentModelPermission,
  dirsPath?: Array<any>
}

export interface ModelPermissions {
  ownerID: string,
  tokenID: string,
  readIDs: Array<string>,
  writeIDs: Array<string>
}
