import { Component, OnDestroy, OnInit, Optional, Injectable } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UserService } from '../../../../rappid-components/services/user.service';
import { MemberGrpComponent } from './member-grp/member-grp.component';


@Component({
  selector: 'opcloud-ugrp-dialog',
  templateUrl: 'update-user-grp.html',
  styleUrls: ['update-user-grp.component.css']
})

@Injectable()
export class UserGroupsComponent implements OnInit, OnDestroy {
  // private subject = new Subject<any>();
  user;
  subscription;
  ref; memRef; adRef;
  memGroups: any;
  constructor(@Optional() public dialogRef: MatDialogRef<UserGroupsComponent>,
    private userService: UserService,
    private _dialog: MatDialog
  ) { }

  ngOnInit() {
    // console.log(`UserGroupsComponent opened`);
    this.subscription = this.userService.user$.subscribe(curUser => {
      this.user = curUser;
    });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  clickedOption(optionClicked) {
    if (optionClicked === 'MemberGrpComponent') {
      this.openSettingOption(MemberGrpComponent, [this.user,
      this.ref,
      this.memRef,
      this.adRef]);
    } else {
      console.log('optionClicked is undefined');
    }
  }


  openSettingOption(settingOptionComp, parameters?) {
    const dialogRef = this._dialog.open(settingOptionComp, { data: parameters });
    dialogRef.afterClosed().subscribe(res => {
      if (res) {
        this.openSettingOption(res.action, res.params);
      } else { // (!!res)
        console.log('no result for openSettingOption');

      }
    });
  }
}
