import {_, getInitRappidShared, popupGenerator} from '../../configuration/rappidEnviromentFunctionality/shared';
import { InitRappidService } from "../../rappid-components/services/init-rappid.service";
import { OpmEntity } from "./OpmEntity";

export class OpmEllipsis extends OpmEntity {

  constructor() {
    super();
    this.set(this.stateAttributes());
    this.attr(this.stateAttrs());
  }

  stateAttributes() {
    return {
      markup: '<image/><g class="rotatable"><g class="scalable"><rect class="outer"/><rect class="inner"/></g><text/></g>',
      type: 'opm.Ellipsis',
      size: { width: 30, height: 15 },
      minSize: { width: 30, height: 15 },
      padding: 5,
    };
  }

  getPortGroups() {

  }

  removeUnusedPorts() {}

  innerOuter() {
    const param = {
      stroke: '#808000',
      rx: 5,
      ry: 5,
    };
    return { ...this.entityShape(), ...param };
  }

  createInner() {
    return {
      'stroke-width': 0,
      width: 30,
      height: 15,
      'ref-x': .5, 'ref-y': .5,
      'x-alignment': 'middle',
      'y-alignment': 'middle',
    };
  }

  createOuter() {
    return {
      width: 40,
      height: 20,
    };
  }

  stateAttrs() {
    return {
      '.outer': { ...this.innerOuter(), ...this.createOuter() },
      '.inner': { ...this.innerOuter(), ...this.createInner() },
      text: {textWrap: { text: '...', width: '100%', height: '100%'}, 'font-weight': 300, 'font-size': 13 },
      rect: { style: { 'pointer-events': 'none' } },
      '.': { magnet: false }
    };
  }

  getParams() {
    return { ...super.getEntityParams() };
  }

  replaceSupers(num: string | number) {
    const chars = {'0': '⁰', '1': '¹', '2': '²', '3': '³', '4': '⁴', '5': '⁵', '6': '⁶', '7': '⁷',  '8': '⁸', '9': '⁹'};
    return String(num).replace(/[0123456789]/g, (match) => chars[match]);
  }


  updateParamsFromOpmModel(visualElement) {
    const attr = {
      '.outer': { ...this.updateEntityFromOpmModel(visualElement), ...{ stroke: visualElement.strokeColor } },
      '.inner': { ...this.updateEntityFromOpmModel(visualElement), ...{ stroke: visualElement.strokeColor } },
    };
    const uniqueAttrs = {'font-size': 13, 'font-weight': 300, textWrap: { height: '100%', width: '100%'} };
    this.attr(attr);
    this.attr('text', uniqueAttrs);
    const numberOfMissingStates = visualElement.getMissingStates();
    this.attr('text/textWrap/text', '...' + (numberOfMissingStates.length ? this.replaceSupers(numberOfMissingStates.length) : ''));
    this.attr({
      '.': {
        'data-tooltip': numberOfMissingStates.join('<br>'),
        'data-tooltip-position': 'top',
        opacity: visualElement.fatherObject.belongsToSubModel ||
        visualElement.fatherObject.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel || v.belongsToFatherModelId) ? 0.6 : 1,
      }
    });
  }

  setAttr() {
    this.attr({
      text: {textWrap: { text: '...', width: '100%', height: '100%' }},
      rect: { style: { 'pointer-events': 'none' } }, '.': { magnet: false }
    });
  }

  greyOutEntity() {
    if ((<any>this.getParentCell().getVisual()) && (<any>this.getParentCell().getVisual()).logicalElement.shouldBeGreyed === true && getInitRappidShared().shouldGreyOut) {
      this.graph.startBatch('ignoreChange');
      this.attr('rect/fill', 'lightgrey');
      this.attr('.outer/stroke', 'grey');
      this.attr('.inner/stroke', 'grey');
      this.graph.stopBatch('ignoreChange');
    } else {
      this.graph.startBatch('ignoreChange');
      this.attr('rect/fill', 'transparent');
      this.attr('.outer/stroke', '#808000');
      this.attr('.inner/stroke', '#808000');
      this.graph.stopBatch('ignoreChange');
    }
  }

  closeTextEditor(rappid) {
  }

  changeAttributesHandle(options) {
  }

  updateShapeAttr(newValue) {
    this.attr('.inner', newValue);
    this.attr('.outer', newValue);
  }

  getShapeAttr() {
    return this.attr('.inner');
  }

  getShapeFillColor() {
    return this.attr('.inner/fill');
  }

  getShapeOutline() {
    return this.attr('.inner/stroke');
  }

  surpressStates() {
    /*this.connectLinksToFather(this.stateArray, this.stateArray[0].getParent())
    for (let i = 0; i < this.stateArray.length; i++) {
      this.stateArray[i].remove();
    }*/
  }

  pasteStyle(copiedParams) {
    return;
  }

  doubleClickHandle(cellView, evt, options: InitRappidService) {
    const parentID = this.attributes.father;
    const parent = cellView.model.getAncestors()[0];
    const visualObject = options.opmModel.getVisualElementById(parentID);
    if (visualObject.belongsToSubModel)
      return;
    options.getOpmModel().logForUndo((<any>visualObject.logicalElement).text + ' states expression');
    options.getOpmModel().setShouldLogForUndoRedo(false, 'expressAction');
    parent.expressAllAction(visualObject, options);
    options.getOpmModel().setShouldLogForUndoRedo(true, 'expressAction');
    // ----------------------------------------------------------------------------------------------------- //
    // ----------------------------------------------------------------------------------------------------- //
    // ----------------------------------------------------------------------------------------------------- //
    // const this_ = this;
    // const states = this.getStatesToExpress(options);
    // let checkboxes = '';
    // let popup;
    // for (let i = 0; i < states.length; i++)
    //   checkboxes += '<input value="' + states[i].text + '" class="chb" type="checkbox" ' + (states[i].exist ? "checked" : "") + '><label for="chb' + i + '">' + states[i].text + '</label><br>';
    // const express = '<input id="express" type="button" value="Express">';
    // const popupContent = ['<form>', checkboxes, express, '</form>'].join('');
    // const popupEvents = {
    //   'click #express': function express() {
    //     const checkboxes = (<HTMLCollectionOf<HTMLInputElement>>document.getElementsByClassName('chb'));
    //     const checked = [];
    //     for (let i = 0; i < checkboxes.length; i++)
    //       checked.push({ text: checkboxes[i].value, checked: checkboxes[i].checked });
    //     this_.expressAction(this_, checked, options);
    //     popup.remove();
    //   }
    // };
    // popup = popupGenerator(cellView.el, popupContent, popupEvents);
    // popup.render();
  }


  public expressAction(ellipsis: OpmEllipsis, checked, rappid: InitRappidService) {
    const parent = ellipsis.getParent();
    const object = <any>rappid.opmModel.currentOpd.visualElements.find(c => c.id === parent.id);

    const expressed = object.expressChecked(checked);
    if (expressed.length === 0)
      return;

    const graph = this.graph;
    graph.startBatch('ignoreEvents');
    graph.startBatch('ignoreChange');

    object.rearrange();
    parent.updateParamsFromOpmModel(object);
    for (let i = 0; i < expressed.length; i++) {
      const state = rappid.getGraphService().createDrawnEntity('State');
      state.updateParamsFromOpmModel(expressed[i]);
      this.graph.addCell(state);
      parent.embed(state);
      state.set('father', state.get('parent'));
    }
    for (let i = 0; i < object.children.length; i++) {
      const embedded = parent.getEmbeddedCells().find(e => e.id === object.children[i].id);
      embedded.updateParamsFromOpmModel(object.children[i]);
    }

    if (object.allStatesExpressed())
      ellipsis.remove();
    else
      ellipsis.updateParamsFromOpmModel(object.ellipsis);

    graph.stopBatch('ignoreChange');
    graph.stopBatch('ignoreEvents');
  }

  //TODO: Daniel: To be moved;
  public getStatesToExpress(rappid: InitRappidService) {
    const parent = this.get('father');
    const object = <any>rappid.opmModel.currentOpd.visualElements.find(c => c.id === parent);
    return object.getStatesToExpress();
  }



  /*connectLinksToFather(states, father) {
    let outBound;
    let inBound;
    for (let i = 0; i < states.length; i++) {
      outBound = father.graph.getConnectedLinks(states[i], { outbound: true });
      inBound = father.graph.getConnectedLinks(states[i], { inbound: true });
      if (inBound.length) {
        _.each(inBound, (link) => {
          (link) ? link.set('preTarget', link.getTargetElement()) : null;
          this.linksArrayInbound.push(link);
          link.set({ target: { id: father.id } });
        });
      }
      if (outBound.length) {
        _.each(outBound, (link) => {
          (link) ? link.set('preSource', link.getSourceElement()) : null;
          this.linksArrayOutbound.push(link);
          link.set({ source: { id: father.id } });
        });
      }
    }
  }

  express() {
    const father = this.attributes.father;
    for (let i = 0; i < this.stateArray.length; i++) {
      father.graph.addCell(this.stateArray[i]);
      father.embed(this.stateArray[i]);
    }
    this.reConnectLinksToStates(this.stateArray);
    this.stateArray.splice(0, this.stateArray.length);
  }

  reConnectLinksToStates(states) {
    for (let j = 0; j < states.length; j++) {
      if (this.linksArrayInbound.length) {
        _.each(this.linksArrayInbound, (link) => {
          if (link.attributes.preTarget.id === states[j].id) {
            link.set({ target: { id: states[j].id } });
          }
        });
      }
      if (this.linksArrayOutbound.length) {
        _.each(this.linksArrayOutbound, (link) => {
          if (link.attributes.preSource.id === states[j].id) {
            link.set({ source: { id: states[j].id } });
          }
        });
      }
    }
  }*/

  pointerDownHandle(cellView, init) {
    init.getOpmModel().setShouldLogForUndoRedo(false, 'ellipsis-pointerDown');
    super.pointerDownHandle(cellView, init);
    init.getOpmModel().setShouldLogForUndoRedo(true, 'ellipsis-pointerDown');
  }

}

