// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
  height: 32px;
  width: 32px;
  font-size: 32px;
  display: inline-block;
  background-size: 32px 32px;
  border-radius: 32px;
  border: 1px white solid;
  margin-right: 10px;
  background-color: whitesmoke;
  overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/avatar/avatar.component.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,WAAA;EACA,eAAA;EACA,qBAAA;EACA,0BAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,4BAAA;EACA,gBAAA;AACF","sourcesContent":[".avatar {\n  height: 32px;\n  width: 32px;\n  font-size: 32px;\n  display: inline-block;\n  background-size: 32px 32px;\n  border-radius: 32px;\n  border: 1px white solid;\n  margin-right: 10px;\n  background-color: whitesmoke;\n  overflow: hidden;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
