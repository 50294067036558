import {TextualModule} from "./interfaces";
import {OpmLogicalThing} from "../OpmLogicalThing";
import {OpmVisualThing} from "../../VisualPart/OpmVisualThing";


export class ConfigurationsTextModule implements TextualModule {

  private thing: OpmLogicalThing<OpmVisualThing>;

  constructor(thing: OpmLogicalThing<OpmVisualThing>) {
    this.thing = thing;
  }

  public getText(): string {
    const cc = this.thing.opmModel.getCurrentConfiguration();
    return '{Instances: ' + cc[this.thing.lid].value + '}';
  }

  public isTextActive(): boolean {
    const cc = this.thing.opmModel.getCurrentConfiguration();
    if ( cc && cc[this.thing.lid] && cc[this.thing.lid].value !== 0)
      return true;
    return false;
  }

  public getPriority() {
    return 4;
  }

}
