import {OpmLink} from './OpmLink';
import {OpmVisualEntity} from "./OpmVisualEntity";
import {linkType} from "../ConfigurationOptions";
import {OpmVisualThing} from "./OpmVisualThing";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";

export abstract class OpmStructuralLink extends OpmLink {
  targetMultiplicity: number;
  constructor(params, logicalElement) {
    super(params, logicalElement);
  }

  isStructuralLink() {
    return true;
  }

  isProceduralLink() {
    return false;
  }

  updateParams(params) {
    super.updateParams(params);
    this.targetMultiplicity = params.targetMultiplicity;
  }
  setParams(params) {
    super.setParams(params);
    this.targetMultiplicity = params.targetMultiplicity;
    this.sourceVisualElementPort = params.sourceVisualElementPort;
    this.targetVisualElementPort = params.targetVisualElementPort;
    if (params.targetVisualElements && params.targetVisualElements[0]?.vertices)
      this.BreakPoints = params.targetVisualElements[0].vertices;
    if (params.vertices)
      this.BreakPoints = params.vertices;
  }
  getStructuralParams() {
    const params = {
      targetMultiplicity: this.targetMultiplicity,
    };
    return {...super.getLinkParams(), ...params};
  }
  getStructuralParamsFromJsonElement(jsonElement) {
    const params = {
      targetMultiplicity: jsonElement.targetMultiplicity
    };
    return {...super.getLinkParamsFromJsonElement(jsonElement), ...params};
  }


  /**
   * This method returns an array of the missing objects names in the OPD to show in a tooltip near the object numbers.
   */
  getMissingChildrenNames(sameTag = false): {names: Array<string>, missing: Array<any>} {
    // getting the visual link
    const visualLink = this;
    // getting the visual source in the relation
    const visualSource = visualLink.sourceVisualElement as OpmVisualEntity;
    // getting all the links attached to the same source from the same link type
    let OutgoingLinksCurrentOpd = visualSource.getLinks().outGoing.filter(link => link.type === visualLink.type);
    if (sameTag) {
      OutgoingLinksCurrentOpd = OutgoingLinksCurrentOpd.filter(link => link.tag === visualLink.tag);
    }
    const LogicalLinksCurrentOpd = new Array(); // an Array for holding the logical links
    // pushing the elements to LogicalLinksCurrentOpd array
    OutgoingLinksCurrentOpd.forEach(link => { LogicalLinksCurrentOpd.push(link.logicalElement); });
    // An array of all the visual links attached to the source from all OPDs from the same link type
    let arrayOfAllLinks = visualSource.getAllLinks().outGoing.filter(link => link.type === visualLink.type);
    if (sameTag) {
      arrayOfAllLinks = arrayOfAllLinks.filter(link => link.tag === visualLink.tag);
    }
    const arrayOfAllLogicals = new Array(); // An array of all the logical links attached to the source from all OPDs
    // pushing the elements for arrayOfAllLogicals
    arrayOfAllLinks.forEach(link => { arrayOfAllLogicals.push(link.logicalElement); });
    // remove duplicates from arrayOfAllLogicals
    const arrayOfAllUniqueLogicals = [...new Set(arrayOfAllLogicals)];
    // returning the difference between the length of the two arrays which indicates how many entities are missing
    const logicalChildren = [];
    const existingTargets = [];
    for (const link of arrayOfAllUniqueLogicals) {
      existingTargets.push(link.visualElements[0].target.logicalElement);
    }
    if (visualLink.type === linkType.Aggregation) {
      for (const vis of visualLink.source.logicalElement.visualElements) {
        if ((<OpmVisualThing>vis).children) {
          (<OpmVisualThing>vis).children.forEach(child => {
            if (child.type && child.type === visualLink.source.type && !logicalChildren.includes(child.logicalElement))
              existingTargets.push(child.logicalElement);
          });
        }
      }
    }
    for (const link of LogicalLinksCurrentOpd) {
      logicalChildren.push(link.visualElements[0].target.logicalElement);
    }
    const notInOPDNames = [];
    const missing = [];
    existingTargets.filter(trgt  => !logicalChildren.includes(trgt)).forEach(entity => {
      notInOPDNames.push(entity.text);
      missing.push(entity);
    });
    return { names: notInOPDNames, missing };
  }
  // Check if we should add a line to the triangle for the current relation and return the number
  // of missing relations from the same source
  CheckAddLine(sameTag = false): { missingNumber: number, missingProcesses: Array<any>, missingObjectsAndStates: Array<any>} {
    // getting the visual link
    const visualLink = this;
    // getting the visual source in the relation
    const visualSource = visualLink.sourceVisualElement as OpmVisualEntity;
    if (!visualSource || !visualLink.target) {
      return { missingNumber: 0, missingProcesses: [], missingObjectsAndStates: [] };
    }
    // getting all the links attached to the same source from the same link type
    let OutgoingLinksCurrentOpd;
    if (sameTag) {
      OutgoingLinksCurrentOpd = visualSource.getLinks().outGoing.filter(link => link.type === visualLink.type && link.tag === this.tag);
    } else {
      OutgoingLinksCurrentOpd = visualSource.getLinks().outGoing.filter(link => link.type === visualLink.type);
    }
    const LogicalLinksCurrentOpd = new Array(); // an Array for holding the logical links
    // pushing the elements to LogicalLinksCurrentOpd array
    OutgoingLinksCurrentOpd.forEach(link => { LogicalLinksCurrentOpd.push(link.logicalElement); });
    // An array of all the visual links attached to the source from all OPDs from the same link type
    let arrayOfAllLinks;
    if (sameTag) {
      arrayOfAllLinks = visualSource.getAllLinks().outGoing.filter(link => link.type === visualLink.type && link.tag === this.tag);
    } else {
      arrayOfAllLinks = visualSource.getAllLinks().outGoing.filter(link => link.type === visualLink.type);
    }
    const arrayOfAllLogicals = new Array(); // An array of all the logical links attached to the source from all OPDs
    // pushing the elements for arrayOfAllLogicals
    arrayOfAllLinks.forEach(link => { arrayOfAllLogicals.push(link.logicalElement); });
    // remove duplicates from arrayOfAllLogicals
    const arrayOfAllUniqueLogicals = [...new Set(arrayOfAllLogicals)];
    // returning the difference between the length of the two arrays which indicates how many entities are missing
    const logicalChildren = [];
    const existingTargets = [];
    for (const link of arrayOfAllUniqueLogicals) {
      existingTargets.push(link.visualElements[0].target.logicalElement);
    }
    if (visualLink.type === linkType.Aggregation) {
      for (const vis of visualLink.source.logicalElement.visualElements) {
        if ((<OpmVisualThing>vis).children) {
          (<OpmVisualThing>vis).children.forEach(child => {
            if (child.type && child.type === visualLink.source.type && !logicalChildren.includes(child.logicalElement))
              logicalChildren.push(child.logicalElement);
          });
        }
      }
    }
    let existInTotal = arrayOfAllUniqueLogicals.length;
    const existInCurrentOpd = OutgoingLinksCurrentOpd.length;
    for (const child of logicalChildren) {
      if (!existingTargets.find(trgt  => trgt === child))
        existInTotal += 1;
    }

    const missingData = this.getMissingChildrenNames(sameTag);

    return {
      missingNumber: existInTotal - existInCurrentOpd,
      missingProcesses: missingData.missing.filter(e => OPCloudUtils.isInstanceOfLogicalProcess(e)),
      missingObjectsAndStates: missingData.missing.filter(e => !OPCloudUtils.isInstanceOfLogicalProcess(e))
    }
  }
}
