import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { OpmLogicalProcess } from '../../models/LogicalPart/OpmLogicalProcess';
import { OpmLogicalThing } from '../../models/LogicalPart/OpmLogicalThing';
import { OpmLogicalObject } from '../../models/LogicalPart/OpmLogicalObject';
import { GraphService } from '../../rappid-components/services/graph.service';
import { TreeViewService } from '../../rappid-components/services/tree-view.service';
import { OpmModel } from '../../models/OpmModel';
import { OpmOpd } from '../../models/OpmOpd';
import { OpmVisualThing } from '../../models/VisualPart/OpmVisualThing';
import { getInitRappidShared, removeDuplicationsInArray } from '../../configuration/rappidEnviromentFunctionality/shared';
import { OpmEntity } from '../../models/DrawnPart/OpmEntity';
import jspdf from 'jspdf';
import { createDocumentProperties, insertText, newPage } from '../savePdf-dialog/savePdf';
import { ContextService } from '../../modules/app/context.service';

@Component({
  selector: 'grey-items-dialog',
  templateUrl: 'grey-items-dialog.component.html',
  styleUrls: ['grey-items-dialog.component.css'],
})

export class GreyItemsDialogComponent {// implements OnInit{
  searchList;
  constList;
  currentOpd;
  showTypeIndex = 0;
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject];
  searchString = '';
  opmModel: OpmModel;
  graphService: GraphService;
  opd: Array<OpmOpd>;
  showExportOptions = false;
  selectedExportType = 'pdf';

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<GreyItemsDialogComponent>,
    private readonly context: ContextService, public treeViewService: TreeViewService) {
    this.opmModel = treeViewService.initRappid.opmModel;
    this.graphService = treeViewService.initRappid.graphService;
    this.opd = treeViewService.initRappid.opmModel.opds;
    this.addOpdToConstList();
    this.searchList = [...this.constList];
    if (data && data.element) {
      this.searchString = data.element.text;
      this.search();
    }
  }

  thingSearching(evt) {
    this.showTypeIndex = evt.target.value;
    this.updateSearchList();
  }

  updateSearchList() {
    this.searchList = [...this.constList];
    this.filterList();
  }

  private sortFunc(e1, e2): number {
    if (e1.name === e2.name)
      return e1.text < e2.text ? -1 : 1;
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  filterList() {
    this.searchList = this.searchList.filter(e => e.thing instanceof this.showType[this.showTypeIndex])
      .sort((e1, e2) => this.sortFunc(e1, e2));
    if (this.searchString.length > 0) {
      this.searchList = this.searchList.filter(e => e.thing.getBareName().toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    }
  }

  search() {
    this.updateSearchList();
  }

  addOpdToConstList() {
    const updatedList = [];
    for (const item of this.opmModel.logicalElements.filter(elm => elm instanceof OpmLogicalThing)) {
      const newItem = { thing: item, opdElements: [], isMarked: (<OpmLogicalThing<OpmVisualThing>>item).shouldBeGreyed };
      for (let i = 0; i < item.visualElements.length; i++) {
        const opd = this.opmModel.getOpdByThingId(item.visualElements[i].id);
        if (opd && !opd.isHidden)
          newItem.opdElements.push({ name: opd.getNumberedName() + ((opd.getNumberedName() === 'SD') ? '' : ': ' + opd.getName()), id: opd.id });
      }
      updatedList.push(newItem);
    }
    this.constList = updatedList;
  }

  goToOpdById(id) {
    this.graphService.changeGraphModel(id, this.treeViewService, "");
    this.dialogRef.close();
  }

  getOpdsNamesByElement(element, seperator = ', ') {
    return removeDuplicationsInArray(element.opdElements.map(opd => opd.name)).join(seperator);
  }

  cancel() {
    this.dialogRef.close();
  }

  save() {
    for (const item of this.constList) {
      (<any>item.thing).setShouldBeGreyed(item.isMarked);
    }
    for (const cell of getInitRappidShared().graph.getCells().filter(c => c instanceof OpmEntity))
      cell.greyOutEntity();
    this.dialogRef.close();
  }

  selectOrUnSelectAll($event: Event) {
    for (const checkBox of $('.cBox'))
      if ((<any>checkBox).checked !== (<any>$event.target).checked)
        checkBox.click();
  }

  exportToPdf() {
    const pdf = new jspdf({ orientation: 'p', unit: 'pt', format: 'a4', putOnlyUsedFonts: true });
    const pdfProps = createDocumentProperties(pdf, 40, 40);
    const modelName = this.opmModel.name ? this.opmModel.name : 'Unsaved Model';
    pdf.setTextColor('#1A3763');
    insertText('Marked Things in the model: ' + modelName, pdf, pdfProps, false, 'center', 'bold', 18);
    insertText('\n', pdf, pdfProps, false, 'center', 'bold', 8);
    pdf.setTextColor('#000');
    const sortedConstList = this.constList.sort((a, b) => {
      return a.thing.getBareName() > b.thing.getBareName() ? 1 : -1;
    });
    for (const item of sortedConstList) {
      const symbol = item.isMarked ? 'V' : 'X';
      const text = symbol + ' ';
      insertText(text, pdf, pdfProps, true, 'left', 'normal', 11);
      let thingColor = item.thing.constructor.name.includes('Process') ? '#0070c0' : '#00b050';
      if (item.isMarked)
        thingColor = '#000';
      pdf.setTextColor(thingColor);
      insertText(item.thing.getBareName(), pdf, pdfProps, false, 'left', 'normal', 11);
      pdf.setTextColor('#000');
    }
    if ((<any>$('#fullExport')[0]).checked) {
      newPage(pdf, pdfProps);
      pdf.setTextColor('#1A3763');
      insertText('Detailed Marked Things:', pdf, pdfProps, false, 'center', 'bold', 18);
      insertText('\n', pdf, pdfProps, false, 'center', 'bold', 8);
      pdf.setTextColor('#000');
      for (const item of sortedConstList.filter(i => i.isMarked)) {
        pdf.setTextColor('#000');
        insertText(item.thing.getBareName(), pdf, pdfProps, true, 'left', 'normal', 11);
        pdf.setTextColor('#000');
        insertText(', appears at:', pdf, pdfProps, false, 'left', 'normal', 11);
        for (const locationStr of this.getOpdsNamesByElement(item, '\n').split('\n'))
          insertText('   - ' + locationStr, pdf, pdfProps, true, 'left', 'normal', 11);
        insertText('', pdf, pdfProps, true, 'left', 'normal', 11);
      }
    }
    pdf.save(modelName + ' marked things.pdf');
  }

  exportToCSV() {
    const modelName = this.opmModel.name ? this.opmModel.name : 'Unsaved Model';
    const sortedConstList = this.constList.sort((a, b) => {
      return a.thing.getBareName() > b.thing.getBareName() ? 1 : -1;
    });
    let csv = 'Is Marked?, Name';
    for (const item of sortedConstList) {
      const symbol = item.isMarked ? 'V' : 'X';
      csv += '\n' + symbol + ',' + item.thing.getBareName() + ',';
    }

    if ((<any>$('#fullExport')[0]).checked) {
      csv += '\n\n';
      csv += 'Name, Location,';
      for (const item of sortedConstList.filter(i => i.isMarked)) {
        const name = item.thing.getBareName();
        const locations = this.getOpdsNamesByElement(item, '\n').split('\n');
        for (const location of locations)
          csv += '\n' + (locations.indexOf(location) === 0 ? name : ' ') + ',' + location + ',';
      }
    }
    const a = document.createElement("a");
    a.href = URL.createObjectURL(new Blob([csv], { type: "text/csv" }));
    a.setAttribute('download', modelName + '.csv');
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  isReadOnly() {
    return !(this.treeViewService.initRappid.modelService.hasToken === true);
  }

  shouldBeDisabled() {
    return this.context.isReadonly()
  }

  continueToExport() {
    this.save();
    this.showExportOptions = false;
    if (this.selectedExportType === 'pdf')
     this.exportToPdf();
    else
      this.exportToCSV();
    this.dialogRef.close()
    ;
  }

}
