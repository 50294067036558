import { Rule, RuleType } from "./rules";
import { OpmVisualEntity } from "../VisualPart/OpmVisualEntity";
import { OpmVisualObject } from "../VisualPart/OpmVisualObject";
import { EntityType } from "../model/entities.enum";
import { OpmVisualState } from "../VisualPart/OpmVisualState";
import { OpmVisualProcess } from '../VisualPart/OpmVisualProcess';
import { EntitiesSet, createSet as Set } from "./entities.set";
import { LinksSet, all as AllLinks} from "./links.set";
import { linkType } from "../ConfigurationOptions";
import {OpmVisualThing} from '../VisualPart/OpmVisualThing';
import {OpmLogicalProcess} from "../LogicalPart/OpmLogicalProcess";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalThing} from "../LogicalPart/OpmLogicalThing";
import {OpmLogicalObject} from "../LogicalPart/OpmLogicalObject";
import {OpmLogicalEntity} from "../LogicalPart/OpmLogicalEntity";


abstract class StructuralRule<S extends OpmVisualEntity, T extends OpmVisualEntity> implements Rule {

  type(): RuleType {
    return RuleType.STRUCTURAL;
  }

  typeLink(): LinksSet {
    return AllLinks;
  }

  abstract typeSource(): EntitiesSet;

  abstract typeTarget(): EntitiesSet;

  abstract canConnect(source: S, target: T, link: linkType): boolean;

  shouldBeApplied(source: EntityType, target: EntityType, link: linkType): boolean {
    return this.typeSource().contains(source) && this.typeTarget().contains(target);
  }

  abstract message(): string;

  abstract description(): string;

}

class StateCannotConnectToFather extends StructuralRule<OpmVisualState, OpmVisualObject> {

  private readonly source = Set(EntityType.State);
  private readonly target = Set(EntityType.Object);

  typeSource(): EntitiesSet {
    return this.source;
  }

  typeTarget(): EntitiesSet {
    return this.target;
  }

  canConnect(source: OpmVisualState, target: OpmVisualObject): boolean {
    return (source.fatherObject !== target);
  }

  message(): string {
    return 'The state is linked to its owning object by definition.';
  }

  description(): string {
    return 'The state is linked to its owning object by definition.';
  }

}

class ObjectAndStateCannotConnectToThemeselfs extends StructuralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = Set(EntityType.Object, EntityType.State);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    return (source.logicalElement !== target.logicalElement);
  }

  message(): string {
    return 'Entity cannot connect to itself.';
  }

  description(): string {
    return 'Entity cannot connect to itself.';
  }

}

class StateCannotConnectToFatherStates extends StructuralRule<OpmVisualState, OpmVisualState> {

  private readonly source = Set(EntityType.State);
  private readonly target = Set(EntityType.State);

  typeSource(): EntitiesSet {
    return this.source;
  }

  typeTarget(): EntitiesSet {
    return this.target;
  }

  canConnect(source: OpmVisualState, target: OpmVisualState): boolean {
    return (source.fatherObject !== target.fatherObject);
  }

  message(): string {
    return 'State cannot connect to it\'s father\'s other states';
  }

  description(): string {
    return 'State cannot connect to it\'s father\'s other states';
  }

}

class ObjectCannotBeConnectedToItsStates extends StructuralRule<OpmVisualObject, OpmVisualState> {

  private readonly source = Set(EntityType.Object);
  private readonly target = Set(EntityType.State);

  typeSource(): EntitiesSet {
    return this.source;
  }

  typeTarget(): EntitiesSet {
    return this.target;
  }

  canConnect(source: OpmVisualObject, target: OpmVisualState): boolean {
    return (source !== target.fatherObject);
  }

  message(): string {
    return 'Object Cannot Be Connected to Its\'s States';
  }

  description(): string {
    return 'Object Cannot Be Connected to Its\'s States';
  }

}

class ThingCannotConnectToFather extends StructuralRule<OpmVisualObject, OpmVisualObject> {

  private readonly set = Set(EntityType.Object);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualObject, target: OpmVisualObject): boolean {
    return (!((source === target.fatherObject) || (target === source.fatherObject)));
  }

  message(): string {
    return 'Cannot be connected to holder.';
  }

  description(): string {
    return 'A Thing Cannot be connected to it\'s father';
  }

}

class InzoomedProcessCannotConnectToIsSubProcess extends StructuralRule<OpmVisualProcess, OpmVisualProcess> {

  private readonly set = Set(EntityType.Process);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualProcess, target: OpmVisualProcess): boolean {
    if (source.isInzoomed() && target.fatherObject === source && !(<OpmLogicalProcess>target.logicalElement).getIsWaitingProcess())
      return false;
    return true;
  }

  message(): string {
    return 'Inzoomed process cannot be connected to its sub processes by links.';
  }

  description(): string {
    return 'Inzoomed process cannot be connected to its sub processes by links.';
  }

}

class Semifoldinglinks extends StructuralRule<OpmVisualThing, OpmVisualThing> {

  private readonly set = Set(EntityType.Process, EntityType.Object);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (source.isFoldedUnderThing().isFolded && target.isFoldedUnderThing().isFolded)
      return false;
    return true;
  }

  message(): string {
    return 'Semi-folded thing cannot connect<br>to another semi-folded thing.';
  }

  description(): string {
    return 'Semi-folded thing cannot connect to another semi-folded thing.';
  }

}

class CannotLinkToValueTypeObjec extends StructuralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = Set(EntityType.Process, EntityType.Object, EntityType.State);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (source instanceof OpmVisualObject && source.isValueTyped()) {
      return false;
    }
    if (target instanceof OpmVisualObject && target.isValueTyped()) {
      return false;
    }
    if (source instanceof OpmVisualState && source.isValueTyped()) {
      return false;
    }
    if (target instanceof OpmVisualState && target.isValueTyped()) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'A value-type object cannot be connected with any link.';
  }

  description(): string {
    return 'A value-type object cannot be connected with any link.';
  }

}

class CannotLinkToRequirementObject extends StructuralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = Set(EntityType.Process, EntityType.Object, EntityType.State);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const model = source.logicalElement.opmModel;
    if (model.getOpdByThingId(source.id)?.requirementsOpd)
      return true;
    if (OPCloudUtils.isInstanceOfVisualThing(source)) {
      const condition = (<OpmLogicalThing<OpmVisualThing>>source.logicalElement).isSatisfiedRequirementObject();
      const stereotype = (<OpmLogicalThing<OpmVisualThing>>source.logicalElement).getStereotype();
      const stereotypeTargetBelongsTo = (<OpmLogicalEntity<OpmVisualEntity>>target.logicalElement).getBelongsToStereotyped()?.getStereotype();
      if (condition && stereotype && stereotype === stereotypeTargetBelongsTo)
        return true;
      if ((<OpmLogicalThing<OpmVisualThing>>source.logicalElement).isSatisfiedRequirementSetObject()) {
        if (OPCloudUtils.isInstanceOfVisualObject(target) && (<OpmLogicalObject>target.logicalElement).isSatisfiedRequirementObject())
          return true;
        return false;
      }
      if ((<OpmLogicalThing<OpmVisualThing>>source.logicalElement).hasRequirements() && OPCloudUtils.isInstanceOfVisualObject(target)
        && (<OpmLogicalObject>target.logicalElement).isSatisfiedRequirementSetObject())
        return true;
      if ((<OpmLogicalThing<OpmVisualThing>>source.logicalElement).isSatisfiedRequirementObject() && source.getAllLinksWith(target).outGoing.length === 0)
        return false;
    }
    if (OPCloudUtils.isInstanceOfVisualThing(target)) {
      if ((<OpmLogicalThing<OpmVisualThing>>target.logicalElement).isSatisfiedRequirementSetObject())
        return false;
      if ((<OpmLogicalThing<OpmVisualThing>>target.logicalElement).isSatisfiedRequirementObject())
        return false;
    }
    if (OPCloudUtils.isInstanceOfVisualState(source)) {
      const logicalFather = source.fatherObject.logicalElement as OpmLogicalObject;
      if (logicalFather.isSatisfiedRequirementSetObject())
        return false;
      if (logicalFather.isSatisfiedRequirementObject())
        return false;
    }
    if (OPCloudUtils.isInstanceOfVisualState(target)) {
      const logicalFather = target.fatherObject.logicalElement as OpmLogicalObject;
      if (logicalFather.isSatisfiedRequirementSetObject())
        return false;
      if (logicalFather.isSatisfiedRequirementObject())
        return false;
    }
    return true;
  }

  message(): string {
    return 'A requirement object or requirement set object cannot be connected with any link.';
  }

  description(): string {
    return 'A requirement object or requirement set object cannot be connected with any link.';
  }

}

class SourceAndTargetOnSameOPD extends StructuralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = Set(EntityType.Process, EntityType.Object, EntityType.State);

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualState, target: OpmVisualState): boolean {
    const model = source.logicalElement.opmModel;
    return model.getOpdByThingId(source.id) === model.getOpdByThingId(target.id);
  }

  message(): string {
    return 'Source and Target must be on the same OPD';
  }

  description(): string {
    return 'Source and Target must be on the same OPD';
  }

}

export const rules = new Array<Rule>(
  new StateCannotConnectToFather(),
  new ObjectAndStateCannotConnectToThemeselfs(),
  new StateCannotConnectToFatherStates(),
  new ObjectCannotBeConnectedToItsStates(),
  new ThingCannotConnectToFather(),
  new InzoomedProcessCannotConnectToIsSubProcess(),
  new Semifoldinglinks(),
  new CannotLinkToValueTypeObjec(),
  new SourceAndTargetOnSameOPD(),
  new CannotLinkToRequirementObject(),
);



