import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component ({
  selector: 'opcloud-input-name-dialog',
  templateUrl: 'input-name-dialog.html',
  styleUrls: ['input-name-dialog.css']
})
export class InputNameDialogComponent implements OnInit {
  inputName: string;

  constructor(
    @Optional() public dialogRef: MatDialogRef<InputNameDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    if (this.data.passwordFlag === true) {
      const nameInputWrapper = document.getElementById('NameInputWrapper');
      if (nameInputWrapper) {
        nameInputWrapper.classList.add('hideInput');
      }
    } else {
      const passwordWrapper = document.getElementById('passwordWrapper');
      if (passwordWrapper) {
        passwordWrapper.classList.add('hideInput');
      }
    }
    if (this.data.inputName) this.inputName = this.data.inputName;
  }


}
