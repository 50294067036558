// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.button-row {
  position: relative;
  height: 66px;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.button-row .button-group {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin: 2px;
}
.button-row .button {
  border: none;
  background: transparent;
  color: #ffffff;
  margin: 4px;
  box-sizing: content-box;
  box-shadow: none;
}

.mat-spinner-color::ng-deep circle {
  stroke: #FFFFFF !important;
}`, "",{"version":3,"sources":["webpack://./src/app/rappid-components/rappid-toolbar/rappid-toolbar.component.scss"],"names":[],"mappings":"AAOA;EACE,kBAAA;EACA,YAAA;EACA,aAAA;EACA,mBAAA;EACA,6BAAA;AANF;AAQE;EACE,aAAA;EACA,mBAAA;EACA,6BAAA;EACA,WAAA;AANJ;AASE;EACE,YAAA;EACA,uBAAA;EACA,cAAA;EAEA,WAAA;EACA,uBAAA;EACA,gBAAA;AARJ;;AAYA;EACE,0BAAA;AATF","sourcesContent":["//@import '../../../assets/styles/material-theme';\n\n// issue with using custom theme colors\n$button-content: #aff8e6;\n$button-border: #1de9b6;\n\n\n.button-row {\n  position: relative;\n  height: 66px;\n  display: flex;\n  align-items: center;\n  justify-content: space-around;\n\n  .button-group {\n    display: flex;\n    align-items: center;\n    justify-content: space-around;\n    margin: 2px;\n  }\n\n  .button {\n    border: none;\n    background: transparent;\n    color: #ffffff;\n    //color: $button-content;\n    margin: 4px;\n    box-sizing: content-box;\n    box-shadow: none;\n  }\n}\n\n.mat-spinner-color::ng-deep circle{\n  stroke: #FFFFFF !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
