import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OplService } from '../../opl-generation/opl.service';
import { PipeTransform, Pipe } from '@angular/core';
import {oplDefaultSettings, Languages, OplTables, DisplayOpt, UnitsOpt, AliasOpt, defaultSettings} from '../../opl-generation/opl-database';
import * as FileSaver from 'file-saver';
import {Essence} from '../../models/ConfigurationOptions';
import {Affiliation} from '../../models/ConfigurationOptions';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {UserService} from '../../rappid-components/services/user.service';
import {OrganizationService} from '../../rappid-components/services/organization.service';
import {Observable} from 'rxjs/Rx';

@Component({
  selector: 'opcloud-opl-dialog',
  templateUrl: 'opl-dialog.component.html',
  styleUrls: ['./opl-dialog.component.css']
})


export class OplDialogComponent implements OnInit {
  organizations$: Observable<Array<any>>;
  placeholder: string;
  selectedOrg = this.user.user.userData.organization;
  public oplTable;
  public language;
  public availableLanguage;
  public edit = {};
  public essence;
  public affiliation;
  public notes: boolean;
  essenceTypes;
  affiliationTypes;
  displayOpts;
  oplNumbering;
  autoFormat;
  disPlayOpt;
  unitsOpts;
  aliasOpts;
  unitsDOpt;
  aliasDOpt;
  newLanguage;
  essenceNum: Number; // auxiliary variable to switching essence when switching languages
  affiliationNum: Number; // auxiliary variable to switching affiliations when switching languages
  public opdTreeProcessesAutoArrangement: boolean;

  constructor(
    private oplService: OplService, private user: UserService, private orgS: OrganizationService) {
    this.language = oplService.orgOplSettings.language;
    this.oplTable = oplService.orgOplSettings.oplTables[this.language];
    this.availableLanguage = oplService.getAvailableLanguages();
    this.essenceNum = Number(oplService.orgOplSettings.essence);
    this.essence = this.essenceName(oplService.orgOplSettings.essence);
    this.oplNumbering = oplService.orgOplSettings.oplNumbering;
    this.autoFormat = oplService.orgOplSettings.autoFormat;
    this.notes = oplService.orgOplSettings.displayNotes;
    this.affiliationNum = Number(oplService.orgOplSettings.affiliation)
    this.affiliation = this.affiliationName(oplService.orgOplSettings.affiliation);
    this.essenceTypes = [this.oplTable['essence']['physical'], this.oplTable['essence']['informatical']];
    this.affiliationTypes = [this.oplTable['affiliation']['systemic'], this.oplTable['affiliation']['environmental']];
    this.displayOpts = DisplayOpt;
    this.disPlayOpt = this.getEssenceSentence(oplService.orgOplSettings.displayOpt);
    this.unitsOpts = UnitsOpt;
    this.aliasOpts = AliasOpt;
    this.unitsDOpt = oplService.orgOplSettings.unitsOpt;
    this.aliasDOpt = oplService.orgOplSettings.aliasOpt;
    this.opdTreeProcessesAutoArrangement = oplService.orgOplSettings.opdTreeProcessesAutoArrangement;
  }

  getEssenceSentence(sentence: string): string {
    return this.oplService.convertDisplayOptForBackwardCompatibility(sentence);
  }

  ngOnInit() {
    const user = this.user.user.userData;
    if (user.SysAdmin)
      this.organizations$ = this.orgS.getOrganizations();
    else
      this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
    this.placeholder = (user.OrgAdmin) ? user.organization : 'Organization';
    this.updateTable( this.language );
    this.initiateEdit();
    // console.log(this.edit);
  }
    selectOrg(event) {
    const that = this ;
    this.selectedOrg = event.value;
    this.orgS.getOrganization(event.value).then(org => {
      if (org && 'essence' in org) {
        that.essence = that.essenceTypes[Number(org.essence)];
        that.essenceNum = Number(org.essence);
      } else { // else cases are for organization that has default settings
        that.essenceNum = Number(defaultSettings.organization.essence);
        that.essence = that.essenceTypes[Number(that.essenceNum)];
      }
      if (org && 'language' in org) {
        that.language = org.language;
        that.updateTable(that.language);
      } else {
        that.language = defaultSettings.organization.language;
        that.updateTable(that.language);
      }
      if (org && 'displayOpt' in org) {
        that.disPlayOpt = that.getEssenceSentence(org.displayOpt);
      } else {
        that.disPlayOpt = defaultSettings.organization.displayOpt;
      }
      if (org && 'unitsOpt' in org) {
        that.unitsDOpt = org.unitsOpt;
      } else {
        that.unitsDOpt = defaultSettings.organization.unitsOpt;
      }
      if (org && 'aliasOpt' in org) {
        that.aliasDOpt = org.aliasOpt;
      } else {
        that.aliasDOpt = defaultSettings.organization.aliasOpt;
      }
      if (org && 'oplNumbering' in org) {
        that.oplNumbering = org.oplNumbering;
      } else {
        that.oplNumbering = defaultSettings.organization.oplNumbering;
      }
      if (org && 'autoFormat' in org) {
        that.autoFormat = org.autoFormat;
      } else {
        that.autoFormat = defaultSettings.organization.autoFormat;
      }
      if (org && 'notes' in org) {
        that.notes = org.displayNotes;
      } else {
        that.notes = defaultSettings.organization.displayNotes;
      }
    });
  }
  updateTable(lan) {
    this.oplTable = this.oplService.getOplTable(lan);
    this.essenceTypes = [this.oplTable['essence']['physical'], this.oplTable['essence']['informatical']];
    this.affiliationTypes = [this.oplTable['affiliation']['systemic'], this.oplTable['affiliation']['environmental']];
    // update essence and affiliation according to the current essence and affiliation and in the right language
    this.essence = this.essenceTypes[Number(this.essenceNum)];
    this.affiliation = this.affiliationTypes[Number(this.affiliationNum)];
    //oplDefaultSettings.language = lan;
  }


  saveTable() {
    const currProc = this;
    const prevData = [currProc.oplService.orgOplSettings.essence, currProc.oplService.orgOplSettings.language,
      currProc.oplService.orgOplSettings.displayOpt, currProc.oplService.orgOplSettings.oplNumbering,
      currProc.oplService.orgOplSettings.autoFormat, currProc.oplService.orgOplSettings.displayNotes];
    currProc.oplService.orgOplSettings.essence = currProc.essenceToEnum(currProc.essence);
    currProc.oplService.orgOplSettings.language = currProc.language;
    currProc.oplService.orgOplSettings.oplNumbering = currProc.oplNumbering;
    currProc.oplService.orgOplSettings.autoFormat = currProc.autoFormat;
    currProc.oplService.orgOplSettings.displayNotes = currProc.notes;
    currProc.oplService.orgOplSettings.displayOpt = currProc.disPlayOpt;
    currProc.oplService.orgOplSettings.unitsOpt = currProc.unitsDOpt;
    currProc.oplService.orgOplSettings.aliasOpt = currProc.aliasDOpt;
    currProc.oplService.orgOplSettings.opdTreeProcessesAutoArrangement = currProc.opdTreeProcessesAutoArrangement;
    const settings = currProc.oplService.orgOplSettings;
    const details = {};
    currProc.essenceNum = Number(currProc.essenceToEnum(currProc.essence));
    Object.keys(settings).forEach(key => {
      if (settings[key] === undefined)
        return;
      if(key === 'oplTables'){
        details[key]={};
        for(let lan of Object.keys(settings[key])){
          if(Languages.indexOf(lan)>-1){
            continue;
          }
          details[key][lan] = settings[key][lan];
        }
        return;
      }
      if (key === 'essence') {
        details[key] = String(settings[key]);
        return;
      }
      details[key] = settings[key];
    });
    currProc.orgS.updateOrganization(currProc.selectedOrg, details)
      .then(result => {validationAlert('Settings Saved', null, 'Success'); })
      .catch(err => {validationAlert('Error! could not update organization details ' + err, null, 'Error'); });
  }

  cancelTableChange() {
    this.oplTable = OplTables[this.language];
  }
  ReturnToDefault(){
    this.language = defaultSettings.organization.language;
    this.oplTable = this.oplService.getOplTable(this.language);
    this.essence = this.essenceName(defaultSettings.organization.essence);
    this.essenceNum = Number(defaultSettings.organization.essence);
    this.disPlayOpt = defaultSettings.organization.displayOpt;
    this.unitsDOpt = defaultSettings.organization.unitsOpt;
    this.aliasDOpt = defaultSettings.organization.aliasOpt;
    this.oplNumbering = defaultSettings.organization.oplNumbering;
    this.autoFormat = defaultSettings.organization.autoFormat;
    this.notes = defaultSettings.organization.displayNotes;
    this.essenceTypes = [this.oplTable['essence']['physical'], this.oplTable['essence']['informatical']];
  }
  initiateEdit() {
    for (const relation of Object.keys(this.oplTable)){
      this.edit[relation] = {};
      for (const link of Object.keys(this.oplTable[relation])){
        this.edit[relation][link] = false;
      }
    }
  }
  addLanguage(lan){
    if(lan === undefined){
      alert('Language most have name.');
      return;
    }
    if (this.availableLanguage.indexOf(lan) === -1){
      oplDefaultSettings.language = lan;
      OplTables[lan] = this.oplTable;
      this.oplService.orgOplSettings.oplTables[lan] = this.oplTable;
      // console.log(OplTables);
      // console.log(this.oplService.orgOplSettings.oplTables);
      this.oplService.updateOrgSettings();
      validationAlert(`New language ${lan} is added!`, null, 'Success');
    }else{
      validationAlert('The language already exists!', null, 'Error');
    }
    this.availableLanguage = this.oplService.getAvailableLanguages();
    this.newLanguage = '';
  }
  exportTable(){
    const table = JSON.stringify(this.oplTable, null, ' ');
    FileSaver.saveAs(new Blob([table], {type: 'text/plain;charset=utf-8'}),
      'OPL_table_' + this.language + '.txt');
  }
  private toDisplay(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  private displayLanguages(str: string) {
    switch (str) {
      case 'en': return 'English';
      case 'cn': return 'Chinese';
      case 'fr' : return 'French';
      case 'gr' : return 'German';
      case 'ko' : return 'Korean';
      case 'jp' : return 'Japanese';
      case 'es': return 'Spanish';
      case 'ml' : return 'Malayalam';
      case 'ru' : return 'Russian';
      case 'pt' : return 'Portuguese';
      default: return this.toDisplay(str); // should not get here unless new languages were added
    }
  }

  essenceName(essence:Essence){
    if(essence === Essence.Physical)
      return this.oplTable['essence']['physical'];
    if(essence === Essence.Informatical)
      return this.oplTable['essence']['informatical'];
    console.log("ERROR: ",essence);
  }
  affiliationName(affiliation:Affiliation){
    if(affiliation === Affiliation.Systemic)
      return this.oplTable['affiliation']['systemic'];
    if(affiliation === Affiliation.Environmental)
      return this.oplTable['affiliation']['environmental'];
    console.log("ERROR: ",affiliation);
  }
  essenceToEnum(essence){
    if(essence === this.oplTable['essence']['physical']){
      return Essence.Physical;
    }
    return Essence.Informatical;
  }

  affiliationToEnum(a){
    if( a === this.oplTable['affiliation']['systemic'])
      return Affiliation.Systemic;
    return Affiliation.Environmental;
  }
}

@Pipe({name: 'keys'})
export class KeysPipe implements PipeTransform {
  transform(value, args: string[]): any {
    const keys = [];
    let key;
    for ( key in value) {
      keys.push({key: key, value: value[key]});
    }
    return keys;
  }
}


//<input type="text" [value]="link.value" class="tdValue"
//  [(ngModel)]="link.value"
//(keyup.enter)="changeReservedPhrase(relation.key, link.key, $event)">
