import { OpmLogicalThing } from '../../models/LogicalPart/OpmLogicalThing';
import { OpmLogicalProcess } from '../../models/LogicalPart/OpmLogicalProcess';
import { OpmLogicalObject } from '../../models/LogicalPart/OpmLogicalObject';
import {
  Component, DoCheck, IterableDiffers,
} from '@angular/core';
import {ModelService} from '../../modules/app/model.service';
import {SimulationElementComponent} from '../simulationElement/SimulationElement';
import {
  getInitRappidShared,
  OPCloudUtils,
  validationAlert
} from '../../configuration/rappidEnviromentFunctionality/shared';
import {code, linkType, valueType} from '../../models/ConfigurationOptions';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import * as Random from 'random';
import {MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";

@Component({
  selector: 'Simulate',
  templateUrl: './Simulation.html',
  styleUrls: ['./Simulation.css']
})
export class SimulationComponent implements DoCheck{
  list;
  constList;

  resultedValues = {};
  differ;
  currentOpd;
  showTypeIndex = 0;
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject];
  searchString = '';
  searchSD = '';
  dictionary = {};
  public isActive = true;
  public menuOpen = false;
  InitRappidService: InitRappidService;
  _dialog: MatDialog;
  init: InitRappidService;

  constructor(public modelService: ModelService, differs: IterableDiffers, public dialogRef: MatDialogRef<SimulationComponent>) {
    this.constList = this.modelService.model.logicalElements;
    this.list = this.constList;
    this.differ = differs.find([]).create(null);
    for (const element of this.list) {
      this.dictionary[element.id] = {};
      this.dictionary[element.id]['text'] = element.text;
      this.dictionary[element.id]['name'] = element.name;
    }
  }

  onchange(event) {
    this.showTypeIndex = event.target.value;
    this.updateList();
  }

  updateList() {
    this.list = this.modelService.model.logicalElements;
    this.filter();
  }

  filter() {
    this.list = this.list.filter(e => e instanceof this.showType[this.showTypeIndex])
      .sort((e1, e2) => this.sortFunc(e1, e2));
    if (this.searchString.length > 0) {
      this.list = this.list.filter(a => a._text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    }
  }

  private sortFunc(e1, e2): number {
    if (e1.name === e2.name) {
      return e1.text < e2.text ? -1 : 1;
    }
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  simulationAction(init: InitRappidService, element?) {
    const sim = element.getSimulationParams();
    getInitRappidShared().dialogService.openDialog(SimulationElementComponent, 700, 600, {sim: sim, logical: element, allowMultipleDialogs: true});
    this.toggleMenu();
  }

  ngDoCheck() {
    if ($('.thingDiv').length > 0 && $('.cdk-overlay-pane').length === 1) {
      let maximalDivWidth = $('.thingDiv')[0].getClientRects()[0].width;
      for (const div of $('.thingDiv'))
        if (div.getClientRects()[0].width > maximalDivWidth)
          maximalDivWidth = div.getClientRects()[0].width;
      const newWidth = Math.max(Math.min(maximalDivWidth + 340, window.innerWidth * 0.99), 600);
      $('.cdk-overlay-pane')[0].style.width = newWidth + 'px';
    }
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.isActive = true;
      return;
    } else if (!this.menuOpen) {
      this.menuOpen = true;
      this.isActive = false;
    }
  }

  search() {
    this.updateList();
  }

  isComputational(element) {
    if (element.constructor.name === 'OpmLogicalObject') {
      return (element.valueType !== valueType.None && element.valueType !== undefined);
    }
    if (element.constructor.name === 'OpmLogicalProcess') {
      return (element.code !== code.Unspecified && element.code !== undefined);
    }
  }

  close() {
    const nonParametricThings = this.list.filter(l => OPCloudUtils.isInstanceOfLogicalThing(l) && l.getSimulationParams().simulated && l.getRandomValues(1).length === 0)
    if (nonParametricThings.length > 0) {
      let msg = 'The following things have no simulation params set:'
      for (const log of nonParametricThings)
        msg += '<br>  -' + log.getBareName();
      validationAlert(msg, 5000, 'error');
    }
    this.dialogRef.close();
  }

  changedSelection($event: Event, element: any) {
    element.getSimulationParams().simulated = (<any>$event.target).checked;
  }

  reset() {
    this.modelService.model.logicalElements.forEach(log => {
      if (log instanceof OpmLogicalThing)
        log.getSimulationParams().simulated = false;
    });
  }

  checkOrUncheckAll($event: Event) {
    for (const logical of this.list.filter(item => this.isComputational(item) && item.constructor.name !== 'OpmLogicalState')) {
      logical.getSimulationParams().simulated = (<any>$event.target).checked;
    }
  }
}
