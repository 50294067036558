import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'settins-undercon',
  template: `
    <h1> Under Construction</h1>
                  <img src="http://www.adipofilling.com/images/Under_Construction.PNG"/>
`,
  styleUrls: ['./settings.component.scss']
})
export class SettingsUnderConstruction implements OnInit {


  constructor(  ){}

  ngOnInit() {

  }

}
