
import {joint, convert, stylePopup} from './../../../../configuration/rappidEnviromentFunctionality/shared';
import { InitRappidService } from '../../../../rappid-components/services/init-rappid.service';

import { OpmVisualObject } from '../../../VisualPart/OpmVisualObject';
import { OpmLogicalObject } from '../../../LogicalPart/OpmLogicalObject';
import { OpmObject } from '../../OpmObject';

export function UnitsPopup(drawn: OpmObject, initRappid: InitRappidService, onFinish: () => void = () => { }) {
    const view = initRappid.paper.findViewByModel(drawn);
    const visual = initRappid.opmModel.getVisualElementById(drawn.get('id')) as OpmVisualObject;
    const logical = visual.logicalElement as OpmLogicalObject;
    const popup = new joint.ui.Popup({
        id: 'units_popup',
        events: {
            'keypress': function (event) {
                if (event.which == 13) {
                    const new_val = this.$('#value').val();
                    logical.units = new_val;
                    drawn.attr({ value: { units: logical.units } }); // TODO: Make this line have no effect.
                    drawn.updateSiblings(visual, initRappid);
                    popup.remove();
                    onFinish();
                }
            },
            'input #value': function () {
                const value = this.$('#value').val();
                const select = this.$('ul');
                select.empty();
                select.html(unitsHtml(value));
            },
            'click .li-selection': click_li_selection_function,
            'click #update': function () {
                const new_val = this.$('#value').val();
                logical.units = new_val;
                drawn.attr({ value: { units: logical.units } }); // TODO: Make this line have no effect.
                drawn.updateSiblings(visual, initRappid);
                popup.remove();
                onFinish();
            }
        },
        content: [createUnitsPopUpContent(logical.units, ' Edit Units:')],
        target: view.el
    }).render();
    const fieldInput = popup.$('#value');
    const fldLength = fieldInput.val().length;
    fieldInput.focus();
    fieldInput[0].setSelectionRange(0, fldLength);
    stylePopup();
}
// return the string that represents the units pop content template
export function createUnitsPopUpContent(value: string, header: string) {
  return '<div class="units-popup">' +
  '           <label class="popupHeader">' + header + '</label>' +
  '           <br>' +
  '           <div>' +
  '             <div class="dropdown">' +
  '               <div id="myDropdown" class="dropdown-content">' +
  '                 <input type="text" placeholder="Insert units" id="value" value="' + value + '">' +
  '                 <ul id="select">' + unitsHtml(value) + '</ul>' +
  '               </div>' +
  '               <button id="update" class="btnUpdate Popup" style="margin-top: 6px;">Update</button>' +
  '             </div>' +
  '           </div>' +
  '          </div>';
}
// show all units options
export function unitsHtml(value: string) {
  const allUnits = convert().possibilities();
  value = value ? value.toLowerCase() : '';
  let relevantUnits = allUnits.filter(unit => unit.toLowerCase().includes(value) || value === '');
  let html = '';
  for (let k = 0; k < relevantUnits.length; k++) {
    html = html + '<li class="li-selection" data-value="' + relevantUnits[k] + '"';
    html = html + ' title="' + convert().describe(relevantUnits[k]).singular + ', '
      + convert().describe(relevantUnits[k]).measure + '">';
    html = html + relevantUnits[k] + '</li>';
  }
  return html;
}
// handling choosing a unit
export function click_li_selection_function (event) {
  const value = $(event.target).data('value');
  this.$('#value').val(value);
  this.$('#value').focus();
  const select = this.$('ul');
  select.empty();
  select.html(unitsHtml(value));
}
