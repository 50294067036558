
import {HttpClient} from "@angular/common/http";
import {InitRappidService} from "./init-rappid.service";
import {Injectable} from "@angular/core";
import {OpmModel} from "../../models/OpmModel";

@Injectable({
  providedIn: "root",
})
export class ServerFlatteningService {

  constructor(private initRappid: InitRappidService, private http: HttpClient) {
  }

  getFlattenedModelFromServer(leafsOnly = false): Promise<{success: boolean, serverModel?: OpmModel}> {
    const serverURL = this.initRappid.oplService.settings.connection.calculationsServer.computingServerURL;
    const jsonModel = JSON.stringify(this.initRappid.getOpmModel().toJson());
    return this.http.post<any>(serverURL + '/flattening', { model: jsonModel, leafsOnly}).toPromise().then((retModel) => {
      const serverModel = new OpmModel();
      serverModel.fromJson(retModel.data);
      return {
        success: true,
        serverModel: serverModel
      };
    }).catch(err => {
      return {
        success: false
      };
    });
  }

  getModelClustersFromServer(dsmArray): Promise<{success: boolean, clusters?}> {
    const serverURL = this.initRappid.oplService.settings.connection.calculationsServer.computingServerURL;
    return this.http.post<any>(serverURL + '/clustering', { dsmArray: dsmArray}).toPromise().then((ret) => {
      return {
        success: true,
        clusters: ret.clusters
      };
    }).catch(err => {
      return {
        success: false
      };
    });
  }

  getPartitionsFromServer(dsmArray): Promise<{success: boolean, partitionedDSM?}> {
    const serverURL = this.initRappid.oplService.settings.connection.calculationsServer.computingServerURL;
    return this.http.post<any>(serverURL + '/partitioning', { dsmArray: dsmArray}).toPromise().then((ret) => {
      return {
        success: true,
        partitionedDSM: ret.partitionedDSM
      };
    }).catch(err => {
      return {
        success: false
      };
    });
  }
}
