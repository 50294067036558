import { Component, OnInit } from '@angular/core';
import { OrganizationService } from '../../../../rappid-components/services/organization.service';
import { GroupsService } from '../../../../rappid-components/services/groups.service';
import { UserService } from '../../../../rappid-components/services/user.service';
import { validationAlert } from '../../../../configuration/rappidEnviromentFunctionality/shared';
import { Observable } from 'rxjs';
import {CollaborationDialogComponent} from '../../../../dialogs/collaboration-dialog/collaboration-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
@Component({
  selector: 'opcloud-add-group',
  templateUrl: './add-group.component.html',
  styleUrls: ['./add-group.component.css']
})
export class AddGroupComponent implements OnInit {
  descriptionPlaceHolder: string;
  namePlaceHolder: string;
  parentPlaceHolder: string;
  selectedOrg: '';
  currentUser;
  parentGroup = '';
  parentGroupName = '';
  group = {
    nameOfGroup: '',
    ID:  '',
    descriptionOfGroup: '',
    parentID: ''
  };
  organizations$: Observable<Array<any>>;
  orgUsers = [];
  multipleusersToAdd = [];
  createAdminGroup = true;
  constructor(private _dialog: MatDialog, private orgService: OrganizationService, private groupService: GroupsService,
  private userService: UserService) {
  }

  ngOnInit() {
    let user;
    if (this.userService.user) {
      user = this.userService.user.userData ;
    }
    if (user && user.SysAdmin){
      this.organizations$ = this.orgService.getOrganizations();
    } else {
      this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
    }
    this.descriptionPlaceHolder = 'Enter Description...';
    this.namePlaceHolder = 'Enter Name...';
    this.parentPlaceHolder = 'Parent Name';
    this.userService.user$.subscribe(user => this.currentUser = user);
    this.groupService.updateOrgUsers();
    this.selectedOrg = user.organization;
  }

  getOrgRef(event: any) {
    const btn = <HTMLInputElement>document.getElementById('orgButton');
    btn.disabled = false;
    this.selectedOrg = event.value;
    this.groupService.updateOrgGroups(this.selectedOrg);
    this.groupService.updateOrgUsers(this.selectedOrg);
  }

  collDialogOpen() {
    const dialogRef = this._dialog.open(CollaborationDialogComponent, {
      // height: '600px',
      width: '775px',
      data: {
        userToken: '',
        headLine: "Select group's parent in " + this.selectedOrg + ' organization',
        userChkBoxFlag : false,
        groupChkBoxFlag : true,
        groupChkBoxOneAble : true,
        currentUser: this.currentUser,
        org: this.selectedOrg,
        checkedUsers: [],
        checkedGroups: [this.parentGroup],
        modelOwner: ''
      },
    });

    dialogRef.afterClosed().subscribe((data) => {
        if (data) {
          this.group = {
            nameOfGroup: '',
            ID:  '',
            descriptionOfGroup: '',
            parentID: ''
          };
          this.parentGroup = data.chosenGroup;
          if (this.parentGroup === '') this.parentGroupName = '';
          else {
              this.orgService.getGroupFromOrg(this.selectedOrg, this.parentGroup).then(group => {
                this.parentGroupName = group.Name;
              });
          }
          document.getElementById('userDisplayForm').hidden = true;

        }
    });
  }
  saveParams() {
    if (typeof this.selectedOrg === 'undefined' || this.group.nameOfGroup === '') {
      const message = 'Something is missing..., you must choose an organization and a name for the group' ;
      validationAlert(message, 4500, 'Error' );
      return;
    }
    this.addGroup();
    this.getOrgUsersForDisplay(this.selectedOrg, this.parentGroup);
    this.groupService.updateOrgGroups(this.selectedOrg);
    this.group = {
      nameOfGroup: this.group.nameOfGroup,
      descriptionOfGroup: this.group.descriptionOfGroup,
      parentID: this.parentGroup,
      ID: ''
    };
  }

  addGroup() {
    this.orgService.getGroups(this.selectedOrg).then(groups => {
      let groupExists = false;
      groups.forEach(group => {
        if ((group.Name === this.group.nameOfGroup) && (group.Parent === this.group.parentID)) {
          groupExists = true;
        }
      });
      if (groupExists) {
        const message = this.group.nameOfGroup + ' already exists in organization ' + this.selectedOrg + ' please choose different name';
        validationAlert(message, 4500, 'Error' );
        document.getElementById('userDisplayForm').hidden = true;
        return false;
      } else {
        const groupIDPromise = this.groupService.addGroup(this.selectedOrg, this.group.nameOfGroup, this.group.descriptionOfGroup, this.parentGroup, this.parentGroupName);
        groupIDPromise.then(groupID => {
          this.group.ID = groupID;
          document.getElementById('userDisplayForm').hidden = false;
          this.groupService.updateOrgGroups(this.selectedOrg);
          const success_adding_group_message = 'creating group succeeded';
          validationAlert(success_adding_group_message, null, 'Success' );
          return true;
        });
      }
    });

  }

  getOrgUsersForDisplay(org, parent) {
    this.orgUsers = [];
      return this.orgService.getOrganizationUsers(org).then(members => {
        if (parent === '') {   // get parent members only
          return this.orgUsers = members;
        } else {
          this.orgService.getGroupFromOrg(org, parent).then(parentGroup => {
            const parentMembersIDs = Object.getOwnPropertyNames(parentGroup.Members);
            members.forEach(member => {
              if (parentMembersIDs.includes(member.uid)) this.orgUsers.push(member);
            });
            return this.orgUsers;
          });
        }
      });
  }

  addMember(user) {
    document.getElementById('Add' + user.uid).hidden = true;
    document.getElementById('Del' + user.uid).hidden = false;
    this.groupService.addMembers(user, this.group.ID, this.selectedOrg);
    validationAlert('Changes were saved', null, 'Success');
  }

  addMultipleUsers(user) {
    document.getElementById('Add' + user.uid).hidden = true;
    if (this.multipleusersToAdd.find(u => u.uid === user.uid) === undefined)
      this.multipleusersToAdd.push(user);
  }

  deleteMember(user) {
    document.getElementById('Add' + user.uid).hidden = false;
    document.getElementById('Del' + user.uid).hidden = true;
    this.groupService.delUserFromGroup(this.selectedOrg, this.group.ID, user);
    validationAlert('Changes were saved', null, 'Success');
  }

  deleteAdminUser(user) {
    document.getElementById('AdminAdd' + user.uid).hidden = false;
    document.getElementById('AdminDel' + user.uid).hidden = true;
    this.groupService.delAdminGroupUser(this.selectedOrg, this.group.ID, user);
    validationAlert('Changes were saved', null, 'Success');
  }

  addAdminGroup() {
    if (this.createAdminGroup) {
      this.createAdminGroup = false;
    } else {
      this.createAdminGroup = true;
    }
  }

  addMemberToAdmin(user) {
    document.getElementById('AdminAdd' + user.uid).hidden = true;
    document.getElementById('AdminDel' + user.uid).hidden = false;
    this.groupService.addUserToAdminGroup(this.selectedOrg, this.group.ID, user);
    validationAlert('Changes were saved', null, 'Success');
  }



}
