import { Component } from '@angular/core';

export interface Command {
  group: string,
  commands: [{ name: string, tooltip: string, icon: string }]
}

export abstract class ToolbarComponent {

  public readonly title: string = "The Best Toolbar In The World";

  public readonly commands: any;

  constructor() {
    this.commands = this.getCommands();
  }

  public abstract getCommands(): any;

  protected buttonClick(command) {
    const func = command.name.substring(0, command.name.indexOf('(')) || command.name;
    const args = command.name.substring(command.name.indexOf('(') + 1, command.name.indexOf(')')) || '';
    return this[func](args);
  }

}
