// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
::ng-deep .mat-form-field-underline {
  display: none;
}

.mat-dialog-content {
  overflow: hidden;
  height: 75px;
}

h2 {
  text-align: center;
  color: #1a3763;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/reset-initial-password/reset-initial-password.component.css"],"names":[],"mappings":";AACA;EACE,aAAa;AACf;;AAEA;EACE,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB","sourcesContent":["\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n\n.mat-dialog-content {\n  overflow: hidden;\n  height: 75px;\n}\n\nh2 {\n  text-align: center;\n  color: #1a3763;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
