// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.resize-bar {
  position: absolute;
  background: transparent;
  z-index: 1;
}
.resize-bar .resize-bar-control {
  border: solid transparent 0;
  visibility: hidden;
}
.resize-bar:hover .resize-bar-control {
  visibility: visible;
  border-color: #1A3763;
}
.resize-bar.top, .resize-bar.bottom {
  cursor: row-resize;
  width: 100%;
  left: 0;
  height: 8px;
}
.resize-bar.top .resize-bar-control, .resize-bar.bottom .resize-bar-control {
  height: 1px;
  border-width: 1px 0;
}
.resize-bar.top {
  top: -5px;
  margin-top: 5px;
}
.resize-bar.bottom {
  bottom: 0;
}
.resize-bar.bottom .resize-bar-control {
  margin-top: 5px;
}
.resize-bar.left, .resize-bar.right {
  cursor: col-resize;
  height: 100%;
  top: 0;
  position: absolute;
  width: 8px;
}
.resize-bar.left .resize-bar-control, .resize-bar.right .resize-bar-control {
  height: 100%;
  width: 1px;
  border-width: 0 1px;
}
.resize-bar.left {
  left: 0;
}
.resize-bar.right {
  right: 0;
}
.resize-bar.right .resize-bar-control {
  margin: 0 5px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/shared/resize-bar/resize-bar.component.scss"],"names":[],"mappings":"AAAA;EACE,kBAAA;EAGA,uBAAA;EACA,UAAA;AADF;AAGE;EACE,2BAAA;EACA,kBAAA;AADJ;AAKI;EACE,mBAAA;EACA,qBAAA;AAHN;AAOE;EACE,kBAAA;EACA,WAAA;EACA,OAAA;EACA,WAAA;AALJ;AAOI;EACE,WAAA;EACA,mBAAA;AALN;AASE;EACE,SAAA;EACA,eAAA;AAPJ;AAUE;EACE,SAAA;AARJ;AASI;EACE,eAAA;AAPN;AAWE;EACE,kBAAA;EACA,YAAA;EACA,MAAA;EACA,kBAAA;EACA,UAAA;AATJ;AAWI;EACE,YAAA;EACA,UAAA;EACA,mBAAA;AATN;AAaE;EACE,OAAA;AAXJ;AAcE;EACE,QAAA;AAZJ;AAcI;EACE,aAAA;AAZN","sourcesContent":[".resize-bar {\n  position: absolute;\n  // for debugging\n  //background: rgba(255, 255, 0, 0.42);\n  background: transparent;\n  z-index: 1;\n\n  .resize-bar-control {\n    border: solid transparent 0;\n    visibility: hidden;\n  }\n\n  &:hover {\n    .resize-bar-control {\n      visibility: visible;\n      border-color: #1A3763;;\n    }\n  }\n\n  &.top, &.bottom {\n    cursor: row-resize;\n    width: 100%;\n    left: 0;\n    height: 8px;\n\n    .resize-bar-control {\n      height: 1px;\n      border-width: 1px 0;\n    }\n  }\n\n  &.top {\n    top: -5px;\n    margin-top: 5px;\n  }\n\n  &.bottom {\n    bottom: 0;\n    .resize-bar-control {\n      margin-top: 5px;\n    }\n  }\n\n  &.left, &.right {\n    cursor: col-resize;\n    height: 100%;\n    top: 0;\n    position: absolute;\n    width: 8px;\n\n    .resize-bar-control {\n      height: 100%;\n      width: 1px;\n      border-width: 0 1px;\n    }\n  }\n\n  &.left {\n    left: 0;\n  }\n\n  &.right {\n    right: 0;\n\n    .resize-bar-control {\n      margin: 0 5px;\n    }\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
