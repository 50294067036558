import {OpmVisualElement} from '../VisualPart/OpmVisualElement';

export class ElementsMap<T> {

  constructor(private readonly map: Map<String, T>) {
  }

  public get(id: string): T {
    if (!id)
      return undefined;
    const visual = this.map.get(id);
    if (!visual)
      throw new Error('element with id ' + id + ' was not found');
    return visual;
  }

}
