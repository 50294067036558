import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GraphService } from "../../rappid-components/services/graph.service";
import { TreeViewService } from "../../rappid-components/services/tree-view.service";
import { OpmModel } from "../../models/OpmModel";
import { OpmOpd } from "../../models/OpmOpd";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";

@Component({
  selector: 'existing-name-dialog',
  templateUrl: 'existing-name-dialog.component.html',
  styleUrls: ['existing-name-dialog.component.css'],
})
export class ExistingNameDialogComponent {
  currentOpd;
  opmModel: OpmModel;
  graphService: GraphService;
  logical;
  logicalToRename;
  title: string;
  locations: Array<{opd: OpmOpd, opdId: string, name: string, visId: string}>;
  shouldShowUseExisting: boolean;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<ExistingNameDialogComponent>,
    public treeViewService: TreeViewService) {
    this.logical = data.logical;
    this.logicalToRename = data.logicalToRename;
    this.graphService = treeViewService.initRappid.graphService;
    const type = this.logical.constructor.name.replace('OpmLogical','');
    const anOrA = type.startsWith('O') ? 'An' : 'A';
    this.title = anOrA + ' ' + type.toLowerCase() + ' by the name ' + this.logical.getBareName() + ' already exists';
    this.setLocations();
    this.setShouldShowUseExisting();
  }

  private setShouldShowUseExisting() {
    this.shouldShowUseExisting = true;
    const vis = this.logicalToRename.visualElements[0];
    const links = this.logicalToRename.visualElements.map(v => v.getAllLinks());
    if (links.some(item => item.inGoing.length > 0 || item.outGoing.length > 0)) {
      this.shouldShowUseExisting = false;
    } else if (this.logicalToRename.visualElements.find(v => v.fatherObject)) {
      this.shouldShowUseExisting = false;
    } else if (vis.getRefineeInzoom() || vis.getRefineeUnfold()) {
      this.shouldShowUseExisting = false;
    } else if (OPCloudUtils.isInstanceOfLogicalObject(this.logicalToRename) && this.logicalToRename.states.length > 0) {
      this.shouldShowUseExisting = false;
    }
  }

  private setLocations() {
    this.locations = [];
    for (const vis of this.logical.visualElements) {
      const opd = this.logical.opmModel.getOpdByThingId(vis.id);
      this.locations.push({ opd: opd, opdId: opd.id, name: opd.getNumberedName() + (opd.getName() === 'SD' ? '' : (': ' +opd.getName())), visId: vis.id});
    }
    const model = this.logical.opmModel;
    this.locations.sort((a,b) => {
      return model.opds.indexOf(a.opd) > model.opds.indexOf(b.opd) ? 1 : -1;
    })
  }

  goToOpdById(opdId: string) {
    this.treeViewService.initRappid.opdHierarchyRef.previousOpdId = this.logical.opmModel.currentOpd.id;
    const opd = this.logical.opmModel.getOpd(opdId);
    if (opd)
      this.graphService.renderGraph(opd, this.treeViewService.initRappid, this.logical);
    this.dialogRef.close();
  }

  onStartDrag(event) {
    if (!!event.touches)
      return;
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e) };
    window.onmouseup = function (e) { that.endDrag(e) };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
  }

  moveDrag(event) {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    this.dialogRef.updatePosition({ left: (event.clientX - 560) + "px", top: (event.clientY - 35 + scrollTop) + "px" })
  }

  renameCurrentElement() {
    this.dialogRef.close({ action: 'rename' });
  }

  useExistingElement() {
    this.dialogRef.close({ action: 'useExisting', existing: this.logical });
  }
}
