import { OpmLogicalElement } from './LogicalPart/OpmLogicalElement';
import { OpmVisualElement } from './VisualPart/OpmVisualElement';
import { OpmOpd } from './OpmOpd';
import { OpmLogicalObject } from './LogicalPart/OpmLogicalObject';
import { OpmLogicalProcess } from './LogicalPart/OpmLogicalProcess';
import { OpmVisualObject } from './VisualPart/OpmVisualObject';
import { OpmVisualProcess } from './VisualPart/OpmVisualProcess';
import { OpmVisualState } from './VisualPart/OpmVisualState';
import { OpmLink } from './VisualPart/OpmLink';
import { OpmLogicalEntity } from './LogicalPart/OpmLogicalEntity';
import { OpmVisualEntity } from './VisualPart/OpmVisualEntity';
import { OpmVisualThing } from './VisualPart/OpmVisualThing';
import { OpmLogicalThing } from './LogicalPart/OpmLogicalThing';
import { OpmModelMetaData } from './OpmModelMetaData';
import { EntityType, RelationType } from './model/entities.enum';
import { logicalFactory } from './logical.factory';
import { LinksModel } from './consistency/links.model';
import { util } from "jointjs";
import uuid = util.uuid;
import { OpmLogicalState } from './LogicalPart/OpmLogicalState';
import {OpmRelation} from './LogicalPart/OpmRelation';
import {OPCloudUtils, removeDuplicationsInArray} from '../configuration/rappidEnviromentFunctionality/shared';
import {StereotypeManager} from './stereotypeManager';
import { RangeValidationAccess } from './components/range-validation/range-validation';


export class BasicOpmModel {

  public name: string;
  public description: string;
  public archiveMode: boolean;
  public permissions: any;
  public logicalElements: Array<OpmLogicalElement<OpmVisualElement>>;
  public opds: Array<OpmOpd>;
  public currentOpd: OpmOpd;
  public modelMetaData: OpmModelMetaData;
  public importedTemplates;
  public thingsNames;
  public relatedRelations: Array<Array<OpmRelation<OpmLink>>>;
  public stereotypes: StereotypeManager;
  public isCurrentlyOnStereotypeCreation = 0;
  public readonly links: LinksModel = new LinksModel(this);
  private _autoOpdTreeSort: boolean;
  public visualsMap: Map<string, OpmVisualElement>;
  public fatherModelName: string;
  public hasUnsavedWork: boolean;

  constructor() {
    this.logicalElements = new Array<OpmLogicalElement<OpmVisualElement>>();
    this.opds = new Array<OpmOpd>();
    this.currentOpd = new OpmOpd('SD');
    this.opds.push(this.currentOpd);
    this.description = '';
    this.archiveMode = false
    this.modelMetaData = new OpmModelMetaData(this);
    this.name = "Model (Not Saved)";
    this.permissions = { ownerID: '', writeIDs: [], readIDs: [], tokenID: '', writeGroupsIDs: [], readGroupsIDs: [] };
    this.thingsNames = {};
    this.importedTemplates = {};
    this.relatedRelations = [];
    this.stereotypes = new StereotypeManager();
    this.visualsMap = new Map<string, OpmVisualElement>();
    this.hasUnsavedWork = false;
  }

  set autoOpdTreeSort(value: boolean) {
    this._autoOpdTreeSort = value;
  }

  get autoOpdTreeSort(): boolean {
    return this._autoOpdTreeSort;
  }


  addOpd(opd: OpmOpd) {
    this.opds.push(opd);
    this.currentOpd = opd;
  }

  add(opmLogicalElement: OpmLogicalElement<OpmVisualElement>) {
    this.logicalElements.push(opmLogicalElement);
  }

  getVisualElementById(visualID) {
    if (!visualID)
      return null;

    let visual = this.visualsMap.get(visualID);
    const stereotypes = this.stereotypes.getStereoTypes();
    // if found - checking that it is still valid (belongs to its logical and the logical still inside the model).
    if (visual && visual.logicalElement.visualElements.includes(visual) &&
      (this.logicalElements.includes(visual.logicalElement) || stereotypes.find(s => s.logicalElements.includes(visual.logicalElement)))) {
      return visual;
    } else if (visual) {
      this.visualsMap.delete(visual.id);
      visual = undefined;
    }

    for (let i = 0; i < this.logicalElements.length; i++) {
      for (let j = 0; j < this.logicalElements[i].visualElements.length; j++)
        if (visualID === this.logicalElements[i].visualElements[j].id) {
          visual = this.logicalElements[i].visualElements[j];
          this.visualsMap.set(visual.id, visual);
          return visual;
        }
    }

    for (const streot of this.stereotypes.getStereoTypes())
      for (const logical of streot.logicalElements)
        for (const vis of logical.visualElements)
          if (visualID === vis.id) {
            visual = vis;
            this.visualsMap.set(visual.id, visual);
            return visual;
          }

    return null;
  }

  getLogicalElementByLid(lid) {
    return this.logicalElements.find(l => l.lid === lid);
  }

  getLogicalElementByVisualId(visualID) {
    if (!visualID)
      return null;

    if (this.visualsMap.get(visualID))
      return this.visualsMap.get(visualID).logicalElement;

    for (let i = 0; i < this.logicalElements.length; i++) {
      for (let j = 0; j < this.logicalElements[i].visualElements.length; j++)
        if (visualID === this.logicalElements[i].visualElements[j].id)
          return this.logicalElements[i];
    }

    for (const streot of this.stereotypes.getStereoTypes())
      for (const logical of streot.logicalElements)
        for (const vis of logical.visualElements)
          if (visualID === vis.id)
            return logical;

    return null;
  }

  getEquivalentLogicalThingFromStereotype(equivalentLID) {
    for (const streot of this.stereotypes.getStereoTypes())
      for (const logical of streot.logicalElements)
          if (logical.lid === equivalentLID)
            return logical;

    return null;
  }

  getOpds(includeHidden = false) {
    if (includeHidden)
     return this.opds;
    return this.opds.filter(opd => opd.isHidden === false);
  }

  getOpdByName(Name: string) {
    for (let opd of this.opds) {
      if (opd.name === Name) {
        return opd;
      }
    }
  }

  getOpdIDByName(name: string) {
    for (let opd of this.opds) {
      if (opd.name === name) {
        return opd.id;
      }
    }
    return null;
  }

  getOpdNameByID(id): string {
    for (let k = 0; k < this.opds.length; k++)
      if (this.opds[k].id === id)
        return this.opds[k].name;
    return '';
  }

  // creates a visual element and insert a reference to its logical element according to elementLogicalType
  createNewVisualElement(elementLogicalType, params, logicalElement) {
    return logicalElement.createVisual(params);
    /*
    switch (elementLogicalType) {
      case 'OpmLogicalObject':
        return new OpmVisualObject(params, logicalElement);
      case 'OpmLogicalProcess':
        return new OpmVisualProcess(params, logicalElement);
      case 'OpmLogicalState':
        return new OpmVisualState(params, logicalElement);
      case 'OpmProceduralRelation':
        return new OpmProceduralLink(params, logicalElement);
      case 'OpmFundamentalRelation':
        return new OpmFundamentalLink(params, logicalElement);
      case 'OpmTaggedRelation':
        return new OpmTaggedLink(params, logicalElement);
    }*/
  }

  createNewVisualElementInsertToCurrentOpd(params, logical: OpmLogicalElement<OpmVisualElement>) {
    const visual = this.createNewVisualElement(undefined, params, logical);
    this.currentOpd.add(visual);
    return visual;
  }

  getCopiedState(visualElement) {
    const vs = visualElement.logicalElement.visualElements;
    for (let k = 0; k < vs.length; k++)
      if (vs[k].logicalElement.text === visualElement.logicalElement.text && vs[k].id != visualElement.id)
        return vs[k];
    return null;
  }


  isComputational(visualThing: OpmVisualThing): boolean {
    return (<OpmLogicalThing<OpmVisualThing>>visualThing.logicalElement).isComputational();
  }

  getOpd(id) {
    for (let k = 0; k < this.opds.length; k++)
      if (this.opds[k].id === id)
        return this.opds[k];
    for (let idx = 0; idx < this.stereotypes.getStereoTypes().length; idx++)
      if (this.stereotypes.getStereoTypes()[idx].opd.id === id)
        return this.stereotypes.getStereoTypes()[idx].opd;
    return null;
  }

  setCurrentOpd(id) {
    this.currentOpd = this.getOpd(id);
  }

  getOpdByThingId(id) {
    if (!id)
      return null;

    for (let k = 0; k < this.opds.length; k++)
      for (let j = 0; j < this.opds[k].visualElements.length; j++)
        if (this.opds[k].visualElements[j].id === id)
          return this.opds[k];

    for (const streot of this.stereotypes.getStereoTypes())
        if (streot.opd.visualElements.find(vis => vis.id === id))
          return streot.opd;

    return null;
  }

  getOpdByElement(visual: OpmVisualElement): OpmOpd {
    for (let k = 0; k < this.opds.length; k++)
      for (let j = 0; j < this.opds[k].visualElements.length; j++)
        if (this.opds[k].visualElements[j] === visual)
          return this.opds[k];

    for (const streot of this.stereotypes.getStereoTypes())
      if (streot.opd.visualElements.find(vis => vis.id === visual.id))
        return streot.opd;

    return undefined;
  }

  getRefineeInzoomingID(thingID) {
    let refineable = this.getVisualElementById(thingID);
    return (<OpmVisualThing>refineable).refineeInzooming.id;
  }

  getRefineeUnfoldingID(thingID) {
    let refineable = this.getVisualElementById(thingID);
    return (<OpmVisualThing>refineable).refineeUnfolding.id;
  }

  isInzoomed(thingID) {
    const refineable = <OpmVisualThing>this.getVisualElementById(thingID);
    if (refineable && refineable instanceof OpmVisualEntity)
      return refineable.isInzoomed();
    else
      return false;
  }

  isUnfolded(thingID) {
    const refineable = <OpmVisualThing>this.getVisualElementById(thingID);
    if (refineable && refineable instanceof OpmVisualEntity)
      return refineable.isUnfolded();
    else
      return false;
  }

  isEmpty(opdID) {
    return this.getOpd(opdID).isEmpty();
  }

  getOpdName(opdID) {
    const opd = this.getOpd(opdID);
    if (opd)
      return this.getOpd(opdID).getName();
    else
      return '';
  }

  arrayFromElementsToIds(array) {
    if (!array) return;
    return array.map(item => {
      if (item.constructor.name.includes('String'))
        return item;
      else return item.id;
    });
  }

  arrayFromIdsToElements(array) {
    if (!array) return;
    const elementsArray = new Array<OpmVisualElement>();
    for (let i = 0; i < array.length; i++) {
      const visual = this.getVisualElementById(array[i]);
      if (visual)
        elementsArray.push(visual);
    }
    return elementsArray;
  }

  addNote(noteParams) {
    this.currentOpd.addNote(noteParams);
  }

  getLogicalByText(text) {
    for (let i = 0; i < this.logicalElements.length; i++) {
      const currentText = (<OpmLogicalEntity<OpmVisualEntity>>this.logicalElements[i]).text;
      if (currentText === text) return this.logicalElements[i];
    }
  }

  public removeElementLocally(visual: OpmVisualElement): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    return this.removeElements([visual]);
  }

  public removeElementInCurrentOpd(visual: OpmVisualElement): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    // Get instances in current opd
    const visuals = this.currentOpd.visualElements.filter(v => v.logicalElement === visual.logicalElement);
    return this.removeElements(visuals);
  }

  public removeElementInModel(visual: OpmVisualElement, force: boolean = false): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    // Get all instances in model
    const visuals = [].concat(visual.logicalElement.visualElements);
    return this.removeElements(visuals, force);
  }

  public removeFundamental(visuals: Array<OpmVisualElement>): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    return this.removeElements(visuals);
  }

  public getOwnerOfRequirementByRequirementLID(lid: string): OpmLogicalThing<OpmVisualThing> {
    for (const log of this.logicalElements) {
      if (!OPCloudUtils.isInstanceOfLogicalThing(log))
        continue;
      const logical = log as OpmLogicalThing<OpmVisualThing>;
      if (logical.hasRequirements() && logical.getAllRequirements()?.find(req => req.getRequirementObjectLID() === lid))
        return logical;
    }
    return undefined;
  }

  public getOwnerOfRequirementSetObjectByLID(lid: string): OpmLogicalThing<OpmVisualThing> {
    for (const log of this.logicalElements) {
      if (!OPCloudUtils.isInstanceOfLogicalThing(log))
        continue;
      const logical = log as OpmLogicalThing<OpmVisualThing>;
      if (logical.hasRequirements() && logical.getSatisfiedRequirementSetModule().getRequirementsSet()?.getRequirementSetObject()?.logicalElement.lid === lid)
        return logical;
    }
    return undefined;
  }

  public removeElements(visuals: Array<OpmVisualElement>, force: boolean = false): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    const logical = visuals[0]?.logicalElement;
    if (!force) {
      visuals = visuals.filter( elmnt => elmnt !== null);
      for (const visual of visuals) {
        if (visual.canBeRemoved() === false)
          return { removed: false };
      }
    }

    const elements: Array<OpmVisualElement> = new Array<OpmVisualElement>();

    if (logical && OPCloudUtils.isInstanceOfLogicalThing(logical)) {
      const log = logical as OpmLogicalThing<OpmVisualThing>;
      if (log.isSatisfiedRequirementObject()) {
        const lidToRemove = log.lid;
        const owner = this.getOwnerOfRequirementByRequirementLID(lidToRemove);
        if (owner && visuals.length === log.visualElements.length) { // only if it is "remove in all model" option and not a local remove.
          const removedVisuals = owner.removeSingleRequirement(lidToRemove);
          elements.push(...removedVisuals);
        }
      } else if (log.isSatisfiedRequirementSetObject()) {
        const owner = this.getOwnerOfRequirementSetObjectByLID(log.lid);
        if (owner) {
          const removed = owner.getSatisfiedRequirementSetModule().toggleAttribute();
          elements.push(...removed);
        }
      } else if (log.hasRequirements()) {
        if (visuals.length === log.visualElements.length) { // only if it is "remove in all model" option and not a local remove.
          const requirements = log.getAllRequirements();
          for (let k = requirements.length - 1 ; k >=0 ; k--) {
            const removedVisuals = log.removeSingleRequirement(requirements[k].getRequirementObjectLID());
            elements.push(...removedVisuals);
          }
        } else { // if it is a local remove
          const requirements = log.getAllRequirements();
          for (const req of requirements) {
            if (req.getRequirementObject())
              elements.push(...req.getRequirementObject().remove());
          }
          const requirementSetObject = log.getSatisfiedRequirementSetModule().getRequirementsSet().getRequirementSetObject();
          if (requirementSetObject)
            elements.push(...requirementSetObject.remove());
        }
      }
    }

    for (const visual of visuals)
      elements.push(...visual.remove());

    // if we no longer need the requirements opd because there are no requirements left in the model.
    const requirementsOpdToRemove = this.opds.find(opd => opd.requirementsOpd && opd.visualElements.length === 0);
    if (requirementsOpdToRemove)
      this.removeOpd(requirementsOpdToRemove.id);

    return { removed: true, elements };

  }

  public removeFromOpd(visual: OpmVisualElement) {
    for (let i = this.opds.length - 1; i >= 0; i--) {
      const index = this.opds[i].visualElements.findIndex(v => v === visual);
      if (index > -1) {
        this.opds[i].visualElements.splice(index, 1);
        return;
      }
    }
  }

  remove(opmVisualElementId) {
    const logicalElement = this.getLogicalElementByVisualId(opmVisualElementId);
    if (logicalElement) {
      const visualElement = (<OpmVisualThing>this.getVisualElementById(opmVisualElementId));
      if (visualElement.refineable) {
        (<OpmVisualThing>(visualElement)).disconnectRefinable();
      }
      logicalElement.remove(opmVisualElementId);
      if (logicalElement.visualElements.length === 0) {
        this.removeLogicalElement(logicalElement);
      }
    }
  }

  removeLogicalElement(opmLogicalElement) {
    opmLogicalElement.removeFromFather();
    for (let i = this.logicalElements.length - 1; i >= 0 ; i--) {
      if (this.logicalElements[i] === opmLogicalElement) {
        this.logicalElements.splice(i, 1);
        break;
      }
    }
    if (opmLogicalElement instanceof OpmRelation && this.getRelatedRelationsByLogicalLink(opmLogicalElement)) {
      this.removeLinkFromRelatedRelation(opmLogicalElement);
    }
    if (opmLogicalElement instanceof OpmLogicalObject && opmLogicalElement.hasRange()) {
        new RangeValidationAccess(this).remove(opmLogicalElement);
    }
  }

  removeOpd(id) {
    const opd = this.getOpd(id);
    let ve;
    opd.disconnectRefineables();
    while (ve = opd.visualElements.pop()) {
      const isLastRealVisual = ve.logicalElement.visualElements.filter(v => this.getOpdByThingId(v.id) && this.getOpdByThingId(v.id).isHidden !== true);
      if (isLastRealVisual.length === 0 && OPCloudUtils.isInstanceOfVisualThing(ve) && ve.logicalElement.getStereotype())
        ve.logicalElement.opmModel.unLinkStereotype(ve);
      this.remove(ve.id);
    }
    for (let k = this.opds.length - 1 ; k >= 0 ; k--) {
      if (this.opds[k].id === id) {
        // this.opds[k].disconnectRefineables();
        if (id !== 'SD')
          this.opds.splice(k, 1);
      }
    }
    if (opd.parendId && this.opds.find(o => o.id === opd.parendId)) {
      const parentOpd = this.opds.find(o => o.id === opd.parendId);
      if (parentOpd.children.includes(opd))
        parentOpd.children.splice(parentOpd.children.indexOf(opd), 1);
    }

  }

  removeElementFromOpds(visual: OpmVisualElement) {
    const opds = this.opds;
    for (let i = 0; i < opds.length; i++) {
      for (let j = opds[i].visualElements.length -1; j >= 0 ; j--) {
        if (opds[i].visualElements[j] === visual) {
          opds[i].visualElements.splice(j, 1);
          break;
        }
      }
    }
  }

  public createLogicalObject(): OpmLogicalObject {
    return this.logicalFactory(EntityType.Object, undefined) as OpmLogicalObject;
  }

  public createLogicalProcess(): OpmLogicalProcess {
    return this.logicalFactory(EntityType.Process, undefined) as OpmLogicalProcess;
  }

  public createLogicalState(): OpmLogicalState {
    return this.logicalFactory(EntityType.State, undefined) as OpmLogicalState;
  }

  public createVisualThing(logical: OpmLogicalThing<OpmVisualThing>, opd: OpmOpd): OpmVisualThing {
    const visual = logical.createVisual(undefined) as OpmVisualThing;
    opd.add(visual);
    return visual;
  }

  public createVisualObject(logical: OpmLogicalObject, opd: OpmOpd): OpmVisualObject {
    const visual = logical.createVisual(undefined);
    opd.add(visual);
    return visual;
  }

  public createVisualProcess(logical: OpmLogicalProcess, opd: OpmOpd): OpmVisualProcess {
    const visual = logical.createVisual(undefined);
    opd.add(visual);
    return visual;
  }

  public createObject(opd: OpmOpd): { visual: OpmVisualObject, logical: OpmLogicalObject } {
    const logical = this.createLogicalObject();
    const visual = logical.visualElements[0];
    opd.add(visual);
    return { visual, logical };
  }


  public createProcess(opd: OpmOpd): { visual: OpmVisualProcess, logical: OpmLogicalProcess } {
    const logical = this.createLogicalProcess();
    const visual = logical.visualElements[0];
    opd.add(visual);
    return { visual, logical };
  }

  // Use for faster tests writing
  public createManyThings(opd: OpmOpd, processesNumber: number, objectsNumber: number):
      {processes: Array<{logical: OpmLogicalThing<OpmVisualThing>, visual: OpmVisualThing}>, objects: Array<{logical: OpmLogicalThing<OpmVisualThing>, visual: OpmVisualThing}>} {
    const processes: Array<{logical: OpmLogicalThing<OpmVisualThing>, visual: OpmVisualThing}> = [] ;
    const objects: Array<{logical: OpmLogicalThing<OpmVisualThing>, visual: OpmVisualThing}> = [];
    for (let i = 0 ; i < objectsNumber ; i++) {
      const logical = this.createLogicalObject();
      const visual = logical.visualElements[0];
      opd.add(visual);
      objects.push({logical: logical as OpmLogicalThing<OpmVisualThing>, visual: visual as OpmVisualThing});
    }
    for (let j = 0 ; j < processesNumber ; j++) {
      const logical = this.createLogicalProcess();
      const visual = logical.visualElements[0];
      opd.add(visual);
      processes.push({logical: logical as OpmLogicalThing<OpmVisualThing>, visual: visual as OpmVisualThing});
    }

    return { processes, objects };
  }

  public bringVisualToOpd(logical: OpmLogicalEntity<OpmVisualEntity>, opd: OpmOpd): OpmVisualEntity  {
    if (logical instanceof OpmLogicalState) {
      const visual = this.bringVisualToOpd(logical.parent, opd) as OpmVisualObject;
      visual.expressAll();
      return visual.states.find(s => s.logicalElement === logical);
    }

    let visual = opd.getVisualElementByLogical(logical);
    const firstVisual = logical.visualElements[0] as OpmVisualThing;
    const params = {
      id: uuid(),
      xPos: logical.visualElements[0].xPos,
      yPos: logical.visualElements[0].yPos,
      width: logical.visualElements[0].width,
      height: logical.visualElements[0].height,
      strokeWidth: (firstVisual.getRefineeInzoom() || firstVisual.getRefineeUnfold() || (<OpmLogicalThing<OpmVisualThing>>logical).getStereotype()) ? 4 : 2,
    };

    if (!visual)
      visual = logical.createVisual(params);
    opd.add(visual);

    return visual as OpmVisualEntity;
  }

  logicalFactory(type: RelationType | EntityType, params): OpmLogicalElement<OpmVisualElement> {
    const logical = logicalFactory(type, this, params);
    this.add(logical);
    return logical;
  }

  takeCareOfLinkEnds(orgLink, newLink, thingID, inzoomedProcess, inzoomedOPD, thing?: OpmVisualElement) {
    let copiedThing, copiedFather;
    let noAdd = false;
    // const isSelfInvocation = (<OpmFundamentalRelation>orgLink.logicalElement).linkType === linkType.SelfInvocation;

    if (orgLink.targetVisualElements[0].targetVisualElement.id === thingID) {
      if (orgLink.sourceVisualElement instanceof OpmVisualState) {
        let copiedState = this.getCopiedState(orgLink.sourceVisualElement);
        if (copiedState == null) {
          copiedFather = orgLink.sourceVisualElement.fatherObject.clone();
          copiedThing = copiedFather.children.filter((child) => child.logicalElement === orgLink.sourceVisualElement.logicalElement)[0];
        }
        else {
          copiedFather = copiedState.fatherObject;
          copiedThing = copiedState;
        }
        copiedFather.fatherObject = null;
      } else if (orgLink.sourceVisualElement === orgLink.targetVisualElements[0].targetVisualElement) {
        copiedThing = inzoomedProcess.children[inzoomedProcess.children.length - 1];
        noAdd = true;
      } else {
        copiedThing = orgLink.sourceVisualElement.clone();
        copiedThing.fatherObject = null;
      }
      (<OpmLink>newLink).sourceVisualElement = copiedThing;
      (<OpmLink>newLink).targetVisualElements[0].targetVisualElement = inzoomedProcess;
    } else {
      if (orgLink.targetVisualElements[0].targetVisualElement instanceof OpmVisualState) {
        const copiedState = this.getCopiedState(orgLink.targetVisualElements[0].targetVisualElement);
        if (copiedState == null) {
          copiedFather = (<OpmVisualEntity>(orgLink.targetVisualElements[0].targetVisualElement)).fatherObject.clone();
          copiedThing = copiedFather.children.filter((child) => child.logicalElement === orgLink.targetVisualElements[0].targetVisualElement.logicalElement)[0];
        } else {
          copiedFather = copiedState.fatherObject;
          copiedThing = copiedState;
        }
        copiedFather.fatherObject = null;
      } else {
        copiedThing = orgLink.targetVisualElements[0].targetVisualElement.clone();
        copiedThing.fatherObject = null;
      }
      (<OpmLink>newLink).sourceVisualElement = inzoomedProcess;
      (<OpmLink>newLink).targetVisualElements[0].targetVisualElement = copiedThing;
    }
    if (copiedFather) {
      inzoomedOPD.add(copiedFather);
      inzoomedOPD.addElements(copiedFather.children);
    } else {
      if (!noAdd) {
        inzoomedOPD.add(copiedThing);
        // if (copiedThing instanceof OpmVisualObject)
        inzoomedOPD.addElements(copiedThing.children);
      }
    }
  }

  getVisualElementOfLogicalAtOpd(logical: OpmLogicalElement<any>, opd: OpmOpd): OpmVisualElement {
    for (const vis of logical.visualElements)
      if (this.getOpdByElement(vis) === opd)
        return vis;
    return undefined;
  }

  getRelatedRelationsByLogicalLink(logicalLink: OpmRelation<OpmLink>): Array<OpmRelation<OpmLink>> {
    return this.relatedRelations.find( group => group.includes(logicalLink));
  }

  addNewRelatedRelation(group: Array<OpmRelation<OpmLink>>) {
    this.relatedRelations.push(group);
  }

  addLinkToExistingRelatedRelationByOther(linkToAdd: OpmRelation<OpmLink>, otherLinkThathasRelatedRelation): boolean {
    const grp = this.relatedRelations.find( group => group.includes(otherLinkThathasRelatedRelation));
    if (grp) {
      grp.push(linkToAdd);
      return true;
    }
    return false;
  }

  addLinkToExistingRelatedRelation(link: OpmRelation<OpmLink>, group: Array<OpmRelation<OpmLink>>) {
    if (!group.includes(link))
      group.push(link);
  }

  // removes the group that contains the *logicalLink*
  removeEntireRelatedRelationByLink(logicalLink: OpmRelation<OpmLink>): boolean {
    const grp = this.relatedRelations.find( group => group.includes(logicalLink));
    const index = this.relatedRelations.indexOf(grp);
    if (index >= 0) {
      this.relatedRelations.splice(index, 1);
      return true;
    }
    return false;
  }

  // removes the array that contains the *logicalLink*
  removeLinkFromRelatedRelation(logicalLink: OpmRelation<OpmLink>): boolean {
    const grp = this.relatedRelations.find( group => group.includes(logicalLink));
    const index = grp.indexOf(logicalLink);
    if (index >= 0) {
      // removing the item from the relation
      grp.splice(index, 1);
      // if the link removal breaks a chain of connections -> now we will recreate an updated separated chains.
      const idx = this.relatedRelations.indexOf(grp);
      if (grp.length < 2 && idx !== -1) {
        this.relatedRelations.splice(idx, 1);
        for (const log of grp)
          for (const vis of log.visualElements) {
            const link = vis as OpmLink;
            const source = link.sourceVisualElement as OpmVisualEntity;
            const target = link.targetVisualElements[0].targetVisualElement as OpmVisualEntity;
            this.links.checkForRelatedRelations(source, target, link);
          }
      }

      this.filterEmptyRelatedRelations();
      this.mergeIntersactingRelatedRelations();
      return true;
    }
    return false;
  }

  filterEmptyRelatedRelations() {
    for (const rel of this.relatedRelations)
      if (rel.length < 2)
        this.relatedRelations.splice(this.relatedRelations.indexOf(rel), 1);
  }

  suppressValueObjectStates(valueObject: OpmVisualObject): { success: boolean } {
    return new RangeValidationAccess(this).suppressStates(valueObject);
  }

  mergeIntersactingRelatedRelations() {
    const relations = this.relatedRelations;
    for (const log of this.logicalElements) {
      if (log.isLink()) {
        const arraysToMerge = relations.filter(arr => arr.includes(<any>log));
        if (arraysToMerge.length > 1) {
          const temp = [];
          for (const arr of arraysToMerge) {
            temp.push(...arr);
            const idx = this.relatedRelations.indexOf(arr);
            if (idx !== -1)
              this.relatedRelations.splice(idx, 1);
          }
          this.relatedRelations.push(<any>removeDuplicationsInArray(temp));
        }
      }
    }
  }

  public getOrCreateRangesOpd(): OpmOpd {
    let opd = this.opds.find(opd => opd.isRangesOpd);
    if (!opd) {
      opd = new OpmOpd('rangesOpd');
      opd.isRangesOpd = true;
      opd.isHidden = true;
      this.addOpd(opd);
    }
    return opd;
  }

  public getRangesOpd(): OpmOpd | undefined {
    return this.opds.find(opd => opd.isRangesOpd);
  }

  public getAllBasicThings(): Array<OpmLogicalElement<any>> {
    return this.logicalElements.filter(l => l.isBasicThing() && !l.visualElements.every(v => v.belongsToSubModel));
  }

  sortOpds() {
    // this.opds = this.opds.sort(function (a, b) {
    //   return a.getOpdDepth() > b.getOpdDepth() ? 1 : -1;
    // });
  }
}
