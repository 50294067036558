import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';

@Component ({
  selector: 'opcloud-enter-value',
  templateUrl: 'enter-value.html',
  styleUrls: ['enter-value.css']
})
export class EnterValueDialogComponent implements OnInit {

  private value;

  constructor(
    @Optional() public dialogRef: MatDialogRef<EnterValueDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.value = 'value';
  }

  ngOnInit() {
    if (!this.data.closeName) { this.data.closeName = 'CLOSE'; }
    if (!this.data.okName) { this.data.okName = 'OK'; }
    if (this.data.closeFlag === true) {
      const closeButt = document.getElementById('closeButton');
      closeButt.style.display = 'none';
    }
    setTimeout( function() { $('#valueInput').select(); }, 200 );
  }

  close() {
    this.dialogRef.close(this.value);
  }

}
