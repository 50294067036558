// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
  text-align: center;
}

.exit-button {
  cursor: pointer;
  margin-left: 260px;
  margin-top: -5px;
  font-size: 10px;
}

.mat-button-red:hover{
  background-color: #ff443d;
  color: white;
}

.example-form {
  min-width: 150px;
  max-width: 500px;
  width: 100%;
}

.example-full-width {
  width: 100%;
}

.buttons {
  text-align: center;
}

.hideInput{
  display: none;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/opl-generative-ai-dialog/generative-ai-update-key-dialog/generative-ai-update-key.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,aAAa;AACf","sourcesContent":["p{\n  text-align: center;\n}\n\n.exit-button {\n  cursor: pointer;\n  margin-left: 260px;\n  margin-top: -5px;\n  font-size: 10px;\n}\n\n.mat-button-red:hover{\n  background-color: #ff443d;\n  color: white;\n}\n\n.example-form {\n  min-width: 150px;\n  max-width: 500px;\n  width: 100%;\n}\n\n.example-full-width {\n  width: 100%;\n}\n\n.buttons {\n  text-align: center;\n}\n\n.hideInput{\n  display: none;\n}\n\n::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
