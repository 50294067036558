import {ElementAction, ElementCommand, ElementHaloHandle, ElementToolbarHandle} from "./command";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {OpmEntity} from "../../DrawnPart/OpmEntity";
import {OpmLogicalEntity} from "../../LogicalPart/OpmLogicalEntity";
import {OpmVisualProcess} from "../../VisualPart/OpmVisualProcess";
import {OpmProcess} from "../../DrawnPart/OpmProcess";
import {OpmLogicalProcess} from "../../LogicalPart/OpmLogicalProcess";

export class UserInputCommand implements ElementCommand {

  constructor(
    private readonly init: InitRappidService,
    private readonly entity: OpmProcess,
    private readonly visual: OpmVisualProcess) {
  }

  createHaloHandle(): ElementHaloHandle {
    const logical = this.visual.logicalElement as OpmLogicalProcess;
    const state = logical.needUserInput;
    return {
      flag: true,
      name: '',
      displayTitle: '',
      svg: '',
      action: new UserInputCommandAction(this.init, this.visual),
    }
  }

  createToolbarHandle(): ElementToolbarHandle {
    const logical = this.visual.logicalElement as OpmLogicalProcess;
    const state = logical.needUserInput;
    return {
      name: 'user-input',
      displayTitle: state ? 'Ask For User Input' : 'Do Not Ask For User Input',
      svg: state ? 'user-input-on' : 'user-input-off',
      action: new UserInputCommandAction(this.init, this.visual),
      gif: '',
    }
  }

}

class UserInputCommandAction implements ElementAction {

  constructor(
    private readonly init: InitRappidService,
    private readonly visual: OpmVisualProcess) {
  }

  act() {
    const logical = this.visual.logicalElement as OpmLogicalProcess;
    logical.needUserInput = !logical.needUserInput;
  }

}
