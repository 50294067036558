// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delOrg {
  background-color: #4B0082;
  border: none;
  color: white;
  padding: 1px 14px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
}


::ng-deep .mat-form-field-underline {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/Settings/organization-mgmt/delete-org/delete-org.component.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,YAAY;EACZ,iBAAiB;EACjB,kBAAkB;EAClB,qBAAqB;EACrB,qBAAqB;EACrB,eAAe;AACjB;;;AAGA;EACE,aAAa;AACf","sourcesContent":[".delOrg {\n  background-color: #4B0082;\n  border: none;\n  color: white;\n  padding: 1px 14px;\n  text-align: center;\n  text-decoration: none;\n  display: inline-block;\n  font-size: 16px;\n}\n\n\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
