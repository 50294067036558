import { OpmLogicalElement } from './OpmLogicalElement';
import { OpmVisualEntity } from '../VisualPart/OpmVisualEntity';
import { LogicalTextModule, BasicLogicalTextModule, BasicNameModule, NameModule } from './LogicalTextModule';
import { OpmRelation } from "./OpmRelation";
import {OPCloudUtils, removeDuplicationsInArray} from "../../configuration/rappidEnviromentFunctionality/shared";
import {linkType} from "../ConfigurationOptions";
import {OpmLink} from "../VisualPart/OpmLink";

export abstract class OpmLogicalEntity<T extends OpmVisualEntity> extends OpmLogicalElement<T> {

  protected readonly textModule: LogicalTextModule = new BasicLogicalTextModule(new BasicNameModule(this.getTextFormatter()));
  public equivalentFromStereotypeLID: string;
  public orderedFundamentalTypes = [];
  private description = '';
  public protectedFromBeingRefinedBySubModel: string;


  constructor(params, model) {
    super(params, model);
    if (params && typeof params.description === 'string') {
      this.description = params.description;
    }
    this.getNameModule().shouldAutoFormat(this.opmModel.getOplService()?.settings?.autoFormat);
  }

  isValidName(value: string): boolean {
    return true;
  }

  setDescription(description: string): void {
    if (description !== undefined && description !== null) {
      this.description = description;
    }
  }
  getDescription(): string {
    return this.description || '';
  }

  updateParams(params) {
    super.updateParams(params);
    this.getNameModule().shouldAutoFormat(params.isAutoFormat == undefined ? this.opmModel.getOplService()?.settings?.autoFormat : params.isAutoFormat);
    this.text = params.text;
    if (params && params.orderedFundamentalTypes) {
      this.orderedFundamentalTypes = params.orderedFundamentalTypes;
    }
    if (params?.hasOwnProperty('protectedFromBeingRefinedBySubModel')) {
      this.protectedFromBeingRefinedBySubModel = params.protectedFromBeingRefinedBySubModel;
    }
    if (!!params && typeof params.description === 'string')
      this.description = params.description;
  }

  setParams(params) {
    super.setParams(params);
    // this.text = params.text;
    if (!!params && typeof params.description === 'string')
      this.description = params.description;
  }

  getEntityParams() {
    const params = {
      text: this.getBareName(),
      isAutoFormat: this.getNameModule().isAutoFormat(),
      description: this.description,
      orderedFundamentalTypes: this.orderedFundamentalTypes,
      protectedFromBeingRefinedBySubModel: this.protectedFromBeingRefinedBySubModel,
    };
    return { ...super.getElementParams(), ...params };
  }

  getIsWaitingProcess(): boolean {
    return false;
  }

  get _text(): string {
    return this.textModule.getDisplayText();
  }

  public updateTextFromView(text: string) {
    this.textModule.updateFromInput(text);
  }

  public getNameModule(): NameModule {
    return this.textModule.getNameModule();
  }

  set text(text: string) {
    this.updateTextFromView(text);
  }

  public setText(text: string) {
    this.textModule.updateFromInput(text);
  }

  public getBelongsToStereotyped() {
    return undefined;
  }

  isAutoFormat() {
    return this.textModule.getNameModule().isAutoFormat();
  }

  public toggleAutoFormat(): { isAutoFormat: boolean, name?: string } {
    const name = this.textModule.getNameModule();
    const auto_format = name.isAutoFormat();

    if (auto_format == true) {
      name.shouldAutoFormat(false);
      return { isAutoFormat: false }
    }

    const new_text = name.formatText(name.getText());
    name.shouldAutoFormat(true);
    name.setText(new_text);
    return { isAutoFormat: true, name: new_text };
  }

  getTextFormatter(): (text: string) => string {
    return (text: string) => {
      return text.split(/\s+/).map(s => {
        if (s.toLowerCase() == 'and' && text.toLowerCase().includes('and '))
          return '&';
        return s.charAt(0).toUpperCase() + s.substring(1).toLowerCase()
      }).join(" ");
    }
  }

  public getDisplayText(): string {
    return this.textModule.getDisplayText();
  }

  get text(): string {
    return this.getDisplayText();
  }

  public isSatisfiedRequirementSetObject(): boolean {
    return false;
  }

  public isSatisfiedRequirementObject(): boolean {
    return false;
  }

  public getBareName(): string {
    return this.textModule.getName();
  }

  getEntityParamsFromJsonElement(jsonElement) {
    const params = {
      text: jsonElement.text,
      isAutoFormat: jsonElement.isAutoFormat,
      URLarray: jsonElement.URLarray, // from json URL saving
      orderedFundamentalTypes: jsonElement.orderedFundamentalTypes,
      protectedFromBeingRefinedBySubModel: jsonElement.protectedFromBeingRefinedBySubModel,
    };
    return { ...super.getElementParamsFromJsonElement(jsonElement), ...params };
  }

  hasFather() {
    for (const vis of this.visualElements) {
      if (vis.fatherObject)
        return true;
    }
    return false;
  }

  getFather(): OpmLogicalEntity<OpmVisualEntity> {
    for (const vis of this.visualElements) {
      if (vis.fatherObject)
        return vis.fatherObject.logicalElement;
    }
    return undefined;
  }

  getChildren(): Array<OpmLogicalEntity<OpmVisualEntity>> {
    const arr = [];
    for (const vis of this.visualElements) {
      if ((<any>vis).children) {
        arr.push(...(<any>vis).children.map(ch => ch.logicalElement));
      }
    }
    return removeDuplicationsInArray(arr) as Array<OpmLogicalEntity<OpmVisualEntity>>;
  }

  getChildrenDeep(): Array<OpmLogicalEntity<OpmVisualEntity>> {
    const arr = [];
    for (const vis of this.visualElements) {
      if ((<any>vis).children) {
        arr.push(...(<any>vis).children.map(ch => ch.logicalElement));
        for (const child of (<any>vis).children) {
          if (child.logicalElement.lid !== this.lid)
            arr.push(...child.logicalElement.getChildrenDeep());
        }
      }
    }
    return arr;
  }

  getChildrenDeepIncludingAggregation(type: 'process' | 'object'): Array<OpmLogicalEntity<OpmVisualEntity>> {
    const arr = [];
    for (const vis of this.visualElements) {
        if ((<any>vis).children) {
            const onlyProcesses = type === 'process';
            const relevantChildren = OPCloudUtils.filterArrayByType((<any>vis).children, onlyProcesses, !onlyProcesses, !onlyProcesses);
            arr.push(...relevantChildren.map(ch => ch.logicalElement));
            for (let child of relevantChildren) {
              arr.push(...(<any>child.logicalElement).getChildrenDeepIncludingAggregation(type));
            }
        }
        const aggregationChildren = [];
        vis.getLinks().outGoing.filter(l => l.type === linkType.Aggregation).forEach(link => {
            aggregationChildren.push(link.target);
            if (OPCloudUtils.isInstanceOfVisualState(link.target)) {
              const father = link.target.fatherObject;
              for (const state of father.states || []) {
                  if (!aggregationChildren.includes(state) && state.getLinks().outGoing.find(lnk => lnk.type === linkType.Aggregation))
                    aggregationChildren.push(state);
              }
            }
        });
        for (const child of aggregationChildren) {
            arr.push(child.logicalElement);
            arr.push(...(<any>child.logicalElement).getChildrenDeepIncludingAggregation(type));
        }
    }
    return removeDuplicationsInArray(arr) as Array<OpmLogicalEntity<OpmVisualEntity>>;
  }

  isRefineable(): boolean {
    const is = this.visualElements.find(vis => (<any>vis).children.filter(c => c instanceof OpmVisualEntity).length > 0);
    return !!is; // ? true : false;
  }

  getLinks(): { inGoing: Array<OpmRelation<any>>, outGoing: Array<OpmRelation<any>> } {
    const inGoing = [];
    const outGoing = [];
    for (const log of this.opmModel.logicalElements) {
      if (log.isLink()) {
        if ((<OpmRelation<OpmLink>>log).sourceLogicalElement === this)
          outGoing.push(log);
        if ((<OpmRelation<OpmLink>>log).targetLogicalElements[0] === this)
          inGoing.push(log);
      }
    }
    return { inGoing: inGoing, outGoing: outGoing };
  }

  getLinksWith(other: OpmLogicalEntity<OpmVisualEntity>): { inGoing: Array<OpmRelation<any>>, outGoing: Array<OpmRelation<any>> } {
    const inGoing = [];
    const outGoing = [];
    for (const log of this.opmModel.logicalElements) {
      if (log instanceof OpmRelation) {
        if (log.sourceLogicalElement === this && log.targetLogicalElements[0] === other)
          outGoing.push(log);
        if (log.targetLogicalElements[0] === this && log.sourceLogicalElement === other)
          inGoing.push(log);
      }
    }
    return { inGoing: inGoing, outGoing: outGoing };
  }


  public hasRequirements(): boolean {
    return false;
  }

  public getAncestorExhibitions(ret =  []): Array<OpmLogicalEntity<OpmVisualEntity>> {
    const inLinks = this.getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition);
    for (const link of inLinks) {
      const source = link.sourceLogicalElement as OpmLogicalEntity<OpmVisualEntity>;
      if (ret.includes(source)) {
        continue;
      }
      ret.push(source);
      if (OPCloudUtils.isInstanceOfLogicalState(source)) {
        ret.push(source.getFather());
        source.getFather().getAncestorExhibitions(ret);
      }
      source.getAncestorExhibitions(ret);
    }
    return ret;
  }
}
