// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host ::ng-deep .mat-dialog-container .ng-tns-c28-3 .ng-trigger .ng-trigger-slideDialog{
  /*position: absolute;*/
  /*top: 100px;*/
  /*left: 600px;*/
  width: 467px;
  height: 238px;
  background: #ffffff;
  border: 1px solid #B3B3B3;
  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25), 0px 30px 50px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}


p{
  text-align: center;
}

.alertBTN{
  position: relative;
  width: 166px;
  height: 53px;
  background: #FFFFFF;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);
  border-radius: 6px;

}

.alertBTN:hover {
  position: relative;
  width: 166px;
  height: 53px;
  background: #FFFFFF;
  box-shadow: 0px 2px 4px rgba(120, 168, 241, 0.64);
  border-radius: 6px;
  color: #78A8F1;
}

::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {
  display: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/new-model-dialog/new-model-dialog.css"],"names":[],"mappings":"AAAA;EACE,sBAAsB;EACtB,cAAc;EACd,eAAe;EACf,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,yBAAyB;EACzB,+EAA+E;EAC/E,mBAAmB;AACrB;;;AAGA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,oCAAoC;EACpC,sBAAsB;EACtB,2CAA2C;EAC3C,kBAAkB;;AAEpB;;AAEA;EACE,kBAAkB;EAClB,YAAY;EACZ,YAAY;EACZ,mBAAmB;EACnB,iDAAiD;EACjD,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,aAAa;AACf","sourcesContent":[":host ::ng-deep .mat-dialog-container .ng-tns-c28-3 .ng-trigger .ng-trigger-slideDialog{\n  /*position: absolute;*/\n  /*top: 100px;*/\n  /*left: 600px;*/\n  width: 467px;\n  height: 238px;\n  background: #ffffff;\n  border: 1px solid #B3B3B3;\n  box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25), 0px 30px 50px rgba(0, 0, 0, 0.1);\n  border-radius: 10px;\n}\n\n\np{\n  text-align: center;\n}\n\n.alertBTN{\n  position: relative;\n  width: 166px;\n  height: 53px;\n  background: #FFFFFF;\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  box-sizing: border-box;\n  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.12);\n  border-radius: 6px;\n\n}\n\n.alertBTN:hover {\n  position: relative;\n  width: 166px;\n  height: 53px;\n  background: #FFFFFF;\n  box-shadow: 0px 2px 4px rgba(120, 168, 241, 0.64);\n  border-radius: 6px;\n  color: #78A8F1;\n}\n\n::ng-deep .mat-form-field-appearance-legacy .mat-form-field-underline {\n  display: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
