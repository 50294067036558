import { Component, OnInit } from '@angular/core';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import {UserService} from '../../../../rappid-components/services/user.service';
import {OrganizationService} from '../../../../rappid-components/services/organization.service';
import {BehaviorSubject, Observable, Subject} from 'rxjs/Rx';
import {OPCloudUtils, validationAlert} from '../../../../configuration/rappidEnviromentFunctionality/shared';
import {ConfirmDialogDialogComponent} from '../../../../dialogs/confirm-dialog/confirm-dialog';
import {InputNameDialogComponent} from '../../../../dialogs/input-name-dialog/input-name-dialog';
import {AuthenticationService} from '../../../../rappid-components/services/authentication/authentication.service';
import {DatabaseService} from "../../../../database/database.service";

@Component({
  selector: 'opcloud-org-admin',
  templateUrl: 'org-admin.component.html',
  styleUrls: ['org-admin.component.css']
})
export class OrgAdminComponent implements OnInit {
  _organizations: Array<OrgItem>;
  organizations$: Observable<Array<any>>;
  selected = '';
  isSysAdmin = false;
  showOnlyHighProfileOrgs = false;
  isLoading = false;
  searchText = '';

  constructor(private _dialog: MatDialog, private orgService: OrganizationService,
              public userService: UserService, private auth: AuthenticationService,
              public dialog: MatDialog, private dbService: DatabaseService) {
    this._organizations = [];
  }
  ngOnInit() {
    const that = this;
    this.userService.user$.take(1).subscribe(user => {
      that.isSysAdmin = that.userService.user.userData.SysAdmin;
      this.updateOrgList(user.userData);
    });
  }

  async updateOrgList(user) {
    if (user.SysAdmin) {
      let orgs = await this.dbService.driver.getOrganizations();
      for (const o of orgs) {
        this.orgService.getOrganization(o.name)
          .then(orgSettings => o.highProfile = orgSettings.highProfile).catch(err => o.highProfile = false);
      }
      if (this.showOnlyHighProfileOrgs) {
        orgs = orgs.filter(o => o.highProfile);
      }
      this._organizations = orgs;
    } else {
      this.orgService.isActiveOrg(user.organization).then(activeFlag => {
        this._organizations = [{id: user._organization, name: user.organization, flag: activeFlag, highProfile: false}];
      });
    }
  }

  orgChangeName(org) {
    const dialogRef = this._dialog.open(InputNameDialogComponent, {
      height: '190px',
      width: '380px',
      data: { message: 'Please enter new name for organization ' + org.name, passwordFlag: false , inputName: 'Organization Name'},
    });
    dialogRef.afterClosed().toPromise().then(async (data) => {
      if (data) {
        let orgExists = false;
        const newName = data.NameInput;
        if (newName === '') {
          validationAlert('The name is illegal  \n Please choose different name', 3500, 'Error');
        } else {
          const allOrgs = await this.dbService.driver.getOrganizations();
          allOrgs.forEach(organization => {
              if (organization.name === newName) {
                validationAlert('The name ' + newName + ' already exists \n Please choose different name', 3500, 'Error');
                orgExists = true;
              }
          });
          if (!orgExists) {
            this.orgService.updateOrgName(org.name, newName).then(() => {
              validationAlert('Name was successfully updated!', 2500, 'Success');
              this.updateOrgList(this.userService.user.userData);
              return;
            });
          }
        }
      }
    });
  }
  orgActivation(org) {
    const that =this;
    this.orgService.activateOrDeactivateOrg(org.name, !org.flag).then(res => { if (org.flag || org.flag === undefined) {
        validationAlert('Organization: ' + org.name + ' is now deactivated');
      } else {
        validationAlert('Organization: ' + org.name + ' is now activated');
      }
    }
    ).then(() => that.updateOrgList(that.userService.user.userData));
  }
  openDialogDeleteOrg(org) {
    const dialogRef = this._dialog.open(InputNameDialogComponent, {
      height: '190px',
      width: '380px',
      data: { message: "Warning: All organization data will be deleted \n Please enter user's password", passwordFlag: true },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.auth.signInWithEmailAndPassword(this.userService.user.userData.Email, data.password).then((result: boolean) => {
          if (result) {
            this.orgService.deleteOrganization(org.name).then(() => {
              validationAlert('Organization Deleted Successfully!');
            }).catch(err => {
              validationAlert('ERRROR! Organization could not be deleted: ' + err);
            });
            this.updateOrgList(this.userService.user.userData);
          }
        }).catch(err => {
          const dialogRef2 = this._dialog.open(ConfirmDialogDialogComponent, {
            height: '150px',
            width: '320px',
            data: {message: 'Wrong password', closeFlag: true},
          });
        });
      }
    });
  }

  toggleHighProfile(org: OrgItem) {
    const that = this;
    this.orgService.updateOrganization(org.name, { highProfile: !org.highProfile })
      .then(() => that.updateOrgList(that.userService.user.userData));
  }

  getOrgProfileTooltip(org: OrgItem) {
    if (!this.isSysAdmin) {
      return '';
    }
    return org.highProfile ? 'Click to set as a regular profile': 'Click to set as a high profile';
  }

  changedHighProfileSelection($event) {
    this.showOnlyHighProfileOrgs = !this.showOnlyHighProfileOrgs;
  }

  getOrgs() {
    if (this.showOnlyHighProfileOrgs) {
      return this._organizations.filter(org => org.highProfile).filter((org) => {
        return org.name.toLowerCase().includes(this.searchText.toLowerCase());
      });
    }
    return this._organizations.filter((org) => {
      return org.name.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }
}

interface OrgItem {
  id: string,
  name: string,
  flag: boolean,
  highProfile: boolean
}
