// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `

#header {
  text-align: center;
  color: #1a3763;
}

#content {
  width: 100%;
  max-height: 450px;
  overflow: auto;
  margin-top: 30px;
}

#actions {
  text-align: center;
  margin-top: 30px;
  display: flex;
  justify-content: space-around;
}

button {
  background-color: #1a3763;
  color: white;
}

#checkboxes {
  margin-top: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/popup-message-content/popup-message-content.component.css"],"names":[],"mappings":";;AAEA;EACE,kBAAkB;EAClB,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,iBAAiB;EACjB,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,gBAAgB;EAChB,aAAa;EACb,6BAA6B;AAC/B;;AAEA;EACE,yBAAyB;EACzB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\n\n#header {\n  text-align: center;\n  color: #1a3763;\n}\n\n#content {\n  width: 100%;\n  max-height: 450px;\n  overflow: auto;\n  margin-top: 30px;\n}\n\n#actions {\n  text-align: center;\n  margin-top: 30px;\n  display: flex;\n  justify-content: space-around;\n}\n\nbutton {\n  background-color: #1a3763;\n  color: white;\n}\n\n#checkboxes {\n  margin-top: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
