// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_holder {
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 5px;
  padding: 2px;
  margin: 5px;
  margin-top: 25px;
}

#main {
  text-align: center;
  display: inline-grid;
}

#header {
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-size: 16px;
  font-weight: bold;
  color: #1A3763;
  padding: 5px;
  border-radius: 6px;
}

.input_text  {
  width: 98%;
  border: none;
}

.error_text {
  margin-left: 2em;
  color: red;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/submodel-name-dialog/submodel-name-dialog.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;EACZ,WAAW;EACX,gBAAgB;AAClB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,iDAAiD;EACjD,eAAe;EACf,iBAAiB;EACjB,cAAc;EACd,YAAY;EACZ,kBAAkB;AACpB;;AAEA;EACE,UAAU;EACV,YAAY;AACd;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".input_holder {\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 5px;\n  padding: 2px;\n  margin: 5px;\n  margin-top: 25px;\n}\n\n#main {\n  text-align: center;\n  display: inline-grid;\n}\n\n#header {\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-size: 16px;\n  font-weight: bold;\n  color: #1A3763;\n  padding: 5px;\n  border-radius: 6px;\n}\n\n.input_text  {\n  width: 98%;\n  border: none;\n}\n\n.error_text {\n  margin-left: 2em;\n  color: red;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
