import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmObject } from "../../DrawnPart/OpmObject";
import { OpmVisualObject } from "../../VisualPart/OpmVisualObject";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class HideTypeCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'hide-type-object',
            displayTitle: 'Hide',
            svg: 'hide-type-object',
            action: new HideValueAction(this.init, this.object)
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'hide-type-object',
            displayTitle: 'Hide',
            svg: 'hide-type-object',
            action: new HideValueAction(this.init, this.object),
            gif: 'assets/gifs/toggle_range_type.gif',
        }
    }

}

class HideValueAction implements ElementAction {

    constructor(private readonly init: InitRappidService,
        private readonly object: OpmObject) {
    }

    act() {
      const cell = this.init.graph.getCell(this.object.id);
      if (cell)
        cell.hideValueObject(this.init);
    }

}
