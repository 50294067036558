import { OpmOpd } from '../../models/OpmOpd';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { OpmModel } from '../../models/OpmModel';
import { VariablesCalculator } from './variables-calculator';
import { handleExeExceptions, hasWhiteSpaces, ObjectItem } from './computationalPartUtils';
import { code, linkType } from '../../models/ConfigurationOptions';
import { getInitRappidShared, validationAlert } from "../rappidEnviromentFunctionality/shared";
import { OpmVisualProcess } from "../../models/VisualPart/OpmVisualProcess";
import {ExecutionRunner, runner} from "./computationalPart";
import {WebSocketCommunicator} from "./communication-object";

/**
 * A class that handles user defined functions.
 * opmModel - the current opm model
 * */
export class UserDefinedFunctionExecutor {
  runner;
  globalRunTimeEnvironment;
  userInputValue;

  constructor(private readonly opmModel: OpmModel, private visProcess: OpmVisualProcess, runner?: ExecutionRunner, globalRunTimeEnvironment = undefined, userInputValue = undefined) {
      this.runner = runner;
      this.globalRunTimeEnvironment = globalRunTimeEnvironment;
      this.userInputValue = userInputValue;
    }

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], functionValue) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.UserDefined, this.globalRunTimeEnvironment);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    return this.runUserDefinedFunction(all_paths, valuesArray, functionValue);
  }
  /**
   * calculates the function variables definitions and the function (replace the variables: a.b -> a_b ,a/b -> a$b),
   * returns the function output
   **/
  private async runUserDefinedFunction(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[],
    userDefinedFunction: { functionInput: string; }) {
    let all_variables_str = [];
    let non_legal_variables_str = []; /* will include illegal variables, for example, if a.b.c is computational object
    (with no other sub objects), then a.b  legal.*/
    let alias = [];
    const vc = new VariablesCalculator(all_paths, valuesArray);
    const userInputVar = 'let userInput = ' + (!isNaN(Number(this.userInputValue)) ? Number(this.userInputValue) : "'"+ this.userInputValue + "'") + ';';
    const function_variables = vc.calc_variables_str(all_variables_str, code.UserDefined, non_legal_variables_str, alias);
    // const function_variables = this.calc_variables_str(all_paths, valuesArray, all_variables_str, non_legal_variables_str);
    const functionContent = this.replaceVariables(userDefinedFunction.functionInput, all_variables_str, non_legal_variables_str);
    // let aliasArr = '';
    // if(alias.length > 0) {
    //   aliasArr = 'let aliasArr = ' + JSON.stringify(alias) + ';';
    // }
    const functionInput = userInputVar + '\n' + function_variables + '\n' + functionContent;
    try {
      const args = 'updateValue, sqlQuerying, aliasArr';
      const this_ = this;
      const outputsAliases = vc.calc_outputs_alias(this.visProcess);
      const aliasArr = alias;
      const updateValue = function (alias, value) {
        let aliasToSearch = alias.replace('_', '.');
        while (aliasToSearch.indexOf('_') !== -1)
          aliasToSearch = aliasToSearch.replace('_', '.');
        const item = aliasArr.find(it => it.alias === aliasToSearch);
        const valueToAssign = String(value);
        if (!item)
          return this_.setValueByOutputsAlias(outputsAliases, aliasToSearch, valueToAssign);
        const lid = item.lid;
        const logical = getInitRappidShared().opmModel.getLogicalElementByLid(lid);
        if (!logical.isComputational())
          return;
        const logicalProcess = this_.visProcess.logicalElement;
        const isAllowedToUpdate = this_.isAllowedToChangeValue(logicalProcess, logical);
        if (!isAllowedToUpdate) {
          validationAlert('Only Effect or Result links can update a value using the updateValue function.', 3500, 'Error');
          return undefined;
        }
        item.value = valueToAssign;
        for (const elm of valuesArray) {
          if (elm.elementId === lid)
            elm.sourceElementValue = valueToAssign;
        }
        const wasSet = logical.setValue(value);
        if (wasSet == false) {
          getInitRappidShared().elementToolbarReference.Executing = false;
          validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
          return;
        }
        logical.states[0].text = value && !!value.toString ? value.toString() : value;
        getInitRappidShared().getGraphService().updateComputationalStateDrawnsByLogicalObject(logical);
        return valueToAssign;
      };
      const sqlQuerying = function (query) {
        const senTquery =  query + ';';
        const sqlWS = new WebSocketCommunicator(getInitRappidShared().handlerMYSQL, getInitRappidShared().activateMySQL);
        let x;
        if (sqlWS.isActive) {
          if (senTquery !== undefined) {
            sqlWS.send({
              what: 'query',
              message: senTquery
            });
            x = sqlWS.get();
          } else {
            x = '';
          }
        } else {
          x = '';
          validationAlert('No SQL WS server connection established!', 2500, undefined, true);
        }
        return x;
      };
      const AsyncFunction = Object.getPrototypeOf(async function(){}).constructor;
      // async function support .ugly name so the users won't be using it by mistake.
      let functionAsAsync = 'let jiow4jo4iwjw4oir5tj4woi5tj = async () => {' + functionInput + '}; return jiow4jo4iwjw4oir5tj4woi5tj();';
      return await AsyncFunction(args, functionAsAsync)(updateValue, sqlQuerying, aliasArr);
    } catch (e) {
      if (!this.runner.isHeadless()) {
        handleExeExceptions(e.toString(), null, userDefinedFunction.functionInput);
      }
    }
  }
  setValueByOutputsAlias(outputsAliases, aliasToSearch, valueToAssign) {
    const targets = outputsAliases[aliasToSearch];
    if (!targets)
      return;
    for (const targetId of targets) {
      const logical = getInitRappidShared().opmModel.getLogicalElementByLid(targetId);
      if (!logical.isComputational())
        continue;

      const wasSet = logical.setValue(valueToAssign);
      if (wasSet == false) {
        getInitRappidShared().elementToolbarReference.Executing = false;
        validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
        return;
      }

      getInitRappidShared().getGraphService().updateComputationalStateDrawnsByLogicalObject(logical);
    }
    return valueToAssign;
  }
  isAllowedToChangeValue(logProcess, logObject) {
    const validTypes = [linkType.Effect, linkType.Result];
    // if there is a direct effect link between the process and the object.
    const conditionA = !!logObject.getLinks().outGoing.find(l =>
      validTypes.includes(l.linkType) && l.targetLogicalElements[0].lid === logProcess.lid);
    if (conditionA)
      return true;
    const parents = logObject.getLinks().inGoing.filter(link =>
      [linkType.Aggregation, linkType.Instantiation, linkType.Exhibition, linkType.Generalization].includes(link.linkType)).map(link => link.sourceLogicalElement);
    for (const p of parents)
      if (!!p.getLinks().outGoing.find(l => validTypes.includes(l.linkType) && l.targetLogicalElements[0].lid === logProcess.lid))
        return true;
    return false;
  }
  /**
   returns the function with variables replaced if needed. for example, if there is an object a.b.c.d and the
   function is return a.b.c.d, it will return 'return a_b_c_d'. also, a/b/c will be replaced to a&b&c to enable names with '/'
   the function also announces the user if they are using an illegal variable for example if a.b.c is legal, then a.b is
   not legal. (though a can be legal)
   **/
  public replaceVariables(functionInput: string, function_variables: string[], non_legal_variables_str: string[]) {
    let function_input_replaced_str = functionInput;
    function_variables.sort((a, b) => b.length - a.length);
    non_legal_variables_str.sort((a, b) => b.length - a.length);
    function_variables.forEach(variable => {
      const var_with_dots_and_dollars = variable.replace(/_/g, '\.').replace(/\$/g, '\/');
      const var_regex = new RegExp(var_with_dots_and_dollars, 'g');
      function_input_replaced_str = function_input_replaced_str.replace(/\r?\n|\r/g, '');
      const occurrence_of_variable = function_input_replaced_str.match(var_regex);
      if (occurrence_of_variable && occurrence_of_variable.length > 0) {
        if (hasWhiteSpaces(variable)) {
          handleExeExceptions('Check ' + var_with_dots_and_dollars + '. Its identifier contains illegal characters (like space)!.<br>Check if it has another possible identifier (use name or alias). ');
        } else {
          function_input_replaced_str = function_input_replaced_str.replace(var_regex, variable);
        }
      }
    });
    non_legal_variables_str.forEach(variable => {
      const var_with_dots_and_dollars = variable.replace(/_/g, '\.').replace(/\$/g, '\/');
      const var_regex = new RegExp(var_with_dots_and_dollars + '(\\*|\\/|;|-|\\+| )*$', 'g'); /* for exact
      match - so only if a sub object is used for example, it should prevent from using a.b.c to alert about using a.b.
       might need refinement for more complex occurrences */
      function_input_replaced_str = function_input_replaced_str.replace(/\r?\n|\r/g, '');
      const occurrence_of_variable = function_input_replaced_str.match(var_regex);
      if (occurrence_of_variable && occurrence_of_variable.length > 0) {
        handleExeExceptions('The variable ' + var_with_dots_and_dollars + ' cannot be used.');
      }
    });
    return function_input_replaced_str;
  }

  /*******************************************************************************************************************/
  /**the next three functions are not used, but stayed in case the object definitions will be changed to the form of
   * let a = {b:30,c:{d:50}}**/
  // /**
  //  * calculates for each object instrumentally linked to the computational process the variable to be defined in the
  //  * function in this form: let a= {b:30}
  //  **/
  // private getRecursiveVariableDef(opd: OpmOpd, all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[],
  //                                 OpmVisualProcess: { id: string; }) {
  //   opd.visualElements.forEach(elm => {
  //     if (elm instanceof OpmProceduralLink && elm.type === linkType.Instrument) {
  //       if (elm.target.id === OpmVisualProcess.id) {
  //         let visited = [];
  //         let strstr = '';
  //         if (this.isSimple(elm.source.id, all_paths)) {
  //           const currVar = valuesArray.filter(value1 => value1.elementId === elm.source.id)[0];
  //           strstr = 'let ' +
  //             (elm.source.logicalElement.alias.length > 0 ? elm.source.logicalElement.alias : elm.source.logicalElement.name)
  //             + ' = ' + currVar.sourceElementValue + ';';
  //         } else {
  //           strstr = this.getVarString(elm.source, visited, true, opd);
  //         }
  //       }
  //     }
  //   });
  // }
  //
  // /**
  //  * for each object, calculates the right variable string
  //  * **/
  // private getVarString(source, visited: boolean[], isFirst: boolean, opd: OpmOpd) {
  //   let varString = isFirst ? 'let ' + source.logicalElement.alias + ' = { ' : source.logicalElement.alias + ' : ';
  //   visited[source.id] = true;
  //   const thingLinks = opd.visualElements.filter((elm) => elm instanceof OpmLink &&
  //     ((elm.type === linkType.Aggregation || elm.type === linkType.Exhibition) && elm.sourceVisualElement.id === source.id));
  //   thingLinks.forEach(link => {
  //     if (link instanceof OpmLink) {
  //       let newStr = '';
  //       if (link.type === linkType.Aggregation || link.type === linkType.Exhibition && link.source.id === source.id &&
  //         visited[link.targetVisualElements[0].targetVisualElement.id] !== true) { // if its an aggregation link (consists of),
  //         // then we should check the target and not the source (because we came from the source)
  //         newStr = this.getVarString(link.targetVisualElements[0].targetVisualElement, visited, false, opd);
  //       } else {
  //         newStr = this.getVarString(link.sourceVisualElement, visited, false, opd);
  //       }
  //       varString = (varString.lastIndexOf(',') === ((varString.length - 2)) || isFirst === true) ?
  //         (varString[varString.length - 1] === '}' ? varString + ' , ' + newStr : varString + newStr)
  //         : varString + ' { ' + newStr + ' , ';
  //     }
  //   });
  //   if (thingLinks.length === 0) {
  //     return source.logicalElement.alias + ' : ' + source.logicalElement.value;
  //   }
  //   return varString.lastIndexOf(',') === (varString.length - 2) ? varString.substring(0, varString.length - 3) + '}' : varString + '}';
  // }
  // /**
  //  * returns true if the object with id toCheckKey doesnot belong to any other object path
  //  * **/
  // private isSimple(toCheckKey: string, all_paths: Map<string, ObjectItem[][]>) {
  //   let isSimple = true;
  //   all_paths.forEach((paths_array, key) => {
  //     if (toCheckKey !== key) {
  //       for (let i = 0; i < paths_array.length; i++) {
  //         const path = paths_array[i];
  //         // key is an id of an object in which toCheckKey is the id of the object right before the path to key's object
  //         if (path.length >= 2 && path[path.length - 2].elementId === toCheckKey) {
  //           isSimple = false;
  //         }
  //       }
  //     }
  //   });
  //   return isSimple;
  // }
}
