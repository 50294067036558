import {OpmStructuralLink} from './OpmStructuralLink';

export class OpmTaggedLink extends OpmStructuralLink {
  tag: string;
  backwardTag: string;
  constructor(params, logicalElement) {
    super(params, logicalElement);
  }
  updateParams(params) {
    super.updateParams(params);
    this.tag = params.tag;
    this.backwardTag = params.backwardTag;
  }
  getParams() {
    const params = {
      tag: this.tag,
      backwardTag: this.backwardTag
    };
    return {...super.getStructuralParams(), ...params};
  }
  getParamsFromJsonElement(jsonElement) {
    const params = {
      tag: jsonElement.tag,
      backwardTag: jsonElement.backwardTag
    };
    return {...super.getStructuralParamsFromJsonElement(jsonElement), ...params};
  }
  clone() {
    const newLink = new OpmTaggedLink(this.getParams(), this.logicalElement);
    newLink.setNewUUID();
    return newLink;
  }
}
