import { Rule, RuleType } from "./rules";
import { OpmVisualEntity } from "../VisualPart/OpmVisualEntity";
import { EntityType } from "../model/entities.enum";
import { EntitiesSet, createSet as Set } from "./entities.set";
import { LinksSet, createSet as Links, all as AllLinks, fundamental, procedural } from './links.set';
import { linkType } from "../ConfigurationOptions";

const AllEntites = Set(EntityType.Object, EntityType.Process, EntityType.State);

abstract class ConsistionalRule<S extends OpmVisualEntity, T extends OpmVisualEntity> implements Rule {

  type(): RuleType {
    return RuleType.CONSISTIONAL;
  }

  typeLink(): LinksSet {
    return AllLinks;
  }

  typeSource(): EntitiesSet {
    return AllEntites;
  }

  typeTarget(): EntitiesSet {
    return AllEntites;
  }

  abstract canConnect(source: S, target: T, link: linkType): boolean;

  shouldBeApplied(source: EntityType, target: EntityType, link: linkType): boolean {
    return this.typeSource().contains(source) &&
      this.typeTarget().contains(target) &&
      this.typeLink().contains(link);
  }
  abstract message(): string;

  abstract description(): string;

}

class BaseConsistency extends ConsistionalRule<OpmVisualEntity, OpmVisualEntity> {

  private set = Links(linkType.Result, linkType.Effect, linkType.Consumption, linkType.Agent, linkType.Instrument);

  typeLink(): LinksSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): boolean {
    const links = source.getAllLinksWith(target);
    for (let i = 0; i < links.inGoing.length; i++) {
      const type = (<any>(links.inGoing[i].logicalElement)).linkType;
      if (this.set.contains(type) && type !== link)
        return false;
    }
    for (let i = 0; i < links.outGoing.length; i++) {
      const type = (<any>(links.outGoing[i].logicalElement)).linkType;
      if (this.set.contains(type) && type !== link)
        return false;
    }
    return true;
  }

  message(): string {
    return 'A link between object to process should be same type in all opds';
  }

  description(): string {
    return 'Entity ';
  }

}

class AgentConsistency extends ConsistionalRule<OpmVisualEntity, OpmVisualEntity> {

  private set = Links(linkType.Agent, linkType.Instrument);

  private source = Set(EntityType.Object, EntityType.State);

  typeSource(): EntitiesSet {
    return this.source;
  }

  typeLink(): LinksSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): boolean {
    const links = source.getAllLinks();
    for (let i = 0; i < links.outGoing.length; i++) {
      const type = (<any>(links.outGoing[i].logicalElement)).linkType;
      if (this.set.contains(type) && type !== link)
        return false;
    }
    if (source.fatherObject && source.fatherObject.states) {
      const illegalType = link === linkType.Agent ? linkType.Instrument : linkType.Agent;
      for (const state of source.fatherObject.states) {
        if (source !== state && state.getLinks().outGoing.find(item => item.type === illegalType))
          return false;
      }
    }
    return true;
  }

  message(): string {
    return 'An object can either have instrument links or Agent link.';
  }

  description(): string {
    return 'An object can either have instrument links or Agent link.';
  }

}
class FundamentalConsistency extends ConsistionalRule<OpmVisualEntity, OpmVisualEntity> {

  private set = fundamental;

  typeLink(): LinksSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): boolean {
    if (!this.set.contains(link))
      return true;

    const links = source.getAllLinksWith(target);
    for (const l of [...links.inGoing, ...links.outGoing])
      if (this.set.contains(l.type) && l.type !== link)
        return false;
    return true;
  }

  message(): string {
    return 'Entities must be connected by a single fundamental link.';
  }

  description(): string {
    return 'Entities must be connected by a single fundamental link.';
  }

}

class ProceduralConsistency extends ConsistionalRule<OpmVisualEntity, OpmVisualEntity> {

  private set = procedural;

  typeLink(): LinksSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): boolean {
    if (!this.set.contains(link))
      return true;
    const links = source.getAllLinksWith(target);
    for (const l of [...links.inGoing, ...links.outGoing])
      if (this.set.contains(l.type) && l.type !== link)
        return false;
    return true;
  }

  message(): string {
    return 'Entities must be connected by a single procedural link.';
  }

  description(): string {
    return 'Entities must be connected by a single procedural link.';
  }

}

export const rules = new Array<Rule>(
  new AgentConsistency(),
  new FundamentalConsistency(),
  new ProceduralConsistency(),
  new BaseConsistency(),
);


