import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import * as workerTimers from "worker-timers";
import {UserService} from "../../rappid-components/services/user.service";

@Component({
  selector: 'enter-email-verification-code-dialog',
  templateUrl: 'enter-email-verification-code-dialog.html',
  styleUrls: ['enter-email-verification-code-dialog.css']
})
export class EnterEmailVerificationCodeDialog implements OnInit {

  private resendDisabled: boolean;
  private timeLeft: number;
  private verificationCode: string;
  private error: string;
  private hideResend: boolean;

  constructor(private dialogRef: MatDialogRef<EnterEmailVerificationCodeDialog>, private userService: UserService,
    @Inject(MAT_DIALOG_DATA) private data) {
    this.resendDisabled = true;
    this.timeLeft = 0;
    this.hideResend = false;
  }

  ngOnInit() {
    const that = this;
    this.countDown();
    setTimeout( function() {
      that.resendDisabled = false;
    }, 61000 );
  }

  countDown() {
    if (this.timeLeft > 0) {
      return;
    }
    this.timeLeft = 60;
    const interval = workerTimers.setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1;
      } else {
        workerTimers.clearInterval(interval);
      }
    }, 1000);
  }

  resendCode() {
    this.resendDisabled = true;
    this.countDown();
    this.userService.resend2FAVerificationCode(this.data.user.Email);
  }

  getResendButtonText() {
    const text = 'Resend';
    if (this.timeLeft > 0) {
      return text + ' (' + this.timeLeft + 's)';
    }
    return text;
  }

  verify() {
    const that = this;
    const user = {
      ...this.data.user,
      verificationCode: this.verificationCode
    }
    this.userService.signInWithEmailAndPassword(user, undefined).then((ret) => this.dialogRef.close(true)).catch(err => {
      that.error = err.message;
      if (that.error?.includes('account is locked')) {
        that.hideResend = true;
      }
    })
  }
}
