import {Component, OnInit, Optional, Inject, AfterViewInit} from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
import {AuthenticationService} from "../../rappid-components/services/authentication/authentication.service";
import {UserService} from "../../rappid-components/services/user.service";
import {validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import * as workerTimers from "worker-timers";
import { RecaptchaVerifier } from "firebase/auth";
import {getAuth} from "firebase/auth";

@Component ({
  selector: 'opcloud-set-2-auth-factors-dialog',
  templateUrl: 'set-2-auth-factors-dialog.html',
  styleUrls: ['set-2-auth-factors-dialog.css']
})
export class Set2AuthFactorsDialog implements OnInit, AfterViewInit {

  private recaptchaVerifier: RecaptchaVerifier;
  private phoneNumber: string;
  private recaptchaWidgetId: number;
  private showSendCode: boolean;
  private userPassword: string;
  private firstStep: boolean;
  private secondStep: boolean;
  private verificationCode: string;
  private thirdStep: boolean;
  private verificationId: any;
  private resendDisabled: boolean = false;
  private isRecaptchaSolved: boolean = false;
  private timeLeft = 0;

  constructor(
    @Optional() public dialogRef: MatDialogRef<Set2AuthFactorsDialog>, private authService: AuthenticationService, private userService: UserService,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    this.phoneNumber = '';
    this.showSendCode = false;
    this.userPassword = '';
    this.firstStep = true;
    this.secondStep = false;
    this.secondStep = false;
    this.verificationCode = '';
  }

  ngOnInit() {

  }

  ngAfterViewInit() {
    const that = this;
    const auth = getAuth();
    this.recaptchaVerifier = new RecaptchaVerifier(
      auth,
      'recaptcha-container', {
        'callback': function(response) {
        }
      });
    this.recaptchaVerifier.render().then(widgetId => this.recaptchaWidgetId = widgetId);
  }


  async sendVerificationCode() {
    if (!this.phoneNumber.startsWith('+')) {
      validationAlert('Your phone number is invalid. It must start with "+"', 5000, 'error');
      return;
    } else if (this.phoneNumber.length < 11) {
      validationAlert('Your phone number is invalid. It is too short.', 5000, 'error');
      return;
    }
    try {
      const that = this;
      setTimeout( function() {
        that.resendDisabled = false;
      }, 60000 );
      this.verificationId = await this.authService.add2FactorAuthenticationForUser(this.recaptchaVerifier, this.phoneNumber);
      this.thirdStep = true;
      this.dialogRef.updateSize(undefined, this.data.mandatory ? '495px' : '470px');
      that.resendDisabled = true;
      this.countDown();
    } catch (e) {
      validationAlert('Invalid phone format or this number is already in use.', 5000, 'error');
    }
  }

  countDown() {
    if (this.timeLeft > 0) {
      return;
    }
    this.timeLeft = 60;
    const interval = workerTimers.setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft = this.timeLeft - 1;
      } else {
        workerTimers.clearInterval(interval);
      }
    }, 1000);
  }

  verifyPassword() {
    const that = this;
    const nextOperation = () => {
      that.firstStep = false;
      that.secondStep = true;
      that.showSendCode = true;
      that.dialogRef.updateSize(undefined, this.data.mandatory ? '550px' : '520px');
    }
    this.authService.signInWithEmailAndPassword(this.userService.user.userData.Email, this.userPassword, this.recaptchaVerifier).then(() => {
      nextOperation();
    }).catch(err => {
      if (err.code === 'auth/multi-factor-auth-required') {
        nextOperation();
      } else if (err.message === 'Wrong Password.') {
        validationAlert(err.message, 3500, 'error');
      }
    })
  }

  stepBack() {
    this.showSendCode = true;
    this.thirdStep = false;
    this.timeLeft = 0;
    this.dialogRef.updateSize(undefined, this.data.mandatory ? '550px' : '520px');
  }

  applyNewNumber() {
    this.authService.finishAdd2FactorAuthForUser(this.verificationCode, this.verificationId).then(res => {
      if (res.success) {
        validationAlert('Success', 3500, 'Success');
        this.dialogRef.close();
      } else {
        validationAlert(res.message, 3500, 'error');
      }
    });
  }

  removeSecondAuthFactor() {
    this.authService.removeSecondAuthFactorForUser().then(() => {
      validationAlert('Success', 3500, 'Success');
      this.dialogRef.close();
    });
  }

  logout() {
    this.authService.signOut();
  }

  phoneNumberKeyUp() {
    const allowedChars = ['0','1','2','3','4','5','6','7','8','9','+'];
    const splitted = this.phoneNumber.split('');
    for (let i = splitted.length - 1 ; i >= 0 ; i-- ) {
      if (!allowedChars.includes(splitted[i])) {
        splitted.splice(i, 1);
      }
    }
    this.phoneNumber = splitted.join('');
  }

  resendCode() {
    if (this.isRecaptchaSolved) {
      this.sendVerificationCode();
      this.resendDisabled = true;
      this.isRecaptchaSolved = false;
      this.countDown();
    } else {
        validationAlert('First verify that you are not a robot.', 5000, 'Error');
        this.recaptchaVerifier.verify().then(() => {
          this.isRecaptchaSolved = true;
        }).catch(err => {});
    }
  }

  getResendButtonText() {
    const text = 'Resend';
    if (this.timeLeft > 0) {
      return text + ' (' + this.timeLeft + 's)';
    }
    return text;
  }
}
