// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.input_holder {
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 5px;
  padding: 5px;
  margin: 5px;
}

.input_holder .input_text  {
  width: 22em;
}

.error_text {
  margin-left: 2em;
  color: red;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/load-model-dialog/sub-dialogs/rename/rename-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;EACZ,WAAW;AACb;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,UAAU;AACZ","sourcesContent":[".input_holder {\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 5px;\n  padding: 5px;\n  margin: 5px;\n}\n\n.input_holder .input_text  {\n  width: 22em;\n}\n\n.error_text {\n  margin-left: 2em;\n  color: red;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
