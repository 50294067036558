import { ChangeDetectorRef, Component, Inject, Input, OnInit } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { Observable } from 'rxjs';
import { NoteService } from '../note.service';
import { NoteData } from '../note';
import { NoteType } from '../note-type';

@Component({
  selector: 'opcloud-note',
  template: `

    <mat-card class="note-card">
      <mat-card-header>

        <!-- TODO: add relevant functionality to the menu -->
        <button mat-icon-button [matMenuTriggerFor]="menu" class="menu">
          <mat-icon>more_vert</mat-icon>
        </button>

        <mat-menu #menu="matMenu">
          <button mat-menu-item>
            <mat-icon>dialpad</mat-icon>
            <span>Redial</span>
          </button>
          <button mat-menu-item disabled>
            <mat-icon>voicemail</mat-icon>
            <span>Check voicemail</span>
          </button>
          <button mat-menu-item>
            <mat-icon>notifications_off</mat-icon>
            <span>Disable alerts</span>
          </button>
        </mat-menu>

        <div mat-card-avatar>
          <mat-icon (click)="openTypeSelect()" [style.color]="noteData.type.color">{{ noteData.type.icon }}</mat-icon>
          <div *ngIf="selectOpen" class="icon-select">
            <mat-icon *ngFor="let type of types" (click)="chooseType(type)" [style.color]="type.color">{{ type.icon }}
            </mat-icon>
          </div>
        </div>

        <mat-card-title>{{ noteData.createdBy.displayName }}</mat-card-title>
        <mat-card-subtitle>{{ noteData.createdDate | date: 'dd.MM.yy hh:mm' }}</mat-card-subtitle>

      </mat-card-header>

      <mat-card-content>
        <p class="content" *ngIf="!edit">
          <button mat-mini-fab class="edit-button" (click)="toggleEdit(true)">
            <mat-icon>mode_edit</mat-icon>
          </button>
          {{ noteData.content }}
        </p>
        <div *ngIf="edit" class="content-input">
          <button mat-mini-fab class="edit-button" (click)="save(content.value)">
            <mat-icon>done</mat-icon>
          </button>
          <textarea matInput
                    #content
                    mdTextareaAutosize
                    mdAutosizeMinRows="2"
                    mdAutosizeMaxRows="5">{{ noteData.content }}</textarea>
        </div>
      </mat-card-content>
    </mat-card>
  `,
  styleUrls: ['./note.component.scss'],
})
export class NoteComponent implements OnInit {
  @Input() noteData: NoteData;
  @Input() noteCell; // reference to the jointJS cell
  edit;
  selectOpen;
  types = NoteType.noteTypes;
  noteService: NoteService;

  constructor(private cd: ChangeDetectorRef, @Inject(DOCUMENT) private document: any) {
  }

  ngOnInit() {
  }

  openTypeSelect() {
    this.selectOpen = true;
    this.cd.detectChanges();
    Observable.fromEvent(this.document, 'click').skip(1).take(1)
      .subscribe(() => {
        this.selectOpen = false;
        this.cd.detectChanges();
      });
  }

  toggleEdit(mode) {
    this.edit = mode;
    this.cd.detectChanges();
  }

  chooseType(type) {
    /*this.noteService.updateNote(this.noteData, { type }, this.noteCell)
      .subscribe(() => {
        this.cd.detectChanges();
      });*/
  }

  save(content) {
    /*this.toggleEdit(false);
    this.noteService.updateNote(this.noteData, { content }, this.noteCell)
      .subscribe(() => {
        this.cd.detectChanges();
      });*/
  }
}
