import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ImagesPoolType} from "../../models/VisualPart/backgroundImageEnum";
import {UntypedFormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {ReplaySubject} from "rxjs";


@Component({
  selector: 'opcloud-pool-container',
  templateUrl: 'images-pool-container.html',
  styleUrls: ['images-pool-container.css']
})

export class ImagesPoolContainer implements OnInit {

  public mode: ImagesPoolType;
  private title = 'Images Pool Management';
  private modes = ImagesPoolType;
  private searchTagsCtrl = new UntypedFormControl('');
  private separatorKeysCodes: number[] = [ENTER, COMMA];
  private searchTags: Array<string>;

  private readonly tabChange = new ReplaySubject<string>(1);
  private readonly tabChange$ = this.tabChange.asObservable();

  constructor(@Optional() public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.searchTags = [];
  }

  ngOnInit() {

  }

  onTabChange($event) {
    this.tabChange.next($event.tab);
  }


}
