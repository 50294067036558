import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ModelService } from '../../modules/app/model.service';
import { getValidationObject } from '../../opl-generation/opl.service';
import {UserService} from "../../rappid-components/services/user.service";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import * as fs from "file-saver";
import {getValidationColor} from "../../models/modules/attribute-validation/validation-module";

@Component ({
  selector: 'validation-settings',
  templateUrl: 'validation-settings.html',
  styleUrls: ['validation-settings.css']
})


export class ValidationSettingsComponent implements OnInit {

  constructor(@Optional() public dialogRef: MatDialogRef<ValidationSettingsComponent>, private userService: UserService,
   private readonly modelService: ModelService, private init: InitRappidService) {
  }

  ngOnInit() {
    const settings = this.userService.user?.userData?.opl;
    const validationTime = settings.validationTime?.toString() || '1';
    const enforcement = settings.validationEnforcementLevel?.toString() || '1';
    (<HTMLSelectElement>$('#validationSelect')[0]).value = validationTime;
    (<HTMLSelectElement>$('#enforcementSelect')[0]).value = enforcement;
  }

  apply() {
    const values = {
      validationTime: Number((<HTMLSelectElement>$('#validationSelect')[0]).value),
      validationEnforcementLevel: Number((<HTMLSelectElement>$('#enforcementSelect')[0]).value),
    };
    this.userService.updateUserValidationSettings(values).then(r => {
      this.modelService.model.validation = getValidationObject(values.validationTime, values.validationEnforcementLevel);
      this.dialogRef.close()
    });

  }


  exportToExcel() {
    const logicalObjects = this.init.opmModel.logicalElements.filter(l => OPCloudUtils.isInstanceOfLogicalObject(l)
      && (<any>l)?.computationModule?.validationModule.isActive());
    const ExcelJS = require('exceljs');
    const workbook = new ExcelJS.Workbook();
    workbook.created = new Date();
    workbook.modified = new Date();
    const worksheet = workbook.addWorksheet('Validation Values');
    worksheet.views = [{state:'normal'}];
    const row1 = worksheet.getRow(1);
    const row2 = worksheet.getRow(2);
    let idx = 1;
    for (const log of logicalObjects) {
      row1.getCell(idx).value = (<any>log).text;
      row2.getCell(idx).value = (<any>log).value !== 'value' ? (<any>log).value : (<any>log).computationModule.getRange();
      const color = getValidationColor(log);
      row2.getCell(idx).fill = {
        type: 'gradient',
        gradient: 'path',
        center:{left:0.5,top:0.5},
        stops: [
          { position:0, color: {argb: color}},
          { position:1, color: {argb: color}}
        ]
      };
      row1.getCell(idx).alignment = { vertical: 'center', horizontal: 'center' };
      row2.getCell(idx).alignment = { vertical: 'center', horizontal: 'center' };
      worksheet.getColumn(idx).width = ((<any>log).text?.length || 20) + 5;
      idx++;
    }
    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, 'ValidationValues.xlsx');
    });
    this.dialogRef.close();
  }

}
