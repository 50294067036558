// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
mat-dialog-container {
  background-color: #3e434b !important;
}

button {
  color: white;
  font-weight: 300;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/python-code-editor-dialog/python-code-editor-dialog.css"],"names":[],"mappings":";AACA;EACE,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB","sourcesContent":["\nmat-dialog-container {\n  background-color: #3e434b !important;\n}\n\nbutton {\n  color: white;\n  font-weight: 300;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
