import {Component, OnInit, ViewChild, ElementRef, AfterViewInit, Optional} from '@angular/core';
@Component({
  selector: 'opcloud-quick-manual',

  templateUrl: '../../../../../../client/src/app/modules/Settings/OPCloudManual/opcloud-manual.component.html',
  styleUrls: ['../../../../../../client/src/app/modules/Settings/OPCloudManual/opcloud-manual.component.css']
})
export class OPCloudManualComponent implements OnInit {

  constructor() {
  }

  ngOnInit() {

  }
}
