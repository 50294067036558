import {OpmDefaultLink} from './OpmDefaultLink';
import {OpmEntity} from '../OpmEntity';
import {OpmStructuralLink} from './OpmStructuralLink';
import {getInitRappidShared, joint} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';


export  class OpmTaggedLink extends OpmStructuralLink {
  sourceElement: OpmEntity;
  targetElement: OpmEntity;
  forwardTag: string;
  backwardTag: string ;

  constructor(sourceElement, targetElement , id?: string) {
    super(id);
    this.sourceElement = sourceElement;
    this.targetElement = targetElement;
    this.set({'source': {'id': sourceElement.id}});
    this.set({'target': {'id': targetElement.id}});
    const this_ = this;
    this.on('change:vertices', function () {
      this_.updateVertices();
    });
  }

  updateVertices() {
    if (getInitRappidShared()) {
      const model = getInitRappidShared().getOpmModel();
      const vis = model.getVisualElementById(this.id);
      if (vis)
        vis.BreakPoints = this.vertices();
    }
  }

  getTaggedLinkParams() {
    const params = {
      forwardTag: this.forwardTag,
      backwardTag : this.backwardTag
    };
    return {...super.getStructuralLinkParams(), ...params};
  }

  popupContentDbClick() {
    const sourceMultiplicity = (this.attributes.sourceMultiplicity) ? this.attributes.sourceMultiplicity : '';
    const targetMultiplicity = (this.attributes.targetMultiplicity) ? this.attributes.targetMultiplicity : '';
    const tag = (this.attributes.tag) ? this.attributes.tag : '';
    const requirements = this.attributes.requirements || '';
    const showReqLabel = this.attributes.showRequirementsLabel;
    setTimeout(() => {
      if ($('.showReq').length)
        (<any>$('.showReq')[0]).checked = showReqLabel;
    }, 500); // fix for a bug that the custom "V" causes.
    return [
    '<div style="height: 16px" ><div class="textAndInput">Source Multiplicity:<input size="2" class="PopupInput srce" value="' + sourceMultiplicity.trim() + '" ></div>' +
    '<span class="iconSpan" data-title="' + this.getSourceMultiplicityPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px" ><div class="textAndInput">Target Multiplicity : <input size="2"  class="trgt PopupInput" value="' + targetMultiplicity.trim() + '"></div><span data-title="' + this.getTargetMultiplicityPopupTooltipText() + '">' +
      '<img class="questionMarkForInfo"src="assets/SVG/questionmark.svg"></span></div><br>', '<div style="height: 16px"><div class="textAndInput">Tag: <input size="2" class="tag PopupInput" value="' + tag.trim() + '"></div>' +
      '<span data-title="' + this.getTagPopupTooltipText() + '"><img class="questionMarkForInfo"src="assets/SVG/questionmark.svg"></span></div><br>' +
      '<div style="height: 30px; margin-bottom: 4px; width: 273px;" ><div class="textAndInput">Requirement Set:<input size="2" class="PopupInput req" value="' + requirements + '" ><span data-title="Displaying satisfied requirements text on the link"><input type="checkbox"" class="checkbox-round showReq"></span></div><span class="iconSpan" style="margin-left: -8px;" data-title="' + this.getRequirementsPopupTooltipText() + '">' +
      '<img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span><img class="urlSvg" src="assets/SVG/url.svg"></div>'];
  }

  getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {
    return  [verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, removeButton];
  }

  isForkedLink(): boolean {
    return false;
  }

  updateSourceMultiplicity(oldVal: string, newVal: string, link: OpmTaggedLink, init: InitRappidService) {
    const visuals = this.getVisual().logicalElement.visualElements.filter(vis => vis !== this.getVisual());
    let newLabel;
    if (newVal)
      newLabel = link.setLabelsOLinks(newVal, 0.2, null);
    link.set('sourceMultiplicity', newVal);
    for (const vis of visuals) {
      (<any>vis).sourceMultiplicity = newVal;
      if (!(<any>vis).labels && newLabel)
        (<any>vis).labels = [newLabel];
      else if ((<any>vis).labels && newVal)
        (<any>vis).labels.push(newLabel);
    }
  }

  updateTagLabel(oldVal: string, newVal: string, link: OpmTaggedLink, init: InitRappidService, center = false) {
    const visuals = this.getVisual().logicalElement.visualElements.filter(vis => vis !== this.getVisual());
    let newLabel;
    if (newVal && !link.isForkedLink())
      newLabel = link.setLabelsOLinks(newVal, center ? 0.5 : 0.8, null, -10);
    link.set('tag', newVal);
    for (const vis of visuals) {
      (<any>vis).tag = newVal;
      if (!(<any>vis).labels && newLabel)
        (<any>vis).labels = [newLabel];
      else if ((<any>vis).labels && newVal) {
        const old = (<any>vis).labels.find(lb => lb.attrs.label.text === oldVal);
        if (old)
          (<any>vis).labels.splice((<any>vis).labels.indexOf(old), 1);
        if (newLabel)
          (<any>vis).labels.push(newLabel);
      }
    }
  }

  getTagPopupTooltipText(): string {
    return 'Tag is a phrase that expresses the semantics — the nature, meaning, or content. The unidirectional default tag is the phrase “relates to“';
  }

}
