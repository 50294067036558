import {OpmVisualEntity} from '../VisualPart/OpmVisualEntity';
import {Rule, RuleType} from './rules';
import {EntityType} from '../model/entities.enum';
import {OpmVisualObject} from '../VisualPart/OpmVisualObject';
import {OpmVisualProcess} from '../VisualPart/OpmVisualProcess';
import {createSet as SetOfEntites, EntitiesSet} from './entities.set';
import {
  consumptions,
  createSet,
  instruments,
  invoactions,
  LinksSet,
  procedural,
  procedural as ProceduralLinks,
  results,
  structural as StructuralLinks
} from './links.set';
import {Essence, linkType} from '../ConfigurationOptions';
import {OpmLink} from '../VisualPart/OpmLink';
import {OpmProceduralRelation} from '../LogicalPart/OpmProceduralRelation';
import {OpmVisualThing} from '../VisualPart/OpmVisualThing';
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalEntity} from "../LogicalPart/OpmLogicalEntity";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {CannotBePhysicalChangeAction} from "./changeActions/CannotBePhysicalChangeAction";

abstract class BehaviouralRule<S extends OpmVisualEntity, T extends OpmVisualEntity> implements Rule {

  type(): RuleType {
    return RuleType.BEHAVIOURAL;
  }

  abstract typeLink(): LinksSet;

  abstract typeSource(): EntitiesSet;

  abstract typeTarget(): EntitiesSet;

  abstract canConnect(source: S, target: T): boolean;

  shouldBeApplied(source: EntityType, target: EntityType, link: linkType): boolean {
    return this.typeSource().contains(source) &&
      this.typeTarget().contains(target) &&
      this.typeLink().contains(link);
  }

  abstract message(): string;

  abstract description(): string;

  abstract warning(source: S, target: T): string;

}

class ProcessCannotBeConnectedToitselfWithProceduralLinks extends BehaviouralRule<OpmVisualProcess, OpmVisualProcess> {

  private readonly source = SetOfEntites(EntityType.Process);
  private readonly target = SetOfEntites(EntityType.Process);

  typeLink(): LinksSet {
    return ProceduralLinks;
  }

  typeSource(): EntitiesSet {
    return this.source;
  }

  typeTarget(): EntitiesSet {
    return this.target;
  }

  canConnect(source: OpmVisualProcess, target: OpmVisualProcess): boolean {
    return (source.logicalElement !== target.logicalElement);
  }

  message(): string {
    return 'Process Cannot Be Connected To itself With Procedural Links';
  }

  description(): string {
    return 'Process Cannot Be Connected To itself With Procedural Links';
  }

  warning(source: OpmVisualProcess, target: OpmVisualProcess): string {
    return undefined;
  }

}

class AlreadyConnectedWithStructural extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly all = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.all;
  }

  typeTarget(): EntitiesSet {
    return this.all;
  }

  typeLink(): LinksSet {
    return StructuralLinks;
  }

  private perdicate(link: OpmLink, source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    return (this.typeLink().contains((<any>link.logicalElement).linkType) &&
      link.sourceVisualElement === source &&
      link.targetVisualElements[0].targetVisualElement === target);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const links = source.getAllLinksWith(target).outGoing;
    for (let i = 0; i < links.length; i++)
      if (this.perdicate(links[i], source, target))
        return false;
    return true;
  }

  message(): string {
    return 'Two entites cannot be connected with more than one link.';
  }

  description(): string {
    return 'Two entites cannot be connected with more than one link.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }
}

class AlreadyConnectedWithProcedural extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly all = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.all;
  }

  typeTarget(): EntitiesSet {
    return this.all;
  }

  typeLink(): LinksSet {
    return procedural;
  }

  private perdicate(link: OpmLink, source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const condition_1 = link.sourceVisualElement === source && link.targetVisualElements[0].targetVisualElement === target;
    const condition_2 = link.sourceVisualElement === target && link.targetVisualElements[0].targetVisualElement === source;
    return (this.typeLink().contains((<any>link.logicalElement).linkType) && (condition_1 || condition_2));
    ;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const links = [...source.getAllLinksWith(target).outGoing, ...source.getAllLinksWith(target).inGoing];
    for (let i = 0; i < links.length; i++)
      if (this.perdicate(links[i], source, target))
        return false;
    return true;
  }

  message(): string {
    return 'Two entites cannot be connected with more than one link.';
  }

  description(): string {
    return 'Two entites cannot be connected with more than one link.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }
}

class AlreadyConnectedWithStructuralOnTheOtherWay extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly all = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.all;
  }

  typeTarget(): EntitiesSet {
    return this.all;
  }

  typeLink(): LinksSet {
    return StructuralLinks;
  }

  private perdicate(link: OpmLink, source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    return (this.typeLink().contains((<any>link.logicalElement).linkType) &&
      link.sourceVisualElement === source &&
      link.targetVisualElements[0].targetVisualElement === target);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const links = target.getAllLinksWith(source).outGoing;
    for (let i = 0; i < links.length; i++)
      if (this.perdicate(links[i], target, source))
        return false;
    return true;
  }

  message(): string {
    return 'These Two entites are already connected with this link on the other way.';
  }

  description(): string {
    return 'These Two entites are already connected with this link on the other way.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }

}

class CantConnectConsumed extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly things = SetOfEntites(EntityType.Object, EntityType.Process);

  typeSource(): EntitiesSet {
    return this.things;
  }

  typeTarget(): EntitiesSet {
    return this.things;
  }

  typeLink(): LinksSet {
    return instruments;
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (target.fatherObject) {
      let highestConsumption = -1;
      let highestResult = -1;
      const children = target.fatherObject.getThingChildrenOrder();
      const indexOfTargetNewLink = children.indexOf(target);
      for (let i = 0; i <= indexOfTargetNewLink; i++) {
        const cnsmp = source.getAllLinksWith(children[i]).outGoing.find(l => l.type === linkType.Consumption);
        highestConsumption = cnsmp ? i : highestConsumption;
        const rslt = source.getAllLinksWith(children[i]).inGoing.find(l => l.type === linkType.Result);
        highestResult = rslt ? i : highestResult;
      }
      if (highestResult < highestConsumption && indexOfTargetNewLink > highestConsumption)
        return false;
    }
    return true;
  }
  message(): string {
    return 'An object cannot be connected with a link after it was consumed by a previous process.';
  }

  description(): string {
    return 'An object cannot be connected with a link after it was consumed by a previous process.';
  }

  warning(): string {
    return undefined;
  }
}

class CantConnectConsumed2 extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly things = SetOfEntites(EntityType.Object, EntityType.Process);

  typeSource(): EntitiesSet {
    return this.things;
  }

  typeTarget(): EntitiesSet {
    return this.things;
  }

  typeLink(): LinksSet {
    return consumptions;
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (target.fatherObject) {
      let highestConsumption = -1;
      let highestResult = -1;
      let closestAgentOrInstrument = -1;
      const children = target.fatherObject.getThingChildrenOrder();
      const indexOfTargetNewLink = children.indexOf(target);
      if (indexOfTargetNewLink == -1)
        return true;
      for (let i = indexOfTargetNewLink; i < children.length; i++) {
        const instrmngagnt = source.getAllLinksWith(children[i]).outGoing.find(l => [linkType.Agent, linkType.Instrument].includes(l.type));
        closestAgentOrInstrument = instrmngagnt ? i : closestAgentOrInstrument;
        if (closestAgentOrInstrument >= 0) break;
        const cnsmp = source.getAllLinksWith(children[i]).outGoing.find(l => l.type === linkType.Consumption);
        highestConsumption = cnsmp ? i : highestConsumption;
        const rslt = source.getAllLinksWith(children[i]).inGoing.find(l => l.type === linkType.Result);
        highestResult = rslt ? i : highestResult;
      }
      if (closestAgentOrInstrument > indexOfTargetNewLink && highestResult < indexOfTargetNewLink)
        return false;
    }
    return true;
  }

  message(): string {
    return 'An object cannot be connected with a link after it was consumed by a previous process.';
  }

  description(): string {
    return 'An object cannot be connected with a link after it was consumed by a previous process.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }
}

class CantConnectBeforeCreated extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly all = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.all;
  }

  typeTarget(): EntitiesSet {
    return this.all;
  }

  typeLink(): LinksSet {
    return instruments;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (target.fatherObject) {
      let links = new Array();
      target.fatherObject.children.forEach(child => links = links.concat(child.getAllLinks().outGoing));
      const results = links.filter(l => l.constructor.name.includes('Procedural') &&
        (l.logicalElement as OpmProceduralRelation).linkType === linkType.Result);
      const childrenOrder = target.fatherObject.getThingChildrenOrder();
      const indicesOfConnectedChildren = new Array();
      results.forEach(c => {
        indicesOfConnectedChildren.push(childrenOrder.indexOf(c.sourceVisualElement));
      });
      const min = Math.min.apply(null, indicesOfConnectedChildren);
      if (results.length === 0 || childrenOrder.indexOf(target) > min) {
        return true;
      }
      return false;
    }
    return true;
  }

  message(): string {
    return 'An object cannot be an instrument before it was created.';
  }

  description(): string {
    return 'An object cannot be an instrument before it was created.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }
}

class CantConnectBeforeCreated2 extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly all = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.all;
  }

  typeTarget(): EntitiesSet {
    return this.all;
  }

  typeLink(): LinksSet {
    return results;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (source.fatherObject) {
      let links = new Array();
      source.fatherObject.children.forEach(child => links = links.concat(child.getAllLinks().inGoing));
      const enablers = links.filter(l => l.constructor.name.includes('Procedural') &&
        ((l.logicalElement as OpmProceduralRelation).linkType === linkType.Instrument ||
          (l.logicalElement as OpmProceduralRelation).linkType === linkType.Agent));
      const childrenOrder = source.fatherObject.getThingChildrenOrder();
      const indicesOfConnectedChildren = new Array();
      enablers.forEach(c => {
        indicesOfConnectedChildren.push(childrenOrder.indexOf(c.targetVisualElements[0].targetVisualElement));
      });
      const max = Math.min.apply(null, indicesOfConnectedChildren);
      if (enablers.length === 0 || childrenOrder.indexOf(target) > max) {
        return true;
      }
      return false;
    }
    return true;
  }

  message(): string {
    return 'An object cannot be an instrument before it was created.';
  }

  description(): string {
    return 'An object cannot be an instrument before it was created.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    return undefined;
  }
}

class CantConnectSelfInvocationForInzoomedProcess extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly processes = SetOfEntites(EntityType.Process);

  typeSource(): EntitiesSet {
    return this.processes;
  }

  typeTarget(): EntitiesSet {
    return this.processes;
  }

  typeLink(): LinksSet {
    return invoactions;
  }

  canConnect(source: OpmVisualProcess, target: OpmVisualProcess): boolean {
    if (source === target && source.children.length > 0) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'An inzoomed process cannot be connected to iteslf by self-Invocation Link.';
  }

  description(): string {
    return 'An inzoomed process cannot be connected to iteslf by self-Invocation Link.';
  }

  warning(source: OpmVisualProcess, target: OpmVisualProcess): string {
    return undefined;
  }

}

class CantConnectInzoomedProcessToItsChildrenWithInvocation extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly processes = SetOfEntites(EntityType.Process);

  typeSource(): EntitiesSet {
    return this.processes;
  }

  typeTarget(): EntitiesSet {
    return this.processes;
  }

  typeLink(): LinksSet {
    return invoactions;
  }

  canConnect(source: OpmVisualProcess, target: OpmVisualProcess): boolean {
    if (source.isInzoomed() && source.children.includes(target)) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'An inzoomed process cannot be connected to its children by Invocation Link.';
  }

  description(): string {
    return 'An inzoomed process cannot be connected to its children by Invocation Link.';
  }

  warning(source: OpmVisualProcess, target: OpmVisualProcess): string {
    return undefined;
  }

}

class ObjectCantConnectToObjectWithProceduralLinks extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly objects = SetOfEntites(EntityType.Object);

  typeSource(): EntitiesSet {
    return this.objects;
  }

  typeTarget(): EntitiesSet {
    return this.objects;
  }

  typeLink(): LinksSet {
    return procedural;
  }

  canConnect(source: OpmVisualObject, target: OpmVisualObject): boolean {
    return false;
  }

  message(): string {
    return 'Object cannot connect another object with procedural link.';
  }

  description(): string {
    return 'Object cannot connect another object with procedural link.';
  }

  warning(source: OpmVisualObject, target: OpmVisualObject): string {
    return undefined;
  }

}

class PreventAggregationBetweenInformaticalToPhysical extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly things = SetOfEntites(EntityType.Object, EntityType.Process);

  typeSource(): EntitiesSet {
    return this.things;
  }

  typeTarget(): EntitiesSet {
    return this.things;
  }

  typeLink(): LinksSet {
    return createSet(linkType.Aggregation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (source.type === target.type && source.getEssence() === Essence.Informatical && target.getEssence() === Essence.Physical)
      return false;
    return true;
  }

  message(): string {
    return 'A physical thing cannot be part of an informatical one.\n' +
      'Please change part to be informatical first.';
  }

  description(): string {
    return 'A physical thing cannot be part of an informatical one.\n' +
      'Please change part to be informatical first.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return undefined;
  }

  changeAction(source: OpmVisualThing, target: OpmVisualThing, init: InitRappidService) {
    return new CannotBePhysicalChangeAction(source, target, init).act();
  }
}

class AggregationBetweenPhysicaltoInformatical extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly things = SetOfEntites(EntityType.Object, EntityType.Process);

  typeSource(): EntitiesSet {
    return this.things;
  }

  typeTarget(): EntitiesSet {
    return this.things;
  }

  typeLink(): LinksSet {
    return createSet(linkType.Aggregation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return true;
  }

  message(): string {
    return '';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    if (source.type === target.type && target.getEssence() === Essence.Informatical && source.getEssence() === Essence.Physical)
      return 'Consider using an Exhibition-Characterization link or a Tagged Structural link instead.';
  }
}

class ExhibitionToPhysical extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly entities = SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);

  typeSource(): EntitiesSet {
    return this.entities;
  }

  typeTarget(): EntitiesSet {
    return this.entities;
  }

  typeLink(): LinksSet {
    return createSet(linkType.Exhibition);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (this.condition(source, target))
      return false;
    return true;
  }

  message(): string {
    return 'Physical Object with agent link cannot be changed to Informatical.';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (this.condition(source, target))
      return 'Physical Object with agent link cannot be changed to Informatical.';
  }

  condition(source: OpmVisualEntity, target: OpmVisualEntity) {
    if (OPCloudUtils.isInstanceOfVisualThing(target) && (<OpmVisualThing>target).getEssence() === Essence.Physical &&
      target.getLinks().outGoing.find(l => l.type === linkType.Agent))
      return true;

    if (OPCloudUtils.isInstanceOfVisualObject(target) && (<OpmVisualThing>target).getEssence() === Essence.Physical &&
      (<OpmVisualObject>target).states.find(st => st.getLinks().outGoing.find(l => l.type === linkType.Agent)))
      return true;

    if (OPCloudUtils.isInstanceOfVisualState(target) && (<OpmVisualThing>target.fatherObject).getEssence() === Essence.Physical &&
      (<OpmVisualObject>target.fatherObject).states.find(st => st.getLinks().outGoing.find(l => l.type === linkType.Agent)))
      return true;

    if (OPCloudUtils.isInstanceOfVisualState(target) && (<OpmVisualThing>target.fatherObject).getEssence() === Essence.Physical &&
      (<OpmVisualObject>target.fatherObject).getLinks().outGoing.find(l => l.type === linkType.Agent))
      return true;


    return false;
  }
}

class LegalConsumptionWarning extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly things = SetOfEntites(EntityType.Object, EntityType.Process);

  typeSource(): EntitiesSet {
    return this.things;
  }

  typeTarget(): EntitiesSet {
    return this.things;
  }

  typeLink(): LinksSet {
    return createSet(linkType.Consumption);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return true;
  }

  message(): string {
    return '';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    const father = target.fatherObject;
    if (father && target instanceof OpmVisualThing) {
      const links = source.getLinksWithOtherAndItsChildren(father).outGoing.filter(l => l.type === linkType.Consumption);
      if (links.length > 0) {
        return 'An object cannot be consumed more than once. You may want to (1) remove the other consumption link or (2) use the XOR logical relation between the two consumption links.';
      }
    }
  }
}

class ProcessToProcess extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Consumption, linkType.Effect, linkType.Result, linkType.Agent, linkType.Instrument);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'process cannot be connected to process with this link.';
  }

  description(): string {
    return 'process cannot be connected to process with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class ObjectToProcess extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Aggregation, linkType.Bidirectional, linkType.Bidirectional,
      linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.UndertimeException, linkType.Invocation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'Object cannot be connected to process with this link.';
  }

  description(): string {
    return 'Object cannot be connected to process with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}


class ProcessToObject extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Aggregation, linkType.Bidirectional, linkType.Bidirectional,
      linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.UndertimeException, linkType.Invocation,
      linkType.Consumption, linkType.Agent, linkType.Instrument);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'Process cannot be connected to object with this link.';
  }

  description(): string {
    return 'Process cannot be connected to object with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}


class ObjectToObject extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Consumption, linkType.Effect, linkType.Result, linkType.Agent, linkType.Instrument,
      linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.UndertimeException, linkType.Invocation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'process cannot be connected to process with this link.';
  }

  description(): string {
    return 'process cannot be connected to process with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class StateToObject extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Invocation, linkType.Result);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'State cannot be connected to object with this link.';
  }

  description(): string {
    return 'State cannot be connected to object with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}


class ObjectToState extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Invocation, linkType.Result, linkType.Instrument,
      linkType.Agent, linkType.Consumption, linkType.UndertimeException, linkType.OvertimeException, linkType.UndertimeOvertimeException, linkType.Effect);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'Object cannot be connected to state with this link.';
  }

  description(): string {
    return 'Object cannot be connected to state with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class StateToProcess extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Aggregation, linkType.Invocation, linkType.Result, linkType.Effect);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'State cannot be connected to process with this link.';
  }

  description(): string {
    return 'State cannot be connected to process with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class ProcessToState extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Aggregation, linkType.Effect, linkType.Agent, linkType.UndertimeOvertimeException, linkType.OvertimeException,
      linkType.Unidirectional, linkType.Bidirectional, linkType.Instrument, linkType.Consumption, linkType.Invocation, linkType.Instantiation,
      linkType.Generalization, linkType.UndertimeException);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'Process cannot be connected to state with this link.';
  }

  description(): string {
    return 'Process cannot be connected to state with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class StateToState extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Aggregation, linkType.Effect, linkType.Agent, linkType.UndertimeOvertimeException, linkType.OvertimeException,
      linkType.Result, linkType.Instrument, linkType.Consumption, linkType.Invocation, linkType.Instantiation,
      linkType.Generalization, linkType.UndertimeException);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return false;
  }

  message(): string {
    return 'State cannot be connected to state with this link.';
  }

  description(): string {
    return 'State cannot be connected to state with this link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return '';
  }
}

class CannotConnectThingToItsInzoomedFather extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object, EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object, EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Exhibition);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (source.getRefineeInzoom() && source.getRefineeInzoom().children.find(child => child.logicalElement === target.logicalElement))
      return false;
    return true;
  }

  message(): string {
    return 'Inzoomed thing cannot connect explicitly to its children with structural link rather then aggregation link.';
  }

  description(): string {
    return 'Inzoomed thing cannot connect explicitly to its children with structural link rather then aggregation link.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    return undefined;
  }
}

class ProcessToProcessExceptionsUndertimeOvertime extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.UndertimeOvertimeException);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return true;
  }

  message(): string {
    return '';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    const dur = (<any>source.logicalElement).getDurationManager().getTimeDuration();
    if ((<any>source.logicalElement).isTimeDuration() === false || !dur.min || !dur.max)
      return 'Source process should have duration parameters.';
    return undefined;
  }
}

class ProcessToProcessExceptionsOvertime extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.OvertimeException);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return true;
  }

  message(): string {
    return '';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    const dur = (<any>source.logicalElement).getDurationManager().getTimeDuration();
    if ((<any>source.logicalElement).isTimeDuration() === false || !dur.max)
      return 'Source process should have maximal duration parameter.';
    return undefined;
  }
}

class ProcessToProcessExceptionsUndertime extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  typeLink(): LinksSet {
    return createSet(linkType.UndertimeException);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    return true;
  }

  message(): string {
    return '';
  }

  description(): string {
    return '';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    const dur = (<any>source.logicalElement).getDurationManager().getTimeDuration();
    if ((<any>source.logicalElement).isTimeDuration() === false || !dur.min)
      return 'Source process should have minimal duration parameter.';
    return undefined;
  }
}

class OnlyOneLevelOfInstantiation extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (source.getAllLinks().inGoing.find(l => l.type === linkType.Instantiation))
      return false;
    return true;
  }

  message(): string {
    return 'An instance cannot have further specializations.';
  }

  description(): string {
    return 'An instance cannot have further specializations.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (this.canConnect(source, target) === false)
      return 'An instance cannot have further specializations.';
    return undefined;
  }
}

class OnlyOneLevelOfInstantiation2 extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (target.getAllLinks().outGoing.find(l => l.type === linkType.Instantiation || l.type === linkType.Generalization))
      return false;
    return true;
  }

  message(): string {
    return 'An instance cannot have further specializations.';
  }

  description(): string {
    return 'An instance cannot have further specializations.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (this.canConnect(source, target) === false)
      return 'An instance cannot have further specializations.';
    return undefined;
  }
}

class GeneralizationPhysicalToPhysical extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Generalization);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if ((<any>source.logicalElement).essence === Essence.Physical && (<any>target.logicalElement).essence === Essence.Informatical)
      return false;
    return true;
  }

  message(): string {
    return 'Specialization of a physical thing should be physical too.';
  }

  description(): string {
    return 'Specialization of a physical thing should be physical too.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if ((<any>source.logicalElement).essence === Essence.Physical && (<any>target.logicalElement).essence === Essence.Informatical)
      return 'Specialization of a physical thing should be physical too.';
    return undefined;
  }
}

class CannotConnectFundamentalFromSharedSubModelSource extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object, EntityType.Process);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Exhibition, linkType.Aggregation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (source.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel))
      return false;
    return true;
  }

  message(): string {
    return 'An entity that is shared with sub-model cannot add new part in the father model.';
  }

  description(): string {
    return 'An entity that is shared with sub-model cannot add new part in the father model.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    if (source.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel))
      return 'An entity that is shared with sub-model cannot add new part in the father model.';
    return undefined;
  }
}

class CannotConnectFundamentalFromSharedSubModelSource2 extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Object, EntityType.Process, EntityType.State);
  }

  typeLink(): LinksSet {
    return createSet(linkType.Instantiation, linkType.Generalization, linkType.Exhibition, linkType.Aggregation);
  }

  canConnect(source: OpmVisualThing, target: OpmVisualThing): boolean {
    if (source.fatherObject.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel))
      return false;
    return true;
  }

  message(): string {
    return 'An entity that is shared with sub-model cannot add new part in the father model.';
  }

  description(): string {
    return 'An entity that is shared with sub-model cannot add new part in the father model.';
  }

  warning(source: OpmVisualThing, target: OpmVisualThing): string {
    if (source.fatherObject.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel))
      return 'An entity that is shared with sub-model cannot add new part in the father model.';
    return undefined;
  }
}

class exhibitionAncestor extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);

  typeLink(): LinksSet {
    return createSet(linkType.Exhibition);
  }

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    const logicalSource = source.logicalElement as OpmLogicalEntity<OpmVisualEntity>;
    const logicalTarget = target.logicalElement as OpmLogicalEntity<OpmVisualEntity>;
    if (logicalSource.getAncestorExhibitions().some(log => log.lid === logicalTarget.lid)) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'A recursive exhibition links is forbidden';
  }

  description(): string {
    return 'A recursive exhibition links is forbidden';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (!this.canConnect(source, target)) {
      return this.message();
    }
    return undefined;
  }

}

class OneUnidirectional extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  private readonly set = SetOfEntites(EntityType.Process, EntityType.Object, EntityType.State);

  typeLink(): LinksSet {
    return createSet(linkType.Unidirectional);
  }

  typeSource(): EntitiesSet {
    return this.set;
  }

  typeTarget(): EntitiesSet {
    return this.set;
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (source.getLinksWith(target).outGoing.find(l => l.target === target)) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'Only one unidirectional link can be used between 2 entities.';
  }

  description(): string {
    return 'Only one unidirectional link can be used between 2 entities.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (!this.canConnect(source, target)) {
      return this.message();
    }
    return undefined;
  }

}

class SingleInstrumentFromStates extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeLink(): LinksSet {
    return createSet(linkType.Instrument);
  }

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.State);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    if (source.fatherObject.getLinksWith(target).outGoing.find(l => l.type === linkType.Instrument)) {
      return false;
    }
    return true;
  }

  message(): string {
    return 'When an object is connected to a process both from itself and its states, only the link originating from the object itself will persist.';
  }

  description(): string {
    return 'When an object is connected to a process both from itself and its states, only the link originating from the object itself will persist.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (!this.canConnect(source, target)) {
      return this.message();
    }
    return undefined;
  }

}

class SingleInstrumentFromStates2 extends BehaviouralRule<OpmVisualEntity, OpmVisualEntity> {

  typeLink(): LinksSet {
    return createSet(linkType.Instrument);
  }

  typeSource(): EntitiesSet {
    return SetOfEntites(EntityType.Object);
  }

  typeTarget(): EntitiesSet {
    return SetOfEntites(EntityType.Process);
  }

  canConnect(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
    return true;
  }

  message(): string {
    return 'When an object is connected to a process both from itself and its states, only the link originating from the object itself will persist.';
  }

  description(): string {
    return 'When an object is connected to a process both from itself and its states, only the link originating from the object itself will persist.';
  }

  warning(source: OpmVisualEntity, target: OpmVisualEntity): string {
    if (source.getChildrenLinks().outGoing.find(l => l.type === linkType.Instrument && l.target === target && OPCloudUtils.isInstanceOfVisualState(l.source))) {
      return this.message();
    }
    return undefined;
  }

}

export const rules = new Array<Rule>(
  new ProcessCannotBeConnectedToitselfWithProceduralLinks(),
  new AlreadyConnectedWithProcedural(),
  new AlreadyConnectedWithStructural(),
  new AlreadyConnectedWithStructuralOnTheOtherWay(),
  new CantConnectSelfInvocationForInzoomedProcess(),
  new CantConnectInzoomedProcessToItsChildrenWithInvocation(),
  new CantConnectConsumed(),
  new CantConnectConsumed2(),
  // new CantConnectBeforeCreated(),
  // new CantConnectBeforeCreated2(),
  new ObjectCantConnectToObjectWithProceduralLinks(),
  new PreventAggregationBetweenInformaticalToPhysical(),
  new AggregationBetweenPhysicaltoInformatical(),
  new ExhibitionToPhysical(),
  new LegalConsumptionWarning(),
  new ProcessToProcess(),
  new ObjectToProcess(),
  new ProcessToObject(),
  new ObjectToObject(),
  new StateToObject(),
  new ObjectToState(),
  new StateToProcess(),
  new ProcessToState(),
  new StateToState(),
  new CannotConnectThingToItsInzoomedFather(),
  new ProcessToProcessExceptionsUndertimeOvertime(),
  new ProcessToProcessExceptionsOvertime(),
  new ProcessToProcessExceptionsUndertime(),
  new OnlyOneLevelOfInstantiation(),
  new OnlyOneLevelOfInstantiation2(),
  new GeneralizationPhysicalToPhysical(),
  new CannotConnectFundamentalFromSharedSubModelSource(),
  new CannotConnectFundamentalFromSharedSubModelSource2(),
  new exhibitionAncestor(),
  new OneUnidirectional(),
  new SingleInstrumentFromStates(),
  new SingleInstrumentFromStates2(),
);

// TODO: Fix CantConnectConsumed & CantConnectBeforeCreated rules set
