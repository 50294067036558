import { validationAlert } from '../rappidEnviromentFunctionality/shared';
import { OpmModel } from '../../models/OpmModel';
import { OpmOpd } from '../../models/OpmOpd';
import { elmIsSubObject, handleExeExceptions, hasWhiteSpaces, ObjectItem } from './computationalPartUtils';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { VariablesCalculator } from './variables-calculator';
import { WebSocketCommunicatorI } from './communication-object';
import { code } from '../../models/ConfigurationOptions';
import {LinkConstraints} from "../../rappid-components/services/linkConstraints";

/**
 * A class that handles SQL functions.
 * opmModel - the current opm model
 * initRappid - init rappid service
 * */
export class SqlFunctionExecutor {
  constructor(private readonly opmModel: OpmModel, private readonly sqlWS: WebSocketCommunicatorI) {
  }
  /**
   savetosnapshot takes as arguments the index of the executed process and the objects
   connected to that specific process , it builds an object containing key-value pairs,
   where the key is the object name and the value is the value/state of the object,
   and sends it to the sql server
   **/
  /*
  public async saveSnapshot(pIndex , ObjectsValuesBeforeExecution) {
    let index;
    if (!ObjectsValuesBeforeExecution) {
      return;
    }
    if (this.sqlWS.isActive) {
      if (pIndex === 0) {
        index = 'true';
      } else {
        index = 'false';
      }
      let object_value_pair = '{' ;
      let i ;
      for ( i = 0 ; i < ObjectsValuesBeforeExecution.length ; i++) { // {"object_name" : "value" , }
        if ( ObjectsValuesBeforeExecution[i].valueType == null && ObjectsValuesBeforeExecution[i].currentState !== '') {
          object_value_pair = object_value_pair + ', "' + ObjectsValuesBeforeExecution[i].name + '": "' + ObjectsValuesBeforeExecution[i].currentState + '"';
        } else if ( ObjectsValuesBeforeExecution[i].value ) {
          object_value_pair = object_value_pair + ', "' + ObjectsValuesBeforeExecution[i].name + '": "' + ObjectsValuesBeforeExecution[i].value + '"';
        }
      }
      object_value_pair = object_value_pair + '}';
      // let message = ' INSERT INTO simulations VALUES ' + ''; // build message
      this.sqlWS.send({
        what: 'snapshot',
        // topic : this.opmModel.id
        index : index ,
        message: object_value_pair
      });
    } else {
      validationAlert('No SQL WS server connection established!', 2500, undefined, true);
    }
    return;
  }
   */

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], SQLFunction) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.SQL);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    const variables_array = this.calc_variables_array(all_paths, valuesArray);
    return this.runSQLFunction(variables_array, SQLFunction, variables_array);
  }

  private async runSQLFunction(valuesArray, SQLFunction, variables_array: ObjectItem[]) {
    let x;
    if (this.sqlWS.isActive) {
      const senTquery = this.calcAndValidateMessage(valuesArray, SQLFunction, variables_array);
      if (senTquery !== undefined) {
        this.sqlWS.send({
          what: 'query',
          message: senTquery
        });
        x = this.sqlWS.get();
      } else {
        x = '';
      }
    } else {
      x = '';
      validationAlert('No SQL WS server connection established!', 2500, undefined, true);
    }
    return x;
  }
  /**
   * returns the message that should be sent (undefined if there is no need to send a message)
   */
  calcAndValidateMessage(valuesArray: any, SQLFunction: any, variables_array: ObjectItem[]) {
    let message;
    if (SQLFunction.SQLquerywhat === 'select') {
      const sqlSelect = SQLFunction.SQLselect_field;
      const sqlSelectInVariableArray = variables_array.filter(value => value.name === sqlSelect || value.alias === sqlSelect)[0];
      const sqlSelectIsEmpty = !sqlSelect || (sqlSelect === '');
      if ( sqlSelectIsEmpty || (sqlSelectInVariableArray !== undefined && sqlSelectInVariableArray.sourceElementValue === '')) {
        handleExeExceptions('The inserted Select field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_select = sqlSelectInVariableArray ? sqlSelectInVariableArray.sourceElementValue : sqlSelect;
      const sqlFrom = SQLFunction.SQLfrom_field;
      const sqlFromInVariableArray = variables_array.filter(value => value.name === sqlFrom || value.alias === sqlFrom)[0];
      const sqlFromIsEmpty = !sqlFrom || (sqlFrom === '');
      if ( sqlFromIsEmpty || hasWhiteSpaces(sqlFrom) || (sqlFromInVariableArray !== undefined && hasWhiteSpaces(sqlFromInVariableArray.sourceElementValue))) {
        handleExeExceptions('The inserted from field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_from = sqlFromInVariableArray ? sqlFromInVariableArray.sourceElementValue : sqlFrom;
      const sqlWhere = SQLFunction.SQLwhere_field;
      const sqlWhereInVariableArray = variables_array.filter(value => value.name === sqlWhere || value.alias === sqlWhere)[0];
      if ( hasWhiteSpaces(sqlWhere) || (sqlWhereInVariableArray !== undefined && hasWhiteSpaces(sqlWhereInVariableArray.sourceElementValue))) {
        handleExeExceptions('The inserted where field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_where = sqlWhereInVariableArray ? sqlWhereInVariableArray.sourceElementValue : sqlWhere;
      message = ' SELECT ' + m_select + ' FROM ' + m_from + (m_where !== '' ? ' WHERE ' + m_where : '') + ';';
    }
    if (SQLFunction.SQLquerywhat === 'insert') {
      const sqlInsert = SQLFunction.SQLinsert_into_field;
      const sqlInsertInVariableArray = variables_array.filter(value => value.name === sqlInsert || value.alias === sqlInsert)[0];
      const sqlInsertIsEmpty = !sqlInsert || (sqlInsert === '');
      if ( sqlInsertIsEmpty || hasWhiteSpaces(sqlInsert) || (sqlInsertInVariableArray !== undefined && hasWhiteSpaces(sqlInsertInVariableArray.sourceElementValue))) {
        handleExeExceptions('The inserted insert into field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_insert = sqlInsertInVariableArray ? sqlInsertInVariableArray.sourceElementValue : sqlInsert;
      const sqlValues = SQLFunction.SQLvalues_field;
      const sqlValuesInVariableArray = variables_array.filter(value => value.name === sqlValues || value.alias === sqlValues)[0];
      const sqlValuesIsEmpty = !sqlValues || (sqlValues === '');
      if ( sqlValuesIsEmpty || (sqlValuesInVariableArray !== undefined && sqlValuesInVariableArray.sourceElementValue === '')) {
        handleExeExceptions('The inserted values field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_values = sqlValuesInVariableArray ? sqlValuesInVariableArray.sourceElementValue : sqlValues;
      message = ' INSERT INTO ' + m_insert + ' VALUES ' + m_values + ';';
    }
    if (SQLFunction.SQLquerywhat === 'create') {
      const sqlCreate = SQLFunction.SQLcreat_table_field;
      const sqlCreateInVariableArray = variables_array.filter(value => value.name === sqlCreate || value.alias === sqlCreate)[0];
      const sqlCreateIsEmpty = !sqlCreate || (sqlCreate === '');
      if ( sqlCreateIsEmpty ||  (sqlCreateInVariableArray !== undefined && sqlCreateInVariableArray.sourceElementValue === '')) {
        handleExeExceptions('The inserted create field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_create = sqlCreateInVariableArray ? sqlCreateInVariableArray.sourceElementValue : sqlCreate;
      message = ' CREATE TABLE ' + m_create + ';';
    }
    if (SQLFunction.SQLquerywhat === 'update') {
      const sqlUpdate = SQLFunction.SQLupdate_field;
      const sqlUpdateInVariableArray = variables_array.filter(value => value.name === sqlUpdate || value.alias === sqlUpdate)[0];
      const sqlUpdatetIsEmpty = !sqlUpdate || (sqlUpdate === '');
      if ( sqlUpdatetIsEmpty ||  (sqlUpdateInVariableArray !== undefined && sqlUpdateInVariableArray.sourceElementValue === '' )) {
        handleExeExceptions('The inserted update field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_update = sqlUpdateInVariableArray ? sqlUpdateInVariableArray.sourceElementValue : sqlUpdate;
      const sqlSet = SQLFunction.SQLset_field;
      const sqlSetInVariableArray = variables_array.filter(value => value.name === sqlSet || value.alias === sqlSet)[0];
      const sqlSetIsEmpty = !sqlSet || (sqlSet === '');
      if ( sqlSetIsEmpty ||  (sqlSetInVariableArray !== undefined && sqlSetInVariableArray.sourceElementValue === '' )) {
        handleExeExceptions('The inserted set field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_set = sqlSetInVariableArray ? sqlSetInVariableArray.sourceElementValue : sqlSet;
      const sqlWhere = SQLFunction.SQLwhere_field;
      const sqlWhereInVariableArray = variables_array.filter(value => value.name === sqlWhere || value.alias === sqlWhere)[0];
      if ( hasWhiteSpaces(sqlWhere) || (sqlWhereInVariableArray !== undefined && hasWhiteSpaces(sqlWhereInVariableArray.sourceElementValue))) {
        handleExeExceptions('The inserted where field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_where = sqlWhereInVariableArray ? sqlWhereInVariableArray.sourceElementValue : sqlWhere;
      message = ' UPDATE ' + m_update + ' SET ' + m_set + (m_where !== '' ? ' WHERE ' + m_where : '') + ';';
    }
    if (SQLFunction.SQLquerywhat === 'drop') {
      const sqlDrop = SQLFunction.SQLdrop_table_field;
      const sqlDropInVariableArray = variables_array.filter(value => value.name === sqlDrop || value.alias === sqlDrop)[0];
      const sqlDropIsEmpty = !sqlDrop || (sqlDrop === '');
      if ( sqlDropIsEmpty ||  (sqlDropInVariableArray !== undefined && sqlDropInVariableArray.sourceElementValue === '' )) {
        handleExeExceptions('The inserted drop field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_drop = sqlDropInVariableArray ? sqlDropInVariableArray.sourceElementValue : sqlDrop;
      message = ' DROP TABLE ' + m_drop + ';';
    }
    if (SQLFunction.SQLquerywhat === 'user defined query'){
      const sqlDefined = SQLFunction.SQLuser_define;
      const sqlDefinedInVariableArray = variables_array.filter(value => value.name === sqlDefined || value.alias === sqlDefined)[0];
      const sqlDropIsEmpty = !sqlDefined || (sqlDefined === '');
      if ( sqlDropIsEmpty ||  (sqlDefinedInVariableArray !== undefined && sqlDefinedInVariableArray.sourceElementValue === '' )) {
        handleExeExceptions('The inserted defined field is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
        return undefined;
      }
      const m_defined = sqlDefinedInVariableArray ? sqlDefinedInVariableArray.sourceElementValue : sqlDefined;
      message =  m_defined + ';';

    }
    return message;
  }
  /**
   * A function that calculates variables_array, which is an ObjectItem array that includes for each object in it the
   * the object name,alias and value.
   **/
  private calc_variables_array(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[]) {
    let all_variables_str = [];
    let variables_array = [];
    let alias = [];
    new VariablesCalculator(all_paths, valuesArray).calc_variables_str(all_variables_str, code.SQL,
      [], alias, variables_array);
    return variables_array;
  }
}
