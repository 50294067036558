import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmObject } from "../../DrawnPart/OpmObject";
import { OpmLogicalObject } from "../../LogicalPart/OpmLogicalObject";
import { OpmVisualObject } from "../../VisualPart/OpmVisualObject";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class DestateCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'destate',
            displayTitle: 'Destate',
            svg: 'destate',
            action: new DestateAction(this.init, this.object, this.visual),
            gif: 'assets/gifs/destating.gif',
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'destate',
            displayTitle: 'Destate',
            svg: 'destate',
            action: new DestateAction(this.init, this.object, this.visual),
            gif: 'assets/gifs/destating.gif',
        }
    }

}

class DestateAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    act() {
      const cell = this.init.graph.getCell(this.visual.id);
      const vis = this.init.opmModel.getVisualElementById(this.visual.id) as OpmVisualObject;
      if (vis && cell) {
        const newVisuals = (vis.logicalElement as OpmLogicalObject).deStating();
        cell.updateDeStating(newVisuals, this.init);
      }
    }

}
