import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";
import {OpmVisualThing} from "../../models/VisualPart/OpmVisualThing";
import {OpmModel} from "../../models/OpmModel";

export abstract class MethodologicalChecker {

  protected model: OpmModel;
  protected title;
  public status;
  protected invalidThings: Array<OpmLogicalThing<OpmVisualThing>>;

  protected constructor(model: OpmModel) {
    this.model = model;
    this.status = MethodologicalCheckingStatus.UNCHECKED;
    this.invalidThings = [];
  }

  public abstract check(): void;
  public abstract getDescriptionTooltip(): string;

  public getInvalidThings(): Array<OpmLogicalThing<OpmVisualThing>> {
    return this.invalidThings;
  }

  protected addToInvalidThings(logical: OpmLogicalThing<OpmVisualThing>): void {
    if (!this.invalidThings.includes(logical)) {
      this.invalidThings.push(logical);
    }
  }

}


export enum MethodologicalCheckingStatus {
  UNCHECKED = 1,
  INVALID,
  VALID,

}
