export interface ElementAction {
    act(): void;
}

export interface ElementHaloHandle {
    flag: boolean,
    name: string,
    displayTitle: string,
    svg: string,
    action: ElementAction,
    gif?: string,
}

export interface ElementToolbarHandle {
    name: string,
    displayTitle: string,
    svg: string,
    action: ElementAction,
    gif: string,
}

export interface ElementCommand {
    createToolbarHandle(): ElementToolbarHandle,
    createHaloHandle(): ElementHaloHandle
}

export abstract class CommandsDecider {

    abstract getHaloCommands(): Array<ElementCommand>;

    abstract getToolabarCommands(): Array<ElementCommand>;

    getHaloHandle(): Array<ElementHaloHandle> {
        return this.getHaloCommands().map(c => c.createHaloHandle());
    }

    getToolabarHandle(): Array<ElementToolbarHandle> {
        return this.getToolabarCommands().map(c => c.createToolbarHandle());
    }

}
