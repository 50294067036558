import { Component, Optional, Inject } from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';


@Component({
  selector: 'styleCopyingDialog',
  templateUrl: './styleCopyingDialog.component.html',
  styleUrls: ['./styleCopyingDialog.component.css']
})
  /**
   * group 04:
   * The 'StyleCopyingDialogComponent' class is the class of the dialog that the user opens when he clicks on Ctrl+Shift+C.
  */
export class StyleCopyingDialogComponent {
  selectedElement;
  isRightClick;
  constructor(@Optional() public dialogRef: MatDialogRef<StyleCopyingDialogComponent>, private initRappid: InitRappidService,
              @Inject(MAT_DIALOG_DATA) public data: any) {
    this.selectedElement = initRappid.selectedElement;
    this.isRightClick = this.data.isRightClick;
  }

  /**
   * group 04:
   * uncheckAll function enables the user to "clean" all elements that are chosen in the dialog.
   */
  uncheckAll() {
    $('input[type="checkbox"]:checked').prop('checked', false);
  }

  checkAll() {
    $('input[type="checkbox"]').prop('checked', true);
  }

  /**
   * group 04:
   * 'apply()' function is called when the user clicks on the 'Submit' button.
   * In this function we keep the style of the selected element in a dictionary named 'copiedStyleParams'.
   * We keep only the style elements that were marked as "checked" in the dialog.
   */
  apply() {
    const dict = {};
    const borderColor = (<HTMLInputElement>$('#border_color')[0]).checked;
    const fontSize = (<HTMLInputElement>$('#font_size')[0]).checked;
    const fillColor = (<HTMLInputElement>$('#fill_color')[0]).checked;
    const font = (<HTMLInputElement>$('#font')[0]).checked;
    const textColor = (<HTMLInputElement>$('#text_color')[0]).checked;
    dict['isRightClick'] = this.isRightClick;
    if (borderColor) {
      dict['borderColor'] = this.selectedElement.getVisual().strokeColor;
    }
    if (fontSize) {
      dict['fontSize'] = this.selectedElement.getVisual().textFontSize;
    }
    if (fillColor) {
      dict['fillColor'] = this.selectedElement.getVisual().fill;
    }
    if (textColor) {
      dict['textColor'] = this.selectedElement.getVisual().textColor;
    }
    if (font) {
      dict['font'] = this.selectedElement.getVisual().textFontFamily;
    }
    this.initRappid.copiedStyleParams = dict;
  }

  toggleAll($event: Event) {
    if ((<HTMLInputElement>$event.target).checked === true)
      return this.checkAll();
    return this.uncheckAll();
  }
}
