import {DisplayModelType} from "../../rappid-components/services/storage/model-storage.interface";
import {FolderPermissions} from "../folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";

export class DeepSearchModel {
  archiveMode: boolean;
  description: string;
  directory_id: string;
  editBy: { date: string, name: string};
  id: string;
  permissions: string;
  title: string;
  path: string;
  type: DisplayModelType;

  constructor(archiveMode: boolean, description: string, directory_id: string,
              editBy: { date: string, name: string}, id: string, permissions: string, title: string) {
    this.archiveMode = archiveMode;
    this.description = description;
    this.directory_id =  directory_id;
    this.editBy = editBy;
    this.id = id;
    this.permissions = permissions;
    this.title = title;
    this.path = '';
    this.type = DisplayModelType.MAIN;
  }
}
export class DeepSearchFolder {
  public father: string;
  public id: string;
  public title: string;
  public permissions: FolderPermissions;

  constructor(father: string, id: string, title: string) {
    this.father = father;
    this.id = id;
    this.title = title;
  }

  get name() {
    return this.title;
  }
}

export interface FoldPathsAndDirs {
  folder: DeepSearchFolder;
  pathAndDir: {path: string, currentDialogPath: Array<{id: string, title: string}>};
}

export interface ModelsPathAndDirs {
  model: DeepSearchModel;
  pathAndDir: { path: string, currentDialogPath: Array<{ id: string, title: string }> };
}
