import {ValueAttributeRange, ValueAttributeType} from "./attribute-range";

const TRUE = 'true';
const FALSE = 'false';

export class BooleanRange implements ValueAttributeRange {

    constructor() {
    }

    public setPattern(value: string): { wasSet: boolean } {
        return { wasSet: true }
    }

    public getDefault(): string {
        return String(FALSE);
    }

    public getPattern(): string {
        return 'BOOLEAN';
    }

    public validate(value: string): boolean {
        return value && (value.toLowerCase() === TRUE || value.toLowerCase() === FALSE);
    }

    public getType(): ValueAttributeType {
        return ValueAttributeType.BOOLEAN;
    }

    public isSubRange(newRange): boolean {
      return true;
    }

}
