// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html, body {
  height: 100%;
}

body {
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  height: 120px;
  animation: rotator 1.9s linear infinite;
}

@keyframes rotator {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(270deg);
  }
}
.path {
  stroke-dasharray: 187;
  stroke-dashoffset: 0;
  transform-origin: center;
  animation: dash 1.9s ease-in-out infinite, colors 7.6s ease-in-out infinite;
}

@keyframes colors {
  0% {
    stroke: #4285F4;
  }
  25% {
    stroke: #4285F4;
  }
  50% {
    stroke: #4285F4;
  }
  75% {
    stroke: #4285F4;
  }
  100% {
    stroke: #4285F4;
  }
}
@keyframes dash {
  0% {
    stroke-dashoffset: 187;
  }
  50% {
    stroke-dashoffset: 46.75;
    transform: rotate(135deg);
  }
  100% {
    stroke-dashoffset: 187;
    transform: rotate(450deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/Spinner/Progress_Spinner.scss"],"names":[],"mappings":"AAEA;EAAa,YAAA;AAAb;;AAEA;EACE,aAAA;EACA,mBAAA;EACA,uBAAA;AACF;;AAOA;EACE,aAAA;EACA,uCAAA;AAJF;;AAOA;EACE;IAAK,uBAAA;EAHL;EAIA;IAAO,yBAAA;EADP;AACF;AAGA;EACE,qBAdO;EAeP,oBAAA;EACA,wBAAA;EACA,2EACE;AAFJ;;AAMA;EAKE;IAAK,eAAA;EANL;EAOA;IAAM,eAAA;EAJN;EAKA;IAAM,eAAA;EAFN;EAGA;IAAM,eAAA;EAAN;EACA;IAAO,eAAA;EAEP;AACF;AAAA;EACE;IAAK,sBAnCE;EAsCP;EAFA;IACE,wBAAA;IACA,yBAAA;EAIF;EAFA;IACE,sBAzCK;IA0CL,yBAAA;EAIF;AACF","sourcesContent":["// This is just to center the spinner\n\nhtml, body { height: 100%; }\n\nbody {\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n// Here is where the magic happens\n\n$offset: 187;\n$duration: 1.9s;\n\n.spinner {\n  height: 120px;\n  animation: rotator $duration linear infinite;\n}\n\n@keyframes rotator {\n  0% { transform: rotate(0deg); }\n  100% { transform: rotate(270deg); }\n}\n\n.path {\n  stroke-dasharray: $offset;\n  stroke-dashoffset: 0;\n  transform-origin: center;\n  animation:\n    dash $duration ease-in-out infinite,\n    colors ($duration*4) ease-in-out infinite;\n}\n\n@keyframes colors {\n  //0% { stroke: #4285F4; }\n  //25% { stroke: #DE3E35; }\n  //50% { stroke: #F7C223; }\n  //75% { stroke: #1B9A59; }\n  0% { stroke: #4285F4; }\n  25% { stroke: #4285F4; }\n  50% { stroke: #4285F4; }\n  75% { stroke: #4285F4; }\n  100% { stroke: #4285F4; }\n}\n\n@keyframes dash {\n  0% { stroke-dashoffset: $offset; }\n  50% {\n    stroke-dashoffset: $offset/4;\n    transform:rotate(135deg);\n  }\n  100% {\n    stroke-dashoffset: $offset;\n    transform:rotate(450deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
