import { AfterViewInit, Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import { GraphService } from '../../../rappid-components/services/graph.service';
import { LinksDialogComponent } from '../../../dialogs/choose-link-dialog/Dialog.component';
import { OplDialogComponent } from '../../../dialogs/opl-dialog/opl-dialog.component';
import { Subscription } from 'rxjs/Subscription';
import { ActivatedRoute } from '@angular/router';
import { g } from 'jointjs';
import {adjustToolbarSizeAndOpl, OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";

@Component({
  selector: 'opc-main',
  templateUrl: `./main.component.html`,
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy, AfterViewInit {
  dialogSubscription: Subscription;
  @ViewChild('sidenav', { static: false }) sidenav;
  dialog$: BehaviorSubject<any>;
  graph;
  paper;
  paperScroller;
  cell$;
  private DialogType;
  user_subscription;
  sdTreeOpen = true;
  inspectorOpen = true;
  toSide = true;
  visible;
  // a flag to set the navigator to be floating on the paper or on the sidebar.
  navigatorIsFloating = false;
  private upperTouchStartFunction;
  private upperTouchEndFunction;
  private bottomTouchEndFunction;
  private bottomTouchMoveFunction;
  private previousDraggableThingsListHeight;
  private oplTouchMoveFunction;
  private chatTouchMoveFunction;
  private oplTouchEndFunction;
  private chatTouchEndFunction;

  constructor(
    private initRappid: InitRappidService,
    private graphService: GraphService,
    private route: ActivatedRoute,
    // commandManagerService: CommandManagerService,
    private MatDialog: MatDialog,
    private viewContainer: ViewContainerRef,
  ) {
    this.graph = graphService.getGraph();
    // this.commandManager = commandManagerService.commandManager;
    this.paper = initRappid.paper;
    this.paperScroller = initRappid.paperScroller;
    this.cell$ = initRappid.cell$;
    this.dialog$ = initRappid.dialog$;
  }

  ngOnInit() {
    this.sunscribeToDialog();
  }

  ngAfterViewInit() {
    this.paperScroller.positionPoint(new g.Point(0, 0), '-99%', '-99%');
    this.graphService.renderGraph(this.initRappid.getOpmModel().currentOpd, this.initRappid);
  }

  sunscribeToDialog() {
    this.dialogSubscription = this.dialog$.subscribe((data) => {
      //console.log(data);
      if (!data || data.chosen) {
        return;
      }

      switch (data.type) {
        case 'choose-link': {
          this.DialogType = LinksDialogComponent;
          data.chosen = true;
          break;
        }
        case 'opl': {
          this.DialogType = OplDialogComponent;
          break;
        }
        case 'close-opl': {
          if (this.DialogType === OplDialogComponent) {
            this.MatDialog.closeAll();
            return;
          }
          break;
        }
        default: {
          return;
        }
      }

      this.MatDialog.open(this.DialogType, {
        viewContainerRef: this.viewContainer,
        panelClass: 'choose-link-dialog',
        hasBackdrop: true, // when true, the dialog is modal
        disableClose: true, // when true, the dialog is not closed by clicking escape
        // or clicking outside the dialog
        data: data.instance
      });
    });
  }

  toggleSidenav() {
    this.sdTreeOpen = !this.sdTreeOpen;
    this.sdTreeOpen ? this.closeSidenav() : this.openSidenav();
  }

  openSidenav() {
    this.sidenav.open();
  }

  getPaperStyle() {
    if (this.initRappid && (this.initRappid.isRendering || this.initRappid.isLoadingModel))
      return {'filter': 'blur(3px'};
    return '';
  }

  closeSidenav() {
    this.sidenav.close();
  }

  toggleInspector() {
    this.inspectorOpen = !this.inspectorOpen;
  }

  ngOnDestroy() {
    if (this.dialogSubscription)
      this.dialogSubscription.unsubscribe();
    if (this.user_subscription)
      this.user_subscription.unsubscribe();
  }

  shouldShowChat(): boolean {
    return this.initRappid.oplService.orgSettings.chatEnabled && this.initRappid.showChatIcon &&
      !this.initRappid.elementToolbarReference?.isExample() && !this.initRappid.elementToolbarReference?.isTemplate();
  }

  toggleOplPosition() {
    this.toSide = !this.toSide;
    this.initRappid.setLeftBarWindowsSizes({ oplAtBottom: this.toSide });
    setTimeout( function() {  adjustToolbarSizeAndOpl(); }, 100 );
  }

  get side() {
    return this.toSide;
  }
  toggleNavigatorPosition() { // this function is called from main.component.html and also from navigator.component.html
    this.navigatorIsFloating = !this.navigatorIsFloating; // change the navigator to be floating or on the side bar.
    const draggableThingsList = $('#listLogical')[0];
    if (!this.navigatorIsFloating) {
      // $('#all_elements_holder')[0].style.height = '150px';
      draggableThingsList.style.height = this.previousDraggableThingsListHeight + 'px';
    } else {
      this.previousDraggableThingsListHeight = $('#listLogical')[0].getClientRects()[0].height;
      const sideNav = $('#sideNavDiv')[0];
      const oldSize = $('#listLogical')[0].getClientRects()[0];
      const newDraggableThingHeight = sideNav.getClientRects()[0].height + sideNav.getClientRects()[0].top - oldSize.top;
      // $('#all_elements_holder')[0].style.height = newDraggableThingHeight + 'px';
      draggableThingsList.style.height = Math.max(150, newDraggableThingHeight - 20) + 'px';
    }
    const navPos = this.navigatorIsFloating ? 'floating' : 'leftMenu';
    this.initRappid.navigatorComponentRef.switchNavigator(navPos);
    // if (this.navigatorIsFloating === false)
    //     (<any>draggableThingsDiv).style.maxHeight = '150px';
    // else
    //   (<any>draggableThingsDiv).style.maxHeight = '62%';
  }
  toggleChatPosition() { // this function is called from main.component.html and also from Chat.component.html
    this.initRappid.ChatIsFloating = !this.initRappid.ChatIsFloating; // change the Chat box to be floating or on the side bar.
    const draggableThingsList = $('#listLogical')[0];
    if (!this.initRappid.ChatIsFloating) {
      // $('#all_elements_holder')[0].style.height = '150px';
      draggableThingsList.style.height = this.previousDraggableThingsListHeight + 'px';
    } else {
      this.previousDraggableThingsListHeight = $('#listLogical')[0].getClientRects()[0].height;
      const sideNav = $('#sideChatBox')[0];
      const oldSize = $('#listLogical')[0].getClientRects()[0];
      const newDraggableThingHeight = sideNav.getClientRects()[0].height + sideNav.getClientRects()[0].top - oldSize.top;
      // $('#all_elements_holder')[0].style.height = newDraggableThingHeight + 'px';
      draggableThingsList.style.height = Math.max(150, newDraggableThingHeight - 20) + 'px';
    }

    const historyDiv = $('#historyContainer')[0];
    const lastScrollHeight = historyDiv.scrollTop;
    // Only one chat should module should exist, switching it's location
    if (this.initRappid.ChatIsFloating) {
      $("#chatContainer").detach().appendTo("#floatingChatBoxPanel");
      /*  Setup history height in floating chat panel */

      // set height and padding according to floatingChatBoxSize
      const newHistoryHeight = $('#floatingChatBox')[0].getClientRects()[0].height - 90 - 25 + 'px';
      historyDiv.style.height = newHistoryHeight;
      historyDiv.style.paddingBottom = 0.3 + 'em';
      // maintain scroll position while switching state
      historyDiv.scrollTop = lastScrollHeight;
    } else {
      debugger
      $("#chatContainer").detach().appendTo("#ChatBoxPanel");
      // maintain scroll position while switching state
      setTimeout(() => { historyDiv.scrollTop = lastScrollHeight; }, 250);

    }
  }

  hideNavigator() {
    this.initRappid.toggleNavigator();
    // const draggableThingsDiv = $('#all_elements_holder').length === 1 ? $('#all_elements_holder')[0] : null;
    // if (draggableThingsDiv)
    //   (<any>draggableThingsDiv).style.maxHeight = '62%';
  }
  // hide chat when toggle chat
  hideChat() {
    this.initRappid.toggleChat();
  }
  onstartDragUpperHr(event) {
    const that = this;
    if (!event.touches) {
      event.preventDefault();
      window.onmousemove = function(e) { that.moveDragUpperHr(e) };
      window.onmouseup = function(e) { that.endDrag(e) };
    } else {
      this.upperTouchStartFunction = function(e) { that.moveDragUpperHr(e) }
      this.upperTouchEndFunction = function(e) { that.endDrag(e) };
      window.addEventListener('touchmove', this.upperTouchStartFunction);
      window.addEventListener('touchend', this.upperTouchEndFunction);
    }
  }
  onstartDragMidlleHr(event) {
    const that = this;
    if (!event.touches) {
      event.preventDefault();
      window.onmousemove = function(e) { that.moveDragUpperHr(e) };
      window.onmouseup = function(e) { that.endDrag(e) };
    } else {
      this.upperTouchStartFunction = function(e) { that.moveDragUpperHr(e) }
      this.upperTouchEndFunction = function(e) { that.endDrag(e) };
      window.addEventListener('touchmove', this.upperTouchStartFunction);
      window.addEventListener('touchend', this.upperTouchEndFunction);
    }
  }
  endDrag(event) {
    window.onmousemove = function(e) {};
    window.onmouseup = function(e) {};
    window.removeEventListener('touchmove', this.upperTouchStartFunction);
    window.removeEventListener('touchend', this.upperTouchEndFunction);
    window.removeEventListener('touchmove', this.bottomTouchMoveFunction);
    window.removeEventListener('touchend', this.bottomTouchEndFunction);
    window.removeEventListener('touchmove', this.oplTouchMoveFunction);
    window.removeEventListener('touchend', this.oplTouchEndFunction);
    window.removeEventListener('touchmove', this.chatTouchMoveFunction);
    window.removeEventListener('touchend', this.chatTouchEndFunction);
  }

  moveDragUpperHr(event) {
    const clientY = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientY : event.clientY;
    const opdHierarchyDiv = $('#opdHierarchy')[0];
    const addition = clientY - opdHierarchyDiv.getBoundingClientRect().bottom;
    const newHeight = Math.max(150, opdHierarchyDiv.getBoundingClientRect().height + addition) + 'px';
    opdHierarchyDiv.style.height = newHeight;
  }

  onstartDragBottomHr(event) {
    const that = this;
    if (!event.touches) {
      event.preventDefault();
      window.onmousemove = function(e) { that.moveDragBottomHr(e) };
      window.onmouseup = function(e) { that.endDrag(e) };
    } else {
      this.bottomTouchMoveFunction = function(e) { that.moveDragBottomHr(e) }
      this.bottomTouchEndFunction = function(e) { that.endDrag(e) };
      window.addEventListener('touchmove', this.bottomTouchMoveFunction);
      window.addEventListener('touchend', this.bottomTouchEndFunction);
    }
  }

  moveDragBottomHr(event) {
    if (!this.initRappid.showDraggableThings) {
      return;
    }
    const clientY = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientY : event.clientY;
    const opdHierarchyDiv = $('#listLogical')[0];
    const addition = clientY - opdHierarchyDiv.getBoundingClientRect().bottom;
    const newHeight = Math.max(150, opdHierarchyDiv.getBoundingClientRect().height + addition) + 'px';
    opdHierarchyDiv.style.height = newHeight;
    // const draggableThingsList = $('.draggableThingsList')[0];
    // draggableThingsList.style.height = Math.max(150, draggableThingsList.getBoundingClientRect().height + addition) + 'px';
  }

  onstartDragOplHr(event) {
    const that = this;
    if (!event.touches) {
      event.preventDefault();
      window.onmousemove = function(e) { that.moveDragOplHr(e) };
      window.onmouseup = function(e) { that.endDrag(e) };
    } else {
      this.bottomTouchMoveFunction = function(e) { that.moveDragOplHr(e) }
      this.bottomTouchEndFunction = function(e) { that.endDrag(e) };
      window.addEventListener('touchmove', this.oplTouchMoveFunction);
      window.addEventListener('touchend', this.oplTouchEndFunction);
    }
  }

  moveDragOplHr(event) {
    const clientY = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientY : event.clientY;
    const oplFullScreen = $('#oplFullScreen')[0];
    const addition = clientY - oplFullScreen.getBoundingClientRect().bottom;
    const newHeight = Math.max(150, oplFullScreen.getBoundingClientRect().height + addition) + 'px';
    oplFullScreen.style.height = newHeight;
    const oplDiv = $('#opl-widget')[0];
    const aiDiv = $('#opl-ai-text')[0];
    oplDiv.style.height = newHeight;
    aiDiv.style.height = newHeight;
  }
  // control resizer dragging
  onstartDragChatHr(event) {
    const that = this;
    if (!event.touches) {
      event.preventDefault();
      window.onmousemove = function(e) { that.moveDragChatHr(e) };
      window.onmouseup = function(e) { that.endDrag(e) };
    } else {
      this.bottomTouchMoveFunction = function(e) { that.moveDragChatHr(e) }
      this.bottomTouchEndFunction = function(e) { that.endDrag(e) };
      window.addEventListener('touchmove', this.oplTouchMoveFunction);
      window.addEventListener('touchend', this.oplTouchEndFunction);
    }
  }
  moveDragChatHr(event) {
    /*  Setup chat height in sideNav Container */
    // get ref to the chat div
    const clientY = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientY : event.clientY;
    const chatDiv = $('#sideChatBox')[0];
    // set height and padding according to clientY
    const addition = clientY - chatDiv.getBoundingClientRect().bottom;
    const newHeight = Math.max(150, chatDiv.getBoundingClientRect().height + addition) + 'px';
    chatDiv.style.height = newHeight;
    // get ref to the history div
    const historyDiv = $('#historyContainer')[0];
    // set height and padding according to chatDiv
    const newHistoryHeight = chatDiv.getBoundingClientRect().height - 100 - 30 + 'px';
    historyDiv.style.height = newHistoryHeight;
    historyDiv.style.paddingBottom = 0.3 + 'em';

  }

  showGIF($event, handlerGif = '') {
    return OPCloudUtils.showGIF($event, handlerGif);
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }

  getListLogicalMinHeight() {
    if (this.initRappid.showDraggableThings) {
      return '280px';
    }
    return '60px';
  }

  showDraggableThings() {
    const that = this;
    this.initRappid.isLoadingModel = true;
    OPCloudUtils.waitXms(100).then(() => {
      that.initRappid.showDraggableThings = true;
      that.initRappid.isLoadingModel = false;
      that.initRappid.setLeftBarWindowsSizes({});
    });
  }
}
