import { OpmVisualElement } from './OpmVisualElement';
import { OpmModel } from '../OpmModel';
import { EntityLinks } from '../linksHolder';
import { OpmLink } from './OpmLink';
import { OpmRelation } from '../LogicalPart/OpmRelation';
import { OpmLogicalEntity } from '../LogicalPart/OpmLogicalEntity';
import { EntityType } from '../model/entities.enum';
import { OpmLogicalState } from "../LogicalPart/OpmLogicalState";
import { ElementsMap } from '../components/ElementsMap';
import { linkType, linkConnectionType } from '../ConfigurationOptions';
import { removeDuplicationsInArray } from '../../configuration/rappidEnviromentFunctionality/shared';

const defaultDescriptionStatus = ' ';

export abstract class OpmVisualEntity extends OpmVisualElement {
  fill: string;
  xPos: number;
  yPos: number;
  width: number;
  height: number;
  refX: number;
  refY: number;
  xAlign: string;
  yAlign: string;
  textWidth: any; // can be text or percentage
  textHeight: any;  // can be text or percentage
  textAnchor: string;
  isManualTextPos: boolean;
  descriptionStatus: string;
  ports: Array<any>;

  abstract get type(): EntityType;

  private _fatherObject;

  constructor(params, logicalElement) {
    super(params, logicalElement);
  }

  get fatherObject() {
    return this._fatherObject;
  }

  set fatherObject(value) {
    this._fatherObject = value;
  }

  setDescriptionStatus(ds: string): void {
    if (typeof ds === 'string') {
      this.descriptionStatus = ds;
    }
  }

  getDescriptionStatus(): string {
    return this.descriptionStatus || defaultDescriptionStatus;
  }

  getEntityParams() {
    const params = {
      xPos: this.xPos,
      yPos: this.yPos,
      width: this.width,
      height: this.height,
      fill: this.fill,
      refX: this.refX,
      refY: this.refY,
      xAlign: this.xAlign,
      yAlign: this.yAlign,
      textAnchor: this.textAnchor,
      textWidth: this.textWidth,
      textHeight: this.textHeight,
      isManualTextPos: this.isManualTextPos,
      descriptionStatus: this.descriptionStatus || defaultDescriptionStatus,
      fatherObjectId: this.fatherObject ? (this.fatherObject.id ? this.fatherObject.id : this.fatherObject) : null,
      ports: this.ports,
    };
    return { ...super.getElementParams(), ...params };
  }

  getDisplayText(): string {
    return (<OpmLogicalEntity<OpmVisualEntity>>this.logicalElement).getDisplayText();
  }

  updateParams(params) {
    super.updateParams(params);
    // preventing the hovered color from being saved if error occurs
    if (params.fill && params.fill === '#E1E6EB')
      params.fill = this.fill ? this.fill : '#FFFFFF';
    this.fill = params.fill;
    this.xPos = params.xPos;
    this.yPos = params.yPos;
    this.width = params.width;
    this.height = params.height;
    this.refX = params.refX;
    this.refY = params.refY;
    this.xAlign = params.xAlign;
    this.yAlign = params.yAlign;
    this.textAnchor = params.textAnchor;
    this.textWidth = params.textWidth;
    this.textHeight = params.textHeight;
    this.ports = params.ports;
    this.isManualTextPos = params.isManualTextPos;
    this.descriptionStatus = params.descriptionStatus || defaultDescriptionStatus;
    if (this.logicalElement) {
      const father = this.logicalElement.opmModel.getVisualElementById(params.fatherObjectId);
      this.fatherObject = father ? father : params.fatherObjectId;
    }
  }

  setParams(params) {
    super.setParams(params);
    const cc = this.logicalElement.opmModel.getCurrentConfiguration();
    if (cc && cc[this.logicalElement.lid] && cc[this.logicalElement.lid].value !== 0)
      delete params['fill'];
    // preventing the hovered color from being saved if error occurs
    if (params.fill && params.fill === '#E1E6EB')
      params.fill = this.fill ? this.fill : '#FFFFFF';
    if (params.fill)
      this.fill = params.fill;
    this.xPos = params.xPos;
    this.yPos = params.yPos;
    this.width = params.width;
    this.height = params.height;
    this.refX = params.refX;
    this.refY = params.refY;
    this.xAlign = params.xAlign;
    this.yAlign = params.yAlign;
    this.textAnchor = params.textAnchor;
    this.textWidth = params.textWidth;
    this.textHeight = params.textHeight;
    this.ports = params.ports;
    this.isManualTextPos = params.isManualTextPos || this.isManualTextPos;
  }

  setDefaultStyleFields() {
    this.fill = '#FFFFFF';
    this.refX = 0.5;
    this.refY = 0.5;
    this.xAlign = 'middle';
    this.yAlign = 'middle';
    this.textAnchor = 'middle'
    this.textWidth = '80%';
    this.textHeight = '80%';
    this.strokeWidth = 2;
    this.textColor = '#000002';
    this.textFontFamily = 'Arial';
    this.textFontSize = 14;
    this.textFontWeight = 600;
    this.isManualTextPos = false;
  }

  resetColors() {
    this.fill = '#FFFFFF';
  }

  getEntityParamsFromJsonElement(jsonElement) {
    let descriptionStatus = jsonElement.descriptionStatus;
    if (typeof descriptionStatus !== 'string') {
      descriptionStatus = defaultDescriptionStatus; // to cope with old version where this was an object.
    }
    const params = {
      fill: jsonElement.fill,
      xPos: jsonElement.xPos,
      yPos: jsonElement.yPos,
      width: jsonElement.width,
      height: jsonElement.height,
      refX: jsonElement.refX,
      refY: jsonElement.refY,
      xAlign: jsonElement.xAlign,
      yAlign: jsonElement.yAlign,
      ports: jsonElement.ports,
      textAnchor: jsonElement.textAnchor,
      textWidth: jsonElement.textWidth,
      textHeight: jsonElement.textHeight,
      fatherObjectId: jsonElement.fatherObjectId,
      isManualTextPos: jsonElement.isManualTextPos,
      descriptionStatus: descriptionStatus
    };
    return { ...super.getElementParamsFromJsonElement(jsonElement), ...params };
  }
  setPos(x, y) {
    this.xPos = x;
    this.yPos = y;
  }
  getPosition() {
    return { x: this.xPos, y: this.yPos };
  }

  getPortsInUse() {
    const ret = [];
    const links = this.getLinks();
    for (const link of links.outGoing)
      if (link.sourceVisualElementPort)
        ret.push(link.sourceVisualElementPort);
    for (const link of links.inGoing)
      if (link.targetVisualElementPort)
        ret.push(link.targetVisualElementPort);

    return ret;
  }
 // group 04
  pasteStyleParams(copiedParams) {
    if (copiedParams['fillColor']) {
      this.fill = copiedParams['fillColor'];
    }
    if (copiedParams['textColor']) {
      this.textColor = copiedParams['textColor'];
    }
    if (copiedParams['fontSize']) {
      this.textFontSize = copiedParams['fontSize'];
    }
    if (copiedParams['font']) {
      this.textFontFamily = copiedParams['font'];
    }
    if (copiedParams['borderColor']) {
      this.strokeColor = copiedParams['borderColor'];
    }
    if (copiedParams['textAlign']) {
      this.textAnchor = copiedParams['textAlign'];
    }
    if (copiedParams['xPosition'] && copiedParams['yPosition']) {
      this.xAlign = copiedParams['xPosition'];
      this.yAlign = copiedParams['yPosition'];
    }
  }

  /**
   * group 04:
   * This function gets the dictionary that includes the desired style we copied from the source entity
   * and updates the target entity's style, using this dictionary('copiedParams').
   * @param copiedParams
   */
  // pasteStyleParams(copiedParams) {
  //   if (copiedParams['fillColor']) {
  //     this.fill = copiedParams['fillColor'];
  //   }
  //   if (copiedParams['textColor']) {
  //     this.textColor = copiedParams['textColor'];
  //   }
  //   if (copiedParams['fontSize']) {
  //     this.textFontSize = copiedParams['fontSize'];
  //   }
  //   if (copiedParams['font']) {
  //     this.textFontFamily = copiedParams['font'];
  //   }
  //   if (copiedParams['borderColor']) {
  //     this.strokeColor = copiedParams['borderColor'];
  //   }
  // }

  getAllLinks(): EntityLinks {
    const model: OpmModel = this.logicalElement.opmModel;
    const logical = this.logicalElement;
    const inGoing = new Array();
    let outGoing = new Array();
    let opds = model.getOpds(true);
    opds = [...opds, ...model.stereotypes.getStereoTypes().map(st => st.opd)];
    opds.forEach(opd => {
      const relevantLinks = opd.visualElements.filter(vis => vis instanceof OpmLink);
      const outLinks = relevantLinks.filter(vis => (<OpmLink>vis).sourceVisualElement && (<OpmLink>vis).sourceVisualElement.logicalElement === logical);
      outGoing = [...outGoing, ...outLinks];
      relevantLinks.forEach(vis => {
        const trgtElmnts = (<OpmLink>vis).targetVisualElements;
        for (let i = 0; i < trgtElmnts.length; i++) {
          const temp = trgtElmnts[i];
          if (temp.targetVisualElement && temp.targetVisualElement.logicalElement === logical) {
            inGoing.push(vis);
            break;
          }
        }
      });
    });
    return { inGoing, outGoing };
  }

  getAllLinksWith(other: OpmVisualEntity): EntityLinks {
    const all = this.getAllLinks();
    let inGoing = all.inGoing;
    const out = all.outGoing;
    const outGoing = new Array<OpmLink>();
    inGoing = inGoing.filter(lnk => {
      const current = <OpmRelation<OpmLink>>lnk.logicalElement;
      if (current.sourceLogicalElement.lid === other.logicalElement.lid)
        return true;
      return false;
    });
    out.forEach(vis => {
      const trgtElmnts = (<OpmLink>vis).targetVisualElements;
      for (let i = 0; i < trgtElmnts.length; i++) {
        const temp = trgtElmnts[i];
        if (temp.targetVisualElement.logicalElement.lid === other.logicalElement.lid) {
          outGoing.push(vis);
          break;
        }
      }
    });
    return { inGoing, outGoing };
  }

  getLinks(): EntityLinks {
    const model: OpmModel = this.logicalElement.opmModel;
    const opd = model.getOpdByElement(this);
    const inGoing = [];
    const outGoing = [];
    if (!opd)
      return { inGoing: [], outGoing: [] };
    for (const element of opd.visualElements) {
      if (element.isLink()) {
        const link = element as OpmLink;
        if (link.source === this) {
          outGoing.push(link);
        }
        if (link.target === this) {
          inGoing.push(link);
        }
      }
    }
    return { inGoing, outGoing };
  }

  getChildrenLinks(): EntityLinks {
    const opd = this.logicalElement.opmModel.getOpdByElement(this);
    const inGoing = new Array<OpmLink>();
    const outGoing = new Array<OpmLink>();
    if (!opd)
      return { inGoing, outGoing };
    for (const visual of opd.visualElements.filter(v => v instanceof OpmLink)) {
      const link = visual as OpmLink;
      if (link.source.fatherObject && link.source.fatherObject === this)
        outGoing.push(link);
      else if (link.target.fatherObject && link.target.fatherObject === this)
        inGoing.push(link);
    }
    return { inGoing, outGoing };
  }

  getLinksWith(other: OpmVisualEntity): EntityLinks {
    const opd = this.logicalElement.opmModel.getOpdByElement(this);
    const inGoing = new Array<OpmLink>();
    const outGoing = new Array<OpmLink>();
    if (!opd)
      return { inGoing, outGoing };
    for (const visual of opd.visualElements) {
      if (visual instanceof OpmLink) {
        if (visual.sourceVisualElement === this && visual.targetVisualElements[0].targetVisualElement === other)
          outGoing.push(visual);
        else if (visual.sourceVisualElement === other && visual.targetVisualElements[0].targetVisualElement === this)
          inGoing.push(visual);
      }
    }
    return { inGoing, outGoing };
  }

  getLinksIncludingChildren(): EntityLinks {
    const inGoing = new Array<OpmLink>();
    const outGoing = new Array<OpmLink>();
    inGoing.push(...<any>this.getLinks().inGoing);
    outGoing.push(...<any>this.getLinks().outGoing);
    for (const child of this.getChildren()) {
      inGoing.push(...<any>child.getLinks().inGoing);
      outGoing.push(...<any>child.getLinks().outGoing);
    }
    return { inGoing, outGoing };
  }

  getLinksWithOtherAndItsChildren(other: OpmVisualEntity): EntityLinks {
    const inGoing = new Array<OpmLink>();
    const outGoing = new Array<OpmLink>();
    inGoing.push(...<any>this.getLinksWith(other).inGoing);
    outGoing.push(...<any>this.getLinksWith(other).outGoing);
    for (const child of other.getChildren()) {
      inGoing.push(...<any>this.getLinksWith(child).inGoing);
      outGoing.push(...<any>this.getLinksWith(child).outGoing);
    }
    return { inGoing, outGoing };
  }

  getChildren() {
    return [];
  }

  canBeRemoved(): boolean {
    return canBeRemoved(this, this.logicalElement as OpmLogicalEntity<OpmVisualEntity>);
  }

  public remove(): ReadonlyArray<OpmVisualElement> {
    const ret = super.remove();
    return [].concat(ret).concat(remove(this, this.logicalElement as OpmLogicalEntity<OpmVisualEntity>));
  }

  public setReferencesFromJson(json: any, map: ElementsMap<OpmVisualElement>): void {
    this.fatherObject = map.get(json.fatherObjectId);
  }

  bringMissingFundamentals(type: linkType): { links: Array<OpmLink>, entities: Array<OpmVisualEntity>, foldedOutEntities: Array<OpmVisualEntity>, foldedOutlinks: Array<OpmLink> } {
    const created = { links: [], entities: [], foldedOutEntities: [], foldedOutlinks: [] };
    const model = this.logicalElement.opmModel;
    const toOpd = model.getOpdByThingId(this.id);
    const that = this as any;
    if (this.isSemiFolded()) {
      const relationsToFoldOut = that.semiFolded.filter(vis => (<any>vis).isFoldedUnderThing().triangleType === type)
      for (const folded of relationsToFoldOut) {
        const ret = model.foldOutFundamentalRelation(folded);
        created.foldedOutlinks.push(...ret.createdLinks);
        created.foldedOutEntities.push(...ret.createdEntities, ...ret.removed);
        if (ret.createdEntities.length === 1 && ret.createdEntities[0].constructor.name.includes('Object'))
          (<any>ret.createdEntities[0]).expressAll();
      }
      that.arrangeInnerSemiFoldedThings();
    }
    let linksToBring = removeDuplicationsInArray(this.getAllLinks().outGoing.filter(
      l => l.type === type).map(visLink => visLink.logicalElement));
    linksToBring = linksToBring.filter(l => !(<any>l).visualElements.find(v => model.getOpdByThingId(v.id) === toOpd));
    for (const link of linksToBring) {
      if ((<OpmRelation<OpmLink>>link).visualElements.some(v => v.target.belongsToSubModel)) {
        continue;
      }
      const logicalTarget = (<any>link).targetLogicalElements[0];
      let visTargetAtOpd = logicalTarget.visualElements.find(v => model.getOpdByThingId(v.id) === toOpd);
      if (!visTargetAtOpd) {
        if (logicalTarget.constructor.name.includes('State')) {
          const father = model.bringVisualToOpd(logicalTarget.parent as any, toOpd) as any;
          father.expressAll();
          created.entities.push(father);
          visTargetAtOpd = logicalTarget.visualElements.find(vis => model.getOpdByThingId(vis.id) === toOpd);
        } else {
          visTargetAtOpd = model.bringVisualToOpd(logicalTarget as any, toOpd);
          created.entities.push(visTargetAtOpd);
          if (visTargetAtOpd.constructor.name.includes('Object'))
            (<any>visTargetAtOpd).expressAll();
        }
      }
      const linkParams = { type: (<any>link).linkType, connection: linkConnectionType.systemic };
      // const ret = model.connect(this, visTargetAtOpd, linkParams);
      // if (ret.success)
      //   created.links.push(...ret.created);
      created.links.push(model.links.connect(this, visTargetAtOpd, linkParams));
    }
    if (type === linkType.Aggregation) {
      let allChildren = [];
      for (const vis of this.logicalElement.visualElements)
        allChildren.push(...((<any>vis).children ? (<any>vis).children : []));
      allChildren = removeDuplicationsInArray(allChildren.map(child => child.logicalElement).filter(
        log => log.constructor.name === this.logicalElement.constructor.name));
      for (const logicalChild of allChildren) {
        let childAtOpd = logicalChild.visualElements.find(v => model.getOpdByThingId(v.id) === toOpd);
        if (childAtOpd && this.getAllLinksWith(childAtOpd).outGoing.find(lnk => lnk.type === linkType.Aggregation))
          continue;
        if (!childAtOpd) {
          childAtOpd = model.bringVisualToOpd(logicalChild as any, toOpd);
          if (childAtOpd.constructor.name.includes('Object'))
            childAtOpd.expressAll();
          created.entities.push(childAtOpd);
        }
        const ret = model.connect(this, childAtOpd, { type: linkType.Aggregation, connection: linkConnectionType.systemic })
        if (ret.success)
          created.links.push(...ret.created);
      }
    }
    if (!this.constructor.name.includes('State'))
      toOpd.beautify(this as any);
    return created;
  }

  generateHalo() {
    return this.getHaloHandles();
  }

  getHaloHandles() {
    return ['remove'];
  }

  isSemiFolded() {
    return false;
  }

  public isTimeDuration(): boolean {
    return (<OpmLogicalState>this.logicalElement).isTimeDuration();
  }

  public isInzoomed(): boolean {
    return false;
  }

  public isInzoomedForTooltip(): boolean {
    return false;
  }

  public isUnfolded(): boolean {
    return false;
  }

  public isUnfoldedForTooltip(): boolean {
    return false;
  }

  toggleManualTextPos() {
    this.isManualTextPos = !this.isManualTextPos;
  }

  public canModifyText(): boolean {
    return true;
  }

}

function remove(visual: OpmVisualEntity, logical: OpmLogicalEntity<OpmVisualEntity>): ReadonlyArray<OpmVisualElement> {
  const elements = new Array<OpmVisualElement>();

  visual.getAllLinks().inGoing.filter(l => l.targetVisualElements[0].targetVisualElement === visual).forEach(v => elements.push(...v.remove()));
  visual.getAllLinks().outGoing.filter(l => l.sourceVisualElement === visual).forEach(v => elements.push(...v.remove()));

  return elements;
}

function canBeRemoved(visual: OpmVisualEntity, logical: OpmLogicalEntity<OpmVisualEntity>): boolean {
  return true;
}
