import { Component, OnInit, Optional, Inject } from '@angular/core';
import {
  MatLegacyDialogRef as MatDialogRef,
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA,
  MatLegacyDialog as MatDialog
} from '@angular/material/legacy-dialog';
import {UserService} from '../../../rappid-components/services/user.service';
import {validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {ConfirmDialogDialogComponent} from '../../confirm-dialog/confirm-dialog';

@Component ({
  selector: 'generative-ai-update-key-dialog',
  templateUrl: 'generative-ai-update-key.html',
  styleUrls: ['generative-ai-update-key.css']
})
export class GenerativeAIUpdateKeyDialogComponent implements OnInit {

  loggedUser: any;
  tooltip: string;

  constructor(
    public dialogRef: MatDialogRef<GenerativeAIUpdateKeyDialogComponent>,
    private userService: UserService,
    private _dialog: MatDialog) {
  }

  ngOnInit() {
    this.loggedUser = this.userService.user?.userData;
    this.tooltip = 'This will reset your API Key of Gemini Generative AI';
  }

  async updateUserAPIKey(apiKey: string) {
    if (!apiKey) {
      validationAlert('The API KEY can not be empty', 3.5, 'Error');
      return;
    }
    await this.userService.updateUserGenAIApiKey(this.loggedUser.uid, apiKey);
    validationAlert('Successfully updated the API KEY', null, 'Success');
    this.dialogRef.close();
  }

  async resetUserKey() {
    const confirmDialog = this._dialog.open(ConfirmDialogDialogComponent, {
      height: '245px',
      width: '550px',
      data: {
        message: 'Warning: This will reset your API Key of Gemini Generative AI.\n\n' +
          '(OPCloud will attempt to use the organizational API key if no personal API key is available.)\n\n' +
          'Are you sure?',
        closeFlag: false,
        okName: 'Yes',
        closeName: 'No'
      },
    });
    confirmDialog.afterClosed().subscribe((data) => {
      if (data) {
        this.userService.resetUserGenAIApiKey().then(_ =>
          validationAlert('Reset API Key finished Successfully', 4, 'Success'));
      }
    });
    this.dialogRef.close();
  }

  closeAPIKeyDialog() {
    this.dialogRef.close();
  }
}
