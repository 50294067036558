import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {checkImageURL, OPCloudUtils, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {UntypedFormControl} from "@angular/forms";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {StorageService} from "../../rappid-components/services/storage.service";
import {UserService} from "../../rappid-components/services/user.service";
import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";
import {OpmVisualThing} from "../../models/VisualPart/OpmVisualThing";
import {ImagesPoolContainer} from "../images-pool-container/images-pool-container";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {BackgroundImageState} from "../../models/VisualPart/backgroundImageEnum";

@Component ({
  selector: 'opcloud-background-photo-dialog',
  templateUrl: 'background-photo-dialog.html',
  styleUrls: ['background-photo-dialog.css']
})
export class BackgroundPhotoDialogComponent implements OnInit {

  private entity;
  private url: string;
  private separatorKeysCodes: number[] = [ENTER, COMMA];
  private tagsCtrl = new UntypedFormControl('');
  private imageTags = [];
  private showTagsSection = false;
  private hadURL = false;
  private poolType: 'private' | 'org' | 'global' = 'private';

  constructor(
    @Optional() public dialogRef: MatDialogRef<BackgroundPhotoDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private readonly storage: StorageService, private userService: UserService, private init: InitRappidService) {
    if (OPCloudUtils.isInstanceOfDrawnThing(this.data.entity))
      this.entity = this.data.entity;
    this.url = this.entity?.attr('image/xlinkHref') || this.entity?.getVisual().logicalElement.getBackgroundImageUrl() || '';
    if (this.url === 'assets/SVG/redx.png')
      this.url = this.entity.getVisual().logicalElement.getBackgroundImageUrl() || '';
    if (this.url.length)
      this.hadURL = true;
    if (this.data.cameFromPool)
      this.showTagsSection = true;
  }

  ngOnInit() {
    if (!this.data.closeName) { this.data.closeName = 'CLOSE'; }
    if (!this.data.okName) { this.data.okName = 'OK'; }
    if (this.data.closeFlag === true) {
      const closeButt = document.getElementById('closeButton');
      closeButt.style.display = 'none';
    }
    if (this.url?.length > 0)
      this.previewImage();
  }



  async saveBackgroundImage() {
    if (!this.url.startsWith('http') || !this.isUrlEndsWithImageSuffix())
      return validationAlert('This url is invalid.');
    checkImageURL(this.url).then(res => {
      const visuals = this.entity.getVisual().logicalElement.visualElements;
      for (const vis of visuals) {
        (<OpmVisualThing>vis).showBackgroundImage = BackgroundImageState.TEXTANDIMAGE;
        if (this.entity.graph.getCell(vis.id))
          this.entity.graph.getCell(vis.id).setBackgroundImage(this.url);
      }
      this.dialogRef.close();
    }).catch(err => {
      validationAlert('This url is invalid.');
    });
  }

  onPaste($event) {
    const this_ = this;
    setTimeout(function() {
      this_.url = $event.target.value;
      }, 10);
  }

  changedInputText($event) {
    this.url = $event.target.value;
  }

  isPreviewDisabled() {
    if (!this.url.startsWith('http') || !this.isUrlEndsWithImageSuffix())
      return true;
  }

  previewImage() {
    (<HTMLImageElement>$('#imgPreview')[0]).src = this.url;
    (<HTMLImageElement>$('#imgPreview')[0]).hidden = false;
  }

  imageError($event) {
    if (this.url !== '' && $event.target.srcset !== '') {
      validationAlert('This url is invalid.');
      (<HTMLImageElement>$('#imgPreview')[0]).hidden = true;
    }
  }

  changePoolType($event) {
    this.poolType = $event.target.value;
  }

  saveToPool() {
    if (this.imageTags.length === 0) {
      this.showTagsSection = true;
      this.dialogRef.updateSize('500px', '650px');
      return;
    }
    checkImageURL(this.url).then(() => {
      this.storage.saveImageToPool(this.poolType, this.url, this.imageTags)
        .then(res => validationAlert('Image was successfully added to the pool.'))
        .catch(err => console.log(err));
    }).catch(() => {
      validationAlert('This url is invalid.');
    });
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value)
      this.imageTags.push(value);
    event.input.value = '';
    this.tagsCtrl.setValue(null);
  }

  removeTag(tag: string): void {
    const index = this.imageTags.indexOf(tag);
    if (index >= 0) {
      this.imageTags.splice(index, 1);
    }
  }

  isUrlEndsWithImageSuffix(): boolean {
    return this.url?.endsWith('.jpeg') || this.url?.endsWith('.jpg') || this.url?.endsWith('.png') || this.url?.endsWith('.svg') || this.url?.endsWith('.bmp') || this.url?.endsWith('.gif');
  }

  getInsertSaveText() {
    if (this.hadURL)
      return 'Save';
    return 'Insert';
  }

  shouldShowSaveInsertButton() {
    return !!this.entity && OPCloudUtils.isInstanceOfDrawnThing(this.entity);
  }

  remove() {
    const visual = this.entity.getVisual() as OpmVisualThing;
    const logical = visual.logicalElement as OpmLogicalThing<OpmVisualThing>;
    logical.setBackgroundImage('');

    for (const vis of logical.visualElements)
      if (this.entity.graph?.getCell(vis.id))
        this.entity.graph?.getCell(vis.id).updateView(vis);

    this.dialogRef.close();
  }

  isImageEditable() {
    if (!this.entity)
      return true;

    const logical = this.entity.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.equivalentFromStereotypeLID || logical.getStereotype())
      return false;

    return true;
  }

  openPoolManagement() {
    this.dialogRef.afterClosed().toPromise().then(() => {
      this.init.dialogService.openDialog(ImagesPoolContainer, 675, 900, {});
    });
    this.dialogRef.close();
  }
}
