import {OpmEntity} from './OpmEntity';

export class UrlsUtils {

  lastOpenedUrlIndex = 0;
  lastOpenedUrlWindow = undefined;

  constructor(private opmEntity: OpmEntity) {  }

  openNextLink() {
    if (!this.opmEntity.hasURLs()) { return; }
    const urls = this.opmEntity.URLarray;
    this.lastOpenedUrlIndex = this.lastOpenedUrlIndex % urls.length; // round-robin
    if (this.lastOpenedUrlWindow) {
      (<any>this.lastOpenedUrlWindow).close(); }
    const url = urls[this.lastOpenedUrlIndex].url;
    this.lastOpenedUrlWindow = window.open(url, 'Opcloud Link', 'width = 1000, height = 1000');
    this.lastOpenedUrlIndex += 1;
  }

  resetCounter() {
    this.lastOpenedUrlIndex = 0;
  }
}
