import {joint, _} from '../../../configuration/rappidEnviromentFunctionality/shared';
  export class OpmLinkRappid extends joint.shapes.standard.Link.define('app.Link', {
    attrs: {
      line: {
        // Alon: to remove the target arrowHead
        targetMarker: {
          type: 'path',
          d: '',
          stroke: 'none'
        }
      },
      wrapper: {
        connection: true,
        strokeWidth: 15,
        strokeLinejoin: 'round'
      }
    },

  }) {
    afterRender(init) {}
  }
