import {
  getInitRappidShared,
  isNumber,
  OPCloudUtils,
  validationAlert,
  vectorizer
} from '../rappidEnviromentFunctionality/shared';
import { OpmVisualProcess } from '../../models/VisualPart/OpmVisualProcess';
import { code, LinkLogicalConnection, linkType, valueType } from '../../models/ConfigurationOptions';
import { OpmObject } from "../../models/DrawnPart/OpmObject";
import { OpmLogicalObject } from "../../models/LogicalPart/OpmLogicalObject";
import { OpmLogicalProcess } from "../../models/LogicalPart/OpmLogicalProcess";
import { FuncDictionary } from './FuncDictionary';
import * as FileSaver from 'file-saver';
import { OpmProceduralLink } from "../../models/VisualPart/OpmProceduralLink";
import { OpmVisualObject } from "../../models/VisualPart/OpmVisualObject";
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { OpmLink } from '../../models/VisualPart/OpmLink';
import { OpmVisualState } from '../../models/VisualPart/OpmVisualState';
import { UserDefinedFunctionExecutor } from './user-defined-function-executor';
import { ExternalFunctionExecutor } from './external-function-executor';
import { RosFunctionExecutor } from './ros-function-executor';
import { MqqtFunctionExecutor } from './mqqt-function-executor';
import { getAllParents, handleExeExceptions, hasWhiteSpaces, textToName } from './computationalPartUtils';
import { HttpCommunicator, WebSocketCommunicator } from './communication-object';
import { OpmProceduralRelation } from '../../models/LogicalPart/OpmProceduralRelation';
import * as fs from 'file-saver';
import {EnterValueDialogComponent} from '../../dialogs/enterValue/enter-value';
import {OpmLogicalState} from '../../models/LogicalPart/OpmLogicalState';
import {PythonFunctionExecutor} from './python-function-executor';
import {SqlFunctionExecutor} from './sql-function-executor';
import {GenAIFunctionExecutor} from './genAI-function-executor';

export let computationalObjectForExport = [];

export function setComputationalObjectForExport(list) {
  computationalObjectForExport = list;
}

export let runner = { stop: false };

export function updateProcess(drawnProcess, initRappid) {
  const visualCell = initRappid.opmModel.getVisualElementById(drawnProcess.get('id'));
  // value is the chosen function
  const value = drawnProcess.attr('value/value');
  drawnProcess.attributes.attrs.value.isComputational = true;
  let newText = drawnProcess.attr('text/textWrap/text');
  let functionTooltip = null;
  if (value === 'None') {
    if (newText.lastIndexOf('()') !== -1) {
      newText = newText.replace('()', '');
      drawnProcess.attr({ text: { textWrap: { text: (newText.trim()) } } });
    }
  } else {
    if (newText.lastIndexOf('()') === -1) {
      drawnProcess.attr({ text: { textWrap: { text: (newText + ' ()') } } });
    }
    if (!drawnProcess.CanBeComputational()) {
      drawnProcess.toggleEssence(visualCell);
    }
    if (value === 'userDefined') {
      functionTooltip = drawnProcess.get('userDefinedFunction').functionInput;
    } else if (value === 'userPythonDefined') {
      functionTooltip = drawnProcess.get('userPythonDefinedFunction').functionInput.code;
    }  else if (value === 'userGenAIDefined') {
      functionTooltip = drawnProcess.get('userGenAIDefinedFunction').functionInput.code;
    } else if (value === 'ros') {
      if (drawnProcess.get('ros').ROStopicwhat != 'script') {
        functionTooltip = drawnProcess.get('ros').ROStopicwhat + ' to ROS topic: ' + drawnProcess.get('ros').ROStopic + ' with data type: ' + drawnProcess.get('ros').ROStopic_type;
      } else {
        functionTooltip = 'Running ROS script:' + drawnProcess.get('ros').ROS_Script;
      }
    } else if (value === 'mqtt') {
      functionTooltip = drawnProcess.get('mqtt').MQTTtopicwhat + ' to MQTT topic: ' + drawnProcess.get('mqtt').MQTTtopic;
    } else if (value === 'sql') {
      functionTooltip =  'SQL type : ' + drawnProcess.get('sql').SQLquerywhat ;
    } else {
      if (value === 'external') {
        functionTooltip = 'Connected to API at URL: ' + drawnProcess.get('externalFunction').functionUrl;
      } else {
        functionTooltip = value;
      }
    }
  }
  if (functionTooltip) {
    drawnProcess.attr({
      '.': {
        'data-tooltip': functionTooltip.replace(/(.{50})/g, '$1<br>'),
        'data-tooltip-position': 'left',
      }
    });
  } else {
    initRappid.paper.findViewByModel(drawnProcess).$el.removeAttr('data-tooltip');
  }
}
export function updateObject(drawnObject, initRappid, value, units, typeOfValue) {
  units = (units !== 'None') ? units : '';
  let valuedDrawnState;
  // object already has a state
  if ((drawnObject.attr('value/value') || drawnObject.attr('value/value') === '' || drawnObject.attr('value/value') === null) &&
    drawnObject.getEmbeddedCells().length > 0) {
    valuedDrawnState = drawnObject.getEmbeddedCells()[0];
  } else {  // it is not a computational object yet or the value was defined by execution but the state wasn't added yet
    valuedDrawnState = drawnObject.defineAsComputationalObject(initRappid);
  }

  const object = initRappid.opmModel.getVisualElementById(drawnObject.id) as OpmVisualObject;
  const logical = object.logicalElement as OpmLogicalObject;
  const wasSet = logical.setValue(value);
  if (wasSet == false) {
    throw new Error('bad value');
  }

  logical.units = units;
  logical.valueType = typeOfValue;

  const state = initRappid.opmModel.getVisualElementById(valuedDrawnState.id);
  state.logicalElement.text = value && !!value.toString ? value.toString() : value;

  drawnObject.updateView(object);
  valuedDrawnState.updateView(state);
  const view = valuedDrawnState.findView(initRappid.paper);
  if (view) {
    view.resize();
  }
}


export async function execute(linksArray, initRappid, opdId, runner: ExecutionRunner) {
  if (!globalRunTimeEnvironment.alreadyRanLinks) {
    initGlobalEnvironment();
  }
  const currentOpdElements = initRappid.opmModel.getOpd(opdId).visualElements;
  // get all processes in the OPD
  let currentOpdProcesses = currentOpdElements.filter(element => (element instanceof OpmVisualProcess));
  // sort processes from top to bottom
  // currentOpdProcesses = currentOpdProcesses.sort((p1, p2) => p1.getPosition().y - p2.getPosition().y);
  currentOpdProcesses = currentOpdProcesses.sort((p1, p2) => {
    const p1HasChildren = p1.children.length > 0;
    const p2HasChildren = p2.children.length > 0;

    // If p1 has children and p2 does not, p1 should come after p2
    if (p1HasChildren && !p2HasChildren) return 1;
    if (!p1HasChildren && p2HasChildren) return -1;

    // If both have or don't have children, sort by position
    return p1.getPosition().y - p2.getPosition().y;
  });
  // go over all processes
  await executeProcesses(currentOpdProcesses, linksArray, initRappid, opdId, runner);
}

function selectConditionalLinks(inbound: Array<any>, init) {
    const conditional = inbound.filter(item => item.condition);
    const nonConditional = inbound.filter(item => !item.condition);
    const dict = {};
    const ret = [];
    for (const item of conditional) {
      const visSource = init.opmModel.getVisualElementById(item.sourceId);
      if (OPCloudUtils.isInstanceOfVisualState(visSource)) {
        if (dict[visSource.fatherObject.id]) {
          dict[visSource.fatherObject.id].push(item);
        } else {
          dict[visSource.fatherObject.id] = [item];
        }
      } else {
        ret.push(item);
      }
    }
    for (const sourceObjectId of Object.keys(dict)) {
      const vis = init.opmModel.getVisualElementById(sourceObjectId);
      const items = dict[sourceObjectId];
      const currentState = vis.states.find(st => st.logicalElement.stateType?.toLowerCase().includes('current'));
      if (currentState) {
        const item = items.find(it => it.sourceId === currentState.id);
        if (item) {
          ret.push(item);
          continue;
        }
      } else {
       const randomIndex = Math.floor(items.length * Math.random());
       ret.push(items[randomIndex]);
      }
    }
    return [...ret, ...nonConditional];
}

async function executeProcesses(processes: Array<any>, linksArray, initRappid, opdId, runner: ExecutionRunner) {
  // If the user clicked on stopExecution during the execution
  for (let i = 0; i < processes.length; i++) {
    if (initRappid.Executing === false) {
      return;
    }
    let inbound = getInboundLinks(processes[i].id, initRappid.opmModel.getOpd(opdId), runner);
    if (shouldBeSkipped(processes[i], inbound)) {
      continue;
    }
    inbound = selectConditionalLinks(inbound, initRappid);
    await runner.processLinks(inbound, { init: initRappid, links: linksArray });
    const refineeInzoom = processes[i].getRefineeInzoom();
    const refineableOpd = initRappid.opmModel.getOpdByThingId(processes[i].getRefineable()?.id);
    // if it is zn in-zoomed process then need to go to the in-zoomed OPD and execute it first
    if (processes[i].refineeInzooming && opdId !== initRappid.opmModel.getOpdByThingId(processes[i].refineeInzooming.id)?.id) {
      //   storeInboundLinks(linksArray, currentOpdProcesses[i].id, initRappid.opmModel.getOpd(opdId));
      const inzoomedOpd = initRappid.opmModel.getOpdByThingId(processes[i].refineeInzooming.id);
      // recursive execution. now the in-zoomed graph will be executed
      await execute(linksArray, initRappid, inzoomedOpd.id, runner);
    } else if (processes[i].refineeUnfolding && opdId !== initRappid.opmModel.getOpdByThingId(processes[i].refineeUnfolding.id)?.id) {
      const unfolded = initRappid.opmModel.getOpdByThingId(processes[i].refineeUnfolding.id);
      await execute(linksArray, initRappid, unfolded.id, runner);
    } else if (initRappid.includeSubModelsInExecution && refineeInzoom && refineableOpd?.sharedOpdWithSubModelId && refineableOpd.parendId === opdId) {
      await execute(linksArray, initRappid, initRappid.opmModel.getOpdByThingId(processes[i].getRefineable()?.id).id, runner);
    } else if (processes[i].logicalElement.code !== code.Unspecified) {
      try {
        await compute(linksArray, processes[i], initRappid.opmModel.getOpd(opdId), initRappid, runner);
      } catch (e) {
        handleExeExceptions(e.toString(), processes[i]);
      }
      //   storeOutboundLinks(linksArray, currentOpdProcesses[i].id, initRappid.opmModel.getOpd(opdId));
    }
    const outbound = await getOutboundLinks(processes[i].id, initRappid.opmModel.getOpd(opdId), runner);
    globalRunTimeEnvironment.alreadyRanLinks.push(...outbound.map(item => item.linkId));
    await runner.processLinks(outbound, { init: initRappid, links: linksArray });
    await OPCloudUtils.waitXms(100);
    const continueLoop = runner.checkInzoomedInvocation(inbound) || runner.checkInzoomedInvocation(outbound);
    if (continueLoop && continueLoop.length) {
      i = -1;
      processes = continueLoop;
      // We are doing self-invocation, and we need to reset the iteration
      globalRunTimeEnvironment.processesToIgnore = [];
      globalRunTimeEnvironment.linksToRun = [];
    }
  }
}

/**
 * Getting an object and its value state, and set it as current, removing the current from other object's state
 * and render the state visual.
 * @param object the object with state to set to 'Current'
 * @param value the state name text value
 */
function setStateAsTypeCurrent(object: OpmVisualObject, value: any, updateVisual) {
  const runtimeObject = globalRunTimeEnvironment.objects.get((<any>object.logicalElement).text);
  if (runtimeObject !== undefined) {
    // Find the current state if non-computational object (valueType.None)
    const currentState = object.states.find(state => (state.getDisplayText() == value &&
      (<any>object.logicalElement).valueType === valueType.None) );
    if (currentState) {
      const objectOtherCurrentState = object.states.find(state => (state !== currentState
        && (<OpmLogicalState>state.logicalElement).stateType.includes('Current')));
      if (objectOtherCurrentState) {
        (<OpmLogicalState>objectOtherCurrentState.logicalElement).stateType =
          (<OpmLogicalState>objectOtherCurrentState.logicalElement).stateType.replace('Current', '');
        if (updateVisual) {
          const currentOtherStateVis = getInitRappidShared().graph.getCell(objectOtherCurrentState.id);
          if (currentOtherStateVis) {
            currentOtherStateVis.attr('.currentImg/display', 'none');
            currentOtherStateVis.updateView(currentState);
          }
        }
      }
      if (!(<OpmLogicalState>currentState.logicalElement).stateType.includes('Current')) {
        (<OpmLogicalState>currentState.logicalElement).stateType += 'Current';
      }
      if (updateVisual) {
        const currentStateVis = getInitRappidShared().graph.getCell(currentState.id);
        if (currentStateVis) {
          currentStateVis.attr('.currentImg/display', 'flex');
          currentStateVis.updateView(currentState);
        }
      }
    }
  }
}

async function getOutboundLinks(processId, opd, runner: ExecutionRunner): Promise<Array<any>> {
  const links = new Array<any>();

  const visProcess = opd.visualElements.find(v => v.id === processId);

  // get all links connected to the process
  const processLinks = opd.getThingLinks(processId);
  // get the outbound links
  let outboundLinks = processLinks.filter(link => (link.sourceVisualElement.id === processId));
  outboundLinks = outboundLinks.filter(link => (link instanceof OpmProceduralLink));
  // get effect links that are inbound because the value is taken anyway and we want the
  // token to run in a reverse direction
  const effectLinks = processLinks.filter(link =>
  ((link.logicalElement.linkType === linkType.Effect) &&
    (link.targetVisualElements[0].targetVisualElement.id === processId)));

  let userInputValue;
  if (!runner.isHeadless() && visProcess?.logicalElement.needUserInput && !visProcess.isComputational()) {
    const promiseVal = new Promise((resolve, reject) => {
      const confirmDialog = getInitRappidShared().dialogService.openDialog(EnterValueDialogComponent, 190, 220, {doNotClose: 'true'});
      confirmDialog.afterClosed().subscribe((data) => {
        resolve(data);
      });
    });
    userInputValue = await promiseVal;
  }

  for (let i = 0; i < outboundLinks.length; i++) {

    if (outboundLinks[i].target instanceof OpmVisualState) {
      const object = outboundLinks[i].target.fatherObject;
      setStateRunTimeValue(object, outboundLinks[i].target.logicalElement.text);
      setStateAsTypeCurrent(object, outboundLinks[i].target.logicalElement.text, runner.isShowCurrentState());
    } else if (outboundLinks[i].target instanceof OpmVisualObject) {
      const object = outboundLinks[i].target;
      if (object.states.length > 0) {
        // if no other link set the current state of the object at this specific run (specifically self-invocation run).
        const hasStateAlreadySet = globalRunTimeEnvironment.alreadyRanLinks
          .map(id => object.logicalElement.opmModel.getLogicalElementByVisualId(id))
          .find(logLink => logLink && logLink.lid !== outboundLinks[i].logicalElement?.lid && logLink.targetLogicalElements[0]?.lid === outboundLinks[i].target.logicalElement.lid);
        const currentState = globalRunTimeEnvironment.objects.get(object.logicalElement.text);
        // set new random value only if value is not computed or simulated (or if was not set yet).
        if (!currentState || (!hasStateAlreadySet && !currentState.isSimulatedOrComputed)) {
          let selectedState;
          if (userInputValue)
            selectedState = object.states.find(st => st.logicalElement.getBareName() === userInputValue);
          if (!selectedState) {
            const random = Math.floor(Math.random() * object.states.length);
            selectedState = object.states[random];
          }
          setStateRunTimeValue(object, selectedState.logicalElement.text);
          setStateAsTypeCurrent(object, selectedState.logicalElement.text, runner.isShowCurrentState());
        }
      }
    }

    links.push({
      linkId: outboundLinks[i].id, opd: opd, direction: 'normal',
      sourceId: processId, targetId: outboundLinks[i].targetVisualElements[0].targetVisualElement.id,
      orxor: outboundLinks[i].logicalElement.sourceLogicalConnection, orxorLinks: getOrXorOutboundLinks(outboundLinks[i])
    });
  }
  for (let i = 0; i < effectLinks.length; i++) {
    links.push({
      linkId: effectLinks[i].id, opd: opd, direction: 'reverse',
      sourceId: processId, targetId: effectLinks[i].sourceVisualElement.id,
      orxor: effectLinks[i].logicalElement.sourceLogicalConnection, orxorLinks: getOrXorOutboundLinks(effectLinks[i])
    });
  }

  let linksToReturn = [];
  for (const link of links) {

    if (link.orxor === null || link.orxor === undefined)
      linksToReturn.push(link);

    if (link.orxor === LinkLogicalConnection.Xor) {
      // if we already chose one of the links.
      if (linksToReturn.find(l => link.orxorLinks.includes(l.linkId)))
        continue;
      const chosenLinkId = pickOneLink(link.orxorLinks);
      const linkToAdd = links.find(lnk => lnk.linkId === chosenLinkId);
      if (linkToAdd)
        linksToReturn.push(linkToAdd);
      const notChosen = link.orxorLinks.filter(l => !linksToReturn.find(ln => ln.linkId === l));
      for (const notChose of notChosen) {
        const init = getInitRappidShared();
        const visTargetId = init.opmModel.getVisualElementById(notChose).target.id;
        globalRunTimeEnvironment.processesToIgnore.push(visTargetId);
      }
    }
    else if (link.orxor === LinkLogicalConnection.Or) {
      // if we already chose one of the links.
      if (linksToReturn.find(l => link.orxorLinks.includes(l.linkId)))
        continue;
      const chosenLinksIds = pickRandomNumberOfLinks(link.orxorLinks);
      for (const id of chosenLinksIds) {
        const linkToAdd = links.find(lnk => lnk.linkId === id);
        if (linkToAdd)
          linksToReturn.push(linkToAdd);
      }
      const notChosen = link.orxorLinks.filter(l => !linksToReturn.find(ln => ln.linkId === l));
      for (const notChose of notChosen) {
        const init = getInitRappidShared();
        const visTargetId = init.opmModel.getVisualElementById(notChose).target.id;
        globalRunTimeEnvironment.processesToIgnore.push(visTargetId);
      }
    }
  }

  return linksToReturn;
}

function getRandomInt(max) {
  return Math.floor(Math.random() * Math.floor(max));
}

function pickOneLink(arr: Array<any>) {
  const random = getRandomInt(arr.length);
  return arr[random];
}

function pickRandomNumberOfLinks(arr: Array<any>) {
  const ret = [];
  let randomLengthToReturn = getRandomInt(arr.length + 1);
  if (randomLengthToReturn === 0)
    randomLengthToReturn++;
  while (ret.length < randomLengthToReturn) {
    const randomIndex = getRandomInt(arr.length);
    if (!ret.includes(arr[randomIndex]))
      ret.push(arr[randomIndex]);
  }
  return ret;
}

function getOrXorInboundLinks(link: OpmLink) {
  const orxorType = (<OpmProceduralRelation>link.logicalElement).targetLogicalConnection;
  if (orxorType === undefined || orxorType === null || orxorType === LinkLogicalConnection.Not || !link.targetVisualElementPort)
    return [];
  const target = link.target;
  return target.getLinks().inGoing.filter(l => l.targetVisualElementPort &&
    l.targetVisualElementPort === link.targetVisualElementPort && l.type === link.type).map(lnk => lnk.id);
}


function getOrXorOutboundLinks(link: OpmLink) {
  const orxorType = (<OpmProceduralRelation>link.logicalElement).sourceLogicalConnection;
  if (orxorType === undefined || orxorType === null || orxorType === LinkLogicalConnection.Not || !link.sourceVisualElementPort)
    return [];
  const source = link.source;
  return source.getLinks().outGoing.filter(l => l.sourceVisualElementPort &&
    l.sourceVisualElementPort === link.sourceVisualElementPort && l.type === link.type).map(lnk => lnk.id);
}

function isInzoomedInvocation(link: OpmLink): boolean {
  // const target: any = link.targetVisualElements[0].targetVisualElement;
  // return (link.type === linkType.Invocation && target.getChildren && target.getChildren().includes(link.sourceVisualElement));
  return (link.type === linkType.Invocation);
}

function getInboundLinks(processId, opd, runner: ExecutionRunner): Array<any> {
  const links = new Array<any>();
  // get all links connected to the process
  const processLinks = opd.getThingLinks(processId);
  // get the inbound links
  let inboundLinks = processLinks.filter(link => (link.targetVisualElements[0].targetVisualElement.id === processId && !isInzoomedInvocation(link) && link.visible !== false));
  inboundLinks = inboundLinks.filter(link => (link instanceof OpmProceduralLink) && !globalRunTimeEnvironment.alreadyRanLinks.includes(link.id));
  // If we are starting from an Object with states, we need to check if it is current state, or randomize one, and set it up
  for (let i = 0; i < inboundLinks.length; i++) {
    let fromCurrentState = true; // Assuming we are from the current state, or general Object
    if (inboundLinks[i].source instanceof OpmVisualState) {
      const object = inboundLinks[i].source.fatherObject;
      // if no other link set the current state of the object at this specific run (specifically self-invocation run).
      const currentState = object.states.find(state => (<OpmLogicalState>state.logicalElement).stateType.includes('Current'));
      // set new random value only if value is not computed or simulated (or if was not set yet).
      if (!currentState) {
        let selectedState;
        if (inboundLinks) {
          selectedState = object.states.find(st => st.logicalElement.getBareName() === inboundLinks);
        }
        if (!selectedState) {
          const random = Math.floor(Math.random() * object.states.length);
          selectedState = object.states[random];
        }
        setStateRunTimeValue(object, selectedState.logicalElement.text);
        setStateAsTypeCurrent(object, selectedState.logicalElement.text, runner.isShowCurrentState());
        if (inboundLinks[i].source === selectedState) {
          fromCurrentState = true;
        }
      } else { // If the source state is NOT the current state
        if (inboundLinks[i].source !== currentState) {
          fromCurrentState = false;
          globalRunTimeEnvironment.processesToIgnore.push(processId);
        }
      }
    } else if (inboundLinks[i].source instanceof OpmVisualObject) {
      const object = inboundLinks[i].source;
      if (object.states.length > 0) {
        // if no other link set the current state of the object at this specific run (specifically self-invocation run).
        const currentState = object.states.find(state => (<OpmLogicalState>state.logicalElement).stateType.includes('Current'));
        // set new random value only if value is not computed or simulated (or if was not set yet).
        if (!currentState) {
          let selectedState;
          if (inboundLinks) {
            selectedState = object.states.find(st => st.logicalElement.getBareName() === inboundLinks);
          }
          if (!selectedState) {
            const random = Math.floor(Math.random() * object.states.length);
            selectedState = object.states[random];
          }
          setStateRunTimeValue(object, selectedState.logicalElement.text);
          setStateAsTypeCurrent(object, selectedState.logicalElement.text, runner.isShowCurrentState());
        }
      }
    }
    if (fromCurrentState) {
      links.push({
        linkId: inboundLinks[i].id,
        opd: opd,
        direction: 'normal',
        sourceId: inboundLinks[i].sourceVisualElement.id,
        targetId: processId,
        type: inboundLinks[i].type,
        condition: inboundLinks[i].logicalElement.condition,
        event: inboundLinks[i].logicalElement.event,
        orxor: inboundLinks[i].logicalElement.targetLogicalConnection || inboundLinks[i].logicalElement.sourceLogicalConnection,
        orxorLinks: getOrXorInboundLinks(inboundLinks[i]).concat(getOrXorOutboundLinks(inboundLinks[i]))
      });
    }
  }
  // get effect links that are outbound because the value is taken anyway and we want the
  // token to run in a reverse direction
  const effectLinks = processLinks.filter(link =>
    ((link.logicalElement.linkType === linkType.Effect) &&
      (link.sourceVisualElement.id === processId)));
  for (let i = 0; i < effectLinks.length; i++) {
    links.push({
      linkId: effectLinks[i].id, opd: opd, direction: 'reverse', type: inboundLinks[i].type, condition: inboundLinks[i].logicalElement.condition,
      event: inboundLinks[i].logicalElement.event, sourceId: effectLinks[i].targetVisualElements[0].targetVisualElement.id, targetId: processId,
      orxor: effectLinks[i].logicalElement.targetLogicalConnection, orxorLinks: getOrXorInboundLinks(effectLinks[i])
    });
  }
  const linksToReturn = [];
  for (const link of links) {

    if (link.orxor === null || link.orxor === undefined) {
      linksToReturn.push(link);
    }
    if (link.orxor === LinkLogicalConnection.Xor) {
      // if we already chose one of the links.
      if (linksToReturn.find(l => link.orxorLinks.includes(l.linkId))) {
        continue;
      }
      let chosenLinkId = (globalRunTimeEnvironment.linksToRun).find(lid => link.orxorLinks.includes(lid));
      if (!chosenLinkId) {
        chosenLinkId = pickOneLink(link.orxorLinks);
        globalRunTimeEnvironment.linksToRun = []; // We are now at a different set of links
      }
      const linkToAdd = links.find(lnk => lnk.linkId === chosenLinkId);
      if (linkToAdd) {
        linksToReturn.push(linkToAdd);
      }
      const init = getInitRappidShared();
      if (!globalRunTimeEnvironment.linksToRun.includes(chosenLinkId)) {
        globalRunTimeEnvironment.linksToRun.push(chosenLinkId);
      }
      const notToRunLinks = link.orxorLinks.filter(lnk => lnk !== chosenLinkId); // Marking all non chosen links to skip displaying
      for (const notChose of notToRunLinks) {
        const linkDetails = init.opmModel.getVisualElementById(notChose);
        const visTargetId = linkDetails.target.id;
        globalRunTimeEnvironment.alreadyRanLinks.push(linkDetails);
        if (link.condition) {
          globalRunTimeEnvironment.processesToIgnore.push(visTargetId);
        }
      }
    } else if (link.orxor === LinkLogicalConnection.Or) {
      // if we already chose one of the links.
      if (linksToReturn.find(l => link.orxorLinks.includes(l.linkId))) {
        continue;
      }
      const chosenLinksIds = pickRandomNumberOfLinks(link.orxorLinks);
      for (const id of chosenLinksIds) {
        const linkToAdd = links.find(lnk => lnk.linkId === id);
        if (linkToAdd) {
          linksToReturn.push(linkToAdd);
        }
      }
      const notToRunLinks = link.orxorLinks.filter(lnk => !chosenLinksIds.includes(lnk)); // Marking all non chosen links to skip displaying
      globalRunTimeEnvironment.alreadyRanLinks.push(...notToRunLinks.map(item => item.linkId));
      if (link.condition) {
        for (const notChose of notToRunLinks) {
          const init = getInitRappidShared();
          const visTargetId = init.opmModel.getVisualElementById(notChose).target.id;
          globalRunTimeEnvironment.processesToIgnore.push(visTargetId);
        }
      }
    }
  }

  return linksToReturn;
}

export async function compute(linksArray, OpmVisualProcess, opd, initRappid, runner: ExecutionRunner) {
  const parents = getAllParents(OpmVisualProcess);
  // get effect links. effect links should be added both to inbound and outbound links
  // because the meaning of effect link is that it gets a value and results a value.
  let effectLinks = opd.getThingLinks(OpmVisualProcess.id).filter(link => ((link.logicalElement.linkType === linkType.Effect)));
  for (const p of parents) {
    const effects = opd.getThingLinks(p.id).filter(link => ((link.logicalElement.linkType === linkType.Effect)));
    effectLinks = [...effectLinks, ...effects];
  }
  // get the inbound links
  let inboundLinks = opd.getThingLinks(OpmVisualProcess.id).filter(link => (link.targetVisualElements[0].targetVisualElement.id === OpmVisualProcess.id));
  for (const p of parents) {
    const ins = opd.getThingLinks(p.id).filter(link => (link.targetVisualElements[0].targetVisualElement.id === p.id));
    inboundLinks = [...inboundLinks, ...ins];
  }
  // filter inbound links to include only consumption, instrument links
  inboundLinks = inboundLinks.filter(link => ((link.logicalElement.linkType === linkType.Instrument) ||
    (link.logicalElement.linkType === linkType.Consumption)));
  inboundLinks = inboundLinks.concat(effectLinks);
  const sc = initRappid.opmModel.getCurrentConfiguration();
  if (sc) {
    const doubledLinks = [];
    for (const inLink of inboundLinks) {
      const lid = inLink.source.logicalElement.lid;
      if (sc[lid] && (sc[lid].value || sc[lid].value === 0)) {
        const numericVal = Number(sc[lid].value) - 1;
        for (let u = 0; u < numericVal; u++)
          doubledLinks.push(inLink);
      }
    }
    inboundLinks = inboundLinks.concat(doubledLinks);
  }
  // sort all input links to have all connected objects ordered from left to right
  inboundLinks = inboundLinks.sort((l1, l2) => l1.sourceVisualElement.getPosition().x - l2.sourceVisualElement.getPosition().x);
  // get the outbound links
  let outboundLinks = opd.getThingLinks(OpmVisualProcess.id).filter(link => (link.sourceVisualElement.id === OpmVisualProcess.id));
  // filter outbound links to include only the result links
  outboundLinks = outboundLinks.filter(link => (link.logicalElement.linkType === linkType.Result));
  outboundLinks = outboundLinks.concat(effectLinks);
  const valuesArray = new Array();
  //  const units = inboundLinks[0].sourceVisualElement.logicalElement.units;
  const units = '';
  //const functionValue = OpmVisualProcess.logicalElement.insertedFunction;
  // if (OpmVisualProcess.logicalElement.code === code.PreDefined) {
  const cc = initRappid.opmModel.getCurrentConfiguration();
  for (let i = 0; i < inboundLinks.length; i++) {
    let src = inboundLinks[i].sourceVisualElement.logicalElement;
    if (OPCloudUtils.isInstanceOfVisualState(inboundLinks[i].sourceVisualElement))
      src = inboundLinks[i].sourceVisualElement.fatherObject.logicalElement;
    if (cc && cc[src.lid] && cc[src.lid].value === 0)
      continue;
    let sourceElementValue = src.value;
    const sourceElementUnit = src.units;
    let name = src.getDisplayText().trim();
    let alias = src.alias;

    if (src.getValidationModule) {
      const validation = src.getValidationModule();
      if (validation && validation.isActive() && sourceElementValue == 'value') {
        const def = validation.getDefault();
        if (def)
          sourceElementValue = def;
      }
    }

    // if its effect link then need to take the value when the link is from process to object direction as well
    if (!sourceElementValue && (inboundLinks[i].logicalElement.linkType === linkType.Effect)) {
      sourceElementValue = inboundLinks[i].targetVisualElements[0].targetVisualElement.logicalElement.value;
      name = inboundLinks[i].targetVisualElements[0].targetVisualElement.logicalElement.getName().trim();
      alias = inboundLinks[i].targetVisualElements[0].targetVisualElement.logicalElement.alias;
    }
    if (sourceElementValue && (sourceElementValue !== 'None')) {
      valuesArray.push({ sourceElementValue, sourceElementUnit, name, alias });
    }

    if (OpmVisualProcess.logicalElement.isTimeDuration())
      valuesArray.push({ name });
  }
  let resultValue;
  let resultUnit = '';
  const functionValue = OpmVisualProcess.logicalElement.insertedFunction;
  let userInputValue = undefined;
  if (OpmVisualProcess.logicalElement.needUserInput && OpmVisualProcess.getAllLinks().inGoing.find(l => l.type === linkType.Agent) && !runner.isHeadless()) {
    const promiseVal = new Promise((resolve, reject) => {
      const confirmDialog = initRappid.dialogService.openDialog(EnterValueDialogComponent, 190, 220, {doNotClose: 'true'});
      confirmDialog.afterClosed().subscribe((data) => {
        resolve(data);
      });
    });
    userInputValue = await promiseVal;
  }
  if (OpmVisualProcess.logicalElement.isSimulated()) {
    resultValue = OpmVisualProcess.logicalElement.getRandomValues(1)[0];
  } else if (OpmVisualProcess.logicalElement.code === code.PreDefined) {
    const pureValuesArray = new Array();
    const pureUnitsArray = new Array();
    const dropdownFunctionsDict = new FuncDictionary();
    valuesArray.forEach(value => pureValuesArray.push(value.sourceElementValue));
    valuesArray.forEach(value => pureUnitsArray.push(value.sourceElementUnit));

    // Finds selected dropdown function, and returns computed value using the predefined function
    // from the FuncDictonary array.
    for (let k = 0; k < dropdownFunctionsDict.funcArray.length; k++) {
      // go through the functions and find its type
      if (dropdownFunctionsDict.funcArray[k].funcName === functionValue) {
        if (dropdownFunctionsDict.funcArray[k].TypeElements === 'Average') {
          const numbersArray = pureValuesArray.map(item => +item);
          resultValue = numbersArray.reduce((a, b) => a + b);
          resultValue = resultValue / numbersArray.length;
          if (pureValuesArray.length > 1) {
            resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0], pureUnitsArray[1]);
          } else {
            resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0]);
          }
        } else if (dropdownFunctionsDict.funcArray[k].TypeElements === 'Single') {
          if (pureValuesArray.length > 1) {
            handleExeExceptions('Function ' + dropdownFunctionsDict.funcArray[k].funcName + ' only handles one element!');
          } else {
            resultValue = dropdownFunctionsDict.funcArray[k].func(pureValuesArray[0]);
            resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0]);
          }
        } else if (dropdownFunctionsDict.funcArray[k].TypeElements === 'Operator') {
          const numbersArray = pureValuesArray.map(item => +item);
          resultValue = numbersArray.reduce((a, b) => eval(String(a) + dropdownFunctionsDict.funcArray[k].func + String(b)));
          resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0], pureUnitsArray[1]);
        } else if (dropdownFunctionsDict.funcArray[k].TypeElements === 'Multiple') {
          const numbersArray = pureValuesArray.map(item => +item);
          resultValue = numbersArray.reduce((a, b) => dropdownFunctionsDict.funcArray[k].func(a, b));
          resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0], pureUnitsArray[1]);
        } else if (dropdownFunctionsDict.funcArray[k].TypeElements === 'String') {
          resultValue = pureValuesArray.reduce((a, b) => a + b);
          resultUnit = dropdownFunctionsDict.funcArray[k].resultUnit(pureUnitsArray[0], pureUnitsArray[1]);
        }
      }
    }
  } else if (OpmVisualProcess.logicalElement.code === code.UserDefined) {
    const userDefined = new UserDefinedFunctionExecutor(initRappid.opmModel, OpmVisualProcess, runner, globalRunTimeEnvironment, userInputValue);
    resultValue = await userDefined.execute(opd, OpmVisualProcess, valuesArray, functionValue);
    // resultValue = runUserDefinedFunction(valuesArray, functionValue);
  } else if (OpmVisualProcess.logicalElement.code === code.Python) {
    const pythonFunction = new PythonFunctionExecutor(initRappid.opmModel, new WebSocketCommunicator(initRappid.handlerPython, initRappid.activatePython), OpmVisualProcess, runner, globalRunTimeEnvironment, userInputValue);
    resultValue = await pythonFunction.execute(opd, OpmVisualProcess, valuesArray, functionValue);
  }  else if (OpmVisualProcess.logicalElement.code === code.GenAI) {
    const genAIFunction = new GenAIFunctionExecutor(initRappid.opmModel, OpmVisualProcess, runner, globalRunTimeEnvironment, userInputValue);
    resultValue = await genAIFunction.execute(opd, OpmVisualProcess, valuesArray, functionValue);
  } else if (OpmVisualProcess.logicalElement.code === code.SQL) {
    const sqlExecutor = new SqlFunctionExecutor(initRappid.opmModel, new WebSocketCommunicator(initRappid.handlerMYSQL, initRappid.activateMySQL));
    resultValue = await sqlExecutor.execute(opd, OpmVisualProcess, valuesArray, functionValue);
  } else if (OpmVisualProcess.logicalElement.code === code.External) {
    const external = new ExternalFunctionExecutor(initRappid.opmModel, new HttpCommunicator(initRappid.http));
    resultValue = await external.execute(opd, OpmVisualProcess, valuesArray, functionValue);
    // resultValue = await runExternalFunction(valuesArray, functionValue, initRappid);
  } else if (OpmVisualProcess.logicalElement.code === code.ROS) {
    const rosExecutor = new RosFunctionExecutor(initRappid.opmModel, new WebSocketCommunicator(initRappid.wshandlerROS, initRappid.activateROS));
    resultValue = await rosExecutor.execute(opd, OpmVisualProcess, valuesArray, functionValue);
    // resultValue = await runROSFunction(valuesArray, functionValue, initRappid);
  } else if (OpmVisualProcess.logicalElement.code === code.MQTT) {
    const mqttExecutor = new MqqtFunctionExecutor(initRappid.opmModel, new WebSocketCommunicator(initRappid.handlerMQTT, initRappid.activateMQTT));
    resultValue = await mqttExecutor.execute(opd, OpmVisualProcess, valuesArray, functionValue);
    // resultValue = await runMQTTFunction(valuesArray, functionValue, initRappid);
  }
  // calculates  maximum digit from right in the input values
  let maxRightDigits = 0;
  if (valuesArray.length === 0) {
    maxRightDigits = 5;
  } else {
    for (let i = 0; i < valuesArray.length; i++) {
      const rightDigits = digitsFromRight(valuesArray[i].sourceElementValue.toString());
      maxRightDigits = Math.max(maxRightDigits, rightDigits);
    }
  }
  if (!(resultValue == null || resultValue === undefined)) {
    for (let j = 0; j < outboundLinks.length; j++) {
      let targetElement = outboundLinks[j].targetVisualElements[0].targetVisualElement.logicalElement;
      if ((outboundLinks[j].logicalElement.linkType === linkType.Effect) &&
        (targetElement instanceof OpmLogicalProcess)) {
        targetElement = outboundLinks[j].sourceVisualElement.logicalElement;
      } else if (OPCloudUtils.isInstanceOfLogicalState(targetElement)) {
        targetElement = outboundLinks[j].targetVisualElements[0].targetVisualElement.fatherObject.logicalElement;
      }
      // if the target is a state then the process change a state of an object.
      // only if the target is an object a value can be assigned
      if ((targetElement instanceof OpmLogicalObject) && (targetElement.valueType !== valueType.None)) {
        if (targetElement.getSimulationParams().simulated)
          resultValue = targetElement.value;
        const typeOfValue = isNumber(resultValue) ? valueType.Number : valueType.String;
        targetElement.valueType = typeOfValue;
        targetElement.value = resultValue;
        // targetElement.units = resultUnit;
        targetElement.units = resultUnit ? resultUnit : targetElement.units;
        if (typeOfValue === valueType.Number)
          targetElement.value = targetElement.value.toFixed ? targetElement.value.toFixed(maxRightDigits).toString() : targetElement.value;
        //  targetElement.units = units;
      }
      if ((targetElement instanceof OpmLogicalObject) && (targetElement.states.length > 0)) {
        // TODO: Is this case necessary? Was written for non-comp object receiving info from a comp process.
        let object = outboundLinks[j].targetVisualElements[0].targetVisualElement;
        if (OPCloudUtils.isInstanceOfVisualProcess(object)) {
          object = outboundLinks[j].sourceVisualElement;
        }
        if (OPCloudUtils.isInstanceOfVisualState(object)) {
          object = object.fatherObject;
        }
        setStateRunTimeValue(object, resultValue, true);
        setStateAsTypeCurrent(object, resultValue, runner.isShowCurrentState());
      }
    }
  }
}

function busy_wait(sec: number) {
  const ms = sec * 1000;
  var start = new Date().getTime();
  var end = start;
  while (end < start + ms) {
    end = new Date().getTime();
  }
}
async function runROSFunction(valuesArray, ROSFunction, initRappid) {
  let x;
  const ws = initRappid.wshandlerROS;
  if (initRappid.activateROS) {
    if (ROSFunction.ROStopicwhat == "publish") {
      ws.send(JSON.stringify({
        what: ROSFunction.ROStopicwhat,
        topic: ROSFunction.ROStopic,
        data_type: ROSFunction.ROStopic_type,
        message: valuesArray[0].sourceElementValue
      }));
      x = "published";
    } else if (ROSFunction.ROStopicwhat == "subscribe") {
      ws.send(JSON.stringify({
        what: ROSFunction.ROStopicwhat,
        topic: ROSFunction.ROStopic,
        data_type: ROSFunction.ROStopic_type,
      }));
      x = await getROSmessage(ws);
    } else if (ROSFunction.ROStopicwhat == "service") {
      ws.send(JSON.stringify({
        what: ROSFunction.ROStopicwhat,
        topic: ROSFunction.ROStopic,
        data_type: ROSFunction.ROStopic_type,
        message: valuesArray[0].sourceElementValue
      }));
      x = "service";
    } else if (ROSFunction.ROStopicwhat == "script") {
      ws.send(JSON.stringify({
        what: ROSFunction.ROStopicwhat,
        ROSScript: ROSFunction.ROS_Script
      }));
      //x = "script";
      x = await getROSmessage(ws);
    }
  } else {
    x = '';
    validationAlert('No ROS connection established!', 2500, undefined, true);
  }
  return x;
}

async function getROSmessage(ws) {
  let value = new Promise(function (resolve, reject) {
    ws.onmessage = function (event) {
      resolve(event.data);
    };
    ws.onerror = function (err) {
      console.error("socket connection error : ", err);
      reject(err);
    };
  });
  return value.then(val => val);
}


async function runMQTTFunction(valuesArray, MQTTFunction, initRappid) {
  let x;
  const ws = initRappid.handlerMQTT;
  if (initRappid.activateMQTT) {
    if (MQTTFunction.MQTTtopicwhat == "publish") {
      ws.send(JSON.stringify({
        what: MQTTFunction.MQTTtopicwhat,
        topic: MQTTFunction.MQTTtopic,
        message: valuesArray[0].sourceElementValue
      }));
      x = "published";
    } else if (MQTTFunction.MQTTtopicwhat == "subscribe") {
      ws.send(JSON.stringify({
        what: MQTTFunction.MQTTtopicwhat,
        topic: MQTTFunction.MQTTtopic,
      }));
      x = await getMQTTmessage(ws);
    }
  } else {
    x = '';
    validationAlert('No MQTT connection established!', 2500, undefined, true);
  }
  return x;
}

async function getMQTTmessage(ws) {
  let value = new Promise(function (resolve, reject) {
    ws.onmessage = function (event) {
      resolve(event.data);
    };
    ws.onerror = function (err) {
      console.error("MQTT socket connection error : ", err);
      reject(err);
    };
  });
  return value.then(val => val);
}

async function runExternalFunction(valuesArray, externalFunction, initRappid) {
  let parametersValues = {};
  for (let i = 0; i < valuesArray.length; i++) {
    if (!hasWhiteSpaces(valuesArray[i].name)) { // no spaces in objects name
      parametersValues[(valuesArray[i].name).toLowerCase()] =
        valuesArray[i].sourceElementValue;
    }
    if (valuesArray[i].alias && valuesArray[i].alias.length && !hasWhiteSpaces(valuesArray[i].alias)) {
      parametersValues[(valuesArray[i].alias).toLowerCase()] =
        valuesArray[i].sourceElementValue;
    }
  }
  let URL = externalFunction.functionUrl;
  let urlParameters = externalFunction.urlParameters;
  let functionCall = '';
  let parameterSet = '';
  if (urlParameters) { //If the API for the external system in in the Text field
    const arr = new Array<{ key: string, value: string }>();
    const p = urlParameters.match(/\(([^,]*),([^)]*)\)/g);
    if (p) {
      for (const i of p) {
        const m = i.match(/\(([^.]*),([^.]*)\)/);
        if (m)
          arr.push({
            key: (parametersValues[m[1]] ? parametersValues[m[1]] : m[1]),
            value: (parametersValues[m[2]] ? parametersValues[m[2]] : m[2])
          });
      }
    }
    for (let i = 0; i < arr.length; i++) {
      parameterSet += encodeURIComponent(arr[i]['key']) + '=' + encodeURIComponent(arr[i]['value']);
      if (i != arr.length - 1) {
        parameterSet += '&';
      }
    }
    functionCall = URL + '?' + parameterSet;
  } else {
    functionCall = URL;
  }
  const x = await getExternalData(functionCall, initRappid);
  return x;
}

async function getExternalData(func, initRappid) {
  const x = initRappid.http.get(func);
  let value = x.take(1).toPromise();
  return value.then(val => val);// expecting the direct value. e.g. 39.
}


function runUserDefinedFunction(valuesArray, userDefinedFunction) {
  let functionParametersDeclaration = '';
  for (let i = 0; i < valuesArray.length; i++) {
    let currentVariableParameters = '';
    let currentValue = valuesArray[i].sourceElementValue;
    if (!isNumber(currentValue)) {
      currentValue = '\'' + currentValue + '\'';
    }
    if (!hasWhiteSpaces(valuesArray[i].name)) { // no spaces in objects name
      currentVariableParameters += ('let ' + valuesArray[i].name + '=' + currentValue + '; ');
    }
    if (valuesArray[i].alias && valuesArray[i].alias.length && !hasWhiteSpaces(valuesArray[i].alias)) {
      currentVariableParameters += ('let ' + valuesArray[i].alias + '=' + currentValue + ';');
    }
    functionParametersDeclaration += currentVariableParameters;
    if (currentVariableParameters === '') {
      handleExeExceptions('Check ' + valuesArray[i].name + '. It must have a name or an alias without spaces!');
    }
  }
  const functionInput = functionParametersDeclaration + '\n' + userDefinedFunction.functionInput;
  try {
    return Function(functionInput)();
  } catch (e) {
    handleExeExceptions(e.toString(), null, userDefinedFunction.functionInput);
  }
}

export function digitsFromRight(number) {
  const dotIndex = number.indexOf('.');
  if (dotIndex === -1) return 0;
  const numbersFromRight = number.substring(dotIndex + 1);
  return numbersFromRight.length;
}

export function showExecution(linksArray, linkIndex, initRappid, exportArray, fileName, downloadCSV) {
  $('#executionButton1').off('click');
  if (initRappid.Executing === false || initRappid.ExecutingPause === true) return;
  if (linkIndex >= linksArray.length) { // no more links in the array
    initRappid.elementToolbarReference.Executing = false;
    return;
  }
  if (linksArray[linkIndex].opd !== initRappid.opmModel.currentOpd) {   // need to switch the OPD
    initRappid.graphService.renderGraph(linksArray[linkIndex].opd, initRappid);
    initRappid.opmModel.currentOpd = linksArray[linkIndex].opd;
  }
  const graph = initRappid.graphService.graph;
  while (((linkIndex + 1) < linksArray.length) &&
    ((linksArray[linkIndex].targetId && (linksArray[linkIndex].targetId === linksArray[linkIndex + 1].targetId)) ||
      (linksArray[linkIndex].sourceId && (linksArray[linkIndex].sourceId === linksArray[linkIndex + 1].sourceId)))) {
    const token2 = vectorizer.V('circle', { r: 5, fill: 'green', stroke: 'red' });
    const currentLinkView = graph.getCell(linksArray[linkIndex].linkId).findView(initRappid.paper);
    const direction = linksArray[linkIndex].direction;
    currentLinkView.sendToken(token2.node, { duration: 1000 * (2.1 - initRappid.opmModel.getTokenRuntimeRatio()), direction: linksArray[linkIndex].direction }, function () {
      try {
        checkTargetForUpdate(initRappid, currentLinkView.model, direction);
      } catch (err) {
        validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
        linksArray = [];
      }
    });
    linkIndex++;
  }
  const token = vectorizer.V('circle', { r: 5, fill: 'green', stroke: 'red' });
  const currentLinkView = graph.getCell(linksArray[linkIndex].linkId) ? graph.getCell(linksArray[linkIndex].linkId).findView(initRappid.paper) : undefined;
  const direction = linksArray[linkIndex].direction;
  if (currentLinkView) {
    currentLinkView.sendToken(token.node, { duration: 1000 * (2.1 - initRappid.opmModel.getTokenRuntimeRatio()), direction: linksArray[linkIndex].direction }, function () {
      try {
        checkTargetForUpdate(initRappid, currentLinkView.model, direction);
      } catch (err) {
        validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
        linksArray = [];
      }
      // if ((linkIndex === linksArray.length - 1) && downloadCSV) {    // last token run
      //   exportValues(exportArray, fileName);
      // }
      // run the token on the next link
      if (initRappid.ExecutingPause === true) { // if pause button was clicked
        $('#executionButton1').click(function (e) { // resume execution event
          showExecution(linksArray, ++linkIndex, initRappid, exportArray, fileName, downloadCSV);
        });
      } else {
        showExecution(linksArray, ++linkIndex, initRappid, exportArray, fileName, downloadCSV)
      }
    });
  }
}

export function checkTargetForUpdate(initRappid, link, direction) {
  let targetElement = initRappid.graph.getCell(link.get('target').id);
  if (direction === 'reverse') targetElement = initRappid.graph.getCell(link.get('source').id);
  if (OPCloudUtils.isInstanceOfDrawnState(targetElement))
    targetElement = targetElement.getParentCell();
  if (targetElement instanceof OpmObject) {
    const logicalTargetElement = initRappid.opmModel.getLogicalElementByVisualId(targetElement.id);
    if (logicalTargetElement.value && (logicalTargetElement.value !== 'None')) {
      updateObject(targetElement, initRappid, logicalTargetElement.value, logicalTargetElement.units, logicalTargetElement.valueType);
    }
  }
}

// stores all values of computational objects in an array and object states current
export function copyAllObjectValues(valuesArray, initRappid) {
  valuesArray = [];
  for (let i = 0; i < initRappid.opmModel.logicalElements.length; i++) {
    if (initRappid.opmModel.logicalElements[i] instanceof OpmLogicalObject) {
      const currentObject = (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]);
      const value = currentObject.value;
      //   value = (value === 'None') ? 'value' : value;
      const valType = currentObject.valueType;
      const unit = currentObject.units;
      if (valType !== valueType.None) {
        valuesArray.push({
          name: currentObject.text,
          value: value === null ? 'value' : value,
          valType: valType,
          unit: unit,
          lid: (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]).lid,
          validationStatus: (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]).getValidationStatus(),
          currentState: '',
        });
      } else { // Check for object with states to save states status
        if ( currentObject.states.length > 0 && currentObject.text !== 'Type') {
          const currentTypeState = currentObject.states.find(state => state.stateType.includes('Current')
            && currentObject.valueType === valueType.None);
          let currentStateSave = '';
          if (currentTypeState) {
            currentStateSave = currentTypeState.getBareName();
          }
          valuesArray.push({
            name: currentObject.text,
            value: value,
            valType: valType,
            unit: unit,
            lid: (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]).lid,
            validationStatus: (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]).getValidationStatus(),
            currentState: currentStateSave,
          });
        }
      }
    }
  }
  return valuesArray;
}

// This function allows to create csv according to user preferences and not by default as copyAllObjectValues does.
export function copyAllObjectValuesAccordingToPreferences(valArray) {
  valArray = [];
  for (let i = 0; i < computationalObjectForExport.length; i++) {
    if (computationalObjectForExport[i] instanceof OpmLogicalObject) {
      const currentObject = (<OpmLogicalObject>computationalObjectForExport[i]);
      const value = currentObject.value;
      const valType = currentObject.valueType;
      const unit = currentObject.units;
      if (valType !== valueType.None) {
        valArray.push({
          name: currentObject.text,
          value: value,
          valType: valType,
          unit: unit,
          lid: currentObject.lid,
          validationStatus: currentObject.getValidationStatus(),
        });
      } else { // Check for object with states to save states status
        if (currentObject.states.length > 0 && currentObject.text !== 'Type') {
          const currentTypeState = currentObject.states.find(state => state.stateType.includes('Current')
            && currentObject.valueType === valueType.None);
          let currentStateSave = 'No Specific State';
          if (currentTypeState) {
            currentStateSave = currentTypeState.getBareName();
          }
          valArray.push({
            name: currentObject.text,
            value: currentStateSave,
            valType: valType,
            unit: unit,
            lid: currentObject.lid,
            validationStatus: 'regularState',
          });
        }
      }
    }
  }
  return valArray;
}

/**
 * helper method to set a state type as none after finishing simulation
 * @param objectOtherCurrentState
 * @param currentState
 * @param initRappid
 */
function updateStateRemovedTypeCurrent(objectOtherCurrentState, currentState, initRappid) {
  (<OpmLogicalState>objectOtherCurrentState.logicalElement).stateType =
    (<OpmLogicalState>objectOtherCurrentState.logicalElement).stateType.replace('Current', '');
  const currentOtherStateVis = getInitRappidShared().graph.getCell(objectOtherCurrentState.id);
  if (currentOtherStateVis) {
    currentOtherStateVis.attr('.currentImg/display', 'none');
    currentOtherStateVis.updateView(currentState);
    const view = currentOtherStateVis.findView(initRappid.paper);
    if (view) {
      view.resize();
    }
  }
}

/**
 * This method will restore the state type of an object
 * @param drawnObject
 * @param initRappid
 * @param valueData
 */
function updateRegularObjectCurrentState(drawnObject, initRappid, valueData: any) {
  const object = initRappid.opmModel.getVisualElementById(drawnObject.id) as OpmVisualObject;
  const currentState = object.states.find(state => state.getDisplayText() === valueData.currentState );
  if (currentState) {
    const objectOtherCurrentState = object.states.find(state => (state !== currentState
      && (<OpmLogicalState>state.logicalElement).stateType.includes('Current')));
    if (objectOtherCurrentState) {
      updateStateRemovedTypeCurrent(objectOtherCurrentState, currentState, initRappid);
    }
    (<OpmLogicalState>currentState.logicalElement).stateType += 'Current';
    const currentStateVis = getInitRappidShared().graph.getCell(currentState.id);
    if (currentStateVis) {
      currentStateVis.attr('.currentImg/display', 'flex');
      currentStateVis.updateView(currentState);
      const view = currentStateVis.findView(initRappid.paper);
      if (view) {
        view.resize();
      }
    }
  } else {
    const objectOtherCurrentState = object.states.find(state => (state !== currentState
      && (<OpmLogicalState>state.logicalElement).stateType.includes('Current')));
    if (objectOtherCurrentState) {
      updateStateRemovedTypeCurrent(objectOtherCurrentState, currentState, initRappid);
    }
  }
}

// gets an array with computational objects and their values and updates the objects in OpmModel
// to have these values
export function updateAllObjectValues(valuesArray, initRappid) {
  if (valuesArray?.length === 0) {
    return;
  }
  const currentOpmModelElements = initRappid.opmModel.logicalElements;
  for (let i = 0; i < currentOpmModelElements.length; i++) {
    if (currentOpmModelElements[i] instanceof OpmLogicalObject) {
      const logOb = currentOpmModelElements[i]; // logical object for updating
      const logObName = textToName((<OpmLogicalObject>logOb).text);
      // the name and value that were stored in the array
      // const valueData = valuesArray.filter(elm => (textToName(elm.name) === logObName))[0];
      const valueData = valuesArray.find(elm => elm.lid === logOb.lid);
      if (!valueData) {
        continue;
      }
      // visual elements of logOb that in the current OPD
      const currentOpdRelevantVisuals = new Array();
      for (let k = 0; k < initRappid.opmModel.currentOpd.visualElements.length; k++) {
        if (initRappid.opmModel.currentOpd.visualElements[k] instanceof OpmVisualObject) {
          if (valueData && initRappid.opmModel.currentOpd.visualElements[k].logicalElement.lid === valueData.lid) {
            currentOpdRelevantVisuals.push(initRappid.opmModel.currentOpd.visualElements[k]);
          }
        }
      }
      for (let j = 0; j < currentOpdRelevantVisuals.length; j++) {
        const drawnObject = initRappid.graph.getCell(currentOpdRelevantVisuals[j].id);
        try {
          if ((<OpmLogicalObject>currentOpmModelElements[i]).valueType !== valueType.None) {
            updateObject(drawnObject, initRappid, valueData.value, valueData.unit, valueData.valType);
          }
          updateRegularObjectCurrentState(drawnObject, initRappid, valueData);
        } catch (err) {
        }
        if ((<OpmLogicalObject>currentOpmModelElements[i]).valueType !== valueType.None) {
          (<OpmLogicalObject>logOb).value = valueData.value;
          (<OpmLogicalObject>logOb).units = valueData.unit;
        }

      }
      // if there are no visual instances in current opd then need to update other opds
      if (currentOpdRelevantVisuals.length === 0) {
        if ((<OpmLogicalObject>currentOpmModelElements[i]).valueType !== valueType.None) {
          (<OpmLogicalObject>logOb).value = valueData.value;
          (<OpmLogicalObject>logOb).units = valueData.unit;
        }
        if ( logOb.states.length > 0 ) {
          // currentState should have the pre simulation current state status
          const currentState = logOb.states.find(state => state.getDisplayText() === valueData.currentState );
          // objectOtherCurrentState is the current in simulation state - should be removed if different
          const objectOtherCurrentState = logOb.states.find(state => state.stateType.includes('Current'));
          if (objectOtherCurrentState) {
            if (objectOtherCurrentState !== currentState) {
              objectOtherCurrentState.stateType = objectOtherCurrentState.stateType.replace('Current', '');
            }
          }
          if (currentState && !currentState.stateType.includes('Current')) {
            currentState.stateType += 'Current';
          }
        }
      }
    }
  }
}

function shouldBeSkipped(process, inbound: Array<any>): boolean {
  if (globalRunTimeEnvironment.processesToIgnore.includes(process.id)) {
    return true;
  }
/*  for (const inner of inbound) {
    const link = inner.opd.visualElements.filter(l => l.id === inner.linkId)[0];
    if ((link.type === linkType.Instrument || link.type === linkType.Agent)) {
      if (!(link.source instanceof OpmVisualState)) {
        continue;
      }
      const state = link.source;
      const object = state.fatherObject;
      const objectInstance = globalRunTimeEnvironment.objects.get(object.logicalElement.text);
      if (!objectInstance && link.logicalElement.condition) {
        return true;
      }
      if (objectInstance && objectInstance.state != state.logicalElement.text) {
        return true;
      }
    }
  }*/
  return false;
}

export function prepArrayForExport(allValuesArray, run): Array<any> {
  const concatArray = [];
  const arrayOfNames = [];
  const arrayOfValues = [];

  let isInstancesRun = false;
  const init = getInitRappidShared();
  if (init.elementToolbarReference.runByConfigurations && init.opmModel.getCurrentConfiguration())
    isInstancesRun = true;
  // runToWrite = runToWrite + ' - Instance Ref#:' + init.opmModel.getCurrentConfiguration().refId;

  if (run === 1) {  // need to write names
    arrayOfNames.push(' ');     // add space for the run number
    if (isInstancesRun)
      arrayOfNames.push('Instance Ref#');
    for (let val of allValuesArray) {
      let name = textToName(val.name).replace(/\xA0/g, ' ');
      name = name.replace(/\n/g, ' ')
      arrayOfNames.push(name);
    }
    concatArray.push(arrayOfNames);
    concatArray.push('\n');
  }

  concatArray.push('Run #', run); // run number
  arrayOfValues.push(' ');     // add space for the run number

  if (init.elementToolbarReference.runByConfigurations && init.opmModel.getCurrentConfiguration())
    arrayOfValues.push(init.opmModel.getCurrentConfiguration().refId); // ref number

  for (let val of allValuesArray) { // values
    arrayOfValues.push('"' + val.value + '"');
    //arrayOfValues.push(val.value);
  }
  // concat the final array
  concatArray.push(arrayOfValues);
  concatArray.push('\n');
  return concatArray;
}

export function exportValues(exportArray, fileName) {
  // TODO: remove after 01/01/22 if still not used anymore.
  // const exportValuesFile = new Blob(exportArray, { type: 'text/csv' });
  // FileSaver.saveAs(exportValuesFile, fileName + '.csv'); // Save the exported file.
}

function getValidationColorByStatus(status) {

  if (status === 'value-set-invalid')
    return 'ff7474';
  if (status === 'value-set-valid')
    return '00ff85';
  if (status === 'value-not-set')
    return '73c7ff';
  if (status === 'regularState')
    return 'ffdb58';

  return 'FFFFFF';

}

export function exportExcel(runParams, fileName, init) {
  const titles = [' '];
  const runByConf = init.elementToolbarReference.runByConfigurations && init.opmModel.getCurrentConfiguration();
  if (runByConf)
      titles.push('Instance Ref#');
  titles.push(...runParams.allValuesArray.map(i => i.name));
  const ExcelJS = require('exceljs');
  const workbook = new ExcelJS.Workbook();
  workbook.created = new Date();
  workbook.modified = new Date();
  const worksheet = workbook.addWorksheet(fileName);
  worksheet.views = [{state:'normal'}];
  const titlesRow = worksheet.getRow(1);
  titlesRow.values = titles;

  worksheet.getColumn(1).width = 20;
  for (let i = 0; i < titles.length; i++) {
      worksheet.getColumn(i + 1).alignment = { vertical: 'center', horizontal: 'center' };
      if (i === 0)
         continue;
      worksheet.getColumn(i + 1).width = titles[i].length;
  }

  let rowIdx = 2;
  let dataIdx = 1;
  for (const runData of runParams.exportExcelData) {
      const currentRow = worksheet.getRow(rowIdx);
      currentRow.getCell(1).value = 'Run #' + dataIdx;
      let colIdx = 2;

      if (runByConf) {
          currentRow.getCell(2).value = runParams.refsIds[dataIdx - 1];
          colIdx = 3;
      }

      for (const item of runData) {
          currentRow.getCell(colIdx).value = item.value;
          const log = init.opmModel.getLogicalElementByLid(item.lid) as any;
          const color = getValidationColorByStatus(item.validationStatus) || 'FFFFFF';

          if (color === '73c7ff') {
              currentRow.getCell(colIdx).value = log.value !== 'value' ? log.value : log.computationModule.getRange();
          }

          if (color !== 'FFFFFF') {
            currentRow.getCell(colIdx).fill = {
              type: 'gradient',
              gradient: 'path',
              center:{left:0.5,top:0.5},
              stops: [
                { position:0, color: {argb: color}},
                { position:1, color: {argb: color}}
              ]
            };
          }
          colIdx++;
      }
      rowIdx++;
      dataIdx++;
  }
  workbook.xlsx.writeBuffer().then((data) => {
    let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
    fs.saveAs(blob, fileName + '.xlsx');
  });
}

export interface ExecutionRunner {
  processLinks(links: Array<any>, params: { init: InitRappidService, links: Array<any> }): Promise<any>;

  isHeadless(): boolean;

  isShowCurrentState(): boolean;

  checkInzoomedInvocation(outbound: Array<any>): Array<any>;
}

class AsyncRunner implements ExecutionRunner {

  public async processLinks(links: Array<any>, params: { init: InitRappidService, links: Array<any> }): Promise<any> {
    params.links.push(...links);
  }

  public isHeadless(): boolean {
    return false;
  }

  public isShowCurrentState(): boolean {
    return false;
  }

  public checkInzoomedInvocation(outbound): Array<any> {
    return undefined;
  }
}

export const asyncRunner = new AsyncRunner();

class SyncRunner implements ExecutionRunner {

  public async processLinks(links: Array<any>, params: { init: InitRappidService, links: Array<any> }): Promise<any> {
    return Promise.all(links.map(l => runToken(l, params.init)));
  }

  public isHeadless(): boolean {
    return false;
  }

  public isShowCurrentState(): boolean {
    return true;
  }

  public checkInzoomedInvocation(outbound): Array<any> {
    const events = outbound.filter(l => l.event);
    if (events.length > 0) { // This is a call with IN bound links to check for self invocation event
      const target = events[0].opd.visualElements.find(v => v.id === events[0].targetId);
      const children: Array<OpmVisualProcess> = target.getChildren().filter(element => (element instanceof OpmVisualProcess)).sort((p1, p2) => {
        const p1HasChildren = p1.children.length > 0;
        const p2HasChildren = p2.children.length > 0;

        // If p1 has children and p2 does not, p1 should come after p2
        if (p1HasChildren && !p2HasChildren) { return 1; }
        if (!p1HasChildren && p2HasChildren) { return -1; }

        // If both have or don't have children, sort by position
        return p1.getPosition().y - p2.getPosition().y;
      });
      children.push(target);
      return children;
    }
    const invocations = outbound.filter(l => l.opd.visualElements.find(v => v.id === l.linkId).type === linkType.Invocation).map(l => l.opd.visualElements.find(v => v.id === l.linkId));
    for (const invocation of invocations) {
      if (isInzoomedInvocation(invocation)) {
        const target = invocation.targetVisualElements[0].targetVisualElement;
        const children = target.getChildren().filter(element => (element instanceof OpmVisualProcess)).sort((p1, p2) => p1.getPosition().y - p2.getPosition().y);
        return children;
      }
    }
    return undefined;
  }
}

export const syncRunner = new SyncRunner();

class HeadlessRunner implements ExecutionRunner {
  public async processLinks(links: Array<any>, params: { init: InitRappidService, links: Array<any> }): Promise<any> {
    const p = new Promise((resolve, reject) => {
      if (params.init.ExecutingPause) {
        const interval = setInterval(function() {
          if (!params.init.ExecutingPause) {
            resolve(null);
            clearInterval(interval);
          }
        }, 300);
      }
      else
        resolve(null);
    });
    await p;
    params.links.push(...links);
  }

  public isHeadless(): boolean {
    return true;
  }

  public isShowCurrentState(): boolean {
    return false;
  }

  public checkInzoomedInvocation(outbound): Array<any> {
    const events = outbound.filter(l => l.event);
    if (events.length > 0) { // This is a call with IN bound links to check for self invocation event
      const target = events[0].opd.visualElements.find(v => v.id === events[0].targetId);
      const children: Array<OpmVisualProcess> = target.getChildren().filter(element => (element instanceof OpmVisualProcess)).sort((p1, p2) => {
        const p1HasChildren = p1.children.length > 0;
        const p2HasChildren = p2.children.length > 0;

        // If p1 has children and p2 does not, p1 should come after p2
        if (p1HasChildren && !p2HasChildren) { return 1; }
        if (!p1HasChildren && p2HasChildren) { return -1; }

        // If both have or don't have children, sort by position
        return p1.getPosition().y - p2.getPosition().y;
      });
      children.push(target);
      return children;
    }
    const invocations = outbound.filter(l => l.opd.visualElements.find(v => v.id === l.linkId).type === linkType.Invocation).map(l => l.opd.visualElements.find(v => v.id === l.linkId));
    for (const invocation of invocations) {
      if (isInzoomedInvocation(invocation)) {
        const target = invocation.targetVisualElements[0].targetVisualElement;
        const children = target.getChildren().filter(element => (element instanceof OpmVisualProcess)).sort((p1, p2) => p1.getPosition().y - p2.getPosition().y);
        return children;
      }
    }
    return undefined;
  }
}

export const headlessRunner = new HeadlessRunner();

export async function runToken(link, init: InitRappidService) {
  const token = vectorizer.V('circle', { r: 5, fill: 'green', stroke: 'red' });

  if (link.opd !== init.opmModel.currentOpd) {   // need to switch the OPD
    (<any>init).graphService.renderGraph(link.opd, init);
    init.opmModel.currentOpd = link.opd;
  }

  return new Promise((resolve, reject) => {
    const view = init.graph.getCell(link.linkId).findView(init.paper);
    view.sendToken(token.node, { duration: 1000 * (2.1 - init.opmModel.getTokenRuntimeRatio()), direction: link.direction }, function () {
      try {
        checkTargetForUpdate(init, view.model, link.direction);
      } catch (err) {
        validationAlert('Non valid value was given. Execution will stop.', 5000, 'error');
        init.elementToolbarReference.Executing = false;
      }
      if (init.ExecutingPause) {
        const interval = setInterval(function() {
          if (!init.ExecutingPause) {
            resolve(null);
            clearInterval(interval);
          }
        }, 300);
      }
      else
        resolve(null);
    });
  });
}

interface ObjectRunTimeInstance {
  visual: OpmVisualObject,
  state: string,
  isSimulatedOrComputed?: boolean
}

interface RunTimeEnvironment {
  objects: Map<string, ObjectRunTimeInstance>,
  alreadyRanLinks: Array<string | String>,
  processesToIgnore: Array<string | String>,
  linksToRun: Array<string | String>,
}

// Daniel: I'm so sorry for this global variable :(
const globalRunTimeEnvironment: RunTimeEnvironment = {
  objects: undefined,
  alreadyRanLinks: undefined,
  processesToIgnore: undefined,
  linksToRun: undefined
};

export function initGlobalEnvironment() {
  globalRunTimeEnvironment.objects = new Map<string, ObjectRunTimeInstance>();
  globalRunTimeEnvironment.alreadyRanLinks = [];
  globalRunTimeEnvironment.processesToIgnore = [];
  globalRunTimeEnvironment.linksToRun = [];
}

function setStateRunTimeValue(object: OpmVisualObject, value: any, isSimulatedOrComputed = false): void {
  // const object = outboundLinks[j].targetVisualElements[0].targetVisualElement;
  let runtimeObject = globalRunTimeEnvironment.objects.get((<any>object.logicalElement).text);
  if (runtimeObject === undefined) {
    runtimeObject = { state: undefined, visual: object, isSimulatedOrComputed: isSimulatedOrComputed };
  }
  runtimeObject.state = value;
  globalRunTimeEnvironment.objects.set((<any>object.logicalElement).text, runtimeObject);
}
