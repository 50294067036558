import { Component, DoCheck } from '@angular/core';
import { OpmObject } from '../../../models/DrawnPart/OpmObject';
import { OpmState } from '../../../models/DrawnPart/OpmState';
import { OpmThing } from '../../../models/DrawnPart/OpmThing';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import { OpmVisualObject } from '../../../models/VisualPart/OpmVisualObject';
import { copy as copy_, paste as paste_ } from '../../../configuration/rappidEnviromentFunctionality/keyboardShortcuts';
import { OpmVisualThing } from '../../../models/VisualPart/OpmVisualThing';
import {
  asyncRunner,
  copyAllObjectValues,
  ExecutionRunner, headlessRunner,
  syncRunner,
  updateAllObjectValues
} from '../../../configuration/elementsFunctionality/computationalPart';
import { textToName } from '../../../configuration/elementsFunctionality/computationalPartUtils';
import { OpmLogicalState } from '../../../models/LogicalPart/OpmLogicalState';
import {
  geometry,
  getInitRappidShared,
  getStyles,
  highlighSD, OPCloudUtils, popupGenerator, removeDuplicationsInArray,
  setSelect, stylePopup,
  validationAlert
} from '../../../configuration/rappidEnviromentFunctionality/shared';
import { OpmLogicalObject } from '../../../models/LogicalPart/OpmLogicalObject';
import { sdLayout } from '../../../configuration/elementsFunctionality/opdLayout';
import { Note } from '../../../models/DrawnPart/Note';
import { ExhibitionLink } from '../../../models/DrawnPart/Links/ExhibitionLink';
import { Essence, statesArrangement, linkConnectionType, linkType, valueType } from '../../../models/ConfigurationOptions';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import * as XLSX from 'xlsx';
import { SimulationComponent } from '../../../dialogs/Simulation/Simulation';
import { OpmEntity } from '../../../models/DrawnPart/OpmEntity';
import { SaveURLComponent } from '../../../dialogs/saveURL-dialog/saveURL';
import { getAllComputationalProcesses, validateUnits } from '../../../configuration/elementsFunctionality/ExePreProcess';
import { EntityType } from '../../../models/model/entities.enum';
import { OpmVisualState } from '../../../models/VisualPart/OpmVisualState';
import { OpmProcess } from '../../../models/DrawnPart/OpmProcess';
import { OpmLogicalEntity } from '../../../models/LogicalPart/OpmLogicalEntity';
import { OpmVisualEntity } from '../../../models/VisualPart/OpmVisualEntity';
import { OpmLogicalThing } from '../../../models/LogicalPart/OpmLogicalThing';
import { SearchItemsDialogComponent } from '../../../dialogs/search-items-dialog/search-items-dialog.component';
import { OpmStereotype } from '../../../models/OpmStereotype';
import { StereotypesDialogComponent } from '../../../dialogs/stereotypes-dialog/stereotypes-dialog.component';
import { StereotypeStorageMode } from '../../../dialogs/stereotypes-dialog/StereotypesRelatedInterface';
import { OpmModel } from '../../../models/OpmModel';
import { exampleStereotype } from '../../../models/exampleStereotype';
import { SimulationElementComponent } from '../../../dialogs/simulationElement/SimulationElement';
import { MatLegacySliderChange as MatSliderChange } from '@angular/material/legacy-slider';
import {
  defaultMqttConnectionSettings,
  defaultPythonConnectionSettings,
  defaultMySQLConnectionSettings,
  defaultRosConnectionSettings
} from '../../../opl-generation/opl.service';
import { OpmVisualProcess } from '../../../models/VisualPart/OpmVisualProcess';
import { OpmLogicalProcess } from '../../../models/LogicalPart/OpmLogicalProcess';
import { multiInstancesDialog } from '../../../dialogs/multi-instances-dialog/multi-instances-dialog';
import { ElementToolbarHandle } from '../../../models/components/commands/command';
import { DownloadCSVComponent } from '../../../dialogs/DownloadCSV/DownloadCSV';
import { StyleCopyingDialogComponent } from '../../../dialogs/styleCopyingDialog/styleCopyingDialog.component';
import { TriangleClass } from '../../../models/DrawnPart/Links/OpmFundamentalLink';
import {CreateViewDialog} from "../../../dialogs/create-view-dialog/create-view-dialog";
import {createDrawnEntity} from "../../../configuration/elementsFunctionality/graphFunctionality";
import {HeadlessRunnerComponent} from "../../../dialogs/headlessRunner-dialog/headlessRunner-dialog.component";
import {TemplatesComponent} from "../../../dialogs/templates-import/templates-import";
import {Arc} from "../../../models/DrawnPart/Links/OrXorArcs";
import {CreateRequirementViewDialog} from "../../../dialogs/create-requirement-view-dialog/create-requirement-view-dialog";
import {ConfirmDialogDialogComponent} from "../../../dialogs/confirm-dialog/confirm-dialog";
import {OpmVisualElement} from "../../../models/VisualPart/OpmVisualElement";
import {BackgroundPhotoDialogComponent} from "../../../dialogs/background-photo-dialog/background-photo-dialog";
import {ImagesPoolManagementComponent} from "../../../dialogs/images-pool-management/images-pool-management-component";
import {ImagesPoolContainer} from "../../../dialogs/images-pool-container/images-pool-container";
import {BackgroundImageState} from "../../../models/VisualPart/backgroundImageEnum";
import {SubModelNameComponent} from "../../../dialogs/submodel-name-dialog/submodel-name-dialog";
import {DisplayModel} from "../../../rappid-components/services/storage/model-storage.interface";
import {DeleteAction} from "../../../models/Actions/deleteAction";
import {fundamental} from "../../../models/consistency/links.set";
import {
  MethodologicalCheckingDialog
} from "../../../dialogs/methodological-checking-dialog/methodological-checking-dialog";
import {FontData, getSupportedFonts} from "./supported-fonts";

let valuesArray = new Array();  // stores values of computational objects after each execution. used for recovering

@Component({
  selector: 'opcloud-element-tool-bar',
  templateUrl: './element-tool-bar.component.html',
  styleUrls: ['./element-tool-bar.component.css'],
})
export class ElementToolBarComponent {

  elementDefault;
  paper;
  graph;
  visible = false;
  isAutoFormat: boolean;
  isObject;
  isProcess;
  isState;
  isLink;
  isFullscreen;
  textChangeMenu;
  currentFontSize;
  textColor;
  strokeColor;
  textFont;
  fillColor;
  defaultfontStyle;
  zoomStatus = '100';
  initialZoomStatus;
  zoomActiveStatus = false;
  runByConfigurations = false;
  isInzoom;
  isInzoomForTooltip;
  isUnfold;
  isUnfoldForTooltip;
  downloadCSV = false;
  numberOfRuns = 1;
  downloadCSVEvery = 1;
  isComputational;
  isTimeDuration;
  file: File;
  arrayBuffer: any;
  uploaded = false;
  simulate = false;
  log = '';
  now = new Date();
  private thing;
  public isActive = true;
  public menuOpen = false;
  isHeadlessRunner: boolean;
  _isExample = false;
  _isStereotype = false;
  _isTemplate = false;
  templatesSupported = false;
  _shouldShowSemifolding = false
  _shouldShowConnectSubModel = false;
  public selected: OpmEntity;
  private supportedFonts: Array<FontData>;
  selectionBox = {
    selectBoxWidth: '',
    selectBoxHeight: '',
    selectBoxRight: '',
    selectionBottom: '',
    selectBoxLeft: '',
    selectBoxTop: ''
  };

  showStylingDiv = false;
  showStatesArrangementDiv = false;
  showExtensionsDiv = false;
  showTemplatesDiv = false;
  isViewsDivOpen = false;
  showOpmRequirementsDiv = false;
  showConnectionsDiv =  false;
  showThingBackgroundDiv = false;
  showImagesIsThingsSelection = false;

  public removeHandle: ElementToolbarHandle;
  public unfoldHandle: ElementToolbarHandle;
  public inzoomHandle: ElementToolbarHandle;
  public setComputationHandle: ElementToolbarHandle;
  public removeComputationHandle: ElementToolbarHandle;
  public addStateHandle: ElementToolbarHandle;
  public destateHandle: ElementToolbarHandle;
  public suppressHandle: ElementToolbarHandle;
  public toogleEssenceHandle: ElementToolbarHandle;
  public toogleAffiliationHandle: ElementToolbarHandle;
  public editAliasHandle: ElementToolbarHandle;
  public editUnitsHandle: ElementToolbarHandle;
  public bringConnectedHandle: ElementToolbarHandle;
  public setTimeDurationHandle: ElementToolbarHandle;
  public textAutoFormatHandle: ElementToolbarHandle;
  public updateComputationalHandle: ElementToolbarHandle;
  public suppressSingleStateHandle: ElementToolbarHandle;
  public userInputHandle: ElementToolbarHandle;
  private areSubModelsSupported: boolean;
  private showBringConnectedDiv: boolean;

  constructor(private readonly init: InitRappidService, private _dialog: MatDialog, private dialog: MatDialog) {
    this.elementDefault = {
      fontStyle: null,
      fontColor: null,
      fill: null,
      stroke: null,
      textSize: null,
    };
    const that = this;
    this.supportedFonts = getSupportedFonts();
    this.init.elementToolbarReference = this;
    this.isHeadlessRunner = false;
    this.init.includeSubModelsInExecution = true;
    this.templatesSupported = this.init.service?.areTemplatesSupported();
    this.areSubModelsSupported = this.init.service?.areSubModelsSupported();
    this.init.getSelectedElement$().subscribe(selected => {
      if (!that.init.isReadOnlyOpd()) {
        if (selected instanceof TriangleClass) {
          selected = undefined;
        }
        if (this.selected && this.selected !== selected) {
          this.closeStylingDiv();
        }
        this.selected = selected;
        if (that.init.copiedStyleParams && that.init.copiedStyleParams.isRightClick && this.selected !== undefined && !this.init.selectedElement.constructor.name.includes('Note') && !this.init.selectedElement.constructor.name.includes('BlankLink')) {
          that.pasteStyleParams();
        } else if (that.init.copiedStyleParams && this.selected !== undefined && !this.init.selectedElement.constructor.name.includes('Note') && !this.init.selectedElement.constructor.name.includes('BlankLink')) {
          that.pasteStyleParams();
          that.init.copiedStyleParams = null;
        }
      } else {
        that.init.selection.collection.reset([]);
        that.init.selection.cancelSelection();
        this.selected = undefined;
      }
      this.onSelection();
    });

    this.init.paper.on('blank:pointerdown', () => {
      if (this.textChangeMenu) {
        this.toggleTextSizeMenu();
      }
      if (this.fillColor) {
        this.toggleFillMenu();
      }
      if (this.textFont) {
        this.toggleFontMnue();
      }
      if (this.strokeColor) {
        this.toggleStrokeMenu();
      }
      if (this.textColor) {
        this.toggleTextColorMenu();
      }
      if (this.zoomActiveStatus) {
        this.zoomActiveStatus = false;
      }
    });

    this.init.paper.on('blank:pointerup', () => {
      this._shouldShowConnectSubModel = this.areSubModelsSupported && this.shouldShowConnectSubModel();
    });

    this.init.paper.on('element:pointerdown', () => {
      if (this.zoomActiveStatus) {
        this.zoomActiveStatus = false;
      }
    });

    this.init.paper.on('blank:pointerup', () => {
      if (this.init.selection.collection.models.length > 1) {
        this.pointerUpHandle();
      }
    });
    this.initialZoomStatus = this.init.paperScroller.zoom();
  }

  get ExecuteMode() {
    return this.init.ExecuteMode;
  }

  set ExecutingPause(val) {
    this.init.ExecutingPause = val;
    this.init.getGraphService().setPaperInteractivity(this.init, this.init.opmModel.currentOpd);
  }

  get ExecutingPause() {
    return this.init.ExecutingPause;
  }

  set Executing(val) {
    this.init.Executing = val;
    this.init.getGraphService().setPaperInteractivity(this.init, this.init.opmModel.currentOpd);
  }

  get Executing() {
    return this.init.Executing;
  }

  get selectionArray() {
    return this.init.selection.collection.models;
  }

  get stateMenu() {
    if (document.getElementById('svgRectFinal')) {
      return true;
    }
    return false;
  }


  isTimeDurational() {
    if (!this.init.selectedElement || !(<any>this.init.selectedElement).getVisual) {
      return false;
    }
    const visual = (<any>this.init.selectedElement).getVisual();
    if (OPCloudUtils.isInstanceOfVisualProcess(visual) && (<OpmLogicalProcess>visual.logicalElement).getIsWaitingProcess()) {
      return false;
    }
    return (this.isProcess || this.isState) && (<any>this.init.selectedElement).getVisual() && (<any>this.init.selectedElement).getVisual().isTimeDuration();
  }

  openStylingDiv() {
    this.closeAllSubToolBars();
    this.showStylingDiv = true;
  }

  closeStylingDiv() {
    this.closeAllSubToolBars();
    this.showStylingDiv = false;
  }

  onSelection() {
    if (this.selected) {
      if (this.selected.getToolbarHandles) {
        const handles = this.selected.getToolbarHandles(this.init);
        this.removeHandle = handles.find(h => h.name == 'remove');
        if (this.init.Executing && !this.init.ExecutingPause) {
          this.removeHandle = undefined;
        }
        this.unfoldHandle = handles.find(h => h.name == 'unfold');
        this.inzoomHandle = handles.find(h => h.name == 'inzoom');
        this.setComputationHandle = handles.find(h => h.name == 'set-computation');
        this.removeComputationHandle = handles.find(h => h.name === 'remove-computation');
        this.addStateHandle = handles.find(h => h.name == 'add-state');
        this.destateHandle = handles.find(h => h.name == 'destate');
        this.suppressHandle = handles.find(h => h.name == 'Suppress All');
        this.suppressSingleStateHandle = handles.find(h => h.name == 'suppress');
        this.toogleEssenceHandle = handles.find(h => h.name == 'toggle-essence');
        this.toogleAffiliationHandle = handles.find(h => h.name == 'toggle-affiliation');
        this.editAliasHandle = handles.find(h => h.name == 'edit-alias');
        this.editUnitsHandle = handles.find(h => h.name == 'edit-units');
        this.bringConnectedHandle = handles.find(h => h.name == 'bring-connected');
        this.setTimeDurationHandle = handles.find(h => h.name == 'set-time-duration');
        this.textAutoFormatHandle = handles.find(h => h.name == 'toggle-text-format');
        this.updateComputationalHandle = handles.find(h =>  h.name === 'update-computation');
        this.userInputHandle = handles.find(h => h.name === 'user-input');
      }

      const logical = this.init.opmModel.getLogicalElementByVisualId(this.selected.id) as OpmLogicalEntity<any>;
      this.defaultfontStyle = this.selected.attr('text/textWrap/text');
      this.visible = false;
      this.isObject = false;
      this.isProcess = false;
      this.isState = false;
      this.isLink = false;

      this.isComputational = (this.selected.isComputational && this.selected.isComputational());
      const type = this.selected.attributes.type;
      this.isAutoFormat = (type === 'opm.Process' || type === 'opm.Object' || type === 'opm.State') ?
        logical?.getNameModule().isAutoFormat() : false;
      if (this.selected.attributes.type === 'opm.Object') {
        this.isObject = true;
        // Alon - hides temporal state buttons
        // this.hideTemporalStateBTN();
      } else if (this.selected.attributes.type === 'opm.Process') {
        this.isProcess = true;
        // Alon - hides temporal state buttons
        // this.hideTemporalStateBTN();
      } else if (this.selected.attributes.type === 'opm.State') {
        this.isState = true;
      } else if (this.selected.attributes.type === 'opm.Link') {
        this.isLink = true;
        // this.hideTemporalStateBTN();
      }
      // Hide tool bar when it's OPMQuery OPD
      if (this.init.opmModel.currentOpd.id === this.init.opmModel.getOPMQueryID()) {
        this.visible = false;
      } else {
        this.visible = true;
      }
    } else {
      this.visible = false;
      this.isObject = false;
      this.isProcess = false;
      this.isState = false;
      this.isLink = false;
    }
    this._shouldShowSemifolding = this.shouldShowSemifolding();
    this._shouldShowConnectSubModel = this.areSubModelsSupported && this.shouldShowConnectSubModel();
  }

  returnVisible() {
    return this.visible;
  }

  timeDurationButtonAction() {
    const element = this.init.selectedElement;
    if (!element) {
      return;
    }
    const visual = this.init.getOpmModel().getVisualElementById(element.id);
    const cellView = this.init.paper.findViewByModel(this.init.selectedElement);
    element.openTimeDuration(cellView.el, (<any>visual).logicalElement.getDurationManager(), { digits: this.init.oplService.settings.timeDurationUnitsDigits });
  }

  timeDurationRemoveButtonAction() {
    const element = this.init.selectedElement;
    if (!element) {
      return;
    }
    const visual = this.init.getOpmModel().getVisualElementById(element.id);
    if (OPCloudUtils.isInstanceOfVisualProcess(visual) && (<OpmLogicalProcess>visual.logicalElement).getIsWaitingProcess()) {
      return;
    }
    const ret = (<any>visual.logicalElement).getDurationManager().removeTimeDuration();
    if (ret.success && (visual.constructor.name.includes('State') || !(<any>visual).getRefineeInzoom() || (<any>visual).getRefineeInzoom() !== visual)) {
      (<any>visual).width = element.get('minSize').width;
      (<any>visual).height = element.get('minSize').height;
    }
    this.init.selectedElement.updateEntityFromOpmModel(visual);
    if (this.init.selectedElement.constructor.name.includes('State')) {
      this.init.selectedElement.getParent().shiftEmbeddedToEdge(this.init);
    }
  }

  arrangeSatesRight() {
    this.init.opmModel.logForUndo('Arrange States');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Arrange States');
    if (this.selectionArray.length > 1) { this.arrangeObjects('right'); }
    if ((<OpmObject>(this.selected)).getStatesOnly().length > 0) {
      (<OpmObject>(this.selected)).arrangeEmbedded(this.init, 'right');
      (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    }
    (<OpmObject>(this.selected)).updateSizePositionToFitEmbeded();
    (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Arrange States');
  }

  arrangeSatesLeft() {
    this.init.opmModel.logForUndo('Arrange States');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Arrange States');
    if (this.selectionArray.length > 1) { this.arrangeObjects('left'); }
    if ((<OpmObject>(this.selected)).getStatesOnly().length > 0) {
      (<OpmObject>(this.selected)).arrangeEmbedded(this.init, 'left');
      (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    }
    (<OpmObject>(this.selected)).updateSizePositionToFitEmbeded();
    (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Arrange States');
  }

  arrangeSatesBottom() {
    this.init.opmModel.logForUndo('Arrange States');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Arrange States');
    if (this.selectionArray.length > 1) { this.arrangeObjects('bottom'); }
    if ((<OpmObject>(this.selected)).getStatesOnly().length > 0) {
      (<OpmObject>(this.selected)).arrangeEmbedded(this.init, 'bottom');
      (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    }
    (<OpmObject>(this.selected)).updateSizePositionToFitEmbeded();
    (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Arrange States');
  }

  arrangeSatesTop() {
    this.init.opmModel.logForUndo('Arrange States');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Arrange States');
    if (this.selectionArray.length > 1) { this.arrangeObjects('top'); }
    if ((<OpmObject>(this.selected)).getStatesOnly().length > 0) {
      (<OpmObject>(this.selected)).arrangeEmbedded(this.init, 'top');
      (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    }
    (<OpmObject>(this.selected)).updateSizePositionToFitEmbeded();
    (<OpmObject>(this.selected)).shiftEmbeddedToEdge(this.init);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Arrange States');
  }

  isDeletableMultiselection(): boolean {
    const case1 = ((this.selected) && this.selected.attributes.type === 'opm.Ellipsis');
    const deleteableElements = this.init.selection.collection.models.filter(m => m instanceof OpmEntity);
    const case3 = deleteableElements.length >= 1;
    return case1 || case3;
  }

  CreateNewDigitaltwin(originalObj): OpmObject {
    const digitalTwin = new OpmObject();
    this.setDigitalTwinText(digitalTwin, originalObj);
    this.setDigitalTwinPosition(digitalTwin, originalObj);
    this.setDigitalTwinEssence(digitalTwin);
    return digitalTwin;
  }

  setDigitalTwinText(digitalTwin: OpmObject, originalObj: OpmObject): void {
    digitalTwin.attr({
      text:
      {
        textWrap:
          { text: originalObj.attr('text/textWrap/text') + '_DigitalTwin' }
      }
    });
  }

  setDigitalTwinPosition(digitalTwin: OpmObject, originalObj: OpmObject): void {
    // TODO if need => overlapping check
    digitalTwin.set(
      {
        position: {
          x: originalObj.get('position').x,
          y: originalObj.get('position').y + 250,
        }
      }
    );
  }

  setDigitalTwinEssence(digitalTwin) {
    digitalTwin.setEssence(Essence.Informatical);
  }

  connectOriginalObjectToDigitalTwin(originalObj: OpmObject, digitalTwin: OpmObject): ExhibitionLink {
    const exhibitionLink = new ExhibitionLink(originalObj, digitalTwin, this.init.getGraphService().graph);
    return exhibitionLink;
  }

  generatedigitalTwin(): void {
    const originalObj = this.selected; // digitalTwin
    const model = this.init.getOpmModel();
    model.logForUndo('Digital twin creation');
    const graph = originalObj.graph;
    // ------------ New Code ----------------------------------------
    // ----------- New Drawn ---------------------------------------
    let digitalTwin;
    let exhibitionLink;
    if (originalObj.checkIfDigitalTwinExists(model)) {
      digitalTwin = this.init.getGraphService().graph.getCell(originalObj.attributes.attrs.digitalTwin);
      if (digitalTwin === undefined) {
        digitalTwin = graph.getCell(originalObj.findDigitalTwin(model, originalObj));
      }
      if (originalObj.checkIfDigitaltwinIsConnected(graph)) {
        if (originalObj.checkIfCHangesExist(originalObj, digitalTwin, model)) {
          return;
        } else {
          originalObj.updateCHanges(originalObj, digitalTwin, this.init);
          return;
        }
      } else {
        exhibitionLink = this.connectOriginalObjectToDigitalTwin(originalObj as OpmObject, digitalTwin);
        originalObj.attributes.attrs.digitalTwinConnected = true;
        (<OpmVisualObject>this.init.getOpmModel().getVisualElementById(originalObj.id)).digitalTwinConnected = true;
        (<OpmVisualObject>this.init.getOpmModel().getVisualElementById(originalObj.id)).updateParams((<OpmVisualObject>this.init.getOpmModel().getVisualElementById(originalObj.id)).getParams());
        this.init.getGraphService().graph.addCell(exhibitionLink);
        return;
      }
    }

    digitalTwin = this.CreateNewDigitaltwin(originalObj);
    exhibitionLink = this.connectOriginalObjectToDigitalTwin(originalObj as OpmObject, digitalTwin);

    this.init.getGraphService().graph.addCell(digitalTwin);
    this.init.getGraphService().graph.addCell(exhibitionLink);
    originalObj.attributes.attrs.digitalTwin = digitalTwin.id;
    originalObj.attributes.attrs.preoriginalObj = digitalTwin.id;
    digitalTwin.attributes.attrs.originalObj = originalObj.id;
    digitalTwin.attributes.attrs.predigitalTwin = digitalTwin.id;
    originalObj.attributes.attrs.digitalTwinConnected = true;
    // ----------- New Drawn ---------------------------------------
    // ----------  New Visual --------------------------------------
    const originalObjVisual = (<OpmVisualObject>this.init.getOpmModel().getVisualElementById(originalObj.id));
    const digitalTwinVisual = (<OpmVisualObject>this.init.getOpmModel().getVisualElementById(digitalTwin.id));
    originalObjVisual.digitalTwin = digitalTwin.id;
    originalObjVisual.preoriginalObj = originalObj.id;
    digitalTwinVisual.originalObj = originalObj.id;
    digitalTwinVisual.predigitalTwin = digitalTwin.id;
    originalObjVisual.digitalTwinConnected = true;
    originalObjVisual.updateParams(originalObjVisual.getParams());
    digitalTwinVisual.updateParams(digitalTwinVisual.getParams());
    // ----------  New Visual --------------------------------------
    // ------------ New Code ----------------------------------------
    this.init.oplService.oplSwitch.next('render');
  }

  simulateElement() {
    const sim = (<any>this.selected.getVisual().logicalElement).getSimulationParams();
    this.init.dialogService.openDialog(SimulationElementComponent, 700, 600, { sim: sim, logical: <any>this.selected.getVisual().logicalElement });
  }

  inZoomElementInDiagram() {
    (<OpmThing>(this.selected)).inzoomInDiagramAction(this.init);
  }

  unfoldelement() {
    (<OpmThing>(this.selected)).unfoldAction(this.init);
  }
  outZoomNewDiagram(type) {
    const visuals = this.init.selection.collection.models.map(dr => this.init.opmModel.getVisualElementById(dr.id));
    const hasInzoomedSelected = visuals.filter(vis => vis.children.length === 0 ||
      (vis.children.length > 0 && !vis.children.find(child => !(child instanceof OpmVisualState))));
    if (hasInzoomedSelected.length < visuals.length) {
      validationAlert('Cannot outzoom a refineable thing');
      return;
    }
    const fathers = visuals.map(v => v.logicalElement.getFather());
    const uniqueFathers = removeDuplicationsInArray(visuals.filter(v => !OPCloudUtils.isInstanceOfVisualState(v)).map(v => v.logicalElement.getFather()).filter(f => !!f));
    if (uniqueFathers.length > 1 || (fathers.includes(undefined) && (visuals.find(v => !OPCloudUtils.isInstanceOfVisualState(v) && v.logicalElement.getFather())))) {
      validationAlert('All of the selected entities shall be inner entities from the same parent.', 5000, 'Error');
      return;
    }
    if (type === 'Process') {
      const sources = [];
      for (const vis of visuals) {
        const links = vis.getAllLinks().inGoing.filter(l => fundamental.contains(l.type));
        if (links.length === 0) {
          sources.push([undefined]);
        } else {
          sources.push(removeDuplicationsInArray(links.map(l => l.source.logicalElement)));
        }
      }
      for (let i = 0; i < sources.length; i++) {
        for (let j = 0; j < sources.length; j++) {
          if (i !== j && sources[i].some(src => !sources[j].includes(src))) {
            validationAlert('For “Out zoom new diagram as a process” all the chosen things should not be connected to “outside” entities by structural links.', 5000, 'Error');
            return;
          }
        }
      }
    }
    visuals.sort((a,b) => {
      if (Math.abs(a.yPos - b.yPos) <= 15) {
        return a.xPos - b.xPos;
      }
      return a.yPos - b.yPos;
    });
    const styleParams = this.init.selection.collection.models.find(m => OPCloudUtils.isInstanceOfDrawnThing(m)).getDefaultStyleParams(this.init);
    const enType = type === 'Object' ? EntityType.Object : EntityType.Process;
    const ret = this.init.opmModel.outzoomNewDiagram(enType, visuals, undefined, styleParams);
    if (ret.success && ret.uiToUpdate) {
      this.updateGraphAfterOuting(ret.uiToUpdate);
    }
    this.init.selection.collection.reset([]);
    if (ret.success === false) {
      validationAlert(ret.message);
    }
    this.init.opdHierarchyRef.sortOpdsToFixNewOrderLoading(this.init);
    if (visuals[0].fatherObject && this.init.graph.getCell(visuals[0].fatherObject) && this.init.graph.getCell(ret.newThing.id)) {
      this.init.graph.getCell(visuals[0].fatherObject).embed(this.init.graph.getCell(ret.newThing.id));
    }
  }

  outFoldNewDiagram() {
    const visuals = this.init.selection.collection.models.map(dr => this.init.opmModel.getVisualElementById(dr.id));
    const hasInzoomedSelected = visuals.filter(vis => vis.children.length === 0 ||
      (vis.children.length > 0 && !vis.children.find(child => !(child instanceof OpmVisualState))));
    if (hasInzoomedSelected.length < visuals.length || visuals.find(vis => vis.constructor.name !== visuals[0].constructor.name)) {
      validationAlert('Cannot out-fold. Make sure refineable things are not selected and that all things are from the same type.');
      return;
    }
    const styleParams = this.init.selection.collection.models.find(m => OPCloudUtils.isInstanceOfDrawnThing(m)).getDefaultStyleParams(this.init);
    const ret = this.init.opmModel.outfoldNewDiagram(visuals, undefined, styleParams);
    if (ret.success && ret.uiToUpdate) {
      this.updateGraphAfterOuting(ret.uiToUpdate);
    }
    this.init.opdHierarchyRef.sortOpdsToFixNewOrderLoading(this.init);
    this.init.selection.collection.reset([]);
    if (ret.success === false) {
      validationAlert(ret.message);
    }
    if (visuals[0].fatherObject && this.init.graph.getCell(visuals[0].fatherObject) && this.init.graph.getCell(ret.newThing.id)) {
      this.init.graph.getCell(visuals[0].fatherObject).embed(this.init.graph.getCell(ret.newThing.id));
    }
  }

  updateGraphAfterOuting(uiToUpdate) {
    const init: InitRappidService = this.init;
    if (init && init.graphService) {
      init.graph.startBatch('outing');
      for (const rem of uiToUpdate.removed) {
        init.graph.startBatch('ignoreChange');
        init.graph.getCell(rem.id) ? init.graph.getCell(rem.id).remove() : null;
        init.graph.stopBatch('ignoreChange');
      }
      const drawnElement = createDrawnEntity(uiToUpdate.newThingLogical.name);
      drawnElement.updateParamsFromOpmModel(uiToUpdate.visual);
      init.graph.addCell(drawnElement);
      uiToUpdate.newlinks = removeDuplicationsInArray(uiToUpdate.newlinks);
      init.graphService.updateLinksView(uiToUpdate.newlinks.filter(l => init.opmModel.getVisualElementById(l.id)));
      init.selection.collection.reset([]);
      init.selection.collection.add(drawnElement);
      init.getTreeView().init(init.opmModel);
      init.graph.stopBatch('outing');
    }
  }

  changeEssence() {
    const visualElement = <OpmVisualThing>(this.init.opmModel.getVisualElementById(this.selected.id));
    const connectedLinks = visualElement.getLinks().outGoing;
    if (connectedLinks.find(link => link.type === linkType.Agent)) {
      validationAlert('The object is an agent and its essence cannot be changed to informatical.', 5000);
      return;
    }
    (<OpmThing>(this.selected)).toggleEssence(visualElement);
  }

  changeAffiliation() {
    const visualElement = <OpmVisualThing>(this.init.opmModel.getVisualElementById(this.selected.id));
    (<OpmThing>(this.selected)).toggleAffiliation(visualElement);
  }
  zoomInSBar() {
    if (this.zoomActiveStatus) {
      this.zoomActive();
    }
    const jumpVal = 10;
    if (parseInt(this.zoomStatus) <= 340) {
      this.zoomStatus = (parseInt(this.zoomStatus) + jumpVal).toString();
      this.init.paperScroller.zoom(0.1, { max: 4 });
    }
  }
  zoomOutSBar() {
    if (this.zoomActiveStatus) {
      this.zoomActive();
    }
    const jumpVal = 10;

    this.zoomStatus = (parseInt(this.zoomStatus) - jumpVal).toString();
    this.init.paperScroller.zoom(-0.1, { min: 0.1 });

  }

  zoomActive() {
    this.zoomActiveStatus = !this.zoomActiveStatus;
  }

  zoomSelect(str?) {
    const val = str;
    let val1;
    let val2;
    let val3;
    let val3Decimal;
    let sign;
    if (val && this.zoomStatus) {
      val1 = val.toString().slice(0, val.toString().length - 1);
      val2 = this.zoomStatus.toString().slice(0, this.zoomStatus.toString().length - 1);
      if (parseInt(val1) >= parseInt(val2)) {
        sign = true;
      } else {
        sign = false;
      }
      val3 = (sign) ? parseInt(val1) - parseInt(val2) : parseInt(val2) - parseInt(val1);
      val3Decimal = val3 / 10;
    }


    switch (val) {
      case '10':
        this.zoomStatus = '10';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '20':
        this.zoomStatus = '20';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '30':
        this.zoomStatus = '30';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '40':
        this.zoomStatus = '40';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '50':
        this.zoomStatus = '50';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '60':
        this.zoomStatus = '60';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '70':
        this.zoomStatus = '70';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '80':
        this.zoomStatus = '80';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '90':
        this.zoomStatus = '90';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '100':
        this.zoomStatus = '100';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '110':
        this.zoomStatus = '110';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '120':
        this.zoomStatus = '120';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '130':
        this.zoomStatus = '130';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '140':
        this.zoomStatus = '140';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '150':
        this.zoomStatus = '150';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '160':
        this.zoomStatus = '160';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '170':
        this.zoomStatus = '170';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '180':
        this.zoomStatus = '180';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '190':
        this.zoomStatus = '190';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
      case '200':
        this.zoomStatus = '200';
        this.init.paperScroller.zoom((sign) ? val3Decimal : -Math.abs(val3Decimal), { min: 0.1 });
        break;
    }
  }

  deleteElementsMultiselection() {
    new DeleteAction(this.init).act();
  }

  toggelfullScreen() {
    const element = document.documentElement;
    if (document.fullscreen === false) {
      (<any>element).webkitRequestFullScreen();
    } else {
      document.exitFullscreen();
    }
  }

  isAtFullScreen() {
    return document.fullscreen;
  }

  ROSactivate() {
    this.init.activateROS = !this.init.activateROS;

    if (this.init.activateROS) {
      const ros_port = this.calcPort('ros');
      const ros_server = this.calcServer('ros');
      this.init.wshandlerROS = new WebSocket('ws://' + ros_server + (ros_port !== '' ? ':' : '') + ros_port + '/');
      // console.log('Websocket activated');

      this.init.wshandlerROS.onerror = function () {
        const errMsg = 'Cannot connect to Websocket';
        validationAlert(errMsg, 5000);
        this.init.activateROS = false;
      }.bind(this, this.init);

      this.init.wshandlerROS.onopen = function (e) {
        // console.log("[open] Websocket connection established");
        // console.log(this.readyState);
        validationAlert('Websocket connection established', 5000, undefined, true);
      };
      this.init.wshandlerROS.onclose = function (e) {
        const errMsg = 'Websocket disconnected';
        validationAlert(errMsg, 5000);
        this.init.activateROS = false;
      }.bind(this, this.init);
    } else {
      this.init.wshandlerROS.close();
      this.init.wshandlerROS = null;
    }
  }

  Pythonactivate() {
    this.init.activatePython = !this.init.activatePython;
    if (this.init.activatePython) {
      const python_port = this.calcPort('python');
      const python_server = this.calcServer('python');
      this.init.handlerPython = new WebSocket('ws://' + python_server + (python_port !== '' ? ':' : '') + python_port + '/');
      this.init.handlerPython.onerror = function () {
        const errMsg = 'Cannot connect to Python Websocket';
        validationAlert(errMsg, 5000);
        this.init.activatePython = false;
      }.bind(this, this.init);

      this.init.handlerPython.onopen = function (e) {
        validationAlert('Python Websocket connection established', 5000, undefined, true);
      };
      this.init.handlerPython.onclose = function (e) {
        const errMsg = 'Python Websocket disconnected';
        validationAlert(errMsg, 5000);
        this.init.activatePython = false;
      }.bind(this, this.init);
    } else {
      this.init.handlerPython.close(1000);
      this.init.handlerPython = null;
    }
  }

  MySQLactivate() {
    // all the parameters:
    const mysql_port = this.calcMySQLPort('mysql');
    const mysql_hostname = this.calcMySQLHostname('mysql');
    const mysql_password = this.calcMySQLPassword('mysql');
    const mysql_username = this.calcMySQLUsername('mysql');
    const schema = this.calcSchema('mysql');
    const ws_port = this.calcWSPort('mysql');
    const ws_server = this.calcWSHostname('mysql');
    // ______________________________________________________________________________
    const config = {
      schema: schema,
      password: mysql_password,
      user: mysql_username,
      host: mysql_hostname,
      port: mysql_port,
    };
    // ______________________________________________________________________________
    this.init.activateMySQL = !this.init.activateMySQL;
    if (this.init.activateMySQL) {
      this.init.handlerMYSQL = new WebSocket('ws://' + ws_server + ':' + ws_port);
      this.init.handlerMYSQL.onerror = function () {
        const errMsg = 'Cannot connect to MYSQL Websocket'  ;
        validationAlert(errMsg, 5000);
        this.init.activateMQTT = false;
      }.bind(this, this.init);
      // ______________________________________________________________________________
      this.init.handlerMYSQL.onopen = function (e) {
        validationAlert('MYSQL Websocket connection established', 5000, undefined, true);
      };
      this.init.handlerMYSQL.addEventListener('open', () => {  this.init.handlerMYSQL.send(JSON.stringify(config)); });
      // This line gets from the server the command result. Like "Table 'guests' already exists" etc.
      // this.init.handlerMYSQL.addEventListener('message', event => {  console.log('activiate_Received:', event.data); });

      this.init.handlerMYSQL.onclose = function (e) {
        const errMsg = 'MYSQL Websocket disconnected';
        validationAlert(errMsg, 5000);
        this.init.activateMYSQL = false;
      }.bind(this, this.init);
    } else {
      this.init.handlerMYSQL.close(1000);
      this.init.handlerMYSQL = null;
    }
  }

  MQTTactivate() {
    this.init.activateMQTT = !this.init.activateMQTT;
    if (this.init.activateMQTT) {
      const mqtt_port = this.calcPort('mqtt');
      const mqtt_server = this.calcServer('mqtt');
      this.init.handlerMQTT = new WebSocket('ws://' + mqtt_server + (mqtt_port !== '' ? ':' : '') + mqtt_port + '/');
      this.init.handlerMQTT.onerror = function () {
        const errMsg = 'Cannot connect to MQTT Websocket';
        validationAlert(errMsg, 5000);
        this.init.activateMQTT = false;
      }.bind(this, this.init);

      this.init.handlerMQTT.onopen = function (e) {
        // console.log("[open] Websocket connection established");
        // console.log(this.readyState);
        validationAlert('MQTT Websocket connection established', 5000, undefined, true);
      };
      this.init.handlerMQTT.onclose = function (e) {
        const errMsg = 'MQTT Websocket disconnected';
        validationAlert(errMsg, 5000);
        this.init.activateMQTT = false;
      }.bind(this, this.init);
    } else {
      this.init.handlerMQTT.close();
      this.init.handlerMQTT = null;
    }
  }

  toggelNotes() {
    const notes_flag = !this.init.notes;
    this.init.oplService.updateUserSettings({ Notes: notes_flag });
    this.init.updateDB({ Notes: notes_flag });

    const notes = this.init.getOpmModel().currentOpd.notes;
    if (notes.length) {
      this.init.getGraphService().renderGraph(this.init.getOpmModel().currentOpd, this.init);
    }
  }

  toggleNavigator() {
    this.init.toggleNavigator();
    this.init.setLeftBarWindowsSizes({});
  }

  copy() {
    copy_(this.init);
  }

  paste() {
    paste_(this.init);
  }

  adConnected() {
    this.selected.bringAction(this.init);
  }
  /*
    excuteBtn() {
    // array that contains all the link ids needed to be visualized by a token
    const linksArray = [];
    execute(linksArray, this.init, 'SD'); // start execute from SD graph
    showExecution(linksArray, 0, this.init);
  }
  */

  // Alon - Changes initial temporal state
  setInitialState() {
    this.init.opmModel.logForUndo(this.getcurrentStateElement().logicalState.text + ' changed initial');
    const state = this.getcurrentStateElement().drawnState;
    // state.attr('image/xlink:href', 'assets/SVG/defaultState.svg');
    const IcheckedInput = this.getcurrentStateElement().drawnState.attr('.outer/stroke-width') === 2 ? 3 : 2;
    const object = state.getParent();
    const allStates = object.getStatesOnly();
    // If there is no other initial state.
    if (IcheckedInput === 3 && !allStates.find(s => s !== state && s.getVisual().logicalElement.stateType.includes('initial'))) {
      this.getcurrentStateElement().drawnState.attr('.outer/stroke-width', 3);
    } else if (IcheckedInput === 2) {
      this.getcurrentStateElement().drawnState.attr('.outer/stroke-width', 2);
    } else {
      validationAlert('Only one state can be the initial state.', 3000, 'error');
      return;
    }
    (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = this.getcurrentStateElement().drawnState.checkType();
  }
  // Alon - Changes default temporal state
  setDefaultState() {
    this.init.opmModel.logForUndo(this.getcurrentStateElement().logicalState.text + ' changed default');
    const state = this.getcurrentStateElement().drawnState;
    const object = state.getParent();
    const allStates = object.getStatesOnly();
    // If there is no other default state.
    if (!(<any>state.getVisual().logicalElement).stateType.includes('default') &&
      !allStates.find(s => s !== state && s.getVisual().logicalElement.stateType.includes('default'))) {
      // state.attr('image/xlink:href', 'assets/SVG/defaultState.svg');
      this.getcurrentStateElement().drawnState.attr('image/display', 'flex');
    } else if ((<any>state.getVisual().logicalElement).stateType.includes('default')) {
      this.getcurrentStateElement().drawnState.attr('image/display', 'none');
    } else {
      validationAlert('Only one state can be the default state.', 3000, 'error');
      return;
    }
    (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = (<OpmState>(this.selected)).checkType();
  }
  // Alon - Changes final temporal state
  setFinalState() {
    this.init.opmModel.logForUndo(this.getcurrentStateElement().logicalState.text + ' changed final');
    const state = this.getcurrentStateElement().drawnState;
    // state.attr('image/xlink:href', 'assets/SVG/defaultState.svg');
    const FcheckedInput = (this.getcurrentStateElement().drawnState.attr('.inner/stroke-width') === 0) ? 2 : 0;
    const object = state.getParent();
    const allStates = object.getStatesOnly();
    // If there is no other initial state.
    if (FcheckedInput === 2 && !allStates.find(s => s !== state && s.getVisual().logicalElement.stateType.includes('final'))) {
      this.getcurrentStateElement().drawnState.attr('.inner/stroke-width', 2);
    } else if (FcheckedInput === 0) {
      this.getcurrentStateElement().drawnState.attr('.inner/stroke-width', 0);
    } else {
      validationAlert('Only one state can be the final state.', 3000, 'error');
      return;
    }
    (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = (<OpmState>(this.selected)).checkType();
    if (this.getcurrentStateElement().drawnState.attr('.inner/stroke-width') === 2) {
      this.setStatesButtonsActiveStyle('svgRectFinal', 'svgRectTextFinal');
    } else {
      this.setStatesButtonDeActiveStyle('svgRectFinal', 'svgRectTextFinal');
    }
  }

  getStateType(state: any) {
    return (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType;
  }
  setCurrentState() {
    this.init.opmModel.logForUndo(this.getcurrentStateElement().logicalState.text + ' changed to current');
    const state = this.getcurrentStateElement().drawnState;
    if (!(<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType.includes('Current')) {
      const object = state.getParent();
      const allStates = object.getStatesOnly();
      if (allStates.find(s => s !== state && s.getVisual().logicalElement.stateType.includes('Current'))) {
        validationAlert('Only one state can be the current state at a time.', 3000, 'error');
        return;
      }
      // state.attr('image/xlink:href', 'assets/SVG/currentState.svg');
      // (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = this.getcurrentStateElement().drawnState.checkType();
      (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = 'Current';
      state.attr('.currentImg/display', 'flex');
    } else {
      state.attr('.currentImg/display', 'none');
      (<OpmLogicalState>(this.getcurrentStateElement().logicalState)).stateType = 'none';
      // state.attr('image/xlink:href', 'assets/SVG/defaultState.svg');
    }
  }



  // Alon - retrurns the logical state and drawn state
  getcurrentStateElement() {
    let logicalState;
    const visualState = this.init.opmModel.getVisualElementById((<OpmState>(this.selected)).id);
    if (visualState) {
      logicalState = this.init.opmModel.getLogicalElementByVisualId(visualState.id);
    }
    const drawnState = (<OpmState>(this.selected));

    return {
      logicalState, drawnState
    };


  }

  // Alon - sets the active button styling
  setStatesButtonsActiveStyle(idRect: string, idText: string) {
    // (document.getElementById(idRect)) ? document.getElementById(idRect).style.fill = '#1A3763' : document.getElementById(idRect);
    // (document.getElementById(idRect)) ? document.getElementById(idRect).style.fillOpacity = '1' : document.getElementById(idRect);
    // (document.getElementById(idText)) ? document.getElementById(idText).style.fill = '#FFF' : document.getElementById(idText);
  }

  // Alon- return to normal styling
  setStatesButtonDeActiveStyle(idRect: string, idText: string) {
    // (document.getElementById(idRect)) ? document.getElementById(idRect).style.fill = 'rgba(73, 114, 132, 0.09)' : document.getElementById(idRect);
    // (document.getElementById(idRect)) ? document.getElementById(idRect).style.fillOpacity = '0.9' : document.getElementById(idRect);
    // (document.getElementById(idText)) ? document.getElementById(idText).style.fill = '#1A3763' : document.getElementById(idText);
  }

  autoFormatName() {
    (<OpmEntity>(this.selected)).toggleAutoFormat(this.init);
    this.isAutoFormat = !this.isAutoFormat;
  }

  editUnits() {
    (<OpmObject>(this.selected)).editUnitsPopup(this.init);
  }

  editAlias() {
    const visual = this.selected.getVisual();
    if (!((<any>visual.logicalElement).getBelongsToStereotyped())) {
      // prevent edit/add an alias for an object that belongs to a stereotype (except the main thing of the stereotype)
      (<OpmObject>(this.selected)).editAliasPopup(this.init);
    }
  }


  toggleTextSizeMenu() {
    if (!this.textChangeMenu) {
      this.textChangeMenu = true;
      this.currentFontSize = this.selected.attr(this.selected.get('type') === 'opm.Note' ? '.noteContentText/font-size' : 'text/font-size');
      return;
    } if (this.textChangeMenu) {
      this.textChangeMenu = false;
      this.currentFontSize = '';
      return;
    }
  }
  incomingfile(event) {
    if (event.target.files.length === 0) {
      this.file = null;
      this.uploaded = false;
      return;
    }
    this.file = event.target.files[0];
    this.uploaded = true;
    this.excelFileReader();
  }
  excelFileReader() {
    const fileReader = new FileReader();
    fileReader.onload = (e) => {
      this.arrayBuffer = fileReader.result;
      const data = new Uint8Array(this.arrayBuffer);
      const arr = new Array();
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const bstr = arr.join('');
      const workbook = XLSX.read(bstr, { type: 'binary' });
      const first_sheet_name = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[first_sheet_name];
      this.thing = XLSX.utils.sheet_to_json(worksheet, { raw: true });
      this.uploaded = true;
    };
    fileReader.readAsArrayBuffer(this.file);
  }
  numberOfRunsChange() {
    const value = (<HTMLInputElement>document.getElementById('numberOfRuns')).value;
    if (value === '') {
      (<HTMLInputElement>document.getElementById('numberOfRuns')).value = '1';
    }
    this.numberOfRuns = Number(value);
    if (this.numberOfRuns > 9) {
      this.downloadCSV = true;
    }
  }
  downloadFileEvery() {
    const value = (<HTMLInputElement>document.getElementById('downloadEvery')).value;
    if (value === '') {
      (<HTMLInputElement>document.getElementById('downloadEvery')).value = '1';
    }
    this.downloadCSVEvery = Number(value);
  }
  toggleCSVDownload() {
    if (this.downloadCSV) {
      this.downloadCSV = false;
      return;
    }
    this.downloadCSV = true;
    getInitRappidShared().dialogService.openDialog(DownloadCSVComponent, 600, 900, { test: 'test', doNotClose: 'true' });
    this.toggleMenu();
  }


  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.isActive = true;
      return;
    } else if (!this.menuOpen) {
      this.menuOpen = true;
      this.isActive = false;
    }
  }
  // getNumberOfSimulation() {
  //   return this.numberOfRuns;
  // }
  simulation() {
    getInitRappidShared().dialogService.openDialog(SimulationComponent, 700, 600, { test: 'test' });
    this.toggleMenu();
  }
  changeTextSize(val) {
    const init = getInitRappidShared();
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    this.init.getOpmModel().logForUndo('Font size change');
    for (const element of elements) {
      const cellView = init.paper.findViewByModel(element);
      if (val && cellView && cellView.el) {
        this.init.graph.startBatch('ignoreEvents');
        const isNote = element.get('type') === 'opm.Note';
        const prevSize = element.attr(isNote ? '.noteContentText/font-size' : 'text/font-size');
        const propertyName = isNote ? ".noteContentText" : "text";
        element.attr({ [propertyName] : { 'font-size': val } });
        if (isNote) {
          element.attr({ '.noteTitleText' : { 'font-size': val } });
        } else if (prevSize < val) {
          element.autosize(this.init);
          this.fixTextOverFlow(element);
        }
        // if the text shown on screen is nothing
        if (cellView.el.textContent === '-') {
          element.attr({ [propertyName] : { 'font-size': prevSize } });
          validationAlert('Font size is too big. Enlarge the element size or choose smaller font size.');
        }
        this.init.graph.stopBatch('ignoreEvents');
      }
    }
    this.toggleTextSizeMenu();
  }

  toggleTextColorMenu() {
    if (!this.textColor) {
      this.textColor = true;
      return;
    } if (this.textColor) {
      this.textColor = false;
      return;
    }
  }

  changeTextColor(color) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    this.init.opmModel.logForUndo('Text Color Change');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'changeTextColor');
    for (const element of elements) {
      element.attr({ text: { fill: color } });
      if (OPCloudUtils.isInstanceOfDrawnNote(element)) {
        element.attr('.noteTitleText/fill', color);
        element.attr('.noteContentText/fill', color);
      }
    }
    this.init.opmModel.setShouldLogForUndoRedo(true, 'changeTextColor');
  }

  toggleStrokeMenu() {
    if (!this.strokeColor) {
      this.strokeColor = true;
      return;
    } if (this.strokeColor) {
      this.strokeColor = false;
      return;
    }
  }

  changeStrokeColor(color) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    this.init.opmModel.logForUndo('Border Color Change');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'changeStrokeColor');
    for (const element of elements) {
      if (element instanceof OpmObject) {
        element.attr({ rect: { stroke: color } });
        element.updateDuplicationMarkBorderColor(color);
      } else if (element instanceof OpmState) {
        element.attr({ rect: { stroke: color } });
        element.attr('rect/stroke', color);
        element.attr('.inner/stroke', color);
        element.attr('.outer/stroke', color);
        element.updateDuplicationMarkBorderColor(color);
      } else if (element instanceof OpmProcess) {
        element.attr({ ellipse: { stroke: color } });
        element.updateDuplicationMarkBorderColor(color);
      } else if (element instanceof Note) {
        (<any>element).attr({ '.notes': { stroke: color } });
        (<any>element).attr({ '.stickyPart': { stroke: color } });
      }
    }
    this.init.opmModel.setShouldLogForUndoRedo(true, 'changeStrokeColor');
  }

  toggleFontMnue() {
    if (!this.textFont) {
      this.textFont = true;
      return;
    } if (this.textFont) {
      this.textFont = false;
      return;
    }
  }

  changeFont(fontStyle) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    this.init.getOpmModel().logForUndo('Font change');
    for (const element of elements) {
      const isNote = element.get('type') === 'opm.Note';
      const propertyName = isNote ? ".noteContentText" : "text";
      element.attr({ [propertyName]: { 'font-family': fontStyle } });
      if (isNote) {
        this.selected.attr({ '.noteTitleText' : { 'font-family': fontStyle } });
      }
    }
    this.toggleFontMnue();
  }

  toggleFillMenu() {
    if (!this.fillColor) {
      this.fillColor = true;
      return;
    } if (this.fillColor) {
      this.fillColor = false;
      return;
    }
  }
  changeFillColor(color) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    this.init.opmModel.logForUndo('Fill Color Change');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'changeFillColor');
    for (const element of elements) {
      if (element instanceof OpmObject) {
        element.attr({ rect: { fill: color } });
        element.updateDuplicationMarkFillColor(color);
      } else if (element instanceof OpmState) {
        (<OpmState>element).setOriginalColor(color);
        element.attr({ rect: { fill: color } });
        element.attr('rect/fill', color);
        element.attr('.inner/fill', color);
        element.attr('.outer/fill', color);
        (<OpmState>element).setOriginalColor(color);
        element.updateDuplicationMarkFillColor(color);
      } else if (element instanceof OpmProcess) {
        element.attr({ ellipse: { fill: color } });
        element.updateDuplicationMarkFillColor(color);
        element.updateView(element.getVisual() as OpmVisualThing);
      } else if (element instanceof Note) {
        (<any>element).attr({ '.notes': { fill: color } });
        (<any>element).attr({ '.stickyPart': { fill: color } });
      }
    }
    this.init.opmModel.setShouldLogForUndoRedo(true, 'changeFillColor');
  }

  returnToDefaultAttributes() {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    for (const element of elements) {
      const thing = (element.attributes.type === 'opm.Process') ? 'ellipse' : 'rect';
      const thingTYpe = element.attributes.type;
      const entType = element.attributes.type.split('.')[1].toLowerCase();
      if (OPCloudUtils.isInstanceOfDrawnState(element)) {
        element.attr('rect/fill', '#FFFFFF');
        element.attr('.inner/fill', '#FFFFFF');
        element.attr('.outer/fill', '#FFFFFF');
        element.attr('.inner/stroke', '#808000');
        element.attr('.outer/stroke', '#808000');
        element.attr('rect/stroke', '#808000');
        element.setOriginalColor('#FFFFFF');
        element.getVisual().strokeColor = '#808000';
        (<any>element.getVisual()).fill = '#ffffff';
        element.attr('text/fill', 'black');
        element.attr('text/font-family', 'Arial, helvetica, sans-serif');
        this.changeTextSize(14);
      } else {
        const userStyles = element.getDefaultStyleParams(this.init);
        element.attr('text/fill', userStyles ? userStyles[entType].text_color : 'black');
        element.attr(`${thing}`, { fill: userStyles ? userStyles[entType].fill : getStyles(thingTYpe).fill });
        element.attr(`${thing}`, { stroke: userStyles ? userStyles[entType].border_color : getStyles(thingTYpe).stroke });
        element.attr('text/font-family', userStyles ? userStyles[entType].font : 'Arial, helvetica, sans-serif');
        this.changeTextSize(userStyles ? userStyles[entType].font_size : 14);
      }
      element.attr('text/text-anchor', 'middle');
      this.textChangeMenu = false;

      if (element.attributes.type === 'opm.Note') {
        element.removeAttr('rect');
        element.removeAttr('text');
        element.attr('.stickyPart/fill', '#fff2ab');
        element.attr('.notes/fill', '#fff7d1');
        //this.selected.attr('.noteContentText/font-size', 14);
        element.attr('.noteTitleText/font-size', 16);
      }
      if (element instanceof OpmEntity) {
        this.resetElementTextPosition(element);
      }
    }

  }
  updateValues(iterationNum, initRappid) {
    const valueArray = this.thing[iterationNum];
    const names = Object.keys(valueArray);
    const values = Object.values(valueArray);
    const currentOpmModelElements = initRappid.opmModel.logicalElements;
    for (let i = 0; i < currentOpmModelElements.length; i++) {
      if (currentOpmModelElements[i] instanceof OpmLogicalObject &&
        (<OpmLogicalObject>currentOpmModelElements[i]).valueType !== valueType.None) { // computational
        const logOb = currentOpmModelElements[i]; // logical object for updating value
        if (logOb.getSimulationParams().simulated) {
          logOb.value = logOb.getRandomValues(1)[0];
        }
        let logObName = textToName((<OpmLogicalObject>logOb).text);
        const logObAlias = (<OpmLogicalObject>logOb).alias;
        for (let j = 0; j < names.length; ++j) {
          logObName = logObName.replace(/\s/g, '');
          names[j] = names[j].replace(/\s/g, '');
          if ((names[j] === logObName) || (names[j] === logObAlias)) {
            (<OpmLogicalObject>initRappid.opmModel.logicalElements[i]).value = values[j];
            this.updateDrawnValues(logOb, values[j]);
          }
        }
      }
    }
  }

  updateDrawnValues(logical: OpmLogicalObject, value) {
    for (const vis of logical.visualElements) {
      const visState = vis.states[0];
      const drawnState = this.init.graph.getCell(visState?.id);
      if (drawnState) {
        this.init.graph.startBatch('ignoreEvents');
        drawnState.attr('text/textWrap/text', String(value));
        this.init.graph.stopBatch('ignoreEvents');
      }
    }
  }

  public syncExecute(fromDialog?: boolean) {
    if (this.isHeadlessRunner) {
      if (!fromDialog) {
        const dialogRef = this.dialog.open(HeadlessRunnerComponent,  {disableClose: true,
          height: '150px',
          width: '350px',
          backdropClass: 'backdropBackground',
          panelClass: 'trend-dialog',
          autoFocus: false
        });
        this.Execute(headlessRunner);
      }
      this.Execute(headlessRunner);
    } else {
      this.Execute(syncRunner);
    }
  }

  public asyncExecute() {
    this.Execute(asyncRunner);
  }

  async Execute(runner: ExecutionRunner) {
    this.simulate = !!this.init.getOpmModel().logicalElements.find(log => (<any>log).simulationModule && (<any>log).getSimulationParams().simulated === true);
    if (this.init.Executing === false) {
      valuesArray = copyAllObjectValues(valuesArray, this.init);
      this.init.Executing = true;
      this.Executing = true;
      // array that contains all the link ids needed to be visualized by a token
      const allValuesArray = [];  // saves values for exporting
      const exportArray = [];
      let linksArray = [];
      // TODO: fileName need to be taken from element-toolbar
      // If the user didn't give a name to the exported file - get default file name
      const fileName = this.init.opmModel.createDefaultModelName().trim();
      const interimSavePoints = this.downloadCSVEvery;
      // execution run loop
      if (this.simulate && !this.uploaded) {
        this.thing = this.getSimulationData(this.numberOfRuns);
      }
      if (this.uploaded === true) {
        this.numberOfRuns = this.thing.length;
      }
      const runParams = {
        runNumber: 0,
        numberOfRuns: this.numberOfRuns,
        downloadCSVEvery: interimSavePoints,
        downloadCSV: this.downloadCSV,
        runner: runner,
        allValuesArray: allValuesArray,
        exportArray: [],
        exportExcelData: [],
        refsIds: [],
        linksArray: linksArray
      };
      const sc = this.init.generatedSelectedConfigurations;
      for (let i = 0; i < this.numberOfRuns; i++) {
        linksArray = [];
        if (this.uploaded || this.simulate) {
          this.updateValues(i, this.init);
        }
        if (this.runByConfigurations && sc?.length > 0) {
          this.init.opmModel.setCurrentConfiguration(sc[i] ? sc[i] : sc[i % sc.length]);
        }
        const allProcesses = getAllComputationalProcesses();
        runParams.runNumber = i;
        runParams.linksArray = [];
        runParams.exportArray = runParams.exportArray.length > 0 ? runParams.exportArray : [];
        if (this.init.includeSubModelsInExecution) {
          await this.init.opdHierarchyRef.loadAllSubModels();
        }
        if (this.runByConfigurations) {
          this.init.graphService.renderGraph(this.init.opmModel.currentOpd);
        }
        let error;
        await validateUnits(allProcesses, 0, runParams).then(res => {
          if (this.runByConfigurations && i === this.numberOfRuns - 1) {
            this.init.opmModel.setCurrentConfiguration(undefined);
            this.init.graphService.renderGraph(this.init.opmModel.currentOpd);
          }
        }).catch(err => {
          error = err?.message;
        });
        if (error) {
          if (String(error).includes('Unsupported unit')) {
            validationAlert(error, 5000, 'Error');
            $('#alertDiv').css({'position': 'absolute', 'top': '120px'});
          }
          this.init.Executing = false;
          this.init.getGraphService().refreshGraph(this.init);
          return;
        };
      }
    }
    if (this.init.ExecutingPause === true) {
      this.init.ExecutingPause = false;
      this.ExecutingPause = false;
    }
  }

  getSimulationData(numberOfSimulations: number) {
    return this.init.getOpmModel().getSimulatedData(numberOfSimulations);
  }
  Simulate() {
    getInitRappidShared().dialogService.openDialog(SimulationComponent, 700, 600, { test: 'test' });
    this.toggleMenu();
  }
  ExecuteStop() {
    if (this.init.Executing === true) {
      updateAllObjectValues(valuesArray, this.init);
    }
    this.init.Executing = false;
    this.Executing = false;
    if (this.init.ExecutingPause === true) {
      $('#executionButton1').off('click');
      this.init.ExecutingPause = false;
      this.ExecutingPause = false;
    }
  }
  ExecutePause() {
    if (this.init.Executing === true) {
      this.init.ExecutingPause = true;
      this.ExecutingPause = true;
    }
  }

  arrangeObjects(direction: 'left' | 'right' | 'top' | 'bottom'): void {
    if (this.selectionArray.length > 1) {
      this.init.opmModel.logForUndo('Arrange Things');
      this.init.opmModel.setShouldLogForUndoRedo(false, 'Arrange Things');
      this.init.getGraphService().arrangeObjects(direction, this.init, this.selectionArray);
      this.init.opmModel.setShouldLogForUndoRedo(true, 'Arrange Things');
    }
  }

  pointerUpHandle() {
    this.selectionBox.selectBoxWidth = this.init.selection.el.firstChild.style.width;
    this.selectionBox.selectBoxHeight = this.init.selection.el.firstChild.style.height;
    this.selectionBox.selectBoxLeft = this.init.selection.el.firstChild.style.left;
    this.selectionBox.selectBoxTop = this.init.selection.el.firstChild.style.top;
    setSelect(this.selectionBox);
  }
  AddURL() { // clicking URL icon, opening  floating window
    const dialogRef = this._dialog.open(SaveURLComponent, {
      height: '600px',
      width: '560px',
      data: { Element: this.init.selectedElement.id }
    });
  }

  beautifyConnectedLinks($event) {
    if ($event.altKey) {
      for (const ent of this.init.graph.getCells().filter(c => c.constructor.name.includes('Object') ||
        c.constructor.name.includes('Process') || c.constructor.name.includes('State'))) {
        ent.beautifyConnectedLinks(this.init);
      }
      return;
    }
    this.init.selectedElement.beautifyConnectedLinks(this.init);
  }

  toggleStylingDiv() {
    const newVal = !this.showStylingDiv;
    this.closeAllSubToolBars();
    this.showStylingDiv = newVal;
  }

  toggleStatesArrangementDiv() {
    const newVal = !this.showStatesArrangementDiv;
    this.closeAllSubToolBars();
    this.showStatesArrangementDiv = newVal;
  }

  closeAllSubToolBars() {
    this.showStylingDiv = false;
    this.showStatesArrangementDiv = false;
    this.showExtensionsDiv = false;
    this.showTemplatesDiv = false;
    this.isViewsDivOpen = false;
    this.showOpmRequirementsDiv = false;
    this.showThingBackgroundDiv = false;
    this.showConnectionsDiv = false;
    this.textChangeMenu = false;
    this.currentFontSize = '';
    this.closeBringConnectedSubDiv();
  }

  closeBringConnectedSubDiv() {
    this.showBringConnectedDiv = false;
    this.init.currentBringConnectedSettings = {...this.init.oplService.settings.bringConnectedSettings};
  }

  visibleNavBarClick($event) {
    if ($event.target.id === 'visibleNav') {
      this.closeAllSubToolBars();
    }
  }

  shouldShowOutzoom() {
    const visuals = this.init.selection.collection.models.map(dr => this.init.opmModel.getVisualElementById(dr.id));
    if (visuals.length <= 1) {
      return false;
    }
    const things = visuals.filter(en => en instanceof OpmVisualThing);
    const condition1 = things.length === visuals.length;

    return condition1 && (this.isProcess || this.isObject && !(this.selected.attributes.type === 'opm.Ellipsis') && !(this.isComputational));
  }

  shouldShowBringLinkBetweenSelected() {
    const visuals = this.init.selection.collection.models.map(dr => this.init.opmModel.getVisualElementById(dr.id)).filter(c => !!c);
    if (visuals.length <= 1) {
      return false;
    }
    const things = visuals.filter(en => en instanceof OpmVisualEntity);
    return things.length > 1;
  }

  getSelectedElementFill() {
    if (this.init.selectedElement) {
      if (this.init.selectedElement instanceof OpmObject) {
        return this.init.selectedElement.attr('rect/fill');
      } else if (this.init.selectedElement instanceof OpmProcess) {
        return this.init.selectedElement.attr('ellipse/fill');
 } else if (this.init.selectedElement instanceof Note) {
        return (<any>this.init.selectedElement).attr('rect/fill') || (<any>this.init.selectedElement).attr('.notes/fill');
 }
      return this.init.selectedElement.attr('rect/fill') ?
        this.init.selectedElement.attr('rect/fill') : this.init.selectedElement.attr('.outer/fill');
    } else { return '#FFFFFF'; }
  }

  getSelectedElementStroke() {
    if (this.init.selectedElement) {
      if (this.init.selectedElement instanceof OpmObject) {
        return this.init.selectedElement.attr('rect/stroke');
      } else if (this.init.selectedElement instanceof OpmProcess) {
        return this.init.selectedElement.attr('ellipse/stroke');
 } else if (this.init.selectedElement instanceof Note) {
        return (<any>this.init.selectedElement).attr('rect/stroke') || (<any>this.init.selectedElement).attr('.notes/stroke');
 } else if (this.init.selectedElement instanceof OpmState) {
        return this.init.selectedElement.attr('rect/stroke') ?
          this.init.selectedElement.attr('rect/stroke') : this.init.selectedElement.attr('.outer/stroke');
 }
    } else { return '#FFFFFF'; }
  }

  shouldShowStyling() {
    return !this.isLink && !(this.selected.attributes.type === 'opm.Ellipsis') && !this.selected.get('type').includes('Triangle');
  }

  shouldShowSemifolding() {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const thing = this.selected.getVisual() as OpmVisualThing;

    if (!thing || thing.getRefineeInzoom() === thing) {
      return false;
    }

    if (!thing || !thing.logicalElement) { return false; }
    const links = thing.getAllLinks().outGoing.filter(rel => !rel.logicalElement.isAtOPD(this.init.opmModel.currentOpd) &&
      !thing.semiFolded.find(r => r.logicalElement === rel.target.logicalElement));

    const toAdd = links.map(link => {
      return { type: link.type, target: link.target.logicalElement, link: link };
    }).filter(rel => [linkType.Exhibition, linkType.Generalization, linkType.Instantiation, linkType.Aggregation].includes(rel.type)
      && (!rel.link.target.fatherObject || !thing.semiFolded.find(visual => visual.logicalElement === rel.link.target.fatherObject?.logicalElement)));

    if (thing.getRefineeInzoom()) {
      const innerThings = thing.getRefineeInzoom().children.filter(chld => chld.constructor.name === thing.constructor.name);
      for (const child of innerThings) {
        if (!toAdd.find(item => item.type === linkType.Aggregation && (<any>item.target).logicalElement === child.logicalElement) &&
          !thing.semiFolded.find(foldedVis => foldedVis.logicalElement === child.logicalElement) &&
          !thing.getLinks().outGoing.find(lnk => lnk.target.logicalElement === child.logicalElement)) {
          return true;
        }
      }
    }

    if (toAdd.length === 0) {
      return false;
    }

    return true;
  }

  shouldShowRemoveSemifolding(): boolean {
    if (!this.isProcess && !this.isObject) {
      return false;
    }

    const thing = this.selected?.getVisual() as OpmVisualThing;
    if (thing?.isSemiFolded()) {
      return true;
    }

    return false;
  }

  showExistingStereotypeOpd(existingSterotype: OpmStereotype) {
    this.init.opmModel.currentOpd = existingSterotype.getOpd();
    this.init.getGraphService().renderGraph(existingSterotype.getOpd(), this.init);
    highlighSD(existingSterotype.getOpd().id, this.init);
  }

  stereotypeAction() {
    const this_ = this;
    const logical = (<OpmLogicalThing<OpmVisualThing>>this.selected.getVisual().logicalElement);
    const existingSterotype = logical.getStereotype();
    if (existingSterotype) {
      return this.showExistingStereotypeOpd(existingSterotype);
    }
    if (logical.protectedFromBeingRefinedBySubModel) {
      validationAlert('Cannot set a stereotype to a a thing that is shared with a sub model.', 5000, 'error');
      return;
    }
    if (this.selected.constructor.name.includes('Object') && (<OpmVisualObject>this.selected.getVisual()).states.length > 0) {
      validationAlert('Cannot set stereotype to an object with states.', 5000, 'error');
      return;
    }
    if ((<OpmVisualThing>this.selected.getVisual()).getRefineeInzoom()) {
      validationAlert('Cannot set stereotype to a refined thing.', 5000, 'error');
      return;
    }

    if ((<OpmLogicalThing<OpmVisualThing>>this.selected.getVisual().logicalElement).isSatisfiedRequirementSetObject()) {
      validationAlert('Cannot set stereotype to a requirement set object.', 5000, 'error');
      return;
    }

    if ((<OpmLogicalThing<OpmVisualThing>>this.selected.getVisual().logicalElement).hasRequirements()) {
      validationAlert('Cannot set stereotype for a thing with requirements.', 5000, 'error');
      return;
    }

    const dialogRef = this.dialog.open(StereotypesDialogComponent, {
      height: '610px',
      width: '873px',
      data: {
        showVersions: false,
        mode: StereotypeStorageMode.SET,
        name: '',
        description: ''
      },
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        let ret;
        if (result.opds?.filter(o => !o.isHidden).length > 1) {
          ret = { success: false, reason: 'Currently, OPCloud supports only stereotypes with one level diagram.' };
        } else {
          ret = this_.init.getOpmModel().addStereotypeToThing(result, <OpmLogicalThing<OpmVisualThing>>this.selected.getVisual().logicalElement);
        }
        if (ret.success) {
          this.init.treeViewService.init(this.init.opmModel);
          this.selected.updateView(<any>this.selected.getVisual());
          this.init.criticalChanges_.next();
        } else {
          validationAlert(ret.reason, 5000, 'error');
        }
      }
    });
    // this.createDummyStereotype(this.init.opmModel);
    // this.init.getOpmModel().addStereotypeToThing(modelAsJson, this.selected.getVisual().logicalElement);
    // this.init.treeViewService.init(this.init.opmModel);
    // this.selected.updateView(<any>this.selected.getVisual());
  }

  createDummyStereotype(model: OpmModel) {
    model.addStereotypeToThing(exampleStereotype, <OpmLogicalThing<OpmVisualThing>>this.selected.getVisual().logicalElement);
  }

  shouldShowSetStereotypeButton() {
    const condition0 = this.selected && this.selected.getVisual && !!this.selected.getVisual();
    if (condition0 === false) { return false; }
    const condition1 = !!this.isProcess || !!this.isObject;
    if (condition1 === false) { return false; }
    const condition2 = !!!(this.selected.attributes.type === 'opm.Ellipsis');
    if (condition2 === false) { return false; }
    const condition3 = !(<any>this.selected.getVisual().logicalElement).getBelongsToStereotyped();
    const condition4 = (<any>this.selected.getVisual().logicalElement).getStereotype();
    return (condition0 && condition1) && condition2 && (condition3 || condition4);
  }

  shouldShowConnectSubModel() {
    if (this.isExample() || this.isTemplate() || this.isStereotype()) {
      return false;
    }
    const selected = this.init.selection.collection.models;
    const objects = selected.filter(en => OPCloudUtils.isInstanceOfDrawnObject(en));
    const processes = selected.filter(en => OPCloudUtils.isInstanceOfDrawnProcess(en) && !en.getParentCell());
    if (objects.length === 0 || processes.length === 0) {
      return false;
    }
    for (const obj of objects) {
      const visObj = obj.getVisual();
      for (const proc of processes) {
        const visProc = proc.getVisual();
        if (!visProc) {
          continue;
        }
        const links = visProc.getLinksWith(visObj).inGoing;
        const exh = links.find(l => l.type === linkType.Exhibition);
        const instrument = links.find(l => l.type === linkType.Instrument);
        if (exh && instrument)
          return true;
      }
    }
    return false;
  }

  stereotypeTooltipMsg() {
    if ((<any>this.selected.getVisual().logicalElement).getStereotype()) {
      return 'Show Stereotype';
    }
    return 'Set Stereotype';
  }

  markedThingsAction() {
    const new_val = !this.init.shouldGreyOut;
    this.init.oplService.updateUserSettings({ markThings: new_val });
    this.init.updateDB({ markThings: new_val });
    for (const cell of this.init.graph.getCells().filter(c => c instanceof OpmEntity)) {
      cell.greyOutEntity();
    }
  }

  findAction() {
    const data = { message: 'Warning: Not a valid search!', closeFlag: true };
    this.init.dialogService.openDialog(SearchItemsDialogComponent, 530, 600, data);
  }

  openInstancesDialog() {
    const data = { closeFlag: true };
    this.init.dialogService.openDialog(multiInstancesDialog, window.innerHeight * 0.9, window.innerWidth * 0.6, data);
  }


  /**
   * group 04:
   * This function is called when the user clicks on the brush icon.
   * In this function we keep the style of the selected element in a dictionary named 'copiedStyleParams'.
   * @param $event
   * @param isRightClick
   */
  regularCopyStyle($event, isRightClick: boolean) {
    if (this.init.copiedStyleParams) {
      this.init.copiedStyleParams = null;
      return;
    }
    $event.preventDefault();
    $event.stopPropagation();
    $event.stopImmediatePropagation();
    if (this.init.selectedElement.constructor.name.includes('Note')) {
      return;
    }
    const dict = {};
    dict['isRightClick'] = isRightClick;
    dict['borderColor'] = this.init.selectedElement.getVisual().strokeColor;
    dict['fontSize'] = this.init.selectedElement.getVisual().textFontSize;
    dict['fillColor'] = this.getSelectedElementFill();
    dict['textColor'] = this.init.selectedElement.getVisual().textColor;
    dict['font'] = this.init.selectedElement.getVisual().textFontFamily;
    this.init.copiedStyleParams = dict;
  }

  /**
   * group 04:
   * this function is called when the user clicks 'Ctrl+Shift+C'.
   * The dialog that we created (in class StyleCopyingDialogComponent) will pop up.
   * @param $event
   * @param isRightClick
   */
  openPasteOptionsDialog($event, isRightClick: boolean) {
    if (this.init.copiedStyleParams) {
      this.init.copiedStyleParams = null;
      return;
    }
    this.init.dialogService.openDialog(StyleCopyingDialogComponent, 425, 350, { isRightClick: isRightClick });
  }

  /**
   * group 04:
   * This function calls the pasteStyle function that is implemented in the Drawn Part, with the 'copiedStyleParams' dictionary.
   */
  pasteStyleParams() {
    this.onSelection();
    this.init.opmModel.logForUndo(this.init.selectedElement.attr('text/textWrap/text') + ' Paste Style');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'pasteStyle');
    this.init.graph.startBatch('ignoreChange');
    if (this.init.selectedElement?.pasteStyle)
      this.init.selectedElement.pasteStyle(this.init.copiedStyleParams);
    if (OPCloudUtils.isInstanceOfDrawnEntity(this.init.selectedElement)) {
      this.init.getGraphService().updateEntity(this.init.selectedElement.getVisual() as OpmVisualEntity);
    }
    this.init.graph.stopBatch('ignoreChange');
    this.init.opmModel.setShouldLogForUndoRedo(true, 'pasteStyle');
    this.init.currentlyPastingStyleParams = true;
  }


  shouldShowUnlinkStereotypeButton() {
    if (this.selected && this.selected.getVisual && !!this.selected.getVisual() && (!!this.isProcess || !!this.isObject) &&
      (<any>this.selected.getVisual().logicalElement).getStereotype()) {
      return true;
    }
    return false;
  }

  unLinkStereotypeAction() {
    const vis = <any>this.selected.getVisual();
    const currentOpd = this.init.getOpmModel().currentOpd;
    const ret = this.init.getOpmModel().unLinkStereotype(vis);
    if (ret.success) {
      this.init.treeViewService.init(this.init.getOpmModel());
      this.init.getOpmModel().currentOpd = currentOpd;
      this.init.graphService.renderGraph(currentOpd);
      this.init.criticalChanges_.next();
    } else if (ret.reason) {
      validationAlert(ret.reason, 7000, 'Error');
    }
  }

  removeStereotypeAction() {
    this.init.getOpmModel().logForUndo('remove stereotype');
    const id = (<any>this.selected.getVisual()).logicalElement.lid;
    this.init.getOpmModel().setShouldLogForUndoRedo(false, 'remove stereotype' + id);
    const vis = <any>this.selected.getVisual();
    const ret = this.init.getOpmModel().removeStereotype(vis);
    if (ret.success) {
        this.init.treeViewService.init(this.init.getOpmModel());
        this.init.graphService.renderGraph(this.init.getOpmModel().currentOpd);
        this.init.criticalChanges_.next();
    } else if (ret.reason) {
        validationAlert(ret.reason, 7000, 'Error');
    }
    this.init.getOpmModel().setShouldLogForUndoRedo(true, 'remove stereotype' + id);
  }

  quitDsmView() {
    $('.selectedTab .closeTab').click();
  }

  toggleTextPos() {
    (<any>this.selected.getVisual()).toggleManualTextPos();
    if (!(<any>this.selected.getVisual()).isManualTextPos) {
      this.resetElementTextPosition(this.selected);
    }
  }

  resetElementTextPosition(element) {
    this.init.getGraphService().resetElementTextPosition(element);
  }

  shouldShowManualTextDiv() {
    return this.selected && (this.selected instanceof OpmEntity);
  }

  alignText(side) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    for (const element of elements) {
      if (element.constructor.name.includes('Note')) {
        element.attr('.noteContentText/text-anchor', side);
      } else {
        element.attr('text/text-anchor', side);
      }
    }
  }

  changeTextRef($event: MatSliderChange, axis: string) {
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    for (const element of elements) {
      if (axis === 'X') {
        element.attr('text/ref-x', $event.value);
      } else {
        element.attr('text/ref-y', $event.value);
      }
      this.fixTextOverFlow(element);
    }
  }

  predefinedTextPos(pos: string) {
    let refX = 0.5;
    let refY = 0.5;
    if (pos.startsWith('top')) {
      refY = 0.1;
    } else if (pos.startsWith('middle')) {
      refY = 0.5;
 } else if (pos.startsWith('bottom')) {
      refY = 0.9;
 }
    if (pos.endsWith('left')) {
      refX = 0.1;
    } else if (pos.endsWith('right')) {
      refX = 0.9;
 }
    const elements = this.init.selection.collection.models.filter(en => OPCloudUtils.isInstanceOfDrawnEntity(en) || OPCloudUtils.isInstanceOfDrawnNote(en));
    if (elements.length === 0) {
      elements.push(this.selected);
    }
    for (const element of elements) {
      element.attr('text/ref-x', refX);
      element.attr('text/ref-y', refY);
      this.fixTextOverFlow(element);
    }
  }

  fixTextOverFlow(element) {
    if (OPCloudUtils.isInstanceOfDrawnEntity(element))
      element.fixTextOverflow();
 //    const refX = this.selected.attr('text/ref-x');
 //    const refY = this.selected.attr('text/ref-y');
 //    if (refX > 0.5) {
 //      this.selected.attr('text/x-alignment', 'right');
 //    } else if (refX < 0.5) {
 //      this.selected.attr('text/x-alignment', 'left');
 // } else {
 //      this.selected.attr('text/x-alignment', 'middle');
 // }
 //
 //    if (refY > 0.5) {
 //      this.selected.attr('text/y-alignment', 'bottom');
 //    } else if (refY < 0.5) {
 //      this.selected.attr('text/y-alignment', 'top');
 // } else {
 //      this.selected.attr('text/y-alignment', 'middle');
 // }
    // const isState = this.selected.constructor.name.includes('State');
    // const isProcess = this.selected.constructor.name.includes('Process');
    // const paper = this.init.paper;
    // const vis = (<any>this.selected).getVisual();
    // if (!paper) return;
    // const cellView = paper.findViewByModel(this.selected);
    // const type = this.selected.constructor.name.includes('Process') ? 'ellipse' : 'rect';
    // if (!cellView) return;
    // let textBBox = cellView.el.getElementsByTagName('text')[0].getClientRects()[0];
    // if (textBBox) {
    //   let bbox = cellView.el.getClientRects()[0];
    //   while (textBBox.left < (bbox.left + (isProcess ? 30 : 15)) && vis.refX < 1 && ['both', 'X'].includes(axis)) {
    //     const newRefX = Math.min(vis.refX + 0.05, 1);
    //     this.selected.attr('text/ref-x', newRefX);
    //     vis.refX = newRefX;
    //     textBBox = cellView.el.getElementsByTagName('text')[0].getClientRects()[0];
    //   }
    //   while (textBBox.top < (bbox.top + (isState ? 5 : 10)) && vis.refY < 1 && ['both', 'Y'].includes(axis)) {
    //     const newRefY = Math.min(vis.refY + 0.05, 1);
    //     this.selected.attr('text/ref-y', newRefY);
    //     vis.refY = newRefY;
    //     textBBox = cellView.el.getElementsByTagName('text')[0].getClientRects()[0];
    //   }
    //   while (textBBox.right > (bbox.right - (isState ? 12 : (isProcess ? 30 : 15))) && vis.refX > 0 && ['both', 'X'].includes(axis)) {
    //     const newRefX = Math.max(vis.refX - 0.05, 0);
    //     this.selected.attr('text/ref-x', newRefX);
    //     vis.refX = newRefX;
    //     textBBox = cellView.el.getElementsByTagName('text')[0].getClientRects()[0];
    //   }
    //   while (textBBox.bottom > (bbox.bottom - (isState ? 5 : 10)) && vis.refY > 0 && ['both', 'Y'].includes(axis)) {
    //     const newRefY = Math.max(vis.refY - 0.05, 0);
    //     this.selected.attr('text/ref-y', newRefY);
    //     vis.refY = newRefY;
    //     textBBox = cellView.el.getElementsByTagName('text')[0].getClientRects()[0];
    //   }
    // }
  }

  getColorPalette() {
    const colorPalette = [
      { content: '#ffe800', tooltip: 'Yellow' },
      { content: '#F2802E', tooltip: 'Orange' },
      { content: '#2EF265', tooltip: 'Green' },
      { content: '#2EC3F2', tooltip: 'Teal' },
      { content: '#3e2ef2', tooltip: 'Blue' },
      { content: '#9C2EF2', tooltip: 'Purple' },
      { content: '#f22e80', tooltip: 'Pink' },
      { content: '#ff0000', tooltip: 'Red' },
      { content: 'transparent', tooltip: 'Transparent' }, // Canvas Color
      { content: '#838383', tooltip: 'Gray' },
      { content: '#FFF', tooltip: 'White' },
    ];
    return colorPalette;
  }

  openColorPopup($event, type: ColorTarget) {
    if ($('.joint-popup').length > 0) {
      return $('.joint-popup').remove();
    }
    const current_color = type === ColorTarget.STROKE ? this.getSelectedElementStroke() : (type === ColorTarget.FILL ? this.getSelectedElementFill() : (this.init.selectedElement.getVisual()?.textColor || this.init.selectedElement.attr('text/fill'))) ;
    let content = '<div id="colorsDiv" style="width:160px; user-select: none;">';
    const colors = [{ content: current_color, tooltip: 'Current Color' }].concat(this.getColorPalette());
    for (const item of colors) {
      const field = type === ColorTarget.STROKE ? `stroke="${item.content}"` : `fill="${item.content}"`;
      content += `
          <a class='colorBtn' title="${item.tooltip}" value="${item.content}"><svg width="36" height="35" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b)">
              <rect width="38" height="34" rx="4" transform="matrix(-1 0 0 1 38 0)" fill="#eee" fill-opacity="0.7" />
            </g>
            <rect width="24" height="21" rx="2" transform="matrix(-1 0 0 1 31 6)"
              ${field} />
            <defs>
              <filter id="filter0_b" x="-4" y="-4" width="46" height="42" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
              </filter>
            </defs>
          </svg></a>`;
    }
    content += '<br>Custom color: <input type="color" id="colorChooser" style="width: 25px; height: 22px; border-color: transparent; margin-bottom: 5px;" value="#FFFFFF">';
    content += `
      <a class='moreBtn' style="margin-left: 3px;">
      <svg width="14" height="16" viewBox="0 0 14 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.2168 6.32227H13.7676V8.71484H8.2168V15.0039H5.67383V8.71484H0.123047V6.32227H5.67383V0.511719H8.2168V6.32227Z" fill="#5A6F8F"/>
      </svg></a>`;
    for (const item of this.init.customColors) {
      content += this.addColor(item.content, type);
    }
    content += '</div>';
    const that = this;
    const popupEvents = {
      'click .colorBtn': function ($event) {
        if (type === ColorTarget.FILL) {
          that.changeFillColor($event.currentTarget.getAttribute('value'));
        } else if (type === ColorTarget.STROKE) {
          that.changeStrokeColor($event.currentTarget.getAttribute('value'));
        } else if (type === ColorTarget.TEXT) {
          that.changeTextColor($event.currentTarget.getAttribute('value'));
        }
      },
      'click .moreBtn': function ($event) {
        const color = ($('#colorChooser')[0] as any).value;
        that.init.customColors.push({ content: color });
        const htmlToAdd = that.addColor(color, type);
        $('#colorsDiv')[0].innerHTML += htmlToAdd;
      }
    };
    popupGenerator($event.target, content, popupEvents).render();
    stylePopup(false, false, false);
    const ppup = $('.joint-popup')[0] as HTMLDivElement;
    $('.joint-popup').addClass('fillStrokePopup');
    ppup.style.lineHeight = '1.6';
  }

  addColor(color: string, type: ColorTarget) {
    const field = type === ColorTarget.STROKE ? `stroke="${color}"` : `fill="${color}"`;
    const content = `
          <a class='colorBtn' value="${color}"><svg width="36" height="35" viewBox="0 0 38 34" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b)">
              <rect width="38" height="34" rx="4" transform="matrix(-1 0 0 1 38 0)" fill="#eee" fill-opacity="0.7" />
            </g>
            <rect width="24" height="21" rx="2" transform="matrix(-1 0 0 1 31 6)"
              ${field} />
            <defs>
              <filter id="filter0_b" x="-4" y="-4" width="46" height="42" filterUnits="userSpaceOnUse"
                color-interpolation-filters="sRGB">
                <feFlood flood-opacity="0" result="BackgroundImageFix" />
                <feGaussianBlur in="BackgroundImage" stdDeviation="2" />
                <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur" result="shape" />
              </filter>
            </defs>
          </svg></a>`;
    return content;
  }

  toggleExtensionsDiv() {
    const value = !this.showExtensionsDiv;
    if (value) {
      this.closeAllSubToolBars();
    }
    this.showExtensionsDiv = value;
  }

  toggleTemplatesDiv() {
    this.showTemplatesDiv = !this.showTemplatesDiv;
  }

  shouldShowTextAnchoring() {
    return this.selected && (this.selected.constructor.name.includes('Note') || this.selected instanceof OpmEntity);
  }

  // returns the current port. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcPort(type: string) {
    const user_port = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      type === 'mysql' ? this.init.oplService.settings.connection[type].ws_port : this.init.oplService.settings.connection[type].port : undefined;
    const organization_port = (this.init.oplService.orgSettings.connection && this.init.oplService.orgSettings.connection[type] !== undefined) ?
      type === 'mysql' ? this.init.oplService.orgSettings.connection[type].ws_port : this.init.oplService.orgSettings.connection[type].port : undefined;
    const default_port = type === 'ros' ? defaultRosConnectionSettings.port : type === 'mqtt' ? defaultMqttConnectionSettings.port : type === 'mysql' ? defaultMySQLConnectionSettings.ws_port : defaultPythonConnectionSettings.port;
    return (user_port !== undefined) ? user_port : (organization_port !== undefined ? organization_port : default_port);
  }
  // returns the current server. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcServer(type: string) {
    const user_server = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      type === 'mysql' ? this.init.oplService.settings.connection[type].ws_hostname : this.init.oplService.settings.connection[type].server : undefined;
    const organization_server = (this.init.oplService.orgSettings.connection && this.init.oplService.orgSettings.connection[type] !== undefined) ?
      type === 'mysql' ? this.init.oplService.orgSettings.connection[type].ws_hostname : this.init.oplService.orgSettings.connection[type].server : undefined;
    const default_server = type === 'ros' ? defaultRosConnectionSettings.server : type === 'mqtt' ? defaultMqttConnectionSettings.server : type === 'mysql' ? defaultMySQLConnectionSettings.ws_hostname : defaultPythonConnectionSettings.server;
    return (user_server !== undefined) ? user_server : (organization_server !== undefined ? organization_server : default_server);
  }

  // returns the current mysql hostname. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcMySQLPort(type: string) {
    const user_port = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].port : undefined;
    const default_port = defaultMySQLConnectionSettings.port ;
    return (user_port !== undefined) ? user_port : default_port;
  }

  // returns the current mysql port. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcMySQLHostname(type: string) {
    const user_hostname = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].hostname : undefined;
    const default_hostname = defaultMySQLConnectionSettings.hostname ;
    return (user_hostname !== undefined) ? user_hostname : default_hostname;
  }
  // returns the current mysql username. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcMySQLUsername(type: string) {
    const user_username = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].username : undefined;
    const default_username = defaultMySQLConnectionSettings.username ;
    return (user_username !== undefined) ? user_username : default_username;
  }
  // returns the current mysql password. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcMySQLPassword(type: string) {
    const user_password = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].password : undefined;
    const default_password = defaultMySQLConnectionSettings.password ;
    return (user_password !== undefined) ? user_password : default_password;
  }
  // returns the current mysql hostname. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcSchema(type: string) {
    const user_schema = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].schema : undefined;
    const default_schema = defaultMySQLConnectionSettings.schema  ;
    return (user_schema !== undefined) ? user_schema : default_schema;
  }
  // returns the current mysql hostname. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcWSPort(type: string) {
    const user_WS_port = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].ws_port : undefined;
    const default_WS_port = defaultMySQLConnectionSettings.ws_port  ;
    return (user_WS_port !== undefined) ? user_WS_port : default_WS_port;
  }
  // returns the current mysql port. if the user defined its own, return it. otherwise return the organization (or default if it does not exist)
  public calcWSHostname(type: string) {
    const user_WS_hostname = (this.init.oplService.settings.connection && this.init.oplService.allow_users && this.init.oplService.settings.connection[type] !== undefined) ?
      this.init.oplService.settings.connection[type].ws_hostname : undefined;
    const default_WS_hostname = defaultMySQLConnectionSettings.ws_hostname ;
    return (user_WS_hostname !== undefined) ? user_WS_hostname : default_WS_hostname;
  }

  shouldShowWaitingProcessIcon() {
    if (!this.isProcess) {
      return false;
    }
    // if is not the waiting process itself
    const condition1 = !(<any>this.selected.getVisual().logicalElement).getIsWaitingProcess();
    // if has no direct waiting process
    // const condition2 = !(<any>this.selected.getVisual().logicalElement).getWaitingProcess();
    const condition2 = !(<any>this.selected).getVisual().getLinks().inGoing.find(l => l.type === linkType.Invocation && l.source.logicalElement.text.includes('Waiting'));
    // if has self-Invocation
    const condition3 = (<any>this.selected.getVisual()).getAllLinks().inGoing.find(l => l.source === l.target && l.type === linkType.Invocation);
    // has waiting process but not in this opd.
    const hasWaitingProcess = (<any>this.selected.getVisual().logicalElement).getWaitingProcess();
    const condition4 = hasWaitingProcess && !this.init.opmModel.currentOpd.visualElements.find(v => v.logicalElement.lid === hasWaitingProcess);
    const condition5 = !(<any>this.selected.getVisual()).children.find(ch => OPCloudUtils.isInstanceOfVisualProcess(ch) && ch.logicalElement.getIsWaitingProcess());
    const condition6 = !(<any>this.selected.getVisual()).fatherObject;
    if (condition5 && condition1 && condition2 && condition3 && condition6) {
      return true;
    }
    if (condition5 && condition6 && condition1 && ((condition3 && condition2) || condition4)) {
      return true;
    }
    return false;
  }

  waitingProcessAction() {
    const drawnProcess = this.selected;
    let visualProcess = drawnProcess.getVisual() as OpmVisualProcess;
    if (visualProcess.fatherObject) {
      visualProcess = visualProcess.fatherObject;
    }
    this.init.opmModel.logForUndo('Added Waiting Process');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Adding Waiting Process');
    const ret = this.init.opmModel.addWaitingProcess(visualProcess);
    if (ret.isNewlyCreated) {
      this.init.graphService.updateEntity(ret.waitingProcess);
      const isInzoomed = visualProcess === visualProcess.getRefineeInzoom();
      if (!isInzoomed) {
        const sourcePortId = drawnProcess.addCustomPort({ x: visualProcess.width * 0.2, y: visualProcess.height });
        const targetPortId = drawnProcess.addCustomPort({ x: visualProcess.width * 0.8, y: visualProcess.height });
        ret.links[0].sourceVisualElementPort = sourcePortId;
        ret.links[1].targetVisualElementPort = targetPortId;
      } else {
        const targetPortId = drawnProcess.addCustomPort({ x: visualProcess.width * 0.8, y: 0.7 * visualProcess.height });
        ret.links[1].targetVisualElementPort = targetPortId;
      }
      if (ret.waitingProcess.fatherObject && this.init.graphService.graph.getCell(ret.waitingProcess.fatherObject.id)) {
        this.init.graphService.graph.getCell(ret.waitingProcess.fatherObject.id).embed(this.init.graphService.graph.getCell(ret.waitingProcess.id));
      }
      this.init.graphService.updateLinksView(ret.links);
      if (ret.removed) {
        this.init.graphService.graph.getCell(ret.removed.id).remove();
      }
      drawnProcess.updateSizePositionToFitEmbeded();
    }
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Added Waiting Process');
  }

  shouldShowCancelWaitingProcessIcon() {
    if (!this.isProcess) {
      return false;
    }
    // if is not the waiting process itself
    const condition1 = !(<any>this.selected.getVisual().logicalElement).getIsWaitingProcess();
    // if has direct waiting process in this opd
    const hasWaitingProcess = (<any>this.selected.getVisual().logicalElement).getWaitingProcess();
    const condition2 = hasWaitingProcess && this.init.opmModel.currentOpd.visualElements.find(v => v.logicalElement.lid === hasWaitingProcess);
    // if has no self-Invocation
    const condition3 = !(<any>this.selected.getVisual()).getAllLinks().inGoing.find(l => l.source === l.target && l.type === linkType.Invocation);
    if (condition1 && condition2 && condition3) {
      return true;
    }
    if ((<any>this.selected.getVisual()).children.find(ch => OPCloudUtils.isInstanceOfVisualProcess(ch) && ch.logicalElement.getIsWaitingProcess())) {
      return true;
    }
    return false;
  }

  removeWaitingProcessAction() {
    this.init.opmModel.logForUndo('Removed Waiting Process');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'Removed Waiting Process');
    const drawnProcess = this.selected;
    let visualProcess = drawnProcess.getVisual() as OpmVisualProcess;
    if (visualProcess.fatherObject) {
      visualProcess = visualProcess.fatherObject;
    }
    const ret = this.init.opmModel.removeWaitingProcess(visualProcess);
    if (this.init.graph.getCell(ret.removed.id)) {
      this.init.graph.getCell(ret.removed.id).remove();
    }
    if (visualProcess.getRefineeInzoom() === visualProcess) {
      const port = this.init.graph.getCell(visualProcess.id).addCustomPort(({ x: visualProcess.width * 0.9, y: visualProcess.height * 0.8 }));
      ret.link.created[0].targetVisualElementPort = port;
    }
    this.init.graphService.updateLinksView(ret.link.created);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'Removed Waiting Process');
  }

  quitConfigurationView() {
    this.init.opmModel.setCurrentConfiguration(undefined);
    this.init.graphService.renderGraph(this.init.opmModel.currentOpd);
  }

  shouldCurrentBeColored() {
    return !this.getStateType(this.selected).includes('Current');
  }

  shouldShowSetRangeIcon() {
    return this.isObject || this.isState;
  }

  toggleValueTypeObject() {
    (<OpmObject>(this.selected)).toggleValueTypeObject(this.init);
  }

  setRange() {
    (<OpmObject>(this.selected)).setRangePopup(this.init);
  }

  removeRange() {
    (<OpmObject>(this.selected)).removeRange(this.init);
  }

  shouldShowInDiagramInzoom() {
    return this.isObject && (<any>this.selected).getVisual() && !( this.selected.attributes.type === 'opm.Ellipsis') &&
      !(this.isComputational) && !(<OpmVisualThing>this.selected.getVisual()).getRefineeInzoom();
  }


  shouldShowSetRange() {
    if (this.selected instanceof OpmObject) {
      const visual = this.selected.getVisual() as OpmVisualObject;
      // const belongsToStereotype = (<OpmLogicalObject>visual.logicalElement).getBelongsToStereotyped();
      return visual.isComputational();
    }
    return false;
  }

  shouldShowRemoveRange() {
    if (this.selected instanceof OpmObject) {
      const visual = this.selected.getVisual() as OpmVisualObject;
      const belongsToStereotype = (<OpmLogicalObject>visual.logicalElement).getBelongsToStereotyped();
      return visual.hasRange() && !belongsToStereotype;
    }
    return false;
  }

  shouldShowResetValueByRange() {
    if (this.selected instanceof OpmObject) {
      const visual = this.selected.getVisual() as OpmVisualObject;
      return visual.hasRange();
    }
    return false;
  }

  shouldShowToggleValueObject() {
    if (this.selected instanceof OpmObject) {
      const visual = this.selected.getVisual() as OpmVisualObject;
      return visual.hasRange();
    }
    return false;
  }

  shouldChangeStateCondition(): boolean {
    if (this.selected instanceof OpmState) {
      const visual = this.selected.getVisual() as OpmVisualState;
      return visual.shouldChangeCondition();
    }
    return false;
  }

  shouldShowStatesStates() {
    return this.selected && (this.selected.attributes.type === 'opm.State');
  }

  shouldShowAddURL() {
    return (this.isProcess || this.isObject || this.isState) && !( this.selected.attributes.type === 'opm.Ellipsis');
  }

  shouldShowDigitalTwin() {
    return this.isObject && !( this.selected.attributes.type === 'opm.Ellipsis') && (this.selected).attr('value/value') === 'None';
  }

  openCreateViewDialog() {
    this.init.dialogService.openDialog(CreateViewDialog, 230, 426, {});
  }

  shouldShowCreateViewIcon() {
    return this.init.selection.collection.models.filter(cell => OPCloudUtils.isInstanceOfDrawnThing(cell)).length > 0;
  }

  shouldShowSimulateElement() {
    return (this.isObject || this.isProcess) && !(this.selected.attributes.type === 'opm.Ellipsis') && (this.isComputational);
  }

  shouldShowDestating() {
    return this.isObject && !( this.selected.attributes.type === 'opm.Ellipsis') && this.selected.hasStates() && (this.selected).attr('value/value') === 'None';
  }

  shouldShowStateArrangementGroupIcon() {
    return this.isObject && this.selected.hasStates() && !( this.selected.attributes.type === 'opm.Ellipsis');
  }

  shouldShowAlias() {
    return this.isObject;
  }

  shouldShowEditUnits() {
    return this.editUnitsHandle != undefined;
  }

  shouldShowAutoFormatting() {
    return this.isProcess || this.isObject || this.isState;
  }

  shouldShowAutoFormatOff() {
    return !this.isAutoFormat;
  }

  shouldShowAutoFormatOn() {
    return this.isAutoFormat;
  }

  toggleRunByConfigurations() {
    this.runByConfigurations = !this.runByConfigurations;
  }

  resetValueToDefaultByRange() {
    (<OpmObject>this.selected).setValueAsDefault();
  }

  tokenRatioChange(event) {
    const val = (<any>event.source).value;
    this.init.opmModel.setTokenRuntimeRatio(Number(val));
  }

  getRatio() {
    return Math.round(this.init.opmModel.getTokenRuntimeRatio() * 100);
  }

  showGIF($event, handlerGif = '') {
    return OPCloudUtils.showGIF($event, handlerGif);
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }

  isExample(): boolean {
    return this._isExample;
  }

  isTemplate(): boolean {
    return this._isTemplate;
  }

  isStereotype(): boolean {
    return this._isStereotype;
  }

  setIsExample(val: boolean) {
    this._isExample = val;
  }

  setIsStereotype(val: boolean) {
    this._isStereotype = val;
  }

  setIsTemplate(val: boolean) {
    this._isTemplate = val;
  }

  toggleChat() {
    this.init.toggleChat();
    this.updateUserShouldShowChatPanel();
  }

  updateUserShouldShowChatPanel() {
    const settings = { chatEnabled: this.init.showChatPanel };
    this.init.oplService.updateUserSettings(settings);
    this.init.updateDB(settings);
  }

  openImportTemplates() {
    this.init.setSelectedElementToNull();
    this.showTemplatesDiv = false;

    // let templateType;
    // if (type === 'org')
    //   templateType = TemplateType.ORG;
    // else if (type === 'system')
    //   templateType = TemplateType.SYS;
    // else
    //   templateType = TemplateType.PERSONAL;

    // const dialog = this.dialog.open(LoadModelDialogComponent, {
    //   height: Math.round(window.innerHeight * 0.9) + 'px',
    //   width: Math.round(window.innerWidth * 0.75) + 'px',
    //   data: {
    //     path: '', showVersions: false, mode: StorageMode.LOAD,
    //     name: '', description: '', showArchivedModels: false,
    //     archiveMode: false,
    //     screenType: ScreenType.TEMPLATES,
    //     templateType: templateType,
    //     isImportMode: true
    //   },
    // });

    const dialog = this.dialog.open(TemplatesComponent, {
      height: Math.round(window.innerHeight * 0.9) + 'px',
      width: Math.round(window.innerWidth * 0.75) + 'px',
      data: { mode: 'import' }
    });

    dialog.afterClosed().subscribe(res => {
      if (res?.importedTemplate) {
        this.loadTemplateToScreen(res.importedTemplate);
        this.init.criticalChanges_.next();
      }
    });
  }

  loadTemplateToScreen(template) {
    const model = this.init.opmModel;
    const currentIds = this.init.graph.getCells().map(v => v.id);
    const bbox = this.init.graph.getCellsBBox(this.init.graph.getCells());
    let currentLinks = this.init.graph.getCells().filter(c => c.isLink());
    model.logForUndo('Template Import');
    model.setShouldLogForUndoRedo(false, 'templateImport');
    const ret = model.mergeOneOpdModel(template);
    if (ret.success === false) {
      validationAlert(ret.message, 5000, 'Error');
      return;
    }
    this.init.treeViewService.init(model);
    this.init.graphService.renderGraph(model.currentOpd, this.init);
    const newCells = this.init.graph.getCells().filter(cell => !currentIds.includes(cell.id));
    const newEntitiesCells = newCells.filter(cell => OPCloudUtils.isInstanceOfDrawnEntity(cell) || OPCloudUtils.isInstanceOfDrawnTriangle(cell));
    currentLinks = currentLinks.map(l => this.init.graph.getCell(l.id));
    for (let i = newEntitiesCells.length - 1; i >= 0 ; i--) {
      // splice out the old triangles (a solution to the problem that triangles changes their ids every render).
      if (OPCloudUtils.isInstanceOfDrawnTriangle(newEntitiesCells[i]) && currentLinks.find(l => l?.source().id === newEntitiesCells[i].id)) {
        newEntitiesCells.splice(i, 1);
      }
    }
    const newCellsBBox = this.init.graph.getCellsBBox(newEntitiesCells);
    let delta = 0;
    if (bbox) {
      delta = (bbox.x + bbox.width) - newCellsBBox.x;
    }
    this.init.treeViewService.init(model);
    this.init.selection.collection.reset(newEntitiesCells);
    this.init.selection.translateSelectedElements(delta > 0 ? delta + 100 : delta * 2 + 100, 0);
    this.init.selection.collection.reset(newEntitiesCells);
    const newItemsCenter = this.init.graph.getCellsBBox(newEntitiesCells).center();
    this.init.selection.$el.show();
    $('.box').hide();
    model.setShouldLogForUndoRedo(true, 'templateImport');
    this.init.paperScroller.transitionToPoint(newItemsCenter);
  }

  shouldShowImportTemplate() {
    return this.templatesSupported && !this.init.opmModel.currentOpd.isStereotypeOpd();
  }

  shrinkToTextSize() {
    if (!this.selected || this.selected.getEmbeddedCells().length > 0) {
      return;
    }
    this.init.opmModel.logForUndo(this.selected.attr('text/textWrap/text') + ' Shrink To Text Size');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'shrinkToTextSize');
    this.init.graph.startBatch('minimalShrink');
    const bbox = this.selected.findView(this.init.paper)?.$el?.find('text')[0]?.getBBox();
    if (bbox) {
      this.selected.set('size', {width: bbox.width + 15 , height: bbox.height + 15});
    }
    this.init.graph.stopBatch('minimalShrink');
    Arc.redrawAllArcs(this.selected, this.init, true);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'shrinkToTextSize');
  }

  selectConnectedThings() {
    if (!this.selected?.getVisual()) {
      return;
    }
    const ins = this.init.graph.getConnectedLinks(this.selected, { 'inbound': true });
    const outs = this.init.graph.getConnectedLinks(this.selected, { 'outbound': true });
    let toSelect = [];
    const visLinks = (<OpmVisualEntity>this.selected.getVisual()).getLinks();
    // visLinks.inGoing.forEach(link => toSelect.push(this.init.graph.getCell(link.source?.id)));
    visLinks.outGoing.forEach(link => toSelect.push(this.init.graph.getCell(link.target?.id)));
    // for (const inL of ins)
    //   if (OPCloudUtils.isInstanceOfDrawnTriangle(inL.getSourceElement()))
    //     toSelect.push(inL.getSourceElement());
    for (const outL of outs) {
      if (OPCloudUtils.isInstanceOfDrawnTriangle(outL.getTargetElement())) {
        toSelect.push(outL.getTargetElement());
      }
    }

    for (const cell of toSelect) {
      if (OPCloudUtils.isInstanceOfDrawnState(cell)) {
        toSelect.push(cell.getParentCell());
      }
    }
    toSelect = toSelect.filter(cell => !!cell && !OPCloudUtils.isInstanceOfDrawnSemiFoldedFundamental(cell));
    this.init.selection.collection.reset(toSelect);
  }

  shouldShowShrinkToTextSize() {
    return (this.isProcess || this.isObject || this.isState && !( this.init.selectedElement.attributes.type === 'opm.Ellipsis'))
      && this.selected.getEmbeddedCells().length === 0;
  }

  toggleViewsDiv() {
    this.isViewsDivOpen = !this.isViewsDivOpen;
    if (this.isViewsDivOpen) {
      this.closeAllSubToolBars();
      this.isViewsDivOpen = true;
    }
  }

  createUnfoldedTreeView() {
    let selected = this.selected;
    if (this.init.selection.collection.length > 1) {
      validationAlert('Creating a full thing tree view can be done only for one selected thing at a time.', 5000, 'error');
      return;
    }
    if (!selected && this.init.selection.collection.models.find(m => OPCloudUtils.isInstanceOfDrawnThing(m)))
      selected = this.init.selection.collection.models[0];
    if (!selected)
      return;
    this.init.opmModel.logForUndo('Create Unfolded Tree View');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'createUnfoldedTreeView');
    const ret = this.init.opmModel.createStructuralViewOpd(selected.getVisual().logicalElement as any, 'horizontal');
    if (!ret) {
      validationAlert('There is an infinite structural loop. Please fix the structure and try again.', 5000, 'error');
      this.init.opmModel.setShouldLogForUndoRedo(true, 'createUnfoldedTreeView');
      return;
    }
    this.init.treeViewService.init(this.init.opmModel);
    this.init.getGraphService().renderGraph(ret, this.init);
    this.init.getGraphService().updateGraphAfterUnfoldedTreeViewCreation(this.init);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'createUnfoldedTreeView');
  }

  removeSemifolding() {
    const visual = this.selected?.getVisual() as OpmVisualThing;
    if (!visual) {
      return;
    }
    this.init.opmModel.logForUndo('Remove semi-folding');
    this.init.opmModel.setShouldLogForUndoRedo(false, 'removeSemifolding');
    this.init.opmModel.removeSemifolding(visual);
    this.init.getGraphService().updateGraphAfterRemoveSemifolding(this.init, visual);
    this.selected = this.init.graph.getCell(visual.id);
    this.resetElementTextPosition(this.selected);
    this.init.opmModel.setShouldLogForUndoRedo(true, 'removeSemifolding');
  }

  toggleAutoResizing() {
    this.init.automaticResizing = !this.init.automaticResizing;
  }

  toggleOpmRequirementsDiv() {
    this.showOpmRequirementsDiv = !this.showOpmRequirementsDiv;
    if (this.showOpmRequirementsDiv) {
      this.closeAllSubToolBars();
      this.showOpmRequirementsDiv = true;
    }
  }

  toggleConnectionsDiv() {
    this.showConnectionsDiv = !this.showConnectionsDiv;
    if (this.showConnectionsDiv) {
      this.closeAllSubToolBars();
      this.showConnectionsDiv = true;
    }
  }

  addRequirement() {
    (<OpmThing>this.selected).addRequirement(this.init);
  }

  toggleRequirementsSet() {
    const visual = this.selected.getVisual() as OpmVisualThing;
    const logical = visual.logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.hasRequirements()) {
      (<OpmThing>this.selected).toggleAttributesSet(this.init, true);
      const reqs = logical.getAllRequirements();
      this.fixViewOfRequirementObjects(reqs.map(r => r.getRequirementObject()).filter(r => !!r));
    } else if (logical.isSatisfiedRequirementSetObject()) {
      const exhLink = visual.getLinks().inGoing.find(l => l.type === linkType.Exhibition && (<OpmLogicalEntity<OpmVisualEntity>>l.source.logicalElement).hasRequirements());
      if (exhLink) {
        this.init.graph.getCell(exhLink.source.id)?.toggleAttributesSet(this.init, false);
        this.fixViewOfRequirementObjects(visual.getLinks().outGoing.map(l => l.target));
      }
    } else {
      (<OpmThing>this.selected).hideSingleRequirement(this.init);
    }
  }

  connectRequirementsStereotype() {
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    const ret = this.init.opmModel.addRequirementStereotypeToRequirement(logical);
    if (ret.success) {
      this.init.treeViewService.init(this.init.opmModel);
      this.selected.updateView(this.selected.getVisual() as OpmVisualThing);
    }
    this.showOpmRequirementsDiv = false;
    this.init.setSelectedElementToNull();
    this.init.selection.collection.reset([]);
  }

  removeRequirementsStereotype() {
    this.removeStereotypeAction();
    this.showOpmRequirementsDiv = false;
  }

  shouldShowConnectRequirementsStereotype(): boolean {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.getStereotype()) {
      return false;
    }

    if (logical.isSatisfiedRequirementObject()) {
      return true;
    }

    return false;
  }

  shouldShowRemoveRequirementsStereotype(): boolean {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;

    if (logical.getStereotype() && logical.isSatisfiedRequirementObject()) {
      return true;
    }

    return false;
  }

  shouldShowToggleRequirements() {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;

    return logical.hasRequirements() || logical.isSatisfiedRequirementObject() || logical.isSatisfiedRequirementSetObject();
  }

  shouldShowAddRequirement() {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;

    return !logical.isSatisfiedRequirementObject();
  }

  getToggleRequirementTooltip(): string {
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.hasRequirements() || logical.isSatisfiedRequirementSetObject()) {
      return 'Toggle Satisfied Requirement Set';
    } else { return 'Toggle Requirement'; }
  }

  shouldShowRemoveRequirement(): boolean {
    if (!this.isProcess && !this.isObject) {
      return false;
    }
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;

    return !!logical.isSatisfiedRequirementObject() || !!logical.isSatisfiedRequirementSetObject();
  }

  getRemoveRequirementsTooltip(): string {
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.isSatisfiedRequirementObject()) {
      return 'Remove Requirement';
    }
    return 'Remove All Requirements';
  }

  removeRequirement() {
    const logical = this.selected.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    this.dialog.open(ConfirmDialogDialogComponent, {
      data: { message: 'Are you sure you want to remove this requirement/s from all the model?', closeName: 'Cancel' },
    }).afterClosed().toPromise().then(res => {
      if (res !== 'OK') {
        return;
      }
      logical.opmModel.logForUndo('Remove requirement/s from the model');
      logical.opmModel.setShouldLogForUndoRedo(false, 'removeRequirement');
      if (logical.isSatisfiedRequirementSetObject() && !logical.getBelongsToStereotyped()) {
        this.init.opmModel.removeAllRequirements(logical);
      } else {
        const vis = this.init.opmModel.getVisualElementById(this.selected?.id) as OpmVisualThing;
        if (vis.getRefineeUnfold()) {
          validationAlert('Cannot Remove Unfolded Requirement. First you should remove the unfolded OPD.');
          logical.opmModel.setShouldLogForUndoRedo(true, 'removeRequirement');
          return;
        } else if (logical.getBelongsToStereotyped()) {
          validationAlert('Cannot Remove Requirement That Came From A Stereotype.');
          logical.opmModel.setShouldLogForUndoRedo(true, 'removeRequirement');
          return;
        }
        const owner = this.init.opmModel.getOwnerOfRequirementByRequirementLID(vis.logicalElement.lid);
        if (owner) {
          owner.removeSingleRequirement(vis.logicalElement.lid);
        }
      }
      logical.opmModel.setShouldLogForUndoRedo(true, 'removeRequirement');
      this.init.setSelectedElementToNull();
      this.init.getGraphService().renderGraph(this.init.opmModel.currentOpd, this.init);
      this.init.treeViewService.init(this.init.opmModel);
    });
  }

  openCreateReqruiementsViewDialog() {
    this.init.dialogService.openDialog(CreateRequirementViewDialog, 700, 500, {});
  }

  toggleAllOpdRequirements() {
    this.init.opmModel.toggleAllOpdRequirements(this.init.opmModel.currentOpd);
    this.init.getGraphService().renderGraph(this.init.opmModel.currentOpd, this.init);
    this.fixViewOfRequirementObjects(this.init.opmModel.currentOpd.visualElements);
  }

  fixViewOfRequirementObjects(visuals: Array<OpmVisualElement>) {
    this.init.getGraphService().fixViewOfRequirementObjects(this.init, visuals);
  }

  openThingBackgroundImageDialog() {
    this.init.dialogService.openDialog(BackgroundPhotoDialogComponent, 550, 550, { entity: this.selected });
  }

  toggleThingBackgroundDiv() {
    this.showThingBackgroundDiv = !this.showThingBackgroundDiv;
    if (this.showThingBackgroundDiv) {
      this.closeAllSubToolBars();
      this.showThingBackgroundDiv = true;
    }
  }

  openImagePoolManagingDialog() {
    this.init.dialogService.openDialog(ImagesPoolContainer, 675, 900, {});
  }

  showImagesChange($event) {
    this.showImagesIsThingsSelection = false;
    const value = $event.target.value;
    let targetValue;
    if (value === 'none') {
      return;
    } else if (value === 'Show Text Only') {
      targetValue = BackgroundImageState.TEXTONLY;
    } else if (value === 'Show Images Only') {
      targetValue = BackgroundImageState.IMAGEONLY;
    } else if (value === 'Show Semi-Transparent Images & Text') {
      targetValue = BackgroundImageState.TEXTANDIMAGE;
    } else if (value === 'Show Images & Text') {
      targetValue = BackgroundImageState.TEXTANDIMAGEFULL;
      validationAlert('Pay attention, you can control the text location using the styling section on the left side of the toolbar.', 5000);
    }
    this.init.opmModel.changeImagesBackgroundStateInCurrentOpd(targetValue);
    this.init.getGraphService().renderGraph(this.init.opmModel.currentOpd, this.init);
  }

  shouldShowDisconnectFromFatherModel(): boolean {
    return !!this.init.opmModel.fatherModelName;
  }

  async createSubModel() {
    const contextService = (<any>this.init.service).model.context;
    if (contextService.context.isEmpty()) {
      validationAlert('Creating Sub Model is possible only for saved models.', 5000, 'error');
      return;
    } else if (contextService.context.isReadonly()) {
      validationAlert('Creating Sub Model is possible only for models you own.', 5000, 'error');
      return;
    }
    const selectedEntities = this.init.selection.collection.models.filter(m => OPCloudUtils.isInstanceOfDrawnEntity(m));
    const visualEntities = selectedEntities.map(ent => ent.getVisual());
    if (visualEntities.find(v => v.logicalElement.protectedFromBeingRefinedBySubModel)) {
      validationAlert('Cannot create sub model from entities that already belong to a sub model.');
      return;
    }
    if (visualEntities.find(v => OPCloudUtils.isInstanceOfVisualThing(v) && (v.getRefineeInzoom() || v.getRefineeUnfold()))) {
      validationAlert('Cannot create sub model from things that were refined.', 5000, 'Error');
      return;
    }
    if (visualEntities.filter(v => OPCloudUtils.isInstanceOfVisualProcess(v)).length > 1) {
      validationAlert('Only One Process can be used for a sub model.', 5000, 'Error');
      return;
    }
    const path = contextService.getCurrentContext().getPath();
    const dir_id = path[0].id === 'root' ? path[path.length-1].id: path[0].id;
    const ret = await (this.init.dialogService.openDialog(SubModelNameComponent, 280, 400, {
      dir_id: dir_id, mode: 'create', fatherModelName: (<any>this.init.service).model.context.context.properties.name
    }).afterClosed().toPromise());
    if (!ret) {
      return;
    }
    this.init.modelService.createSubModel(this.init.getOpmModel(), visualEntities, contextService, ret).then(success => {
      if (success) {
        this.init.treeViewService.init(this.init.opmModel);
        this.init.save();
      }
    });

  }

  async disconnectSubModelFromFatherModel() {
    const confirmDialog = this._dialog.open(ConfirmDialogDialogComponent, {
      height: '250px',
      width: '360px',
      data: { message: 'Warning: This model will be disconnected from its father model and its father model will no longer be able to import it. Undo will not be possible after this action and the model will be automatically saved.\n Are you sure?', closeFlag: false },
    });
    const confirmed = await confirmDialog.afterClosed().toPromise();
    if (confirmed === 'OK') {
      this.init.opmModel.disconnectSubModelFromFatherModel();
      this.init.modelService.modelObject.modelData.fatherModelName = undefined;
      this.init.getGraphService().renderGraph(this.init.opmModel.currentOpd, this.init);
      this.init.save();
    }
  }

  toggleBringDiv() {
    this.showBringConnectedDiv = !this.showBringConnectedDiv;
    if (this.showBringConnectedDiv) {
      this.init.currentBringConnectedSettings = { ...this.init.oplService.settings.bringConnectedSettings };
    }
  }

  toggleGrid() {
    this.init.toggleGrid();
  }

  bringLinksBetweenSelected() {
    const visuals = this.init.selection.collection.models.map(dr => this.init.opmModel.getVisualElementById(dr.id))
      .filter(v => !!v && OPCloudUtils.isInstanceOfVisualEntity(v));
    if (visuals.length < 2) {
      return;
    }
    this.init.opmModel.bringLinksBetweenSelected(visuals);
    this.init.getGraphService().renderGraph(this.init.opmModel.currentOpd, this.init, null, false, true);
  }

  methodologicalChecking() {
    this.init.dialogService.openDialog(MethodologicalCheckingDialog, 520, 400, { });
  }

  getFontListItemStyle(font: FontData) {
    const currentFont = this.selected.getFont();
    return font.name.toLowerCase() === currentFont.toLowerCase() ? 'border: 3px solid white;' : '';
  }
}

export enum ColorTarget {
  TEXT = 1,
  FILL,
  STROKE,
}

