import { Component, Inject, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { simulatedElementParams } from "../../models/LogicalPart/components/SimulationModule";
import { isNumber } from "../../configuration/rappidEnviromentFunctionality/shared";
import { OpmLogicalThing } from "../../models/LogicalPart/OpmLogicalThing";
import { OpmVisualThing } from "../../models/VisualPart/OpmVisualThing";

@Component({
  selector: 'Simulate-Elements',
  templateUrl: './SimulationElement.html',
  styleUrls: ['./SimulationElement.css']
})
export class SimulationElementComponent {
  numerical = false;
  textual = false;
  distribution = 'uniform';
  uniform = true;
  normal = false;
  binomial = false;
  geometric = false;
  bernoulli = false;
  exponential = false;
  poisson = false;
  integer = false;
  range = false;
  probability = true;
  range1 = null;
  range2 = null;
  selectedValue = 'uniform';
  UniMin = null;
  UniMax = null;
  NormalMu = null;
  NormalSigma = null;
  binomial_n = null;
  binomial_p = null;
  Geometric = null;
  Bernoulli_p = null;
  exponentialLambda = null;
  poissonLambda = null;
  textualArray: { text: string; percent: string }[];
  sim: simulatedElementParams;
  element: OpmLogicalThing<OpmVisualThing>;


  constructor(@Optional() private dialogRef: MatDialogRef<SimulationElementComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.updateFields(data);
    this.element = data.logical;
  }

  updateFields(data) {
    this.sim = data.sim;
    this.distribution = this.sim.selectedValue ? this.sim.selectedValue : 'uniform';
    this.range1 = this.sim.min_range;
    this.range2 = this.sim.max_range;
    this.selectedValue = this.sim.selectedValue || 'uniform';
    this.UniMin = this.sim.min_uniform;
    this.UniMax = this.sim.max_uniform;
    this.NormalMu = this.sim.normal_mu;
    this.NormalSigma = this.sim.normal_sigma;
    this.binomial_n = this.sim.binomial_n;
    this.binomial_p = this.sim.binomial_p;
    this.Geometric = this.sim.Geometric;
    this.Bernoulli_p = this.sim.Bernoulli_p;
    this.exponentialLambda = this.sim.exponential_Lambda;
    this.poissonLambda = this.sim.poisson_Lambda;
    this.numerical = this.sim.numerical;
    this.textual = this.sim.textual;
    this.integer = this.sim.integer;
    this.range = this.sim.range;
    this.probability = true;
    this.textualArray = this.sim.textualArray ? this.sim.textualArray : [{ text: '', percent: '' }];
  }

  addTextField() {
    let sum = 0;
    for (const item of this.textualArray) {
      if (item.percent && isNumber(Number(item.percent)))
        sum += Number(item.percent);
    }
    const perc = (100 - sum > 0) ? (100 - sum) : '';
    this.textualArray.push({ text: '', percent: String(perc) });
  }
  showRelevantFields(e) {
    this.uniform = false;
    this.normal = false;
    this.binomial = false;
    this.exponential = false;
    this.poisson = false;
    this.bernoulli = false;
    this.geometric = false;
    this.distribution = e.target.value;
    this.selectedValue = e.target.value;
  }
  changeNumerical() {
    this.numerical = true;
    this.textual = false;
  }
  changeTextual() {
    this.textual = true;
    this.numerical = false;
  }
  checkPercentage() {// check if sum of percentage == 100 and fill empty percents.
    if (this.textual) {
      let percentageSum = 0;
      let empty = false;
      let numberOfEmptyFields = 0;
      for (let i = 0; i < this.textualArray.length; i++) {
        if (this.textualArray[i].percent !== '' && this.textualArray[i].percent !== 'percent') {
          percentageSum += Number(this.textualArray[i].percent);
        } else {
          empty = true;
          numberOfEmptyFields += 1;
          percentageSum += 0;
        }
      }
      if (!empty && percentageSum !== 100) {
        alert('Error: The percentage sum is not equal to 100');
      }
      if (empty) {
        for (let i = 0; i < this.textualArray.length; i++) {
          if (this.textualArray[i].percent === '' || this.textualArray[i].percent === 'percent') {
            this.textualArray[i].percent = String((100 - percentageSum) / numberOfEmptyFields);
          }
        }
      }
    }
  }
  submit(e) {
    this.textualArray = this.textualArray.filter(item => item.percent && item.percent.trim() !== '' && item.text && item.text.trim() !== '');
    if (this.textualArray.length === 0)
      this.textualArray.push({ text: '', percent: '' });
    this.sim.simulated = !!(this.numerical || this.textual);
    this.sim.min_range = this.range1 ? this.range1 : -Infinity;
    this.sim.max_range = this.range2 ? this.range2 : Infinity;
    this.sim.selectedValue = this.selectedValue;
    this.sim.min_uniform = this.UniMin;
    this.sim.max_uniform = this.UniMax;
    this.sim.normal_mu = this.NormalMu;
    this.sim.normal_sigma = this.NormalSigma;
    this.sim.binomial_n = this.binomial_n;
    this.sim.binomial_p = this.binomial_p;
    this.sim.Geometric = this.Geometric;
    this.sim.Bernoulli_p = this.Bernoulli_p;
    this.sim.exponential_Lambda = this.exponentialLambda;
    this.sim.poisson_Lambda = this.poissonLambda;
    this.sim.numerical = this.numerical;
    this.sim.textual = this.textual;
    this.sim.integer = this.integer;
    this.sim.range = this.range;
    this.sim.probability = true;
    this.sim.textualArray = this.textualArray;
    this.checkPercentage();
    this.dialogRef.close();
  }

  textChange(textField: string, item: { text: string; percent: string }, $event) {
    if (textField === 'text')
      item.text = $event.target.value;
    else if (textField === 'percent')
      item.percent = $event.target.value;
  }

  resetValues() {
    this.element.resetSimulationParams();
    const data = { sim: this.element.getSimulationParams() };
    this.updateFields(data);
  }
}
