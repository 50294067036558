import { Injectable } from '@angular/core';
import { WriteModelSchema, ModelSchema, CurrentModelPermission, ReceivedModelFromServer } from './storage/model-object.class';
import { DisplayStereotype, WriteStereotypeSchema, Stereotype, StereotypeType } from "../../dialogs/stereotypes-dialog/StereotypesRelatedInterface";
import { UserService } from './user.service';
import { DatabaseDriver } from '../../database/databaseDriverInterface';
import { DatabaseService } from '../../database/database.service';
import {DirectoryData, DisplayFolder, DisplayModel, VerisonsServerData } from "./storage/model-storage.interface";
import { RenameServerResponse } from "./server-response/rename-response";
import { RemoveModelServerResponse } from "./server-response/removeModelServerResponse";
import {validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import { getChatResponse, DisplayChat, postChatResponse, getChatAction } from "./storage/chat/chat-storage.interface";
import {FolderPermissions} from "../../dialogs/folder-permissions-dialog/folder-permissions-dialog/folder-permissions-dialog.component";
import {ImagesPoolType} from "../../models/VisualPart/backgroundImageEnum";

@Injectable()
export class StorageService {

  autoSaveInterval = 5;

  private readonly db: DatabaseDriver;

  private user;

  constructor(readonly database: DatabaseService, private userService: UserService) {
    this.db = database.driver;
    userService.user$.subscribe(user => {
      this.user = user;
      if (this.user.userData.autosave)
        this.autoSaveInterval = this.user.userData.autosave;
    });
  }

  public async getModelByPath(title: string, path: string): Promise<ModelSchema> {
    return this.db.getModelByPath(title, path).then(model => {
      // TODO: These flags should be generated in a better way - taken from the server.
      return {
        id: model.meta.id,
        name: model.meta.title,
        description: model.meta.description,
        archiveMode: model.meta.archiveMode,
        isAutosave: false,
        isVersion: false,
        path: '',
        permission: model.meta.permissions == "write" ? CurrentModelPermission.WRITE : CurrentModelPermission.READ,
        editBy: {
          date: model.meta.editBy.date,
          name: model.meta.editBy.name
        },

        logicalElements: model.data.logicalElements,
        currentOpd: model.data.currentOpd,
        opds: model.data.opds,
        stereotypes: model.data.stereotypes,
      };
    });
  }

  public async getVersions(model_id: string): Promise<VerisonsServerData> {
    return this.db.getVersions(model_id);
    // return {
    //   id: 'id1',
    //   title: 'Good Model',
    //   description: 'Some very good model',
    //   editBy: 'daniel',
    //   versions: [
    //     { date: '25.04.23', ver_index: '0' },
    //     { date: '25.04.23', ver_index: '1' },
    //     { date: '25.04.23', ver_index: '2' }
    //   ]
    // }
  }

  public getUserObservable() {
    return this.userService.getUserObservable();
  }

  public async getVersionModel(model_id: string, ver_index: string): Promise<ModelSchema> {
    return this.generateModelSchema(this.db.getVersionModel(model_id, ver_index), { isAutosave: false, isVersion: true });
  }

  private async generateModelSchema(p: Promise<ReceivedModelFromServer>, params: { isAutosave: boolean, isVersion: boolean }): Promise<ModelSchema> {
    return p.then(model => {
      // TODO: These flags should be generated in a better way - taken from the server.
      return {
        id: model.meta.id,
        name: model.meta.title,
        dirsPath: model.meta.dirsPath,
        description: model.meta.description,
        archiveMode: model.meta.archiveMode,
        isAutosave: params.isAutosave,
        isVersion: params.isVersion,
        path: '',
        permission: model.meta.permissions == "write" ? CurrentModelPermission.WRITE : CurrentModelPermission.READ,
        editBy: {
          date: model.meta.editBy.date,
          name: model.meta.editBy.name
        },
        autoOpdTreeSort: model.data.autoOpdTreeSort,
        importedTemplates: model.data.importedTemplates,
        relatedRelations: model.data.relatedRelations,
        fatherModelName: model.data.fatherModelName,
        logicalElements: model.data.logicalElements,
        currentOpd: model.data.currentOpd,
        opds: model.data.opds,
        stereotypes: model.data.stereotypes,
      };
    })
    //   .catch(err => {
    //   let msg;
    //   if (err.status === 404)
    //     msg = 'Unable to load the model. It seems you do not have permission to view it.';
    //   else
    //     msg = 'Unable to load the model. It seems the model does not exist.';
    //
    //   if (!err.message.includes('sysExamples=false'))
    //     validationAlert(msg, 6000, 'Error');
    //
    //   throw Error(err.message);
    // });
  }

  public async getModel(id: string, ver: 'MAIN' | 'AUTO', isSysExamples?: boolean, isGlobalTemplates?: boolean): Promise<ModelSchema> {
    // TODO: Split into two functions
    let p;
    let isAutosave;
    if (ver == 'MAIN') {
      p = this.db.getMainModel(id, isSysExamples, isGlobalTemplates);
      isAutosave = false;
    } else {
      p = this.db.getAutosaveModel(id);
      isAutosave = true;
    }

    return this.generateModelSchema(p, { isAutosave, isVersion: false });
  }

  public async createModel(params: { at_directory: string }, model: WriteModelSchema): Promise<{ created_id: string }> {
    return this.db.createModel(params.at_directory, model);
  }

  public async getImagesPool(type: string) {
    return this.db.getImagesPool(type);
  }

  public async overrideModel(params: { model_id: string }, model: WriteModelSchema): Promise<any> {
    return this.db.overrideModel(params.model_id, model);
  }

  public async archiveModel(params: { model_id: string, archiveMode: boolean }): Promise<any> {
    return this.db.archiveModel(params.model_id, params.archiveMode);
  }

  public async autosaveModel(params: { model_id: string }, modelData: WriteModelSchema): Promise<void> {
    return this.db.autosaveModel(params.model_id, modelData);
  }
// get the chat messages from the database
  public async getChatMessages(model_id: string, action: getChatAction): Promise<getChatResponse> {
    return this.db.getChatMessages(model_id, action);
  }
// push the chat messages to the database
  public async pushChatMessage(message: DisplayChat): Promise<postChatResponse> {
    return this.db.pushChatMessage(message);
  }

  public async removeChatMessage(msg_id: string, model_id: string): Promise<void> {
    return this.db.removeChatMessage(msg_id, model_id);
  }

  getFolders(id: string, sysExamples = false, globalTemplates = false): Promise<Array<DisplayFolder>> {
    return this.db.getFolders(id, sysExamples, globalTemplates);
  }

  public async getAllFolders(globalTemplates = false): Promise<Array<DirectoryData>> {
    return this.db.getAllFolders(globalTemplates);
  }

  public async getAllModelsUserCanLoad(globalTemplates = false): Promise<Array<any>> {
    return this.db.getAllModelsUserCanLoad(globalTemplates);
  }

  getModels(id: string, archiveMode: boolean, sysExamples = false, globalTemplates = false): Promise<Array<DisplayModel>> {
    return this.db.getModels(id, archiveMode, sysExamples, globalTemplates);
  }

  getLastModels(): Promise<Array<DisplayModel>> {
    return this.db.getLastModels().then(models => models.reverse());
  }

  isSysAdmin(): boolean {
    return this.user?.userData?.SysAdmin;
  }

  isOrgAdmin(): boolean {
    return this.user?.userData?.OrgAdmin;
  }

  public async deleteModel(name: string, path: string, fileType: string): Promise<void> {
    // let promise;
    // if (fileType === 'folder')
    //   promise = this.db.deleteFolder(this.user.userData.organization, path, name);
    // else if (fileType === 'model')
    //   promise = this.db.deleteModel(this.user.userData.organization, path, name);
    // return promise;
  }

  public async createFolder(father_id: string, name: string, sysExample?: boolean, orgExample?: boolean, globalTemplate?: boolean, orgTemplate?: boolean): Promise<{ ret:{ success:boolean, message?: string, created_id?: string }}> {
    return this.db.createFolder(father_id, name, sysExample, orgExample, globalTemplate, orgTemplate);
  }

  public async removeModel(model_id: string, sysExamples: boolean, globalTemplates: boolean): Promise<RemoveModelServerResponse> {
    return this.db.removeModel(model_id, sysExamples, globalTemplates);
  }

  public async removeFolder(fid: string, sysExamples: boolean, globalTemplates: boolean): Promise<RemoveModelServerResponse> {
    return this.db.removeFolder(fid, sysExamples, globalTemplates);
  }

  public async renameModel(id: string, title: string, sysExamples: boolean, globalTemplates?: boolean): Promise<RenameServerResponse> {
    return this.db.renameModel(id, title, sysExamples, globalTemplates);
  }

  public async renameFolder(id: string, title: string, sysExample?: boolean, globalTemplates?: boolean): Promise<RenameServerResponse> {
    return this.db.renameFolder(id, title, sysExample, globalTemplates);
  }

  public getAutosaveTime(): number {
    return this.autoSaveInterval; // time in minutes
  }

  public async updatePermissions(model_id: string, permissions): Promise<void> {
    return this.db.updatePermissions(model_id, permissions);
  }

  public async getFolderPermissions(folderId: string): Promise<FolderPermissions> {
    return this.db.getFolderPermissions(folderId);
  }

  public async updateFolderPermissions(folderId: string, permissions: FolderPermissions): Promise<{success: boolean, message?: string}> {
    return this.db.updateFolderPermissions(folderId, permissions);
  }

  public async getPermissions(model_id: string): Promise<any> {
    return this.db.getPermissions(model_id);
  }

  getAllStereotypes(): Promise<Array<DisplayStereotype>> {
    return this.db.getAllStereotypes();
  }

  getFavoriteStereotypes(): Promise<Array<string>> {
    return this.db.getFavoriteStereotypes();
  }

  getFavoriteExamples(): Promise<Array<DisplayModel>> {
    return this.db.getFavoriteExamples();
  }

  getFavoriteTemplates(): Promise<Array<DisplayModel>> {
    return this.db.getFavoriteTemplates();
  }

  setFavoriteExample(modelData: DisplayModel, exampleType: 'SYS' | 'ORG'): Promise<void> {
    return this.db.setFavoriteExample(modelData, exampleType);
  }

  setFavoriteTemplate(modelData: DisplayModel, type: 'SYS' | 'ORG' | 'PERSONAL'): Promise<void> {
    return this.db.setFavoriteTemplate(modelData, type);
  }

  unsetFavoriteExample(modelData: DisplayModel): Promise<void> {
    return this.db.unsetFavoriteExample(modelData);
  }

  unsetFavoriteTemplate(modelData: DisplayModel): Promise<void> {
    return this.db.unsetFavoriteTemplate(modelData);
  }

  saveModelAsSystemExample(model_id: string, model: WriteModelSchema): Promise<void> {
    return this.db.saveModelAsSystemExample(model_id, model);
  }

  setFavoriteStereotype(id: string): Promise<void> {
    return this.db.setFavoriteStereotype(id);
  }

  unsetFavoriteStereotype(id: string): Promise<void> {
    return this.db.unsetFavoriteStereotype(id);
  }

  saveImageToPool(poolType: string, url: string, imageTags: Array<string>): Promise<{success: boolean, message?: string}> {
    return this.db.saveImageToPool(poolType, url, imageTags);
  }

  updatePoolImageTags(id: string, imageTags: string[], poolType: string) {
    return this.db.updatePoolImageTags(id, imageTags, poolType);
  }

  deletePoolImage(id: string, poolType: string) {
    return this.db.deletePoolImage(id, poolType);
  }

  public async getStereotype(stereotype_id: string): Promise<Stereotype> {
    return this.db.getStereotype(stereotype_id)
      .then(s => {
        s.id = stereotype_id;
        s.permission = CurrentModelPermission.READ;
        if (s.type == StereotypeType.System && this.userService.isSysAdmin())
          s.permission = CurrentModelPermission.WRITE;
        else if (s.type == StereotypeType.Organization && (this.userService.isSysAdmin() || this.userService.isOrgAdmin()))
          s.permission = CurrentModelPermission.WRITE;
        return s;
        // const context = new StereotypeContext({ id: s.id, name: s.name, description: s.description, type: s.type, hasWritePermissions });
        // this.modelService.set(s, context);
        // }).then(res => validationAlert(`Successfully loaded stereotype [${stereotype.name}].`, 2500, 'Success'))
        // .catch(err => validationAlert(`Failed to load stereotype [${stereotype.name}].`, 2500, 'Error'));
      });
  }

  public async saveStereotype(stereotype: WriteStereotypeSchema): Promise<void> {
    return this.db.saveStereotype(stereotype);
  }

  public async deleteStereotype(stereotype_id: string): Promise<void> {
    return this.db.deleteStereotype(stereotype_id);
  }

  public async setStereotype(stereotype: DisplayStereotype): Promise<Stereotype> {
    return this.db.getStereotype(stereotype.id).then(s => {
      s.id = stereotype.id;
      return s;
    });
  }

  updateUserLastTabsToDB(relevantTabs: Array<string>): Promise<void> {
    return this.db.updateUserLastTabsToDB(relevantTabs).then(() => {}).catch(err => {});
  }

  getUserLastTabsFromDB(): Promise<Array<string>> {
    return this.db.getUserLastTabsFromDB();
  }

  public async downloadModel(modelId: string, isSysExample = false, isGlobalTemplate = false) {
    return this.db.downloadModel(modelId, isSysExample, isGlobalTemplate);
  }

  moveFolder(sourceId: string, targetId: string, sysExamples: boolean, isGlobalTemplate: boolean) {
    return this.db.moveFolder(sourceId, targetId, sysExamples, isGlobalTemplate);
  }

  getModelLastEditDate(modelId: string): Promise<string> {
    return this.db.getModelLastEditDate(modelId);
  }

  moveModel(modelId: string, dir_id: string, sysExamples: boolean, isGlobalTemplate: boolean) {
    return this.db.moveModel(modelId, dir_id, sysExamples, isGlobalTemplate);
  }

  getOrganizationAnalytics(org: string) {
    return this.db.getOrganizationAnalytics(org);
  }
}
