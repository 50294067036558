import { OpmOpd } from '../../models/OpmOpd';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { ObjectItem, hasWhiteSpaces } from './computationalPartUtils';
import { OpmModel } from '../../models/OpmModel';
import { VariablesCalculator } from './variables-calculator';
import { HttpCommunicatorI } from './communication-object';
import { code } from '../../models/ConfigurationOptions';

/**
 * A class that handles external functions.
 * opmModel - the current opm model
 * initRappid - init rappid service
 * */
export class ExternalFunctionExecutor {
  constructor(private readonly opmModel: OpmModel, private readonly http: HttpCommunicatorI) {
  }

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], externalFunction) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.External);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    const variables_array = this.calc_variables_array(all_paths, valuesArray);
    return this.runExternalFunction(variables_array, externalFunction);
  }

  private async runExternalFunction(valuesArray: ObjectItem[], externalFunction) {
    let parametersValues = {};
    for (let i = 0; i < valuesArray.length; i++) {
      if (!hasWhiteSpaces(valuesArray[i].name)) { // no spaces in objects name
        /***
         * consider changing to parametersValues[(valuesArray[i].name)] = valuesArray[i].sourceElementValue;
         * */
        parametersValues[(valuesArray[i].name).toLowerCase()] = valuesArray[i].sourceElementValue;
      }
      if (valuesArray[i].alias && valuesArray[i].alias.length && !hasWhiteSpaces(valuesArray[i].alias)) {
        /***
         * consider changing to parametersValues[(valuesArray[i].alias)] = valuesArray[i].sourceElementValue;
         * */
        parametersValues[(valuesArray[i].alias).toLowerCase()] = valuesArray[i].sourceElementValue;
      }
    }
    const urlValueArray = valuesArray;
    let URL = this.replaceUrl(externalFunction.functionUrl, urlValueArray);
    let urlParameters = externalFunction.urlParameters;
    let functionCall = '';
    let parameterSet = '';
    if (urlParameters) { //If the API for the external system in in the Text field
      const arr = new Array<{ key: string, value: string }>();
      const p = urlParameters.match(/\(([^,]*),([^)]*)\)/g);
      if (p) {
        for (const i of p) {
          // const m = i.match(/\(([^.]*),([^.]*)\)/);
          // changed so for example, (a.b,param) and (a/b,param) will also count. consider remove ')' and '(' and then split by ','
          const m = i.match(/\(([\/|\w][\/\w|\.]*),([\/|\w][\/\w|\.]*)\)/);
          if (m)
            arr.push({
              key: (parametersValues[m[1].toLowerCase()] ? parametersValues[m[1].toLowerCase()] : m[1]),
              value: (parametersValues[m[2].toLowerCase()] ? parametersValues[m[2].toLowerCase()] : m[2])
            });
        }
      }
      for (let i = 0; i < arr.length; i++) {
        parameterSet += encodeURIComponent(arr[i]['key']) + '=' + encodeURIComponent(arr[i]['value']);
        if (i !== arr.length - 1) {
          parameterSet += '&';
        }
      }
      functionCall = URL + '?' + parameterSet;
    } else {
      functionCall = URL;
    }
    // const x = await this.getExternalData(functionCall);
    const x = await fetch(functionCall).then(res => res.text());
    return x;
  }

  private async getExternalData(func) {
    // const x = this.initRappid.http.get(func);
    return this.http.get(func);
    // expecting the direct value. e.g. 39.
  }

  /**
   * A function that calculates variables_array, which is an ObjectItem array that includes for each object in it the
   * the object name,alias and value.
   **/
  private calc_variables_array(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[]) {
    let all_variables_str = [];
    let variables_array = [];
    let alias = [];
    new VariablesCalculator(all_paths, valuesArray).calc_variables_str(all_variables_str, code.External,
      [], alias, variables_array);
    return variables_array;
  }

  /**
   * replace each variable(the variable are separated by '/') in the URL with it's value (if a variable with this
   * alias/name exists)
   * url - the string we are searching for variables to replace
   * variables_array - includes the variables and their values
   **/
  private replaceUrl(url: string, variables_array: ObjectItem[]) {
    let string_to_return = '';
    const m = url.split('+');
    for (const sub_topic_or_data of m) {
      const variable = variables_array.filter(value => value.name === sub_topic_or_data || value.alias === sub_topic_or_data)[0];
      if (variable !== undefined && !hasWhiteSpaces(sub_topic_or_data)) { // do not replace variables without legal identifiers
        string_to_return += variable.sourceElementValue;
      } else {
        string_to_return += sub_topic_or_data;
      }
    }
    return string_to_return;
  }
}
