// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.title {
  font-size: large;
}

p {
  text-align: left;
  font-size: medium;
}

.buttonContainer {
  float: right;
}

button {
  background: #1A3763;
  color: #fff;
  border-radius: 4px;
  border-style: none;
  margin: 5px;
}

#select-user {
  margin-left: 6px;
  width: 170px !important;
}

.gray-out {
  color: grey !important;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/remove-user/remove-user.component.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,iBAAiB;AACnB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,mBAAmB;EACnB,WAAW;EACX,kBAAkB;EAClB,kBAAkB;EAClB,WAAW;AACb;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;AACzB;;AAEA;EACE,sBAAsB;AACxB","sourcesContent":[".title {\n  font-size: large;\n}\n\np {\n  text-align: left;\n  font-size: medium;\n}\n\n.buttonContainer {\n  float: right;\n}\n\nbutton {\n  background: #1A3763;\n  color: #fff;\n  border-radius: 4px;\n  border-style: none;\n  margin: 5px;\n}\n\n#select-user {\n  margin-left: 6px;\n  width: 170px !important;\n}\n\n.gray-out {\n  color: grey !important;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
