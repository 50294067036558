export class PenManager {
  isInPenMode: boolean;
  penDrawing: Array<any>;
  lastMovement;

  constructor() {
    this.isInPenMode = false;
    this.penDrawing = [];
  }

  get isPenMode() { return this.isInPenMode }
  set isPenMode(val: boolean) { this.isInPenMode = val}
  togglePenMode() { this.isInPenMode = !this.isInPenMode }
  get penDrawingSvg() { return this.penDrawing }
  resetPenDrawing() { this.penDrawing = [] };
  addDrawingSvg(svg) { this.penDrawing.push(svg) }
  get lastMovementTime() { return this.lastMovement }
  set lastMovementTime(time) { this.lastMovement = time }
}
