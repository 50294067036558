import {Component, DoCheck, EventEmitter, HostListener, Inject, OnInit} from '@angular/core';
import {
  uniteAgentsAndInstruments,
  uniteConsumptions,
  uniteResults
} from '../../configuration/elementsFunctionality/linkDrawing';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {GraphService} from '../../rappid-components/services/graph.service';
import {OpmObject} from "../../models/DrawnPart/OpmObject";
import {OpmState} from "../../models/DrawnPart/OpmState";
import {ResultLink} from "../../models/DrawnPart/Links/ResultLink";
import {ConsumptionLink} from "../../models/DrawnPart/Links/ConsumptionLink";
import {OpmProcess} from "../../models/DrawnPart/OpmProcess";
import {
  OPCloudUtils,
  removeDuplicationsInArray,
  validationAlert
} from '../../configuration/rappidEnviromentFunctionality/shared';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {LinkConstraints} from '../../rappid-components/services/linkConstraints';
import {OpmModel} from '../../models/OpmModel';
import {OpmVisualEntity} from '../../models/VisualPart/OpmVisualEntity';
import {getLinkType} from '../../models/consistency/consistancy.model';
import {Essence, linkConnectionType, linkType} from '../../models/ConfigurationOptions';
import {OpmLink} from '../../models/VisualPart/OpmLink';
import {InOutPairType, ModelConnectResult} from '../../models/consistency/links.model';
import {structural} from '../../models/consistency/links.set';
import {OpmProceduralLink} from '../../models/VisualPart/OpmProceduralLink';
import {OpmSemifoldedFundamental} from '../../models/DrawnPart/OpmSemifoldedFundamental';
import {OpmVisualObject} from "../../models/VisualPart/OpmVisualObject";

@Component({
  selector: 'opcloud-choose-link-dialog',
  templateUrl: './Dialog.component.html',
  styleUrls: ['./Dialog.component.css']
})
export class LinksDialogComponent implements OnInit, DoCheck {
  // resize
  x: number;
  y: number;
  px: number;
  py: number;
  width: number;
  height: number;
  width_min: number;
  height_min: number;
  minArea: number;
  draggingCorner: boolean;
  draggingWindow: boolean;
  resizer: Function;
  close = new EventEmitter();
  public newLink: any;
  public linkSource: any;
  public linkTarget: any;
  public opmLinks: Array<any>;
  private selected: any;
  listExpanded = false;
  show = true;
  noshow = false;
  // links arrays
  public Structural_Links: Array<any> = [];
  public Agent_Links: Array<any> = [];
  public Agent_Negation_Links: Array<any> = [];
  public Instrument_Links: Array<any> = [];
  public Instrument_Negation_Links: Array<any> = [];
  public Effect_links: Array<any> = [];
  public Effect_Negation_links: Array<any> = [];
  public Consumption_links: Array<any> = [];
  public Consumption_Negation_links: Array<any> = [];
  public Result_Link: Array<any> = [];
  public Exception_links: Array<any> = [];
  public Invocation_links: Array<any> = [];
  public Relation_Links: Array<any> = [];
  public In_out_Link_Pair: Array<any> = [];
  Graph = null;
  graph = null;
  agentSelection: any;
  replaceTriangleLink: OpmLink;
  instrumentSelection: any;
  consumptionSelection: any;
  effectSelection: any;
  exceptionSelection: any;
  splitSelection: any;
  asOnlyOneState;
  orxorSelection: string;
  notSelection: string;
  agentNotSelection: boolean;
  instrumentNotSelection: boolean;
  consumptionNotSelection: boolean;
  effectNotSelection: boolean;
  multiTargets: Array<any>;
  _shouldBeDisabled = {};


  ngOnInit() {
    const state = (this.linkTarget instanceof OpmState) ? this.linkTarget : (this.linkSource instanceof OpmState) ? this.linkSource : undefined; //Alon: Checks if the state is source, target or neither
    if (state) {
      this.asOnlyOneState = (state.getParent().getEmbeddedCells().filter((child) => { return child instanceof OpmState }).length <= 1); // Alon: Checks if there sre more then 1 state in the object
    }
  }

  ngDoCheck() {
    if ($('select').length > 0 && ($('select')[0] as any).value === 'Agent')
      ($('select')[0] as any).selectedIndex = 0;
  }


  constructor(
    @Inject(MAT_DIALOG_DATA) private data: any,
    public dialogRef: MatDialogRef<LinksDialogComponent>, private graphService: GraphService, private initRappid: InitRappidService) {
    this.Graph = graphService.getGraph();
    this.graph = this.Graph;
    this.x = 400;
    this.y = 100;
    this.px = 0;
    this.py = 0;
    this.width = 600;
    this.height = 778;
    this.width_min = 455;
    this.height_min = 420;
    this.draggingCorner = false;
    this.draggingWindow = false;
    this.minArea = 150000;

    this.newLink = data.newLink;
    this.linkSource = data.linkSource;
    this.linkTarget = data.linkTarget;
    this.opmLinks = data.opmLinks;
    this.Structural_Links = data.Structural_Links;
    this.Agent_Links = data.Agent_Links;
    this.Agent_Negation_Links = data.Agent_Negation_Links;
    this.Instrument_Links = data.Instrument_Links;
    this.Instrument_Negation_Links = data.Instrument_Negation_Links;
    this.Effect_links = data.Effect_links;
    this.Effect_Negation_links = data.Effect_Negation_links;
    this.Consumption_links = data.Consumption_links;
    this.Consumption_Negation_links = data.Consumption_Negation_links;
    this.Result_Link = data.Result_Link;
    this.Exception_links = data.Exception_links;
    this.Invocation_links = data.Invocation_links;
    this.Relation_Links = data.Relation_Links;
    this.In_out_Link_Pair = data.In_out_Link_Pair;
    this.orxorSelection = 'None';
    this.notSelection = 'None';
    this.agentNotSelection = false;
    this.instrumentNotSelection = false;
    this.consumptionNotSelection = false;
    this.effectNotSelection = false;
    this.replaceTriangleLink = data.replaceTriangleLink;

    if (this.Agent_Links.length) {
      this.agentSelection = this.Agent_Links[0].name;
    }
    if (this.Instrument_Links.length) {
      this.instrumentSelection = this.Instrument_Links[0].name;
    }
    if (this.Consumption_links.length) {
      this.consumptionSelection = this.Consumption_links[0].name;
    }
    if (this.Effect_links.length) {
      this.effectSelection = this.Effect_links[0].name;
    }
    if (this.Exception_links.length) {
      if (this.shouldShowExceptionLinks('overtime'))
        this.exceptionSelection = this.Exception_links[0].name;
      else if (this.shouldShowExceptionLinks('undertime'))
        this.exceptionSelection = this.Exception_links[1].name;
      else if (this.shouldShowExceptionLinks('overtimeundertime'))
        this.exceptionSelection = this.Exception_links[2].name;
      else this.exceptionSelection = this.Exception_links[0].name;
    }
    if (this.In_out_Link_Pair.length) {
      this.splitSelection = this.In_out_Link_Pair[0].name;
    }
    if (this.initRappid.selection.collection.models.length > 1) {
      this.multiTargets = this.initRappid.selection.collection.models.filter(e => !e.get('type').includes('Triangle') && e.getVisual && e.getVisual()?.logicalElement);
      this.multiTargets = this.multiTargets.filter(e => {
        if (e.constructor.name.includes('State') && e.getParentCell() && this.multiTargets.includes(e.getParentCell()))
          return false;
        return true;
      });
    }
    else this.multiTargets = [this.linkTarget];
    if (this.multiTargets.indexOf(this.linkTarget) === -1)
      this.multiTargets.push(this.linkTarget);
    if (this.isProcessSelected() && this.isObjectSelected() && this.isStateSelected()) {
      validationAlert('No common link for all targets');
      this.DefaultExit(this.newLink);
      this.initRappid.selection.collection.reset([]);
      this.close.emit('event');
      this.dialogRef.close();
    }
  }

  getExtremeSubProcesses(subProcesses: Array<OpmProcess>): { highest: Array<OpmProcess>, lowest: Array<OpmProcess> } {
    let highest = [subProcesses[0]];
    let lowest = [subProcesses[1]];
    for (const sub of subProcesses) {
      const subY = sub.get('position').y;
      const lowestY = lowest[0].get('position').y;
      const highestY = highest[0].get('position').y;
      if (Math.abs(subY - lowestY) <= 30) {
        lowest.push(sub);
      } else if (subY > lowestY) {
        lowest = [sub];
      }
      if (Math.abs(subY - highestY) <= 30) {
        highest.push(sub);
      } else if (subY < highestY) {
        highest = [sub];
      }
    }
    return { highest: highest, lowest: lowest };
  }
  isInvocationFromLastSubtoFirstSub(): boolean {
    const model: OpmModel = this.initRappid.getOpmModel();
    const source = this.linkSource;
    const target = this.linkTarget;
    const isInvocation = getLinkType(this.newLink.attributes.name) === linkType.Invocation;
    if (!isInvocation) return false;
    if (source.getParent() && target.getParent() && source.getParent() === target.getParent()) {
      const subProcesses = source.getParent().getEmbeddedCells().filter(cell => cell.get('type') === 'opm.Process');
      if (subProcesses.length < 2) return false;
      const extreams = this.getExtremeSubProcesses(subProcesses);
      if (extreams.lowest.includes(source) && extreams.highest.includes(target))
        return true;
    }
    return false;
  }

  async tryToConnect(): Promise<{ result: ModelConnectResult, affected: Array<OpmVisualEntity> }> {
    const model: OpmModel = this.initRappid.getOpmModel();
    const source = this.linkSource;
    let target = this.linkTarget;
    const name = this.newLink.attributes.name;
    const isCondition = this.newLink.attributes.name.includes('Condition');
    const isEvent = this.newLink.attributes.name.includes('Event');
    const isNegation = (this.notSelection === 'NOT' || this.orxorSelection === 'NOT');

    const params = { type: getLinkType(name), connection: linkConnectionType.enviromental, isCondition, isEvent, isNegation };

    let targets: Array<any>; // used in case of multi targets
    if (params.type === linkType.Exhibition)
      targets = this.initRappid.selection.collection.models.filter(el => !el.get('type').includes('Triangle'));
    else
      targets = this.initRappid.selection.collection.models.filter(e => target.get('type') === e.get('type'));

    targets = targets.filter(e => {
      if (e.constructor.name.includes('State') && e.getParentCell() && targets.includes(e.getParentCell()))
        return false;
      return true;
    });

    if (!(targets.length === 1 && targets[0] !== target)) {
      if (targets.indexOf(target) === -1)
        targets.push(target);
      targets = removeDuplicationsInArray(targets);
      targets = targets.filter(a => a !== source);
    }

    const isInvocationFromLastToFirst = this.isInvocationFromLastSubtoFirstSub();
    if (isInvocationFromLastToFirst) {
      target = target.getParent();
      validationAlert('Invocation link from the last sub process to the first is redundant,' +
        ' connected to the in-zoomed process instead.', 6000);
      targets = [target];
    }

    const visualSource = <OpmVisualEntity>model.getVisualElementById(source.id);

    if (targets.length >= 2) { // if multiple targets exists
      const visualTargets = targets.map(t => model.getVisualElementById(t.id)) as Array<OpmVisualEntity>;
      return Promise.resolve({ result: model.connectMultiple(visualSource, visualTargets, params), affected: visualTargets.concat(visualSource) });
    }

    const visualTarget = <OpmVisualEntity>model.getVisualElementById(target.id);

    if (params.type === linkType.Instrument && OPCloudUtils.isInstanceOfVisualState(visualSource)) {
      const currentStatesConnectedLinks = visualSource.fatherObject.getChildrenLinks().outGoing
        .filter(l => l.source.constructor.name.includes('State') && l.target === visualTarget && l.type === linkType.Instrument);
      const currentStatesConnectedLIDs = currentStatesConnectedLinks.map(l => l.source.logicalElement.lid);
      if (!currentStatesConnectedLIDs.includes(visualSource.logicalElement.lid)) {
        currentStatesConnectedLIDs.push(visualSource.logicalElement.lid);
      }
      const allStatesLIDs = visualSource.fatherObject.logicalElement.states.map(s => s.lid);
      if (allStatesLIDs.length === currentStatesConnectedLIDs.length) {
        model.logForUndo('Automatic link connection');
        model.setShouldLogForUndoRedo(false, 'Automatic link connection');
        const ret = model.allStatesInstrumentConnection(visualSource, visualTarget, currentStatesConnectedLinks, params);
        if (ret.result.success) {
          source.getParentCell().updateView(source.getParentCell().getVisual());
          source.getParentCell().shiftEmbeddedToEdge(this.initRappid);
        }
        model.setShouldLogForUndoRedo(true, 'Automatic link connection');
        if (ret.result.success) {
          validationAlert('The link has moved because when an object is connected to a process both from all of its states, the correct connection is from the object itself to the process.');
        }
        return Promise.resolve(ret);
      }
    } else if (params.type === linkType.Instrument && OPCloudUtils.isInstanceOfVisualObject(visualSource)) {
      const currentStatesConnectedLinks = visualSource.getChildrenLinks().outGoing
        .filter(l => l.source.constructor.name.includes('State') && l.target === visualTarget && l.type === linkType.Instrument);
      if (currentStatesConnectedLinks.length > 0) {
        const ret = model.allStatesInstrumentConnectionFromFather(visualSource as OpmVisualObject, visualTarget, currentStatesConnectedLinks, params);
        if (ret.result.success) {
          source.updateView(visualSource);
          source.shiftEmbeddedToEdge(this.initRappid);
          validationAlert('When an object is connected to a process both from itself and its states, only the link originating from the object itself will persist.');
        }
        return Promise.resolve(ret);
      }
    }
    const result = model.connect(visualSource, visualTarget, params);
    if (!result.success && result.changeAction) {
      const retResult = await result.changeAction(visualSource, visualTarget, this.initRappid);
      if (retResult.changed) {
        return this.tryToConnect();
      } else {
        result.message = undefined;
      }
    }
    return Promise.resolve({ result: result, affected: [visualSource, visualTarget] });
  }

  replaceTriangle(): { result: ModelConnectResult, affected: Array<OpmVisualEntity> } {
    const model: OpmModel = this.initRappid.getOpmModel();
    const source = this.linkSource;
    const target = this.linkTarget;
    const name = this.newLink.attributes.name;

    const visualSource = <OpmVisualEntity>model.getVisualElementById(source.id);
    const visualTarget = <OpmVisualEntity>model.getVisualElementById(target.id);

    const type = getLinkType(name);
    if (structural.contains(type))
      return { result: model.replaceTriangle(visualSource, visualTarget, this.replaceTriangleLink, type), affected: [visualSource, visualTarget] };
    return { result: { success: false, message: 'Not a triangle' }, affected: [] };
  }

  createInOutPair(): { result: ModelConnectResult, affected: Array<OpmVisualEntity> } {
    const model: OpmModel = this.initRappid.getOpmModel();
    const source = this.linkSource;
    const target = this.linkTarget;

    const name = this.newLink.attributes.name;

    const visualSource = <OpmVisualEntity>model.getVisualElementById(source.id);
    const visualTarget = <OpmVisualEntity>model.getVisualElementById(target.id);

    let type: InOutPairType;
    if (name.includes('Condition'))
      type = InOutPairType.Condition;
    else if (name.includes('Event'))
      type = InOutPairType.Event;
    else if (name.includes('Split'))
      type = InOutPairType.Split;
    else
      type = InOutPairType.Standart;

    const result = model.connectInOurPair(visualSource, visualTarget, type);

    return { result, affected: [visualSource, visualTarget] };
  }

  isSourceObject() {
    return this.linkSource.get('type').includes('Object');
  }
  isSourceProcess() {
    return this.linkSource.get('type').includes('Process');
  }
  isSourceState() {
    return this.linkSource.get('type').includes('State');
  }
  numberOfStatesSelected() {
    const numberOfSelectedStates = this.multiTargets.filter
      (mod => mod.get('type').includes('State'));
    return numberOfSelectedStates.length;
  }
  isSourceInTargets() {
    let selectedTargets = this.multiTargets;
    if (selectedTargets.length === 0)
      selectedTargets = [this.linkTarget];
    return selectedTargets.indexOf(this.linkSource) !== -1;
  }
  checkStructural(link) {
    if (link.name === 'Exhibition-Characterization' || link.name === 'Generalization-Specialization') {
      const condition1 = !this.isStateSelected() && this.isSourceState();
      const condition2 = this.isStateSelected() && this.isSourceObject();
      const condition6 = this.isSourceObject() && this.isProcessSelected();
      const condition7 = this.isSourceProcess() && this.isObjectSelected() && !this.isStateSelected();
      const condition8 = this.isSourceObject() && this.isObjectSelected() && !this.isProcessSelected() && !this.isStateSelected();
      const condition9 = this.isSourceProcess() && this.isProcessSelected() && !this.isObjectSelected() && !this.isStateSelected();
      const condition10 = this.isSourceProcess() && this.isStateSelected();
      const condition11 = this.isStateSelected() && this.isSourceState();
      return condition1 || condition2 || condition6 || condition7 || condition8 || condition9 || condition10 || condition11;
    } else {
      const condition3 = this.isSourceProcess() && !this.isObjectSelected();
      const condition4 = this.isSourceObject() && !this.isProcessSelected() && !this.isStateSelected();
      const condition5 = this.isSourceState() && this.isObjectSelected() && !this.isProcessSelected() && !this.isStateSelected();
      const condition9 = this.isSourceObject() && !this.isProcessSelected() && this.isStateSelected() && link.name === 'Aggregation-Participation';
      return (condition3) || (condition4) || condition5 || condition9;
    }
  }
  checkRelationLinks() {
    const case1 = (this.isSourceObject() || this.isSourceState()) && (this.isObjectSelected() || this.isStateSelected()) && !this.isProcessSelected();
    const case2 = (this.isSourceProcess() && this.isProcessSelected() && !this.isStateSelected() && !this.isObjectSelected());
    return (case1 || case2);
  }
  isObjectSelected() {
    let selectedTargets = this.multiTargets;
    if (selectedTargets.length === 0)
      selectedTargets = [this.linkTarget];
    selectedTargets = selectedTargets.filter(sel => sel.get('type').includes('Object'));
    return selectedTargets.length > 0;
  }
  isProcessSelected() {
    let selectedTargets = this.multiTargets;
    if (selectedTargets.length === 0)
      selectedTargets = [this.linkTarget];
    selectedTargets = selectedTargets.filter(sel => sel.get('type').includes('Process'));
    return selectedTargets.length > 0;
  }

  isStateSelected() {
    let selectedTargets = this.multiTargets;
    if (selectedTargets.length === 0)
      selectedTargets = [this.linkTarget];
    selectedTargets = selectedTargets.filter(sel => sel.get('type').includes('State'));
    return selectedTargets.length > 0;
  }

  async onClickedExit(link) {
    this.selected = link;
    this.selected.graph = this.newLink.graph;
    this.newLink.attributes.name = this.selected.name;
    const name: string = this.selected.name;

    let ret: { result: ModelConnectResult, affected: Array<OpmVisualEntity> };

    if (this.replaceTriangleLink) {
      ret = this.replaceTriangle();
    } else if (name.includes('In-out_Link_Pair') || name.includes('Split')) {
      ret = this.createInOutPair();
    } else {
      ret = await this.tryToConnect();
    }

    if (this.newLink.getSourceElement()?.constructor.name.includes('Semi') && ret.result.success)
      ret.result.created[0].sourceVisualElementPort = OpmSemifoldedFundamental.bestSemiFoldedPort(this.linkSource.getVisual().fatherObject,
        this.linkTarget.getVisual());
    if (this.newLink.getTargetElement()?.constructor.name.includes('Semi') && ret.result.success)
      ret.result.created[0].targetVisualElementPort = OpmSemifoldedFundamental.bestSemiFoldedPort(this.linkTarget.getVisual().fatherObject,
        this.linkSource.getVisual());

    this.newLink.remove();

    if (ret.result?.success) {
      this.initRappid.opmModel.setShouldLogForUndoRedo(false, 'DialogComponent-onClickedExit1');
      const graph = this.initRappid.getGraphService();
      const isNewCreated = ret.result.created.length > 0;
      const toDraw = this.initRappid.opmModel.currentOpd.visualElements.filter(vis => vis instanceof OpmLink &&
        !this.initRappid.graph.getCell(vis.id) && vis.visible !== false);
      if (isNewCreated && ret.result.created[0].type === linkType.Invocation && ret.result.created[0].source === ret.result.created[0].target)
        this.linkSource.addOldPortsForSelfInvocation();
      graph.updateLinksView(toDraw as Array<OpmLink>);
      graph.viewRemove(ret.result.removed);
      graph.viewEntityUpadate(ret.affected);
      if (isNewCreated && ret.result.created[0].type === linkType.Invocation) {
        const drawnInvocation = this.initRappid.graph.getCell(ret.result.created[0]);
        const cellView = this.initRappid.paper.findViewByModel(drawnInvocation);
        this.initRappid.opmModel.setShouldLogForUndoRedo(false, 'DialogComponent-onClickedExit2');
        drawnInvocation?.pointerUpHandle(cellView, this.initRappid);
        this.initRappid.opmModel.setShouldLogForUndoRedo(true, 'DialogComponent-onClickedExit2');
      }
      if (isNewCreated && ret.result.created[0].type === linkType.Result) {
        const createdDrawn = this.initRappid.graph.getCell(ret.result.created[0].id);
        if (createdDrawn)
          uniteResults([createdDrawn]);
      }
      else if (isNewCreated) {
        const createdDrawn = this.initRappid.graph.getCell(ret.result.created[0].id);
        if (createdDrawn && ret.result.created[0].type === linkType.Consumption)
          uniteConsumptions([createdDrawn]);
        else if (createdDrawn && (ret.result.created[0].type === linkType.Agent || ret.result.created[0].type === linkType.Instrument))
          uniteAgentsAndInstruments([createdDrawn]);
      }
      if (ret.result.warnings && ret.result.warnings.length > 0)
        ret.result.warnings.forEach(w => validationAlert(w));

      if (isNewCreated && ret.result.created[0].type === linkType.Invocation && ret.result.created[0].source === ret.result.created[0].target) {
        this.linkSource.removeUnusedPorts();
        this.graph.getCell(ret.result.created[0].id)?.addHandle();
      }
    } else {
      validationAlert(ret.result.message, null, 'Error');
    }

    if (this.orxorSelection && this.orxorSelection !== 'None' && ret.result.success && ret.result.created.length > 0) {
      const linksToConnect = [];
      const point = this.getTargetsMidPoint(ret.result.created);
      let side = 'source';
      if (ret.result.created[0].type === linkType.Effect && this.linkSource instanceof OpmProcess) {
        side = 'target';
      }
      let port = this.linkSource instanceof OpmState ? 10 : this.linkSource.findClosestEmptyPort(point);
      this.initRappid.selection.collection.reset([]);
      for (const created of ret.result.created) {
        const drawnLink = this.initRappid.graph.getCell(created.id);
        if (!drawnLink) continue;
        linksToConnect.push(drawnLink);
        drawnLink.set(side, { id: drawnLink.get(side).id, port: port });
        (created as OpmProceduralLink).sourceVisualElementPort = port;
      }
      const linkDirection = (side === 'target') ? { inbound: true } : { outbound: true };
      if (linksToConnect[0] && !linksToConnect[0].triangle)
        linksToConnect[0].repositionPort(side, this.linkSource, linkDirection, this.initRappid);
      const arc = (side === 'target') ? linksToConnect[0].getTargetArcOnLink() : linksToConnect[0].getSourceArcOnLink();
      if (this.orxorSelection === 'OR' && !linksToConnect[0].triangle && arc)
        arc.toggleArcType();
    }
    if (this.notSelection && this.notSelection.toUpperCase() !== 'NONE' && ret.result.success && ret.result.created.length > 1) {
      const linksToConnect = [];
      const point = this.getTargetsMidPoint(ret.result.created);
      let side = 'source';
      if (ret.result.created[0].type === linkType.Effect && this.linkSource instanceof OpmObject) {
        side = 'target';
      }
      let port = this.linkSource instanceof OpmState ? 10 : this.linkSource.findClosestEmptyPort(point);
      this.initRappid.selection.collection.reset([]);
      for (const created of ret.result.created) {
        const drawnLink = this.initRappid.graph.getCell(created.id);
        if (!drawnLink) continue;
        linksToConnect.push(drawnLink);
        drawnLink.set(side, { id: drawnLink.get(side).id, port: port });
        (created as OpmProceduralLink).sourceVisualElementPort = port;
      }
      const linkDirection = (side === 'target') ? { inbound: true } : { outbound: true };
      if (linksToConnect[0] && !linksToConnect[0].triangle)
        linksToConnect[0].repositionPort(side, this.linkSource, linkDirection, this.initRappid);
      const arc = (side === 'target') ? linksToConnect[0].getTargetArcOnLink() : linksToConnect[0].getSourceArcOnLink();
      if (this.orxorSelection === 'None' && !linksToConnect[0].triangle && arc)
        arc.toggleArcType(this.notSelection);
    }
    this.initRappid.opmModel.setShouldLogForUndoRedo(true, 'DialogComponent-onClickedExit1');
    this.close.emit(this.selected);
    this.dialogRef.close(this.selected);
  }
  getTargetsMidPoint(created: ReadonlyArray<OpmLink>) {
    const point = { x: 0, y: 0 };
    for (const link of created) {
      const drawnLink = this.initRappid.graph.getCell(link.id);
      if (drawnLink) {
        const cellView = this.initRappid.paper.findViewByModel(drawnLink);
        const target = cellView.getPointAtLength(-1);
        point.x += target.x;
        point.y += target.y;
      }
    }
    point.x = point.x / created.length;
    point.y = point.y / created.length;
    return point;
  }

  filterArray(selection) {
    let filteredFinalArray = [];
    for (let i = 0; i < selection.length; i++) {
      if (selection[i] instanceof OpmObject || selection[i] instanceof OpmProcess) {
        filteredFinalArray.push(selection[i]);
      }
    }
    return filteredFinalArray;
  }

  legalLinks() {
    let link_attrs = LinkConstraints.isValidLink(this.newLink, this.selected.name, this.initRappid);
    if (!(link_attrs.isValidLink)) {
      validationAlert(link_attrs.errorMessage, null, 'Error');
      this.DefaultExit(this.newLink);
      return false;
    }
    let prevTragetNewlinkid = this.newLink.get('target').id;
    let prevTragetNewlinkPort = this.newLink.get('target').port;
    if (this.newLink.selection && this.newLink.selection.collection.models) {
      this.selected.targetId = this.newLink.get('target').id;
      for (let i = 0; i < this.newLink.selection.collection.models.length; i++) {
        if (this.newLink.selection.collection.models[i].id !== this.selected.targetId) {
          this.newLink.set('target', { id: this.newLink.selection.collection.models[i].id });
          let link_attrs = LinkConstraints.isValidLink(this.newLink, this.selected.name, this.initRappid);
          if (!(link_attrs.isValidLink)) {
            validationAlert(link_attrs.errorMessage, null, 'Error');
            this.DefaultExit(this.newLink);
            return false;
          }
        }
      }
    }
    const target = { id: prevTragetNewlinkid };
    if (prevTragetNewlinkPort) {
      target['port'] = prevTragetNewlinkPort;
    }
    // this.newLink.set('target', target);
    return true;

  }

  /**
   * Alon: creating links from state to object
   */
  stateToObject() {
    let partner;
    const source = this.linkSource;
    const target = this.linkTarget;
    const isCondition = null;
    const isEvent = null;
    const isNegation = null;
    partner = this.findPartner(source, target);
    if (partner === null || partner === undefined) {
      const consumptionLink = new ConsumptionLink(source, target, isCondition, isEvent, isNegation);
      const resultLink = new ResultLink(target, source.getAncestors()[0], isCondition, isEvent);
      consumptionLink.set('previousTargetId', target.id);
      consumptionLink.set('previousSourceId', source.id);
      resultLink.set('previousTargetId', target.id);
      resultLink.set('previousSourceId', source.id);
      consumptionLink.partner = resultLink;
      resultLink.partner = consumptionLink;
      resultLink.set('fromState', 'stateToObject');
      this.graph.addCell(consumptionLink);
      this.graph.addCell(resultLink);
      // this.graph.getConnectedLinks(this.linkSource)[0].remove();
      this.findDefault(source, target).remove();
    }
    else {
      this.findDefault(source, target).remove();
    }
    this.close.emit(this.selected);
    this.dialogRef.close(this.selected);
  }

  processToState() {
    let partner;
    const source = this.linkSource;
    const target = this.linkTarget;
    const isCondition = null;
    const isEvent = null;
    const isNegation = null;
    partner = this.findPartner(source, target);
    if (partner === null || partner === undefined) {
      const consumptionLink = new ConsumptionLink(source, target, isCondition, isEvent, isNegation);
      const resultLink = new ResultLink(target.getAncestors()[0], source, isCondition, isEvent);
      consumptionLink.set('previousTargetId', source.id);
      consumptionLink.set('previousSourceId', target.id);
      resultLink.set('previousTargetId', source.id);
      resultLink.set('previousSourceId', target.id);
      consumptionLink.partner = resultLink;
      resultLink.partner = consumptionLink;
      resultLink.set('fromState', 'ProcessToState');
      this.graph.addCell(consumptionLink);
      this.graph.addCell(resultLink);
      // this.graph.getConnectedLinks(this.linkSource)[0].remove();
      this.findDefault(source, target).remove();
    } else {

      this.findDefault(source, target).remove();
    }

    this.close.emit(this.selected);
    this.dialogRef.close(this.selected);
  }

  findPartner(source, target) {
    let partnerFound;
    let linkArr = (this.graph.getConnectedLinks(source).length > 1) ? this.graph.getConnectedLinks(source) : this.graph.getConnectedLinks(target);
    for (let i = 0; i < linkArr.length; i++) {
      if (linkArr[i].partner) {
        partnerFound = linkArr[i].partner;
        break;
      }
    }
    return partnerFound;
  }

  findDefault(source, target) {
    let defaultLink;
    let linkArr = (this.graph.getConnectedLinks(source).length > 1) ? this.graph.getConnectedLinks(source) : this.graph.getConnectedLinks(target);
    for (let i = 0; i < linkArr.length; i++) {
      if (linkArr[i].attr('line/strokeDasharray') === "8 5") {
        defaultLink = linkArr[i];
        break;
      }
    }
    return defaultLink;
  }

  // use for colors
  get_style(data) {

    switch (data) {
      case 'opm.Object':
        return '#00b050';

      case 'opm.Process':
        return '#0070c0';
      case 'opm.State':
        return '#808000';
    }
  }

  // check link array size
  check_empty(links_set) {
    if (links_set.length === 0) {
      return this.noshow;
    } else {
      return this.show;
    }
  }

  // IsPhysicalObject(element) {
  //   if (element instanceof OpmObject) {
  //     return element.attributes.attrs.rect.filter.args.dx != 0;
  //   }
  //   if (element instanceof OpmState) {
  //     return this.graph.getCell(element.attributes.father).attributes.attrs.rect.filter.args.dx != 0;
  //   }
  // }
  // Kfir: not sure if this function is required
  IsPhysicalObject(element) {
    if (element instanceof OpmObject) {
      return element.getEssence() === Essence.Physical;
    }
    if (element instanceof OpmState) {
      return this.graph.getCell(element.attributes.father).getEssence() === Essence.Physical;
    }
  }

  replacename(linkname) {
    let serv = linkname;
    let ch;
    if (typeof linkname !== 'undefined') {
      for (ch of serv) {
        // if (ch === '_' || ch === '-' || ch === ' '){
        //   serv.charAt(serv.indexOf(ch)+1).toUpperCase();
        // }
        if (serv.indexOf('_') >= 0) {
          serv = linkname.replace(/_/g, ' ');
        }
        // else if (serv.indexOf('-') >= 0) {
        //   serv = linkname.replace(/-/g, ' ');
        // }
      }
      if (linkname === 'In-out_Link_Pair') {
        serv = 'In-Out Link Pair';
      } else if (linkname === 'In-out_Link_Pair_Condition') {
        serv = 'In-Out Condition Link Pair';
      } else if (linkname === 'In-out_Link_Pair_Event') {
        serv = 'In-Out Event Link Pair';
      } else if (linkname === 'OvertimeUndertime-exception') {
        serv = 'Overtime-Undertime Exception';
      } else if (linkname === 'Split_input') {
        serv = 'Split Input Link Pair';
      } else if (linkname === 'Split_output') {
        serv = 'Split Output Link Pair';
      }
    }
    let uppercaseChar;
    for (let character of serv) {
      if (character === ' ') { //character === '_' || character === '-' ||
        uppercaseChar = serv.charAt(serv.indexOf(character) + 1).toUpperCase();
        break;
      }
    }
    let arr = Array.from(serv);
    if (arr.indexOf(' ') > 0) {
      arr.splice(arr.indexOf(' ') + 1, 1, uppercaseChar);
      serv = arr.join('');
    }

    return serv;
  }

  agentUpdateNotIfNecessary(event) {
    this.notSelection = (event.target.value === 'NOT') ? 'NOT' : 'NONE';
    this.agentNotSelection = (event.target.value === 'NOT');
  }

  instrumentUpdateNotIfNecessary(event) {
    this.notSelection = (event.target.value === 'NOT') ? 'NOT' : 'NONE';
    this.instrumentNotSelection = (event.target.value === 'NOT');
  }

  consumptionUpdateNotIfNecessary(event) {
    this.notSelection = (event.target.value === 'NOT') ? 'NOT' : 'NONE';
    this.consumptionNotSelection = (event.target.value === 'NOT');
  }

  effectUpdateNotIfNecessary(event) {
    this.notSelection = (event.target.value === 'NOT') ? 'NOT' : 'NONE';
    this.effectNotSelection = (event.target.value === 'NOT');
  }
  agentNotChange(event) {
    this.notSelection = event.target.value;
    this.agentNotSelection = (event.target.value === 'NOT');
    const allNOTSelections = document.getElementsByClassName('AgentNOTSelection');
    for (const sel of allNOTSelections as any) {
      sel.value = this.notSelection;
    }
  }

  instrumentNotChange(event) {
    this.notSelection = event.target.value;
    this.instrumentNotSelection = (event.target.value === 'NOT');
    const allNOTSelections = document.getElementsByClassName('InstrumentNOTSelection');
    for (const sel of allNOTSelections as any) {
      sel.value = this.notSelection;
    }
  }

  consumptionNotChange(event) {
    this.notSelection = event.target.value;
    this.consumptionNotSelection = (event.target.value === 'NOT');
    const allNOTSelections = document.getElementsByClassName('ConsumptionNOTSelection');
    for (const sel of allNOTSelections as any) {
      sel.value = this.notSelection;
    }
  }

  effectNotChange(event) {
    this.notSelection = event.target.value;
    this.effectNotSelection = (event.target.value === 'NOT');
    const allNOTSelections = document.getElementsByClassName('EffectNOTSelection');
    for (const sel of allNOTSelections as any) {
      sel.value = this.notSelection;
    }
  }

  orXorChange(event) {
    this.orxorSelection = event.target.value;
    const allOrXorSelections = document.getElementsByClassName('ORXORSelection');
    for (const sel of allOrXorSelections as any) {
      sel.value = this.orxorSelection;
    }
  }

  // Close Button
  DefaultExit(link) {
    this.close.emit('event');
    // if (link.getSourceElement().constructor.name.includes('Semi'))
    //   link.getSourceElement().set('type', 'opm.SemiFolded');
    link.remove();
    this.dialogRef.close();
  }

  area() {
    return this.width * this.height;
  }

  onWindowPress(event: MouseEvent) {
    this.draggingWindow = true;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  onWindowDrag(event: MouseEvent) {
    if (!this.draggingWindow) {
      return;
    }
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;
    this.x += offsetX;
    this.y += offsetY;
    this.px = event.clientX;
    this.py = event.clientY;
  }

  topLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.y += offsetY;
    this.width -= offsetX;
    this.height -= offsetY;
  }

  topRightResize(offsetX: number, offsetY: number) {
    this.y += offsetY;
    this.width += offsetX;
    this.height -= offsetY;
  }

  bottomLeftResize(offsetX: number, offsetY: number) {
    this.x += offsetX;
    this.width -= offsetX;
    this.height += offsetY;
  }

  bottomRightResize(offsetX: number, offsetY: number) {
    this.width += offsetX;
    this.height += offsetY;
  }

  onCornerClick(event: MouseEvent, resizer?: Function) {
    this.draggingCorner = true;
    this.px = event.clientX;
    this.py = event.clientY;
    this.resizer = resizer;
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener('document:mousemove', ['$event'])
  onCornerMove(event: MouseEvent) {
    if (!this.draggingCorner) {
      return;
    }
    const offsetX = event.clientX - this.px;
    const offsetY = event.clientY - this.py;

    const lastX = this.x;
    const lastY = this.y;
    const pWidth = this.width;
    const pHeight = this.height;

    this.resizer(offsetX, offsetY);
    if (this.width < this.width_min || this.height < this.height_min) {
      this.x = lastX;
      this.y = lastY;
      this.width = pWidth;
      this.height = pHeight;
    }
    this.px = event.clientX;
    this.py = event.clientY;
  }

  @HostListener('document:mouseup', ['$event'])
  onCornerRelease(event: MouseEvent) {
    this.draggingWindow = false;
    this.draggingCorner = false;
  }

  ShowAlret() {
    return this.noshow;
  }

  setLinkDisplayAgent(event): any {
    this.agentSelection = event.target.value;
  }

  setLinkDisplayInsturment(event): any {
    this.instrumentSelection = event.target.value;
  }

  setLinkDisplayConsumption(event): any {
    this.consumptionSelection = event.target.value;
  }

  setLinkDisplayEffect(event): any {
    this.effectSelection = event.target.value;
  }

  setLinkDisplayException(event): any {
    this.exceptionSelection = event.target.value;
  }

  setLinkDisplaySplit(event): any {
    this.splitSelection = event.target.value;
  }

  shouldBeDisabled(type) {
    if (this._shouldBeDisabled.hasOwnProperty(type))
      return this._shouldBeDisabled[type];

    let lType;
    if (type.includes('Agent')) {
      lType = linkType.Agent;
    } else if (type.includes('Instrument')) {
      lType = linkType.Instrument;
    } else if (type.includes('Invocation')) {
      lType = linkType.Invocation;
    } else if (type.includes('Result')) {
      lType = linkType.Result;
    } else if (type.includes('Consumption')) {
      lType = linkType.Consumption;
    } else if (type.includes('UndertimeOvertimeException')) {
      lType = linkType.UndertimeOvertimeException;
    } else if (type.includes('Effect')) {
      lType = linkType.Effect;
    } else if (type.includes('Overtime')) {
      lType = linkType.OvertimeException;
    } else if (type.includes('Undertime')) {
      lType = linkType.UndertimeException;
    } else if (type.includes('Unidirectional')) {
      lType = linkType.Unidirectional;
    } else if (type.includes('Bidirectional')) {
      lType = linkType.Bidirectional;
    } else if (type.includes('Aggregation')) {
      lType = linkType.Aggregation;
    } else if (type.includes('Exhibition')) {
      lType = linkType.Exhibition;
    } else if (type.includes('Generalization')) {
      lType = linkType.Generalization;
    } else if (type.includes('Instantiation')) {
      lType = linkType.Instantiation;
    }
    const can = this.initRappid.opmModel.links.canConnect(this.linkSource.getVisual(), this.linkTarget.getVisual(), lType);
    this._shouldBeDisabled[type] = can;
    return can;
  }

  getToolTipMessages(name) {
    // if (this.shouldBeDisabled(name).success === true)
      return this.shouldBeDisabled(name).warnings.join('\n');
    // return '';
    // return this.shouldBeDisabled(link.name).message;
  }

  shouldShowWarningSign(name) {
    const ret = this.shouldBeDisabled(name);
    if (ret.success === true && ret.warnings && ret.warnings.length > 0)
      return true;
    if (ret.success === false && ret.warnings && ret.warnings.length > 0)
      return true;
    return false;
  }

  shouldShowExceptionLinks(type: string) {
    if (!(this.isSourceProcess() || this.isSourceState()) && !this.isSourceInTargets() &&
      (this.isProcessSelected() && !this.isObjectSelected() && !this.isStateSelected()))
      return false;

    if (this.isSourceObject())
      return false;

    if (this.isSourceProcess())
      return true;

    const isDuration = this.linkSource.getVisual().logicalElement.isTimeDuration();
    const duration = this.linkSource.getVisual().logicalElement.getDurationManager().getTimeDuration();

    if (type === 'all' && (!isDuration || (!duration.max && !duration.min)))
      return false;
    else if (type === 'overtime' && !duration.max)
      return false;
    else if (type === 'undertime' && !duration.min)
      return false;
    else if (type === 'overtimeundertime' && (!duration.max || !duration.min))
      return false;

    return true;
  }
}

function createCode(linkType, source, target) {
  if (linkType === 'Aggregation-Participation') {
    // console.log('class ' + source + '{');
    // console.log('    ' + target + ': number;');
    // console.log('}');
  }
}
