import {OpmRelation} from './OpmRelation';
import {OpmProceduralLink} from '../VisualPart/OpmProceduralLink';
import {TimeDurationModule} from "./components/TimeDurationModule";

class Rate {
  value: number;
  unit: string;
}
export class OpmProceduralRelation extends OpmRelation<OpmProceduralLink> {

  pathText: string;
  probability: number;
  rate: Rate;
  condition: boolean;
  event: boolean;
  negation: boolean;
  center: {x: number, y: number};
  duration = new TimeDurationModule();

  constructor(params, model) {
    super(params, model);
    this.duration.setTimeDuration({
      min: null,
      nominal: 1,
      max: null,
      units: 's'
    })
  }

  createVisual(params): OpmProceduralLink {
    return new OpmProceduralLink(params, this)
  }

  setParams(params) {
    super.setParams(params);
    this.center = params.center;
    this.condition = params.condition;
    this.event = params.event;
    this.negation = params.negation;
    this.pathText = params.path;
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
  }

  updateParams(params) {
    super.updateParams(params);
    this.center = params.center;
    this.condition = params.condition;
    this.event = params.event;
    this.negation = params.negation;
    this.pathText = params.path;
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
  }

  getParams() {
    const visualElementsParams = new Array();
    for (let i = 0; i < this.visualElements.length; i++) {
      visualElementsParams.push(this.visualElements[i].getParams());
    }
    const params = {
      condition: this.condition,
      event: this.event,
      negation: this.negation,
      path: this.pathText,
      visualElementsParams: visualElementsParams,
      min: this.duration.getTimeDuration().min,
      nominal: this.duration.getTimeDuration().nominal,
      max: this.duration.getTimeDuration().max,
      units: this.duration.getTimeDuration().units,
      timeDurationStatus:this.duration.getTimeDuration().timeDurationStatus,
    };
    return {...super.getRelationParams(), ...params};
  }
  getParamsFromJsonElement(jsonElement) {
    const params = {
      condition: jsonElement.condition,
      event: jsonElement.event,
      negation: jsonElement.negation
    };
    return {...super.getRelationParamsFromJsonElement(jsonElement), ...params};
  }

  getDurationManager(): TimeDurationModule {
    return this.duration;
  }
}
