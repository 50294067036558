// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  padding-top: 10px;
  text-align: center;
}

h3 {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
  margin-top: 3px;
}

.statusIndicator {
  width: 30px;
  float: left;
  margin-top: 4px;
}

::ng-deep .mat-tooltip  {
  white-space: pre-line !important;
}

hr {
  margin-top: -2px;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/methodological-checking-dialog/methodological-checking-dialog.css"],"names":[],"mappings":"AAAA;EACE,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,WAAW;EACX,WAAW;EACX,eAAe;AACjB;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".buttons {\n  padding-top: 10px;\n  text-align: center;\n}\n\nh3 {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n  margin-top: 3px;\n}\n\n.statusIndicator {\n  width: 30px;\n  float: left;\n  margin-top: 4px;\n}\n\n::ng-deep .mat-tooltip  {\n  white-space: pre-line !important;\n}\n\nhr {\n  margin-top: -2px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
