// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.settings_main_card_subcard {
  color: white;
  margin: 100px;
  width: 548px;
  height: 541px;
  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);
}

.settings_main_card_subcard .mat-grid-tile {
  transition: all 400ms ease-out 50ms;
}

.settings_main_card_subcard .mat-headline {
  font-size: x-large;
}

::ng-deep .mat-form-field-underline {
  display: none;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/Settings/settings-home/settings-home.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,YAAA;EACA,aAAA;EACA,uHAAA;AACF;;AAGA;EACE,mCAAA;AAAF;;AAGA;EACE,kBAAA;AAAF;;AAIA;EACE,aAAA;AADF","sourcesContent":[".settings_main_card_subcard{\n  color: white;\n  margin: 100px;\n  width: 548px;\n  height: 541px;\n  box-shadow: 0 16px 24px 2px rgba(0, 0, 0, 0.14), 0 6px 30px 5px rgba(0, 0, 0, 0.12), 0 8px 10px -5px rgba(0, 0, 0, 0.3);\n}\n\n\n.settings_main_card_subcard .mat-grid-tile {\n  transition: all 400ms ease-out 50ms;\n}\n\n.settings_main_card_subcard .mat-headline{\n  font-size: x-large;\n}\n\n\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
