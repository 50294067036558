// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opl-container {
  padding: 5px;
  overflow: auto;
  font-size: 14px;
}

p {
  margin: 5px 0;
}

.oplText{
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  /*align: Left;*/
}

:host >>> .object { color: #00b050; }
/*:host >>> .object { color: #21B609; }*/
:host >>> .process { color: #0070c0; }
:host >>> .state{ color: #808000; }

.highlighted rect{
  fill: #FF0000;
  stroke-width: 3px;
  stroke: #FF0000;
}

.highlighted ellipse{
  fill: #FF0000;
  stroke-width: 3px;
  stroke: #FF0000;
}

.oplHighlight{
  /* background-color: #F7DC6F */
  background-color: #E1E6EB



}

span:hover {
  /* background-color: #F7DC6F; */
  /* background-color: #D1D8E1 */
  background-color: #E1E6EB
}

p {
  display:inline-block;
}



`, "",{"version":3,"sources":["webpack://./src/app/rappid-components/rappid-opl/rappid-opl.component.css"],"names":[],"mappings":"AAAA;EACE,YAAY;EACZ,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,eAAe;AACjB;;AAEA,oBAAoB,cAAc,EAAE;AACpC,wCAAwC;AACxC,qBAAqB,cAAc,EAAE;AACrC,kBAAkB,cAAc,EAAE;;AAElC;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,8BAA8B;EAC9B;;;;AAIF;;AAEA;EACE,+BAA+B;EAC/B,8BAA8B;EAC9B;AACF;;AAEA;EACE,oBAAoB;AACtB","sourcesContent":[".opl-container {\n  padding: 5px;\n  overflow: auto;\n  font-size: 14px;\n}\n\np {\n  margin: 5px 0;\n}\n\n.oplText{\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  /*align: Left;*/\n}\n\n:host >>> .object { color: #00b050; }\n/*:host >>> .object { color: #21B609; }*/\n:host >>> .process { color: #0070c0; }\n:host >>> .state{ color: #808000; }\n\n.highlighted rect{\n  fill: #FF0000;\n  stroke-width: 3px;\n  stroke: #FF0000;\n}\n\n.highlighted ellipse{\n  fill: #FF0000;\n  stroke-width: 3px;\n  stroke: #FF0000;\n}\n\n.oplHighlight{\n  /* background-color: #F7DC6F */\n  background-color: #E1E6EB\n\n\n\n}\n\nspan:hover {\n  /* background-color: #F7DC6F; */\n  /* background-color: #D1D8E1 */\n  background-color: #E1E6EB\n}\n\np {\n  display:inline-block;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
