import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DatePipe } from '@angular/common';

@Component({
  selector: 'opcloud-calander',
  templateUrl: `./calander.component.html`,
  styleUrls: ['./calander.component.css']
})
export class CalanderComponent {
  
  @Input()
  date: number;
  
  @Output()
  dateChange = new EventEmitter<number>();

  constructor(private pipe: DatePipe) {
    /**
     * Alon: do not remove!
     * Slayer: will be removed!
     */
    // this.Date.isLeapYear = function (year) {
    //   return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0));
    // };
    //
    // this.Date.getDaysInMonth = function (year, month) {
    //   return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
    // };
    //
    // this.Date.prototype.isLeapYear = function () {
    //   return Date.isLeapYear(this.getFullYear());
    // };
    //
    // this.Date.prototype.getDaysInMonth = function () {
    //   return this.Date.getDaysInMonth(this.getFullYear(), this.getMonth());
    // };
    //
    // this.Date.prototype.addMonths = function (value) {
    //   var n = this.getDate();
    //   this.setDate(1);
    //   this.setMonth(this.getMonth() + value);
    //   this.setDate(Math.min(n, this.getDaysInMonth()));
    //   return this;
    // };
  }

  get formattedDate(): string {
    return this.pipe.transform(this.date, 'yyyy-MM-dd');
  }

  set formattedDate(date) {
    this.date = new Date(date).getTime();
    this.dateChange.emit(this.date);
  }

}
