import {Component, OnInit} from '@angular/core';
import {OplService, OplSettings} from '../../../opl-generation/opl.service';
import {defaultSettings, DisplayOpt, oplDefaultSettings, UnitsOpt, AliasOpt} from '../../../opl-generation/opl-database';
import {validationAlert} from '../../../configuration/rappidEnviromentFunctionality/shared';
import {Essence} from '../../../models/ConfigurationOptions';
import {UserService} from '../../../rappid-components/services/user.service';


@Component({
  selector: 'opcloud-opl-settings',
  templateUrl: './opl-settings.component.html',
  styleUrls: ['./opl-settings.component.css']
})
export class OplSettingsComponent implements OnInit {

  public availableLanguage;
  public edit = {};
  displayOpts = DisplayOpt;
  unitsOpts = UnitsOpt;
  aliasOpts = AliasOpt;
  public settings: OplSettings;
  EssenceTypes = Essence;
  oplTable = oplDefaultSettings.oplTables[oplDefaultSettings.language];

  constructor(private oplService: OplService, private user: UserService) {
  }

  ngOnInit() {
    this.availableLanguage = this.oplService.getAvailableLanguages();
    this.settings = {
      language: this.oplService.settings.language,
      displayOpt: this.oplService.settings.displayOpt,
      unitsOpt: this.oplService.settings.unitsOpt,
      aliasOpt: this.oplService.settings.aliasOpt,
      essence: this.oplService.settings.essence,
      oplNumbering: this.oplService.settings.oplNumbering,
      autoFormat: this.oplService.settings.autoFormat,
      highlightOpl: this.oplService.settings.highlightOpl,
      highlightOpd: this.oplService.settings.highlightOpd,
      syncOplcolorsFromOpd: this.oplService.settings.syncOplcolorsFromOpd,
      opdTreeProcessesAutoArrangement: this.oplService.settings.opdTreeProcessesAutoArrangement,
    };
  }

  updateSettingsAndUserOplSettings(settings: OplSettings) {
    this.oplService.updateUserSettings(settings);
    this.user.updateUserOplSetting(settings).then(() => validationAlert('Saved', null, 'Success'));
  }

  ReturnToDefault() {
    this.settings.language = defaultSettings.user.language;
    this.settings.displayOpt = defaultSettings.user.displayOpt;
    // this.settings.unitsOpt = defaultSettings.user.unitsOpt;
    this.settings.unitsOpt = this.oplService.orgOplSettings.unitsOpt;
    this.settings.aliasOpt = this.oplService.orgOplSettings.aliasOpt;
    // this.settings.aliasOpt = defaultSettings.user.aliasOpt;
    this.settings.essence = defaultSettings.user.essence;
    this.settings.syncOplcolorsFromOpd = defaultSettings.user.syncOplcolorsFromOpd;
    this.oplTable = defaultSettings.organization.oplTables[this.settings.language];
    this.settings.opdTreeProcessesAutoArrangement = defaultSettings.organization.opdTreeProcessesAutoArrangement;
    this.updateSettingsAndUserOplSettings(this.settings);
  }

  public updateLanguage() {
    const lan = this.settings.language;
    this.oplTable = this.oplService.getOplTable(lan);
    const settings_update = { 'language': lan, 'essence': this.oplService.settings.essence };
    this.updateSettingsAndUserOplSettings(settings_update);
  }

  public updateDisplayOpt() {
    const settings = { displayOpt: this.settings.displayOpt };
    this.updateSettingsAndUserOplSettings(settings);
  }
  public updateUnitsOpt() {
    const settings = { unitsOpt: this.settings.unitsOpt };
    this.updateSettingsAndUserOplSettings(settings);
  }
  public updateAliasOpt() {
    const settings = { aliasOpt: this.settings.aliasOpt };
    this.updateSettingsAndUserOplSettings(settings);
  }
  public updateEssence() {
    const settings = { essence: this.settings.essence };
    this.updateSettingsAndUserOplSettings(settings);
  }

  public updateOPLNumbering() {
    const settings = { oplNumbering: this.settings.oplNumbering };
    this.updateSettingsAndUserOplSettings(settings);
  }

  public updateAutoFormat() {
    const settings = { autoFormat: this.settings.autoFormat };
    this.updateSettingsAndUserOplSettings(settings);
  }

  public updateHighLightOpl() {
    const settings = { highlightOpl: this.settings.highlightOpl };
    this.updateSettingsAndUserOplSettings(settings);
  }

  public updateHighLightOpd() {
    const settings = { highlightOpd: this.settings.highlightOpd };
    this.updateSettingsAndUserOplSettings(settings);
  }

  private toDisplay(str: string) {
    return str[0].toUpperCase() + str.slice(1);
  }

  private displayLanguages(str: string) {
    switch (str) {
      case 'en': return 'English';
      case 'cn': return 'Chinese';
      case 'fr' : return 'French';
      case 'gr' : return 'German';
      case 'ko' : return 'Korean';
      case 'jp' : return 'Japanese';
      case 'es': return 'Spanish';
      case 'ml' : return 'Malayalam';
      case 'ru' : return 'Russian';
      case 'pt' : return 'Portuguese';
      default: return this.toDisplay(str); // should not get here unless new languages were added
    }
  }

  updateSyncOplcolorsFromOpd() {
    const settings = { syncOplcolorsFromOpd: this.settings.syncOplcolorsFromOpd };
    this.updateSettingsAndUserOplSettings(settings);
  }

  updateOpdTreeProcessesArrangment() {
    const settings = {'opdTreeProcessesAutoArrangement': this.settings.opdTreeProcessesAutoArrangement};
    this.updateSettingsAndUserOplSettings(settings);
  }
}

