import { OpmLogicalEntity } from './OpmLogicalEntity';
import { OpmVisualState } from '../VisualPart/OpmVisualState';
import { OpmVisualEllipsis } from '../VisualPart/OpmVisualEllipsis';
import { OpmEllipsis } from '../DrawnPart/EllipsisState';
import { OpmState } from '../DrawnPart/OpmState';
import { OpmVisualObject } from '../VisualPart/OpmVisualObject';
import { OpmLogicalObject } from './OpmLogicalObject';
import { TimeDurationModule } from './components/TimeDurationModule';
import {defaultSettings} from '../../opl-generation/opl-database';

export class OpmLogicalState extends OpmLogicalEntity<OpmVisualState> {

  private duration = new TimeDurationModule();

  private _stateType;
  // NEEDS TO BE CONST
  public /*readonly*/ _parent: OpmLogicalObject;

  constructor(params, model) {
    super(params, model);
    if (params)   // if the state is ellipsis state them there are no params
      this.parent = <OpmLogicalObject>this.opmModel.getLogicalElementByVisualId(params.fatherObjectId);
    //   this.visualElements[0].fatherObject.logicalElement.states.push(this);
    this.textModule.addTextualModules(this.duration);
  }

  // TODO: Created just untill we change the c'tor
  set parent(parent: OpmLogicalObject) {
    this._parent = parent;
  }

  get parent(): OpmLogicalObject {
    return this._parent;
  }

  get stateType() {
    return this._stateType;
  }

  set stateType(stateType) {
    this._stateType = stateType;
  }

  updateParams(params) {
    super.updateParams(params);
    this.stateType = params.stateType;
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
  }

  setParams(params) {
    super.setParams(params);
    this.stateType = params.stateType;
    if (params.timeDurationStatus) {
      const durationParams = {
        min: params.min ? params.min : null,
        nominal: params.nominal ? params.nominal : null,
        max: params.max ? params.max : null,
        units: params.units ? params.units : 's',
      };
      this.duration.setTimeDuration(durationParams);
    }
  }

  getParams() {
    const visualElementsParams = new Array();
    for (let i = 0; i < this.visualElements.length; i++) {
      visualElementsParams.push(this.visualElements[i].getParams());
    }
    const params = {
      stateType: this.stateType,
      visualElementsParams: visualElementsParams,
      min: this.duration.getTimeDuration().min,
      nominal: this.duration.getTimeDuration().nominal,
      max: this.duration.getTimeDuration().max,
      units: this.duration.getTimeDuration().units,
      //timeDurationStatus:this.duration.isTimeDuration(),
      timeDurationStatus:this.duration.getTimeDuration().timeDurationStatus,
    };
    return { ...super.getEntityParams(), ...params };
  }

  getParamsFromJsonElement(jsonElement) {
    const params = {
      stateType: jsonElement.stateType
    };
    return { ...super.getEntityParamsFromJsonElement(jsonElement), ...params };
  }

  createVisual(param): OpmVisualState {
    return new OpmVisualState(param, this);
  }

  createVisualState(parent: OpmVisualObject, param?): OpmVisualState {
    if (!param) {
      const drawn = new OpmState(this.text);
      param = drawn.getParams();
    }

    const visual = this.createVisual(param);
    visual.fatherObject = parent;
    //this.opmModel.currentOpd.visualElements.push(visual);

    return visual;
  }

  removeVisual(visual: OpmVisualState): void {
    for (let i = this.visualElements.length - 1; i >= 0; i--) {
      if (this.visualElements[i] === visual) {
        this.visualElements.splice(i, 1);
        this.opmModel.removeElementFromOpds(visual);
        break;
      }
    }
  }

  removeAllVisuals(): void {
    const list = Array<OpmVisualState>();
    for (let i = 0; i < this.visualElements.length; i++)
      list.push(this.visualElements[i]);
    for (let i = 0; i < list.length; i++)
      this.removeVisual(list[i]);
  }

  removeFromFather() {
    this.parent.removeState(this);
  }

  // TODO: Here just untill we change the c'tor
  removeFirstOnInit() {
    this.visualElements.length = 0;
  }

  // Architecture won't allow this to be any place eles.
  removeThis() {
    this.opmModel.removeLogicalElement(this);
    for (let i = this.visualElements.length - 1; i >= 0 ; i--) {
      const father = (<OpmVisualObject>this.visualElements[i].fatherObject);
      father.children.splice(father.children.findIndex(c => c === this.visualElements[i]), 1);
      const opd = this.opmModel.getOpdByThingId(this.visualElements[i].id);
      if (opd) opd.remove(this.visualElements[i].id);
    }
    // this.visualElements.length = 0;
  }

  isRefineable(): boolean {
    return false;
  }

  public isTimeDuration(): boolean { // true if has timeDuration
    return (this.duration.isTimeDuration());
  }

  public getBareName(): string {
    return this.textModule.getName();
  }

  getDurationManager(): TimeDurationModule {
    return this.duration;
  }

  getTextFormatter(): (text: string) => string {
    return (t: string) => super.getTextFormatter()(t).toLowerCase();
  }

  public isValidName(value: string): boolean {
    const parent = this.getFather() as OpmLogicalObject;
    const validation = parent.getValidationModule();
    if (validation.isActive()) {
      if (validation.validateValue(value) == false) {
        return false;
      }
    }

    return true;
  }

  public setText(text: string) {
    const parent = this.getFather() as OpmLogicalObject;
    if (parent.isComputational()) {
      parent.value = text;
    }

    super.setText(text);
  }

  private isNumeric(str) {
    return !isNaN(str as any) && !isNaN(parseFloat(str));
  }

  public getDisplayText(): string {
    const parent = this.getFather() as OpmLogicalObject;
    if (parent && parent.isComputational()) {
      const value = parent.value;
      if (value && value !== 'value') {
        if (this.isNumeric(value)) {
          const num = +value;
          const displayDigitsNum = this.opmModel.getOplService()?.settings?.numericComputationalDigits !== undefined
            ? this.opmModel.getOplService()?.settings?.numericComputationalDigits : defaultSettings.user['numericComputationalDigits'];
          if (num.toString().split('.')[1]?.length && num.toString().split('.')[1].length > displayDigitsNum) {
            return num.toFixed(displayDigitsNum).toString();
          }
          return num.toString();
        }
        return value;
      }
      const validation = parent.getValidationModule();
      if (validation.isActive()) {
        return parent.getValidationModule().getRange();
      }
      return 'value';
    }
    return this.textModule.getDisplayText();
  }

}

export class OpmLogicalStateEllipsis {

  public name: string = "Opm.StateEllipsis";
  public text: string = '...';

  visualElements = new Array<OpmVisualEllipsis>();

  constructor() {
  }

  getDisplayText() {
    return '...';
  }

  setParams(params) {
    // We make no use of it. And remove it evantualy.
  }

  createVisual(parent: OpmVisualObject): OpmVisualEllipsis {
    const drawn = new OpmEllipsis();
    const visual = new OpmVisualEllipsis(drawn.getParams(), this);
    visual.fatherObject = parent;
    this.visualElements.push(visual);
    return visual;
  }

  remove(visual: OpmVisualEllipsis) {
    this.visualElements.filter(v => v !== visual);
  }

}
