import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from '../../rappid-components/services/user.service';


@Component({
    selector: 'user-agreement',
    templateUrl: 'user-agreement.component.html',
    styleUrls: ['user-agreement.component.css']
})
export class UserAgreementComponent {

    constructor(private dialogRef: MatDialogRef<UserAgreementComponent>,
      private userService: UserService) {
    }

    Accept() {
        this.userService.updateDB({ AcceptedEula: true }).then((res) => this.dialogRef.close());
    }
  //sign out from the database is based on the original way - with firebase
    Decline() {
      this.userService.signOutWithFirebase().then((res) => this.dialogRef.close());
    }


}
