import { OpmDefaultLink } from './OpmDefaultLink';
import { OpmEntity } from '../OpmEntity';
import { OpmProceduralLink as OpmVisualProceduralLink } from '../../VisualPart/OpmProceduralLink';
import {
  joint,
  _,
  validationAlert,
  popupGenerator,
  geometry,
  getInitRappidShared, stylePopup, removeDuplicationsInArray, OPCloudUtils
} from '../../../configuration/rappidEnviromentFunctionality/shared';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import { Arc } from './OrXorArcs';
import { OpmLink } from '../../VisualPart/OpmLink';
import {click_li_selection_function, createUnitsPopUpContent, unitsHtml} from '../components/units/units.popup';
import {OpmVisualProcess} from "../../VisualPart/OpmVisualProcess";
import {OpmVisualEntity} from "../../VisualPart/OpmVisualEntity";
import {OpmVisualObject} from "../../VisualPart/OpmVisualObject";
import {OpmVisualThing} from "../../VisualPart/OpmVisualThing";
import {linkType} from "../../ConfigurationOptions";

export interface Pathable {
  removePath(options): void;
}

export class OpmProceduralLink extends OpmDefaultLink {

  sourceElement: OpmEntity;
  targetElement: OpmEntity;
  condition: boolean;
  event: boolean;
  negation: boolean;
  sourceArcOnLink: Arc;
  targetArcOnLink: Arc;

  constructor(sourceElement, targetElement, condition, event, negation, id?: string) {
    super(id);
    this.sourceElement = sourceElement;
    this.targetElement = targetElement;
    this.condition = condition;
    this.event = event;
    this.negation = negation;
    this.set({ 'source': { 'id': typeof sourceElement !== 'undefined' ? this.sourceElement.id : '' } });
    this.set({ 'target': { 'id': typeof targetElement !== 'undefined' ? this.targetElement.id : '' } });
    this.attr({ 'line': { 'strokeDasharray': '0' } });
    this.attr({ 'line': { 'stroke': '#586D8C' } });
    this.attr({ 'line': { 'strokeWidth': 2 } });
    this.attr({ 'sourceMarker': { 'strokeWidth': 2 } });
    this.attr({ 'targetMarker': { 'strokeWidth': 2 } });
    this.UpdateSpecialLinks();
    this.registerEvents();
  }

  isProceduralLink(): boolean {
    return true;
  }

  changePositionHandle(initRappid) {
    super.changePositionHandle(initRappid);
    this.UpdateVertices();
  }

  getSourceArcOnLink() {
    return this.sourceArcOnLink;
  }

  getTargetArcOnLink() {
    return this.targetArcOnLink;
  }

  setSourceArcOnLink(arcToSet) {
    this.sourceArcOnLink = arcToSet;
  }

  setTargetArcOnLink(arc) {
    this.targetArcOnLink = arc;
  }

  UpdateVertices(src?, dst?) {
  }
  registerEvents() {
    this.on('change:vertices', function () {
      let init;
      if (this.getSourceArcOnLink()) {
        init = this.getSourceArcOnLink().initRappid;
      }
      if (this.getTargetArcOnLink()) {
        init = this.getTargetArcOnLink().initRappid;
      }
      if (init)
        Arc.redrawLinkArcs(this, init);
    });
    this.on('change:source', function () {
      let init;
      if (this.getSourceArcOnLink()) {
        init = this.getSourceArcOnLink().initRappid;
        Arc.makeLinksArcsTransparent(this);
      }
      if (init && this.getSourceElement())
        Arc.redrawLinkArcs(this, init);
    });
    this.on('change:target', function () {
      let init;
      if (this.getTargetArcOnLink()) {
        init = this.getTargetArcOnLink().initRappid;
        Arc.makeLinksArcsTransparent(this);
      }
      if (init && this.getTargetElement())
        Arc.redrawLinkArcs(this, init);
    });
  }
  doubleClickHandle(cellView, evt, init) {
    if (this.getSourceArcOnLink() || this.getTargetArcOnLink())
      Arc.redrawLinkArcs(this, init);
  }
  UpdateSpecialLinks(distance = 0.9) {
    let symbolAdding: string = this.getSymbolLinkPerType();
    if (symbolAdding !== '') {    // The link is a condition/event/negation link
      const xDiff = this.targetElement.get('position').x - this.sourceElement.get('position').x;
      const yDiff = this.targetElement.get('position').y - this.sourceElement.get('position').y;
      const labels = this.labels().filter(item => (item.attrs.label));  // only those with labels of new type
      const lableIndex = labels.filter(item => (item.attrs.label.text === symbolAdding));
      this.removeLabel(lableIndex);
      // sets the label for condition/event/negation multiplicity.
      this.setLabelsOLinks(symbolAdding, 0.9, 10, -5, undefined, undefined, false);
    }
  }

  getSymbolLinkPerType() {
    if (this.condition && this.negation) {
      // Negation condition link
      return "\u00ac" + ' ' + 'c';
    } else if (this.condition) {
      // Condition link
      return 'c';
    } else if (this.event) {
      // Event link
      return 'e';
    } else if (this.negation) {
      // Negation link (UTF-8 'NOT' operator)
      return "\u00ac";
    }
    return '';
  }

  updateMarkersColor(color = '#586D8C') {
    this.attr('line/sourceMarker/stroke', color);
    this.attr('line/targetMarker/stroke', color);
  }

  getProceduralLinkParams() {
    const params = {
      path: this.get('Path'),
      Probability: this.get('Probability'),
      rate: this.get('rate'),
      rateUnits: this.get('rateUnits'),
      sourceMultiplicity: this.get('sourceMultiplicity'),
      targetMultiplicity: this.get('targetMultiplicity'),
      timeMin: this.get('timeMin'),
      timeMax: this.get('timeMax'),
      timeMinVal: this.get('timeMinVal'),
      timeMaxVal: this.get('timeMaxVal'),
      condition: this.condition,
      event: this.event,
      negation: this.negation
    };
    return { ...super.getDefaultLinkParams(), ...params };
  }

  popupContentDbClick() {
    const sourceMultiplicity = (this.attributes.sourceMultiplicity) ? this.attributes.sourceMultiplicity : '';
    const targetMultiplicity = (this.attributes.targetMultiplicity) ? this.attributes.targetMultiplicity : '';
    const path = this.attributes.Path ? this.attributes.Path : ''; // ? this.attributes.Path : '';
    const Probability = (this.attributes.Probability) ? this.attributes.Probability : '';
    const requirements = this.attributes.requirements || '';
    // const timeMin = (this.attributes.timeMin) ? this.attributes.timeMin : '';
    // const timeMax = (this.attributes.timeMax) ? this.attributes.timeMax : '';
    // const timeMinVal = (this.attributes.timeMaxVal) ? this.attributes.timeMaxVal : '';
    // const timeMaxVal = (this.attributes.timeMinVal) ? this.attributes.timeMinVal : '';
    return this.createLabelsToShow(sourceMultiplicity, targetMultiplicity, path, Probability, requirements);

  }

  /**
   * Alon: decides which labels to return
   * @returns {string[]}
   */
  createLabelsToShow(sourceMultiplicity, targetMultiplicity, path, Probability, requirements) {
    const labelsForEnablerProceduralLinks = [
      '<div style="height: 16px" ><div class="textAndInput">Source Multiplicity:<input size="2" class="PopupInput srce" value="' + sourceMultiplicity.trim() + '" ></div>' +
      '<span class="iconSpan" data-title="' + this.getSourceMultiplicityPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px"><div class="textAndInput">Path:<input size="2" class="PopupInput pth" value="' + path.trim() + '"></div>' +
      '<span class="iconSpan" data-title="' + this.getPathPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px"><div class="textAndInput">Probability (0..1): <input size="2" class="PopupInput prob" value="' + Probability.trim() + '"></div>' +
      '<span  class="iconSpan" data-title="' + this.getProbabilityPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      this.getRequirementsPopupContent()
    ];

    const labelForProceduralNonEnablerLinks = [
      '<div style="height: 16px" ><div class="textAndInput">Source Multiplicity:<input size="2" class="PopupInput srce" value="' + sourceMultiplicity.trim() + '" ></div>' +
      '<span class="iconSpan" data-title="' + this.getSourceMultiplicityPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px" ><div class="textAndInput">Target Multiplicity : <input size="2"  class="trgt PopupInput" value="' + targetMultiplicity.trim() + '"></div><span data-title="' + this.getTargetMultiplicityPopupTooltipText() + '">' +
      '<img class="questionMarkForInfo"src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px"><div class="textAndInput">Path:<input size="2" class="PopupInput pth" value="' + path.trim() + '"></div>' +
      '<span class="iconSpan" data-title="' + this.getPathPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
      '<div style="height: 16px"><div class="textAndInput">Probability (0..1): <input size="2" class="PopupInput prob" value="' + Probability.trim() + '"></div>' +
      '<span  class="iconSpan" data-title="' + this.getProbabilityPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span></div><br>',
    ];

    return (this.get('name').includes('Agent') || this.get('name').includes('Instrument') || this.get('name').includes('time')) ? labelsForEnablerProceduralLinks : labelForProceduralNonEnablerLinks;

    // [
    //    +
    // 'TIme: <br>' +
    // '<input size="2" class="min" value="' + timeMin.trim() + '"> ' +
    // '<select id="selectMin">' +
    // '<option value="ms">Milliseconds</option>' +
    // '<option value="sec">Seconds</option>' +
    // '<option value="min">Minutes</option>' +
    // '<option value="hrs">Hours</option>' +
    // '<option value="days">Days</option>' +
    // '<option value="mths">Months</option>' +
    // '</select>' +
    // ' <br>' +
    // '<input size="2" class="max" value="' + timeMax.trim() + '"> '+
    // '<select id="selectMax">' +
    // '<option value="ms">Milliseconds</option>' +
    // '<option value="sec">Seconds</option>' +
    // '<option value="min">Minutes</option>' +
    // '<option value="hrs">Hours</option>' +
    // '<option value="days">Days</option>' +
    // '<option value="mths">Months</option>' +
    // '</select>' +
    // ' <br>' +
    // ];
  }

  getRequirementsPopupContent() {
    const requirements = this.attributes.requirements || '';
    const showReqLabel = this.attributes.showRequirementsLabel;
    setTimeout(() => {
      if ($('.showReq').length)
        (<any>$('.showReq')[0]).checked = showReqLabel;
    }, 500); // fix for a bug that the custom "V" causes.
    return '<div style="height: 30px; width: 273px;" ><div class="textAndInput">Requirement Set:<input size="2" class="PopupInput req" value="' + requirements + '" ></div><span data-title="Displaying satisfied requirements text on the link"><input type="checkbox"" class="checkbox-round showReq"></span>' +
    '<span class="iconSpan" style="margin-left: -8px;" data-title="' + this.getRequirementsPopupTooltipText() + '"><img class="questionMarkForInfo" src="assets/SVG/questionmark.svg"></span><img class="urlSvg" src="assets/SVG/url.svg"></div>';
  }

  popupEventsDbClick(element, init: InitRappidService) {
    const this_ = this;
    let isProb = false;

    return {
      'click .urlSvg': function() {
        const dataToRemember = this_.getPopupDataToRemember(this);
        this_.openLinkURLEditing(init).afterClosed().toPromise().then(res => {
          this_.rightClickHandlePopoup(init.paper.findViewByModel(element).el, init);
          this_.restorePopupData(dataToRemember);
        });
        this.remove();
      },
      'click .btnUpdate': function () {
        init.getOpmModel().logForUndo('link labels update');
        const path = this.$('.pth').val().trim();
        let prob = (this_.isLegalProboballity(this.$('.prob').val()).trim()) ?
          this_.isLegalProboballity(this.$('.prob').val().trim()) : this_.attributes.Probability;
        if (prob === ' ') {
          prob = '';
        }
        const rate = this.$('.rate').val()?.trim().length ? this.$('.rate').val().trim() : undefined;
        const rateUnits = this.$('.rateUnits').val();
        const textArray = [];
        const requirementsText = this.$('.req').val()?.trim();
        const showRequirementsLabel = this.$('.showReq')[0].checked;
        element.set('requirements', requirementsText);
        element.set('showRequirementsLabel', showRequirementsLabel);
        // if (path && (/\w/.test(path))) { // if a path field was inputted
        //   const val = path.trim();
        //   textArray.push(val);
        // }
        if (prob) {// && (/\d/.test(prob))) {
          isProb = true;
          textArray.push('Pr = ' + prob.toLowerCase().trim());
        }
        if (rate && (rate !== '') && (/\S/.test(rate))) {
          textArray.push('Rate = ' + rate.toLowerCase().trim() + (rateUnits ? ' [' + rateUnits.trim() + ']' : ''));
        }

        const trgLblTxt = (this.$('.trgt').val() !== undefined) && (/\S/.test(this.$('.trgt').val().trim())) ? this.$('.trgt').val().toLowerCase().trim() : '';
        const srcLblTxt = (this.$('.srce').val() !== undefined) && (/\S/.test(this.$('.srce').val().trim())) ? this.$('.srce').val().toLowerCase().trim() : '';
        const probabillityRatePathLblTxt = textArray.join('; ');
        const minTimeTxt = 'Min: ' + this.$('.min').val() + this.$('#selectMin').val();
        const maxTimeTxt = 'Max: ' + this.$('.max').val() + this.$('#selectMax').val();
        let symbulTxt;
        if (element.labels().length > 0) {
          element.labels().forEach((el) => {
            if (el.attrs.label.text === 'c' || el.attrs.label.text === 'e' || el.attrs.label.text.includes("\u00ac")) {
              symbulTxt = el.attrs.label.text;
            }
          });
        }

        if (element.labels().length > 0)
          element.deleteLabel();

        // sets the label for target multiplicity.

        element.setLabelsOLinks(trgLblTxt, 0.9, -10);

        // sets the label for source multiplicity.
        element.setLabelsOLinks(srcLblTxt, 0.1, -10, undefined, undefined, undefined, srcLblTxt?.trim() !== '?');

        if (path !== this_.get('Path'))
          this_.updatePathToAllRelatedRelations(element, path, this_.get('Path'));

        // sets the label/s for path, probability & rate multiplicity.
        if (path || prob || rate)
          element.setLabelsOLinks(probabillityRatePathLblTxt, 0.55, 10);

        if (showRequirementsLabel && requirementsText.length > 0)
          element.setLabelsOLinks('Satisfied: ' + requirementsText, 0.5, 0, -30);

        // sets the label for minimum time multiplicity.
        // if (this.$('.min').val())
        //   element.setLabelsOLinks(minTimeTxt, 0.3, 20, -15);

        // sets the label for maximum time multiplicity.
        // if (this.$('.max').val())
        //   element.setLabelsOLinks(maxTimeTxt, 0.3, 75);

        // sets the label for condition/ event  multiplicity.
        if (symbulTxt)
          element.setLabelsOLinks(symbulTxt, 0.9, 10, -5, undefined, undefined, false);
        // console.log(element.labels());
        element.set({
          'sourceMultiplicity': this.$('.srce').val()?.trim(),
          'targetMultiplicity': this.$('.trgt').val()?.trim(),
          'Path': this.$('.pth').val()?.trim(),
          'Probability': (isProb) ? prob : '',
          'rate': this.$('.rate').val()?.trim(),
          'rateUnits': this.$('.rateUnits').val(),
          'timeMin': this.$('.min').val(),
          'timeMinVal': this.$('#selectMin').val(),
          'timeMaxVal': this.$('#selectMax').val(),
          'timeMax': this.$('.max').val(),
        });

        this.remove();
        const labelsArray = {
          'sourceMultiplicity': this.$('.srce').val(),
          'targetMultiplicity': this.$('.trgt').val(),
          'Path': this.$('.pth').val()?.trim(),
          'Probability': (isProb) ? prob : '',
          'rate': this.$('.rate').val()?.trim(),
          'rateUnits': this.$('.rateUnits').val()
        };

        const link = init.getOpmModel().getVisualElementById(element.id) as OpmVisualProceduralLink;
        const links = link.setLabels(labelsArray);

        for (const l of links) {
          const cell = element.graph.getCell(l.id);
          if (cell) {
            cell.setPath(l.path);
          }
        }
        this_.addDblClickListenerForLabels();
      },
      /**
       * group 04:
       * By clicking on the 'Copy Style' button, we keep the style of the source link in the 'linkCopiedStyleParams' dictionary.
       */
      'click .btnStyleCopy': function () {
        this.remove();
        init.linkCopiedStyleParams = {};
        init.linkCopiedStyleParams['strokeWidth'] = this_.attr('line/strokeWidth');
        init.linkCopiedStyleParams['strokeColor'] = this_.attr('line/stroke');
      },
      'click .btnStyle': function () {
        this.remove();
        const stylePopupContent = ['Link color: <input type="color" class="linkColor PopupColorInput" value=' + this_.attr('link/fill') + '><br>',
        'Link width: <input type="width" style="width:35px;padding-top: 5px" class="linkwidth PopupInput" value=' + this_.attr('line/strokeWidth') + '><br>',
          '<div class="center" style="padding-top: 5px"><button class="btnUpdateStyle Popup" style="margin-left: 6px">Update Style</button></div>'];
        const stylePopupEvents = {
          'click .btnUpdateStyle': function () {
            if (this.$('.linkwidth').val() < '1' || this.$('.linkwidth').val() > '6') {
              const errorMessage = 'Maximum width is 6';
              validationAlert(errorMessage, 5000, 'Error');
              return;
            }
            init.getOpmModel().logForUndo('link style change');
            this_.attr({ line: { 'stroke': this.$('.linkColor').val() } });
            this_.attr({ line: { 'strokeWidth': this.$('.linkwidth').val() } });
            this_.updateMarkersColor(this.$('.linkColor').val());
            this.remove();
          },
        };
        const el = init.paper.findViewByModel(this_).el;
        popupGenerator(el, stylePopupContent, stylePopupEvents).render();
        stylePopup();
        (<HTMLInputElement>$('.linkColor')[0]).value = this_.attr('line/stroke');
      },
      'click .rateUnits': function () {
        /*handling choosing the units (rate units) pop up*/
        this_.unitsPopUp(init, this);
      }
    };
  }

  /* handling choosing units (for the rate)*/
  public unitsPopUp(init: InitRappidService, linkPopUp) {
    const this_ = this;
    const target = init.paper.findViewByModel(this_).el;
    const units = this_.attributes.rateUnits ? this_.attributes.rateUnits : ''; // current units if existed
    const popup =  new joint.ui.Popup({
      id: 'units_popup',
      events: {
        'input #value': function () {
          const value = this.$('#value').val() ? this.$('#value').val() : '';
          const select = this.$('ul');
          select.empty();
          select.html(unitsHtml(value));
        },
        'click .li-selection': click_li_selection_function , // handling selecting a unit
        'click #update': function () { // saving the chosen unit
          const new_val = this.$('#value').val();
          this_.attributes.rateUnits = new_val;
          popup.remove();
          new joint.ui.Popup({ events: linkPopUp.events,
            content: linkPopUp.$el.contents(),
            target: target})
            .render();
          /* updating the new pop rate units value and styling it*/
          const unitsPopup = document.getElementsByClassName('joint-popup joint-theme-modern')[0] as HTMLDivElement;
          const elm = (unitsPopup.getElementsByClassName('rateUnits')[0] as HTMLInputElement);
          elm.value = new_val;
          stylePopup();
        }
      },
      content: [createUnitsPopUpContent(units, ' Rate Units:')],
      target: target
    }).render();
    stylePopup();
  }

  // deleteLabel() {
  //   if (this.labels().length > 0) {
  //     for (let i = 0; i < this.labels().length; i++) {
  //       this.removeLabel(this.labels()[i]);
  //     }
  //     this.deleteLabel();
  //   }
  // }

  /**
   * Alon: link pair are drawn with path (a-z)
   */
  // pathLettering() {
  //   const thisText = String.fromCharCode(this.getCounter() + 96);
  //   return {thisText};
  // }
  // addPath() {
  //   const target = this.targetElement;
  //   const connectedLinks = this.graph.getConnectedLinks(target, {inbound: true});
  //   for (let i = 0; i < connectedLinks.length; i++) {
  //     if ( connectedLinks[i].partner) {
  //       OpmProceduralLink.pairCounter++;
  //     }
  //   }
  //   this.set('labels', [
  //     { position: 0.5, attrs: { text: {
  //           text: this.pathLettering().thisText} } }]);
  // }

  updatePathToAllRelatedRelations(link: OpmDefaultLink, newPath: string, oldPath: string) {
    const model = getInitRappidShared().getOpmModel();
    const logical = model.getLogicalElementByVisualId(link.id);
    const related = model.getRelatedRelationsByLogicalLink(logical);
    // link.set('Path', newPath);
    if (!related) return;
    for (const log of related)
      for (const vis of log.visualElements) {
        if (vis.path === oldPath || (!vis.path && !link.get('Path'))) {
          vis.path = newPath;
          const drawn = this.graph.getCell(vis.id);
          if (!drawn) {
            if (vis.labels) {
              const visualLabelToUpdate = vis.labels.find(lb => lb.attrs.label.text === oldPath);
              if (visualLabelToUpdate)
                visualLabelToUpdate.attrs.label.text = newPath;
            }
            continue;
          }
          drawn.set('Path', vis.path);
          if (!drawn.get('labels')) {
            if (drawn !== this)
              drawn.setLabelsOLinks(vis.path, 0.5, 0);
            continue;
          }
          const currentLabel = drawn.get('labels').find(lb => lb.attrs.label.text === oldPath);
          if (!currentLabel) continue;
          const idxLbl = drawn.labels().indexOf(currentLabel);
          currentLabel.attrs.label.text = newPath;
          if (idxLbl !== -1) {
            drawn.removeLabel(idxLbl);
            drawn.insertLabel(-1, currentLabel);
          }
        }
      }
  }

  getAllSourcesOfLinkType() {
    let links = [];
    if (this.targetElement.attributes.type === 'opm.Process') {
      links = this.graph.getConnectedLinks(this.targetElement);
    } else {
      links = this.graph.getConnectedLinks(this.sourceElement);
    }
    const cells = [];
    const sources = [];
    const multiplicity = [];
    for (const link of links) {
      if (link.attributes.name === this.attributes.name && !link.attributes.partner) {
        const source = (link.getSourceElement().attributes.type != 'opm.Process') ? link.getSourceElement() : link.getTargetElement();
        sources.push(source);
        multiplicity.push(link.attributes.sourceMultiplicity);
        cells.push(link);
      }
    }
    return [sources, multiplicity, cells];
  }

  removeHandle(options) {
    super.removeHandle(options);
    let element;
    // if there is an Arc on the source side of the link - remove it an redraw it if it still should have an arc
    if (this.getSourceArcOnLink() != null) {
      element = this.sourceElement;
      this.getSourceArcOnLink().remove();
      Arc.redrawAllArcs(element, options, true);
    }
    // if there is an Arc on the target side of the link - remove it an redraw it if it still should have an arc
    if (this.getTargetArcOnLink() != null) {
      element = this.targetElement;
      this.getTargetArcOnLink().remove();
      Arc.redrawAllArcs(element, options, true);
    }
  }

  // When link is pressed we will make its arcs transparent (until pointerup)
  pointerDownHandle(cellView, initRappid) {
    Arc.makeLinksArcsTransparent(this);
  }

  mergeToOrXorIfPlacedOnOtherLink(cellView, options: InitRappidService) {
    if ((this.getSourceElement() && this.target().x && this.target().y) || (this.getTargetElement() && this.source().x && this.source().y)) {
      let side;
      if ((this.getSourceElement() && this.target().x && this.target().y))
        side = 'target';
      else side = 'source';
      const xPoint = (side === 'target') ? this.target().x : this.source().x;
      const yPoint = (side === 'target' ? this.target().y : this.source().y);
      const clientPoint = options.paper.localToClientPoint(xPoint, yPoint);
      const elements = document.elementsFromPoint(clientPoint.x, clientPoint.y);
      const htmlLinks = elements.filter(el => el.parentElement && el.parentElement.classList.value.includes('joint-link'));
      let jointLinks = htmlLinks.map(htmlLink => options.graph.getCell(htmlLink.parentElement.getAttribute('model-id'))).filter(
        l => l.constructor.name === this.constructor.name && l !== this);
      if (side === 'target' && jointLinks.find(l => l.getTargetArcOnLink()))
        jointLinks = jointLinks.concat(jointLinks.find(l => l.getTargetArcOnLink()).getTargetArcOnLink().getLinksArray());
      if (side === 'source' && jointLinks.find(l => l.getSourceArcOnLink()))
        jointLinks = jointLinks.concat(jointLinks.find(l => l.getSourceArcOnLink()).getSourceArcOnLink().getLinksArray());
      jointLinks = removeDuplicationsInArray(jointLinks);
      if (jointLinks.length < 1)
        return;
      options.getOpmModel().setShouldLogForUndoRedo(false, 'mergeToOrXorIfPlacedOnOtherLink');
      const linksElement = (side === 'target') ? jointLinks.find(lnk => lnk !== this).getTargetElement() : jointLinks.find(lnk => lnk !== this).getSourceElement();
      let bestPort;
      const lv = jointLinks[0].findView(options.paper);
      if (side === 'target')
        bestPort = jointLinks.find(lnk => lnk.target().port) ? jointLinks.find(lnk => lnk.target().port).target().port : jointLinks[0].getTargetElement().findClosestEmptyPort(lv.getPointAtRatio(1));
      else
        bestPort = jointLinks.find(lnk => lnk.source().port) ? jointLinks.find(lnk => lnk.source().port).source().port : jointLinks[0].getSourceElement().findClosestEmptyPort(lv.getPointAtRatio(0));
      if (!bestPort) {
        const point = (side === 'target') ? this.target() : this.source();
        bestPort = linksElement.findClosestEmptyPort(point) - 1;
      }
      if (bestPort !== -1) {
        if (side === 'target') {
          this.target({ id: linksElement.id, port: bestPort });
          (<OpmLink>this.getVisual()).targetVisualElementPort = bestPort;
          for (const lnk of jointLinks) {
            lnk.target({ id: linksElement.id, port: bestPort });
            lnk.getVisual().targetVisualElementPort = bestPort;
          }
        } else {
          this.source({ id: linksElement.id, port: bestPort });
          (<OpmLink>this.getVisual()).sourceVisualElementPort = bestPort;
          for (const lnk of jointLinks) {
            lnk.source({ id: linksElement.id, port: bestPort });
            lnk.getVisual().sourceVisualElementPort = bestPort;
          }
        }
        options.graphService.updateLinksView([...jointLinks.map(ln => ln.getVisual()), this.getVisual()]);
        setTimeout(() => { linksElement.pointerUpHandle(options.paper.findViewByModel(linksElement), options, undefined); }, 50);

      }
    }
    options.getOpmModel().setShouldLogForUndoRedo(true, 'mergeToOrXorIfPlacedOnOtherLink');
  }

  pointerUpHandle(cellView, initRappid, $event) {
    if ((this.getSourceElement() && this.target().x && this.target().y) || (this.getTargetElement() && this.source().x && this.source().y))
      this.mergeToOrXorIfPlacedOnOtherLink(cellView, initRappid);
    super.pointerUpHandle(cellView, initRappid, $event); // call the parent function
    // this.resizePort();
    if (cellView.model.graph) {
      if (cellView.model.changed.vertices) {
        Arc.redrawLinkArcs(cellView.model, initRappid);
      }
      // if the pointer up wasn't for add or remove a vertex the it was for changing source\ target
      if (!this.changed.vertices || ( this.constructor.name.includes('InvocationLink') && (this.changed.target || this.changed.source))) {
        // if a port was updated then there is XOR connection by default
        const logicalRelation = initRappid.opmModel.getLogicalElementByVisualId(this.get('id'));
        // no close source link found but there was logical connection before
        if (!this.repositionPort('source', this.getSourceElement(), { outbound: true }, initRappid) &&
          (logicalRelation.sourceLogicalConnection != null)) {
          // un-link source port in all opds and update connection
          logicalRelation.disconnectSourceLogicalConnectionAllVisuals();
          const previousArcPort = this.removeArc({ 'outbound': true }, this); // (source)
          // If after we took out the link there still should be an arc - Drawing it
          this.drawArc(initRappid, 'source', this.getSourceElement(), previousArcPort, { 'outbound': true });
        }
        // no close target link found but there was logical connection before
        if (!this.repositionPort('target', this.getTargetElement(), { inbound: true }, initRappid) &&
          (logicalRelation.targetLogicalConnection != null)) {
          // un-link target port in all opds and update connection
          logicalRelation.disconnectTargetLogicalConnectionAllVisuals();
          const previousArcPort = this.removeArc({ 'inbound': true }, this); // (target)
          // If after we took out the link there still should be an arc - Drawing it
          this.drawArc(initRappid, 'target', this.getTargetElement(), previousArcPort, { 'inbound': true });
        }
        // this.resizePort();
      }
    }
  }
  areBothEnablers(link1, link2) {
    if (link1.constructor.name.includes('Instrument') && link2.constructor.name.includes('Agent'))
      return true;
    if (link1.constructor.name.includes('Agent') && link2.constructor.name.includes('Instrument'))
      return true;
    return false;
  }
  // If a link connected to a port and there is another link of the same type on or two ports
  // next to the current the the port of the current link will be updated so that the bot be
  // at the same port.
  // side: source or target, element: connected element on side, linkDirection: outbound or inbound
  repositionPort(side, element, linkDirection, initRappid: InitRappidService, resultLinksFlag = false) {
    if (this.get(side).port) {
      let sameLinks = this.graph.getConnectedLinks(element, linkDirection);
      sameLinks = sameLinks.filter(item =>
        (((item.constructor.name === this.constructor.name) || this.areBothEnablers(this, item)) && (item !== this)));
      if (resultLinksFlag) {
        const trgt = this.get('target');
        const parentId = this.graph.getCell(trgt.id).get('parent');
        sameLinks = sameLinks.filter(item =>
          (item.graph.getCell(item.get('target').id).get('parent') &&
            item.graph.getCell(item.get('target').id).get('parent') === parentId));
      }
      this.negation = (this.negation === undefined || this.negation === null || this.negation === false) ? false : true;
      for (let i = 0; i < sameLinks.length; i++) {
        // if link of the same type has same condition\event definition
        sameLinks[i].condition = sameLinks[i].condition ? true : false;
        sameLinks[i].event = sameLinks[i].event ? true : false;
        sameLinks[i].negation = sameLinks[i].negation ? true : false;
        if (sameLinks[i].condition !== this.condition) continue;
        if (sameLinks[i].event !== this.event) continue;
        if (sameLinks[i].negation !== this.negation) continue;
        // if another link starts\ends in a port that is close to the current link port
        // close ports are ports with 2 step distance. objects and processes have 30 ports.
        // Therefore ports 29/0, 30/0, 30/1 are with distance>=29 and are close.

        const sameLinkPoint = (side === 'source') ?
          initRappid.paper.findViewByModel(sameLinks[i]).sourcePoint :
          initRappid.paper.findViewByModel(sameLinks[i]).targetPoint;
        const thisLinkPoint = (side === 'source') ?
          initRappid.paper.findViewByModel(this).sourcePoint :
          initRappid.paper.findViewByModel(this).targetPoint;
        const pointsDistance = Math.sqrt(Math.pow((sameLinkPoint.x - thisLinkPoint.x), 2) +
          Math.pow((sameLinkPoint.y - thisLinkPoint.y), 2));

        const distanceToMerge = resultLinksFlag ? 5 : 15;
        if ((sameLinks[i].get(side).port && pointsDistance <= distanceToMerge)) {
          const nonConsistantOpd = initRappid.opmModel.getOpdWithOneMissingLink(sameLinks[i].get('id'), this.get('id'));
          if (nonConsistantOpd) {
            validationAlert('One or more links are missing in ' + nonConsistantOpd +
              'while at least another one exists there. Add missing links and try again.', null, 'Error');
          } else {  // no consistency problems. can update port and connection
            const connectionPort = sameLinks[i].get(side).port ?
              sameLinks[i].get(side).port : this.get(side).port;
            this.set(side, { id: this.get(side).id, port: connectionPort });
            sameLinks[i].set(side, { id: sameLinks[i].get(side).id, port: connectionPort });
            initRappid.paper.findViewByModel(this).hideTools();
            if ($("[joint-selector=button]") || $("[joint-selector=icon]") || $("[data-tool-name=source-arrowhead]")) {
              $("[joint-selector=button]").remove();
              $("[joint-selector=icon]").remove();
              $("[data-tool-name=source-arrowhead]").remove();
            }
            const logicalSameLink = initRappid.opmModel.getLogicalElementByVisualId(sameLinks[i].get('id'));
            (<any>(initRappid.opmModel.getLogicalElementByVisualId(this.get('id')))).updatePortAndDataToAllVisuals(logicalSameLink, side);
            // draw arc
            this.drawArc(initRappid, side, element, sameLinks[i].get(side).port, linkDirection);
            return true;
          }
        }
      }
    }
    return false;
  }

  // linkDirection: inbound or outbound
  // port: number of the port
  // element: the element from which (if source) or to which (if target) links are connected
  // side: source or target
  drawArc(initRappid, side, element, port, linkDirection) {
    let myArcType = 1;
    // getting the logical representation of the link
    const logicalLink = initRappid.opmModel.getLogicalElementByVisualId(this.id);
    // finding the type of the loogical connection
    const currentLogicalConnection = (side === 'source') ? logicalLink.sourceLogicalConnection : logicalLink.targetLogicalConnection;
    if (currentLogicalConnection != null) {
      myArcType = currentLogicalConnection;
    }
    // get all link connected to the element
    let linksToConnect = element.graph.getConnectedLinks(element, linkDirection);
    linksToConnect.forEach(l => {
      l.condition = l.condition ? true : false;
      l.event = l.event ? true : false;
      l.negation = l.negation ? true : false;
    });
    // all the links get out from the same source
    linksToConnect = linksToConnect.filter(link => link.get(side).port === port
      && (link.constructor.name === this.constructor.name || this.areBothEnablers(this, link))
      && link.condition === this.condition
      && link.event === this.event
      && link.negation === this.negation
      && link.attr('./display') !== 'none');
    // if there are less than 2 link - no need to draw an Arc
    if (linksToConnect.length < 2) {
      return;
    }
    // if there is already an arc - remove it
    const previousArc = side === 'source' ? linksToConnect[0].getSourceArcOnLink() : linksToConnect[0].getTargetArcOnLink();
    if (previousArc) {
      previousArc.remove();
    }
    const pointsArray = new Array();
    // finding the points with distance 30 on all the links for finding angles
    for (let i = 0; i < linksToConnect.length; i++) {
      if (!initRappid.paper) {
        pointsArray.push({ x: 0, y: 0 });
        continue;
      }
      const linkView = initRappid.paper.findViewByModel(linksToConnect[i]);
      if (!linkView)
        continue;
      const line = geometry.g.line(linkView.sourcePoint, linkView.targetPoint);
      let point;
      if (side === 'source') {
        // point = linkView.getPointAtRatio(0.05);
        point = linkView.getPointAtLength(30);
      } else {
        const sourcePoint = linkView.sourceAnchor;
        const targetPoint = linkView.targetAnchor;
        const length = linkView.metrics.length;
        // point = linkView.getPointAtRatio(0.95);
        point = linkView.getPointAtLength(-30);
      }
      pointsArray.push(point);
    }
    let arc;
    if (initRappid.paper) {
      // origin point or target point of all the links to connect
      let dockPoint;
      const linkView = initRappid.paper.findViewByModel(linksToConnect[0]);
      if (!linkView)
        return;
      const line = geometry.g.line(linkView.sourcePoint, linkView.targetPoint);
      if (side === 'source') {
        // dockPoint = linkView.getPointAtLength(0);
        dockPoint = line.start;
      } else {
        const sourcePoint = linkView.sourceAnchor;
        const targetPoint = linkView.targetAnchor;
        const length = linkView.metrics.length;
        // dockPoint = linkView.getPointAtLength(length);
        dockPoint = line.end;
      }
      // first point of the arc
      let firstPoint;
      // second point of the arc
      let secondPoint;
      let largestAngle = 0;
      // finding the 2 points with the max angle that is < 180
      for (let i = 0; i < pointsArray.length; i++) {
        for (let j = i + 1; j < pointsArray.length; j++) {
          let currentAngle = dockPoint.angleBetween(pointsArray[i], pointsArray[j]);
          if (currentAngle > 180) {
            currentAngle = 360 - currentAngle;
          }
          if (currentAngle > largestAngle) {
            largestAngle = currentAngle;
            firstPoint = pointsArray[i];
            secondPoint = pointsArray[j];
          }
        }
      }
      // Creating a point for later use as an X-axis with the dockPoint for calculating angles
      const axisPoint = { x: dockPoint.x + 5, y: dockPoint.y };
      let firstAngle = 90 + dockPoint.angleBetween(firstPoint, axisPoint);
      let secondAngle = 90 + dockPoint.angleBetween(secondPoint, axisPoint);
      if (firstAngle > secondAngle) {
        const temp = firstAngle;
        firstAngle = secondAngle;
        secondAngle = temp;
      }
      // creating the drawn representation of the arc
      arc = new Arc(initRappid, linksToConnect.slice(0), dockPoint.x, dockPoint.y,
        side, firstAngle, secondAngle, linksToConnect[0].get(side).port);
    } else {
      arc = new Arc(initRappid, linksToConnect.slice(0), 0, 0,
        side, 10, 20, linksToConnect[0].get(side).port);
    }
    // adding the arc to the screen
    if (initRappid.paper)
      arc.getArcVec().appendTo(initRappid.paper.viewport);
    // Adding the arc to all of the links in the relation of OR/XOR
    if (side === 'source') {
      linksToConnect.forEach(function (linkInTheRelation) {
        linkInTheRelation.setSourceArcOnLink(arc);
      });
    } else {
      linksToConnect.forEach(function (linkInTheRelation) {
        linkInTheRelation.setTargetArcOnLink(arc);
      });
    }
    initRappid.oplService.oplSwitch.next('change arch');
  }

  // removing an Arc from the links
  removeArc(linkDiretion, link) {
    const parentElement = linkDiretion.outbound === true ? link.getSourceElement() : link.getTargetElement();
    const side = linkDiretion.outbound === true ? 'source' : 'target';
    let sameLinks = this.graph.getConnectedLinks(parentElement, linkDiretion);
    let linkPort;
    if (linkDiretion.outbound === true)
      linkPort = link.getSourceArcOnLink() ? link.getSourceArcOnLink().port : null;
    else
      linkPort = link.getTargetArcOnLink() ? link.getTargetArcOnLink().port : null;
    if (!linkPort) return;
    sameLinks = sameLinks.filter(linkInRelation => (linkInRelation.get(side).port === linkPort));
    let removedArcPort;
    if (linkDiretion.outbound === true) {
      removedArcPort = link.getSourceArcOnLink().getPort();
      link.getSourceArcOnLink().remove();
      link.setSourceArcOnLink(null);
      sameLinks.forEach(function (item) {
        if (item.getSourceArcOnLink() !== null) {
          item.setSourceArcOnLink(null);
        }
      });
    } else {
      removedArcPort = link.getTargetArcOnLink().getPort();
      link.getTargetArcOnLink().remove();
      link.setTargetArcOnLink(null);
      sameLinks.forEach(function (item) {
        if (item.getTargetArcOnLink() !== null) {
          item.setTargetArcOnLink(null);
        }
      });
    }
    return removedArcPort;
  }

  getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {

    return [verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, removeButton];
  }

  getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {
    return [verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, removeButton];
  }

  removePath() {
    const mates = this.getMates();
    this.attributes.Path = undefined;
    mates.forEach(m => m.remove());
  }

  setPath(label: string) {
    this.attributes.Path = label;
    // this.deleteLa  bel();
    this.setLabelsOLinks(label, 0.4);
  }

  protected getMates() {
    return [];
  }

  isLegalProboballity(value: string): string {
    let stringArray = Array.from(value);

    const hasLetters = this.checkforLettersInTheString(stringArray);
    if (hasLetters) {
      let errorMsg = 'Probabillity may only be numbers and mustn\'t include letters';
      validationAlert(errorMsg, null, 'Error', true);
      return;
    };
    if (stringArray.length === 0) {
      return ' ';
    }
    const hasDecimalPoint = stringArray.indexOf('.') !== -1;
    const isFirstCharEqualZero = stringArray[0] === '0';
    const isSecondCharDecimalPoint = stringArray.indexOf('.') === 1;
    if (hasDecimalPoint && isFirstCharEqualZero && isSecondCharDecimalPoint) {
      return value;
    }
    if (hasDecimalPoint && !(isFirstCharEqualZero)) {
      let placesToMoveLeft = Math.pow(10, stringArray.indexOf('.'));
      let stringToInt = parseInt(value);
      return (stringToInt / placesToMoveLeft).toString();
    }
    if (value && !(hasDecimalPoint) && !(isFirstCharEqualZero)) {
      return '0.' + value;
    }
  }

  checkforLettersInTheString(stringArray): boolean {
    for (let char of stringArray) {
      if (char.toLowerCase() != char.toUpperCase()) {
        return true;
      }
    }
    return false
  }

  switchEffectButton() {
    return new joint.linkTools.Button({
      markup: [
        // {
        //   tagName: 'circle',
        //   selector: 'button',
        //   attributes: {
        //     'r': 15,
        //     'fill': 'rgba(74, 185, 236, 0)',
        //     'cursor': 'pointer'
        //   }
        // },
        {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            'd': 'M 0 2 H 7 V 0 L 12 3 L 7 6 L 7 3 L 0 3 z M 3 7 L -5 7 L -5 5 L -9 8 L -5 11 L -5 8 L 3 8 z ',
            'fill': '#2B3E8A',
            'stroke': '#2B3E8A',
            'stroke-width': 1,
            'cursor': 'pointer',
            'transform': 'translate(7,0) rotate(90) scale(1.4)',
            'z-index': '999',
          }
        }],
      distance: '50%',
      action: null,
    });
  }


  /*
  Gets information about the distributing type to be executed: "inZoomed" or "structural refinement"
  (i.e. "classic" unfolding). The argument "fatherType" assists in the distributing procedure that
  occurs in "structuralSplit" method.
   */
  switchFromUnifiedToDistributed(state: string, fatherType: string) {
    const init = getInitRappidShared();
    const model = init.getOpmModel();
    const visualLink = model.getVisualElementById(this.id);
    const ret = (state === 'inZoomed') ? model.inzoomSplit(visualLink) : model.structuralSplit(visualLink, fatherType);
    const drawnLinkToRemove = init.graph.getCell(ret.remove.id);
    if (drawnLinkToRemove)
      drawnLinkToRemove.remove();
    init.graphService.updateLinksView(ret.show || []);
  }

  /*
  Gets information about the union procedure type to be executed: "inZoomed" or "structural refinement"
  (i.e. "classic" unfolding). The argument "fatherType" assist in the union procedure that occurs in
  "structuralUnite" method.
   */
  switchFromDistributedToUnified(state: string, fatherType: string) {
    const init = getInitRappidShared();
    const model = init.getOpmModel();
    const visualLink = model.getVisualElementById(this.id);
    const ret = (state === 'inZoomed') ? model.inzoomUnite (visualLink) : model.structuralUnite (visualLink, fatherType);
    for (const link of ret.remove) {
      const drawnLinkToRemove = init.graph.getCell (link.id);
      if (drawnLinkToRemove)
        drawnLinkToRemove.remove();
    }
    init.graphService.updateLinksView(ret.show || []);
  }

  /*
   Finds out if all other sub-processes are connected with enabler links to the same source object
   */
  isAllSiblingsAlsoConnected(): boolean {
    const init = getInitRappidShared();
    const model = init.getOpmModel();
    const sourceVis = this.getSourceElement().getVisual();
    const targetVis = this.getTargetElement().getVisual();
    const father = (<OpmVisualEntity>targetVis).fatherObject;
    const children = father.children.filter(ch => OPCloudUtils.isInstanceOfVisualProcess(ch));
    const connectionType: linkType = model.getVisualElementById(this.id).type;
    const instrumentLinksFromSource = sourceVis.getLinks().outGoing.filter(link => link.type === connectionType);
    let count = 0;
    for (const child of children) {
      for (const link of instrumentLinksFromSource) {
        if ((<OpmLink>link).target === child) {
          count++;
        }
      }
    }
    return count === children.length;
  }

  /*
   An assistant method, that determines if a visual thing is connected with procedural links,
   either out-going or in-going.
   */
  isAlone(sideKick: OpmVisualThing): boolean {
    if (sideKick.getLinks().outGoing.filter(link => link.isStructuralLink()).length > 0) {
      return false;
    }
    else if (sideKick.getLinks().inGoing.filter(link => link.isStructuralLink()).length > 0) {
      return false;
    }
    return true;
  }

  /*
  Determine if all out-going procedural links from visual-process are of the same type.
   */
  isAllStructuralsSameTypeProc(targetVis: OpmVisualProcess): boolean {
    const structLinksOut = targetVis.getLinks().outGoing.filter(link => link.isStructuralLink());
    // const structLinksIn = targetVis.getLinks().inGoing.filter(link => link.isStructuralLink());
    // if (structLinksIn.length > 0) {
    //   return false;
    // }
    if (structLinksOut.length === 0) {
      return false;
    }
    const lnkType = structLinksOut[0].type;
    for (const link of structLinksOut) {
      if (link.type !== lnkType) {
        return false;
      }
    }
    return true;
  }

  /*
  Determine if all out-going procedural links from visual-object are of the same type.
   */
  isAllStructuralsSameTypeObj(sourceVis: OpmVisualObject): boolean {
    const structLinksOut = sourceVis.getLinks().outGoing.filter(link => link.isStructuralLink());
    const structLinksIn = sourceVis.getLinks().inGoing.filter(link => link.isStructuralLink());
    if (structLinksIn.length > 0) {
      return false;
    }
    if (structLinksOut.length === 0) {
      return false;
    }
    const lnkType = structLinksOut[0].type;
    for (const link of structLinksOut) {
      if (link.type !== lnkType) {
        return false;
      }
    }
    return true;
  }

  /*
  Find out if all sub-objects/processes are also connected to the source/target element with the same enabler link type.
   */
  isAllOtherPartsAlsoConnectedWithSameStruct(visThing: OpmVisualThing): boolean {  // add check for same struct relation
    const init = getInitRappidShared();
    const model = init.getOpmModel();
    const sourceVis = this.getSourceElement().getVisual();
    const targetVis = this.getTargetElement().getVisual();
    const connectionType: linkType = model.getVisualElementById(this.id).type;
    const structLinks = visThing.getLinks().inGoing.filter(link => link.isStructuralLink());
    let flag;
    if (structLinks.length === 0 || structLinks.length > 1) {
      return false;
    }
    const structuralFather = structLinks[0].sourceVisualElement;
    let enablerLinksToTarget;
    if (OPCloudUtils.isInstanceOfVisualObject(structuralFather)) {
      if (!(this.isAllStructuralsSameTypeObj(structuralFather as OpmVisualObject))) {
        return false;
      }
      enablerLinksToTarget = targetVis.getLinks().inGoing.filter(link => link.type === connectionType);
      flag = 'object';
    }
    else {
      if (!(this.isAllStructuralsSameTypeProc(structuralFather as OpmVisualProcess))) {
        return false;
      }
      enablerLinksToTarget = sourceVis.getLinks().outGoing.filter(link => link.type === connectionType);
      flag = 'process';
    }
    const structLinksToChildren = (<OpmVisualThing>structuralFather).getLinks().outGoing.filter(link => link.isStructuralLink());
    const otherParts = [];
    for (const link of structLinksToChildren) {
      otherParts.push(link.target);
    }
    let count = 0;
    for (const part of otherParts) {
      for (const link of enablerLinksToTarget) {
        if (flag === 'object') {
          if ((<OpmLink>link).source === part) {
            count++;
          }
        } else {
          if ((<OpmLink>link).target === part) {
            count++;
          }
        }
      }
    }
    return count === otherParts.length;
  }

  /*
    Augmenting the toolset that pops-up whenever the user hovers over a link. Determine in which situations the
    "toggle enabler link" icon should be displayed.
  */
  getEnablersToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton): any[] {
    const that = this;
    const distributeButton = this.distributeButton();
    const unionButton = this.unionButton();
    const targetVis = this.getTargetElement().getVisual();
    const sourceVis = this.getSourceElement().getVisual();
    let fatherType;
    let state;
    // case A: process is InZoomed:
    if (targetVis.refineable && targetVis.children.length > 0) {
      state = 'inZoomed';
      fatherType = null;
      distributeButton.options.action = () => that.switchFromUnifiedToDistributed(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool,
        boundaryTool, removeButton).concat([distributeButton]);
    }
    // case B: process is sub-process and all sub-processes are connected:
    if (!targetVis.refineable && targetVis.fatherObject && this.isAllSiblingsAlsoConnected()) {
      state = 'inZoomed';
      fatherType = null;
      unionButton.options.action = () => that.switchFromDistributedToUnified(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool,
        sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton).concat([unionButton]);
    }
    // case C: Distribute - object is connected to several objects in structural and similar relation:
    if (!targetVis.fatherObject && !targetVis.refineable && this.isAlone(targetVis) && this.isAllStructuralsSameTypeObj(sourceVis)) {
      state = 'structural connected';
      fatherType = 'object';
      distributeButton.options.action = () => that.switchFromUnifiedToDistributed(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool,
        boundaryTool, removeButton).concat([distributeButton]);
    }
    // case D: Distribute - process is connected to several processes in structural relation:
    if (!targetVis.fatherObject && targetVis.children.length === 0 && this.isAllStructuralsSameTypeProc(targetVis)) {
      state = 'structural connected';
      fatherType = 'process';
      distributeButton.options.action = () => that.switchFromUnifiedToDistributed(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool,
        boundaryTool, removeButton).concat([distributeButton]);
    }
    // case E: Unite - object (child) is part of structural family:
    if (!targetVis.fatherObject && !targetVis.refineable && this.isAlone(targetVis) && this.isAllOtherPartsAlsoConnectedWithSameStruct(sourceVis)) {
      state = 'structural connected';
      fatherType = 'object';
      unionButton.options.action = () => that.switchFromDistributedToUnified(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool,
        sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton).concat([unionButton]);
    }
    // case F: Unite - process (child) is part of structural family:
    if (!targetVis.fatherObject && !targetVis.refineable && this.isAllOtherPartsAlsoConnectedWithSameStruct(targetVis)) {
      state = 'structural connected';
      fatherType = 'process';
      unionButton.options.action = () => that.switchFromDistributedToUnified(state, fatherType);
      return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool,
        sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton).concat([unionButton]);
    }
    return this.getProceduralToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool,
      targetAnchorTool, boundaryTool, removeButton);
  }

  getSourceMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances of Object consumed by the Process, if greater than 1';
  }

  getTargetMultiplicityPopupTooltipText(): string {
    return 'The integer number or parameter of instances of Object produced by the Process, if greater than 1';
  }

  getPathPopupTooltipText(): string {
    return 'The path label determines the control flow such that the outgoing link to be followed is the one with the same label as the incoming one';
  }

  getProbabilityPopupTooltipText(): string {
    return 'A value assigned to each link in a XOR diverging link fan that specifies the probability of following that link, such that the sum of the probabilities is 1';
  }

  getRatePopupTooltipText(): string {
    return '';
  }

  getRateUnitsPopupTooltipText(): string {
    return 'Units of measurement';
  }

  distributeButton() {
    return new joint.linkTools.Button({
      markup: [
        {
          tagName: 'circle',
          selector: 'button',
          attributes: {
            'r': 18,
            'fill': 'rgba(74, 185, 236, 0)',
            'cursor': 'pointer',
          }
        },
        {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            'd': 'M 5.8 9.7 l 2.8 -1.9 l 2.7 -1.9 l -2.7 -1.9 l -2.8 -1.9 l 0 2.1 c -0.7 0 -1.4 0 -1.8 0 c -1.3 0.1 -2.8 -1.1 -4.5 -2.6 c -0.6 -0.5 -1.3 -1.1 -1.9 -1.6 c 0.7 -0.5 1.3 -1.1 1.9 -1.6 c 1.6 -1.5 3.2 -2.6 4.5 -2.6 l 1.8 0 l 0 2.1 l 2.8 -1.9 l 2.8 -1.9 l -2.8 -1.9 l -2.8 -1.9 l 0 2.1 l -1.8 0 c -2.6 0.1 -4.5 1.8 -6.2 3.3 c -1.6 1.6 -3.2 2.7 -4 2.6 l -5 0 l 0 3.3 l 5 0 c 0.8 -0.1 2.4 1.1 4 2.6 c 1.7 1.5 3.6 3.2 6.2 3.3 l 1.7 0.2 l 0.1 1.9 l 0 0 l 0 0 z',
            'fill': '#2B3E8A',
            'cursor': 'pointer',
            'z-index': '999',
            'transform': 'scale(1.25, 1.25)',
          }
        }],
      distance: '30',
      rotate: true,
      action: null
    });
  }

  unionButton() {
    return new joint.linkTools.Button({
      markup: [
        {
          tagName: 'circle',
          selector: 'button',
          attributes: {
            'r': 18,
            'fill': 'rgba(74, 185, 236, 0)',
            'cursor': 'pointer',
          }
        },
        {
          tagName: 'path',
          selector: 'icon',
          attributes: {
            'd': 'M 8.2 -1.7 l -2.7 -1.7 l 0 1.9 l -4.8 0 c -0.8 0.1 -2.3 -1 -3.9 -2.4 c -1.6 -1.3 -3.5 -3 -6 -3 l -1.7 0 l 0 3.1 l 1.7 0 c 1.2 -0 2.7 1 4.3 2.4 c 0.6 0.5 1.2 1 1.8 1.5 c -0.6 0.5 -1.2 1 -1.8 1.5 c -1.6 1.4 -3.1 2.5 -4.3 2.4 l -1.7 0 l 0 3.1 l 1.7 0 c 2.5 -0 4.3 -1.7 6 -3 c 1.6 -1.4 3.1 -2.5 3.9 -2.4 l 4.8 0 l 0 1.9 l 2.7 -1.7 l 2.7 -1.7 l -2.7 -1.7 l -0 0 z',
            'fill': '#2B3E8A',
            'cursor': 'pointer',
            'transform': 'rotate(180) scale(1.4, 1.4)',
            'z-index': '999'
          }
        }],
      distance: '-48',
      rotate: true,
      action: null
    });
  }
}

