import {joint, _} from '../../configuration/rappidEnviromentFunctionality/shared';

export class TextBlockClass extends joint.shapes.standard.TextBlock {
  changeSizeHandle(initRappid) {}
  changePositionHandle(initRappid) {}
  removeHandle(initRappid) {}
  addHandle(initRappid) {}
  changeAttributesHandle(initRappid) {}
  pointerUpHandle(initRappid) {}
  setHaloPosition(initRappid) {}
  pointerDownHandle(initRappid) {}
  doubleClickHandle(cellView, evt, initRappid) {}
  openMenu(initRappid) {}
  getParams() {}
  removeDuplicationMark() {}
}
