import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../../rappid-components/services/user.service';
import { oplDefaultSettings } from '../../../opl-generation/opl-database';
import { OplService } from '../../../opl-generation/opl.service';
import { validationAlert } from '../../../configuration/rappidEnviromentFunctionality/shared';
@Component({
  selector: 'tree-view',
  templateUrl: './TreeView.component.html',
  styleUrls: ['./TreeView.component.css']
})
export class TreeViewComponent_user implements OnInit {
  settingsForOrg = false;
  public checked: boolean;

  constructor(private oplService: OplService, private user: UserService) { }

  ngOnInit() {
    // this.checked = oplDefaultSettings.SDNames;
  }

  update(val) {
    // this.oplService.userOplSettings.SDNames = val.checked;
    // const details = {SDNames: val.checked};
    // this.user.updateDB(details).then(_ => validationAlert('Saved', null, 'Success'));
    // this.oplService.updateDefaultSettings();
  }

}
