import { TextualModule } from './interfaces';
import { ComputationModule } from './computation-module';
import {getInitRappidShared} from "../../../configuration/rappidEnviromentFunctionality/shared";

export class AliasingModule implements TextualModule {

  public alias: string;
  private computation: ComputationModule;

  constructor(computation: ComputationModule) {
    this.computation = computation;
  }

  public getText(): string {
    return ( this.isActive()  ? '{' +  (this.alias || '')  + '}' :  '' );
  }

  public isTextActive(): boolean {
    // show alias (even if it is empty) if the object computational, otherwise show only if exists.
    return this.isActive() || this.computation.isActive();
  }

  public isActive(): boolean {
    const aliasCondition = (this.alias !== null && this.alias !== undefined && this.alias !== 'None' && this.alias !== '');
    // tslint:disable-next-line:max-line-length
    const userSelectAlias = getInitRappidShared()?.oplService?.settings?.aliasOpt || 'Show only when applicable';
    // const aliasSettingsBool = (userSelectAlias === 'Show only when applicable' || userSelectAlias === 'Always show alias');
    const computational = this.computation.isActive();
    const computationalCondition = (((userSelectAlias === 'Show only when applicable' && aliasCondition) || userSelectAlias === 'Always show alias') &&  computational);
    const nonComputationalCondition = (!computational && aliasCondition);
    return (computationalCondition || nonComputationalCondition);
  }
  public getPriority() {
    return 3;
  }

}

