import { StereotypeType } from '../../dialogs/stereotypes-dialog/StereotypesRelatedInterface';
import { ModelStorageService } from '../../rappid-components/services/storage/model-storage.service';
import { CurrentModelPermission } from '../../rappid-components/services/storage/model-object.class';
import { ContextService, Path } from './context.service';
import {OPCloudUtils, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import { Subscription, Observable } from 'rxjs';

export interface StorageOperator {
  save(context: ContextService, modelService: ModelStorageService, image?: string): void;
  makeUrl(): { allowed: boolean, url?: string, opdUrl?: string };
}

export interface CurrentModelContext extends StorageOperator {
  isEmpty(): boolean;
  isReadonly(): boolean;
  isGlobalTemplate(): boolean;
  supportModelPermissions(): boolean;
  getWholeTextName(): string;
  getPath(): Path;
  terminate(): void;
  isExample(): boolean;
  isTemplate(): boolean;
  isStereotype(): boolean;
  updateLocalPermissionsAfterChange(data: any, gotToken: boolean): void;
  isOrgExample(): boolean;
  isOrgTemplate(): boolean;
  autoSaveManually();
  isAutoSave(): boolean;
  isDSMContext(): boolean;
}

export class EmptyContext implements CurrentModelContext {

  public static readonly default_name: string = 'Model (Not Saved)';

  getWholeTextName(): string {
    return EmptyContext.default_name;
  }

  isGlobalTemplate(): boolean {
    return false;
  }

  isAutoSave(): boolean {
    return false;
  }

  isExample(): boolean {
    return false;
  }

  isTemplate(): boolean {
    return false;
  }

  isStereotype(): boolean {
    return false;
  }

  autoSaveManually() {}

  isOrgExample(): boolean {
    return false;
  }

  isOrgTemplate(): boolean {
    return false;
  }

  isEmpty(): boolean {
    return true;
  }

  isDSMContext(): boolean {
    return false;
  }

  getPath(): Path {
    return undefined;
  }

  isReadonly(): boolean {
    return false;
  }

  supportModelPermissions(): boolean {
    return false;
  }

  save(context: ContextService, modelStorage: ModelStorageService, image?: string) {
    modelStorage.openSaveModelDialog();
  }

  makeUrl() {
    return { allowed: false };
  }

  autosave(context: ContextService): void {
  }

  terminate(): void {
  }

  updateLocalPermissionsAfterChange(data: any, gotToken: boolean): void {
  }

}


export class DSMContext extends EmptyContext {

  private readonly name;

  constructor(modelName) {
    super();
    this.name = modelName;
  }

  getWholeTextName(): string {
    return this.name;
  }

  isDSMContext(): boolean {
    return true;
  }

  save(context: ContextService, modelStorage: ModelStorageService, image?: string) {
    validationAlert('This is only an analysis view of an existing model. Saving is not available.');
  }
}


export interface ModelContextProperties {
  id: string,
  name: string,
  path: Path,
  description: string,
  archiveMode: boolean,
  permission: CurrentModelPermission,
  isVersion: boolean,
  isAutosave: boolean,
  isSysExample?: boolean,
  isOrgExamples?: boolean,
  isOrgTemplate?: boolean;
  isGlobalTemplate?: boolean;
}

export class ModelContext implements CurrentModelContext {

  private subscription: Subscription;
  private subModelsSubscription: Subscription;
  private subModelsEditDates;

  constructor(public readonly properties: ModelContextProperties, private readonly context: ContextService) {
    this.startSubModelsCheck();
    if (this.isALlowedToSave())
      this.startInterval();
  }

  getCurrentModelId() {
    return this.properties.id;
  }

  isExample(): boolean {
    return this.properties.isOrgExamples || this.properties.isSysExample;
  }

  isTemplate(): boolean {
    return this.properties.isOrgTemplate || this.properties.isGlobalTemplate;
  }

  isStereotype(): boolean {
    return false;
  }

  isSysExample() {
    return !!this.properties.isSysExample;
  }

  isAutoSave(): boolean {
    return this.properties.isAutosave;
  }

  isGlobalTemplate() {
    return !!this.properties.isGlobalTemplate;
  }

  isOrgExample() {
    return !!this.properties.isOrgExamples;
  }

  isOrgTemplate(): boolean {
    return !!this.properties.isOrgTemplate;
  }

  getWholeTextName(): string {
    let name = this.properties.name;
    if (this.isReadonly())
      name += ' ' + '(read only)';
    else if (this.properties.isAutosave)
      name += ' ' + '(Autosave)';
    else if (this.properties.isVersion)
      name += ' ' + '(Version)';

    if (this.isExample())
      name = '<<OPM Example Model>> ' + name;
    else if (this.isTemplate())
      name = '<<Template>> ' + name;

    return name;
  }

  getPath(): Path {
    return this.properties.path;
  }

  autoSaveManually() {
    return this.context.saveModel({
      model_id: this.properties.id,
      path: this.properties.path,
      sysExample: this.isSysExample(),
      globalTemplate: this.isGlobalTemplate()
    }, 'autosave');
  }

  public startSubModelsCheck() {
    const that = this;
    const check = () => {
      if (that.context.getCurrentContext() === this) {
        that.context.getSubModelsEditDates().then(ret => {
          that.subModelsEditDates = ret;
        });
      }
    }
    OPCloudUtils.waitXms(1000).then(res => {
      check();
    });
    this.subModelsSubscription = Observable.interval(20000).subscribe(() => {
      check();
    });
  }

  public startInterval() {
    if (this.subscription && this.subscription.closed === false)
      return;
    const minutes = this.context.getAutosaveInterval();
    this.subscription = Observable.interval(60000 * minutes).subscribe(() => {
      this.context.saveModel({
        model_id: this.properties.id,
        path: this.properties.path,
        sysExample: this.isSysExample(),
        globalTemplate: this.isGlobalTemplate()
      }, 'autosave')
        .then(() => {
          if (!this.context.isTemplate() && !this.context.isExample())
            validationAlert(`Successfully autosaved model [${this.properties.name}].`, 2500, undefined)
        })
    });
  }

  private stopInterval() {
    if (this.subscription && this.subscription.closed === false)
      this.subscription.unsubscribe();
  }

  public terminate() {
    this.stopInterval();
    this.subModelsSubscription.unsubscribe();
  }

  isEmpty(): boolean {
    return false;
  }

  isDSMContext(): boolean {
    return false;
  }

  isReadonly(): boolean {
    return (this.properties.permission === CurrentModelPermission.READ);
  }

  supportModelPermissions(): boolean {
    return true;
  }


  updateLocalPermissionsAfterChange(data: any, gotToken: boolean): void {
    this.properties.permission = gotToken ? CurrentModelPermission.WRITE : CurrentModelPermission.READ;
  }

  public isALlowedToSave(): boolean {

    return (this.isReadonly() == false &&
      (this.properties.isAutosave == false) &&
      (this.properties.isVersion == false)) || (this.isGlobalTemplate() && this.context.isUserSysAdmin()) || (this.isSysExample() && this.context.isUserSysAdmin());
  }

  save(context: ContextService, modelStorage: ModelStorageService, image?: string) {
    if (this.isALlowedToSave()) {
      context.isCurrentlySavingModel = true;
      validationAlert(`Saving...`, 3000);
      context.saveModel({
        model_id: this.properties.id,
        path: this.properties.path,
        image: this.isTemplate() ? image : null,
        sysExample: this.isSysExample(),
        globalTemplate: this.isGlobalTemplate()
      }, 'override')
        .then(() => {
          context.isCurrentlySavingModel = false;
          validationAlert(`Successfully saved model [${this.properties.name}].`, 2500);
        }).catch(err => {
        context.isCurrentlySavingModel = false;
        if (err.status === 0) {
          validationAlert(`It seems you have an Internet connection problem. Could not save.`, 5000, 'Error');
          return;
        }
        modelStorage.openSaveModelDialog();
        validationAlert(`It seems you no longer have write permission to the containing folder. Ask an owner to grant access or save at different location.`, 5000, 'Error');
      });
    }
    else {
      modelStorage.openSaveModelDialog();
    }
  }

  makeUrl(): { allowed: boolean, url?: string } {
    if (this.properties.id) {
      const url = window.location.origin + '/load/' + this.properties.id;
      return {
        allowed: true,
        url: url,
      };
    }
    return { allowed: false };
  }
}

export interface StereotypeContextProperties {
  id: string,
  name: string,
  type: StereotypeType,
  description: string,
  permission: CurrentModelPermission,
}

export class StereotypeContext implements CurrentModelContext {

  constructor(public readonly properties: StereotypeContextProperties) {
  }

  isExample(): boolean {
    return false;
  }

  isGlobalTemplate(): boolean {
    return false;
  }

  isOrgExample(): boolean {
    return false;
  }

  isAutoSave(): boolean {
    return false;
  }

  autoSaveManually() {}

  isTemplate(): boolean {
    return false;
  }

  isStereotype(): boolean {
    return true;
  }

  isOrgTemplate(): boolean {
    return false;
  }

  getWholeTextName() {
    return '<<Stereotype>> ' + this.properties.name;
  }

  getPath(): Path {
    return undefined;
  }

  isEmpty(): boolean {
    return false;
  }

  isDSMContext(): boolean {
    return false;
  }

  isReadonly(): boolean {
    return (this.properties.permission == CurrentModelPermission.READ);
  }

  supportModelPermissions(): boolean {
    return false;
  }

  save(context: ContextService, modelStorage: ModelStorageService, image?: string) {
    if (!this.isValidStereotype(modelStorage))
      return;
    if (this.isReadonly())
      modelStorage.openSaveStereotypeDialog();
    else
      context.saveStereotype({ ...this.properties }, true)
        .then(res => validationAlert(`Successfully saved stereotype [${this.properties.name}].`, 2500, 'Success'))
  }

  isValidStereotype(modelStorage: ModelStorageService): boolean {
    if (modelStorage.getOpmModel().opds.filter(o => !o.isHidden).length > 1) {
      validationAlert('Currently, OPCloud supports only stereotypes with one level diagram.', 5000, 'Error');
      return false;
    }
    return true;
  }

  makeUrl() {
    return { allowed: false };
  }

  autosave(context: ContextService): void {
  }

  terminate(): void {
  }

  updateLocalPermissionsAfterChange(data: any): void {
  }

}

export interface EdxModelContextProperties {
  name: string,
}

export class EdxModelContext {

  constructor(private readonly properties: EdxModelContextProperties) {
  }

  getTitle() {
    return this.properties.name;
  }

}
