import { Component, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';

// Create a component for the dialog box where the user chooses the name of the exported file .
@Component ({
  selector: 'opcloud-choose-exported-file-name-dialog',
  templateUrl: 'choose-exported-file-name.html',
  styleUrls: ['choose-exported-file-name.css']
})

export class ChooseExportedFileNameComponent implements OnInit {
  // Initialize the component of the dialog box and the field.
  constructor(
    @Optional() public dialogRef: MatDialogRef<ChooseExportedFileNameComponent>,
    public initRappidService: InitRappidService) {
  }

  // Creating the component.
  ngOnInit() {
  }

  getDefaultModelName() {
    return this.initRappidService.opmModel.createDefaultModelName();
  }
}
