import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {DisplayPopupMessageItem} from "../create-popup-message/popup.message.interface";
import {StorageService} from "../../rappid-components/services/storage.service";

@Component({
  selector: 'opcloud-popup-views-or-confirms',
  templateUrl: './popup-views-or-confirms.component.html',
  styleUrls: ['./popup-views-or-confirms.component.scss']
})
export class PopupViewsOrConfirmsComponent implements OnInit {

  private message: DisplayPopupMessageItem;
  private type: 'confirms' | 'views';
  private dataToShow;
  private mode: 'org' | 'system';
  private allOrgsNames: Array<string>;
  private selectedOrg: string;
  private countString: string;

  constructor(@Optional() private dialogRef: MatDialogRef<PopupViewsOrConfirmsComponent>, @Inject(MAT_DIALOG_DATA) private data: any, private storage: StorageService) {
    this.message = this.data.message;
    this.type = this.data.type;
    this.mode = this.data.mode;
    this.countString = '';
    this.dataToShow = [];
    this.allOrgsNames = [];
  }

  async ngOnInit() {
    const user = await this.storage.getUserObservable().take(1).toPromise();
    if (this.mode === 'org') {
      this.allOrgsNames = [user.userData.organization];
    } else {
      this.allOrgsNames = (await this.storage.database.driver.getAllOrganizations()).map(org => org.name);
    }
    this.selectedOrg = this.allOrgsNames[0];
    this.loadOrgUsers(this.selectedOrg);
  }

  loadOrgUsers(org: string) {
    this.storage.database.driver.getOrganizationUsers(org).then(users => {
      this.dataToShow = [];
      for (let user of users) {
        const u = user as any;
        this.dataToShow.push({
          name: u.Name,
          email: u.Email,
          status: this.message[this.type].includes(u.uid)
        });
      }
      this.countString = '  ('+ this.dataToShow.filter(itm => itm.status).length + ' of ' + users.length + ')';
      this.dataToShow.sort((a,b) => a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1);
    });
  }

  changedSelectedOrg($event) {
    this.selectedOrg = $event.target.value;
    this.loadOrgUsers(this.selectedOrg);
  }
}
