import {Component, Inject, Input, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {UntypedFormControl} from "@angular/forms";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {UserService} from "../../rappid-components/services/user.service";
import {BackgroundImageState, ImagesPoolItem, ImagesPoolType} from "../../models/VisualPart/backgroundImageEnum";
import {StorageService} from "../../rappid-components/services/storage.service";
import {OpmEntity} from "../../models/DrawnPart/OpmEntity";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {BackgroundPhotoDialogComponent} from "../background-photo-dialog/background-photo-dialog";
import {checkImageURL, OPCloudUtils, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";
import {OpmVisualThing} from "../../models/VisualPart/OpmVisualThing";
import {ImagesPoolContainer} from "../images-pool-container/images-pool-container";
import * as XLSX from 'xlsx';

@Component ({
  selector: 'opcloud-images-pool',
  templateUrl: 'images-pool-management-component.html',
  styleUrls: ['images-pool-management-component.css']
})
export class ImagesPoolManagementComponent implements OnInit {

  private urlsData: Array<ImagesPoolItem>;
  private urlsDataToDisplay: Array<ImagesPoolItem>;
  private selectedItem: ImagesPoolItem;
  private separatorKeysCodes;
  private tagsCtrl = new UntypedFormControl('');
  private searchTags;
  private searchTagsCtrl;
  private mode: ImagesPoolType;
  private entity: OpmEntity;
  @Input() additionalData;
  private showWarningAtLeastOneTag: boolean;

  constructor(private userService: UserService, @Optional() public dialogRef: MatDialogRef<ImagesPoolManagementComponent>,
    @Inject(MAT_DIALOG_DATA) public dialogData: any, private storage: StorageService, private init: InitRappidService) {
    this.searchTags = [];
  }

  async ngOnInit() {
    this.mode = this.additionalData.mode;
    this.searchTagsCtrl = this.additionalData.searchTagsCtrl;
    this.searchTags = this.additionalData.searchTags;
    this.separatorKeysCodes = this.additionalData.separatorKeysCodes;
    if (OPCloudUtils.isInstanceOfDrawnThing(this.init.selectedElement))
      this.entity = this.init.selectedElement;
    this.urlsData = await this.storage.getImagesPool(this.convertPoolTypeToString(this.mode)) || [];
    this.urlsData.forEach(itm => itm.imageTags = itm.imageTags || []);
    this.urlsDataToDisplay = [...this.urlsData];
    this.additionalData.tabChange.subscribe((val) => this.changedSearchTags());
  }

  selectItem(item: ImagesPoolItem) {
    if (item !== this.selectedItem)
      this.selectedItem = item;
    else
      this.selectedItem = undefined;
  }

  addTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value) {
      this.selectedItem.imageTags.push(value);
      this.storage.updatePoolImageTags(this.selectedItem.id, this.selectedItem.imageTags, this.convertPoolTypeToString(this.mode));
    }
    event.input.value = '';
    this.tagsCtrl.setValue(null);
  }

  convertPoolTypeToString(type) {
    if (type === ImagesPoolType.PERSONAL) {
      return 'private';
    } else if (type === ImagesPoolType.ORG) {
      return 'organizational';
    }
    return 'global';
  }

  removeTag(tag: string): void {
    const index = this.selectedItem.imageTags.indexOf(tag);
    if (index >= 0 && this.selectedItem.imageTags.length > 1) {
      this.selectedItem.imageTags.splice(index, 1);
      this.storage.updatePoolImageTags(this.selectedItem.id, this.selectedItem.imageTags, this.convertPoolTypeToString(this.mode));
    } else if (this.selectedItem.imageTags.length === 1) {
      this.showWarningAtLeastOneTag = true;
      setTimeout(() => {this.showWarningAtLeastOneTag = false;}, 5000);
    }
  }

  addSearchTag(event: MatChipInputEvent): void {
    const value = (event.value || '').trim();
    if (value)
      this.searchTags.push(value);
    event.input.value = '';
    this.searchTagsCtrl.setValue(null);
    this.changedSearchTags();
  }

  removeSearchTag(tag: string): void {
    const index = this.searchTags.indexOf(tag);
    if (index >= 0) {
      this.searchTags.splice(index, 1);
    }
    this.changedSearchTags();
  }

  changedSearchTags() {
    const searchTags = this.searchTags;
    if (this.searchTags.length === 0 ) {
      this.urlsDataToDisplay = [...this.urlsData];
      return;
    }
    this.urlsDataToDisplay = this.urlsData.filter(item => {
      const itemTags = item.imageTags.map(t => t.toLowerCase());
      for (const sTag of searchTags)
        if (!itemTags.includes(sTag.toLowerCase()))
          return false;
      return true;
    });

    if (!this.urlsDataToDisplay.includes(this.selectedItem))
      this.selectItem(undefined);
  }

  canEditTagsOrDelete(): boolean {
    const user = this.userService.user;
    if (!user)
      return false;
    if (user.userData.SysAdmin)
      return true;
    if (user.userData.OrgAdmin && this.mode === ImagesPoolType.ORG)
      return true;
    if (this.mode === ImagesPoolType.PERSONAL)
      return true;

    return false;
  }

  insert(itemToInsert) {
    if (!itemToInsert)
      return;
    const visuals = this.entity.getVisual().logicalElement.visualElements;
    for (const vis of visuals) {
      (<OpmVisualThing>vis).showBackgroundImage = BackgroundImageState.TEXTANDIMAGE;
      if (this.entity.graph.getCell(vis.id))
        this.entity.graph.getCell(vis.id).setBackgroundImage(itemToInsert.url);
    }
    this.dialogRef.close();
  }

  addNewImage() {
    this.dialogRef.afterClosed().toPromise().then(() => {
      this.init.dialogService.openDialog(BackgroundPhotoDialogComponent, 670, 550, { cameFromPool: true })
        .afterClosed().toPromise().then(() => {this.init.dialogService.openDialog(ImagesPoolContainer, 675, 900, {});});
    });
    this.dialogRef.close();
  }

  deleteImage() {
    this.storage.deletePoolImage(this.selectedItem.id, this.convertPoolTypeToString(this.mode)).then(ret => {
      const item = this.urlsData.find(i => i.id === this.selectedItem.id);
      if (item) {
        const index = this.urlsData.indexOf(item);
        this.urlsData.splice(index, 1);
        this.selectItem(undefined);
        this.changedSearchTags();
      }
    });
  }

  doubleClickItem(item: ImagesPoolItem) {
    this.selectItem(item);
    if (this.entity && this.isImageEditable())
      this.insert(item);
  }

  isImageEditable() {
    if (!this.entity)
      return true;

    const logical = this.entity.getVisual().logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.equivalentFromStereotypeLID || logical.getStereotype())
      return false;

    return true;
  }

  async validateImageURL(url): Promise<void> {
    if (url.endsWith('.jpeg') || url.endsWith('.jpg') || url.endsWith('.png') || url.endsWith('.svg') || url.endsWith('.bmp') || url.endsWith('.gif')) {
      return checkImageURL(url).then(() => Promise.resolve()).catch(err => Promise.reject());
    } else {
      return Promise.reject();
    }
  }

  importFromExcel(event) {
    if (!this.userService.isSysAdmin())
      return;
    if (event.target.files.length === 0) {
      return;
    }
    const file = event.target.files[0];
    let arrayBuffer;
    const fileReader = new FileReader();
    const that = this;
    fileReader.onload = (e) => {
      arrayBuffer = fileReader.result;
      const data = new Uint8Array(arrayBuffer);
      const arr = [];
      for (let i = 0; i !== data.length; ++i) {
        arr[i] = String.fromCharCode(data[i]);
      }
      const workbook = XLSX.read(arr.join(''), { type: 'binary' });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];
      const result = XLSX.utils.sheet_to_json(worksheet);
      for (const item of result) {
        if (item['url'] && item['tags']) {
          const tags = (item['tags'] || '').split(';').map(t => t.trim());
          if (tags.length === 0)
            continue;
          this.validateImageURL(item['url']).then(() => {
            this.storage.saveImageToPool(this.poolModeToString(), item['url'], tags).then(res => {
              if (result.indexOf(item) === result.length - 1) {
                this.ngOnInit();
                validationAlert('Finished importing.');
              }
            }).catch(err => {});
          }).catch(err => {
            if (result.indexOf(item) === result.length - 1) {
              this.ngOnInit();
              validationAlert('Finished importing.');
            }
          });
        }
      }
    };
    fileReader.readAsArrayBuffer(file);
  }

  poolModeToString() {
    if (this.mode === ImagesPoolType.PERSONAL) {
      return 'private';
    } else if (this.mode === ImagesPoolType.ORG) {
      return 'org';
    }
    return 'global';
  }
}
