import {MethodologicalChecker, MethodologicalCheckingStatus} from "../methodological-checker";
import {OpmModel} from "../../../models/OpmModel";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OpmVisualThing} from "../../../models/VisualPart/OpmVisualThing";
import {OpmLogicalThing} from "../../../models/LogicalPart/OpmLogicalThing";
import {fundamental} from "../../../models/consistency/links.set";
import {OpmLogicalProcess} from "../../../models/LogicalPart/OpmLogicalProcess";

export class PartUnfoldContentChecker extends MethodologicalChecker {

  constructor(model: OpmModel) {
    super(model);
    this.title = 'Part-unfolding thing content';
  }

  check(): void {
    this.invalidThings = [];
    for (const opd of this.model.opds.filter(o => !o.isHidden)) {
      for (const vis of opd.visualElements) {
        if (OPCloudUtils.isInstanceOfVisualThing(vis)) {
          const refinee = (<OpmVisualThing>vis).getRefineeUnfold() as OpmVisualThing;
          if (refinee && refinee.getLinks().outGoing.filter(l => fundamental.contains(l.type)).length < 2) {
            this.addToInvalidThings(refinee.logicalElement as OpmLogicalThing<OpmVisualThing>);
          }
        }
      }
    }
    this.status = this.invalidThings.length === 0 ? MethodologicalCheckingStatus.VALID : MethodologicalCheckingStatus.INVALID;
  }

  getDescriptionTooltip(): string {
    return `A Part-unfolded thing must consist of at least two things.`;
  }
}
