import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmObject } from "../../DrawnPart/OpmObject";
import { OpmVisualObject } from "../../VisualPart/OpmVisualObject";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class SupressValueStatesCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly object: OpmObject,
        private readonly visual: OpmVisualObject) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'suppres-value-states',
            displayTitle: 'Suppress',
            svg: 'supressHalo',
            action: new SupressValueStatesAction(this.init, this.object)
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'suppres-value-states',
            displayTitle: 'Suppress',
            svg: 'supressHalo',
            action: new SupressValueStatesAction(this.init, this.object),
            gif: '',
        }
    }

}

class SupressValueStatesAction implements ElementAction {

    constructor(private readonly init: InitRappidService,
        private readonly object: OpmObject) {
    }

    act() {
      const cell = this.init.graph.getCell(this.object.id);
      if (cell)
        cell.suppressValueStates(this.init);
    }

}
