
import { Injectable } from '@angular/core';
import { validationAlert } from '../../../../configuration/rappidEnviromentFunctionality/shared';
import { ContextService } from '../../../../modules/app/context.service';

import { StorageService } from '../../storage.service';
import { DisplayChat, getChatAction, postChatResponse } from "./chat-storage.interface";

@Injectable()
export class ChatStorageService {

  /**
   * A service for the chat module.
   *
   * Used for getting and sending messages.
   * @see getAllChatMessages,getUnreadChatMessages,pushChatMessage
   *
   * Also used to load on OPM Model in OPCloud via link
   * @see loadModelFromLink
   */

  constructor(private readonly storage: StorageService,
    private readonly context: ContextService) {
  }

  public async getAllChatMessages(model_id: string): Promise<Array<DisplayChat>> {
    return this.storage.getChatMessages(model_id, getChatAction.ALL)
      .then(messages => { return messages.chatMessages })
      .catch(err => { return [] });
  }

  public async getUnreadChatMessages(model_id: string): Promise<Array<DisplayChat>> {
    return this.storage.getChatMessages(model_id, getChatAction.UNREAD)
      .then(messages => { return messages.chatMessages })
      .catch(err => { return [] });
  }

  public async pushChatMessage(message: DisplayChat): Promise<postChatResponse> {
    return this.storage.pushChatMessage(message);
  }

  public async loadModelFromLink(model_id: string): Promise<boolean> {
    if (this.context.getTabs().find(t => (<any>t?.context)?.properties?.id === model_id)) {
      validationAlert(`The model is already open on other tab.`, 2500, 'warning');
      return true;
    }
    return this.context.loadModel(model_id, null, 'MAIN')
      .then(() => {
        validationAlert(`Successfully loaded model.`, 2500, 'warning');
        return true; // The model was loaded successfully, no need to open the link in new tab
      })
      .catch(err => {
        validationAlert(`Failed to load model,  Opening link in a new tab`, 2500, 'Error');
        return false; // A model was not loaded, open the link in new tab
      });
  }

  public async removeMessage(msg_id: string, model_id: string) {
    return this.storage.removeChatMessage(msg_id, model_id);
  }
}
