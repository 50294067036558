import { Component, EventEmitter, OnDestroy, OnInit, Optional, DoCheck } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {UserDetails, UserService} from '../../../../rappid-components/services/user.service';
import { validationAlert } from '../../../../configuration/rappidEnviromentFunctionality/shared';
import {Observable} from 'rxjs';
import {User} from '../../../../rappid-components/services/authentication/authentication.service';
import {InitRappidService} from "../../../../rappid-components/services/init-rappid.service";
import {Set2AuthFactorsDialog} from "../../../../dialogs/set-2-auth-factors/set-2-auth-factors-dialog";
import {environment} from "../../../../../environments/environment";


@Component({
  selector: 'opcloud-udet-dialog',
  templateUrl: 'update-user-details.html',
  styleUrls: ['update-user-details.component.css']
})

export class UserDetailsComponent implements OnInit {

  name = '';
  photoURL = '';

  orgS;
  user$: Observable<User>;
  canResetPassword: boolean;
  // isSysAdmin: boolean;
  // isOrgAdmin: boolean;
  canSet2AuthFactors: boolean;


  constructor(
    @Optional() public dialogRef: MatDialogRef<UserDetailsComponent>,
    private userService: UserService, private init: InitRappidService) {
  }

  ngOnInit() {
    this.user$ = this.userService.user$;
    this.canResetPassword = !this.userService.shouldChangePassword();
    this.orgS = this.userService.userOrg;
    this.canSet2AuthFactors = !environment.serverSideAuth && this.init.oplService.orgOplSettings.auth2Factors &&
      this.init.oplService.orgOplSettings.auth2Factors !== 'disabled' && !this.userService.user?.userData?.sso_user;
    // this.isSysAdmin = this.user$.subscribe((val) => {
    //   return val.userData.SysAdmin;
    // });
    // this.isOrgAdmin = this.user$.subscribe((val) => {
    //   return val.userData.OrgAdmin;
    // });
  }


  updateUserDB() {
    const details: UserDetails = {
      Name: this.name,
      PhotoURL: this.photoURL
    };
    this.userService.updateDB(details).then((res) => {
      validationAlert('contact details were saved', null, 'Success');
      // this.name = '';
      // this.photoURL = '';
    }).catch(err => {
      validationAlert('ERROR:' + err, null, 'Error');
    });
  }

  resetPassword() {
    const mail = this.userService.user.userData.Email;
    if (this.userService.user.userData.sso_user)
      return;
    const threeMinutes = 3 * 60 * 1000;
    const lastResetPasswordTime = localStorage.getItem('lastResetPasswordTime') ? Number(localStorage.getItem('lastResetPasswordTime')) : null;
    if (lastResetPasswordTime && (new Date().getTime() - lastResetPasswordTime < threeMinutes)) {
      validationAlert('Too many attempts. Please try again in few minutes.', 5000, 'Error');
      return;
    }
    this.userService.resetPassword(mail)
      .then(_ => {
        validationAlert('Mail with reset details sent to ' + mail, null, 'Success');
        localStorage.setItem('lastResetPasswordTime', String(new Date().getTime()));
      })
      .catch(err => validationAlert('Failed: ' + err, null, 'Error'));
  }

  shouldShowResetPassword() {
    return !this.userService.user?.userData?.sso_user;
  }

  openSet2AuthenticationFactorsDialog() {
    this.init.dialogService.openDialog(Set2AuthFactorsDialog, 445, 400, { });
  }
}
