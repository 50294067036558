import { Component, OnInit } from '@angular/core';
import { menuGroups } from '../menuGroups';
import {UserService} from "../../../rappid-components/services/user.service";

@Component({
  selector: 'opc-settins-home',
  templateUrl: './settings-home.html',
  styleUrls: ['./settings-home.scss']
})

export class SettingsHome implements OnInit {
  menuGroups = menuGroups;
  user$;
  
  ngOnInit() {
    //this.getOrgAdmin();
  }

  constructor(private userServices: UserService){
    //this.user$ = this.userServices.user$;
  };

 /* getOrgAdmin() {
    return this.user$.subscribe(user => {
      // TODO: Was here... in a very bad place.
      this.userServices.isSysAdmin = user.userData['SysAdmin'];
      return this.setOrgAdmin(user.userData['OrgAdmin']);
    });
  }
  setOrgAdmin(orgAdminStatus) {
    this.userServices.isOrgAdmin = orgAdminStatus;
  }*/
}



