import { Component } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { UserService } from '../../rappid-components/services/user.service';
import {environment} from "../../../environments/environment";


@Component({
    selector: 'reset-initial-password',
    templateUrl: 'reset-initial-password.component.html',
    styleUrls: ['reset-initial-password.component.css']
})
export class ResetInitialPasswordComponent {

    constructor(private dialogRef: MatDialogRef<ResetInitialPasswordComponent>,
      private userService: UserService) {
    }

    Accept() {
      const that = this;
      this.userService.changeUserInitialPassword().then(() => {
        if (environment.serverSideAuth) {
          return that.userService.resetPasswordForNotLoggedUser(that.userService.user.userData.Email);
        }
        return that.userService.resetPassword(that.userService.user.userData.Email) as any;
      }).then((res) => {
        that.userService.signOutWithFirebase();
        location.reload();
      });
    }

    Decline() {
      this.userService.signOutWithFirebase().then((res) => {
        this.dialogRef.close();
        location.reload();
      });
    }

}
