import { Injectable, APP_INITIALIZER } from '@angular/core';
import { HttpClient } from '@angular/common/http';

export interface Config {
    target: string,
    version: string,
    semester: string,
    endpoint: string
}

@Injectable()
export class ConfigService {

    constructor(private http: HttpClient) { }

    private config_: Config;

    get(): Readonly<Config> {
        return this.config_;
    }

    build(): Promise<void> {
        return new Promise((resolve, reject) => {
            this.http.get('assets/config/edx.config.json').toPromise()
                .then((data: Config) => {
                    this.config_ = {
                        target: data['TARGET'],
                        semester: data['SEMESTER'],
                        version: data['VERSION'],
                        endpoint: data['ENDPOINT']
                    };
                    resolve();
                }, err => {
                    reject(err);
                });
        });
    }
}

export function ConfigFactory(config: ConfigService) {
    return () => config.build();
}

export function init() {
    return {
        provide: APP_INITIALIZER,
        useFactory: ConfigFactory,
        deps: [ConfigService],
        multi: true
    }
}

export const ConfigModule = {
    init: init
}
