import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig} from "@angular/material/legacy-dialog";
import { AuthenticationService } from '../rappid-components/services/authentication/authentication.service';
import { UserService } from '../rappid-components/services/user.service';
import { UserAgreementComponent } from '../dialogs/UserAgreement/user-agreement.component';
import {ResetInitialPasswordComponent} from "../dialogs/reset-initial-password/reset-initial-password.component";
import {OrganizationService} from "../rappid-components/services/organization.service";
import {Set2AuthFactorsDialog} from "../dialogs/set-2-auth-factors/set-2-auth-factors-dialog";
import {environment} from "../../environments/environment";

@Injectable()
export class CanActivateViaAuthGuard  {

  private readonly authenticated: Observable<boolean>;

  constructor(private auth: AuthenticationService, private route: ActivatedRoute, private router: Router,
              private user: UserService, dialog: MatDialog, private orgService: OrganizationService) {
    const that = this;
    user.user$.subscribe(async user => {
      if (!user.userData['AcceptedEula'])
        dialog.open(UserAgreementComponent, {disableClose: true}).afterClosed().toPromise().then(closed => {
          if (user.userData['forceToChangeInitialPassword'])
            dialog.open(ResetInitialPasswordComponent, {disableClose: true});
          else location.reload();
        });
      if (user.userData['AcceptedEula'] && user.userData['forceToChangeInitialPassword']) {
        dialog.open(ResetInitialPasswordComponent, {disableClose: true});
      } else if (!user.userData.multiFactorAuth && user.userData['AcceptedEula'] && !user.userData.sso_user) {
        const orgSettings = await that.orgService.getOrganization(user.userData.organization);
        if (orgSettings.auth2Factors === 'mandatory' && !environment.serverSideAuth) {
          const config = new MatDialogConfig();
          config.height = '545px';
          config.width = '400px';
          config.backdropClass= 'bluredBackground';
          config.disableClose = true;
          config.data = { mandatory: true };
          dialog.open(Set2AuthFactorsDialog, config);
        }
      }
    });

  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (route.queryParams.path && route.queryParams.name)
      this.user.autoload = { path: route.queryParams.path, name: route.queryParams.name };

    return this.auth.authenticated$.map(bool => {
      if (!bool)
        this.router.navigate(['/login']);
      return bool;
    });;
  }

}
