import {AfterViewInit, Component, Inject, Optional} from '@angular/core';
import {
  MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialog as MatDialog,
  MatLegacyDialogRef as MatDialogRef
} from '@angular/material/legacy-dialog';
import {CodeModel} from '@ngstack/code-editor';
import {
  PathsFromObjectsToProcessCalculator
} from '../../configuration/elementsFunctionality/paths-from-objects-to-process-calculator';
import {code} from '../../models/ConfigurationOptions';
import {VariablesCalculator} from '../../configuration/elementsFunctionality/variables-calculator';
import {GenAIFunctionExecutor} from '../../configuration/elementsFunctionality/genAI-function-executor';
import {UserService} from '../../rappid-components/services/user.service';
import {ConfirmDialogDialogComponent} from '../confirm-dialog/confirm-dialog';

@Component ({
  selector: 'genAI-computational-editor-dialog',
  templateUrl: 'genAI-computational-editor-dialog.html',
  styleUrls: ['genAI-computational-editor-dialog.css']
})
export class GenAIComputationalEditorDialog implements AfterViewInit {

  private theme: string;
  private codeModel: CodeModel;
  private options;
  private monaco: any;
  private themes: string[];
  private spinnerFlag = true;
  private init;
  protected runLocally: boolean;
  private currentUser;
  private userService: UserService;

  constructor(
      @Optional() public dialogRef: MatDialogRef<GenAIComputationalEditorDialog>,
      private userSer: UserService,
      private _dialog: MatDialog,
      @Inject(MAT_DIALOG_DATA) public data: any) {
    this.userService = userSer;
    this.userService.user$.subscribe(user => this.currentUser = user);
    if (!this.userService.isGenAIUser(this.currentUser)) {
      _dialog.open(ConfirmDialogDialogComponent, {
        height: '300px',
        width: '430px',
        data: { message: 'Unlock Advanced Generative AI Features!\n\n' +
            'Take your work to the next level with OPL summary generation, impact analysis, ' +
            'modeling insights, enhanced coding, and even natural language-based calculations.\n\n'
            + 'Interested in upgrading?\n' +
            'Contact us at <a href="mailto:contact@opcloud.tech">contact@opcloud.tech</a> for more details.',
          okName: 'Got it!', okColor: '#1a3763', centerText: true, closeFlag: true },
      });
      this.dialogRef.close();
      return;
    }
    this.init = data.initRappid;
    this.theme = 'vs';
    this.codeModel = {
      language: 'plaintext',
      uri: 'main.json',
      value: this.data.code,
      dependencies: []
    };
    this.runLocally = data.executionLocation === 'local';
    this.options = {
      contextmenu: true,
      colorDecorators: true,
      minimap: {
        enabled: true
      }
    };
    this.monaco = (<any>window).monaco;
    this.monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
      diagnosticCodesToIgnore: [1108, 2304, 7027]
    });
    this.getExtraData();
  }

  changeTheme($event) {
    const value = $event.target.value;
    if (value === 'VS Dark') {
      return this.defaultTheme();
    } else {
      this.monaco.editor.setTheme(value);
    }

    this.init.updateDB({codeEditorTheme: value});
  }

  async loadThemes() {
    $('#codeEditor').css({'opacity' : '0'});
    this.themes = ['Active4D', 'Merbivore', 'Solarized-light', 'Tomorrow', 'krTheme', 'Clouds', 'GitHub',
      'SpaceCadet', 'Twilight', 'monoindustrial', 'Amy', 'Cobalt', 'IDLE', 'Monokai', 'Sunburst', 'Dawn', 'Katzenmilch', 'Blackboard',
      'Tomorrow-Night-Blue', 'Dracula', 'LAZY', 'Tomorrow-Night-Bright', 'Zenburnesque', 'Dreamweaver', 'Tomorrow-Night-Eighties',
      'iPlastic', 'Eiffel', 'Solarized-dark', 'Tomorrow-Night', 'idleFingers'];
    for (const name of this.themes) {
      const data = await fetch('./assets/codeEditorThemes/' + name + '.json');
      const jsn = await data.json();
      this.monaco.editor.defineTheme(name, jsn);
    }
    this.themes.push('VS Dark');
    const userChoice = this.init.oplService.settings.codeEditorTheme;
    if (userChoice && this.themes.includes(userChoice)) {
      this.monaco.editor.setTheme(userChoice);
      (<HTMLSelectElement>$('#themeSelect')[0]).selectedIndex = this.themes.indexOf(userChoice);
    } else {
      this.monaco.editor.setTheme('Active4D');
    }
    $('#codeEditor').css({'opacity' : '1'});
    this.spinnerFlag = false;
  }

  ngAfterViewInit() {
    if (!this.userService.isGenAIUser(this.currentUser)) {
      this.dialogRef.close();
      return;
    }
    $('.mat-dialog-container').css({'background-color' : 'rgba(255,255,255,0)', 'padding': '0px', 'box-shadow': 'none'});
    this.loadThemes();
  }

  defaultTheme() {
    this.monaco.editor.setTheme('vs-dark');
  }

  onCodeChanged() {
  }

  update() {
    const val = this.codeModel.value;
    return this.dialogRef.close({code: val.split('-----#\n\n')[1]});
  }

  cancel() {
    this.dialogRef.close();
  }

  onStartDrag(event) {
    if (!!event.touches) {
      return;
    }
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e); };
    window.onmouseup = function (e) { that.endDrag(e); };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
    const rect = $('.mat-dialog-container')[0].getClientRects()[0];
    if (rect && (rect.x - 20 > window.innerWidth || rect.y - 20 > window.innerHeight)) {
      this.dialogRef.updatePosition({ left: '100px', top: '100px' });
    }
  }

  moveDrag(event) {
    const rect = $('.mat-dialog-container')[0].getClientRects()[0];
    const left = Math.max(0, rect.left + event.movementX) + 'px';
    const top = Math.max(0, rect.top + event.movementY) + 'px';
    this.dialogRef.updatePosition({ left: left, top: top });
  }

  private getExtraData() {
    const globalRunTimeEnvironment = { objects: new Map<string, any>(), alreadyRanLinks: [], processesToIgnore: [] };
    const visual = this.data.visual;
    const model = visual.logicalElement.opmModel;
    const opd = model.getOpdByThingId(visual.id);
    const valuesArray = [];
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, visual, model, code.GenAI, globalRunTimeEnvironment);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    const all_variables_str = [];
    const non_legal_variables_str = [];
    const alias = [];
    const vc = new VariablesCalculator(all_paths, valuesArray);
    const executor = new GenAIFunctionExecutor(model, visual);
    const function_variables = vc.calc_variables_str(all_variables_str, code.GenAI, non_legal_variables_str, alias);
    const functionContent = executor.replaceVariables('', all_variables_str, non_legal_variables_str);
    let functionInput = function_variables + '\n' + functionContent as any;
    let aliasArr = '';
    for (const item of alias) {
      delete item['lid'];
    }
    if (alias.length > 0) {
      aliasArr = 'aliasArr = ' + JSON.stringify(alias, null, 2);
      let count = 0;
      aliasArr = aliasArr.replace(/{/g, function() {
          count++;
          return count + ':{';
        }
      );
      aliasArr = aliasArr.replace('[', '{');
      aliasArr = aliasArr.replace(']', '}');
    }
    functionInput = aliasArr + '\n' + functionInput;
    const warning = '\n\n#--------Don\'t edit or change the lines above here. These are runtime variables that will be updated with real values on execution-------#\n\n';
    this.codeModel.value = functionInput.replaceAll(';', '\n') + warning + this.codeModel.value;
  }
}
