import { Component, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GraphService } from '../../rappid-components/services/graph.service';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import * as fileSaver from 'file-saver';
import {OPCloudUtils, validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";

/*
* This Component enables to save screenshots of the OPD model as jpeg files.
* inputs: open OPM model in the web
* output: jpeg files of requested OPDs
*/

@Component({
  selector: 'opcloud-save-screenshot-dialog',
  templateUrl: 'saveScreenshot.html',
  styleUrls: ['saveScreenshot.css']
})

export class SaveScreenshotComponent {
  includeTooltips = false;
  format = 'jpeg';
  private shouldShowMessageAboutBackgroundImages: boolean;

  constructor(
    @Optional() public dialogRef: MatDialogRef<SaveScreenshotComponent>,
    private graphService: GraphService,
    private initRappidService: InitRappidService) {
    this.shouldShowMessageAboutBackgroundImages = !!this.initRappidService.opmModel.getAllLogicalThings().find(l => l.getBackgroundImageUrl()?.length);
  }

  /*
  * This is the main function of screenshot saving.
  * It checks which OPDs to capture, and then calls to 'createJpegAndDownload' function for each.
  * Inputs: fileName = user input or default, saveOption: {'Current' , 'SD', 'Tree'} according to user's choice
  * Output(s): jpeg file(s)
  */
  public async screenshotMain(fileName, saveOption, resolution, includeTooltips, includeSubModels = false) {
    this.includeTooltips = includeTooltips;
    if ((fileName === 'download') || (fileName === '')) { // if no user input
      fileName = this.initRappidService.opmModel.getOpd('SD').getDefaultName();
    }
    if (saveOption === 'Current') {
      this.createImageAndDownload(fileName, resolution);
    } else {
      // SD 0 or Tree
      const currentOpd = this.initRappidService.opmModel.currentOpd; // save current open OPD to render after the process has finished
      if (includeSubModels) {
        await this.initRappidService.opdHierarchyRef.loadAllSubModels();
      }
      const tree = this.initRappidService.opmModel.opds.filter(o => !o.isHidden);
      let length = tree.length;
      if (saveOption === 'SD') { // to save only SD-0 the loop should finish after first OPD in the tree
        length = 1;
      }
      for (let i = 0; i < length; i++) {
        if (tree[i].sharedOpdWithSubModelId && tree[i].visualElements.length === 0) {
          continue;
        }
        this.graphService.renderGraph(tree[i], this.initRappidService); // Goes to the next OPD in the tree
        const nameOfOpd = tree[i].getNumberedName() + ' ' + tree[i].name; // Get the name of the OPD.
        this.createImageAndDownload(fileName + ' - ' + nameOfOpd, resolution);
      }
      this.graphService.renderGraph(currentOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
    }
    this.dialogRef.close();
  }
  /*
  * Capture the components of currently open Opd paper and turn them to jpeg data URI string (with toJPEG function).
  */
  public createImageAndDownload(fileName = 'Sd', resolution = '1') {
    const paper = this.initRappidService.paper; // get the current open OPD
    if (this.includeTooltips)
      for (const proc of this.initRappidService.graph.getCells().filter(c => OPCloudUtils.isInstanceOfDrawnProcess(c)))
        proc.showDummyTooltip();

    try {
      if (this.format === 'jpeg') {
        paper.toJPEG(function (imageData) { // capture the components on the paper
          downloadJpeg(imageData, fileName + '.jpeg');
        }, {
          padding: 40, useComputedStyles: false, size: resolution + 'x', quality: 1.0 // make wide borders in order to capture also the shades
        })
      } else {
        paper.toSVG(function (imageData) { // capture the components on the paper
          downloadSVG(imageData, fileName + '.svg');
        }, {
          useComputedStyles: false, convertImagesToDataUris: true
        })
      }
      $('.dummyTooltip').remove();
    } catch(err) {
      validationAlert('Cannot export images. It seems the resolution is too high. Set a lower resolution and try again.', 3500, 'Error');
    }

  }
  getDefaultModelName() {
    return this.initRappidService.opmModel.createDefaultModelName();
  }

  getCurrentOPDName() {
    return ' ' + this.initRappidService.opmModel.currentOpd.getNumberedName() + ' ' + this.initRappidService.opmModel.currentOpd.name;
  }

  formatChange($event) {
    this.format = $event.value;
  }

}

/*
* Convert from jpeg data URI (base64-encoded) string, to blob, in order to download as jpeg file
*/
function downloadJpeg(imageData, fileName) {
  const byteCharacters = atob(imageData.replace(/^data:image\/(png|jpeg|jpg);base64,/, ''));
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  const blob = new Blob([byteArray], { type: 'image/jpeg' });
  fileSaver.saveAs(blob, fileName.replace(/\./g, '_'));
}

function downloadSVG(imageData, fileName) {
  const blob = new Blob([imageData], {type: "image/svg+xml"});
  fileSaver.saveAs(blob, fileName.replace(/\./g, '_'));
}

