import { Component, OnInit } from '@angular/core';
import {OrganizationService} from '../../../../rappid-components/services/organization.service';
import {GroupsService} from '../../../../rappid-components/services/groups.service';
import {validationAlert} from '../../../../configuration/rappidEnviromentFunctionality/shared';
import {BehaviorSubject, Observable, ReplaySubject, Subject} from 'rxjs';
import {CollaborationDialogComponent} from '../../../../dialogs/collaboration-dialog/collaboration-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import {UserService} from '../../../../rappid-components/services/user.service';
import {ConfirmDialogDialogComponent} from '../../../../dialogs/confirm-dialog/confirm-dialog';


@Component({
  selector: 'opcloud-group-administration',
  templateUrl: './group-administration.component.html',
  styleUrls: ['./group-administration.component.css']
})
export class GroupAdministrationComponent implements OnInit {
  // private readonly users: Subject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  namePlaceHolder: string;
  selectedOrg: string;
  selectedGroups = [];
  potentialMembers = [];
  currentUser;
  group;
  groupName = '';
  groupDescrpition = '';
  defaultPhotoUrl = '../../../../../assets/SVG/logoPic.svg' ;
  organizations$: Observable<Array<any>>;
  tooltip = 'Your changes will take effect as soon as you nevigate away from the text field';
  // placeholder: string;
  private readonly groups: ReplaySubject<Array<string>> = new ReplaySubject<Array<string>>(1);
  public readonly groups$: Observable<Array<string>> = this.groups.asObservable();

  constructor(private _dialog: MatDialog, private orgService: OrganizationService,
              private groupService: GroupsService, private userService: UserService,
  ) {

  }

  ngOnInit() {
    let user;
    if (this.userService.user) {
      user = this.userService.user.userData ;
    }
    if (user && user.SysAdmin){
      this.organizations$ = this.orgService.getOrganizations();
    } else {
      this.organizations$ = Observable.of([{ id: user._organization, name: user.organization }]);
    }
    const currProcess = this;
    this.userService.user$.subscribe(user => this.currentUser = user);
    this.userService.user$.take(1).subscribe(data => {
      // this.placeholder = data.userData.organization;
      currProcess.selectedOrg = data.userData.organization;
      currProcess.orgService.getOrganizationUsers(currProcess.selectedOrg).then(members => {currProcess.potentialMembers = members; });
    });
    this.groupService.updateOrgUsers();
    this.groupService.updateOrgGroups();
  }

  getOrgRef(event: any) {
    const currProcess = this;
    this.selectedOrg = event.value;
    document.getElementById('userDisplayForm').hidden = true;
    const deleteBtn = <HTMLInputElement>document.getElementById('deleteButton');
    const editBtn = <HTMLInputElement>document.getElementById('editButton');
    deleteBtn.disabled = true;
    editBtn.disabled = true;
    this.groupName = '';
    this.groupDescrpition = '';
    this.group = {};
    this.selectedGroups = [];
    this.groupService.updateOrgGroups(this.selectedOrg);
    this.groupService.updateOrgUsers(this.selectedOrg);
    // const btn = <HTMLInputElement>document.getElementById('orgButton');
    // btn.disabled = false;
    currProcess.potentialMembers = [];
    this.orgService.getOrganizationUsers(this.selectedOrg).then(members => {
      currProcess.potentialMembers = members;
    });
    this.orgService.getGroups(this.selectedOrg).then(groups => {
      this.groups.next(groups);
    });
  }

  deleteGroup() {
    const dialogRef = this._dialog.open(ConfirmDialogDialogComponent, {
      height: '150px',
      width: '380px',
      data: { message: 'Are you sure you want to delete the group? Note that sub groups will also be deleted', closeFlag: false },
    });
    dialogRef.afterClosed().subscribe((data) => {
      if (data) {
        this.selectedGroups.forEach(group => {
          const groupName = this.group.Name;
          const groupKey = this.group.key;
          const groupParent = this.group.Parent;
          let parentName = '';
          this.deleteSubGroups(group);
          if (groupParent !== '') {
            parentName = this.groupService.getGroupNameByID(groupParent);
            this.orgService.deleteSubgroup(this.selectedOrg, groupKey, groupParent, parentName);
          }
          this.groupService.deleteGroupg(this.selectedOrg, groupKey, groupParent, parentName).then(() => {
            this.groupService.updateOrgGroups(this.selectedOrg);
          });
          this.groupName = '';
          validationAlert('Group ' + groupName + ' has been removed from ' + this.selectedOrg + ' organization', 4500, 'Success');
        });
        this.selectedGroups = [];
        const deleteBtn = <HTMLInputElement>document.getElementById('deleteButton');
        const editBtn = <HTMLInputElement>document.getElementById('editButton');
        document.getElementById('userDisplayForm').hidden = true;
        deleteBtn.disabled = true;
        editBtn.disabled = true;
      }
    });
    // if (!window.confirm('Are you sure you want to delete the group?')) return;

  }
  deleteSubGroups(groupID) {
    const group = this.groupService.getGroupByID(groupID);
    if (group.subGroups.length > 0) {
      group.subGroups.forEach(subGroup => {
        this.deleteSubGroups(subGroup);
        this.groupService.deleteGroupg(this.selectedOrg, subGroup, group.GroupID, this.groupService.getGroupNameByID(group.GroupID));
      });
    }
  }
  updateChosenGroupMembers() {
    const currentProcess = this;
    // currentProcess.potentialMembers = [];
    currentProcess.orgService.getGroupFromOrg(currentProcess.selectedOrg, currentProcess.selectedGroups[0]).then(group => {
      // const group = this.groupService.getGroupByID(this.selectedGroups[0])
      currentProcess.group = group;
      currentProcess.groupName = currentProcess.group.Name;
      /*updating potential members in ngOnInit and getOrgRef*/
      // if (group.Parent === '')  {
      // console.log('currentProcess.orgService.getGroupFromOrg');
      // await currentProcess.orgService.getOrganizationUsers(currentProcess.selectedOrg).then(members => {
      //    currentProcess.potentialMembers = members;
      //   });
      // } else {
      //   const memberList = this.groupService.getAllUsersByGroup(group.Parent);
      //   memberList.forEach(member => {
      //     this.potentialMembers .push(this.groupService.getUserById(member));
      //   });
      // }
      // });
    });
  }
  setUserButtons () {
    const currentProcess = this;
    currentProcess.potentialMembers.forEach(user => {
      let membersList = [];
      let adminsList = [];
      if (currentProcess.group.Members) {
        membersList = Object.keys(currentProcess.group.Members);
      }
      if (currentProcess.group.Administrators) {
        adminsList = Object.keys(currentProcess.group.Administrators);
      }
      if (membersList.includes(user.uid)) {
        document.getElementById('Add' + user.uid).hidden = true;
        document.getElementById('Del' + user.uid).hidden = false;
      } else {
        document.getElementById('Add' + user.uid).hidden = false;
        document.getElementById('Del' + user.uid).hidden = true;
      }
      if (adminsList.includes(user.uid)) {
        document.getElementById('AdminAdd' + user.uid).hidden = true;
        document.getElementById('AdminDel' + user.uid).hidden = false;
      } else {
        document.getElementById('AdminAdd' + user.uid).hidden = false;
        document.getElementById('AdminDel' + user.uid).hidden = true;
      }
    });
  }
  editGroup() {
    // document.getElementById('groupDispaly').hidden = false;
    document.getElementById('userDisplayForm').hidden = false;
    this.setUserButtons();
    const editBtn = <HTMLInputElement>document.getElementById('editButton');
    editBtn.disabled = true;
  }
  updateGroupName() {
    if (this.groupName !== '') {
      this.orgService.updateGroupName(this.selectedOrg, this.group.GroupID, this.groupName);
      this.groupService.updateOrgGroups(this.selectedOrg);
      validationAlert('Changes were saved', null, 'Success');
    } else {
      validationAlert('Group name must contain at least one character', 3500, 'Error');
    }
  }
  updateGroupDescription() {
    this.orgService.updateGroupDescription(this.selectedOrg, this.group.GroupID, this.groupDescrpition);
    this.groupService.updateOrgGroups(this.selectedOrg);
    validationAlert('Changes were saved', null, 'Success');
  }
  addMember(user) {
    document.getElementById('Add' + user.uid).hidden = true;
    document.getElementById('Del' + user.uid).hidden = false;
    this.groupService.addMembers(user, this.selectedGroups[0], this.selectedOrg);
    validationAlert('Changes were saved', null, 'Success');
  }
  deleteMember(user) {
    document.getElementById('Add' + user.uid).hidden = false;
    document.getElementById('Del' + user.uid).hidden = true;
    this.groupService.delUserFromGroup(this.selectedOrg, this.selectedGroups[0], user);
    validationAlert('Changes were saved', null, 'Success');
  }
  addMemberToAdmin(user) {
    document.getElementById('AdminAdd' + user.uid).hidden = true;
    document.getElementById('AdminDel' + user.uid).hidden = false;
    this.groupService.addUserToAdminGroup(this.selectedOrg, this.selectedGroups[0], user);
    validationAlert('Changes were saved', null, 'Success');
  }
  deleteAdminUser(user) {
    document.getElementById('AdminAdd' + user.uid).hidden = false;
    document.getElementById('AdminDel' + user.uid).hidden = true;
    this.groupService.delAdminGroupUser(this.selectedOrg, this.selectedGroups[0], user);
    validationAlert('Changes were saved', null, 'Success');
  }
  collDialogOpen() {
    const currentProcess = this;
    const dialogRef = currentProcess._dialog.open(CollaborationDialogComponent, {
      // height: '873px',
      width: '775px',
      data: {
        userToken: '',
        groupChkBoxOneAble : true,
        headLine: 'Choose group in ' + currentProcess.selectedOrg + ' organization',
        userChkBoxFlag : false,
        groupChkBoxFlag : true,
        currentUser: currentProcess.currentUser,
        org: currentProcess.selectedOrg,
        checkedUsers: [],
        checkedGroups: currentProcess.selectedGroups,
        modelOwner: ''
      },
    });

    dialogRef.afterClosed().subscribe(((data) => {
      if (data) {
        document.getElementById('userDisplayForm').hidden = true;
        currentProcess.selectedGroups = data.checkedGroupList;
        currentProcess.updateChosenGroupMembers();
        if (currentProcess.group) {
          currentProcess.groupName = currentProcess.group.Name;
          currentProcess.groupDescrpition = currentProcess.group.Description;
        }
        const deleteBtn = <HTMLInputElement>document.getElementById('deleteButton');
        const editBtn = <HTMLInputElement>document.getElementById('editButton');
        if (currentProcess.selectedGroups.length > 0) {
          deleteBtn.disabled = false;
          editBtn.disabled = false;
        } else {
          deleteBtn.disabled = true;
          editBtn.disabled = true;
        }
      }
    }));
  }

  sortArray(arr) {
    if (arr) {
      let tempArr = arr;
      tempArr.sort((a, b) => {
        return (a.Name.charAt(0).toUpperCase() < b.Name.charAt(0).toUpperCase()) ? -1 : a.Name.charAt(0).toUpperCase() > b.Name.charAt(0).toUpperCase() ? 1 : 0 ;
      });
      return tempArr;
    }
  }
}
