import { Component, Inject, OnDestroy, OnInit, Optional } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { AngularFireList } from '@angular/fire/compat/database';

@Component({
  selector: 'opcloud-memgrp-dialog',
  templateUrl: 'member-grp.html',
  styleUrls: ['member-grp.component.css']
})
export class MemberGrpComponent implements OnInit {
  user;
  ref;
  memRef;
  mGroups: AngularFireList<any>;
  memGrp: any;

  constructor
    (@Optional() public dialogRef: MatDialogRef<MemberGrpComponent>,
      @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any) {
  }
  ngOnInit() {
    this.user = this.dialogData[0];
    this.ref = this.dialogData[1];
    this.memRef = this.dialogData[2];
    this.mGroups = this.dialogData[4];
  }

  getIterableGrps(groups: any) {
    const arrayGroups = [];
    if (groups === undefined) { return arrayGroups; }
    for (const key of Object.keys(groups)) {
      if (groups[key] !== undefined) {
        arrayGroups.push(groups[key]);
      }
    }
    return arrayGroups;
  }

}
