import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import {StorageService} from "../../rappid-components/services/storage.service";
import {ModelTitleValidator} from "../load-model-dialog/name-validator";

@Component({
  selector: 'submodel-name-dialog',
  templateUrl: './submodel-name-dialog.html',
  styleUrls: ['./submodel-name-dialog.css']
})
export class SubModelNameComponent implements OnInit {

  private mode: 'create' | 'rename';
  private modelName: string;
  private error: string;
  private directory_id: string;
  private existingModelsNames: Array<string>;
  private title: string;
  private subTitle: string;
  private fatherModelName: string;

  constructor(
    @Optional() private readonly dialogRef: MatDialogRef<SubModelNameComponent>, private readonly storage: StorageService,
    @Inject(MAT_DIALOG_DATA) private readonly data) {
    this.directory_id = data.dir_id;
    this.existingModelsNames = [];
    this.mode = data.mode;
    this.fatherModelName = data.fatherModelName;
  }

  ngOnInit() {
    if (this.mode === 'create') {
      this.storage.getModels(this.directory_id, false).then(models => this.existingModelsNames = models.map(m => m.title));
    }
    this.modelName = this.data.name || '';
    this.setTitles();
  }

  setTitles() {
    if (this.mode === 'create') {
      this.title = 'Sub Model Creation';
      this.subTitle = 'Please enter a name for the sub model. A new OPD with this name will be created as the sub model.';
    } else {
      this.title = 'Rename Sub Model View Name';
      this.subTitle = 'Please enter a name for the sub model view.';
    }
  }

  apply() {
    let nameToSet = this.modelName + ' of ' + this.fatherModelName;
    if (!nameToSet.endsWith('Subsystem model')) {
      nameToSet += ' Subsystem model';
    }
    if (ModelTitleValidator.create().validateTitle(nameToSet) === false) {
      this.error = 'The name includes illegal characters.';
      return;
    } else if (this.existingModelsNames.find(title => title === nameToSet)) {
      this.error = 'The name already exists.';
      return;
    }
    this.dialogRef.close({ nameForOpd: this.modelName, nameForModel: nameToSet });
  }

  cancel() {
    this.dialogRef.close();
  }

}
