// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
  text-align: center;
  color: #1a3763;
  font-size: 18px;
  font-weight: 500;
}

hr {
  border: 1px solid #a2a4a64a;
  border-radius: 5px;
}

#allElements {
  height: 370px;
  overflow-y: scroll;
}

.decidedItem {
  margin-bottom: 4px;
  display: inline-grid;
  border-radius: 5px;
}

#decided {
  /*white-space:nowrap;*/
  height: 120px;
  overflow-y: scroll;
}

.inputNumber {
  width: 40px;
  margin-right: 10px;
  background: #FFFFFF;
  border: 1px solid #D9E7EE;
  box-sizing: border-box;
  border-radius: 5px;
  text-align-last: center;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  opacity: 1;
}

.instanceDiv {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding-top: 4px;
  padding-left: 4px;
  margin-top: 4px;
  overflow-x: scroll;
}

.incDecButton {
  color: #1a3763;
}

.constrainsSelect {
  margin-right: 5px;
  margin-left: -2px;
  border: 1px solid #D9E7EE;
  box-sizing: border-box;
  border-radius: 4px;
}

.decidedItem:hover {
  background-color: rgba(212, 212, 212, 0.59);
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/multi-instances-dialog/multi-instances-dialog.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,oBAAoB;EACpB,kBAAkB;AACpB;;AAEA;EACE,sBAAsB;EACtB,aAAa;EACb,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;EAClB,mBAAmB;EACnB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;EAClB,uBAAuB;AACzB;;AAEA;;EAEE,UAAU;AACZ;;AAEA;EACE,oCAAoC;EACpC,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,iBAAiB;EACjB,yBAAyB;EACzB,sBAAsB;EACtB,kBAAkB;AACpB;;AAEA;EACE,2CAA2C;AAC7C","sourcesContent":["\n.header {\n  text-align: center;\n  color: #1a3763;\n  font-size: 18px;\n  font-weight: 500;\n}\n\nhr {\n  border: 1px solid #a2a4a64a;\n  border-radius: 5px;\n}\n\n#allElements {\n  height: 370px;\n  overflow-y: scroll;\n}\n\n.decidedItem {\n  margin-bottom: 4px;\n  display: inline-grid;\n  border-radius: 5px;\n}\n\n#decided {\n  /*white-space:nowrap;*/\n  height: 120px;\n  overflow-y: scroll;\n}\n\n.inputNumber {\n  width: 40px;\n  margin-right: 10px;\n  background: #FFFFFF;\n  border: 1px solid #D9E7EE;\n  box-sizing: border-box;\n  border-radius: 5px;\n  text-align-last: center;\n}\n\ninput[type=number]::-webkit-inner-spin-button,\ninput[type=number]::-webkit-outer-spin-button {\n  opacity: 1;\n}\n\n.instanceDiv {\n  border: 1px solid rgba(0, 0, 0, 0.1);\n  border-radius: 4px;\n  padding-top: 4px;\n  padding-left: 4px;\n  margin-top: 4px;\n  overflow-x: scroll;\n}\n\n.incDecButton {\n  color: #1a3763;\n}\n\n.constrainsSelect {\n  margin-right: 5px;\n  margin-left: -2px;\n  border: 1px solid #D9E7EE;\n  box-sizing: border-box;\n  border-radius: 4px;\n}\n\n.decidedItem:hover {\n  background-color: rgba(212, 212, 212, 0.59);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
