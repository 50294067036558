
export const textWrapping = {

  updateCell(cell, x, y, cornerX, cornerY) {
    cell.set({
      position: {x: x, y: y},
      size: {width: cornerX - x, height: cornerY - y}
    });
  },

  getParagraphWidthByParams(text, fontSize, fontWeight, fontFamily) {
    const textArray = text.split('\n');
    let maxLineWidth = 0, textWidth;
    for (let i = 0; i < textArray.length; i++) {
      textWidth = this.getTextWidth(textArray[i], fontSize, fontWeight, fontFamily);
      if (textWidth > maxLineWidth)
        maxLineWidth = textWidth;
    }
    return maxLineWidth;
  },

  getParagraphWidth(text, cell) {
    return this.getParagraphWidthByParams(text, cell.attributes.attrs.text['font-size'], cell.attributes.attrs.text['font-weight'], cell.attributes.attrs.text['font-family']);
  },

  getParagraphHeightByParams(text, fontSize, fontWeight, fontFamily) {
    const textArray = text.split('\n');
    const textHeight = this.getTextHeight(textArray[0], fontSize, fontWeight, fontFamily);
    return textArray.length * textHeight;
  },

  getParagraphHeight(text, cell) {
    return this.getParagraphHeightByParams(text, cell.attributes.attrs.text['font-size'], cell.attributes.attrs.text['font-weight'], cell.attributes.attrs.text['font-family']);
  },

  wrapTextAfterSizeChange(cell) {
    let textString = cell.attributes.attrs.text.textWrap.text;
    if (!textString) return;
    textString = this.refactorText(textString, cell, cell.get('size').width);
    let heightTextAndStates = this.getParagraphHeight(textString, cell) + 20 + cell.get('padding');;
    let widthTextAndStates = this.getParagraphWidth(textString, cell) + 20 + cell.get('padding');
    heightTextAndStates = (heightTextAndStates > cell.get('size').height) ? heightTextAndStates : cell.get('size').height;
    widthTextAndStates = (widthTextAndStates > cell.get('size').width) ? widthTextAndStates : cell.get('size').width;
    return {
      width: widthTextAndStates,
      height: heightTextAndStates,
      text: textString
    };
  },

  resizeCell(cell):void{
    let textString = cell.attributes.attrs.text.textWrap.text;
    const cellWidth = textWrapping.wrapTextAfterSizeChange(cell).width;
    const cellHeight = textWrapping.wrapTextAfterSizeChange(cell).height;
    if((this.getParagraphHeight(textString, cell) > cell.get('size').height) || (this.getParagraphWidth(textString, cell) > cell.get('size').width)) {
      cell.set({
        'size': {
          width: cellWidth * 1.2,
          height: cellHeight * 1.2
        }
      });
    }
  },


  resizefather(fatherObject): void{
    let embeddedStates = fatherObject.getStatesOnly();
    let widthArr = [];
    let heightArr = [];
    for(let state of embeddedStates){
        widthArr.push(state.get('size').width);
        heightArr.push(state.get('size').height);
    }
    let maxWidth = widthArr.reduce(textWrapping.getSum, 0);
    let maxHeight = heightArr.reduce(textWrapping.getSum, 0);
    // let maxHeight = Math.max(...heightArr);
    fatherObject.set({
      'size':{
        width:maxWidth*1.2,
        height:maxHeight*1.2
      }
    });
  },

  getSum(total, num) {
  return total + Math.round(num);
},


  // units have to be bellow the object's name
  unitsNewLine(textString) {
    if (textString.includes('[') && !textString.includes('\n[')) {
      const unitsIndex = textString.indexOf('[');
      const actualText = textString.substring(0, unitsIndex).trim();
      const units = textString.substring(unitsIndex, textString.length);
       textString = actualText + '\n' + units;
      if (textString.includes('[\n')) {
         textString = textString.replace('[\n', '[');
      }
    }
    return textString;
  },

  wrapText(textString, cell, width) {
    if (!textString) return;
    textString = textString.replace(/\n/g, ' ');
    const wordsArr = textString.split(' ');
    let newStr = wordsArr[0];
    let i = 1;
    while (i < wordsArr.length) {
      if (this.getParagraphWidth((newStr + ' ' + wordsArr[i]), cell) < width) {
        newStr = newStr + ' ' + wordsArr[i];
      }
      else {
        newStr = newStr + '\n' + wordsArr[i];
      }
      i++;
    }
    return newStr;
  },

  refactorText(textString, cell, width) {
    textString = this.wrapText(textString, cell, width);
    // wrapText remove spaces from the end
    const lastChar = textString.charAt(textString.length - 1);
    textString = ((lastChar === ' ') || (lastChar === '\u00A0')) ? (textString + ' ') : textString;
    textString = this.unitsNewLine(textString);
    return textString;
  },
  calculateNewTextSize(textString, cell) {
    let addition = 1, increase = false;
    const stateWidth = cell.get('statesWidthPadding') ? cell.get('statesWidthPadding') : 0;
    const stateHeight = cell.get('statesHeightPadding') ? cell.get('statesHeightPadding') : 0;
    textString = this.refactorText(textString, cell, cell.get('size').width - stateWidth - cell.get('padding'));
    let textWidth = this.getParagraphWidth(textString, cell) + stateWidth;
    let textHeight = this.getParagraphHeight(textString, cell) + stateHeight;
    while ((textHeight > (cell.get('size').height * addition - cell.get('padding'))) || (textWidth > (cell.get('size').width * addition - cell.get('padding')))) {
      increase = true;
      addition = addition * 1.1;
      textString = this.refactorText(textString, cell, cell.get('size').width * addition - stateWidth - cell.get('padding'));
      textWidth = this.getParagraphWidth(textString, cell) + stateWidth;
      textHeight = this.getParagraphHeight(textString, cell) + stateHeight;
    }
    // try to make the entity smaller only if it doesn't have embedded cells
    if (!cell.getEmbeddedCells().length) {
      while ((textWidth < (cell.get('size').width * addition / 1.1 - cell.get('padding'))) &&
      (cell.get('size').width * addition / 1.1 >= cell.get('minSize').width) && !increase) {
        addition = addition / 1.1;
        textString = this.refactorText(textString, cell, cell.get('size').width * addition - stateWidth - cell.get('padding'));
        textWidth = this.getParagraphWidth(textString, cell) + stateWidth;
      }
    }
    return {
      width: cell.get('size').width * addition,
      height: cell.get('size').height,
      text: textString
    };
  }
};
