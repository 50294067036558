import {Component} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogConfig as MatDialogConfig, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {InitRappidService} from '../../../../rappid-components/services/init-rappid.service';
import {UploadFile} from '../../../../dialogs/FileUploader/FileUploader';
import {SaveScreenshotComponent} from '../../../../dialogs/saveScreenshot-dialog/saveScreenshot';
import {AboutDialogComponent} from '../../../../dialogs/About/about';
import {OplService} from '../../../../opl-generation/opl.service';
import {Router} from '@angular/router';
import {GraphService} from '../../../../rappid-components/services/graph.service';
import {ChooseExportedFileNameComponent} from '../../../../dialogs/choose-exported-file-name/choose-exported-file-name';

import * as FileSaver from 'file-saver';
import {opmQueryDialogComponent} from '../../../../dialogs/opmQuery-dialog/opmQuery-dialog';
import {OpmLogicalObject} from '../../../../models/LogicalPart/OpmLogicalObject';
import {OpmProcess} from '../../../../models/DrawnPart/OpmProcess';
import {OpmObject} from '../../../../models/DrawnPart/OpmObject';
import {UserService} from '../../../../rappid-components/services/user.service';
import {highlighSD, validationAlert} from '../../../../configuration/rappidEnviromentFunctionality/shared';
import {TreeViewService} from '../../../../rappid-components/services/tree-view.service';
import {SavePdfComponent} from '../../../../dialogs/savePdf-dialog/savePdf';
import {ListLogicalComponent} from '../../main/list-logical/list-logical.component';
import {GreyItemsDialogComponent} from '../../../../dialogs/grey-items-dialog/grey-items-dialog.component';
import {ModelStorageService} from '../../../../rappid-components/services/storage/model-storage.service';
import {ValidationSettingsComponent} from '../../../../dialogs/validation-settings/validation-settings';
import {
  ExamplesType,
  ScreenType,
  StorageMode,
  TemplateType
} from '../../../../dialogs/load-model-dialog/load-model-dialog.component';
import {OpdtreeModelSettingsComponent} from '../../../../dialogs/opdtree-model-settings/opdtree-model-settings.component';
import {TemplatesComponent} from '../../../../dialogs/templates-import/templates-import';
import {ExportOPLAPIService} from '../../../app/export-opl.service';
import {NewModelByWizardComponentComponent} from '../../../../dialogs/new-model-by-wizard-component/new-model-by-wizard-component.component';
import {ServerFlatteningService} from '../../../../rappid-components/services/server-flattening.service';
import {ContextService} from '../../../app/context.service';
import {OpmModel} from '../../../../models/OpmModel';
import {OPLGenerativeAIDialogComponent} from '../../../../dialogs/opl-generative-ai-dialog/opl-generative-ai';
import {
  GenerativeAIUpdateKeyDialogComponent
} from '../../../../dialogs/opl-generative-ai-dialog/generative-ai-update-key-dialog/generative-ai-update-key';
import {
  GenerativeAIImpactAnalysisDialogComponent
} from '../../../../dialogs/opl-generative-ai-dialog/generative-ai-impact-analysis-dialog/generative-ai-impact-analysis';
import {ConfirmDialogDialogComponent} from '../../../../dialogs/confirm-dialog/confirm-dialog';
import {ExportModelAsHtmlComponent} from '../../../../dialogs/export-model-as-html-dialog/export-model-as-html';

@Component({
  selector: 'opc-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent {

  public isActive: boolean = true;
  public menuOpen: boolean = false;
  currentUser;

  // private flatOPD:OpmOpd;

  constructor(
    private graphService: GraphService,
    private initRappidService: InitRappidService,
    private readonly model: ModelStorageService,
    private _dialog: MatDialog,
    private oplService: OplService,
    private userService: UserService,
    public _treeViewService: TreeViewService,
    private router: Router,
    private context: ContextService,
    private serverFlatteningService: ServerFlatteningService,
    private exportOPLService: ExportOPLAPIService) {

    initRappidService.paper.on('blank:pointerdown', () => {
      if (this.menuOpen) {
        this.toggleMenu();
      }
    });

    this.userService.user$.subscribe(user => this.currentUser = user);
  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.isActive = true;
    } else if (!this.menuOpen) {
      this.menuOpen = true;
      this.isActive = false;
    }
  }

  importModel() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(UploadFile);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  notGenAIUserMessage() {
    this._dialog.open(ConfirmDialogDialogComponent, {
      height: '300px',
      width: '430px',
      data: { message: 'Unlock Advanced Generative AI Features!\n\n' +
          'Take your work to the next level with OPL summary generation, impact analysis, ' +
          'modeling insights, enhanced coding, and even natural language-based calculations.\n\n'
          + 'Interested in upgrading?\n' +
          'Contact us at <a href="mailto:contact@opcloud.tech">contact@opcloud.tech</a> for more details.',
        okName: 'Got it!', okColor: '#1a3763', centerText: true, closeFlag: true },
    });
    return;
  }

  AIOplSummary(summaryType: string) {
    if (!this.userService.isGenAIUser(this.currentUser)) {
      this.notGenAIUserMessage();
      return;
    }
    this.toggleMenu();
    const dialogRef = this._dialog.open(OPLGenerativeAIDialogComponent, {
      data: {
        type: summaryType
      },
    });
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  AIImpactAnalysis() {
    if (!this.userService.isGenAIUser(this.currentUser)) {
      this.notGenAIUserMessage();
      return;
    }
    this.toggleMenu();
    const dialogRef = this._dialog.open(GenerativeAIImpactAnalysisDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  updateGenerativeAIApiKey() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(GenerativeAIUpdateKeyDialogComponent);
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  saveScreenshot() {
    this.toggleMenu();
    this._dialog.open(SaveScreenshotComponent);
  }

  savePdf() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(SavePdfComponent, {
      height: '545px',
      width: '500px',
      data: { modelName: this.initRappidService.modelService.displayName }
    });
  }

  exportHTML() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(ExportModelAsHtmlComponent, {
      height: '545px',
      width: '500px',
      data: { modelName: this.initRappidService.modelService.displayName }
    });
  }

  openSettings() {
    this.initRappidService.saveWhichTreeNodesAreOpen();
    this.router.navigate(['/settings', { outlets: { settings_main: ['home'] } }], { skipLocationChange: true });
  }

  about() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(AboutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {

      }
    });
  }

  openOPCloudManual() {
    this.router.navigate(['/settings', { outlets: { settings_main: ['opcloud-quick-manual'] } }], { skipLocationChange: true });
  }

  copyLink() {
    this.model.openUrlDialog();
  }

  exportOPL() {
    this.toggleMenu();
    // Open a dialog box that will allow the user to choose the file name
    const dialogRef = this._dialog.open(ChooseExportedFileNameComponent, {
      height: '380px',
      width: '400px',
    });
    // This function invokes the exporting OPL process
    dialogRef.afterClosed().subscribe(async (fileName) => {
      if (!fileName || !fileName[0] || fileName[0] === 'CLOSED') { // If the user pressed CANCEL.
        return;
      } else {
        if (fileName[2]) {
          await this.initRappidService.opdHierarchyRef.loadAllSubModels();
        }
        this.exportOPLService.exportOPL(fileName);
      }
    });
  }

  getSavingIsNotAvailableForViewerAccountMessage() {
    return 'This operation is not available for viewer accounts. Please contact your organization\'s admin to make changes. Thank you!';
  }

  async clickSave() {
    this.toggleMenu();
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert(this.getSavingIsNotAvailableForViewerAccountMessage(), 5000, 'ERROR');
      return;
    }
    const image = await this.initRappidService.getModelImage();
    this.model.save(image);
  }

  clickSaveModelAs() {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert(this.getSavingIsNotAvailableForViewerAccountMessage(), 5000, 'ERROR');
      return;
    }
    if (this.initRappidService.isDSMClusteredView.value) {
      validationAlert('This is only an analysis view of an existing model. Saving is not available.');
      return;
    }
    this.model.openSaveModelDialog();
  }

  downloadModel() {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    this.model.downloadModel();
  }

  loadModelFromFile() {
    if (this.currentUser && !this.userService.isSysAdmin())
      return;
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    $('#loadModelFromFile').click();
  }

  readSingleFile($event) {
    const file = $event.target.files[0];
    if (!file)
      return;
    const reader = new FileReader();
    const that = this;
    reader.onload = function(e) {
      const json = JSON.parse((<any>e.target).result);
      that.model.loadModelFromFile(json);
      that.toggleMenu();
    };
    reader.readAsText(file);
  }

  clickNewModel() {
    this.toggleMenu();
    OpmProcess.resetCounter();
    OpmObject.resetCounter();
    this.initRappidService.setSelectedElementToNull();
    this.model.newModel();
  }

  clickSaveStereotype() {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert(this.getSavingIsNotAvailableForViewerAccountMessage(), 5000, 'ERROR');
      return;
    }
    this.model.openSaveStereotypeDialog();
  }

  executeIfLogged(func) {
    this.toggleMenu();
    return this[func]();
  }

  clickLoadModel() {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    this.model.openLoadModelDialog();
  }

  clickLoadExample(type: string) {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    const exType = type === 'org' ? ExamplesType.ORG : ExamplesType.SYS;
    this.model.openModelDialog(StorageMode.LOAD, ScreenType.EXAMPALES, exType);
  }

  clickSaveExample(type: string) {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert(this.getSavingIsNotAvailableForViewerAccountMessage(), 5000, 'ERROR');
      return;
    }
    const exType = type === 'org' ? ExamplesType.ORG : ExamplesType.SYS;
    if (this.initRappidService.isDSMClusteredView.value) {
      validationAlert('This is only an analysis view of an existing model. Saving is not available.');
      return;
    }
    this.model.openModelDialog(StorageMode.SAVE, ScreenType.EXAMPALES, exType);
  }

  clickSaveTemplate(type: string) {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    let templateType;
    if (type === 'org')
      templateType = TemplateType.ORG;
    else if (type === 'system')
      templateType = TemplateType.SYS;
    else {
      templateType = TemplateType.PERSONAL;
      validationAlert('Pay attention, personal templates are visible only for you. To share a template with other modelers use the model permissions screen.', 8500, 'warning');
    }
    this.model.openModelDialog(StorageMode.SAVE, ScreenType.TEMPLATES, undefined, templateType);
  }

  clickTemplate(mode: 'edit' | 'save') {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    if (this.currentUser.userData.isViewerAccount) {
      validationAlert(this.getSavingIsNotAvailableForViewerAccountMessage(), 5000, 'ERROR');
      return;
    }
    if (this.initRappidService.isDSMClusteredView.value && mode === 'save') {
      validationAlert('This is only an analysis view of an existing model. Saving is not available.');
      return;
    }
    this._dialog.open(TemplatesComponent, {
      height: Math.round(window.innerHeight * 0.9) + 'px',
      width: Math.round(window.innerWidth * 0.75) + 'px',
      data: { mode: mode }
    });
  }

  compareModel(path?: string, name?: string, data?: any) {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    this.model.openLoadModelComparisonDialog();
  }

  OPMQueryPreCheck(): boolean {
    //Check if already in the OPM Query Results view
    const currentOpd = this.initRappidService.opmModel.currentOpd;
    if (currentOpd.id === this.initRappidService.opmModel.getOPMQueryID()) {
      validationAlert('Can\'t run query while on OPM Insights result view', null, 'Error');
      return false;
    }

    //Check minimum number of elements to execute a query
    if (this.initRappidService.opmModel.logicalElements.length < 2) {
      validationAlert('There is not enough elements to execute analysis', null, 'Error');
      return false;
    }

    return true;
  }

  async flatOPM() {
    this.toggleMenu();
    if (this.OPMQueryPreCheck()) {
      let serverFlattening;
      if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
        serverFlattening = await this.serverFlatteningService.getFlattenedModelFromServer(false);
        if (!serverFlattening.success) {
          validationAlert('Unable to run Flattening algorithm on the BE server. Running it locally.');
        }
      }
      let flatOPD, copyModel;
      // Generate Flat OPD
      if (serverFlattening?.success) {
        flatOPD = serverFlattening.serverModel.getOpd('OPMqUeRy');
      } else {
        copyModel = new OpmModel().fromJson(this.initRappidService.opmModel.toJson());
        flatOPD = copyModel.flattening(false);
      }
      this.initRappidService.isDSMClusteredView = { value: true, type: 'flattening' };
      const that = this;
      this.initRappidService.isLoadingModel = true;
      setTimeout( function() {
        const modelToShow = serverFlattening?.serverModel || copyModel;
        modelToShow.name = '[Flattened] ' + modelToShow.name;
        that.context.loadDSMFlatteningModel(modelToShow);
        that.router.navigate(['']);
        that.initRappidService.isLoadingModel = false;
      }, 800 );
    }
  }

  openOpmQuery(type) {
    this.toggleMenu();
    if (type === 'Centrality') {
      validationAlert('Centrality Queries are not enabled yet', null, 'Error');
    } else {
      if (this.OPMQueryPreCheck()) {
        const config = new MatDialogConfig();
        config.disableClose = true;
        const dialogRef: MatDialogRef<opmQueryDialogComponent> = this._dialog.open(opmQueryDialogComponent, config);
        dialogRef.componentInstance.type = type;
      }
    }
  }

  DestatingAll() {
    for (let i = 0; i < this.initRappidService.opmModel.logicalElements.length; i++) {
      if (this.initRappidService.opmModel.logicalElements[i] instanceof OpmLogicalObject) {
        if ((<OpmLogicalObject>this.initRappidService.opmModel.logicalElements[i]).states.length > 0 && (<OpmLogicalObject>this.initRappidService.opmModel.logicalElements[i]).value === 'None') {
          const newVisuals = (<OpmLogicalObject>this.initRappidService.opmModel.logicalElements[i]).deStating();
          const onlyOneIterationPerFather = [];
          for (let k = 0; k < newVisuals.length; k++) {
            if (onlyOneIterationPerFather.includes(<OpmLogicalObject>newVisuals[k][0].logicalElement) === false) {
              onlyOneIterationPerFather.push(<OpmLogicalObject>newVisuals[k][0].logicalElement);
              (<OpmObject>this.initRappidService.graph.getCell(newVisuals[k][0].id)).updateDeStating(newVisuals, this.initRappidService);
            }
          }
        }
      }
    }
  }

  showSystemMap() {
    ListLogicalComponent.instances[0].showMap();
    this.toggleMenu();
  }

  clickLoadStereotype() {
    this.initRappidService.setSelectedElementToNull();
    this.toggleMenu();
    this.model.openLoadStereotypeDialog();
  }

  markThings() {
    const config = new MatDialogConfig();
    config.height = '700px';
    config.width = '800px';
    this._dialog.open(GreyItemsDialogComponent, config);
    this.toggleMenu();
  }

  opdTreeArrangementOptions() {
    const config = new MatDialogConfig();
    config.height = '170px';
    config.width = '760px';
    this._dialog.open(OpdtreeModelSettingsComponent, config);
    this.toggleMenu();
  }

  modelValidationOptions() {
    const config = new MatDialogConfig();
    config.height = '250px';
    config.width = '550px';
    this._dialog.open(ValidationSettingsComponent, config);
    this.toggleMenu();
  }

  newModelByWizard() {
    this.initRappidService.dialogService.openDialog(NewModelByWizardComponentComponent, 720, 1100, { doNotClose: 'true' });
    this.toggleMenu();
  }
}
