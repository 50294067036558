import {
  convert, OPCloudUtils,
  popupGenerator,
  stylePopup, validationAlert
} from "../../configuration/rappidEnviromentFunctionality/shared";
import { TimeDurationModule } from '../LogicalPart/components/TimeDurationModule';
import { OpmEntity } from './OpmEntity';

let lastEnteredUnits: string;

export function openTimeDurationPopup(target, drawn, manager: TimeDurationModule, params: { digits: number }) {
  //const allUnits = convert().possibilities();
  let selectContent = '<select id="selectUnits" class="Units PopupSelection value">';
  const relevantUnits = ['s', 'ms', 'min', 'h', 'd', 'week', 'month', 'year'];
  //const selectedIndex = relevantUnits.indexOf(visual.logicalElement.units);
  const duration = manager.getTimeDuration();
  lastEnteredUnits = duration.units;
  const selectedIndex = relevantUnits.indexOf(duration.units);
  for (let k = 0; k < relevantUnits.length; k++) {
    const isSelected = (selectedIndex === k) ? ' selected' : '';
    selectContent = selectContent + '<option value=' + relevantUnits[k];
    // title includes name of unit and what is measured by it
    selectContent = selectContent + ' title=' + '"' + relevantUnits[k] + '"';
    selectContent = selectContent + isSelected + '>' + relevantUnits[k] + '</option>';
  }
  selectContent += '</select>';
  const content = 'Time Duration Parameters: <br>'
    + "Units: " + selectContent + "<br>" +
    'Minimal: <input size="2" type="number" step="any" class="min" value="' + (duration.min ? duration.min : '') + '"><br>' +
    'Nominal: <input size="2" type="number" step="any" class="nominal" value="' + (duration.nominal ? duration.nominal : '') + '"><br>' +
    'Maximal: <input size="2" type="number" step="any" class="max" value="' + (duration.max ? duration.max : '') + '"><br>' +
    '<button class="Popup btnUpdate" style="left: 65px;margin-top: 9px;">Update</button>';
  popupGenerator(target, content, timeDurationEvents(drawn, manager, params)).render();
  stylePopup();
  const popup = $('.joint-popup')[0] as HTMLDivElement;
  popup.style.lineHeight = '1.6';
}

function timeDurationEvents(drawn, manager: TimeDurationModule, params: { digits: number }) {
  return {
    'click .btnUpdate': function () {
      manager.setDisplayParams({ digitsAfterDot: params.digits });
      const ret = setTimeDurationParams(drawn, manager, { 
        units: this.$('.Units').val(),
        min: extractNumber(this.$('.min').val()),
        nominal: extractNumber(this.$('.nominal').val()),
        max: extractNumber(this.$('.max').val())
      });

      if (ret) {
        this.remove();
        if (drawn && drawn.getLink2)
          drawn.getLink2().addTimerIcon();
      }

    },
    'change #selectUnits': function () {
      const new_units = this.$('.Units').val();
      this.$('.min')[0].value = clearValue(this.$('.min')[0].value, { old: lastEnteredUnits, new: new_units });
      this.$('.max')[0].value = clearValue(this.$('.max')[0].value, { old: lastEnteredUnits, new: new_units });
      this.$('.nominal')[0].value = clearValue(this.$('.nominal')[0].value, { old: lastEnteredUnits, new: new_units });
      lastEnteredUnits = new_units;
    }
  };
}

function extractNumber(val: string): number {
  try {
    if (val == '')
      return null;
    let ret: number = null;
    if (val.includes('e'))
      ret = Number(Number(val).toPrecision());
    else
      ret = Number(val);
    return ret;
  } catch (err) {
    return null;
  }
}

function clearValue(val: string, units: { old, new }): string {
  try {
    if (val)
      return convert(val).from(units.old).to(units.new);
    return '';
  } catch (err) {
    return '';
  }
}

function setTimeDurationParams(drawn: OpmEntity, manager: TimeDurationModule, args: { units: string, min: number, nominal: number, max: number }) {
  const ret = manager.setTimeDuration(args);
  if (ret.success === true && OPCloudUtils.isInstanceOfDrawnEntity(drawn)) {
    drawn.updateTextView();
  } else {
    for (const msg of ret.messages)
      validationAlert(msg);
  }
  return ret.success;
}
