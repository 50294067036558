import {Component, OnInit} from "@angular/core";
import {StorageService} from "../../../rappid-components/services/storage.service";
import {UserService} from "../../../rappid-components/services/user.service";
import {DatabaseService} from "../../../database/database.service";
import {MatLegacySelectChange as MatSelectChange} from "@angular/material/legacy-select";

@Component({
  selector: 'Organization-Analytics',
  templateUrl: './organization-analytics.component.html',
  styleUrls: ['./organization-analytics.component.css']
})
export class OrganizationAnalyticsComponent implements OnInit {

  private data;
  private isLoading: boolean;
  private selectedOrg: string;
  private orgsNames;
  private isSysAdmin: boolean;

  private modelersSortColumn: string;
  private viewersSortColumn: string;

  private modelersSortDirection;
  private viewersSortDirection;

  constructor(private dbService: DatabaseService, private userService: UserService) {
    this.modelersSortColumn = 'name';
    this.viewersSortColumn = 'name';
    this.modelersSortDirection ='down';
    this.viewersSortDirection ='down';

    this.selectedOrg = this.userService.user?.userData?.organization;
    this.orgsNames = [];
    this.resetData();
  }

  resetData() {
    this.data = {
      connectedModelers: [],
      connectedViewers: [],
      hasUsersLimit: false,
      modelersLimit: 0,
      viewersLimit: 0
    }
  }

  ngOnInit() {
    this.isSysAdmin = this.userService.isSysAdmin();
    if (this.isSysAdmin) {
      this.dbService.driver.getAllOrganizations().then(orgs => this.orgsNames = orgs.map(org => org.name));
    }
    if (this.selectedOrg) {
      this.loadData();
    }
  }

  loadData() {
    this.isLoading = true;
    this.dbService.driver.getOrganizationAnalytics(this.selectedOrg).then(data => {
      this.data = data;
      this.sortModelersData();
      this.sortViewersData();
      this.isLoading = false;
    }).catch(err => this.isLoading = false);
  }

  modelersColumnClick(colName: 'name' | 'email') {
    this.toggleModelersSortDirection();
    this.modelersSortColumn = colName;
    this.sortModelersData();
  }

  viewersColumnClick(colName: 'name' | 'email') {
    this.toggleViewersSortDirection();
    this.viewersSortColumn = colName;
    this.sortViewersData();
  }

  toggleModelersSortDirection() {
    this.modelersSortDirection = this.modelersSortDirection === 'up' ? 'down' : 'up';
  }

  toggleViewersSortDirection() {
    this.viewersSortDirection = this.viewersSortDirection === 'up' ? 'down' : 'up';
  }

  sortModelersData() {
    this.data.connectedModelers = this.data.connectedModelers.sort((a, b) => {
      const sign = this.modelersSortDirection === 'up' ? -1 : 1;
      if (this.modelersSortColumn === 'name') {
        return a.Name > b.Name ? sign * 1 : sign * -1;
      } else {
        return a.Email > b.Email ? sign * 1 : sign * -1;
      }
    });
  }

  sortViewersData() {
    this.data.connectedViewers = this.data.connectedViewers.sort((a, b) => {
      const sign = this.viewersSortDirection === 'up' ? -1 : 1;
      if (this.viewersSortColumn === 'name') {
        return a.Name > b.Name ? sign * 1 : sign * -1;
      } else {
        return a.Email > b.Email ? sign * 1 : sign * -1;
      }
    });
  }

  getModelersArrowSign(colName: string) {
    if (colName === this.modelersSortColumn) {
      return this.modelersSortDirection === 'up' ? '↑ ' : '↓ ';
    }
    return '';
  }

  getViewersArrowSign(colName: string) {
    if (colName === this.viewersSortColumn) {
      return this.viewersSortDirection === 'up' ? '↑ ' : '↓ ';
    }
    return '';
  }

  refresh() {
    this.resetData();
    this.loadData();
  }

  onSelectedOrgChange($event: MatSelectChange) {
    this.selectedOrg = $event.value;
    this.refresh();
  }
}

