import { AuthenticationDriver } from "../rappid-components/services/authentication/authentication.driver";


import { Injectable } from "@angular/core";
import { ReplaySubject, Observable } from "rxjs";

import {AngularFireAuth} from "@angular/fire/compat/auth";

import {getAuth, GoogleAuthProvider} from "firebase/auth";
import { PhoneMultiFactorGenerator,  PhoneAuthProvider} from "firebase/auth";
import * as firebase from "firebase/app";
import {environment} from "../../environments/environment";
@Injectable()
export class FirebaseAuthDriver implements AuthenticationDriver {

  private readonly token_ = new ReplaySubject<string>(1);
  private readonly token$ = this.token_.asObservable();

  public token(): Observable<string> {
    return this.token$;
  }

  public readonly availableProviders = []; // environment.firebaseAuthProviders;

  constructor(private authentication: AngularFireAuth) {
    this.init();
    this.authentication.onIdTokenChanged(obs => {
      if (obs)
        obs.getIdToken().then(token => this.token_.next(token))
    });
  }

  private init() {
    firebase.initializeApp(environment.firebaseCredentials);
  }

  public async refreshExpiredToken() {
    console.log('refreshing token.');
    const user = await this.authentication.currentUser;
    if (user) {
      user.getIdToken(true).then(token => this.token_.next(token));
    }
  }

  async signInWith2FactorAuthentication(error, recaptchaVerifier) {
    const resolver = error.resolver;
    const phoneInfoOptions = {
      multiFactorHint: resolver.hints[0],
      session: resolver.session
    };
    const auth = getAuth();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    const verificationCode = prompt("We sent a code to your phone. Please Enter the code here:");
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    const ret = await resolver.resolveSignIn(multiFactorAssertion)
    return ret;
  }

  async add2FactorAuthenticationForUser(recaptchaVerifier, phoneNumber: string): Promise<any> {
    const user = await this.authentication.currentUser;
    const multiFactorSession = await user.multiFactor.getSession();
    const auth = getAuth();
    const phoneAuthProvider = new PhoneAuthProvider(auth);
    const phoneInfoOptions = {
      phoneNumber: phoneNumber,
      session: multiFactorSession
    };
    const verificationId = await phoneAuthProvider.verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier);
    return verificationId;
  }

  async finishAdd2FactorAuthForUser(verificationCode: string, verificationId): Promise<{success: boolean, message?: string}> {
    const user = await this.authentication.currentUser;
    const cred = PhoneAuthProvider.credential(verificationId, verificationCode);
    const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred);
    if (user.multiFactor?.enrolledFactors[0]) {
      await user.multiFactor.unenroll(user.multiFactor?.enrolledFactors[0]);
    }
    return user.multiFactor.enroll(multiFactorAssertion, 'My phone number').then(() => {
      return { success: true };
    }).catch(err => {
      let message = 'Unknown error has occurred.';
      if (err.code === 'auth/invalid-verification-code') {
        message = 'Wrong verification code.';
      }
      return { success: false, message };
    });
  }

  async removeSecondAuthFactorForUser(): Promise<void> {
    const user = await this.authentication.currentUser;
    return user.multiFactor.unenroll(user.multiFactor?.enrolledFactors[0]);
  }

  public async signInWithCustomToken(token: string): Promise<any> {
    return Promise.resolve(await this.authentication.signInWithCustomToken(token));
  }

  public async signInAnonymously(): Promise<any> {
    return Promise.resolve(await this.authentication.signInAnonymously());
  }

  public async signInWithEmailAndPassword(email, password): Promise<any> {
    /*const promise = this.authentication.auth.signInWithEmailAndPassword(email, password);
    return new Promise<User>((resolve, reject) => {
      promise.then(user => {
        console.log(user);
        alert('Sign');
        this.database.findUser(user).then((userData) => {
          resolve({ ...user, userData });
        }).catch(err => reject(err));
      }).catch(err => reject(err));
    });*/
    return Promise.resolve(await this.authentication.signInWithEmailAndPassword(email, password));
  }

  public autoSignIn(): Promise<any> {
    return new Promise((resolve, reject) => this.authentication.authState.subscribe(resolve, reject));
    /*return new Promise<User>((resolve, reject) => {
      this.authentication.authState.subscribe(user => {
        alert('Auto');
        if (!user)
          return reject('Bad Autosing');
        console.log(user)
        resolve({ ...<any>user });
        this.database.findUser(user).then((userData) => {
          resolve({ ...<any>user, userData });
        }).catch(err => reject(err));
      });
    });*/
  }

  public signOut(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authentication.signOut().then(res => resolve(true)).catch(err => reject());
    });
  }

  public sendPasswordResetEmail(user_email: string): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      this.authentication.sendPasswordResetEmail(user_email).then(_ => resolve(true));
    });
  }

  public signInWithPopup(provider) {
    const authProviders = {
      google: GoogleAuthProvider
    };

    if (!this.availableProviders.includes(provider)) {
      throw (new Error(`Auth provider is not supported: ${provider}`));
    }
    return this.authentication.signInWithPopup(new authProviders[provider]());
  }

  public async getToken(): Promise<string> {
    const user = await this.authentication.currentUser;
    return new Promise<string>((resolve, reject) => user.getIdToken().then(resolve, reject));
  }
}
