import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";

export class UpdateComputationCommand implements ElementCommand {

  constructor(
    private readonly init: InitRappidService,
    private readonly thing: OpmThing,
    private readonly visual: OpmVisualThing) {
  }

  createHaloHandle(): ElementHaloHandle {
    return {
      flag: true,
      name: 'update-computation',
      displayTitle: 'Update Computation',
      svg: 'updateComputationalProcess',
      action: new UpdateComputationalAction(this.init, this.thing),
      gif: 'assets/gifs/update_computation.gif',
    }
  }

  createToolbarHandle(): ElementToolbarHandle {
    return {
      name: 'update-computation',
      displayTitle: 'Update Computation',
      svg: 'updateComputationalProcess',
      action: new UpdateComputationalAction(this.init, this.thing),
      gif: 'assets/gifs/update_computation.gif',
    };
  }

}

class UpdateComputationalAction implements ElementAction {

  constructor(private readonly init: InitRappidService,
              private readonly thing: OpmThing) {
  }

  act() {
    const cell = this.init.graph.getCell(this.thing.id);
    if (cell)
      cell.updateComputational(this.init);
  }

}
