import { OpmLogicalThing } from './OpmLogicalThing';
import { OpmVisualObject } from '../VisualPart/OpmVisualObject';
import { linkType, valueType } from '../ConfigurationOptions';
import { OpmLogicalState, OpmLogicalStateEllipsis } from './OpmLogicalState';
import { OpmState } from '../DrawnPart/OpmState';
import { OpmVisualState } from '../VisualPart/OpmVisualState';
import { OpmLink } from '../VisualPart/OpmLink';
import { OpmProceduralRelation } from './OpmProceduralRelation';
import { OpmFundamentalLink } from '../VisualPart/OpmFundamentalLink';
import { util } from 'jointjs';
import uuid = util.uuid;
import { OpmFundamentalRelation } from './OpmFundamentalRelation';
import { EntityType } from '../model/entities.enum';
import { ComputationModule } from './components/computation-module';
import { AliasingModule } from './components/aliasing-module';
import { UnitsTextModule } from './components/units-text-module';
import { ValidationModule } from '../modules/attribute-validation/validation-module';
import { ElementsMap } from '../components/ElementsMap';
import { OpmLogicalElement } from './OpmLogicalElement';
import {OpmVisualEntity} from "../VisualPart/OpmVisualEntity";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalEntity} from "./OpmLogicalEntity";

export class OpmLogicalObject extends OpmLogicalThing<OpmVisualObject> {

  private readonly computationModule = new ComputationModule();
  private readonly aliasModule = new AliasingModule(this.computationModule);
  private readonly unitsModule = new UnitsTextModule(this.computationModule);

  public valuedObjectFor: OpmLogicalObject;

  static logicalCounter = 1;
  static resetLogicalCounter() {
    OpmLogicalObject.logicalCounter = 1;
  }

  private /*readonly*/ states_: Array<OpmLogicalState> = new Array<OpmLogicalState>();
  private readonly ellipsis_: OpmLogicalStateEllipsis;

  constructor(params, model) {
    super(params, model);
    this.ellipsis_ = new OpmLogicalStateEllipsis();
    OpmLogicalObject.logicalCounter++;
    // this.textModule.addTextualModules(this.computation);
    this.textModule.addTextualModules(this.aliasModule);
    this.textModule.addTextualModules(this.unitsModule);
  }

  public removeAllStates(): void {
    this.states_.length = 0;
    this.ellipsis_.visualElements.length = 0;
  }

  // getters and setters

  get computation() {
    return this.computationModule;
  }

  get valueType(): valueType {
    return this.computationModule.valueType;
  }

  set valueType(valueType: valueType) {
    this.computationModule.valueType = valueType;
    // this._valueType = valueType;
  }

  get value(): any {
    return this.computationModule.value;
  }

  set alias(value: any) {
    this.aliasModule.alias = value;
  }

  public setValue(value: any): boolean {
    const validation = this.getValidationModule();
    if (validation.isActive() && validation.validateValue(value) == false) {
      if (this.opmModel.shouldAllowInvalidValueAtExecutionTime() == false) {
        return false;
      }
    }
    this.value = value;
    return true;
  }

  set value(value: any) {
    this.computationModule.value = value;
  }

  get units(): string {
    // TODO: The value NONE should not be special or treted diferently.
    return this.unitsModule.units == 'None' ? '' : this.unitsModule.units;
  }

  set units(units: string) {
    this.unitsModule.units = units;
    // this._units = units;
  }

  get states(): /*ReadonlyArray*/Array<OpmLogicalState> {
    return this.states_.sort((a,b) => this.opmModel.logicalElements.indexOf(a) - this.opmModel.logicalElements.indexOf(b));
  }

  get ellipsis(): Readonly<OpmLogicalStateEllipsis> {
    return this.ellipsis_;
  }

  get alias() {
    let temp = '' + this.text;
    const hasInstance = temp.indexOf('{Instances: ');
    if (hasInstance !== -1) {
      const endInsIdx = temp.indexOf('}', hasInstance);
      const toRemove = temp.slice(hasInstance, endInsIdx + 1);
      temp = temp.replace(toRemove, '');
    }
    const indexOfStartAlias = temp.indexOf('{');
    const indexOfEndAlias = temp.indexOf('}');
    if ((indexOfStartAlias > 0) && (indexOfEndAlias > 0) && (indexOfEndAlias > indexOfStartAlias)) {
      return temp.substring(indexOfStartAlias + 1, indexOfEndAlias).trim();
    }
    return this.aliasModule.alias;
  }

  setParams(params) {
    super.setParams(params);
    if (params?.alias)
      this.alias = params.alias;
  }

  // needed for computational part
  getName() {
    if ((this.valueType === valueType.None) && (this.value === 'None'))
      return this.text;
    else {
      let indexOfStartUnits = this.text.lastIndexOf('[');
      if (indexOfStartUnits <= 0) indexOfStartUnits = this.text.length;
      return this.text.substring(0, indexOfStartUnits).trim();
    }
  }

  public createVisual(params): OpmVisualObject {
    return new OpmVisualObject(params, this);
  }

  public createState(): OpmLogicalState {
    const text = "state" + (this.states_.length + 1);
    const drawn = new OpmState(text); // TODO: Should be changed to a const default value.
    const logical = this.opmModel.logicalFactory(EntityType.State, drawn.getParams()) as OpmLogicalState;

    logical.text = text;
    logical.parent = this;

    this.states_.push(logical);
    this.opmModel.currentOpd.add(logical.visualElements[0]);

    return logical;
  }

  public removeState(state: OpmLogicalState) {
    for (let i = this.states_.length - 1; i >= 0; i--) {
      if (state === this.states_[i]) {
        this.states_.splice(i, 1);
        return;
      }
    }
  }

  updateParams(params) {
    super.updateParams(params);
    this.valueType = params.valueType;
    this.value = params.value;
    this.units = params.units;
    this.alias = params.alias;

    if (params.validation && params.validation.attribute) {
      // TODO: pay attention.
      let stereotypeValidator;
      const stereotypeEquivalentLogical = this.opmModel.getEquivalentLogicalThingFromStereotype(this.equivalentFromStereotypeLID) as any;
      stereotypeValidator = stereotypeEquivalentLogical?.getValidationModule().getValidator();
      this.getValidationModule().setRange(params.validation.attribute.type, params.validation.attribute.range, stereotypeValidator)
    }

    if (params.text)
      this.extractDataFromText(params.text);
  }

  private extractDataFromText(text: string) {
    // alias
    const start = text.lastIndexOf("{");
    const end = text.lastIndexOf("}");
    if (start > 0 && end > 0)
      this.alias = text.substring(start + 1, end);
    this.text = text;
    this.getNameModule().setText(text);
  }

  getParams() {
    const visualElementsParams = [];
    for (let i = 0; i < this.visualElements.length; i++) {
      visualElementsParams.push(this.visualElements[i].getParams());
    }
    const states = [];
    for (let i = 0; i < this.states_.length; i++)
      if (this.states_[i].visualElements.length === 0)
        states.push(this.states_[i].getParams());
    const params = {
      valueType: this.valueType,
      value: this.value,
      units: this.units,
      visualElementsParams: visualElementsParams,
      statesWithoutVisual: states,
      alias: this.alias,
      validation: this.computationModule.validationModule.toJson(),
      valuedObjectForId: this.valuedObjectFor ? this.valuedObjectFor.lid : undefined
    };
    return { ...super.getThingParams(), ...params };
  }

  getParamsFromJsonElement(jsonElement) {
    const params = {
      valueType: (jsonElement.valueType === 'None') ? valueType.None : jsonElement.valueType,
      value: jsonElement.value,
      units: jsonElement.units,
      alias: jsonElement.alias,
      statesWithoutVisual: jsonElement.statesWithoutVisual ? jsonElement.statesWithoutVisual : [],
    };
    return { ...super.getThingParamsFromJsonElement(jsonElement), ...params };
  }

  // TODO: Remove after refactoring OpmModel.
  concatToStates(array: Array<OpmLogicalState>) {
    array.forEach(e => this.states_.push(e));
  }

  public createLogicalAndVisualState(parent: OpmVisualObject): OpmVisualState {
    const state = this.createState();
    //const visual = state.createVisual(parent);
    const visual = state.visualElements[0];
    visual.fatherObject = parent;
    return visual;
  }

  public createVisualState(object: OpmVisualObject, state: OpmLogicalState): OpmVisualState {
    if (!this.states_.find(s => s === state))
      return undefined;
    return state.createVisualState(object);
  }
  get counter() {
    return OpmLogicalObject.logicalCounter;
  }

  getNumberedName(): string {
    return 'Object ' + this.counter;
  }

  public toggleLetter(letter) {
    return letter.toUpperCase();
  }

  public toggleCapitalize(text) {
    for (let i = 0; i < text.length; i++) {
      // capitalize the first letter of the first word and each word after a space or an enter
      if (i === 0) {
        text = this.toggleLetter(text.charAt(i)) + text.substr(i + 1, text.length);
      } else if ((text.charAt(i - 1) === ' ') || (text.charAt(i - 1) === '\n')) {
        text = text.substr(0, i) +
          this.toggleLetter(text.charAt(i)) + text.substr(i + 1, text.length);
      }
    }
    return text;
  }

  public deStating() {
    this.opmModel.logForUndo('Destate ' + this.text);
    const logObjectsofStates = [];
    const logicalFundamentalRelations = [];
    const newVisualsInCurrentOpd = [];
    const cleanParams = this.getParams();
    delete cleanParams['backgroundImageUrl'];
    // create new logical object for every logical state
    for (let j = 0; j < this.states.length; j++) {
      const logObjectOfState = this.opmModel.logicalFactory(EntityType.Object, cleanParams) as OpmLogicalObject;
      logObjectOfState.lid = uuid();
      logObjectOfState.URLarray = this.states[j].URLarray;
      // delete the automatically created visual object
      logObjectOfState.removeVisual(logObjectOfState.visualElements[0]);
      logObjectOfState.text = this.toggleCapitalize(this.states[j].text) + ' ' + this.text;
      logObjectsofStates.push(logObjectOfState);
      const par = {
        linkConnectionType: 1,
        linkType: linkType.Generalization,
      };
      const newLogicLink = new OpmFundamentalRelation(par, this.opmModel, false);
      newLogicLink.removeVisual(newLogicLink.visualElements[0]);
      newLogicLink.sourceLogicalElement = this;
      newLogicLink.targetLogicalElements = [logObjectOfState];
      logicalFundamentalRelations.push(newLogicLink);
      this.opmModel.add(newLogicLink);
    }
    // go over all opds
    for (let i = 0; i < this.opmModel.opds.length; i++) {
      // filter all visual objects related to our logicalObject
      const allVisualElements = this.opmModel.opds[i].visualElements.filter(elm => elm.logicalElement === this);
      for (let k = 0; k < allVisualElements.length; k++) {
        (<OpmVisualObject>allVisualElements[k]).expressAll();
        if ((<OpmVisualObject>allVisualElements[k]).states.length === (<OpmVisualObject>allVisualElements[k]).children.length) {
          (<OpmVisualObject>allVisualElements[k]).height = 60;
          (<OpmVisualObject>allVisualElements[k]).width = 135;
          (<OpmVisualObject>allVisualElements[k]).textHeight = '80%';
          (<OpmVisualObject>allVisualElements[k]).textWidth = '80%';
          (<OpmVisualObject>allVisualElements[k]).refY = 0.5;
          (<OpmVisualObject>allVisualElements[k]).refX = 0.5;
          (<OpmVisualObject>allVisualElements[k]).xAlign = 'middle';
          (<OpmVisualObject>allVisualElements[k]).yAlign = 'middle';
        }
        for (let n = 0; n < (<OpmVisualObject>allVisualElements[k]).states.length; n++) {
          // create new visual object for every visual state
          const newVisualObjectOfVisualState = logObjectsofStates[n].createVisual((<OpmVisualObject>allVisualElements[k]).getParams());
          newVisualObjectOfVisualState.height = 60;
          newVisualObjectOfVisualState.width = 135;
          newVisualObjectOfVisualState.id = uuid();
          newVisualObjectOfVisualState.yPos = (<OpmVisualObject>allVisualElements[k]).yPos + (<OpmVisualObject>allVisualElements[k]).height + newVisualObjectOfVisualState.height * (n) + 15 * (n + 1);
          newVisualObjectOfVisualState.textHeight = '80%';
          newVisualObjectOfVisualState.textWidth = '80%';
          newVisualObjectOfVisualState.refY = 0.5;
          newVisualObjectOfVisualState.refX = 0.5;
          newVisualObjectOfVisualState.xAlign = 'middle';
          newVisualObjectOfVisualState.yAlign = 'middle';
          newVisualObjectOfVisualState.refineeInzooming = undefined;
          newVisualObjectOfVisualState.refineable = undefined;
          newVisualObjectOfVisualState.refineeUnfolding = undefined;
          newVisualObjectOfVisualState.strokeWidth = 2;

          this.opmModel.opds[i].add(newVisualObjectOfVisualState);
          const par = {
            id: uuid(),
            linkConnectionType: 1,
            linkType: linkType.Generalization,
            sourceElementId: (<OpmVisualObject>allVisualElements[k]).id,
            targetElementId: newVisualObjectOfVisualState.id,
          };
          const newLink = new OpmFundamentalLink(par, logicalFundamentalRelations[n]);
          this.opmModel.opds[i].add(newLink);
          // restore all links
          const allLinks = this.opmModel.opds[i].getThingLinks((<OpmVisualObject>allVisualElements[k]).states[n].id);
          const inboundLinks = allLinks.filter(link => ((<OpmLink>link).targetVisualElements[0].targetVisualElement.id
            === (<OpmVisualObject>allVisualElements[k]).children[n].id));
          const outboundLinks = allLinks.filter(link => ((<OpmLink>link).sourceVisualElement.id
            === (<OpmVisualObject>allVisualElements[k]).children[n].id));
          for (let m = 0; m < inboundLinks.length; m++) {
            (<OpmLink>inboundLinks[m]).targetVisualElements[0].targetVisualElement = newVisualObjectOfVisualState;
            (<OpmProceduralRelation>inboundLinks[m].logicalElement).targetLogicalElements = [newVisualObjectOfVisualState.logicalElement];
          }
          for (let m = 0; m < outboundLinks.length; m++) {
            (<OpmLink>outboundLinks[m]).sourceVisualElement = newVisualObjectOfVisualState;
            (<OpmProceduralRelation>outboundLinks[m].logicalElement).sourceLogicalElement = newVisualObjectOfVisualState.logicalElement;
          }
          if (this.opmModel.opds[i] === this.opmModel.currentOpd) {
            newVisualsInCurrentOpd.push([(<OpmVisualObject>allVisualElements[k]), newVisualObjectOfVisualState, newLink, inboundLinks, outboundLinks]);

          }
        }
        const counter = (<OpmVisualObject>allVisualElements[k]).states.length;
        for (let n = 0; n < counter; n++) {
          this.opmModel.remove((<OpmVisualObject>allVisualElements[k]).states[0].id);
          if (this.opmModel.opds[i] === this.opmModel.currentOpd) {
            this.opmModel.currentOpd.remove((<OpmVisualObject>allVisualElements[k]).states[0].id);
          }
          // Following is necessary as the remove above does not delete the VisualState from VisualObject.children
          (<OpmVisualObject>allVisualElements[k]).removeState(<OpmVisualState>(<OpmVisualObject>allVisualElements[k]).states[0]);
        }
      }
    }
    return newVisualsInCurrentOpd;
  }

  isComputational(): boolean {
    return this.computationModule.isActive();
  }

  isTimeDuration(): boolean {
    return false;
  }

  getComputationModule(): ComputationModule {
    return this.computationModule;
  }

  getValidationModule(): ValidationModule {
    return this.computationModule.validationModule;
  }

  hasRange(): boolean {
    return this.computationModule.hasRange();
  }

  isValueTyped(): boolean {
    return this.valuedObjectFor != undefined;
  }

  isBasicThing(): boolean {
    if (this.isValueTyped())
      return false;
    return true;
  }

  removeComputation() {
    this.computationModule.remove();
  }

  setReferencesFromJson(json, map: ElementsMap<OpmLogicalElement<any>>) {
    if (json.validation && json.validation.valueTypeElementId)
      this.getValidationModule().setValueTypeElement(map.get(json.validation.valueTypeElementId) as OpmLogicalObject);
    if (json.valuedObjectForId)
      this.valuedObjectFor = map.get(json.valuedObjectForId) as OpmLogicalObject;
  }

  public getValidationStatus(): 'no-range' | 'value-not-set' | 'value-set-valid' | 'value-set-invalid' {
    const validation = this.getValidationModule();

    const range = validation.isActive();
    const set = this.value !== "value";
    const valid = validation.validateValue(this.value);

    if (range == false)
      return 'no-range';

    if (set == false)
      return 'value-not-set';

    if (valid == false)
      return 'value-set-invalid';

    return 'value-set-valid';
  }

  public isSatisfiedRequirementSetObject(): boolean {
    return this.hiddenAttributesModule.satisfiedRequirementSetModule.isRequirementSetObject;
  }

  public isSatisfiedRequirementObject(): boolean {
    return this.hiddenAttributesModule.satisfiedRequirementSetModule.isRequirementObject;
  }

  public getAncestorExhibitions(ret =  []): Array<OpmLogicalEntity<OpmVisualEntity>> {
    const inLinks = this.getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition);
    if (this.states.length) {
      for (const state of this.states) {
        inLinks.push(...state.getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition));
      }
    }
    for (const link of inLinks) {
      const source = link.sourceLogicalElement as OpmLogicalEntity<OpmVisualEntity>;
      if (ret.includes(source)) {
        continue;
      }
      ret.push(source);
      if (OPCloudUtils.isInstanceOfLogicalState(source)) {
        ret.push(source.getFather());
        source.getFather().getAncestorExhibitions(ret);
      }
      source.getAncestorExhibitions(ret);
    }
    return ret;
  }
}
