import { OpmVisualEntity } from './OpmVisualEntity';
import { OpmLogicalState } from '../LogicalPart/OpmLogicalState';
import { OpmVisualElement } from './OpmVisualElement';
import { EntityType } from '../model/entities.enum';
import { statesArrangement } from '../ConfigurationOptions';
import { OpmLogicalObject } from '../LogicalPart/OpmLogicalObject';
import { OpmVisualObject } from './OpmVisualObject';
import { ElementCommand } from '../components/commands/command';
import { StateCommandsDecider } from '../components/commands/state-decider';

interface Params {
  fatherObjectId: string,
  fill: string,
  height: number,
  id: string,
  refX: number,
  refY: number,
  stateType: string,
  strokeColor: string,
  strokeWidth: string,
  text: string,
  textColor: string,
  textFontFamily: string,
  textFontSize: number,
  textFontWeight: number,
  textHeight: string,
  textWidth: string,
  width: number,
  xAlign: string,
  xPos: number,
  yAlign: string,
  yPos: number,
}

const params: Params = {
  fatherObjectId: undefined,
  fill: '#ffffff',
  height: 30,
  id: "6b7dfc99-e6b3-4d02-8801-73626a4fe025",
  refX: 0.5,
  refY: 0.5,
  stateType: "none",
  strokeColor: "#808000",
  strokeWidth: undefined,
  text: "state3",
  textColor: "#000000",
  textFontFamily: "Arial, helvetica, sans-serif",
  textFontSize: 14,
  textFontWeight: 300,
  textHeight: "80%",
  textWidth: "80%",
  width: 60,
  xAlign: "middle",
  xPos: 0,
  yAlign: "middle",
  yPos: 0,
}

export class OpmVisualState extends OpmVisualEntity {

  get type(): EntityType {
    return EntityType.State;
  }

  constructor(params, logicalElement) {
    super(params, logicalElement);
    if (this.fatherObject && this.fatherObject.children) {
      // need this check for clone(). in case a state already exist in children array it will not be added again
      const sameChild = this.fatherObject.children.filter(element => (element.id === this.id))[0];
      if (!sameChild) this.fatherObject.children.push(this);
    }
  }

  getParams() {
    return super.getEntityParams();
  }

  getParamsFromJsonElement(jsonElement) {
    return super.getEntityParamsFromJsonElement(jsonElement);
  }

  setDefaultStyleFields() {
    super.setDefaultStyleFields();
    this.strokeColor = '#808000';
  }

  clone() {
    const clone = (<OpmLogicalState>this.logicalElement).createVisualState(this.fatherObject, this.getParams());
    clone.updateParams(this.getParams());
    clone.setNewUUID();
    return clone;
  }

  isInzoomed() {
    return false;
  }

  isUnfolded() {
    return false;
  }

  isComputational() {
    return false;
  }

  isFatherComputational(): boolean {
    const father = this.fatherObject as OpmVisualObject;
    return father.isComputational();
  }

  public suppress(): boolean {
    if (this.canBeSuppressed()) {
      this.fatherObject.updateLastStatesOrder();
      this.fatherObject.removeState(this);
      this.fatherObject.createEllipsis();
      (<OpmLogicalState>this.logicalElement).removeVisual(this);

      return true;
    }
    return false;
  }

  canBeSuppressed(): boolean {
    // Should be: this.attached.length === 0
    return (/*!this.logical.valued && */(<OpmLogicalState>this.logicalElement).opmModel.currentOpd.getThingLinks(this.id).length === 0);
    // return (/*!this.logical.valued && */(<OpmLogicalState>this.logicalElement).opmModel.currentOpd.getThingLinks(this.id)
    //   .filter( l => (l as OpmLink).visible !== false).length === 0);
  }

  public new_val: string = ''; // For computation show

  getHaloHandles() {
    if (this.isValueTyped()) {
      return [];
    } else if (this.fatherObject.logicalElement.isComputational() && (this.isTimeDuration)) {
      return ['styling', 'timeDurationFunction'];
    } else {
      return [...super.getHaloHandles(), 'suppress', 'styling', 'timeDurationFunction'];
    }
  }

  removeAction(): { removed: boolean, elements?: ReadonlyArray<OpmVisualElement> } {
    const logical = this.logicalElement as OpmLogicalState;

    const visuals: ReadonlyArray<OpmVisualState> = [].concat(logical.visualElements);
    for (const visual of visuals)
      if (visual.canBeRemoved() == false)
        return { removed: false }

    const elements: Array<OpmVisualElement> = new Array<OpmVisualElement>();
    for (const visual of visuals)
      elements.push(...visual.remove());

    logical.parent.removeState(logical);
    logical.opmModel.removeLogicalElement(logical);

    return { removed: true, elements };
  }

  canBeRemoved(): boolean {
    return canBeRemoved(this, this.logicalElement as OpmLogicalState);
  }

  remove(): ReadonlyArray<OpmVisualElement> {
    const ret = super.remove();
    if (this.fatherObject.states.length === 1)
      this.fatherObject.statesArrangement = statesArrangement.Bottom;
    return [].concat(ret).concat(remove(this, this.logicalElement as OpmLogicalState));
  }

  setReferencesOnCreate() {
  }

  private isParentComputational() {
    const logical = this.logicalElement as OpmLogicalState;
    const parent = logical.parent as OpmLogicalObject;
    return parent && parent.isComputational();
  }

  isFoldedUnderThing() {
    return { isFolded: false };
  }

  hasRange(): boolean {
    const father = this.fatherObject as OpmVisualObject;
    return father.hasRange();
  }

  isValueTyped(): boolean {
    const father = this.fatherObject as OpmVisualObject;
    return father.isValueTyped();
  }

  public shouldChangeCondition(): boolean {
    return (this.isParentComputational() == false && this.isValueTyped() == false);
  }

  public getHaloCommands(): Array<ElementCommand> {
    return [];
  }

  public getCommandsDecider(): StateCommandsDecider {
    return new StateCommandsDecider();
  }

  public canModifyText(): boolean {
    return (this.isValueTyped() == false);
  }

  public getValidationView(): { color?: string } {
    const logical = this.logicalElement as OpmLogicalState;
    const status = this.getValidationStatus();

    if (logical.opmModel.shouldAllowInvalidValueAtDesignTime()) {
      if (status.status === 'value-set-invalid')
        return { color: '#FA8072'}; // Red
      if (status.status === 'value-set-valid')
        return { color: '#90EE90' }; // Green
      if (status.status === 'value-not-set')
        return { color: '#ADD8E6' }; // Blue
    }

    return { };
  }

  public getValidationStatus(): { status: 'no-range' | 'value-not-set' | 'value-set-valid' | 'value-set-invalid' } {
    const logical = this.logicalElement as OpmLogicalState;
    const father = logical.getFather() as OpmLogicalObject;
    const validation = father.getValidationModule();

    const range = validation.isActive();
    const set = !!(father.value && father.value !== "value");
    const valid = validation.validateValue(father.value);

    if (range == false)
      return { status: 'no-range' };

    if (set == false)
      return { status: 'value-not-set' }

    if (valid == false)
      return { status: 'value-set-invalid' };

    return { status: 'value-set-valid' };
  }

}

function canBeRemoved(visual: OpmVisualState, logical: OpmLogicalState): boolean {
  return !(logical.parent.isComputational()) && !logical.belongsToFatherModelId &&
    !logical.parent.visualElements.find(v => v.belongsToSubModel || v.belongsToFatherModelId || v.protectedFromBeingChangedBySubModel);
}

function remove(visual: OpmVisualState, logical: OpmLogicalState): ReadonlyArray<OpmVisualElement> {
  visual.fatherObject.removeState(visual);
  logical.removeVisual(visual);

  return new Array<OpmVisualElement>();
}
