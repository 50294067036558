import {Directive, ElementRef, HostListener, Input} from "@angular/core";

@Directive({
  standalone: true,
  selector: '[fontHighlight]',
})
export class FontHighlightDirective {

  private highlightColor = '#78A8F1';

  constructor(private el: ElementRef) {}

  @HostListener('mouseenter') onMouseEnter() {
    this.highlight();
  }

  @HostListener('mouseleave') onMouseLeave() {
    this.unhighlight();
  }

  private highlight() {
    this.el.nativeElement.style.background = this.highlightColor;
  }

  private unhighlight() {
    this.el.nativeElement.style.background = null;
  }
}
