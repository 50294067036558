import {Component, Inject, Optional} from '@angular/core';
import {
  getInitRappidShared,
  OPCloudUtils,
  validationAlert
} from '../../configuration/rappidEnviromentFunctionality/shared';
import { OpmLogicalThing } from '../../models/LogicalPart/OpmLogicalThing';
import { OpmVisualThing } from '../../models/VisualPart/OpmVisualThing';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OpmLogicalElement} from "../../models/LogicalPart/OpmLogicalElement";
import {OpmVisualElement} from "../../models/VisualPart/OpmVisualElement";
import {moveItemInArray} from "@angular/cdk/drag-drop";
import {OpmEntity} from "../../models/DrawnPart/OpmEntity";

@Component({
  selector: 'opcloud-save-url-dialog',
  templateUrl: 'saveURL.html',
  styleUrls: ['saveURL.css']
})

export class SaveURLComponent {
  modelName; // Used for showing to the user the default model name when choosing a name for the pdf file.
  element: OpmLogicalElement<OpmVisualElement>;
  urlArray;
  constructor(
    @Optional() public dialogRef: MatDialogRef<SaveURLComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any) {
    // getting the element from the path
    this.element = getInitRappidShared().opmModel.getLogicalElementByVisualId(data.Element || getInitRappidShared().selectedElement.id) as OpmLogicalElement<OpmVisualElement>;
    if (OPCloudUtils.isInstanceOfDrawnEntity(getInitRappidShared().selectedElement))
      getInitRappidShared().selectedElement.closeTextEditor(getInitRappidShared()); // closing text editor in order to enable writing immediately on the floating window
    this.urlArray = this.element.URLarray.map(item => JSON.parse(JSON.stringify(item)));
    if (this.urlArray.length === 0) {
      this.plusButtonFunc();
    }
  }
  public saveURL() {
    if (this.urlArray.find(item => item.url.trim() === '')) {
      validationAlert('Empty URL links are not allowed.');
      return;
    }
    // function that saves the URL sets that were filled
    // the rest of the function is about final saving- the URL's can be changed by the user after first adding.
    const form = document.getElementsByName('URLSet'); // form is getting the set of URL's from HTML file
    if (!form)// checking it exsists
      return; // if not exists - return
    // const myform = form[0] as HTMLFormElement;//
    let urlData = []; // temp will save the icon, link, and description of each URL in the set
    const icons = document.getElementsByName('TypeOfURL'); // getting the type of the URL from HTML
    const links = document.getElementsByName('link'); // getting the URL link from HTML
    const descriptions = document.getElementsByName('description'); // getting the description from HTML
    // for each URL set(icon, URL and description) that appear in the window, save all the fields into temp
    for (let i = 0; i < icons.length; i++) {
      urlData.push({ icon: (<any>icons[i]).value, desc: (<any>descriptions[i]).value, link: (<any>links[i]).value });
    }
    urlData = urlData.filter(r => r.link !== '' && r.link !== 'http://');  // filtering unvalid inputs
    while (this.urlArray.length > 0) { // deleting old values of URLset
      this.urlArray.pop();
    }
    for (const item of urlData) { // updating new values of URLset
      this.urlArray.push({ iconType: item.icon, url: item.link, description: item.desc });
    }
    this.element.URLarray = this.urlArray;
    this.element.visualElements.forEach(vis => {
      if (getInitRappidShared().graph.getCell(vis.id))
        getInitRappidShared().graph.getCell(vis.id).updateURLArray();
    });
    // updates the URLarray
    // validationAlert(' The URL was saved'); // shows this massage on the screen
    for (const vis of this.element.visualElements) {
      const cell = getInitRappidShared().graph.getCell(vis.id);
      if (cell) {
        cell.urlsUtils.resetCounter();
      }
    }
    this.close();
  }
  // adding another index to the array while user press plus
  plusButtonFunc() {
    this.urlArray.push({ iconType: 'picture', url: 'http://', description: '' });
  }

  delete(item) { // delete a specific URL while user press on X
    const index = this.urlArray.indexOf(item); // get the specific index to delete
    if (index === 0 && this.urlArray.length === 1) {
      item.url = 'http://';
      item.description = '';
    } else if (index > -1)
      this.urlArray.splice(index, 1); // delete the index from the array
  }

  inputChange(item, $event) {
    item.url = $event.target.value;
  }

  showPreview(url: string) { // open a preview window of the URL link
    if (url.trim() !== '') {
      try {
        window.open(url, '', 'height=200, width=400, scrollbars=no');
      } catch (e) {
        validationAlert('Invalid URL.');
      }
    }

  }

  close() {
    // this.element.URLarray = this.element.URLarray.filter(item => item.url !== '' && item.url !== 'http://');
    // if (this.element.URLarray.length === 0)
    //   this.element.URLarray.push({ iconType: 'picture', url: 'http://', description: ' ' });
    this.dialogRef.close();
  }

  onStartDrag(event) {
    if (!!event.touches)
      return;
    event.preventDefault();
    const that = this;
    window.onmousemove = function (e) { that.moveDrag(e) };
    window.onmouseup = function (e) { that.endDrag(e) };
  }

  endDrag(event) {
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
  }

  moveDrag(event) {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    // clone.cloned.style.top = (event.clientY - 30 + scrollTop) + "px";
    // clone.cloned.style.left = (event.clientX - 67) + "px";
    this.dialogRef.updatePosition({ left: (event.clientX - 440) + "px", top: (event.clientY - 30 + scrollTop) + "px" })
  }

  dropItem($event) {
    const prevIndex = $event.previousIndex;
    const newIndex = $event.currentIndex;
    if (prevIndex === newIndex) {
      return;
    }
    moveItemInArray(this.urlArray, prevIndex, newIndex);
  }
}
