import {OpmVisualThing} from "../VisualPart/OpmVisualThing";
import {OpmVisualObject} from "../VisualPart/OpmVisualObject";
import {EntityType} from "../model/entities.enum";
import {OpmLogicalObject} from "../LogicalPart/OpmLogicalObject";
import {linkConnectionType, linkType, valueType} from "../ConfigurationOptions";
import {OpmFundamentalLink} from "../VisualPart/OpmFundamentalLink";
import {OpmModel} from "../OpmModel";
import {OpmLogicalState} from "../LogicalPart/OpmLogicalState";
import {HiddenAttributeInterface, SatisfiedRequirementSetJSON} from "./hidden-attributes-interfaces";
import {OpmVisualElement} from "../VisualPart/OpmVisualElement";
import {OPCloudUtils} from "../../configuration/rappidEnviromentFunctionality/shared";

// the manager of the whole requirements data (if active, and using the structural classes).
export class SatisfiedRequirementSetModule implements HiddenAttributeInterface {

  private _requirementSet: SatisfiedRequirementSet;
  private _isRequirementObject: boolean;
  private _isRequirementSetObject: boolean;

  constructor(params: SatisfiedRequirementSetJSON, model: OpmModel) {
    this._isRequirementObject = false;
    this._isRequirementSetObject = false;
    if (params)
      this.fromJson(params, model);
  }

  set isRequirementObject(value: boolean) {
    this._isRequirementObject = value;
  }

  set isRequirementSetObject(value: boolean) {
    this._isRequirementSetObject = value;
  }

  get isRequirementObject(): boolean {
    return this._isRequirementObject;
  }

  get isRequirementSetObject() {
    return !!this._isRequirementSetObject;
  }

  removeSingleRequirement(lid: string): Array<OpmVisualThing> {
    const removedVisuals = [];
    if (this.getRequirementsSet()) {
      const set =  this.getRequirementsSet();
      const removedElements = set.removeSingleRequirement(lid);
      removedVisuals.push(...removedElements);
      if (this.getRequirementsSet().getAllRequirements().length === 0) {
          if (this.getRequirementsSet().getRequirementSetObject()) {
            const setObject = this.getRequirementsSet().getRequirementSetObject();
            for (let i = setObject.logicalElement.visualElements.length - 1; i >= 0 ; i--) {
              const removed = setObject.logicalElement.visualElements[i].remove();
              removedVisuals.push(...removed);
            }
          }
          this._requirementSet = undefined;
      }
    }
    return removedVisuals;
  }

  getRequirementsSet(): SatisfiedRequirementSet {
    return this._requirementSet;
  }

  toggleAttribute(shouldHideSetObject = false): Array<OpmVisualElement> {
    return this._requirementSet.toggleAttribute(shouldHideSetObject);
  }

  get requirementSet(): SatisfiedRequirementSet {
    return this._requirementSet;
  }

  toJson(): SatisfiedRequirementSetJSON {
    const hasRequirementsSet = !!this.getRequirementsSet();
    return {
      isRequirementObject: this.isRequirementObject,
      isRequirementSetObject: this.isRequirementSetObject,
      logicalRequirementSetObjectLID: hasRequirementsSet ? this.getRequirementsSet().getSetObjectLID() : null,
      ownerLID: hasRequirementsSet ? this.getRequirementsSet().getOwnerLID() : null,
      setObjectPos: this.getRequirementsSet()?.lastPosition,
      requirements:  hasRequirementsSet ? this.getRequirementsSet().getAllRequirements().map(req => {
        return {
          reqId: req.getRequirementObjectLID(),
          lastPosition: req.lastPosition
        }
      }) : null,
    }
  }

  fromJson(json: SatisfiedRequirementSetJSON, model: OpmModel) {
    this.isRequirementObject = json.isRequirementObject;
    this.isRequirementSetObject = json.isRequirementSetObject;
    if (json.requirements) {
      this._requirementSet = new SatisfiedRequirementSet(model);
      this._requirementSet.fromJson(json);
    }
  }

  createSatisfiedRequirementSet(owner: OpmVisualThing) {
    this._requirementSet = new SatisfiedRequirementSet(owner.logicalElement.opmModel);
    this._requirementSet.create(owner);
  }

  addRequirement(owner: OpmVisualThing) {
    (<any>owner.logicalElement).hiddenAttributesModule.satisfiedRequirementSetModule.getRequirementsSet().addRequirement();
  }

  hideSingleRequirement(visual: OpmVisualThing) {
    const requirement = this._requirementSet.getAllRequirements().find(req => req.getRequirementObjectLID() === visual.logicalElement.lid);
    if (requirement)
      requirement.backupLastPosition();
    visual.remove();
  }
}

// represents each requirements *SET* object (the one which all the requirements connected to).
export class SatisfiedRequirementSet {

  private readonly requirements: Array<SatisfiedRequirement>;
  private logicalRequirementSetObjectLID: string;
  private ownerLID: string;
  private model: OpmModel;
  public lastPosition;

  constructor(model: OpmModel) {
    this.requirements = [];
    this.lastPosition = {};
    this.updateModel(model);
  }

  updateModel(updated: OpmModel) {
    this.model = updated;
  }

  create(owner: OpmVisualThing) {
    this.ownerLID = owner.logicalElement.lid;
    const ret = owner.logicalElement.opmModel.createToScreen(EntityType.Object);
    const requirementsSetObject = ret.visual as OpmVisualObject;
    requirementsSetObject.setDefaultStyleFields();
    this.logicalRequirementSetObjectLID = requirementsSetObject.logicalElement.lid;
    ret.logical.setText('Satisfied Requirement Set');
    (<OpmLogicalObject>ret.logical).hiddenAttributesModule.satisfiedRequirementSetModule.isRequirementSetObject = true;
    this.connectOwnerToRequirementSetObject();
    this.setInitialPositionAndSize(owner, requirementsSetObject);
    this.copyRequirementsSetObjectToRequirementsOpd(owner, requirementsSetObject);
    if (owner.fatherObject && OPCloudUtils.isInstanceOfVisualThing(owner))
      requirementsSetObject.fatherObject = owner.fatherObject;
    this.addRequirement();
  }

  getSetObjectLID(): string {
    return this.logicalRequirementSetObjectLID;
  }

  updateSetObjectLID(lid: string) {
    this.logicalRequirementSetObjectLID = lid;
  }

  getOwnerLID(): string {
    return this.ownerLID;
  }

  getAllRequirements(): Array<SatisfiedRequirement> {
    return this.requirements;
  }

  getOwnerAtCurrentOpd(): OpmVisualThing {
    const logical = this.model.getLogicalElementByLid(this.ownerLID);
    return this.model.currentOpd.getVisualElementByLogical(logical) as OpmVisualThing;
  }

  updateOwnerLID(lid: string) {
    this.ownerLID = lid;
  }

  copyRequirementsSetObjectToRequirementsOpd(owner: OpmVisualThing, requirementsSetObject: OpmVisualObject) {
    const model = owner.logicalElement.opmModel;
    const currentOpd = model.currentOpd;
    const opd = model.getOrCreateRequirementsOpd();
    model.currentOpd = opd;
    const ownerCopy = owner.copyToOpd(opd);
    const setObjectCopy = requirementsSetObject.copyToOpd(opd);
    model.links.connect(ownerCopy, setObjectCopy, { type: linkType.Exhibition, connection: linkConnectionType.systemic });
    model.currentOpd = currentOpd;
  }

  setInitialPositionAndSize(owner: OpmVisualThing, requirementsSetObject: OpmVisualObject) {
    requirementsSetObject.xPos = owner.xPos;
    requirementsSetObject.yPos = owner.yPos + 150;
    requirementsSetObject.width = 130;
    requirementsSetObject.height = 65;
  }

  toggleAttribute(shouldHideSetObject = false): Array<OpmVisualElement> {
    const removed = [];
    const missingRequirements = this.requirements.filter(req => !req.getRequirementObject());
    if (missingRequirements.length === 0) {
      for (const req of this.requirements) {
        removed.push(...req.removeRequirementObject());
      }
      if (shouldHideSetObject) {
        this.backupLastPosition();
        const removedSetObject = this.getRequirementSetObject()?.remove();
        if (removedSetObject)
          removed.push(removedSetObject);
      }
    } else {
      this.restoreAttribute();
    }
    return removed;
  }

  restoreAttribute() {
    let requirementsSetObject = this.getRequirementSetObject();
    const lastPos = this.lastPosition[this.model.currentOpd.id];
    if (!requirementsSetObject) {
      const logical = this.model.getLogicalElementByLid(this.logicalRequirementSetObjectLID);
      requirementsSetObject = (<OpmVisualThing>logical.visualElements[0]).copyToOpd(this.model.currentOpd) as OpmVisualObject;
      if (this.getOwnerAtCurrentOpd().fatherObject) {
        requirementsSetObject.fatherObject = this.getOwnerAtCurrentOpd().fatherObject;
      }
      if (lastPos) {
        requirementsSetObject.xPos = lastPos.xPos;
        requirementsSetObject.yPos = lastPos.yPos;
      }
    }
    if (!this.getOwnerAtCurrentOpd().getLinksWith(requirementsSetObject).outGoing.find(l => l.type === linkType.Exhibition)) {
      const link = this.model.links.connect(this.getOwnerAtCurrentOpd(), requirementsSetObject, { type: linkType.Exhibition, connection: linkConnectionType.systemic }) as OpmFundamentalLink;
      if (link && lastPos?.trianglePos)
        link.setSymbolPos(lastPos.trianglePos[0], lastPos.trianglePos[1]);
    }
    for (const req of this.requirements) {
      req.restoreRequirement(this.model, requirementsSetObject);
    }
  }

  addRequirement(): SatisfiedRequirement {
    let requirementsSetObject = this.getRequirementSetObject() as OpmVisualObject;
    if (!requirementsSetObject) {
        this.toggleAttribute(); // closes all requirements
        this.toggleAttribute(); // opens all requirements and created the requirements set object.
        requirementsSetObject = this.getRequirementSetObject();
    }
    const requirement = new SatisfiedRequirement(this.model);
    requirement.create(requirementsSetObject, this.requirements.length + 1);
    this.requirements.push(requirement);
    this.copyRequirementsObjectToRequirementsOpd(requirement);
    const logicalOwner = this.model.getOwnerOfRequirementSetObjectByLID(this.getRequirementSetObject().logicalElement.lid);
    const owner = this.model.currentOpd.visualElements.find(v => v.logicalElement.lid === logicalOwner?.lid) as OpmVisualThing;
    if (owner?.fatherObject && OPCloudUtils.isInstanceOfVisualThing(owner))
      requirement.getRequirementObject().fatherObject = owner.fatherObject;
    return requirement;
  }

  copyRequirementsObjectToRequirementsOpd(requirement: SatisfiedRequirement) {
    const model = requirement.getRequirementObject().logicalElement.opmModel;
    const requirementObject = requirement.getRequirementObject();
    const owner = requirementObject.getLinks().inGoing[0].source as OpmVisualThing;
    const currentOpd = model.currentOpd;
    const opd = model.getOrCreateRequirementsOpd();
    model.currentOpd = opd;
    const source = opd.getVisualElementByLogical(owner.logicalElement) as OpmVisualThing;
    const targetCopy = requirementObject.copyToOpd(opd);
    model.links.connect(source, targetCopy, { type: linkType.Aggregation, connection: linkConnectionType.systemic });
    model.currentOpd = currentOpd;
  }

  connectOwnerToRequirementSetObject(): OpmFundamentalLink {
    const requirementsSetObject = this.getRequirementSetObject();
    return this.model.links.connect(this.getOwnerAtCurrentOpd(), requirementsSetObject, {type: linkType.Exhibition, connection: linkConnectionType.systemic}) as OpmFundamentalLink;
  }

  getRequirementSetObject(): OpmVisualObject {
    const logical = this.model.getLogicalElementByLid(this.logicalRequirementSetObjectLID);
    return this.model.currentOpd.getVisualElementByLogical(logical) as OpmVisualObject;
  }

  removeRequirementsSetObject() {
    this.getRequirementSetObject()?.remove();
  }

  fromJson(json: SatisfiedRequirementSetJSON) {
    this.ownerLID = json.ownerLID;
    this.logicalRequirementSetObjectLID = json.logicalRequirementSetObjectLID;
    for (const reqObjectData of json.requirements) {
      const req = new SatisfiedRequirement(this.model);
      req.setLogicalRequirementObjectLID(reqObjectData.reqId);
      req.lastPosition = reqObjectData.lastPosition || {};
      this.requirements.push(req);
    }
    if (json.setObjectPos)
      this.lastPosition = json.setObjectPos;
  }

  removeSingleRequirement(lid: string): ReadonlyArray<OpmVisualElement> {
    const reqToRemove = this.requirements.find(req => req.getRequirementObjectLID() === lid);
    if (reqToRemove)
      this.requirements.splice(this.requirements.indexOf(reqToRemove), 1);

    const removed = [];
    const logical = this.model.getLogicalElementByLid(lid) as OpmLogicalObject;
    if (logical?.getStereotype())
      this.model.removeStereotype(logical.visualElements[0] as OpmVisualThing);
    const visuals = logical?.visualElements || [];
    for (let i = visuals.length - 1 ; i >=0 ; i--)
       if (visuals[i])
        removed.push(...visuals[i].remove());

    this.updateRequirementsNumberingAfterRemoval();

    return removed;
  }

  updateRequirementsNumberingAfterRemoval() {
    for (let i = 0 ; i < this.requirements.length; i++) {
      const logical = this.model.getLogicalElementByLid(this.requirements[i].getRequirementObjectLID());
      (<OpmLogicalObject>logical).setText('Satisfied Requirement #' + (i + 1));
    }
  }

  backupLastPosition() {
    if (this.getRequirementSetObject()) {
      this.lastPosition[this.model.currentOpd.id] = {
        xPos: this.getRequirementSetObject().xPos,
        yPos: this.getRequirementSetObject().yPos,
        trianglePos: (<OpmFundamentalLink>this.getRequirementSetObject()?.getLinks().inGoing[0])?.getSymbolPos()
      }
    }
  }
}

// represents each requirement object
export class SatisfiedRequirement {

  private model: OpmModel;
  private logicalRequirementObjectLID: string;
  public lastPosition;

  constructor(model: OpmModel) {
    this.updateModel(model);
    this.lastPosition = {};
  }

  updateModel(updated: OpmModel) {
    this.model = updated;
  }

  create(requirementSetObject: OpmVisualObject, index: number) {
    const requirementObject = this.model.createToScreen(EntityType.Object).visual as OpmVisualObject;
    this.logicalRequirementObjectLID = requirementObject.logicalElement.lid;
    const logical = requirementObject.logicalElement as OpmLogicalObject;
    logical.hiddenAttributesModule.satisfiedRequirementSetModule.isRequirementObject = true;
    this.setInitialPositionAndSize(requirementSetObject, requirementObject, index);
    this.model.setAsComputational(requirementObject);
    logical.setText('Satisfied Requirement #' + index);
    const value = 'Requirement name or ID';
    logical.value = value;
    logical.valueType = valueType.String;
    const state = requirementObject.states[0];
    const logicalState = (<OpmLogicalState>state.logicalElement);
    if (logicalState.isAutoFormat())
      logicalState.toggleAutoFormat();
    logicalState.setText(value);
    state.width = 120;
    state.height = 45;
    state.xPos = requirementObject.xPos + (requirementObject.width - state.width) / 2;
    state.yPos = requirementObject.yPos + requirementObject.height - 5;
    this.connectRequirementSetObjectToRequirementObject(requirementSetObject, requirementObject);
  }

  setLogicalRequirementObjectLID(value: string) {
    this.logicalRequirementObjectLID = value;
  }

  getRequirementObjectLID() {
    return this.logicalRequirementObjectLID;
  }

  setInitialPositionAndSize(requirementSetObject: OpmVisualObject, requirementObject: OpmVisualObject, index: number) {
    requirementObject.xPos = requirementSetObject.xPos;
    requirementObject.yPos = requirementSetObject.yPos + 100 + 150 * (index - 1);
    requirementObject.width = 215;
    requirementObject.height = 100;
    requirementObject.refX = 0.5;
    requirementObject.refY = 0.2;
  }

  connectRequirementSetObjectToRequirementObject(requirementSetObject: OpmVisualObject, requirementObject: OpmVisualObject): OpmFundamentalLink {
    const model = requirementSetObject.logicalElement.opmModel;
    const link =  model.links.connect(requirementSetObject, requirementObject, {type: linkType.Aggregation, connection: linkConnectionType.systemic}) as OpmFundamentalLink;
    link.setSymbolPos(requirementSetObject.xPos - 45, requirementSetObject.yPos + 60);
    return link;
  }

  getRequirementObject(): OpmVisualObject {
    const logical = this.model.getLogicalElementByLid(this.logicalRequirementObjectLID);
    return this.model.currentOpd.getVisualElementByLogical(logical) as OpmVisualObject;
  }

  removeRequirementObject(): Array<OpmVisualElement> {
    this.backupLastPosition();
    const removed = this.getRequirementObject()?.remove() || [];
    return [...removed];
  }

  restoreRequirement(model: OpmModel, requirementsSetObject: OpmVisualObject) {
    let requirementObject = this.getRequirementObject();
    const lastPos = this.lastPosition[model.currentOpd.id];
    if (!requirementObject) {
      const logical = this.model.getLogicalElementByLid(this.logicalRequirementObjectLID);
      requirementObject = (<OpmVisualObject>logical.visualElements[0]).copyToOpd(model.currentOpd) as OpmVisualObject;
      requirementObject.resetColors();
      const logicalOwner = this.model.getOwnerOfRequirementSetObjectByLID(requirementsSetObject.logicalElement.lid);
      const owner = this.model.currentOpd.visualElements.find(v => v.logicalElement.lid === logicalOwner?.lid) as OpmVisualThing;
      if (owner?.fatherObject && OPCloudUtils.isInstanceOfVisualThing(owner))
        requirementObject.fatherObject = owner.fatherObject;
      if (lastPos) {
        requirementObject.xPos = lastPos.xPos;
        requirementObject.yPos = lastPos.yPos;
      }
    }
    const link = model.links.connect(requirementsSetObject, requirementObject, { type: linkType.Aggregation, connection: linkConnectionType.systemic }) as OpmFundamentalLink;
    if (link && lastPos?.trianglePos)
      link.setSymbolPos(lastPos.trianglePos[0], lastPos.trianglePos[1]);
  }

  backupLastPosition() {
    if (this.getRequirementObject()) {
      this.lastPosition[this.model.currentOpd.id] = {
        xPos: this.getRequirementObject().xPos,
        yPos: this.getRequirementObject().yPos,
        trianglePos: (<OpmFundamentalLink>this.getRequirementObject()?.getLinks().inGoing[0])?.getSymbolPos()
      }
    }
  }
}
