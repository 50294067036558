// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div {
  position: relative;
  width: 453px;
  left: 50px;
  top: 50px;
}

::ng-deep .mat-form-field-underline {
  display: none;
}

.header {
  color: #1A3763;
  text-align: center;
  padding-bottom: 30px;
}

#whole #modelTriplets {
  display: grid;
  justify-content: center;
  width: 100%;
  padding-bottom: 40px;
}

.Btn {
  color: #1A3763;
  opacity: 60%;
  font-weight: 500;
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 4px;
  height: 36px;
  margin-left: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/Settings/model-analysis-tools/model-analysis-tools.component.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;EACZ,UAAU;EACV,SAAS;AACX;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,oBAAoB;AACtB;;AAEA;EACE,aAAa;EACb,uBAAuB;EACvB,WAAW;EACX,oBAAoB;AACtB;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;EACZ,iBAAiB;AACnB","sourcesContent":[".div {\n  position: relative;\n  width: 453px;\n  left: 50px;\n  top: 50px;\n}\n\n::ng-deep .mat-form-field-underline {\n  display: none;\n}\n\n.header {\n  color: #1A3763;\n  text-align: center;\n  padding-bottom: 30px;\n}\n\n#whole #modelTriplets {\n  display: grid;\n  justify-content: center;\n  width: 100%;\n  padding-bottom: 40px;\n}\n\n.Btn {\n  color: #1A3763;\n  opacity: 60%;\n  font-weight: 500;\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 4px;\n  height: 36px;\n  margin-left: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
