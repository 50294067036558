import {Component, DoCheck, OnDestroy} from '@angular/core';

import { GraphService } from "../../../../rappid-components/services/graph.service";
import { InitRappidService } from "../../../../rappid-components/services/init-rappid.service";
import { OpmLogicalThing } from '../../../../models/LogicalPart/OpmLogicalThing';

import { createDrawnEntity } from "../../../../configuration/elementsFunctionality/graphFunctionality";
import { OpmThing } from '../../../../models/DrawnPart/OpmThing';

import { OpmVisualObject } from '../../../../models/VisualPart/OpmVisualObject';
import { OpmVisualThing } from '../../../../models/VisualPart/OpmVisualThing';
import { SearchItemsDialogComponent } from '../../../../dialogs/search-items-dialog/search-items-dialog.component';
import {
  getShowMapButton,
  OPCloudUtils,
  setShowMapButton,
  validationAlert
} from '../../../../configuration/rappidEnviromentFunctionality/shared';
import { OpmOpd } from "../../../../models/OpmOpd";
import { OpmImage } from "../../../../models/OpmImage";
import { OpmImageLink } from "../../../../models/OpmImageLink";
import {linkType} from "../../../../models/ConfigurationOptions";
import {ExhibitionLink} from "../../../../models/DrawnPart/Links/ExhibitionLink";
import {OpmLink} from "../../../../models/VisualPart/OpmLink";
import {OpmDefaultLink} from "../../../../models/DrawnPart/Links/OpmDefaultLink";
import {BlankLink} from "../../../../models/DrawnPart/Links/BlankLink";
import {Subscription} from "rxjs/Subscription";

@Component({
  selector: 'opcloud-list-logical',
  templateUrl: './list-logical.component.html',
  styleUrls: ['./list-logical.component.css']
})
export class ListLogicalComponent implements OnDestroy {
  static instances = [];
  optionsMenu = false;
  selectMenu = false;
  addVObject = true;
  addVProcess = true;
  clearSearch = false;
  list;
  constList;
  differ;
  currentOpd;
  showType: string = OpmLogicalThing.name;
  searchString = "";
  lastKnownLength: number;
  wasListCut = false;
  cutIndex = 1;
  private lastListLength = 0;
  private readonly maxItemsPerPage = 100;
  private sub1: Subscription;
  private sub2: Subscription;
  private sub3: Subscription;

  constructor(private graphService: GraphService, private initRappidService: InitRappidService) {
    this.constList = this.initRappidService.opmModel.getAllBasicThings();
    this.lastKnownLength = this.initRappidService.opmModel.getAllBasicThings().length;
    this.list = this.constList;
    const that = this;
    ListLogicalComponent.instances.push(this);
    this.initRappidService.graph.on('add', this.onGraphAdd.bind(this), 'onGraphAdd');
    this.initRappidService.graph.on('remove', this.onGraphRemove.bind(this), 'onGraphRemove');

    this.sub1 = this.initRappidService.elementTextChange$.subscribe(elements => {
      if (that.initRappidService.showDraggableThings) {
        this.updateTextList(elements);
      }
    });
    this.sub2 = this.initRappidService.criticalChanges$.subscribe(updateAll => {
      if (that.initRappidService.showDraggableThings) {
        this.updateListChecker();
        if (updateAll) {
          this.updateTextList();
          this.updateList();
        }
      }
    });
    this.sub3 = this.initRappidService.modelService.modelChange$.subscribe(change => {
      if (that.initRappidService.showDraggableThings) {
        this.updateListChecker();
        this.updateTextList();
      }
    });
  }

  onGraphAdd(cell) {
    if (this.initRappidService.isRendering || this.initRappidService.graph.hasActiveBatch('undoredo')) {
      return;
    }
    if (!this.initRappidService.showDraggableThings) {
      return;
    }
    if (cell instanceof OpmThing) {
      this.updateTextList([cell.getVisual()?.logicalElement]);
      this.updateListChecker();
    } else if (cell instanceof ExhibitionLink) {
      const visualLink = cell.getVisual() as OpmLink;
      this.updateTextList([visualLink?.source?.logicalElement, visualLink?.target?.logicalElement]);
    }
  }

  onGraphRemove(cell) {
    if (!this.initRappidService.showDraggableThings) {
      return;
    }
    if (!this.initRappidService.graph.hasActiveBatch('undoredo'))
      this.updateListChecker(cell);
  }

  updateListChecker(cell?) {
    if (cell && cell instanceof ExhibitionLink) {
      const src = this.initRappidService.graph.getCell(cell.mainUpperLink?.source()?.id);
      const trgt = this.initRappidService.graph.getCell(cell.target()?.id);
      this.updateTextList([
        src?.getVisual()?.logicalElement,
        trgt?.getVisual()?.logicalElement,
      ]);
    } else if (cell instanceof OpmDefaultLink || cell instanceof BlankLink) {
      return;
    }
    const basicThingsLength = this.initRappidService.opmModel.getAllBasicThings().length;
    if (Math.abs(this.lastKnownLength - basicThingsLength) > 1)
      this.cutIndex = 1;
    this.updateList();
    this.lastKnownLength = basicThingsLength;
  }

  updateTextList(elements = []) {
    elements = elements.filter(elm => !!elm);
    if (elements.length > 0) {
      for (const logical of elements) {
        logical.textForListLogical = this.getElementText(logical);
        const links = logical.getLinks();
        for (const out of links.outGoing.filter(l => l.linkType === linkType.Exhibition)) {
          const target = out.targetLogicalElements[0];
          if (target)
            target.textForListLogical = this.getElementText(target);
        }
      }
    } else {
      for (const element of this.initRappidService.opmModel.getAllBasicThings()) {
        const elm = element as OpmLogicalThing<OpmVisualThing>;
        elm.textForListLogical = this.getElementText(elm);
      }
    }
  }

  setLengthOftextbox() {
    const sideNavContainer = document.getElementById('titleW');
    const elementContainer = document.getElementById('itemContainer');

    if (elementContainer) {
      const elementContainerRect = elementContainer.getBoundingClientRect();
      const rightSideRect = document.getElementById('rightLimitRect');

      const rightLimitRect = rightSideRect.getBoundingClientRect();
      const iconWidth = 16;
      let textRectWidth = elementContainerRect.width - ((elementContainerRect.right - rightLimitRect.left + 10) + (elementContainerRect.left + iconWidth + 10));
      const elementsArray = document.getElementsByClassName('list-item');
      const sideNavRect = sideNavContainer.getBoundingClientRect();
      for (let i = 0; i < elementsArray.length; i++) {
        (<HTMLElement>elementsArray[i]).style.width = sideNavRect.width - 20 + 'px';
        (<HTMLElement>document.getElementsByClassName('block')[i]).style.width = textRectWidth + 'px';
      }
    }
  }

  setIconPosition() {
    const elementContainer = document.getElementById('itemContainer');
    const iconHeight = 12;
    const rectIconHeight = 19;
    if (elementContainer) {
      const elementContainerRect = elementContainer.getBoundingClientRect();
      let iconHeightPosition;
      let iconHeightPositionRect;
      const elementsArray = document.getElementsByClassName('objectSquare');
      const rightSideRectArray = document.getElementsByClassName('rightRect');
      for (let i = 0; i < elementsArray.length; i++) {
        let elementIndexContainerHeight = (<HTMLElement>elementsArray[i]).parentElement.getBoundingClientRect().height;
        iconHeightPosition = (elementIndexContainerHeight / 2) - (iconHeight / 2);
        (<HTMLElement>elementsArray[i]).style.top = iconHeightPosition + 'px';
        iconHeightPositionRect = (elementIndexContainerHeight / 2) - (rectIconHeight / 2);
        (<HTMLElement>rightSideRectArray[i]).style.top = iconHeightPositionRect + 'px';
      }
    }
  }

  showGIF($event, handlerGif = '') {
    return OPCloudUtils.showGIF($event, handlerGif);
  }

  setTextWrapping(element) {
    let text = element.el.textContent;
    let elementsTextList = document.getElementsByClassName('block');
    const block = document.getElementsByClassName('block');
    const elementContainer = document.getElementById('itemContainer');
    const elementContainerRect = elementContainer.getBoundingClientRect();
    const rightSideRect = document.getElementById('rightLimitRect');
    const rightLimitRect = rightSideRect.getBoundingClientRect();
    const iconWidth = 16;
    let textRectWidth = elementContainerRect.width - ((elementContainerRect.right - rightLimitRect.left + 10) + (elementContainerRect.left + iconWidth + 10));
    for (let i = 0; i < block.length; i++) {
      (<HTMLElement>block[i]).style.width = textRectWidth + 'px';
    }
    for (let i = 0; i < elementsTextList.length; i++) {
      if (text === (<HTMLElement>elementsTextList[i]).innerText) {
        (<HTMLElement>elementsTextList[i]).style.wordBreak = 'break-all';
      }
    }
  }

  addV(string) {
    if (string === 'object')
      this.addVObject = !(this.addVObject);
    else if (string === 'process')
      this.addVProcess = !(this.addVProcess);
    this.cutIndex = 1;
    this.updateList();
  }

  updateList() {
    this.list = this.initRappidService.opmModel.getAllBasicThings();
    this.filter();
  }

  onStartDrag(event, index) {
  }

  onDrop(event, index) {
    const duplicate = this.initRappidService.opmModel.canBeDuplicated(this.list[index]);
    if (!duplicate) {
      validationAlert('Can not duplicate inner process of another process at the same OPD');
      return;
    }
    const currentOpd = this.initRappidService.getOpmModel().currentOpd;
    if (currentOpd.isStereotypeOpd() || currentOpd.requirementViewOf || this.initRappidService.isDSMClusteredView.value === true ||
      currentOpd.sharedOpdWithSubModelId || currentOpd.belongsToSubModel)
      return;
    const eventCX = event.type === 'touchend' ? event.changedTouches[event.changedTouches.length - 1].clientX : event.clientX;
    const eventCy = event.type === 'touchend' ? event.changedTouches[event.changedTouches.length - 1].clientY : event.clientY;
    const b = this.initRappidService.paperScroller.getVisibleArea();
    const c = $(this.initRappidService.paperScroller.el).offset();
    if (c.left <= eventCX && eventCX <= c.left + b.width &&
      c.top <= eventCy && eventCy <= c.top + b.height) {
      this.initRappidService.getOpmModel().logForUndo(this.list[index].text + ' duplication add');
      // Daniel: Needs to be heavily refactored. But I do not want to change too many files.
      // The way I want it to be:
      /*
        const vis = this.list[index].createNewVisual();
        this will work as follow:
          create a new default visual. We don't need any parameters, becuse they all known.
          this method will call the visual c'tor which will create a new drawnElement which will be
          added to the current graph. No rendering needed.
          Logical -> Visual -> Drawn.
        vis.setPos(a.x - (vis.width / 2), a.y - (vis.height / 2));
      */
      const defaultStyleSettings = this.list[index].constructor.name.endsWith('Object') ?
        this.initRappidService.oplService.getObjectStyleDefaultSettings() : this.initRappidService.oplService.getProcessStyleDefaultSettings();
      const styleSettings = this.list[index].constructor.name.endsWith('Object') ?
        (this.initRappidService.oplService.settings.style?.object || this.initRappidService.oplService.getObjectStyleDefaultSettings())  :
        (this.initRappidService.oplService.settings.style?.process || this.initRappidService.oplService.getProcessStyleDefaultSettings());
      const a = this.initRappidService.paper.clientToLocalPoint(eventCX, eventCy);
      const params = {
        id: 0,
        xPos: 0,
        yPos: 0,
        width: 130,
        height: 60,
        fill: styleSettings.fill_color || defaultStyleSettings.fill_color,
        strokeColor: styleSettings.border_color || defaultStyleSettings.border_color,
        textFontFamily: styleSettings.font || defaultStyleSettings.font,
        textColor: styleSettings.text_color || defaultStyleSettings.text_color,
        textFontSize: styleSettings.font_size || defaultStyleSettings.font_size
      };

      const visual = this.initRappidService.opmModel.copyToScreen(this.list[index]);
      visual.setParams(params);
      visual.setNewUUID();
      visual.xPos = a.x - (visual.width / 2);
      visual.yPos = a.y - (visual.height / 2);
      visual.refY = this.list[index].visualElements[0].refY;
      visual.refX = this.list[index].visualElements[0].refX;
      visual.refineeInzooming = visual.getRefineeInzoom();
      visual.refineeUnfolding = visual.getRefineeUnfold();
      visual.refineable = visual.getRefineable();
      if (this.list[index].visualElements.find(v => v.getDescriptionStatus()?.includes('show')))
        visual.setDescriptionStatus('showDescription ');
      if (visual.refineable || (<any>visual.logicalElement).getStereotype())
        visual.strokeWidth = 4;
      const drawnElement = createDrawnEntity(visual.logicalElement.name);
      drawnElement.updateParamsFromOpmModel(visual);
      visual.setParams(drawnElement.getParams());
      this.graphService.fromStencil = true;
      this.graphService.graph.addCell(drawnElement);
      this.graphService.fromStencil = false;
      this.graphService.resetElementTextPosition(drawnElement);
      this.graphService.graph.startBatch('ignoreAddEvent');
      // drawnElement.updateView(visual);
      // if (visual instanceof OpmVisualObject)
      //   if (visual.isComputational())
      //     drawnElement.expressAllAction(visual, this.initRappidService);
      if (visual.type.includes('Object') && (<OpmVisualObject>visual).states) {
        drawnElement.expressAllAction(visual, this.initRappidService, true);
        drawnElement.syncStatesOrder(this.initRappidService, true, (<any>visual.logicalElement.visualElements[0]).states.map(s => s.logicalElement.lid));
      }

      drawnElement.autosize(this.initRappidService);
      this.graphService.graph.stopBatch('ignoreAddEvent');

      if (drawnElement instanceof OpmThing)
        drawnElement.drawDuplicationMarkToAllDuplicatesInSameOPD(this.initRappidService);
    }
  }

  allowDrop(event) {
    event.preventDefault();
  }

  private sortFunc(e1, e2): number {
    if (e1.name == e2.name)
      return e1.text < e2.text ? -1 : 1;
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  filter() {
    this.list = this.list.filter(e => (((this.addVObject) && e.name === 'OpmLogicalObject') || ((this.addVProcess) && e.name === 'OpmLogicalProcess')))
      .sort((e1, e2) => this.sortFunc(e1, e2));
    this.list = this.list.filter(log => !log.isSatisfiedRequirementObject() && !log.isSatisfiedRequirementSetObject());
    if (this.searchString.length > 0)
      this.list = this.list.filter(a => a.textForListLogical?.toLowerCase().includes(this.searchString.toLowerCase()));
    this.lastListLength = this.list.length;
    if (this.list.length > this.maxItemsPerPage) {
      this.list = this.list.slice((this.cutIndex-1) * this.maxItemsPerPage, this.maxItemsPerPage * this.cutIndex);
      this.wasListCut = true;
    } else {
      this.wasListCut = false;
    }
  }

  plus() {
    this.cutIndex = Math.min(this.cutIndex + 1, Math.floor((this.lastListLength / this.maxItemsPerPage) + 1));
    this.updateList();
  }

  minus() {
    this.cutIndex = Math.max(this.cutIndex - 1, 1);
    this.updateList();
  }

  setToStart() {
    this.cutIndex = 1;
    this.updateList();
  }

  setToEnd() {
    this.cutIndex = Math.floor((this.lastListLength / this.maxItemsPerPage) + 1);
    this.updateList();
  }

  search() {
    this.cutIndex = 1;
    this.updateList();
    this.searchString.length > 0 ? this.clearSearch = true : this.clearSearch = false;
  }

  clearSearchText() {
    this.searchString = '';
    document.getElementById('draggableThingsSearchInput')?.setAttribute("placeholder","Search");
    this.clearSearch = false;
    this.search();
  }

  setSelectMenuIsOpen() {
    (this.selectMenu) = !(this.selectMenu);
    (this.optionsMenu) = !(this.optionsMenu);
  }

  draggableRightClick(element: OpmLogicalThing<OpmVisualThing>) {
    if (element) {
      const data = { element: element };
      this.initRappidService.dialogService.openDialog(SearchItemsDialogComponent, 530, 600, data);
    }
  }

  showMap() {
    const showMapButton = !getShowMapButton();
    setShowMapButton(showMapButton);

    const root: OpmOpd = this.initRappidService.opmModel.opds[0];

    if (!showMapButton) {
      this.initRappidService.graph.resetCells([]);
      const currOpd: OpmOpd = this.initRappidService.opmModel.currentOpd;
      this.graphService.renderGraph(currOpd, this.initRappidService);
      return;
    }
    if (root.visualElements.length == 0) {
      setShowMapButton(false);
      return;
    }
    const currOpdId = this.initRappidService.opmModel.currentOpd.id;
    setTimeout(() => {
      try {
        this.initRappidService.currentlyCreatingSystemMap = true;
        this.buildTree(currOpdId);
        this.initRappidService.currentlyCreatingSystemMap = false;
      } catch(err) {
        console.error(err);
        this.initRappidService.currentlyCreatingSystemMap = false;
      }

    }, 50);

  }

  private async buildTree(currOpdId) {
    validationAlert('For large models, the process of creating a system map may take a several minutes', 3500, 'warning');
    await this.initRappidService.showLoadingSpinner();
    const brothersInDepth: number[] = new Array(this.getMaxDepthTree() + 1).fill(0);
    const indexInDepth: number[] = new Array(this.getMaxDepthTree() + 1).fill(0);
    const shapes: OpmImage[] = [];
    const links: OpmImageLink[] = [];
    this.countBrothersInDepth(brothersInDepth);
    const _this = this;
    function buildTheTree(root: OpmOpd, depth: number = 0): OpmImage {
      let shape = _this.getShape(brothersInDepth, indexInDepth, root, depth, currOpdId);
      shapes.push(shape);
      depth++;

      root.children.forEach(child => {
        if (child.isHidden || child.requirementsOpd || child.isRangesOpd || child.visualElements.length === 0) {
          return;
        }
        links.push(new OpmImageLink({
          source: {
            id: shape.id,
            port: 'out'
          },
          target: {
            id: buildTheTree(child, depth).id,
            port: 'in'
          },
          labels: [
            { position: .5, attrs: { text: { text: child.getName(), 'font-weight': 'bold' } } }
          ]
        }));
        indexInDepth[depth]++;
      });

      depth--;
      return shape;
    }

    buildTheTree(this.initRappidService.opmModel.opds[0]);
    this.initRappidService.graph.resetCells([]);
    shapes.forEach(elem => elem.addTo(this.initRappidService.graph));
    links.forEach(link => link.addTo(this.initRappidService.graph));
    this.initRappidService.paperScroller.scrollToContent();
    this.initRappidService.treeViewService.createDummyNode('SystemMap', 'System Map', true);
    this.initRappidService.treeViewService.treeView.treeModel.getNodeById('SystemMap').toggleActivated();
    this.initRappidService.isLoadingModel = false;
  }

  private getShape(brothersInDepth: number[], indexInDepth: number[], opd: OpmOpd, depth, currOpdId): OpmImage {
    const width: number = Math.max(...brothersInDepth) * 250;
    let shape = opd.shape;
    if (!shape || opd.id == currOpdId) {
      this.initRappidService.graph.resetCells([]);
      this.graphService.renderGraph(opd);
      shape = this.graphService.makeShape(opd);
    }
    const nodeWidth = width / (brothersInDepth[depth]);
    const xCordinate = (nodeWidth / 2) + nodeWidth * indexInDepth[depth];
    const yCordinate = 300 * depth;
    shape.position(xCordinate, yCordinate);
    opd.shape = shape;
    return shape;
  }

  private getMaxDepthTree(root: OpmOpd = this.initRappidService.opmModel.opds[0]) {
    let maxDepth = 0;
    function maxDepthTree(root: OpmOpd, depth: number = 0) {
      depth++;
      root.children.forEach((child) => {
        if (child !== root)
          maxDepthTree(child, depth);
      });
      depth--;
      maxDepth = Math.max(depth, maxDepth);
    }
    maxDepthTree(root);
    return maxDepth;
  }

  private countBrothersInDepth(brothersInDepth: number[]) {
    brothersInDepth[0] = 1;
    let depth = 0;
    const _this = this;
    function brothersInD(root: OpmOpd = _this.initRappidService.opmModel.opds[0]) {
      depth++;
      root.children.forEach((child) => {
        if (child !== root) {
          brothersInDepth[depth]++;
          brothersInD(child);
        }
      });
      depth--;
    }
    brothersInD();
  }

  getElementText(element: OpmLogicalThing<OpmVisualThing>) {
    const belongsToStereotype = element.getBelongsToStereotyped();
    let txt = element.getDisplayText();
    if (belongsToStereotype && txt.includes('<') && txt.includes('>'))
      txt = txt.substring(txt.lastIndexOf('>') + 1).trim()

    if (belongsToStereotype)
      return txt;

    txt = element.getBareName();
    const exhibitions = element.getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition);
    if (exhibitions.length > 0) {
      const names = exhibitions.map(l => (<any>l.sourceLogicalElement).getBareName());
      if (names.length === 1)
        txt += ' of ' + names[0]
      else if (names.length === 2)
        txt += ' of ' + names[0] + ' and ' + names[1];
      else if (names.length > 2)
        txt += ' of ' + names.slice(0, names.length-1).join(', ') + ' and ' + names[names.length - 1];
    }
    return txt;
  }

  getList() {
    // if (this.differ.diff(this.initRappidService.opmModel.logicalElements))
    //   this.updateList();
    return this.list;
  }

  mouseLeave() {
    OPCloudUtils.removeAllExplainationsDivs();
  }

  getMissingText(element) {
    element.textForListLogical = this.getElementText(element);
    return element.textForListLogical;
  }

  closeDraggable() {
    this.sub1.unsubscribe();
    this.sub2.unsubscribe();
    this.sub3.unsubscribe();
    this.initRappidService.getGraphService().graph.off('add', undefined, 'onGraphAdd');
    this.initRappidService.getGraphService().graph.off('remove', undefined, 'onGraphRemove');
    this.initRappidService.showDraggableThings = false;
    $('#listLogical').css('height', '60px');
  }

  ngOnDestroy() {
    this.closeDraggable();
  }
}
