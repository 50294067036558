// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
#floatingNavigatorBox {
  padding-top: 30px;
  box-sizing: border-box;
  border: solid 1px #ccc;
  color: rgba(0, 0, 0, 0.87);
  background: #fff;
  border-radius: 4px;
  position: absolute;
  z-index: 200;
  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),
  0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
}

#floatingNavigatorBox:active {
  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),
  0 8px 10px 1px rgba(0, 0, 0, 0.14),
  0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

#dragHandleButton {
  opacity:0.4;
  fill:#1A3763;
  position: absolute;
  top: 4px;
  left: 5px;
  cursor: move;
  width: 24px;
  height: 24px;
}
#floatingNavigatorButtons {
  position: absolute;
  top: 8px;
  right: 5px;
}

.sizeButtons {
  position: absolute;
  top: 6px;
  right: 38px;
  z-index: 201
}
`, "",{"version":3,"sources":["webpack://./src/app/modules/layout/opcloud-navigator/navigator.component.css"],"names":[],"mappings":";AACA;EACE,iBAAiB;EACjB,sBAAsB;EACtB,sBAAsB;EACtB,0BAA0B;EAC1B,gBAAgB;EAChB,kBAAkB;EAClB,kBAAkB;EAClB,YAAY;EACZ,uDAAuD;EACvD;;iCAE+B;AACjC;;AAEA;EACE;;oCAEkC;AACpC;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,QAAQ;EACR,SAAS;EACT,YAAY;EACZ,WAAW;EACX,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,QAAQ;EACR,UAAU;AACZ;;AAEA;EACE,kBAAkB;EAClB,QAAQ;EACR,WAAW;EACX;AACF","sourcesContent":["\n#floatingNavigatorBox {\n  padding-top: 30px;\n  box-sizing: border-box;\n  border: solid 1px #ccc;\n  color: rgba(0, 0, 0, 0.87);\n  background: #fff;\n  border-radius: 4px;\n  position: absolute;\n  z-index: 200;\n  transition: box-shadow 200ms cubic-bezier(0, 0, 0.2, 1);\n  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2),\n  0 2px 2px 0 rgba(0, 0, 0, 0.14),\n  0 1px 5px 0 rgba(0, 0, 0, 0.12);\n}\n\n#floatingNavigatorBox:active {\n  box-shadow: 0 5px 5px -3px rgba(0, 0, 0, 0.2),\n  0 8px 10px 1px rgba(0, 0, 0, 0.14),\n  0 3px 14px 2px rgba(0, 0, 0, 0.12);\n}\n\n#dragHandleButton {\n  opacity:0.4;\n  fill:#1A3763;\n  position: absolute;\n  top: 4px;\n  left: 5px;\n  cursor: move;\n  width: 24px;\n  height: 24px;\n}\n#floatingNavigatorButtons {\n  position: absolute;\n  top: 8px;\n  right: 5px;\n}\n\n.sizeButtons {\n  position: absolute;\n  top: 6px;\n  right: 38px;\n  z-index: 201\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
