import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";
import {DeleteAction} from "../../Actions/deleteAction";

export class RemoveCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: false,
            name: 'remove',
            displayTitle: 'Remove',
            svg: 'delete',
            action: new RemoveAction(this.init)
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'remove',
            displayTitle: 'Remove',
            svg: 'delete',
            action: new RemoveAction(this.init),
            gif: '',
        }
    }

}

class RemoveAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService) {
    }

    act() {
      new DeleteAction(this.init).act();
    }

}
