// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.example-loading-shade {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 56px;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.example-rate-limit-reached {
  color: #980000;
  max-width: 360px;
  text-align: center;
}

table {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/dialogs/logs-dashboard/logs-dashboard.component.scss"],"names":[],"mappings":"AAGE;EACE,kBAAA;EACA,MAAA;EACA,OAAA;EACA,YAAA;EACA,QAAA;EACA,+BAAA;EACA,UAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;AAFJ;;AAKE;EACE,cAAA;EACA,gBAAA;EACA,kBAAA;AAFJ;;AAKE;EACE,WAAA;AAFJ","sourcesContent":["\n\n\n  .example-loading-shade {\n    position: absolute;\n    top: 0;\n    left: 0;\n    bottom: 56px;\n    right: 0;\n    background: rgba(0, 0, 0, 0.15);\n    z-index: 1;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n  }\n  \n  .example-rate-limit-reached {\n    color: #980000;\n    max-width: 360px;\n    text-align: center;\n  }\n  \n  table {\n    width: 100%;\n  }\n  "],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
