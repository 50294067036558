  import {OpmLogicalElement} from './OpmLogicalElement';
  import {OpmLink} from '../VisualPart/OpmLink';
  import {OpmVisualElement} from '../VisualPart/OpmVisualElement';
  import {linkConnectionType, LinkLogicalConnection} from '../ConfigurationOptions';
  import {linkType} from '../ConfigurationOptions';

  export abstract class OpmRelation<T extends OpmLink> extends OpmLogicalElement<T> {
    private _sourceLogicalElement: OpmLogicalElement<OpmVisualElement>;
    private _targetLogicalElements: Array<OpmLogicalElement<OpmVisualElement>>;
    private _linkConnectionType: linkConnectionType;
    private _sourceCardinality: string;
    private _targetCardinality: string;
    private _sourceLogicalConnection: LinkLogicalConnection;
    private _targetLogicalConnection: LinkLogicalConnection;
    private _linkType: linkType;
    private _linkRequirements: string;
    get sourceLogicalElement(): OpmLogicalElement<OpmVisualElement> {return this._sourceLogicalElement; }
    set sourceLogicalElement(value: OpmLogicalElement<OpmVisualElement>) {this._sourceLogicalElement = value; }
    get targetLogicalElements(): Array<OpmLogicalElement<OpmVisualElement>> {return this._targetLogicalElements; }
    set targetLogicalElements(value: Array<OpmLogicalElement<OpmVisualElement>>) {this._targetLogicalElements = value; }
    get linkConnectionType(): linkConnectionType {return this._linkConnectionType; }
    set linkConnectionType(value: linkConnectionType) {this._linkConnectionType = value; }
    get sourceCardinality(): string {return this._sourceCardinality; }
    set sourceCardinality(value: string) {this._sourceCardinality = value; }
    get targetCardinality(): string {return this._targetCardinality; }
    set targetCardinality(value: string) {this._targetCardinality = value; }
    get sourceLogicalConnection(): LinkLogicalConnection {return this._sourceLogicalConnection; }
    set sourceLogicalConnection(value: LinkLogicalConnection) {this._sourceLogicalConnection = value; }
    get targetLogicalConnection(): LinkLogicalConnection {return this._targetLogicalConnection; }
    set targetLogicalConnection(value: LinkLogicalConnection) {this._targetLogicalConnection = value; }
    get linkType(): linkType {return this._linkType; }
    set linkType(value: linkType) {this._linkType = value; }
    get linkRequirements(): string {return this._linkRequirements; }
    set linkRequirements(value: string) {this._linkRequirements = value; }

    constructor(params, model) {
      super(params, model);
      this.sourceLogicalConnection = params ? params.sourceLogicalConnection : null;
      this.targetLogicalConnection = params ? params.targetLogicalConnection : null;
      this.linkType = params ? params.linkType : null;
      this.linkRequirements = params?.linkRequirements || '';
    }

    isLink(): boolean {
      return true;
    }

    setParams(params) {
      super.setParams(params);
      this.linkConnectionType = params.linkConnectionType;
      this.linkType = params.linkType;
      if (params.hasOwnProperty('linkRequirements'))
        this.linkRequirements = params.linkRequirements;
    }

    updateParams(params) {
      super.updateParams(params);
      this.targetLogicalElements = new Array<OpmLogicalElement<OpmVisualElement>>();
      const sourceLogicalElement = this.opmModel.getLogicalElementByVisualId(params.sourceElementId);
      this.sourceLogicalElement = sourceLogicalElement ? sourceLogicalElement : params.sourceElementId;
      let targetLogicalElement = this.opmModel.getLogicalElementByVisualId(params.targetElementId);
      targetLogicalElement = targetLogicalElement ? targetLogicalElement : params.targetElementId;
      this.targetLogicalElements.push(this.opmModel.getLogicalElementByVisualId(params.targetElementId));
      this.linkConnectionType = params.linkConnectionType;
      this.linkType = params.linkType;
      if (params.hasOwnProperty('linkRequirements'))
        this.linkRequirements = params.linkRequirements;
    }

    getRelationParams() {
      const params = {
        linkConnectionType: this.linkConnectionType,
        linkType: this.linkType,
        sourceLogicalConnection: this.sourceLogicalConnection,
        targetLogicalConnection: this.targetLogicalConnection,
        linkRequirements: this.linkRequirements,
      };
      return {...super.getElementParams(), ...params};
    }
    getRelationParamsFromJsonElement(jsonElement) {
      return {
        ...super.getElementParamsFromJsonElement(jsonElement),
        linkConnectionType: jsonElement.linkConnectionType,
        linkType: jsonElement.linkType,
        sourceLogicalConnection: jsonElement.sourceLogicalConnection,
        targetLogicalConnection: jsonElement.targetLogicalConnection,
        linkRequirements: jsonElement.linkRequirements,
      };
    }
    updateSourceAndTargetFromJson() {
      if (typeof this.sourceLogicalElement === 'string') {
        this.sourceLogicalElement = this.opmModel.getLogicalElementByVisualId(this.sourceLogicalElement);
      }
      for (let i = 0; i < this.targetLogicalElements.length; i++) {
        if (typeof this.targetLogicalElements[i] === 'string') {
          this.targetLogicalElements[i] = this.opmModel.getLogicalElementByVisualId(this.targetLogicalElements[i]);
        }
      }
      for (let i = 0; i < this.visualElements.length; i++) {
        this.visualElements[i].updateSourceAndTargetFromJson();
      }
    }
    // in each opd that has a visual link of this and a visual link of relation, update the port
    // of this to be the same as relation in side
    updatePortAndDataToAllVisuals(relation, side) {
      for (let i = 0; i < this.visualElements.length; i++) {
        const opd = this.opmModel.getOpdByThingId(this.visualElements[i].id);
        const link = <OpmLink>(opd.getVisualElementByLogical(relation));
        if (link) {
          const elementOnLinkSide = (side === 'source') ? link.sourceVisualElement : link.targetVisualElements[0].targetVisualElement;
          const elementOnCurrentSide = (side === 'source') ? this.visualElements[i].sourceVisualElement : this.visualElements[i].targetVisualElements[0].targetVisualElement;
          if (elementOnLinkSide === elementOnCurrentSide) {
            let portOnLinkSide = (side === 'source') ? link.sourceVisualElementPort : link.targetVisualElementPort;
            portOnLinkSide = portOnLinkSide ? portOnLinkSide : 1;
            if (side === 'source') {
              this.visualElements[i].sourceVisualElementPort = portOnLinkSide;
              link.sourceVisualElementPort = portOnLinkSide;
              // if link wasn't logically connected to other links yet at the sourec
              if (relation.sourceLogicalConnection === null || relation.sourceLogicalConnection === undefined) {
                // no existing connection. the default is XOR
                relation.sourceLogicalConnection = LinkLogicalConnection.Xor;
              }
              if (!link.sourceConnectedLinks) {
                // initialize new array
                link.sourceConnectedLinks = new Array<OpmLink>();
                link.sourceConnectedLinks.push(link);
              }
              // set source logical connection to be the same as relation
              this.sourceLogicalConnection = relation.sourceLogicalConnection;
              // insert current link to sourceConnectedLinks Array
              link.sourceConnectedLinks.push(this.visualElements[i]);
              // store sourceConnectedLinks array to the current visual link
              this.visualElements[i].sourceConnectedLinks = link.sourceConnectedLinks;
            } else {  // same as source
              this.visualElements[i].targetVisualElementPort = portOnLinkSide;
              link.targetVisualElementPort = portOnLinkSide;
              // if link wasn't logically connected to other links yet ate the target
              if (relation.targetLogicalConnection === null || relation.targetLogicalConnection === undefined) {
                // no existing connection. the default is XOR
                relation.targetLogicalConnection = LinkLogicalConnection.Xor;
              }
              if (!link.targetConnectedLinks) {
                // initialize new array
                link.targetConnectedLinks = new Array<OpmLink>();
                link.targetConnectedLinks.push(link);
              }
              // set source logical connection to be the same as relation
              this.targetLogicalConnection = relation.targetLogicalConnection;
              // insert current link to targetConnectedLinks Array
              link.targetConnectedLinks.push(this.visualElements[i]);
              // store targetConnectedLinks array to the current visual link
              this.visualElements[i].targetConnectedLinks = link.targetConnectedLinks;
            }
          }
        }
      }
    }
    disconnectSourceLogicalConnectionAllVisuals () {
      this.sourceLogicalConnection = null;
      for (let i = 0; i < this.visualElements.length; i++) {
        this.visualElements[i].sourceVisualElementPort = null;
        // remove current visual element from arrays of its previously connected links
        if ( this.visualElements[i].sourceConnectedLinks ) {
          this.visualElements[i].sourceConnectedLinks = <any>this.visualElements[i].sourceConnectedLinks.map( link => {
            if (link.constructor.name.includes('String'))
              return this.opmModel.getVisualElementById(link);
            else
              return link;
          }).filter( link => link);
          const updatedArray = this.visualElements[i].sourceConnectedLinks.filter(link => link !== this.visualElements[i]
            && link !== undefined && !link.constructor.name.includes('String'));
          for (let j = 0; j < updatedArray.length; j++) {
            updatedArray[j].sourceConnectedLinks = updatedArray;
            if (updatedArray.length < 2) {// if it was a relation of two links and now one is disconnected
              updatedArray[j].sourceConnectedLinks = null;
              (<OpmRelation<OpmLink>>(updatedArray[j].logicalElement)).sourceLogicalConnection = null;
            }
          }
          this.visualElements[i].sourceConnectedLinks = null;
        }
      }
    }
    disconnectTargetLogicalConnectionAllVisuals () {
      this.targetLogicalConnection = null;
      for (let i = 0; i < this.visualElements.length; i++) {
        this.visualElements[i].targetVisualElementPort = null;
        // remove current visual element from arrays of its previously connected links
        if (this.visualElements[i].targetConnectedLinks) {
          this.visualElements[i].targetConnectedLinks = <any>this.visualElements[i].targetConnectedLinks.map( link => {
            if (link.constructor.name.includes('String'))
              return this.opmModel.getVisualElementById(link);
            else
              return link;
          }).filter( link => link);
          const updatedArray = this.visualElements[i].targetConnectedLinks.filter(link => link !== this.visualElements[i]);
          for (let j = 0; j < updatedArray.length; j++) {
            updatedArray[j].targetConnectedLinks = updatedArray;
            if (updatedArray.length < 2) {// if it was a relation of two links and now one is disconnected
              updatedArray[j].targetConnectedLinks = null;
              (<OpmRelation<OpmLink>>(updatedArray[j].logicalElement)).targetLogicalConnection = null;
            }
          }
          this.visualElements[i].targetConnectedLinks = null;
        }
      }
    }

  }

