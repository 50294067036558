import { linkConnectionType, linkType } from '../../ConfigurationOptions';
import {
  distanceBetweenPoints,
  geometry,
  getInitRappidShared,
  joint, OPCloudUtils,
  popupGenerator, popupInputsEnterListener,
  stylePopup,
  validationAlert
} from '../../../configuration/rappidEnviromentFunctionality/shared';
import { OpmLinkRappid } from './OpmLinkRappid';
import { oplFunctions } from '../../../opl-generation/opl-functions';
import { OpmModel } from '../../OpmModel';
import { OpmVisualEntity } from '../../VisualPart/OpmVisualEntity';
import { OpmLink } from '../../VisualPart/OpmLink';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import { structural } from '../../consistency/links.set';
import { OpmVisualElement } from '../../VisualPart/OpmVisualElement';
import { TriangleClass } from './OpmFundamentalLink';
import { OpmSemifoldedFundamental } from '../OpmSemifoldedFundamental';
import {SaveURLComponent} from "../../../dialogs/saveURL-dialog/saveURL";

export class OpmDefaultLink extends OpmLinkRappid {

  private lastPointerUpLocation;

  constructor(id?: string) {
    super();
    this.set(this.linkAttributes());
    this.attr(this.linkAttrs());
    this.IDSetter(id);
    this.legalSelfInvocation = false;
    this.on('change:source', this.onChangedSource);
    this.on('change:target', this.onChangedTarget);
  }

  onChangedSource(cell, changed) {
    if (cell.previousAttributes().source.x)
      this.lastPointerUpLocation = cell.previousAttributes().source;
    if (getInitRappidShared().graph.hasActiveBatch('rendering'))
      return;
    if (!changed.x && !changed.anchor && cell.getSourceElement() && !cell.source().port && OPCloudUtils.isInstanceOfDrawnTriangle(this.getTargetElement())) {
      const delta = cell.getSourceElement().getStructuralLinkConnectionPointDelta();
      this.prop('source/anchor', {
        name: 'center',
        args: {
          rotate: true,
          dx: delta,
          dy: delta,
        }
      });
    }
  }

  onChangedTarget(cell, changed) {
    if (cell.previousAttributes().target.x)
      this.lastPointerUpLocation = cell.previousAttributes().target;
    if (getInitRappidShared().graph.hasActiveBatch('rendering')) {
      return;
    } else if ((cell.constructor.name.includes('Default') && !cell.getTargetElement()) || (OPCloudUtils.isInstanceOfDrawnSemiFoldedFundamental(cell.getTargetElement()))) {
      return;
    } else if (!changed.x && !changed.anchor && cell.getTargetElement() && !cell.target().port && OPCloudUtils.isInstanceOfDrawnTriangle(this.getSourceElement())) {
      const delta = cell.getTargetElement().getStructuralLinkConnectionPointDelta();
      this.prop('target/anchor', {
        name: 'center',
        args: {
          rotate: true,
          dx: delta,
          dy: delta,
        }
      });
    }
  }

  legalSelfInvocation: boolean;

  getVisual(): OpmVisualElement {
    return getInitRappidShared().getOpmModel().getVisualElementById(this.id);
  }

  IDSetter(id) {
    if (id) {
      this.set('id', id);
    }
  }

  deleteLabel() {
    if (this.labels().length > 0) {
      for (let i = 0; i < this.labels().length; i++) {
        this.removeLabel(this.labels()[i]);
      }
      this.deleteLabel();
    }
    if (this.getVisual())
      (<any>this.getVisual()).labels = [];
  }

  isProceduralLink(): boolean {
    return false;
  }

  getAllFundamentalLinks() { }

  linkAttributes() {
    return {
      type: 'opm.Link',
      name: 'defaultLink',
      connector: {
        name: 'jumpover'
      },

    };

  }

  linkAttrs() {
    return {
      'line': { 'strokeWidth': '2', 'strokeDasharray': '8 5', 'stroke': '#586D8C' }
    };
  }

  getDefaultLinkParams() {
    const source = this.get('source');
    const target = this.get('target');
    return {
      sourceElementId: source ? source.id : null,
      sourceVisualElementPort: source ? source.port : null,
      targetElementId: target ? target.id : null,
      targetVisualElementPort: target ? target.port : null,
      vertices: this.get('vertices'),
      linkConnectionType: (this.attr('line/strokeDasharray') === '0') ? linkConnectionType.systemic : linkConnectionType.enviromental,
      textColor: 'black',
      textFontWeight: 'normal',
      textFontSize: '12',
      textFontFamily: 'ariel',
      strokeColor: this.attr('line/stroke'),
      strokeWidth: this.attr('line/strokeWidth'),
      id: this.get('id'),
      tag: this.get('tag'),
      labels: this.labels(),
      linkRequirements: this.get('requirements'),
      showRequirementsLabel: this.get('showRequirementsLabel'),
    };
  }

  doubleClickHandle(cellView, evt, initRappid) {
  }

  openLinkURLEditing(init) {
    return init.dialogService.getDialog().open(SaveURLComponent, {
      height: '600px',
      width: '560px',
      data: { Element: this.id }
    });
  }

  restorePopupData(dataToRemember) {
    if ($('.srce').length)
      $('.srce').val(dataToRemember['sourceMultiplicity'] || '');

    if ($('.trgt').length)
      $('.trgt').val(dataToRemember['targetMultiplicity'] || '');

    if ($('.pth').length)
      $('.pth').val(dataToRemember['Path'] || '');

    if ($('.prob').length)
      $('.prob').val(dataToRemember['Probability'] || '');

    if ($('.rate').length)
      $('.rate').val(dataToRemember['rate'] || '');

    if ($('.rateUnits').length)
      $('.rateUnits').val(dataToRemember['rateUnits'] || '');

    if ($('.min').length)
      $('.min').val(dataToRemember['timeMin'] || '');

    if ($('#selectMin').length)
      $('#selectMin').val(dataToRemember['timeMinVal'] || '');

    if ($('#selectMax').length)
      $('#selectMax').val(dataToRemember['timeMaxVal'] || '');

    if ($('.max').length)
      $('.max').val(dataToRemember['timeMax'] || '');

    if ($('.req').length)
      $('.req').val(dataToRemember['requirementsText'] || '');

    if ($('.showReq').length && dataToRemember.hasOwnProperty('showRequirementsLabel'))
      (<any>$('.showReq')[0]).checked = dataToRemember['showRequirementsLabel'];

    if ($('.ordered').length && dataToRemember['ordered'] !== undefined)
      (<any>$('.ordered')[0]).checked = dataToRemember['ordered'];

    if ($('.tag').length)
      $('.tag').val(dataToRemember['tag'] || '');

    if ($('.btag').length)
      $('.btag').val(dataToRemember['backwardTag'] || '');

  }

  updateURLArray() {
  }

  getPopupDataToRemember(popup) {
    return {
      'sourceMultiplicity': popup.$('.srce').val(),
      'targetMultiplicity': popup.$('.trgt').val(),
      'Path': popup.$('.pth').val(),
      'Probability': popup.$('.prob').val(),
      'rate': popup.$('.rate').val(),
      'rateUnits': popup.$('.rateUnits').val(),
      'timeMin': popup.$('.min').val(),
      'timeMinVal': popup.$('#selectMin').val(),
      'timeMaxVal': popup.$('#selectMax').val(),
      'timeMax': popup.$('.max').val(),
      'requirementsText': popup.$('.req').val().trim(),
      'showRequirementsLabel': popup.$('.showReq')[0].checked,
      'ordered': popup.$('.ordered')[0]?.checked,
      'tag': popup.$('.tag').val(),
      'backwardTag': popup.$('.btag').val(),
    };
  }

  updateRequirementsLabel(oldVal: string, newVal: string, shouldShow: boolean, link: OpmDefaultLink, init: InitRappidService) {
    const visuals = this.getVisual().logicalElement.visualElements.filter(vis => vis !== this.getVisual());
    let newLabel;
    if (newVal && shouldShow)
      newLabel = link.setLabelsOLinks('Satisfied: ' + newVal, 0.5, 0, -30);
    link.set('requirements', newVal);
    link.set('showRequirementsLabel', shouldShow);
    for (const vis of visuals) {
      if (!(<any>vis).labels && newLabel)
        (<any>vis).labels = [newLabel];
      else if ((<any>vis).labels && newVal) {
        const old = (<any>vis).labels.find(lb => lb.attrs.label.text === 'Satisfied: ' + oldVal);
        if (old)
          (<any>vis).labels.splice((<any>vis).labels.indexOf(old), 1);
        if (newLabel)
          (<any>vis).labels.push(newLabel);
      }
    }
  }

  popupEventsDbClick(element, init?) {
    const this_ = this;
    return {
      'click .urlSvg': function() {
        const dataToRemember = this_.getPopupDataToRemember(this);
        this_.openLinkURLEditing(init).afterClosed().toPromise().then(res => {
          this_.rightClickHandlePopoup(init.paper.findViewByModel(element).el, init);
          this_.restorePopupData(dataToRemember);
        });
        this.remove();
      },
      'click .btnUpdate': function () { // when the button is clicked
        element.appendLabel({
          markup: [
            {
              tagName: 'text',
              selector: 'label'
            }
          ],
          attrs: {
            label: {
              text: (/\S/.test(this.$('.tag').val())) ? this.$('.tag').val().trim() : console.log(), // alert('Field must have value'), // text to show
              fill: 'black'
            }
          },
        });
        element.set('tag', this.$('.tag').val());
        element.set('requirements', this.$('.req').val().trim());
        element.set('showRequirementsLabel', this.$('.showReq')[0].checked);
        this_.addDblClickListenerForLabels();
        this.remove();
      },
      /**
       * group 04:
       * By clicking on the 'Copy Style' button, we keep the style of the source link in the 'linkCopiedStyleParams' dictionary.
       */
      'click .btnStyleCopy': function () {
        this.remove();
        init.linkCopiedStyleParams = {};
        init.linkCopiedStyleParams['strokeWidth'] = this_.attr('line/strokeWidth');
        init.linkCopiedStyleParams['strokeColor'] = this_.attr('line/stroke');
      },
      'click .btnStyle': function () {
        this.remove();
        const stylePopupContent = ['Link color: <input type="color" class="linkColor PopupColorInput" value=' + this_.attr('link/fill') + '><br>',
        'Link width: <input type="width" style="width:35px;padding-top: 5px" class="linkwidth PopupInput" value=' + this_.attr('line/strokeWidth') + '><br>',
          '<button class="btnUpdateStyle Popup" style="padding-top: 5px;margin-left: 6px">Update Style</button>'];
        const stylePopupEvents = {
          'click .btnUpdateStyle': function () {
            if (this.$('.linkwidth').val() < '1' || this.$('.linkwidth').val() > '6') {
              const errorMessage = 'Maximum width is 6';
              validationAlert(errorMessage, 5000, 'Error');
              return;
            }
            init.getOpmModel().logForUndo('link style change');
            this_.attr({ line: { 'stroke': this.$('.linkColor').val() } });
            this_.attr({ line: { 'strokeWidth': this.$('.linkwidth').val() } });

            this.remove();
          }
        };
        const el = init.paper.findViewByModel(this_).el;
        popupGenerator(el, stylePopupContent, stylePopupEvents).render();
        stylePopup();
        popupInputsEnterListener();
        (<HTMLInputElement>$('.linkColor')[0]).value = this_.attr('line/stroke');
      }
    }; // closing popup
  }

  isFundamentalLink(): boolean {
    return false;
  }

  pointerDownHandle(cellView, options) { }

  longTouchHandle(cellView, options: InitRappidService, event) {
    this.rightClickHandle(cellView, event, options)
    console.log('long touch');
  }

  checkIflegalSourceConnection(link, objectToConnectTo) {
    const source = link.getSourceElement();
    const triangle = link.getTargetElement();
    if (!source || !triangle)
      return true;
    const structuralLinks = link.graph.getConnectedLinks(triangle, { outbound: true });
    const targetElements = [];
    structuralLinks.forEach(lnk => {
      targetElements.push(lnk.getTargetElement());
    });
    for (let i = 0; i < targetElements.length; i++) {
      if (targetElements[i] === objectToConnectTo)
        return false;
    }
    return true;
  }
  checkIflegalTargetConnection(link, objectToConnectTo) {
    const target = link.getTargetElement();
    const triangle = link.getSourceElement();
    if (!target || !triangle)
      return true;
    const structuralLinks = link.graph.getConnectedLinks(triangle, { outbound: true });
    const defaultLink = link.graph.getConnectedLinks(triangle, { inbound: true })[0];
    const elements = [defaultLink.getSourceElement()];
    structuralLinks.forEach(lnk => {
      if (lnk !== link)
        elements.push(lnk.getTargetElement());
    });
    for (let i = 0; i < elements.length; i++) {
      if (elements[i] === objectToConnectTo)
        return false;
    }
    return true;
  }

  setNewSourceForVisuals(newSource, newPort, defaultLink, init) {
    const visualNewSource = init.getOpmModel().getVisualElementById(newSource.id);
    const drawnLinks = defaultLink.graph.getConnectedLinks(defaultLink.getTargetElement(), { outbound: true });
    for (let i = 0; i < drawnLinks.length; i++) {
      drawnLinks[i].sourceElement = newSource;
      drawnLinks[i].resizePort();
      const visualLink = init.getOpmModel().getVisualElementById(drawnLinks[i].id);
      visualLink.sourceVisualElement = visualNewSource;
      if (newPort)
        visualLink.sourceVisualElementPort = newPort;
    }
  }

  removeTools() {
    if ($("[joint-selector=button]") || $("[joint-selector=icon]") || $("[data-tool-name=source-arrowhead]")) {
      $("[joint-selector=button]").remove();
      $("[joint-selector=icon]").remove();
      $("[data-tool-name=source-arrowhead]").remove();
      $('.joint-marker-vertex').remove();
      $('.joint-marker-segment').remove();
    }
  }

  hide() {
    this.attr('./display', 'none');
    $('[data-tool-name=source-arrowhead]').hide();
    $('[data-tool-name=target-arrowhead]').hide();
    $('[data-tool-name=segments]').hide();
    $('[data-tool-name=vertices]').hide();
    $('[data-tool-name=button]').hide();
    const vis = getInitRappidShared().getOpmModel().getVisualElementById(this.id);
    if (vis)
      (vis as OpmLink).visible = false;
  }


  isNoteLink(source, target) {
    if (source?.constructor.name.includes('Note') || target?.constructor.name.includes('Note')) {
      return true;
    }
    return false;
  }

  getRequirementsPopupTooltipText(): string {
    return 'The requirement that is satisfied or partly satisfied by this link';
  }


  setPrevious() {

    const prevSourceId = this.get('previousSourceId');
    const prevTargetId = this.get('previousTargetId');

    if (!this.getSourceElement()) {
      this.set({ 'source': { 'id': prevSourceId } });
      this.set('previousSourceId', prevSourceId);
    } else if (!this.getTargetElement()) {
      this.set({ 'target': { 'id': prevTargetId } });
      this.set('previousTargetId', prevTargetId);
    } else if (this.getSourceElement().id !== prevSourceId) {
      this.set({ 'source': { 'id': prevSourceId } });
      this.set('previousSourceId', prevSourceId);
    } else if (this.getTargetElement().id !== prevTargetId) {
      this.set({ 'target': { 'id': prevTargetId } });
      this.set('previousTargetId', prevTargetId);
    } else {
      this.remove();
    }

  }

  refactoredPointerUpHandle(cellView, options, $event) {
    options.getOpmModel().logForUndo('link movement');
    this.removeTools();

    const model: OpmModel = options.getOpmModel();
    let source = this.getSourceElement();
    let target = this.getTargetElement();
    let name = this.get('name');
    let linkId = this.get('id');
    let triangle;

    if (!source || !target || this.isNoteLink(source, target)) {
      this.setPrevious();
      return false;
    }

    if (target.constructor.name.includes('Triangle')) {
      const defaultlink = this.graph.getConnectedLinks(target, { outbound: true })[0];
      const reallink = this.graph.getConnectedLinks(target, { outbound: true })[0];
      linkId = reallink.get('id');
      name = reallink.get('name');
      target = defaultlink.getTargetElement();
      const targets = [];
      triangle = this.getTargetElement();
      for (const lnk of this.graph.getConnectedLinks(triangle, { outbound: true }))
        targets.push(lnk.getTargetElement());
      if (targets.includes(source)) {
        this.setPrevious();
        return false;
      }
    }

    if (source.constructor.name.includes('Triangle')) {
      const defaultlink = this.graph.getConnectedLinks(source, { inbound: true })[0];
      const reallink = this.graph.getConnectedLinks(source, { outbound: true })[0];
      name = reallink.get('name');
      source = defaultlink.getSourceElement();
      triangle = this.getSourceElement();
      const targets = [];
      for (const lnk of this.graph.getConnectedLinks(triangle, { outbound: true }))
        targets.push(lnk.getTargetElement());
      if (targets.includes(source)) {
        this.setPrevious();
        return false;
      }
    }

    const visualLink = <OpmLink>model.getVisualElementById(linkId);
    const visualSource = <OpmVisualEntity>model.getVisualElementById(source.id);
    const visualTarget = <OpmVisualEntity>model.getVisualElementById(target.id);

    if (!visualSource || !visualTarget || !visualLink) {
      this.setPrevious();
      return false;
    }

    // Check according to old OPL table
    // TODO: Create a new static rule set
    const relevantLinks = options.getLinksByOpl(source, target);
    const legal = relevantLinks.filter((l) => (l.name === name)).length > 0;
    const legal2 = model.links.canConnect(visualSource, visualTarget, visualLink.type);
    if (legal === false || legal2.success === false) {
      let souldSetPrevious = false;
      if (legal2.success === false && legal2.message.includes('more than one link')) {
        const exist = visualSource.getLinksWith(visualTarget).outGoing.filter(l => structural.contains(l.type));
        if (exist.length > 1)
          souldSetPrevious = true;
      } else if (legal2.success === false && legal2.message.includes('cannot connect to itself')) {
        souldSetPrevious = true;
      }
      if (legal === false || souldSetPrevious === true) {
        this.setPrevious();
        const message = legal2.message ? legal2.message : 'Not allowed according to OPM standard';
        validationAlert(message, 5000);
        return false;
      }
    }

    if (this.getTargetElement().constructor.name.includes('Triangle')) {
      const newSource = model.getVisualElementById(this.get('source').id);
      const oldSource = model.getVisualElementById(this.get('previousSourceId')) || newSource;
      const fundLinks = (<any>oldSource).getLinks().outGoing.filter(l => l.type === visualLink.type).map(v =>
        options.graph.getCell(v.id)).filter(c => !!c);
      for (const lnk of fundLinks) {
        const visTarget = model.getVisualElementById(lnk.get('target').id);
        const visLink = model.getVisualElementById(lnk.id);
        const ret = model.move(<OpmVisualEntity>newSource, <OpmVisualEntity>visTarget, <OpmLink>visLink);
        if (ret.success === false) { // Check according to old OPL table
          lnk.setPrevious();
          validationAlert(ret.message, 5000);
        }
        const linkToUpdate = ret.success ? ret.link : visLink;
        if (newSource.id !== (<OpmLink>visLink).sourceVisualElement.id || this.get('previousSourceId') !== newSource.id) {
          const shouldJoinExistingTriangle = (<OpmVisualEntity>newSource).getLinks().outGoing.filter(
            l => l.type === (<OpmLink>visLink).type).length;
          if (shouldJoinExistingTriangle > 1 || !options.graph.getCell(lnk.id)) {
            options.graph.getCell(lnk.id)?.remove();
            options.getGraphService().drawLink(linkToUpdate);
          }
        }
      }
      const portData = this.createPortForMovedLink(cellView, source, target, options, $event);
      if (portData) {
        options.graph.stopBatch('arrowhead-move');
        this.set(portData.side, {id: this.get(portData.side).id, port: portData.portId});
        options.graph.startBatch('arrowhead-move');
      }
      return true;
    }

    const result = model.move(visualSource, visualTarget, visualLink);
    if (result.success === false) { // Check according to old OPL table
      this.setPrevious();
      validationAlert(result.message, 5000);
      return false;
    }

    const portData = this.createPortForMovedLink(cellView, source, target, options, $event);
    if (portData)
      this.set(portData.side, { id: this.get(portData.side).id, port: portData.portId });

    const fundamentals = [linkType.Exhibition, linkType.Aggregation, linkType.Generalization, linkType.Instantiation];
    if (!fundamentals.includes(visualLink.type) && this.getSourceElement().constructor.name.includes('Triangle'))
      this.attributes.previousSourceId = visualSource.id;
    this.attributes.previousTargetId = visualTarget.id;
    visualLink.sourceVisualElementPort = this.mainUpperLink ? this.mainUpperLink.get('source').port : this.get('source').port;
    visualLink.targetVisualElementPort = this.get('target').port;

    if (this.getSourceElement().constructor.name.includes('Semi')) {
      const bestSourcePort = OpmSemifoldedFundamental.bestSemiFoldedPort(this.getSourceElement().getVisual().fatherObject,
        this.getTargetElement().getVisual());
      this.set('source', { id: this.getSourceElement().id, port: bestSourcePort });
      visualLink.sourceVisualElementPort = bestSourcePort;
    } else if (this.getTargetElement().constructor.name.includes('Semi')) {
      const bestTargetPort = OpmSemifoldedFundamental.bestSemiFoldedPort(this.getTargetElement().getVisual().fatherObject,
        this.getSourceElement().getVisual());
      this.set('target', { id: this.getTargetElement().id, port: bestTargetPort });
      visualLink.targetVisualElementPort = bestTargetPort;
    }

    if (source.removeUnusedPorts)
      source.removeUnusedPorts();
    if (target.removeUnusedPorts)
      target.removeUnusedPorts();

    return true;
  }

  createPortForMovedLink(linkView, source, target, init, $event) {
    let shape;
    if (!this.lastPointerUpLocation)
      return undefined;
    if (!$event || $event.toElement.tagName === 'tspan' || !$event.toElement.parentElement) return undefined;
    let leftPointerOn = $event.toElement.parentElement.getAttribute('model-id');
    if (!leftPointerOn) leftPointerOn = $event.toElement.parentElement.parentElement.getAttribute('model-id');
    if (!leftPointerOn) leftPointerOn = $event.toElement.parentElement.parentElement.parentElement.getAttribute('model-id');
    if (!leftPointerOn) return undefined;
    let element = (leftPointerOn === target.get('id')) ? target : source;
    if (!OPCloudUtils.isInstanceOfDrawnEntity(element))
      return undefined;
    const side = (element === source) ? 'source' : 'target';
    const bbox = { x: element.get('position').x, y: element.get('position').y, width: element.get('size').width, height: element.get('size').height };
    if (element.constructor.name.includes('Process'))
      shape = new geometry.g.Ellipse.fromRect(bbox);
    else shape = new geometry.g.rect(bbox);
    const line = new geometry.g.line(linkView.getPointAtRatio((side === 'source') ? 1 : 0), this.lastPointerUpLocation);
    line.setLength(line.length() + 10);
    const intersectionPoints = shape.intersectionWithLine(line);
    // TODO: select the correct point if there are more the one intersection points.
    if (!intersectionPoints) return;
    let closestPoint = { x: 0, y: 0 };
    if (intersectionPoints.length > 0) {
      const distances = intersectionPoints.map(p =>
        (distanceBetweenPoints(p, this.lastPointerUpLocation)));
      const min = Math.min(...distances);
      closestPoint = intersectionPoints[distances.indexOf(min)];
    } else closestPoint = intersectionPoints[0];
    closestPoint.x = closestPoint.x - element.get('position').x;
    closestPoint.y = closestPoint.y - element.get('position').y;
    const newPortId = element.addCustomPort(closestPoint);
    this.removeTools();
    element.animatePort(newPortId, init);
    return { portId: newPortId, side: side }
  }

  pointerUpHandle(cellView, options, $event) {
    this.refactoredPointerUpHandle(cellView, options, $event);
    // this.resizePort();
    // if ($("[joint-selector=button]") || $("[joint-selector=icon]") || $("[data-tool-name=source-arrowhead]")) {
    //   $("[joint-selector=button]").remove();
    //   $("[joint-selector=icon]").remove();
    //   $("[data-tool-name=source-arrowhead]").remove();
    // }
    // let errorMessage;
    // const prevSourceId = this.get('previousSourceId');
    // const prevTargetId = this.get('previousTargetId');
    // const previousSource = options.graph.getCell(prevSourceId);
    // const previousTarget = options.graph.getCell(prevTargetId);
    // let source = this.getSourceElement();
    // let target = this.getTargetElement();

    // const isLegalConnection = this.checkIflegalSourceConnection(this, source);

    // if (this.attributes.name.includes('defaultLink')) {
    //   this.resizePort();
    //   const newport = this.get('source').port;
    //   if (!source && target) {
    //     const prev = this.graph.getConnectedLinks(target, { outbound: true });
    //     this.set({ 'source': { 'id': prev[0].sourceElement.id } });
    //     this.set('previousSourceId', prev[0].sourceElement.id);
    //     return;
    //   }
    //   if (source && previousSource && source.get('type').includes(previousSource.get('type')) && isLegalConnection) {
    //     if (previousSource === source) {
    //       this.set('previousSourceId', prevSourceId);
    //       return;
    //     }
    //     this.set({ 'source': (newport) ? { 'id': source.id, port: newport } : { 'id': source.id } });
    //     // this.set({ 'source': { 'id': source.id } });
    //     this.set('previousSourceId', prevSourceId);
    //     const triangle = target;
    //     if (triangle) {
    //       this.setNewSourceForVisuals(source, newport, this, options);
    //     }
    //     return;
    //   }
    //   if (!source || (prevSourceId && source.id !== prevSourceId)) {
    //     this.set({ 'source': { 'id': prevSourceId } });
    //     this.set('previousSourceId', prevSourceId);
    //     const triangle = target;
    //     if (triangle) {
    //       this.setNewSourceForVisuals(previousSource, null, this, options);
    //     }
    //     let errMsg;
    //     if (!source)
    //       errMsg = 'A link must have a source element.';
    //     else
    //       errMsg = 'This action is not allowed.1';
    //     validationAlert(errMsg, 5000);
    //     return;
    //   }
    // }
    // if (this.attributes.name.includes('Aggregation-Participation') ||
    //   this.attributes.name.includes('Exhibition-Characterization') ||
    //   this.attributes.name.includes('Generalization-Specialization') ||
    //   this.attributes.name.includes('Classification-Instantiation')) {
    //   const isLegalTargetConnection = this.checkIflegalTargetConnection(this, target);
    //   const isSameType = target ? target.get('type').includes(previousTarget.get('type')) : null;
    //   if (target && previousTarget && isSameType && isLegalTargetConnection) {
    //     if (previousTarget === target) {
    //       this.set('previousTargetId', prevTargetId);
    //       return;
    //     }
    //     this.set({ 'target': { 'id': target.id } });
    //     this.set('previousTargetId', prevTargetId);
    //     return;
    //   }
    //   if (!target || (prevTargetId && target.id !== prevTargetId)) {
    //     this.set({ 'target': { 'id': prevTargetId } });
    //     this.set('previousTargetId', prevTargetId);
    //     let errMsg;
    //     if (!target)
    //       errMsg = 'A link must have a target element.';
    //     else
    //       errMsg = 'This action is not allowed.2';
    //     validationAlert(errMsg, 5000);
    //     return;
    //   }
    // }

    // /**
    //  * Alon: Check that the same link will not be connected between the same source and target things.
    //  */
    // if (target && source) {
    //   if ((this.constructor.name === 'OpmDefaultLink' && target instanceof Note) || (this.constructor.name === 'OpmDefaultLink' && source instanceof Note)) { // adding note link to the opd array
    //     // linkDrawing.deletePredefinedLinks(new Array(this), options.graph, false, false);
    //     options.opmModel.currentOpd.addNoteLink(cellView.model.attributes);
    //   }
    //   const linksOut = this.graph.getConnectedLinks(source, { outbound: true });
    //   if (this.attributes.name !== 'defaultLink') {
    //     let linkToCheck;
    //     for (let i = 0; i < linksOut.length; i++) {
    //       if (linksOut[i].id !== this.id /* && linksOut[i].attributes.name === this.attributes.name */) { // If the link in index[i] is not the link im moving
    //         // from one elemenet to another but it is the same type of link as the one im moving save it to check
    //         linkToCheck = linksOut[i];
    //       }
    //     }
    //     if (linkToCheck && linkToCheck.attributes.source.id === this.attributes.source.id && linkToCheck.attributes.target.id === this.attributes.target.id) {
    //       if (this.attributes.source.id !== prevSourceId) {
    //         this.set({ 'source': { 'id': prevSourceId } });
    //       } else if (this.attributes.target.id !== prevTargetId) {
    //         this.set({ 'target': { 'id': prevTargetId } });
    //       }
    //       errorMessage = 'Can not connect two links between the same source and target';
    //       // errorMessage = 'Can not connect the same type of link between the same source and target things';
    //     }
    //   }
    // }

    // if (!target && !source) {
    //   // the link is disconnected, nothing to do
    //   return;
    // }

    // if (!target) {
    //   // check if target is a collection of selected element(s)
    //   if (this.get('target').x != null && this.get('target').y != null) {
    //     const elementInPoint = this.graph.findModelsFromPoint(this.get('target'))[0];
    //     options.selection.collection.models = options.selection.collection.models.filter(
    //       item => !item.constructor.name.includes('Triangle'));
    //     const selection = options.selection.collection.models;
    //     if (elementInPoint && (selection.length > 0) && (source !== elementInPoint) && selection.includes(elementInPoint)) {
    //       this.set('target', { id: elementInPoint.get('id') });
    //       target = elementInPoint;
    //       this.selection = options.selection;
    //       if (target.id === source.get('parent')) {
    //         errorMessage = 'A State can\'t be connected to its parent element';
    //       }
    //     } else {
    //       // If a new link and not connected, show error
    //       errorMessage = 'A link must be connected to a target element';
    //     }
    //   }
    // } else if (!source) {
    //   errorMessage = 'A link must be connected to a source element';
    // } else { // Both source and target exist
    //   const sourceString = source.constructor.name.substr(3, source.constructor.name.length); // removing the "Opm"
    //   const exemptionForSubObjectTOProcess = (source.constructor.name === 'OpmObject' && target.constructor.name === 'OpmProcess');
    //   if (this.constructor.name !== 'OpmDefaultLink' && source instanceof Note || this.constructor.name !== 'OpmDefaultLink' && target instanceof Note) {
    //     if (source instanceof Note) {
    //       this.set('source', { id: prevSourceId });
    //       this.set('source', { id: this.sourceElement.id });
    //     }
    //     this.set('target', { id: prevTargetId });
    //     this.set('target', { id: this.targetElement.id });
    //     validationAlert('This action is not allowed3', 2500, 'Error');
    //   }
    //   if (target.id === source.get('parent') && !exemptionForSubObjectTOProcess) {

    //     if (target.get('type') === 'opm.Process' && source.get('type') === 'opm.Process') {
    //       let embeds = target.get('embeds');
    //       embeds = embeds.filter(el => {
    //         let x = cellView.model.graph.getCell(el);
    //         x = x.get('type');
    //         return x === 'opm.Process'; });
    //       if (embeds && embeds.length && embeds[embeds.length - 1] !== source.id) {
    //         errorMessage = `${sourceString} must be the last embedded sub process to self invoke its parent!`;
    //       }
    //       if (!this.get('target')) {
    //         errorMessage = errorMessage || `${sourceString} must connect to a port of the parent (of the sub process)!`;
    //       }
    //     } else { // In general - if target is the parent of the source
    //       errorMessage = errorMessage || `${sourceString} cannot be connected to its parent!`;
    //     }
    //   } else if (source.id === target.get('parent')) { // new for issue
    //     // If source is the parent of the target
    //     errorMessage = `${sourceString} cannot be connected to its embedded!`;
    //   } else if (source.id === target.id) {
    //     if (target.constructor.name === 'OpmProcess' &&
    //       (this.constructor.name === 'SelfInvocationLink' || this.constructor.name === 'OpmDefaultLink')
    //       //    && (this.get('previousTargetId') !== target))
    //     ) {
    //       const embeds = target.get('embeds');
    //       source = (embeds && embeds.length) ? embeds[embeds.length - 1] : source;
    //     } else {
    //       errorMessage = 'An element cannot be connected to itself!';
    //     }
    //   } else if ((source.constructor.name === 'OpmState') && (target.constructor.name === 'OpmState') &&
    //     source.get('parent') === target.get('parent')) {
    //     // If source and target are states of the same object
    //     errorMessage = 'A link cannot connect between two states inside the same object!';
    //   } /*else if (this.paths && this.paths.hasPath()) {
    //     const changed = (source !== this.sourceElement) ? this.sourceElement : this.targetElement;
    //     const changedTo = (source !== this.sourceElement) ? source : target;
    //     if (changed.constructor.name === 'OpmProcess') {
    //       errorMessage = 'you must keep this link connect to the same process';
    //     } else {
    //       if (changed.parent() !== changedTo.parent())
    //         errorMessage = 'you must keep this link connect to a state of the same object';
    //       else {
    //         const paths = this.paths.getPaths();
    //         if (paths.length > 1)
    //           errorMessage = 'multipathed link cannot be moved';
    //         else {
    //           const partner = paths[0].partner;
    //           if (source !== this.sourceElement) { // Source state changed
    //             if (partner.targetElement === source)
    //               errorMessage = 'cannot connect to the starting state';
    //             else if (partner.paths.getPaths().find(p => p.partner.sourceElement === source))
    //               errorMessage = 'this path already exist';
    //           } else { // Target state changed
    //             if (partner.sourceElement === target)
    //               errorMessage = 'cannot connect to the starting state';
    //             else if (partner.paths.getPaths().find(p => p.partner.targetElement === target))
    //               errorMessage = 'this path already exist';
    //           }
    //         }
    //       }
    //     }
    //     if (!errorMessage) {
    //       this.targetElement = target;
    //       this.sourceElement = source;
    //     }
    //   } */
    //   else if ((target.constructor.name === 'OpmProcess') && (target.getEmbeddedCells().length != 0) &&
    //     ((this.get('name') === 'Consumption'))) {//|| (this.get('name') === 'Effect')
    //     // Have an in-zoomed process and changing the target of a consumption\effect link to the containing process
    //     errorMessage = 'This process can\'t be the target of this link';
    //   } else if ((source.constructor.name === 'OpmProcess') && (source.getEmbeddedCells().length != 0) &&
    //     ((this.get('name') === 'Result') || (this.get('name') === 'Effect'))) {
    //     // Have an in-zoomed process and changing the source of a result\effect link to the containing process
    //     errorMessage = 'This process can\'t be the source of this link';
    //   } else if ((source.constructor.name === 'OpmState') || (target.constructor.name === 'OpmState')) {
    //     const logicalParentSource = options.opmModel.getLogicalElementByVisualId(source.get('parent'));
    //     const logicalParentTarget = options.opmModel.getLogicalElementByVisualId(target.get('parent'));
    //     if ((logicalParentSource && logicalParentSource.value && (logicalParentSource.value !== 'None')) ||
    //       (logicalParentTarget && logicalParentTarget.value && (logicalParentTarget.value !== 'None')))
    //       errorMessage = 'can\'t link a state which represents a value';
    //   } /*else if (target.attributes.type === 'opm.Notecell') {
    //     linkDrawing.deletePredefinedLinks(new Array(this), options.graph, false, false);
    //   }*/ else if (this.triangle && (source.constructor.name !== 'TriangleClass')) {
    //     errorMessage = 'link must be connected to triangle';
    //   }
    //   // TODO check and  correct update of previous target/source id's in linkDrawing
    //   if (this['partner']) {
    //     const partner = this.graph.getCell(this['partner']);
    //     if (source.constructor.name === 'OpmState' && source === partner.getTargetElement()) {
    //       this.set('source', { id: prevSourceId });
    //       this.set('source', { id: this.sourceElement.id });
    //       validationAlert('This action is not allowed4', 2500, 'Error');
    //     } else if (target.constructor.name === 'OpmState' && target === partner.getSourceElement()) {
    //       this.set('target', { id: prevTargetId });
    //       this.set('target', { id: this.targetElement.id });
    //       validationAlert('This action is not allowed5', 2500, 'Error');
    //     }
    //     // TODO: turn in to effectLink 21.03.19
    //     if ((partner.getTargetElement() === this.getSourceElement() && (partner.getTargetElement().constructor.name === 'OpmObject'))) {
    //       this.set('source', { id: prevSourceId });
    //       this.set('source', { id: this.sourceElement.id });
    //       validationAlert('This action is not allowed6', 2500, 'Error');
    //       // linkDrawing.drawLinkSilent(this.graph,'Effect', partner.getTargetElement(), this.getTargetElement());
    //       // this.remove()
    //     }
    //     if ((partner.getSourceElement() === this.getTargetElement() && (this.getTargetElement().constructor.name === 'OpmObject'))) {
    //       this.set('target', { id: prevTargetId });
    //       this.set('target', { id: this.targetElement.id });
    //       validationAlert('This action is not allowed7', 2500, 'Error');
    //       // linkDrawing.drawLinkSilent(this.graph,'Effect', partner.getSourceElement(), this.getSourceElement());
    //       // this.remove()

    //     }
    //     // if (this.graph.getCell(this._previousAttributes.target).get('type') === 'opm.Object' && partner.graph.getCell(partner._previousAttributes.source).get('type') === 'opm.Object' ) {
    //     //   linkDrawing.drawLinkSilent(this.graph, 'Effect' ,this._previousAttributes.target , partner._previousAttributes.target);
    //     //   this.remove();
    //     //   partner.remove();
    //     // }

    //   }
    // }
    // let linkValidation;
    // if (!errorMessage && source && target) {
    //   linkValidation = LinkConstraints.isValidLink(this, this.attributes.name, options);
    //   if (!(linkValidation.isValidLink)) {
    //     errorMessage = linkValidation.errorMessage;
    //   }
    // }
    // if (errorMessage) {
    //   // source illegal update, connect to previously disconnected source
    //   if ((prevSourceId && !source) || (prevSourceId && source && (prevSourceId !== source.id))) {
    //     if (this.triangle) {
    //       this.set({ 'source': { 'id': prevSourceId, port: 'out' } });
    //     } else
    //       this.set({ 'source': { 'id': prevSourceId } });
    //     // target illegal update, connect to previously disconnected target
    //   } else if ((prevTargetId && !target) || (prevTargetId && target && (prevTargetId !== target.id))) {
    //     this.set({ 'target': { 'id': prevTargetId } });
    //   } else
    //     this.remove();
    //   // Had an error, show the message and delete the link
    //   validationAlert(errorMessage);
    //   return;
    // }
    // // If got here, had no errors
    // const relevantLinks = oplFunctions.generateLinksWithOpl(this);
    // if (relevantLinks.length === 0) {
    //   // There are no links available between source and according to OPM ISO
    //   if (linkValidation.isValidLink) {
    //     this.set('previousTargetId', target.id);
    //     this.set('previousSourceId', source.id);
    //   }
    //   return; // nothing to do
    // }
    // const isSrcUpdate = prevSourceId !== source.id;
    // const isTargetUpdate = prevTargetId !== target.id;
    // if (!prevTargetId) {
    //   // If a new link, save current connections
    //   // the previous values are saved here so that if a link is chosen from the dialog,
    //   // the data will be available for the next updates for this link
    //   this.set('previousTargetId', target.id);
    //   this.set('previousSourceId', source.id);
    //   if (!target.cameFromInZooming && !(this.partner)) { // there is no such flag(cameFromInZooming)
    //     createDialog(options, this);
    //   }
    // } else if (isSrcUpdate || isTargetUpdate) { // will not enter here in case the user moved the link and returned to the same entity
    //   const linkIsLegal = relevantLinks.filter((link) => (link.name === this.get('name'))).length !== 0;
    //   if (linkIsLegal) {
    //     // the current link between source and target is legal
    //     this.set('previousTargetId', target.id);
    //     this.set('previousSourceId', source.id);
    //   } else if (!(this.partner)) {
    //     // the current link for the current source and target is illegal,
    //     // connect it back to the previous source\target (canceling the action)
    //     // and show an error flash message
    //     const sourceString = source.constructor.name.substr(3, source.constructor.name.length); // removing the "Opm"
    //     const targetString = target.constructor.name.substr(3, source.constructor.name.length); // removing the "Opm"
    //     errorMessage = `This link cannot be connected between ${sourceString} and ${targetString}.`;
    //     validationAlert(errorMessage, 5000); // show for 5 seconds
    //     if (isSrcUpdate && !(this.partner)) {
    //       // the source changed, update to previous source
    //       this.set({ 'source': { 'id': prevSourceId } });
    //     } else {
    //       // the target changed, update to previous target
    //       this.set({ 'target': { 'id': prevTargetId } });
    //     }
    //   }
    // }
  }

  changeAttributesHandle(options) {
  }

  changeSizeHandle(initRappid) {
  }

  changePositionHandle(initRappid) {
  }

  addHandle(options) {
  }

  removeHandle(options) {
    const source = options.graph.getCell(this.get('source').id);
    const target = options.graph.getCell(this.get('target').id);
    if (target && (target.get('type') === 'opm.TriangleAgg'))
      target.remove();
    if (source && source.removeUnusedPorts && source.getVisual && source.getVisual())
      source.removeUnusedPorts();
    if (target && target.removeUnusedPorts && target.getVisual && target.getVisual())
      target.removeUnusedPorts();
  }

  getTargetArcOnLink() {
  }

  getSourceArcOnLink() {
  }

  drawArc(initRappid, side, element, port, linkDirection) {
  }
  checkPortsValidity() {
    const sourceElement = this.getSourceElement();
    const sourcePort = this.get('source').port;
    const targetElement = this.getTargetElement();
    const targetPort = this.get('target').port;
    if (!sourceElement.hasPort(sourcePort)) {
      this.attributes.source.port = null;
      validationAlert('Please note: you model had a link connected to ' +
        'invalid port which was removed. A XOR or an OR relation may disconnected');
    }
    if (!targetElement.hasPort(targetPort)) {
      this.attributes.target.port = null;
      validationAlert('Please note: you model had a link connected to ' +
        'invalid port which was removed. A XOR or an OR relation may disconnected');
    }
  }
  validatePort(port) {
    if (port > 100 && port <= 130) {
      port -= 100;
    }
    port = Math.max(1, Math.min(port, 30))
    return port;
  }
  resizePort() {
    // in and out ports are in triangle of fundamental links
    // if (this.get('source').port && (this.get('source').port !== 'out')) {
    //   const sourcePort = +this.get('source').port;
    //   const sourceElement = this.getSourceElement();
    //   const updatedPort = this.validatePort(sourcePort);
    //   if (updatedPort !== sourcePort) {
    //     this.source(sourceElement, {port: updatedPort});
    //   } else {
    //     // sourceElement.portProp(+sourcePort, 'attrs/rect', {width: 1, height: 1, x: 0, y: 0});
    //   }
    // }
    // if (this.get('target').port && (this.get('target').port !== 'in')) {
    //   const targetPort = this.get('target').port;
    //   const targetElement = this.getTargetElement();
    //   const updatedPort =  this.validatePort(targetPort);
    //   if (updatedPort !== targetPort) {
    //     this.target(targetElement, {port: updatedPort}); // port: updatedPort});
    //  } else {
    //     // targetElement.portProp(+targetPort, 'attrs/rect', {width: 1, height: 1, x: 0, y: 0});
    //   }
    // }
  }

  isForkedLink(): boolean {
    return false;
  }

  setLinkTools(linkView, isTouch?) {
    const verticesTool = new joint.linkTools.Vertices({ snapRadius: 4, vertexAdding: !this.isForkedLink() });
    const segmentsTool = new joint.linkTools.Segments();
    const sourceArrowheadTool = new joint.linkTools.SourceArrowhead();
    const targetArrowheadTool = new joint.linkTools.TargetArrowhead();
    const sourceAnchorTool = new joint.linkTools.SourceAnchor();
    const targetAnchorTool = new joint.linkTools.TargetAnchor();
    const boundaryTool = new joint.linkTools.Boundary();

    const this_ = this;

    const removeButton = new joint.linkTools.Remove({
      distance: isTouch ? -35 : -20,
      rotate: false,
      markup: [{
        tagName: 'circle',
        selector: 'button',
        attributes: {
          'r': isTouch ? 10 : 7,
          'fill': '#FF1D00',
          'cursor': 'pointer'
        }
      }, {
        tagName: 'path',
        selector: 'icon',
        attributes: {
          'd': 'M -3 -3 3 3 M -3 3 3 -3',
          'fill': 'none',
          'stroke': '#FFFFFF',
          'stroke-width': 2,
          'pointer-events': 'none',
          'transform': isTouch ? 'scale(1.5)' : 'scale(1)',
        }
      }],
      action: function (evt) {
        this_.removeAction();
      }
    });

    let tools = this.getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton);
    if (this.getSourceElement()?.constructor.name.includes('Semi') && tools.includes(sourceArrowheadTool))
      tools.splice(tools.indexOf(sourceArrowheadTool), 1);
    // temporary until we can support changing source/target and vertices with touch
    if (isTouch)
      tools = tools.filter(t => ![verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool].includes(t));
    const toolsView = new joint.dia.ToolsView({
      tools: tools
    });
    linkView.addTools(toolsView);
    // if (isTouch) {
    // scaling up the tools icons size when it is touch event
    // for ( const toolHead of $('.target-arrowhead, .source-arrowhead')) {
    //   toolHead.setAttribute('transform' , toolHead.getAttribute('transform') + 'scale(1.5)');
    // }
    // }
  }

  removeAction() {
    const initRappid: InitRappidService = getInitRappidShared();
    if (this.isNoteLink(this.getSourceElement(), this.getTargetElement())) {
      initRappid.getOpmModel().currentOpd.removeNoteLink(this.id);
      this.remove();
    } else {
      initRappid.showRemoveOptions(this);
    }
  }

  getToolsArray(verticesTool, segmentsTool, sourceArrowheadTool, targetArrowheadTool, sourceAnchorTool, targetAnchorTool, boundaryTool, removeButton) {//Noa: so we can edit default link that is connected to notes
    if (this.get('OpmLinkType')) {
      return (this.get('source') && this.get('source') && this.graph.getCell(this.get('source').id) &&
        this.graph.getCell(this.get('source').id).attributes.attrs.digitalTwinConnected) ?
        [verticesTool, segmentsTool] : [verticesTool, segmentsTool, removeButton, sourceArrowheadTool];
    } else {
      return [verticesTool, segmentsTool, removeButton];
    }
  }

  rightClickHandle(linkView, event, init?: InitRappidService) {
    const currentOpd = init.getOpmModel().currentOpd;
    if (currentOpd.isStereotypeOpd() || init.isDSMClusteredView.value === true ||
      currentOpd.sharedOpdWithSubModelId || currentOpd.belongsToSubModel)
      return;
    linkView.hideTools();
    if (this.popupContentDbClick) {

      if (this.get('vertices')) {
        const clickPoint = init.paper.clientToLocalRect(event.clientX, event.clientY);
        // delete new vertex that was created because of the double click
        const dummyVertex = this.get('vertices').filter((vertex) => Math.abs(vertex.x - clickPoint.x) <= 5).filter((vertex) => Math.abs(vertex.y - clickPoint.y) <= 5)[0];
        const realVertices = this.get('vertices').filter((vertex) => vertex !== dummyVertex);
        this.set('vertices', realVertices);
      }
      this.rightClickHandlePopoup(linkView?.el || event.target, init);
      if (init.linkCopiedStyleParams)
        this.pasteLinkStyle(init.linkCopiedStyleParams, init);
    }
  }

  rightClickHandlePopoup(target, init) {
    /**
     * group 04:
     * Here we added the 'Copy Style' button, next to the 'update' and 'style' buttons.
     */
    const popupContent = this.popupContentDbClick().concat(['<div style="padding-top: 8px"><button class="Popup btnUpdate" style="margin-left: 16px;">Update</button> &nbsp&nbsp&nbsp; <button class="Popup btnStyle" >Style</button>&nbsp&nbsp&nbsp; <button class="Popup btnStyleCopy" >Copy Style</button></div>']);
    popupGenerator(target, popupContent, this.popupEventsDbClick(this, init)).render();
    popupInputsEnterListener();

    stylePopup();
    if ($('.PopupInput')[0]) {
      $('.PopupInput')[0].focus();
    }
  }

  /**
   * group 04:
   * This function updates the target link's style using the dictionary it receives(the dictionary includes the desired source style).
   * In case of link with triangle, we make sure that the 2 separated parts of the link will be with the same style.
   * @param linkCopiedStyleParams
   * @param init
   */
  pasteLinkStyle(linkCopiedStyleParams, init?: InitRappidService) {
    if (this.getSourceElement() && this.getSourceElement().constructor.name.includes('Triangle')) {
      const upperLink = (<any>this).getMainUpperLink();
      upperLink.attr({ line: { 'strokeWidth': init.linkCopiedStyleParams['strokeWidth'] } });
      upperLink.attr({ line: { 'stroke': init.linkCopiedStyleParams['strokeColor'] } });
    }
    // console.log('copy_dict:', init.linkCopiedStyleParams);
    this.attr({ line: { 'strokeWidth': init.linkCopiedStyleParams['strokeWidth'] } });
    this.attr({ line: { 'stroke': init.linkCopiedStyleParams['strokeColor'] } });
    init.linkCopiedStyleParams = null;

  }
  /**
   * Alon: Sets Labels on links with default position
   * @param text
   * @param distance
   * @param offsetX
   * @param offsetY
   * @param offset
   * @param labelIndex
   * @param oldText
   */

  setLabelsOLinks(text: string, distance: number, offsetX: number = 0, offsetY?: number, offset?, labelIndex?, keepGradient = true) {
    if (!text) return;
    const labelIdx = (labelIndex) ? labelIndex : this.labels().length++;
    const labelData = {
      markup: [
        {
          tagName: 'text',
          selector: 'label',
        }
      ],
      attrs: {
        label: {
          event: 'label:pointerclick',
          textAnchor: 'middle',
          textVerticalAnchor: 'middle',
          fill: 'black',
          fontSize: 16,
          x: (offsetX !== undefined && offsetX !== null) ? offsetX : 0,
          y: (offsetY !== undefined && offsetY !== null) ? offsetY : 10,
          text: text,
        },
      },
      position: {
        distance: distance,
        angle: 0,
        offset: {
          x: 0,
          y: 0
        },
        // offset: {x: (offsetX !== undefined && offsetX !== null) ? offsetX : 5, y: (offsetY !== undefined && offsetY !== null) ? offsetY : -5},
        args: {
          keepGradient: keepGradient,
          ensureLegibility: true,
        }
      },
    };
    this.label(labelIdx, labelData);
    return labelData;
  }

  /**
   * Alon: return a link's labels array
   */
  getLabels(): Array<any> {
    return this.labels();
  }

  /**
   * Alon: Extract the distance and offset of a label and overwrites the default
   * position values of a label
   * TODO: Call this function from the update label function so after you update labels the location will be also saved
   */
  extractLabelsPositions(): void {
    let distance: number;
    let offset: number;
    let text: string;
    let labelArrayWithtext = this.getLabels().filter((label) => label.attrs.label.text !== '');
    for (let i = 0; i < labelArrayWithtext.length; i++) {
      text = labelArrayWithtext[i].attrs.label.text;
      distance = labelArrayWithtext[i].position.distance;
      offset = labelArrayWithtext[i].position.offset;
      this.setLabelsOLinks(text, distance, null, null, offset, i);
    }
  }


  updateParamsFromOpmModel(link) {
    this.set('source', { id: link.source.id, port: link.sourceVisualElementPort });
    if (this.getTargetElement() && this.getTargetElement().constructor.name.includes('Triangle'))
      this.set('target', link.target);
    else
      this.set('target', { id: link.target.id, port: link.targetVisualElementPort });
    this.set('id', link.id);
  }

  addDblClickListenerForLabels() {
    // TODO: remove in the future if rappid's labels click events works good.
    // const that = this;
    // const init = getInitRappidShared();
    // const linkView = init.paper.findViewByModel(this);
    // if (!linkView) return;
    // const linkEl = linkView.el;
    // for (const child of linkEl.children) {
    //   if (child.className.baseVal === 'labels') {
    //     for (const label of child.children) {
    //       label.ondblclick = function ($event = null) {
    //         that.rightClickHandle(linkView, $event, init);
    //         for (const inputField of $('.PopupInput')) {
    //           const inputValue = (<HTMLInputElement>inputField).value;
    //           if (label.textContent === inputValue || (label.textContent.includes('0.') && inputValue.includes('0.'))) {
    //             inputField.focus();
    //             (inputField as HTMLInputElement).select();
    //             return;
    //           }
    //         }
    //       };
    //     }
    //   }
    // }
  }

  getSourceMultiplicityPopupTooltipText(): string {
    return '';
  }
  getTargetMultiplicityPopupTooltipText(): string {
    return '';
  }
}
