import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementCommand, ElementHaloHandle, ElementAction, ElementToolbarHandle } from "./command";
import {validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";

export class RemoveComputationCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    createHaloHandle(): ElementHaloHandle {
        return {
            flag: true,
            name: 'remove-computation',
            displayTitle: 'Remove Computation',
            svg: 'deleteFunction',
            action: new RemoveComputationAction(this.init, this.thing)
        }
    }

    createToolbarHandle(): ElementToolbarHandle {
        return {
            name: 'remove-computation',
            displayTitle: 'Remove Computation',
            svg: 'deleteFunction',
            action: new RemoveComputationAction(this.init, this.thing),
            gif: '',
        }
    }

}

class RemoveComputationAction implements ElementAction {

    constructor(private readonly init: InitRappidService,
        private readonly thing: OpmThing) {
    }

    act() {
      const cell = this.init.graph.getCell(this.thing.id);
      if (cell.getVisual().logicalElement.belongsToFatherModelId) {
        validationAlert('Cannot remove computation for a shared thing with a father model.', 5000, 'Error');
        return;
      }
      if ((<OpmVisualThing>cell?.getVisual()).logicalElement.visualElements.find(v => v.protectedFromBeingChangedBySubModel)) {
        validationAlert('Cannot remove computation for a shared thing with a sub model.', 5000, 'Error');
        return;
      }
      if (cell)
        cell.removeComputational(this.init);
    }

}
