import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmProcess } from "../../DrawnPart/OpmProcess";
import { OpmVisualProcess } from "../../VisualPart/OpmVisualProcess";
import { BringConnectedCommand } from "./bring-connected";
import { ElementCommand, CommandsDecider } from "./command";
import { InzoomCommand } from "./inzoom";
import { RemoveCommand } from "./remove";
import { RemoveComputationCommand } from "./remove-comp";
import { UnfoldCommand } from "./unfold";
import { SetComputationCommand } from "./computation";
import { StylingCommand } from "./style";
import { SetProcessTimeDurationCommand } from "./set-process-time-duration";
import { ToggleAffiliationCommand } from './toggle-affiliation';
import { ToggleEssenceCommand } from './toggle-essence';
import { ToggleTextAutoFormatCommand } from './toggle-text-formation';
import {UpdateComputationCommand} from './updateComputationalProcess';
import {UserInputCommand} from "./user-input-command";

export class ProcessCommandsDecider extends CommandsDecider {

    private drawn: OpmProcess;
    private init: InitRappidService;
    private visual: OpmVisualProcess;

    set(init: InitRappidService, drawn: OpmProcess, visual: OpmVisualProcess): CommandsDecider {
        this.init = init;
        this.drawn = drawn;
        this.visual = visual;
        return this;
    }

    private inzoom(): ElementCommand {
        return new InzoomCommand(this.init, this.drawn, this.visual);
    }

    private setComputation(): ElementCommand {
        return new SetComputationCommand(this.init, this.drawn, this.visual);
    }

    private removeComp(): ElementCommand {
        return new RemoveComputationCommand(this.init, this.drawn, this.visual);
    }

    private updateComp(): ElementCommand {
      return new UpdateComputationCommand(this.init, this.drawn, this.visual);
    }

    private unfold(): ElementCommand {
        return new UnfoldCommand(this.init, this.drawn, this.visual);
    }

    private bringConnected(): ElementCommand {
        return new BringConnectedCommand(this.init, this.drawn, this.visual);
    }

    private remove(): ElementCommand {
        return new RemoveCommand(this.init);
    }

    private style(): ElementCommand {
        return new StylingCommand(this.init, this.drawn, this.visual);
    }

    private setTimeDuration(): ElementCommand {
        return new SetProcessTimeDurationCommand(this.init, this.drawn, this.visual);
    }

    private toggleAffiliation(): ElementCommand {
        return new ToggleAffiliationCommand(this.init, this.drawn, this.visual);
    }

    private toggleEssence(): ElementCommand {
        return new ToggleEssenceCommand(this.init, this.drawn, this.visual);
    }

    private toggleTextAutoFormat(): ElementCommand {
        return new ToggleTextAutoFormatCommand(this.init, this.drawn, this.visual);
    }

    private userInput(): ElementCommand {
      return new UserInputCommand(this.init, this.drawn, this.visual);
    }

    getHaloCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();

        commands.push(this.remove());

        if ((this.visual.isInzoomed() || this.visual.isUnfolded()) == false) {
            commands.push(this.setComputation());
        }

        if (this.visual.isComputational()) {
            commands.push(this.updateComp(), this.removeComp(), this.bringConnected());
        } else {
            commands.push(this.inzoom(), this.unfold(), this.bringConnected());
        }

        commands.push(this.style(), this.setTimeDuration());

        return commands;
    }

    getToolabarCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();

      if (this.visual.logicalElement.opmModel.currentOpd.requirementViewOf)
        return commands;

        commands.push(this.remove(), this.toggleAffiliation(), this.setTimeDuration());

        if ((this.visual.isInzoomed() || this.visual.isUnfolded()) == false) {
            commands.push(this.setComputation());
        }

        if (this.visual.isComputational()) {
            commands.push(this.removeComp(), this.bringConnected(), this.updateComp());
        } else {
            commands.push(this.inzoom(), this.unfold(), this.bringConnected(), this.toggleEssence());
        }

        if (this.visual.canModifyText())
            commands.push(this.toggleTextAutoFormat());

        if (this.visual.canUseUserInput())
            commands.push(this.userInput())

        return commands;
    }

}
