import {MethodologicalChecker, MethodologicalCheckingStatus} from "../methodological-checker";
import {OpmModel} from "../../../models/OpmModel";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalProcess} from "../../../models/LogicalPart/OpmLogicalProcess";

export class IngProcessesNamesChecker extends MethodologicalChecker {

  constructor(model: OpmModel) {
    super(model);
    this.title = '"ing" processes suffix (Gerund)';
  }

  check(): void {
    this.invalidThings = [];
    for (const thing of this.model.logicalElements) {
      if (OPCloudUtils.isInstanceOfLogicalProcess(thing)) {
        const bareName = (<OpmLogicalProcess>thing).getBareName();
        if (!bareName.toLowerCase().endsWith('ing')) {
          this.invalidThings.push(thing as OpmLogicalProcess);
        }
      }
    }
    this.status = this.invalidThings.length === 0 ? MethodologicalCheckingStatus.VALID : MethodologicalCheckingStatus.INVALID;
  }

  getDescriptionTooltip(): string {
    return `“ing” process suffix (Gerund) -
      If possible, a process should end with “ing”.`;
  }
}
