import { Injectable } from "@angular/core";

@Injectable()
export abstract class AbstractVersionService {

    constructor() {
    }

    abstract save(image?: string): void;

    abstract updateDB(obj: any): void;

    abstract areTemplatesSupported(): boolean;

    abstract areSubModelsSupported(): boolean;

    abstract isBackendSupported(): boolean;

    abstract renameModel(id, name): Promise<void>;

}
