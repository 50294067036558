import { BasicOpmModel } from "../../BasicOpmModel";
import { linkType, linkConnectionType, statesArrangement } from '../../ConfigurationOptions';
import { OpmLogicalObject } from '../../LogicalPart/OpmLogicalObject';
import { OpmLogicalState } from '../../LogicalPart/OpmLogicalState';
import { ValueAttributeType } from '../../modules/attribute-validation/attribute-range';
import { ValidationModule } from '../../modules/attribute-validation/validation-module';
import { OpmLink } from '../../VisualPart/OpmLink';
import { OpmVisualElement } from '../../VisualPart/OpmVisualElement';
import { OpmVisualObject } from '../../VisualPart/OpmVisualObject';

export interface RangeValidationStatus {
    hasRange: boolean,
    hasValueTypedObject: boolean
}

interface SetRangeResponse {
    wasSet: boolean,
    errors?: Array<string>,
    elements?: Array<OpmVisualElement>
}

interface RemoveRangeResponse {
    removed: boolean,
    elements?: ReadonlyArray<OpmVisualElement>
}

interface ToggleValueTypeObjectResponse {
    changed: boolean,
    current: 'on' | 'off',
    errors?: Array<string>,
    elements?: Array<OpmVisualElement>
}

interface HideValueTypeObjectResponse {
    hide: boolean,
    errors?: Array<string>,
    elements?: Array<OpmVisualElement>
}

export class RangeValidationAccess {

    private static STATES_NAME = [
        { title: 'int', type: ValueAttributeType.INTEGER },
        { title: 'float', type: ValueAttributeType.FLOAT },
        { title: 'string', type: ValueAttributeType.STRING },
        { title: 'char', type: ValueAttributeType.CHAR },
        { title: 'boolean', type: ValueAttributeType.BOOLEAN }
    ];

    constructor(private readonly model: BasicOpmModel) {
    }

    public setRange(visual: OpmVisualObject, range: { type: ValueAttributeType, pattern: string }): SetRangeResponse {
        const logical = visual.logicalElement as OpmLogicalObject;

        if (logical.isComputational() == false) {
            return { wasSet: false, errors: ['Cannot set range to a non-computational object.'] };
        }

        const computationModule = logical.getComputationModule();
        const validationModule = logical.getValidationModule();

        if (logical.getBelongsToStereotyped() && range.type !== validationModule.getType())
          return { wasSet: false, errors: ['Should match the stereotype\'s range type.'] };

        let stereotypeValidator;
        const stereotypeEquivalentLogical = logical.opmModel.getEquivalentLogicalThingFromStereotype(logical.equivalentFromStereotypeLID) as any;
        stereotypeValidator = stereotypeEquivalentLogical?.getValidationModule().getValidator();
        const ret = computationModule.setRange(range.type, range.pattern, stereotypeValidator);

        if (ret.wasSet == false) {
            return { wasSet: false, errors: ret.errors };
        }

        const prop = this.getProperties(visual);

        if (prop.valueType.logical == undefined) {
            // no range, create, bring and connect
            this.createRangeAtRangesOpd(visual.logicalElement as OpmLogicalObject);
            this.bringValueTypeElement(visual);
            this.connectValueTypeElement(visual);
        }
        this.applyOnValueTypeElement(validationModule);

        return {
            wasSet: true,
            elements: []
        };

    }

    public toggleValueTypeObject(visual: OpmVisualObject): ToggleValueTypeObjectResponse {
        const prop = this.getProperties(visual);
        let current: 'on' | 'off';

        if (prop.valueType.visualAtCurrentOpd) {
            // value-typed object is already presented
            if (prop.connector.visualAtCurrentOpd && prop.valueType.visualAtCurrentOpd) {
                // value-typed object is already connected - disconnect
                prop.valueType.visualAtCurrentOpd.remove();
                current = 'off';
            } else {
                // value-typed object is absent - connect
                this.connectValueTypeElement(visual);
                current = 'on';
            }
        } else {
            // value-typed object is not presented, bring and connect
            this.bringValueTypeElement(visual);
            this.connectValueTypeElement(visual);
            current = 'on';
        }

        return { changed: true, current: current };
    }

    public removeRange(visual: OpmVisualObject): RemoveRangeResponse {
        const prop = this.getProperties(visual);
        if (prop.hasRange == false)
            return { removed: false };
        return this.remove(visual.logicalElement as OpmLogicalObject);
    }

    public suppressStates(valueObject: OpmVisualObject): { success: boolean } {
        const toSuppress = valueObject.states.filter(s => (<OpmLogicalState>s.logicalElement).stateType !== 'Current')
        toSuppress.forEach(s => s.suppress());
        return { success: true };
    }

    // Hide Value Object along with it's links
    public hideValueObject(valueObject: OpmVisualObject): HideValueTypeObjectResponse {
        const ret = valueObject.remove();
        return { hide: ret.length > 0 };
    }

    public getState(visual: OpmVisualObject): RangeValidationStatus {
        const prop = this.getProperties(visual);

        return {
            hasRange: !!(prop.valueType.logical),
            hasValueTypedObject: !!(prop.connector.visualAtCurrentOpd),
        }
    }

    public remove(logical: OpmLogicalObject) {
        const validation = logical.getValidationModule();
        const someValueObjectVisual = validation.getValueTypeElement().visualElements[0];
        validation.removeRange();

        // We delete all instances - including from rangesOpd - this should do the job
        const ret = this.model.removeElementInModel(someValueObjectVisual);
        return ret;
    }

    private applyOnValueTypeElement(validationModule: ValidationModule) {
        // set current state according to the validation module
        const type = validationModule.getType();
        for (const state of validationModule.getValueTypeElement().states) {
            state.stateType = '';
            const number = RangeValidationAccess.STATES_NAME.findIndex(v => v.type == type);
            if (state.text == RangeValidationAccess.STATES_NAME[number].title)
                state.stateType = 'Current';
        }
    }

    private createValueTypeObject(): OpmVisualObject {
        const current = this.model.currentOpd;
        const rangesOpd = this.model.getOrCreateRangesOpd();
        this.model.currentOpd = rangesOpd;

        const logicalValueObject = this.model.createLogicalObject();
        logicalValueObject.text = 'Type';

        const visualRangedObject = logicalValueObject.visualElements[0];

        const intState = visualRangedObject.createState();
        (intState.logicalElement as OpmLogicalState).text = RangeValidationAccess.STATES_NAME.find(s => s.type == ValueAttributeType.INTEGER).title;

        const floatState = visualRangedObject.createState();
        (floatState.logicalElement as OpmLogicalState).text = RangeValidationAccess.STATES_NAME.find(s => s.type == ValueAttributeType.FLOAT).title;

        const stringState = visualRangedObject.createState();
        (stringState.logicalElement as OpmLogicalState).text = RangeValidationAccess.STATES_NAME.find(s => s.type == ValueAttributeType.STRING).title;

        const charState = visualRangedObject.createState();
        (charState.logicalElement as OpmLogicalState).text = RangeValidationAccess.STATES_NAME.find(s => s.type == ValueAttributeType.CHAR).title;

        const booleanState = visualRangedObject.createState();
        (booleanState.logicalElement as OpmLogicalState).text = RangeValidationAccess.STATES_NAME.find(s => s.type == ValueAttributeType.BOOLEAN).title;

        rangesOpd.addElements([visualRangedObject, intState, floatState, stringState, charState, booleanState]);

        this.model.currentOpd = current;

        return visualRangedObject;
    }

    public createFromStereotype(logical: OpmLogicalObject, range: { type: ValueAttributeType, pattern: string }) {
        const computationModule = logical.getComputationModule();
        computationModule.setRange(range.type, range.pattern, undefined);
        this.createRangeAtRangesOpd(logical);
        this.applyOnValueTypeElement(computationModule.validationModule);
    }

    private createRangeAtRangesOpd(logical: OpmLogicalObject) {
        const originalOpd = this.model.currentOpd;
        const rangesOpd = this.model.getOrCreateRangesOpd();

        this.model.currentOpd = rangesOpd;
        // Create logical value-type element and bring it to ranges-opd
        const valueObjectAtRangesOpd = this.createValueTypeObject();
        // Create a duplication visual at ranged opd
        const visualAtRangesOpd = this.model.createVisualThing(logical, rangesOpd) as OpmVisualObject;
        // Connect ranged-object with exhibition to valued-object
        this.model.links.connect(visualAtRangesOpd, valueObjectAtRangesOpd, { type: linkType.Exhibition, connection: linkConnectionType.systemic });
        this.model.currentOpd = originalOpd;

        // Set references
        logical.getValidationModule().setValueTypeElement(valueObjectAtRangesOpd.logicalElement as OpmLogicalObject);
        (valueObjectAtRangesOpd.logicalElement as OpmLogicalObject).valuedObjectFor = logical;
    }

    // Connect value-type object to current element
    private bringValueTypeElement(visual: OpmVisualObject): OpmVisualObject {
        const prop = this.getProperties(visual);

        if (prop.valueType.visualAtCurrentOpd) {
            // A value-typed object present at current opd
            return prop.valueType.visualAtCurrentOpd;
        }

        // A value-typed object is not presented at current opd, and we should bring it
        const valueObjectAtCurrentOpd = this.model.bringVisualToOpd(prop.valueType.logical, prop.opd) as OpmVisualObject;

        // Beutify
        valueObjectAtCurrentOpd.xPos = visual.xPos - 150;
        valueObjectAtCurrentOpd.yPos = visual.yPos + 200;
        valueObjectAtCurrentOpd.width = 160;
        valueObjectAtCurrentOpd.height = 215;
        valueObjectAtCurrentOpd.xPos = 190;
        valueObjectAtCurrentOpd.statesArrangement = statesArrangement.Right;

        valueObjectAtCurrentOpd.expressAll();

        valueObjectAtCurrentOpd.rearrange(statesArrangement.Right);

        return valueObjectAtCurrentOpd;
    }

    // Connect value-type object to current visual
    private connectValueTypeElement(visual: OpmVisualObject): OpmLink {
        const prop = this.getProperties(visual);

        if (!prop.valueType.logical || !prop.valueType.visualAtCurrentOpd)
            return;

        if (prop.connector.visualAtCurrentOpd) {
            // A value-typed object is already connected
            return prop.connector.visualAtCurrentOpd;
        }

        // A value-typed object is not connect
        return this.model.links.connect(visual, prop.valueType.visualAtCurrentOpd, { type: linkType.Exhibition, connection: linkConnectionType.systemic });
    }

    public getProperties(visual: OpmVisualObject) {
        const logical = visual.logicalElement as OpmLogicalObject;
        const validation = logical.getValidationModule();
        const currentOpd = this.model.getOpdByElement(visual);
        const hasRange = validation.isActive();

      if (hasRange == false || !currentOpd) {
            return {
                hasRange: false,
                valueType: {
                    logical: undefined,
                    visualAtCurrentOpd: undefined
                },
                connector: {
                    logical: undefined,
                    visualAtCurrentOpd: undefined
                },
                opd: currentOpd
            };
        }

        const valueTypeLogical = validation.getValueTypeElement();
        const valueTypedAtCurrentOpd = currentOpd.visualElements.find(v => v.logicalElement == valueTypeLogical) as OpmVisualObject;
        const links = logical.getLinksWith(valueTypeLogical);
        const connectorLogical = links.outGoing.find(l => l.linkType === linkType.Exhibition);

        let connectorAtCurrentOpd: OpmLink;
        if (connectorLogical && valueTypedAtCurrentOpd)
            connectorAtCurrentOpd = connectorLogical.visualElements.find(v => v.sourceVisualElement === visual && v.targetVisualElements[0].targetVisualElement === valueTypedAtCurrentOpd)

        return {
            hasRange: true,
            valueType: {
                logical: valueTypeLogical,
                visualAtCurrentOpd: valueTypedAtCurrentOpd
            },
            connector: {
                logical: connectorLogical,
                visualAtCurrentOpd: connectorAtCurrentOpd
            },
            opd: currentOpd,
        };
    }

}
