// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
  margin-top: 3px;
}

#main {
  text-align: center;
}

mat-form-field {
  width: 75px;
  text-align: center;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #1A3763 !important;
}

::ng-deep .mat-form-field-underline, ::ng-deep .mat-form-field-ripple {
  background-color: #1A3763 !important;
}

button {
  color: #1A3763;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/enter-email-verification-code-dialog/enter-email-verification-code-dialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,cAAc;AAChB;;AAEA;;EAEE,wBAAwB;EACxB,SAAS;AACX","sourcesContent":["h3 {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n  margin-top: 3px;\n}\n\n#main {\n  text-align: center;\n}\n\nmat-form-field {\n  width: 75px;\n  text-align: center;\n}\n\n::ng-deep .mat-form-field.mat-focused .mat-form-field-label {\n  color: #1A3763 !important;\n}\n\n::ng-deep .mat-form-field-underline, ::ng-deep .mat-form-field-ripple {\n  background-color: #1A3763 !important;\n}\n\nbutton {\n  color: #1A3763;\n}\n\ninput::-webkit-outer-spin-button,\ninput::-webkit-inner-spin-button {\n  -webkit-appearance: none;\n  margin: 0;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
