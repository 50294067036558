import {SatisfiedRequirementSetModule} from "./satisfied-requirement-set";
import {OpmVisualThing} from "../VisualPart/OpmVisualThing";
import {SatisfiedRequirementSetJSON} from "./hidden-attributes-interfaces";
import {OpmModel} from "../OpmModel";

export class HiddenAttributesModule {

  public satisfiedRequirementSetModule: SatisfiedRequirementSetModule;

  constructor(params: SatisfiedRequirementSetJSON, model: OpmModel) {
    this.satisfiedRequirementSetModule = new SatisfiedRequirementSetModule(params, model);
  }

  createSatisfiedRequirementSet(owner: OpmVisualThing) {
    return this.satisfiedRequirementSetModule.createSatisfiedRequirementSet(owner);
  }
}
