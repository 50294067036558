// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.selected {
  background: lightskyblue;
}

#userDetailsComponent {
  overflow: auto;
}

button{
  background: #1A3763;
  color: #fff;
}

hr{
  color: #1A3763;
}

::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {
  background-color: #0075ff !important; /* Red background for example */
}

::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: #c1ddff !important;
}

::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: #0075ff !important;
}

::ng-deep mat-form-field.mat-focused .mat-form-field-label {
  color: #0075ff !important;
}



`, "",{"version":3,"sources":["webpack://./src/app/dialogs/update-user-dialog/update-user-dialog.component.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,mBAAmB;EACnB,WAAW;AACb;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,oCAAoC,EAAE,+BAA+B;AACvE;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,oCAAoC;AACtC;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".selected {\n  background: lightskyblue;\n}\n\n#userDetailsComponent {\n  overflow: auto;\n}\n\nbutton{\n  background: #1A3763;\n  color: #fff;\n}\n\nhr{\n  color: #1A3763;\n}\n\n::ng-deep .mat-checkbox-checked.mat-accent .mat-checkbox-background, .mat-checkbox-indeterminate.mat-accent .mat-checkbox-background, .mat-accent .mat-pseudo-checkbox-checked, .mat-accent .mat-pseudo-checkbox-indeterminate, .mat-pseudo-checkbox-checked, .mat-pseudo-checkbox-indeterminate {\n  background-color: #0075ff !important; /* Red background for example */\n}\n\n::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-bar {\n  background-color: #c1ddff !important;\n}\n\n::ng-deep .mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {\n  background-color: #0075ff !important;\n}\n\n::ng-deep mat-form-field.mat-focused .mat-form-field-label {\n  color: #0075ff !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
