import {BasicOpmModel} from '../BasicOpmModel';
import {OpmVisualEntity} from '../VisualPart/OpmVisualEntity';
import {OpmLink, TargetElementData} from '../VisualPart/OpmLink';
import {ConnectionResult, Consistency} from './consistancy.model';
import {Essence, linkConnectionType, LinkLogicalConnection, linkType} from '../ConfigurationOptions';
import {OpmProceduralLink} from '../VisualPart/OpmProceduralLink';
import {OpmFundamentalLink} from '../VisualPart/OpmFundamentalLink';
import {OpmTaggedLink} from '../VisualPart/OpmTaggedLink';
import {OpmRelation} from '../LogicalPart/OpmRelation';
import {OpmProceduralRelation} from '../LogicalPart/OpmProceduralRelation';
import {OpmFundamentalRelation} from '../LogicalPart/OpmFundamentalRelation';
import {OpmTaggedRelation} from '../LogicalPart/OpmTaggedRelation';
import {OpmLogicalElement} from '../LogicalPart/OpmLogicalElement';
import {OpmVisualElement} from '../VisualPart/OpmVisualElement';
import {OpmOpd} from '../OpmOpd';
import {commutativeDirect, fundamental, LinksSet, structural, tagged} from './links.set';
import {EntityType, RelationType} from '../model/entities.enum';
import {OpmVisualState} from '../VisualPart/OpmVisualState';
import {OpmVisualProcess} from '../VisualPart/OpmVisualProcess';
import {OpmVisualThing} from '../VisualPart/OpmVisualThing';
import {OpmLogicalEntity} from '../LogicalPart/OpmLogicalEntity';
import {getInitRappidShared, initRappidShared, validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';

export interface ModelConnectResult {
    success: boolean;
    message?: string;
    created?: ReadonlyArray<OpmLink>;
    removed?: Array<OpmLink>;
    warnings?: Array<string>;
    changeAction?(...args);
}

export enum InOutPairType {
    Standart,
    Split,
    Condition,
    Event,
    Negation
}

export class LinksModel {

    constructor(private readonly model: BasicOpmModel) {
    }

    private readonly consistency: Consistency = new Consistency();

    public canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): ConnectionResult {
        return this.consistency.canConnect(source, target, link);
    }

    public isLegal(source: OpmVisualEntity, target: OpmVisualEntity): ConnectionResult {
        return this.consistency.isLegal(source, target);
    }

    public move(source: OpmVisualEntity, target: OpmVisualEntity, link: OpmLink): OpmLink {
        const type: linkType = (<any>(link.logicalElement)).linkType;
        if (type === linkType.Effect) {
          const hiddenLinks = this.isHavingHiddenInOuts(<any>link.sourceVisualElement, <any>link.targetVisualElements[0].targetVisualElement);
          const links = [...hiddenLinks.ins, ...hiddenLinks.outs];
          links.forEach(l => l.visible = true);
          if (initRappidShared && initRappidShared.getGraphService() && links.length > 0)
            initRappidShared.getGraphService().updateLinksView(links);
        }

        let relation = this.getExistingRelation(source, target, type);

        if (relation == undefined) {
            const opd = this.model.getOpdByElement(source);

            const params: LinkParams = { type: link.type, connection: linkConnectionType.enviromental };
            if (link instanceof OpmProceduralLink) {
                params.isCondition = (<any>link.logicalElement).condition;
                params.isEvent = (<any>link.logicalElement).event;
                params.isNegation = (<any>link.logicalElement).negation;
            }

            relation = createLogicalLink(params, this.model, opd, source.logicalElement, target.logicalElement);
            // Remove these after we change logical to not create a visual by default
            const badVisual = relation.visualElements[0];
            relation.removeVisual(badVisual);
            opd.removeVisual(badVisual);
        }

        if (relation !== link.logicalElement) {
            for (let i = link.logicalElement.visualElements.length - 1; i >= 0 ; i--)
                if (link.logicalElement.visualElements[i] === link)
                    link.logicalElement.visualElements.splice(i, 1);

            if (link.logicalElement.visualElements.length === 0)
                this.model.removeLogicalElement(link.logicalElement);

            relation.visualElements.push(link);
            link.logicalElement = relation;
        }

        // TODO: Update Ports and Params
        // Update Needed Params Visual - Temporary
        link.sourceVisualElement = source;
        link.targetVisualElements = [new TargetElementData(target, undefined)];
        return link;
    }

    public connect(source: OpmVisualEntity, target: OpmVisualEntity, link: LinkParams): OpmLink {
        const relation = this.getExistingRelation(source, target, link.type);
        const opd = this.model.getOpdByElement(source);
        if (link.type === linkType.Effect && source.type === EntityType.Process) {
          const temp = source;
          source = target;
          target = temp;
        }
        let created: OpmLink;
        if (relation) {
            created = factoryOpmLink(link.type, relation);
            created.setNewUUID();
            if (tagged.contains(link.type))
              this.copyTags(created as OpmTaggedLink);
            opd.add(created);
        } else {
            const logical = createLogicalLink(link, this.model, opd, source.logicalElement, target.logicalElement);
            created = logical.visualElements[0];
        }

        // TODO: Update Ports and Params
        // Update Needed Params Visual - Temporary
        created.sourceVisualElement = source;
        created.targetVisualElements = [new TargetElementData(target, undefined)];

        if (link.type === linkType.Exhibition && (target.type === EntityType.Object || target.type === EntityType.Process) && !target.isComputational())
          this.setEssences(source, <OpmVisualThing>target);
            // target.setEssence(Essence.Informatical);

        if (link.path)
          (created as OpmProceduralLink).path = link.path;

        if (link.linkRequirements)
          (created.logicalElement as OpmRelation<OpmLink>).linkRequirements = link.linkRequirements;

        this.checkForRelatedRelations(source, target, created);
        if (relation) {
          this.inheritSourceMultiplicity(created, relation);
          this.inheritTargetMultiplicity(created, relation);
        }


        return created;
    }

    copyTags(taggedVisual: OpmTaggedLink) {
      const tagged = (<Array<OpmTaggedLink>>taggedVisual.logicalElement.visualElements).find(v => v.tag);
      const backwardTagged = (<Array<OpmTaggedLink>>taggedVisual.logicalElement.visualElements).find(v => v.tag);
      if (tagged)
        taggedVisual.tag = tagged.tag;
      if (backwardTagged)
        taggedVisual.backwardTag = backwardTagged.backwardTag;
    }

    inheritSourceMultiplicity(newVis, logical) {
      if (!tagged.contains(logical.linkType))
        return;
      const visWithSrcM = logical.visualElements.find(vis => vis.sourceMultiplicity);
      if (visWithSrcM) {
        newVis.sourceMultiplicity = visWithSrcM.sourceMultiplicity;
        const label = visWithSrcM.labels ? visWithSrcM.labels.find(lb => lb.attrs.label.text === visWithSrcM.sourceMultiplicity) : undefined;
        if (label && newVis.labels) {
          newVis.labels.push(label);
        } else if (label && !newVis.labels) {
          newVis.labels = [label];
        }
      }
    }

    inheritTargetMultiplicity(newVis, logical) {
      if (!fundamental.contains(logical.linkType) && !tagged.contains(logical.linkType))
        return;
      const visWithTrgtM = logical.visualElements.find(vis => vis.targetMultiplicity);
      if (visWithTrgtM) {
        newVis.targetMultiplicity = visWithTrgtM.targetMultiplicity;
        const label = visWithTrgtM.labels ? visWithTrgtM.labels.find(lb => lb.attrs.label.text === visWithTrgtM.targetMultiplicity) : undefined;
        if (label && newVis.labels) {
          newVis.labels.push(label);
        } else if (label && !newVis.labels) {
          newVis.labels = [label];
        }
      }
    }

    setEssences(source: OpmVisualEntity, target: OpmVisualThing) {
      if (this.model.isCurrentlyOnStereotypeCreation > 0 || (<any>target.logicalElement).getBelongsToStereotyped())
        return;
      const realSource = source.type === EntityType.State ? source.fatherObject : source;
      if (realSource.type === EntityType.Object) {
        if (target.type === EntityType.Object)
          target.changeEssence(Essence.Informatical);
        if (target.type === EntityType.Process)
          target.changeEssence((<OpmVisualThing>realSource).getEssence());
      } else if (realSource.type === EntityType.Process) {
        if (target.type === EntityType.Object)
          target.changeEssence(Essence.Informatical);
        if (target.type === EntityType.Process)
          target.changeEssence((<OpmVisualThing>realSource).getEssence());
      }

    }

    public checkForRelatedRelations(source, target, created, mergeAndClear = true) {
      if (!source || !target || !created)
        return;
      if (source.fatherObject && target.fatherObject && source instanceof OpmVisualThing && target instanceof OpmVisualThing)
        return;
      this.farRelatedRelation(source, target, created);
      if (mergeAndClear) {
        created.logicalElement.opmModel.mergeIntersactingRelatedRelations();
        created.logicalElement.opmModel.filterEmptyRelatedRelations();
      }
    }



    farRelatedRelation(source: OpmVisualEntity, target: OpmVisualEntity, created: OpmVisualThing) {
      if (!source || !target || !created) return;
      const that = this;
      let sourceHeritage = [source.logicalElement];
      let targetHeritage = [target.logicalElement];
      if (source instanceof OpmVisualThing)
        sourceHeritage = (source as OpmVisualThing).getThingHeritage();
      if (target instanceof OpmVisualThing)
        targetHeritage = (target as OpmVisualThing).getThingHeritage();

      for (const srcLog of sourceHeritage) {
        for (const trgLog of targetHeritage) {
          const links = this.getLogicalLinksBetween(<any>srcLog, <any>trgLog).filter(l => l.linkType === created.type);
          links.forEach( l => {
            if (l !== created.logicalElement) {
              let rr = that.model.getRelatedRelationsByLogicalLink(l);
              if (!rr) rr = that.model.getRelatedRelationsByLogicalLink(<any>created.logicalElement);
              if (!rr)
                that.model.addNewRelatedRelation([l, <any>created.logicalElement]);
              else {
                if (rr.includes(<any>created.logicalElement))
                  that.model.addLinkToExistingRelatedRelation(l, rr);
                else
                  that.model.addLinkToExistingRelatedRelation(<any>created.logicalElement, rr);
              }
            }
          });
        }
      }
    }

    getLogicalLinksBetween(source: OpmLogicalEntity<OpmVisualEntity>, target: OpmLogicalEntity<OpmVisualEntity>) {
      const links =  this.model.logicalElements.filter( el => el instanceof OpmRelation);
      const ret = [];
      for (const link of links) {
        const l = link as OpmRelation<OpmLink>;
        if (l.sourceLogicalElement === source && l.targetLogicalElements.includes(target))
          ret.push(l);
      }
      return ret;
    }

    private getExistingRelation(source: OpmVisualEntity, target: OpmVisualEntity, type: linkType) {
        const links = source.getAllLinksWith(target);
        const existing = links.outGoing.filter(l => (<any>(l.logicalElement)).linkType === type);

        if (existing.length == 0)
            return undefined;
        else if (existing.length == 1)
            return existing[0].logicalElement;

        const relation = existing[0].logicalElement;
        // else existing.length > 1, therefore, all logicals must be the same
        // extra safety
        // for (let i = 1; i < existing.length; i++)
        //     if (relation !== existing[i].logicalElement)
        //         throw new Error('Some problem in the model');

        return relation;
    }

    private getCommutativeSet(type: linkType): LinksSet {
        if (commutativeDirect.contains(type))
            return commutativeDirect;
        else if (structural.contains(type))
            return structural;
        return undefined;
    }


    public getCommutativeConnection(source: OpmVisualEntity, target: OpmVisualEntity, type: linkType): OpmLink {
      const links = source.getLinksWith(target);
      const set = this.getCommutativeSet(type);
      for (const lnk of [...links.inGoing, ...links.outGoing]) {
        if (lnk.source === source && lnk.target === target && lnk.type === type )
          return lnk;
      }
      if (set === undefined)
          return undefined;
      for (const link of [...links.inGoing, ...links.outGoing])
          if (set.contains(link.type))
              return link;
    }

    public replace(source: OpmVisualEntity, target: OpmVisualEntity, toReplace: OpmLink, link: LinkParams): ModelConnectResult {
        const removed = ([]).concat(toReplace.logicalElement.visualElements) as Array<OpmLink>;
        const created = new Array<OpmLink>();

        const actually_removed = this.model.removeElements(removed);

        for (const replace of removed) {
            let result: ConnectionResult;
            if (source.logicalElement === replace.source.logicalElement)
                result = this.canConnect(replace.source, replace.target, link.type);
            else
                result = this.canConnect(replace.target, replace.source, link.type);
            if (result.success == false) {
                // Restore
                const connection = { type: replace.type, connection: linkConnectionType.systemic };
                for (const replace of removed) {
                    let created: OpmLink;
                    if (source.logicalElement === replace.source.logicalElement)
                        created = this.connect(replace.source, replace.target, connection);
                    else
                        created = this.connect(replace.target, replace.source, connection);
                    created.id = replace.id;
                }
                return result;
            }
        }

        for (const replace of removed) {
            let create: OpmLink;
            if (source.logicalElement === replace.source.logicalElement)
                create = this.connect(replace.source, replace.target, link);
            else
                create = this.connect(replace.target, replace.source, link);
            created.push(create);
        }

        return { success: true, created: created, removed: ([]).concat(actually_removed.elements) };
    }

    public replaceTriangle(source: OpmVisualEntity, target: OpmVisualEntity, link: OpmLink, type: linkType): ConnectionResult {
        const opd = this.model.getOpdByElement(source);

        const links = opd.visualElements.filter(v =>
            v instanceof OpmLink && v.type === link.type && v.sourceVisualElement === source
        ) as Array<OpmFundamentalLink>;

        const linkParams = { type: type, connection: linkConnectionType.enviromental };
        const result = { success: true, created: new Array<OpmLink>(), removed: new Array<OpmLink>(), warnings: []};
        for (const link of links) {
            const trgt = target.constructor.name.includes('State') ? target.fatherObject : target;
            const foldedVisual = trgt.logicalElement.visualElements.find(vis => vis.isFoldedUnderThing().isFolded && vis.isFoldedUnderThing().triangleType === link.type);
            let foldedType;
            if (foldedVisual) {
              foldedType = foldedVisual.foldedUnderThing.triangleType;
              foldedVisual.foldedUnderThing.triangleType = linkParams.type;
            }
            const replace = this.replace(source, target, link, linkParams);
            if (replace.success) {
                result.created.push(...replace.created);
                result.removed.push(...replace.removed);
                if (foldedVisual)
                  foldedVisual.foldedUnderThing.triangleType = linkParams.type;
            } else if (foldedVisual) {
                foldedVisual.foldedUnderThing.triangleType = foldedType;
            }
            if (replace.success === false)
              result.warnings = [replace.message];
        }

        return result;
    }

    public isCommutativeConnection(source: OpmVisualEntity, target: OpmVisualEntity, params: LinkParams): { connected: boolean, link?: OpmLink } {
        const link = this.getCommutativeConnection(source, target, params.type);
        return { connected: link !== undefined, link };
    }

    public getDestinationInOutState(sourceState, type) {
        if (type === InOutPairType.Split)
          return sourceState.fatherObject;

        let i = 0;
        for (const sibling of sourceState.fatherObject.states) {
          if (sourceState === sibling) {
            const next = sourceState.fatherObject.states[i + 1];
            if (next === undefined)
              return sourceState.fatherObject.states[i - 1];
            return next;
          }
          i++;
        }
    }

    public connectInOutPair(source: OpmVisualEntity, target: OpmVisualEntity, type: InOutPairType): ModelConnectResult {

        if (this.isHavingHiddenInOuts(source, target).isHaving === true)
          return { success: false, message: 'Cannot connect In/Out if there are Hidden links. Reveal those links first.'};

        const getState = () => {
            if (target.type === EntityType.State)
                return target;
            else if (source.type === EntityType.State)
                return source;
            throw new Error('Should not been called');
        }

        const getProcess = () => {
            if (target.type === EntityType.Process)
                return target;
            else if (source.type === EntityType.Process)
                return source;
            throw new Error('Should not been called');
        }

        const state = getState() as OpmVisualState;
        const process = getProcess() as OpmVisualProcess;


        const dest = this.getDestinationInOutState(state, type);

        if (dest === undefined)
            return { success: false, message: 'Could not create' };

        const entity1 = (target.type === EntityType.State) ? state : dest;
        const entity2 = (target.type === EntityType.State) ? dest : state;

        // const first = this.canConnect(process, entity1, link1type);
        // const second = this.canConnect(entity2, process, link2type);
        // if (first.success && second.success) {
        const condition = type === InOutPairType.Condition;
        const event = type === InOutPairType.Event;

        const link1ResultParams = { type: linkType.Result, connection: linkConnectionType.enviromental };
        const link2ConsumptionParams = { type: linkType.Consumption, connection: linkConnectionType.enviromental, isCondition: condition, isEvent: event };

        let link1, link2, ret1, ret2;

        const isAlreadyConnected1 = this.isCommutativeConnection(process, entity1, link1ResultParams);
        const isAlreadyConnected2 = this.isCommutativeConnection(entity2, process, link1ResultParams);

        if(isAlreadyConnected1.connected && (<any>isAlreadyConnected1.link).path)
          isAlreadyConnected1.connected = false;
        if(isAlreadyConnected2.connected && (<any>isAlreadyConnected2.link).path)
          isAlreadyConnected2.connected = false;
        if (isAlreadyConnected1.connected) {
          ret1 = this.replace(process, entity1, isAlreadyConnected1.link, link1ResultParams);
          link1 = ret1.created[0];
          validationAlert('Please note this action replaced the previous In/Out link. For creating a flipflop link please add link path first.')
        }
        else
          link1 = this.connect(process, entity1, link1ResultParams) as OpmProceduralLink;

        if (isAlreadyConnected2.connected) {
          const dummyLinks = [];
          if (ret1 && ret1.removed.includes(isAlreadyConnected2.link)) {
            const toReconnectTemporarily = ret1.removed.filter(l => l.type === isAlreadyConnected2.link.type);
            for (const l of toReconnectTemporarily) {
              const dummyParams = { type: l.type, connection: linkConnectionType.enviromental };
              const dummyLink = this.connect(l.source, l.target, dummyParams);
              isAlreadyConnected2.link = dummyLink;
              dummyLinks.push(dummyLink);
            }
          }
          ret2 = this.replace(entity2, process, isAlreadyConnected2.link, link2ConsumptionParams);
          if (ret2.success === false) {
              const moreToRemove = entity2.getLinksWith(process).outGoing.filter(ltr =>
                [linkType.Consumption, linkType.Result].includes(ltr.type)).map(rl => rl.remove());
              const removedToFix = [];
              for (const lr of moreToRemove)
                  removedToFix.push(...lr);
              ret2.created = [this.connect(entity2, process, link2ConsumptionParams)];
              ret2.removed = removedToFix;
          }
          dummyLinks.forEach(lnk => lnk.remove());
          link2 = ret2.created[0];
        }
        else
          link2 = this.connect(entity2, process, link2ConsumptionParams) as OpmProceduralLink;

        link1.setAsPartner(link2);

        // those values should be only true or false. not null or undefined.
        link1.condition = link1.condition ? true : false;
        link2.condition = link2.condition ? true : false;
        link1.event = link1.event ? true : false;
        link2.event = link2.event ? true : false;
        // taking care of related relations
        const rr1 = this.model.getRelatedRelationsByLogicalLink(link1.logicalElement);
        const rr2 = this.model.getRelatedRelationsByLogicalLink(link2.logicalElement);
        if (rr1)
          rr1.push(link1.logicalElement);
        else if (rr2)
          rr2.push(link2.logicalElement);
        else if (!rr1 && !rr2)
          this.model.addNewRelatedRelation([link1.logicalElement, link2.logicalElement]);

        return { success: true, created: [link1, link2], removed: [...(ret1 ? ret1.removed : []), ...(ret2 ? ret2.removed : [])] };
        // }

        // return { success: false, message: 'Could not create' };
    }

    fixLinksWithNonExistingState(visualProcess: OpmVisualProcess) {
      const ins = visualProcess.getLinks().inGoing;
      const outs = visualProcess.getLinks().outGoing;
      const model = getInitRappidShared().getOpmModel();
      for (const link of ins) {
        const visSource = link.sourceVisualElement;
        if (!visSource.logicalElement.visualElements.includes(visSource)) {
          const newSource = visSource.logicalElement.visualElements.find(
            vis => model.getOpdByThingId(vis.id) === model.getOpdByThingId(link.id));
          this.move(<any>newSource, <any>link.targetVisualElements[0].targetVisualElement, link);
        }
      }
      for (const link of outs) {
        const visTarget = link.targetVisualElements[0].targetVisualElement;
        if (!visTarget.logicalElement.visualElements.includes(visTarget)) {
          const newTarget = visTarget.logicalElement.visualElements.find(
            vis => model.getOpdByThingId(vis.id) === model.getOpdByThingId(link.id));
          this.move(<any>link.sourceVisualElement, <any>newTarget, link);
        }
      }
    }

    switchEffectToInOuts(linkEffct: OpmProceduralLink) {
      // linkEffct.visible = false;
      const visualProcess = linkEffct.sourceVisualElement instanceof OpmVisualProcess ?
        linkEffct.sourceVisualElement : linkEffct.targetVisualElements[0].targetVisualElement;
      const visualObject = linkEffct.sourceVisualElement instanceof OpmVisualProcess ?
        linkEffct.targetVisualElements[0].targetVisualElement : linkEffct.sourceVisualElement;
      const hide = [];
      // this.fixLinksWithNonExistingState(<any>visualProcess);
      const ins = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).inGoing.filter(l => l !== linkEffct);
      const outs = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).outGoing.filter(l => l !== linkEffct);
      let links = [...ins, ...outs];
      links = links.filter(l => l.type === linkType.Result || l.type === linkType.Consumption);
      // if there is no links at the moment - create them
      if (ins.length < 1 || outs.length < 1) {
        const ret = this.connectInOutPair((<any>visualObject).children[0], <any>visualProcess, InOutPairType.Standart);
        links.push(...ret.created);
      } else {
        links.forEach( l => {
          l.visible = true;
          if (ins.includes(l) && l.sourceVisualElement instanceof OpmVisualState) {
            const shouldReplaceState = !l.sourceVisualElement.logicalElement.visualElements.includes(l.sourceVisualElement);
            if (!shouldReplaceState) return;
            const newState = (<any>visualObject).children.find( ch => ch.logicalElement.text ===
              l.sourceVisualElement.logicalElement.text);
            const sp = l.sourceVisualElementPort;
            const tp = l.targetVisualElementPort;
            // if the states was supressed - express will create new states with other ids...
            const ret = this.move(newState, <any>visualProcess, l);
            ret.BreakPoints = l.BreakPoints;
            ret.sourceVisualElementPort = sp;
            ret.targetVisualElementPort = tp;
          } else if (outs.includes(l) && l.targetVisualElements[0].targetVisualElement instanceof OpmVisualState) {
            const shouldReplaceState = !l.targetVisualElements[0].targetVisualElement.logicalElement.visualElements.
            includes(l.targetVisualElements[0].targetVisualElement);
            if (!shouldReplaceState) return;
            const newState = (<any>visualObject).children.find( ch => ch.logicalElement.text ===
              l.targetVisualElements[0].targetVisualElement.logicalElement.text);
            const sp = l.sourceVisualElementPort;
            const tp = l.targetVisualElementPort;
            // if the states was supressed - express will create new states with other ids...
            const ret = this.move(<any>visualProcess, newState, l);
            ret.BreakPoints = l.BreakPoints;
            ret.sourceVisualElementPort = sp;
            ret.targetVisualElementPort = tp;
          }
        });
      }
      linkEffct.remove();
      return {success: true, show: links, hide: hide};
    }

    switchInOutsToEffect(link: OpmProceduralLink) {
      const visualProcess = link.sourceVisualElement instanceof OpmVisualProcess ?
        link.sourceVisualElement : link.targetVisualElements[0].targetVisualElement;
      let visualObject = link.sourceVisualElement instanceof OpmVisualProcess ?
        link.targetVisualElements[0].targetVisualElement : link.sourceVisualElement;
      if (visualObject instanceof OpmVisualState)
        visualObject = visualObject.fatherObject;
      let existingEffectVisual;
      let links = [...(<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).inGoing,
        ...(<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).outGoing];
      existingEffectVisual = links.find( l => l.type === linkType.Effect);
      if (!existingEffectVisual) {
        const params = { type: linkType.Effect, connection: linkConnectionType.enviromental };
        const ret = this.connect(<OpmVisualEntity>visualProcess, <OpmVisualEntity>visualObject, params);
        existingEffectVisual = ret;
      }
      existingEffectVisual.visible = true;
      const hide = [];
      const show = [existingEffectVisual];
      links = links.filter(l => l.type === linkType.Result || l.type === linkType.Consumption);
      links.forEach( l => {
        l.visible = false;
        hide.push(l);
      });
      let rr = this.model.getRelatedRelationsByLogicalLink(existingEffectVisual.logicalElement);
      if (!rr && links.length > 0)
        rr = this.model.getRelatedRelationsByLogicalLink(links[0].logicalElement);
      const logicalEffect = this.model.getLogicalElementByVisualId(existingEffectVisual.id);
      if (rr && !rr.includes(<any>logicalEffect))
        this.model.addLinkToExistingRelatedRelation(<any>logicalEffect, rr);
      if (!rr && links.length > 0)
        this.model.addNewRelatedRelation([<any>logicalEffect, links[0].logicalElement]);

      return {success: true, show: show, hide: hide};
    }

    isHavingInouts(source: OpmVisualEntity, target: OpmVisualEntity): boolean {
      for (const visSrc of source.logicalElement.visualElements)
        for (const visTrgt of target.logicalElement.visualElements) {
          if (this.isHavingVisibleInOuts(<any>visSrc, <any>visTrgt).isHaving === true ||
            this.isHavingHiddenInOuts(<any>visSrc, <any>visTrgt).isHaving === true)
            return true;
        }
      return false;
    }

    isHavingHiddenInOuts(source: OpmVisualEntity, target: OpmVisualEntity): {isHaving: boolean, ins: Array<OpmLink> , outs: Array<OpmLink>} {
      const visualProcess = source instanceof OpmVisualProcess ? source : target;
      let visualObject = source instanceof OpmVisualProcess ? target : source;
      if (visualObject instanceof OpmVisualState)
        visualObject = visualObject.fatherObject;
      const ins = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).inGoing.filter(l => l.type !== linkType.Effect && l.visible === false);
      const outs = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).outGoing.filter(l => l.type !== linkType.Effect && l.visible === false);
      if (ins.length < 1 || outs.length < 1)
        return {isHaving: false, ins: ins , outs: outs};
      return {isHaving: true, ins: ins , outs: outs};
    }

    isHavingVisibleInOuts(source: OpmVisualEntity, target: OpmVisualEntity): {isHaving: boolean, ins: Array<OpmLink> , outs: Array<OpmLink>} {
      if (!source || ! target)
        return {isHaving: false, ins: [] , outs: []};
      const visualProcess = source instanceof OpmVisualProcess ? source : target;
      let visualObject = source instanceof OpmVisualProcess ? target : source;
      if (visualObject instanceof OpmVisualState)
        visualObject = visualObject.fatherObject;
      const ins = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).
          inGoing.filter(l => l.type !== linkType.Effect);
      const outs = (<any>visualProcess).getLinksWithOtherAndItsChildren(visualObject).
          outGoing.filter(l => l.type !== linkType.Effect);
      if (ins.length < 1 || outs.length < 1)
        return {isHaving: false, ins: ins , outs: outs};
      return {isHaving: true, ins: ins , outs: outs};
    }

  canChangeArcType(newArcType: LinkLogicalConnection, visualLinks: Array<OpmLink>, side: string): boolean {

    if (side === 'target' && newArcType === LinkLogicalConnection.Or) {
      const statesFromSameObject = visualLinks.filter(l => l.source.constructor.name.includes('State'))
        .map(l => l.source.fatherObject);
      // if there are 2 or more states from the same object in the relation it must be XOR
      if (statesFromSameObject.length !== (new Set(statesFromSameObject)).size) {
        return false;
      }
    }

    if (newArcType === LinkLogicalConnection.Xor || side === 'target')
      return true;
    const condition = !visualLinks.find(l => l.type !== linkType.Result) && this.isHavingVisibleInOuts(visualLinks[0].source, visualLinks[0].target).isHaving;
    if (condition) {
      return false;
    }
    return true;
  }
}

// Slayer: Temporary.

function createLogicalLink(link: LinkParams, model: BasicOpmModel, opd: OpmOpd, source: OpmLogicalElement<OpmVisualElement>,
    target: OpmLogicalElement<OpmVisualElement>): OpmRelation<OpmLink> {

    const logical = factoryLogicalLink(link.type, model);

    logical.sourceLogicalElement = source;
    logical.targetLogicalElements = new Array<OpmLogicalElement<OpmVisualElement>>();
    logical.targetLogicalElements.push(target);
    logical.linkConnectionType = linkConnectionType.systemic; // TODO: Is needed?
    logical.linkType = link.type;

    // This will be removed
    const visual = logical.visualElements[0];
    model.currentOpd.removeVisual(visual);
    if (opd)
      opd.add(visual);

    if (logical instanceof OpmProceduralRelation) {
        logical.condition = link.isCondition;
        logical.event = link.isEvent;
        logical.negation = link.isNegation;
    }

    return logical;
}

export interface LinkParams {
    type: linkType,
    connection: linkConnectionType,
    isCondition?: boolean,
    isEvent?: boolean,
    isNegation?: boolean,
    path?: string,
    linkRequirements?: string,
}

function factoryOpmLink(link: linkType, logical): OpmLink {
    switch (link) {
        case linkType.Agent:
        case linkType.Result:
        case linkType.Consumption:
        case linkType.Effect:
        case linkType.Instrument:
        case linkType.Invocation:
        case linkType.OvertimeException:
        case linkType.UndertimeException:
        case linkType.UndertimeOvertimeException:
            return new OpmProceduralLink(undefined, logical);
        case linkType.Aggregation:
        case linkType.Exhibition:
        case linkType.Generalization:
        case linkType.Instantiation:
            return new OpmFundamentalLink(undefined, logical);
        case linkType.Unidirectional:
        case linkType.Bidirectional:
            return new OpmTaggedLink(undefined, logical);
    }
    throw new Error('bad link type');
}

export function factoryLogicalLink(link: linkType, model: BasicOpmModel): OpmRelation<OpmLink> {
    switch (link) {
        case linkType.Agent:
        case linkType.Result:
        case linkType.Consumption:
        case linkType.Effect:
        case linkType.Instrument:
        case linkType.Invocation:
        case linkType.OvertimeException:
        case linkType.UndertimeException:
        case linkType.UndertimeOvertimeException:
            return model.logicalFactory(RelationType.Procedural, undefined) as OpmProceduralRelation;
        case linkType.Aggregation:
        case linkType.Exhibition:
        case linkType.Generalization:
        case linkType.Instantiation:
            return model.logicalFactory(RelationType.Fundamental, undefined) as OpmFundamentalRelation;
        case linkType.Unidirectional:
        case linkType.Bidirectional:
            return model.logicalFactory(RelationType.Tagged, undefined) as OpmTaggedRelation;
    }
    throw new Error('bad link type');
}
