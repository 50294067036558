import {Component, Inject, OnInit} from '@angular/core';
import {linkType} from "../../models/ConfigurationOptions";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {COMMA, ENTER} from "@angular/cdk/keycodes";
import {MatLegacyChipInputEvent as MatChipInputEvent} from "@angular/material/legacy-chips";
import {UntypedFormControl} from "@angular/forms";
import {validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {ContextService} from "../../modules/app/context.service";
import {NewModelByWizardParams} from "./newModelWizardParamsInterface";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {ConfirmDialogDialogComponent} from "../confirm-dialog/confirm-dialog";

@Component({
  selector: 'opcloud-new-model-by-wizard-component',
  templateUrl: './new-model-by-wizard-component.component.html',
  styleUrls: ['./new-model-by-wizard-component.component.scss']
})
export class NewModelByWizardComponentComponent implements OnInit {

  private stageIndex: number;
  private title: string;
  private stagesData: Array<any>;
  private formParams: NewModelByWizardParams;
  private totalStagesNumber: number;
  private separatorKeysCodes: number[] = [ENTER, COMMA];
  private tagsCtrl = new UntypedFormControl('');
  private tagsCtrl2 = new UntypedFormControl('');
  private tagsCtrl3 = new UntypedFormControl('');
  private showStakeholderDiv: boolean;
  private showIngDiv: boolean;
  private showPhysicalDiv: boolean;
  private showCreatesDiv: boolean;
  private showAffectsDiv: boolean;
  private showChangesDiv: boolean;
  private showHoverDiv: boolean;
  private showToolsHelpDiv: boolean;
  private showEnvironmentalDiv: boolean;
  private previousMainOutputLinkType: "changes" | "creates" | "affects";

  constructor(private dialogRef: MatDialogRef<NewModelByWizardComponentComponent>, private contextService: ContextService, private init: InitRappidService, @Inject(MAT_DIALOG_DATA) private data) {
    this.setStagesData();
    this.stageIndex = 0;
    this.totalStagesNumber = 12;
    this.title = this.stagesData[0].title;
    this.showStakeholderDiv = false;
    this.showIngDiv = false;
    this.showPhysicalDiv = false;
    this.showCreatesDiv = false;
    this.showAffectsDiv = false;
    this.showChangesDiv = false;
    this.showHoverDiv = false;
    this.showToolsHelpDiv = false;
    this.showEnvironmentalDiv = false;
    this.formParams = {
      mainFunctionality: '',
      beneficiaryGroup: '',
      beneficiary: '',
      beneficiaryState1: '',
      beneficiaryState2: '',
      isMainBeneficiaryAlsoHandler: undefined,
      systemHandlers: [],
      systemName: '',
      tools: [],
      toolsData: [],
      mainInputs: [],
      mainOutput: '',
      mainOutputIsInput: undefined,
      mainOutputLinkType: undefined,
      mainOutputLinkChangesState1: '',
      mainOutputLinkChangesState2: '',
      environmentalObjects: [],
    }
    // this.dummyParams();
  }

  ngOnInit() {
  }

  private nextStage() {
    const canProceed = this.canProceed();
    if (this.canProceed().can) {
      this.stageIndex++;
    } else {
      validationAlert(canProceed.message);
    }
  }

  private canProceed(): { can: boolean, message?: string} {
    if (this.stageIndex === 2 && this.formParams.mainFunctionality.trim().length === 0) {
      return { can: false, message: 'Cannot proceed without setting the main functionality of the system.' };
    } else if (this.stageIndex === 3 && this.formParams.beneficiaryGroup.trim().length === 0) {
      return { can: false, message: 'Cannot proceed without setting the beneficiary group of the system.' };
    } else if (this.stageIndex === 4 && this.formParams.beneficiary.trim().length === 0) {
      return { can: false, message: 'Cannot proceed without setting the beneficiary of the system.' };
    } else if (this.stageIndex === 4 && (this.formParams.beneficiaryState1.trim().length === 0 || this.formParams.beneficiaryState2.trim().length === 0)) {
      return { can: false, message: 'Cannot proceed without setting the beneficiary states of the system.' };
    } else if (this.stageIndex === 5 && this.formParams.isMainBeneficiaryAlsoHandler === undefined) {
      return { can: false, message: 'Cannot proceed without setting if the beneficiary of the system is also an handler.' };
    } else if (this.stageIndex === 5 && this.formParams.isMainBeneficiaryAlsoHandler === false && this.formParams.systemHandlers.length === 0) {
      return { can: false, message: 'If the main beneficiary is not the system\'s handler you should set at least one handler.' };
    } else if (this.stageIndex === 6 && this.formParams.systemName.trim().length === 0) {
      return { can: false, message: 'Cannot proceed without setting if the system\'s name.' };
    } else if (this.stageIndex === 7 && this.formParams.tools.length === 0) {
      return { can: false, message: 'Cannot proceed without setting at least one system\'s tool.' };
    } else if (this.stageIndex === 8 && this.formParams.mainInputs.length === 0) {
      return { can: false, message: 'Cannot proceed without setting at least one system\'s input.' };
    } else if (this.stageIndex === 9 && this.formParams.mainOutputIsInput === undefined) {
      return { can: false, message: 'Cannot proceed without setting if the system\'s main output is also an input.' };
    } else if (this.stageIndex === 9 && this.formParams.mainOutput.trim().length === 0) {
      return { can: false, message: 'Cannot proceed without setting the system\'s main output.' };
    } else if (this.stageIndex === 9 && this.formParams.mainOutputLinkType === undefined) {
      return { can: false, message: 'Cannot proceed without setting the system\'s main output connection type.' };
    } else if (this.stageIndex === 9 && this.formParams.mainOutputLinkType === 'changes' && (this.formParams.mainOutputLinkChangesState1.trim().length === 0 || this.formParams.mainOutputLinkChangesState2.trim().length === 0)) {
      return { can: false, message: 'Cannot proceed without setting the system\'s main output states.' };
    }
    return { can: true };
  }

  private previousStage() {
    this.stageIndex--;
  }

  private setStagesData() {
    this.stagesData = [
      { title: 'Welcome!' },
      { title: 'Motivation' },
      { title: 'System’s Main Functionality' },
      { title: 'Beneficiary Group' },
      { title: 'Beneficiary Attribute' },
      { title: 'Agent' },
      { title: 'System Name' },
      { title: 'Instrument' },
      { title: 'Input' },
      { title: 'Output' },
      { title: 'Environmental Objects' },
      { title: 'The Model Is Completed' },
    ];
  }

  private finish() {
    if (this.data.sandbox) {
      return this.finishForSandbox();
    }
    this.contextService.createNewModelFromWizard(this.formParams);
    this.init.graphService.renderGraph(this.init.opmModel.currentOpd, this.init);
    this.init.graph.getCells().forEach(c => c.autosize ? c.autosize(this.init): undefined);
    this.init.criticalChanges_.next(true);
    this.dialogRef.close();
  }

  private finishForSandbox() {
    this.init.modelService.reset();
    this.init.getOpmModel().createModelFromWizardParams(this.formParams)
    this.init.graphService.renderGraph(this.init.opmModel.currentOpd, this.init);
    this.init.graph.getCells().forEach(c => c.autosize ? c.autosize(this.init): undefined);
    this.init.criticalChanges_.next(true);
    this.dialogRef.close();
  }

  private async cancel() {
    const confirmDialog = this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 220, 350, {
      allowMultipleDialogs: true,
      message: 'Warning \n\n Are you sure you want to close the wizard?',
      closeFlag: false,
      okName: 'Yes',
      closeName: 'No',
      centerText: true
    });
    const leave = await confirmDialog.afterClosed().toPromise();
    if (leave === 'OK') {
      this.dialogRef.close();
    }
  }

  private removeSystemEnablerTag(tag: string) {
    const index = this.formParams.systemHandlers.indexOf(tag);
    if (index >= 0) {
      this.formParams.systemHandlers.splice(index, 1);
    }
  }

  private addSystemEnablerTag(event: MatChipInputEvent): void {
    if (this.formParams.systemHandlers.length === 3) {
      validationAlert('The wizard is limited for only 3 inputs.', 3500);
      this.tagsCtrl.setValue(null);
      return;
    }
    const value = (event.value || '').trim();
    if (this.formParams.systemHandlers.some(it => it.toLowerCase().trim() === value.toLowerCase().trim())) {
      validationAlert('A value can be used only once.', 3500);
      event.input.value = '';
      this.tagsCtrl.setValue(null);
      return;
    }
    if (value) {
      this.formParams.systemHandlers.push(value);
    }
    event.input.value = '';
    this.tagsCtrl.setValue(null);
  }

  private removeSystemToolTag(tag: string) {
    const index = this.formParams.tools.indexOf(tag);
    if (index >= 0) {
      this.formParams.tools.splice(index, 1);
      this.formParams.toolsData.splice(index, 1);
      const idx = this.formParams.environmentalObjects.findIndex(obj => obj.name === tag);
      if (idx) {
        this.formParams.environmentalObjects.splice(idx, 1);
      }
    }
  }

  private addSystemToolTag(event: MatChipInputEvent): void {
    if (this.formParams.tools.length === 3) {
      validationAlert('The wizard is limited for only 3 tools.', 3500);
      this.tagsCtrl.setValue(null);
      return;
    }
    const value = (event.value || '').trim();
    if (this.formParams.tools.some(it => it.toLowerCase().trim() === value.toLowerCase().trim())) {
      validationAlert('A value can be used only once.', 3500);
      event.input.value = '';
      this.tagsCtrl2.setValue(null);
      return;
    }
    if (value) {
      this.formParams.tools.push(value);
      this.formParams.toolsData.push({ name: value, isPhysical: false });
      this.formParams.environmentalObjects.push({ name: value, isEnvironmental: false });
    }
    event.input.value = '';
    this.tagsCtrl2.setValue(null);
  }

  private removeSystemInputTag(tag: string) {
    const index = this.formParams.mainInputs.indexOf(tag);
    if (index >= 0) {
      this.formParams.mainInputs.splice(index, 1);
      const idx = this.formParams.environmentalObjects.findIndex(obj => obj.name === tag);
      if (idx) {
        this.formParams.environmentalObjects.splice(idx, 1);
      }
    }
  }

  private addSystemInputTag(event: MatChipInputEvent): void {
    if (this.formParams.mainInputs.length === 3) {
      validationAlert('The wizard is limited for only 3 inputs.', 3500);
      this.tagsCtrl3.setValue(null);
      return;
    }
    const value = (event.value || '').trim();
    if (this.formParams.mainInputs.some(it => it.toLowerCase().trim() === value.toLowerCase().trim())) {
      validationAlert('A value can be used only once.', 3500);
      event.input.value = '';
      this.tagsCtrl3.setValue(null);
      return;
    }
    if (value) {
      this.formParams.mainInputs.push(value);
      this.formParams.environmentalObjects.push({ name: value, isEnvironmental: false });
    }
    event.input.value = '';
    this.tagsCtrl3.setValue(null);
  }

  dummyParams() {
    this.formParams =  {
        mainFunctionality: 'Main System Doing',
        beneficiaryGroup: 'Beneficiary Group updated',
        beneficiary: 'Beneficiary relevant attribute',
        beneficiaryState1: 'problematic',
        beneficiaryState2: 'satisfactory',
        isMainBeneficiaryAlsoHandler: true,
        systemHandlers: ['handler1', 'handler2', 'handler3'],
        systemName: 'System name',
        tools: ['tool1', 'tool2', 'tool3'],
        toolsData: [
          { name: 'tool1', isPhysical: true },
          { name: 'tool2', isPhysical: true },
          { name: 'tool3', isPhysical: false }
        ],
        mainInputs: ['input1', 'input2', 'input3'],
        mainOutput: 'Output',
        mainOutputIsInput: undefined,
        mainOutputLinkType: 'affects',
        mainOutputLinkChangesState1: 'state change 1',
        mainOutputLinkChangesState2: 'state change 2',
        environmentalObjects: [{ name: 'tool1', isEnvironmental: true }]
    }
  }

  isMainOutputAlsoInputChange($event) {
    this.formParams.mainOutputIsInput = $event.value;
    if (this.formParams.mainOutputIsInput) {
      this.previousMainOutputLinkType = this.formParams.mainOutputLinkType;
      this.formParams.mainOutputLinkType = 'changes';
    } else {
      this.formParams.mainOutputLinkType = this.previousMainOutputLinkType;
    }
  }

  onInputAsMainOutputSelection($event) {
    this.formParams.mainOutput = $event.value;
  }

  getToolsSelectionLabel() {
    return (this.formParams.toolsData.filter(t => t.isPhysical).length === 0) ?
      'Select' : this.formParams.toolsData.filter(t => t.isPhysical).map(it => it.name).join(', ');
  }

  getEnvironmentalObjectsSelectionLabel() {
    return (this.formParams.environmentalObjects.filter(t => t.isEnvironmental).length === 0) ?
      'Select' : this.formParams.environmentalObjects.filter(t => t.isEnvironmental).map(it => it.name).join(', ');
  }
}

