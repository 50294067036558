import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { RenameServerResponse } from '../../../../rappid-components/services/server-response/rename-response';
import { StorageService } from '../../../../rappid-components/services/storage.service';
import { DisplayModel, DisplayFolder } from '../../../../rappid-components/services/storage/model-storage.interface';
import { ModelTitleValidator } from '../../name-validator';
import {validationAlert} from "../../../../configuration/rappidEnviromentFunctionality/shared";

interface RENAME_DIALOG_INPUT {
  model: DisplayModel,
  folder: DisplayFolder,
  sysExamples?: boolean,
  globalTemplates?: boolean,
  usedNames?: Array<string>
}

@Component({
  selector: 'rename-dialog',
  templateUrl: './rename-dialog.component.html',
  styleUrls: ['./rename-dialog.component.css']
})
export class RenameDialogComponent implements OnInit {

  public title: string;
  public error: string;
  public sysExamples: boolean;
  public globalTemplates: boolean;

  constructor(
    @Optional() private readonly dialogRef: MatDialogRef<RenameDialogComponent>,
    private readonly storage: StorageService,
    @Inject(MAT_DIALOG_DATA) private readonly data: RENAME_DIALOG_INPUT
  ) {
  }

  ngOnInit() {
    this.title = this.data.model ? this.data.model.title : this.data.folder.name
    this.sysExamples = !!this.data.sysExamples;
    this.globalTemplates = !!this.data.globalTemplates;
  }

  rename() {
    if (ModelTitleValidator.create().validateTitle(this.title) == false) {
      this.error = 'Wrong title name';
      return;
    }

    if (this.data.folder)
      this.renameFolder();
    else if (this.data.model)
      this.renameModel();
  }

  private renameFolder() {
    if (this.data.usedNames?.find(f => f.trim().toLowerCase() === this.title.toLowerCase())) {
      validationAlert('This name is already been used. Choose a different name.', 5000, 'Error');
      return;
    }
    this.finish(this.storage.renameFolder(this.data.folder.id, this.title, this.sysExamples, this.globalTemplates));
  }

  private renameModel() {
    this.finish(this.storage.renameModel(this.data.model.id, this.title, this.sysExamples, this.globalTemplates));
  }

  private finish(p: Promise<RenameServerResponse>) {
    p.then(res => {
      if (res && res.renamed)
        this.dialogRef.close({ renamed: true });
      else
        this.error = res.message;
    }).catch(err => this.error = 'some error occurred');
  }

  cancel() {
    this.dialogRef.close({ renamed: false });
  }

}
