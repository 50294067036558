export abstract class ChatStorageInterface {
  abstract getAllChatMessages(model_id: string): Promise<Array<DisplayChat>>
  abstract pushChatMessage(message: DisplayChat): Promise<void>
}

export enum getChatAction {
  ALL = 'all',
  UNREAD = 'unread',
  UNREAD_COUNT = 'unread_count'
}

export interface getChatResponse {
  result: number;
  chatMessages: Array<DisplayChat>;
}

export interface postChatResponse {
  result: number;
  chatMessageId: string;
}

export interface DisplayChat {
  id: string;
  sender: string;
  message: string;
  modelId: string;
  timestamp: number;
  type: string;
}
