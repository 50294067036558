import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import { Injectable } from '@angular/core';

@Injectable()
export class DialogService {

  constructor(private dialog: MatDialog) { }

  public getDialog(): MatDialog {
    return this.dialog;
  }

  public openDialog(dialog, height:number = 500, width:number = 600, data:Object){
    // Alon: open a dialog box
    let disableClose = false;
    if (('doNotClose' in data) && (data['doNotClose'] === 'true')) {
      disableClose = true;
    }
    if (this.dialog.openDialogs.length === 0 || (data && data['allowMultipleDialogs'] === true))
      return this.dialog.open(dialog, {
        height: height ? (height + 'px') : undefined,
        width: width ? (width + 'px') : undefined,
        data: data,
        disableClose: disableClose,
        autoFocus: false
      });
  }

}
