import { BrowserModule } from '@angular/platform-browser';
import { ErrorHandler, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { AppComponent } from './app/app.component';

import { CoreModule } from './core.module';
import { SharedModule } from './shared/shared.module';
import { UserService } from '../rappid-components/services/user.service';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AboutDialogComponent } from '../dialogs/About/about';
import { InputNameDialogComponent } from '../dialogs/input-name-dialog/input-name-dialog';
import { ConfirmDialogDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog';
import { OplDialogComponent } from '../dialogs/opl-dialog/opl-dialog.component';
import { FileSelectDirective, FileDropDirective } from 'ng2-file-upload';
import { UploadFile } from "../dialogs/FileUploader/FileUploader";
import { ProgressSpinner } from "../dialogs/Spinner/Progress_Spinner";
import { ClearCanvasComponent } from '../dialogs/clear-canvas/clear-canvas';
import { NewModelComponent } from "../dialogs/new-model-dialog/new-model-dialog";
import { KeysPipe } from '../dialogs/opl-dialog/opl-dialog.component';
import { OplService } from "../opl-generation/opl.service";
import { MemberGrpComponent } from './Settings/group-mgmt/update-user-grp/member-grp/member-grp.component'
// import { NgProgressModule } from 'ngx-progressbar';
import { ModelAnalysisComponent } from "./Settings/model-analysis/model-analysis.component";
import { SettingsNewOrg } from "./Settings/organization-mgmt/new-org/new-org.component";

import { ReactiveFormsModule } from '@angular/forms';
import { ConfigModule, ConfigService } from '../configuration/service/config.service';
import { LayoutModule } from './layout/layout.module';
import { OpmModelComparisonService } from "./app/opm-model-comparison.service";
import { opmQueryDialogComponent } from "../dialogs/opmQuery-dialog/opmQuery-dialog";
import { GraphDBService } from "../rappid-components/services/GraphDB/graphDB.service";
import { MatLegacyPaginatorModule as MatPaginatorModule } from '@angular/material/legacy-paginator';
import { GlobalErrorHandler } from '../error-handler/global-error-handler';
import { ChatStorageService } from '../rappid-components/services/storage/chat/chat-storage.service';
import { CodeEditorModule } from '@ngstack/code-editor';
import {ExportOPLAPIService} from './app/export-opl.service';

@NgModule({
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        SharedModule,
        RouterModule,
        HttpClientModule,
        ReactiveFormsModule,
        LayoutModule,
        MatPaginatorModule,
        CodeEditorModule.forRoot({
            baseUrl: 'assets/monaco'
        }),
    ],
    declarations: [
        AppComponent,
        // AboutDialogComponent,
        // InputNameDialogComponent,
        // ConfirmDialogDialogComponent,
        // ClearCanvasComponent,
        // NewModelComponent,
        // OplDialogComponent,
        // Tabs,
        // Tab,
        // FileSelectDirective, /** The FileSelectDirective is what we will require , Drop is an option too*/
        // FileDropDirective,
        // UploadFile,
        // ProgressSpinner,
        // IndexComponent,
        // CreateComponent,
        // EditComponent,
        // ChooseExportedFileNameComponent,
        // SaveScreenshotComponent,
        // AdminDeleteVarificationComponent,
        // ConnectionDialogComponent,
        // ValidationDialogComponent,
        opmQueryDialogComponent,
    ],
    providers: [
        // ConfigService,
        // { provide: 'DatabaseDriver', useClass: FirebaseDatabaseDriver },
        // { provide: 'AuthenticationDriver', useClass: FirebaseAuthDriver },
        // MongoDatabaseDriver,
        // FirebaseDatabaseDriver,
        // AuthenticationService,
        // UserService,
        // OplService,
        // CanActivateViaAuthGuard,
        // OrganizationService,
        // StorageService,
        // DBService,
        // DatabaseService,
        // Repository,
        // GroupsService,
        ChatStorageService,
        ConfigService,
        ConfigModule.init(),
        OpmModelComparisonService,
        GraphDBService,
        ExportOPLAPIService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
