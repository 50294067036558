// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.header {
  text-align: center;
  color: #1a3763;
  font-size: 18px;
  font-weight: 500;
}

hr {
  border: 1px solid #a2a4a64a;
  border-radius: 5px;
}

button {
  margin: auto;
}

/*.ticks {*/
/*  display: none;*/
/*}*/

#paretoPaper {
  margin-left: calc(50% - 250px);
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/pareto-frontier-dialog/pareto-frontier-dialog.css"],"names":[],"mappings":";AACA;EACE,kBAAkB;EAClB,cAAc;EACd,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,2BAA2B;EAC3B,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA,WAAW;AACX,mBAAmB;AACnB,IAAI;;AAEJ;EACE,8BAA8B;AAChC","sourcesContent":["\n.header {\n  text-align: center;\n  color: #1a3763;\n  font-size: 18px;\n  font-weight: 500;\n}\n\nhr {\n  border: 1px solid #a2a4a64a;\n  border-radius: 5px;\n}\n\nbutton {\n  margin: auto;\n}\n\n/*.ticks {*/\n/*  display: none;*/\n/*}*/\n\n#paretoPaper {\n  margin-left: calc(50% - 250px);\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
