import { ValueAttributeType, ValueAttributeRange } from "./attribute-range";
import { BooleanRange } from "./boolean-range";
import { CharRange } from "./char-range";
import { NumericRange } from "./numeric-range";
import { StringRange } from "./string-range";

export class AttributeValue {

    private active: boolean = false;
    private type: ValueAttributeType;
    private validator: ValueAttributeRange;

    public setRange(type: ValueAttributeType, range: string, stereotypeValidator: ValueAttributeRange): boolean {
        const validator = factory(type);
        const result = validator.setPattern(range);
        if (result.wasSet == false)
            return false;

        if (stereotypeValidator && !checkIsSubRange(stereotypeValidator, validator)) {
            return false;
        }

        this.active = true;
        this.type = type;
        this.validator = validator;
        return true;
    }

    public remove() {
        this.active = false;
        this.type = undefined;
        this.validator = undefined;
    }

    public validate(value: string): boolean {
        if (this.active == false || this.validator == undefined)
            return false;

        return this.validator.validate(value);
    }

    public getDefault(): string {
        if (this.validator)
            return this.validator.getDefault();
        return undefined;
    }

    public isActive(): boolean {
        return this.active;
    }

    public getType(): ValueAttributeType {
        return this.type;
    }

    public getRange(): string {
        if (this.validator)
            return this.validator.getPattern();
        return '';
    }

    public getValidator(): ValueAttributeRange {
      return this.validator;
    }

}

const factory = function(type: ValueAttributeType): ValueAttributeRange {
    if (type == ValueAttributeType.INTEGER)
        return new NumericRange('integer');
    else if (type == ValueAttributeType.FLOAT)
        return new NumericRange('float');
    else if (type == ValueAttributeType.STRING)
        return new StringRange();
    else if (type == ValueAttributeType.CHAR)
        return new CharRange();
    else if (type == ValueAttributeType.BOOLEAN)
        return new BooleanRange();
    return new NumericRange('integer');
}

function checkIsSubRange(originalValidator: ValueAttributeRange, newValidator: ValueAttributeRange) {

  if (originalValidator.getType() !== newValidator.getType())
      return false;

  return originalValidator.isSubRange(newValidator);
}
