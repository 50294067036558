import { TextualModule } from './interfaces';
import {OpmLogicalThing} from '../OpmLogicalThing';
import {OpmVisualThing} from '../../VisualPart/OpmVisualThing';

export class StereotypeModule implements TextualModule {

  private thing: OpmLogicalThing<OpmVisualThing>;

  constructor(thing: OpmLogicalThing<OpmVisualThing>) {
    this.thing = thing;
  }

  public getText(): string {
      return '«' + this.thing.getStereotype().getName() + '» ';
  }

  public isTextActive(): boolean {
    if (this.thing.getStereotype())
      return true;
    return false;
  }

  public isActive(): boolean {
    if (this.thing.getStereotype())
      return true;
    return false;
  }
   public getPriority() {
      return -1;
   }

}

export class BelongsToStereotypTextModule implements TextualModule {

  private thing: OpmLogicalThing<OpmVisualThing>;

  constructor(thing: OpmLogicalThing<OpmVisualThing>) {
    this.thing = thing;
  }

  public getText(): string {
    return 'of ' + this.thing.getBelongsToStereotyped().getBareName();
  }

  public isTextActive(): boolean {
    if (this.thing.getBelongsToStereotyped())
      return true;
    return false;
  }

  public isActive(): boolean {
    if (this.thing.getBelongsToStereotyped())
      return true;
    return false;
  }

  public getPriority() {
    return 1;
  }
}
