import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmObject } from "../../DrawnPart/OpmObject";
import { OpmVisualObject } from "../../VisualPart/OpmVisualObject";
import { AddStateCommand } from "./add-states";
import { BringConnectedCommand } from "./bring-connected";
import { ElementCommand, CommandsDecider } from "./command";
import { EditAliasCommand } from "./edit-alias";
import { InzoomCommand } from "./inzoom";
import { RemoveCommand } from "./remove";
import { RemoveComputationCommand } from "./remove-comp";
import { UnfoldCommand } from "./unfold";
import { SetComputationCommand } from "./computation";
import { EditUnitsCommand } from "./edit-units";
import { SuppressCommand } from "./suppress";
import { DestateCommand } from "./destate";
import { ToggleAffiliationCommand } from "./toggle-affiliation";
import { ToggleEssenceCommand } from "./toggle-essence";
import { ToggleTextAutoFormatCommand } from "./toggle-text-formation";
import { HideTypeCommand } from "./hide-type-object";
import { SupressValueStatesCommand } from "./supress-value-object-states";
import {OpmLogicalThing} from "../../LogicalPart/OpmLogicalThing";
import {OpmVisualThing} from "../../VisualPart/OpmVisualThing";

export class ObjectCommandsDecider extends CommandsDecider {

    private object: OpmObject;
    private init: InitRappidService;
    private visual: OpmVisualObject;

    set(init: InitRappidService, object: OpmObject, visual: OpmVisualObject): CommandsDecider {
        this.init = init;
        this.object = object;
        this.visual = visual;
        return this;
    }

    private inzoom(): ElementCommand {
        return new InzoomCommand(this.init, this.object, this.visual);
    }

    private setComputation(): ElementCommand {
        return new SetComputationCommand(this.init, this.object, this.visual);
    }

    private removeComp(): ElementCommand {
        return new RemoveComputationCommand(this.init, this.object, this.visual);
    }

    private addState(): ElementCommand {
        return new AddStateCommand(this.init, this.object, this.visual);
    }

    private suppress(): ElementCommand {
        return new SuppressCommand(this.init, this.object, this.visual);
    }

    private destate(): ElementCommand {
        return new DestateCommand(this.init, this.object, this.visual);
    }

    private unfold(): ElementCommand {
        return new UnfoldCommand(this.init, this.object, this.visual);
    }

    private bringConnected(): ElementCommand {
        return new BringConnectedCommand(this.init, this.object, this.visual);
    }

    private remove(): ElementCommand {
        return new RemoveCommand(this.init);
    }

    private editAlias(): ElementCommand {
        return new EditAliasCommand(this.init, this.object, this.visual);
    }

    private editUnits(): ElementCommand {
        return new EditUnitsCommand(this.init, this.object, this.visual);
    }

    private toggleAffiliation(): ElementCommand {
        return new ToggleAffiliationCommand(this.init, this.object, this.visual);
    }

    private toggleEssence(): ElementCommand {
        return new ToggleEssenceCommand(this.init, this.object, this.visual);
    }

    private toggleTextAutoFormat(): ElementCommand {
        return new ToggleTextAutoFormatCommand(this.init, this.object, this.visual);
    }

    private hideValueObject(): ElementCommand {
        return new HideTypeCommand(this.init, this.object, this.visual);
    }

    private suppressStatesValueObject(): ElementCommand {
        return new SupressValueStatesCommand(this.init, this.object, this.visual);
    }

    getHaloCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();

        if (this.visual.isValueTyped())
            return [this.hideValueObject(), this.suppressStatesValueObject()];


        const logical = this.visual.logicalElement as OpmLogicalThing<OpmVisualThing>;

        if (!logical.isSatisfiedRequirementObject() && !logical.isSatisfiedRequirementSetObject())
          commands.push(this.remove());

        if ((this.visual.isInzoomed() || this.visual.isUnfolded()) == false && !logical.isSatisfiedRequirementSetObject() && !this.visual.isComputational()) {
            commands.push(this.setComputation());
        }

        if (this.visual.isComputational()) {
            commands.push(this.removeComp(), this.bringConnected(), this.editUnits());
            if (logical.isSatisfiedRequirementObject() && logical.getStereotype())
              commands.push(this.unfold());
        } else {
            commands.push(this.inzoom(), this.unfold(), this.bringConnected());
            if (!logical.isSatisfiedRequirementSetObject())
              commands.push(this.addState());
        }

        commands.push(this.editAlias());

        if (this.visual.states.length > 0 && this.visual.isComputational() == false)
            commands.push(this.suppress());

        return commands;
    }

    getToolabarCommands(): Array<ElementCommand> {
        const commands = new Array<ElementCommand>();

        if (this.visual.isValueTyped())
            return commands;

        if (this.visual.logicalElement.opmModel.currentOpd.requirementViewOf)
          return commands;

        const logical = this.visual.logicalElement as OpmLogicalThing<OpmVisualThing>;
        if (!logical.isSatisfiedRequirementObject() && !logical.isSatisfiedRequirementSetObject())
          commands.push(this.remove());

        commands.push(this.toggleAffiliation());

        if ((this.visual.isInzoomed() || this.visual.isUnfolded()) == false && !logical.isSatisfiedRequirementSetObject() && !this.visual.isComputational()) {
            commands.push(this.setComputation());
        }

        if ((this.visual.isInzoomed() || this.visual.isUnfolded()) == false && !logical.isSatisfiedRequirementSetObject() && this.visual.isComputational()) {
          commands.push(this.removeComp());
        }

        if (this.visual.isComputational()) {
            commands.push(this.removeComp(), this.bringConnected(), this.editUnits());
            if (logical.isSatisfiedRequirementObject() && logical.getStereotype())
              commands.push(this.unfold());
        } else {
            commands.push(this.inzoom(), this.unfold(), this.bringConnected(), this.toggleEssence());
            if (!logical.isSatisfiedRequirementSetObject())
              commands.push(this.addState());
        }

        commands.push(this.editAlias());

        if (this.visual.states.length > 0 && this.visual.isComputational() == false)
            commands.push(this.suppress(), this.destate());

        if (this.visual.canModifyText())
            commands.push(this.toggleTextAutoFormat());

        return commands;
    }

}
