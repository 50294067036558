import {OpmLogicalThing} from '../../models/LogicalPart/OpmLogicalThing';
import {OpmLogicalProcess} from '../../models/LogicalPart/OpmLogicalProcess';
import {OpmLogicalObject} from '../../models/LogicalPart/OpmLogicalObject';
import {Component, DoCheck} from '@angular/core';
import {ModelService} from '../../modules/app/model.service';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {valueType} from '../../models/ConfigurationOptions';
import {MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OpmModel} from '../../models/OpmModel';
import {setComputationalObjectForExport} from '../../configuration/elementsFunctionality/computationalPart';
import {CdkDragDrop, moveItemInArray, transferArrayItem} from '@angular/cdk/drag-drop';

@Component({

  selector: 'DownloadCSV-Elements',
  templateUrl: './DownloadCSV.html',
  styleUrls: ['./DownloadCSV.css']
})
export class DownloadCSVComponent implements DoCheck {

  showTypeIndex = 0;
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject];
  searchString = '';
  opmModel: OpmModel;
  // array of objects that are going to be exported to csv
  checkedObjList = [];
  // array of objects that are dropped
  droppedObjectsList = [];
  // array of objects that are can be dragged
  draggableObjectsList = [];
  // boolean operator. if true, objects sorted by name from A to Z
  objectAscSort = true;
  // boolean operator. if true, objects sorted by location from A to Z
  locationAscSort = true;
  // array of all elements (objects and OPDs)
  list;


  constructor(public modelService: ModelService, public dialogRef: MatDialogRef<DownloadCSVComponent>) {
    this.opmModel = modelService.model;
    this.updateList();
    for (const element of this.list) { // This is only check of uncheck the list
      if (((this.isComputational(element) || this.withStates(element)) && element.constructor.name !== 'OpmLogicalState')) {
        element.checked = true;
        this.checkedObjList.push(element);
      }
    }
  }

  // created array of arrays. each sub-array is 'tuple' of comp. obj. name and it's opd location.
  createArrFromObjToLoc() {
    const arr = [];
    for (const element of this.list) {
      if (element.name === 'OpmLogicalObject') {
        arr.push([element, this.getObjectLocation(element)]);
      }
    }
    return arr;
  }

  // Sort data displayed on screen by location
  sortByLoc() {
    (<HTMLInputElement>document.getElementById('Object')).innerHTML = ('Object');
    const arr = this.createArrFromObjToLoc();
    // Sort the array based on the second element
    arr.sort(function (a, b) {
      const x = a[1].toLowerCase(),
        y = b[1].toLowerCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
    const objArray = [];
    for (const tuple of arr) {
      objArray.push(tuple[0]);
    }
    this.list = objArray;
    if (this.locationAscSort) {
      this.locationAscSort = false;
      (<HTMLInputElement>document.getElementById('Location')).innerHTML = ('Location <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>');
    } else {
      this.locationAscSort = true;
      this.list = this.list.reverse();
      (<HTMLInputElement>document.getElementById('Location')).innerHTML = ('Location <i class="fa fa-sort-alpha-desc" aria-hidden="true"></i>');

    }
  }

  // Returns opd of the given object
  getObjectLocation(object) {
    if (object.visualElements.length === 0) {
      return '';
    }
    return this.opmModel.getOpdByThingId(object.visualElements[0].id).getNumberedName();
  }

  // Updates list of logical elements and filter them.
  updateList() {
    this.list = this.modelService.model.logicalElements;
    this.filter();
  }

// Enables ascending\descending sort of list of logical elements.
  sortByObjectName() {
    (<HTMLInputElement>document.getElementById('Location')).innerHTML = ('Location');
    this.list = this.list.filter(e => e instanceof this.showType[this.showTypeIndex])
      .sort((e1, e2) => this.sortFunc(e1, e2));
    if (this.searchString.length > 0) {
      this.list = this.list.filter(a => a._text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    }
    if (this.objectAscSort) {
      this.objectAscSort = false;
      (<HTMLInputElement>document.getElementById('Object')).innerHTML = 'Object <i class="fa fa-sort-alpha-asc" aria-hidden="true"></i>';
    } else {
      this.objectAscSort = true;
      this.list = this.list.reverse();
      (<HTMLInputElement>document.getElementById('Object')).innerHTML = 'Object <i class="fa fa-sort-alpha-desc" aria-hidden="true"></i>';

    }
  }

  // filter list of logical elements.
  filter() {
    this.list = this.list.filter(e => e instanceof this.showType[this.showTypeIndex]);
    if (this.searchString.length > 0) {
      this.list = this.list.filter(a => a._text.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    }
  }


  // aux function for sort. Return 1 if e1 smaller that e2 (sort made by comparing two strings that include integers)
  private sortFunc(e1, e2): number {
    if (e1.name === e2.name) {
      let e1Text = (e1.text).replace(/ *\{[^}]*\) */g, '');
      let e2Text = (e2.text).replace(/ *\{[^}]*\) */g, '');
      e1Text = e1Text.replace(/ *\[[^]*\) */g, '');
      e2Text = e2Text.replace(/ *\[[^]*\) */g, '');
      const items = [e1Text, e2Text];
      items.sort((a, b) => a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}));
      return items[0] === e1Text ? -1 : 1;
    }
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  ngDoCheck() {
    if ($('.thingDiv').length > 0 && $('.cdk-overlay-pane').length === 1) {
      let maximalDivWidth = $('.thingDiv')[0].getClientRects()[0].width;
      for (const div of $('.thingDiv')) {
        if (div.getClientRects()[0].width > maximalDivWidth) {
          maximalDivWidth = div.getClientRects()[0].width;
        }
      }
      const newWidth = Math.max(Math.min(maximalDivWidth + 340, window.innerWidth * 0.99), 600);
      $('.cdk-overlay-pane')[0].style.width = newWidth + 'px';
    }
  }


  search() {
    (<HTMLInputElement>document.getElementById('Location')).innerHTML = ('Location');
    (<HTMLInputElement>document.getElementById('Object')).innerHTML = ('Object');
    this.updateList();
  }

  // check if the element in computational
  isComputational(element) {
    if (element.constructor.name === 'OpmLogicalObject') {
      return (element.valueType !== valueType.None && element.valueType !== undefined);
    }
  }

  /**
   * check if the element is regular object that has two or more states (without the range Type object)
   * @param element
   */
  withStates(element) {
    if (element.constructor.name === 'OpmLogicalObject') {
      return (element.states.length > 1 && element.text !== 'Type');
    }
  }

  // Close Modal
  closeUsingCloseBtn() {
    this.checkedObjList = [];
    for (const logical of this.list.filter(item => (this.isComputational(item) || this.withStates(item)) &&
      item.constructor.name !== 'OpmLogicalState')) {
      this.checkedObjList.push(logical);
    }
    setComputationalObjectForExport(this.checkedObjList);
    this.dialogRef.close();
  }

  // check only checkbox near to selected object.If object is checked- add to checkedObjList, otherwise remove
  changedSelection($event: Event, element: any) {
    element.checked = (<any>$event.target).checked;
    if (element.checked) {
      this.checkedObjList.push(element);
    } else {
      this.checkedObjList = this.checkedObjList.filter(item => item !== element);
    }
    // if at least one object is unchecked,then main checkbox should be unchecked too.
    for (element of this.list) {
      if ((this.isComputational(element) || this.withStates(element)) && element.name !== 'OpmLogicalState') {
        if (!element.checked) {
          (<HTMLInputElement>document.getElementById('mainCheck')).checked = false;
          return;
        }
      }
    }
    (<HTMLInputElement>document.getElementById('mainCheck')).checked = true;
  }

  // check all checkboxes and update checkedObjList that stores all computational objects that we will sort and export to csv later.
  checkOrUncheckAll($event: Event) {
    for (const logical of this.list.filter(item => (this.isComputational(item) || this.withStates(item)) &&
      item.constructor.name !== 'OpmLogicalState')) {
      logical.checked = (<any>$event.target).checked;
      if (logical.checked) {
        if (!this.checkedObjList.includes(logical)) {
          this.checkedObjList.push(logical);
        }
      } else {
        this.checkedObjList = this.checkedObjList.filter(item => item !== logical);

      }
    }
  }

  // show or hide div components
  // when switching between two windows, make sure the preferences state equal to user choices.
  showHide(str) {
    if (str === 'Next') {
      for (const object of this.list) {
        if (!object.checked) {
          this.draggableObjectsList = this.draggableObjectsList.filter(item => item !== object);
          this.droppedObjectsList = this.droppedObjectsList.filter(item => item !== object);
        }
        if (object.checked && !this.droppedObjectsList.includes(object) && !this.draggableObjectsList.includes(object)) {
          this.draggableObjectsList.push(object);
        }
      }
    } else if (str === 'Back') {
      this.checkedObjList = this.draggableObjectsList.concat(this.droppedObjectsList.filter(x => this.draggableObjectsList.every(y => y !== x)));

    }
    document.getElementById('wrapper').classList.toggle('open');
  }

  sameType(listElement, element: any) {
    return (typeof listElement === typeof element);
  }


// Transferring dragged items between connected drop zones
  drop(event: CdkDragDrop<string[]>) {
    if (event.previousContainer === event.container) {
      moveItemInArray(event.container.data, event.previousIndex, event.currentIndex);
    } else {
      transferArrayItem(event.previousContainer.data,
        event.container.data,
        event.previousIndex,
        event.currentIndex);
    }
  }


  getObjectForExportAndCLose(droppedObjects, undroppedObjects) {
    // remove empty values from list.
    droppedObjects = droppedObjects.filter(item => item !== undefined);
    const objectForExport = droppedObjects.concat(undroppedObjects);
    setComputationalObjectForExport(objectForExport);
    this.dialogRef.close();
    validationAlert('Saved successfully', 3500, 'Success');
  }

}






