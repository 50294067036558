import { Component } from '@angular/core';
import { GraphService } from '../../../rappid-components/services/graph.service';
import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';
import { AboutDialogComponent } from '../../../dialogs/About/about';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { OplService } from '../../../opl-generation/opl.service';
import { ChooseExportedFileNameComponent } from '../../../dialogs/choose-exported-file-name/choose-exported-file-name';
import * as FileSaver from 'file-saver';
import { UploadFile } from '../../../dialogs/FileUploader/FileUploader';
import { SaveScreenshotComponent } from '../../../dialogs/saveScreenshot-dialog/saveScreenshot';
import { Router } from '@angular/router';
import { EntityType } from '../../../models/model/entities.enum';
import { factory as Drawn } from '../../../configuration/elementsFunctionality/draw.view';
import { OpmLogicalProcess } from "../../../models/LogicalPart/OpmLogicalProcess";
import { OpmLogicalObject } from "../../../models/LogicalPart/OpmLogicalObject";
import { Essence } from "../../../models/ConfigurationOptions";
import {TabsManager} from "../../app/tabsService";
import {ContextService} from "../../app/context.service";
import {OpmThing} from "../../../models/DrawnPart/OpmThing";
import {OpmProcess} from "../../../models/DrawnPart/OpmProcess";
import {OpmObject} from "../../../models/DrawnPart/OpmObject";
// import {getInitRappidShared} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {HttpClient} from "@angular/common/http";

@Component({
  selector: 'opc-header',
  templateUrl: `./header.component.html`,
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  paperScroller;
  graph;
  paper;
  modelName: string;
  currentUser: any;
  numToCreate = 1;
  numTOCreateDIvIsVisible = false;

  autosaveObservable;
  isSubscribe;
  menuOpen;
  isActive = true;
  elementtatMenuIsOpn;
  isSystemic;
  isEnviromental;
  isInformatical;
  isPhysical;
  drawnElement;
  private myUrl: any;
  private scenario: {
    project?: boolean;
    path?: string;
    name?: string;
    model?: boolean,
    modelData?: any
  } = {};

  private newThingTouchMoveFunc;
  private newThingTouchEndFunc;
  private tabsManager: TabsManager;

  constructor(
    private graphService: GraphService,
    private initRappidService: InitRappidService,
    private _dialog: MatDialog,
    private oplService: OplService,
    private router: Router,
    private contextService: ContextService,
    private http: HttpClient
  ) {
    this.tabsManager = new TabsManager(this.initRappidService, this.contextService);
    this.graph = graphService.graph;
    this.paper = initRappidService.paper;
    this.paperScroller = initRappidService.paperScroller;

    this.paper.on('blank:pointerdown', () => {
      if (this.menuOpen) {
        this.toggleMenu();
      }
    });
  }

  about() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(AboutDialogComponent);

    dialogRef.afterClosed().subscribe(result => {
      if (!!result) {

      }
    });
  }

  executeIfLogged(func) {
    this.toggleMenu();
    return this[func]();

  }

  toggleMenu() {
    if (this.menuOpen) {
      this.menuOpen = false;
      this.isActive = true;
      return;
    } else if (!this.menuOpen) {
      this.menuOpen = true;
      this.isActive = false;
    }
  }

  onDrop(event, val) {
    const currentOpd = this.initRappidService.getOpmModel().currentOpd;
    if (currentOpd.isStereotypeOpd() || currentOpd.requirementViewOf || this.initRappidService.isDSMClusteredView.value === true ||
      currentOpd.sharedOpdWithSubModelId || currentOpd.belongsToSubModel)
      return;
    const paper = this.initRappidService.paper;
    this.graphService.graph.startBatch('addNewThing');
    const b = this.initRappidService.paperScroller.getVisibleArea();
    const c = $(this.initRappidService.paperScroller.el).offset();
    const visibleNavbar = document.getElementById('visibleNav');
    const hiddenNavbar = document.getElementById('hiddenNav');
    const curNav = (hiddenNavbar) ? hiddenNavbar : visibleNavbar;
    const eventCX = event.type === 'touchend' ? event.changedTouches[event.changedTouches.length - 1].clientX : event.clientX;
    const eventCy = event.type === 'touchend' ? event.changedTouches[event.changedTouches.length - 1].clientY : event.clientY;
    const paperScale = paper.scale();
    if (c.left <= eventCX && eventCX <= c.left * paperScale.sx + b.width * paperScale.sx &&
      c.top * paperScale.sy <= eventCy && eventCy <= c.top * paperScale.sy + b.height * paperScale.sy) {
      // this.graphService.fromStencil = true;
      const a = this.initRappidService.paper.clientToLocalPoint(eventCX, eventCy);
      // const value = (event.type === 'touchend') ?  event.path.find( b => b.constructor.name === 'HTMLButtonElement').value : event.srcElement.firstElementChild.value;
      const value = val;
      const type = value === 'object' ? EntityType.Object : EntityType.Process;

      const model = this.initRappidService.getOpmModel();
      model.logForUndo(value + ' added');
      model.setShouldLogForUndoRedo(false, 'headerComponent-onDrop');
      const ret = model.createToScreen(type);

      const visual = ret.visual;

      const drawn = Drawn(visual.type);
      drawn.attr({ text: { textWrap: { text: (<any>visual.logicalElement).text } } });
      drawn.lastEnteredText = drawn.attr('text/textWrap/text');

      const heightOffset = (curNav && eventCy < curNav.getBoundingClientRect().bottom)
        ? curNav.getBoundingClientRect().height * 1.5 : 0;

      drawn.set({
        position: {
          x: a.x - drawn.get('size').width / 2,
          y: a.y - drawn.get('size').height / 2 + heightOffset
        }
      });

      visual.updateParams(drawn.getParams());
      visual.logicalElement.updateParams(drawn.getParams());

      // this.graphService.graph.startBatch('ignoreEvents');
      // this.graphService.graph.startBatch('ignoreAddEvent');
      this.graphService.fromStencil = true;
      drawn.createPorts();
      this.graphService.graph.addCell(drawn);
      if (visual.getEssence() === Essence.Physical && visual.fatherObject && visual.fatherObject.getEssence() === Essence.Informatical) {
          (<OpmThing>drawn).toggleEssence(visual);
      }
      this.graphService.fromStencil = false;
      // this.graphService.graph.stopBatch('ignoreAddEvent');
      // this.graphService.graph.stopBatch('ignoreEvents');

      const cellView = paper.findViewByModel(drawn.id);
      drawn.openTextEditor(cellView, this.initRappidService);
      cellView.model.pointerUpHandle(cellView, this.initRappidService);
      this.graphService.graph.stopBatch('addNewThing');
      model.setShouldLogForUndoRedo(true, 'headerComponent-onDrop');
    }
  }

  onStartDrag(event, index) {
    const that = this;
    const cloned = this.cloneThing(event);
    event.preventDefault();
    if (!event.touches) {
      window.onmousemove = function (e) { that.moveCloned(e, cloned) };
      window.onmouseup = function (e) { that.removeCloned(e, cloned) };
    } else {
      this.newThingTouchMoveFunc = function (e) { that.moveCloned(e, cloned) };
      this.newThingTouchEndFunc = function (e) { that.removeCloned(e, cloned) };
      window.addEventListener('touchmove', this.newThingTouchMoveFunc);
      window.addEventListener('touchend', this.newThingTouchEndFunc);
    }
  }

  removeCloned(event, clone) {
    clone.cloned.remove();
    clone.textNode.remove();
    const type = clone.cloned.id.includes('object') ? 'object' : 'process';
    this.onDrop(event, type)
    window.onmousemove = function (e) { };
    window.onmouseup = function (e) { };
    window.removeEventListener('touchmove', this.newThingTouchMoveFunc);
    window.removeEventListener('touchend', this.newThingTouchEndFunc);
  }

  moveCloned(event, clone) {
    const scrollTop = Math.max(document.body.scrollTop, document.documentElement.scrollTop);
    const clientX = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientX : event.clientX;
    const clientY = event.changedTouches && event.changedTouches.length > 0 ? event.changedTouches[0].clientY : event.clientY;
    clone.cloned.style.top = (clientY - 30 + scrollTop) + "px";
    clone.cloned.style.left = (clientX - 67) + "px";
    const clonedRect = clone.cloned.getClientRects()[0];
    const textRect = clone.textNode.getClientRects()[0];
    clone.textNode.style.top = clonedRect.y + ((clonedRect.height - textRect.height) / 2) + "px";
    clone.textNode.style.left = clonedRect.x + ((clonedRect.width - textRect.width) / 2) + "px";
  }

  cloneThing(event) {
    let cloned;
    if (event.currentTarget.children[0].id === 'object')
      cloned = $('#objectDrag')[0].cloneNode() as any;
    else cloned = $('#processDrag')[0].cloneNode() as any;

    cloned.style.position = "absolute";
    cloned.style.top = "-800px";
    cloned.style.left = "-800px";
    cloned.style.zIndex = "90000";
    cloned.style.opacity = .25;
    if (this.oplService.settings.essence !== Essence.Informatical)
      cloned.style.filter = 'url(#dropShadowv-2-1096745468)';
    document.body.appendChild(cloned);
    const textNode = document.createElement('label') as any;
    const textContent = event.currentTarget.children[0].id === 'object' ? 'Object ' + (OpmLogicalObject.logicalCounter) :
      OpmLogicalProcess.getNumberedNameByNum(OpmLogicalProcess.logicalCounter);
    textNode.innerHTML = textContent;
    textNode.style.position = 'absolute';
    textNode.style.top = '20px';
    textNode.style.left = '20px';
    textNode.style.zIndex = '999999999';
    textNode.style.fontFamily = 'Arial';
    textNode.style.fontSize = '14px';
    textNode.style.fontWeight = 'bold';
    textNode.style.opacity = .25;
    document.body.appendChild(textNode);
    return { cloned: cloned, textNode: textNode };
  }

  exportOPL() {
    this.toggleMenu();
    // Open a dialog box that will allow the user to choose the file name
    const dialogRef = this._dialog.open(ChooseExportedFileNameComponent, {
      height: '270px',
      width: '400px',
    });
    // This function invokes the exporting OPL process
    dialogRef.afterClosed().subscribe((fileName) => {
      if (fileName === 'CLOSED') { // If the user pressed CANCEL.
        return;
      } else {
        // If the user pressed OK but didn't give a name to the exported file -
        // get default file name
        if ((fileName.trim() === '') || !fileName) {
          fileName = this.initRappidService.opmModel.createDefaultModelName();
        }
        // The process of saving the exported file is starting.
        // An array that will include the OPL sentences for each OPD separately.
        const arrayOfOpds = new Array();
        arrayOfOpds.push('<html><body>'); // Open an HTML Tag for arrayOfOpds.
        // The next lines define the colors of words according to their type - object \ process \ state for arrOfSD.
        arrayOfOpds.push('<style> .object { color: #00b050; } </style>');
        arrayOfOpds.push('<style> .process { color: #0070c0; } </style>');
        arrayOfOpds.push('<style> .state { color: #808000; } </style>');
        // An array that will include the OPL sentences for all Opds together
        // without duplication.
        const arrOfAllOpl = new Array();
        arrOfAllOpl.push('<html><body>'); // Open an HTML Tag for arrOfAllOpl.
        /// The next lines define the colors of words according to their type - object \ process \ state for arrOfAllOpl.
        arrOfAllOpl.push('<style> .object { color: #006400; } </style>');
        arrOfAllOpl.push('<style> .process { color: #00008B; } </style>');
        arrOfAllOpl.push('<style> .state { color: #808000; } </style>');
        arrOfAllOpl.push('<b>OPL spec.</b>'); // Insert a title for the arrOfAllOpl array.
        arrOfAllOpl.push('<br>');
        // Save the current Opd that the user is editing, for rendering it at the end of the function.
        const currentOpd = this.initRappidService.opmModel.currentOpd;
        // Get all existing OPDs in the graph.
        const allOpd = this.initRappidService.opmModel.opds.filter(o => !o.isHidden);
        // This loop goes over all OPDs and inserts the OPL sentences to the arrays.
        for (let i = 0; i < allOpd.length; i++) {
          this.graphService.renderGraph(allOpd[i], this.initRappidService); // Goes to the next OPD in the allOpd array.
          // Get the name of the current OPD.
          const nameOfOpd = allOpd[i].name;
          arrayOfOpds.push('<b>');
          arrayOfOpds.push(nameOfOpd); // Insert the name of the current OPD as a title in the arrOfSD array.
          arrayOfOpds.push('</b>');
          arrayOfOpds.push('<br>');
          // Get the cells that contain the OPL sentences of the current OPD.
          const cells = this.oplService.generateOpl(); // YANG's function
          // This loop goes over all of the cells and inserts the OPL sentences to the arrays.
          for (let j = 0; j < cells.length; j++) {
            if (cells[j].opl) { // Check if the cell contains an OPL sentence.
              // Get the OPL sentence of the cell.
              const innerHTML = cells[j].opl;
              arrayOfOpds.push(innerHTML); // Insert the OPL sentence into the arrOfSD array.
              arrayOfOpds.push('<br>');
              // Initialization of a temp boolean variable.
              // It will be used as a flag to check if the current OPL sentence already exists in the arrOfAllOpl array.
              if (!arrOfAllOpl.includes(innerHTML)) {
                arrOfAllOpl.push(innerHTML); /// Insert the OPL sentence into the arrOfAllOpl array.
                arrOfAllOpl.push('<br>');
              }
              /*
              let exists = 'False';
              // This loop goes over the arrOfAllOpl array and checks if the current OPL sentence already exists in the array.
              for (let k = 0; k < arrOfAllOpl.length; k++) {
                if (arrOfAllOpl[k] === innerHTML) {
                  exists = 'True';
                }
              }
              if (exists === 'False') { /// Check if the flag indicates that the current OPL sentence doesn't already exist in the array.
                arrOfAllOpl.push(innerHTML); /// Insert the OPL sentence into the arrOfAllOpl array.
                arrOfAllOpl.push('<br>');
              }*/
            }
          }
          arrayOfOpds.push('<br>');
        }
        arrayOfOpds.push('</body></html>'); // Close an HTML Tag for arrayOfOpds.
        arrOfAllOpl.push('</body></html>'); // Close an HTML Tag for arrOfAllOpl.
        this.graphService.renderGraph(currentOpd, this.initRappidService); // Goes back to the OPD that the user is editing.
        // Concatenation of the arrOfSD and the arrOfAllOpl arrays.
        const exportFileContent = arrayOfOpds.concat(arrOfAllOpl);
        // Create the exported file.
        const exportFile = new Blob(exportFileContent, { type: 'html/plain;charset=utf-8' });
        FileSaver.saveAs(exportFile, fileName + '.html'); // Save the exported file.
      }
    });
  }

  closeTextEditorForNewSelection() {
    if (this.initRappidService.textEditor && this.initRappidService.selectedElement)
      this.initRappidService.selectedElement.closeTextEditor(this.initRappidService);
  }

  importModel() {
    this.toggleMenu();
    const dialogRef = this._dialog.open(UploadFile);
    const That = this;
    dialogRef.afterClosed().subscribe(result => {
    });
  }

  saveScreenshot() {
    this.toggleMenu();
    this._dialog.open(SaveScreenshotComponent);
  }

  openSettings() {
    this.router.navigate(['/settings', { outlets: { settings_main: ['home'] } }], { skipLocationChange: true });
  }

  rightClickHandle(evt) {
    evt.preventDefault();
    this.setNumOfElToCreate();
  }
  setNumOfElToCreate() {
    this.numTOCreateDIvIsVisible = !this.numTOCreateDIvIsVisible;
  }

  setNumOfElementsToCreate(e) {
    this.numToCreate = parseInt(e.target.value);
  }

  /*
  TODODANIEL
  collDialogOpen() {
    console.log('Collaboration Settings');
    const dialogRef = this._dialog.open(CollaborationDialogComponent, {
      height: '600px',
      width: '600px',
      data: {
        userToken: this.initRappidService.getOpmModel().permissions.tokenID, currentUser: this.currentUser,
        org: this.userService.userOrg,
        checkedUsers: this.initRappidService.getOpmModel().permissions.readIDs,
        checkedGroups: this.initRappidService.getOpmModel().permissions.readGroupsIDs,
        modelOwner: this.initRappidService.getOpmModel().permissions.ownerID
      },
    });
  }*/

  dropTab(event) {
    return this.tabsManager.dropTab(event);
  }

  replaceContextByTab(item, keepSubModels = true) {
    if (this.initRappidService.isRendering || this.initRappidService.isLoadingModel)
      return;
    return this.tabsManager.replaceContextByTab(item, keepSubModels);
  }

  closeTab(item: { context, modelData }) {
    return this.tabsManager.closeTab(item);
  }

  getTabsStyle() {
    return 'height: 25px; margin-top: 37px; display: flex; width: ' + (window.innerWidth - 850) + 'px;'
  }

  newModel() {
    OpmProcess.resetCounter();
    OpmObject.resetCounter();
    this.initRappidService.setSelectedElementToNull();
    this.contextService.newModel();
  }

  itemHasSubModels(item) {
    return item.modelData.opds.some(opd => opd.sharedOpdWithSubModelId);
  }

  refreshSubModels(item) {
    return this.replaceContextByTab(item, false);
  }

  async testTest() {
    // const model = JSON.stringify(getInitRappidShared().getOpmModel().toJson());
    // const url = 'https://20231109t150130-dot-opcloud-trial.uc.r.appspot.com/flattening';
    // console.log(new Date());
    // const ret = await this.http.post<any>(url, {model}).toPromise().catch(err => console.error(err));
    // console.log(new Date());
    // this.initRappidService.opmModel.fromJson(ret.data);
  }
}
