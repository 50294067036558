// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p{
  text-align: center;
}

h3 {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
  margin-top: 3px;
}

.selectDiv{
  width: 245px;
  margin-left: 20px;
  margin-top: -2px;
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 4px;
  padding: 3px;
}

.selectDiv select{
  width: 240px;
  background-image: url('/assets/icons/select_arrow.png');
  background-repeat: no-repeat;
  background-position: right center;
  border: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  overflow:hidden;
  color: #1A3763;
  font-family: Roboto, Arial, Helvetica, sans-serif;
  font-weight: normal;
  font-size: medium;
  Opacity: 70%;
  text-align-last: center;
  outline-color: rgba(255,214,112,0);
}

.flexInline {
  display: inline-flex;
  margin-top: 8px;
}

#footerButtons {
  width: 270px;
  display: flex;
  align-items: center;
  height: 50px;
  margin-top: 30px;
  margin-left: calc(50% - 135px);
}

button {
  margin: auto;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/validation-settings/validation-settings.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,iBAAiB;EACjB,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,uDAAuD;EACvD,4BAA4B;EAC5B,iCAAiC;EACjC,YAAY;EACZ,wBAAwB;EACxB,qBAAqB;EACrB,eAAe;EACf,cAAc;EACd,iDAAiD;EACjD,mBAAmB;EACnB,iBAAiB;EACjB,YAAY;EACZ,uBAAuB;EACvB,kCAAkC;AACpC;;AAEA;EACE,oBAAoB;EACpB,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,YAAY;EACZ,gBAAgB;EAChB,8BAA8B;AAChC;;AAEA;EACE,YAAY;AACd","sourcesContent":["p{\n  text-align: center;\n}\n\nh3 {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n  margin-top: 3px;\n}\n\n.selectDiv{\n  width: 245px;\n  margin-left: 20px;\n  margin-top: -2px;\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 4px;\n  padding: 3px;\n}\n\n.selectDiv select{\n  width: 240px;\n  background-image: url('/assets/icons/select_arrow.png');\n  background-repeat: no-repeat;\n  background-position: right center;\n  border: none;\n  -webkit-appearance: none;\n  -moz-appearance: none;\n  overflow:hidden;\n  color: #1A3763;\n  font-family: Roboto, Arial, Helvetica, sans-serif;\n  font-weight: normal;\n  font-size: medium;\n  Opacity: 70%;\n  text-align-last: center;\n  outline-color: rgba(255,214,112,0);\n}\n\n.flexInline {\n  display: inline-flex;\n  margin-top: 8px;\n}\n\n#footerButtons {\n  width: 270px;\n  display: flex;\n  align-items: center;\n  height: 50px;\n  margin-top: 30px;\n  margin-left: calc(50% - 135px);\n}\n\nbutton {\n  margin: auto;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
