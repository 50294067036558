import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA  } from '@angular/material/legacy-dialog';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';
import {UserService} from '../../rappid-components/services/user.service';
import {validationAlert} from '../../configuration/rappidEnviromentFunctionality/shared';
import {ExportOPLAPIService} from '../../modules/app/export-opl.service';

@Component ({
  selector: 'opl-generative-ai-dialog',
  templateUrl: 'opl-generative-ai.html',
  styleUrls: ['opl-generative-ai.css']
})
export class OPLGenerativeAIDialogComponent implements OnInit {

  loggedUser: any;
  response: string;
  tooltip: string;
  waiting: boolean;
  title: string;
  type: string;

  constructor(
    public dialogRef: MatDialogRef<OPLGenerativeAIDialogComponent>,
    private exportOPLService: ExportOPLAPIService,
    private userService: UserService,
    private initRappidService: InitRappidService,
    @Inject(MAT_DIALOG_DATA) data: any) {
    this.type = data.type;
  }

  ngOnInit() {
    this.waiting = false;
    this.loggedUser = this.userService.user?.userData;
    if (this.type === 'model') {
      this.title = 'AI OPM Model Documentation and Summarization';
    } else {
      this.title = 'Generating a summary of this diagram from the OPD\'s formal OPL';
    }
    this.response = 'Generating a readable OPL from the formal OPL by Gemini AI';
    this.tooltip = 'Note: This action may take time and incur computational costs.';
  }

  async generativeOPLByAI() {
    this.waiting = true;
    let oplText: string;
    if (this.type === 'model') {
      const params = [this.initRappidService.opmModel.name, false, null, false];
      oplText = await this.exportOPLService.exportOPL(params);
    } else {
      oplText = $('.opl-container')[0].innerText;
    }
    const AIopl = await this.userService.getOPLGenAI(this.loggedUser.uid, oplText);
    this.response = AIopl;
    this.waiting = false;
  }

  copyGenAIText() {
    navigator.clipboard.writeText(this.response).then().catch(e => console.error(e));
    validationAlert('Generative AI text copied to clipboard', 2000, 'warning');
  }

}
