// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.buttons {
  padding-top: 5px;
  text-align: center;
}

h3 {
  color: #1a3763;
}

input {
  width: calc(100% - 150px);
}
img {
  max-height: 200px;
}

.main {
  text-align: center;
}

#imgPreview {
  /*width: 100%;*/
}

.previewDiv {
  height: 250px;
  margin-top: 20px;
}

::ng-deep .mat-form-field.mat-focused .mat-form-field-label {
  color: #1A3763 !important;
}

::ng-deep .mat-form-field-underline, ::ng-deep .mat-form-field-ripple {
  background-color: #1A3763 !important;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/background-photo-dialog/background-photo-dialog.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,yBAAyB;AAC3B;AACA;EACE,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,gBAAgB;AAClB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,oCAAoC;AACtC","sourcesContent":[".buttons {\n  padding-top: 5px;\n  text-align: center;\n}\n\nh3 {\n  color: #1a3763;\n}\n\ninput {\n  width: calc(100% - 150px);\n}\nimg {\n  max-height: 200px;\n}\n\n.main {\n  text-align: center;\n}\n\n#imgPreview {\n  /*width: 100%;*/\n}\n\n.previewDiv {\n  height: 250px;\n  margin-top: 20px;\n}\n\n::ng-deep .mat-form-field.mat-focused .mat-form-field-label {\n  color: #1A3763 !important;\n}\n\n::ng-deep .mat-form-field-underline, ::ng-deep .mat-form-field-ripple {\n  background-color: #1A3763 !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
