import { validationAlert } from '../rappidEnviromentFunctionality/shared';
import { OpmModel } from '../../models/OpmModel';
import { OpmOpd } from '../../models/OpmOpd';
import { elmIsSubObject, handleExeExceptions, hasWhiteSpaces, ObjectItem } from './computationalPartUtils';
import { PathsFromObjectsToProcessCalculator } from './paths-from-objects-to-process-calculator';
import { VariablesCalculator } from './variables-calculator';
import { WebSocketCommunicatorI } from './communication-object';
import { code } from '../../models/ConfigurationOptions';

/**
 * A class that handles MQTT functions.
 * opmModel - the current opm model
 * initRappid - init rappid service
 * */
export class MqqtFunctionExecutor {
  constructor(private readonly opmModel: OpmModel, private readonly mqttWS: WebSocketCommunicatorI) {
  }

  public async execute(opd: OpmOpd, OpmVisualProcess, valuesArray: ObjectItem[], MQTTFunction) {
    const pathsCalculator = new PathsFromObjectsToProcessCalculator(opd, OpmVisualProcess, this.opmModel, code.MQTT);
    const all_paths = pathsCalculator.calculate(opd, valuesArray);
    const variables_array = this.calc_variables_array(all_paths, valuesArray);
    return this.runMQTTFunction(variables_array, MQTTFunction, variables_array);
  }
  private async runMQTTFunction(valuesArray, MQTTFunction, variables_array: ObjectItem[]) {
    let x;
    const message = this.calcAndValidateMessage(valuesArray, MQTTFunction, variables_array);
    if (this.mqttWS.isActive) {
      if (MQTTFunction.MQTTtopicwhat == "publish") {
        this.mqttWS.send({
          what: MQTTFunction.MQTTtopicwhat,
          topic: this.replace_topic(MQTTFunction.MQTTtopic, variables_array),
          message: message
        });
        x = "published";
      } else if (MQTTFunction.MQTTtopicwhat == "subscribe") {
        this.mqttWS.send({
          what: MQTTFunction.MQTTtopicwhat,
          topic: this.replace_topic(MQTTFunction.MQTTtopic, variables_array),
        });
        x = this.mqttWS.get();
      }
    } else {
      x = '';
      validationAlert('No MQTT connection established!', 2500, undefined, true);
    }
    return x;
  }
  /**
   * returns the message that should be sent (undefined if there is no need to send a message)
   */
  calcAndValidateMessage(valuesArray: any, MQTTFunction: any, variables_array: ObjectItem[]) {
    if (MQTTFunction.MQTTtopicwhat === 'publish'){
      const mqttMessage = MQTTFunction.MQTTMessage;
      const mqttMessageInVariableArray = variables_array.filter(value => value.name === mqttMessage || value.alias === mqttMessage)[0];
      const mqttMessageIsEmpty = !mqttMessage || (mqttMessage === '');
      const mqttMessageVariable = ( mqttMessageIsEmpty || hasWhiteSpaces(mqttMessage)) ? undefined : mqttMessageInVariableArray;
      if ( !mqttMessageIsEmpty && (hasWhiteSpaces(mqttMessage) || mqttMessageInVariableArray === undefined)){
        handleExeExceptions('The inserted message is invalid (included spaces or does not exist). The sent message <br> will be according to the connected computational objects.');
      }
      if (mqttMessageVariable === undefined ){
        // const mqtt_variables_array_concated_ids_as_set = new Set(variables_array.map(elm => elm.concatedId));
        // const amount_of_instrumented_links_computational_object = new Set(variables_array.filter(elm => elm.concatedId.split('_').length > 2)).size;
        const variables_array_as_ids = variables_array.map(elm => elm.concatedId);
        const amount_of_different_computational_object = new Set((variables_array.filter(elm => !elmIsSubObject(elm.concatedId,variables_array_as_ids))).map(elm => elm.concatedId)).size; // the set is needed to prevent an object with alias and name to appear twice
        if (amount_of_different_computational_object > 1) { // the same object whether if they will have different identifiers will have the same concatedID
          handleExeExceptions('A MQTT computational process can have only one connected computational object.');
        } else {
          if (variables_array.length === 0) {
            handleExeExceptions('A MQTT computational process must have one connected computational object.');
          }
        }
      }
      // there should be a valid message or one computational object (or its subs)
      return mqttMessageVariable ? mqttMessageVariable.sourceElementValue: valuesArray[0].sourceElementValue;
    } else { // the other topicwhat is subscribe and there is no need for a message
      return undefined;
    }
  }
  /**
   * A function that calculates variables_array, which is an ObjectItem array that includes for each object in it the
   * the object name,alias and value.
   **/
  private calc_variables_array(all_paths: Map<string, ObjectItem[][]>, valuesArray: ObjectItem[]) {
    let all_variables_str = [];
    let variables_array = [];
    let alias = [];
    new VariablesCalculator(all_paths, valuesArray).calc_variables_str(all_variables_str, code.MQTT,
      [], alias, variables_array);
    return variables_array;
  }

  /**
   * replace each variable(the variable are separated by '/') in the topic with it's value (if a variable with this
   * alias/name exists)
   * topic - the string we are searching for variables to replace
   * variables_array - includes the variables and their values
   **/
  private replace_topic(topic: string, variables_array: ObjectItem[]) {
    let string_to_return = '';
    const m = topic.split('/');
    for (const sub_topic_or_data of m) {
      const variable = variables_array.filter(value => value.name === sub_topic_or_data || value.alias === sub_topic_or_data)[0];
      if (variable !== undefined && !hasWhiteSpaces(sub_topic_or_data)) { // do not replace variables without legal identifiers
        string_to_return += (variable.sourceElementValue + '/');
      } else {
        string_to_return += (sub_topic_or_data + '/');
      }
    }
    return string_to_return.substring(0, string_to_return.length - 1);
  }
}
