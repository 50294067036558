import {
  BrowserCacheLocation,
  InteractionType,
  IPublicClientApplication,
  LogLevel,
  PublicClientApplication
} from "@azure/msal-browser";
import {MsalGuardConfiguration, MsalInterceptorConfiguration} from "@azure/msal-angular";

export function loggerCallback(logLevel, message) {
  // console.log(message);
}

export function MSALInstanceFactory(): IPublicClientApplication {
  const isIE = false;
  return new PublicClientApplication({
    auth: {
      clientId: '5b5dd86d-c12a-46b6-9ab6-6bdbcbb4ba11',
      // redirectUri: 'http://localhost:4200',
      // postLogoutRedirectUri: 'http://localhost:4200'
      redirectUri: document.location.origin,
      postLogoutRedirectUri: 'https://microsoft.com'
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
      storeAuthStateInCookie: isIE, // set to true for IE 11
    },
    system: {
      allowNativeBroker: false,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false
      }
    }
  });
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return { interactionType: InteractionType.Redirect };
}
