export enum EntityType {
    Object = 'OpmLogicalObject',
    Process = 'OpmLogicalProcess',
    State = 'OpmLogicalState'
}

export enum RelationType {
    Procedural,
    Tagged,
    Fundamental
}
