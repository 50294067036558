import { InitRappidService } from "../../../rappid-components/services/init-rappid.service";
import { OpmThing } from "../../DrawnPart/OpmThing";
import { OpmVisualThing } from "../../VisualPart/OpmVisualThing";
import { ElementAction, ElementCommand, ElementHaloHandle, ElementToolbarHandle } from "./command";

export class UnfoldCommand implements ElementCommand {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing,
        private readonly visual: OpmVisualThing) {
    }

    createHaloHandle(): ElementHaloHandle {
      const inUnfoldedOPD = this.isUnfoldedInCurrentOPD();
      const displayTitle = this.visual.getRefineeUnfold() ? inUnfoldedOPD ? 'This is the unfolded view' : 'Show Unfold View' : 'Unfold';
      return {
        flag: true,
        name: 'unfold',
        displayTitle: displayTitle,
        svg: 'unfold',
        action: new UnfoldAction(this.init, this.thing),
        gif: 'assets/gifs/unfolding.gif',
      };
    }

  createToolbarHandle(): ElementToolbarHandle {
    const inUnfoldedOPD = this.isUnfoldedInCurrentOPD();
    const displayTitle = this.visual.getRefineeUnfold() ? inUnfoldedOPD ? 'This is the unfolded view' : 'Show Unfold View' : 'Unfold';
    return {
      name: 'unfold',
      displayTitle: displayTitle,
      svg: 'unfold',
      action: new UnfoldAction(this.init, this.thing),
      gif: 'assets/gifs/unfolding.gif',
    };
  }

  private isUnfoldedInCurrentOPD() {
    let inUnfolded = false;
    const unfoldOPD = this.init.opmModel.getOpdByThingId(this.visual.getRefineeUnfold()?.id);
    if (unfoldOPD === this.init.opmModel.currentOpd) {
      inUnfolded = true;
    }
    return inUnfolded;
  }
}

class UnfoldAction implements ElementAction {

    constructor(
        private readonly init: InitRappidService,
        private readonly thing: OpmThing) {
    }

    act() {
      const cell = this.init.graph.getCell(this.thing.id);
      if (cell?.unfoldAction)
        cell.unfoldAction(this.init);
    }

}
