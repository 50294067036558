import { Component, Inject, OnInit, Optional } from '@angular/core';
import { MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {Router} from "@angular/router";
import {getInitRappidShared} from "../../configuration/rappidEnviromentFunctionality/shared";


@Component({
  selector: 'opcloud-logs-dashboard-dialog',
  templateUrl: 'logs-dashboard-dialog.html'
})
export class LogsDashboardDialogComponent {// implements OnInit{


  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<LogsDashboardDialogComponent>,private init: InitRappidService, private router: Router) {
  }

  copyModelData(json) {
    navigator.clipboard.writeText(json).then().catch(e => console.error(e));
  }

  loadModelFromData(jsonStr) {
    const that = this;
    const json = JSON.parse(jsonStr);
    this.router.navigate(['']).then(val => {
      that.dialogRef.close();
      setTimeout( () => {
        that.init.opmModel.fromJson(json);
        that.init.treeViewService.init(that.init.opmModel);
        that.init.getGraphService().renderGraph(that.init.opmModel.opds[0]);
        }, 1000 );
    });
  }

  remove() {
    this.dialogRef.close(this.data);
  }

  logError(errorMessage: string) {
    console.log(errorMessage.split('selected element:')[0]);
  }
}
