import {Component, ElementRef, Inject, OnInit, Optional, ViewChild} from "@angular/core";
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from "@angular/material/legacy-dialog";
import {validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {ActivatedRoute} from "@angular/router";
import {StorageService} from "../../rappid-components/services/storage.service";
import {CreatePopupMessage, PopupMessageData, UpdatePopupMessage} from "./popup.message.interface";
import {PopupMessageContentComponent} from "../popup-message-content/popup-message-content.component";
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";

@Component({
  selector: 'Create-Popup-Messages',
  templateUrl: './create-popup-message.component.html',
  styleUrls: ['./create-popup-message.component.css']
})
export class CreatePopupMessagesComponent implements OnInit {

  private title: string = '';
  private mode: 'view' | 'create' | 'update';
  private type: 'system' | 'org';
  private readonly options;
  private messageAppearance;
  private messageContent: string;
  private subject: string;
  private actionText: string;
  private exp_date;
  private hasToConfirm: boolean;
  private messageId: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, @Optional() public dialogRef: MatDialogRef<CreatePopupMessagesComponent>,
              private storage: StorageService, private init: InitRappidService) {
    this.options = ['Only on next login', 'On every login'];
    this.messageAppearance = this.data.message?.messageAppearance || this.options[0];
    this.subject = this.data.message?.subject || '';
    this.messageContent = this.data.message?.content || '';
    this.hasToConfirm = !!this.data.message?.hasToConfirm;
    this.messageId = this.data.message?.id;
    if (this.data.message?.exp_date_as_number)
      this.exp_date = this.data.message?.exp_date_as_number;
  }

  ngOnInit() {
    this.mode = this.data.mode;
    this.type = this.data.type;
    this.setTitleAndActionButtonText();
  }

  setTitleAndActionButtonText() {
    if (this.mode === 'create') {
      this.title = 'Create New Message';
      this.actionText = 'Publish';
    } else if (this.mode === 'view') {
      this.title = 'View Message';
      this.actionText = 'Close';
    } else {
      this.title = 'Update Message';
      this.actionText = 'Update';
    }
  }

  action() {
    if (!this.exp_date || this.subject.length === 0 || this.messageContent.trim().length === 0) {
      validationAlert('One or more of the required fields are empty.');
      return;
    }
    if (this.mode === 'create') {
      const message: CreatePopupMessage = {
        subject: this.subject,
        content: this.messageContent,
        exp_date: this.exp_date,
        hasToConfirm: this.hasToConfirm,
        messageAppearance: this.messageAppearance
      }
      return this.storage.database.driver.createPopupMessage(this.type, message).then(() => this.dialogRef.close(true));
    } else if (this.mode === 'update') {
      const message: UpdatePopupMessage = {
        id: this.messageId,
        subject: this.subject,
        content: this.messageContent,
        exp_date: this.exp_date,
        hasToConfirm: this.hasToConfirm,
        messageAppearance: this.messageAppearance
      }
      return this.storage.database.driver.updatePopupMessage(this.type, message).then(() => this.dialogRef.close(true));
    }
  }

  close() {
    this.dialogRef.close();
  }

  dateChanged($event) {
    this.exp_date = $event.target.valueAsNumber;
  }

  preview() {
    const message: PopupMessageData = {
      id: '',
      subject: this.subject,
      content: this.messageContent,
      hasToConfirm: this.hasToConfirm,
      messageAppearance: this.messageAppearance
    };
    this.init.dialogService.openDialog(PopupMessageContentComponent, null, 700, { mode: 'preview', message, type: this.type, allowMultipleDialogs: true });
  }
}
