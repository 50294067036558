import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'opc-avatar',
  templateUrl: './avatar.component.html',
  styleUrls: ['./avatar.component.scss']
})
export class AvatarComponent implements OnInit {
  @Input() photoUrl;
  @Input() color;

  constructor() { }

  ngOnInit() {
  }

  getAvatarImage() {
    return `url(${this.photoUrl})`;
  }

  error($event: any) {
    this.photoUrl = undefined;
  }
}
