export interface NoteTypeDef {
  value: string;
  icon: string;
  color: string;
}

export class NoteType {
  static noteTypes: NoteType[] = [];

  static getType(type: string | NoteType) {
    const foundType = typeof type === 'string'
      ? NoteType.noteTypes.find(noteType => noteType.value === type)
      : NoteType.noteTypes.find(noteType => noteType.value === type.value);
    if (!type) {
      console.error(`the specified note type ${type} is not defined in the model system.`);
    }
    return foundType;
  }

  get value() {
    return this._type.value;
  }

  get icon() {
    return this._type.icon;
  }

  get color() {
    return this._type.color;
  }

  constructor(private _type: NoteTypeDef) {
    NoteType.noteTypes.push(this);
  }

  static addTypes(typeDefs: NoteTypeDef[]) {
    typeDefs.forEach(typeDef => new NoteType(typeDef));
  }
}
