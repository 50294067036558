import { TextualModule } from "./interfaces";
import { isNumber } from '../../../configuration/rappidEnviromentFunctionality/shared';
import * as ConfigurationOptions from "../../ConfigurationOptions";
import { TimeDurationType } from "../../ConfigurationOptions";

export interface TimeDurationParameters {
  min: number,
  nominal: number,
  max: number,
  units: string
}

export interface TimeDurationDisplayParams {
  digitsAfterDot: number
}

export class TimeDurationModule implements TextualModule {

  private min: number = null;
  private nominal: number = null;
  private max: number = null;
  private units: string = 's';

  private readonly display: TimeDurationDisplayParams = {
    digitsAfterDot: 2
  }

  private timeDurationStatus: ConfigurationOptions.TimeDurationType;

  public setDisplayParams(display: TimeDurationDisplayParams) {
    this.display.digitsAfterDot = display.digitsAfterDot;
  }

  public getText(): string {
    const arr = [this.min, this.nominal, this.max];
    const text = '[' + this.units + ']' + '(' + arr.filter(a => !isNaN(a)).map(a => Number(Number(a).toFixed(this.display.digitsAfterDot))).join(',') + ')';
    return text;
  }

  public isTextActive() {
    return this.isActive();
  }

  public isTimeDuration(): boolean { // true if has timeDuration
    return (this.timeDurationStatus !== TimeDurationType.Unspecified && this.timeDurationStatus !== undefined)
  }

  private isActive(): boolean {
    return this.isTimeDuration();
  }

  private validateDuration(duration: TimeDurationParameters): { success: boolean, messages: Array<string> } {
    let val = true;
    const errorMsgs = [];

    if (duration.max == null && duration.nominal == null && duration.min == null)
      return { success: false, messages: [''] }

    if (duration.min != null && duration.min < 0) {
      errorMsgs.push('Warning! The minimal duration cannot be negative.');
      val = false;
    }

    if (duration.max != null && duration.max < 0) {
      errorMsgs.push('Warning! The maximal duration cannot be negative.');
      val = false;
    }

    if (duration.nominal != null && duration.nominal < 0) {
      errorMsgs.push('Warning! The nominal duration cannot be negative.');
      val = false;
    }

    if ((duration.min != null) && (duration.min.toString() === '') && (duration.nominal != null) &&
      (duration.nominal.toString() === '') && (duration.max !== null) && (duration.max.toString() === '') &&
      (duration.min === null) && (duration.nominal === null) && (duration.max === null)) {
      errorMsgs.push('Warning! at least one of the time fields must be entered.');
      val = false;
    }

    if (val === true) {
      if ((duration.min != null) && (duration.max != null) && (duration.max.toString() !== '') && (duration.min.toString() !== 'null') &&
        (duration.max.toString() !== 'null') && (Number(duration.min.valueOf()) > Number(duration.max.valueOf()))) {
        errorMsgs.push('Warning! The maximal time cannot be smaller than the minimal');
        val = false;
      }
    }

    if (val === true) {
      if ((duration.min !== null) && (duration.nominal !== null) && (duration.nominal.toString() !== '') && (duration.min.toString() !== 'null')
        && (duration.nominal.toString() !== 'null') && (Number(duration.min.valueOf()) > Number(duration.nominal.valueOf()))) {
        errorMsgs.push('Warning! The minimal time cannot be larger than the nominal');
        val = false;
      }
    }

    // if entered nominal > max, display alert and change to null the inserted parameter
    if (val === true) {
      if ((duration.nominal != null) && (duration.max != null) && (duration.max.toString() !== '') && (duration.nominal.toString() !== 'null')
        && (duration.max.toString() !== 'null') && (Number(duration.nominal.valueOf()) > Number(duration.max.valueOf()))) {
        errorMsgs.push('Warning! The maximal time cannot be smaller than the nominal');
        val = false;
      }
    }

    return { success: val, messages: errorMsgs };
  }

  public setTimeDuration(duration: TimeDurationParameters): { success: boolean, messages: Array<string> } {
    const ret = this.validateDuration(duration);
    if (ret.success === false)
      return ret;
    this.min = duration.min;
    this.nominal = duration.nominal;
    this.max = duration.max;
    this.units = duration.units;
    this.timeDurationStatus = ConfigurationOptions.TimeDurationType.Specified;
    return { success: true, messages: [] };
  }

  public getTimeDuration(): { min: number, nominal: number, max: number, units: string, timeDurationStatus: ConfigurationOptions.TimeDurationType } {
    return { min: this.min, nominal: this.nominal, max: this.max, units: this.units, timeDurationStatus: this.timeDurationStatus };
  }

  public editUnits(units: string): void {
    this.units = units;
  }

  public removeTimeDuration() {
    this.min = null,
    this.nominal = null;
    this.max = null;
    this.units = 's';
    this.timeDurationStatus = ConfigurationOptions.TimeDurationType.Unspecified;
    return { success: true };
  }

  public getPriority() {
    return 3;
  }

}
