import {Component, Inject, OnInit, Optional} from '@angular/core';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {ScreenType, StorageMode, TemplateType} from "../load-model-dialog/load-model-dialog.component";
import {UserService} from "../../rappid-components/services/user.service";

@Component({
  selector: 'opcloud-templates-import',
  templateUrl: 'templates-import.html',
  styleUrls: ['templates-import.css']
})

export class TemplatesComponent implements OnInit{

  public privateTemplatesSettings;
  public orgTemplatesSettings;
  public globalTemplatesSettings;
  private isGlobalEnabled: boolean = false;
  private isOrganizationalEnabled: boolean = false;
  private title = '';

  constructor(private userService: UserService, @Optional() public dialogRef: MatDialogRef<any>, @Inject(MAT_DIALOG_DATA) public data: any) {
  }

  ngOnInit() {
    this.privateTemplatesSettings = this.getSettings(TemplateType.PERSONAL);
    this.orgTemplatesSettings = this.getSettings(TemplateType.ORG);
    this.globalTemplatesSettings = this.getSettings(TemplateType.SYS);
    this.setTitle();
    this.setIsOrganizationalEnabled();
    this.setIsGlobalEnabled();
  }

  setTitle() {
    switch (this.data.mode) {
      case 'save':
        this.title = 'Save Template';
        break;
      case 'edit':
        this.title = 'Edit Template';
        break;
      case 'import':
        this.title = 'Insert Template';
        break;
    }
  }

  setIsOrganizationalEnabled() {
    if (this.data.mode === 'import' || this.userService.isOrgAdmin() || this.userService.isSysAdmin())
      this.isOrganizationalEnabled = true;
  }

  setIsGlobalEnabled() {
    if (this.data.mode === 'import' || this.userService.isSysAdmin())
      this.isGlobalEnabled = true;
  }

  getSettings(type: TemplateType) {
    const mode = this.data.mode === 'save' ? StorageMode.SAVE : StorageMode.LOAD;
    const importMode = this.data.mode === 'import';
    return {
      height: Math.round(window.innerHeight * 0.9) + 'px',
      width: Math.round(window.innerWidth * 0.75) + 'px',
      data: {
        path: '', showVersions: false, mode: mode,
        name: '', description: '', showArchivedModels: false,
        archiveMode: false,
        screenType: ScreenType.TEMPLATES,
        templateType: type,
        isImportMode: importMode
      },
    }
  }

}
