import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { UserService } from '../../../../rappid-components/services/user.service';
import { Router } from '@angular/router';
import { validationAlert } from '../../../..//configuration/rappidEnviromentFunctionality/shared';
import { OrganizationService } from "../../../../rappid-components/services/organization.service";
import { GroupsService } from '../../../../rappid-components/services/groups.service';


@Component({
  selector: 'new-user',
  templateUrl: 'new-org.component.html',
  styleUrls: ['./new-org.component.scss'],
})
export class SettingsNewOrg implements OnInit {
  org = {
    Name: '',
  };
  ErrorMsg: string = null;


  constructor(
    private router: Router,
    private userService: UserService,
    private orgService: OrganizationService,
    private groupService: GroupsService
  ) { }

  ngOnInit() {
  }

  CreateOrg() {
    return this.orgService.createOrganization(this.org)
      .then(() => {
        validationAlert("New Organization Created", null, 'Success');
        this.org = {
          Name: "",
        };
      })
      .catch(err => validationAlert("Some error occurred", null, 'Error'));
  }


  isOrgExists(name): boolean {
    //TODO: implement this function in orgService
    return false;
  }

  isAdminEmailExists(mail): boolean {
    //TODO: implement this function in orgService
    return true;
  }

}
