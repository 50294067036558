// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `p {
    text-align: center;
    font-size: medium;
}

ul {
    list-style-type: none;
    float: left;
    padding: 5px;

}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/add-users-excel/add-users.component.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,qBAAqB;IACrB,WAAW;IACX,YAAY;;AAEhB","sourcesContent":["p {\n    text-align: center;\n    font-size: medium;\n}\n\nul {\n    list-style-type: none;\n    float: left;\n    padding: 5px;\n\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
