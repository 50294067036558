import {InitRappidService} from "../../rappid-components/services/init-rappid.service";
import {ContextService} from "./context.service";
import {validationAlert} from "../../configuration/rappidEnviromentFunctionality/shared";
import {ConfirmDialogDialogComponent} from "../../dialogs/confirm-dialog/confirm-dialog";


export class TabsManager {

  private init: InitRappidService;
  private context: ContextService;

  constructor(initRappid: InitRappidService, contextService: ContextService) {
    this.init = initRappid;
    this.context = contextService;
  }

  dropTab(event) {
    const steps = Math.sign(event.distance.x) * Math.floor((Math.abs(event.distance.x) / 200) + 0.5) ;
    const originalIndex = event.previousIndex;
    let toIndex = originalIndex + steps;

    if (toIndex < 0)
      toIndex = 0;
    else if (toIndex > this.context.getTabs().length - 1)
      toIndex = this.context.getTabs().length - 1;

    this.context.moveTab(event.previousIndex, toIndex);
    $('mat-tooltip-component').remove();
  }

  async replaceContextByTab(item: { context, modelData, undo, redo, lastOperations? }, keepSubModels = true) {

    if (this.isModelRunning()) {
      validationAlert('Cannot change tab while running a model.', 3500, 'Error');
      return;
    } else if (this.context.isCurrentlySavingModel) {
      validationAlert('Cannot change tab while saving a model.', 3500, 'Error');
      return;
    }

    if (item.context.isDSMContext())
      return;

    const currentContext = this.context.getTabs().find(item => item.context === this.context.getCurrentContext());
    if (currentContext && !currentContext.context.isDSMContext()) {
      this.context.updateTabData(currentContext, keepSubModels);
    } else if (currentContext && currentContext.context.isDSMContext()) {
      const canClose = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 210, 350, {
        message: 'Pay attention, leaving this analysis tab means the analysis view would be generated from the beginning next time. Are you sure you want to leave?',
        okName: 'Leave', okColor: '#ff0000', centerText: true, closeName: 'Stay'
      }).afterClosed().toPromise();
      if (!canClose)
        return;
      this.init.isDSMClusteredView = { value: false, type: undefined };
      const idx = this.context.getTabs().indexOf(currentContext);
      if (idx >= 0)
        this.context.removeTab(idx);
    }

    this.init.setSelectedElementToNull();
    this.init.clearClipboard();
    this.init.isLoadingModel = true;
    setTimeout(() => {
      this.context.replaceContextByTab(item);
      this.context.getModelService().model.setUndoStack(item.undo);
      this.context.getModelService().model.setRedoStack(item.redo);
      this.context.getModelService().model.lastOperations = item.lastOperations || [];
      this.init.elementToolbarReference.setIsExample(this.context.getCurrentContext().isExample());
      this.init.elementToolbarReference.setIsTemplate(this.context.getCurrentContext().isTemplate());
      this.init.elementToolbarReference.setIsStereotype(this.context.getCurrentContext().isStereotype());
      // let opdToRender = this.init.getOpmModel().getOpd(item.modelData.currentOpd.id);
      // if (!opdToRender)
      //   opdToRender = this.init.getOpmModel().opds.find(opd => opd.id === 'SD');
      // this.init.getGraphService().renderGraph(opdToRender, this.init);
      this.init.isLoadingModel = false;
    }, 50);
  }

  async closeTab(item: { context, modelData }) {
    if (this.isModelRunning()) {
      validationAlert('Cannot close tab while running a model.', 3500, 'Error');
      return;
    }

    if (item.context.isDSMContext()) {
      const canClose = await this.init.dialogService.openDialog(ConfirmDialogDialogComponent, 210, 350, {
        message: 'Pay attention, leaving this analysis tab means the analysis view would be generated from the beginning next time. Are you sure you want to leave?',
        okName: 'Leave', okColor: '#ff0000', centerText: true, closeName: 'Stay'
      }).afterClosed().toPromise();
      if (!canClose)
        return;
      this.init.isDSMClusteredView = {value: false, type: undefined};
    }

    this.init.setSelectedElementToNull();
    this.init.clearClipboard();
    this.context.closeTab(item);
    this.init.treeViewService.init(this.init.getOpmModel());
    this.init.getGraphService().renderGraph(this.init.getOpmModel().currentOpd, this.init);
  }

  isModelRunning(): boolean {
    return this.init.Executing;
  }

  refreshTab() {
    this.replaceContextByTab(this.context.getCurrentTabItem());
  }
}
