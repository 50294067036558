import { Component, OnInit, Optional, ViewEncapsulation } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { GraphDBService } from '../../rappid-components/services/GraphDB/graphDB.service';
import { _catch } from 'rxjs/operator/catch';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { Position } from '../../ImportOPX/OPX.API';

@Component({
  selector: 'opmQuery-dialog',
  templateUrl: 'opmQuery-dialog.html',
  styleUrls: ['opmQuery-dialog.css'],
  encapsulation: ViewEncapsulation.None
})

export class opmQueryDialogComponent implements OnInit {
  type: string;
  // results_only = true;

  // selectedSource: any;
  // selectedTarget: any;
  // selectedQuery: any;

  cypherQuery: string = null;
  copyMessage: string = null;
  errorMessage: string = null;
  thingList: any;
  queryList: any;
  graphDBmsg: string;
  myConf: any;
  inputs: any;
  DEBUG = false;

  constructor(
    public dialogRef: MatDialogRef<opmQueryDialogComponent>,
    private graphDBService: GraphDBService,
    private _dialog: MatDialog,
    private _dialogRef: MatDialogRef<any>,
    private initRappidService: InitRappidService,
  ) {
    /* todo search pattern */
    // tslint:disable-next-line: max-line-length
    this.thingList = this.initRappidService.opmModel.logicalElements.filter((thing) => ((thing.name === 'OpmLogicalObject') || (thing.name === 'OpmLogicalProcess') || (thing.name === 'OpmLogicalState'))).sort((e1, e2) => this.sortFunc(e1, e2));
    this.inputs = [];
  }

  private test() {
    console.log('inputs:', this.inputs);
    console.log('source2:', this.myConf['Shortest Path']['Source2']);
  }
  ngOnInit() {
    this.graphDBService.streamingGraphDBmsg.subscribe(msg => { this.graphDBmsg = msg; });
    this.myConf = this.getConf(this.type);
  }

  private getConf(type): any {
    const conf = {
      'Path Finding': {
        'Shortest Path': {
          'info': 'The query will identify the shortest path between two OPM things',
          'options': {
            'Source': this.thingList,
            'Target': this.thingList,
            'Flat Model Visibility': ['Hide', 'Show'],
          }
        },
        'Longest Path': {
          'info': 'The query will identify the longest path between two OPM things',
          'options': {
            'Source': this.thingList,
            'Target': this.thingList,
            'Flat Model Visibility': ['Hide', 'Show'],
          }
        }
      },
      'Neighborhood': {
        'Unidirectional': {
          'info': 'The Query will idetify all the elements with max depth from and to root node',
          'options': {
            'Root': this.thingList,
            'Max Depth': this.getRangeArray(1, 5),
            'Flat Model Visibility': ['Hide'],
            'Only Procedural': [true, false]
          }
        },
        'Directional': {
          'info': 'The Query will idetify all the elements with max depth from or to root node',
          'options': {
            'Root': this.thingList,
            'Max Depth': this.getRangeArray(1, 5),
            'Direction': ['INCOMING', 'OUTGOING'],
            'Flat Model Visibility': ['Hide'],
            'Only Procedural': [true, false]
          }
        }

      },
      'Centrality': {
        'PageRank': {
          'info': 'The query measures the influence of nodes',
          'options': {
            'Flat Model Visibility': ['Hide', 'Show'],
            'Link Type': ['Any', 'Procedural', 'Fundamental'],
            'Thing Type': ['Any', 'Process', 'Object'],
            'Number of Items': this.getRangeArray(1, 5)
          }
        }

      }

    };
    return conf[type];
  }

  // private getDisplayOptions(): any{
  //   return ['Results Only', 'Results with grayed out elements'];
  // }

  private getRangeArray(min: number, max: number): number[] {
    const arr: number[] = [];
    for (let i = min ? min : 0; i < max + 1; i++) {
      arr.push(i);
    }
    return arr;
  }

  private sortFunc(e1, e2): number {
    if (e1.name === e2.name) {
      return e1.text < e2.text ? -1 : 1;
    }
    return (e1.name === 'OpmLogicalObject') ? -1 : 1;
  }

  closeDialog() {
    this._dialog.closeAll();
  }

  RunQuery() {
    this.errorMessage = '';
    // this.cypherQuery = '';
    if (this.isInputOK()) {
      let q_input = this.parseInput();
      this.graphDBService.runQuery(q_input).then((res) => {
        this.closeDialog();
      }).catch((err) => {
        this.errorMessage = 'Error! ' + err;
      });
    }
  }

  private parseInput() {
    const parsed = {};
    for (const key of Object.keys(this.inputs)) {
      const kArr = key.toLowerCase().split(/\s+/);
      for (let j = 1; j < kArr.length; j++) { kArr[j] = kArr[j].charAt(0).toUpperCase() + kArr[j].slice(1); }
      const k = kArr.join('');

      parsed[k] = this.inputs[key];
    }
    parsed['type'] = this.type;
    if (parsed['root']) {
      parsed['source'] = parsed['root'];
      delete parsed['root']
    }
    // Setup Source/Traget type
    if (parsed['source']) {
      parsed['source'].type = parsed['source'].name.replace(/OpmLogical/g, '').toLowerCase();
    }
    if (parsed['target']) {
      parsed['target'].type = parsed['target'].name.replace(/OpmLogical/g, '').toLowerCase();
    }
    // if (parsed['root']) {
    //   parsed['root'].type = parsed['root'].name.replace(/OpmLogical/g, '').toLowerCase();
    // }
    // setup bollean for display results
    if (parsed['flatModelVisibility']) {
      if (parsed['flatModelVisibility'] === 'Hide') {
        parsed['resultOnly'] = true;
      } else {
        parsed['resultOnly'] = false;
      }
      delete parsed['flatModelVisibility']
    }
    this.debug(['parsed:', parsed, 'inputs:', this.inputs]);
    return parsed;
  }

  private debug(arr) {
    if (this.DEBUG) {
      console.log(arr);
    }
  }

  private isInputOK(): boolean {
    //Check if all parameters has input
    for (const key of Object.keys(this.myConf[this.inputs['Query']].options)) {
      // console.log('key',key);
      if ((typeof this.inputs[key] === 'undefined') || (this.inputs[key] === null)) {
        this.errorMessage = `${key} should be specified`;
        return false;
      }
    }
    switch (this.type) {
      case 'Path Finding': {
        if (this.inputs['Source']._text === this.inputs['Target']._text) {
          this.errorMessage = 'Source and Target can\'t be the same element';
          return false;
        }
        return true;
      }
      default: {
        return true;
      }
    }
  }

  copyToClipboard(item) {
    document.addEventListener('copy', (e: ClipboardEvent) => {
      e.clipboardData.setData('text/plain', (item));
      e.preventDefault();
      document.removeEventListener('copy', null);
    });
    document.execCommand('copy');
    this.copyMessage = 'Query Copied to clipboard...';
  }






}
