import {OpmStereotype} from './OpmStereotype';


export class StereotypeManager {
  private stereotypes = new Array<OpmStereotype>();

  constructor() {
  }

  addStereotype(stereo: OpmStereotype): OpmStereotype {
    if (!this.stereotypes.find(stro => stro.id === stereo.id)) {
      this.stereotypes.push(stereo);
      return stereo;
    }
    return this.stereotypes.find(stro => stro.id === stereo.id);
  }

  removeStereotype(stereo: OpmStereotype): boolean {
    if (!this.stereotypes.includes(stereo))
     return false;
    this.stereotypes.splice(this.stereotypes.indexOf(stereo), 1);
    return true;
  }

  getStereoTypes() {
    return this.stereotypes;
  }

  getStereotypeById(sterotypeId: string) {
    return this.stereotypes.find(s => s.id === sterotypeId);
  }
  
}
