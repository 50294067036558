import { OpmVisualEntity } from '../VisualPart/OpmVisualEntity';
import { linkType } from '../ConfigurationOptions';
import { EntityType } from '../model/entities.enum';
import {InitRappidService} from "../../rappid-components/services/init-rappid.service";

export enum RuleType {
  STRUCTURAL,
  BEHAVIOURAL,
  CONSISTIONAL
}

export interface Rule {
  type(): RuleType;
  shouldBeApplied(source: EntityType, target: EntityType, link: linkType): boolean;
  canConnect(source: OpmVisualEntity, target: OpmVisualEntity, link: linkType): boolean;
  message(): string;
  description(): string;
  changeAction?(source: OpmVisualEntity, target: OpmVisualEntity, init: InitRappidService);
}
