import { OpmLogicalElement } from '../LogicalPart/OpmLogicalElement';
import { util } from "jointjs";
import uuid = util.uuid;
import {ElementsMap} from '../components/ElementsMap';

export abstract class OpmVisualElement {

  logicalElement: OpmLogicalElement<OpmVisualElement>;
  textColor: string;
  textFontSize: number;
  textFontFamily: string;
  strokeWidth: number;
  strokeColor: string;
  textFontWeight: number;
  id: string;
  belongsToSubModel: string; // TODO: take care of saving.
  belongsToFatherModelId: string;
  protectedFromBeingChangedBySubModel: string;

  constructor(params, logicalElement) {
    this.logicalElement = logicalElement;
    this.setNewUUID();
    if (logicalElement)
      logicalElement.add(this, false);
    if (params) this.updateParams(params);
  }

  isLink() {
    return false;
  }

  pointToFather(opmLogicalElement) {
    this.logicalElement = opmLogicalElement;
  }

  getElementParams() {
    return {
      textFontWeight: this.textFontWeight,
      textFontSize: this.textFontSize,
      textFontFamily: this.textFontFamily,
      textColor: this.textColor,
      strokeWidth: this.strokeWidth,
      strokeColor: this.strokeColor,
      id: this.id,
      belongsToSubModel: this.belongsToSubModel,
      protectedFromBeingChangedBySubModel: this.protectedFromBeingChangedBySubModel,
      belongsToFatherModelId: this.belongsToFatherModelId,
    };
  }

  updateParams(params) {
    if (params.textColor !== 'transparent')
      this.textColor = params.textColor;
    this.textFontSize = params.textFontSize;
    this.textFontFamily = params.textFontFamily;
    this.textFontWeight = params.textFontWeight;
    this.strokeColor = params.strokeColor;
    this.strokeWidth = params.strokeWidth;
    this.id = params.id;
    if (params.hasOwnProperty('belongsToSubModel'))
      this.belongsToSubModel = params.belongsToSubModel;
    if (params.hasOwnProperty('protectedFromBeingChangedBySubModel'))
      this.protectedFromBeingChangedBySubModel = params.protectedFromBeingChangedBySubModel;
    if (params.hasOwnProperty('belongsToFatherModelId'))
      this.belongsToFatherModelId = params.belongsToFatherModelId;
  }

  setParams(params) {
    if (params.textColor !== 'transparent')
      this.textColor = params.textColor;
    this.textFontSize = params.textFontSize;
    this.textFontFamily = params.textFontFamily;
    this.textFontWeight = params.textFontWeight;
    this.strokeColor = params.strokeColor;
    this.strokeWidth = params.strokeWidth;
    this.id = params.id;
    if (params.hasOwnProperty('belongsToSubModel'))
      this.belongsToSubModel = params.belongsToSubModel;
    if (params.hasOwnProperty('protectedFromBeingChangedBySubModel'))
      this.protectedFromBeingChangedBySubModel = params.protectedFromBeingChangedBySubModel;
    if (params.hasOwnProperty('belongsToFatherModelId'))
      this.belongsToFatherModelId = params.belongsToFatherModelId;
  }

  getElementParamsFromJsonElement(jsonElement) {
    return {
      textColor: jsonElement.textColor,
      textFontSize: jsonElement.textFontSize,
      textFontFamily: jsonElement.textFontFamily,
      textFontWeight: jsonElement.textFontWeight,
      strokeColor: jsonElement.strokeColor,
      strokeWidth: jsonElement.strokeWidth,
      id: jsonElement.id,
      belongsToSubModel: jsonElement.belongsToSubModel,
      belongsToFatherModelId: jsonElement.belongsToFatherModelId,
      protectedFromBeingChangedBySubModel: jsonElement.protectedFromBeingChangedBySubModel
    };
  }

  // updateComplexityReferences() { }

  setNewUUID() {
    this.id = uuid();
  }

  clone() { return null; }

  isInzoomed() {
    return false;
  }

  isUnfolded() {
    return false;
  }

  isComputational() {
    return false;
  }

  deriveProperties() {
    // console.log('derive Properties for ' + this.id);
    // A method to do special processing during unfolding of the element to derive properties from in zoomed version.
  }

  abstract canBeRemoved(): boolean;

  setReferencesFromJson(json: any, map: ElementsMap<OpmVisualElement>): void {
  }

  setReferencesOnCreate(): void {
  }

  public remove(): ReadonlyArray<OpmVisualElement> {
    return remove(this, this.logicalElement);
  }

}

function remove(visual: OpmVisualElement, logical: OpmLogicalElement<OpmVisualElement>): ReadonlyArray<OpmVisualElement> {
  const elements = new Array<OpmVisualElement>();
  elements.push(visual);

  const ret = logical.removeVisual(visual) as any;
  if (ret && ret.removed && ret.removed.length > 0)
    elements.push(...ret.removed.filter(v => !elements.includes(v)));
  logical.opmModel.removeFromOpd(visual);

  return elements;
}
