import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import { menuGroups } from './menuGroups';
import { UserService} from "../../rappid-components/services/user.service";
import {GroupsService} from '../../rappid-components/services/groups.service';
import {getInitRappidShared} from '../../configuration/rappidEnviromentFunctionality/shared';

@Component({
  selector: 'opc-settins',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  menuGroups = menuGroups;
  toggle_list = {};
  user$;
  isAnon;
  publicManuUnauthorized = ['Group Management', 'Organization Management'];

  constructor(
    private groupService: GroupsService,
    private router: Router,
    private _dialog: MatDialog,
    private userService: UserService,
  ){
    if (this.userService.isPublicOrganization) this.updateMenu();
    this.resetToggle();
    this.user$ = this.userService.user$;
    // this.isAnon = environment.Anonymous.Enabled? environment.Anonymous.Enabled : false;
  }
  updateMenu() {
    const finalManu = [];
    this.menuGroups.forEach(title => {
      if (!this.publicManuUnauthorized.includes(title.group)) {
        finalManu.push(title);
      }
    });
    this.menuGroups = finalManu;
  }

  ngOnInit() {
  }

  toggle(group) {
    this.toggle_list[group] = this.toggle_list[group] ? false : true;
  }

  resetToggle(){
    this.menuGroups.forEach(menuGroup => {this.toggle_list[menuGroup.group] = false})
  }


  backToMain() {
    this.groupService.updateOrgGroups(this.userService.userOrg);
    this.groupService.updateOrgUsers(this.userService.userOrg);
    this.router.navigate(['']).then(val => {
      setTimeout( () => {
        getInitRappidShared().recoverOpenTreeNodes();
        getInitRappidShared().setLeftBarWindowsSizes({});
        }, 1000 );
    });
  }


}
