// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header-spinner {
  height: 50px;
}

.settings_button {
  position: relative;
  top: 6px;
  border: none;
  background: transparent;
  margin-top: 25px;
}

.user-info {
  position: relative;
  top: -15px;
  height: 66px;
}
.user-info .signed-in-user-container {
  position: relative;
  top: -13px;
  height: 66px;
  margin-left: 2px;
  padding-top: 0px;
}
.user-info .signed-in-user-container .usernamelink {
  padding: 0 10px 0 0;
  text-transform: none;
  white-space: nowrap;
  top: -4px;
  background-color: none;
  color: white;
  box-shadow: none;
  position: relative;
}
.user-info .signed-in-user-container .usernamelink .username {
  display: inline-block;
  line-height: 30px;
  vertical-align: top;
  width: calc(100% - 40px);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  margin-top: -6px;
}
.user-info .orgnmae {
  margin-top: -10px;
}`, "",{"version":3,"sources":["webpack://./src/app/modules/layout/header/user-status/user-status.component.scss"],"names":[],"mappings":"AAEA;EACE,YAAA;AADF;;AAIA;EACE,kBAAA;EACA,QAAA;EACA,YAAA;EACA,uBAAA;EACA,gBAAA;AADF;;AAIA;EACE,kBAAA;EACA,UAAA;EACA,YAAA;AADF;AAGE;EACE,kBAAA;EACA,UAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AADJ;AAGI;EACE,mBAAA;EACA,oBAAA;EACA,mBAAA;EACA,SAAA;EACA,sBAAA;EACA,YAAA;EACA,gBAAA;EACA,kBAAA;AADN;AAGM;EACE,qBAAA;EACA,iBAAA;EACA,mBAAA;EACA,wBAAA;EACA,mBAAA;EACA,gBAAA;EACA,uBAAA;EACA,gBAAA;EACA,gBAAA;AADR;AAME;EACE,iBAAA;AAJJ","sourcesContent":["$button-content: #aff8e6;\n$button-border: #1de9b6;\n.header-spinner {\n  height: 50px;\n}\n\n.settings_button {\n  position: relative;\n  top: 6px;\n  border: none;\n  background: transparent;\n  margin-top: 25px;\n}\n\n.user-info {\n  position: relative;\n  top: -15px;\n  height: 66px;\n\n  .signed-in-user-container {\n    position: relative;\n    top: -13px;\n    height: 66px;\n    margin-left: 2px;\n    padding-top: 0px;\n\n    .usernamelink {\n      padding: 0 10px 0 0;\n      text-transform: none;\n      white-space: nowrap;\n      top: -4px;\n      background-color: none;\n      color: white;\n      box-shadow: none;\n      position: relative;\n\n      .username {\n        display: inline-block;\n        line-height: 30px;\n        vertical-align: top;\n        width: calc(100% - 40px);\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        text-align: left;\n        margin-top: -6px;\n      }\n    }\n  }\n  \n  .orgnmae {\n    margin-top: -10px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
