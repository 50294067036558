export class FuncDictionary {
  funcArray: any;
  /*
   funcName - the name of the function as the user see it on the selection box
   func - the actual function as it used for computations
   TypeElements - the type of the function. multiple: more then one argument,
                                            single: only one argument
                                            operator: one or more aruments,
                                            average: hardcoded implementation
  */
  constructor() {
    this.funcArray = [
      {funcName: 'Adding', func: '+', TypeElements: 'Operator',
        tooltip: 'Return the addition of 2 numbers or more', resultUnit: retFirstUnit},
      {funcName: 'Subtracting', func: '-', TypeElements: 'Operator',
        tooltip: 'Return the subtraction of 2 numbers or more', resultUnit: retFirstUnit},
      {funcName: 'Multiplying', func: '*', TypeElements: 'Operator',
        tooltip: 'Return the multiplication of 2 numbers or more', resultUnit: retMulUnit},
      {funcName: 'Dividing', func: '/', TypeElements: 'Operator',
        tooltip: 'Return the division of 2 numbers or more', resultUnit: retDivUnit},
      {funcName: 'Min', func: Math.min, TypeElements: 'Multiple', tooltip: 'Return the minimum number', resultUnit: retFirstUnit},
      {funcName: 'Max', func: Math.max, TypeElements: 'Multiple', tooltip: 'Return the maximum number', resultUnit: retFirstUnit},
      {funcName: 'Sin', func: Math.sin, TypeElements: 'Single', tooltip: 'Return the sine of a number', resultUnit: retNoUnit},
      {funcName: 'Cos', func: Math.cos, TypeElements: 'Single', tooltip: 'Return the cosine of a number', resultUnit: retNoUnit},
      {funcName: 'Abs', func: Math.abs, TypeElements: 'Single', tooltip: 'Return the absolute value of a number', resultUnit: retFirstUnit},
      {funcName: 'Tan',   func: Math.tan, TypeElements: 'Single', tooltip: 'Return the tangens of a number', resultUnit: retNoUnit},
      {funcName: 'Concat', func: '+', TypeElements: 'String', tooltip: 'Return the concatenation of strings', resultUnit: retNoUnit},
      {funcName: 'Log', func: Math.log, TypeElements: 'Single', tooltip: 'Return the logarithm of a number', resultUnit: retFirstUnit},
      {funcName: 'Exponent', func: Math.exp, TypeElements: 'Single', tooltip: 'Return the exponent of a number', resultUnit: retFirstUnit},
      {funcName: 'Power', func: Math.pow, TypeElements: 'Multiple',
        tooltip: 'Return the power of the numbers by each other', resultUnit: retFirstUnit},
      {funcName: 'Sqrt', func: Math.sqrt, TypeElements: 'Single', tooltip: 'Return the square root of a number', resultUnit: retFirstUnit},
      {funcName: 'Round', func: Math.round, TypeElements: 'Single', tooltip: 'Return the a rounded number', resultUnit: retFirstUnit},
      {funcName: 'Average', func: '', TypeElements: 'Average', tooltip: 'Return the average of 2 numbers or more', resultUnit: retFirstUnit}
    ];
  }
}

export function retFirstUnit(unit1, unit2 = '') {
  return unit1;
}

export function retMulUnit(unit1, unit2) {
  const all_but_last1 = unit1.substring(0, unit1.length - 2);
  const all_but_last2 = unit2.substring(0, unit2.length - 2);
  if (all_but_last1 === all_but_last2) {
    const last_char1 = unit1[unit1.length - 1];
    const last_char2 = unit2[unit2.length - 1];
    if (last_char1 === '2' && !isDigit(last_char2)) {
      return unit2 + '3';
    }
    if (last_char2 === '2' && !isDigit(last_char1)) {
      return unit1 + '3';
    }
    if (unit1 === unit2 && !isDigit(last_char1) && !isDigit(last_char2)) {
      return unit1 + '2';
    }
  }
  return unit1 + '*' + unit2;
}

export function retDivUnit(unit1, unit2) {
  if (unit1 === unit2) {
    return '';
  }
  const all_but_last1 = unit1.substring(0, unit1.length - 2);
  const all_but_last2 = unit2.substring(0, unit2.length - 2);
  if (all_but_last1 === all_but_last2) {
    const last_char1 = unit1[unit1.length - 1];
    const last_char2 = unit2[unit2.length - 1];
    if (last_char1 === '2' && !isDigit(last_char2)) {
      return unit2;
    }
    if (last_char2 === '2' && !isDigit(last_char1)) {
      return unit1;
    }
    if (last_char1 === '3' && !isDigit(last_char2)) {
      return unit2 + '2';
    }
    if (last_char2 === '3' && !isDigit(last_char1)) {
      return unit1 + '2';
    }
    if (last_char1 === '3' && last_char2 === '2') {
      return all_but_last1;
    }
    if (last_char2 === '3' && last_char1 === '2') {
      return all_but_last1;
    }
  }
  return unit1 + '/' + unit2;
}

export function retNoUnit(unit1 = '', unit2 = '') {
  return '';
}

function isDigit(chr) {
  return (chr >= '1' && chr <= '9');
}
