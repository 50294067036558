import {OPCloudUtils, validationAlert} from '../rappidEnviromentFunctionality/shared';
import {linkType} from "../../models/ConfigurationOptions";

/**
 * An interface that represents an item in valuesArray, that is an array of objects that are connected to a
 * computational process (by consists of/ aggregation and instrument links). Each item includes at least a name and also
 * maybe one of the following: element id, value,unit and alias.
 **/
export interface ObjectItem {
  elementId?: string ;
  sourceElementValue?: string | number;
  sourceElementUnit?: any;
  name: string;
  alias?: string;
  concatedId?: string;
}

export function hasWhiteSpaces(text) {
  if (text.indexOf(String.fromCharCode(160)) > 0) return true;
  if (text.indexOf(' ') > 0) return true;
  if (text.indexOf('-') > 0) return true;
  if (text.indexOf('\n') > 0) return true;
  return false;
}

export function getAllParents(process) {
  const linksFromParents = process.getLinks().inGoing.filter(
    l => OPCloudUtils.isInstanceOfVisualProcess(l.source) && [linkType.Aggregation, linkType.Exhibition].includes(l.type));
  const ret = linksFromParents.map(l => l.source);
  const father = process.fatherObject;
  if (father && !ret.includes(father))
    ret.push(father);
  return ret;
}

export function handleExeExceptions(exception, process = null, functionDecleration = null) {
  let errorText = '';
  if (process && (exception.search('no initial value') > 0)) {
    errorText = 'Process ' + process.logicalElement.text +
      ' is a computational process with ' + process.logicalElement.insertedFunction +
      ' function but has no input values';
  } else if (functionDecleration && (exception.search('not defined') > 0)) {
    exception = exception.replace('ReferenceError: ', '');
    errorText = 'Function syntax is \n' + functionDecleration + '\nbut ' + exception;
  }  else if (exception.search('SyntaxError: ') >= 0) {
    errorText = exception.replace('SyntaxError: ', '');
  } else if (exception.search('TypeError: ') >= 0) {
    errorText = exception.replace('TypeError: ', '');
  } else if (exception.search('object Object') >= 0) { // this error rises when using external function
    errorText = 'Problem in execution.';
  } else if (exception.search('cannot be used.') >= 0 ||
    exception.indexOf('Its identifier contains illegal characters (like space)!') >= 0 ||
    exception.search('has been declared more than once') >= 0 ||
    exception.search('only handles one element!') >= 0 ||
    exception.search('can have only one connected computational object') >= 0 ||
    exception.search('must have one connected computational object')) {
    errorText = exception;
  } else {
    // errorText = exception;
    errorText = 'Problem in execution.';
  }
  // sweetalert('Problem in execution', errorText, 'warning');
  validationAlert(errorText, null, 'Error');
}

export function textToName(text) {
  const unitIndex = text.indexOf('[');
  let name: string;
  // if (unitIndex === -1) return text.trim();
  if (unitIndex === -1) {
    const aliasIndex = text.indexOf('{'); // only alias, not a computational object
    if (aliasIndex === -1) {
      return text.trim();
    } else {
      name = text.substring(0, aliasIndex);
    }
  } else {
     name = text.substring(0, unitIndex);
  }
  return name.trim();
}

/* returns true if there is an id different than conacatedId that concatedId is its prefix and false otherways.*/
export function elmIsSubObject(concatedId: string, variables_array_as_ids: string[]): boolean {
  for (let i = 0 ; i < variables_array_as_ids.length ; i++){
    if(variables_array_as_ids[i].startsWith(concatedId) && variables_array_as_ids[i] !== concatedId){
      return true;
    }
  }
  return false;
}
