import { OpmEntity } from './OpmEntity';
import { _, popupGenerator, validationAlert, getInitRappidShared, stylePopup, convert, getStyles, joint } from '../../configuration/rappidEnviromentFunctionality/shared';
import { OpmLogicalState } from '../LogicalPart/OpmLogicalState';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { OpmVisualState } from '../VisualPart/OpmVisualState';
import { OpmEllipsis } from "./EllipsisState";
import { statesArrangement, valueType } from '../ConfigurationOptions';
import { Arc } from './Links/OrXorArcs';
import { openTimeDurationPopup } from './TimeDurationPopup';
import { TimeDurationModule } from '../LogicalPart/components/TimeDurationModule';
import { ElementHaloHandle, ElementToolbarHandle } from '../components/commands/command';
import { OpmLogicalObject } from '../LogicalPart/OpmLogicalObject';
import {defaultStateStyleSettings} from "../Defaults/style";

export class OpmState extends OpmEntity {

  constructor(stateName = 'State') {
    super();
    this.set(this.stateAttributes());
    if (typeof stateName === 'string' || typeof stateName === 'number')
      this.attr(this.stateAttrs(String(stateName)));
    else
      this.attr(this.stateAttrs((<any>stateName['attrs']).text.textWrap.text));
  }

  // createPorts(visual) {
  //   // const portsInUse = (<any>this.getVisual()).getPortsInUse();
  //   // for (const index of Array.from({length: 16}, (x, i) => i)) {
  //   //   const refData = this.convertOldPortToRelativePosition(index);
  //   //   this.addPort({ id: index, group: 'aaa', args: { x: refData.refX*100+'%', y: refData.refY*100+'%' }, markup: this.defaultPortMarkup });
  //   // }
  // }

  convertOldPortToRelativePosition(portId): { id: number, refX: number, refY: number } {
    const covnvertionTable = [
      { id: 0, refX: 0.1, refY: 0 }, { id: 1, refX: 0.3, refY: 0 }, { id: 2, refX: 0.5, refY: 0 },
      { id: 3, refX: 0.7, refY: 0 }, { id: 4, refX: 0.9, refY: 0 }, { id: 5, refX: 1, refY: 0.167 },
      { id: 6, refX: 1, refY: 0.5 }, { id: 7, refX: 1, refY: 0.83 }, { id: 8, refX: 0.1, refY: 1 },
      { id: 9, refX: 0.3, refY: 1 }, { id: 10, refX: 0.5, refY: 1 }, { id: 11, refX: 0.7, refY: 1 },
      { id: 12, refX: 0.9, refY: 1 }, { id: 13, refX: 0, refY: 0.167 }, { id: 14, refX: 0, refY: 0.5 },
      { id: 15, refX: 0, refY: 0.83 }
    ];
    return covnvertionTable.find(item => Number(portId) === item.id);
  }

  getPortGroups() {
    return {
      'aaa': {
        markup: this.defaultPortMarkup,
        position: 'absolute'
      }
    }
    // return {
    //   'top': this.createPortGroup('top', null, 0, -2, 5, 5),
    //   'bottom': this.createPortGroup('bottom', null, 0, -2, 5, 5),
    //   'left': this.createPortGroup('left', null, -2, 0, 5, 5),
    //   'right': this.createPortGroup('right', null, -2, 0, 5, 5)
    // };
  }

  stateAttributes() {
    return {
      markup: [
        {
          tagName: 'g',
          className: 'whole',
          children: [
            {
              tagName: 'g',
              className: 'scalable',
              selector: 'scalable',
              children: [
                {
                  tagName: 'rect',
                  className: 'outer',
                },
                {
                  tagName: 'rect',
                  className: 'inner',
                },
              ],
            },
            {
              tagName: 'text',
            },
            {
              tagName: 'image',
            },
            {
              tagName: 'image',
              className: 'currentImg',
            },
            {
              tagName: 'image',
              className: 'rangeImg',
            }
          ],
        },
      ],
      type: 'opm.State',
      size: { width: 60, height: 30 },
      minSize: { width: 60, height: 30 },
      padding: 5
    };
  }

  innerOuter(styleSettings) {
    const param = {
      fill: styleSettings.fill_color,
      stroke: styleSettings.border_color,
      rx: 5,
      ry: 5,
      'ref-x': .5, 'ref-y': .5,
      'x-alignment': 'middle',
      'y-alignment': 'middle',
    };
    return { ...this.entityShape(), ...param };
  }

  createInner(styleSettings) {
    return {
      fill: styleSettings.fill_color,
      'stroke-width': 0,
      width: 60,
      height: 30,
      'ref-x': .5, 'ref-y': .5,
      'x-alignment': 'middle',
      'y-alignment': 'middle',

    };
  }

  createOuter(styleSettings) {
    return {
      fill: styleSettings.fill_color,
      width: 70,
      height: 40,
    };
  }

  getDefaultStyleSettings() {
    return {
      font_size: defaultStateStyleSettings.font_size,
      font: defaultStateStyleSettings.font,
      text_color: defaultStateStyleSettings.text_color,
      border_color: defaultStateStyleSettings.border_color,
      fill_color: defaultStateStyleSettings.fill_color
    };
  }

  stateAttrs(stateName) {
    const styleSettings = this.getDefaultStyleSettings();
    const init: InitRappidService = getInitRappidShared();
    if (init) {
      const oplService = init.oplService;
      if (oplService) {
        this.setRelevantStyleSettings(styleSettings, oplService);
      }
    }
    return {
      rect: { fill: styleSettings.fill_color } ,
      '.outer': { ...this.innerOuter(styleSettings), ...this.createOuter(styleSettings) },
      '.inner': { ...this.innerOuter(styleSettings), ...this.createInner(styleSettings) },
      '.whole': { 'ref-width': 0.99, 'ref-height': 0.99 },
      text: { textWrap: { text: stateName }, 'font-weight': 300, fill: styleSettings.text_color, 'font-size': styleSettings.font_size,
        'font-family': styleSettings.font },
      'image': {
        'xlink:href': 'assets/SVG/defaultState.svg',
        // 'xlink:href': 'assets/SVG/currentState.svg',
        display: 'none',
        'ref-x': 1,
        'ref-y': 1,
        x: -18,
        y: -18,
        ref: 'rect',
        width: 25,
        height: 25
      },
      '.currentImg': {
        'xlink:href': 'assets/SVG/currentState.svg',
        display: 'none',
        'ref-x': 1,
        'ref-y': 1,
        x: -13,
        y: -18,
        ref: 'rect',
        width: 25,
        height: 25
      },
      '.rangeImg': {
        'xlink:href': 'assets/SVG/rangeState.svg',
        display: 'none',
        'ref-x': 0.5,
        'ref-y': 1,
        x: -12,
        y: -8,
        ref: 'rect',
        width: 24,
        height: 8
      }
    };
  }

  setRelevantStyleSettings(styleSettingsToUpdate, oplService) {
    Object.keys(styleSettingsToUpdate).forEach(function (key) { // override according to organization settings if exists
      if (oplService.orgOplSettings?.style?.state?.hasOwnProperty(key) && oplService.orgOplSettings.style?.state[key] !== undefined
        && oplService.orgOplSettings?.style?.state[key] !== null) {
        styleSettingsToUpdate[key] = oplService.orgOplSettings.style?.state[key];
      }
    });
    Object.keys(styleSettingsToUpdate).forEach(function (key) { // override according to user settings if exists
      if (oplService.userOplSettings?.style?.state?.hasOwnProperty(key) && oplService.settings.style?.state[key] !== undefined && oplService.settings.style?.state[key] !== null) {
        styleSettingsToUpdate[key] = oplService.settings.style.state[key];
      }
    });
    this.originalColor = oplService.settings?.style?.state?.fill_color || oplService.orgOplSettings?.style?.state?.fill_color || this.originalColor;
  }

  getParams() {
    const params = {
      stateType: this.checkType(),
      fill: this.originalColor || this.attr('rect/fill'),
      strokeColor: this.attr('rect/stroke') || this.attr('.outer/stroke')
    };
    return { ...super.getEntityParams(), ...params };
  }

  private originalColor = "#FFFFFF";

  setOriginalColor(color: string) {
    this.originalColor = color;
  }

  updateParamsFromOpmModel(visualElement) {
    this.attr({'.' : { opacity: visualElement.belongsToSubModel ||  visualElement.fatherObject.logicalElement.visualElements.some(v => v.protectedFromBeingChangedBySubModel || v.belongsToFatherModelId) ? 0.6 : 1 }});
    this.originalColor = visualElement.fill;
    const entityParams = this.updateEntityFromOpmModel(visualElement);
    const stroke = entityParams.stroke ? entityParams.stroke : (visualElement.strokeColor ? visualElement.strokeColor : '#808000');
    const attr = {
      '.outer': { ...entityParams, ...{ stroke: stroke } },
      '.inner': { ...entityParams, ...{ stroke: stroke } },
      fill: visualElement.fill ? visualElement.fill : '#ffffff',
      strokeColor: stroke
    };
    this.attr(attr);
    this.updateStateByType(visualElement.logicalElement.stateType);
    this.attr('rect/fill', visualElement.fill ? visualElement.fill : '#ffffff');
    this.attr('.inner/width', visualElement.width - 10);
    this.attr('.outer/width', visualElement.width);
    this.attr('text/textWrap/text', visualElement.logicalElement.getDisplayText());
    this.attr('.rangeImg/display', visualElement.hasRange() ? 'block' : 'none');
    if (getInitRappidShared().paper)
      this.findView(getInitRappidShared().paper)?.resize();
    this.updateURLArray();
  }

  updateStateByType(type) {
    let init = 2, final = 0, def = 'none';
    if (type.indexOf('initial') > -1) init = 3;
    if (type.indexOf('final') > -1) final = 2;
    if (type.indexOf('default') > -1) def = 'flex';
    if ((type === 'Initial') || (type === 'finInitial') || (type === 'DefInitial') || (type === 'all')) init = 3;
    if ((type === 'finInitial') || (type === 'Final') || (type === 'all') || (type === 'DefFinal')) final = 2;
    if ((type === 'Default') || (type === 'DefFinal') || (type === 'DefInitial') || (type === 'all')) def = 'flex';
    if (type.includes('Current'))
      this.attr('.currentImg/display', 'flex');
    this.attr('image/display', def);
    this.attr('.outer/stroke-width', init);
    this.attr('.inner/stroke-width', final);
  }

  getDescriptionSvgIcon(descXpos, descYpos, title) {
    return `<svg class="descSign" x=${descXpos} y=${descYpos} width="9.6" height="12" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M11.9904 4.03649C11.9923 4.00564 11.9923 3.97472 11.9904 3.94387C11.9904 3.94387 11.9904 3.9298 11.9904 3.92375C11.9836 3.88758 11.9727 3.85244 11.9578 3.81905C11.9578 3.809 11.9578 3.80295 11.9482 3.7949C11.9387 3.78685 11.9214 3.74257 11.9061 3.71841L11.885 3.69223C11.864 3.66175 11.8402 3.63344 11.814 3.6077L8.16037 0.143077C8.13548 0.121178 8.10919 0.101003 8.08178 0.0826837L8.05112 0.0685921C8.03082 0.0562368 8.00966 0.045471 7.98785 0.0363816L7.96869 0.024303L7.89776 0.0021585H7.87666C7.84478 -0.000719501 7.81273 -0.000719501 7.78084 0.0021585H0.575081C0.424857 0.00211554 0.28058 0.0638069 0.173109 0.174039C0.065638 0.284268 0.00350547 0.434287 0 0.592006V14.3961C0 14.5563 0.0605887 14.7099 0.168437 14.8231C0.276286 14.9364 0.422561 15 0.575081 15H11.4249C11.5774 15 11.7237 14.9364 11.8316 14.8231C11.9394 14.7099 12 14.5563 12 14.3961V4.05259C12 4.05259 11.9904 4.04251 11.9904 4.03649ZM8.34826 1.94081L9.9393 3.45267H8.34826V1.94081ZM1.15016 13.7962V1.19595H7.20575V4.05259C7.20575 4.21276 7.26634 4.36637 7.37418 4.47962C7.48205 4.59291 7.62832 4.65654 7.78084 4.65654H10.8479V13.7962H1.15016Z" fill="#5A6F8F"/>
            <line x1="3" y1="5.5" x2="9" y2="5.5" stroke="#5A6F8F"/>
            <line x1="3" y1="9.5" x2="9" y2="9.5" stroke="#5A6F8F"/>
            <line x1="3" y1="11.5" x2="7" y2="11.5" stroke="#5A6F8F"/>
            <line x1="3" y1="7.5" x2="7" y2="7.5" stroke="#5A6F8F"/>
            <path d="M0 0H8L10 2L12 4V15H0V0Z" fill="white" fill-opacity="0.01"/>
            <title>${title}</title>
            </svg>
      `;
  }


  updateURLArray() {
    const init = getInitRappidShared();
    if (init.exportingOpl || !init?.paper?.findViewByModel(this))
      return;
    let markup;
    if (this.hasURLs() || this.hasDescription().value) {
      const statePos = init.paper.findViewByModel(this).el.getBoundingClientRect();
      const textPos = init.paper.findViewByModel(this).el.querySelector('text').getBoundingClientRect();
      const relativePos = { x: textPos.left - statePos.left - 8, y: textPos.top - statePos.top - 6 };
      const xPos = "'{}'".replace('{}', String(relativePos.x));
      const yPos = "'{}'".replace('{}', String(relativePos.y));
      let urlMarkup = '', descriptionMarkup = '';

      if (this.hasURLs())
        urlMarkup = this.getUrlSvgIcon(xPos, yPos);

      const descXpos = "'{}'".replace('{}', String(relativePos.x + textPos.width + 9));
      const descYpos = "'{}'".replace('{}', String(relativePos.y + 1));
      const title = "'{}'".replace('{}', String(this.hasDescription().desc));
      if (this.hasDescription().value)
        descriptionMarkup = this.getDescriptionSvgIcon(descXpos, descYpos, title);

      markup = `<g class="whole"><g class="scalable"><rect class="outer"/><rect class="inner"/></g><text/><image/>` + urlMarkup + descriptionMarkup + `</g>`
    } else {
      markup = this.stateAttributes().markup;
    }
    this.set('markup', markup);
    this.addSvgsClickEvents(init);
  }

  greyOutEntity() {
    if (getInitRappidShared().exportingOpl) {
      return;
    }
    if ((<any>this.getVisual()).fatherObject && (<any>this.getVisual()).fatherObject.logicalElement.shouldBeGreyed === true && getInitRappidShared().shouldGreyOut) {
      this.graph.startBatch('ignoreChange');
      const attr = {
        rect: {
          fill: 'lightgrey',
        },
        '.inner': {
          stroke: 'grey',
        },
        '.outer': {
          stroke: 'grey',
        },
      };
      this.attr(attr);
      this.graph.stopBatch('ignoreChange');
    } else {
      this.graph.startBatch('ignoreChange');
      const vis = this.getVisual();
      if ((<any>vis).fill === 'lightgrey' || vis.strokeColor === 'grey') {
        (<any>vis).fill = ((<any>vis).fill === 'lightgrey' || !(<any>vis).fill) ? getStyles('opm.State').fill : (<any>vis).fill;
        (<any>vis).strokeColor = ((<any>vis).strokeColor === 'lightgrey' || !(<any>vis).strokeColor) ? getStyles('opm.State').stroke : (<any>vis).strokeColor;
      }
      this.updateParamsFromOpmModel(this.getVisual());
      this.graph.stopBatch('ignoreChange');
    }
  }

  removeHandle(options: InitRappidService) {
    /*const fatherObject = <OpmObject>options.graph.getCell(this.get('father'));

    if (fatherObject.get('embeds').length === 0) {
      fatherObject.arrangeEmbededParams(0.5, 0.5, 'middle', 'middle', 'bottom', 0, 0);
      fatherObject.attr('text/textWrap', { width: '80%', height: '80%' })
      //    fatherObject.updateTextSize(textWrapping.calculateNewTextSize(fatherObject.attr('text/textWrap/text'), fatherObject));
      // if the state was a value of the object then delete the value from the object
      if (fatherObject.attr('value/value') !== 'None') {
        fatherObject.removeValue(options, 'None', 'None', valueType.None);
      }
    } else {
      fatherObject.arrangeEmbedded(options);
    }
    Arc.redrawAllArcs(fatherObject, options, true);*/
  }

  removeAction(visual: OpmVisualState, init: InitRappidService) {
    init.getOpmModel().logForUndo(visual.fatherObject.logicalElement.text + ' state removal');
    const ret = visual.removeAction();
    if (ret.removed) {
      const parent = this.getParent();
      init.getGraphService().viewRemove(ret.elements);
      parent.updateSiblings(visual.fatherObject, init);
      if (visual.fatherObject.states.length === 0 && visual.fatherObject.children.length === 0) {
        if (!visual.fatherObject.isManualTextPos) {
          parent.attr('text/ref-x', '0.5');
          parent.attr('text/ref-y', '0.5');
        }
        parent.attr('text/x-alignment', 'middle');
        parent.attr('text/y-alignment', 'middle');
      }
      return;
    }
    init.getOpmModel().removeLastUndoOperation();
    validationAlert('Cannot Remove', 2500, undefined, true);
  }

  checkType() {
    let type = '';
    if (this.attr('.currentImg/display') === 'flex')
      type += 'Current';
    if (this.attr('image/display') === 'flex')
      type += 'default';
    if (this.attr('.inner/stroke-width') > 0)
      type += 'final';
    if (this.attr('.outer/stroke-width') > 2)
      type += 'initial';

    if (type === '')
      return 'none';
    return type;
  }

  getTypeForOpl() {
    const type = this.checkType();
    const ret = [];
    if (type.includes('default'))
      ret.push('default');
    if (type.includes('initial'))
      ret.push('initial');
    if (type.includes('final'))
      ret.push('final');

    if (ret.length === 0)
      return 'none';

    return ret.join('_');
  }

  changePositionHandle(initRappid, direction: any = null) {
    const parent = this.getParent();
    if (!parent)
      return;
    let changed = false;
    this.graph.startBatch('ignoreEvents');
    const parentPos = parent.get('position');
    if (parentPos.x + 10 > this.get('position').x) {
      changed = true;
      const widthDiff = 10 - (this.get('position').x - parent.get('position').x)
      parent.set('position', {x: parent.get('position').x - widthDiff, y: parent.get('position').y});
      parent.set('size', {width: parent.get('size').width + widthDiff, height: parent.get('size').height});
    }
    if (parentPos.y + 10 > this.get('position').y) {
      changed = true;
      const newYPos = Math.min(this.get('position').y - 10, parent.get('position').y);
      const heightDiff = parent.get('position').y - newYPos;
      parent.set('position', {x: parent.get('position').x, y: newYPos});
      parent.set('size', {width: parent.get('size').width, height: parent.get('size').height + heightDiff});
    }
    this.graph.stopBatch('ignoreEvents');
    parent.redrawDuplicationMark(initRappid);
    if (!changed)
      super.changePositionHandle(initRappid, direction);
  }

  getConfigurationTools(initRappid) {
    return [...super.getConfigurationTools(initRappid), { action: 'suppress', content: 'Suppress' }];
  }

  configurationContextToolbarEvents(target, contextToolbar, initRappid: InitRappidService) {
    super.configurationContextToolbarEvents(target, contextToolbar, initRappid);
    const this_ = this;
    const visual = <any>initRappid.opmModel.currentOpd.visualElements.find(c => c.id === this.id);
    contextToolbar.on('action:suppress', function () {
      initRappid.graph.startBatch('statesSuppression');
      this_.suppressAction(visual, initRappid);
      initRappid.graph.stopBatch('statesSuppression');
      this.remove();
    });
  }
  changeAttributesHandle(options) {
    super.changeAttributesHandle(options);
    /*if ((this.attr('text/textWrap/text') !== this.lastEnteredText) &&
      this.attr('text/textWrap/text').trim() !== '') { // if the text was changed
      const fatherObject = <OpmObject>options.graph.getCell(this.get('father'));
      if (fatherObject)
        fatherObject.minimumSizeHandle(options);
    }*/
  }
  getNextPort(port) {
    if (port === 15) return 1;
    return port + 1;
  }

  public suppressAction(visual: OpmVisualState, init?) {
    if (visual.suppress() === false) {
      validationAlert('The state ' + (<any>visual.logicalElement).getBareName() + ' cannot be suppressed because of its connected links.')
      return;
    }

    this.graph.startBatch('ignoreEvents');
    this.graph.startBatch('ignoreChange');
    const ellipsis = visual.fatherObject.ellipsis.setDefaultPosition();
    let e_cell = this.graph.getCell(ellipsis.id);
    if (!e_cell) {
      e_cell = new OpmEllipsis();
      e_cell.updateParamsFromOpmModel(ellipsis);
      this.graph.addCell(e_cell);
      this.getParent().embed(e_cell);
      e_cell.set('father', e_cell.get('parent'));
    }
    this.getParent().updateParamsFromOpmModel(ellipsis.fatherObject);
    this.graph.stopBatch('ignoreChange');
    this.graph.stopBatch('ignoreEvents');
    const graph = this.graph;
    const parent = this.getParent();
    graph.startBatch('ignoreRemoveEvent');
    this.remove();
    graph.stopBatch('ignoreRemoveEvent');
    parent.updateView(parent.getVisual());
    if (init) {
      init.setSelectedElementToNull();
      parent.shiftEmbeddedToEdge(init);
    };

  }
  isTextCut(init) {
    let fullText = this.attributes.attrs.text.textWrap.text;
    if (fullText)
      fullText = fullText.replace(/(\r\n|\n|\r| )/g, '');
    let shownText = init.paper.findViewByModel(this).$el[0].textContent;
    if (shownText)
      shownText = shownText.replace(/(\r\n|\n|\r| )/g, '');
    if (fullText && shownText && shownText.length < fullText.length) {
      return true;
    }
    return false;
  }
  haloConfiguration(halo, options) {
    super.haloConfiguration(halo, options);
    const this_ = this;
    const curentObject = this_.getAncestors()[0];
    const embeddedStates: Array<OpmState> = curentObject.getEmbeddedCells().filter(child => child instanceof OpmState);
    const hasStates = embeddedStates.length > 0;
    const Dcheckbox = '<input id="Default" class="Default" name="Default" type="checkbox"' +
      ((this.attr('image/display') === 'flex') ? ' checked' : '') + '> <label for="Default">Default</label>';
    const Icheckbox = '<input  id="Initial" class="Initial" name="Initial" type="checkbox" ' +
      ((this.attr('.outer/stroke-width') === 3) ? ' checked' : '') + '> <label for="Initial">Initial</label>';
    const Fcheckbox = '<input id="Final" class="Final" name="Final" type="checkbox" ' +
      (this.attr('.inner/stroke-width') === 2 ? ' checked' : '') + '> <label for="Final">Final</label>';
    // halo.addHandle(this.addHandleGenerator('stateType', 'sw', 'Click to define state type', 'right'));
    // halo.addHandle({name:'stateType', direction: 'bottom', icon: 'assets/SVG/stateTypeHalo.svg', attr: {
    //     '.handle': {
    //       'data-tooltip-class-name': 'small',
    //       'data-tooltip': 'stateType',
    //       'data-tooltip-position': 'top',
    //       'data-tooltip-padding': 15,
    //     }
    //   }});
    // halo.on('action:stateType:pointerdown', function () {
    //   console.log('stateType');
    //   const cellModel = this.options.cellView.model;
    //   const log: OpmLogicalState = options.opmModel.getLogicalElementByVisualId(this_.id);
    //
    //   // access properties using this keyword
    //   const popupEvents = {
    //     'click .Default': function toggleCheckboxD() {
    //       const DcheckInput = (<HTMLInputElement>document.getElementById('Default')).checked ? 'flex' : 'none';
    //       cellModel.attr('image/display', DcheckInput);
    //       log.stateType = this_.checkType();
    //     },
    //     'click .Initial': function toggleCheckboxI() {
    //       const IcheckedInput = (<HTMLInputElement>document.getElementById('Initial')).checked ? 3 : 2;
    //       cellModel.attr('.outer/stroke-width', IcheckedInput);
    //       log.stateType = this_.checkType();
    //     },
    //     'click .Final': function toggleCheckboxF() {
    //       const FcheckedInput = (<HTMLInputElement>document.getElementById('Final')).checked ? 2 : 0;
    //       cellModel.attr('.inner/stroke-width', FcheckedInput);
    //       log.stateType = this_.checkType();
    //     },
    //   };
    //   const popupContent = ['<form>', Dcheckbox, Icheckbox, Fcheckbox, '</form>'].join('');
    //   popupGenerator(this.el, popupContent, popupEvents).render();
    // });
    // if ( hasStates) {
    //   halo.addHandle({
    //     name: 'suppress', icon: 'assets/SVG/supressHalo.svg', attrs: {
    //       '.slice': {
    //         'data-tooltip-class-name': 'small',
    //         'data-tooltip': 'Suppress',
    //         'data-tooltip-position': 'bottom',
    //         'data-tooltip-padding': 15,
    //       }
    //     }
    //   });
    // }
    halo.on('action:suppress:pointerdown', function () {
      getInitRappidShared().getOpmModel().logForUndo((<OpmLogicalState>this_.getVisual().logicalElement).text + ' state suppression');
      getInitRappidShared().graph.startBatch('statesSuppression');
      this_.suppressAction(<OpmVisualState>this_.getVisual(), options);
      halo.toggleState('default');
      curentObject.redrawDuplicationMark(options);
      curentObject.greyOutEntity();
      Arc.redrawAllArcs(curentObject, options, true);
      curentObject.shiftEmbeddedToEdge(options);
      getInitRappidShared().graph.stopBatch('statesSuppression');
    });

    halo.on('action:timeDurationFunction:pointerdown', function () {
      this_.openTimeDuration(options.paper.findViewByModel(this_).el, (<any>this_).getVisual().logicalElement.getDurationManager(), { digits: options.oplService.settings.timeDurationUnitsDigits });
      this.remove();
    });
  }

  pointerDownHandle(cellView, init) {
    super.pointerDownHandle(cellView, init);
    this.getParent().previousStatesOrder = this.getParent().getStatesOnly().map(st => st.id);
  }

  pointerUpHandle(cellView, init) {
    if (!this.getVisual())
      return;
    super.pointerUpHandle(cellView, init);
    this.getParent().getVisual()?.updateLastStatesOrder();
    const changed = this.getParent().checkIfStatesOrderChanged();
    this.getParent().previousStatesOrder = [];
    if (!changed)
      return;
    this.getParent().syncStatesOrder(init, false);
  }

  updateShapeAttr(newValue) {
    this.attr('.inner', newValue);
    this.attr('.outer', newValue);
  }

  getShapeAttr() {
    return this.attr('.inner');
  }

  getShapeFillColor() {
    return this.attr('.inner/fill');
  }

  getShapeOutline() {
    return this.attr('.inner/stroke');
  }

  // change the first letter to lower case
  toggleFirstLetter(word) {
    return word.charAt(0).toLowerCase() + word.substring(1); // capitalizing the first letter of the word
  }

  toggleLetter(letter) {
    return letter.toLowerCase();
  }

  // after closing the text editor, need to update the value of the object if the state
  // is a value
  closeTextEditor(initRappid) {
    // super.closeTextEditor(initRappid);
    const parent = this.getParent();
    if (parent && parent.attr('value/valueType') !== valueType.None) {
      parent.updateObjectValueType(initRappid, this.attr('text/textWrap/text'));
      parent.lastEnteredText = parent.attr('text/textWrap/text');
      // parent.setCursorToUnits(initRappid);
    }
  }

  getExhibitors() {
    const links = this.graph.getConnectedLinks(this, { inbound: true });
    const exibitors = [];
    for (const link of links) {
      if (link.attributes.name === 'Exhibition-Characterization') {
        exibitors.push(link.getSource());
      }
    }
    return exibitors;
  }

  public updateView(visual: OpmVisualState) {
    super.updateView(visual);
    this.setValidationView(visual);
  }

  private setValidationView(visual: OpmVisualState) {
    const status = visual.getValidationView();
    this.attr('rect/fill', status.color || this.originalColor);
    this.attr('.inner/fill', status.color || this.originalColor);
    this.attr('.outer/fill', status.color || this.originalColor);
  }

  public canBeSuppressed(): boolean {
    // Should be moved to the logica part...
    return !(this.graph.getConnectedLinks(this).length);
  }
  updateDuplicationMarkFillColor(fillColor) { }
  updateDuplicationMarkBorderColor(fillColor) { }

  getIconsForHalo() {
    return Object.assign(super.getIconsForHalo(), { suppress: 'assets/SVG/supressHalo.svg' }, { styling: 'assets/SVG/styleElement.svg' }, { timeDurationFunction: 'assets/SVG/timeDuration.svg' });
  }

  embedText() {
    const padding = 10;
    const fullText = String(this.attr('text/textWrap/text'));
    const attrs = { 'font-size': this.attr('text/font-size') };
    const size = { width: this.get('size').width + 0, height: this.get('size').height + 0 };
    let sizeToTest = { width: size.width - padding, height: size.height - padding };
    let brokenText = joint.util.breakText(fullText, sizeToTest, attrs, { ellipsis: true });
    let changed = false;
    while (brokenText.endsWith('…') || brokenText === '') { // the text is bigger then the shape
      size.width = size.width + 10;
      size.height = size.height + 5;
      sizeToTest = { width: size.width - padding, height: size.height - padding };
      brokenText = joint.util.breakText(fullText, sizeToTest, attrs, { ellipsis: true });
      changed = true;
    }
    this.manuallyResized = false;
    if (changed)
      size.width += padding;
    this.set('size', size);
  }

  updateTextView() {
    super.updateTextView();
    this.getParent().changeSizeHandle(getInitRappidShared());
  }

  changeSizeHandle(initRappid, direction: any = null) {
    super.changeSizeHandle(initRappid, direction);
    this.updateURLArray();
  }

  getEssence() {
    return this.getParent().getEssence();
  }

  public openTimeDuration(target, manager: TimeDurationModule, params: { digits: number }) {
    openTimeDurationPopup(target, this, manager, params);
  }

  // public updateTextFromModel(logical) {
  //   this.getParent().changeSizeHandle(getInitRappidShared());
  // }

  isTimeDuration(): boolean {
    const init = getInitRappidShared();
    const visual = init.getOpmModel().getVisualElementById(this.id);
    if (visual)
      return visual.logicalElement.isTimeDuration();
    else
      return false;
  }

  getHaloHandles(init: InitRappidService): Array<ElementHaloHandle> {
    const visual = this.getVisual() as OpmVisualState;
    if (!visual)
      return [];
    const decider = visual.getCommandsDecider();
    return decider.set(init, this, visual).getHaloHandle();
  }

  getToolbarHandles(init: InitRappidService): Array<ElementToolbarHandle> {
    const visual = this.getVisual() as OpmVisualState;
    if (!visual)
      return [];
    const decider = visual.getCommandsDecider();
    return decider.set(init, this, visual).getToolabarHandle();
  }

}
