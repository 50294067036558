import { Component, OnInit } from '@angular/core';
import { OrganizationService, Organization } from '../../rappid-components/services/organization.service';
import { UserService } from '../../rappid-components/services/user.service';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';
import { UpdateUserDialogComponent } from '../update-user-dialog/update-user-dialog.component';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { ChangePasswordDialogComponent } from '../change-password-dialog/change-password-dialog.component';
import { ActivateUserDialogComponent } from '../activate-user-dialog/activate-user-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { RemoveUserComponent } from '../remove-user/remove-user.component';
import * as FileSaver from 'file-saver';

@Component({
  selector: 'opcloud-set-admin-user-list',
  templateUrl: './set-admin-user-list.component.html',
  styleUrls: ['./set-admin-user-list.component.css']
})
export class SetAdminUserListComponent implements OnInit {
  organizations$: Observable<Array<any>>;
  OrgAdminOrganization = [];
  OrgAdmin;
  sortedUsersArray;
  displayedColumns = ['Email', 'Name'];



  userWithdetails = [];
  private readonly users: Subject<Array<any>> = new BehaviorSubject<Array<any>>([]);
  public readonly users$: Observable<Array<any>> = this.users.asObservable();

  currentOrg: string;
  currentUser: any;
  placeholder: string;
  listView = false;
  searchText = '';

  usersList: Array<any>;

  selected = '';
  loggedUser: any;
  chosenOrganizationName: string; /* the chosen organization name if routed to this component after clicking
  organization name in the organization administration */

  constructor(private orgService: OrganizationService,
    private userService: UserService,
    // public deletevarification: AdminDeleteVarificationComponent,
    public dialog: MatDialog, private route: ActivatedRoute
  ) {
    // this.organizations.push('Select Organization');
    route.params.subscribe(params => { /* get the chosen organization if routed to this component after clicking
    organization name in the organization administration */
      this.chosenOrganizationName = params['chosenOrganization'];
    });
  }

  ngOnInit() {
    const came_from_org_admin = this.chosenOrganizationName !== null && this.chosenOrganizationName !== undefined;
    this.userService.user$.take(1).subscribe(data => {
      const user = data.userData;
      this.loggedUser = user;
      const organization_name = (came_from_org_admin === true) ? this.chosenOrganizationName : user.organization;
      if (user.SysAdmin) {
        this.organizations$ = this.orgService.getOrganizations();
      } else {
        this.organizations$ = Observable.of([{ id: user._organization, name: organization_name }]);
      }
      // this.placeholder = (user.OrgAdmin) ? user.organization : 'Organization';
      this.placeholder = organization_name;
      this.OrgAdmin = user.OrgAdmin;
      this.selected = organization_name;
      this.getUsersDeatailsById(this.selected);
    });
  }

  getOrgUsersId(event: any) {
    const selectedOrg = event.value.split(',')[1];
    this.selected = selectedOrg;
    this.getUsersDeatailsById(selectedOrg);
    const that = this;
  }

  getUsersDeatailsById(org) {
    const currProc = this;
    currProc.currentOrg = org;
    currProc.users.next([]);
    currProc.orgService.getOrganizationUsers(org).then((result) => {
      currProc.users.next(currProc.usersList = result);
    }).catch((err) => currProc.users.next([]));

    /*this.userWithdetails = this.userService.getUserListWithDetails(org);
    this.userWithdetails.splice(0, this.userWithdetails.length);
    this.userwithDetailsFiltered = this.userWithdetails;*/
  }

  openDialogUpdateUser(user) {
    this.dialog.open(UpdateUserDialogComponent, { data: { user: user, organization: this.selected } });
  }

  /**
   * input: a user
   * output: activating or deactivating the given user
   * */
  async openDialogDelUser(user) {
    const currProc = this;
    currProc.currentUser = user;
    let wantToDeactivate = false;
    if (currProc.currentUser.isActive || currProc.currentUser.isActive === undefined) {
      wantToDeactivate = window.confirm('Are sure you want to deactivate the user: ' + user.Email + '?');
      if (!wantToDeactivate) { return; } // the user clicked 'cancel' so nothing should happen
    }
    const action = (wantToDeactivate) ? 'Deactivated' : 'Activated';
    const errorMsg = 'ERROR! User wasn\'t ' + action + '!';
    const successMsg = 'User ' + action + ' Successfully!';
    if (!wantToDeactivate) { // if user should be activated
      if (!currProc.isUserActivatedAccordingToDate(user.exp_date)) {
        const dialogRef = currProc.dialog.open(ActivateUserDialogComponent, { data: { user: user, organization: this.selected } });
        await dialogRef.afterClosed().toPromise() // should wait until the user updated expiration date or decided to do nothing
          .then(res => {
            if (currProc.isUserActivatedAccordingToDate(currProc.currentUser.exp_date)) { // expiration date is in the future
              currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
            }
          });
      } else { // original expiration date is in the future
        currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
      }
    } else { // user should be deactivated
      currProc.changeActivationStatusWrapper(successMsg, errorMsg, user, wantToDeactivate);
    }
  }
  /***
   * input: a string to be displayed in case of success (successMsg) or failure (errorMsg),
   * the user that should be activated/deactivated and a boolean value (wantToDeactivate) that indicates if the user
   * should be deactivated or deactivated(if true- should be deactivated)
   * output: this function changes the user activation status.
   * **/
  private changeActivationStatusWrapper(successMsg: string, errorMsg: string, user, wantToDeactivate: boolean) {
    this.orgService.changeActivationStatus(user.uid, !wantToDeactivate)
      .then(res => { validationAlert(successMsg, null, 'Success'); user.isActive = !wantToDeactivate; })
      .catch(err => { validationAlert(errorMsg + err, null, 'Error'); });
  }

  resetPassword(user) {
    this.currentUser = user;
    if (this.userService.shouldChangePassword()) {
      this.dialog.open(ChangePasswordDialogComponent, { data: { user: user } });
    } else {
      this.userService.resetPassword(this.currentUser.Email).then(
        res => { validationAlert('Mail with reset details sent to ' + this.currentUser.Email, null, 'Success'); }
      ).catch(err => { validationAlert('Failed: ' + err, null, 'Error'); });
    }
  }

  toggleListView() {
    this.listView = !this.listView;
  }

  /**
   * input: a list of observables, retrieves them to a list of users
   * output: count of users in the organization
   * */
  countUsers(usersList) {
    let userCount = 0;
    usersList.subscribe((value) => {
      userCount = Object.keys(value).length;
    }).unsubscribe();
    return userCount;
  }

  /**
   * input: a list of observables, retrieves them to a list of users
   * output: filter the users that their email or name includes current search input (searchText)
   * */
  filterUsers(usersList) {
    const auxUsersList = [];
    usersList.subscribe((value) => {
      for (let i = 0; i < Object.keys(value).length; i++) {
        auxUsersList.push(value[i]);
      }
    }).unsubscribe();
    if (this.searchText === undefined || this.searchText === '') { return auxUsersList; }
    return auxUsersList.filter((user) => {
      return user.Name.toLowerCase().includes(this.searchText.toLowerCase())
        || user.Email.toLowerCase().includes(this.searchText.toLowerCase());
    });
  }
  /**
   * input: a list of users
   * output: returns it sorted*/
  sortArray(arr) {
    arr.sort((a, b) => (a.Name.charAt(0).toUpperCase() < b.Name.charAt(0).toUpperCase()) ? -1 : a.Name.charAt(0).toUpperCase() > b.Name.charAt(0).toUpperCase() ? 1 : 0);
    return Observable.of(arr);
  }

  /**
   * input: receives a user expiration date (timestamp)
   * output: true if the user has permanent access or if the expiration date is in the future (or today).
   * otherwise, returns false*/
  isUserActivatedAccordingToDate(user_exp_date) {
    if (user_exp_date === '') { // the user has permanent access
      return true;
    }
    if (user_exp_date) {
      const currDateTime = new Date().getTime();
      return (currDateTime <= user_exp_date);
    }
    return false;
  }

  /**
   * an error event handler, called when there an error on loading the picture
   * input: receives the event and the default picture url
   * output: updates the img src to be the default url
   * */
  updateToDefaultUrl(event, defaultPictureUrl: string) {
    event.target.src = defaultPictureUrl;
  }

  deleteUser(user: any) {
    if (user.uid === this.loggedUser['uid']) {
      validationAlert('You cannot delete yourself. ', 5000, 'Error');
    } else {
      const dialog_ref = this.dialog.open(RemoveUserComponent, {
        width: '600',
        data: { user: { user } }
      });
      dialog_ref.afterClosed().toPromise().then(res => {
        if (res.removed)
          this.getUsersDeatailsById(this.selected)
      });
    }
  }

  /*returns true if the delete button should be seen*/
  canDeleteUsers() {
    return (this.loggedUser['SysAdmin']);
  }

  /**
   * Helper method to the export CSV file to prepare the CSV array from the triplets data
   */
  createModelersArray(modelersList) {
    const modelersArray = ['Full Name,', 'Email,', 'Accesses Date,', 'Type\n'];
    const modelersData = [];
    modelersList.subscribe((value) => {
      for (let i = 0; i < Object.keys(value).length; i++) {
        modelersData.push(value[i]);
      }
    }).unsubscribe();
    for (const modeler of modelersData) {
      modelersArray.push(modeler.Name + ',');
      modelersArray.push(modeler.Email + ',');
      const accessesDate = (modeler.exp_date !== '' ? (modeler.exp_date === 'NaN' ? 'Unknown' :
        new Date(modeler.exp_date * 1).toDateString()) : 'Permanent Access');
      modelersArray.push(accessesDate + ',');
      const type = (modeler.sso_user) ? 'SSO User' : 'Regular';
      modelersArray.push(type + '\n');
    }
    return modelersArray;
  }

  /**
   * A function for creating a CSV file with the organization modelers list
   * input: the users (modelers) list
   * output: a CSV file
   * */
  downloadUsersList(usersList) {
    const currDateTime = new Date().toDateString();
    const fileName = this.currentOrg + ' accounts ' + currDateTime;
    const exportTriplesDataArray = this.createModelersArray(usersList);
    const exportValuesFile = new Blob(exportTriplesDataArray, { type: 'text/csv' });
    FileSaver.saveAs(exportValuesFile, fileName + '.csv'); // Save the exported file.
  }
}
