import {OpmModel} from "../OpmModel";
import {OpmVisualThing} from "../VisualPart/OpmVisualThing";
import {OpmRelation} from "../LogicalPart/OpmRelation";
import {OpmLink} from "../VisualPart/OpmLink";
import {OpmLogicalEntity} from "../LogicalPart/OpmLogicalEntity";
import {OpmVisualEntity} from "../VisualPart/OpmVisualEntity";
import {OPCloudUtils, removeDuplicationsInArray} from "../../configuration/rappidEnviromentFunctionality/shared";
import {linkConnectionType, linkType} from "../ConfigurationOptions";
import {OpmVisualObject} from "../VisualPart/OpmVisualObject";
import {OpmProceduralLink} from "../VisualPart/OpmProceduralLink";
import {OpmLogicalThing} from "../LogicalPart/OpmLogicalThing";
import {bringConnectedRules, FetchingRule} from "../consistency/bringConnectedRules";
import { util } from 'jointjs';
import uuid = util.uuid;
import {OpmOpd} from "../OpmOpd";
import {BringConnectedTypes} from "./BringConnectedOptionsInterface";

export class BringConnectedEntitiesAction {

  private neededRelations;
  private neededEntities;
  private expandingRules: Array<FetchingRule>;
  private broughtEntities: Array<OpmVisualEntity>;

  constructor(private readonly model: OpmModel, private readonly visual: OpmVisualThing, private readonly opd: OpmOpd) {
    this.broughtEntities = [];
  }

  act(opt: Array<BringConnectedTypes>, styleParams) {
    this.filterRelevantRules(opt);
    this.neededRelations = new Array<OpmRelation<OpmLink>>();
    this.neededEntities = new Array<OpmLogicalEntity<OpmVisualEntity>>();
    this.collectEntitiesAndLinks();
    this.filterEntitiesAndRelations();
    this.createNeededThings();
    const created = this.createNeededRelations();
    this.setPartnersForConsumptionAndResultLinks(created);
    this.handleRequirements();
    this.bringLinksBetweenBroughtEntities(); // TODO
    this.setStyleParamsForBroughtThings(styleParams);
    this.opd.beautify(this.visual);
  }

  private filterRelevantRules(opt: Array<BringConnectedTypes>) {
    if (!opt || opt.length === 0) {
      this.expandingRules = bringConnectedRules;
    } else {
      this.expandingRules = bringConnectedRules.filter(rule => rule.ruleType.some(r => opt.includes(r)));
    }
  }

  private collectEntitiesAndLinks() {
    const links = this.visual.getAllLinks();
    for (const child of this.visual.children.filter(c => OPCloudUtils.isInstanceOfVisualState(c))) {
      const temp = child.getAllLinks();
      links.inGoing = [...links.inGoing, ...temp.inGoing];
      links.outGoing = [...links.outGoing, ...temp.outGoing];
    }
    const allLinks = [...links.inGoing, ...links.outGoing].filter(l => !l.belongsToSubModel);
    for (const link of allLinks)
      for (const rule of this.expandingRules) {
        const toFetch = rule.getNeededElements(link.logicalElement as OpmRelation<OpmLink>);
        this.neededRelations.push(...toFetch.relations);
        this.neededEntities.push(...toFetch.entities);
      }
  }

  private filterEntitiesAndRelations() {
    const statesObjects = this.neededEntities.filter(en => OPCloudUtils.isInstanceOfLogicalState(en)).map(st => st.parent); // bringing states parent objects
    this.neededEntities.unshift(...statesObjects);
    this.neededEntities.unshift(...this.neededEntities.filter(en => en.visualElements.some(v => v.belongsToSubModel)));
    this.neededEntities = this.neededEntities.filter(en => en && !en.hasFather());
    this.neededRelations = this.neededRelations.filter(rel => this.isNotRelationOfEntityInsideInzoomed(rel));
    this.neededRelations = removeDuplicationsInArray(this.neededRelations) as Array<OpmRelation<OpmLink>>;
    this.neededEntities = removeDuplicationsInArray(this.neededEntities) as Array<OpmLogicalEntity<OpmVisualEntity>>;
    this.neededRelations = this.neededRelations.filter(r => (r !== null && r.isAtOPD(this.opd) === false));
    this.neededEntities = this.neededEntities.filter(en => (en !== null && en.isAtOPD(this.opd) === false));
    this.neededEntities = this.neededEntities.filter(en => !en.constructor.name.includes('State'));
  }

  // if the relation doesn't connect an entity which sits inside a father inzoomed thing
  private isNotRelationOfEntityInsideInzoomed(rel: OpmRelation<OpmLink>): boolean {
    if (!rel.sourceLogicalElement || !rel.targetLogicalElements[0])
      return false;
    const sourceHasFather = (rel.sourceLogicalElement as OpmLogicalEntity<OpmVisualEntity>).hasFather();
    const targetHasFather = (rel.targetLogicalElements[0] as OpmLogicalEntity<OpmVisualEntity>).hasFather();
    const isSourceState = (rel.sourceLogicalElement as OpmLogicalEntity<OpmVisualEntity>).constructor.name.includes('State');
    const isTargetState = (rel.targetLogicalElements[0] as OpmLogicalEntity<OpmVisualEntity>).constructor.name.includes('State');
    if (sourceHasFather && isSourceState && targetHasFather && isTargetState)
      return true;
    if (!sourceHasFather && !targetHasFather)
      return true;
    // if (sourceHasFather && isSourceState && !targetHasFather)
    //   return true;
    // if (targetHasFather && isTargetState && !sourceHasFather)
    //   return true;
    if (targetHasFather && !sourceHasFather)
      return true;
    if (sourceHasFather && !targetHasFather)
      return true;
    if (OPCloudUtils.isInstanceOfVisualThing(rel.visualElements[0].target) && (<OpmVisualThing>rel.visualElements[0].target).getRefineeInzoom())
      return true;
    return false;
  }

  private createNeededThings() {
    for (const ent of this.neededEntities) {
      const thing = ent as OpmLogicalThing<OpmVisualThing>;
      const params = {
        id: uuid(),
        xPos: thing.visualElements[0].xPos,
        yPos: thing.visualElements[0].yPos,
        width: thing.visualElements[0].width,
        height: thing.visualElements[0].height,
      };
      const vis = thing.createVisual(params) as OpmVisualThing;
      vis.showBackgroundImage = thing.visualElements[0].showBackgroundImage;
      this.opd.add(vis);
      this.broughtEntities.push(vis);
      if (OPCloudUtils.isInstanceOfVisualObject(vis)) {
        (<OpmVisualObject>vis).expressAll();
        this.broughtEntities.push(...((<OpmVisualObject>vis).states || []))
      }
    }
  }

  private createNeededRelations(): Array<{ original: OpmLink, newRelation: OpmLink }> {
    const created = [];
    for (const curr of this.neededRelations as Array<OpmRelation<OpmLink>>) {
      const linkParams = {
        type: curr.linkType,
        connection: linkConnectionType.enviromental,
        isEvent: curr['event'],
        isCondition: curr['condition']
      };
      let source = this.model.getVisualElementOfLogicalAtOpd(curr.sourceLogicalElement, this.opd) as OpmVisualEntity;
      let target = this.model.getVisualElementOfLogicalAtOpd(curr.targetLogicalElements[0], this.opd) as OpmVisualEntity;
      if (!target && OPCloudUtils.isInstanceOfLogicalState(curr.targetLogicalElements[0])) {
        const visObjectFather = this.model.getVisualElementOfLogicalAtOpd((<any>curr.targetLogicalElements[0]).parent, this.opd);
        if (visObjectFather) {
          (<OpmVisualObject>visObjectFather).expressAll();
          target = this.model.getVisualElementOfLogicalAtOpd(curr.targetLogicalElements[0], this.opd) as OpmVisualEntity;
        }
      }
      if (curr.linkType === linkType.Consumption && OPCloudUtils.isInstanceOfVisualThing(target) && (<OpmVisualThing>target).getRefineeInzoom() === target
        && (<OpmVisualThing>target).getFirstChild())
        target = (<OpmVisualThing>target).getFirstChild();
      if (source && [linkType.Result, linkType.Invocation].includes(curr.linkType) && (<OpmVisualThing>source).getRefineeInzoom() === source
        && (<OpmVisualThing>source).getLastChild())
        source = (<OpmVisualThing>source).getLastChild();
      let canConnect = (!!source && !!target);
      if (canConnect)
        canConnect = this.model.links.canConnect(source, target, curr.linkType).success === true;
      canConnect = canConnect && !source.getLinksWith(target).outGoing.find(l => l.type === curr.linkType);
      if (canConnect) {
        const res = this.model.links.connect(source, target, linkParams);
        created.push({ original: curr.visualElements[0], newRelation: res });
        // keeping the or/xor relation in the new OPD
        res.sourceVisualElementPort = curr.visualElements.filter(v => v !== res)[0].sourceVisualElementPort;
        if (res.sourceVisualElementPort)
          this.visual.inheritPort(curr.visualElements.filter(v => v !== res)[0].source, res.source, res.sourceVisualElementPort);
        res.targetVisualElementPort = curr.visualElements.filter(v => v !== res)[0].targetVisualElementPort;
        if (res.targetVisualElementPort)
          this.visual.inheritPort(curr.visualElements.filter(v => v !== res)[0].target, res.target, res.targetVisualElementPort);
      }
    }
    return created;
  }

  // partner property for In/Out link pair.
  private setPartnersForConsumptionAndResultLinks(created: Array<{ original: OpmLink, newRelation: OpmLink }>) {
    const types = [linkType.Result, linkType.Consumption];
    for (const relation of created.filter(item => types.includes(item.original.type))) {
      const partnerAtOpdCameFrom = (<OpmProceduralLink>relation.original).getPartner();
      if (!partnerAtOpdCameFrom)
        continue;
      const partnerAtNewOpd = created.find(itm => itm.original.logicalElement === partnerAtOpdCameFrom.logicalElement);
      if (partnerAtNewOpd && partnerAtNewOpd.newRelation)
        (<OpmProceduralLink>partnerAtNewOpd.newRelation).setAsPartner(relation.newRelation as OpmProceduralLink);
    }
  }

  private handleRequirements() {
    const logical = this.visual.logicalElement as OpmLogicalThing<OpmVisualThing>;
    if (logical.hasRequirements()) {
      logical.getSatisfiedRequirementSetModule().toggleAttribute();
      if (logical.getAllRequirements().find(req => !req.getRequirementObject())) {
        logical.getSatisfiedRequirementSetModule().toggleAttribute();
      }
    }
    if (OPCloudUtils.isInstanceOfLogicalObject(logical) && logical.isSatisfiedRequirementObject())
      this.model.setRequirementsBroughtEntitiesPositions(this.visual as OpmVisualObject, this.opd);
  }

  private bringLinksBetweenBroughtEntities() {
    const linksToBring = [];
    for (const entity1 of this.broughtEntities) {
      for (const entity2 of this.broughtEntities) {
        if (entity1.logicalElement.lid === entity2.logicalElement.lid) {
          continue;
        }
        const links = entity1.getAllLinksWith(entity2);
        linksToBring.push(...links.inGoing.filter(l => !this.opd.getVisualElementByLogical(l.logicalElement)));
        linksToBring.push(...links.outGoing.filter(l => !this.opd.getVisualElementByLogical(l.logicalElement)));
      }
    }
    this.neededRelations = removeDuplicationsInArray(linksToBring.map(l => l.logicalElement));
    this.createNeededRelations();
  }

  private setStyleParamsForBroughtThings(styleParams) {
    if (!styleParams)
      return;
    for (const thing of this.broughtEntities.filter(en => OPCloudUtils.isInstanceOfVisualThing(en))) {
      (<OpmVisualThing>thing).applyDefaultStyleParams(styleParams);
    }
  }
}
