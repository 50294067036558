import { Component, Injectable, OnInit, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { UserDetails, UserService } from '../../rappid-components/services/user.service';
import { validationAlert } from '../../configuration/rappidEnviromentFunctionality/shared';


@Component({
  selector: 'opcloud-activate-user-dialog',
  templateUrl: './activate-user-dialog.component.html',
  styleUrls: ['./activate-user-dialog.component.css']
})
@Injectable()
export class ActivateUserDialogComponent implements OnInit {

  public isPermanent: boolean;

  user;
  subscription;

  details: UserDetails = {
    Name: undefined,
    Email: undefined,
    PhotoURL: undefined,
    SysAdmin: undefined,
    OrgAdmin: undefined,
    exp_date: undefined
  };

  constructor(
    private service: UserService,
    @Inject(MAT_DIALOG_DATA) private data,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.user = this.data.user;
    this.details.SysAdmin = this.user.SysAdmin;
    this.details.OrgAdmin = this.user.OrgAdmin;
    this.details.exp_date = new Date().getTime() + 5184000000; // if this dialog is called then the user exp date had passed, the default should be two month ahead.
    this.isPermanent = (this.user.exp_date === '');
  }

  activateUserDialog() {
    const curr = this;
    if (curr.isPermanent) {
      curr.details['exp_date'] = '';
    }
    curr.service.updateUser(curr.user.uid, curr.data.organization, curr.details).then(res => {
      Object.keys(curr.details).forEach((key) => {
        if (curr.details[key] || key === 'exp_date') {
          curr.user[key] = curr.details[key];
        }
      });
      curr.dialog.closeAll();
    });
  }

  cancelActivateUserDialog() {
    this.dialog.closeAll();
  }
}

