// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
  position: relative;
  font-family: Roboto, "Helvetica Neue", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  font-size: 20px;
  text-align: center;
  color: #1A3763;
  margin-top: 3px;
}

.reqLine {
  height: 50px;
  width: 100%;
  display: inline-flex;
  /*border-bottom: solid 1px #acacac;*/
}

.reqName {
  width: 230px;
  margin-top: 10px;
  margin-left: 20px;
  color: #1A3763;
  opacity: 80%;
}

.reqButtonPart {
  text-align: center;
}

.buttonCreateOrUpdate {
  width: 210px;
}

.mat-button {
  color: #1A3763;
  opacity: 60%;
  font-weight: 500;
  border: 1px solid rgba(88, 109, 140, 0.5);
  border-radius: 4px;
}
.buttonUpdate {
  color: white;
  background-color: #1a3763;
  opacity: 60%;
}
`, "",{"version":3,"sources":["webpack://./src/app/dialogs/create-requirement-view-dialog/create-requirement-view-dialog.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,iDAAiD;EACjD,kBAAkB;EAClB,gBAAgB;EAChB,mBAAmB;EACnB,eAAe;EACf,kBAAkB;EAClB,cAAc;EACd,eAAe;AACjB;;AAEA;EACE,YAAY;EACZ,WAAW;EACX,oBAAoB;EACpB,oCAAoC;AACtC;;AAEA;EACE,YAAY;EACZ,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;EACd,YAAY;AACd;;AAEA;EACE,kBAAkB;AACpB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,cAAc;EACd,YAAY;EACZ,gBAAgB;EAChB,yCAAyC;EACzC,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,yBAAyB;EACzB,YAAY;AACd","sourcesContent":["h3 {\n  position: relative;\n  font-family: Roboto, \"Helvetica Neue\", sans-serif;\n  font-style: normal;\n  font-weight: 500;\n  line-height: normal;\n  font-size: 20px;\n  text-align: center;\n  color: #1A3763;\n  margin-top: 3px;\n}\n\n.reqLine {\n  height: 50px;\n  width: 100%;\n  display: inline-flex;\n  /*border-bottom: solid 1px #acacac;*/\n}\n\n.reqName {\n  width: 230px;\n  margin-top: 10px;\n  margin-left: 20px;\n  color: #1A3763;\n  opacity: 80%;\n}\n\n.reqButtonPart {\n  text-align: center;\n}\n\n.buttonCreateOrUpdate {\n  width: 210px;\n}\n\n.mat-button {\n  color: #1A3763;\n  opacity: 60%;\n  font-weight: 500;\n  border: 1px solid rgba(88, 109, 140, 0.5);\n  border-radius: 4px;\n}\n.buttonUpdate {\n  color: white;\n  background-color: #1a3763;\n  opacity: 60%;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
