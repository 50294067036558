import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RappidPaperComponent } from '../rappid-components/rappid-paper/rappid-paper.component';
import { RappidOplComponent } from '../rappid-components/rappid-opl/rappid-opl.component';
import { NoteComponent } from '../models/notes/note/note.component';
import { SharedModule } from './shared/shared.module';

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
    ],
    declarations: [
        RappidPaperComponent,
        RappidOplComponent,
        NoteComponent,
    ],
    exports: [
        RappidPaperComponent,
        RappidOplComponent,
    ]
})
export class RappidModule {
}
