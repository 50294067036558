import {OpmFundamentalLink} from './OpmFundamentalLink';
import {linkType, GeneralizationSVG, GenSVGWithLine, InstantiationSVG} from '../../ConfigurationOptions';

export class GeneralizationLink extends OpmFundamentalLink {
  constructor(sourceElement, targetElement, graph ,id?:string) {
    super(sourceElement, targetElement, graph ,id);
    this.attributes.name = 'Generalization-Specialization';
  }

  getParams() {
    const params = { linkType: linkType.Generalization };
    return {...super.getFundamentalLinkParams(), ...params};
  }

  getTriangleSVG(withLine = false, color =  '#586D8C'): string  {
    if (!withLine) {
      return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="">
        <path fill="${color}" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/><path fill="white" stroke="${color}" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z"/>
        </svg>`
    }
    return `<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" style="">
            <path fill="${color}" fill-opacity="1" stroke="#000000" stroke-opacity="1" stroke-width="2" stroke-dasharray="none" stroke-linejoin="round" stroke-linecap="butt" stroke-dashoffset="" fill-rule="nonzero" opacity="1" marker-start="" marker-mid="" marker-end="" id="svg_1" d="M2.5000095926228028,2.500009592622746 " style="color: rgb(0, 0, 0);"/><path fill="white" stroke="${color}" stroke-width="3" stroke-linejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,24.97922767453879 L15.327331224413275,0.7604779167355875 L28.162149898128664,24.97922767453879 L2.492512550697853,24.97922767453879 z"/>
            <path fill="${color}" stroke="${color}" stroke-width="3" strokelinejoin="round" stroke-dashoffset="" fill-rule="nonzero" marker-start="" marker-mid="" marker-end="" id="svg_3" d="M2.492512550697853,30 L28.162149898128664,30 z"/>
            </svg>`

  }

}
