
import { Injectable } from '@angular/core';

import { StereotypeStorageMode } from '../../../dialogs/stereotypes-dialog/StereotypesRelatedInterface';
import {
  ExamplesType,
  LoadModelDialogComponent,
  ScreenType,
  StorageMode, TemplateType
} from '../../../dialogs/load-model-dialog/load-model-dialog.component';
import { StereotypesDialogComponent } from '../../../dialogs/stereotypes-dialog/stereotypes-dialog.component';
import { MatLegacyDialog as MatDialog } from '@angular/material/legacy-dialog';import { createUrlPopup } from '../../../configuration/rappidEnviromentFunctionality/shared';
import { ContextService } from '../../../modules/app/context.service';
import { ModelService } from '../../../modules/app/model.service';

@Injectable()
export class ModelStorageService {

  constructor(private readonly context: ContextService,
    private readonly modelService: ModelService,
    private readonly dialog: MatDialog) {
  }

  public openLoadModelDialog() {
    this.openModelDialog(StorageMode.LOAD);
  }

  public openUrlDialog() {
    createUrlPopup(this.context.makeUrl());
  }

  public save(image?: string) {
    this.context.save(this, image);
  }

  public getOpmModel() {
    return this.modelService.model;
  }

  public openSaveModelDialog() {
    this.openModelDialog(StorageMode.SAVE);
  }

  public getCurrentDir(path) {
    if (path[path.length - 1] === '/') {
      path = path.substring(0, path.length - 1);
    }
    const lastdir = path.split('/').pop();
    return lastdir;
  }

  public newModel() {
    this.context.newModel();
  }

  public openLoadModelComparisonDialog() {
      this.dialog.open(LoadModelDialogComponent, {
        height: '855px',
        width: '873px',
        data: { path: '', mode: StorageMode.LOAD, comparison: true },
      });
  }

  public openLoadStereotypeDialog() {
    this.openStereotypeDialog(StereotypeStorageMode.LOAD);
  }

  public openSaveStereotypeDialog() {
    this.openStereotypeDialog(StereotypeStorageMode.SAVE);
  }

  public openSetStereotypeDialog() {
    this.openStereotypeDialog(StereotypeStorageMode.SET);
  }

  private openStereotypeDialog(mode: StereotypeStorageMode) {
    this.dialog.open(StereotypesDialogComponent, {
      height: '680px',
      width: '860px',
      data: {
        mode: mode
      },
    });
  }

  public openModelDialog(mode: StorageMode, type?: ScreenType, exampleType?: ExamplesType, templateType?: TemplateType) {
    this.dialog.open(LoadModelDialogComponent, {
      // minHeight: '680px',
      // minWidth: '860px',
      height: Math.round(window.innerHeight * 0.9) + 'px',
      width: Math.round(window.innerWidth * 0.75) + 'px',
      data: {
        path: this.modelService.modelObject.path || '', showVersions: false, mode: mode,
        name: this.modelService.modelObject.name || '', description: '', showArchivedModels: false,
        archiveMode: this.modelService.modelObject.archiveMode || false,
        screenType: type,
        exampleType: exampleType,
        templateType: templateType
      },
    });
    // const elStyle = $('.mat-dialog-container')[0].style;
    // elStyle.overflow = 'hidden';
    // elStyle.position = 'relative';
    // elStyle.resize = 'auto';
  }

  downloadModel() {
    this.context.downloadModel();
  }

  loadModelFromFile(json: Object) {
    this.context.loadModelFromFile(json);
  }

  renameModel(id: string, name: string, sysExamples: boolean, globalTemplates: boolean): Promise<void> {
    return this.context.renameModel(id, name, sysExamples, globalTemplates);
  }
}
