import {Component, OnInit, Optional} from '@angular/core';
import {MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef} from '@angular/material/legacy-dialog';
import {OpmLogicalProcess} from "../../models/LogicalPart/OpmLogicalProcess";
import {OpmLogicalThing} from "../../models/LogicalPart/OpmLogicalThing";
import {OpmLogicalObject} from "../../models/LogicalPart/OpmLogicalObject";
import {GraphService} from "../../rappid-components/services/graph.service";
import {TreeViewService} from "../../rappid-components/services/tree-view.service";
import {OpmModel} from "../../models/OpmModel";
import {OpmOpd} from "../../models/OpmOpd";
import {
  getInitRappidShared,
  OPCloudUtils,
} from '../../configuration/rappidEnviromentFunctionality/shared';
import {InitRappidService} from '../../rappid-components/services/init-rappid.service';

@Component ({
  selector: 'remove-locator',
  templateUrl: 'remove-locator.html',
  styleUrls: ['remove-locator.css'],
})

/**
 * The search component opens up a search box.
 * Once the users enters an input, the dialog box displaying the things and the levels they are located in.
 * The user can click on the wanted "thing" and access the opd where it is located.
 **/
export class RemoveLocator {// implements OnInit{
  searchList;
  constList;
  currentOpd;
  showTypeIndex = 0;
  showType = [OpmLogicalThing, OpmLogicalProcess, OpmLogicalObject];
  searchString = '';
  opmModel: OpmModel;
  graphService: GraphService;
  opd: Array<OpmOpd>;

  constructor(@Optional() public dialogRef: MatDialogRef<RemoveLocator>,
              public treeViewService: TreeViewService) {
    this.opmModel = treeViewService.initRappid.opmModel;
    this.graphService = treeViewService.initRappid.graphService;
    this.opd = treeViewService.initRappid.opmModel.opds;
    this.searchList = [];
    const initRappid: InitRappidService = getInitRappidShared();
    const toRemove = initRappid.getElementToRemove();
    if (toRemove && OPCloudUtils.isInstanceOfDrawnThing(toRemove))
      this.search();
  }

  search() {
    const model = this.treeViewService.initRappid.opmModel;
    const logical = this.treeViewService.initRappid.getElementToRemove().getVisual().logicalElement;
    const visuals = logical.visualElements.filter(v => model.getOpdByThingId(v.id) && !model.getOpdByThingId(v.id)?.isHidden);
    const that = this;
    this.searchList = [{thing: logical, opdElements: visuals.map(vis => { return {name: that.getOpdName(vis.id), id: model.getOpdByThingId(vis.id).id }}).sort((a,b) => {
      return model.opds.indexOf(model.getOpd(a.id)) > model.opds.indexOf(model.getOpd(b.id)) ? 1 : -1;
      })}];
  }

  getOpdName(visualId: string) {
    const opd = this.opmModel.getOpdByThingId(visualId);
    if (opd) {
      const opdSubTitle = this.treeViewService.treeView.treeModel.getNodeById(opd.id)?.data.subTitle || '';
      const isRootSD = opd.getName().endsWith('SD') ? '' : ': ' + opd.getName();
      return 'SD' + opdSubTitle + isRootSD;
    }
    else return 'Unknown';
  }

  goToOpdById(id, element) {
    this.graphService.changeGraphModel(id, this.treeViewService, '', element.thing);
    this.dialogRef.close();
  }

  getToolTipMsg() {
    const element = this.searchList.find(item => (item.thing.constructor.name.includes('Object') || item.thing.constructor.name.includes('Process')) && (<any>item.thing).getBelongsToStereotyped());
    if (element)
      return '*' + (<any>element.thing).getBareName() + ' belongs to a stereotype. Hence, it will be removed visually only.';
    return '';
  }
}
