import {MethodologicalChecker, MethodologicalCheckingStatus} from "../methodological-checker";
import {OpmModel} from "../../../models/OpmModel";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";
import {OpmLogicalObject} from "../../../models/LogicalPart/OpmLogicalObject";
import Pluralize from 'pluralize';

export class ObjectNameAsSingularChecker extends MethodologicalChecker {

  constructor(model: OpmModel) {
    super(model);
    this.title = 'Object name as singular';
  }

  check(): void {
    this.invalidThings = [];
    for (const thing of this.model.logicalElements) {
      if (OPCloudUtils.isInstanceOfLogicalObject(thing)) {
        const bareName = (<OpmLogicalObject>thing).getBareName();
        const splitted = bareName.split(' ');
        const lastWord = splitted[splitted.length - 1];
        if (bareName.toLowerCase().endsWith('s') && lastWord === Pluralize(lastWord)) {
          this.invalidThings.push(thing as OpmLogicalObject);
        }
      }
    }
    this.status = this.invalidThings.length === 0 ? MethodologicalCheckingStatus.VALID : MethodologicalCheckingStatus.INVALID;
  }

  getDescriptionTooltip(): string {
    return `Object name as singular -
  An object must be in singular form.
   For plural use a “Set” for inanimate objects and “Group” for humans.`;
  }
}
