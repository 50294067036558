import { Component, OnInit } from '@angular/core';
import {GraphService} from "../../../rappid-components/services/graph.service";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";

@Component({
  selector: 'opcloud-list-things',
  templateUrl: './list-things.component.html',
  styleUrls: ['./list-things.component.css']
})
export class ListThingsComponent implements OnInit {
  graph;
  htmlList;
  constructor(private graphService: GraphService, private initRappidService: InitRappidService) { }

  ngOnInit() {
    this.graph = this.graphService.getGraph();
    this.list();
  }

  /**
   * Alon: button for mapping elements in the model
   */
  list() {
    const options = this.initRappidService;
     this.htmlList = this.graphService.displayElements(options);
  }

}
