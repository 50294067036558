import {OntologyEnforcementLevel, OntologyItem} from "./ontologyInterfaces";
import {InitRappidService} from "../../../rappid-components/services/init-rappid.service";
import {OntologySuggestionDialog} from "../../../dialogs/ontologySugesstionDialog/ontology-suggestion-dialog";
import {OPCloudUtils} from "../../../configuration/rappidEnviromentFunctionality/shared";


export interface OntologyReplacementDecision {
  shouldReplace: boolean,
  toReplace?: string,
  replacement?: string
}

export class OntologyApplier {

  constructor(private text: string, private init: InitRappidService, private ontology: Array<OntologyItem>, private enforcementLevel: OntologyEnforcementLevel) {

  }

  public getAllSuggestions(): Array<{wordToReplace: string, replacements: Array<string>}> {
    if (this.enforcementLevel === OntologyEnforcementLevel.NONE)
      return [];
    const toSuggest = [];
    for (const item of this.ontology) {
      const wordsToSave = item.synonyms.filter(word => OPCloudUtils.replaceWords(this.text + '', word, '%') !== this.text);
      wordsToSave.forEach(word => {
        let textToTest = '' + this.text;
        for (const phr of item.phrases)
          while(textToTest.includes(phr))
            textToTest = textToTest.replace(phr, '')
        const condition1 = !toSuggest.find(it => it.wordToReplace.toLowerCase() === word.toLowerCase()); // not already suggested.
        const condition2 = !item.phrases.find(ph => textToTest.includes(ph)); // not already exists in the text as it should be.
        const condition3 = textToTest.toLowerCase().includes(word.toLowerCase()); // after cleaning still has the word that needs to be replaced.
        if (condition1 && condition2 && condition3)
          toSuggest.push({wordToReplace: word, replacements: item.phrases})
      });
    }
    return toSuggest
  }

  public openOntologySuggestionDialog(phraseToReplace: string, suggestedReplacements: Array<string>): Promise<OntologyReplacementDecision> {
    const that = this;
    // if there is only one option => don't even show popup - just replace.
    if (suggestedReplacements.length === 1 && that.enforcementLevel === OntologyEnforcementLevel.FORCE)
      return new Promise((resolve, reject) => {
        resolve({shouldReplace: true, toReplace: phraseToReplace, replacement: suggestedReplacements[0]});
      });
    return new Promise((resolve, reject) => {
        const dialog = that.init.dialogService.openDialog(OntologySuggestionDialog, 320, 600, {
          allowMultipleDialogs: true,
          text: that.text,
          phraseToReplace: phraseToReplace,
          suggestedReplacements: suggestedReplacements,
          enforcementLevel: that.enforcementLevel
        });
        dialog.afterClosed().subscribe(val => {
          if (val)
            resolve({shouldReplace: true, toReplace: phraseToReplace, replacement: val});
          else
            resolve({shouldReplace: false});
        });
    });
  }

  updateText(value: string) {
    this.text = value;
  }
}
