import {Component, ElementRef, DoCheck, ViewChild, HostListener, OnDestroy} from '@angular/core';
import { trigger, style, animate, transition } from '@angular/animations';

import { InitRappidService } from '../../../rappid-components/services/init-rappid.service';

import { ChatStorageService } from '../../../rappid-components/services/storage/chat/chat-storage.service';
import { DisplayChat } from '../../../rappid-components/services/storage/chat/chat-storage.interface';
import { NgZone, ChangeDetectorRef } from '@angular/core';

import {
  validationAlert
} from '../../../configuration/rappidEnviromentFunctionality/shared';
import { UserService } from '../../../rappid-components/services/user.service';
import { interval, Subscription } from 'rxjs';
import {ContextService} from '../../app/context.service';
import * as BadWords from 'bad-words';

/*
* This is the component for the OPCloud chat panel:
* a small dialog/box in which the user can send and receive messages in the context of the working model.
*/

@Component({
  selector: 'opcloud-chat-component-panel',
  templateUrl: 'chat.component.panel.html',
  styleUrls: ['chat.component.panel.scss'],
  animations: [
    trigger(
      'inOutAnimation',
      [
        transition(':enter', [
          style({ transform: 'translateY(100%)', opacity: 0 }),
          animate('250ms', style({ transform: 'translateY(0)', opacity: 1 }))
        ]),
        transition(':leave', [
          style({ transform: 'translateY(0)', opacity: 1 }),
          animate('250ms', style({ transform: 'translateY(100%)', opacity: 0 }))
        ])
      ]
    )
  ]

})

export class ChatComponentPanel implements OnDestroy {
  timer: number;
  chatRefreshSubscription: Subscription;
  interval;
  chatRefreshInterval = 5000;
  MODEL_ID: string;
  last_MODEL_ID: string;
  userName: string;
  displayedMessagesArray: Array<DisplayChat>;
  messagesArray: Array<DisplayChat>;
  unreadMessagesArray: Array<DisplayChat>;
  searchedMessagesArray: Array<DisplayChat>;
  unreadMessagesCount = 0; // number of messages which was not even retrieved yet
  unseenMessagesCount = 0; // number of messages which are displayed in history but user needs to scroll down to see
  firstTime: boolean;
  firstTimeScroll: boolean;
  searchString = '';
  showSTB = false;
  historyAtBottom = true;
  historyAtTop = false;
  lastScrollHeight = 0;
  searchActivated = false;
  private cleanLanguage;

  @ViewChild('messageInput') messageInput: ElementRef;
  @ViewChild('history') messageListDiv: ElementRef;

  constructor(private initRappid: InitRappidService,
    public readonly chat: ChatStorageService,
    private readonly userService: UserService,
    private changeDetectorRef: ChangeDetectorRef,
    private context: ContextService,
    private ngZone: NgZone) {
    this.initRappid.ChatComponentRef = this;
    this.cleanLanguage = new BadWords();
  }

  ngOnInit() {
    this.MODEL_ID = (<any>this.context.getCurrentContext())?.properties?.id;
    this.last_MODEL_ID = this.MODEL_ID;
    // using userService to get current user name, for updating UI accordingly
    this.userService.user$.subscribe(user => this.userName = user.userData.Name);
    this.init();
    const this_ = this;
    this.interval = setInterval(function() {
      this_.updateMessages();
    }, this_.chatRefreshInterval);
    this.initRappid.modelService.modelChange$.subscribe(change => this_.updateMessages());
  }

  updateMessages() {
    const that = this;
    this.MODEL_ID = (<any>this.context.getCurrentContext())?.properties?.id;
    if (this.MODEL_ID) {
      if (this.MODEL_ID === this.last_MODEL_ID) {
        if (that.MODEL_ID) {
          this.refreshChatMessages();
        }
      } else { // new model has been saved/ loaded
        this.init();
        this.getUnreadChatMessages();
        // Setup chat refresher only if the chat is saved in DB
        if (that.MODEL_ID) {
          this.refreshChatMessages();
        }
        // update last_MODEL_ID to check if model has changed
        this.last_MODEL_ID = this.MODEL_ID;
      }
    }
  }

  // ngDoCheck() {
  //   const that = this;
  //   this.MODEL_ID = (<any>this.context.getCurrentContext())?.properties?.id;
  //
  //   // Setup chat refresher only if the model is saved in DB
  //   if (this.MODEL_ID || this.initRappid.getOpmModel().permissions.ownerID != '') {
  //     if (this.MODEL_ID === this.last_MODEL_ID) {
  //       // same model, don't change anything
  //     }
  //     else { // new model has been saved/ loaded
  //       this.init()
  //
  //       // get unread count and first messages if the chat is saved in DB
  //       this.getUnreadChatMessages();
  //
  //       // Setup chat refresher only if the chat is saved in DB
  //       this.chatRefreshSubscription = interval(this.chatRefreshInterval).subscribe((x => {
  //         if (that.MODEL_ID)
  //           this.refreshChatMessages();
  //       }));
  //
  //       //update last_MODEL_ID to check if model has changed
  //       this.last_MODEL_ID = this.MODEL_ID;
  //     }
  //
  //   } else if (this.chatRefreshSubscription) {
  //     //remove chat refresher if the model was closed
  //     this.chatRefreshSubscription.unsubscribe();
  //   }
  // }

  // setup the component when the user loads or saves a new model
  init() {
    // module initial values
    this.messagesArray = [];
    this.displayedMessagesArray = [];
    this.unreadMessagesArray = [];
    this.unreadMessagesCount = 0;
    this.initRappid.chatUnreadCount = 0;
    this.firstTime = true;
    this.firstTimeScroll = true;
    this.searchString = '';
  }

  getUnreadChatMessages() {
    this.chat.getUnreadChatMessages(this.MODEL_ID).then(msg => {
      this.unreadMessagesArray = msg;
      this.unreadMessagesCount = msg.length;
      this.initRappid.chatUnreadCount = this.unreadMessagesCount;
      // if this is the first time getMessages is called and there are no unread messages
      // populate the chat panel with all messages
      if (this.firstTime && msg.length === 0) {
        this.getAllChatMessages(true);
        this.firstTime = false;
      }
      // if this is the first time getMessages is called and there are unread messages
      // populate the chat panel with the unread messages
      if (this.firstTime && msg.length > 0) {
        this.messagesArray = msg;
        this.displayedMessagesArray = this.messagesArray;
        this.firstTime = false;
        this.scrollHistoryToBottom(false, 500);
      }
    }
    ).catch(err => console.log(err));
  }

  async getAllChatMessages(shouldScrollDown: boolean = false) {
    return this.chat.getAllChatMessages(this.MODEL_ID).then(msg => {
      // load message in reverse to set the newest message at the bottom
      this.messagesArray = msg.reverse();
      if (this.messagesArray.length !== this.displayedMessagesArray.length && !this.searchActivated) {
        this.unseenMessagesCount += this.messagesArray.length - this.displayedMessagesArray.length;
      }

      this.displayedMessagesArray = this.messagesArray;
      // On first receiving messages, scroll to the bottom = first message
      if (shouldScrollDown) {
        this.scrollHistoryToBottom(false, 500);
      }
      this.messageSearch();
      if (this.historyAtBottom) {
        this.unseenMessagesCount = 0;
        this.scrollHistoryToBottom(false, 0);
      } else if (!this.historyAtBottom && this.unseenMessagesCount > 0) {
        this.showSTB = true;
      }
    }

    ).catch(err => console.log(err));
  }

  // Refresh messages according to module state: opened - actual messages, closed - unread messages
  refreshChatMessages() {
    if (this.initRappid.showChatPanel) {
      this.getAllChatMessages();
    } else {
      this.getUnreadChatMessages();
    }
  }

  // scroll the history section to the bottom (the newest message is at the bottom)
  scrollHistoryToBottom(smooth: boolean = false, delay: number = 600) {
    setTimeout(() => {
      this.messageListDiv.nativeElement.scroll({
        top: this.messageListDiv.nativeElement.scrollHeight,
        left: 0,
        behavior: smooth ? 'smooth' : 'auto'
      });
    }, delay);
    this.unseenMessagesCount = 0;
  }

  // detects scrolling in the history section and handle stb
  scrollDetect(event: Event) {
    // debugger
    const scrollEvent = (event.target as HTMLElement);
    const st = scrollEvent.scrollTop;
    // Scroll is at top + 30px padding
    if (st <= 30) {
      this.showSTB = true;
      this.historyAtTop = true;
    }
    // Scroll is at bottom + 30px padding
    if (st + scrollEvent.offsetHeight >= scrollEvent.scrollHeight - 30) {
      this.showSTB = false;
      this.historyAtBottom = true;
      this.unseenMessagesCount = 0;
    }
    // Scrolling down
    if (st > this.lastScrollHeight) {
      this.showSTB = true;
      this.historyAtTop = false;
    } else {
      // Scrolling down
      this.showSTB = false;
      this.historyAtBottom = false;
    }
    this.lastScrollHeight = st <= 0 ? 0 : st;
  }


  sendChatMessage() {
    if (this.messageInput.nativeElement.value !== '') {
      const msgText = this.cleanLanguage.clean(this.messageInput.nativeElement.value);
      // create DisplayChat structure
      const message = {
        id: '',
        sender: '',
        message: msgText,
        modelId: this.MODEL_ID,
        timestamp: 0,
        type: 'text'
      };
      const msgCopy = Object.assign({}, message, { sender: this.userName, timestamp: Date.now()});
      this.messagesArray?.push(msgCopy);
      this.scrollHistoryToBottom(false, 0);
      // Send the message and retrieve all messages
      this.chat.pushChatMessage(message)
        .then(() => { this.getAllChatMessages(); })
        .catch(err => {
          console.log(err);
          this.messagesArray.pop();
          validationAlert('Error Sending Message: ' + err, 5000);
        });

      // Clear input box after sending the message
      this.messageInput.nativeElement.value = '';
    } else {
      // Prevent sending empty message
      const errMsg = 'Empty Message';
      validationAlert(errMsg, 5000);
    }

  }

  // Create unique color for every sender according to his name
  nameToHexColor(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    let colour = '#';
    for (let i = 0; i < 3; i++) {
      const value = (hash >> (i * 8)) & 0xFF;
      colour += ('00' + value.toString(16)).substr(-2);
    }
    return colour;
  }

  // parse links in text message to <a> tag
  private linkify(plainText): string {
    let replacedText;
    let replacePattern1;
    let replacePattern2;

    // URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = plainText.replace(replacePattern1, '<a class="linkInMessage" href="$1" target="_blank">$1</a>');

    // URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a class="linkInMessage" href="http://$2" target="_blank">$2</a>');

    return replacedText;
  }

  // Detect clicking on link, if the link is to an OPCloud model - load it without refresh, else open the link in a new tab.
  @HostListener('document:click', ['$event'])
  async a_tag_click(e) {
    const element = e.target || e.srcElement;

    // detect <a> class="linkInMessage" click
    if ((element.tagName === 'A' || element.tagName === 'a') && element.className === 'linkInMessage') {
      if (element.href.includes('/load/')) {
        let linkModelId = element.href.substring(element.href.indexOf('/load/') + 6);
        if (linkModelId.includes('/')) {
          linkModelId = linkModelId.substring(0, linkModelId.indexOf('/'));
        }

        e.preventDefault();
        const res = await this.chat.loadModelFromLink(linkModelId);
        if (!res) {
          window.open(element.href, '_blank');
        }

        return !res;
      }
      return false;
    }
    return true;
  }

  // Set message sent time in human readable format, according the time passed
  beautifyTime(value: string | number) {
    this.removeTimer();
    const d = new Date(value);
    const now = new Date();
    const seconds = Math.round(Math.abs((now.getTime() - d.getTime()) / 1000));
    const timeToUpdate = (Number.isNaN(seconds)) ? 1000 : this.getSecondsUntilUpdate(seconds) * 1000;
    this.timer = this.ngZone.runOutsideAngular(() => {
      if (typeof window !== 'undefined') {
        return window.setTimeout(() => {
          this.ngZone.run(() => this.changeDetectorRef.markForCheck());
        }, timeToUpdate);
      }
      return null;
    });
    const minutes = Math.round(Math.abs(seconds / 60));
    const hours = Math.round(Math.abs(minutes / 60));
    const days = Math.round(Math.abs(hours / 24));
    const months = Math.round(Math.abs(days / 30.416));
    const years = Math.round(Math.abs(days / 365));
    if (Number.isNaN(seconds)) {
      return '';
    } else if (seconds <= 45) {
      return 'just now';
    } else if (seconds <= 90) {
      return 'a minute ago';
    } else if (minutes <= 45) {
      return minutes + ' minutes ago';
    } else if (minutes <= 90) {
      return 'an hour ago';
    } else if (hours <= 22) {
      return hours + ' hours ago';
    } else if (hours <= 36) {
      return 'a day ago';
    } else if (days <= 25) {
      return days + ' days ago';
    } else if (days <= 45) {
      return 'a month ago';
    } else if (days <= 345) {
      return months + ' months ago';
    } else if (days <= 545) {
      return 'a year ago';
    } else { // (days > 545)
      return years + ' years ago';
    }
  }

  // remove beautifyTime timer, and chatRefresher
  ngOnDestroy(): void {
    this.removeTimer();
    if (this.interval) {
      clearInterval(this.interval);
    }
    if (this.chatRefreshSubscription) {
      this.chatRefreshSubscription.unsubscribe();
    }
  }

  private removeTimer() {
    if (this.timer) {
      window.clearTimeout(this.timer);
      this.timer = null;
    }
  }

  // Update time passed proportionally
  private getSecondsUntilUpdate(seconds: number) {
    const min = 60;
    const hr = min * 60;
    const day = hr * 24;
    if (seconds < min) { // less than 1 min, update every 2 secs
      return 2;
    } else if (seconds < hr) { // less than an hour, update every 30 secs
      return 30;
    } else if (seconds < day) { // less then a day, update every 5 mins
      return 300;
    } else { // update every hour
      return 3600;
    }
  }

  // display only messages consisting of searchString while on search
  private messageSearch() {
    if (this.searchString.length > 0) {
      this.searchActivated = true;
      this.displayedMessagesArray = this.messagesArray.filter(a => a.message.toLowerCase().indexOf(this.searchString.toLowerCase()) > -1);
    } else {
      this.searchActivated = false;
      this.displayedMessagesArray = this.messagesArray;
    }
  }

  removeChatMessage(item: DisplayChat) {
    const idx = this.messagesArray.indexOf(item);
    if (idx >= 0 && this.isAllowedToRemoveMessage()) {
      this.chat.removeMessage(item.id, this.MODEL_ID).then(r => {}).catch(err => {});
      this.messagesArray.splice(idx, 1);
    }
  }

  isAllowedToRemoveMessage(): boolean {
    return this.userService.isOrgAdmin() || this.userService.isSysAdmin();
  }
}
