import { Component, OnInit } from '@angular/core';
import {InitRappidService} from '../../../rappid-components/services/init-rappid.service';
import {OpmLink} from '../../../models/VisualPart/OpmLink';
import {OpmVisualEntity} from '../../../models/VisualPart/OpmVisualEntity';
import {OpmLogicalEntity} from '../../../models/LogicalPart/OpmLogicalEntity';
import * as FileSaver from 'file-saver';
import {linkType} from '../../../models/ConfigurationOptions';
import {ServerFlatteningService} from "../../../rappid-components/services/server-flattening.service";
import {validationAlert} from "../../../configuration/rappidEnviromentFunctionality/shared";

// Triples and Triple Components types
interface TripleComp {
  type: string;
  opm_type: string;
  label: string;
  id: string;
}
interface Triple  {
  source: TripleComp;
  link: TripleComp;
  target: TripleComp;
}

@Component({
  selector: 'opcloud-model-analysis-tools',
  templateUrl: './model-analysis-tools.component.html',
  styleUrls: ['./model-analysis-tools.component.css']
})
export class ModelAnalysisToolsComponent implements OnInit {

  private initRappid;
  private model;

  constructor(private initRappidService: InitRappidService, private serverFlatteningService: ServerFlatteningService) {
    this.initRappid = initRappidService;
    this.model = initRappidService.getOpmModel();
  }

  ngOnInit() {
  }

  /**
   * This function is marking the current OPD, calls the DSM flattening algorithm and prepare from the data
   * triplets of [Source, Link, Target] with the needed values. Restoring the graph to current OPD once finished.
   * It uses the flattening OPD id for creating the temporary flatten OPD.
   * @private
   */
  private async createTriplesFromOPM(includeExhibit): Promise<Triple[]> {
    const TRIPLEs: Triple[] = [];
    const currOpd = this.model.currentOpd;
    let serverFlattening;
    if (this.initRappidService.oplService.settings.connection.calculationsServer.computingServerCalculations) {
      serverFlattening = await this.serverFlatteningService.getFlattenedModelFromServer(false);
      if (!serverFlattening?.success) {
        validationAlert('Unable to run Flattening algorithm on the BE server. Running it locally.');
      }
    }
    let flatOPD;
    if (serverFlattening?.success) {
      flatOPD = serverFlattening.serverModel.getOpd('OPMqUeRy');
    } else {
      flatOPD = this.initRappid.opmModel.flattening(false);
    }
    const allLinks = flatOPD.visualElements.filter(element => element instanceof OpmLink);
    for (let link = 0; link < allLinks.length; link++) {
      // Source
      const sourceVisual = (<OpmLink>allLinks[link]).sourceVisualElement;
      // This check if at one point a thing was connected to an in-zoomed thing so during flatting it now have multiple sources
      // but none of them are its real source. This needs to be updated in the flattening algorithm.
      if (!(sourceVisual instanceof OpmVisualEntity) ) {
        continue;
      }
      const src_tripleComp: TripleComp = {
        type: 'Source',
        id: sourceVisual.id,
        opm_type: (<OpmVisualEntity>sourceVisual).type.replace('OpmLogical', '').toLowerCase(),
        label: (<OpmLogicalEntity<OpmVisualEntity>>sourceVisual.logicalElement).text.replace(/'/, '')
      };
      // Link
      const linkVisual = (<OpmLink>allLinks[link]);
      const link_tripleComp: TripleComp = {
        type: 'link',
        id: linkVisual.id,
        opm_type: linkType[linkVisual.type],
        label: linkVisual.logicalElement.name
      };
      // Target
      const targetVisual = (<OpmLink>allLinks[link]).targetVisualElements[0].targetVisualElement;
      // This check if at one point a thing was connected to an in-zoomed thing so during flatting it now have multiple targets
      // but none of them are its real target. This needs to be updated in the flattening algorithm.
      if (!(targetVisual instanceof OpmVisualEntity) ) {
        continue;
      }
      let targetName = (<OpmLogicalEntity<OpmVisualEntity>>targetVisual.logicalElement).text.replace(/'/, '');
      if (includeExhibit) {
        const exhibitions = (<OpmLogicalEntity<OpmVisualEntity>>targetVisual.logicalElement).getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition);
        if (exhibitions.length > 0) {
          const names = exhibitions.map(l => (<any>l.sourceLogicalElement).getBareName());
          if (names.length === 1) {
            targetName += ' of ' + names[0];
          } else if (names.length === 2) {
            targetName += ' of ' + names[0] + ' and ' + names[1];
          } else if (names.length > 2) {
            targetName += ' of ' + names.slice(0, names.length - 1).join(', ') + ' and ' + names[names.length - 1];
          }
        }
      }
      const trg_tripleComp: TripleComp = {
        type: 'Target',
        id: targetVisual.id,
        opm_type: (<OpmVisualEntity>targetVisual).type.replace('OpmLogical', '').toLowerCase(),
        label: targetName
      };
      // Merge All
      const curr_triple: Triple = {source: src_tripleComp, link: link_tripleComp, target: trg_tripleComp};
      TRIPLEs.push(curr_triple);
    }
    if (!serverFlattening?.success) {
      this.removeOPMQueryOPD();
    }
    this.model.currentOpd = currOpd;
    return TRIPLEs;
  }

  /**
   * Helper method for deleting the temporary flatten OPD
   */
  removeOPMQueryOPD() {
    this.model.setShouldLogForUndoRedo(false, 'query');
    const opm_q_id = 'OPMqUeRy'; // The ID used at the DSM flattening - needs to be matched
    if (this.model.getOpd(opm_q_id) !== null) {
      this.model.removeOpd(opm_q_id);
    }
    this.model.setShouldLogForUndoRedo(true, 'query');
  }

  /**
   * Helper method to the export CSV file to prepare the CSV array from the triplets data
   */
  public async createTripletsArray(includeExhibit) {
    const tripletsArray = ['Source,', 'Relation,', 'Target\n'];
    const tripletsData = await this.createTriplesFromOPM(includeExhibit);
    for (const triplet of tripletsData) {
      tripletsArray.push(triplet.source.label += ',');
      tripletsArray.push(triplet.link.opm_type += ',');
      tripletsArray.push(triplet.target.label += '\n');
    }
    return tripletsArray;
  }

  /**
   * The main method called from the HTML to export the model triplets data as a CSV file.
   */
  async exportTripletsCSVFile(includeExhibit) {
    const fileName = 'Triplets_' + this.initRappid.opmModel.name;
    const exportTriplesDataArray = await this.createTripletsArray(includeExhibit);
    const exportValuesFile = new Blob(exportTriplesDataArray, { type: 'text/csv' });
    FileSaver.saveAs(exportValuesFile, fileName + '.csv'); // Save the exported file.
  }

}
