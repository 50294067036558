import {OpmVisualEntity} from './OpmVisualEntity';
import {OpmVisualElement} from './OpmVisualElement';
import {OpmFundamentalLink} from './OpmFundamentalLink';
import * as ConfigurationOptions from '../ConfigurationOptions';
import {Affiliation, code, Essence, linkConnectionType, linkType} from '../ConfigurationOptions';
import {OpmOpd} from '../OpmOpd';
import {OPCloudUtils} from '../../configuration/rappidEnviromentFunctionality/shared';
import {OpmProceduralLink} from './OpmProceduralLink';
import {OpmLogicalThing} from '../LogicalPart/OpmLogicalThing';
import {OpmLogicalEntity} from '../LogicalPart/OpmLogicalEntity';
import {OpmVisualState} from './OpmVisualState';
import {EntityType} from '../model/entities.enum';
import {fundamental} from '../consistency/links.set';
import {OpmLogicalProcess} from '../LogicalPart/OpmLogicalProcess';
import {ElementsMap} from '../components/ElementsMap';
import {BackgroundImageState} from "./backgroundImageEnum";
import {BringConnectedEntitiesAction} from "../Actions/BringConnectedEntitiesAction";
import {BringConnectedTypes} from "../Actions/BringConnectedOptionsInterface";


export abstract class OpmVisualThing extends OpmVisualEntity {

  refineable: OpmVisualElement;
  refineeInzooming: OpmVisualElement;
  refineeUnfolding: OpmVisualElement;

  showBackgroundImage: BackgroundImageState = BackgroundImageState.IMAGEONLY;

  semiFolded: Array<any>; // used for carrying the subvisual things that are folded with triangles.
  foldedUnderThing: { isFolded: boolean, triangleType: linkType, realTarget: any, targetPos: { xPos: number, yPos: number } }; // if true, this thing is folded under other thing as triangle.

  static subThingsNumber: number = 3;
  static OpmThingWidth: number = 135;
  static opmThingHeight: number = 60;

  children: Array<OpmVisualEntity> = new Array<OpmVisualEntity>();

  constructor(params, logicalElement) {
    super(params, logicalElement);
    this.semiFolded = params && params.semiFolded ? params.semiFolded : [];
    this.showBackgroundImage = params?.showBackgroundImage;
    this.foldedUnderThing = params && params.foldedUnderThing ? params.foldedUnderThing : {
      isFolded: false, triangleType: undefined,
      realTarget: undefined, targetPos: { xPos: undefined, yPos: undefined }
    };
  }

  updateParams(params) {
    super.updateParams(params);
    this.semiFolded = params.semiFolded ? params.semiFolded : this.semiFolded;
    this.showBackgroundImage = params?.showBackgroundImage;
    if (params.foldedUnderThing) {
      this.foldedUnderThing = {
        isFolded: params.foldedUnderThing.isFolded,
        triangleType: params.foldedUnderThing.triangleType,
        realTarget: params.foldedUnderThing.realTarget,
        targetPos: params.targetPos,
      };
    }
    // let refineable, refineeInzooming, refineeUnfolding;
    // if (this.logicalElement && this.logicalElement.opmModel) {
    //   refineable = this.logicalElement.opmModel.getVisualElementById(params.refineableId);
    //   refineeInzooming = this.logicalElement.opmModel.getVisualElementById(params.refineeInzoomingId);
    //   refineeUnfolding = this.logicalElement.opmModel.getVisualElementById(params.refineeUnfoldingId);
    // }
    // if (!this.refineable)
    //   this.refineable = refineable ? refineable : params.refineableId;
    // if (!this.refineeInzooming)
    //   this.refineeInzooming = refineeInzooming ? refineeInzooming : params.refineeInzoomingId;
    // if (!this.refineeUnfolding)
    //   this.refineeUnfolding = refineeUnfolding ? refineeUnfolding : params.refineeUnfoldingId;
  }

  setParams(params) {
    super.setParams(params);
    this.semiFolded = params.semiFolded ? params.semiFolded : this.semiFolded;
    this.foldedUnderThing = params.foldedUnderThing ? params.foldedUnderThing : this.foldedUnderThing;
    if (params.hasOwnProperty('showBackgroundImage'))
      this.showBackgroundImage = params.showBackgroundImage;
  }

  getThingParams() {
    const childrenIds = [];
    this.children.forEach(function (child) {
      if (child && child.id)
        childrenIds.push(child.id);
    });
    const semifoldedIds = [];
    this.semiFolded.forEach(function (child) {
      if (child && child.id)
        semifoldedIds.push(child.id);
    });
    const params = {
      children: childrenIds,
      semiFolded: semifoldedIds,
      // foldedUnderThing: this.foldedUnderThing,
      showBackgroundImage: this.showBackgroundImage,
      foldedUnderThing: {
        isFolded: this.foldedUnderThing.isFolded,
        triangleType: this.foldedUnderThing.triangleType,
        realTarget: this.foldedUnderThing.realTarget ? this.foldedUnderThing.realTarget.visualElements[0].id : undefined,
        targetPos: this.foldedUnderThing.targetPos && this.foldedUnderThing.targetPos.xPos ? this.foldedUnderThing.targetPos : { xPos: undefined, yPos: undefined },
      },
    };
    return { ...super.getEntityParams(), ...params };
  }

  getThingParamsFromJsonElement(jsonElement) {
    const params = {
      refineableId: jsonElement.refineableId,
      refineeInzoomingId: jsonElement.refineeInzoomingId,
      refineeUnfoldingId: jsonElement.refineeUnfoldingId,
      children: jsonElement.children,
      showBackgroundImage: jsonElement.showBackgroundImage
    };
    return { ...super.getEntityParamsFromJsonElement(jsonElement), ...params };
  }

  public getCorrectStrokeWidth() {
    if (this.getRefineeInzoom() || this.getRefineeUnfold() || (<OpmLogicalThing<OpmVisualThing>>this.logicalElement).getStereotype())
      return 4;
    return 2;
  }

  getShowBackgroundImageState() {
    return this.showBackgroundImage;
  }

  getChildren() {
    return this.children;
  }

  isFoldedUnderThing() {
    if (this.foldedUnderThing?.realTarget) { // a fix for a common bug.
      const logRealTarget = this.logicalElement.opmModel.getLogicalElementByLid(this.foldedUnderThing.realTarget.lid);
      this.foldedUnderThing.realTarget = logRealTarget;
    }
    return this.foldedUnderThing;
  }

  calculateMinHeight() {
    return 0;
  }

  calculateMinWidth() {
    return 0;
  }

  arrangeInnerSemiFoldedThings() {
  }

  setFoldedUnderThing(value: boolean, type: linkType, realTarget: OpmLogicalEntity<OpmVisualEntity>, targetPos: { xPos: number, yPos: number }) {
    this.foldedUnderThing = { isFolded: value, triangleType: type, realTarget, targetPos: targetPos };
  }

  getDisplayText(): string {
    let txt = (<OpmLogicalEntity<OpmVisualEntity>>this.logicalElement).getDisplayText();
    if (this.getRefineeInzoom() === this && this.type === EntityType.Process)
      return txt;
    const logicalExhibitions = (<OpmLogicalEntity<OpmVisualEntity>>this.logicalElement).getLinks().inGoing.filter(l => l.linkType === linkType.Exhibition);
    const visualExhibitions = this.getLinks().inGoing.filter(l => l.type === linkType.Exhibition);
    let needToAdd = false;
    for (const logLink of logicalExhibitions)
      if (!visualExhibitions.find(v => v.logicalElement === logLink))
        needToAdd = true;

    if (!needToAdd)
      return txt;

    const names = logicalExhibitions.map(l => (<any>l.sourceLogicalElement).getBareName());
    if (names.length === 1)
      txt += ' of ' + names[0]
    else if (names.length === 2)
      txt += ' of ' + names[0] + ' and ' + names[1];
    else if (names.length > 2)
      txt += ' of ' + names.slice(0, names.length-1).join(', ') + ' and ' + names[names.length - 1];
    return txt;
  }

  removeThingFromSemiFoldedArray(thing: OpmVisualThing) {
    const idx = this.semiFolded.indexOf(thing);
    if (idx !== -1)
      this.semiFolded.splice(idx, 1);
  }

  public getThingHeritage() {
    const arr = [];
    const logicalThing = this.logicalElement as OpmLogicalEntity<OpmVisualEntity>;
    arr.push(logicalThing);
    let father = logicalThing.getFather();
    while (father && !arr.includes(father)) {
      arr.push(father);
      father = father.getFather();
    }
    arr.push(...logicalThing.getChildrenDeep());
    return arr;
  }

  inzoom(inzoomedOPD: OpmOpd, styleParams): OpmVisualThing {
    let inzoomed = this.clone();
    inzoomedOPD.addElements(inzoomed.children.filter(t => t.constructor.name.includes('State')));
    inzoomedOPD.add(inzoomed);
    inzoomed.refineable = this;
    this.refineeInzooming = inzoomed;
    inzoomed.logicalElement.visualElements.forEach(vis => {
      vis.strokeWidth = 4;
      vis.refineeInzooming = inzoomed;
    });
    let yOffset = 100; // vertical padding. leave some space for text
    let betweenSubProcessesOffset = 30;
    inzoomed.width = OpmVisualThing.OpmThingWidth * 3;
    inzoomed.height = (betweenSubProcessesOffset + OpmVisualThing.opmThingHeight) * OpmVisualThing.subThingsNumber + yOffset + 65;
    inzoomed.xPos = inzoomed.xPos - (inzoomed.width / 2) + (this.width / 2);
    inzoomed.yPos = inzoomed.yPos - (inzoomed.height / 2) + (this.height / 2) + 200;
    inzoomed.textWidth = '50%';
    inzoomed.textHeight = '90%';
    inzoomed.refX = 0.5;
    inzoomed.refY = 0.1;
    inzoomed.yAlign = 'top';
    inzoomed.logicalElement.visualElements.forEach(vis => vis.strokeWidth = 4);
    let startYCoord = inzoomed.yPos + yOffset;
    let xOffset = inzoomed.xPos + inzoomed.width / 2;
    for (let k = 0; k < OpmVisualThing.subThingsNumber; k++) {
      let fatherParams = (<any>this.logicalElement).getParams();
      delete fatherParams['lid'];
      delete fatherParams['timeDurationStatus'];
      delete fatherParams['max'];
      delete fatherParams['min'];
      delete fatherParams['units'];
      delete fatherParams['nominal'];
      delete fatherParams['text'];
      delete fatherParams['isAutoFormat'];
      delete fatherParams['description'];
      delete fatherParams['satisfiedRequirementsSetParams'];
      delete fatherParams['backgroundImageUrl'];
      delete fatherParams['belongsToFatherModelId'];
      let logicalProcess;
      logicalProcess = this.logicalElement.opmModel.logicalFactory(this.type, fatherParams);
      logicalProcess.code = code.Unspecified;
      logicalProcess.URLarray = [{ iconType: 'picture', url: 'http://', description: ' ' }];
      let visualProcess = logicalProcess.visualElements[0];
      if (styleParams) {
        (<OpmVisualThing>visualProcess).applyDefaultStyleParams(styleParams);
      }
      visualProcess.width = OpmVisualThing.OpmThingWidth;
      visualProcess.height = OpmVisualThing.opmThingHeight;
      visualProcess.setNewUUID();
      visualProcess.fatherObject = inzoomed;
      visualProcess.yPos = startYCoord;
      visualProcess.xPos = xOffset - visualProcess.width / 2;
      startYCoord += visualProcess.height + betweenSubProcessesOffset;
      inzoomedOPD.add(visualProcess);
      inzoomed.children.push(visualProcess);
    }
    return inzoomed;
  }

  removeUnneededParams(params) {
    delete params['lid'];
    delete params['timeDurationStatus'];
    delete params['max'];
    delete params['min'];
    delete params['units'];
    delete params['nominal'];
    delete params['text'];
    delete params['isAutoFormat'];
    delete params['description'];
    delete params['satisfiedRequirementsSetParams'];
    delete params['backgroundImageUrl'];
    delete params['belongsToSubModel'];
    delete params['belongsToFatherModelId'];
    return params;
  }

  inzoomInDiagram(inzoomedOPD: OpmOpd): OpmVisualThing {
    const inzoomed = this;
    inzoomed.refineable = this;
    this.refineeInzooming = this;
    const prevHeight = this.height;
    let yOffset = 80;
    const betweenSubThingsOffset = 30;
    inzoomed.width = OpmVisualThing.OpmThingWidth * 2;
    inzoomed.height = (betweenSubThingsOffset + OpmVisualThing.opmThingHeight) * OpmVisualThing.subThingsNumber + 110;
    if (this.fatherObject) {
      this.fatherObject.height = this.fatherObject.height + inzoomed.height - prevHeight;
      this.fatherObject.beautifyInzoomSubThings();
    }
    inzoomed.textWidth = '50%';
    inzoomed.textHeight = '90%';
    inzoomed.refX = 0.5;
    inzoomed.refY = 0.1;
    inzoomed.yAlign = 'top';
    inzoomed.logicalElement.visualElements.forEach(vis => vis.strokeWidth = 4);
    let startYCoord = inzoomed.yPos + yOffset;
    let xOffset = inzoomed.xPos + inzoomed.width / 2;
    for (let k = 0; k < OpmVisualThing.subThingsNumber; k++) {
      const fatherParams = this.removeUnneededParams((<any>this.logicalElement).getParams());
      let logicalThing;
      logicalThing = this.logicalElement.opmModel.logicalFactory(this.type, fatherParams);
      logicalThing.code = code.Unspecified;
      logicalThing.URLarray = [{ iconType: 'picture', url: 'http://', description: ' ' }];
      let visualThing = logicalThing.visualElements[0];
      visualThing.width = OpmVisualThing.OpmThingWidth;
      visualThing.height = OpmVisualThing.opmThingHeight;
      visualThing.setNewUUID();
      visualThing.fatherObject = inzoomed;
      visualThing.yPos = startYCoord;
      visualThing.xPos = xOffset - visualThing.width / 2;
      startYCoord += visualThing.height + betweenSubThingsOffset;
      inzoomedOPD.add(visualThing);
      inzoomed.children.push(visualThing);
    }
    return this;
  }

  beautifyInzoomSubThings(yOffset = 100, betweenSubProcessesOffset = 30) {
    let startYCoord = this.yPos + yOffset;
    const xOffset = this.xPos + this.width / 2;
    for (const child of this.children.filter(c => typeof c === typeof this)) {
      child.yPos = startYCoord;
      child.xPos = xOffset - child.width / 2;
      startYCoord += child.height + betweenSubProcessesOffset;
    }
  }

  unfold(unfoldedOPD, styleParams) {
    const unfolded = this.clone();
    unfolded.refY = 0.5;
    unfolded.refX = 0.5;

    unfolded.yAlign = 'middle';
    unfoldedOPD.add(unfolded);
    unfolded.fatherObject = null;

    if (unfolded.states)
      unfolded.states.forEach(st => unfoldedOPD.add(st));

    unfolded.setNewUUID();
    unfolded.refineable = this;
    this.refineeUnfolding = unfolded;
    unfolded.logicalElement.visualElements.forEach(vis => {
      vis.strokeWidth = 4;
      vis.refineeUnfolding = unfolded;
    });

    const proceduralLinks = this.addStructuralElements(unfoldedOPD, unfolded);
    proceduralLinks.forEach(link => {
      // A. find source and target logical elements of the link
      const proceduralLink = <OpmProceduralLink>link;
      const source = proceduralLink.sourceVisualElement;
      const target = proceduralLink.targetVisualElements[0].targetVisualElement;
      // B. check that both elements have visual elements in the OPD
      const unfoldedSources = source.logicalElement.visualElements.filter(ve =>
        unfoldedOPD.visualElements.includes(ve));
      const unfoldedTargets = target.logicalElement.visualElements.filter(ve =>
        ve === unfolded || unfoldedOPD.visualElements.includes(ve));
      if (unfoldedSources.length > 0 && unfoldedTargets.length > 0) {
        // C. clone the link and add it to the OPD and the logical link as yet another visual instance.
        const newLink = proceduralLink.clone();
        newLink.sourceVisualElement = unfoldedSources[0];
        newLink.targetVisualElements[0].targetVisualElement = unfoldedTargets[0];
        unfoldedOPD.add(newLink);
      }
    });

    if (unfolded.getAllLinks().outGoing.filter(l => fundamental.contains(l.type) && (<OpmLogicalEntity<OpmVisualEntity>>l.target.logicalElement).isSatisfiedRequirementSetObject() === false).length === 0 && !unfolded.getRefineeInzoom()) {
      this.addAggregationsIfHadNone(unfolded, unfoldedOPD, styleParams);
    }
    return unfolded;
  }

  addAggregationsIfHadNone(thing, opd, styleParams) {
    const model = this.logicalElement.opmModel;
    model.setShouldLogForUndoRedo(false, 'addAggregationsIfHadNone');
    const thingType = this.constructor.name.includes('Object') ? 'Object' : 'Process';
    const objectsNum = thingType === 'Object' ? 3 : 0;
    const processesNum = thingType === 'Process' ? 3 : 0;
    const ret = model.createManyThings(opd, processesNum, objectsNum);
    const createdThings = [...ret.objects.map(ob => ob.visual), ...ret.processes.map(pr => pr.visual)];
    ret.processes.forEach(item => (<OpmLogicalProcess>item.logical).code = ConfigurationOptions.code.Unspecified);
    const initialXpos = thing.xPos - 10;
    const initialYpos = thing.yPos + thing.height + 80;
    const linkParams = { type: linkType.Aggregation, connection: linkConnectionType.enviromental };
    for (let i = 0; i < createdThings.length; i++) {
      if (styleParams) {
        (<OpmVisualThing>createdThings[i]).applyDefaultStyleParams(styleParams);
      }
      createdThings[i].xPos = initialXpos + i * 160;
      createdThings[i].yPos = initialYpos;
      createdThings[i].width = 135;
      createdThings[i].height = 60;
      createdThings[i].setEssence(thing.getEssence());
      model.connect(thing, createdThings[i], linkParams);
    }
    model.setShouldLogForUndoRedo(true, 'addAggregationsIfHadNone');
  }

  /**
   * Adds structural elements into the opd and creates fundamental links among embedded children and parents, and also
   * gathered all procedural links associated with a folded elements to be processed in the opd as well when recursive
   * descent is finished.
   * @param inzoomedOPD
   * @param inzoomedObject
   * @param includeEmbedded
   */
  addStructuralElements(inzoomedOPD, inzoomedObject, includeEmbedded = true): OpmVisualElement[] {
    const thingID = this.id;
    const proceduralLinks = this.logicalElement.opmModel.currentOpd.getThingProceduralLinks(this.id);
    let moreLinks = [];
    if (includeEmbedded) {
      moreLinks = this.addEmbeddedElements(inzoomedOPD, inzoomedObject);
      proceduralLinks.concat(moreLinks);
    }
    const structuralLnks = this.logicalElement.opmModel.currentOpd.getThingStructuralLinks(this.id);
    if (structuralLnks.length === 0) {
      return proceduralLinks;
    }
    structuralLnks.forEach((link) => {
      const strctLnk = <OpmFundamentalLink>link;
      if (strctLnk.sourceVisualElement.id === this.id) {
        const newPL = strctLnk.clone();
        this.logicalElement.opmModel.takeCareOfLinkEnds(strctLnk, newPL, thingID, inzoomedObject, inzoomedOPD);
        inzoomedOPD.add(newPL);
        moreLinks = [];
        if (strctLnk.sourceVisualElement.id === thingID) {
          moreLinks = (<OpmVisualThing>strctLnk.targetVisualElements[0]
            .targetVisualElement).addStructuralElements(inzoomedOPD, <OpmVisualThing>(newPL.targetVisualElements[0].targetVisualElement));
        } else if (strctLnk.targetVisualElements[0].targetVisualElement.id === thingID) {
          moreLinks = (<OpmVisualThing>strctLnk.sourceVisualElement)
            .addStructuralElements(inzoomedOPD, <OpmVisualThing>(newPL.sourceVisualElement));
        }
        proceduralLinks.concat(moreLinks);
      }
    });
    return proceduralLinks;
  }

  /**
   * Adds the embedded elements of an inzoomed object, and also collects relevant procedural links of that element
   * to be returned at the conclusion of the recursive call.
   * @param inzoomedOPD
   * @param inzoomedObject
   */
  addEmbeddedElements(inzoomedOPD, inzoomedObject): OpmVisualElement[] {
    const elms = this.logicalElement.visualElements;
    const copied = [];
    const proceduralLinks = [];
    for (let k = 0; k < elms.length; k++) {
      const embedded = (<OpmVisualThing>elms[k]).children.filter(child => !(<OpmVisualThing>elms[k]).semiFolded.includes(child));
      embedded.forEach(child => {
        if (inzoomedObject.constructor === child.constructor) {
          if (copied.find(l => l === child.logicalElement) !== undefined) {
            return;
          }
          const cloned = child.clone();
          copied.push(child.logicalElement);
          cloned.fatherObject = null;
          inzoomedOPD.add(cloned);
          for (const child of (cloned.children || []))
            inzoomedOPD.add(child);
          const model = this.logicalElement.opmModel;
          const linkParams = { type: linkType.Aggregation, connection: linkConnectionType.enviromental };
          const ret = model.connect(inzoomedObject, cloned, linkParams, false);
          const moreLinks = (<OpmVisualThing>child).addStructuralElements(inzoomedOPD, cloned);
          proceduralLinks.concat(moreLinks)
        }
      });
    }
    return proceduralLinks;
  }

  getSemifoldedThingsOrdered(): Array<OpmVisualThing> {
    const orderedTypes = (<OpmLogicalThing<OpmVisualThing>>this.logicalElement).orderedFundamentalTypes || [];
    const ret = this.semiFolded.sort((a,b) => {
      if (a.isFoldedUnderThing().triangleType > b.isFoldedUnderThing().triangleType)
        return 1;
      if (a.isFoldedUnderThing().triangleType < b.isFoldedUnderThing().triangleType)
        return -1;
      if (orderedTypes.includes(a.isFoldedUnderThing().triangleType)) {
        return a.logicalElement.visualElements[0].yPos > b.logicalElement.visualElements[0].yPos ? 1 : -1;
      } else {
        return a.logicalElement.text > b.logicalElement.text ? 1 : -1;
      }
    });
    return ret;
  }

  setPos(x, y) {
    let dx = x - this.xPos;
    let dy = y - this.yPos;
    super.setPos(x, y);
    this.children.forEach((child) => child.setPos(child.xPos + dx, child.yPos + dy));
  }

  isInzoomed(): boolean {
    return !!(this.refineeInzooming || this.refineable);
  }

  isUnfolded(): boolean {
    return !!(this.refineeUnfolding || this.refineable);
  }

  isInzoomedForTooltip(): boolean {
    return !!(this.getRefineeInzoom());
  }

  isUnfoldedForTooltip(): boolean {
    return !!(this.getRefineeUnfold());
  }

  getRefineable() {
    let refineable;
    if (this.getRefineeInzoom())
      refineable = this.getRefineeInzoom().refineable;
    else if (this.getRefineeUnfold())
      refineable = this.getRefineeUnfold().refineable;
    return refineable ? refineable : undefined;
  }

  disconnectRefinable() {
    if (!(<OpmVisualThing>this.getRefineable()))
      return;
    if (((<OpmVisualThing>this.getRefineable()).refineeInzooming === this && !this.getRefineeUnfold()) || ((<OpmVisualThing>this.getRefineable()).refineeUnfolding === this && !this.getRefineeInzoom()))
      (<OpmLogicalThing<OpmVisualThing>>this.logicalElement).resetVisualsStrokeWidth();
    if (this.getRefineeInzoom() === this) {
      for (const vis of this.logicalElement.visualElements) {
        (<OpmVisualThing>vis).refineeInzooming = undefined;
        if (!this.getRefineeUnfold()) {
          (<OpmVisualThing>vis).refineable = undefined;
          (<OpmLogicalThing<OpmVisualThing>>vis.logicalElement).cancelRefineable();
        }
      }
      // (<OpmVisualThing>this.refineable).refineeInzooming = undefined;
    }
    if (this.getRefineeUnfold() === this) {
      for (const vis of this.logicalElement.visualElements) {
        (<OpmVisualThing>vis).refineeUnfolding = undefined;
        if (!this.getRefineeInzoom()) {
          (<OpmVisualThing>vis).refineable = undefined;
          (<OpmLogicalThing<OpmVisualThing>>vis.logicalElement).cancelRefineable();
        }
      }
      // (<OpmVisualThing>this.refineable).refineeUnfolding = undefined;
    }
  }

  getFirstSubProcess(inzoomedOPD) {
    let firstSubProcess = null;
    for (let k = 0; k < inzoomedOPD.visualElements.length; k++)
      if (inzoomedOPD.visualElements[k].fatherObject === this)
        if (firstSubProcess == null)
          firstSubProcess = inzoomedOPD.visualElements[k];
        else if (firstSubProcess.yPos > inzoomedOPD.visualElements[k].yPos)
          firstSubProcess = inzoomedOPD.visualElements[k];
    return firstSubProcess;
  }

  getFirstChild() {
    const children = this.children.filter(v => v.type === this.type);
    let first = children[0];
    for (let k = 1; k < children.length; k++)
      if (first.yPos > children[k].yPos)
        first = children[k];
    return first;
  }

  getLastChild() {
    const children = this.children.filter(v => v.type === this.type);
    let last = children[0];
    for (let k = 1; k < children.length; k++)
      if (last.yPos < children[k].yPos)
        last = children[k];
    return last;
  }

  getLastSubProcess(inzoomedOPD) {
    let firstSubProcess = null;
    for (let k = 0; k < inzoomedOPD.visualElements.length; k++)
      if (inzoomedOPD.visualElements[k] instanceof OpmVisualEntity && inzoomedOPD.visualElements[k].fatherObject === this)
        if (firstSubProcess == null)
          firstSubProcess = inzoomedOPD.visualElements[k];
        else if (firstSubProcess.yPos < inzoomedOPD.visualElements[k].yPos)
          firstSubProcess = inzoomedOPD.visualElements[k];
    return firstSubProcess;
  }

  deleteChild(childId) {
    for (let i = this.children.length - 1; i >= 0; i--) {
      if (this.children[i].id === childId) {
        this.children.splice(i, 1);
        return;
      }
    }
  }

  /*
  bringKnownThings(links: Array<OpmLink>, opd: OpmOpd) {
    for (let i = 0; i < links.length; i++) {
      const link = links[i];
      const relation = <OpmRelation<any>>links[i].logicalElement;
      const source = link.sourceVisualElement;
      const target = link.targetVisualElements[0].targetVisualElement;

      //if (relation.linkType === linkType.SelfInvocation)
      //  continue;

      let aux; // [source, target, index_of_this]
      if (source.logicalElement === this.logicalElement) // ThisThing is the source
        aux = [this, undefined, 0];
      else if (target.logicalElement === this.logicalElement) // ThisThing is the target
        aux = [undefined, this, 1];
      else
        continue;

      // If this link is already presented in this OPD.
      if (opd.visualElements.find(v => v.logicalElement === relation))
        continue;

      // TODO: ifs are taken from OpdModel.ts line 377
      if (this.logicalElement.name === 'OpmLogicalProcess' && this.refineable &&
        (<OpmVisualThing>this.refineable).refineeInzooming === this) {
        if (relation.linkType === linkType.Consumption)
          aux[aux[2]] = this.getFirstSubProcess(opd);
        else if (relation.linkType === linkType.Effect)
          aux[aux[2]] = this.getFirstSubProcess(opd);
        else if (relation.linkType === linkType.Result)
          aux[aux[2]] = this.getLastSubProcess(opd);
      }

      // Find if the second end presents at the current opd
      const visual = <OpmVisualEntity>(aux[2] ? source : target);
      aux[(aux[2] + 1) % 2] = opd.visualElements.find(v => v.logicalElement === visual.logicalElement);

      // If the second end does not present at the current opd
      if (aux[(aux[2] + 1) % 2] === undefined) {
        // If it has a father, bring the father.
        let father = visual.fatherObject;
        if (father) {
          // Search for father at curent opd. If not present, copy it.
          let fatherInCurrentOpd = <OpmVisualThing>opd.visualElements.find(v => v.logicalElement === father.logicalElement);
          if (fatherInCurrentOpd === undefined)
            fatherInCurrentOpd = father.copyToOpd(opd);
          for (let i = 0; i < fatherInCurrentOpd.children.length; i++)
            if (fatherInCurrentOpd.children[i].logicalElement === visual.logicalElement)
              aux[(aux[2] + 1) % 2] = fatherInCurrentOpd.children[i];
        } else {
          let inCurrentOpd = <OpmVisualThing>opd.visualElements.find(v => v.logicalElement === visual.logicalElement);
          if (inCurrentOpd === undefined)
            inCurrentOpd = (<OpmVisualThing>visual).copyToOpd(opd);
          aux[(aux[2] + 1) % 2] = inCurrentOpd;
        }
      }

      // To avoid connection between a thing and it's father.
      if (aux[0] === (<OpmVisualThing>aux[1]).fatherObject || (<OpmVisualThing>aux[0]).fatherObject === aux[1])
        return;

      link.copyToOpd(opd, aux[0], aux[1]);
    }
  }
*/


  bring(model, opt: Array<BringConnectedTypes> = [], styleParams?) {
    const opd = model.getOpdByThingId(this.id);
    (new BringConnectedEntitiesAction(model, this, opd)).act(opt, styleParams);
  }

  // Daniel: Clone the current visual and insert to (current) opd
  copyToOpd(opd: OpmOpd): OpmVisualThing {
    const copy = this.clone();
    (<OpmVisualThing>copy).xPos = this.xPos + 10;
    (<OpmVisualThing>copy).yPos = this.yPos + 10;
    //this.logicalElement.visualElements.push(copy);
    copy.fatherObject = undefined;
    opd.visualElements.push(copy);
    if (copy.children)
      opd.addElements(copy.children);
    return copy;
  }

  applyDefaultStyleParams(styleParams) {
    if (!styleParams)
      return;
    const type = (this.type === EntityType.Object) ? 'object' : 'process';
    this.fill = styleParams[type].fill;
    this.strokeColor = styleParams[type].border_color;
    this.textColor = styleParams[type].text_color;
    this.textFontFamily = styleParams[type].font;
    this.textFontSize = styleParams[type].font_size;
  }

  getAffiliation(): Affiliation {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;
    return logical.affiliation;
  }

  setFatherObject(father: OpmVisualThing) {
    this.fatherObject = father;
    if (!this.fatherObject.children.includes(this))
      this.fatherObject.children.push(this);
  }

  toggleAffiliation(): { changed: boolean, value: Affiliation, reason?: string } {
    this.logicalElement.opmModel.logForUndo((<OpmLogicalThing<OpmVisualThing>>this.logicalElement).text + ' change affiliation');
    const affiliation = this.getAffiliation();

    // toggle
    let value: Affiliation;
    if (affiliation === Affiliation.Environmental)
      value = Affiliation.Systemic;
    else if (affiliation === Affiliation.Systemic)
      value = Affiliation.Environmental;

    return this.setAffiliation(value);
  }

  private setAffiliation(affiliation: Affiliation): { changed: boolean, value: Affiliation, reason?: string } {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;

    const checkLegality = this.isLegalAffiliation(affiliation);
    if (checkLegality.isLegal) {
      logical.affiliation = affiliation;
      return { changed: true, value: affiliation };
    }

    return { changed: false, value: logical.affiliation, reason: checkLegality.reason };
  }

  private isLegalAffiliation(affiliation: Affiliation): { isLegal: boolean, reason?: string } {
    if ((<any>this.logicalElement).getBelongsToStereotyped() && (<OpmLogicalThing<OpmVisualThing>>this.logicalElement).affiliation !== affiliation)
      return { isLegal: false, reason: 'Cannot change affiliation for a thing that belongs to a stereotype.' };

    if (this.logicalElement.belongsToFatherModelId) {
      return { isLegal: false, reason: 'Cannot change affiliation for a shared thing with a father model.' };
    }
    if (this.logicalElement.visualElements.find(v => v.protectedFromBeingChangedBySubModel)) {
      return { isLegal: false, reason: 'Cannot change affiliation for a shared thing with a sub model.' };
    }
    return { isLegal: true };
  }

  CanBeComputational(): boolean {
    const condition = !(<any>this.logicalElement).getBelongsToStereotyped() || ((<any>this.logicalElement).getBelongsToStereotyped() && this.isComputational());
    return (this.getEssence() === Essence.Informatical) && condition && !(<any>this.logicalElement).getStereotype();
  }

  getEssence(): Essence {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;
    return logical.essence;
  }

  toggleEssence(): { changed: boolean, value: Essence, reason?: string } {
    this.logicalElement.opmModel.logForUndo((<OpmLogicalThing<OpmVisualThing>>this.logicalElement).text + ' change essence');
    const essence = this.getEssence();

    // toggle
    let value: Essence;
    if (essence === Essence.Informatical)
      value = Essence.Physical;
    else if (essence === Essence.Physical)
      value = Essence.Informatical;

    return this.setEssence(value);
  }

  public changeEssence(essence: Essence) {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;
    logical.essence = essence;
  }

  public setEssence(essence: Essence): { changed: boolean, value: Essence, reason?: string } {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;

    const checkLegality = this.isLegalEssence(essence);
    if (checkLegality.isLegal) {
      logical.essence = essence;
      return { changed: true, value: essence };
    }

    return { changed: false, value: logical.essence, reason: checkLegality.reason };
  }

  public isLegalEssence(essence: Essence): { isLegal: boolean, reason?: string } {
    const logical = <OpmLogicalThing<OpmVisualThing>>this.logicalElement;

    if ((logical.getBelongsToStereotyped() && essence !== logical.essence) || (logical.getStereotype()))
      return { isLegal: false };

    if (essence === Essence.Physical && logical.isComputational())
      return { isLegal: false };

    if (essence === Essence.Informatical && this.getAllLinks().outGoing.find(l => l.type === linkType.Agent))
      return { isLegal: false };

    if (essence === Essence.Informatical && this.getLinks().inGoing.find(l => l.type === linkType.Generalization && (<any>l.source.logicalElement).essence === Essence.Physical))
      return { isLegal: false };

    const fatherElement = this.fatherObject;
    if (fatherElement && (<OpmVisualThing>fatherElement).getEssence() === Essence.Informatical && essence === Essence.Physical) {
        return { isLegal: false };
    }

    if (this.logicalElement.belongsToFatherModelId) {
      return { isLegal: false, reason: 'Cannot change essence for a shared thing with a father model.' };
    }

    if (this.logicalElement.visualElements.find(v => v.protectedFromBeingChangedBySubModel)) {
      return { isLegal: false, reason: 'Cannot change essence for a shared thing with a sub model.' };
    }

    if (this.getRefineeInzoom() && this.children.length > 0) {
      const isPhysicalChild = this.children.find(child => OPCloudUtils.isInstanceOfVisualThing(child) && (<OpmVisualThing>child).getEssence() === Essence.Physical);
      if (isPhysicalChild && essence === Essence.Informatical) {
        return { isLegal: false };
      }
    }

    const exh = this.getAllLinks().inGoing.find(l => l.type === linkType.Exhibition);
    if (exh) {
      const source = exh.source.type === EntityType.State ? exh.source.fatherObject : exh.source;
      const target = exh.target as OpmVisualEntity;
      if (source.type === EntityType.Object && target.type === EntityType.Object && essence === Essence.Physical)
        return { isLegal: false };
      if (source.type === EntityType.Object && (<any>source).getEssence() === Essence.Informatical && target.type === EntityType.Process)
        return { isLegal: false };
      if (source.type === EntityType.Process && target.type === EntityType.Object && essence === Essence.Physical)
        return { isLegal: false };
      if (source.type === EntityType.Process && (<any>source).getEssence() === Essence.Informatical && target.type === EntityType.Process)
        return { isLegal: false };
    }
    return { isLegal: true };
  }

  public isComputational(): boolean {
    return (<OpmLogicalThing<OpmVisualThing>>this.logicalElement).isComputational();
  }

  getHaloHandles() {
    const isInzoomed = !!(this.getRefineeInzoom());
    const isUnfolded = !!(this.getRefineeUnfold());
    const inzoom = isInzoomed ? 'ShowInZoom' : 'inzoom';
    const unfold = isUnfolded ? 'ShowUnfold' : 'unfold';
    const handles = [...super.getHaloHandles(),];

    if (!isInzoomed && !isUnfolded)
      handles.push('computation');

    if (this.isComputational()) {
      handles.push('deleteFunction');
      handles.push('updateComputationalProcess');
    }
    else
      handles.push(inzoom, unfold);

    handles.push('addConnected');
    return handles;
  }

  public setReferencesFromJson(json: any, map: ElementsMap<OpmVisualElement>): void {
    super.setReferencesFromJson(json, map);
    if (!json.children)
      json.children = [];
    for (const childId of json.children)
      this.children.push(<any>(map.get(childId)));
    // this.semiFolded.forEach(s => this.semiFolded[this.semiFolded.indexOf(s)] = <any>(map.get(s)));
    this.semiFolded = this.semiFolded.map(s => typeof s === 'string' ? <any>(map.get(s)) : s);
    // the following code prevent unnecessary visual removal due to refinee/refineable information missing.
    // later the autofix knows to complete the missing information
    try {
      this.refineable = map.get(json.refineableId);
    } catch (err) {
      this.refineable = undefined;
      console.error('refineable not found!');
    }
    try {
      this.refineeInzooming = map.get(json.refineeInzoomingId);
    } catch (err) {
      this.refineeInzooming = undefined;
      console.error('refinee Inzooming not found!');
    }
    try {
      this.refineeUnfolding = map.get(json.refineeUnfoldingId);
    } catch (err) {
      this.refineeUnfolding = undefined;
      console.error('refinee Unfolding not found!');
    }
  }

  public afterCreatingReferencesFix(map: ElementsMap<OpmVisualElement>) {
    const inzooming = this.refineeInzooming as OpmVisualThing;
    if (inzooming && !inzooming.refineable)
      inzooming.refineable = this;
    const unfolded = this.refineeUnfolding as OpmVisualThing;
    if (unfolded && !unfolded.refineable)
      unfolded.refineable = this;

    if (this.semiFolded && this.semiFolded.length > 0)
      this.semiFolded = this.semiFolded.map(s => typeof s === 'string' ? map.get(s) : s);

    if (this.fatherObject && this.fatherObject.constructor.name.includes('tring'))
      this.fatherObject = undefined;
    const father = this.fatherObject as OpmVisualThing;
    if (father && !father.children.find(v => v === this))
      father.children.push(this);
    for (const child of this.children) {
      if (!child.fatherObject)
        child.fatherObject = this;
    }

    if (this.isFoldedUnderThing().isFolded && typeof this.foldedUnderThing.realTarget === 'string')
      this.foldedUnderThing.realTarget = <any>map.get(<any>this.foldedUnderThing.realTarget).logicalElement;
  }

  getThingChildrenOrder(): Array<OpmVisualThing> {
    let sortedArray = new Array<OpmVisualThing>();
    this.children.forEach(child => {
      if (child instanceof OpmVisualThing)
        sortedArray.push(child);
    });
    sortedArray = sortedArray.sort((n1, n2) => {
      if (n1.yPos > n2.yPos)
        return 1;
      else if (n1.yPos < n2.yPos)
        return -1;
      return 0;
    });
    return sortedArray;
  }

  // gets a refinee ( unfold / inzoom ) and returns the closest visual to the root SD up the opds tree.
  // the last element in the array is the closest visual to the Root SD.
  getVisualsSortedFromRefineeToRoot(refinee: OpmVisualThing): Array<OpmVisualThing> {
    const model = this.logicalElement.opmModel;
    const refineeOpd = model.getOpdByThingId(refinee.id);
    const ret = [];
    let currentOpd = model.getOpd(refineeOpd.parendId);
    while (currentOpd?.parendId) {
      const visAtOpd = currentOpd.visualElements.filter(vis => vis.logicalElement === refinee.logicalElement);
      ret.push(...visAtOpd);
      if (currentOpd.getName() === 'SD' && currentOpd.parendId === 'SD')
        break;
      currentOpd = model.getOpd(currentOpd.parendId);
    }
    return ret;
  }

  public getRefineeInzoom(): OpmVisualThing {
    const visuals = this.logicalElement.visualElements as Array<OpmVisualThing>;
    for (const visual of visuals)
      if (visual.refineeInzooming)
        return visual.refineeInzooming as OpmVisualThing;
    return undefined;
  }

  public getRefineeUnfold(): OpmVisualThing {
    const visuals = this.logicalElement.visualElements as Array<OpmVisualThing>;
    for (const visual of visuals)
      if (visual.refineeUnfolding)
        return visual.refineeUnfolding as OpmVisualThing;
    return undefined;
  }

  public canBeRemoved(): boolean {
    return canBeRemoved(this, this.logicalElement as OpmLogicalThing<OpmVisualThing>);
  }

  public remove(): ReadonlyArray<OpmVisualElement> {
    if (this.getRefineable() === this) {
      let array;
      if (this.getRefineeInzoom() === this || this.getRefineeInzoom() === this.getRefineable())
        array = [...this.logicalElement.visualElements];
      else array = this.getVisualsSortedFromRefineeToRoot(this);
      const newRefineable = array[array.length - 1] === this ? array[array.length - 2] : array[array.length - 1];
      const refineeInzooming = this.getRefineeInzoom();
      const refineeUnfolding = this.getRefineeUnfold();
      this.logicalElement.visualElements.forEach(vis => {
        (<OpmVisualThing>vis).refineable = newRefineable;
        (<OpmVisualThing>vis).refineeInzooming = refineeInzooming;
        (<OpmVisualThing>vis).refineeUnfolding = refineeUnfolding;
      });
    }
    if (this.fatherObject) {
      if (this.isFoldedUnderThing().isFolded) {
        const idx = this.fatherObject.semiFolded?.indexOf(this);
        if (idx >= 0)
          this.fatherObject.semiFolded.splice(idx, 1);
      }
      const idx = this.fatherObject.children.indexOf(this);
      if (idx >= 0)
        this.fatherObject.children.splice(idx, 1);
    }

    // do not move before the code above. it is needed to have the super information first.
    const ret = super.remove();

    const logical = this.logicalElement as any;
    let removeLogical = false;
    if (logical.isValueTyped && logical.isValueTyped()) {
      removeLogical = logical.valuedObjectFor.getValidationModule().valueTypeElement == undefined;
    } else if (logical.visualElements.filter(v => v.isAtRangesOpd() == false).length == 0) {
      removeLogical = true;
    }

    return [].concat(ret).concat(remove(this, logical, removeLogical));
  }

  isSemiFolded() {
    return this.semiFolded.length > 0;
  }

  addToSemiFoldedArray(item: OpmVisualThing) {
    this.semiFolded.push(item);
  }

  removeFromSemiFoldedArray(item: OpmVisualThing) {
    if (this.semiFolded.includes(item))
      this.semiFolded.splice(this.semiFolded.indexOf(item), 1);
  }

  isAtRangesOpd(): boolean {
    const logical = this.logicalElement as OpmLogicalThing<OpmVisualThing>;
    const model = logical.opmModel;
    const rangesOpd = model.getRangesOpd();
    if (rangesOpd)
      return rangesOpd.hasVisual(this);
    return false;
  }

  inheritPort(original: OpmVisualEntity, newVisual: OpmVisualEntity, portId) {
    newVisual.ports = newVisual.ports || [];
    if (!newVisual.ports.find(p => p.id === portId)) {
      const portToCopy = original.ports?.find(pr => pr.id === portId);
      if (portToCopy)
        newVisual.ports.push(portToCopy);
    }
  }
}

function canBeRemoved(visual: OpmVisualThing, logical: OpmLogicalThing<OpmVisualThing>): boolean {

  const inzoomed = visual.getRefineeInzoom();
  if (visual.getRefineeInzoom() && visual.getRefineable() === inzoomed)
    return true;
  if (inzoomed === visual)
    return false;
  if (inzoomed && inzoomed !== visual) {
    const closestVisToRootArray = visual.getVisualsSortedFromRefineeToRoot(inzoomed);
    if ((closestVisToRootArray.indexOf(visual) !== -1) && (closestVisToRootArray.length <= 1))
      return false;
  }

  if (visual.protectedFromBeingChangedBySubModel || visual.belongsToFatherModelId) {
    return false;
  }

  if ((<any>logical).getParams().isWaitingProcess)
    return false;

  const unfolded = visual.getRefineeUnfold();
  if (unfolded) {
    if (unfolded === visual)
      return false;
    const closestVisToRootArray = visual.getVisualsSortedFromRefineeToRoot(unfolded);
    if ((closestVisToRootArray.indexOf(visual) !== -1) && (closestVisToRootArray.length <= 1))
      return false;
  }

  for (const child of visual.children)
    if (!child.canBeRemoved() && !(child instanceof OpmVisualState))
      return false;

  return true;
}

function remove(visual: OpmVisualThing, logical: OpmLogicalThing<OpmVisualThing>, removeLogical: boolean): ReadonlyArray<OpmVisualElement> {
  const elements = new Array<OpmVisualElement>();

  if (removeLogical) {
    logical.opmModel.removeLogicalElement(logical);
    if ((<any>logical).states) // TODO: Move to Object's class
      [].concat((<any>logical).states).forEach(s => logical.opmModel.removeLogicalElement(s));
  }

  if (visual.semiFolded?.length)
    for (let i = visual.semiFolded.length - 1 ; i >= 0 ; i--)
      if (visual.semiFolded[i]?.remove)
        visual.semiFolded[i].remove();

  visual.disconnectRefinable();
  [].concat(visual.children).forEach(c => elements.push(...c.remove()));

  return elements;
}
