import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MainComponent } from './main/main.component';
import { HeaderComponent } from './header/header.component';
import { SharedModule } from '../shared/shared.module';
import { OplContainerComponent } from './opl-container/opl-container.component';
import { FormsModule } from '@angular/forms';
import { RappidModule } from '../rappid.module';
import { ListLogicalComponent } from './main/list-logical/list-logical.component';
import { OPDHierarchyComponent } from '../../opd-hierarchy/opd-hierarchy.component';
import { TreeModule } from '@ali-hm/angular-tree-component';
import { ModelService } from '../app/model.service';
import { RouterModule } from '@angular/router';
import { ElementToolBarComponent } from './element-tool-bar/element-tool-bar.component';
import { OplService } from '../../opl-generation/opl.service';
import { LinksDialogComponent } from '../../dialogs/choose-link-dialog/Dialog.component';
import { TreeViewService } from '../../rappid-components/services/tree-view.service';
import { NoteService } from '../../models/notes/note.service';
import { InitRappidService } from '../../rappid-components/services/init-rappid.service';
import { GraphService } from '../../rappid-components/services/graph.service';
import { DialogService } from "../../rappid-components/services/dialog.service";
import { RemoveOperationComponent } from '../../dialogs/remove-operation-selection/remove-operation';
import { RemoveLocator } from '../../dialogs/remove-locator/remove-locator';
import { RemoveLocatorLinks } from '../../dialogs/remove-locator-links/remove-locator-links';
import { MatSidenavModule } from '@angular/material/sidenav';
import { VersionLayoutModule } from '../../../environments/version/version';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { NavigatorComponent } from './opcloud-navigator/navigator.component';
import { SearchItemsDialogComponent } from '../../dialogs/search-items-dialog/search-items-dialog.component';
import {MatLegacySliderModule as MatSliderModule} from "@angular/material/legacy-slider";
import {MatBadgeModule} from "@angular/material/badge";
import {ChatComponentFloat} from "./opcloud-chat-component-float/chat.component.float";
import {ChatComponentPanel} from "./opcloud-chat-component-panel/chat.component.panel";
import {ExistingNameDialogComponent} from "../../dialogs/existing-name-dialog/existing-name-dialog.component";
import {TabTitlePipe} from "../../pipes/tab-title-pipe.pipe";
import {FontHighlightDirective} from "./element-tool-bar/directives/font-highlight.directive";

@NgModule({
    imports: [
        CommonModule,
        SharedModule,
        TreeModule,
        RouterModule,
        FormsModule,
        RappidModule,
        MatSidenavModule,
        VersionLayoutModule,
        DragDropModule,
        MatSliderModule,
        MatBadgeModule,
        TabTitlePipe,
        FontHighlightDirective,
    ],
    providers: [
        GraphService,
        ModelService,
        InitRappidService,
        NoteService,
        TreeViewService,
        OplService,
        DialogService
    ],
    declarations: [
        MainComponent,
        HeaderComponent,
        OPDHierarchyComponent,
        OplContainerComponent,
        LinksDialogComponent,
        ListLogicalComponent,
        ElementToolBarComponent,
        RemoveOperationComponent,
        RemoveLocator,
        RemoveLocatorLinks,
        NavigatorComponent,
        SearchItemsDialogComponent,
        ChatComponentFloat,
        ChatComponentPanel,
        ExistingNameDialogComponent
    ]
})
export class LayoutModule { }
